import { FC, useState } from 'react';
import './dinamicPills.scss';
interface IDinamicPillsDetails {
  text?: string;
  hoverText?: string;
}

interface IDinamicPills {
  dataPills: IDinamicPillsDetails;
  elementsNumber: number;
}

const DinamicPills: FC<IDinamicPills> = ({ dataPills, elementsNumber }: IDinamicPills) => {
  const maxWidth = 100 / elementsNumber;
  const [showOver, setShowOver] = useState<boolean>();
  return (
    <div
      id="dinamicPills"
      onMouseEnter={() => {
        setShowOver(true);
      }}
      onMouseOver={() => {
        setShowOver(true);
      }}
      onMouseLeave={() => {
        setShowOver(false);
      }}
      style={{ maxWidth: `${maxWidth || 100}%`, border: showOver ? '1px solid #FF664E' : '' }}
    >
      <div className={`normalTextPills truncate ${showOver ? 'hoverText' : ''}`}> {dataPills?.text}</div>
      {showOver && dataPills?.hoverText && <div className="hoverPills">{dataPills?.hoverText}</div>}
    </div>
  );
};

export default DinamicPills;
