import { displayErrorNotification } from './displayNudosStandardNotifications';

const isFileValid = (
  file: File,
  validFormats: string[],
  sizeLimitInMegabytes: number,
  formatErrorMessage: string,
  sizeErrorMessage: string
) => {
  const isFormatCorrect = validFormats.includes(file.type);
  const isSizeValid = file.size / 1024 / 1024 < sizeLimitInMegabytes;
  if (!isFormatCorrect) {
    displayErrorNotification({ customJSXMessage: <>{formatErrorMessage}</> });
    return false;
  }
  if (!isSizeValid) {
    displayErrorNotification({ customJSXMessage: <>{sizeErrorMessage}</> });
    return false;
  }
  return true;
};

export default isFileValid;

export const validFormatsForDocumentsIncludingImages = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/webp',
  'image/gif',
  'image/tiff',
  'image/svg+xml'
];

export const formatErrorTextForImageDocuments =
  'El documento debe estar en formato PDF o alguno de estos formatos de imagen: png, jpeg, webp, gif, tiff, svg';
