import { FC } from 'react';
import CheckIcon from '../../../../assets/images/components/customCheck/CheckIcon';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import tems from '../../../../assets/docs/terms.pdf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import privacy from '../../../../assets/docs/privacy.pdf';
import { useTranslation, Trans } from 'react-i18next';

interface ICustomCheck {
  check: boolean;
  onChangeState: () => void;
  type: string;
}

const CustomCheck: FC<ICustomCheck> = ({ check, onChangeState, type }: ICustomCheck) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'authentication:SignUpForm:useSignUpFormStep3Controller:customCheck:';

  const changeState = () => {
    onChangeState();
  };

  const openTerms = () => {
    window.open(tems, '_blank');
  };

  const openSecondTerms = () => {
    window.open(privacy, '_blank');
  };

  return (
    <div id="customCheck">
      {check ? (
        <div className={'mr-1.5 checked'} onClick={changeState}>
          <div className="positionArrow">
            <CheckIcon />
          </div>
        </div>
      ) : (
        <div className="noChecked" onClick={changeState} />
      )}
      {type === 'terms' ? (
        <div className="textCheck" onClick={openTerms}>
          <Trans
            i18nKey={t(`${i18nLanguageKey}textCheckTerms`)}
            components={{ 1: <span className="underline"></span> }}
          />
        </div>
      ) : (
        <div className="textCheck" onClick={openSecondTerms}>
          <Trans
            i18nKey={t(`${i18nLanguageKey}textCheckPrivacy`)}
            components={{ 1: <span className="underline"></span> }}
          />
        </div>
      )}
    </div>
  );
};

export default CustomCheck;
