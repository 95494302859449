import { useEffect, useState } from 'react';
import ContainerModules from '../../../components/containerModules';
import { v4 as uuid } from 'uuid';

import {
  addNewAddressOffice,
  getOrganizationAccount,
  getPersonalAccount,
  servicesLocationAddress,
  updateOrganizationData,
  updatePersonalData,
  updateStatusAddress
} from '../../../services/account';
import { uploadProfilePhoto, getUsersOrganizationData } from '../../../services/services';
import useProfileImage from '../../../state/useProfileImage';
import { useUserAuth } from '../../../state/userAuthContext';
import {
  IListLocationAddress,
  ILocationAddress,
  ILocationCountryAddress,
  IOrganization,
  IPersonal
} from '../../../types/account';
import useEcommerceControler from '../../ecommerce/ecommerce.controller';
import {
  CompanyForm,
  LoaderOrganization,
  LoaderPersonal,
  ModalAddressDetail,
  PersonalForm,
  Tabs,
  ModalAddOfficeAddress
} from './components/AccountComponents';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import segment from '../../../utils/segment';
import { NudosGenericModal, NudosModalContent } from '../../../components/NudosComponents';
import ModalEditAddress from './components/editAddress/ModalEditAddress';
import { ICountryDetail } from '../../../types/countries';
import { getCountriesList } from '../../../services/countries.service';
import OrganizaztionAddressesTab from './components/OrganizationAddressesTab/OrganizationAddressesTab';
import OrganizationBillingData from './components/OrganizationBillingData/OrganizationBillingData';
import { ICoordinates, Tany } from '../../../types/global';
import { useTranslation } from 'react-i18next';
import './AccountModule.scss';

interface IPersonalInfo {
  firstName: string;
  lastName: string;
  phone: string;
  countryPhoneId: number;
}

interface iUpdateAddress {
  address?: string;
  additionalReferences?: string | null;
  country?: string;
  coordinates: ICoordinates;
  city: string;
}

interface IOrganizationInfo {
  image?: string;
  file?: Tany;
  numberOfEmployees: number | string;
  address?: iUpdateAddress;
}

const Account = () => {
  const { t } = useTranslation();
  const { orgData } = useEcommerceControler();
  const { user } = useUserAuth();
  const { setTemporalImg } = useProfileImage();
  const [selectTab, setSelectTab] = useState<number>(0);
  const [personalData, setPersonalData] = useState<IPersonal>();
  const [loadingPersonal, setLoadingPersonal] = useState<boolean>(false);
  const [organizationData, setOrganizationData] = useState<IOrganization>();
  const [loadingOrganization, setLoadingOrganization] = useState<boolean>();
  const [isPersonalEdit, setIsPersonalEdit] = useState<boolean>(false);
  const [modalAddress, setModalAddress] = useState<ILocationAddress>();
  const [openModalNewAddress, setOpenModalNewAddress] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [loadingNewAddres, setLoaddingNewAddress] = useState<boolean>(false);
  const [addressList, setAddressList] = useState<IListLocationAddress>();
  const [validCountryList, setValidCountryList] = useState<ICountryDetail[]>();
  const [totalAvailableCountriesList, setTotalAvailableCountriesList] = useState<ICountryDetail[]>();
  const [editDefaultAddress, setEditDefaultAddress] = useState<ILocationAddress>();
  const [editAddressCountryData, setEditAddressCountryData] = useState<ICountryDetail>();
  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();
  const i18nLanguageKey = 'nodi:account:';

  const isImageFileValid = (file: File) => {
    const isFormatCorrect = file.type === 'image/png' || file.type === 'image/jpeg';
    const isSizeValid = file.size / 1024 / 1024 < 2;
    if (!isFormatCorrect) {
      displayErrorNotification({
        customJSXMessage: <>{t(`recurrentWords:isImageFileValid:format`)}</>
      });
      return false;
    }
    if (!isSizeValid) {
      displayErrorNotification({
        customJSXMessage: <>{t(`recurrentWords:isImageFileValid:size`)}</>
      });
      return false;
    }
    return true;
  };

  const handleFileChange = async (event: Tany) => {
    const uploadedFile = event.target.files[0];
    const validFile = isImageFileValid(uploadedFile);
    if (!validFile) return;
    const formData = new FormData();
    formData.append('file', uploadedFile, `img_profile-${uuid()}`);
    try {
      const response = await uploadProfilePhoto(orgData.userId, formData);
      if (response && user) {
        const userData = await getUsersOrganizationData(user.uid);
        setTemporalImg(userData.photoProfile || '');
        localStorage.setItem('orgData', JSON.stringify(userData));
        displaySuccessNotification({ customJSXMessage: <>{t(`${i18nLanguageKey}handleFileChange`)}</> });
      }
    } catch (error) {
      displayErrorNotification();
    }
  };

  const getPersonal = async () => {
    setLoadingPersonal(true);
    try {
      const dataPersonal = await getPersonalAccount(orgData.userId);
      setPersonalData(dataPersonal);
      dataPersonal && localStorage.setItem('personalData', JSON.stringify(dataPersonal));
      setLoadingPersonal(false);
    } catch (error) {
      setLoadingPersonal(false);
      displayErrorNotification();
    }
  };

  const getOrganization = async () => {
    setLoadingOrganization(true);
    try {
      const dataOrganization = await getOrganizationAccount(orgData?.organizationId);
      setOrganizationData(dataOrganization);
      setLoadingOrganization(false);
    } catch (error) {
      setLoadingOrganization(false);
      displayErrorNotification();
    }
  };

  const updatePersonalInfo = async (personalInfo: IPersonalInfo) => {
    setLoadingPersonal(true);
    try {
      const formData = new FormData();
      formData.append(
        'body',
        JSON.stringify({
          firstName: personalInfo?.firstName || '',
          lastName: personalInfo?.lastName || '',
          countryPhoneId: personalInfo?.countryPhoneId || '',
          phone: personalInfo?.phone || ''
        })
      );

      await updatePersonalData(orgData.userId, formData);
      await getPersonal();
      setIsPersonalEdit(false);
      displaySuccessNotification({ customJSXMessage: <>{t(`${i18nLanguageKey}changesSaved`)}</> });
      segment.sessionEndingEvent();
    } catch (error) {
      setLoadingPersonal(false);
      displayErrorNotification();
    }
  };

  const updateOrganizationInfo = async (organizationInfo: IOrganizationInfo) => {
    setLoadingOrganization(true);
    const formData = new FormData();
    organizationInfo?.file && formData.append('file', organizationInfo.file, `img_organization-${uuid()}`);
    const verifyAddress = {
      numberOfEmployees: organizationInfo?.numberOfEmployees ? JSON.stringify(organizationInfo?.numberOfEmployees) : ''
    };

    organizationInfo?.numberOfEmployees && formData.append('body', JSON.stringify(verifyAddress));
    try {
      await updateOrganizationData(orgData.organizationId, formData);
      await getOrganization();
      displaySuccessNotification({ customJSXMessage: <>{t(`${i18nLanguageKey}changesSaved`)}</> });
      segment.sessionEndingEvent();
    } catch (error) {
      setLoadingOrganization(false);
      displayErrorNotification();
    }
  };

  const getDataServicesAddress = async () => {
    setLoadingAddress(true);
    try {
      const data = await servicesLocationAddress(orgData.organizationId);
      setAddressList(data);
      setLoadingAddress(false);
    } catch (error) {
      setLoadingAddress(false);
      displayErrorNotification();
    }
  };

  const openModalAddressDetail = (item: ILocationAddress) => {
    setModalAddress(item);
  };

  const changeStatusAddress = async (address: ILocationAddress, isDisabled?: boolean) => {
    if (address.id) {
      setModalLoading(true);
      try {
        await updateStatusAddress(address.id);
        setModalLoading(false);
        setModalAddress(undefined);
        displaySuccessNotification({
          customJSXMessage: (
            <>
              {isDisabled
                ? t(`${i18nLanguageKey}changeStatusAddress`)
                : t(`${i18nLanguageKey}changeStatusAddressDefault`)}
            </>
          )
        });
        getDataServicesAddress();
      } catch (error) {
        setModalLoading(false);
        setModalAddress(undefined);
        getDataServicesAddress();
        displayErrorNotification();
      }
    }
  };

  const getAvailableCountriesList = async () => {
    const availableCountries = await getCountriesList();
    setTotalAvailableCountriesList(availableCountries);
  };

  const validList = () => {
    if (addressList?.office) {
      const countryCodesAlreadyWithAddress = addressList.office.map(office => office?.country?.code);
      const validCountries = totalAvailableCountriesList?.filter(
        availableCountry => !countryCodesAlreadyWithAddress.includes(availableCountry.code)
      );
      setValidCountryList(validCountries);
    } else {
      setValidCountryList(totalAvailableCountriesList);
    }
  };

  const addNewCountry = async (newCountry: ILocationCountryAddress) => {
    setLoaddingNewAddress(true);
    try {
      await addNewAddressOffice(orgData.organizationId, newCountry);
      await getDataServicesAddress();
      setOpenModalNewAddress(false);
      setLoaddingNewAddress(false);
      setEditDefaultAddress(undefined);
      displaySuccessNotification({ customJSXMessage: <>{t(`${i18nLanguageKey}addNewCountry`)}</> });
    } catch (error) {
      setLoaddingNewAddress(false);
      setEditDefaultAddress(undefined);
      displayErrorNotification();
    }
  };

  const openEditAddress = (editAddres: ILocationAddress) => {
    setEditDefaultAddress(editAddres);
  };

  const getCountries = async () => {
    try {
      const countriesData = await getCountriesList('registry');
      setCountriesList(countriesData);
    } catch (error) {
      displayErrorNotification();
    }
  };

  const getEditAddressCountryData = (editDefaultAddress: ILocationAddress) => {
    if (!totalAvailableCountriesList) return;
    const countriesMatchingEditAddressCode = totalAvailableCountriesList?.filter(
      country => country?.code === editDefaultAddress?.country?.code
    );
    const newEditAddressCountryData =
      countriesMatchingEditAddressCode?.length >= 1 ? countriesMatchingEditAddressCode[0] : undefined;
    newEditAddressCountryData && setEditAddressCountryData(newEditAddressCountryData);
  };

  const defaultTabs = [
    {
      id: 0,
      title: 'Personal',
      component: loadingPersonal ? (
        <LoaderPersonal />
      ) : (
        <>
          {personalData && (
            <PersonalForm
              personalData={personalData}
              handleFileChange={handleFileChange}
              updatePersonalInfo={updatePersonalInfo}
              setIsPersonalEdit={setIsPersonalEdit}
              isPersonalEdit={isPersonalEdit}
              countriesList={countriesList}
            />
          )}
        </>
      )
    },
    {
      id: 1,
      title: t(`${i18nLanguageKey}defaultTabs:id1`),
      component: loadingOrganization ? (
        <LoaderOrganization />
      ) : (
        <>
          {organizationData && personalData && (
            <CompanyForm
              organizationData={organizationData}
              updateOrganizationInfo={updateOrganizationInfo}
              personalData={personalData}
              listOfCountries={totalAvailableCountriesList}
            />
          )}
        </>
      )
    },
    {
      id: 2,
      title: t(`${i18nLanguageKey}defaultTabs:id2`),
      component: (
        <OrganizaztionAddressesTab
          addressList={addressList}
          openModalAddressDetail={openModalAddressDetail}
          loadingAddress={loadingAddress}
          blockedAddButton={validCountryList && validCountryList?.length === 0}
          setOpenModalNewAddress={setOpenModalNewAddress}
          editAddress={openEditAddress}
        />
      )
    },
    {
      id: 3,
      title: t(`${i18nLanguageKey}defaultTabs:id3`),
      component: <OrganizationBillingData />
    }
  ];

  const handleTab = (tab: number) => {
    if (tab !== selectTab) setSelectTab(tab);
  };

  const generateTabs = () => {
    return defaultTabs.map(item => {
      return (
        <Tabs
          key={item.id}
          isSelect={item.id === selectTab}
          title={item.title}
          handleChange={() => handleTab(item.id)}
        />
      );
    });
  };

  useEffect(() => {
    validList();
  }, [addressList, totalAvailableCountriesList]);

  useEffect(() => {
    if (!totalAvailableCountriesList || !editDefaultAddress?.country) return;
    getEditAddressCountryData(editDefaultAddress);
  }, [editDefaultAddress]);

  useEffect(() => {
    getPersonal();
    getOrganization();
    getDataServicesAddress();
    getAvailableCountriesList();
    getCountries();
  }, []);

  return (
    <ContainerModules>
      {editDefaultAddress && editAddressCountryData && (
        <NudosGenericModal
          clickOutsideCallback={() => setEditDefaultAddress(undefined)}
          modalContent={
            <NudosModalContent
              customWidth="580px"
              customHeight="438px"
              toCloseModal={() => {
                setEditDefaultAddress(undefined);
              }}
              modalContentTitle={t(`${i18nLanguageKey}officeAddress`)}
              CustomModalComponent={
                <ModalEditAddress
                  loading={loadingNewAddres}
                  addNewCountry={e => addNewCountry(e)}
                  addressData={
                    editAddressCountryData?.code === editDefaultAddress?.country?.code ? editDefaultAddress : undefined
                  }
                  addressCountry={editAddressCountryData}
                />
              }
            />
          }
        />
      )}
      {openModalNewAddress && validCountryList && validCountryList?.length > 0 && (
        <NudosGenericModal
          clickOutsideCallback={() => setOpenModalNewAddress(false)}
          modalContent={
            <NudosModalContent
              customHeight="438px"
              customWidth="580px"
              modalContentTitle={t(`${i18nLanguageKey}officeAddress`)}
              toCloseModal={() => setOpenModalNewAddress(false)}
              CustomModalComponent={
                <ModalAddOfficeAddress
                  loadingNewAddres={loadingNewAddres}
                  validCountryList={validCountryList}
                  handleNewCountry={e => addNewCountry(e)}
                />
              }
            />
          }
        />
      )}
      {modalAddress && (
        <NudosGenericModal
          clickOutsideCallback={() => {
            setModalAddress(undefined);
          }}
          modalContent={
            <NudosModalContent
              customHeight="300px"
              customWidth="466px"
              modalContentTitle={t(`${i18nLanguageKey}otherAddresses`)}
              toCloseModal={() => setModalAddress(undefined)}
              CustomModalComponent={
                <ModalAddressDetail
                  modalCallBack={() => changeStatusAddress(modalAddress, modalAddress?.active)}
                  modalData={modalAddress}
                  modalExit={() => setModalAddress(undefined)}
                  modalLoading={modalLoading}
                />
              }
            />
          }
        />
      )}
      <div id="accountModule">
        <div className="fullContainer">
          <div className="containerTabs">{generateTabs()}</div>
          <div className="containerForms">{selectTab < defaultTabs.length && defaultTabs[selectTab]?.component}</div>
        </div>
      </div>
    </ContainerModules>
  );
};

export default Account;
