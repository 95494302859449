import { FC, useState } from 'react';
import XSign from '../../../../../assets/images/components/NudosComponents/XSign';
import { addressIssueIcon, addressEditedByOps } from '../../../../../assets/images/views/RequestLogistics';
import { NudosButton } from '../../../../../components/NudosComponents';
import { IUpdateConfirmEdition, IshipmentDetails } from '../../../../../types/orders';
import { updateConfirmEdition } from '../../../../../services/orders';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { Trans, useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { supportUrl } from '../../../../../utils/productDefinitions';
import './NoticeProblemsWithAddresses.scss';

interface INoticeProblemsWithAddresses {
  changeStatusBanner: () => void;
  problemsWithAddresses: boolean;
  addressesHaveBeenUpdated: boolean;
  editShipments?: IshipmentDetails;
  getOrderDatawithoutLoader: () => void;
}

const NoticeProblemsWithAddresses: FC<INoticeProblemsWithAddresses> = ({
  changeStatusBanner,
  problemsWithAddresses,
  addressesHaveBeenUpdated,
  editShipments,
  getOrderDatawithoutLoader
}: INoticeProblemsWithAddresses) => {
  const transtlations = 'nodi:orderDetails:orderDetailsSummary:noticeProblemsWithAddresses';
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const updateShipmentEdit = async () => {
    setLoading(true);
    try {
      const bodyUpdate: IUpdateConfirmEdition = {
        reportId: Number(editShipments?.editShipments?.id),
        shipmentId: Number(editShipments?.shipmentId),
        status: 'CLOSED',
        haveReport: false
      };
      await updateConfirmEdition(bodyUpdate);
      await getOrderDatawithoutLoader();
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const openSupportWindow = () => {
    window.open(supportUrl);
    segmentTrackEvent({
      nodiOrderInnerCardBannerSupportClick: {
        Country: editShipments?.countryName || '',
        OrderStatus: editShipments?.statusName || ''
      }
    });
  };

  return (
    <div
      id="noticeProblemsWithAddresses"
      style={{
        border: `${addressesHaveBeenUpdated ? '1px solid #54A1FC' : ''}`,
        height: `${addressesHaveBeenUpdated ? '102px' : ''}`
      }}
    >
      <div
        className="closeNotice"
        onClick={() => {
          changeStatusBanner();
        }}
      >
        <XSign fill="#999999" />
      </div>
      <div className="noticeInfo">
        <div className="noticeTitle">
          {t(`${transtlations}:${problemsWithAddresses ? 'titleProblemAddress' : 'titleUpdateAddress'}`)}
        </div>
        <div className="noticeSubtitle">
          <Trans
            i18nKey={`${transtlations}:${
              problemsWithAddresses ? 'descriptionProblemAddress' : 'descriptionUpdateAddress'
            }`}
            components={{ 1: <br /> }}
          />
        </div>
        <div
          className="finalText"
          onClick={() => {
            openSupportWindow();
          }}
        >
          {t(`${transtlations}:contact`)}{' '}
          <span className="text-nudos-blue font-bold underline cursor-pointer">
            {t(`${transtlations}:blueContact`)}
          </span>
        </div>
        {addressesHaveBeenUpdated && (
          <div className="noticeButtonContainer">
            <NudosButton
              customFontStyles={{ fontSize: '10px' }}
              buttonText={t(`${transtlations}:minButton`)}
              customHeight="18px"
              customWidth="80px"
              isButtonLoading={loading}
              handleClick={() => {
                updateShipmentEdit();
              }}
            />
          </div>
        )}
      </div>
      <div className="noticeImgContainer">
        <div
          className="boxImg"
          style={{ backgroundImage: `url("${problemsWithAddresses ? addressIssueIcon : addressEditedByOps}")` }}
        ></div>
      </div>
    </div>
  );
};

export default NoticeProblemsWithAddresses;
