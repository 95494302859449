import { IorderDetails } from '../../../../../types/orders';
import { convertDateToLocaleString } from '../../../../../utils/formatDatesAndHours';
import OrderStatusDisplay from '../OrderStatusDisplay/OrderStatusDisplay';
import { useTranslation } from 'react-i18next';
import { getEstimatedDeliveredDate } from '../../../../../services/catalogue.services';
import useStoreCountryState from '../../../../../state/useStoreCountryState';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { useEffect, useState } from 'react';
import './OrderDetailsHeader.scss';

/**
 * @property {IorderDetails} orderData - Required, the information of the order with the specified orderId. This component required the properties orderId, shipmentInfo, createdAt, globalStatus of the orderData to correctly display its information.
 */
const OrderDetailsHeader = ({ orderData }: { orderData: IorderDetails }) => {
  const { t } = useTranslation();
  const { storeCountry } = useStoreCountryState();
  const { orderId, shipmentsCount, createdAt, globalStatus } = orderData;
  const [rangeDays, setRangeDays] = useState<string>();
  const i18nLanguageKey = 'nodi:orderDetails:orderDetailsHeader:';
  const orderText = t(`${i18nLanguageKey}orderText`);
  const estimatedDeliveryText = t(`${i18nLanguageKey}estimatedDelivery`);
  const businessDaysText = t(`${i18nLanguageKey}businessDays`);
  const beginOnceTheOrderIsConfirmedText = t(`${i18nLanguageKey}beginOnceTheOrderIsConfirmed`);
  const orderIdText = `${orderText} Nº ${orderId || '---'}`;
  const shipmentsCountText = `${shipmentsCount || '---'} ${
    shipmentsCount === 1 ? t(`${i18nLanguageKey}shipmentsCount`) : t(`${i18nLanguageKey}shipmentsCountDefault`)
  }`;
  const creationDateText = `${t(`${i18nLanguageKey}creationDateText`)} ${
    createdAt ? convertDateToLocaleString(createdAt)?.textWithTimezone : '---'
  }`;
  const statusText = globalStatus?.split(' ')[0] || '';
  const statusNametranslationKey = orderData?.globalStatusStringId || 'Pendiente de confirmación';
  const orderInTransit = orderData?.globalStatus !== 'Entregada' && orderData?.globalStatus !== 'Cancelada';

  const estimatedDays = async () => {
    if (storeCountry && orderInTransit) {
      try {
        const dataDays = await getEstimatedDeliveredDate(storeCountry?.countryCode);
        setRangeDays(`${dataDays.minDeliverTime}-${dataDays.maxDeliverTime}`);
      } catch (error) {
        displayErrorNotification();
      }
    }
  };

  useEffect(() => {
    estimatedDays();
  }, []);

  return (
    <div className="orderDetailsHeader">
      <div className="orderId">{orderIdText}</div>
      <div className="shipmentsCount">{shipmentsCountText}</div>
      <div className="creationDate">{creationDateText}</div>
      {rangeDays && orderInTransit && (
        <div className="estimateDays">
          <div>
            <div className="estimatedText">
              <div className="grayEstimatedText">{estimatedDeliveryText}:</div>📆&nbsp;&nbsp;&nbsp;{rangeDays}{' '}
              {businessDaysText}
            </div>
            <div className="subEstimatedText">{beginOnceTheOrderIsConfirmedText}</div>
          </div>
        </div>
      )}
      <OrderStatusDisplay statusName={statusText} translationKey={statusNametranslationKey} />
    </div>
  );
};

const OrderDetailsHeaderSkeletonLoader = () => {
  return (
    <div className="orderDetailsHeaderSkeletonLoader">
      <div className="orderIdSkeleton animationLoader" />
      <div className="shipmentsCountSkeleton animationLoader" />
      <div className="creationDateSkeleton animationLoader" />
      <div className="orderStatusDisplaySkeleton animationLoader" />
    </div>
  );
};

export { OrderDetailsHeader, OrderDetailsHeaderSkeletonLoader };
