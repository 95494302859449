import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={10} height={11} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M5.8125 2.125C4.49627 3.20341 3.83859 3.86098 2.8125 5.125C3.83859 6.38902 4.49627 7.04659 5.8125 8.125"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SmallReturnButton = memo(ForwardRef);
export default SmallReturnButton;
