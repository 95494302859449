import { useState } from 'react';
import * as yup from 'yup';
import { ICountryDetail } from '../../../../../../types/countries';
import { IformInputs } from '../../../../../../types/SignUp';
import { useTranslation } from 'react-i18next';

const useSignUpFormStep1Controller = (countriesRawOptions: ICountryDetail[]) => {
  const { t } = useTranslation();
  const [selectedNumberOfEmployees, setSelectedNumberOfEmployees] = useState<string>();
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const i18nLanguageKey = 'authentication:SignUpForm:useSignUpFormStep1Controller:';

  const numberOfEmployees = ['0-10', '11-50', '51-100', '101-200', '+ 200'].map(option => {
    return (
      <div key={`option-${option}`} className="optionContainer" onClick={() => setSelectedNumberOfEmployees(option)}>
        {option}
      </div>
    );
  });

  const countryOptions = () =>
    countriesRawOptions.map(country => {
      return (
        <div
          key={`option-${country?.name}`}
          className="optionContainer countryOptionContainer"
          onClick={() => setSelectedCountry(country)}
        >
          <div className="flagContainer" style={{ backgroundImage: `url(${country.flag})` }} />
          <div className="countryName">{country?.name?.slice(0, 3)}</div>
        </div>
      );
    });

  const formInputsStep1: IformInputs[] = [
    {
      id: 'organizationName',
      placeholder: 'Nudos',
      label: t(`${i18nLanguageKey}organizationName`),
      type: 'text'
    },
    {
      id: 'organizationBusinessName',
      placeholder: 'Somos nudos',
      label: t(`${i18nLanguageKey}organizationBusinessName`),
      type: 'text'
    },
    {
      id: 'country',
      placeholder: t(`${i18nLanguageKey}country`),
      label: `${t(`${i18nLanguageKey}country`)}*`,
      type: 'select',
      options: countryOptions()
    },
    {
      id: 'rfc',
      placeholder: t(`${i18nLanguageKey}rfc`),
      label: t(`${i18nLanguageKey}rfcLabel`),
      type: 'text'
    },
    {
      id: 'numberOfEmployees',
      placeholder: '0-10',
      label: t(`${i18nLanguageKey}numberOfEmployees`),
      type: 'select',
      options: numberOfEmployees
    }
  ];

  const formSchemaStep1 = yup.object().shape({
    organizationName: yup
      .string()
      .required()
      .min(2, 'El nombre de la empresa debe incluir al menos 2 caracteres')
      .max(100, 'El nombre de la empresa no debe exceder los 100 caracteres'),
    organizationBusinessName: yup
      .string()
      .required()
      .min(2, 'La razón social debe incluir al menos 2 caracteres')
      .max(100, 'La razón social no debe exceder los 100 caracteres'),
    rfc: yup.string().required(),
    numberOfEmployees: yup.string().required()
  });

  return {
    formInputsStep1,
    formSchemaStep1,
    selectedNumberOfEmployees,
    setSelectedNumberOfEmployees,
    selectedCountry,
    setSelectedCountry
  };
};

export default useSignUpFormStep1Controller;
