import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_8559_202084)">
      <path
        d="M8.00028 15.1428C11.9452 15.1428 15.1431 11.9449 15.1431 7.99997C15.1431 4.05508 11.9452 0.857117 8.00028 0.857117C4.05539 0.857117 0.857422 4.05508 0.857422 7.99997C0.857422 11.9449 4.05539 15.1428 8.00028 15.1428Z"
        fill="#FF0000"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.5 10.5L5.5 5.5" stroke="white" strokeLinecap="round" />
      <path d="M10.5 5.49993L5.5 10.4999" stroke="white" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_8559_202084">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const ErrorIcon = memo(ForwardRef);
export default ErrorIcon;
