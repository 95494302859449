import { useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import useOtpController from '../../components/Otp/otp.controller';
import useSignStore from '../signUp/state';
import { FieldError } from 'react-hook-form';
import { displayErrorNotification } from '../../utils/displayNudosStandardNotifications';
import { verifyPhoneAndEmail } from '../../services/register';
import { emailAndPhoneValidationResponseTexts } from '../../utils/backendConstants';

const useSignInController = () => {
  const { push } = useHistory();
  const { loginEmailAndPassWord } = useOtpController();
  const { setEmail } = useSignStore();
  const [numErrors, setNumErrors] = useState<number>(0);
  const [error, setError] = useState<string>('');

  const verifyEmail = async (
    email: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setLoginStep: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (numErrors >= maxErrors) return;
    const validateParams = {
      email,
      phone: '-validationNotRequired-'
    };
    setEmail(validateParams.email || '');
    setLoading(true);
    try {
      const response = await verifyPhoneAndEmail(validateParams);
      const emailExists = [
        emailAndPhoneValidationResponseTexts.emailExists,
        emailAndPhoneValidationResponseTexts.phoneAndEmailExists
      ].includes(response);
      if (!emailExists) {
        push(`/not-registered?email=${email}`);
      } else {
        setLoginStep(1);
      }
    } catch (err) {
      displayErrorNotification();
    } finally {
      setLoading(false);
      setError('');
    }
  };

  const onSubmit = async (
    data: FormValues,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setPasswordError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    if (numErrors >= maxErrors) return;
    setLoading(true);
    try {
      await loginEmailAndPassWord(data.email, data.password, setPasswordError);
    } catch (err) {
      displayErrorNotification();
    } finally {
      setLoading(false);
      setError('');
    }
  };

  const onError = (data: { email?: FieldError }) => {
    setNumErrors(numErrors + 1);
    const values = Object.values(data).map(value => value.message);
    setError(`${values[0]}`);
  };

  const resolverSchema = yup.object().shape({
    email: yup
      .string()
      .required('Es necesario llenar correo del contacto')
      .matches(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
        'El correo no es válido'
      )
      .min(9, 'El correo de contacto debe tener al menos 9 dígitos'),
    password: yup.string().required()
  });

  const maxErrors = 10;

  return {
    resolverSchema,
    maxErrors,
    numErrors,
    verifyEmail,
    onSubmit,
    onError,
    error
  };
};

type FormValues = { countryCode: string; email: string; password: string };

export default useSignInController;
export type { FormValues };
