import { FC, useState } from 'react';
import { NudosButton, NudosSimpleSelect, NudosTextInput } from '../../../../../components/NudosComponents';
import { IDTOCreateToolPetition } from '../../../../../types/uploadExternalTools.types';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import { Trans, useTranslation } from 'react-i18next';
import './toolsModal.scss';
interface ISelectTools {
  value: number;
  name: string;
}
interface IFormdata {
  toolName?: string;
  toolBrand?: string;
  toolSku?: string;
  toolCategory?: string;
  storage?: string;
  ram?: string;
  processor?: string;
  screen?: string;
  aditionalReference?: string;
  frequency?: string;
  resolution?: string;
  hd?: string;
  toolDescription?: string;
}

interface IToolsModal {
  loading: boolean;
  createTool: (data: IDTOCreateToolPetition) => void;
}
const ToolsModal: FC<IToolsModal> = ({ loading, createTool }: IToolsModal) => {
  const { t } = useTranslation();
  const { orgData, personalData } = useEcommerceControler();
  const [selectItem, setSelectItem] = useState<ISelectTools>();
  const [generateForm, setGenerateForm] = useState<IFormdata>();
  const [startModal, setStartModal] = useState<boolean>(false);
  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:ToolsModal:';
  const toolsList = [
    { value: 1, name: 'Laptops' },
    { value: 2, name: t(`${i18nLanguageKey}toolsList:value2`) },
    { value: 3, name: 'PCs' },
    { value: 4, name: t(`${i18nLanguageKey}toolsList:value4`) }
  ];

  const generatePills = (value?: number) => {
    if (value) {
      if (value === 1) {
        return [
          {
            Almacenamiento: generateForm?.storage || ''
          },
          { Memoria: generateForm?.ram || '' },
          { Procesador: generateForm?.processor || '' },
          { Pantalla: generateForm?.screen || '' },
          { 'Referencia Adicional': generateForm?.aditionalReference || '' }
        ];
      }
      if (value === 2) {
        return [
          {
            Frecuencia: generateForm?.frequency || ''
          },
          { Pantalla: generateForm?.screen || '' },
          { Resolucion: generateForm?.resolution || '' },
          { HD: generateForm?.hd || '' },
          { 'Referencia Adicional': generateForm?.aditionalReference || '' }
        ];
      }
      if (value === 3) {
        return [
          {
            Almacenamiento: generateForm?.storage || ''
          },
          { Memoria: generateForm?.ram || '' },
          { Procesador: generateForm?.processor || '' },
          { 'Referencia Adicional': generateForm?.aditionalReference || '' }
        ];
      }
      if (value === 4) {
        return [
          { 'Tipo de Producto': generateForm?.toolDescription || '' },
          { 'Referencia Adicional': generateForm?.aditionalReference || '' }
        ];
      }
      return '';
    }
    return '';
  };

  const sendForm = () => {
    const body = {
      organizationName: orgData?.organizationName || '',
      firstName: personalData?.firstName || '',
      lastName: personalData?.lastName || '',
      email: personalData?.email || '',
      phone: personalData?.phone || '',
      toolCategory: generateForm?.toolCategory || '',
      toolSku: generateForm?.toolSku || '',
      toolBrand: generateForm?.toolBrand,
      toolName: generateForm?.toolName,
      additionalReference: generateForm?.aditionalReference,
      toolDescription: generateForm?.toolDescription || null,
      toolSpecs: generatePills(selectItem?.value)
    };
    createTool(body);
  };

  const isEnabledButton = () => {
    if (selectItem?.value === 1) {
      if (
        generateForm?.toolName &&
        generateForm?.toolBrand &&
        generateForm?.toolCategory &&
        generateForm?.storage &&
        generateForm?.ram &&
        generateForm?.processor &&
        generateForm?.screen
      ) {
        return true;
      }
      return false;
    } else if (selectItem?.value === 2) {
      if (
        generateForm?.toolCategory &&
        generateForm?.toolBrand &&
        generateForm?.toolName &&
        generateForm?.frequency &&
        generateForm?.screen &&
        generateForm?.resolution &&
        generateForm?.hd
      ) {
        return true;
      }
      return false;
    } else if (selectItem?.value === 3) {
      if (
        generateForm?.toolCategory &&
        generateForm?.toolBrand &&
        generateForm?.toolName &&
        generateForm?.storage &&
        generateForm?.ram &&
        generateForm?.processor
      ) {
        return true;
      }
      return false;
    } else if (selectItem?.value === 4) {
      if (
        generateForm?.toolCategory &&
        generateForm?.toolDescription &&
        generateForm?.toolBrand &&
        generateForm.toolName
      ) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  return (
    <div id="toolsModal">
      <div className="boxToolsModal">
        {startModal && !selectItem && (
          <div className="superiorTitle">{t(`${i18nLanguageKey}boxToolsModal:superiorTitle1`)}</div>
        )}
        {!!selectItem && <div className="superiorTitle">{t(`${i18nLanguageKey}boxToolsModal:superiorTitle2`)}</div>}
        {!startModal && (
          <div className="containerWelcomeModal">
            <div className="blueText">{t(`${i18nLanguageKey}boxToolsModal:blueText`)}</div>
            <div className="listModal">
              <Trans i18nKey={t(`${i18nLanguageKey}boxToolsModal:listModal`)} components={{ 1: <br /> }} />
            </div>
          </div>
        )}
        {startModal && (
          <div className="containerSelect">
            <div className="titleSelectModal" style={{ color: selectItem ? '#383838' : '' }}>
              {t(`${i18nLanguageKey}boxToolsModal:titleSelectModal`)}
            </div>
            <NudosSimpleSelect
              changeValue={e => {
                setSelectItem(e);
                setGenerateForm({ ...generateForm, ...{ toolCategory: e.name } });
              }}
              selectList={toolsList}
              isGray={!selectItem}
              enabled={true}
              customHeight={38}
              myPlaceHolder="Laptops"
              defaultValue={selectItem}
            />
          </div>
        )}
        {selectItem && (
          <div className="formToolsModal">
            <div className="firstLine">
              <div className="smallInputSize mr-5">
                <NudosTextInput
                  handleChange={e => {
                    setGenerateForm({ ...generateForm, ...{ toolSku: e } });
                  }}
                  isFilled={false}
                  label={t(`${i18nLanguageKey}formToolsModal:sku`)}
                  placeholder="ABC123D4"
                />
              </div>
              {selectItem?.value === 4 && (
                <div className="mediumBigItem mr-5">
                  <NudosTextInput
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ toolDescription: e } });
                    }}
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:productQuestion:label`)}
                    placeholder={t(`${i18nLanguageKey}formToolsModal:productQuestion:placeholder`)}
                  />
                </div>
              )}
              <div className="mediumInputSize mr-5">
                <NudosTextInput
                  label={t(`${i18nLanguageKey}formToolsModal:brand`)}
                  placeholder="Apple"
                  isFilled={false}
                  handleChange={e => {
                    setGenerateForm({ ...generateForm, ...{ toolBrand: e } });
                  }}
                />
              </div>
              {selectItem?.value !== 4 && (
                <div className="bigInputSize">
                  <NudosTextInput
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ toolName: e } });
                    }}
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:nameOfProduct:label`)}
                    placeholder="Macbook Pro"
                  />
                </div>
              )}
            </div>
            <div className="secondLine">
              {selectItem?.value === 4 && (
                <div className="bigInputSize mr-5">
                  <NudosTextInput
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:nameOfProduct:label`)}
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ toolName: e } });
                    }}
                    placeholder={t(`${i18nLanguageKey}formToolsModal:nameOfProduct:placeholder`)}
                  />
                </div>
              )}
              {(selectItem?.value === 1 || selectItem?.value === 3) && (
                <div className="smallInputSize mr-5">
                  <NudosTextInput
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:storage`)}
                    placeholder="250 GB"
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ storage: e } });
                    }}
                  />
                </div>
              )}
              {(selectItem?.value === 1 || selectItem?.value === 3) && (
                <div className="smallInputSize mr-5">
                  <NudosTextInput
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:memory`)}
                    placeholder="16 GB"
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ ram: e } });
                    }}
                  />
                </div>
              )}
              {(selectItem?.value === 1 || selectItem?.value === 3) && (
                <div className="smallInputSize mr-5">
                  <NudosTextInput
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:processor`)}
                    placeholder="M2"
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ processor: e } });
                    }}
                  />
                </div>
              )}
              {selectItem?.value === 2 && (
                <div className="smallInputSize mr-5">
                  <NudosTextInput
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:frequency`)}
                    placeholder="60 Hz"
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ frequency: e } });
                    }}
                  />
                </div>
              )}
              {(selectItem?.value === 1 || selectItem?.value === 2) && (
                <div className="smallInputSize mr-5">
                  <NudosTextInput
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:screenSize`)}
                    placeholder="16’’"
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ screen: e } });
                    }}
                  />
                </div>
              )}
              {selectItem?.value === 2 && (
                <div className="smallInputSize mr-5">
                  <NudosTextInput
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:resolution`)}
                    placeholder="1920x1080"
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ resolution: e } });
                    }}
                  />
                </div>
              )}
              {selectItem?.value === 2 && (
                <div className="smallInputSize mr-5">
                  <NudosTextInput
                    isFilled={false}
                    label={t(`${i18nLanguageKey}formToolsModal:typeOfHd`)}
                    placeholder="4K"
                    handleChange={e => {
                      setGenerateForm({ ...generateForm, ...{ hd: e } });
                    }}
                  />
                </div>
              )}
              <div className="mediumBigItem">
                <NudosTextInput
                  isFilled={false}
                  label={t(`${i18nLanguageKey}formToolsModal:additionalReference:label`)}
                  placeholder={t(`${i18nLanguageKey}formToolsModal:additionalReference:placeholder`)}
                  handleChange={e => {
                    setGenerateForm({ ...generateForm, ...{ aditionalReference: e } });
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="containerFloatButton">
          <NudosButton
            isButtonDisabled={!startModal ? false : !isEnabledButton() || loading}
            isButtonLoading={loading}
            buttonText={
              !startModal
                ? t(`${i18nLanguageKey}containerFloatButton:start`)
                : t(`${i18nLanguageKey}containerFloatButton:save`)
            }
            handleClick={() => {
              if (!startModal) {
                setStartModal(true);
              } else {
                sendForm();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ToolsModal;
