import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import CustomBar from './CustomBar';
import './equipamentChart.scss';

interface IMyChartData {
  name: string;
  totalPositions: number;
  equippedNumber: number;
  equippedPercent: number;
}

interface IChartData {
  chartData: IMyChartData[];
}

const EquipamentChart: FC<IChartData> = ({ chartData }: IChartData) => {
  const myPositions = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0];
  return (
    <div id="equipamentChart">
      <div className="porcentContainer">
        <div className="up">100%</div>
        <div className="down">0%</div>
      </div>
      <div className="lineContainer">
        <div className="grayLines">
          <div className="boxGrayLines">
            {myPositions &&
              myPositions.map(item => {
                return <div className="myGrayLine" key={`positionLine-${item}`} style={{ bottom: item }} />;
              })}
          </div>
        </div>
        <div className="barsContainer">
          <div className="barBoxContainer">
            {chartData &&
              chartData.length > 0 &&
              chartData.map(item => {
                return (
                  <CustomBar
                    key={uuid()}
                    equippedPercent={item.equippedPercent}
                    name={item.name}
                    equippedNumber={item.equippedNumber}
                    totalPositions={item.totalPositions}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipamentChart;
