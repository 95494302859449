import { useEffect, useState } from 'react';
import ContainerModules from '../../../components/containerModules';
import { getOrderList, getOrdersFilters } from '../../../services/orders';
import useStateOrders from '../../../state/useStateOrders';
import { IorderInListing } from '../../../types/orders';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import useEcommerceControler from '../../ecommerce/ecommerce.controller';
import { LoaderCard, NewOrderCard } from './components';
import OrdersFilters from './components/OrdersFilters/OrdersFilters';
import { ImoduleFilter } from '../../../types/global';
import { ZeroStateView, UnsuccessfulFilteredResultsView } from '../../../components';
import './orderList.scss';

const OrderList = () => {
  const { setOrderNumbers, ordersFilters, setOrdersFilters } = useStateOrders();
  const [ordersData, setOrdersData] = useState<IorderInListing[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const { orgData } = useEcommerceControler();
  const getDataOrders = async (filters?: ImoduleFilter[]) => {
    setLoading(true);
    try {
      const filtersParam = filters && filters.length > 0 && JSON.stringify(filters);
      const filterQuery = filtersParam ? `?filters=${filtersParam}` : '';
      const myOrderData = await getOrderList(orgData.organizationId, filterQuery);
      if (myOrderData) {
        setOrderNumbers(myOrderData.length);
        setOrdersData(myOrderData);
      }
      setLoading(false);
    } catch (error) {
      displayErrorNotification();
      setLoading(false);
    }
  };

  const ordersFound = ordersData && ordersData.length > 0;
  const filtersApplied = ordersFilters && ordersFilters.length > 0;

  useEffect(() => {
    getDataOrders(ordersFilters);
  }, [ordersFilters]);

  return (
    <ContainerModules>
      <div id="orderList">
        <OrdersFilters
          ordersFilters={ordersFilters || []}
          setOrdersFilters={setOrdersFilters}
          getOrdersFiltersRequestFunction={getOrdersFilters}
        />
        <div className="containerOrderList">
          <div className="containerOrderCards">
            {loading && <LoaderCard />}
            {!loading &&
              ordersFound &&
              ordersData.map((item, index) => {
                return <NewOrderCard dataOrderCard={item} key={`key-order-card-${item.orderId}-index-${index}`} />;
              })}
            {!loading && !ordersFound && filtersApplied && <UnsuccessfulFilteredResultsView paddingTop={150} />}
            {!loading && !ordersFound && !filtersApplied && (
              <ZeroStateView
                greyText={'Aún no tienes órdenes,'}
                orangeText={'crea la primera'}
                redirectionPath={'/catalogue/home'}
                paddingTop={150}
              />
            )}
          </div>
        </div>
      </div>
    </ContainerModules>
  );
};
export default OrderList;
