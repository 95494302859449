import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6451_3495)">
      <path
        d="M4.99492 1.69405C5.3816 0.758011 6.6775 0.727666 7.06606 1.66293C7.3351 2.3105 7.54039 2.97734 7.74715 3.84864C8.66085 3.83932 9.3751 3.85908 10.096 3.92386C11.1094 4.01494 11.5185 5.24732 10.7295 5.8899C10.2835 6.25311 9.80462 6.58905 9.20436 6.96579C9.03542 7.07183 8.95939 7.2793 9.02067 7.46914C9.30079 8.33775 9.48996 9.04278 9.63713 9.7825C9.83299 10.7662 8.7967 11.5167 7.95448 10.972C7.30873 10.5544 6.72214 10.0738 6.0002 9.38804C5.28479 10.0467 4.70108 10.514 4.06275 10.9289C3.21566 11.4797 2.15709 10.7373 2.35376 9.74616C2.4959 9.02984 2.68542 8.33308 2.97384 7.47321C3.0381 7.28163 2.96207 7.07052 2.79062 6.96357C2.17212 6.57777 1.68092 6.2341 1.22006 5.85535C0.443777 5.21737 0.840079 4.00134 1.84063 3.90889C2.57289 3.84122 3.30406 3.82687 4.25325 3.84864C4.50318 2.9985 4.72756 2.34122 4.99492 1.69405Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6451_3495">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuPerksIcon = memo(ForwardRef);
export default MenuPerksIcon;
