import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg className="lightbulb" width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <rect x="0.5" y="0.5" width="13" height="13" rx="3.5" fill="#383838" stroke="#383838" />
    <g clipPath="url(#clip0_1775_93358)">
      <path
        d="M3.42871 7.89286L6.02611 10.5714C7.24835 7.06045 8.26625 5.5198 10.5716 3.42857"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1775_93358">
        <rect width="10" height="10" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const BlackCheckIcon = memo(ForwardRef);
export default BlackCheckIcon;
