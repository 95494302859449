import { IconInformation } from '../../../assets/DesignSystem/SVGComponents';
import { DownloadIcon } from '../../../assets/images/components/NudosComponents';
import './NudosLinkTypeButton.scss';

/**
 * @property { string } text - The text to show in the link like button
 * @property { () => void } handleClickCallback - The calback to execute upon clicking the button
 * @property { string } customClassname - A classname for custom styles
 * @property { JSX.Element } customIcon - An icon to use instead of the default "information" icon
 * @property { "download" } specialIcon - One of the special icons, that can be included by just passing the string that identifies it. Accepted values: 'download'
 * @property { boolean  } hideIcon - A boolean indicating if the icon should be hide
 * @property { JSX.Element } rightIcon - An icon to use on the right side of the text
 * @property { boolean  } isDisabled - A boolean indicating if the button is disabled
 * @property { JSX.Element } explainingHover - A jsx element to display when the button is hovered, as to be used in the explaination of its action or state
 */
const NudosLinkTypeButton = ({
  text,
  handleClickCallback,
  customClassname,
  specialIcon,
  customIcon,
  hideIcon,
  rightIcon,
  isDisabled,
  explainingHover
}: {
  text: string;
  handleClickCallback: () => void;
  customClassname?: string;
  specialIcon?: 'download';
  customIcon?: JSX.Element;
  hideIcon?: boolean;
  rightIcon?: JSX.Element;
  isDisabled?: boolean;
  explainingHover?: JSX.Element;
}) => {
  const disabledStyles = isDisabled ? 'disabled' : '';
  const onClickCallback = isDisabled ? undefined : handleClickCallback;

  const getIcon = () => {
    switch (specialIcon) {
      case 'download':
        return <DownloadIcon className="downloadIcon" />;
      default:
        return <IconInformation />;
    }
  };

  const icon = getIcon();

  return (
    <div className={`nudosLinkTypeButton ${customClassname || ''} ${disabledStyles}`} onClick={onClickCallback}>
      {!hideIcon && <div className="icon">{customIcon || icon}</div>}
      <div className="text">{text}</div>
      {rightIcon && <div className="icon">{rightIcon}</div>}
      {explainingHover && <div className="hoverElementContainer">{explainingHover}</div>}
    </div>
  );
};

export default NudosLinkTypeButton;
