import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="m7.265 11.378 7.334-7.12a.925.925 0 0 1 1.28 0l.856.83a.861.861 0 0 1 .001 1.242L10.924 12l5.812 5.67c.352.343.352.899-.002 1.242l-.855.83a.925.925 0 0 1-1.28 0l-7.334-7.12a.862.862 0 0 1 0-1.244Z"
      fill={props.fill || '#2C7152'}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const ReturnButton = memo(ForwardRef);
export default ReturnButton;
