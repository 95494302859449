import {
  IconBuilding,
  IconEmployee,
  IconReserveBox,
  IconShield,
  IconToolWrench
} from '../../../../../assets/DesignSystem/SVGComponents';
import { Loading } from '../../../../../components';
import NudosSearchbarDropdown from '../../../../../components/DesignSystem/NudosSearchbarDropdown/NudosSearchbarDropdown';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import './UnassignmentSelectToolDestinationDropdown.scss';
import { useTranslation } from 'react-i18next';

/**
 * @property { (option: TstoragePlace) => void } optionSavingCallback - The callback function that updates the selected destination in the parent component
 * @property { TstoragePlace } selectedDestination - The current value for the selected destination
 */
const UnassignmentSelectToolDestinationDropdown = ({
  optionSavingCallback,
  selectedDestination,
  canOfferDiagnosticsAndWarrantyManagement = true,
  withoutLogistics = false,
  loadingWarehousesList = false,
  warrantyIsPossibleForSelectedTools
}: {
  optionSavingCallback: (option: TstoragePlace) => void;
  selectedDestination?: TstoragePlace;
  canOfferDiagnosticsAndWarrantyManagement?: boolean;
  withoutLogistics?: boolean;
  loadingWarehousesList?: boolean;
  warrantyIsPossibleForSelectedTools?: boolean;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'services:unassignmentModule';
  const destinationsRawOptions: ItoolDestinationOption[] = [
    {
      value: 'user',
      icon: <IconEmployee className="IconEmployee" />,
      title: t(`${i18nLanguageKey}:destinationOptions:user:title`),
      description: t(`${i18nLanguageKey}:destinationOptions:user:description`)
    },
    {
      value: 'office',
      icon: <IconBuilding className="IconBuilding" />,
      title: t(`${i18nLanguageKey}:destinationOptions:office:title`),
      description: t(`${i18nLanguageKey}:destinationOptions:office:description`)
    },
    {
      value: 'nudos',
      icon: <IconReserveBox className="IconReserveBox" />,
      title: t(`${i18nLanguageKey}:destinationOptions:nudos:title`),
      description: t(`${i18nLanguageKey}:destinationOptions:nudos:description`)
    },
    {
      value: 'warranty',
      icon: <IconShield className="IconShield" />,
      title: t(`${i18nLanguageKey}:destinationOptions:warranty:title`),
      description: t(`${i18nLanguageKey}:destinationOptions:warranty:description`)
    },
    {
      value: 'diagnostic',
      icon: <IconToolWrench className="IconToolWrench" />,
      title: t(`${i18nLanguageKey}:destinationOptions:diagnostic:title`),
      description: t(`${i18nLanguageKey}:destinationOptions:diagnostic:description`)
    }
  ];

  const getDestinationsOptionsByOriginCountry = () => {
    if (withoutLogistics)
      return destinationsRawOptions.filter(option => !['diagnostic', 'warranty', 'nudos'].includes(option?.value));
    if (!canOfferDiagnosticsAndWarrantyManagement)
      return destinationsRawOptions.filter(option => !['diagnostic', 'warranty'].includes(option?.value));
    return destinationsRawOptions;
  };

  const currentDestinationOption = destinationsRawOptions.find(option => option.value === selectedDestination);
  const destinationsRequiringSpecifics = ['user', 'office', 'nudos'];
  const componentSize = destinationsRequiringSpecifics.includes(selectedDestination || '') ? 'medium' : 'large';

  const warrantyIsForbiddenHoverText = (
    <div className="warrantyIsForbiddenHoverText">{t(`${i18nLanguageKey}:warrantyIsForbiddenHoverText`)}</div>
  );

  const formDestinationDropdownUIOptions = (
    rawOptions: ItoolDestinationOption[],
    clickOptionCallback?: (option: ItoolDestinationOption) => void
  ) => {
    if (loadingWarehousesList)
      return [
        <div className="optionContainer" key="loading">
          <Loading />
        </div>
      ];
    return rawOptions.map((option, i) => {
      const warrantyOptionIsForbidden = !warrantyIsPossibleForSelectedTools && option?.value === 'warranty';
      const forbiddenOptionStyles = warrantyOptionIsForbidden ? 'forbiddenOption' : '';
      const handleSelectThisOption = () => {
        if (warrantyOptionIsForbidden) return;
        optionSavingCallback(option?.value);
        clickOptionCallback && clickOptionCallback(option);
      };
      return (
        <div
          className={`optionContainer selectDestinationOption ${forbiddenOptionStyles}`}
          key={`option${option}${i}`}
          onClick={handleSelectThisOption}
        >
          {warrantyOptionIsForbidden && warrantyIsForbiddenHoverText}
          <div className="iconContainer">{option.icon}</div>
          <div className="titleAndDescription">
            <div className="optionTitle">{option?.title}</div>
            <div className="optionDescription">{option?.description}</div>
          </div>
        </div>
      );
    });
  };

  const formSelectedOption = (rawSelectedOption: ItoolDestinationOption) => {
    return <div className="selectedOption">{rawSelectedOption?.title}</div>;
  };

  const filterBySearchStringCallback = (search: string, rawOptions: ItoolDestinationOption[]) => {
    return rawOptions.filter(taxData => taxData?.title?.toLowerCase().trim().includes(search.toLowerCase().trim()));
  };

  return (
    <div className="UnassignmentSelectToolDestinationDropdown">
      <NudosSearchbarDropdown
        componentSize={componentSize}
        customPlaceholder={t(`${i18nLanguageKey}:destinationOptions:customPlaceholder`)}
        rawOptions={getDestinationsOptionsByOriginCountry()}
        formDropdownOptionsCallback={formDestinationDropdownUIOptions}
        formSelectedOptionUICallback={formSelectedOption}
        filterBySearchStringCallback={filterBySearchStringCallback}
        isFilled={!!selectedDestination}
        currentValue={currentDestinationOption}
        includeMagnifierIcon={false}
      />
    </div>
  );
};

export default UnassignmentSelectToolDestinationDropdown;

interface ItoolDestinationOption {
  value: TstoragePlace;
  icon: JSX.Element;
  title: string;
  description: string;
}
