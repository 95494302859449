import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill={props.fill} xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <rect x="0.5" y="0.5" width="13" height="13" rx="3.5" fill="white" stroke={props?.stroke || '#CCCCCC'} />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const NoCheck = memo(ForwardRef);
export default NoCheck;
