import { useState } from 'react';
import { EmployeeDestinationModal } from '../../../../../../../components/DestinationModals';
import { NudosGenericModal, NudosModalContent } from '../../../../../../../components/NudosComponents';
import { updateEmployeeData } from '../../../../../../../services/employees.service';
import { DTOupdateEmployeeData } from '../../../../../../../types/DTO';
import { Iemployee } from '../../../../../../../types/global';
import { displayErrorNotification } from '../../../../../../../utils/displayNudosStandardNotifications';
import { ICountryDetail } from '../../../../../../../types/countries';
import './ShipToEmployeeModalFlux.scss';

/**
 * @property { React.Dispatch<React.SetStateAction<boolean>> } setShowShipToEmployeeModalFlux - a react state updater function for determing if the modal is open or closed
 * @property { Iemployee } employeeData -- The employee data with which the modal will preloaded. This is the employee whose information will be updated as part of the modal callback
 * @property { (employeeUserId: string | number) => Promise<void> } finishEmployeeDestinationFluxCallbackFn - a callback function to execute upon the end of the handleClickModalButton, right before the modal is closed
 */
const ShipToEmployeeModalFlux = ({
  setShowShipToEmployeeModalFlux,
  employeeData,
  finishEmployeeDestinationFluxCallbackFn,
  completeListOfCountries
}: {
  setShowShipToEmployeeModalFlux: React.Dispatch<React.SetStateAction<boolean>>;
  employeeData: Iemployee;
  finishEmployeeDestinationFluxCallbackFn: (employeeUserId: string | number) => Promise<void>;
  completeListOfCountries?: ICountryDetail[];
}) => {
  const [loading, setLoading] = useState(false);

  const handleClickModalButton = async (modalData: DTOupdateEmployeeData) => {
    if (!modalData || !employeeData.userId || !employeeData.country?.id || !modalData?.countryPhoneId) return;
    setLoading(true);
    try {
      await updateEmployeeData(employeeData.userId, modalData);
      finishEmployeeDestinationFluxCallbackFn && finishEmployeeDestinationFluxCallbackFn(employeeData?.userId);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="shipToEmployeeModalFlux">
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            customHeight="474px"
            customWidth="636px"
            modalContentTitle="Información empleado"
            CustomModalComponent={
              <EmployeeDestinationModal
                subtitle="Agrega o valida  la información del empleado para el envío"
                buttonText="Guardar"
                employeeData={employeeData}
                clickButtonCallback={handleClickModalButton}
                isButtonLoading={loading}
                listOfCountries={completeListOfCountries}
              />
            }
            toCloseModal={setShowShipToEmployeeModalFlux}
          />
        }
      />
    </div>
  );
};

export default ShipToEmployeeModalFlux;
