import { Trans, useTranslation } from 'react-i18next';
import CheckIcon from '../../../assets/images/components/customCheck/CheckIcon';
import { sendSubscriptionSlackMessage } from '../../../services/subscriptions.services';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { getOrgData, getPersonalData } from '../../../utils/getLocalStorageData';
import { NudosButton } from '../../NudosComponents';
import { segmentTrackEvent } from '../../../utils/segment';
import './NudosDynamicBanner.scss';

const NudosDynamicBannerContent = ({
  bannerTitle,
  bannerSubtitle,
  closeModalCallback,
  openedBannerOrigin,
  submitBannerCallback,
  startNodiMultiColor,
  alertType,
  eventWithinTheAlert,
  resetExternalEvent
}: {
  bannerTitle: string;
  bannerSubtitle: string;
  openedBannerOrigin: string;
  closeModalCallback: () => void;
  submitBannerCallback?: () => void;
  startNodiMultiColor?: string;
  alertType?: number;
  eventWithinTheAlert?: string;
  resetExternalEvent?: () => void;
}) => {
  const { t } = useTranslation();
  const dynamicBannerTranslations = 'nodi:dynamicBanner:';
  const organizationData = getOrgData();
  const personalData = getPersonalData();
  const nodiPrime = <div className="nodiPrimeTitle">Nodi Prime</div>;

  const nodiFreeBenefits = [
    t(`${dynamicBannerTranslations}list:free:first`),
    t(`${dynamicBannerTranslations}list:free:second`),
    t(`${dynamicBannerTranslations}list:free:third`)
  ];
  const nodiPrimeBenefits = [
    t(`${dynamicBannerTranslations}list:prime:first`),
    t(`${dynamicBannerTranslations}list:prime:second`),
    t(`${dynamicBannerTranslations}list:prime:third`),
    t(`${dynamicBannerTranslations}list:prime:fourth`),
    t(`${dynamicBannerTranslations}list:prime:fifth`),
    t(`${dynamicBannerTranslations}list:prime:sixth`),
    t(`${dynamicBannerTranslations}list:prime:seventh`),
    t(`${dynamicBannerTranslations}list:prime:eighth`)
  ];
  const sendSlackMessage = async () => {
    const notificationMessage =
      alertType === 2 ? (
        'Gracias por adquirir Prime, en breves recibirás a tu correo el link para completar el pago'
      ) : (
        <Trans
          i18nKey={t(`nodi:footerBanner:notificationMessage`)}
          components={{
            1: 'Nodi Prime'
          }}
        />
      );
    try {
      const body = {
        organizationName: organizationData?.organizationName || '',
        requesterName: `${personalData?.firstName} ${personalData?.lastName}`,
        service: 'Nodi Prime',
        origin: openedBannerOrigin,
        userRole: personalData?.role
      };
      await sendSubscriptionSlackMessage(body);
      displaySuccessNotification({
        customJSXMessage: <>{notificationMessage}</>,
        button1Text: t(`recurrentWords:understoodButton`),
        button1Action: () => {
          if (eventWithinTheAlert) {
            segmentTrackEvent({ [eventWithinTheAlert]: null });
            if (resetExternalEvent) {
              resetExternalEvent();
            }
          }
        }
      });
    } catch (error) {
      displayErrorNotification();
    } finally {
      submitBannerCallback && submitBannerCallback();
      closeModalCallback();
    }
  };
  return (
    <div className="NudosDynamicBannerContent">
      <div className="dynamicText">
        <div className="bannerTitle">
          {startNodiMultiColor && <div className="nodiPrimeTitle">{`${startNodiMultiColor}`}&nbsp;</div>}
          {bannerTitle}
          {!startNodiMultiColor && (
            <>
              {t(`${dynamicBannerTranslations}with`)} {nodiPrime}
            </>
          )}
        </div>
        <div className="bannerSubtitle">{bannerSubtitle}</div>
      </div>
      <div className="nodiFree">
        <div className="nodiName free">Nodi Free</div>
        <div className="price">$0 USD</div>
        <hr className="divisionLine" />
        {nodiFreeBenefits.map(benefit => (
          <div className="benefit" key={benefit}>
            <CheckIcon className="checkIcon" />
            {benefit}
          </div>
        ))}
        <div className="currentPlan">{t(`${dynamicBannerTranslations}buttons:freeButton`)}</div>
      </div>
      <div className="nodiPrime">
        <div className="nodiPrimeContent">
          <div className="nodiName">Nodi Prime</div>
          <div className="primePrice">
            <div className="price">$2.500 USD</div>
            <div className="primeTime">{t(`${dynamicBannerTranslations}perYear`)}</div>
          </div>
          <hr className="divisionLine" />
          <div className="benefit"> {t(`${dynamicBannerTranslations}list:prime:freebenefits`)}</div>
          {nodiPrimeBenefits.map(benefit => (
            <div className="benefit" key={benefit}>
              <CheckIcon className="checkIcon" />
              {benefit}
            </div>
          ))}
          <div className="containerPrimeButton">
            <NudosButton
              buttonText={t(`${dynamicBannerTranslations}buttons:primeButton`)}
              customWidth="93px"
              customHeight="28px"
              customClassName="nodiPrimeButton"
              customFontStyles={{ fontSize: '10px' }}
              handleClick={() => sendSlackMessage()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NudosDynamicBannerContent;
