import { Link, useLocation } from 'react-router-dom';
import { FC } from 'react';
import useHeaderNavigationRoutes from './useHeaderNavigationRoutes';
import { BreadcrumbButtonReturnIcon } from '../../../../assets/images/components/NudosComponents';
import './HeaderBreadcrumbsButton.scss';

interface IHeaderBreadcrumbsButton {
  customMarginLeft?: number;
}

const HeaderBreadcrumbsButton: FC<IHeaderBreadcrumbsButton> = ({ customMarginLeft }: IHeaderBreadcrumbsButton) => {
  const { pathname, state }: { pathname: string; state: IcustomHeaderBreadcrumbsNavigationState } = useLocation();
  const { headerNavigationRoutes } = useHeaderNavigationRoutes();
  const { customReturnPath, customNavigationTitle, customNavigationSubtitle } = state || {};

  const pathWithoutIds = pathname.replace(/\/[0-9]+/, '');
  const currentRouteNavigationData = headerNavigationRoutes[pathWithoutIds];
  const headerNavigationTitle = customNavigationTitle || currentRouteNavigationData?.title || '';
  const headerNavigationSubtitle = customNavigationSubtitle || currentRouteNavigationData?.subtitle || '';
  const separatorCharacter = headerNavigationSubtitle ? ' / ' : '';
  const returnPath = customReturnPath || currentRouteNavigationData?.returnPath;
  const navigationTitleDetailStyle =
    headerNavigationSubtitle && headerNavigationTitle ? 'navigationTitleDetailStyle' : '';

  const divideTitle = (str: string) => (str.includes('-') ? [str.split('-')[0], str.slice(str.indexOf('-'))] : [str]);

  const dividedTitle = divideTitle(headerNavigationTitle);
  const firstSectionTitle = dividedTitle[0] || '';
  const secondSectionTitle = dividedTitle[1] || '';

  const navigationText = (
    <div className="navigationText">
      <div className="headerNavigationTitleContainer">
        <span className={`headerNavigationTitle ${navigationTitleDetailStyle}`}>{firstSectionTitle}</span>
        <span className="navigationTitleDetailStyle">{secondSectionTitle}</span>
      </div>
      <span className="separatorCharacter">{separatorCharacter}</span>
      <span className="headerNavigationSubtitle">{headerNavigationSubtitle}</span>
    </div>
  );

  return (
    <div className="HeaderBreadcrumbsButton" style={{ marginLeft: customMarginLeft ? `${customMarginLeft}px` : '' }}>
      {returnPath && (
        <Link className="navigationLink" to={returnPath}>
          {<BreadcrumbButtonReturnIcon className="BreadcrumbButtonReturnIcon" />}
          {navigationText}
        </Link>
      )}
      {!returnPath && navigationText}
    </div>
  );
};

export default HeaderBreadcrumbsButton;

export interface IcustomHeaderBreadcrumbsNavigationState {
  customReturnPath?: string;
  customNavigationTitle?: string;
  customNavigationSubtitle?: string;
}
