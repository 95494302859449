import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BigGoToDashboardIcon } from '../../assets/images/components/header/';
import { isRouteMyLocation } from '../../utils/getPathRoute';
import useEcommerceControler from '../../views/ecommerce/ecommerce.controller';
import SearchProduct from '../SearchProduct/SearchProduct';
import useCheckoutStore from '../../views/ecommerce/checkout/state';
import ProfileInfo from '../profileInfo';
import MinSmile from '../../assets/images/views/Admin/main/minSmile';
import MinCart from '../../assets/images/components/header/minCart';
import useCartState from '../../state/useCartState';
import { productsNumber } from '../../utils/cart';
import useStateSubscriptions from '../../state/useStateSubscriptions';
import useLogOutState from '../../state/useLogOutState';
import HeaderBreadcrumbsButton from './Components/HeaderBreadcrumbsButton/HeaderBreadcrumbsButton';
import { nodiFreeTag, nodiPrimeTag, nudosOnePointLogo } from '../../assets/images/views/Admin/main';
import { formatOrgData } from '../../utils/orgFormatData';
import { CountryButton } from '../CountryButton';
import useLanguagePlatformState from '../../state/useLenguageState';

import './NodiHeader.scss';

function NodiHeader() {
  const { platformLanguage } = useLanguagePlatformState();
  const { t } = useTranslation();
  const { push, replace } = useHistory();
  const { orgData } = useEcommerceControler();

  const formatOrgInfo = formatOrgData();

  const { setOpenCart, openCart } = useCartState();
  const { step: checkoutStep } = useCheckoutStore();
  const { stateSubscription } = useStateSubscriptions();
  const { openMinMenu, setOpenMinMenu } = useLogOutState();
  const isCheckout = isRouteMyLocation('checkout');
  const isCheckoutLastStep = isCheckout && checkoutStep === 3;
  const isEcommerce = isRouteMyLocation('catalogue', 1);
  const isEcommerceHome = isRouteMyLocation('catalogue', 1) && isRouteMyLocation('home', 2);
  const isEcommerceSearch = isRouteMyLocation('catalogue', 1) && isRouteMyLocation('search', 2);
  const isEcommercProductDetails = isRouteMyLocation('catalogue', 1) && isRouteMyLocation('product', 2);
  const isBoard = isRouteMyLocation('nodi', 1);
  const isProductDetails = isRouteMyLocation('catalogue', 1) && isRouteMyLocation('product', 2);
  const isAccount = isRouteMyLocation('nodi', 1) && isRouteMyLocation('account', 2);
  const accountModuleStyles = isAccount ? 'accountModule' : '';
  const productsNumberCart = productsNumber();
  const isFree = stateSubscription?.levelSubscription === 'Free';
  const [openModal, setOpenModal] = useState<boolean>(false);
  const displaySearchProduct = isEcommerceHome || isEcommerceSearch || isEcommercProductDetails;

  //blockedCompany 332
  const blockMarketPlace = formatOrgInfo?.organizationId === 332;

  const onClick = () => {
    setOpenCart(false);
    orgData?.organizationState === 'active' && push('/catalogue/checkout');
  };

  const redirectToPath = (path: string) => {
    if (orgData?.organizationState !== 'active') return;
    else replace(path);
  };

  const handleGoToCatalogue = () => {
    redirectToPath('/catalogue/home');
    setOpenMinMenu(false);
  };

  const handleGoHome = () => orgData?.organizationState === 'active' && replace('/nodi/dashboard');

  const handleChangeStatusModal = (status: boolean) => {
    setOpenModal(status);
  };

  const goToCatalogButton = (
    <div className="containerButtonsNodi">
      {!blockMarketPlace && (
        <button
          id="button-header-marketPlace"
          className={`buttonStore ${isEcommerce ? 'buttonInEcommerce' : ''}`}
          onClick={handleGoToCatalogue}
        >
          {t(`nodi:headBar:buttonStore`)}
          <MinSmile className="minSmile" />
        </button>
      )}
    </div>
  );

  return (
    <header
      id="nodiHeader"
      className={`${accountModuleStyles} ${openModal ? 'checkoutModalOpen' : ''}`}
      style={{ zIndex: accountModuleStyles && openMinMenu ? 1 : '' }}
    >
      <section className="headerLeftSection">
        <img className="nudosOnePointLogo" src={nudosOnePointLogo} onClick={handleGoHome} />
        <HeaderBreadcrumbsButton customMarginLeft={24} />
        {isEcommerce && !isCheckout && (
          <div className="platformTypeContainer">
            <div className="platformTypeBox">
              {isFree ? (
                <div className="freeItem">
                  <img src={nodiFreeTag} />
                </div>
              ) : (
                <div className="primeItem">
                  <img src={nodiPrimeTag} />
                </div>
              )}
            </div>
          </div>
        )}
        {displaySearchProduct && (
          <SearchProduct
            customClassName={`${isEcommerceSearch ? 'ecommerceSearchBar' : ''}`}
            marginLeft={platformLanguage === 'enUS' ? 24 : undefined}
          />
        )}
      </section>
      <section id="headerRight" className="headerRightSection">
        {isBoard && goToCatalogButton}
        {isEcommerce && (
          <div className="ecommerceSpecificHeaderContents">
            {isCheckout && !isCheckoutLastStep && goToCatalogButton}
            {(!isCheckout || isCheckoutLastStep) && (
              <BigGoToDashboardIcon className="goToDashboardIcon" onClick={() => redirectToPath('/nodi/dashboard')} />
            )}
            {!isCheckout && (
              <div className="absoluteCart">
                <div className="adjustCart">
                  <div
                    id="icon-cart-header"
                    onMouseOver={() => {
                      if (isEcommerceHome || isProductDetails) setOpenCart(true);
                    }}
                    onMouseLeave={() => {
                      if (!productsNumberCart) setOpenCart(false);
                    }}
                    className={`containerCart ${openCart ? 'bg-nudos-orange' : ''}`}
                    {...{ onClick }}
                  >
                    <div className="boxCart">
                      <MinCart className={`shoppingCartIcon ${openCart ? 'extraShoppingCartIcon' : ''}`} />
                    </div>
                    {!!productsNumberCart && (
                      <div className={`boxQuantity ${openCart ? 'extraBoxQuantity' : ''}`}>
                        <div className="orangeItem">
                          <div className="textCart">{productsNumberCart}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {isBoard && (
          <>
            {stateSubscription?.levelSubscription === 'Free' ? (
              <img className="nodiFreeTag" src={nodiFreeTag} />
            ) : (
              <img src={nodiPrimeTag} className="nodiPrimeTag" />
            )}
            <ProfileInfo />
          </>
        )}
        {isEcommerce && <CountryButton openModal={openModal} handleChangeStatusModal={handleChangeStatusModal} />}
      </section>
    </header>
  );
}

export default NodiHeader;
