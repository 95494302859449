import './topFiltersLoader.scss';

const TopLoaderFilters = () => {
  return (
    <div id="topLoaderFilters" className="adjustNewStore">
      <div className="loaderContainer animationLoader"></div>
    </div>
  );
};
export default TopLoaderFilters;
