import * as yup from 'yup';
import usePlatform from '../../../../../../hooks/usePlatform';
import { IformInputs } from '../../../../../../types/SignUp';
import { InudosNotification } from '../../../../../../components/NudosNotification/NudosNotification';
import { useTranslation } from 'react-i18next';

const isMobile = usePlatform();

const useSignUpFormStep3Controller = () => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'authentication:SignUpForm:useSignUpFormStep3Controller:';

  const formInputsStep3: IformInputs[] = [
    {
      id: 'email',
      placeholder: 'juanita@somosnudos.com',
      label: t(`${i18nLanguageKey}email`),
      type: 'text'
    },
    {
      id: 'password',
      placeholder: '',
      label: isMobile ? t(`${i18nLanguageKey}password`) : t(`${i18nLanguageKey}password`),
      type: 'password'
    },
    {
      id: 'confirmPassword',
      placeholder: '',
      label: t(`${i18nLanguageKey}confirmPassword`),
      type: 'password'
    }
  ];

  const formSchemaStep3 = yup.object().shape({
    email: yup
      .string()
      .required()
      .matches(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
        t(`${i18nLanguageKey}formSchemaStep3:email`)
      ),
    password: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.-=])([A-Za-z\d$@$!%*?&.-=]|[^ ]){8,15}$/,
        t(`${i18nLanguageKey}formSchemaStep3:password`)
      ),
    confirmPassword: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.-=])([A-Za-z\d$@$!%*?&.-=]|[^ ]){8,15}$/,
        t(`${i18nLanguageKey}formSchemaStep3:confirmPassword`)
      )
  });

  const verifyPassword = (password: string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.-=])([A-Za-z\d$@$!%*?&.-=]|[^ ]){8,15}$/;
    return regex.test(password);
  };

  const checkPasswordInputs = (password: string, confirmPassword: string) => {
    if (!!password && !!confirmPassword) {
      if (password === confirmPassword && !!verifyPassword(password)) {
        return true;
      }
      return false;
    }
    return true;
  };

  const signUpFormStep3ErrorNotifications: { [key: string]: InudosNotification } = {
    companyAlreadyRegistered: {
      className: 'notification-nudos-single',
      message: 'Esta empresa ya se encuentra registrada'
    },
    userAlreadyRegistered: {
      className: 'notification-nudos-single',
      message: 'Este usuario ya está registrado. Intenta con otro correo o teléfono.'
    },
    invalidPhoneNumber: {
      className: 'notification-nudos-single',
      message: 'El número de teléfono no es válido, por favor verificalo.'
    },
    successfulRegistration: {
      className: 'notification-nudos-double',
      message: 'Hemos enviado un link de acceso al e-commerce a '
    },
    reSendEmail: {
      className: 'notificacion-nudos-single',
      message: 'Enviamos de nuevo el código a tu celular'
    }
  };

  return { formInputsStep3, formSchemaStep3, verifyPassword, checkPasswordInputs, signUpFormStep3ErrorNotifications };
};

export default useSignUpFormStep3Controller;
