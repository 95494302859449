import { FC, useState } from 'react';
import IconGreenThunder from '../../../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import IconWarningInformation from '../../../../../../assets/DesignSystem/SVGComponents/IconWarningInformation';
import { ILogisticQuoteGeneralData } from '../../../../../../types/logisticsServicesModule.types';
import { formatCost } from '../../../../../../utils/formatNumbers';

import './TotalDescription.scss';

interface ITotalDescription {
  typePlan: 'Free' | 'Care' | 'Lite';
  logisticServiceQuoteData?: ILogisticQuoteGeneralData;
}

const TotalDescription: FC<ITotalDescription> = ({ typePlan, logisticServiceQuoteData }: ITotalDescription) => {
  const [showPaymentCommissionTooltip, setShowPaymentCommissionTooltip] = useState<boolean>(false);
  const [showManagementTooltip, setShowManagementTooltip] = useState<boolean>(false);

  return (
    <div id="totalDescription">
      <div className="boxTotalDescription">
        <div className="generalTotalDescription">
          <div className="simpleContainer">
            <div className="lElement">
              <div className="boxLElement">
                <div className="simpleText">Subtotal</div>
              </div>
            </div>
            <div className="rElement">
              <div className="boxRElement">
                <div className="simpleText">
                  {`$${formatCost(Number(logisticServiceQuoteData?.subtotal || 0), 2)} USD`}
                </div>
              </div>
            </div>
          </div>

          <div className="simpleContainer">
            <div className="lElement">
              <div className="boxLElement ">
                <div className="simpleText">Comisión de gestión</div>
                {(typePlan === 'Free' || typePlan === 'Lite') && (
                  <div className="iconInformationContainer">
                    <IconWarningInformation
                      className="cursor-pointer"
                      color="#999999"
                      onMouseOverCapture={() => {
                        setShowManagementTooltip(true);
                      }}
                      onMouseMoveCapture={() => {
                        setShowManagementTooltip(true);
                      }}
                      onMouseOutCapture={() => {
                        setShowManagementTooltip(false);
                      }}
                      onMouseOut={() => {
                        setShowManagementTooltip(false);
                      }}
                    />
                    {showManagementTooltip && (
                      <div className="minTooltip" style={{ width: '220px' }}>
                        <div className="minBlueTitle mb-1">Comisión de gestión</div>
                        <div className="minGraySubtitle mb-1">
                          La Comisión de gestión es el costo asociado al <br /> manejo de cada dispositivo
                        </div>
                        <div className="minNumbersCenter">
                          Pago ={' '}
                          {(
                            (Number(logisticServiceQuoteData?.managementCommission || 0) /
                              Number(logisticServiceQuoteData?.subtotal || 0)) *
                            100
                          ).toFixed()}
                          %
                        </div>
                        <div className="minCommissionText">
                          <div className="lCommission">Comisión de gestión </div>
                          <div className="RComission">
                            {typePlan === 'Lite' ? (
                              <>
                                <span className="alertateText">Cubierto con Nodi Prime</span>
                                <IconGreenThunder fill="#7B61FF" className="marginIconGreen" />
                              </>
                            ) : (
                              <>{`$${formatCost(logisticServiceQuoteData?.managementCommission)} USD`}</>
                            )}
                          </div>
                        </div>
                        {typePlan === 'Lite' && (
                          <div className="footerTooltip">
                            <div className="continerMinIcon">
                              <IconWarningInformation color="#999999" className="resizeInfIcon" />
                            </div>
                            <div className="minTextGray">
                              Nota: Para almacenamientos en Bodegas Nudos, Nodi Prime <strong>NO cubre</strong> la
                              comisión de gestión generada por el tiempo en el que las herramientas estuvieron
                              almacenadas con Nodi Free
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="rElement">
              <div className="boxRElement">
                <div className="containerMinIcon">
                  <IconGreenThunder fill={typePlan === 'Free' ? '#CCCCCC' : typePlan === 'Lite' ? '#FFBB00' : ''} />
                </div>
                {typePlan !== 'Free' &&
                  !!logisticServiceQuoteData?.managementCommission &&
                  logisticServiceQuoteData?.managementCommission > 0 && (
                    <div className="simpleTextUnderline">
                      {`$${formatCost(Number(logisticServiceQuoteData?.managementCommission || 0), 2)} USD`}{' '}
                    </div>
                  )}
                <div className="simpleText">
                  {`$${formatCost(Number(logisticServiceQuoteData?.managementCommissionToCharge || 0), 2)} USD`}
                </div>
              </div>
            </div>
          </div>
          <div className="simpleContainer">
            <div className="lElement">
              <div className="boxLElement">
                <div className="simpleText">Comisión de pago</div>
                {(typePlan === 'Free' || typePlan === 'Lite') && (
                  <div className="iconInformationContainer">
                    <IconWarningInformation
                      className="cursor-pointer"
                      color="#999999"
                      onMouseOverCapture={() => {
                        setShowPaymentCommissionTooltip(true);
                      }}
                      onMouseMoveCapture={() => {
                        setShowPaymentCommissionTooltip(true);
                      }}
                      onMouseOutCapture={() => {
                        setShowPaymentCommissionTooltip(false);
                      }}
                      onMouseOut={() => {
                        setShowPaymentCommissionTooltip(false);
                      }}
                    />
                    {showPaymentCommissionTooltip && (
                      <div className="minTooltip" style={{ width: '220px' }}>
                        <div className="minBlueTitle mb-1">Comisión de pago</div>
                        <div className="minGraySubtitle mb-1">
                          La Comisión de pago es el costo asociado al <br /> método de pago
                        </div>
                        <div className="minNumbersCenter">Pago = 2.9% + 0.30 USD</div>
                        <div className="minCommissionText">
                          <div className="lCommission">Comisión de pago </div>
                          <div className="RComission">
                            {typePlan === 'Lite' ? (
                              <>
                                <span className="alertateText">Cubierto con Nodi Prime</span>
                                <IconGreenThunder fill="#7B61FF" className="marginIconGreen" />
                              </>
                            ) : (
                              <> {`$ ${formatCost(logisticServiceQuoteData?.paymentCommission)} USD`}</>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="rElement">
              <div className="boxRElement">
                <div className="containerMinIcon">
                  <IconGreenThunder fill={typePlan === 'Free' ? '#CCCCCC' : typePlan === 'Lite' ? '#FFBB00' : ''} />
                </div>
                {typePlan !== 'Free' &&
                  !!logisticServiceQuoteData?.paymentCommission &&
                  logisticServiceQuoteData?.paymentCommission > 0 && (
                    <div className="simpleTextUnderline">
                      {`$${formatCost(Number(logisticServiceQuoteData?.paymentCommission || 0), 2)} USD`}{' '}
                    </div>
                  )}
                <div className="simpleText">{`$ ${formatCost(
                  Number(logisticServiceQuoteData?.paymentCommissionToCharge || 0),
                  2
                )} USD`}</div>
              </div>
            </div>
          </div>

          {!!logisticServiceQuoteData?.nudosCareSaving && (
            <div className="simpleContainer">
              <div className="lElement">
                <div className="boxLElement">
                  <div className="simpleText">Cobertura por Nudos Care</div>
                </div>
              </div>
              <div className="rElement">
                <div className="boxRElement">
                  <div className="simpleTextGreen">-${formatCost(logisticServiceQuoteData?.nudosCareSaving)} USD</div>
                </div>
              </div>
            </div>
          )}
          <div className="simpleContainer">
            <div className="lElement">
              <div className="boxLElement">
                <div className="bigSimpleText">Total</div>
              </div>
            </div>
            <div className="rElement">
              <div className="boxRElement">
                <div className="bigSimpleText">{`$${formatCost(
                  Number(logisticServiceQuoteData?.total || 0),
                  2
                )} USD`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalDescription;
