import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} fill="none" ref={ref} {...props}>
    <g>
      <path
        stroke="#999"
        strokeLinecap="round"
        d="M5 8.691c1.058 0 1.915-.714 1.915-1.595 0-.88-.857-1.595-1.915-1.595-1.057 0-1.914-.714-1.914-1.596 0-.88.857-1.595 1.914-1.595m0 6.381c-1.057 0-1.914-.714-1.914-1.595M5 8.691v.639m0-7.02v-.638m0 .638c1.058 0 1.915.714 1.915 1.595"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconDollarSign = memo(ForwardRef);
export default IconDollarSign;
