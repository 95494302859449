import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { expandedHelpButton } from '../../../../assets/images/components/floatingHelpButton';
import { cycleIcon, envelopeIcon, phoneIcon } from '../../../../assets/images/views/PendingOrRejectedView';
import { NodiIcon, nodiNotRegistered } from '../../../../assets/images/views/signIn';
import registerChecked from '../../../../assets/images/views/signUp/registerChecked.webp';
import { NudosButton } from '../../../../components/NudosComponents';
import { getUsersOrganizationData } from '../../../../services/services';
import { useUserAuth } from '../../../../state/userAuthContext';
import { getPersonalData } from '../../../../utils/getLocalStorageData';
import { segmentTrackEvent } from '../../../../utils/segment';
import { useTranslation } from 'react-i18next';
import { supportUrl } from '../../../../utils/productDefinitions';
import './PendingOrRejectedView.scss';

const PendingOrRejectedView = () => {
  const { t } = useTranslation();
  const { replace, push } = useHistory();
  const { user } = useUserAuth();
  const stringOrgData = localStorage.getItem('orgData');
  const storedOrgData = stringOrgData && JSON.parse(stringOrgData);
  const signUpSegmentData = getPersonalData();
  const stringSessionLogin = localStorage.getItem('sessionLogin');
  const sessionLogin = stringSessionLogin && JSON.parse(stringSessionLogin);
  const [orgData, setOrgData] = useState(storedOrgData);
  const pending = orgData?.organizationState === 'pending' ? true : false;
  const i18nLanguageKey = 'nodi:pendingOrRejectedView:';

  const handleClickForHelp = () => {
    window.open(supportUrl);
  };

  const handleReload = () => window.location.reload();

  const verifyStatus = async () => {
    if (user?.uid) {
      const userData = await getUsersOrganizationData(user?.uid);
      if (userData.organizationState === 'active') {
        setOrgData(userData);
        localStorage.setItem('orgData', JSON.stringify(userData));
        push('/nodi/dashboard');
      }
      if (userData.organizationState === 'rejected') {
        setOrgData(userData);
        localStorage.setItem('orgData', JSON.stringify(userData));
      }
    }
  };

  useLayoutEffect(() => {
    if (orgData?.organizationState === 'active') push('/nodi/dashboard');
    verifyStatus();
  }, []);

  useEffect(() => {
    if (!sessionLogin || !orgData) replace(`/login`);
  }, [orgData, sessionLogin]);

  useEffect(() => {
    if (!orgData) return;

    if (orgData?.organizationState === 'pending') {
      segmentTrackEvent({
        signupWaitingView: {
          ContactNumber:
            signUpSegmentData?.phone ||
            `${signUpSegmentData?.phoneData?.phoneCode} ${signUpSegmentData?.phoneData?.phone}`,
          Name: signUpSegmentData?.firstName || orgData?.firstName,
          RoleName: '',
          Surname: signUpSegmentData?.lastName || orgData?.lastName
        }
      });
    }
    if (orgData?.organizationState === 'rejected') {
      segmentTrackEvent({
        signupRejectedView: {
          ContactNumber:
            signUpSegmentData?.phone ||
            `${signUpSegmentData?.phoneData?.phoneCode} ${signUpSegmentData?.phoneData?.phone}`,
          Name: signUpSegmentData?.firstName || orgData?.firstName,
          RoleName: '',
          Surname: signUpSegmentData?.lastName || orgData?.lastName
        }
      });
    }
  }, [orgData]);

  return (
    <div className="pendingOrRejectedView">
      <div className="content">
        <NodiIcon className="nodiIcon" />
        {pending && (
          <div className="imageContainer">
            <img src={registerChecked} alt="Icono registro completado" />
          </div>
        )}
        {!pending && (
          <div className="imageContainer">
            <img src={nodiNotRegistered} alt="Icono Nodi bloqueado" />
          </div>
        )}
        <div className="explanationTitle">
          {pending ? t(`${i18nLanguageKey}explanationTitle`) : t(`${i18nLanguageKey}explanationTitleDefault`)}
        </div>
        <div className="explanationSection">
          {pending && (
            <>
              <div className="textBox">
                <div className="icon" style={{ top: '-12px' }}>
                  <img src={`${envelopeIcon}`} alt="" />
                </div>
                <div className="text">{t(`${i18nLanguageKey}textEnvelopeIcon`)}</div>
              </div>
              <div className="textBox">
                <div className="icon">
                  <img src={`${cycleIcon}`} alt="" />
                </div>
                <div className="text">{t(`${i18nLanguageKey}textCycleIcon`)}</div>
              </div>
            </>
          )}
          {!pending && (
            <div className="textBox">
              <div className="icon">
                <img src={`${phoneIcon}`} alt="" />
              </div>
              <div className="text">{t(`${i18nLanguageKey}textPhoneIcon`)}</div>
            </div>
          )}
        </div>
        {!pending && (
          <div className="helpButton" onClick={handleClickForHelp}>
            <img src={expandedHelpButton} alt="Expanded help button" />
          </div>
        )}
        {pending && (
          <NudosButton
            buttonText={t(`${i18nLanguageKey}nudosButton`)}
            colorPalette="secondary"
            componentSize="large"
            handleClick={handleReload}
          />
        )}
      </div>
    </div>
  );
};

export default PendingOrRejectedView;
