import { DTOOrganizationDocumentAlreadyExistResponse } from '../../types/SignUp';
import { NudosGenericModal, NudosModalContent } from '../NudosComponents';
import InviteCollaboratorCustomModalComponent from './InviteCollaboratorCustomModalComponent';
import './InviteCollaboratorModal.scss';

const InviteCollaboratorModal = ({
  setOpenInviteCollaboratorModal,
  isAnInvitation,
  existingOrganizationData,
  modalTitle
}: IinviteCollaboratorModalParams) => {
  return (
    <div className="inviteCollaboratorModal">
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            componentSize="medium"
            modalContentTitle={modalTitle || 'Empresa registrada'}
            CustomModalComponent={
              <InviteCollaboratorCustomModalComponent
                isAnInvitation={!!isAnInvitation}
                setOpenInviteCollaboratorModal={setOpenInviteCollaboratorModal}
                existingOrganizationData={existingOrganizationData}
              />
            }
            toCloseModal={() => setOpenInviteCollaboratorModal(false)}
          />
        }
      />
    </div>
  );
};

export default InviteCollaboratorModal;

export interface IinviteCollaboratorModalParams {
  setOpenInviteCollaboratorModal: React.Dispatch<React.SetStateAction<boolean>>;
  existingOrganizationData: DTOOrganizationDocumentAlreadyExistResponse;
  isAnInvitation?: boolean;
  modalTitle?: string;
}
