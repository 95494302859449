import { FC, useEffect, useState } from 'react';
import ArrowsFilter from '../../../../../../assets/images/components/genericFilter/arrowsFilter';
import { SerialIcon } from '../../../../../../assets/images/views/Admin/successLoad';
import { NudosCareTag } from '../../../../../../components/NudosComponents';
import { IToolQuoteInfo } from '../../../../../../types/logisticsServicesModule.types';
import { TnudosCare } from '../../../../../../types/global';
import { formatCost } from '../../../../../../utils/formatNumbers';
import IconGreenThunder from '../../../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import { TstoragePlace } from '../../../../../../types/assignationFluxes';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import IconWarningInformation from '../../../../../../assets/DesignSystem/SVGComponents/IconWarningInformation';
import useStatusFixedTooltip from '../../../../../../state/fixedTooltipsState';

import './LogisticsQuoteByTool.scss';

interface ICardQuoteTool {
  index: number;
  cardData: IToolQuoteInfo;
  destinationPlace?: TstoragePlace;
  originPlace?: TstoragePlace;
}
const CardQuoteTool: FC<ICardQuoteTool> = ({ cardData, destinationPlace, originPlace, index }: ICardQuoteTool) => {
  const [openCard, setOpenCard] = useState<boolean>(false);
  const showAmountPaid = ['Log', 'Full', 'Basic'].includes(cardData?.nudosCareName || '');
  const {
    showFirstMonthTooltip,
    setShowFirstMonthTooltip,
    showLSTooltip,
    setShowLSTooltip,
    showDatesTooltip,
    setShowDatesTooltip
  } = useStatusFixedTooltip();
  const [position, setPosition] = useState<{ x: number; y: number } | undefined>();

  const keyFirstMonthTooltip = `first-month-tooltip-${cardData.productId}-index-${index}`;
  const keyDateMonthTooltip = `dates-tooltip-${cardData.productId}-index-${index}`;
  const keysLSTooltip = `logistics-service-tooltip-${cardData.productId}-index-${index}`;
  const assignPositionFirstMonthTooltip = () => {
    if (showFirstMonthTooltip || showLSTooltip || showDatesTooltip) {
      const myElement = document.getElementById(showFirstMonthTooltip || showLSTooltip || showDatesTooltip || '');
      if (myElement) {
        const positionTooltip = myElement ? myElement.getBoundingClientRect() : undefined;
        if (positionTooltip?.x && positionTooltip?.y) {
          setPosition({ x: positionTooltip.x, y: positionTooltip?.y + 14 });
        }
      }
    }
  };

  const calculatePercentageRedistribution = (redistributionWithoutDiscount: number, redistribution: number) => {
    if (redistribution && redistributionWithoutDiscount) {
      const onePorcent = redistributionWithoutDiscount / 100;
      const discountRate = redistribution / onePorcent;
      return `${discountRate}% `;
    }
    return '0%';
  };

  useEffect(() => {
    assignPositionFirstMonthTooltip();
  }, [showFirstMonthTooltip, showLSTooltip, showDatesTooltip]);

  return (
    <div id="cardQuoteTool">
      <div className="principalDetailsTool">
        <div className="boxPricipalContaier" onClick={() => setOpenCard(!openCard)}>
          <div className="lSectionQuote">
            <div className="toolNameQuote">{cardData.name}</div>
            <div className="serialIconQuote">
              <SerialIcon />
            </div>
            <div className="toolSerialQuote">{cardData.serial || '-'}</div>
          </div>
          <div className="rSectionQuote">
            <div className="containerTag">
              <NudosCareTag customHeight="16px" nudosCareType={cardData.nudosCareName as TnudosCare} />
            </div>
            <div className="amountQuoteText">${formatCost(cardData.toolSubtotal, 2)} USD</div>
            <div className="arrowQuoteCard">
              <ArrowsFilter className={openCard ? 'rotateArror' : ''} fill="#383838" />
            </div>
          </div>
        </div>
      </div>
      {openCard && (
        <div className="detaulsQuoteTool">
          {!!cardData?.fees?.redistribution && (
            <div className="itemListContainer">
              <div className="lItemList">
                Servicio logístico
                {cardData?.isBuyAndHold && (
                  <div className="infoGrayIcon">
                    <div className="boxInfoGrayIcon" id={keysLSTooltip}>
                      <IconWarningInformation
                        className="cursor-pointer"
                        color="#999999"
                        onMouseOverCapture={() => {
                          setShowLSTooltip(keysLSTooltip);
                        }}
                        onMouseMoveCapture={() => {
                          setShowLSTooltip(keysLSTooltip);
                        }}
                        onMouseOutCapture={() => {
                          setShowLSTooltip(undefined);
                        }}
                        onMouseOut={() => {
                          setShowLSTooltip(undefined);
                        }}
                      />
                      {showLSTooltip && showLSTooltip === keysLSTooltip && position && (
                        <div className="containetTooltip" style={{ left: position?.x || 720, top: position?.y || 330 }}>
                          <div className="minBlueTitle">Servicio logístico</div>
                          <div className="minGrayText">
                            Al ser una herramienta comprada en el Nodi MarketPlace y elegir almacenar la herramienta, el
                            Servicio Logístico tiene un{' '}
                            {calculatePercentageRedistribution(
                              cardData?.fees?.redistributionWithoutDiscount || 0,
                              cardData?.fees?.redistribution || 0
                            )}
                            de descuento
                          </div>
                          <div className="monthlyPriceMin">
                            Costo del servicio ={' '}
                            {`$${formatCost(cardData?.fees?.redistributionWithoutDiscount || 0)} USD`}
                          </div>
                          <div className="minPaymentAmount">
                            <div className="minDownText">Servicio logístico</div>
                            <div className="minDownText ">
                              {`$${formatCost(cardData?.fees?.redistributionWithoutDiscount || 0)} USD`}
                            </div>
                          </div>{' '}
                          <div className="minPaymentAmount">
                            <div className="minDownText">
                              Descuento por Buy & Hold{' '}
                              <span className="extraMinGray">
                                (
                                {calculatePercentageRedistribution(
                                  cardData?.fees?.redistributionWithoutDiscount || 0,
                                  cardData?.fees?.redistribution || 0
                                )}
                                off)
                              </span>
                            </div>
                            <div className="minDownText ">
                              {`-$${formatCost(
                                (cardData?.fees?.redistributionWithoutDiscount || 0) -
                                  (cardData?.fees.redistribution || 0)
                              )} USD`}{' '}
                            </div>
                          </div>
                          <div className="minPaymentAmount">
                            <div className="minDownText">Monto a pagar</div>
                            <div className="minDownText minBigDownText">
                              {`$${formatCost(cardData?.fees.redistribution)} USD`}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {showAmountPaid ? (
                <div className="rItemList">
                  <div className="containerGreenIcon">
                    <IconGreenThunder />
                  </div>
                  <span>${formatCost(cardData?.fees?.redistribution, 2)} USD</span>
                </div>
              ) : (
                <div className="rItemList">
                  {cardData.isBuyAndHold && (
                    <div className="containerYellowIcon">
                      <div className="yellowIcon">
                        <IconGreenThunder fill="#FFBB00" />
                      </div>
                      <div className="amountText">{`$${formatCost(
                        cardData?.fees?.redistributionWithoutDiscount || 0
                      )} USD`}</div>
                    </div>
                  )}
                  ${formatCost(cardData?.fees?.redistribution, 2)} USD
                </div>
              )}
            </div>
          )}

          {!!cardData?.fees?.entryStorage && destinationPlace === 'nudos' && (
            <div className="itemListContainer">
              <div className="lItemList">
                {`Almacenamiento (primer mes)`}
                <div className="infoGrayIcon">
                  <div className="boxInfoGrayIcon" id={keyFirstMonthTooltip}>
                    <IconWarningInformation
                      onMouseOverCapture={() => {
                        setShowFirstMonthTooltip(keyFirstMonthTooltip);
                      }}
                      onMouseMoveCapture={() => {
                        setShowFirstMonthTooltip(keyFirstMonthTooltip);
                      }}
                      onMouseOutCapture={() => {
                        setShowFirstMonthTooltip(undefined);
                      }}
                      onMouseOut={() => {
                        setShowFirstMonthTooltip(undefined);
                      }}
                      className="cursor-pointer"
                      color="#999999"
                    />

                    {showFirstMonthTooltip && showFirstMonthTooltip === keyFirstMonthTooltip && position && (
                      <div className="containetTooltip" style={{ left: position?.x || 720, top: position?.y || 330 }}>
                        <div className="minBlueTitle">Almacenamiento</div>
                        <div className="minGrayText">
                          Al almacenar una herramienta en nuestras bodegas, se incluye el primer mes de almacenamiento
                          dentro del Servicio. Al solicitar la salida, se facturan los meses acumulados de
                          almacenamiento, descontando el mes inicial ya pagado.
                        </div>
                        <div className="monthlyPriceMin">
                          Precio mensual = {`$${formatCost(cardData.fees.entryStorage || 0)} USD`}
                        </div>
                        <div className="minPaymentAmount">
                          <div className="minDownText">Monto a pagar</div>
                          <div className="minDownText minBigDownText">
                            {`$${formatCost(cardData.fees.entryStorage || 0)} USD`}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {showAmountPaid ? (
                <div className="rItemList">
                  <div className="containerGreenIcon">
                    <IconGreenThunder />
                  </div>
                  <span>${formatCost(cardData?.fees?.entryStorage)} USD</span>
                </div>
              ) : (
                <div className="rItemList">${formatCost(cardData?.fees?.entryStorage)} USD</div>
              )}
            </div>
          )}

          {!!cardData?.fees?.exitStorage && originPlace === 'nudos' && (
            <div className="itemListContainer">
              <div className="lItemList">
                {`Almacenamiento (${formatShortDate(cardData?.storageDates?.entryDate).onlyDate} al ${
                  formatShortDate(cardData?.storageDates?.exitDate)?.onlyDate
                })`}

                <div className="infoGrayIcon">
                  <div className="boxInfoGrayIcon" id={keyDateMonthTooltip}>
                    <IconWarningInformation
                      onMouseOverCapture={() => {
                        setShowDatesTooltip(keyDateMonthTooltip);
                      }}
                      onMouseMoveCapture={() => {
                        setShowDatesTooltip(keyDateMonthTooltip);
                      }}
                      onMouseOutCapture={() => {
                        setShowDatesTooltip(undefined);
                      }}
                      onMouseOut={() => {
                        setShowDatesTooltip(undefined);
                      }}
                      className="cursor-pointer"
                      color="#999999"
                    />

                    {showDatesTooltip && showDatesTooltip === keyDateMonthTooltip && position && (
                      <div className="containetTooltip" style={{ left: position?.x || 720, top: position?.y || 330 }}>
                        <div className="minBlueTitle">Almacenamiento</div>
                        <div className="minGrayText">
                          Al almacenar una herramienta en nuestras bodegas, se cobra el primer mes al momento de
                          ingresar. Al solicitar la salida, se facturan los meses acumulados de almacenamiento,
                          descontando el mes inicial ya pagado.
                        </div>
                        <div className="monthlyPriceMin" style={{ lineHeight: 'normal', marginBottom: '0px' }}>
                          Precio mensual = {`$${formatCost(cardData.storageDetails?.monthlyFee)} USD`}
                        </div>
                        <div className="monthlyPriceMin" style={{ lineHeight: 'normal' }}>
                          Tiempo de almacenamiento = {cardData.storageDetails?.totalStorageMonths} meses
                        </div>
                        <div className="minPaymentAmount">
                          <div className="minDownText">Almacenamiento</div>
                          <div className="minDownText">
                            {`$${formatCost(cardData?.storageDetails?.totalStorageCosts || 0)} USD`}
                          </div>
                        </div>

                        {cardData?.isBuyAndHold ? (
                          <div className="minPaymentAmount">
                            <div className="minDownText">Descuento por Buy & Hold</div>
                            <div className="minDownText">
                              {`$${formatCost(cardData.storageDetails?.storageSavings || 0)} USD`}
                            </div>
                          </div>
                        ) : (
                          <div className="minPaymentAmount">
                            <div className="minDownText">Primer mes de almacenamiento</div>
                            <div className="minDownText">
                              {`-$${formatCost(cardData.storageDetails?.storageSavings || 0)} USD`}
                            </div>
                          </div>
                        )}

                        <div className="minPaymentAmount">
                          <div className="minDownText">Monto a pagar</div>
                          <div className="minDownText minBigDownText">{`$${formatCost(
                            cardData?.fees?.exitStorage || 0
                          )} USD`}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {showAmountPaid ? (
                <div className="rItemList">
                  <div className="containerGreenIcon">
                    <IconGreenThunder />
                  </div>
                  <span>${formatCost(cardData?.fees?.exitStorage)} USD</span>
                </div>
              ) : (
                <div className="rItemList">${formatCost(cardData?.fees?.exitStorage)} USD</div>
              )}
            </div>
          )}

          {!!cardData?.fees?.basicConfiguration && (
            <div className="itemListContainer">
              <div className="lItemList">Configuración básica</div>
              {showAmountPaid ? (
                <div className="rItemList">
                  <div className="containerGreenIcon">
                    <IconGreenThunder />
                  </div>
                  <span>${formatCost(cardData?.fees?.basicConfiguration, 2)} USD</span>
                </div>
              ) : (
                <div className="rItemList">${formatCost(cardData?.fees?.basicConfiguration, 2)} USD</div>
              )}
            </div>
          )}
          {!!cardData?.fees?.wiping && (
            <div className="itemListContainer">
              <div className="lItemList">Wiping</div>
              {showAmountPaid ? (
                <div className="rItemList">
                  <div className="containerGreenIcon">
                    <IconGreenThunder />
                  </div>
                  <span>${formatCost(cardData?.fees?.wiping, 2)} USD</span>
                </div>
              ) : (
                <div className="rItemList">${formatCost(cardData?.fees?.wiping, 2)} USD</div>
              )}
            </div>
          )}
          <div className="itemListContainer">
            <div className="lItemList" style={{ color: '#383838' }}>
              Subtotal del equipo
            </div>
            <div className="rItemList" style={{ fontSize: '12px' }}>
              ${formatCost(cardData?.toolSubtotal, 2)} USD
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardQuoteTool;
