import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_7036_155287)">
      <path
        d="M1.09678 11.7353C1.27293 13.3818 2.59716 14.706 4.24283 14.8895C6.77059 15.1712 9.22898 15.1712 11.7567 14.8895C13.4024 14.706 14.7267 13.3818 14.9028 11.7353C15.0328 10.5198 15.1426 9.27201 15.1426 7.99983C15.1426 6.72765 15.0328 5.47982 14.9028 4.26437C14.7267 2.61792 13.4024 1.29368 11.7567 1.11024C9.22898 0.828487 6.77059 0.828487 4.24283 1.11024C2.59716 1.29368 1.27293 2.61792 1.09678 4.26437C0.966743 5.47982 0.856934 6.72765 0.856934 7.99983C0.856934 9.27201 0.966744 10.5198 1.09678 11.7353Z"
        fill="#CCCCCC"
        stroke="white"
      />
      <path d="M8 5.14307V10.8574" stroke="white" strokeLinecap="round" />
      <path d="M10.8574 8H5.14307" stroke="white" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_7036_155287">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const GrayAddIcon = memo(ForwardRef);
export default GrayAddIcon;
