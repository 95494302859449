import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M7.851 10.617a1 1 0 0 1-1.702 0L1.01 2.274A1 1 0 0 1 1.862.75h10.276a1 1 0 0 1 .851 1.524l-5.138 8.343Z"
      fill="#363636"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const PopoverArrow = memo(ForwardRef);
export default PopoverArrow;
