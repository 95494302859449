import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NudosButton, NudosDownloadDocumentButton } from '../../../../../../components/NudosComponents';
import { getOrderRequiredDataByPaymentMethod } from '../../../../../../utils/productDefinitions';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../utils/displayNudosStandardNotifications';
import { regenerateInvoice } from '../../../../../../services/orders';
import IconRegenerateInvoice from '../../../../../../assets/DesignSystem/SVGComponents/IconRegenerateInvoice';
import { GenericLoader } from '../../../../dashboard/components';
import { BILLING_METHODS, IorderDetails } from '../../../../../../types/orders';
import { isValidLink } from '../../../../../../utils/formValidations';
import { requestOrderSummaryPDF } from '../../../../../../services/checkout';
import { DownloadIcon } from '../../../../../../assets/images/components/NudosComponents';
import './OrderPaymentDocuments.scss';

const OrderPaymentDocuments = ({
  orderData,
  getOrderDatawithoutLoader
}: {
  orderData?: IorderDetails;
  getOrderDatawithoutLoader?: () => void;
}) => {
  const { t } = useTranslation();

  const [loadingRegenerateInvoice, setLoadingRegenerateInvoice] = useState<boolean>(false);
  const [quotationPdfUrl, setQuotationPdfUrl] = useState('');
  const [loadingQuotation, setLoadingQuotation] = useState(false);

  const { orderId, invoiceUrl, paymentMethod, proofOfPaymentUrl, billingMethod } = orderData || {};
  const theBillingCountryIsMexico = billingMethod?.billingMethodName === BILLING_METHODS.LOCAL_SALE_MEXICO;

  const i18nLanguageKey = 'nodi:orderDetails:orderPaymentDetails:';
  const requiresProofOfPayment = getOrderRequiredDataByPaymentMethod(
    paymentMethod || '',
    billingMethod?.billingMethodName as BILLING_METHODS
  );
  const validLinkProofOfPayment = isValidLink(proofOfPaymentUrl || '') ? proofOfPaymentUrl : undefined;

  const downloadQuotation = async () => {
    if (quotationPdfUrl) window.open(quotationPdfUrl, 'blank');
    if (!orderId) return;
    setLoadingQuotation(true);
    try {
      const { url } = await requestOrderSummaryPDF(orderId);
      if (url) {
        setQuotationPdfUrl(url);
        window.open(url, 'blank');
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingQuotation(false);
    }
  };

  const regenerateNewInvoice = async () => {
    if (orderId) {
      setLoadingRegenerateInvoice(true);
      try {
        await regenerateInvoice(+orderId);
        getOrderDatawithoutLoader && getOrderDatawithoutLoader();
        displaySuccessNotification({
          customJSXMessage: (
            <>
              La factura fue generada con éxito, puedes descargarla <br />
              nuevamente.
            </>
          )
        });
      } catch (error) {
        displayErrorNotification();
      } finally {
        setLoadingRegenerateInvoice(false);
      }
    }
  };

  return (
    <div className="orderPaymentDocuments">
      <div className="paymentInfoTitle paymentDocumentTitle">{t(`${i18nLanguageKey}paymentDocumentTitle`)}</div>
      <div className="documentsContainer">
        {requiresProofOfPayment && (
          <NudosDownloadDocumentButton
            width={82}
            height={18}
            buttonText={t(`${i18nLanguageKey}receipt`)}
            downloadLink={validLinkProofOfPayment || undefined}
          />
        )}
        <NudosButton
          buttonText={t(`${i18nLanguageKey}orderSummary`)}
          buttonIcon={<DownloadIcon className="downloadIcon" />}
          customClassName="requestQuotationButton"
          handleClick={downloadQuotation}
          isButtonLoading={loadingQuotation}
          isButtonDisabled={loadingQuotation || !orderId}
        />
        <NudosDownloadDocumentButton
          width={56}
          height={18}
          buttonText={t(`${i18nLanguageKey}invoice`)}
          downloadLink={!loadingRegenerateInvoice ? invoiceUrl || undefined : undefined}
        />

        {invoiceUrl && theBillingCountryIsMexico && (
          <div className="containerRegenerateInvoice">
            {loadingRegenerateInvoice && (
              <div className="containerMinLoading">
                <GenericLoader sizeLoader={10} borderWidth={1} />
              </div>
            )}
            {!loadingRegenerateInvoice && (
              <div className="boxRegenerate" onClick={() => regenerateNewInvoice()}>
                <div className="textRegenerate">volver a generar</div>
                <div className="regenerateIconContainer">
                  <IconRegenerateInvoice />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderPaymentDocuments;
