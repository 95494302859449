import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none" ref={ref} {...props}>
    <path
      fill="#fff"
      stroke="#383838"
      d="M20.825 13.988a.384.384 0 0 1-.083-.239v-2.492c0-.086.03-.17.083-.238l1.15-1.44a1.648 1.648 0 0 0 .139-1.853l-.67-1.16a1.648 1.648 0 0 0-1.674-.807l-1.82.278a.395.395 0 0 1-.25-.048L15.54 4.743a.392.392 0 0 1-.165-.199l-.672-1.708a1.645 1.645 0 0 0-1.53-1.047H11.83a1.645 1.645 0 0 0-1.531 1.047l-.675 1.708a.392.392 0 0 1-.165.193L7.296 5.989a.393.393 0 0 1-.25.048L5.23 5.759a1.647 1.647 0 0 0-1.673.807l-.67 1.16a1.647 1.647 0 0 0 .139 1.852l1.149 1.44a.383.383 0 0 1 .083.24v2.491c0 .087-.03.171-.083.239l-1.15 1.44a1.647 1.647 0 0 0-.139 1.852l.67 1.16a1.646 1.646 0 0 0 1.674.807l1.821-.277a.394.394 0 0 1 .25.048l2.158 1.246a.39.39 0 0 1 .165.192l.672 1.715a1.646 1.646 0 0 0 1.53 1.047h1.341a1.645 1.645 0 0 0 1.531-1.047l.672-1.715a.391.391 0 0 1 .165-.192l2.159-1.246a.396.396 0 0 1 .249-.048l1.821.277a1.646 1.646 0 0 0 1.673-.806l.67-1.16a1.646 1.646 0 0 0-.139-1.853l-1.143-1.44Zm-8.325 1.96a3.444 3.444 0 1 1 0-6.89 3.444 3.444 0 0 1 0 6.89Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconAccountGear = memo(ForwardRef);
export default IconAccountGear;
