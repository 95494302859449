import { useCallback, useEffect, useState } from 'react';
import { NudosButton, NudosSelectButton } from '../../../../../components/NudosComponents';
import { ProductDescription } from '../../../../../components/uploadExternalProducts';
import { IUploadCountries, IuploadedItemData } from '../../../../../types/uploadExternalTools.types';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { segmentTrackEvent } from '../../../../../utils/segment';
import ProductItemDataForm from '../ProductItemDataForm';
import { servicesDefaultLocationAddress } from '../../../../../services/account';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { IListDefaultLocationAddress } from '../../../../../types/account';
import { useTranslation } from 'react-i18next';
import useLoadTools from '../../../../../state/useStateSuccessLoadTools';
import './Step2AddInformation.scss';

export interface Istep2SelectReferences {
  countriesData?: IUploadCountries[];
}

const Step2AddInformation = (props: Istep2SelectReferences) => {
  const { t } = useTranslation();
  const { selectedReferences, setSelectedReferences, setCurrentStep } = useLoadTools();
  const { countriesData } = props;
  const [sharedDataForToolReferenceItems, setSharedDataForToolReferenceItems] = useState<
    IsharedDataForToolReferenceItems[]
  >([]);
  const [repeatedSerials, setRepeatedSerials] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [listDefaultAddress, setListDefaultAddress] = useState<IListDefaultLocationAddress>();
  const orInfo = formatOrgData();
  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:step2AddInformation:';

  const updateItemData = (referenceSku: string, itemPosition: number, updatedItemData: IuploadedItemData) => {
    const newSelectedReferences = [...selectedReferences];
    const searchedReferenceIndex = newSelectedReferences.findIndex(reference => reference.sku === referenceSku);
    if (searchedReferenceIndex < 0) return;
    newSelectedReferences[searchedReferenceIndex].individualToolsData[itemPosition] = updatedItemData;
    setSelectedReferences(newSelectedReferences);
  };

  const isButtonDisabled = !selectedReferences.every(reference => {
    return reference.individualToolsData.every(item => {
      // 2 is "Other addresses", 3 is "Employee"
      const hasRequiredDestinationData = [2, 3].includes(item?.optionId)
        ? !!item?.assignedEmployee || !!item?.otherAddressDetail
        : true;
      const hasSerialIfRequired = reference?.category === 'Accesorios' ? true : !!item?.serial; // accessories don't require serial
      const hasCompleteData = !!item?.productCondition && !!item?.country && !!item?.optionId;
      return hasRequiredDestinationData && hasSerialIfRequired && hasCompleteData;
    });
  });

  const getRepeatedSerials = useCallback(() => {
    const newlyEvaluatedRepeatedSerials = selectedReferences.reduce<{
      evaluatedSerials: string[];
      repeatedSerials: string[];
    }>(
      (prev, reference) => {
        const newData = { ...prev };
        for (let i = 0; i < reference.individualToolsData.length; i++) {
          const currentSerial = reference.individualToolsData[i].serial;
          const alreadyInRepeatedSerials = newData.repeatedSerials.includes(currentSerial);
          if (!alreadyInRepeatedSerials) {
            const hasBeenFoundBefore = newData.evaluatedSerials.includes(currentSerial);
            (!hasBeenFoundBefore || newData.evaluatedSerials.length === 0) &&
              newData.evaluatedSerials.push(currentSerial);
            hasBeenFoundBefore && newData.repeatedSerials.push(currentSerial);
          }
        }
        return newData;
      },
      { evaluatedSerials: [], repeatedSerials: [] }
    ).repeatedSerials;
    setRepeatedSerials(newlyEvaluatedRepeatedSerials);
    return newlyEvaluatedRepeatedSerials;
  }, [selectedReferences]);

  const handleClickContinue = () => {
    setLoading(true);
    const repeatedSerials = getRepeatedSerials();
    const hasRepeatedSerials = repeatedSerials.length > 0 && repeatedSerials.every(serial => !!serial);
    setLoading(false);
    if (hasRepeatedSerials) {
      return displayErrorNotification({
        customJSXMessage: (
          <>Tienes algunos seriales repetidos, asegúrate de que cada serial en rojo sea único y vuelve a hacer click</>
        )
      });
    }
    setCurrentStep(2);
  };

  const getNewSharedDataForReference = (sku: string) => {
    const searchedReference = selectedReferences.find(reference => reference?.sku === sku);
    if (
      !searchedReference?.sku ||
      !searchedReference?.individualToolsData[0]?.productCondition ||
      !searchedReference?.individualToolsData[0]?.country ||
      !searchedReference.individualToolsData[0]?.optionId
    )
      return;
    const commonData = {
      sku: searchedReference?.sku,
      condition: searchedReference?.individualToolsData[0]?.productCondition,
      country: searchedReference?.individualToolsData[0]?.country,
      optionId: searchedReference.individualToolsData[0]?.optionId
    };
    return commonData;
  };

  const handleCheckSharedDataForToolReferenceItems = (sku: string) => {
    const commonData = getNewSharedDataForReference(sku);
    if (!commonData) return;
    const updatedSharedDataForToolReferenceItems = [...sharedDataForToolReferenceItems];
    updatedSharedDataForToolReferenceItems.push(commonData);
    setSharedDataForToolReferenceItems(updatedSharedDataForToolReferenceItems);
  };

  const handleUncheckSharedDataForToolReferenceItems = (sku: string) => {
    const searchedSkuIndex = sharedDataForToolReferenceItems.findIndex(
      dateAndConditionObject => dateAndConditionObject.sku === sku
    );
    if (searchedSkuIndex < 0) return;
    const updatedSharedDataForToolReferenceItems = [...sharedDataForToolReferenceItems];
    updatedSharedDataForToolReferenceItems.splice(searchedSkuIndex, 1);
    setSharedDataForToolReferenceItems(updatedSharedDataForToolReferenceItems);
  };

  const handleClickSharedDataForToolReferenceItems = (sku: string, isChecked: boolean) => {
    if (!isChecked) handleCheckSharedDataForToolReferenceItems(sku);
    if (isChecked) handleUncheckSharedDataForToolReferenceItems(sku);
  };

  const getDefaultAddress = async () => {
    try {
      const dataDefaultAddress = await servicesDefaultLocationAddress(Number(orInfo?.organizationId));
      setListDefaultAddress({ ...dataDefaultAddress });
    } catch {
      displayErrorNotification();
    }
  };

  const refreshAddress = async () => {
    await getDefaultAddress();
  };

  useEffect(() => {
    segmentTrackEvent({ toolsUploadInformationView: null });
    getDefaultAddress();
  }, []);

  return (
    <div className="step2AddInformation">
      <section className="itemsDataFormSection">
        {selectedReferences.map((reference, i) => {
          const shareDataForThisReference = sharedDataForToolReferenceItems.find(
            referenceWithSameDate => referenceWithSameDate.sku === reference.sku
          );
          const isSelectButtonChecked = !!shareDataForThisReference;
          const disabledSharedDataStyles = !getNewSharedDataForReference(reference.sku) ? 'disabled' : '';
          return (
            <div key={`reference-container-${i}-${reference.sku}`} className="productReferenceContainer">
              <ProductDescription name={reference.name} pills={reference.pills} sku={reference.sku}>
                {reference.individualToolsData.length > 1 && (
                  <div
                    className="applySameDateAndState"
                    onClick={() => handleClickSharedDataForToolReferenceItems(reference.sku, isSelectButtonChecked)}
                  >
                    <NudosSelectButton
                      isButtonActive={isSelectButtonChecked}
                      isButtonDisabled={!getNewSharedDataForReference(reference.sku)}
                    />
                    <div className={`instructionsText ${disabledSharedDataStyles}`}>
                      Usar en todas las unidades los mismos datos de <strong>estado y país</strong>
                    </div>
                  </div>
                )}
              </ProductDescription>
              {reference.individualToolsData.map((item, i) => {
                const handleUpdateThisItemData = (updatedItemData: IuploadedItemData) =>
                  updateItemData(reference.sku, i, updatedItemData);
                const itemFormIdentifier = `reference-${reference.sku}-item-${i}`;
                return (
                  <div key={itemFormIdentifier} className="itemContainer">
                    <ProductItemDataForm
                      handleUpdateThisItemData={handleUpdateThisItemData}
                      previousItemData={item}
                      sharedData={shareDataForThisReference}
                      sharedDataForToolReferenceItemsIsChecked={isSelectButtonChecked}
                      uncheckSharedDataForToolReferenceItems={() =>
                        handleUncheckSharedDataForToolReferenceItems(reference.sku)
                      }
                      serialIsRepeatedMessage={
                        item.serial && repeatedSerials?.includes(item.serial)
                          ? 'Verifica que este serial no se repita'
                          : undefined
                      }
                      countriesData={countriesData}
                      listDefaultAddress={listDefaultAddress}
                      refreshAddress={refreshAddress}
                      index={i}
                      itemFormIdentifier={itemFormIdentifier}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </section>
      <NudosButton
        buttonText={t(`${i18nLanguageKey}nudosButton`)}
        componentSize="small"
        isButtonDisabled={isButtonDisabled}
        handleClick={handleClickContinue}
        isButtonLoading={loading}
      />
    </div>
  );
};

export default Step2AddInformation;

export interface IsharedDataForToolReferenceItems {
  sku: string;
  condition: string;
  country: IUploadCountries;
  optionId: number;
}
