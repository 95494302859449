import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="employeePhotoPlaceholder"
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx="24.001" cy="24" r="24" fill="#ECECEC" />
    <g clipPath="url(#clip0_4616_148339)">
      <path
        d="M26.8605 19.5L26.154 18.584C26.0133 18.4029 25.8332 18.2562 25.6274 18.155C25.4215 18.0538 25.1954 18.0008 24.966 18H23.034C22.8047 18.0008 22.5785 18.0538 22.3727 18.155C22.1669 18.2562 21.9868 18.4029 21.846 18.584L21.1395 19.5H26.8605Z"
        fill="#CCCCCC"
      />
      <path
        d="M24 27C25.1046 27 26 26.1046 26 25C26 23.8954 25.1046 23 24 23C22.8954 23 22 23.8954 22 25C22 26.1046 22.8954 27 24 27Z"
        fill="#CCCCCC"
      />
      <path
        d="M27.5 20.5H20.5C19.8372 20.5008 19.2018 20.7644 18.7331 21.2331C18.2644 21.7018 18.0008 22.3372 18 23V27.5C18.0008 28.1628 18.2644 28.7982 18.7331 29.2669C19.2018 29.7356 19.8372 29.9992 20.5 30H27.5C28.1628 29.9992 28.7982 29.7356 29.2669 29.2669C29.7356 28.7982 29.9992 28.1628 30 27.5V23C29.9992 22.3372 29.7356 21.7018 29.2669 21.2331C28.7982 20.7644 28.1628 20.5008 27.5 20.5ZM24 28C23.4067 28 22.8266 27.8241 22.3333 27.4944C21.8399 27.1648 21.4554 26.6962 21.2284 26.1481C21.0013 25.5999 20.9419 24.9967 21.0576 24.4147C21.1734 23.8328 21.4591 23.2982 21.8787 22.8787C22.2982 22.4591 22.8328 22.1734 23.4147 22.0576C23.9967 21.9419 24.5999 22.0013 25.1481 22.2284C25.6962 22.4554 26.1648 22.8399 26.4944 23.3333C26.8241 23.8266 27 24.4067 27 25C26.9992 25.7954 26.6829 26.558 26.1204 27.1204C25.558 27.6829 24.7954 27.9992 24 28Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_4616_148339">
        <rect width="12" height="12" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const EmployeePhotoPlaceholder = memo(ForwardRef);
export default EmployeePhotoPlaceholder;
