import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6451_3484)">
      <path
        d="M5.99993 4.0692C7.09707 4.0692 7.71422 3.45205 7.71422 2.35491C7.71422 1.25777 7.09707 0.640625 5.99993 0.640625C4.90279 0.640625 4.28564 1.25777 4.28564 2.35491C4.28564 3.45205 4.90279 4.0692 5.99993 4.0692Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.92878 11.3605C2.75164 11.3605 3.21449 10.8976 3.21449 10.0748C3.21449 9.25192 2.75164 8.78906 1.92878 8.78906C1.10592 8.78906 0.643066 9.25192 0.643066 10.0748C0.643066 10.8976 1.10592 11.3605 1.92878 11.3605Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00007 11.3605C6.82293 11.3605 7.28578 10.8976 7.28578 10.0748C7.28578 9.25192 6.82293 8.78906 6.00007 8.78906C5.17721 8.78906 4.71436 9.25192 4.71436 10.0748C4.71436 10.8976 5.17721 11.3605 6.00007 11.3605Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0714 11.3605C10.8942 11.3605 11.3571 10.8976 11.3571 10.0748C11.3571 9.25192 10.8942 8.78906 10.0714 8.78906C9.2485 8.78906 8.78564 9.25192 8.78564 10.0748C8.78564 10.8976 9.2485 11.3605 10.0714 11.3605Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.71436 8.78237V7.92522C1.71436 6.97845 2.48187 6.21094 3.42864 6.21094H8.5715C9.5183 6.21094 10.2858 6.97845 10.2858 7.92522V8.78237"
        stroke={props?.stroke || '#ECECEC'}
      />
      <path d="M6 4.07031V8.7846" stroke="#ECECEC" />
    </g>
    <defs>
      <clipPath id="clip0_6451_3484">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuAnalyticsIcon = memo(ForwardRef);
export default MenuAnalyticsIcon;
