import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import SimpleArrow from '../../../assets/images/components/simpleSelect/SimpleArrow';
import { v4 as uuid } from 'uuid';
import { Tany } from '../../../types/global';
import './nudosSimpleSelect.scss';

interface IElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: Tany;
}

interface ISelectData {
  value: number;
  name: string;
  active?: boolean;
}

interface ISimpleSelect {
  enabled?: boolean;
  selectList?: ISelectData[];
  defaultValue?: ISelectData;
  changeValue?: (item: ISelectData) => void;
  isGray?: boolean;
  customHeight?: number;
  myPlaceHolder?: string;
  addressOptions?: boolean;
}

const NudosSimpleSelect: FC<ISimpleSelect> = ({
  enabled,
  selectList,
  defaultValue,
  changeValue,
  isGray,
  customHeight,
  myPlaceHolder,
  addressOptions
}: ISimpleSelect) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        setOpen(false);
      }
    },
    [refContainer]
  );

  const handleChange = (item: ISelectData) => {
    if (changeValue) {
      changeValue(item);
    }
  };

  const generateItems = () => {
    return selectList?.map(item => {
      return (
        <Fragment key={uuid()}>
          {addressOptions ? (
            <div key={uuid()} className="itemSelectContainer">
              <div className="descriptionText" style={{ color: !item?.active ? '#CCCCCC' : '' }}>
                {item.name}
              </div>
              <div
                className="viewDetailText"
                onClick={() => {
                  handleChange(item);
                  setOpen(false);
                }}
              >
                Ver
              </div>
            </div>
          ) : (
            <div
              className="itemSelect"
              onClick={() => {
                handleChange(item);
                setOpen(false);
              }}
            >
              {item.name}
            </div>
          )}
        </Fragment>
      );
    });
  };

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div id="nudosSimpleSelect" style={{ height: customHeight ? `${customHeight}px` : '' }} ref={refContainer}>
      <div
        className="selectButton"
        style={{ border: !enabled || isGray ? '0.5px solid #CCCCCC' : '' }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="nameButton" style={{ color: !enabled || isGray ? '#CCCCCC' : '' }}>
          {defaultValue?.name || myPlaceHolder || 'Seleccionar'}
        </div>
        <SimpleArrow style={{ transform: open ? 'rotate(180deg)' : '' }} fill={!enabled || isGray ? '#CCCCCC' : ''} />
      </div>
      {enabled && open && selectList && selectList.length > 0 && (
        <div className="floatSelect">{selectList && selectList.length > 0 && generateItems()}</div>
      )}
    </div>
  );
};

export default NudosSimpleSelect;
