import { PAYMENT_TYPES } from '../types/checkout';
import { IdeliveryAddress, IreceiverInformation } from '../types/global';
import { IPhoneData, Iemployee } from '../types/global';
import { BILLING_METHODS } from '../types/orders';

export const supportUrl = 'https://nodi.crisp.help/es/';
export const countriesRequiringPersonalId = ['br'];
export const countriesRequiringTaxSituationDocument = ['co'];
export const validPersonalIdByCountry = (personalId: string, countryCode?: string | null) => {
  //CAMBIAR-JC: Si se agregan más países considerar agregar columnas en backend en organization.Country para los personalIds
  if (countryCode === 'br') {
    const brazileanDocumentRegex = /^(\d{3}\.){2}\d{3}-\d{2}$/g;
    return brazileanDocumentRegex.test(personalId);
  }
  return true;
};

export const validPersonalIdExampleByCountry = (countryCode?: string | null) => {
  //CAMBIAR-JC: Si se agregan más países considerar agregar columnas en backend en organization.Country para los personalIds
  if (countryCode === 'br') return '000.000.000-00';
  return;
};

export const businessNameRegex = /^.*[a-zñÑA-Z]{2}.*$/; // Por definición de Sebastián, al menos 2 letras es lo que exige el SAT https://nudos.slack.com/archives/C03G1CYMPB6/p1700087243501429?thread_ts=1700069229.253139&cid=C03G1CYMPB6

export const zipCodeLengthLimit = 10;
export const invalidZipCodeText = 'Código inválido';
export const invalidBusinessName = 'El nombre no es válido';
export const formatZipCode = (rawZipCode?: string | null) => {
  if (!rawZipCode) return '';
  return rawZipCode.trim().replaceAll(' ', '');
};
export const getIsInvalidZipCode = (zipCode?: string | null) => zipCode && zipCode.length > zipCodeLengthLimit;
export const getIsInvalidBusinessName = (businessName?: string | null) =>
  !!businessName && (businessName.length < 2 || !businessNameRegex.test(businessName));

/**
 * @param phoneData - The phone data of the employee
 * @returns a boolean indicating if the phone data is complete according to Nudos definition
 */
export const isPhoneDataComplete = (phoneData: IPhoneData, validateNumberFormat: boolean) => {
  const isNumberRegex = /^[0-9]*$/g;
  const isPhoneANumber = isNumberRegex.test(phoneData?.phone || '');
  const phoneNumberIsLargerThan5Digits = phoneData?.phone && phoneData?.phone.length > 5;
  return (
    !!phoneData?.phone &&
    !!phoneData?.phoneCode &&
    (!validateNumberFormat || isPhoneANumber) &&
    phoneNumberIsLargerThan5Digits
  );
};

/**
 * @param address - The address data of the employee
 * @param requireAdditionalReferences - a boolean indicating if the address additional reference is a required field to consider the address data as complete. By DEFAULT is not required.
 * @returns a boolean indicating if the address data is complete according to Nudos definition
 */
export const isAddressDataComplete = (address: IdeliveryAddress, requireAdditionalReferences?: boolean) => {
  return (
    !!address?.city &&
    !!address?.address &&
    !!address?.zipCode &&
    (!!address?.additionalReferences || !requireAdditionalReferences) &&
    !!address?.coordinates
  );
};

/**
 * @param employee - The employee data object
 * @param excludeLocationIdFromValidation - a boolean indicating if the locationId of the user should be excluded from the validatio nto consider the employee data as complete. By DEFAULT is required.
 * @returns a boolean indicating if the employee data is complete according to Nudos definition
 */
export const isEmployeeDataComplete = (
  employee: Iemployee,
  excludeLocationIdFromValidation?: boolean,
  requiresCityId?: boolean
) => {
  const { address, phoneData, personalId, country, locationId, city, cityId } = employee;
  const requirePersonalId = countriesRequiringPersonalId.includes(country?.code || '');
  const requirePersonalIdAndDoesntHaveIt = requirePersonalId && !personalId;
  const invalidPersonalId = requirePersonalId && !validPersonalIdByCountry(personalId || '', country?.code || '');
  const requiresCityIdAndDoesntHaveIt = requiresCityId && !cityId;
  if (
    !city ||
    requiresCityIdAndDoesntHaveIt ||
    !phoneData ||
    !phoneData?.countryId ||
    !phoneData?.phone ||
    !address ||
    requirePersonalIdAndDoesntHaveIt ||
    invalidPersonalId ||
    (!locationId && !excludeLocationIdFromValidation)
  )
    return false;
  return isPhoneDataComplete(phoneData, true) && isAddressDataComplete(address, true);
};

/**
 * @param receiverInformation - The receiver data object
 * @param receiverCountryCode - The ISO two letter code for the country of the receiver, ej "mx"
 * @returns a boolean indicating if the receiver data is complete according to Nudos definition
 */
export const isReceiverDataComplete = (receiverInformation?: IreceiverInformation, receiverCountryCode?: string) => {
  if (!receiverInformation || !receiverCountryCode) return false;
  const completeReceiverPhone = isPhoneDataComplete(
    {
      phone: receiverInformation?.receiverPhone,
      phoneCode: receiverInformation?.receiverPhoneCountry?.phoneCode
    },
    true
  );
  const requirePersonalId = countriesRequiringPersonalId.includes(receiverCountryCode || '');
  const requirePersonalIdAndDoesntHaveIt = requirePersonalId && !receiverInformation?.receiverIdDocument;
  const invalidPersonalId =
    requirePersonalId &&
    !validPersonalIdByCountry(receiverInformation?.receiverIdDocument || '', receiverCountryCode || '');
  if (!receiverInformation?.receiverName || !completeReceiverPhone) return false;
  if (requirePersonalIdAndDoesntHaveIt || invalidPersonalId) return false;
  return true;
};

export const doesAddressHasTheRightFormat = (employeeAddress: IdeliveryAddress) => {
  return (
    !!employeeAddress &&
    !!employeeAddress?.address &&
    typeof employeeAddress?.address === 'string' &&
    !!employeeAddress?.country &&
    !!employeeAddress?.city
  );
};

export const lowerLimitForFreeShipping = 350;
export const costForPaidShipments = 25;
export const minimumShippingCost = 10;
export const logisticServiceInCourseAlertText =
  'No puedes realizar estos movimientos hasta que se complete el servicio logístico.';
export const disableToolManagementBeforeDeliveryAlertText =
  'No puedes realizar estos movimientos hasta que se complete el envío.';

export const additionalDaysForUnassignmentCollectionDate = 3;

export const methodsWithoutProofOfPayment = [PAYMENT_TYPES.TRANSFER_MXN, PAYMENT_TYPES.CARD_MXN];

export const methodsShowingLocalCurrency = [
  PAYMENT_TYPES.TRANSFER_MXN,
  PAYMENT_TYPES.CARD_MXN,
  PAYMENT_TYPES.CARD_COP,
  PAYMENT_TYPES.TRANSFER_COP,
  PAYMENT_TYPES.TRANSFER_ARS,
  PAYMENT_TYPES.TRANSFER_PEN
];

export const getOrderRequiredDataByPaymentMethod = (paymentMethod: string, billingMethod: BILLING_METHODS) => {
  const { LOCAL_SALE_MEXICO, INTERNATIONAL_SALE, LOCAL_SALE_USA, INTERNATIONAL_SALE_MEXICO } = BILLING_METHODS;

  const mexicanInternationalCard =
    [LOCAL_SALE_MEXICO, INTERNATIONAL_SALE_MEXICO].includes(billingMethod) &&
    paymentMethod === PAYMENT_TYPES.INTERNATIONAL_CARD;
  const colombianCard = paymentMethod === PAYMENT_TYPES.CARD_COP;
  const requiresPaymentLink = mexicanInternationalCard || colombianCard;

  const methodDoesntRequireProof = methodsWithoutProofOfPayment.includes(paymentMethod as PAYMENT_TYPES);
  const billingMethodDoesntRequireProof = [INTERNATIONAL_SALE, LOCAL_SALE_USA].includes(billingMethod);
  const exemptFromProofOfPayment = methodDoesntRequireProof || billingMethodDoesntRequireProof;

  return {
    requiresProofOfPayment: !exemptFromProofOfPayment,
    requiresPaymentLink,
    showLocalCurrency: methodsShowingLocalCurrency.includes(paymentMethod as PAYMENT_TYPES),
    requiresBankData: !paymentMethod?.includes('Tarjeta')
  };
};

export const FREE_TOOLS_TO_UPLOAD_LIMIT = 70;
