import { Link } from 'react-router-dom';
import { greyShoppingCartIcon } from '../../assets/images/components';
import './ZeroStateView.scss';

interface IzeroStateProps {
  greyText: string;
  orangeText: string;
  redirectionPath: string;
  paddingTop?: number;
}

/**
 * @property {string} greyText - Required, the grey text
 * @property {string} orangeText - Required, the orange text that acts as a link to the desired path, usually the catalogue
 * @property {string} redirectionPath - Required, the path to which the user will be redirected upon clicking the orange text
 * @property {number} paddingTop - Optional, the padding top in px, default is 150 (150px)
 */
const ZeroStateView = ({ greyText, orangeText, redirectionPath, paddingTop }: IzeroStateProps) => {
  const customStyles = { paddingTop: typeof paddingTop === 'number' ? `${paddingTop}px` : '150px' };

  return (
    <div className="zeroStateView" style={customStyles}>
      <div className="iconContainer">
        <img src={`${greyShoppingCartIcon}`} />
      </div>
      <p>
        {greyText}
        <Link to={redirectionPath} className="linkText">
          {orangeText}
        </Link>
      </p>
    </div>
  );
};

export default ZeroStateView;
