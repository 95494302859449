import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_8559_202062)">
      <path
        d="M8.00028 15.1428C11.9452 15.1428 15.1431 11.9449 15.1431 7.99997C15.1431 4.05508 11.9452 0.857117 8.00028 0.857117C4.05539 0.857117 0.857422 4.05508 0.857422 7.99997C0.857422 11.9449 4.05539 15.1428 8.00028 15.1428Z"
        fill="#81BC3F"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14258 8.71431L7.2205 10.8572C8.19829 8.04839 9.01261 6.81587 10.8569 5.14288"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8559_202062">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SucessIcon = memo(ForwardRef);
export default SucessIcon;
