import { useEffect, useState } from 'react';
import { NudosDownloadDocumentButton } from '../../../../../../components/NudosComponents';
import { TstoragePlace } from '../../../../../../types/assignationFluxes';
import { IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import { useTranslation } from 'react-i18next';
import './LogisticServiceDocumentsSection.scss';

interface IShowDocuments {
  showReceiptDocumentUrl?: boolean;
  showDeliveryDocumentUrl?: boolean;
  showDocumentUrl?: boolean;
}

const LogisticServiceDocumentsSection = ({
  logisticServiceData,
  isLoading = false
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();
  const {
    documentUrl,
    receiptDocumentUrl,
    deliveryDocumentUrl,
    collection,
    delivery,
    isPaymentReceived,
    proofOfPaymentUrl,
    quoteDocumentUrl
  } = logisticServiceData || {};
  const [showDocuments, setShowDocuments] = useState<IShowDocuments>();

  const translationKey = 'nodi:logisticServices:logisticServiceDocumentsSection:';
  const validPayment = isPaymentReceived || proofOfPaymentUrl;
  const sectionTitle = t(`${translationKey}sectionTitle`);
  const quoteText = t(`${translationKey}quoteText`);
  const deliveryText = t(`${translationKey}deliveryText`);
  const receiptText = t(`${translationKey}receiptText`);
  const deviceStatusText = t(`${translationKey}deviceStatusText`);

  const valdiationShowDocuments = () => {
    const collectionPlace: TstoragePlace = collection?.place as TstoragePlace;
    const deliveryPlace: TstoragePlace = delivery?.place as TstoragePlace;

    if (collectionPlace === 'nudos') {
      if (deliveryPlace === 'office' || deliveryPlace === 'user') {
        setShowDocuments({ showReceiptDocumentUrl: true });
      }
    }
    if (collectionPlace === 'office' || collectionPlace === 'other' || collectionPlace === 'user') {
      if (deliveryPlace === 'nudos') {
        setShowDocuments({ showDeliveryDocumentUrl: true, showDocumentUrl: true });
      }
      if (deliveryPlace === 'office' || deliveryPlace === 'user') {
        setShowDocuments({ showDeliveryDocumentUrl: true, showReceiptDocumentUrl: true });
      }
      if (deliveryPlace === 'warranty' || deliveryPlace === 'diagnostic') {
        setShowDocuments({ showDeliveryDocumentUrl: true });
      }
    }

    if (collectionPlace === 'warranty' || collectionPlace === 'diagnostic') {
      if (deliveryPlace === 'office' || deliveryPlace === 'user') {
        setShowDocuments({ showReceiptDocumentUrl: true });
      }
    }
  };

  useEffect(() => {
    valdiationShowDocuments();
  }, [logisticServiceData]);

  if (isLoading || !logisticServiceData) return <div className="logisticServiceDocumentsSection animationLoader" />;
  return (
    <div className="logisticServiceDocumentsSection">
      <div className="sectionTitle" style={{ width: validPayment ? '58px' : '' }}>
        {sectionTitle}
      </div>
      <div className="sectionContents">
        <NudosDownloadDocumentButton
          width={76}
          height={18}
          buttonText={quoteText}
          downloadLink={quoteDocumentUrl || undefined}
        />
        {showDocuments?.showDeliveryDocumentUrl && (
          <NudosDownloadDocumentButton buttonText={deliveryText} downloadLink={deliveryDocumentUrl || ''} height={18} />
        )}
        {showDocuments?.showReceiptDocumentUrl && (
          <NudosDownloadDocumentButton buttonText={receiptText} downloadLink={receiptDocumentUrl || ''} height={18} />
        )}
        {showDocuments?.showDocumentUrl && (
          <NudosDownloadDocumentButton buttonText={deviceStatusText} downloadLink={documentUrl || ''} height={18} />
        )}
      </div>
    </div>
  );
};

export default LogisticServiceDocumentsSection;
