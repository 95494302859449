import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12} fill="none" ref={ref} {...props}>
    <g clipPath="url(#clip0_11570_3519)">
      <path d="M9.25781 4.125H11.2578V10.625H1.25781V4.125H3.25781" fill="white" />
      <path d="M1.25781 4.125L6.25781 7.875L11.2578 4.125" fill="white" />
      <path
        d="M9.25781 4.125H11.2578M11.2578 4.125V10.625H1.25781V4.125M11.2578 4.125L6.25781 7.875L1.25781 4.125M1.25781 4.125H3.25781"
        stroke="#CCCCCC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25781 4.66992V1.16992M6.25781 1.16992L7.75781 2.66992M6.25781 1.16992L4.75781 2.66992"
        stroke="#CCCCCC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11570_3519">
        <rect width="12" height="12" fill="white" transform="translate(0.257812)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconSendEmail = memo(ForwardRef);
export default IconSendEmail;
