import { useTranslation } from 'react-i18next';
import {
  IconNudosFace,
  IconQuestion,
  IconReserveBox,
  IconShield,
  IconToolWrench
} from '../../../assets/DesignSystem/SVGComponents';
import { warehouseMap } from '../../../assets/images/views/RequestLogistics';
import { TstoragePlace } from '../../../types/assignationFluxes';
import { IwarehouseData, TlogisticsUserHeaderType } from '../../../types/requestLogisticsModule';
import NudosLogisticServiceUserHeader from '../NudosLogisticServiceUserHeader/NudosLogisticServiceUserHeader';
import './NudosLogisticsWarehouseCard.scss';

/**
 * @property { TlogisticsUserHeaderType } type -The type of service card, indicating if its origin or destination, or a customized card
 * @property { IwarehouseData } warehouseData - The data of the selected warehouse
 * @property { TstoragePlace } selectedOriginOrDestination - The selected destination, it would be used to determined the icon and text to show according to the purpose of the logistics to the warehouse (warranty, diagnostics or just storage)
 */
const NudosLogisticsWarehouseCard = ({
  type,
  warehouseData,
  selectedOriginOrDestination
}: {
  type: TlogisticsUserHeaderType;
  warehouseData?: IwarehouseData;
  selectedOriginOrDestination?: TstoragePlace;
}) => {
  const { t } = useTranslation();
  const warehouseCountryData = warehouseData?.countryData || warehouseData?.country;

  const getCardInformation = () => {
    switch (selectedOriginOrDestination) {
      case 'warranty':
        return { cardName: 'Marca', icon: <IconShield className="iconShield" /> };
      case 'diagnostic':
        return { cardName: 'Diagnóstico (Bodegas Nudos)', icon: <IconToolWrench className="iconToolWrench" /> };
      case 'nudos':
        return {
          cardName: `${t('recurrentWords:warehouse')} ${warehouseCountryData?.name || ''}`,
          icon: <IconReserveBox className="iconReserveBox" />
        };
      default:
        return { cardName: '', icon: <IconQuestion className="iconQuestion" /> };
    }
  };
  const cardInformation = getCardInformation();
  const isWarranty = selectedOriginOrDestination === 'warranty';
  const mapTitleStyles = { left: isWarranty ? '141.25px' : '152.25px' };
  const mapTitle = isWarranty ? 'Gestión de garantía' : `${t('recurrentWords:warehouse')} Nudos`;
  const warehouseCardIcon = <div className="warehouseCardIcon">{cardInformation?.icon}</div>;

  return (
    <div className="NudosLogisticsWarehouseCard">
      <NudosLogisticServiceUserHeader
        name={cardInformation?.cardName}
        flagUrl={warehouseCountryData?.flag}
        type={type}
        photoJSX={warehouseCardIcon}
      />
      <div className="warehousesMap">
        <div className="mapTitle" style={mapTitleStyles}>
          <IconNudosFace className="iconNudosFace" />
          <div className="text">{mapTitle}</div>
        </div>
        <img src={warehouseMap} alt="Mapa bodegas" />
      </div>
    </div>
  );
};

export default NudosLogisticsWarehouseCard;
