import { useCallback, useEffect, useRef, useState } from 'react';
import { IElement } from '../../../types/global';
import NudosSearchBar from '../NudosSearchBar';
import { IconDropdownArrow } from '../../../assets/DesignSystem/SVGComponents';
import './NudosDropdownWithSearchbar.scss';

/**
 * IMPORTANT: if the styles of the implementation you are going to make of this component differs significantly from the design system options, DONT MODIFY THIS COMPONENT, use the customClassname, componentStyles, dropdownStyles, dropdownOptionsListStyles properties to customized it.
 * IMPORTANT: for the formDropdownOptionsCallback each of the returned options in the returned array should have the className "optionContainer" to inherit the default styles for the options UI.
 * IMPORTANT: for the formDropdownOptionsCallback each of the returned options should include as the onClick prop the logic to select the option, ie updated the current option with the newly selected value
 * @property {T[]} rawOptions : Optional, an array of all the possible values from which select. The type of the elements in the array must be the same for all, but can be anything required by the instance of this component. This type is the same one expected in the formDropdownOptionsCallback and filterBySearchStringCallback
 * @property { (rawOptions: T[], clickOptionCallback: (option: T) => void) => JSX.Element[] } formDropdownOptionsCallback : a function that maps the rawOptions array to an array of JSX.Element that will be displayed as the options for dropdown
 * @property { (search: string, rawOptions: T[]) => T[]; } filterBySearchStringCallback : a function that implements the filtering logic using the search string of the dropdown. Must return an array of elements with the same type used in the elements of the rawOptions property, or an empty array if no results are found.
 * @property { JSX.Element} currentValue : Required, the current selected value, it is this value that updates upon clicking on an option in the dropdown. Must be of the same type used for the elements of the rawOptions array
 * @property {string} componentSize : Optional, one of the standard sizes for the components, corresponding to one of the following words: "extra-small", "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {string} customClassname : Optional, a string classname to provide customizing styles.
 * @property {string} customPlaceholder : Optional, the desired text when the dropdown is closed and no option has been selected yet. Default is the text "Elige una opción"
 * @property {boolean} isFilled : Optional, a boolean indicating if the dropdown component must have filled styles or not.
 * @property {string} label : Optional, a label for the select input.
 * @property {string} errorText : Optional, the text to be shown if theres an error with the component or its current selected value. If an error is passed the style of the component will change to reflect that.
 * @property {boolean} showSkeletonLoader - Optional,a  boolean indicating if the component is loading and therefore the skeleton loader is to be shown. Default is false.
 * @property {boolean} isDeactivated : Optional, boolean indicating if the dropdown input is deactivated. If a value is provided the input will behave as a read only field and styles will be accordingly.
 * @property {{[key:string]: string}} componentStyles : an object with valid styles to be used as the value for the style property of the parent component. Use it to customized the NudosDropdownWithSearchbar component.
 * @property {{[key:string]: string}} dropdownStyles : an object with valid styles to be used as the value for the style property of the open dropdown section. Use it to customized the NudosDropdownWithSearchbar component.
 * @property {{[key:string]: string}} dropdownOptionsListStyles : an object with valid styles to be used as the value for the style property of the dropdown options list. Use it to customized the NudosDropdownWithSearchbar component.
 * @property {boolean} closesOnClickOutside : Optional, a boolean indicating if the dropdown closes when clicked outside. If no value is pause the default is true, with the select value being updated to the default.
 * @property {boolean} closesOnChangeSelection : Optional, a boolean indicating if the dropdown closes when the selected option(s) change. If no value is pause the default is true.
 * @property {string} hideErrorText : Optional, if the dropdown is part of another component that shows an error text, used this boolean to prevent from repeating the error text.
 */
const NudosDropdownWithSearchbar = <T,>({
  rawOptions,
  formDropdownOptionsCallback,
  filterBySearchStringCallback,
  currentValue,
  componentSize,
  customClassname,
  customPlaceholder,
  isFilled,
  label,
  errorText,
  showSkeletonLoader = false,
  isDeactivated,
  componentStyles,
  dropdownStyles,
  dropdownOptionsListStyles,
  closesOnClickOutside = true,
  closesOnChangeSelection = true,
  hideErrorText,
  notFoundElement,
  addOutAction,
  customLabel
}: INudosDropdownWithSearchbar<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredRawOptions, setFilteredRawOptions] = useState<T[]>([]);
  const [dropdownSelectOptions, setDropdownSelectOptions] = useState<JSX.Element[]>([]);
  const [searchString, setSearchString] = useState('');

  const refContainer = useRef<HTMLDivElement>(null);

  const customClassnameStyle = customClassname ? customClassname : '';
  const componentSizeStyle = componentSize || '';
  const deactivatedStyles = isDeactivated ? 'deactivated' : '';
  const filledStyles = isFilled || isDropdownOpen ? 'filled' : '';
  const errorStyles = errorText && errorText.length > 0 ? 'error' : '';

  const openDropDown = () => !isDeactivated && setIsDropdownOpen(true);
  const closeDropDown = () => {
    setIsDropdownOpen(false);
    setSearchString('');
  };

  const closeDropdownOnSelection = () => closesOnChangeSelection && closeDropDown();

  const handleClickSelect = () => !isDropdownOpen && openDropDown();

  const currentValueUI = currentValue
    ? formDropdownOptionsCallback([currentValue], closeDropdownOnSelection)[0]
    : undefined;

  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        closesOnClickOutside && closeDropDown();
      }
    },
    [refContainer]
  );

  const getSkeletonLoader = () => {
    return (
      <div className={`nudosDropdownWithSearchbarSkeletonLoader ${componentSizeStyle || 'extraSmall'}`}>
        {label && <div className="componentLabel truncate">{label}</div>}
        <div className="componentDropdown">
          <div className="closedDropdownSkeletonLoader animationLoader" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  useEffect(() => {
    const newDropdownOptions = formDropdownOptionsCallback(filteredRawOptions, closeDropdownOnSelection);
    setDropdownSelectOptions(newDropdownOptions);
  }, [filteredRawOptions]);

  useEffect(() => {
    if (!searchString) return setFilteredRawOptions(rawOptions);
    const newFilteredOptions = filterBySearchStringCallback(searchString, rawOptions);
    setFilteredRawOptions(newFilteredOptions);
  }, [searchString, rawOptions]);

  return (
    <>
      {showSkeletonLoader && getSkeletonLoader()}
      {!showSkeletonLoader && (
        <div
          className={`nudosDropdownWithSearchbar ${customClassnameStyle} ${componentSizeStyle} ${deactivatedStyles} ${filledStyles} ${errorStyles}`}
        >
          {label && <div className={`componentLabel truncate ${customLabel}`}>{label}</div>}
          <div className="componentDropdown" onClick={handleClickSelect}>
            <div className="closedDropdown" style={componentStyles}>
              <div className="currentValueOrplaceholder">
                {currentValueUI || customPlaceholder || 'Elige una opción'}
              </div>
              {!isDropdownOpen && <IconDropdownArrow className="IconDropdownArrow expandArrow" />}
              {isDropdownOpen && <IconDropdownArrow className="IconDropdownArrow contractArrow" />}
            </div>
            {isDropdownOpen && !isDeactivated && (
              <div className="openDropdown" ref={refContainer} style={dropdownStyles}>
                <NudosSearchBar
                  handleChange={setSearchString}
                  placeholder={'Buscar'}
                  defaultValue={searchString}
                  isFilled={!!searchString}
                />
                <div className="dropdownOptionsList" style={dropdownOptionsListStyles}>
                  {dropdownSelectOptions}
                  {notFoundElement && dropdownSelectOptions.length === 0 && searchString && (
                    <div className="notFoundElements">
                      <div className="searchText"> {searchString}</div>
                      <div className="separation">-</div>
                      <div
                        className="addBlueText"
                        onClick={() => {
                          if (addOutAction) {
                            setIsDropdownOpen(false);
                            addOutAction(searchString);
                          }
                        }}
                      >
                        Agregar
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {errorText && !hideErrorText && errorText.length > 0 && <div className="errorText">{errorText}</div>}
        </div>
      )}
    </>
  );
};

export default NudosDropdownWithSearchbar;

export interface INudosDropdownWithSearchbar<T> {
  rawOptions: T[];
  formDropdownOptionsCallback: (rawOptions: T[], clickOptionCallback: (option: T) => void) => JSX.Element[];
  filterBySearchStringCallback: (search: string, rawOptions: T[]) => T[];
  currentValue?: T;
  componentSize?: 'extraSmall' | 'small' | 'medium' | 'large';
  customClassname?: string;
  customPlaceholder?: string;
  isFilled?: boolean;
  label?: string;
  errorText?: string;
  showSkeletonLoader?: boolean;
  isDeactivated?: boolean;
  componentStyles?: { [key: string]: string };
  dropdownStyles?: { [key: string]: string };
  dropdownOptionsListStyles?: { [key: string]: string };
  closesOnClickOutside?: boolean;
  closesOnChangeSelection?: boolean;
  hideErrorText?: boolean;
  notFoundElement?: boolean;
  addOutAction?: (e: string) => void;
  customLabel?: string;
}
