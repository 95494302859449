import create from 'zustand';
import { Iuser } from '../../../types/global';

export interface iState {
  step: number;
  phone?: string;
  newUser?: Iuser;
  email?: string;
  clearAll: () => void;
  setState: (state: iState) => void;
  setStep: (step: number) => void;
  setUser: (user: Iuser) => void;
  setPhone: (phone: string) => void;
  setEmail: (email: string) => void;
  addSteps: (n?: number) => void;
}

const useSignStore = create<iState>(set => ({
  step: 0,
  phone: undefined,
  newUser: undefined,
  email: undefined,
  clearAll: () => set({ step: 0, newUser: undefined }),
  setState: currentState => set(currentState),
  setStep: step => set({ step }),
  setUser: user => set({ newUser: user }),
  setPhone: phone => set({ phone }),
  setEmail: email => set({ email }),
  addSteps: (n = 1) => set(state => ({ step: state.step + n }))
}));

export default useSignStore;

export interface IsignUpSegmentData {
  organizationName: string;
  userEmail: string;
  userPhone: string;
  userName: string;
  userLastName: string;
}
