import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={33} fill="none" ref={ref} {...props}>
    <rect y="0.25" width="32" height="32" rx="16" fill="#FF664E" />
    <g clipPath="url(#clip0_10128_10889)">
      <path
        d="M16.0003 23.3912C19.9452 23.3912 23.1431 20.1932 23.1431 16.2483C23.1431 12.3034 19.9452 9.10547 16.0003 9.10547C12.0554 9.10547 8.85742 12.3034 8.85742 16.2483C8.85742 20.1932 12.0554 23.3912 16.0003 23.3912Z"
        fill="#FF664E"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9991 16.9124C17.3794 16.9124 18.4983 15.7935 18.4983 14.4132C18.4983 13.033 17.3794 11.9141 15.9991 11.9141C14.6188 11.9141 13.4999 13.033 13.4999 14.4132C13.4999 15.7935 14.6188 16.9124 15.9991 16.9124Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2326 22.0033C19.0478 22.8764 17.5837 23.3924 15.9991 23.3924C14.4145 23.3924 12.9503 22.8764 11.7656 22.0033C12.0016 21.1147 12.5119 20.3207 13.2271 19.7361C14.0094 19.0967 14.9887 18.7474 15.9991 18.7474C17.0095 18.7474 17.9888 19.0967 18.7711 19.7361C19.4863 20.3207 19.9966 21.1147 20.2326 22.0033Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10128_10889">
        <rect width="16" height="16" fill="white" transform="translate(8 8.25)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconOrangeEmployee = memo(ForwardRef);
export default IconOrangeEmployee;
