import { NudosGenericModal, NudosModalContent } from '../../../../../../components/NudosComponents';
import ModalShareInvoiceContent from './ModalShareInvoiceContent';

function ModalShareInvoice({
  emailShareInvoice,
  closeModalCallback,
  setEmailShareInvoice
}: {
  emailShareInvoice?: string;
  closeModalCallback: () => void;
  setEmailShareInvoice?: (email: string) => void;
}) {
  return (
    <div>
      <div className="ModalShareInvoice">
        <NudosGenericModal
          closeModalCallback={closeModalCallback}
          modalContent={
            <NudosModalContent
              hideNudosIcon
              excludeHeader
              customWidth="580px"
              customHeight="438px"
              toCloseModal={closeModalCallback}
              modalContentTitle="Revisa y confirma los datos de facturación"
              CustomModalComponent={
                <ModalShareInvoiceContent
                  emailShareInvoice={emailShareInvoice}
                  closeModalCallback={closeModalCallback}
                  setEmailShareInvoice={setEmailShareInvoice}
                />
              }
            />
          }
        />
      </div>
    </div>
  );
}

export default ModalShareInvoice;
