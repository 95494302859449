import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { successArrowLoad, SerialIcon } from '../../../../assets/images/views/Admin/successLoad';
import ContainerModules from '../../../../components/containerModules';
import { NudosButton } from '../../../../components/NudosComponents';
import useLoadTools from '../../../../state/useStateSuccessLoadTools';
import segment, { segmentTrackEvent } from '../../../../utils/segment';
import { greyAlertImage, greyEncircledXImage } from '../../../../assets/DesignSystem/images';
import CopyIcon from '../../../../assets/images/views/Ecommerce/CopyIcon';
import './UploadExternalToolsSummary.scss';

const UploadExternalToolsSummary = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { uploadedToolsData, selectedReferences, setSelectedReferences, setCurrentStep, setUploadedToolsData } =
    useLoadTools();
  const { totalRecords = 0, recordsWithErrors = 0, errors } = uploadedToolsData || {};

  const uploadedProductsNames = selectedReferences ? selectedReferences?.map(reference => reference.name) : undefined;
  const successfullyLoadedTools = totalRecords - recordsWithErrors;
  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:uploadToolSteps:summary:';
  const haveErrorsToShow = !!recordsWithErrors && errors && errors.length > 0;
  const allRecordsHaveErrors = totalRecords === recordsWithErrors;
  const failedSerials = errors?.map(error => error?.serial);
  const failedSerialsText = failedSerials?.length ? failedSerials.join('\n') : '';

  const getDisplayData = () => {
    if (allRecordsHaveErrors) {
      return {
        title: t(`${i18nLanguageKey}failureTitle`),
        icon: greyEncircledXImage,
        customStyles: 'failureStyles'
      };
    }
    if (recordsWithErrors) {
      return {
        title: t(`${i18nLanguageKey}partialSuccessTitle`),
        icon: greyAlertImage,
        customStyles: 'partialSuccessStyles'
      };
    }
    return {
      title: t(`${i18nLanguageKey}completeSuccessTitle`),
      icon: successArrowLoad,
      customStyles: 'successStyles'
    };
  };

  const { title, icon, customStyles } = getDisplayData();

  const copyFailedSerials = async () => await navigator.clipboard.writeText(failedSerialsText);

  const cleanUploadToolsState = () => {
    setSelectedReferences([]);
    setCurrentStep(0);
    setUploadedToolsData(undefined);
  };

  useEffect(() => {
    if (!uploadedToolsData) push('/nodi/dashboard');
    segmentTrackEvent({ toolsUploadConfirmationView: { ProductNamesUploaded: uploadedProductsNames || [] } });
    if (successfullyLoadedTools > 0) segment.sessionEndingEvent();

    return () => cleanUploadToolsState();
  }, []);

  return (
    <ContainerModules fullScreen>
      <div id="uploadExternalToolsSummary">
        <div className="centeringContainer">
          <div className={`summaryIcon ${customStyles}`}>
            <img src={icon} />
          </div>
          <div className="textSuccess">{title}</div>
          {!!totalRecords && <div className="totalRecords">{`${successfullyLoadedTools}/${totalRecords}`}</div>}
          {haveErrorsToShow && (
            <div className="failedUploadSerialsTitle">
              <div className="text">{t(`${i18nLanguageKey}serialsText`)}</div>
              <CopyIcon className="copyIcon" onClick={copyFailedSerials} />
            </div>
          )}
          {recordsWithErrors > 0 && errors && (
            <div className={`failedUploadSerialsSection ${customStyles}`}>
              {errors.map(error => {
                return (
                  <div className="serialContainer" key={uuid()}>
                    <div className="iconSerial">
                      <SerialIcon />
                    </div>
                    <div className="serialNumber">{error.serial}</div>
                  </div>
                );
              })}
            </div>
          )}
          <NudosButton
            componentSize="medium"
            handleClick={() => push('/nodi/tools')}
            buttonText={t(`${i18nLanguageKey}button`)}
          />
        </div>
      </div>
    </ContainerModules>
  );
};

export default UploadExternalToolsSummary;
