import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EditPencilIcon } from '../../../../../../assets/images/views/Admin/employees';
import { NudosButton, NudosToolTip } from '../../../../../../components/NudosComponents';
import { Iemployee } from '../../../../../../types/global';
import { truncateText } from '../../../../../../utils/truncateText';
import { doesAddressHasTheRightFormat } from '../../../../../../utils/productDefinitions';
import { useTranslation } from 'react-i18next';
import { NudosConfirmActionModal } from '../../../../../../components/DesignSystem';
import { LS_STATUS } from '../../../../../../types/logisticsServicesModule.types';
import './EmployeeInfoPopover.scss';

const EmployeeInfoPopover = ({ employee }: { employee: Iemployee }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [openNudosConfirmActionModal, setOpenNudosConfirmActionModal] = useState<boolean>(false);

  const { address, personalId, phoneData, toolsInTransit, userId, mail } = employee || {};
  const employeeInfoPopoverLenguage = 'nodi:employeeInternal:employeeInfoPopover:';
  const popOverPhoneTitle = t(`${employeeInfoPopoverLenguage}popOver:phone`);
  const truncateThreshold = 45;
  const addressExistInCorrectFormat = address ? doesAddressHasTheRightFormat(address) : false;
  const noAddressStyle = !addressExistInCorrectFormat ? 'noAddress' : '';
  const phoneCode = phoneData?.phoneCode || '';
  const phoneExistsInCorrectFormat = phoneData?.phone ? `${phoneCode} ${phoneData?.phone}` : '';

  const employeeAdditionalReferences = address?.additionalReferences;
  const employeeAddress = address?.address;
  const decisionModalText = t(`${employeeInfoPopoverLenguage}decisionModalText`);

  const lastActiveLogisticService = toolsInTransit?.length
    ? toolsInTransit.find(
        tool =>
          !!tool?.logisticServiceId &&
          ![LS_STATUS.CANCELLED, LS_STATUS.DELIVERED].includes(tool?.logisticStatus as LS_STATUS)
      )
    : undefined;
  const activeLogisticServiceId = lastActiveLogisticService?.logisticServiceId;
  const logisticDetailUrl = `/nodi/logistics-services/details/${activeLogisticServiceId}`;

  const handleClick = () => setIsPopoverOpen(!isPopoverOpen);

  const handleClickEditData = () => {
    if (activeLogisticServiceId) {
      setIsPopoverOpen(false);
      return setOpenNudosConfirmActionModal(true);
    }
    return push(`/nodi/employees/edit/${userId}`);
  };

  const nudosConfirmActionModalAction = () => {
    setOpenNudosConfirmActionModal(false);
    if (!activeLogisticServiceId) return;
    push(logisticDetailUrl);
  };

  return (
    <div className="employeeInfoPopover">
      {openNudosConfirmActionModal && activeLogisticServiceId && (
        <NudosConfirmActionModal
          modalText={decisionModalText}
          firstButtonText={t(`${employeeInfoPopoverLenguage}firstButtonText`)}
          secondButtonText={t(`${employeeInfoPopoverLenguage}secondButtonText`)}
          firstButtonAction={nudosConfirmActionModalAction}
          secondButtonAction={() => setOpenNudosConfirmActionModal(false)}
        />
      )}
      <NudosToolTip
        type="popover"
        clickIconHandler={handleClick}
        clickAwayCallback={() => setIsPopoverOpen(false)}
        shouldStayOpen={isPopoverOpen}
      >
        <div className="toolTip">{t(`${employeeInfoPopoverLenguage}toolTip`)}</div>
        {isPopoverOpen && (
          <div className="popOver">
            <div className="address infoSection includesHoverText">
              <div className="title">{t(`${employeeInfoPopoverLenguage}popOver:address`)}</div>
              <div className={`content ${noAddressStyle}`}>
                {addressExistInCorrectFormat && truncateText(employeeAddress || '', truncateThreshold)}
                {!addressExistInCorrectFormat && (
                  <div className="noAddressText">{t(`${employeeInfoPopoverLenguage}noAddressText`)}</div>
                )}
              </div>
              <div className="hoverText">{employeeAddress || t(`${employeeInfoPopoverLenguage}noAddressText`)}</div>
            </div>
            <div className={`additionalReferences infoSection includesHoverText`}>
              <div className="title">{t(`${employeeInfoPopoverLenguage}popOver:additionalReferences`)}</div>
              <div className="content">
                {addressExistInCorrectFormat &&
                  (truncateText(employeeAdditionalReferences || undefined, truncateThreshold) || '-')}
                {!addressExistInCorrectFormat && <div className="dash">-</div>}
              </div>
              <div className="hoverText">{employeeAdditionalReferences || 'Sin indicaciones registradas'}</div>
            </div>
            <div className={`personalId infoSection includesHoverText`}>
              <div className="title">ID</div>
              <div className="content">
                {personalId && truncateText(personalId, truncateThreshold)}
                {!personalId && <div className="dash">-</div>}
              </div>
              <div className="hoverText">{personalId || 'Sin ID registrado'}</div>
            </div>
            <div className={`phone infoSection includesHoverText`}>
              <div className="title">{popOverPhoneTitle}</div>
              <div className={`content ${!phoneExistsInCorrectFormat ? 'noPhone' : ''}`}>
                {phoneExistsInCorrectFormat ? (
                  `+${phoneExistsInCorrectFormat}`
                ) : (
                  <div className="noPhoneText">Sin teléfono registrado</div>
                )}
              </div>
              <div className="hoverText">
                {phoneExistsInCorrectFormat ? `+${phoneExistsInCorrectFormat}` : 'Sin teléfono registrado'}
              </div>
            </div>
            <div className="email infoSection includesHoverText">
              <div className="title">{t(`${employeeInfoPopoverLenguage}popOver:email`)}</div>
              <div className="content">{mail && truncateText(mail, truncateThreshold)}</div>
              {mail && <div className="hoverText">{mail}</div>}
            </div>
            <NudosButton
              customClassName="editTextButton"
              customWidth="128px"
              customHeight="18px"
              buttonText={t(`${employeeInfoPopoverLenguage}popOver:nudosButton`)}
              buttonIcon={<EditPencilIcon />}
              handleClick={handleClickEditData}
            />
          </div>
        )}
      </NudosToolTip>
    </div>
  );
};

export default EmployeeInfoPopover;
