import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} fill="none" ref={ref} {...props}>
    <g>
      <path
        className="path1"
        fill="#fff"
        d="M6.806 6.111c-.479-.39-1.128-.61-1.805-.61s-1.326.22-1.805.61c-1.173.96-.547 2.13-.2 3.263h4.01c.347-1.133.973-2.304-.2-3.263Z"
      />
      <path
        className="path2"
        fill="#fff"
        d="M6.806 6.111c-.479-.39-1.128-.61-1.805-.61s-1.326.22-1.805.61c-1.173.96-.547 2.13-.2 3.263h4.01c.347-1.133.973-2.304-.2-3.263ZM6.806 4.89c-.479.39-1.128.61-1.805.61s-1.326-.22-1.805-.61c-1.173-.96-.547-2.13-.2-3.263h4.01c.347 1.133.973 2.304-.2 3.263Z"
      />
      <path
        className="path3"
        fill="#999"
        d="M6.806 6.111 6.49 6.5l.316-.388Zm-3.61 0-.316-.387.316.387Zm-.2 3.263-.478.146.108.354h.37v-.5Zm4.01 0v.5h.37l.109-.354-.478-.146Zm-.2-4.484-.316-.388.316.388Zm-3.61 0-.316.387.316-.387Zm-.2-3.263v-.5a.5.5 0 0 0-.478.354l.478.146Zm4.01 0 .479-.146a.5.5 0 0 0-.478-.354v.5Zm.116 4.097c-.576-.471-1.34-.723-2.12-.723v1c.573 0 1.107.186 1.488.498l.632-.775Zm-2.12-.723c-.781 0-1.545.252-2.122.723l.633.775C3.893 6.187 4.428 6 5 6V5Zm-2.122.723c-.351.287-.59.609-.725.963a2.254 2.254 0 0 0-.127 1.038c.032.33.118.65.21.946.047.148.098.296.145.437.048.142.094.278.135.412l.956-.292c-.046-.15-.096-.298-.143-.44-.049-.144-.095-.28-.138-.416a3.722 3.722 0 0 1-.17-.742 1.26 1.26 0 0 1 .065-.584c.066-.17.19-.355.425-.547l-.633-.775Zm.116 4.15h4.01v-1h-4.01v1Zm4.489-.354c.04-.134.086-.27.134-.412.048-.14.098-.289.145-.437.092-.295.178-.617.21-.946.032-.333.01-.686-.126-1.038-.136-.354-.374-.676-.726-.963L6.49 6.5c.235.192.359.376.424.547.067.173.086.363.065.584a3.722 3.722 0 0 1-.17.742c-.042.135-.089.272-.137.416-.048.142-.098.29-.144.44l.957.292ZM6.49 4.502c-.381.312-.915.499-1.489.499v1c.78 0 1.545-.253 2.121-.724l-.632-.775ZM5 5.001c-.573 0-1.107-.187-1.488-.499l-.633.775C3.457 5.748 4.22 6 5.001 6V5Zm-1.488-.499c-.236-.192-.36-.376-.425-.547a1.26 1.26 0 0 1-.064-.584c.022-.225.083-.469.169-.742.043-.135.089-.272.138-.416.047-.142.097-.29.143-.44l-.956-.292c-.041.134-.087.27-.135.412-.047.14-.098.289-.144.437a4.688 4.688 0 0 0-.21.946c-.033.333-.01.686.126 1.038.136.354.374.676.725.963l.633-.775Zm-.517-2.375h4.01v-1h-4.01v1Zm3.532-.354c.046.15.096.298.144.44.048.144.095.28.137.416.086.273.148.517.17.742.02.221.002.411-.065.584-.065.17-.189.355-.424.547l.632.775c.352-.287.59-.609.726-.963.135-.352.158-.705.126-1.038a4.688 4.688 0 0 0-.21-.946c-.047-.148-.097-.296-.145-.437a15.395 15.395 0 0 1-.134-.412l-.957.292Z"
      />
      <path
        className="path4"
        stroke="#999"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.719 1.627h6.555M1.719 9.374h6.555"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconSandClock = memo(ForwardRef);
export default IconSandClock;
