import { AxiosError, AxiosRequestConfig } from 'axios';
import { apiGet, apiPost, apiPut } from './api';
import { orgAPI, subscriptionAPI } from '../constants';
import { IInvoicePaymentMethods, IOrderResponse, IRequestCreateOrder } from '../types/cart';
import {
  DTOgetTaxationRelatedDefinitionsOptions,
  DTOrequestQuotationPdf,
  IBank,
  IbillingData,
  IcreateOrderPaymentDto,
  IcreateOrderPaymentResponse,
  StripePaymentSessionDataDto,
  IstripePaymentStatusResponse,
  IrequestShoppingCartQuote
} from '../types/checkout';
import { Tany } from '../types/global';

export const createOrderPayment = async (body: IcreateOrderPaymentDto): Promise<IcreateOrderPaymentResponse> => {
  return apiPost(`${subscriptionAPI}/order/create-payment`, body)
    .then(response => Promise.resolve(response?.data || response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getSpeiBankData = async (orderId: number | string): Promise<IBank> => {
  return apiGet<AxiosRequestConfig, IBank>(`${subscriptionAPI}/order/stripe/bank-data/${orderId}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const putProcessOrder = async (orderId: number | string): Promise<Tany> => {
  return apiPut(`${subscriptionAPI}/order/process`, { orderId, status: 'PROCESS' })
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getPaymentMethods = async (countryCode: string) => {
  return apiGet<IInvoicePaymentMethods[], IInvoicePaymentMethods[]>(`${orgAPI}/country/payment-methods/${countryCode}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getTaxationRelatedDefinitionsOptions = async () => {
  return apiGet<AxiosRequestConfig, DTOgetTaxationRelatedDefinitionsOptions>(`${orgAPI}/organization/tax/data`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const postOrUpdateBillingDataByCountry = async (
  organizationId: string | number,
  formData: FormData
): Promise<IbillingData> => {
  return apiPost<AxiosRequestConfig, FormData>(`${orgAPI}/organization/billing-data/${organizationId}`, formData)
    .then(response => {
      return Promise.resolve(response?.data || response);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

export const createCartOrder = async (orderBody: IRequestCreateOrder) => {
  return apiPost<AxiosRequestConfig, IRequestCreateOrder>(`${subscriptionAPI}/order/create`, orderBody)
    .then(response => {
      const responseData: IOrderResponse = response.data;
      return Promise.resolve(responseData);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

export const requestQuotationPdf = async (body: IrequestShoppingCartQuote): Promise<DTOrequestQuotationPdf> => {
  return apiPost<AxiosRequestConfig, IrequestShoppingCartQuote>(`${subscriptionAPI}/order/shopping-cart-quote`, body)
    .then(response => Promise.resolve(response?.url ? response : response.data))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const requestOrderSummaryPDF = async (orderId: string | number): Promise<DTOrequestQuotationPdf> => {
  return apiPost<AxiosRequestConfig, DTOrequestQuotationPdf>(`${subscriptionAPI}/order/resume/pdf?orderId=${orderId}`)
    .then(response => Promise.resolve(response?.url ? response : response.data))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const putExpireStripePaymentSession = async (body: StripePaymentSessionDataDto): Promise<void> => {
  return apiPut<AxiosRequestConfig, StripePaymentSessionDataDto>(`${subscriptionAPI}/order/expire-payment`, body)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const putVerifyStripePaymentStatus = async (
  body: StripePaymentSessionDataDto
): Promise<IstripePaymentStatusResponse> => {
  return apiPut<AxiosRequestConfig, StripePaymentSessionDataDto>(`${subscriptionAPI}/order/payment-status`, body)
    .then(response => Promise.resolve(response?.data || response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const putUpdateBillingData = async (orgId: number, body: IbillingData): Promise<IbillingData> => {
  return apiPut<AxiosRequestConfig, IbillingData>(`${orgAPI}/organization/gigstack/billing-data/update/${orgId}`, body)
    .then(response => Promise.resolve(response?.data || response))
    .catch((error: AxiosError) => Promise.reject(error));
};
