import { useHistory } from 'react-router-dom';
import useOtpController from '../../components/Otp/otp.controller';
import { useUserAuth } from '../../state/userAuthContext';
import { IpriceInformation, IproductInfo, valueKey } from '../../types/global';
import { formatCost } from '../../utils/formatNumbers';

const useEcommerceControler = () => {
  const { getUserData } = useOtpController();
  const { replace } = useHistory();
  const { user } = useUserAuth();
  const _orgData = localStorage.getItem('orgData');
  const orgData = _orgData && JSON.parse(_orgData);
  const _personalData = localStorage.getItem('personalData');
  const personalData = _personalData && JSON.parse(_personalData);
  const _userLogin = localStorage.getItem('sessionLogin');
  const userLogin = _userLogin && JSON.parse(_userLogin);
  const _activeWasShown = localStorage.getItem('activeWasShown');
  const activeWasShown = _activeWasShown && JSON.parse(_activeWasShown);
  const isITProduct = (product: IproductInfo) => {
    const category = product.categoryName;
    return (
      category === 'Laptops' ||
      category === 'Laptops con DEP' ||
      category === 'Laptops CTOs' ||
      category === 'Monitores'
    );
  };

  const formPriceFiltersUI = (priceFilters: number[]) => {
    if (priceFilters.length === 4)
      return [
        { label: `Hasta ${formatCost(priceFilters[0])} mx`, value: `0-${priceFilters[0]}` },
        {
          label: `${formatCost(priceFilters[0])} - ${formatCost(priceFilters[1])} mx`,
          value: `${priceFilters[0]}-${priceFilters[1]}`
        },
        {
          label: `${formatCost(priceFilters[1])} - ${formatCost(priceFilters[2])} mx`,
          value: `${priceFilters[1]}-${priceFilters[2]}`
        },
        {
          label: `Desde ${formatCost(priceFilters[2])} mx`,
          value: `${priceFilters[2]}-${priceFilters[3]}`
        }
      ];
    else if (priceFilters.length === 2)
      return [
        { label: `Hasta ${formatCost(priceFilters[0])} mx`, value: `0-${priceFilters[0]}` },
        {
          label: `Desde ${formatCost(priceFilters[0])} mx`,
          value: `${priceFilters[0]}-${priceFilters[1]}`
        }
      ];
    else return [];
  };

  const formSinglePillFilterUI = (pillsFilters: [string, string[]]) => {
    const singlePillsFilter: valueKey[] = [];
    pillsFilters[1]?.forEach(pillFilterOption => {
      singlePillsFilter.push({ label: pillFilterOption, value: pillFilterOption });
    });
    return singlePillsFilter;
  };

  const getProductCurrentPriceRangeInfo = (priceRanges: IpriceInformation[], quantity: number) =>
    (quantity &&
      priceRanges.find(
        priceRank =>
          quantity >= +priceRank.startsAtUnits && quantity <= +(priceRank.endsAtUnits || Number.MAX_SAFE_INTEGER)
      )) || {
      price: priceRanges[0]?.price,
      ivaAmount: priceRanges[0]?.ivaAmount,
      startsAtUnits: priceRanges[0]?.startsAtUnits,
      endsAtUnits: priceRanges[0]?.endsAtUnits || null
    };

  const getPriceScaleText = (index: number, prices: IpriceInformation[], isPricesArrayInverted?: boolean) => {
    if (index > prices.length - 1) return;
    if (isPricesArrayInverted) {
      if (index === 0) return `por >= ${prices[index]?.startsAtUnits} und`;
      else return `de ${prices[index]?.startsAtUnits} a ${prices[index]?.endsAtUnits} und`;
    } else {
      if (index === prices.length - 1) return `>= ${prices[index]?.startsAtUnits} unidades`;
      else return `de ${prices[index]?.startsAtUnits} a ${prices[index]?.endsAtUnits} unidades`;
    }
  };

  return {
    user,
    orgData,
    personalData,
    replace,
    getUserData,
    activeWasShown,
    userLogin,
    isITProduct,
    formPriceFiltersUI,
    formSinglePillFilterUI,
    getProductCurrentPriceRangeInfo,
    getPriceScaleText
  };
};

export default useEcommerceControler;
