import SerialIcon from '../../assets/images/components/newCard/serialIcon';
import { NudosHoverText } from '../NudosComponents';
import './ToolSerial.scss';

/**
 * @property { string } serial - The text to shown as serial
 * @property { number } charactersLimitForHover - the máximum number of characters before the serial text is truncated and the text shown as hover. If this value isnt provided the text will be truncated when the component reaches the max width (default 150px, can be modified with the customStyles param)
 * @property { { [key: string]: string } } customStyles - an object of custom styles for the ToolSerial component
 */
const ToolSerial = ({ serial, charactersLimitForHover, customStyles }: IToolSerial) => {
  const includeHover = typeof charactersLimitForHover === 'number' && charactersLimitForHover >= 0;
  return (
    <div className="toolSerial" style={customStyles}>
      <SerialIcon className="serialIcon" />
      {!includeHover && <div className="serialText">{serial}</div>}
      {includeHover && <NudosHoverText onlyIfTruncated text={serial} charactersLimit={charactersLimitForHover} />}
    </div>
  );
};

export default ToolSerial;

interface IToolSerial {
  serial: string;
  charactersLimitForHover?: number;
  customStyles?: { [key: string]: string };
}
