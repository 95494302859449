import { useState } from 'react';
import { NudosSelectButton } from '../../../../../../components/NudosComponents';
import SelectLogisticServiceBillingCountryModal from '../SelectLogisticServiceBillingCountryModal/SelectLogisticServiceBillingCountryModal';
import useLogisticsServiceBillingState from '../../../../../../state/useLogisticsQuoteAndBilling';
import { ICountryDetail } from '../../../../../../types/countries';
import { NudosStaticAlertInComponent } from '../../../../../../components/DesignSystem';
import './RequestOtherBillingCountryForLogisticService.scss';

const RequestOtherBillingCountryForLogisticService = ({ listOfCountries }: { listOfCountries: ICountryDetail[] }) => {
  const { billingCountry, setBillingCountry } = useLogisticsServiceBillingState();
  const [showSelectBillingCountryModal, setShowSelectBillingCountryModal] = useState(false);

  const clickSelectButton = () => {
    if (billingCountry) setBillingCountry(undefined);
    else setShowSelectBillingCountryModal(true);
  };

  const closeModalCallback = () => {
    setShowSelectBillingCountryModal(false);
  };

  const serviceMayBeCanceledAlertText =
    'Solo seleccione esta opción si ha hablado con su KAM previamente de este tema. En caso de no ser asi tu servicio puede ser cancelado.';

  const alertOptions = {
    subtitle: serviceMayBeCanceledAlertText
  };

  return (
    <div className="requestOtherBillingCountryForLogisticService">
      {showSelectBillingCountryModal && (
        <SelectLogisticServiceBillingCountryModal
          closeModalCallback={closeModalCallback}
          listOfCountries={listOfCountries}
        />
      )}
      <div className="requestOtherBillingCountryCheckbox">
        <NudosSelectButton
          componentSize="small"
          isButtonActive={!!billingCountry}
          handleClickButton={clickSelectButton}
        />
        <div className="explainingText">
          <span>Necesito factura emitida desde otro país</span>
          {billingCountry && <span> -</span>}
          <span className="countryName"> {billingCountry?.name}</span>
        </div>
      </div>
      <NudosStaticAlertInComponent
        color="blue"
        type="information"
        keepTypeIconButChangeColor
        alertOptions={alertOptions}
      />
    </div>
  );
};

export default RequestOtherBillingCountryForLogisticService;
