import { RadioButton } from '../../../assets/images/components/NudosComponents';
import './NudosRadioButton.scss';

/**
 * React functional component corresponding to the Nudos radio button
 * @property {boolean} isButtonActive - a boolean indicating if the component is active (true) or not (false). Default value is false.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "small", "medium", "large". Defaul value is medium.
 * @property {function(): void} handleClickButton - Optional, callback to execute on clicking the component.
 */
const NudosRadioButton = ({ componentSize, isButtonActive, handleClickButton }: InudosRadioButton) => {
  const sizeStyles = componentSize ? componentSize : 'medium';
  const activeStyles = isButtonActive ? 'active' : 'inactive';

  return (
    <div className={`nudosRadioButton ${sizeStyles} ${activeStyles}`} onClick={handleClickButton}>
      <RadioButton />
    </div>
  );
};

export default NudosRadioButton;

export interface InudosRadioButton {
  isButtonActive?: boolean;
  componentSize?: string;
  handleClickButton?: () => void;
}
