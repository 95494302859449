import { useEffect, useState } from 'react';
import SelectToolsForAssignmentModal from '../../Components/SelectToolsForAssignmentModal/SelectToolsForAssignmentModal';
import NudosLogisticsGeneralContainer from '../../../../../components/DesignSystem/NudosLogisticsGeneralContainer/NudosLogisticsGeneralContainer';
import ToolsToAssignListingSection from './ToolsToAssignListingSection';
import NoToolsToAssignSection from './NoToolsToAssignSection';
import { IlogisticAdditionalService, ItoolEssentialData } from '../../../../../types/requestLogisticsModule';
import { DTOgetModuleFilters, IQueryTools } from '../../../../../types/DTO';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import { NudosConfirmActionModal } from '../../../../../components/DesignSystem';
import { useTranslation } from 'react-i18next';
import './AssignmentModuleStep1SelectTools.scss';

const AssignmentModuleStep1SelectTools = ({
  filtersData,
  selectedOrigin,
  showExitWarning,
  selectedToolsToAssign,
  toolsAvailableToAssignAccordingToAppliedFilters,
  allToolsAvailableToAssignForThisOrganization,
  toolsOriginCountryWarehouseAdditionalServices,
  closeModalCallback,
  backToOriginAction,
  toolsSelectionCallback,
  handleChangeExtraQueryData,
  refreshLoading
}: {
  filtersData?: DTOgetModuleFilters;
  showExitWarning: boolean;
  selectedToolsToAssign: ItoolEssentialData[];
  selectedOrigin?: TstoragePlace;
  allToolsAvailableToAssignForThisOrganization?: ItoolEssentialData[];
  toolsOriginCountryWarehouseAdditionalServices?: IlogisticAdditionalService[];
  toolsAvailableToAssignAccordingToAppliedFilters?: ItoolEssentialData[];
  backToOriginAction: () => void;
  closeModalCallback: () => void;
  toolsSelectionCallback: (newToolsToAssign: ItoolEssentialData[]) => void;
  handleChangeExtraQueryData?: (query: IQueryTools) => void;
  refreshLoading?: boolean;
}) => {
  const { t } = useTranslation();

  const [showSelectToolsModal, setShowSelectToolsModal] = useState(false);

  const translationKey = 'services:logistics:';
  const notSelectedToolsTitle = t(`${translationKey}notSelectedToolsTitle`);
  const decisionModalText = t(`${translationKey}decisionModal:text`);
  const buttonText = t(`${translationKey}decisionModal:buttonText`);
  const leaveWithoutSavingText = t('recurrentWords:leaveWithoutSaving');

  const hasAvailableToolsToAssign =
    !!allToolsAvailableToAssignForThisOrganization && allToolsAvailableToAssignForThisOrganization.length > 0;
  const hasSelectedTools = !!selectedToolsToAssign && selectedToolsToAssign.length > 0;

  const closeSelectToolsModal = () => setShowSelectToolsModal(false);
  const openSelectToolsModal = () => setShowSelectToolsModal(true);

  const autoOpenModal = () => {
    if (toolsAvailableToAssignAccordingToAppliedFilters && toolsAvailableToAssignAccordingToAppliedFilters.length > 0) {
      setShowSelectToolsModal(true);
    }
  };

  const handleSelectTool = (tool: ItoolEssentialData) => {
    const searchProductId = selectedToolsToAssign.find(element => element.productId === tool.productId);
    if (!searchProductId) {
      const actualToolsList = selectedToolsToAssign || [];
      const newToolsList: ItoolEssentialData[] = [...actualToolsList, tool];
      toolsSelectionCallback(newToolsList);
    }
  };

  const handleUnselectTool = (tool: ItoolEssentialData) => {
    const toolsListWithoutUnselectedTool = selectedToolsToAssign.filter(
      element => element.productId !== tool.productId
    );
    toolsSelectionCallback(toolsListWithoutUnselectedTool);
  };

  const handleClickToolSelectButton = (tool: ItoolEssentialData) => {
    const toolIsSelected = selectedToolsToAssign.some(tool => tool.productId);
    if (toolIsSelected) return handleUnselectTool(tool);
    return handleSelectTool(tool);
  };

  useEffect(() => {
    autoOpenModal();
  }, []);

  return (
    <div className="AssignmentModuleStep1SelectTools">
      {showExitWarning && (
        <NudosConfirmActionModal
          modalText={decisionModalText}
          firstButtonText={leaveWithoutSavingText}
          secondButtonText={buttonText}
          firstButtonAction={backToOriginAction}
          secondButtonAction={closeModalCallback}
        />
      )}
      {showSelectToolsModal && (
        <SelectToolsForAssignmentModal
          filtersData={filtersData}
          closeModalCallback={closeSelectToolsModal}
          toolsSelectionCallback={toolsSelectionCallback}
          toolsAvailableToAssignAccordingToAppliedFilters={toolsAvailableToAssignAccordingToAppliedFilters}
          selectedToolsToAssign={selectedToolsToAssign}
          handleChangeExtraQueryData={handleChangeExtraQueryData}
          refreshLoading={refreshLoading}
        />
      )}
      <NudosLogisticsGeneralContainer title={notSelectedToolsTitle}>
        {hasAvailableToolsToAssign && hasSelectedTools && (
          <ToolsToAssignListingSection
            openModalCallback={openSelectToolsModal}
            selectedToolsToAssign={selectedToolsToAssign}
            toolsOriginCountryWarehouseAdditionalServices={toolsOriginCountryWarehouseAdditionalServices}
            handleClickToolSelectButton={handleClickToolSelectButton}
            selectedOrigin={selectedOrigin}
            handleUpdateSelectedToolsToAssign={toolsSelectionCallback}
          />
        )}
        {(!hasAvailableToolsToAssign || !hasSelectedTools) && (
          <NoToolsToAssignSection
            openModalCallback={openSelectToolsModal}
            allToolsAvailableToAssignForThisOrganization={allToolsAvailableToAssignForThisOrganization || []}
            selectedToolsToAssign={selectedToolsToAssign || []}
          />
        )}
      </NudosLogisticsGeneralContainer>
    </div>
  );
};

export default AssignmentModuleStep1SelectTools;
