import { useTranslation } from 'react-i18next';
import { CloseFile } from '../../../assets/images/views/Ecommerce';
import CopyIcon from '../../../assets/images/views/Ecommerce/CopyIcon';
import useCartState from '../../../state/useCartState';
import { IspeiRequiredDataResponse, PAYMENT_TYPES } from '../../../types/checkout';
import { Tany } from '../../../types/global';
import { IorderDetails } from '../../../types/orders';
import { formatRoundedUpNumber } from '../../../utils/formatNumbers';
import { GenericLoader } from '../../../views/Admin/dashboard/components';
import { NudosHoverText } from '../../NudosComponents';
import './NudosTransfDetails.scss';

const NudosTransfDetails = ({
  SPEIBankData,
  loading,
  chargeFile,
  uploadFile,
  deleteFile,
  loadingFile,
  orderData
}: {
  SPEIBankData?: IspeiRequiredDataResponse;
  loading?: boolean;
  chargeFile: (file: Tany) => void;
  uploadFile?: Tany;
  deleteFile: () => void;
  loadingFile?: boolean;
  orderData?: IorderDetails;
}) => {
  const { t } = useTranslation();
  const { dataFinishCart } = useCartState();

  const translationKey = 'nodi:billingDataSection:nudosTransfDetails:';
  const uploadDocumentTitle = t(`${translationKey}uploadDocumentTitle`);
  const valueTitle = t(`${translationKey}valueTitle`);
  const addressFieldTitle = t(`${translationKey}addressFieldTitle`);
  const receipt = t(`${translationKey}receipt`);
  const bankBeneficiary = t(`${translationKey}bankBeneficiary`);
  const bank = t(`${translationKey}bank`);
  const noData = t(`${translationKey}noData`);
  const numberAccount = t(`${translationKey}numberAccount`);
  const checkingAccount = t(`${translationKey}checkingAccount`);
  const beneficiaryTitle = t(`${translationKey}beneficiaryTitle`);
  const reference = t(`${translationKey}reference`);
  const bankAccountInfo = orderData?.bankAccountInfo || dataFinishCart?.bankAccountInfo;
  const invoiceIsUy = bankAccountInfo?.accountNumber?.includes('7252');
  const beneficiaryCountryIsPeru = bankAccountInfo?.beneficiary?.toLowerCase().includes('peru');
  const hasAccountNumber =
    invoiceIsUy ||
    beneficiaryCountryIsPeru ||
    orderData?.paymentMethod === PAYMENT_TYPES.TRANSFER_COP ||
    dataFinishCart?.paymentType === PAYMENT_TYPES.TRANSFER_COP ||
    !!SPEIBankData?.accountNumber;
  const accountNumber = bankAccountInfo?.accountNumber;
  const clabe = SPEIBankData?.clabe || SPEIBankData?.accountNumber || bankAccountInfo?.clabe;
  const accountNumberOrCLABE = accountNumber || clabe || noData;
  const bankName = bankAccountInfo?.bank || SPEIBankData?.bank || noData;
  const beneficiary =
    bankAccountInfo?.beneficiary ||
    SPEIBankData?.reference ||
    SPEIBankData?.beneficiary ||
    orderData?.bankAccountInfo?.reference ||
    noData;
  const beneficiaryOrReferenceTitle =
    SPEIBankData?.reference || orderData?.bankAccountInfo?.reference ? reference : beneficiaryTitle;
  const displayARSLayout =
    orderData?.paymentMethod === 'Transf. ARS' ||
    dataFinishCart?.paymentType === 'Transf. ARS' ||
    orderData?.currency === 'USD';
  const CBU = bankAccountInfo?.cbu;
  const CUIT = bankAccountInfo?.cuit;

  const accountDetailsTitle =
    (hasAccountNumber && numberAccount) || (displayARSLayout && checkingAccount) ? numberAccount : 'CLABE';
  const displayAddress = bankAccountInfo?.beneficiaryAddress || SPEIBankData?.beneficiaryAddress;
  const beneficiaryBank = bankAccountInfo?.beneficiaryBank || SPEIBankData?.beneficiaryBank;
  const displayRUT = bankAccountInfo?.rut || SPEIBankData?.rut;
  const displayCCI = SPEIBankData?.cci || orderData?.bankAccountInfo?.cci;
  const displaySwift = bankAccountInfo?.swift || SPEIBankData?.swift;
  const displayDocumentInput = dataFinishCart && (!!invoiceIsUy || !!displaySwift);
  const paymentInPenCurrency =
    orderData?.currency === PAYMENT_TYPES.TRANSFER_PEN || dataFinishCart?.paymentType === PAYMENT_TYPES.TRANSFER_PEN;
  const currencySymbol = paymentInPenCurrency ? 'S/' : '$';
  const countriesToDisplayPrinceInUSD = beneficiaryCountryIsPeru || displayARSLayout;
  const valueToPay =
    SPEIBankData?.amountRemaining ||
    (countriesToDisplayPrinceInUSD ? orderData?.totalUsd : orderData?.total) ||
    dataFinishCart?.orderTotal;
  const transferValue = valueToPay ? formatRoundedUpNumber(Number(valueToPay), 2) : noData;
  const transferCurrencyCode = displayARSLayout ? 'USD' : orderData?.currency || dataFinishCart?.currencyCode;
  const transferValueText = `${currencySymbol} ${transferValue} ${transferCurrencyCode}`;
  const charactersLimit = 26;

  if (loading)
    return (
      <div className="loaderData">
        <GenericLoader sizeLoader={25} borderWidth={3} />
      </div>
    );

  return (
    <div id="nudosTransfDetails">
      <div className="informationField">
        <div className="title">{accountDetailsTitle}</div>
        <div className="accountCointainer" onClick={() => navigator.clipboard.writeText(String(accountNumberOrCLABE))}>
          {accountNumberOrCLABE}
          <CopyIcon className="copyTextIcon" stroke="#383838" />
        </div>
      </div>
      <div className="informationField">
        <div className="title">{beneficiaryOrReferenceTitle}</div>
        <NudosHoverText
          customClassName="subtitle"
          text={beneficiary}
          charactersLimit={charactersLimit}
          onlyIfTruncated
        />
      </div>
      {!!displayRUT && (
        <div className="informationField">
          <div className="title">RUT</div>
          <div className="accountCointainer" onClick={() => navigator.clipboard.writeText(String(displayRUT))}>
            {displayRUT}
            <CopyIcon className="copyTextIcon" stroke="#383838" />
          </div>
        </div>
      )}
      {displayCCI && (
        <div className="informationField">
          <div className="title">CCI</div>
          <div className="accountCointainer" onClick={() => navigator.clipboard.writeText(String(displayCCI))}>
            <NudosHoverText
              customClassName="subtitle"
              text={displayCCI}
              charactersLimit={charactersLimit}
              onlyIfTruncated
            />
            <CopyIcon className="copyTextIcon" stroke="#383838" />
          </div>
        </div>
      )}{' '}
      {!!displaySwift && !displayCCI && (
        <div className="informationField">
          <div className="title">Swift</div>
          <NudosHoverText
            customClassName="subtitle"
            text={displaySwift}
            charactersLimit={charactersLimit}
            onlyIfTruncated
          />
        </div>
      )}
      <div className="informationField">
        <div className="title">{bank}</div>
        <NudosHoverText customClassName="subtitle" text={bankName} charactersLimit={charactersLimit} onlyIfTruncated />
      </div>
      {displayARSLayout && !!CBU && (
        <div className="informationField">
          <div className="title">CBU</div>
          <NudosHoverText customClassName="subtitle" text={CBU} charactersLimit={charactersLimit} onlyIfTruncated />
        </div>
      )}
      {!!beneficiaryBank && (
        <div className="informationField">
          <div className="title">{bankBeneficiary}</div>
          <NudosHoverText
            customClassName="subtitle"
            text={beneficiaryBank}
            charactersLimit={charactersLimit}
            onlyIfTruncated
          />
        </div>
      )}
      {!!displayAddress && (
        <div className="informationField addressField">
          <div className="title">{addressFieldTitle}</div>
          <NudosHoverText
            customClassName="subtitle"
            text={displayAddress}
            charactersLimit={2 * charactersLimit}
            onlyIfTruncated
          />
        </div>
      )}
      <div className="informationField">
        <div className="title">{valueTitle}</div>
        <NudosHoverText
          customClassName="subtitle"
          charactersLimit={charactersLimit}
          onlyIfTruncated
          text={transferValueText}
        />
      </div>
      {displayARSLayout && !!CUIT && (
        <div className="informationField">
          <div className="title">CUIT</div>
          <NudosHoverText customClassName="subtitle" text={CUIT} charactersLimit={charactersLimit} onlyIfTruncated />
        </div>
      )}
      {displayDocumentInput && (
        <div className="uploadDocument">
          <div className="title">{uploadDocumentTitle}</div>
          {uploadFile ? (
            <div onClick={() => deleteFile()} className="selectFile">
              <div className="redText"> {uploadFile?.name}</div>
              <CloseFile />
            </div>
          ) : (
            <>
              <input className="documentInput" type="file" onChange={e => chargeFile(e)} />
              <div className="noSelectFile">
                {loadingFile ? (
                  <GenericLoader borderWidth={1} sizeLoader={8} pb={5} />
                ) : (
                  <>
                    <div className="grayText">{receipt}</div>
                    <CloseFile stroke="#CCCCCC" />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NudosTransfDetails;
