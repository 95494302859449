import EnclircledMinusIcon from '../../../../../../assets/images/components/EnclircledMinusIcon';
import { EmployeePhotoPlaceholder, HomeIcon } from '../../../../../../assets/images/views/Admin/employees';
import { EmployeePills } from '../../../../../../components';
import { NudosToolTip } from '../../../../../../components/NudosComponents';
import { Iemployee } from '../../../../../../types/global';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { truncateText } from '../../../../../../utils/truncateText';
import EmployeeInfoPopover from '../EmployeeInfoPopover';
import EquippingProgressBar from '../EquippingProgressBar';
import { useTranslation, Trans } from 'react-i18next';
import './EmployeeDetailsHeader.scss';

const EmployeeDetailsHeader = ({
  employee,
  disabledEmploye
}: {
  employee: Iemployee;
  disabledEmploye?: () => void;
}) => {
  const { t } = useTranslation();
  const currentEquipment = [...(employee?.assignedTools || [])].filter(tool => {
    return !tool.assignedUntil;
  });
  const employeeFullName = `${employee.firstName} ${employee.lastName}`;
  const employeeDetailsLenguage = 'nodi:employeeInternal:';
  const cityPillKey = t(`${employeeDetailsLenguage}newEmployeeForm:nameAndPills:employeePills:city`);
  const areaPillKey = t(`${employeeDetailsLenguage}newEmployeeForm:nameAndPills:employeePills:area`);
  const positionPillKey = t(`${employeeDetailsLenguage}newEmployeeForm:nameAndPills:employeePills:position`);

  const employeePills = {
    [cityPillKey]: employee.city || '',
    [areaPillKey]: employee['employeeArea.nameStringId']
      ? t(`services:employeeArea:${employee['employeeArea.nameStringId']}`)
      : employee.area || '',
    [positionPillKey]: employee.position || ''
  };
  const noAddress =
    !employee.address ||
    !employee.address.address ||
    typeof employee.address.address !== 'string' ||
    !employee.address.country ||
    !employee.address.city;
  const noAddressStyle = noAddress ? 'noAddress' : '';
  const addressExistsAndApply = !noAddress;
  const currentTools = employee?.assignedTools?.filter(tool => !tool?.assignedUntil);
  const getEquippingStatusPercentage = () => {
    if (!currentTools || currentTools?.length <= 0) return 0;
    if (currentTools?.length > 4) return 100;
    return +((currentTools?.length / 4) * 100).toFixed(0);
  };

  const updateUserState = () => {
    if (disabledEmploye) {
      disabledEmploye();
      segmentTrackEvent({
        employeesDetailDisableClick: {
          EmployeeCountry: employee?.country?.name || 'Otro',
          EmployeeName: employeeFullName
        }
      });
    }
  };

  return (
    <>
      <div id="employeeDetailsHeader">
        <div className="imageSection">
          {!employee?.photoProfile && <EmployeePhotoPlaceholder />}
          {employee?.photoProfile && (
            <div
              className={`profilePhoto`}
              style={{
                backgroundImage: `url("${employee?.photoProfile.replaceAll(' ', '%20')}")`
              }}
            ></div>
          )}
        </div>
        <div className="namePillsAddressSection">
          {employee.firstName && employee.lastName && (
            <div className="name">
              {truncateText(employeeFullName, 22)}
              <div className="nameHover">{employeeFullName}</div>
              {employee.country?.flag && (
                <div
                  className="flagContainer"
                  style={{
                    backgroundImage: `url(${employee?.country?.flag})`
                  }}
                />
              )}
            </div>
          )}
          <EmployeePills pills={employeePills} isActive={true} />
          <div className={`address ${noAddressStyle}`}>
            <HomeIcon className="homeIcon" />
            {addressExistsAndApply && (
              <>
                <div className="w-full">{truncateText(employee?.address?.address || '', 35)}</div>
                <div className="addressHover">{employee?.address?.address}</div>
              </>
            )}
            {noAddress && (
              <div className="noAddressText">{t(`${employeeDetailsLenguage}namePillsAddressSection:noAddress`)}</div>
            )}
            <div
              className="flagContainer"
              style={{
                backgroundImage: `url(${employee?.country?.flag || ''})`
              }}
            />
          </div>
        </div>
        <div className="buttonsSection">
          <div className="statusContainer">
            {employee?.active ? (
              <>
                <div className="text">{t(`${employeeDetailsLenguage}buttonsSection:active`)}</div>
                <div className="circle" />
              </>
            ) : (
              <>
                <div className="text">{t(`${employeeDetailsLenguage}buttonsSection:inactive`)}</div>
                <div className="grayCircle" />
              </>
            )}
          </div>
          <div className="disableButtonContainer">
            {currentEquipment && currentEquipment.length > 0 ? (
              <div className="disableButton">{t(`${employeeDetailsLenguage}buttonsSection:disableButton`)}</div>
            ) : (
              <div
                className="enabledButton"
                onClick={() => {
                  updateUserState();
                }}
              >
                {employee?.active
                  ? t(`${employeeDetailsLenguage}buttonsSection:disableButton`)
                  : t(`${employeeDetailsLenguage}buttonsSection:enable`)}
              </div>
            )}
            <NudosToolTip>
              <div className="tooltip">
                <Trans
                  i18nKey={`${employeeDetailsLenguage}buttonsSection:tooltip`}
                  components={{
                    1: <strong />,
                    2: <EnclircledMinusIcon className="iconWrapper" />
                  }}
                />
              </div>
            </NudosToolTip>
          </div>
        </div>
        <div className="popoverButtonSection">
          <EmployeeInfoPopover employee={employee} />
        </div>
        <div className="progressbarSection">
          <EquippingProgressBar equippingStatusPercentage={getEquippingStatusPercentage()} />
        </div>
      </div>
    </>
  );
};

export default EmployeeDetailsHeader;
