import { FC, useCallback, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import ArrowsFilter from '../../assets/images/components/genericFilter/arrowsFilter';
import './GenericFilter.scss';
import { NudosSelectButton } from '../NudosComponents';
interface IElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any;
}
interface IIOptions {
  value: number | string;
  name: string;
  isChecked: boolean;
}
interface IIGenericFilter {
  filterName?: string;
  openFilter?: boolean;
  setOpenFilter?: (status: boolean) => void;
  optionsList?: IIOptions[];
  setNewList?: (items: IIOptions[]) => void;
  positionList?: number;
  disabledFilter?: boolean;
}

const GenericFilter: FC<IIGenericFilter> = ({
  filterName,
  openFilter,
  setOpenFilter,
  optionsList,
  setNewList,
  positionList,
  disabledFilter
}: IIGenericFilter) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const handleChangeFilter = () => {
    if (setOpenFilter && !disabledFilter) {
      setOpenFilter(!openFilter);
    }
  };
  const isActivate = !!optionsList?.find(element => element.isChecked);
  const handleChangeList = (item: IIOptions, index: number) => {
    if (optionsList && setNewList) {
      const oldList = optionsList;
      oldList[index].isChecked = !item.isChecked;
      setNewList([...oldList]);
    }
  };

  const generateList = () => {
    if (optionsList && optionsList.length) {
      return optionsList.map((item, index: number) => {
        return (
          <div
            key={uuid()}
            className="itemSelect"
            onClick={() => {
              handleChangeList(item, index);
            }}
          >
            <div className="optionLabel">
              <div className="textOption">{item.name}</div>
            </div>
            {<NudosSelectButton iconName="xmark" componentSize="small" isButtonActive={item?.isChecked} />}
          </div>
        );
      });
    }
    return null;
  };

  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        if (setOpenFilter) {
          setOpenFilter(false);
        }
      }
    },
    [refContainer]
  );

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div id="genericFilter">
      <div className={`boxFilter ${disabledFilter ? 'cursor-not-allowed' : ''} `} onClick={handleChangeFilter}>
        {!!openFilter && <div className="falseHeader" />}
        <div className="titleFilter">
          <div className={isActivate ? 'textActivate' : 'textFilter'} style={{ opacity: disabledFilter ? '0.2' : '' }}>
            {filterName || 'Titulo filtro'}
          </div>
        </div>
        <div className="iconFilter">
          <ArrowsFilter
            className={isActivate ? 'textActivate' : ''}
            style={{ transform: openFilter ? 'rotate(180deg)' : '', opacity: disabledFilter ? '0.2' : '' }}
          />
        </div>
      </div>
      {!!openFilter && (
        <div
          className="containerOptions"
          style={{ top: positionList ? `calc(100% + ${positionList}px)` : '' }}
          ref={refContainer}
        >
          <div className="boxOptions">{optionsList && generateList()}</div>
        </div>
      )}
    </div>
  );
};
export default GenericFilter;
