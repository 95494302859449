import { useState } from 'react';
import { getUsersOrganizationData, uploadProfilePhoto } from '../../services/services';
import { useUserAuth } from '../../state/userAuthContext';
import useEcommerceControler from '../../views/ecommerce/ecommerce.controller';
import noPicture from '../../assets/images/views/Admin/main/noPicture.png';
import useProfileImage from '../../state/useProfileImage';
import { displayErrorNotification, displaySuccessNotification } from '../../utils/displayNudosStandardNotifications';
import { useHistory } from 'react-router-dom';
import { IconHeaderAccount, IconHeaderLogOut } from '../../assets/images/components';
import useLogOutState from '../../state/useLogOutState';
import { Tany } from '../../types/global';
import { useTranslation } from 'react-i18next';
import './ProfileInfo.scss';

const ProfileInfo = () => {
  const { push } = useHistory();
  const { user } = useUserAuth();
  const { orgData, personalData } = useEcommerceControler();
  const fullName = `${personalData?.firstName || ''} ${personalData?.lastName || ''}`;
  const [updatedProfilePicture, setUpdatedProfilePicture] = useState(0);
  const { temporalImg } = useProfileImage();
  const { setIsLogoutModalOpen, setOpenLogoutModal, setOpenMinMenu, openMinMenu } = useLogOutState();
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:headBar:profileInfo:';

  const getUserProfileType = () => {
    if (!personalData || !personalData?.role || personalData?.role === 'admin')
      return t(`${i18nLanguageKey}profileType:admin`);
    return t(`${i18nLanguageKey}profileType:user`);
  };

  const isNameDataComplete = fullName && fullName.length > 1;
  const isImageFileValid = (file: File) => {
    const isFormatCorrect = file.type === 'image/png' || file.type === 'image/jpeg';
    const isSizeValid = file.size / 1024 / 1024 < 2;
    if (!isFormatCorrect) {
      displayErrorNotification({ customJSXMessage: <>{t(`recurrentWords:isImageFileValid:format`)}</> });
      return false;
    }
    if (!isSizeValid) {
      displayErrorNotification({ customJSXMessage: <>{t(`recurrentWords:isImageFileValid:size`)}</> });
      return false;
    }
    return true;
  };

  const handleFileChange = async (event: Tany) => {
    const uploadedFile = event.target.files[0];
    const validFile = isImageFileValid(uploadedFile);
    if (!validFile || !user) return;
    const formData = new FormData();
    formData.append('file', uploadedFile);
    try {
      const response = await uploadProfilePhoto(orgData.userId, formData);
      if (response) {
        const userData = await getUsersOrganizationData(user.uid);
        localStorage.setItem('orgData', JSON.stringify(userData));
        setUpdatedProfilePicture(updatedProfilePicture + 1);
        displaySuccessNotification({ customJSXMessage: <>La imagen fue actualizada exitosamente</> });
      }
    } catch (error) {
      displayErrorNotification();
    }
  };

  const handleClickSeeProfile = () => {
    push('/nodi/account');
    setOpenMinMenu(false);
  };

  return (
    <div id="profileInfo">
      <div className="containerImg">
        <input className="inputPosition" type={'file'} onChange={handleFileChange} />
        {temporalImg ? (
          <div
            className="imgStyle"
            style={{
              backgroundImage: `url(${temporalImg})`
            }}
          />
        ) : (
          <div
            className="imgStyle"
            style={{
              backgroundImage: `url(${
                orgData?.photoProfile && orgData?.photoProfile.length > 0
                  ? `${orgData.photoProfile.replaceAll(' ', '%20')}`
                  : noPicture
              })`
            }}
          />
        )}
      </div>
      <div className="containerName">
        <div className="boxName">
          <div className="containerCenter">
            <div className={`profileName ${!isNameDataComplete && 'incomplete'}`}>
              {isNameDataComplete ? fullName.toLowerCase() : 'Completa tu cuenta'}
            </div>
            <div className="profileType">
              <div className="profile">{getUserProfileType()}</div>
              <button
                className="seeProfileDataButton"
                onClick={() => {
                  setOpenMinMenu(true);
                }}
              >
                {t(`${i18nLanguageKey}seeProfileDataButton`)}
              </button>
            </div>
          </div>
          {openMinMenu && (
            <div
              className="floatOptions"
              onMouseLeave={() => {
                setOpenMinMenu(false);
              }}
            >
              <div className="boxFloatOptions">
                <div className="containerOption mb-1">
                  <div className="textOption" onClick={handleClickSeeProfile}>
                    {t(`${i18nLanguageKey}floatOptions:goToAccount`)}
                  </div>
                  <IconHeaderAccount />
                </div>
                <div
                  className="containerOption"
                  onClick={() => {
                    setOpenLogoutModal(true);
                    setIsLogoutModalOpen(true);
                  }}
                >
                  <div className="textOption"> {t(`${i18nLanguageKey}floatOptions:logOut`)}</div>
                  <IconHeaderLogOut />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
