import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={27} height={30} fill={props.fill} xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M2.28125 18.3235C-0.385413 16.7839 -0.385417 12.9349 2.28125 11.3953L20.2812 1.00297C22.9479 -0.536632 26.2813 1.38787 26.2813 4.46707L26.2813 25.2517C26.2813 28.3309 22.9479 30.2554 20.2813 28.7158L2.28125 18.3235Z"
      fill={props?.fill || '#FAFAFA'}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const PolygonOnboarding = memo(ForwardRef);
export default PolygonOnboarding;
