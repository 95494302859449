import { Link } from 'react-router-dom';
import { NudosButton } from '../../components/NudosComponents';
import notFoundImage from '../../assets/images/views/NotFound/notFoundImage.webp';
import { supportUrl } from '../../utils/productDefinitions';
import './NotFound.scss';

const NotFound = () => {
  const handleClickForHelp = () => window.open(supportUrl);

  return (
    <div className="NotFound">
      <img className="notFoundImage" src={notFoundImage} />
      <div className="notFoundTitle">Lo sentimos, la página que buscas se perdió en el camino. </div>
      <div className="notFoundSubtitle">Si crees que se trata de un error, contacta a nuestro equipo de soporte</div>
      <div className="buttonsContainer">
        <NudosButton
          buttonText="Contactar a soporte"
          handleClick={handleClickForHelp}
          colorPalette="secondary"
          componentSize="small"
        />
        <Link to={'dashboard'}>
          <NudosButton customClassName="notFoundButton" buttonText="Volver al inicio" />
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
