import { FC } from 'react';
import { BlackAddIcon, GrayAddIcon } from '../../../../../assets/images/views/Admin/account';
import { useTranslation } from 'react-i18next';
import './addCountryAddress.scss';
interface IAddCountryAddress {
  blockedAddButton?: boolean;
  setOpenModalNewAddress?: (state: boolean) => void;
}

const AddCountryAddress: FC<IAddCountryAddress> = ({
  blockedAddButton,
  setOpenModalNewAddress
}: IAddCountryAddress) => {
  const blocked = blockedAddButton;
  const { t } = useTranslation();
  return (
    <div
      id="addCountryAddress"
      style={{ cursor: blocked ? 'not-allowed' : '' }}
      onClick={() => {
        if (!blocked && setOpenModalNewAddress) {
          setOpenModalNewAddress(true);
        }
      }}
    >
      <div className="boxAddIcon">{blocked ? <GrayAddIcon /> : <BlackAddIcon />}</div>
      <div className="boxTitleAdd" style={{ color: blocked ? '#CCCCCC' : '' }}>
        {t('nodi:account:organizationAddressesTab:boxTitleAdd')}
      </div>
    </div>
  );
};

export default AddCountryAddress;
