import { useTranslation } from 'react-i18next';
import { NudosStepsProgressBar, NudosToolTip } from '../../../../../../components/NudosComponents';
import { TlogisticsStatus } from '../../../../../../types/logisticsServicesModule.types';
import './LogisticServiceStepper.scss';

const LogisticServiceStepper = ({
  currentStep = 'por confirmar',
  loading,
  manualQuote
}: {
  currentStep?: TlogisticsStatus | null;
  loading?: boolean;
  manualQuote?: boolean;
}) => {
  const { t } = useTranslation();

  const translationKey = 'nodi:logisticServices:logisticServiceStepper:';
  const confirmedStatus = t(`${translationKey}confirmedStatus`);
  const inProcessStatus = t(`${translationKey}inProcessStatus`);
  const activeStatus = t(`${translationKey}activeStatus`);
  const deliveredStatus = t(`${translationKey}deliveredStatus`);
  const toBeConfirmedStatus = t(`${translationKey}toBeConfirmedStatus`);
  const cancelledStatus = t(`${translationKey}cancelledStatus`);

  const confirmedHoverText = t(`${translationKey}confirmedHoverText`);
  const inProcessHoverText = t(`${translationKey}inProcessHoverText`);
  const activeHoverText = t(`${translationKey}activeHoverText`);
  const deliveredHoverText = t(`${translationKey}deliveredHoverText`);
  const defaultHoverText = t(`${translationKey}defaultHoverText`);
  const defaultManualHoverText = t(`${translationKey}defaultManualHoverText`);
  const confirmedManualHoverText = t(`${translationKey}confirmedManualHoverText`);

  const canceledStyles = currentStep === 'cancelado' ? 'canceled' : '';
  const firstAndLastConectorWidth = currentStep === 'cancelado' ? 202 : 32;

  const getStepAdditionalData = (step: TlogisticsStatus) => {
    switch (step) {
      case 'confirmado':
        return {
          order: 1,
          hoverText: manualQuote ? confirmedManualHoverText : confirmedHoverText
        };
      case 'en proceso':
        return {
          order: 2,
          hoverText: inProcessHoverText
        };
      case 'activo':
        return {
          order: 3,
          hoverText: activeHoverText
        };
      case 'entregado':
        return { order: 4, hoverText: deliveredHoverText };
      default:
        return {
          order: 0,
          hoverText: manualQuote ? defaultManualHoverText : defaultHoverText
        };
    }
  };

  const getStepTooltip = (step: TlogisticsStatus) => (
    <NudosToolTip
      type="question"
      tooltipContent={<>{getStepAdditionalData(step)?.hoverText}</>}
      tooltipContentCustomStyles={{ width: '150px' }}
      position={step === 'cancelado' || step === 'entregado' ? 'right' : 'left'}
    />
  );

  const cancelSteps = [
    {
      order: 0,
      stepContent: (
        <div className={`logisticServiceStepContent`}>
          <div className={'stepText cancelado'}>{cancelledStatus}</div>
        </div>
      ),
      stepWidth: 55
    }
  ];

  const progressSteps = [
    {
      order: 0,
      stepContent: (
        <div className={`step0 logisticServiceStepContent`}>
          <div className="stepText">{toBeConfirmedStatus}</div>
          {getStepTooltip('por confirmar')}
        </div>
      ),
      stepWidth: 83
    },
    {
      order: 1,
      stepContent: (
        <div className={`step1 logisticServiceStepContent`}>
          <div className="stepText">{confirmedStatus}</div>
          {getStepTooltip('confirmado')}
        </div>
      ),
      stepWidth: 75
    },
    {
      order: 2,
      stepContent: (
        <div className={`step1 logisticServiceStepContent`}>
          <div className="stepText">{inProcessStatus}</div>
          {getStepTooltip('en proceso')}
        </div>
      ),
      stepWidth: 70
    },
    {
      order: 3,
      stepContent: (
        <div className={`step2 logisticServiceStepContent`}>
          <div className="stepText">{activeStatus}</div>
          {getStepTooltip('activo')}
        </div>
      ),
      stepWidth: 50
    },
    {
      order: 4,
      stepContent: (
        <div className={`step3 logisticServiceStepContent`}>
          <div className={`stepText ${canceledStyles}`}>{canceledStyles ? cancelledStatus : deliveredStatus}</div>
          {getStepTooltip(canceledStyles ? 'cancelado' : 'entregado')}
        </div>
      ),
      stepWidth: canceledStyles ? 75 : 68
    }
  ];
  return (
    <div className="LogisticServiceStepper">
      <NudosStepsProgressBar
        steps={canceledStyles ? cancelSteps : progressSteps}
        currentStep={getStepAdditionalData(currentStep || 'por confirmar')?.order}
        customStepperClassname={canceledStyles ? 'canceledStepper' : ''}
        firstAndLastConectorWidth={firstAndLastConectorWidth}
        intermediateStepsConectorWidth={40}
        customHeight={24}
        skeletonSteps={progressSteps}
        loading={loading}
      />
    </div>
  );
};

export default LogisticServiceStepper;
