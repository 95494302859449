import { useLocation } from 'react-router-dom';

export function getPathRoute(): string {
  const { pathname } = useLocation();
  return pathname.split('/')[2];
}

export function isRouteMyLocation(route: string, index?: number): boolean {
  const { pathname } = useLocation();
  return route === pathname.split('/')[index || 2];
}
