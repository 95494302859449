import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AddInformationStepIcon,
  SelectReferenceStepIcon,
  UploadToolsStepIcon
} from '../../../assets/images/views/Admin/uploadExternalToolModule';
import { GenericStepsProgressBar } from '../../../components';
import {
  NudosBreadcrumbButton,
  NudosGenericModal,
  NudosLoaderModal,
  NudosModalContent
} from '../../../components/NudosComponents';
import { getCountriesList } from '../../../services/countries.service';
import { createNewTool, getCounterExternalTools, uploadProducts } from '../../../services/uploadExternalTools.service';
import useLoadTools from '../../../state/useStateSuccessLoadTools';
import { IDTOCreateToolPetition, IUploadCountries } from '../../../types/uploadExternalTools.types';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { Step1SelectReferences, Step2AddInformation, Step3UploadTools, ToolsModal } from './Components';
import { useTranslation, Trans } from 'react-i18next';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { formatOrgData } from '../../../utils/orgFormatData';
import NudosDynamicBanner, {
  INudosDynamicBanner
} from '../../../components/DesignSystem/NudosDynamicBanner/NudosDynamicBanner';
import { FREE_TOOLS_TO_UPLOAD_LIMIT } from '../../../utils/productDefinitions';
import './UploadExternalToolsModule.scss';

const UploadExternalToolsModule = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { search } = useLocation();
  const orgData = formatOrgData();
  const fromWhereItCame = new URLSearchParams(search).get('from');
  const { setUploadedToolsData, selectedReferences, currentStep, setCurrentStep } = useLoadTools();
  const { stateSubscription } = useStateSubscriptions();
  const freePlatform = stateSubscription?.levelSubscription === 'Free';
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingNumberExternalReferences, setLoadingNumberExternalReferences] = useState<boolean>(false);
  const [loadingTool, setLoadingTool] = useState<boolean>(false);
  const [countries, setCountries] = useState<IUploadCountries[]>();
  const [modalTools, setModalTools] = useState<boolean>(false);
  const [messageDynamicBanner, setMessageDynamicBanner] = useState<INudosDynamicBanner>();
  const [numberOfExternalReferences, setNumberOfExternalReferences] = useState<number>(0);

  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:uploadToolSteps:';
  const screenName = 'Tools Upload Searcher';

  const updateExteralTools = async () => {
    const orgId = getOrgData()?.organizationId;
    if (!orgId) return;
    setLoading(true);
    const referencesCopy = [...selectedReferences];
    const correctlyFormatedProducts = referencesCopy.map(reference => {
      const currentAdditionalData = [...reference.individualToolsData];
      const correctlyFormatedItems = currentAdditionalData.map(item => {
        const assigneeInfo = {
          userId: item?.assignedEmployee?.userId || undefined,
          firstName: item?.assignedEmployee?.firstName || undefined,
          lastName: item?.assignedEmployee?.lastName || undefined
        };
        const itemsWithCountryAndAssignment = {
          ...item,
          locationInfo: {
            place:
              item?.optionId === 1 ? 'office' : item?.optionId === 2 ? 'other' : item?.optionId === 3 ? 'user' : null,
            placeName: item.optionId === 2 ? item?.otherAddressDetail?.locationName : null
          },
          countryId: item.country?.id,
          userInfo:
            !!assigneeInfo?.userId && !!assigneeInfo?.firstName && !!assigneeInfo?.lastName ? assigneeInfo : undefined
        };
        delete itemsWithCountryAndAssignment.country;
        delete itemsWithCountryAndAssignment.assignedEmployee;
        return itemsWithCountryAndAssignment;
      });
      return { ...reference, pills: undefined, individualToolsData: correctlyFormatedItems };
    });
    try {
      const bodyForUploadProductsRequest = {
        organizationId: orgId,
        references: correctlyFormatedProducts
      };
      const responseToUploadProducts = await uploadProducts(bodyForUploadProductsRequest);
      if (!responseToUploadProducts) return;
      setUploadedToolsData(responseToUploadProducts);
      push('/nodi/upload-external-tools/summary');
    } catch (error) {
      displayErrorNotification({
        customJSXMessage: (
          <>Tuvimos un problema cargando las herramientas comunícate con soporte dando click en el chat</>
        )
      });
    } finally {
      setLoading(false);
    }
  };

  const activateModal = () => {
    setModalTools(true);
  };

  const uploadToolSteps = [
    {
      order: 0,
      icon: <SelectReferenceStepIcon />,
      title: t(`${i18nLanguageKey}order0:title`),
      subtitle: t(`${i18nLanguageKey}order0:subtitle`),
      returnText: '',
      component: (
        <Step1SelectReferences
          activateModal={activateModal}
          freePlatform={freePlatform}
          numberOfExternalReferences={numberOfExternalReferences}
          maximumExternalUnits={FREE_TOOLS_TO_UPLOAD_LIMIT}
          screenName={screenName}
        />
      )
    },
    {
      order: 1,
      icon: <AddInformationStepIcon />,
      title: t(`${i18nLanguageKey}order1:title`),
      subtitle: t(`${i18nLanguageKey}order1:subtitle`),
      returnText: t(`${i18nLanguageKey}order1:returnText`),
      customClassName: 'step2AddInformationContainer',
      component: <Step2AddInformation countriesData={countries} />
    },
    {
      order: 2,
      icon: <UploadToolsStepIcon />,
      title: t(`${i18nLanguageKey}order2:title`),
      returnText: t(`${i18nLanguageKey}order2:returnText`),
      component: <Step3UploadTools updateExteralTools={updateExteralTools} />
    }
  ];

  const handleClickReturn = () => {
    if (currentStep === 2 || currentStep === 1) return setCurrentStep(currentStep - 1);
    if (fromWhereItCame === 'dashboard') return push('/nodi/dashboard');
    return push('/nodi/tools');
  };

  const getReturnText = () => {
    if (currentStep === 2 || currentStep === 1) return uploadToolSteps[currentStep].returnText;
    if (fromWhereItCame === 'dashboard') return 'Dashboard';
    return t(`nodi:returnTextBasedOnPreviousLocation:inventory`);
  };

  const createTool = async (data: IDTOCreateToolPetition) => {
    setLoadingTool(true);
    try {
      await createNewTool(data);
      setModalTools(false);
      setLoadingTool(false);
      displaySuccessNotification({
        customJSXMessage: (
          <div>
            <Trans i18nKey={`${i18nLanguageKey}createTool`} components={{ 1: <div></div> }} />
          </div>
        )
      });
    } catch (error) {
      displayErrorNotification();
      setLoadingTool(false);
    }
  };

  const getCountries = async () => {
    try {
      const dataCountries = await getCountriesList();
      if (dataCountries) setCountries(dataCountries);
    } catch {
      displayErrorNotification();
    }
  };

  const organizationExternalTools = async () => {
    if (freePlatform) {
      setLoadingNumberExternalReferences(true);
      try {
        const externalData = await getCounterExternalTools(Number(orgData?.organizationId));
        setNumberOfExternalReferences(externalData?.length || 0);
        if (externalData?.length && externalData.length > FREE_TOOLS_TO_UPLOAD_LIMIT) {
          setMessageDynamicBanner({
            bannerSubtitle:
              'Actualiza a Nodi Prime para cargar todas las herramientas que desees, obtener descuentos y disfrutar de mucho más con todo lo que Nudos tiene para ti.',
            bannerTitle: 'Carga todas las herramientas que necesites'
          });
        }
      } catch (error) {
        displayErrorNotification();
      } finally {
        setLoadingNumberExternalReferences(false);
      }
    }
  };

  const getStepStyle = (stepOrder: number) =>
    currentStep < stepOrder ? '' : currentStep === stepOrder ? 'current' : 'checked';

  useEffect(() => {
    getCountries();
    organizationExternalTools();
  }, []);

  return (
    <div className="uploadExternalToolsModule">
      {messageDynamicBanner && (
        <NudosDynamicBanner
          openedBannerOrigin="cargue de herramientas"
          bannerSubtitle={messageDynamicBanner.bannerSubtitle || ''}
          bannerTitle={messageDynamicBanner?.bannerTitle || ''}
          closeModalCallback={() => {
            push('/nodi/tools');
          }}
          alertType={2}
        />
      )}{' '}
      {modalTools && (
        <NudosGenericModal
          modalContent={
            <NudosModalContent
              customHeaderClass="headerCustomClass"
              modalContentTitle={t(`${i18nLanguageKey}nudosModalContent`)}
              customHeight="409px"
              customWidth="780px"
              toCloseModal={() => {
                setModalTools(false);
              }}
              CustomModalComponent={<ToolsModal loading={loadingTool} createTool={createTool} />}
            />
          }
        />
      )}
      {(loading || loadingNumberExternalReferences) && (
        <NudosLoaderModal
          loaderText={t(`${i18nLanguageKey}nudosLoaderModal`)}
          modalTopPosition={0}
          preventCenteringForLargeScreens
        />
      )}
      <NudosBreadcrumbButton smallHeader returnText={getReturnText()} returnAction={handleClickReturn} />
      <div className="progressBarContainer">
        <GenericStepsProgressBar
          currentStep={currentStep}
          stepsData={uploadToolSteps}
          progressBarWidth={580}
          individualLineWidth={126}
        />
        <div className="progressBarTexts">
          <div className={`text ${getStepStyle(0)}`}>{t(`${i18nLanguageKey}progressBarContainer:text0`)}</div>
          <div className={`text ${getStepStyle(1)}`}>{t(`${i18nLanguageKey}progressBarContainer:text1`)}</div>
          <div className={`text ${getStepStyle(2)}`}>{t(`${i18nLanguageKey}progressBarContainer:text2`)}</div>
        </div>
      </div>
      <div className={`currentStepContainer ${uploadToolSteps[currentStep]?.customClassName || ''}`}>
        <h2 className="stepTitle">{uploadToolSteps[currentStep]?.title}</h2>
        {uploadToolSteps[currentStep]?.subtitle && (
          <h2 className="stepSubtitle">{uploadToolSteps[currentStep]?.subtitle}</h2>
        )}
        {uploadToolSteps[currentStep]?.component}
      </div>
    </div>
  );
};

export default UploadExternalToolsModule;
