import { FC } from 'react';
import { NudosSpinnerLoader } from '../../../../../components/NudosComponents';
import './GenericLoader.scss';
interface IGenericLoader {
  sizeLoader?: number;
  borderWidth?: number;
  pb?: number;
  percentageSpinner?: number;
}

const GenericLoader: FC<IGenericLoader> = ({ sizeLoader, borderWidth, pb, percentageSpinner }: IGenericLoader) => {
  return (
    <div id="generiLoader">
      <div className="containLoader" style={{ paddingBottom: pb ? `${pb}px` : '' }}>
        <div
          className="sizeSpinner"
          style={{ height: sizeLoader ? `${sizeLoader}px` : '', width: sizeLoader ? `${sizeLoader}px` : '' }}
        >
          <NudosSpinnerLoader borderWidth={borderWidth || 6} arcColor="#FF664E" percentageSpinner={percentageSpinner} />
        </div>
      </div>
    </div>
  );
};

export default GenericLoader;
