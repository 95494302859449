import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={9} height={9} fill="none" ref={ref} {...props}>
    <g clipPath="url(#clip0_14288_29507)">
      <path
        d="M7.18304 4.56836L3.68304 8.31836C3.64595 8.35794 3.59699 8.38439 3.54355 8.3937C3.49011 8.40302 3.43509 8.3947 3.3868 8.37001C3.3385 8.34531 3.29954 8.30558 3.27581 8.2568C3.25208 8.20802 3.24485 8.15285 3.25523 8.09961L3.71335 5.80804L1.91241 5.13179C1.87374 5.11733 1.83924 5.0935 1.81202 5.06245C1.7848 5.0314 1.76569 4.99409 1.7564 4.95386C1.74712 4.91362 1.74794 4.87171 1.75881 4.83187C1.76967 4.79203 1.79024 4.7555 1.81866 4.72554L5.31866 0.975545C5.35575 0.935962 5.40471 0.909517 5.45815 0.9002C5.51159 0.890883 5.56661 0.8992 5.6149 0.923895C5.6632 0.94859 5.70216 0.988325 5.72589 1.0371C5.74962 1.08588 5.75685 1.14105 5.74648 1.19429L5.2871 3.48836L7.08804 4.16367C7.12643 4.17823 7.16064 4.20203 7.18766 4.23296C7.21467 4.26389 7.23365 4.30099 7.24291 4.34099C7.25218 4.381 7.25145 4.42267 7.24079 4.46232C7.23013 4.50198 7.20986 4.53839 7.18179 4.56836H7.18304Z"
        fill={props?.fill || '#81BC3F'}
      />
    </g>
    <defs>
      <clipPath id="clip0_14288_29507">
        <rect width="8" height="8" fill="white" transform="translate(0.5 0.648438)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconGreenThunder = memo(ForwardRef);
export default IconGreenThunder;
