import { FC } from 'react';
import IconGreenThunder from '../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import { nudosFormatCurrency } from '../../../utils/formatNumbers';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { Trans, useTranslation } from 'react-i18next';
import './DiscountPercentTooltip.scss';

interface IDiscountPercentTooltip {
  tooltipKey: string;
  selectTooltipKey: string;
  subTotalWithoutPrime?: string;
  subTotalWithPrime?: string;
  subTotalDiscountValue?: string;
}

const DiscountPercentTooltip: FC<IDiscountPercentTooltip> = ({
  tooltipKey,
  selectTooltipKey,
  subTotalWithoutPrime,
  subTotalWithPrime,
  subTotalDiscountValue
}: IDiscountPercentTooltip) => {
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();
  const { levelSubscription } = stateSubscription;
  const isPrime = levelSubscription === 'Lite' || levelSubscription === 'Vip';

  const tooltipId = tooltipKey === selectTooltipKey ? tooltipKey : undefined;
  const myElement = document.getElementById(tooltipId || '');
  const positionIcon = myElement ? myElement.getBoundingClientRect() : undefined;
  const translationKey = 'nodi:checkout:discountPercentTooltip:';

  const calcTop = () => {
    if (isPrime) return 122;
    return 112;
  };

  return (
    <>
      {tooltipId && (
        <div
          id="discountPercentTooltip"
          style={{
            top: `${positionIcon?.top ? positionIcon?.top - calcTop() : 0 || 0}px`,
            left: `${positionIcon?.left ? positionIcon?.left - 180 : 0 || 0}px`
          }}
        >
          <div className="titleProductTooltip">{t(`${translationKey}titleProductTooltip`)}</div>
          <div className="grayProductTooltip">{t(`${translationKey}grayProductTooltip`)}</div>
          {isPrime ? (
            <>
              <div className="primeList">
                <div className="keyList">Subtotal</div>
                <div className="valueList">
                  {`${nudosFormatCurrency({ value: Number(subTotalWithoutPrime || 0), maximumFractionDigits: 2 })} USD`}
                </div>
              </div>
              <div className="primeList">
                <div className="keyList">
                  <Trans
                    i18nKey={t(`${translationKey}primeList`)}
                    components={{ 1: <span className="subKeyList"></span> }}
                  />
                </div>
                <div className="valueList">{`${nudosFormatCurrency({
                  value: Number(subTotalDiscountValue || 0)
                })} USD`}</div>
              </div>
              <div className="primeList">
                <div className="keyList">{t(`${translationKey}keyList`)}</div>
                <div className="valueList font-bold">
                  {`${nudosFormatCurrency({ value: Number(subTotalWithPrime || 0) })} USD`}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="grayProductTooltip mb-0" style={{ marginBottom: '0px' }}>
                {`${t(`${translationKey}valueNoDiscount`)}  = ${nudosFormatCurrency({
                  value: Number(subTotalWithoutPrime || 0),
                  maximumFractionDigits: 2
                })} USD`}
              </div>
              <div className="grayProductTooltip">
                {`${t(`${translationKey}valueWithDiscount`)}  = ${nudosFormatCurrency({
                  value: Number(subTotalWithPrime || 0)
                })} USD`}
              </div>
              <div className="endTooltip">
                {t(`recurrentWords:pay`)}
                <strong>
                  &nbsp;
                  {`${nudosFormatCurrency({
                    value: Number(subTotalDiscountValue || 0),
                    maximumFractionDigits: 2
                  })} USD ${t('recurrentWords:less')}`}
                  &nbsp;
                </strong>
                {t(`nodi:dynamicBanner:with`)}
                <div className="endText">&nbsp;Nodi Prime</div>
                <div className="containerIconTooltip">
                  <IconGreenThunder fill="#7B61FF" />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DiscountPercentTooltip;
