import React from 'react';
import './NudosTextArea.scss';

/**
 * React functional component corresponding to the Nudos text area (Nudos long text)
 * @property {string} label - Required
 * @property {string} placeholder - Required
 * @property {string} currentValue - Required, the string with the current text, as it is being stored as state or part of a form.
 * @property {number} maxLength - Required, the maximum number of characters allowed for the field.
 * @property {React.Dispatch<React.SetStateAction<string>>} updateCurrentValueCallback - Required, a callback to update the stored input value on changing the textarea input.
 * @property {string} caption - Optional, a text to display at the left bottom corner of the text area
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {boolean} isDeactivated - Optional, boolean indicating if the field is deactivated. If a value is provided the field will behave as a read only field and styles will be accordingly.
 * @property {boolean} hidenCounter - Optional, boolean indicating if the counter for the number of characters should be hidden
 * @property {boolean} noResize - Optional, a boolean indicating if the text area can or cant be resized
 * @property {string} xPadding - Optional, a valid number for the x padding in pixels (eg: 10)
 * @property {string} yPadding - Optional,a valid number for the y padding in pixels (eg: 10)
 * @property {number} customFontSize - Optional, a valid number for the font size padding in pixels (eg: 10)
 * @property {number} textHeight - Optional, a valid number for the text area height in pixels (eg: 10)
 */
const NudosTextArea = ({
  label,
  placeholder,
  currentValue,
  errorText,
  maxLength,
  updateCurrentValueCallback,
  caption,
  componentSize,
  isDeactivated,
  hidenCounter,
  noResize,
  xPadding,
  yPadding,
  customFontSize,
  textHeight,
  borderError
}: InudosTextArea) => {
  const errorStyles = currentValue.length > maxLength || errorText || borderError ? 'error' : '';
  const filledStyles = currentValue && currentValue.length > 0 && !isDeactivated ? 'filled' : '';
  const deactivatedStyles = isDeactivated ? 'deactivated' : '';

  const handleChange = (event: React.FormEvent) => {
    const { value } = event.target as HTMLTextAreaElement;
    updateCurrentValueCallback(value);
  };

  return (
    <div
      className={`nudosTextArea ${errorStyles} ${filledStyles} ${deactivatedStyles} ${
        componentSize ? componentSize : ''
      }`}
    >
      {label && <label>{label}</label>}
      <textarea
        className={noResize ? 'noRezise' : ''}
        style={{
          height: textHeight ? `${textHeight}px` : '',
          fontSize: customFontSize ? `${customFontSize}px` : '',
          padding: `${yPadding ? `${yPadding}px` : ''} ${xPadding ? `${xPadding}px` : ''}`
        }}
        placeholder={placeholder}
        readOnly={isDeactivated}
        value={currentValue}
        onChange={handleChange}
      />
      <div className="bottomContent">
        {caption && !errorText && <div className="caption">{caption}</div>}
        {errorText && <div className="errorText">{errorText}</div>}
        {!hidenCounter && <div className="lengthText">{`${currentValue?.length}/${maxLength}`}</div>}
      </div>
    </div>
  );
};

export default NudosTextArea;

export interface InudosTextArea {
  label?: string;
  placeholder: string;
  currentValue: string;
  maxLength: number;
  updateCurrentValueCallback: (newValue: string) => void;
  caption?: string;
  errorText?: string;
  componentSize?: string;
  isDeactivated?: boolean;
  hidenCounter?: boolean;
  noResize?: boolean;
  xPadding?: string;
  yPadding?: string;
  customFontSize?: number;
  textHeight?: number;
  borderError?: boolean;
}
