import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6451_3473)">
      <path
        d="M5.99986 6.30538C7.00323 6.30538 7.81662 5.49199 7.81662 4.48862C7.81662 3.48526 7.00323 2.67188 5.99986 2.67188C4.9965 2.67188 4.18311 3.48526 4.18311 4.48862C4.18311 5.49199 4.9965 6.30538 5.99986 6.30538Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.96635 8.62507C8.80769 7.98554 8.44613 7.41312 7.93412 6.99464C7.38822 6.54843 6.70484 6.30469 5.99978 6.30469C5.29472 6.30469 4.61134 6.54843 4.06543 6.99464C3.55344 7.41311 3.19189 7.98554 3.0332 8.62507C3.18656 8.67624 3.3467 8.7119 3.51173 8.73032C4.32123 8.82058 5.15239 8.89807 5.99981 8.89807C6.84722 8.89807 7.67839 8.82058 8.48789 8.73032C8.65289 8.7119 8.813 8.67624 8.96635 8.62507Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57715 0.804688C8.89498 0.838929 9.20998 0.875636 9.52189 0.911781C10.3443 1.0071 11.0005 1.6629 11.0929 2.48565C11.1277 2.79554 11.1628 3.10851 11.1954 3.42432"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
      />
      <path
        d="M8.57715 11.19C8.89498 11.1557 9.20998 11.119 9.52189 11.0829C10.3443 10.9875 11.0005 10.3317 11.0929 9.50897C11.1277 9.19911 11.1628 8.88617 11.1954 8.57031"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
      />
      <path
        d="M3.42296 0.804688C3.10509 0.838929 2.79008 0.875636 2.47818 0.911781C1.65576 1.0071 0.999536 1.6629 0.907162 2.48565C0.87237 2.79554 0.837294 3.10851 0.804688 3.42432"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
      />
      <path
        d="M3.42296 11.19C3.10509 11.1557 2.79008 11.119 2.47818 11.0829C1.65576 10.9875 0.999536 10.3317 0.907162 9.50897C0.87237 9.19911 0.837294 8.88617 0.804688 8.57031"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6451_3473">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuTeamsIcon = memo(ForwardRef);
export default MenuTeamsIcon;
