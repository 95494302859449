import { useHistory } from 'react-router-dom';
import { DetailIcon } from '../../../../../../assets/images/views/Admin/orderList';
import { NudosHoverText } from '../../../../../../components/NudosComponents';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import { DTOlogisticsServiceInListingData } from '../../../../../../types/logisticsServicesModule.types';
import LogisticsStatusDisplay from '../../../Components/LogisticsStatusDisplay/LogisticsStatusDisplay';
import { useTranslation } from 'react-i18next';
import { getNameByPlace } from '../../../../../../utils/getNameByPlace';
import './LogisticServiceCardInListing.scss';

const LogisticServiceCardInListing = ({ logisticsServiceData }: ILogisticsServiceCardInListing) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const {
    id,
    productsQuantity,
    status,
    statusStringId,
    createdAt,
    originName,
    originPlace,
    originFlag,
    destinationName,
    destinationPlace,
    destinationFlag
  } = logisticsServiceData;

  const destinationNameByPlace = getNameByPlace({
    name: destinationName ?? '',
    place: destinationPlace ?? ''
  });

  const originNameByPlace = getNameByPlace({
    name: originName ?? '',
    place: originPlace ?? ''
  });

  const redirectToLogisticServiceDetails = () => push(`logistics-services/details/${id}`);

  const getNumberOfProductsText = (productsQuantity?: number | null) => {
    if (!productsQuantity) return 'Sin datos';
    if (productsQuantity === 1) return `${productsQuantity} ${t('recurrentWords:device')}`;
    if (productsQuantity > 1) return `${productsQuantity} ${t('recurrentWords:devices')}`;
    return 'Sin datos';
  };

  const getLocationUI = (locatioName?: string | null, locationFlag?: string | null) => {
    return (
      <div className="locationContainer">
        <NudosHoverText onlyIfTruncated customClassName="locationName" text={locatioName || ''} charactersLimit={40} />
        <div className="locationFlag" style={{ backgroundImage: `url(${locationFlag || ''})` }} />
      </div>
    );
  };

  return (
    <div className="logisticServiceCardInListing" onClick={redirectToLogisticServiceDetails}>
      <div className="creationDate">{formatShortDate(createdAt || '')?.textWithTimezone}</div>
      <div className="logisticServiceId">SL Nº{id}</div>
      <div className="numberOfProducts withSpace">{getNumberOfProductsText(productsQuantity)}</div>
      <div className="originAndDestination">
        {getLocationUI(originNameByPlace, originFlag)}-{getLocationUI(destinationNameByPlace, destinationFlag)}
      </div>
      <div className="status">
        <LogisticsStatusDisplay statusName={status || ''} statusStringId={statusStringId || ''} />
      </div>
      <DetailIcon className="threeDotsIcon" />
    </div>
  );
};

const LogisticServiceCardInListingSkeletonLoader = () => {
  return (
    <div className="logisticServiceCardInListingSkeletonLoader">
      <div className="creationDate animationLoader" />
      <div className="logisticServiceId animationLoader" />
      <div className="numberOfProducts animationLoader" />
      <div className="originAndDestination animationLoader" />
      <div className="status animationLoader" />
    </div>
  );
};

export { LogisticServiceCardInListing, LogisticServiceCardInListingSkeletonLoader };

interface ILogisticsServiceCardInListing {
  logisticsServiceData: DTOlogisticsServiceInListingData;
}
