import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12} fill="none" ref={ref} {...props}>
    <g clipPath="url(#clip0_11178_11969)">
      <path
        d="M6.02344 11.25C8.92293 11.25 11.2734 8.89949 11.2734 6C11.2734 3.10051 8.92293 0.75 6.02344 0.75C3.12394 0.75 0.773438 3.10051 0.773438 6C0.773438 8.89949 3.12394 11.25 6.02344 11.25Z"
        fill="white"
        stroke="#54A1FC"
      />
      <circle cx="6.02344" cy="3.375" r="0.75" fill="#54A1FC" />
      <rect x="5.27344" y="5.25" width="1.5" height="4.125" rx="0.75" fill="#54A1FC" />
    </g>
    <defs>
      <clipPath id="clip0_11178_11969">
        <rect width="12" height="12" fill="white" transform="translate(0.0234375)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconInformation = memo(ForwardRef);
export default IconInformation;
