import { FC } from 'react';
import { InudoInfo } from '../../../../../types/global';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import {
  IconBuilding,
  IconEmployee,
  IconPin,
  IconReserveBox,
  IconShield,
  IconToolWrench
} from '../../../../../assets/DesignSystem/SVGComponents';
import { Link } from 'react-router-dom';
import LoaderProductLocationCard from './LoaderProductLocationCard';
import { NudosRedirectionIcon } from '../../../../../components/DesignSystem';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import './ProductLocationCard.scss';

interface IProductLocationCard {
  dataLocationCard?: InudoInfo;
  generalLoading?: boolean;
}

const ProductLocationCard: FC<IProductLocationCard> = ({ dataLocationCard, generalLoading }: IProductLocationCard) => {
  const isBuyAndHold = !!dataLocationCard?.isBuyAndHold;
  const nudosToolLocationText = isBuyAndHold ? 'Buy and hold' : 'Almacenamiento';
  const currentAssignment = dataLocationCard?.currentAssignment ? dataLocationCard?.currentAssignment[0] : undefined;
  const lastLocationData = dataLocationCard?.oldAssignment?.find(location => !location?.exitDate);
  const entryDate = formatShortDate(currentAssignment?.entryDate || '')?.onlyDate;
  const lastLocationEntryDate = entryDate || formatShortDate(lastLocationData?.entryDate || '')?.onlyDate || '-';
  const currentMovementLogisticsId = currentAssignment?.logisticServiceId || lastLocationData?.logisticServiceId;
  const currentLocationMovementHadLogistics =
    currentMovementLogisticsId && currentMovementLogisticsId === dataLocationCard?.logisticServiceId;
  const currentLocationMovementLogisticsStatus = currentLocationMovementHadLogistics
    ? dataLocationCard?.logisticStatus
    : undefined;

  const generateDetailData = (place?: TstoragePlace) => {
    switch (place) {
      case 'user':
        return {
          profileImg: currentAssignment?.photoProfile || '',
          icon: <IconEmployee style={{ transform: 'scale(.75)' }} />,
          name: dataLocationCard?.assignedTo,
          countryName: dataLocationCard?.country?.name || '',
          addressDetails: dataLocationCard?.address,
          dateTitle: 'Asignado: '
        };
      case 'nudos':
        return {
          icon: <IconReserveBox />,
          name: 'Bodegas Nudos',
          countryName: dataLocationCard?.country?.name || ''
        };
      case 'office':
        return {
          icon: <IconBuilding style={{ transform: 'scale(0.8)' }} />,
          name: `Oficinas ${dataLocationCard?.country?.name}`,
          countryName: dataLocationCard?.country?.name || '',
          addressDetails: dataLocationCard?.address
        };
      case 'other':
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />,
          name: dataLocationCard?.locationName || 'Otra dirección',
          countryName: dataLocationCard?.country?.name || '',
          addressDetails: dataLocationCard?.address
        };
      case 'warranty':
        return {
          icon: <IconShield style={{ transform: 'scale(1.13)' }} />,
          name: 'Garantia con la marca',
          countryName: dataLocationCard?.country?.name || ''
        };
      case 'diagnostic':
        return {
          name: 'Diagnóstico',
          icon: <IconToolWrench style={{ transform: 'scale(1.13)' }} />,
          countryName: dataLocationCard?.country?.name || ''
        };
      default:
        return { icon: <IconPin style={{ transform: 'scale(0.56)' }} /> };
    }
  };

  const redirectToEmployeeDetails = {
    pathname: `/nodi/employees/details/${dataLocationCard?.userId}`,
    state: {
      originUrl: `/nodi/details/${dataLocationCard?.productId}`,
      returnText: 'Herramienta'
    }
  };

  const detailData = generateDetailData(dataLocationCard?.place);

  if (generalLoading) return <LoaderProductLocationCard />;

  return (
    <div id="productLocationCard">
      <div className="titleLocationCard">Asignado / ubicado:</div>
      {currentLocationMovementHadLogistics && (
        <div className="nameSL">
          <div className="title">Servicio logístico</div>
          <div className="dinamicContainer">
            <div className="blackText">{currentLocationMovementLogisticsStatus}</div>
            <div className="spaceGray">&nbsp;-&nbsp;</div>
            <Link
              to={`/nodi/logistics-services/details/${currentMovementLogisticsId}?from=tools&id=${dataLocationCard?.productId}`}
            >
              <div className="blueText">Ver</div>
            </Link>
          </div>
        </div>
      )}
      <div className="boxProductLocationCard">
        <div className="containerLocationCard">
          <div className="detailsContainerProduct">
            <div className="containerIconLocation">
              <div className="iconBoxGray">
                {dataLocationCard?.place === 'user' && detailData?.profileImg ? (
                  detailData?.profileImg && (
                    <div
                      className="photoStyle"
                      style={{
                        backgroundImage: `url("${detailData?.profileImg?.replace(/\s/g, '%20')}")`
                      }}
                    />
                  )
                ) : (
                  <div className="centerLocationIcon">{detailData?.icon}</div>
                )}
                {dataLocationCard?.country?.flag && (
                  <div
                    className="flagLocation"
                    style={{ backgroundImage: `url("${dataLocationCard?.country?.flag}")` }}
                  />
                )}
              </div>
            </div>
            <div className="contaierLocationName">
              <div className="centerItems">
                <div className="locationNameContainer">
                  <div className="locationNameText">{detailData?.name}</div>
                  {dataLocationCard?.place === 'user' && (
                    <NudosRedirectionIcon redirectionDataObject={redirectToEmployeeDetails} />
                  )}
                </div>
                <div className="movementDates">
                  <span className="dateTitle">{detailData?.dateTitle || 'Ingreso: '}</span>
                  <span className="date">{lastLocationEntryDate}</span>
                </div>
              </div>
            </div>
            <div className="containerAddress">
              <div className="centerItems">
                <div className="countryName">{detailData?.countryName}</div>
                {detailData?.addressDetails && <div className="detailAddress">{detailData?.addressDetails}</div>}
              </div>
            </div>
            {dataLocationCard?.place === 'nudos' && <div className="nudosLocationText">{nudosToolLocationText}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLocationCard;
