import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { sendInviteCollaboratorEmail } from '../../services/signUp.service';
import { DTOOrganizationDocumentAlreadyExistResponse } from '../../types/SignUp';
import { isEmailValid } from '../../utils/formValidations';
import segment, { segmentTrackEvent } from '../../utils/segment';
import { NudosButton, NudosTextInput } from '../NudosComponents';
import { displayErrorNotification, displaySuccessNotification } from '../../utils/displayNudosStandardNotifications';
import { useTranslation, Trans } from 'react-i18next';

const InviteCollaboratorCustomModalComponent = ({
  existingOrganizationData,
  setOpenInviteCollaboratorModal,
  isAnInvitation
}: IinviteCollaboratorCustomModalParams) => {
  const { t } = useTranslation();
  const [inputEmail, setInputEmail] = useState<string>();
  const [emailExistError, setEmailExistError] = useState<string>();
  const [submittedEmail, setSubmittedEmail] = useState<string>();
  const [loading, setLoading] = useState(false);
  const i18nLanguageKey = 'nodi:account:companyForm:inviteCollaborator:';
  const email = existingOrganizationData?.email ? existingOrganizationData.email : '';
  const organization = existingOrganizationData?.name ? existingOrganizationData.name : '';
  const emailInputLabel = isAnInvitation
    ? t(`${i18nLanguageKey}emailInputLabelColaborator`)
    : t(`${i18nLanguageKey}emailInputLabel`);

  const isButtonDisabled = () => {
    if (!inputEmail) return true;
    if (inputEmail.length < 6) return true;
    return !isEmailValid(inputEmail);
  };

  const updateInputEmail = (inputText: string) => {
    setInputEmail(inputText);
    if (emailExistError && inputText !== inputEmail) setEmailExistError(undefined);
  };

  const emailError = () => {
    if (submittedEmail === inputEmail && emailExistError) return emailExistError;
    return '';
  };

  const handleSendEmail = async () => {
    if (!inputEmail) return;
    setSubmittedEmail(inputEmail);
    setLoading(true);
    const body = {
      email: inputEmail,
      organizationId: existingOrganizationData.organizationId,
      registrationInfoId: existingOrganizationData.id,
      isInvite: isAnInvitation ? true : false
    };
    try {
      const response = await sendInviteCollaboratorEmail(body);
      if (response) {
        setOpenInviteCollaboratorModal(false);
        sessionStorage.removeItem('newRegisterInformation');
        !body.isInvite &&
          displaySuccessNotification({
            customJSXMessage: (
              <div>
                <p>¡Gracias por tu interés en Nodi!</p>
                <p>
                  {`El correo se envío a ${existingOrganizationData.email}, `}
                  espera su invitación en tu correo para terminar el registro.
                </p>
              </div>
            )
          });
        body.isInvite &&
          displaySuccessNotification({
            customJSXMessage: (
              <p>
                Se envió correctamente la invitación a{' '}
                {existingOrganizationData?.email || inputEmail || 'la dirección de correo suministrada'}
              </p>
            )
          });
        body.isInvite && segment.sessionEndingEvent();
      }
    } catch (error: unknown) {
      const errorData = error as AxiosError;
      if (errorData?.response?.data?.message === 'emailAlreadyRegistered') {
        setEmailExistError('Este correo ya está registrado, intenta con otro');
      } else {
        displayErrorNotification();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!existingOrganizationData?.collaboratorEmail) return;
    setInputEmail(existingOrganizationData.collaboratorEmail);
  }, [existingOrganizationData]);

  useEffect(() => {
    if (isAnInvitation) return;
    segmentTrackEvent({
      signupNewColaboratorView: {
        CompanyName: existingOrganizationData?.organizationName || '',
        CompanyDocument: existingOrganizationData?.rfc || '',
        CountryName: existingOrganizationData?.countryName || 'Sin información del país',
        ContactMail: existingOrganizationData?.email || ''
      }
    });
  }, []);

  return (
    <div className={`inviteCollaboratorCustomModalComponent ${isAnInvitation ? 'invitation' : ''}`}>
      {!isAnInvitation && <h2 className="modalTitle">Esta empresa ya se encuentra registrada</h2>}
      {!isAnInvitation && (
        <div className="explanation">
          <Trans
            i18nKey={t(`${i18nLanguageKey}explanation`)}
            components={{
              1: <strong></strong>,
              2: ` ${email} `,
              3: `${organization} `
            }}
          />
        </div>
      )}
      {isAnInvitation && (
        <div className="explanation invitation">
          <Trans
            i18nKey={t(`${i18nLanguageKey}invitation`)}
            components={{ 1: <strong></strong>, 2: ` ${organization} ` }}
          />
        </div>
      )}
      <NudosTextInput
        componentSize="medium"
        label={emailInputLabel}
        placeholder="correo@somosnudos.com"
        isFilled={!!inputEmail}
        handleChange={updateInputEmail}
        errorText={emailError()}
        customClassName="invitation"
        defaultValue={existingOrganizationData?.collaboratorEmail}
      />
      {!isAnInvitation && (
        <div>
          <NudosButton
            componentSize="medium"
            buttonText={t(`${i18nLanguageKey}nudosButtonsend`)}
            customClassName="sendButton"
            isButtonDisabled={isButtonDisabled()}
            isButtonLoading={loading}
            handleClick={handleSendEmail}
          />
          <NudosButton
            componentSize="medium"
            colorPalette="secondary"
            buttonText={'Pediré acceso yo mismo'}
            customClassName="askMyselfButton"
            isButtonDisabled={loading}
            handleClick={() => setOpenInviteCollaboratorModal(false)}
          />
        </div>
      )}
      {
        <NudosButton
          componentSize="medium"
          buttonText={t(`${i18nLanguageKey}nudosButtonInvitation`)}
          customClassName="sendButton invitation"
          isButtonDisabled={isButtonDisabled()}
          isButtonLoading={loading}
          handleClick={handleSendEmail}
        />
      }
    </div>
  );
};

export default InviteCollaboratorCustomModalComponent;

export interface IinviteCollaboratorCustomModalParams {
  setOpenInviteCollaboratorModal: React.Dispatch<React.SetStateAction<boolean>>;
  isAnInvitation: boolean;
  existingOrganizationData: DTOOrganizationDocumentAlreadyExistResponse;
}
