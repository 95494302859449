import { SPECIAL_BUSINESS_NAME_TYPES } from '../types/checkout';

interface IValidatePaymentMethods {
  storeCountryCode: string;
  billinDataCountryCode: string;
  documentCountryCode: string;
  specialBusinessNameTypeId?: SPECIAL_BUSINESS_NAME_TYPES;
}

const validatePaymentMethods = ({
  storeCountryCode,
  billinDataCountryCode,
  documentCountryCode,
  specialBusinessNameTypeId
}: IValidatePaymentMethods) => {
  const internationalCode = 'xx';
  const countriesWhereWeCanBill = ['ar', 'co', 'mx', 'pe', 'uy', 'us'];
  const isUruguayanSale = storeCountryCode === 'uy';
  // The order of this statements matter, dont change without consideration
  if (specialBusinessNameTypeId === SPECIAL_BUSINESS_NAME_TYPES.TRADING) return internationalCode;
  if (isUruguayanSale) return 'uy';
  if (storeCountryCode !== billinDataCountryCode) return internationalCode;
  if (!countriesWhereWeCanBill.includes(billinDataCountryCode)) return internationalCode;
  return documentCountryCode;
};

export { validatePaymentMethods };
