import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g>
      <path
        d="M1.64542 17.6032C1.90964 20.073 3.89599 22.0593 6.36449 22.3344C10.1561 22.757 13.8437 22.757 17.6353 22.3344C20.1039 22.0593 22.0902 20.073 22.3544 17.6032C22.5495 15.78 22.7142 13.9083 22.7142 12C22.7142 10.0917 22.5495 8.21997 22.3544 6.39681C22.0902 3.92712 20.1039 1.94076 17.6353 1.66561C13.8437 1.24297 10.1561 1.24297 6.36449 1.66561C3.89599 1.94076 1.90964 3.92712 1.64542 6.39681C1.45036 8.21997 1.28564 10.0917 1.28564 12C1.28564 13.9083 1.45036 15.78 1.64542 17.6032Z"
        fill="white"
        stroke="#FF664E"
      />
      <path d="M16 12H8" stroke="#FF664E" strokeLinecap="round" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const RemoveIcon = memo(ForwardRef);
export default RemoveIcon;
