import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6248_1231)">
      <path
        d="M0.822739 8.80159C0.954853 10.0365 1.94802 11.0296 3.18228 11.1672C5.0781 11.3785 6.92189 11.3785 8.81768 11.1672C10.052 11.0296 11.0451 10.0365 11.1772 8.80159C11.2748 7.89001 11.3571 6.95413 11.3571 5.99999C11.3571 5.04586 11.2748 4.10998 11.1772 3.1984C11.0451 1.96356 10.052 0.97038 8.81768 0.832804C6.92189 0.621487 5.0781 0.621487 3.18228 0.832804C1.94802 0.97038 0.954853 1.96356 0.822739 3.1984C0.72521 4.10998 0.642853 5.04586 0.642853 5.99999C0.642853 6.95413 0.725211 7.89001 0.822739 8.80159Z"
        fill="white"
        stroke={props?.stroke || '#ff664e'}
      />
      <path d="M7.51523 4.48486L4.48477 7.51532" stroke={props?.stroke || '#ff664e'} strokeLinecap="round" />
      <path d="M7.51523 7.51532L4.48477 4.48486" stroke={props?.stroke || '#ff664e'} strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_6248_1231">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CloseIcon = memo(ForwardRef);
export default CloseIcon;
