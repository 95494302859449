import { useEffect, useState } from 'react';
import {
  NudosBillingCountryDropdown,
  NudosBillingDataFormModal,
  NudosStaticAlertInComponent
} from '../../../../components/DesignSystem';
import { NudosButton, NudosSelectButton } from '../../../../components/NudosComponents';
import { ModalShareInvoice } from './Components';
import { IconArrow } from '../../../../assets/DesignSystem/SVGComponents';
import { IbillingData } from '../../../../types/checkout';
import { ICountryDetail } from '../../../../types/countries';
import { EditPencilIcon } from '../../../../assets/images/views/Admin/employees';
import useLogisticsServiceBillingState from '../../../../state/useLogisticsQuoteAndBilling';
import NudosBillingDataSummaryComponent from '../../../../components/DesignSystem/BillingDataSummary/NudosBillingDataSummaryComponent';
import RequestOtherBillingCountryForLogisticService from './Components/RequestOtherBillingCountryForLogisticService/RequestOtherBillingCountryForLogisticService';
import './LogisticServiceBillingData.scss';

const LogisticServiceBillingData = ({
  allCountries,
  availableCountries,
  updateBillingData,
  organizationBillingData,
  cleanUpdatedBillingData
}: {
  allCountries: ICountryDetail[];
  availableCountries: ICountryDetail[];
  updateBillingData: React.Dispatch<React.SetStateAction<boolean>>;
  organizationBillingData?: IbillingData[];
  cleanUpdatedBillingData: () => void;
}) => {
  const {
    setLogisticsServiceBillingData,
    invoiceAdditionalEmail,
    setInvoiceAdditionalEmail,
    logisticsServiceBillingData
  } = useLogisticsServiceBillingState();
  const [showShareInvoiceEmailModal, setShowShareInvoiceEmailModal] = useState(false);
  const [showLogisticBillingDataModal, setShowLogisticBillingDataModal] = useState(false);

  const hasBillingDataForSomeCountry = organizationBillingData && !!organizationBillingData.length;
  const showBillingDataDropdown = !logisticsServiceBillingData && hasBillingDataForSomeCountry;
  const showAddBillingButton = !logisticsServiceBillingData && !hasBillingDataForSomeCountry;
  const showBillinDataSummary = logisticsServiceBillingData;

  const openBillingDataModal = () => setShowLogisticBillingDataModal(true);
  const closeBillingDataModal = () => setShowLogisticBillingDataModal(false);

  const submitModalCallback = (newData?: IbillingData) => {
    setLogisticsServiceBillingData(newData || undefined);
    updateBillingData(true);
    closeBillingDataModal();
  };
  const handleCheckBoxClick = () => {
    if (!invoiceAdditionalEmail) setShowShareInvoiceEmailModal(true);
    if (invoiceAdditionalEmail) setInvoiceAdditionalEmail('');
  };

  const setBillingDataWhenJustOneExist = () => {
    if (organizationBillingData && organizationBillingData?.length === 1)
      setLogisticsServiceBillingData(organizationBillingData[0]);
  };

  useEffect(() => {
    setBillingDataWhenJustOneExist();
  }, []);
  return (
    <div className="LogisticServiceBillingData">
      <div className="logisticServiceBillingContainer">
        {showShareInvoiceEmailModal && (
          <ModalShareInvoice
            emailShareInvoice={invoiceAdditionalEmail}
            closeModalCallback={() => setShowShareInvoiceEmailModal(false)}
            setEmailShareInvoice={setInvoiceAdditionalEmail}
          />
        )}
        {showLogisticBillingDataModal && (
          <NudosBillingDataFormModal
            customZIndex={79}
            initialBillingData={logisticsServiceBillingData}
            closeModalCallback={closeBillingDataModal}
            submitModalCallback={submitModalCallback}
            availableCountries={availableCountries || []}
          />
        )}
        <div className="billingSummaryTitle">Facturación</div>
        <NudosStaticAlertInComponent
          type="warning"
          color="gray"
          keepTypeIconButChangeColor
          alertOptions={{
            title:
              'Los servicios logísticos son facturados desde USA y cobrados en USD a través del link de pago que recibirás en tu correo al finalizar el servicio'
          }}
        />
        <RequestOtherBillingCountryForLogisticService listOfCountries={allCountries} />
        {showAddBillingButton && (
          <NudosButton
            buttonText="Agregar datos de facturación"
            buttonIcon={<IconArrow className="IconArrow" />}
            handleClick={openBillingDataModal}
            customWidth="162px"
            customHeight="28px"
          />
        )}
        {showBillingDataDropdown && (
          <NudosBillingCountryDropdown
            componentSize="medium"
            countriesList={allCountries || []}
            currentValue={logisticsServiceBillingData}
            billingDataForAllCountriesProp={organizationBillingData}
            updateCurrentValueCallback={(newValue: IbillingData) => setLogisticsServiceBillingData(newValue)}
            addNewBillingCountryCallback={openBillingDataModal}
          />
        )}
        {showBillinDataSummary && (
          <NudosBillingDataSummaryComponent
            billingData={logisticsServiceBillingData}
            openBillingDataModal={openBillingDataModal}
            cleanUpdatedBillingData={cleanUpdatedBillingData}
          />
        )}
      </div>
      <div className="shareInvoiceSectionContainer">
        <div className="shareInvoiceButton">
          <NudosSelectButton
            isButtonActive={!!invoiceAdditionalEmail}
            handleClickButton={() => handleCheckBoxClick()}
          />
          Enviar factura e información de pago a un correo adicional
        </div>
        {invoiceAdditionalEmail && (
          <div className="shareInvoiceEmail">
            {invoiceAdditionalEmail}
            <EditPencilIcon className="editPencilIcon" onClick={() => setShowShareInvoiceEmailModal(true)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LogisticServiceBillingData;
