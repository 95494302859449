import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ShipmentStatusTracker.scss';

/**
 * @property {string} shipmentStatus - The name of the shipment status, must be one of "Pendiente de confirmación", "Confirmado", "Proceso de entrega", "Entregado" or "Cancelado". If none one of those values the component behaves as if the status was "Pendiente de confirmación"
 */
const ShipmentStatusTracker = ({ shipmentStatus }: { shipmentStatus?: string | null }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>();
  const [isCanceled, setIsCanceled] = useState(false);
  const i18nLanguageKey = 'nodi:orderDetails:shipmentStatusTracker:';

  const getCurrentStatusStep = (shipmentStatus: string) => {
    if (shipmentStatus === 'Pendiente de confirmación' || shipmentStatus === 'Cancelado') return setCurrentStep(0);
    if (shipmentStatus === 'Confirmado') return setCurrentStep(1);
    if (shipmentStatus === 'Proceso de entrega') return setCurrentStep(2);
    if (shipmentStatus === 'Entregado') return setCurrentStep(3);
    return setCurrentStep(0);
  };

  const getConnector = (activeStyle: string, width?: number, customClass?: string) => {
    const widthStyle = { width: typeof width === 'number' ? `${width}px` : '16px' };
    const isFirstOrLastConnector = customClass === 'firstConnector' || customClass === 'lastConnector';
    return (
      <div className={`connector ${activeStyle} ${customClass}`} style={widthStyle}>
        {isFirstOrLastConnector && <div className={`circle ${activeStyle}`} />}
      </div>
    );
  };

  const shipmentSteps = [
    { order: 0, text: isCanceled ? t('recurrentWords:canceled') : t(`${i18nLanguageKey}order0`), width: '117px' },
    { order: 1, text: t(`${i18nLanguageKey}order1`), width: '79px' },
    { order: 2, text: t(`${i18nLanguageKey}order2`), width: '127px' },
    { order: 3, text: t(`${i18nLanguageKey}order3`), width: '79px' }
  ];

  const lastConnectorStyle = currentStep && currentStep >= 3 ? 'active' : '';

  useLayoutEffect(() => {
    shipmentStatus && getCurrentStatusStep(shipmentStatus);
    if (shipmentStatus === 'Cancelado') setIsCanceled(true);
  }, [shipmentStatus]);

  return (
    <>
      {shipmentStatus && typeof currentStep === 'number' && (
        <div className="shipmentStatusTracker">
          {shipmentSteps.map(step => {
            const stepActiveStyle = currentStep < step.order ? '' : 'active';
            const customClass = step.order === 0 ? 'firstConnector' : undefined;
            const connectorWidth = step.order === 0 ? 32 : 16;
            const canceledStyle = step.order === 0 && isCanceled ? 'canceled' : '';
            return (
              <div
                key={`shipmentStep${step.order}`}
                className={`shipmentStep shipmentStep${step.order} ${canceledStyle}`}
              >
                {getConnector(stepActiveStyle, connectorWidth, customClass)}
                <div className={`textBox ${stepActiveStyle}`} style={{ width: step.width }}>
                  {step.text}
                </div>
              </div>
            );
          })}
          {getConnector(lastConnectorStyle, 32, 'lastConnector')}
        </div>
      )}
      {(!shipmentStatus || typeof currentStep !== 'number') && <ShipmentStatusTrackerSkeletonLoader />}
    </>
  );
};

const ShipmentStatusTrackerSkeletonLoader = () => {
  return (
    <div className="shipmentStatusTrackerSkeletonLoader">
      <div className="stepSkeleton stepSkeleton1 animationLoader" />
      <div className="stepSkeleton stepSkeleton2 animationLoader" />
      <div className="stepSkeleton stepSkeleton3 animationLoader" />
      <div className="stepSkeleton stepSkeleton4 animationLoader" />
    </div>
  );
};

export { ShipmentStatusTracker, ShipmentStatusTrackerSkeletonLoader };
