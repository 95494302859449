import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    id="arrow"
    width={12}
    height={12}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2.4001 4.80005C3.69419 6.37952 4.48327 7.16874 6.0001 8.40005C7.51693 7.16874 8.306 6.37952 9.6001 4.80005"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MinArrowFilter = memo(ForwardRef);
export default MinArrowFilter;
