import { signOut } from '@firebase/auth';
import { auth } from '../state/firebase';
import { displayErrorNotification } from './displayNudosStandardNotifications';

export function logoutDueToErrorInAuthToken() {
  // Clear local storage items first
  [
    'orgData',
    'sessionLogin',
    'bagItems',
    'savedStep',
    'ajs_anonymous_id',
    'typeLogin',
    'activeWasShown',
    'personalData'
  ].forEach(storedElement => localStorage.removeItem(`${storedElement}`));
  // Log out from Firebase auth
  localStorage.setItem('tokenExpired', 'true');
  signOut(auth)
    .then(() => {
      window.location.replace('/login');
    })
    .catch(error => {
      // Handle any errors that occur during signOut
      displayErrorNotification();
      console.error('Error during logout:', error);
    });
}
