import NudosLogisticsGeneralContainer from '../../../../../components/DesignSystem/NudosLogisticsGeneralContainer/NudosLogisticsGeneralContainer';
import {
  IadditionalServiceDataForToolLogistics,
  IlocationAddressForLogistics,
  ItoolEssentialData,
  IwarehouseData,
  TlogisticsOriginOrDestinationData
} from '../../../../../types/requestLogisticsModule';
import { NudosToolLogisticsWithAdditionalServices } from '../../../Components';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import { NudosStaticAlertInComponent } from '../../../../../components/DesignSystem';
import { IconLightbulb } from '../../../../../assets/DesignSystem/SVGComponents';
import { LOCATION_PLACE_TYPE } from '../../../../../types/global';
import './ToolsManagementStep3Tools.scss';

const ToolsManagementStep3Tools = ({
  toolsToManage,
  handleUpdateSelectedToolsToAssign,
  selectedDestination,
  originData,
  destinationData,
  nudosWarehousesList
}: {
  toolsToManage?: ItoolEssentialData[];
  handleUpdateSelectedToolsToAssign: (newToolsToAssign: ItoolEssentialData[]) => void;
  selectedDestination?: TstoragePlace;
  originData?: IlocationAddressForLogistics;
  destinationData?: TlogisticsOriginOrDestinationData;
  nudosWarehousesList?: IwarehouseData[];
}) => {
  const selectedOrigin = originData?.place || '';

  const originOrDestinationWarehouse =
    selectedOrigin === 'nudos'
      ? (originData as IlocationAddressForLogistics)
      : selectedDestination === 'nudos'
      ? (destinationData as IlocationAddressForLogistics)
      : undefined;

  const originOrDestinationWarehouseLocationId =
    originOrDestinationWarehouse?.locationId || originOrDestinationWarehouse?.id;

  const nudosWarehouseAtOriginOrDestinationData = originOrDestinationWarehouseLocationId
    ? nudosWarehousesList?.find(warehouse => warehouse?.locationId === originOrDestinationWarehouseLocationId)
    : undefined;

  const warehouseAditionalServices = nudosWarehouseAtOriginOrDestinationData?.logisticAdditionalServices || [];
  const atLeastOneToolCanHaveAdditionalServices = toolsToManage?.some(
    tool =>
      (tool.category === 'Laptops' ||
        tool?.category === 'Laptops CTOs' ||
        tool?.category === 'Laptops con DEP' ||
        tool.category === 'PC') &&
      warehouseAditionalServices?.length
  );

  const isAssignment = selectedDestination === LOCATION_PLACE_TYPE.USER;
  const showAdditionalServicePurpleAlert = atLeastOneToolCanHaveAdditionalServices && isAssignment;

  const listingWithoutAdditionalServicesStyle = atLeastOneToolCanHaveAdditionalServices
    ? undefined
    : 'noAdditionalServices';

  const additionalServicesPossibilityAlertOptions = {
    title: 'Puedes escoger servicios adicionales para entregar los equipos preparados a tu empleado',
    icon: <IconLightbulb className="IconLightbulb" />
  };

  const updateAdditionalServicesForTheTool = (
    specificTool?: ItoolEssentialData,
    type?: 'edit' | 'delete',
    additionalServicesForSpecificTool?: IadditionalServiceDataForToolLogistics[]
  ) => {
    if (!specificTool || type !== 'edit') return;
    specificTool.newLogisticAdditionalServices = additionalServicesForSpecificTool;
    handleUpdateSelectedToolsToAssign([specificTool]);
  };

  return (
    <div className={`ToolsManagementStep3Tools`}>
      <NudosLogisticsGeneralContainer title="Confirma la herramienta que deseas asignar">
        <section className="selectedToolsSection">
          <div className="sectionTitle">Herramientas seleccionadas</div>
          <div className={`toolsContainer ${listingWithoutAdditionalServicesStyle}`}>
            {toolsToManage?.map((tool, i) => {
              return (
                <NudosToolLogisticsWithAdditionalServices
                  key={`tool${tool?.productId}-${i}`}
                  toolData={tool}
                  isSelected
                  toolHasSelectButton={false}
                  toolLogisticsPlaceThatMayBeWarehouse={nudosWarehouseAtOriginOrDestinationData ? 'nudos' : undefined}
                  warehouseAditionalServices={warehouseAditionalServices}
                  updateToolsToAssignOrUnassignData={updateAdditionalServicesForTheTool}
                  destination={selectedDestination as LOCATION_PLACE_TYPE}
                />
              );
            })}
          </div>
          {showAdditionalServicePurpleAlert && (
            <NudosStaticAlertInComponent alertOptions={additionalServicesPossibilityAlertOptions} />
          )}
        </section>
      </NudosLogisticsGeneralContainer>
    </div>
  );
};

export default ToolsManagementStep3Tools;
