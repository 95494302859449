import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={17} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6531_143406)">
      <path
        d="M15.8008 8.49888C15.8008 9.699 12.6029 13.9275 8.65799 13.9275C4.7131 13.9275 1.51514 9.699 1.51514 8.49888C1.51514 7.29877 4.7131 3.07031 8.65799 3.07031C12.6029 3.07031 15.8008 7.29877 15.8008 8.49888Z"
        fill="white"
        stroke="#ff664e"
        strokeLinejoin="round"
      />
      <path
        d="M8.65827 10.7863C9.92064 10.7863 10.944 9.76292 10.944 8.50056C10.944 7.23819 9.92064 6.21484 8.65827 6.21484C7.39591 6.21484 6.37256 7.23819 6.37256 8.50056C6.37256 9.76292 7.39591 10.7863 8.65827 10.7863Z"
        fill="white"
        stroke="#ff664e"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6531_143406">
        <rect width="16" height="16" fill="white" transform="translate(0.658203 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const OpenEye = memo(ForwardRef);
export default OpenEye;
