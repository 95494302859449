import { v4 as uuid } from 'uuid';

import './loaderOrderCard.scss';

const LoaderCard = () => {
  const cards = Array(20).fill('');

  const generateLoaderCards = () => {
    return (
      <div id="loaderOrderCard" key={uuid()}>
        <div className="loaderDateOrderCard">
          <div className="fakeDate animationLoader" />
        </div>
        <div className="loaderNumberOrderCard">
          <div className="loadNumber animationLoader" />
        </div>
        <div className="loaderDeliveryNumberOrderCard">
          <div className="loadShipping animationLoader" />
        </div>
        <div className="loaderPaymentMethodOrderCard">
          <div className="loadAddress animationLoader" />
        </div>
        <div className="loaderAmountOrderCard">
          <div className="loadCountry animationLoader" />
        </div>
        <div className="loaderVoucherOrderCard">
          <div className="animationLoader loaderProducts" />
        </div>
        <div className="loaderStatusOrderCard">
          <div className="animationLoader loaderStatus" />
        </div>
      </div>
    );
  };

  return (
    <>
      {cards &&
        cards.length > 0 &&
        cards.map(() => {
          return generateLoaderCards();
        })}
    </>
  );
};
export default LoaderCard;
