import { FC } from 'react';
import { ILocationAddress } from '../../../../../types/account';
import { useTranslation } from 'react-i18next';
import './countryCard.scss';

interface IListAddressCard {
  cardData: ILocationAddress;
  editAddress?: () => void;
}
const CountryCard: FC<IListAddressCard> = ({ cardData, editAddress }: IListAddressCard) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:account:organizationAddressesTab:countryCard:';

  return (
    <div id="countryCard">
      <div className="boxCountryCard">
        <div className="countryFlag">
          <div className="myFlag">
            {cardData?.country && <img alt="flag_icon" src={cardData?.country?.flag || ''} />}
          </div>
        </div>
        <div className="defaultAddress">
          <div>
            <div className="title">{t(`${i18nLanguageKey}title`)}</div>
            {cardData?.address ? (
              <div className="addressContainer">
                <div className="addressText">{`${cardData?.address}${cardData?.city ? ', ' : ''}${
                  cardData?.city
                }`}</div>
                <div
                  className="blueEditText"
                  onClick={() => {
                    if (editAddress) {
                      editAddress();
                    }
                  }}
                >
                  {t(`${i18nLanguageKey}blueEditText`)}
                </div>
              </div>
            ) : (
              <div className="noAddresSubtitle">{t(`${i18nLanguageKey}noAddresSubtitle`)}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CountryCard;
