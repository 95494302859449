import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11} height={11} fill="none" ref={ref} {...props}>
    <path
      d="M2.15625 6.64062L4.42898 8.98438C5.49844 5.91228 6.3891 4.5642 8.40625 2.73438"
      stroke={props?.stroke || '#383838'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconSimpleCheck = memo(ForwardRef);
export default IconSimpleCheck;
