import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IaddressFromGoogleMaps, IdeliveryAddress } from '../../../types/global';
import { ICountryDetail } from '../../../types/countries';
import { NudosAddressSearchBar, NudosSelectDropdown, NudosTextInput } from '../../NudosComponents';
import { getAddressErrorText } from '../../../utils/formValidations';
import { NudosCityDropdown } from '..';
import { IcityDetails } from '../../../types/cities.types';
import './NudosLogisticsCardAddressForm.scss';

/**
 * @property { (newAddressData?: IdeliveryAddress) => void } addressUpdaterCallback - the callback function to update the address state in the parent component
 * @property { (newAddressCountry?: ICountryDetail) => void } addressCountryUpdaterCallback - the callback function to update the country data for the address state in the parent component
 * @property { ICountryDetail[] } listOfCountries - the list of countries to which the address can belong
 * @property { IdeliveryAddress } initialAddressData - the initial address data, with which all the address fields in the form  will be preloaded
 * @property { ICountryDetail } initialAddressCountry - the data of the country to which the initial address belong
 * @property { boolean } disableCountryUpdating - a boolean to disabled the updating of the country, forcing the address selection to the same country as the default
 */
const NudosLogisticsCardAddressForm = ({
  currentCity,
  cityUpdaterCallback,
  addressUpdaterCallback,
  addressCountryUpdaterCallback,
  listOfCountries,
  initialAddressData,
  initialAddressCountry,
  disableCountryUpdating,
  cityId
}: {
  currentCity?: IcityDetails;
  cityUpdaterCallback?: (newCity?: IcityDetails) => void;
  addressUpdaterCallback: (newAddressData?: IdeliveryAddress) => void;
  addressCountryUpdaterCallback: (newAddressCountry?: ICountryDetail) => void;
  listOfCountries: ICountryDetail[];
  initialAddressData?: IdeliveryAddress;
  initialAddressCountry?: ICountryDetail;
  disableCountryUpdating?: boolean;
  cityId?: number;
}) => {
  const { t } = useTranslation();

  const [logisticsAddress, setLogisticsAddress] = useState<IdeliveryAddress>(emptyAddress);
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();

  const i18nLanguageKey = 'designSystemComponents:nudosLogisticsCardAddressForm';

  const updateAddressFromGoogleMaps = (newAddress: IaddressFromGoogleMaps) => {
    setLogisticsAddress({
      ...logisticsAddress,
      address: newAddress?.address || '',
      coordinates: newAddress?.coordinates,
      country: newAddress?.country
    });
  };
  const updateAdditionalReferences = (newAdditionalReferences: string) =>
    setLogisticsAddress({ ...logisticsAddress, additionalReferences: newAdditionalReferences || '' });
  const updateZipCode = (newZipCode: string) => setLogisticsAddress({ ...logisticsAddress, zipCode: newZipCode });
  const updateSelectedCountry = (newSelectedCountry: ICountryDetail) => setSelectedCountry(newSelectedCountry);
  const updateCityCallback = (newCity?: IcityDetails) => {
    setLogisticsAddress({ ...logisticsAddress, city: newCity?.name, cityId: newCity?.id });
    cityUpdaterCallback && cityUpdaterCallback(newCity);
  };

  const countryOptions = listOfCountries.map((option, i) => {
    return (
      <div key={`countryOption${i}`} className={`optionContainer`} onClick={() => updateSelectedCountry(option)}>
        {option.name}
      </div>
    );
  });

  const addressErrorText = !logisticsAddress?.address
    ? t(`${i18nLanguageKey}:addressErrorText`)
    : getAddressErrorText(
        selectedCountry?.code,
        selectedCountry?.name,
        logisticsAddress?.country || undefined,
        true,
        logisticsAddress?.coordinates
      );
  const zipCodeErrorText = !logisticsAddress?.zipCode ? t(`${i18nLanguageKey}:zipCodeErrorText`) : undefined;

  const addressSearchBarCustomStyles = { height: addressErrorText ? '80px' : '70px' };
  const additionalReferencesErrorText =
    logisticsAddress?.address && !logisticsAddress?.additionalReferences
      ? t(`${i18nLanguageKey}:additionalReferencesErrorText`)
      : '';

  const initialCity = () => {
    if (cityId && initialAddressData?.city && cityUpdaterCallback) {
      cityUpdaterCallback({ name: initialAddressData.city, id: cityId });
    }
  };

  useEffect(() => {
    addressUpdaterCallback(logisticsAddress);
  }, [logisticsAddress]);

  useEffect(() => {
    addressCountryUpdaterCallback(selectedCountry);
  }, [selectedCountry]);

  useEffect(() => {
    if (initialAddressData) setLogisticsAddress(initialAddressData);
    if (!selectedCountry && initialAddressCountry) setSelectedCountry(initialAddressCountry);
    initialCity();
  }, [initialAddressData, initialAddressCountry]);

  return (
    <div className="NudosLogisticsCardAddressForm">
      <h2 className="title">{t(`${i18nLanguageKey}:address`)}</h2>
      <div className="formItems formItems1">
        <NudosSelectDropdown
          selectOptions={countryOptions}
          label={t(`${i18nLanguageKey}:country`)}
          currentValueOrplaceholder={selectedCountry?.name || t(`${i18nLanguageKey}:country`)}
          isFilled={!!selectedCountry}
          componentSize="small-184"
          isDeactivated={disableCountryUpdating}
        />
        <NudosCityDropdown
          countryId={selectedCountry?.id}
          componentSize={'small-184'}
          updateCurrentValueCallback={updateCityCallback}
          currentValue={currentCity}
          isFocused={true}
        />
      </div>
      <div className="formItems formItems2">
        <NudosAddressSearchBar
          label={t(`${i18nLanguageKey}:addressSearchBar:label`)}
          customPlaceholder={t(`${i18nLanguageKey}:addressSearchBar:placeHolder`)}
          componentSize="large"
          handleAddressSelection={updateAddressFromGoogleMaps}
          defaultValueAddressName={logisticsAddress?.address || undefined}
          errorText={addressErrorText}
          regionBias={selectedCountry?.code}
          didntFindAddressTooltipTopPosition={56 + (addressErrorText ? 10 : 0)}
          didntFindAddressTooltipRightPosition={0}
          componentCustomStyles={addressSearchBarCustomStyles}
        />
      </div>
      <div className="formItems formItems3">
        <NudosTextInput
          componentSize="small-184"
          label={t(`${i18nLanguageKey}:directionsInput:label`)}
          placeholder={t(`${i18nLanguageKey}:directionsInput:placeHolder`)}
          errorText={additionalReferencesErrorText}
          isFilled={!!logisticsAddress?.additionalReferences}
          handleChange={updateAdditionalReferences}
          defaultValue={logisticsAddress?.additionalReferences || undefined}
        />
        <NudosTextInput
          componentSize="extraSmall"
          label={t(`${i18nLanguageKey}:zipCodeInput:label`)}
          placeholder={t(`${i18nLanguageKey}:zipCodeInput:placeHolder`)}
          isFilled={!!logisticsAddress?.zipCode}
          handleChange={updateZipCode}
          defaultValue={logisticsAddress?.zipCode || undefined}
          errorText={zipCodeErrorText}
        />
      </div>
    </div>
  );
};

export default NudosLogisticsCardAddressForm;

const emptyAddress = {
  address: '',
  coordinates: undefined,
  additionalReferences: '',
  receiverInformation: undefined,
  country: undefined,
  zipCode: undefined,
  city: undefined
};
