import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { formatOrgData } from '../../../utils/orgFormatData';
import {
  AssignmentModuleStep1SelectTools,
  AssignmentModuleStep2ConfirmAddresses,
  AssignmentModuleStep3SelectDate,
  AssignmentModuleStep4SendEmail,
  AssignmentModuleStep5DataVerification
} from './AssignmentModuleSteps';
import { NudosBreadcrumbButton, NudosButton } from '../../../components/NudosComponents';
import { AssignmentModuleStepper } from './Components';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { getBoardFilters, getSubscriptions } from '../../../services/services';
import { GenericLoader } from '../../Admin/dashboard/components';
import {
  IOutRange,
  IadditionalServiceDataForToolLogistics,
  IlocationAddressForLogistics,
  IlogisticAdditionalService,
  ItoolEssentialData,
  TlogisticsOriginOrDestinationData
} from '../../../types/requestLogisticsModule';
import {
  aproximateDays,
  getNudosWarehousesListIncludingAdditionalServices,
  isAutomatable,
  rejectedLogisticService
} from '../../../services/logisticsServicesModule.services';
import { ICountryDetail } from '../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import { DTOgetModuleFilters, IQueryTools } from '../../../types/DTO';
import { IDocumentDetail, Iemployee, TnudosCare } from '../../../types/global';
import { formatDateFromDateRange } from '../../../utils/formatDateRange';

import { IDetailsQuote, TstoragePlace } from '../../../types/assignationFluxes';
import {
  assignOrUnassign,
  formatCheckQuoteAndBillingDataTools,
  formatDateRange,
  getEmployeeDataByUserId,
  getLocationData,
  isDataComplete,
  updateLocationsData
} from '../utils/requestNewLogisticsUtilFunctions';
import LogisticsQuoteAndBillingStep from '../LogisticsQuoteAndBillingStep/LogisticsQuoteAndBillingStep';
import { rangeHorus } from '../../../utils/calculateDateRangeHours';
import { IIsAutomatable, IToolsRejected } from '../../../types/logisticsServicesModule.types';
import useLogisticsServiceBillingState from '../../../state/useLogisticsQuoteAndBilling';
import { CancelLogisticServiceModal } from '../LogisticsQuoteAndBillingStep/LogisticServicePrincingQuote/Components';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../utils/segment';
import './AssignmentModule.scss';

const AssignmentModule = () => {
  const { t } = useTranslation();

  const { userId: destinationUserId } = useParams<{ userId: string }>();
  const {
    invoiceAdditionalEmail,
    billingCountry: selectedBillingCountry,
    logisticsServiceBillingData,
    setLogisticsServiceBillingData,
    cleanLsBillingSate
  } = useLogisticsServiceBillingState();
  const { search } = useLocation();
  const { push } = useHistory();
  const orgData = formatOrgData();
  const [showTimeOutModal, setShowTimeOutModal] = useState<boolean>(false);
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<DTOgetModuleFilters>();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [toolsAvailableToAssignAccordingToAppliedFilters, setToolsAvailableToAssignAccordingToAppliedFilters] =
    useState<ItoolEssentialData[]>();
  const [allToolsAvailableToAssignForThisOrganization, setAllToolsAvailableToAssignForThisOrganization] =
    useState<ItoolEssentialData[]>();
  const [listOfCountriesOpenForLogistics, setListOfCountriesOpenForLogistics] = useState<ICountryDetail[]>();
  const [listOfAllCountries, setListOfAllCountries] = useState<ICountryDetail[]>();
  const [loadingSubmitting, setLoadingSummitting] = useState(false);
  const [loadingOrigin, setLoadingOrigin] = useState<boolean>(false);
  const [loadingDestination, setLoadingDestination] = useState<boolean>(false);
  const [selectedOrigin, setSelectedOrigin] = useState<TstoragePlace>();
  const [originData, setOriginData] = useState<TlogisticsOriginOrDestinationData>();
  const [destinationData, setDestinationData] = useState<Iemployee>();
  const [selectedToolsToAssign, setSelectedToolsToAssign] = useState<ItoolEssentialData[]>();
  const [toolsOriginCountryWarehouseAdditionalServices, setToolsOriginCountryWarehouseAdditionalServices] =
    useState<IlogisticAdditionalService[]>();
  const [extraQueryData, setExtraQueryData] = useState<IQueryTools>({ extraQuery: '', stringQuery: '' });
  const [firstRender, setFirstRender] = useState<boolean>(false);
  const [showExitWarning, setShowExitWarning] = useState<boolean>(false);
  const [loadingRejected, setLoadingRejected] = useState<boolean>(false);

  // states step 3 ranges
  const [dateRange, setDateRange] = useState<IOutRange>();
  const [logisticsEstimatedTimeByCountry, setLogisticsEstimatedTimeByCountry] = useState<string>('');
  const [aproximateDay, setAproximateDay] = useState<number>(0);
  const [existDateRange, setExistDateRange] = useState<boolean>(false);
  const [collectionDetailsText, setCollectionDetailsText] = useState<string>();
  const [formatDeliveryDateRange, setFormatDeliveryDateRange] = useState<string>();

  // states step 4 email and message
  const [employeMessage, setEmployeeMessage] = useState<string>();
  const [optionalEmployeeFile, setOptionalEmployeeFile] = useState<IDocumentDetail>();

  const [detailsQuote, setDetailsQuote] = useState<IDetailsQuote>();
  const [loadingQuote, setLoadingQuote] = useState<boolean>(false);
  const [errorQuote, setErrorQuote] = useState<boolean>(false);
  const [rejectComments, setRejectComments] = useState<string>();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showCanQuoteBeAutomated, setShowCanQuoteBeAutomated] = useState<boolean>(false);
  const [loadingShowBillingData, setLoadingShowBillingData] = useState<boolean>(false);

  const next = t('nodi:onboarding:buttons:next');
  const skipText = t('recurrentWords:skip');
  const setAndContinueText = t('recurrentWords:setAndContinue');
  const confirmOnboardingText = t('services:logistics:endStep:confirmOnboarding');
  const toolsText = t('recurrentWords:devices');
  const emailText = t('recurrentWords:email');
  const addressesText = t('recurrentWords:location');
  const dateText = t('recurrentWords:dates');

  const searchParams = new URLSearchParams(search);
  const navigationOrigin = searchParams.get('origin');
  const navigationOriginId = searchParams.get('originId');

  const originDataCountry = originData?.locationId
    ? originData?.country
    : (originData as IlocationAddressForLogistics)?.countryData;
  const originDataLocationId = originData?.locationId || (originData as IlocationAddressForLogistics)?.id;
  const originIsWarehouse = ['nudos', 'diagnostic', 'warranty'].includes(selectedOrigin || '');
  const originAsUser = originData as Iemployee;

  const requireLogisticsJSON = searchParams?.get('logistics');
  const requireLogistics: boolean = requireLogisticsJSON ? JSON.parse(requireLogisticsJSON) : false;
  const isLocalAssignation = originDataCountry?.code === destinationData?.country?.code;
  // When the user selects billing in local country, the invoice is generated in the destination country
  const billingCountry =
    selectedBillingCountry?.code === 'localBillingCountry' ? destinationData?.country : selectedBillingCountry;

  const showQuoteAndBillingData = showCanQuoteBeAutomated && requireLogistics;

  const incompleteDestinationDataForLogistics =
    requireLogistics && (!destinationData?.locationId || !destinationData?.address?.address);
  const validationToDisplaySecondBillingButton = showQuoteAndBillingData && step === 4;

  const advanceStep = () => setStep(step + 1);
  const returnStep = () => {
    setErrorQuote(false);
    setStep(step - 1);
  };

  const checkShowQuoteAndBillingData = async () => {
    setLoadingShowBillingData(true);
    try {
      const body: IIsAutomatable = {
        sameCountryRelocation: isLocalAssignation,
        origin: selectedOrigin,
        destination: 'user',
        tools: formatCheckQuoteAndBillingDataTools(selectedToolsToAssign || [])
      };
      const checkIsAutomatable = await isAutomatable(body);
      if (checkIsAutomatable) {
        setShowCanQuoteBeAutomated(checkIsAutomatable?.canQuoteBeAutomated);
      }
      advanceStep();
      setLoadingShowBillingData(false);
    } catch (error) {
      displayErrorNotification();
      setLoadingShowBillingData(false);
    }
  };

  const logicSelectTools = async () => {
    await checkShowQuoteAndBillingData();
  };

  const handleBackToOriginOrShowExitWarning = () => {
    if (selectedToolsToAssign && selectedToolsToAssign.length > 0) {
      setShowExitWarning(true);
    } else backToOriginAction();
  };

  const closeModalCallback = () => setShowExitWarning(false);

  const backToOriginAction = () => {
    switch (navigationOrigin) {
      case 'employee-details':
        return push(navigationOriginId ? `/nodi/employees/details/${navigationOriginId}` : '/nodi/employees');
      case 'inventory':
        return push('/nodi/tools');
      case 'tool-details':
        return push(navigationOriginId ? `/nodi/details/${navigationOriginId}` : '/nodi/tools');
      default:
        return push('/nodi/tools');
    }
  };

  const backToOriginText = () => {
    switch (navigationOrigin) {
      case 'employee-details':
        return 'Detalle empleado';
      case 'inventory':
        return 'Inventario';
      case 'tool-details':
        return 'Detalle herramienta';
      default:
        return 'Regresar';
    }
  };

  const getReturnText = () => {
    switch (step) {
      case 0:
        return backToOriginText();
      case 1:
        return toolsText;
      case 2:
        if (!requireLogistics) return emailText;
        return addressesText;
      case 3:
        return dateText;
      case 4:
        return emailText;
      default:
        return 'Regresar';
    }
  };

  const returnAction = step === 0 ? handleBackToOriginOrShowExitWarning : returnStep;

  const checkIfCommentIsRequired = (tools: ItoolEssentialData[]) => {
    const requireComment = tools.filter(element => !!element?.newLogisticAdditionalServices?.length);
    if (requireComment && requireComment.length > 0) {
      const individualTools = requireComment.find(element =>
        element.newLogisticAdditionalServices?.find(
          element => (element.id === 2 || element.id == 3) && !element?.comments
        )
      );
      if (individualTools) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const handleDisabledButton = () => {
    switch (step) {
      case 0:
        if (selectedToolsToAssign && selectedToolsToAssign.length > 0) {
          const disabledForComment = checkIfCommentIsRequired(selectedToolsToAssign);
          if (disabledForComment) {
            return true;
          }
          return false;
        }
        return true;
      case 1:
        if (!requireLogistics) {
          if (optionalEmployeeFile) {
            if (employeMessage) {
              return false;
            }
            return true;
          }
          if (employeMessage) {
            return false;
          }
          return true;
        }
        return (
          !isDataComplete('user', destinationData, listOfCountriesOpenForLogistics || []) ||
          !isDataComplete(selectedOrigin || '', originData, listOfCountriesOpenForLogistics || [])
        );
      case 2:
        if (requireLogistics) {
          if (dateRange && existDateRange) {
            return false;
          }
          return true;
        }
        return false;

      case 3:
        if (optionalEmployeeFile) {
          if (employeMessage) {
            return false;
          }
          return true;
        }
        if (employeMessage) {
          return false;
        }
        return true;
      case 4:
        if (showQuoteAndBillingData) return !logisticsServiceBillingData;
        return false;
      default:
        return true;
    }
  };

  const updateOriginDataCallback = (originData: TlogisticsOriginOrDestinationData) =>
    originDataLocationId && setOriginData(originData);

  const updateDestinationDataCallback = (destinationData: TlogisticsOriginOrDestinationData) => {
    const destinationAsEmployee = destinationData as Iemployee;
    if (!destinationAsEmployee?.userId) return;
    setDestinationData(destinationAsEmployee);
  };

  const handleToolsToAssignSelection = (newToolsToAssign: ItoolEssentialData[]) => {
    setSelectedToolsToAssign([...newToolsToAssign]);
  };

  const handleChangeExtraQueryData = (e: IQueryTools) => {
    setExtraQueryData(e);
  };

  const handleCheckRange = (dateRange: IOutRange) => {
    if (dateRange) {
      setDateRange(dateRange);
    }
  };

  const isValidRange = (status: boolean) => {
    setExistDateRange(status);
  };

  const handleCollectionDetailsText = (text: string) => {
    setCollectionDetailsText(text);
  };

  const handleEmployeeMessage = (message: string) => {
    setEmployeeMessage(message);
  };

  const handleChangeOptionalFile = (fileDetails: IDocumentDetail | undefined) => {
    setOptionalEmployeeFile(fileDetails);
  };

  const formatDeliveryDate = () => {
    const formatDateRange = formatDateFromDateRange(dateRange, destinationData?.country?.timeZone);
    setFormatDeliveryDateRange(formatDateRange);
  };

  const loadingLocations = (loading: boolean) => {
    setLoadingOrigin(loading);
    setLoadingDestination(loading);
  };

  const selectedDatesRange = dateRange && formatDateRange(dateRange);

  const redirectRefusedService = async () => {
    const collectionName = `${originAsUser?.firstName} ${originAsUser?.lastName}`;
    const deliveryName = `${destinationData?.firstName} ${destinationData?.lastName}`;
    const originLocationData = {
      place: (originData as IlocationAddressForLogistics)?.place || (originAsUser?.userId && 'user'),
      countryName: originData?.country?.name,
      locationName: (originData as IlocationAddressForLogistics)?.locationName,
      userName: collectionName
    };
    const destinationLocationData = { place: 'user', userName: deliveryName };
    const logisticServiceData = {
      products: selectedToolsToAssign,
      estimatedDelivery: formatDeliveryDateRange,
      dateRange: selectedDatesRange,
      originLocationData: originLocationData,
      destinationLocationData: destinationLocationData
    };
    const originUrl = `/nodi/request-logistics/assignment/${destinationUserId}?logistics=${requireLogisticsJSON}&origin=${navigationOrigin}&originId=${navigationOriginId}`;
    const redirectionDataObject = {
      pathname: `/nodi/request-logistics/rejected`,
      state: {
        originUrl: originUrl,
        logisticServiceData: logisticServiceData,
        flux: 'assignment'
      }
    };
    push(redirectionDataObject);
  };

  const onlyNames = (additional: IadditionalServiceDataForToolLogistics[]) => {
    const names: string[] = [];
    const originalList = additional;
    for (let i = 0; i < originalList.length; i++) {
      const indexItem = originalList[i];
      if (indexItem.id) {
        names.push(
          indexItem.id === 1
            ? 'Wiping'
            : indexItem.id === 2
            ? 'configuración básica'
            : indexItem.id === 3
            ? 'configuración avanzada'
            : ''
        );
      }
    }
    return names;
  };

  const formatRefuseTools = (tools: ItoolEssentialData[]) => {
    const toolsList: IToolsRejected[] = [];
    const originalList = tools;
    for (let i = 0; i < originalList.length; i++) {
      const indexItem = originalList[i];
      const newItem: IToolsRejected = {
        sku: indexItem?.sku || '',
        productId: Number(indexItem?.productId),
        productName: indexItem?.commercialName || '',
        productBrand: indexItem?.brand || '',
        serial: indexItem?.serial || '',
        nudosCareName: indexItem?.nudosCareName as TnudosCare,
        logisticAdditionalServices: onlyNames(indexItem?.newLogisticAdditionalServices || [])
      };
      if (newItem?.productId) {
        toolsList.push(newItem);
      }
    }
    return toolsList;
  };
  const refuseLogisticService = async (sendComment?: boolean) => {
    setLoadingRejected(true);
    const originTools = originData as Iemployee;
    const rejectedBody = {
      organizationId: Number(orgData?.organizationId),
      userId: Number(orgData?.userId),
      origin: {
        country: originTools?.country?.name || '',
        city: originTools?.city || '',
        place: 'user'
      },
      destination: {
        country: destinationData?.country?.name || '',
        city: destinationData?.city || '',
        place: 'user'
      },
      rejectComments: sendComment ? rejectComments || '' : '',
      rejectedAmount: Number(detailsQuote?.total),
      tools: formatRefuseTools(selectedToolsToAssign || [])
    };
    try {
      await rejectedLogisticService(rejectedBody);
      await redirectRefusedService();
      sendComment &&
        displaySuccessNotification({
          customJSXMessage: (
            <>Gracias por tus comentarios, los tomaremos en cuenta para mejorar nuestros servicios para ti.</>
          )
        });
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingRejected(false);
    }
  };

  const getSecondButtonOnlyShownForBillingStep = () => {
    if (!validationToDisplaySecondBillingButton) return <></>;
    return (
      <div
        className="billingButtonContainer"
        onClick={() => {
          setShowCancelModal(true);
        }}
      >
        <NudosButton
          isButtonLoading={loadingRejected}
          isButtonDisabled={loadingQuote || errorQuote || loadingSubmitting}
          buttonText="Rechazar y cancelar servicio"
          colorPalette="secondary"
        />
      </div>
    );
  };

  const handeSuccessfulAssignament = (logisticServiceId?: number | string) => {
    setLogisticsServiceBillingData(undefined);
    if (requireLogistics) {
      return push(
        `/nodi/request-logistics/success/${logisticServiceId}?origin=${navigationOrigin}&oldUserId=${destinationUserId}&flux=assignment`
      );
    } else {
      return push(`/nodi/employees/details/${destinationUserId}`);
    }
  };

  const handleAssignment = () => {
    if (!originDataLocationId || !destinationData?.country?.id || !selectedOrigin) return;
    if (incompleteDestinationDataForLogistics || !originDataCountry?.id) return;
    const sendMail = !!employeMessage;
    const originAndDestinationData = {
      originLocationId: originDataLocationId,
      originCountryId: originDataCountry?.id,
      destinationCountryId: destinationData?.country?.id,
      destinationLocationId: destinationData?.locationId || undefined,
      origin: selectedOrigin,
      destination: 'user'
    };
    if (!destinationData?.firstName || !destinationData?.lastName || !destinationData?.userId) return;
    const assignmentInfo = {
      userId: destinationData?.userId,
      firstName: destinationData?.firstName,
      lastName: destinationData?.lastName,
      products:
        selectedToolsToAssign?.reduce<number[]>((prev, tool) => {
          !!tool?.productId && prev.push(+tool?.productId);
          return prev;
        }, []) || [],
      address: destinationData?.address?.address || undefined,
      countryId: destinationData?.country?.id,
      customMessage: employeMessage || '',
      destinationLocationId: destinationData?.locationId || undefined
    };
    const originDataAsOfficeOrOtherAddress = originData as IlocationAddressForLogistics;
    const collectionInfo = originIsWarehouse
      ? undefined
      : {
          delivererName: originDataAsOfficeOrOtherAddress?.receiverInformation?.receiverName,
          delivererPersonalId: originDataAsOfficeOrOtherAddress?.receiverInformation?.receiverIdDocument,
          delivererCountryIdPhone: originDataAsOfficeOrOtherAddress?.receiverInformation?.receiverPhoneCountry?.id,
          delivererPhone: originDataAsOfficeOrOtherAddress?.receiverInformation?.receiverPhone
        };
    const deliveryInfo = {
      chosenDeliveryDate: formatDeliveryDateRange,
      deliveryComment: collectionDetailsText,
      receiverName: `${destinationData?.firstName || ''} ${destinationData?.lastName || ''}`,
      receiverPersonalId: destinationData?.personalId,
      receiverCountryIdPhone: destinationData?.phoneData?.countryId,
      receiverPhone: destinationData?.phoneData?.phone
    };
    segmentTrackEvent({ generalOnCompleted: { OnCompleted: true } });
    assignOrUnassign(
      true,
      requireLogistics,
      sendMail,
      originAndDestinationData,
      logisticsServiceBillingData || null,
      billingCountry,
      selectedToolsToAssign,
      assignmentInfo,
      collectionInfo,
      deliveryInfo,
      optionalEmployeeFile?.documentFile,
      invoiceAdditionalEmail,
      setLoadingSummitting,
      handeSuccessfulAssignament
    );
  };

  const resetSteps = () => {
    setShowTimeOutModal(false);
    setStep(0);
  };

  const handleLoadingQuote = (loading: boolean) => {
    setLoadingQuote(loading);
  };

  const handleErrorQuote = (therIsAnError: boolean) => {
    setErrorQuote(therIsAnError);
  };

  const checkDate = () => {
    const localDate = JSON.parse(localStorage.getItem('actualDateLogisticsService') || '');
    const saveDate: Date = new Date(localDate?.date || '');
    const hours = rangeHorus(saveDate, new Date());
    if (hours >= 24) {
      setShowTimeOutModal(true);
    } else {
      handleAssignment();
    }
  };

  const getQuouteDetails = (details: IDetailsQuote) => {
    setDetailsQuote(details);
  };

  const assignmentModuleSteps = [
    {
      buttonData: {
        text: next,
        callback: logicSelectTools,
        loading: loading || loadingShowBillingData
      },
      onlyWithLogistics: false,
      component: (
        <AssignmentModuleStep1SelectTools
          filtersData={filtersData}
          toolsSelectionCallback={handleToolsToAssignSelection}
          selectedToolsToAssign={selectedToolsToAssign || []}
          selectedOrigin={selectedOrigin}
          toolsAvailableToAssignAccordingToAppliedFilters={toolsAvailableToAssignAccordingToAppliedFilters}
          allToolsAvailableToAssignForThisOrganization={allToolsAvailableToAssignForThisOrganization}
          toolsOriginCountryWarehouseAdditionalServices={toolsOriginCountryWarehouseAdditionalServices}
          handleChangeExtraQueryData={handleChangeExtraQueryData}
          closeModalCallback={closeModalCallback}
          backToOriginAction={backToOriginAction}
          showExitWarning={showExitWarning}
          refreshLoading={refreshLoading}
        />
      )
    },
    {
      buttonData: {
        text: next,
        callback: () =>
          updateLocationsData(
            selectedOrigin,
            originData,
            setOriginData,
            'user',
            destinationData,
            setDestinationData as (newData: TlogisticsOriginOrDestinationData) => void,
            loadingLocations,
            advanceStep
          ),
        loading: loading || loadingOrigin || loadingDestination
      },
      onlyWithLogistics: true,
      component: (
        <AssignmentModuleStep2ConfirmAddresses
          originData={originData}
          selectedOrigin={selectedOrigin}
          destinationData={destinationData}
          listOfCountries={listOfAllCountries}
          updateOriginDataCallback={updateOriginDataCallback}
          updateDestinationDataCallback={updateDestinationDataCallback}
        />
      )
    },
    {
      buttonData: {
        text: next,
        callback: advanceStep,
        loading: loading
      },
      onlyWithLogistics: true,
      component: (
        <AssignmentModuleStep3SelectDate
          startDay={aproximateDay}
          handleCheckRange={handleCheckRange}
          isValidRange={isValidRange}
          defaultDateRange={dateRange}
          handleCollectionDetailsText={handleCollectionDetailsText}
          collectionDetailsText={collectionDetailsText}
        />
      )
    },
    {
      buttonData: {
        text: setAndContinueText,
        callback: advanceStep,
        loading: loading
      },
      onlyWithLogistics: false,
      component: (
        <AssignmentModuleStep4SendEmail
          destinationEmployeeData={destinationData}
          handleEmployeeMessage={handleEmployeeMessage}
          defaultEmployeeMessage={employeMessage}
          handleChangeOptionalFile={handleChangeOptionalFile}
          defaultFile={optionalEmployeeFile}
        />
      )
    },
    {
      buttonData: {
        text: showQuoteAndBillingData
          ? 'Aprobar servicio'
          : requireLogistics
          ? 'Cotizar el servicio logístico'
          : confirmOnboardingText,
        callback: showQuoteAndBillingData ? checkDate : handleAssignment,
        loading: loading || loadingSubmitting,
        isDisabled: incompleteDestinationDataForLogistics
      },
      onlyWithLogistics: false,
      component:
        showQuoteAndBillingData && requireLogistics ? (
          <LogisticsQuoteAndBillingStep
            originData={originData}
            destinationData={destinationData}
            selectedDatesRange={selectedDatesRange}
            logisticsEstimatedTimeByCountry={logisticsEstimatedTimeByCountry}
            logisticsType="assignment"
            resetSteps={resetSteps}
            showTimeOutModal={showTimeOutModal}
            toolsData={selectedToolsToAssign}
            getQuouteDetails={getQuouteDetails}
            handleLoadingQuote={handleLoadingQuote}
            handleErrorQuote={handleErrorQuote}
          />
        ) : (
          <AssignmentModuleStep5DataVerification
            originData={originData}
            destinationData={destinationData}
            dateRange={dateRange}
            toolsToAssignData={selectedToolsToAssign}
            selectedOrigin={selectedOrigin}
            type={isLocalAssignation ? 'locale' : 'international'}
            emailToAssignee={employeMessage}
            logisticsEstimatedTimeByCountry={logisticsEstimatedTimeByCountry}
          />
        )
    }
  ];

  const definitiveSteps = requireLogistics
    ? assignmentModuleSteps
    : assignmentModuleSteps?.filter(step => !step?.onlyWithLogistics);

  const getAllToolsAvailableToAssign = async (noLoader?: boolean) => {
    const queryParams = `?filters=[{"name":"productAvailability","includes":"in","values":["Disponibles"]}${
      extraQueryData?.extraQuery ? `,${extraQueryData?.extraQuery}` : ''
    }]`;
    if (!noLoader) {
      setLoading(true);
    } else {
      setRefreshLoading(true);
    }
    try {
      const organizationTools = await getSubscriptions(
        `/order/organization/${Number(orgData?.organizationId)}${queryParams}${
          extraQueryData?.stringQuery ? `&q=${extraQueryData?.stringQuery || ''}` : ''
        }`
      );
      const codesOfCountriesSupportingLogistics = requireLogistics
        ? listOfCountriesOpenForLogistics?.map(country => country?.code) || []
        : undefined;
      const completeAvailableTools = organizationTools.filter(tool => {
        const logisticServiceInCourse =
          tool?.logisticStatus && tool?.logisticStatus !== 'entregado' && tool?.logisticStatus !== 'cancelado';
        const toolHasntBeenDelivered =
          tool?.referenceModel === 'CatalogProduct' && tool?.shipmentStatusName !== 'Entregado';
        const toolIsInUnavailableLocation = requireLogistics
          ? ['ownLocation', 'start', 'user'].includes(tool?.place || '')
          : ['nudos', 'warranty', 'diagnostic'].includes(tool?.place || '');
        const disabledTool = !!logisticServiceInCourse || !!toolHasntBeenDelivered || toolIsInUnavailableLocation;
        return !disabledTool;
      });
      const availableToolsFilteredByRequirementOfLogistics = codesOfCountriesSupportingLogistics
        ? completeAvailableTools.filter(tool => codesOfCountriesSupportingLogistics.includes(tool?.country?.code || ''))
        : completeAvailableTools;
      setToolsAvailableToAssignAccordingToAppliedFilters(availableToolsFilteredByRequirementOfLogistics || []);
      !extraQueryData?.extraQuery &&
        setAllToolsAvailableToAssignForThisOrganization(availableToolsFilteredByRequirementOfLogistics || []);
    } catch {
      displayErrorNotification();
    } finally {
      setLoading(false);
      setRefreshLoading(false);
    }
  };

  const getToolsOriginCountryWarehouseData = async (toolsOriginCountry: ICountryDetail) => {
    const { organizationId } = getOrgData() || {};

    if (!toolsOriginCountry?.id || !organizationId) return;
    try {
      const warehousesInCountry = await getNudosWarehousesListIncludingAdditionalServices(
        organizationId,
        toolsOriginCountry.id
      );
      const toolsOriginCountryWarehouseAdditionalServices = warehousesInCountry[0]?.logisticAdditionalServices;
      setToolsOriginCountryWarehouseAdditionalServices(toolsOriginCountryWarehouseAdditionalServices);
    } catch {
      displayErrorNotification();
    }
  };

  const toolsFilters = async () => {
    const dataFilters = await getBoardFilters(Number(orgData?.organizationId));
    if (dataFilters) {
      setFiltersData(dataFilters);
    }
  };

  const getAproximateDay = async (countryCode: string) => {
    try {
      const dataDay = await aproximateDays(countryCode);
      setAproximateDay(dataDay.deliveryGap || 0);
      setLogisticsEstimatedTimeByCountry(`${dataDay.deliverySince}-${dataDay.deliveryUntil}`);
    } catch {
      displayErrorNotification();
      setAproximateDay(3);
    }
  };

  const assignRangeDays = async () => {
    const locale = originDataCountry?.code === destinationData?.country?.code;
    if (originDataCountry?.id && destinationData?.country?.id) {
      await getAproximateDay(locale ? originDataCountry?.code : '1111');
    }
  };

  const ommitEmailButton =
    (step === 3 && requireLogistics) || (step === 1 && !requireLogistics) ? (
      <div className="emailButtonContainer">
        <NudosButton
          colorPalette="secondary"
          buttonText={skipText}
          isButtonDisabled={
            !!employeMessage || !!optionalEmployeeFile?.documentFile || !!optionalEmployeeFile?.documentName
          }
          handleClick={definitiveSteps[step]?.buttonData?.callback}
        />
      </div>
    ) : null;

  useEffect(() => {
    getListOfCountriesByOfferedService(setListOfCountriesOpenForLogistics, 'logisticService');
    getListOfCountriesByOfferedService(setListOfAllCountries);
    toolsFilters();
    return () => cleanLsBillingSate();
  }, []);

  useEffect(() => {
    getEmployeeDataByUserId(destinationUserId, setDestinationData, setLoadingDestination);
  }, [destinationUserId]);

  useEffect(() => {
    if (!selectedToolsToAssign || !selectedToolsToAssign.length) {
      setSelectedOrigin(undefined);
      return setOriginData(undefined);
    }
    const newOriginLocationId = selectedToolsToAssign[0]?.locationId;
    if (!newOriginLocationId || newOriginLocationId === originDataLocationId || loadingOrigin) return;
    getLocationData(newOriginLocationId, setOriginData, setLoadingOrigin);
  }, [selectedToolsToAssign]);

  useEffect(() => {
    const newSelectedOrigin = originData
      ? ((originData as IlocationAddressForLogistics)?.place as TstoragePlace)
      : undefined;
    if (!newSelectedOrigin) return;
    setSelectedOrigin(newSelectedOrigin);
  }, [originData]);

  useEffect(() => {
    if (!listOfCountriesOpenForLogistics) return;
    getAllToolsAvailableToAssign();
  }, [listOfCountriesOpenForLogistics, requireLogistics]);

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
    } else {
      getAllToolsAvailableToAssign(true);
    }
  }, [extraQueryData?.extraQuery, extraQueryData?.stringQuery]);

  useEffect(() => {
    if (!selectedToolsToAssign || selectedToolsToAssign.length <= 0) return;
    const firstToolData = selectedToolsToAssign[0]; // All tools to assign come from the same location, hence the location data for the first tool is common to all tools selected to assign
    const toolsToAssignWarehouseCountry = firstToolData?.place === 'nudos' ? firstToolData?.country : undefined;
    const toolsToAssignWarehouseCountryWithId = listOfCountriesOpenForLogistics?.find(
      country => country.code === toolsToAssignWarehouseCountry?.code
    );
    if (!toolsToAssignWarehouseCountryWithId) return;
    getToolsOriginCountryWarehouseData(toolsToAssignWarehouseCountryWithId);
  }, [selectedToolsToAssign]);

  useEffect(() => {
    if (dateRange?.endDate && dateRange?.startDate && existDateRange && existDateRange) {
      formatDeliveryDate();
    }
  }, [dateRange, existDateRange]);

  useEffect(() => {
    assignRangeDays();
  }, [originDataCountry?.code, destinationData?.country?.code]);

  if (loading)
    return (
      <div className="AssignmentModule loading">
        <GenericLoader />
      </div>
    );

  return (
    <div className="AssignmentModule">
      {showCancelModal && (
        <CancelLogisticServiceModal
          updateValue={e => {
            setRejectComments(e);
          }}
          skipCancellation={() => refuseLogisticService(false)}
          continueCancellation={() => refuseLogisticService(true)}
          defaultValue={rejectComments}
          loading={loadingRejected}
        />
      )}
      <NudosBreadcrumbButton returnText={getReturnText()} returnAction={returnAction} />
      <AssignmentModuleStepper
        currentStep={step}
        requireLogistics={requireLogistics}
        includeQuoteAndBillingStep={showQuoteAndBillingData}
      />
      {definitiveSteps[step]?.component}
      {/* The following is meant to position the button at the bottom of the component while ensuring there is always at least 32px of space between the content preceding the button and the button itself. */}
      <div className="spaceBeforeButton h-20 w-10" />
      <div className="spaceBeforeButton h-0.5 w-10" />
      <div className="asssigmentFloatButton">
        {ommitEmailButton}
        {getSecondButtonOnlyShownForBillingStep()}
        <NudosButton
          customClassName="buttonPosition"
          isButtonDisabled={
            handleDisabledButton() ||
            definitiveSteps[step]?.buttonData?.isDisabled ||
            loadingRejected ||
            errorQuote ||
            loadingQuote
          }
          componentSize="large"
          isButtonLoading={definitiveSteps[step]?.buttonData?.loading}
          buttonText={definitiveSteps[step]?.buttonData?.text}
          handleClick={definitiveSteps[step]?.buttonData?.callback}
        />
      </div>
    </div>
  );
};

export default AssignmentModule;
