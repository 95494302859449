import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M13.25 7c0 1.05-2.798 4.75-6.25 4.75S.75 8.05.75 7C.75 5.95 3.548 2.25 7 2.25s6.25 3.7 6.25 4.75Z"
      fill="#fff"
      stroke="#ff664e"
      strokeLinejoin="round"
    />
    <path
      d="M7 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      fill="#fff"
      stroke="#ff664e"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const OpenEyeIcon = memo(ForwardRef);
export default OpenEyeIcon;
