import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconNoteOnPaper from '../../../../../assets/DesignSystem/SVGComponents/IconNoteOnPaper';
import { IadditionalServiceDataForToolLogistics } from '../../../../../types/requestLogisticsModule';
import NudosLinkTypeButton from '../../../NudosLinkTypeButton/NudosLinkTypeButton';
import { getAdditionalServiceDisplayElements } from '../../utils';
import ConfigurationCommentsModal from '../../../../../views/Admin/LogisticsServicesModule/LogisticsServiceDetail/Components/ConfigurationCommentsModal/ConfigurationCommentsModal';
import { LS_ADDITIONAL_SERVICE_STATUS } from '../../../../../types/logisticsServicesModule.types';
import { markAdditionalServiceNotCompletedAlertViewed } from '../../../../../services/logisticsServicesModule.services';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import './NudosAdditionalServiceStatus.scss';

const NudosAdditionalServiceStatus = ({
  additionalServiceData,
  callToActionCallback
}: {
  additionalServiceData: IadditionalServiceDataForToolLogistics;
  callToActionCallback: () => void;
}) => {
  const { t } = useTranslation();
  const [showConfigurationCommentsModal, setShowConfigurationCommentsModal] = useState(false);

  const { id: additionalServiceProductId, comments, status: additionalServiceStatus } = additionalServiceData || {};
  const { statusName, statusViewedByUserId } = additionalServiceStatus || {};
  const { text, icon, status, callToActionText, color } = getAdditionalServiceDisplayElements(additionalServiceData);

  const serviceIsToBeCompleted = statusName === LS_ADDITIONAL_SERVICE_STATUS.TO_COMPLETE;
  const serviceWasNotCompleted = statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED;
  const checkServiceEvidenceModalIsDisabled = !statusName || serviceIsToBeCompleted;

  const markEvidenceViewed = async () => {
    if (!additionalServiceProductId || statusViewedByUserId) return;
    try {
      await markAdditionalServiceNotCompletedAlertViewed(additionalServiceProductId);
    } catch (error) {
      displayErrorNotification();
    }
  };

  const handleCheckServiceEvidenceModal = () => {
    if (checkServiceEvidenceModalIsDisabled) return;
    if (serviceWasNotCompleted) markEvidenceViewed();
    callToActionCallback();
  };

  const explainingHoverElement = (
    <div className="toCompleteStatusHover">{t('services:logistics:additionalServices:explainingHover')}</div>
  );
  const toCompleteStatusHover = serviceIsToBeCompleted ? explainingHoverElement : undefined;

  const openConfigurationCommentsModal = () => setShowConfigurationCommentsModal(true);
  const closeConfigurationCommentsModal = () => setShowConfigurationCommentsModal(false);

  return (
    <div className="nudosAdditionalServiceStatus">
      {icon}
      <div className="serviceName">{text}</div>
      {!!comments && (
        <div className="commentsSection">
          <div className="iconContainer">
            <IconNoteOnPaper className="IconNoteOnPaper" onClick={openConfigurationCommentsModal} />
            <div className="showCommentsTooltip !w-[100px]">{`${t('recurrentWords:view')} ${t(
              'recurrentWords:configuration'
            )}`}</div>
          </div>
        </div>
      )}
      <div className="serviceStatus" style={{ color }}>
        {status}
      </div>
      <div className="colorDot" style={{ backgroundColor: color }} />
      <NudosLinkTypeButton
        customClassname="serviceCheckDataButton"
        hideIcon
        text={callToActionText}
        handleClickCallback={handleCheckServiceEvidenceModal}
        isDisabled={checkServiceEvidenceModalIsDisabled}
        explainingHover={toCompleteStatusHover}
      />
      {showConfigurationCommentsModal && comments && (
        <ConfigurationCommentsModal closeModalCallback={closeConfigurationCommentsModal} comments={comments} />
      )}
    </div>
  );
};

export default NudosAdditionalServiceStatus;
