import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6416_11338)">
      <path
        d="M7.24595 3.7786C7.29676 4.25354 7.67875 4.63554 8.15346 4.68845C8.50606 4.72775 8.86813 4.7615 9.23722 4.7615C9.6063 4.7615 9.96836 4.72775 10.3209 4.68845C10.7957 4.63554 11.1776 4.25354 11.2284 3.7786C11.266 3.42799 11.2976 3.06804 11.2976 2.70107C11.2976 2.33409 11.266 1.97413 11.2284 1.62353C11.1776 1.14859 10.7957 0.766596 10.3209 0.713683C9.96836 0.674381 9.6063 0.640625 9.23722 0.640625C8.86813 0.640625 8.50606 0.674381 8.15346 0.713683C7.67875 0.766596 7.29676 1.14859 7.24595 1.62353C7.20844 1.97413 7.17676 2.33409 7.17676 2.70107C7.17676 3.06804 7.20844 3.42799 7.24595 3.7786Z"
        stroke={props.stroke || 'white'}
      />
      <path
        d="M0.771824 3.7786C0.822637 4.25354 1.20463 4.63554 1.67934 4.68845C2.03194 4.72775 2.39397 4.7615 2.76308 4.7615C3.13219 4.7615 3.49422 4.72775 3.84682 4.68845C4.32153 4.63554 4.70352 4.25354 4.75433 3.7786C4.79184 3.42799 4.82352 3.06804 4.82352 2.70107C4.82352 2.33409 4.79184 1.97413 4.75433 1.62353C4.70352 1.14859 4.32153 0.766596 3.84682 0.713683C3.49422 0.674381 3.13219 0.640625 2.76308 0.640625C2.39397 0.640625 2.03194 0.674381 1.67934 0.713683C1.20463 0.766596 0.822637 1.14859 0.771824 1.62353C0.734313 1.97413 0.702637 2.33409 0.702637 2.70107C0.702637 3.06804 0.734313 3.42799 0.771824 3.7786Z"
        stroke={props.stroke || 'white'}
      />
      <path
        d="M0.771824 10.3724C0.822637 10.8473 1.20463 11.2292 1.67934 11.2822C2.03194 11.3215 2.39397 11.3552 2.76308 11.3552C3.13219 11.3552 3.49422 11.3215 3.84682 11.2822C4.32153 11.2292 4.70352 10.8473 4.75433 10.3724C4.79184 10.0218 4.82352 9.66179 4.82352 9.29484C4.82352 8.92782 4.79184 8.56788 4.75433 8.21728C4.70352 7.74234 4.32153 7.36035 3.84682 7.30744C3.49422 7.26813 3.13219 7.23438 2.76308 7.23438C2.39397 7.23438 2.03194 7.26813 1.67934 7.30744C1.20463 7.36035 0.822637 7.74234 0.771824 8.21728C0.734313 8.56788 0.702637 8.92782 0.702637 9.29484C0.702637 9.66179 0.734313 10.0218 0.771824 10.3724Z"
        stroke={props.stroke || 'white'}
      />
      <path
        d="M7.24595 10.3724C7.29676 10.8473 7.67875 11.2292 8.15346 11.2822C8.50606 11.3215 8.86813 11.3552 9.23722 11.3552C9.6063 11.3552 9.96836 11.3215 10.3209 11.2822C10.7957 11.2292 11.1776 10.8473 11.2284 10.3724C11.266 10.0218 11.2976 9.66179 11.2976 9.29484C11.2976 8.92782 11.266 8.56788 11.2284 8.21728C11.1776 7.74234 10.7957 7.36035 10.3209 7.30744C9.96836 7.26813 9.6063 7.23438 9.23722 7.23438C8.86813 7.23438 8.50606 7.26813 8.15346 7.30744C7.67875 7.36035 7.29676 7.74234 7.24595 8.21728C7.20844 8.56788 7.17676 8.92782 7.17676 9.29484C7.17676 9.66179 7.20844 10.0218 7.24595 10.3724Z"
        stroke={props.stroke || 'white'}
      />
    </g>
    <defs>
      <clipPath id="clip0_6416_11338">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuDashBoardIcon = memo(ForwardRef);
export default MenuDashBoardIcon;
