import { toolSystemStatusZeroStateIcon } from '../../../../../assets/images/views/Admin/nodiProductDetails';
import './NudosToolAdditionalServicesZeroState.scss';

const NudosToolAdditionalServicesZeroState = ({ hasBeenInWarehouse }: { hasBeenInWarehouse: boolean }) => {
  const explanationTitle = hasBeenInWarehouse
    ? 'No se aplicaron servicios adicionales'
    : 'No se han aplicado servicios adicionales';

  const explanationText =
    'En este espacio podrás visualizar los servicios adicionales, como el wiping y las configuraciones, que posee esta herramienta.';

  return (
    <div className="nudosToolAdditionalServicesZeroState">
      {!hasBeenInWarehouse && (
        <div className="iconContainer">
          <img src={toolSystemStatusZeroStateIcon} alt="Laptop icon" />
        </div>
      )}
      <div className="explanationtitle">{explanationTitle}</div>
      {!hasBeenInWarehouse && <div className="explanationText">{explanationText}</div>}
    </div>
  );
};

export default NudosToolAdditionalServicesZeroState;
