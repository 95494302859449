import { IIssueReported, IlogisticServiceDetailedData } from '../../../types/logisticsServicesModule.types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NudosGenericModal, NudosModalContent } from '../../NudosComponents';
import NudosEditOfficeAddressModal from './NudosEditOfficeAddressModal/NudosEditOfficeAddressModal';
import NudosEditUserAddressModal from './NudosEditUserAddressModal/NudosEditUserAddressModal';
import { segmentTrackEvent } from '../../../utils/segment';

const NudosEditAddressModal = ({
  closeModalCallback,
  requestCancelLSCallback,
  originDestination,
  logisticServiceData,
  getLogisticServiceDetailCallback,
  issueReported
}: {
  closeModalCallback: () => void;
  requestCancelLSCallback: () => void;
  originDestination: 'origin' | 'destination';
  logisticServiceData?: IlogisticServiceDetailedData;
  getLogisticServiceDetailCallback: () => void;
  issueReported?: IIssueReported;
}) => {
  const { t } = useTranslation();
  const isOriginCard = originDestination === 'origin';
  const { collection, delivery, manualQuote } = logisticServiceData || {};

  const locationData = isOriginCard ? collection : delivery;
  const placeIsUser = locationData?.place === 'user';
  const placeIsOffice = locationData?.place === 'office';
  const modalHeight = placeIsUser ? '560px' : '590px';
  const translationKey = 'designSystemComponents:referenceOptions:';

  const modalType = placeIsUser ? 'Employee' : 'Office';
  const editingPoint = originDestination === 'origin' ? 'Origin' : 'Destination';
  const logServiceStatus = logisticServiceData?.status === 'por confirmar' ? 'To be confirmed' : 'Confirmed';

  const [fieldsEditedList, setFieldsEditedList] = useState<string[]>([]);

  const generalDataEvents = {
    country:
      originDestination === 'origin'
        ? logisticServiceData?.collection?.country?.name
        : logisticServiceData?.delivery?.country?.name,
    employeeDetails: {
      employeeID:
        originDestination === 'origin'
          ? logisticServiceData?.collection?.locationUser?.id
          : logisticServiceData?.delivery?.locationUser?.id,
      employeePhone:
        originDestination === 'origin'
          ? logisticServiceData?.collection?.locationUser?.phone
          : logisticServiceData?.delivery?.locationUser?.phone,
      directions:
        originDestination === 'origin'
          ? logisticServiceData?.collection?.additionalReferences
          : logisticServiceData?.delivery?.additionalReferences,
      address:
        originDestination === 'origin'
          ? logisticServiceData?.collection?.address
          : logisticServiceData?.delivery?.address
    }
  };

  const getModalTitle = () => {
    if (placeIsUser) return t(`${translationKey}employeeInformation`);
    if (placeIsOffice) return t(`${translationKey}officeAddress`);
    return t(`${translationKey}otherAddress`);
  };

  const closeEvent = () => {
    if (!manualQuote) {
      segmentTrackEvent({
        logisticServicesEditAutomatedModalCancelClick: {
          Country: generalDataEvents.country || '',
          EditingPoint: editingPoint,
          LogServiceStatus: logServiceStatus,
          ModalType: modalType
        }
      });
    }
  };

  const editEvent = () => {
    if (manualQuote) {
      segmentTrackEvent({
        logisticServicesEditManualModalSaveClick: {
          Country: generalDataEvents?.country || '',
          EditingPoint: editingPoint,
          LogServiceStatus: logServiceStatus,
          ModalType: modalType
        }
      });
    } else {
      segmentTrackEvent({
        logisticServicesEditAutomatedModalSaveClick: {
          Country: generalDataEvents?.country || '',
          EditingPoint: editingPoint,
          LogServiceStatus: logServiceStatus,
          ModalType: modalType,
          FieldsEdited: fieldsEditedList?.toString()
        }
      });
    }
  };

  const updateData = async () => {
    editEvent();
    await getLogisticServiceDetailCallback();
  };

  const closeModal = () => {
    closeEvent();
    closeModalCallback();
  };
  const updateEditFieldList = (field: string) => {
    const findField = fieldsEditedList.find(fieldInList => fieldInList === field);
    if (!findField) {
      setFieldsEditedList([...fieldsEditedList, field]);
    }
  };

  const nudosEditDirectionModalContent = placeIsUser ? (
    <NudosEditUserAddressModal
      originDestination={originDestination}
      closeModalCallback={closeModalCallback}
      logisticServiceData={logisticServiceData}
      requestCancelLSCallback={requestCancelLSCallback}
      issueReported={issueReported}
      getLogisticServiceDetailCallback={updateData}
      updateEditFieldList={updateEditFieldList}
    />
  ) : (
    <NudosEditOfficeAddressModal
      subtitle={t(`${translationKey}modalSubtitle`)}
      buttonText={t(`${translationKey}modalButtonText`)}
      currentOfficeData={locationData}
      originDestination={originDestination}
      closeModalCallback={closeModalCallback}
      logisticServiceData={logisticServiceData}
      requestCancelLSCallback={requestCancelLSCallback}
      giverOrReceiverSectionTitle={t(`${translationKey}giverOrReceiverTitle`)}
      getLogisticServiceDetailCallback={getLogisticServiceDetailCallback}
      issueReported={issueReported}
      updateEditFieldList={updateEditFieldList}
    />
  );

  const initialEvent = () => {
    segmentTrackEvent({
      logiticServiceEditClick: {
        Country: generalDataEvents.country || '',
        EditingPoint: editingPoint,
        LogServiceStatus: logServiceStatus
      }
    });
    if (manualQuote) {
      segmentTrackEvent({ logisticServicesEditManualModalView: { ModalType: modalType } });
    } else {
      segmentTrackEvent({ logisticServicesEditAutomatedModalView: { ModalType: modalType } });
    }
  };

  useEffect(() => {
    initialEvent();
  }, []);

  return (
    <div className="nudosEditAddressModal">
      <NudosGenericModal
        closeModalCallback={closeModal}
        modalContent={
          <NudosModalContent
            hideNudosIcon
            customWidth="636px"
            customHeight={modalHeight}
            toCloseModal={closeModal}
            modalContentTitle={getModalTitle()}
            CustomModalComponent={nudosEditDirectionModalContent}
          />
        }
      />
    </div>
  );
};

export default NudosEditAddressModal;
