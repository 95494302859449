import { FC } from 'react';
import './countries.scss';
import { IProductUpdate } from '../../../../../../../types/cart';
import {
  CheckoutProductItemCard,
  CheckoutProductItemCardSkeletonLoader
} from '../CheckoutProductItemCard/CheckoutProductItemCard';
import { useTranslation } from 'react-i18next';
import useStoreCountryState from '../../../../../../../state/useStoreCountryState';

interface IDataCard {
  referencesList: IProductUpdate[];
}
const CountriesList: FC<IDataCard> = ({ referencesList }: IDataCard) => {
  const { t } = useTranslation();
  const { storeCountry } = useStoreCountryState();

  const countryName = t(`services:countries:${storeCountry?.nameStringId}`);

  return (
    <div className="countriesProductList">
      <div className="countryTitleList">
        {referencesList && referencesList.length > 0 && (
          <div className="flagCountryList" style={{ backgroundImage: `url(${storeCountry?.countryFlag})` }}></div>
        )}
        <div className="countryTitleListText">{countryName}</div>
      </div>
      {referencesList?.length > 0 &&
        referencesList.map((item, i) => {
          return <CheckoutProductItemCard key={`country${item.countryId}Item${item.productId}${i}`} itemData={item} />;
        })}
    </div>
  );
};

const CountriesListSkeletonLoader: FC = () => {
  return (
    <div className="countriesProductList countriesProductListSkeletonLoader">
      <div className="countryTitleList animationLoader" />
      {[0, 1, 2].map(i => {
        return <CheckoutProductItemCardSkeletonLoader key={`checkoutProductItemCardSkeletonLoader${i}`} />;
      })}
    </div>
  );
};
export { CountriesList, CountriesListSkeletonLoader };
