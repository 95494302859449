import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11} height={11} fill="none" ref={ref} {...props}>
    <g>
      <path
        fill="#fff"
        d="M8.227 6.39a1.398 1.398 0 0 0-1.394-1.387h-.325V1.9a.744.744 0 0 0-.572-.736.73.73 0 0 0-.882.713v3.125H4.74a1.398 1.398 0 0 0-1.394 1.393l-.744 2.336A.417.417 0 0 0 3 9.273h5.563a.417.417 0 0 0 .398-.541L8.227 6.39Zm-4.891 0h4.891-4.891ZM5.78 8.46V6.39 8.46ZM7.063 6.39l.379 2.069-.38-2.069Zm-2.563 0L4.12 8.46 4.5 6.39Z"
      />
      <path
        stroke="#383838"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.227 6.39a1.398 1.398 0 0 0-1.394-1.387h-.325V1.9a.744.744 0 0 0-.572-.736.73.73 0 0 0-.882.713v3.125H4.74a1.398 1.398 0 0 0-1.394 1.393l-.744 2.336A.417.417 0 0 0 3 9.273h5.563a.417.417 0 0 0 .398-.541L8.227 6.39Zm0 0H3.336M5.78 8.46V6.39m1.282 0 .379 2.069M4.5 6.39 4.12 8.46"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconBroom = memo(ForwardRef);
export default IconBroom;
