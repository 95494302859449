import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import { PersonIcon } from '../../../../assets/images/views/Admin/dashboard';
import { IEmployeeCountries } from '../../../../types/dashboard';
import { useTranslation } from 'react-i18next';
import './employeesTable.scss';

interface IEmployeesTable {
  dataEmployees: IEmployeeCountries[];
}

const EmployeesTable: FC<IEmployeesTable> = ({ dataEmployees }: IEmployeesTable) => {
  const { t } = useTranslation();
  return (
    <div id="employeesTable">
      <div className="headerTable">
        <div className="countrySpace">
          <div className="titleCountry">{t('nodi:dashboard:body:employees:country')}</div>
        </div>
        <div className="percentageSpace">
          <div className="titlePercetage">%</div>
        </div>
        <div className="numberSpace">
          <div className="titleNumber">
            <PersonIcon />
          </div>
        </div>
      </div>
      <div className="bodyTableEmployees">
        {dataEmployees &&
          dataEmployees?.map(item => {
            return (
              <div key={uuid()} className="countryDetailTable">
                <div className="countrySpace">
                  <div className="subtitleCountry">
                    {item.nameStringId ? t(`services:countries:${item.nameStringId}`) : item.name}
                  </div>
                </div>
                <div className="percentageSpace">
                  <div className="subtitleCountry">{item.percent}%</div>
                </div>
                <div className="numberSpace">
                  <div className="subtitleNumber">{item.number}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EmployeesTable;
