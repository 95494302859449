import { FC } from 'react';
import { MinNudosLogo } from '../../../../../../../../../assets/images/components/NudosComponents';
import { NudosButton, NudosGenericModal } from '../../../../../../../../../components/NudosComponents';
import { v4 as uuid } from 'uuid';
import XSign from '../../../../../../../../../assets/images/components/NudosComponents/XSign';
import { IModuleList } from '../../../../../../../../../types/global';
import { useTranslation } from 'react-i18next';
import './ModalDeleteSerial.scss';

interface IModalDeleteSerial {
  title?: string;
  subtitle?: string;
  textFirstButton?: string;
  textSecondButton?: string;
  firstLoading?: boolean;
  secondLoading?: boolean;
  listModal?: IModuleList[];
  firstAction?: () => void;
  secondAction?: () => void;
  handleClose: () => void;
}

const ModalDeleteSerial: FC<IModalDeleteSerial> = ({
  title,
  subtitle,
  textFirstButton,
  textSecondButton,
  firstLoading,
  secondLoading,
  listModal,
  firstAction,
  secondAction,
  handleClose
}: IModalDeleteSerial) => {
  return (
    <NudosGenericModal
      clickOutsideCallback={() => handleClose()}
      modalContent={
        <GenericSerialModal
          title={title}
          subtitle={subtitle}
          textFirstButton={textFirstButton}
          textSecondButton={textSecondButton}
          firstLoading={firstLoading}
          secondLoading={secondLoading}
          listModal={listModal}
          firstAction={firstAction}
          secondAction={secondAction}
          handleClose={handleClose}
        />
      }
    />
  );
};

export default ModalDeleteSerial;

const GenericSerialModal: FC<IModalDeleteSerial> = ({
  title,
  subtitle,
  textFirstButton,
  textSecondButton,
  firstLoading,
  secondLoading,
  listModal,
  firstAction,
  secondAction,
  handleClose
}: IModalDeleteSerial) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:toolInternal:ModalDeleteSerial:';

  return (
    <div id="modalDeleteSerial">
      <XSign className="closeIcon" fill="#B3B3B3" onClick={handleClose} />
      <div className="titleContainer">
        <MinNudosLogo className="mr-2" />
        <div className="textStyle">{title || t(`${i18nLanguageKey}titleContainer`)}</div>
      </div>
      <div className="subtitle">{subtitle || t(`${i18nLanguageKey}subtitle`)}</div>
      {listModal && listModal.length > 0 && (
        <div className="bodyList">
          {listModal.map(item => {
            return (
              <div className="containerList" key={uuid()}>
                <div className="minPoint" />
                <div className="blackTitle">
                  {`${item.title}: `}
                  <span className="font-normal">{item.subtitle}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="containerButtons">
        {firstAction && (
          <div className="firstButton" style={{ marginRight: !secondAction ? '0px' : '' }}>
            <NudosButton
              buttonText={textFirstButton || t(`${i18nLanguageKey}firstButton`)}
              isButtonLoading={firstLoading}
              isButtonDisabled={firstLoading}
              handleClick={() => {
                firstAction();
              }}
            />
          </div>
        )}
        {secondAction && (
          <div className="secondButton">
            <NudosButton
              buttonText={textSecondButton || t(`${i18nLanguageKey}secondButton`)}
              colorPalette="secondary"
              isButtonLoading={secondLoading}
              isButtonDisabled={secondLoading}
              handleClick={() => {
                secondAction();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
