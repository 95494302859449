import { useTranslation } from 'react-i18next';
import { TlogisticsUserHeaderType } from '../../../types/requestLogisticsModule';
import { NudosHoverText } from '../../NudosComponents';
import { capitalize } from '../../../utils/unCamelCase';
import './NudosLogisticServiceUserHeader.scss';

/**
 * @property { 'origin' | 'destination' | 'customized' } type - The type of user header, can be one of 'origin' | 'destination' | 'customized'
 * @property { string } name
 * @property { string } photoUrl - In case the photoJSX prop is passed that one takes precedence
 * @property { string } flagUrl - If no value is provided no flag will be shown
 * @property { string } customTitle - In order for this to be shown it is required that the type prop be "customized"
 * @property { JSX.Element } photoJSX - A JSX element to show instead of the photo url. The styles for this componente are a responsability for componente that uses the NudosLogisticServiceUserHeader
 * @property { string } customClassName - A classname for customized styles
 */
const NudosLogisticServiceUserHeader = ({
  type,
  name,
  photoUrl,
  flagUrl,
  customTitle,
  photoJSX,
  showSkeletonLoader = false,
  customClassName
}: INudosLogisticServiceUserHeader) => {
  const { t } = useTranslation();
  const originText = capitalize(t('recurrentWords:origin'));
  const destinationText = capitalize(t('recurrentWords:destination'));

  const i18nLanguageKey = 'services:nudosLogisticServiceUserHeader';
  const photo = photoUrl?.replace(/\s/g, '%20') || '';
  const flag = flagUrl ? flagUrl.replace(/\s/g, '%20') || '' : undefined;
  const inactiveNameStyles = !name ? 'inactive' : '';
  const customStyleClassname = customClassName || '';
  const getNameText = () => {
    if (name) return name;
    if (type === 'origin') return t(`${i18nLanguageKey}:originHoverText`);
    if (type === 'destination') return t(`${i18nLanguageKey}:destinationHoverText`);
    return '';
  };

  const getTitle = () => {
    switch (type) {
      case 'origin':
        return originText;
      case 'destination':
        return destinationText;
      case 'customized':
        return customTitle || '';
      default:
        return '';
    }
  };

  return (
    <>
      {showSkeletonLoader && NudosLogisticServiceUserHeaderSkeletonLoader()}
      {!showSkeletonLoader && (
        <div className={`NudosLogisticServiceUserHeader ${customStyleClassname}`}>
          <div className="userTitle">{getTitle()}</div>
          <div className="userInformation">
            <div className="photoAndFlag">
              {!photoJSX && <div className="photo" style={{ backgroundImage: `url("${photo}")` }} />}
              {photoJSX || null}
              {flag && <div className="flag" style={{ backgroundImage: `url(${flag})` }} />}
            </div>
            <NudosHoverText
              onlyIfTruncated
              customClassName={`userName ${inactiveNameStyles}`}
              text={getNameText()}
              charactersLimit={40}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NudosLogisticServiceUserHeader;

const NudosLogisticServiceUserHeaderSkeletonLoader = () => {
  return (
    <div className={`NudosLogisticServiceUserHeaderSkeletonLoader`}>
      <div className={`userTitleSkeleton animationLoader`} />
      <div className="userInformation">
        <div className="photoAndFlag">
          <div className="photo animationLoader" />
        </div>
        <div className="userNameSkeleton animationLoader" />
      </div>
    </div>
  );
};
interface INudosLogisticServiceUserHeader {
  type: TlogisticsUserHeaderType;
  name?: string;
  photoUrl?: string;
  flagUrl?: string;
  showSkeletonLoader?: boolean;
  customTitle?: string;
  photoJSX?: JSX.Element;
  customClassName?: string;
}
