import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  NudosAddressSearchBar,
  NudosButton,
  NudosPhoneInput,
  NudosSelectDropdown,
  NudosTextInput
} from '../../../../../components/NudosComponents';
import NudosEncircledPhotoFileInput from '../../../../../components/NudosComponents/NudosEncircledPhotoFileInput';
import validateFile from '../../../../../utils/validateFile';
import useNewEmployeeFormSchema from './NewEmployeeForm.schema';
import { IaddressFromGoogleMaps, Iemployee, INewEmployeeInfo, Tany } from '../../../../../types/global';
import { EmployeePills } from '../../../../../components';
import { ICountryDetail } from '../../../../../types/countries';
import { getOrgData } from '../../../../../utils/getLocalStorageData';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import {
  getAddressErrorText,
  getIdDocumentData,
  isEmailValid,
  isPhoneValid
} from '../../../../../utils/formValidations';
import { formatZipCode, getIsInvalidZipCode, invalidZipCodeText } from '../../../../../utils/productDefinitions';
import { getActiveOrdersByEmployee } from '../../../../../services/orders';
import { useTranslation } from 'react-i18next';
import AssignToolsModal from '../assignToolsModal';
import { NudosCityDropdown, NudosCountryDropdown } from '../../../../../components/DesignSystem';
import { IcityDetails } from '../../../../../types/cities.types';
import './NewEmployeeForm.scss';

const NewEmployeeForm = ({
  handleSubmitCustomCallback,
  customTextButton,
  isButtonLoading,
  currentEmployeeData,
  logisticsCountry,
  countriesList,
  listOfCountriesWithLogistics,
  navigationOrigin,
  setCreateAndAssign,
  setRequireLogistics
}: {
  handleSubmitCustomCallback: (employee: INewEmployeeInfo, createAndAssignParam?: boolean) => void;
  customTextButton?: string;
  isButtonLoading?: boolean;
  currentEmployeeData?: Iemployee;
  logisticsCountry?: string;
  countriesList?: ICountryDetail[];
  listOfCountriesWithLogistics?: ICountryDetail[];
  navigationOrigin?: string;
  setCreateAndAssign?: (boolean: boolean) => void;
  setRequireLogistics?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { selectedArea, selectedCountry, setSelectedCountry, areaOptions, setSelectedArea } = useNewEmployeeFormSchema({
    countriesList: countriesList
  });
  const orgData = getOrgData();
  const { push } = useHistory();
  const { t } = useTranslation();
  const { state }: { state: { createFromSpecificCountry: string; requireAllFields: boolean } } = useLocation();

  const [uploadedPhoto, setUploadedPhoto] = useState<File>();
  const [userAddress, setUserAddress] = useState<IaddressFromGoogleMaps | undefined>();
  const [userAdditionalReferences, setUserAdditionalReferences] = useState<string>();
  const [, setIsUploadedPhotoValid] = useState<boolean>();
  const [temporaryPhotoUrl, setTemporaryPhotoUrl] = useState<string>();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [emailAlreadyRegisteredError, setEmailAlreadyRegisteredError] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneCountry, setPhoneCountry] = useState<ICountryDetail>();
  const [phoneAlreadyRegisteredError, setPhoneAlreadyRegisteredError] = useState<string>();
  const [personalId, setPersonalId] = useState<string>();
  const [position, setPosition] = useState<string>();
  const [city, setCity] = useState<IcityDetails>();
  const [zipCode, setZipCode] = useState<string>();
  const [showAssignToolsModal, setShowAssignToolsModal] = useState<boolean>(false);

  const { createFromSpecificCountry, requireAllFields } = state || {};
  const predefinedCountry = logisticsCountry || createFromSpecificCountry;
  const blockCountrySelection = !!predefinedCountry && !!selectedCountry;
  const newEmployeeForm = 'nodi:employeeInternal:newEmployeeForm:';
  const cityPillKey = t(`${newEmployeeForm}nameAndPills:employeePills:city`);
  const areaPillKey = t(`${newEmployeeForm}nameAndPills:employeePills:area`);
  const positionPillKey = t(`${newEmployeeForm}nameAndPills:employeePills:position`);
  const additionalReferencesErrorText = userAddress && !userAdditionalReferences ? 'Completa las indicaciones' : '';
  const currentName = firstName && lastName ? `${firstName} ${lastName}` : undefined;
  const idDocumentData = getIdDocumentData(selectedCountry || undefined, personalId);

  const alertEmployeeActiveOrders = async (employeeId?: number | string | null) => {
    if (!employeeId) return;
    try {
      const employeeActiveOrders = await getActiveOrdersByEmployee(employeeId);
      if (employeeActiveOrders?.length <= 0 || !employeeActiveOrders[0] || !employeeActiveOrders[0].orderId) return;
      const redirection = () =>
        push(`/nodi/orders/details/${employeeActiveOrders[0].orderId}?from=employee-edit&id=${employeeId}`);
      displaySuccessNotification({
        customJSXMessage: (
          <div>
            <div>Este empleado tiene una orden activa. Si editas datos en el</div>
            <div>perfil no se actualiza la orden, solo se actualizará el perfil.</div>
          </div>
        ),
        button1Text: 'Ver orden',
        button1Action: redirection
      });
    } catch {
      displayErrorNotification();
    }
  };

  const uploadedFileHandler = (newUploadedPhoto: Tany) => {
    if (!newUploadedPhoto) return;
    setUploadedPhoto(newUploadedPhoto);
    const isFileValid = validateFile(
      newUploadedPhoto,
      ['image/png', 'image/jpeg'],
      2,
      t(`recurrentWords:isImageFileValid:format`),
      t(`recurrentWords:isImageFileValid:size`)
    );
    setIsUploadedPhotoValid(isFileValid);
    isFileValid && setTemporaryPhotoUrl(URL.createObjectURL(newUploadedPhoto));
  };

  const employeePills = {
    [cityPillKey]: city?.name || `${t(`${newEmployeeForm}nameAndPills:employeePills:city`)}`,
    [areaPillKey]: selectedArea || `${t(`${newEmployeeForm}nameAndPills:employeePills:area`)}`,
    [positionPillKey]: position || `${t(`${newEmployeeForm}nameAndPills:employeePills:position`)}`
  };

  const defaultPhoneCountryData =
    currentEmployeeData?.country?.id &&
    currentEmployeeData?.country?.name &&
    currentEmployeeData?.country?.code &&
    currentEmployeeData?.country?.flag &&
    currentEmployeeData?.phoneData?.phoneCode
      ? {
          id: currentEmployeeData?.country?.id,
          name: currentEmployeeData?.country?.name,
          code: currentEmployeeData?.country?.code,
          flag: currentEmployeeData?.country?.flag,
          phoneCode: currentEmployeeData?.phoneData?.phoneCode
        }
      : undefined;

  const findCountryData = (countryCode?: string) => {
    if (!countryCode) return;
    return countriesList?.find(element => countryCode === element?.code);
  };
  const disableButton = () => {
    if (!orgData.organizationId || getPhoneError() || getEmailError()) return true;
    if (!firstName || !lastName || !city?.name || !email || !phoneNumber || !phoneCountry) return true;
    if (!selectedArea || !position) return true;
    if (!selectedCountry) return true;
    if (logisticsCountry && userAddress?.country !== logisticsCountry) return true;
    if ((logisticsCountry || requireAllFields) && !userAddress) return true;
    if (requireAllFields && !zipCode) return true;
    if (userAddress && selectedCountry && userAddress.country !== selectedCountry?.code) return true;
    if (idDocumentData?.errorText) return true;
    if (getIsInvalidZipCode(zipCode)) return true;
    if (userAddress && !userAdditionalReferences) return true;
    return false;
  };
  const addressErrorText = getAddressErrorText(
    selectedCountry?.code,
    selectedCountry?.name,
    userAddress?.country || undefined,
    true,
    userAddress?.coordinates
  );

  const getEmailError = () => {
    if (!!email && !isEmailValid(email)) return t('recurrentWords:inputError:invalidEmail');
    return emailAlreadyRegisteredError;
  };

  const getPhoneError = () => {
    if (phoneNumber && phoneNumber.length > 3 && !isPhoneValid(phoneNumber))
      return `${t('recurrentWords:inputError:invalidPhone')} ${
        phoneCountry?.name || t(`${newEmployeeForm}firstLineInputs:phone`)
      }`;
    return phoneAlreadyRegisteredError;
  };

  const getZipCodeErrorText = () => {
    if (requireAllFields && !zipCode && userAdditionalReferences) return ' ';
    return getIsInvalidZipCode(zipCode) ? invalidZipCodeText : undefined;
  };

  const registerInitialData = () => {
    if (!firstName) setFirstName(currentEmployeeData?.firstName || undefined);
    if (!lastName) setLastName(currentEmployeeData?.lastName || undefined);
    if (!email) setEmail(currentEmployeeData?.mail || undefined);
    if (!city) setCity({ name: currentEmployeeData?.city || undefined });
    if (!personalId) setPersonalId(currentEmployeeData?.personalId || undefined);
    if (!position) setPosition(currentEmployeeData?.position || undefined);
    if (!zipCode) setZipCode(formatZipCode(currentEmployeeData?.address?.zipCode) || undefined);
    if (!selectedArea) setSelectedArea(currentEmployeeData?.area || undefined);
  };

  const closeModalCallback = () => {
    setShowAssignToolsModal(false), setCreateAndAssign && setCreateAndAssign(false);
    displayErrorNotification({
      customJSXMessage: (
        <>{'Empleado no guardado. Selecciona Solo Guardar o completa la asignación de herramientas para guardar.'}</>
      )
    });
  };

  const handleSubmitCallback = async (createAndAssignParam?: boolean) => {
    if (disableButton()) return;
    setShowAssignToolsModal && setShowAssignToolsModal(false);
    setIsFormLoading(true);
    const completeAddress = {
      address: userAddress?.address || '',
      coordinates: userAddress?.coordinates,
      additionalReferences: userAdditionalReferences,
      country: userAddress?.country,
      zipCode,
      city: city?.name?.trim()
    };

    const body = {
      organizationId: orgData?.organizationId,
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      personalId: personalId?.trim(),
      email: email?.trim(),
      phone: phoneNumber?.trim(),
      city: city?.name?.trim(),
      cityId: city?.id,
      area: selectedArea?.trim(),
      position: position?.trim(),
      country: selectedCountry,
      countryPhone: phoneCountry,
      uploadedPhoto,
      address: completeAddress
    };
    try {
      handleSubmitCustomCallback(body as INewEmployeeInfo, createAndAssignParam);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setIsFormLoading(false);
    }
  };

  const handleClickCreateAndAssign = async () => {
    const employeeCountryAllowsLogistics = listOfCountriesWithLogistics?.some(
      countryWithLogistics => countryWithLogistics?.code === selectedCountry?.code
    );
    if (!employeeCountryAllowsLogistics) {
      setRequireLogistics && setRequireLogistics(false);
      return handleSubmitCallback(true);
    }
    setCreateAndAssign && setCreateAndAssign(true);
    setShowAssignToolsModal(true);
  };

  const ButtonsSection = () => {
    const originIsCreateEmployee = navigationOrigin && navigationOrigin === 'create-employee' ? true : false;
    return (
      <>
        {originIsCreateEmployee && (
          <section className="buttonsSection">
            <NudosButton
              buttonText={'Guardar y asignar herramientas'}
              componentSize="medium"
              handleClick={handleClickCreateAndAssign}
              isButtonLoading={isFormLoading || isButtonLoading}
              isButtonDisabled={disableButton()}
            />
            <NudosButton
              buttonText={'Solo guardar'}
              handleClick={() => handleSubmitCallback(false)}
              colorPalette="secondary"
              componentSize="small"
              isButtonDisabled={disableButton()}
              isButtonLoading={isFormLoading || isButtonLoading}
            />
          </section>
        )}
        {!originIsCreateEmployee && (
          <section className="buttonsSection">
            <NudosButton
              buttonText={customTextButton || t(`${newEmployeeForm}addressInfoSection:secondLineInputs:NudosButon`)}
              handleClick={() => handleSubmitCallback(false)}
              componentSize="medium"
              isButtonDisabled={disableButton()}
              isButtonLoading={isFormLoading || isButtonLoading}
            />
          </section>
        )}
      </>
    );
  };

  useEffect(() => {
    // This effect handles country preselection in the cases where an specific country is required
    if (!predefinedCountry || !countriesList?.length) return;
    const countryFromWhichToCreate = countriesList?.find(country => country?.name === createFromSpecificCountry);
    const predefinedCountryCode = logisticsCountry ? logisticsCountry : countryFromWhichToCreate?.code;
    const newCountry = findCountryData(predefinedCountryCode);
    newCountry && setSelectedCountry(newCountry);
  }, [predefinedCountry, countriesList]);

  useEffect(() => {
    if (currentEmployeeData?.address?.address && currentEmployeeData?.address?.country) {
      setUserAddress({
        address: currentEmployeeData?.address?.address,
        country: currentEmployeeData?.address?.country,
        city: currentEmployeeData?.address?.city || '',
        coordinates: currentEmployeeData?.address?.coordinates || undefined
      });
    }
    if (currentEmployeeData?.country) setSelectedCountry(currentEmployeeData?.country);
    setUserAdditionalReferences(currentEmployeeData?.address?.additionalReferences || '');
  }, [currentEmployeeData]);

  useEffect(() => {
    registerInitialData();
    currentEmployeeData && alertEmployeeActiveOrders(currentEmployeeData?.userId);
  }, [currentEmployeeData]);

  useEffect(() => {
    phoneAlreadyRegisteredError && setPhoneAlreadyRegisteredError(undefined);
  }, [phoneNumber]);

  useEffect(() => {
    emailAlreadyRegisteredError && setEmailAlreadyRegisteredError(undefined);
  }, [email]);

  return (
    <div className="newAssigneeForm">
      <section className="generalInfoSection">
        <div className="profilePhotoSection">
          <div className="photoContainer">
            <NudosEncircledPhotoFileInput
              uploadedFileHandler={uploadedFileHandler}
              selectedImageUrl={temporaryPhotoUrl ? temporaryPhotoUrl : ''}
              customPlaceholderImageUrl={
                currentEmployeeData && currentEmployeeData?.photoProfile ? currentEmployeeData.photoProfile : undefined
              }
            />
          </div>
          <div className="nameAndPills">
            {currentName && <div className="currentName">{currentName}</div>}
            {(!currentName || currentName.length === 0) && (
              <div className="noName">{t(`${newEmployeeForm}nameAndPills:noName`)}</div>
            )}
            <EmployeePills pills={employeePills} isActive={true} />
          </div>
        </div>
        <div className="sectionTitle">{t(`${newEmployeeForm}sectionTitle`)}</div>
        <div className="generalInfoForm">
          <div className="firstLineInputs">
            <NudosTextInput
              label={t(`${newEmployeeForm}firstLineInputs:name`)}
              placeholder={t(`${newEmployeeForm}firstLineInputs:name`)}
              isFilled={!!firstName}
              componentSize="small"
              customClassName=""
              handleChange={setFirstName}
              defaultValue={currentEmployeeData?.firstName || undefined}
            />
            <NudosTextInput
              label={t(`${newEmployeeForm}firstLineInputs:lastName`)}
              placeholder={t(`${newEmployeeForm}firstLineInputs:lastName`)}
              isFilled={!!lastName}
              componentSize="small"
              handleChange={setLastName}
              defaultValue={currentEmployeeData?.lastName || undefined}
            />
            <NudosTextInput
              label={t(`${newEmployeeForm}firstLineInputs:email`)}
              placeholder={t(`${newEmployeeForm}firstLineInputs:emailPlaceholder`)}
              isFilled={!!email}
              componentSize="small"
              isDeactivated={!!currentEmployeeData?.mail || false}
              errorText={getEmailError()}
              handleChange={setEmail}
              defaultValue={currentEmployeeData?.mail || undefined}
            />
            <NudosPhoneInput
              componentSize="small"
              label={t(`${newEmployeeForm}firstLineInputs:phone`)}
              handleCountryPhoneChange={setPhoneCountry}
              handlePhoneChange={setPhoneNumber}
              defaultPhoneNumber={currentEmployeeData?.phoneData?.phone || undefined}
              defaultCountryData={defaultPhoneCountryData || undefined}
              personalClass={'countryInput'}
              placeholder={t(`${newEmployeeForm}firstLineInputs:phone`)}
              errorText={getPhoneError()}
              countriesList={countriesList || []}
            />
            <NudosTextInput
              label={idDocumentData?.label}
              placeholder={idDocumentData?.placeholder}
              isFilled={!!idDocumentData?.value}
              handleChange={setPersonalId}
              componentSize="small"
              customClassName="idDocumentInput"
              defaultValue={idDocumentData?.value}
              errorText={idDocumentData?.errorText}
              caption={idDocumentData?.caption}
            />
          </div>
          <div className="secondLineInputs">
            <NudosSelectDropdown
              selectOptions={areaOptions}
              label="Área"
              currentValueOrplaceholder={selectedArea || 'Área'}
              isFilled={!!selectedArea}
              componentSize="small"
            />
            <NudosTextInput
              label={t(`${newEmployeeForm}secondLineInputs:postiton`)}
              placeholder={t(`${newEmployeeForm}secondLineInputs:postiton`)}
              isFilled={!!position}
              handleChange={setPosition}
              componentSize="small"
              defaultValue={currentEmployeeData?.position || undefined}
            />
          </div>
        </div>
      </section>
      <section className="addressInfoSection">
        <div className="sectionTitle">{t(`${newEmployeeForm}addressInfoSection:sectionTitle`)}</div>
        <div className="addressFields">
          <div className="firstLineInputs">
            <NudosCountryDropdown
              charactersLimit={22}
              currentValue={selectedCountry}
              countriesList={countriesList || []}
              updateCurrentValueCallback={setSelectedCountry}
              isDeactivated={blockCountrySelection}
            />
            <NudosCityDropdown
              countryId={selectedCountry?.id}
              componentSize={'small'}
              updateCurrentValueCallback={setCity}
              currentValue={city}
              isFocused={true}
            />
            <NudosTextInput
              label={t(`${newEmployeeForm}addressInfoSection:firstLineInputs:zipCode`)}
              placeholder={t(`${newEmployeeForm}addressInfoSection:firstLineInputs:zipCode`)}
              isFilled={!!zipCode}
              handleChange={setZipCode}
              handleBlur={newZipCode => setZipCode(formatZipCode(newZipCode))}
              componentSize="small"
              defaultValue={zipCode || formatZipCode(currentEmployeeData?.address?.zipCode) || undefined}
              errorText={getZipCodeErrorText()}
            />
          </div>
          <div className="secondLineInputs">
            <NudosAddressSearchBar
              label={t(`${newEmployeeForm}addressInfoSection:secondLineInputs:address`)}
              componentSize="large"
              handleAddressSelection={selectedAddress => setUserAddress(selectedAddress)}
              defaultValueAddressName={
                currentEmployeeData && currentEmployeeData?.address?.country
                  ? currentEmployeeData.address?.address || undefined
                  : undefined
              }
              errorText={addressErrorText}
              regionBias={selectedCountry?.code}
              didntFindAddressTooltipTopPosition={56 + (addressErrorText ? 10 : 0)}
              didntFindAddressTooltipRightPosition={0}
              customPlaceholder={t(`${newEmployeeForm}addressInfoSection:secondLineInputs:addressCustomPlaceholder`)}
            />
            <NudosTextInput
              label={t(`${newEmployeeForm}addressInfoSection:secondLineInputs:directions`)}
              placeholder={t(`${newEmployeeForm}addressInfoSection:secondLineInputs:directionsPlaceholder`)}
              componentSize="medium"
              isFilled={!!userAdditionalReferences}
              handleChange={(inputText: string) => setUserAdditionalReferences(inputText)}
              defaultValue={currentEmployeeData?.address?.additionalReferences || undefined}
              errorText={additionalReferencesErrorText}
            />
          </div>
        </div>
        {showAssignToolsModal && (
          <AssignToolsModal
            submitModalCallback={handleSubmitCallback}
            navigationOrigin={navigationOrigin}
            closeModalCallback={closeModalCallback}
            setRequireLogistics={setRequireLogistics}
          />
        )}
      </section>
      <ButtonsSection />
    </div>
  );
};

export default NewEmployeeForm;
