import { FC, useState } from 'react';
import IconGreenThunder from '../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import IconWarningInformation from '../../../assets/DesignSystem/SVGComponents/IconWarningInformation';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { nudosFormatCurrency } from '../../../utils/formatNumbers';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../utils/segment';
import './ShipmentSubtotalTooltip.scss';

interface IShipmentSubtotalTooltip {
  step?: number;
  primeTotal?: number;
  customMessageText?: string;
}

const ShipmentSubtotalTooltip: FC<IShipmentSubtotalTooltip> = ({
  step,
  primeTotal = 0,
  customMessageText = undefined
}: IShipmentSubtotalTooltip) => {
  const { t } = useTranslation();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const myElement = document.getElementById('information-id-subtotal-tooltip');
  const positionIcon = myElement ? myElement.getBoundingClientRect() : undefined;

  const languageKey = 'nodi:checkout:shipmentSubtotalTooltip:';
  const { stateSubscription } = useStateSubscriptions();
  const { levelSubscription } = stateSubscription;
  const isFree = levelSubscription === 'Free';
  const finalTotalValue = primeTotal ? nudosFormatCurrency({ value: primeTotal, maximumFractionDigits: 2 }) : '$0';

  const floatShipmentContainerStyle = {
    top: `${positionIcon?.bottom ? positionIcon?.bottom - 120 : 0}px`,
    left: `${positionIcon?.left ? positionIcon?.left - 210 : 0}px`
  };

  const warningOnMouseEnterFunction = () => {
    if (!customMessageText) {
      segmentTrackEvent({
        [isFree ? 'freeToolTipShipmentOrder' : 'primeToolTipShipmentOrder']: {
          ScreenName: step === 1 ? 'Kart' : step === 2 ? 'Shipping' : 'Billing&Payment'
        }
      });
    }
  };

  return (
    <div id="shipmentSubtotalTooltip">
      <div
        id="information-id-subtotal-tooltip"
        className="iconInformationTooltip"
        onMouseOverCapture={() => setShowTooltip(true)}
        onMouseMoveCapture={() => setShowTooltip(true)}
        onMouseOutCapture={() => setShowTooltip(false)}
        onMouseOut={() => setShowTooltip(false)}
      >
        <IconWarningInformation color="#999999" onMouseEnter={warningOnMouseEnterFunction} />
        <></>

        {showTooltip && (
          <>
            {!!customMessageText && (
              <div
                className="simpleTooltipContainer"
                style={{
                  top: `${positionIcon?.bottom ? positionIcon?.bottom + 30 : 0}px`,
                  left: `${positionIcon?.left ? positionIcon?.left - 75 : 0}px`
                }}
              >
                {customMessageText && <div className="textSimpleTooltip">{customMessageText}</div>}
              </div>
            )}
            {!customMessageText && (
              <div className="floatShipmentContainer" style={floatShipmentContainerStyle}>
                <div className="minTitle">{t(`${languageKey}minTitle`)}</div>
                <div className="minGray">{`${
                  step === 2 ? t(`${languageKey}step2`) : t(`${languageKey}otherStep`)
                }`}</div>
                <div className="minGray">{t(`${languageKey}lessThanMinGray`)}</div>
                {isFree && (
                  <>
                    <div className="minGray">{t(`${languageKey}moreThanMinGray`)}</div>
                    <div className="minGray darkGray finalText">
                      {`${t('recurrentWords:pay')} ${finalTotalValue} USD ${t('nodidynamicBanner:with')}`}
                      <div className="text-nudos-purple">&nbsp;Nodi Prime</div>
                      <div className="spaceIcon">
                        <IconGreenThunder fill="#7B61FF" />
                      </div>
                    </div>
                  </>
                )}
                {!isFree && (
                  <div className="minGray finalText">
                    {`${t(`${languageKey}moreThan`)} 350 USD = `}
                    <div className="text-nudos-purple ml-0.5">{t(`${languageKey}finalText`)}</div>
                    <div className="spaceIcon">
                      <IconGreenThunder fill="#7B61FF" />
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShipmentSubtotalTooltip;
