import { memo, useEffect, useState } from 'react';
import useSWR from 'swr';
import { getBrands } from '../../services/services';
import useShopStore from '../../state/useShopContext';
import useEcommerceControler from '../../views/ecommerce/ecommerce.controller';
import { Filter } from '..';
import FiltersIcon from '../../assets/images/components/sidebar/FiltersIcon';
import { getPathRoute, isRouteMyLocation } from '../../utils/getPathRoute';
import SidebarFiltersSkeleton from './Components/SidebarFiltersSkeleton/SidebarFiltersSkeleton';
import { getProductLine, getProfileType } from '../../services/filters';
import { IFilter, IProfileType } from '../../types/global';
import { DeleteSquare } from '../../assets/images/views/Admin/main';
import ModalPassWord from '../modalPassword';
import { EquipmentFilters, NodiMenu, TopLoaderFilters } from './Components';
import { displayErrorNotification } from '../../utils/displayNudosStandardNotifications';
import useLogOutState from '../../state/useLogOutState';
import { useTranslation } from 'react-i18next';

import './SideBarStyles.scss';

function SideBar({ path }: { path: 'catalogue' | 'nodi' }) {
  const { t } = useTranslation();
  const { filters, pillsFilters, categoryFilters, setFilters, loadingFilterPills } = useShopStore();
  const { isLogoutModalOpen } = useLogOutState();
  const { orgData, user } = useEcommerceControler();
  const currentModule = getPathRoute();

  const [loadingBrands, setLoadingBrands] = useState<boolean>(false);
  const [profileFilter, setProfileFilter] = useState<IFilter>();
  const [lineFilter, setLineFilter] = useState<IFilter>();
  const [loadingLineFilter, setLoadingLineFilter] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const isAdmin = path === 'nodi';
  const isUploadTools = isAdmin && currentModule === 'upload-external-tools';
  const isAssignation = isAdmin && currentModule === 'assignation';
  const isEcommerce = path === 'catalogue' && currentModule === 'home';
  const isCheckout = path === 'catalogue' && currentModule === 'checkout';
  const isNotFoundView = isAdmin && currentModule === 'not-found';
  const isProductDetails = path === 'catalogue' && currentModule === 'product';
  const isLogisticServiceDetail = isRouteMyLocation('logistics-services', 2) && isRouteMyLocation('details', 3);
  const isEcommerceSearch = path === 'catalogue' && currentModule === 'search';
  const isRequestLogistics = isRouteMyLocation('request-logistics', 2);
  const existEquipmentFilters = true;
  const isEditOrCreateEmployee =
    isRouteMyLocation('employees', 2) && (isRouteMyLocation('create', 3) || isRouteMyLocation('edit', 3));

  const moduleDoesntHaveSidebar =
    isProductDetails ||
    isCheckout ||
    isEcommerceSearch ||
    isEditOrCreateEmployee ||
    isAssignation ||
    isLogisticServiceDetail ||
    isRequestLogistics ||
    isNotFoundView;

  const filteredCategory =
    filters.find(filter => filter.name === 'category')?.values[0] ||
    categoryFilters?.find(filter => filter.label === 'Laptops')?.value;

  const areFiltersActive = filters.some(filter => filter.name !== 'category');
  const resetFilters = () => {
    if (areFiltersActive) {
      const newFilters = filters.filter(filter => filter.name === 'category');
      newFilters.length > 0 ? setFilters(newFilters) : setFilters([]);
    }
  };
  const brandsByCategory = filteredCategory ? `?categoryId=${JSON.stringify(filteredCategory)}` : '';
  const productSyles = {
    height: currentModule === 'product' ? '92px' : ''
  };
  const adminBar = {
    width: '102px'
  };

  const headerSpace = {
    top: '54px',
    height: `calc(100vh - 54px)`
  };

  const extraStyles = {
    ...productSyles,
    ...(isAdmin ? adminBar : {}),
    ...headerSpace
  };

  const formatFilter = (profiles: IProfileType) => {
    const newList: IFilter = [];
    for (const profile of profiles) {
      const newProfile = {
        label: t(`services:filters:name:${profile?.nameStringId}`),
        value: profile?.name,
        id: profile?.id,
        description: t(`services:filters:description:${profile?.descriptionStringId}`)
      };
      newList.push(newProfile);
    }
    return newList;
  };

  const getLineAndProfileFilters = async () => {
    setLoadingLineFilter(true);
    try {
      const profile = await getProfileType();
      const line = await getProductLine();
      const formatProfile = formatFilter(profile);
      const formatLine = formatFilter(line);
      setProfileFilter(formatProfile);
      setLineFilter(formatLine);
      setLoadingLineFilter(false);
    } catch (error) {
      displayErrorNotification();
      setLoadingLineFilter(false);
    }
  };

  const showModalPassword = () => {
    if (orgData?.organizationState === 'active' && !localStorage.getItem('typeLogin')) {
      setShowModal(true);
    }
  };

  const handleChangeFilters = (key: string, value: string) => {
    const myFilter = filters.find(item => item.name === key);
    const checkeds = myFilter?.values || [];
    const valueIndex = checkeds.findIndex(v => `${v}` === `${value}`);
    const newValues = checkeds;
    if (valueIndex >= 0) {
      newValues.splice(valueIndex, 1);
    } else {
      newValues.push(`${value}`);
    }
    const newParam = {
      name: key,
      includes: 'in',
      values: newValues
    };
    if (filters.length > 0) {
      const params = filters;
      const index = params.findIndex(param => param.name === key);
      if (index >= 0) {
        if (newValues.length === 0) {
          params.splice(index, 1);
        } else {
          params[index] = newParam;
        }
      } else {
        params.push(newParam);
      }
      setFilters(params);
    } else {
      setFilters([newParam]);
    }
  };

  const isFilterActive = (key: string, value?: string) => {
    if (key && value) {
      const filterName = filters.find(item => item.name === key);
      if (filterName && filterName?.values?.length > 0) {
        const existValues = filterName.values.find(element => element === value);
        if (existValues) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    showModalPassword();
  }, []);

  useEffect(() => {
    if (isEcommerce) getLineAndProfileFilters();
  }, [isEcommerce]);

  if (moduleDoesntHaveSidebar) return <></>;

  return (
    <aside
      id="sideBar"
      style={extraStyles}
      className={`${isLogoutModalOpen || showModal ? 'modal-open' : ''} ${isEcommerce ? 'ecommerce-home' : ''}`}
    >
      {showModal && <ModalPassWord setShowModal={setShowModal} />}
      {user && isEcommerce && (
        <>
          <div
            id="filter-space"
            className="limitsSidebarFilters"
            style={{ marginTop: existEquipmentFilters ? '16px' : '', paddingBottom: '0px' }}
          >
            <div className={`filter-space-title spaceNewHeader`}>
              <FiltersIcon />
              <h1>{t(`recurrentWords:filters`)}</h1>
            </div>
            <FetchFilter
              identifier={brandsByCategory}
              fetcher={getBrands}
              setLoadingBrands={setLoadingBrands}
              loadingFilterPills={loadingFilterPills}
              loadingLineFilter={loadingLineFilter}
            />
            {!loadingBrands &&
              !loadingLineFilter &&
              !!pillsFilters &&
              !!pillsFilters.length &&
              !loadingFilterPills &&
              pillsFilters.map(pillFilter => {
                const [pillFilterName, pillFilterValues] = Object.entries(pillFilter)[0];
                const keyByCategory = `${pillFilterName}-${
                  filters.find(filter => filter.name === 'category')?.name || 'no-category'
                }`;
                return <Filter key={keyByCategory} name={pillFilterName.toLowerCase()} filters={pillFilterValues} />;
              })}
            {(loadingBrands || loadingFilterPills || loadingLineFilter) && <SidebarFiltersSkeleton />}
          </div>
          {loadingBrands || loadingFilterPills || loadingLineFilter ? (
            <TopLoaderFilters />
          ) : (
            <EquipmentFilters
              filtersStyles={'filtersStyles'}
              handleChangeFilters={handleChangeFilters}
              isFilterActive={isFilterActive}
              profileFilter={profileFilter}
              lineFilter={lineFilter}
            />
          )}
        </>
      )}
      {user && isAdmin && !isAssignation && !isEditOrCreateEmployee && !isUploadTools && (
        <div className="board-sidebar">
          <NodiMenu />
        </div>
      )}
      {isEcommerce && (
        <div className={`clean-filters-button-container ${areFiltersActive ? 'active' : ''}`} onClick={resetFilters}>
          <p>{t(`recurrentWords:cleanFilters`)}</p>
          <DeleteSquare />
        </div>
      )}
    </aside>
  );
}

export default SideBar;

const _FetchFilter = ({
  identifier,
  fetcher,
  setLoadingBrands,
  loadingFilterPills,
  loadingLineFilter
}: {
  identifier: string;
  fetcher: (fetch_filters: string) => void;
  setLoadingBrands: (status: boolean) => void;
  loadingFilterPills?: boolean;
  loadingLineFilter?: boolean;
}) => {
  const options = {
    revalidateOnFocus: false
  };
  const { t } = useTranslation();
  const { data, error } = useSWR(`${identifier}`, fetcher, options);
  const loadingFilter = !data && !error;

  useEffect(() => {
    setLoadingBrands(loadingFilter);
  }, [loadingFilter]);

  if (data && !loadingFilterPills && !loadingLineFilter)
    return <Filter name="marca" filters={data} alias={t('nodi:checkout:brand')} />;
  return <></>;
};

const FetchFilter = memo(_FetchFilter);
