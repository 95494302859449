import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <rect width={24} height={24} rx={4} fill="#ff664e" />
    <path
      d="M17.374 16.084a65.327 65.327 0 0 0 .105-5.767 1.91 1.91 0 0 0-.367-1.083c-.88-1.198-1.58-1.943-2.735-2.834a1.878 1.878 0 0 0-1.107-.387A57.976 57.976 0 0 0 12 6c-1.357 0-2.452.04-3.584.119a1.923 1.923 0 0 0-1.79 1.797A65.307 65.307 0 0 0 6.5 12c0 1.4.043 2.766.126 4.084.06.964.826 1.731 1.79 1.797C9.548 17.96 10.643 18 12 18c1.357 0 2.452-.04 3.584-.119a1.923 1.923 0 0 0 1.79-1.797Z"
      fill="#ff664e"
      stroke="#fff"
    />
    <path d="M10.094 12.016h3.812" stroke="#fff" strokeLinecap="round" />
    <path d="M10.094 9h2.3m-2.3 5.922h3.8-3.8Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const AddInformationStepIcon = memo(ForwardRef);
export default AddInformationStepIcon;
