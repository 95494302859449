import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6451_3467)">
      <path
        d="M11.8205 5.38L11.316 3.8675C11.1837 3.4691 10.9291 3.12253 10.5886 2.87704C10.2481 2.63155 9.83881 2.49962 9.419 2.5H8.45C8.33395 1.93571 8.02691 1.42867 7.58063 1.06436C7.13434 0.700045 6.5761 0.500727 6 0.5H2.5C1.8372 0.500794 1.20178 0.764441 0.73311 1.23311C0.264441 1.70178 0.000793929 2.3372 0 3L0 9.75C0.00427757 10.0957 0.110363 10.4325 0.304994 10.7182C0.499625 11.0039 0.774166 11.226 1.09429 11.3565C1.41442 11.4871 1.76593 11.5204 2.10488 11.4523C2.44383 11.3841 2.75518 11.2176 3 10.9735C3.24482 11.2176 3.55617 11.3841 3.89512 11.4523C4.23407 11.5204 4.58558 11.4871 4.90571 11.3565C5.22583 11.226 5.50037 11.0039 5.69501 10.7182C5.88964 10.4325 5.99572 10.0957 6 9.75V9.5H7.5V9.75C7.5 10.2141 7.68437 10.6593 8.01256 10.9874C8.34075 11.3156 8.78587 11.5 9.25 11.5C9.71413 11.5 10.1592 11.3156 10.4874 10.9874C10.8156 10.6593 11 10.2141 11 9.75V9.2315C11.304 9.05601 11.5564 8.80362 11.7319 8.49969C11.9074 8.19576 11.9999 7.85098 12 7.5V6.487C12.0001 6.11075 11.9394 5.73695 11.8205 5.38ZM10.3675 4.184L10.806 5.5H8.5V3.5H9.419C9.62888 3.50005 9.83342 3.56614 10.0037 3.6889C10.1739 3.81167 10.3012 3.98488 10.3675 4.184ZM1 3C1 2.60218 1.15804 2.22064 1.43934 1.93934C1.72064 1.65804 2.10218 1.5 2.5 1.5H6C6.39782 1.5 6.77936 1.65804 7.06066 1.93934C7.34196 2.22064 7.5 2.60218 7.5 3V8.5H1V3ZM1.75 10.5C1.55109 10.5 1.36032 10.421 1.21967 10.2803C1.07902 10.1397 1 9.94891 1 9.75V9.5H2.5V9.75C2.5 9.94891 2.42098 10.1397 2.28033 10.2803C2.13968 10.421 1.94891 10.5 1.75 10.5ZM5 9.75C5 9.94891 4.92098 10.1397 4.78033 10.2803C4.63968 10.421 4.44891 10.5 4.25 10.5C4.05109 10.5 3.86032 10.421 3.71967 10.2803C3.57902 10.1397 3.5 9.94891 3.5 9.75V9.5H5V9.75ZM10 9.75C10 9.94891 9.92098 10.1397 9.78033 10.2803C9.63968 10.421 9.44891 10.5 9.25 10.5C9.05109 10.5 8.86032 10.421 8.71967 10.2803C8.57902 10.1397 8.5 9.94891 8.5 9.75V9.5H10V9.75ZM10 8.5H8.5V6.5H11V7.5C11 7.76522 10.8946 8.01957 10.7071 8.20711C10.5196 8.39464 10.2652 8.5 10 8.5Z"
        fill={props?.fill || '#999999'}
      />
    </g>
    <defs>
      <clipPath id="clip0_6451_3467">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuServicesIcon = memo(ForwardRef);
export default MenuServicesIcon;
