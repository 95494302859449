import { FC, useEffect, useState } from 'react';
import { NudosButton, NudosGenericModal, NudosModalContent } from '../../../../../components/NudosComponents';
import { getSpeiBankData } from '../../../../../services/checkout';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import { NudosTransfDetails } from '../../../../../components/DesignSystem';
import { IBank } from '../../../../../types/checkout';
import useCartState from '../../../../../state/useCartState';
import { Tany } from '../../../../../types/global';
import { updateProofOfPayment } from '../../../../../services/orders';
import isFileValid from '../../../../../utils/validateFile';
import { IorderDetails } from '../../../../../types/orders';
import { useTranslation } from 'react-i18next';
import './DetailsPaymentModal.scss';

interface IDetailsPaymentModal {
  closeModal: () => void;
  paymentMethod: string;
  orderData: IorderDetails;
}

const DetailsPaymentModal: FC<IDetailsPaymentModal> = ({
  closeModal,
  paymentMethod,
  orderData
}: IDetailsPaymentModal) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:orderDetails:orderPaymentDetails:detailsPaymentModal:';

  const { dataFinishCart } = useCartState();
  const [SPEIBankData, setSPEIBankData] = useState<IBank>();
  const [loading, setLoading] = useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<Tany>();
  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const orderId = dataFinishCart?.orderId || orderData?.orderId;
  const isPaid = !!SPEIBankData?.isPaid;
  const chargeFileNotification = t(`recurrentWords:chargeFile:chargeFileNotification`);
  const chargeFileErrorFormat = t(`recurrentWords:chargeFile:chargeFileErrorFormat`);
  const chargeFileErrorSize = t(`recurrentWords:chargeFile:chargeFileErrorSize`);
  const isPaidMessage = t(`${i18nLanguageKey}isPaidMessage`);
  const modalContentTitle = t(`${i18nLanguageKey}modalContentTitle`);
  const buttonText = t(`${i18nLanguageKey}buttonText`);

  const chargeFile = async (file: Tany) => {
    const uploadedFile = file.target.files[0];
    const fileValid = isFileValid(uploadedFile, ['application/pdf'], 2, chargeFileErrorFormat, chargeFileErrorSize);
    if (!fileValid) return;
    if (!orderId) return;
    setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', uploadedFile);
    try {
      await updateProofOfPayment(orderId, formData);
      setUploadFile(uploadedFile);
      setLoadingFile(false);
      displaySuccessNotification({
        customJSXMessage: <>{chargeFileNotification}</>
      });
    } catch (error) {
      setLoadingFile(false);
      displayErrorNotification();
    }
  };

  const deleteFile = () => setUploadFile(undefined);

  const getInfoBank = async () => {
    if (!orderId) return;
    setLoading(true);
    try {
      const data = await getSpeiBankData(orderId);
      setSPEIBankData(data);
      setLoading(false);
    } catch (error) {
      displayErrorNotification();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paymentMethod === 'Transf. MXN') getInfoBank();
    else setLoading(false);
  }, []);

  return (
    <div id="detailsPaymentModalContainer">
      <NudosGenericModal
        clickOutsideCallback={() => closeModal()}
        closeModalCallback={() => closeModal()}
        modalContent={
          <NudosModalContent
            hideNudosIcon
            customWidth="446px"
            leftAlignmentTitle
            toCloseModal={() => closeModal()}
            modalContentTitle={modalContentTitle}
            CustomModalComponent={
              <div id="detailsPaymentModal">
                {!isPaid && (
                  <NudosTransfDetails
                    orderData={orderData}
                    SPEIBankData={SPEIBankData}
                    loading={loading}
                    chargeFile={file => chargeFile(file)}
                    uploadFile={uploadFile}
                    deleteFile={deleteFile}
                    loadingFile={loadingFile}
                  />
                )}
                {isPaid && <div className="isPaidMessage">{isPaidMessage}</div>}
                <div className="buttonContainer">
                  <NudosButton
                    buttonText={buttonText}
                    customWidth="380px"
                    customHeight="38px"
                    isButtonDisabled={loading}
                    handleClick={() => closeModal()}
                  />
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default DetailsPaymentModal;
