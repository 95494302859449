import { NudosSearchbarDropdown } from '../../../../../../components/DesignSystem';
import { AVAILABLE_BILLING_COUNTRIES_FOR_LS } from '../../../../../../constants/logisticServices.constants';
import { ICountryDetail } from '../../../../../../types/countries';
import './LogisticServiceBillingCountrySearchbarDropdown.scss';

const LogisticServiceBillingCountrySearchbarDropdown = ({
  selectedBillingCountry,
  setSelectedBillingCountry,
  listOfCountries
}: {
  selectedBillingCountry: ICountryDetail | undefined;
  setSelectedBillingCountry: (selectedBillingCountry: ICountryDetail) => void;
  listOfCountries: ICountryDetail[];
}) => {
  const countryOptions = listOfCountries.filter(country => AVAILABLE_BILLING_COUNTRIES_FOR_LS.includes(country?.code));
  const formDropdownOptionsCallback = (
    rawOptions: ICountryDetail[],
    clickOptionCallback?: (option: ICountryDetail) => void
  ) => {
    const countriesOptions = rawOptions.map((rawOption, i) => {
      const handleClick = () => {
        setSelectedBillingCountry(rawOption);
        clickOptionCallback && clickOptionCallback(rawOption);
      };
      return (
        <div key={`option-${rawOption?.code}-${i}`} className="optionContainer billingOption" onClick={handleClick}>
          {rawOption?.name}
        </div>
      );
    });

    return countriesOptions;
  };

  const formSelectedOptionUICallback = (rawOption: ICountryDetail) => {
    return <div className="optionContainer">{rawOption?.name}</div>;
  };

  const filterBySearchStringCallback = (search: string, rawOptions: ICountryDetail[]) => {
    return rawOptions.filter(rawOption => rawOption?.name?.toLowerCase().trim().includes(search?.toLowerCase().trim()));
  };
  return (
    <NudosSearchbarDropdown
      customClassname="logisticServiceBillingCountrySearchbarDropdown"
      label={'País de facturación'}
      componentSize="medium"
      customPlaceholder={'Elige el país de la factura'}
      isFilled={!!selectedBillingCountry}
      currentValue={selectedBillingCountry}
      includeMagnifierIcon={false}
      rawOptions={countryOptions}
      formDropdownOptionsCallback={formDropdownOptionsCallback}
      formSelectedOptionUICallback={formSelectedOptionUICallback}
      filterBySearchStringCallback={filterBySearchStringCallback}
    />
  );
};

export default LogisticServiceBillingCountrySearchbarDropdown;
