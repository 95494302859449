const LoaderOrganization = () => {
  return (
    <div id="companyForm">
      <div className="titleFormAccount">Información de la empresa</div>
      <div className="profileSection">
        <div className="w-64 h-10 animationLoader rounded-md"></div>
      </div>
      <div className="firstSection">
        <div className="spaceCompany mr-5">
          <div className="textInput" style={{ color: 'white' }}>
            Nombre de la empresa
          </div>
          <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
        </div>
        <div className="spaceCompany">
          <div className="textInput" style={{ color: 'white' }}>
            Nombre de la empresa
          </div>
          <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
        </div>
      </div>
      <div className="firstSection">
        <div className="spaceCompany mr-5">
          <div className="textInput" style={{ color: 'white' }}>
            Razón social o denominación
          </div>
          <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
        </div>
        <div className="spaceCompany">
          <div className="textInput" style={{ color: 'white' }}>
            RFC
          </div>
          <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
        </div>
      </div>
      <div className="spaceCompany mt-6">
        <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
      </div>
    </div>
  );
};
export default LoaderOrganization;
