import { NudosGenericModal, NudosModalContent } from '../../../../components/NudosComponents';
import AdditionalServicesCommentsModalContent from './AdditionalServicesCommentsModalContent';
import './AdditionalServicesCommentsModal.scss';
import { useTranslation } from 'react-i18next';

const AdditionalServicesCommentsModal = ({
  closeModalCallback,
  submitModalCallback,
  currentComment
}: {
  closeModalCallback: () => void;
  submitModalCallback: (modalData: string) => void;
  currentComment?: string;
}) => {
  const { t } = useTranslation();
  const settings = t('recurrentWords:settings');

  return (
    <div className="AdditionalServicesCommentsModal">
      <NudosGenericModal
        closeModalCallback={closeModalCallback}
        modalContent={
          <NudosModalContent
            modalContentTitle={settings}
            customWidth="466px"
            customHeight="304px"
            toCloseModal={!currentComment ? closeModalCallback : undefined}
            CustomModalComponent={
              <AdditionalServicesCommentsModalContent
                submitModalCallback={submitModalCallback}
                currentComment={currentComment}
              />
            }
          />
        }
      />
    </div>
  );
};

export default AdditionalServicesCommentsModal;
