import { FC, useEffect } from 'react';
import { bgPrimeModal } from '../../../../assets/images/components/Onboarding';
import { NudosButton, NudosGenericModal } from '../../../NudosComponents';
import './NodiPrimeStartModal.scss';
import { segmentTrackEvent } from '../../../../utils/segment';
import { useTranslation } from 'react-i18next';

interface INodiPrimeStartModal {
  clickInitializeSteps: () => void;
}

const NodiPrimeStartModal: FC<INodiPrimeStartModal> = ({ clickInitializeSteps }: INodiPrimeStartModal) => {
  const { t } = useTranslation();
  const translations = 'nodi:onboarding:startPrimeModal:';
  const buttonsTranslations = 'nodi:onboarding:buttons:';

  const primeModalEvent = () => {
    segmentTrackEvent({ nodiOnboardingPrime0View: null });
  };

  useEffect(() => {
    primeModalEvent();
  }, []);

  return (
    <NudosGenericModal
      modalContent={
        <div id="nodiPrimeStartModal">
          <div className="headerStartModal" style={{ backgroundImage: `url("${bgPrimeModal}")` }}></div>
          <div className="bodyStartModal">
            <div className="titlePrimeModal">{t(`${translations}title`)}</div>
            <div className="subtitlePrimeModal">{t(`${translations}subtitle`)}</div>
            <div className="containerPrimeButton">
              <NudosButton
                customHeight="38px"
                customWidth="180px"
                buttonText={t(`${buttonsTranslations}start`)}
                handleClick={() => {
                  clickInitializeSteps();
                }}
              />
            </div>
          </div>
        </div>
      }
    ></NudosGenericModal>
  );
};
export default NodiPrimeStartModal;
