import { FormEvent } from 'react';
import { CameraIcon } from '../../../assets/images/components/NudosComponents';
import EditPhotoIcon from '../../../assets/images/components/NudosComponents/EditPhotoIcon';
import { Tany } from '../../../types/global';
import './NudosEncircledPhotoFileInput.scss';

const NudosEncircledPhotoFileInput = ({
  uploadedFileHandler,
  selectedImageUrl,
  customPlaceholderImageUrl,
  isDisabled
}: InudosEncircledPhotoFileInput) => {
  const placeholderImageUrl =
    customPlaceholderImageUrl && customPlaceholderImageUrl.length ? customPlaceholderImageUrl : CameraIcon;

  const handleChange = (event: FormEvent) => {
    const { files } = event.target as HTMLInputElement;
    if (!files) return;
    const uploadedFile = files[0];
    uploadedFileHandler(uploadedFile);
  };

  return (
    <div className={`nudosEncircledPhotoFileInput ${isDisabled ? 'disabled' : ''}`}>
      {!isDisabled && <input className="fileInput" type={'file'} onChange={handleChange} />}
      <div
        className={`imageContainer ${selectedImageUrl || customPlaceholderImageUrl ? 'bg-cover' : 'bg-no-repeat'}`}
        style={{
          backgroundImage: `url('${selectedImageUrl ? selectedImageUrl : placeholderImageUrl.replaceAll(' ', '%20')}')`
        }}
      >
        <EditPhotoIcon className="editPhotoIcon" />
      </div>
    </div>
  );
};

export default NudosEncircledPhotoFileInput;

export interface InudosEncircledPhotoFileInput {
  uploadedFileHandler: TfunctionOneToVoid;
  selectedImageUrl: string;
  customPlaceholderImageUrl?: string;
  isDisabled?: boolean;
}

export type TfunctionOneToVoid = (param: Tany) => void;
