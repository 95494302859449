import { v4 as uuid } from 'uuid';
import { FC } from 'react';

import './loaderProductCard.scss';

interface ILoaderProductCard {
  numberCards?: number;
}

const LoaderProductCard: FC<ILoaderProductCard> = ({ numberCards }: ILoaderProductCard) => {
  const loaderCards = Array(numberCards || 10).fill('');

  return (
    <>
      {loaderCards.map(() => {
        return (
          <section id="loaderProductCard" key={uuid()}>
            <div className="containerProductCard">
              <div className="descriptionCard">
                <div className="containerDescription">
                  <div className="descriptionTitle">
                    <div className="textDescriptionTitle animationLoader w-28 rounded mr-2" />
                  </div>
                  <div className="descriptionPills">
                    <div className="h-full w-44 animationLoader rounded" />
                  </div>
                  <div className="descriptionProfiles">
                    <div className="h-full w-52 animationLoader rounded" />
                  </div>
                  <div className="descriptionCountries">
                    <div className="titleCountries">
                      <div className="h-full w-14 animationLoader rounded" />
                    </div>
                    <div className="flagProductCountryBox">
                      <div className="w-16 h-full  animationLoader rounded" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="previewProduct">
                <div className="containerMinImg animationLoader rounded" />
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};
export default LoaderProductCard;
