const LoaderPersonal = () => {
  return (
    <div id="personalForm">
      <div className="titleFormAccount">Información personal</div>
      <div className="profileSection">
        <div className="w-64 h-10 animationLoader rounded-md"></div>
      </div>
      <div className="firstSection">
        <div className="nameInput">
          <div className="textInput" style={{ color: 'white' }}>
            Nombre
          </div>
          <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
        </div>
        <div className="nameInput">
          <div className="textInput" style={{ color: 'white' }}>
            Apellido
          </div>
          <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
        </div>
        <div className="nameInput">
          <div className="textInput" style={{ color: 'white' }}>
            Teléfono
          </div>
          <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
        </div>
      </div>
      <div className="titleFormAccount mt-6" style={{ color: 'white' }}>
        Información de acceso
      </div>
      <div className="secondSection">
        <div className="nameInput">
          <div className="textInput" style={{ color: 'white' }}>
            Correo corporativo
          </div>
          <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
        </div>
        <div className="nameInput"></div>
      </div>
      <div className="textChangePassword" style={{ color: 'white' }}>Cambiar contraseña</div>

      <div className="saveButton">
        <div style={{ height: '38px' }} className="w-full animationLoader rounded-md" />
      </div>
      <div className="lineForm" />
      <div className="titleFormAccount mt-6">Políticas del aplicativo</div>
      <button className="buttonTerms animationLoader" style={{ border: 'none' }}></button>
    </div>
  );
};

export default LoaderPersonal;
