import { FC, useState } from 'react';
import { CheckPaymentIcon } from '../../../../../../assets/images/views/Ecommerce';
import { TdestinationAddress } from '../../../../../../types/assignationFluxes';
import { NudosButton } from '../../../../../../components/NudosComponents';
import { uploadToolsLocationWithoutLogistics } from '../../../../../../services/assignationFluxes.service';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import { useTranslation } from 'react-i18next';
import { GenericLoader } from '../../../../dashboard/components';
import './LocationsSelectModal.scss';

interface ILocationsSelectModal {
  productId: number;
  temporalOption: number;
  handleOption: (option: number) => void;
  loadingAddress: boolean;
  handleChangeOption: (option: number) => void;
  organizationOfficeAddress?: TdestinationAddress;
  defaultOtherAddress?: TdestinationAddress;
  updateCardListData: () => void;
  closeLocationModal: () => void;
}

const LocationsSelectModal: FC<ILocationsSelectModal> = ({
  temporalOption,
  handleOption,
  loadingAddress,
  handleChangeOption,
  organizationOfficeAddress,
  defaultOtherAddress,
  updateCardListData,
  productId,
  closeLocationModal
}) => {
  const { t } = useTranslation();
  const [loadingAddressOffice, setLoadingAddressOffice] = useState<boolean>(false);

  const [loadingOtherAddress, setLoadingOtherAddress] = useState<boolean>(false);

  const disabledFirstStep = () => {
    if (temporalOption === 1 && !organizationOfficeAddress?.locationId) return true;
    return false;
  };
  const i18nLanguageKey = 'nodi:tools:locationsSelectModal:';

  const updateOfficeAddress = async () => {
    setLoadingAddressOffice(true);
    try {
      await uploadToolsLocationWithoutLogistics([productId], Number(organizationOfficeAddress?.locationId));
      await updateCardListData();
      closeLocationModal();
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingAddressOffice(false);
    }
  };

  const disabledOtherFirstStep = () => {
    if (temporalOption === 2 && !defaultOtherAddress?.locationId) return true;
    return false;
  };

  const updateOtherAddress = async () => {
    setLoadingOtherAddress(true);
    try {
      await uploadToolsLocationWithoutLogistics([Number(productId)], Number(defaultOtherAddress?.locationId));
      await updateCardListData();
      closeLocationModal();
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingOtherAddress(false);
    }
  };

  return (
    <div id="locationsSelectModal">
      <div className="locationTitle"> {t(`${i18nLanguageKey}locationTitle`)} </div>
      <div className="locationSubtitle">{t(`${i18nLanguageKey}locationSubtitle`)}</div>
      <div className="boxOptions">
        <div className="continerOptions">
          <div className="optionText">
            <div
              className="containerCheck"
              onClick={() => {
                if (temporalOption !== 1) handleOption(1);
              }}
            >
              {temporalOption === 1 ? <CheckPaymentIcon /> : <div className="radioContainer" />}
            </div>
            <div className="textStyle">{t(`${i18nLanguageKey}office`)}</div>
          </div>
          <div className="detailAddress">
            <div className="detailText">
              {loadingAddress ? (
                <GenericLoader sizeLoader={12} borderWidth={1} percentageSpinner={78} />
              ) : (
                organizationOfficeAddress?.address || ''
              )}
            </div>
            <div className="detailBlue" onClick={() => handleChangeOption(1)}>
              {organizationOfficeAddress?.address
                ? t(`${i18nLanguageKey}detailBlue:edit`)
                : t(`${i18nLanguageKey}detailBlue:add`)}
            </div>
          </div>
          <div className="optionText">
            <div
              className="containerCheck"
              onClick={() => {
                if (temporalOption !== 2) handleOption(2);
              }}
            >
              {temporalOption === 2 ? <CheckPaymentIcon /> : <div className="radioContainer" />}
            </div>

            <div className="textStyle">{t(`${i18nLanguageKey}otherDirection`)}</div>
          </div>
          <div className="detailAddress">
            <div className="detailText">
              {loadingAddress ? (
                <GenericLoader sizeLoader={12} borderWidth={1} percentageSpinner={78} />
              ) : (
                defaultOtherAddress?.locationName || defaultOtherAddress?.address || ''
              )}
            </div>
            <div className="detailBlue" onClick={() => handleChangeOption(2)}>
              {defaultOtherAddress?.address
                ? t(`${i18nLanguageKey}detailBlue:edit`)
                : t(`${i18nLanguageKey}detailBlue:add`)}
            </div>
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        <div className="buttonBox">
          {temporalOption !== 2 ? (
            <NudosButton
              buttonText={t(`${i18nLanguageKey}nudosButton`)}
              isButtonLoading={loadingAddressOffice}
              isButtonDisabled={!temporalOption || loadingAddressOffice || disabledFirstStep()}
              handleClick={() => {
                if (temporalOption === 1) updateOfficeAddress();
                else handleChangeOption(temporalOption);
              }}
            />
          ) : (
            <NudosButton
              buttonText={t(`${i18nLanguageKey}nudosButton`)}
              isButtonLoading={loadingOtherAddress}
              isButtonDisabled={!temporalOption || loadingOtherAddress || disabledOtherFirstStep()}
              handleClick={() => {
                if (temporalOption === 2) updateOtherAddress();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationsSelectModal;
