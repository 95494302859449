import { TstoragePlace } from './assignationFluxes';
import { ISelectPrice } from './cart';
import { ICountryDetail } from './countries';
import { IadditionalServiceDataForToolLogistics } from './requestLogisticsModule';

export interface ObjectKey<T> {
  [key: string]: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Tany = any;

export interface DateRange {
  initialDate: Date | null;
  finalDate: Date | null;
}

export interface valueKey {
  id?: number;
  label: string;
  value?: string | number;
  imageUrl?: string;
  nameStringId?: string;
}

export interface Filter {
  columnFiled: string;
  filters: Array<string>;
}
export interface IParam {
  name: string;
  includes: string;
  values: Tany[];
}
export interface Route {
  layout: 'nodi' | '' | 'catalogue' | 'state';
  path?: string;
  name: string;
  icon?: React.ComponentType<unknown>;
  component: React.ComponentType<unknown>;
  disabledComponent?: React.ComponentType<unknown>;
  hidden?: boolean;
  label: string;
  disabledFree?: boolean;
}

export interface Iuser {
  name: string;
  lastName: string;
  email: string;
  country?: string;
  phone: number;
  business: string;
  nit: string;
  employees: string;
  businessName: string;
  password: string;
  confirmPassword: string;
  uid: string;
}
export interface Iinputs {
  id: keyof Iuser;
  placeholder: string;
  label?: string;
  type: string;
  order?: number;
  size: string;
  options?: [number, string][];
  defaultValue?: string;
  numberStep?: number;
  typeInput?: string;
}
export interface Isubscription {
  duration: number;
  cost: number;
  iva: number;
}

export interface IproductInfo {
  itemId: number;
  productId: string;
  commercialName: string;
  sku?: string | null;
  categoryId: number;
  categoryName: string;
  brandId: number;
  brandName: string;
  brandImage?: string | null;
  imageId: number;
  image: string;
  alternativeImages: string[];
  prices: IpriceInformation[];
  descriptions: descriptionItem[];
  description: string | null;
  productTechnicalDetails?: TproductTechnicalDetails;
  pills?: ObjectKey<string>;
  dataSheet: string;
  rentValue?: string | undefined | null;
  longDescription?: string | undefined | null;
  imageUrl?: string;
  usdLowerPrice: string;
  profiles: string[];
  availableCountries?: ISelectPrice[];
}

export interface IproductList {
  products: IproductInfo[];
  pillsFilters?: ObjectKey<string[]>;
  priceFilters: number[];
}

export interface IdeliveryAddress {
  address?: string | null;
  coordinates?: ICoordinates | null;
  additionalReferences?: string | null;
  receiverInformation?: IreceiverInfo | null;
  country?: string | null;
  zipCode?: string | null;
  city?: string | null;
  cityId?: number | null;
}

export interface IreceiverInfo {
  name: string;
  phone: string;
}
export interface ICoordinates {
  lat?: string | null;
  lng?: string | null;
}

export interface IproductDetails {
  processor: string;
  display: string;
  hardDrive: string;
  ram: string;
  inBoxAccessories: string;
  weight: string;
}

export type TimgSize = 'thumb' | 'medium' | 'regular' | 'large';

export type TbuttonType = 'button' | 'submit' | 'reset' | undefined;

export type TapprovalStatus = 'active' | 'pending' | 'rejected';

export interface IusersOrgData {
  organizationId: number;
  userId: number;
  firstName: string;
  lastName: string;
  firstLogin: boolean;
  countryCode: string;
  organizationState: TapprovalStatus;
  organizationName: string;
}

export enum TnudoType {
  Compra = 'sale',
  Suscripción = 'subscription'
}

export enum TnudoState {
  PurchasePayment = 'Pago en proceso',
  Confirmed = 'Confirmado',
  PurchaseDelivery = 'Proceso de entrega',
  PurchaseDelivered = 'Entregado',
  PurchaseCanceled = 'Cancelado',
  SubscriptionActive = 'Suscripción activa',
  SubscriptionApplication = 'Solicitud de suscripción DaaS'
}
export interface Idescriptions {
  name: string;
  description: string[];
}

export type TlogisticServiceStatus = 'por confirmar' | 'confirmado' | 'entregado' | 'cancelado';

export interface IlogisticsDestinationData {
  place?: string | null;
  address?: string | null;
  city?: string | null;
  locationName?: string | null;
  locationUserName?: string | null;
  country?: ICountryDetail;
}
export interface InudoInfo {
  isBuyAndHold?: number;
  nudosId: string;
  nudoType: TnudoType;
  cost?: number | null;
  duration: number;
  uid?: string | null;
  orderId?: number | string | null;
  productId: string;
  category?: TtoolCategories | null;
  commercialName: string;
  brand: string;
  imageUrl: string;
  productDescription: string[];
  nudoState: keyof typeof TnudoState;
  deliveryDate?: string | null;
  endDate?: string | null;
  software?: string[] | null;
  assignedTo?: string | null;
  assignmentId?: number | null;
  userId?: number | null;
  serial?: string | null;
  city?: string | null;
  country?: ICountryDetail;
  place?: TstoragePlace;
  locationName?: string | null;
  locationUserName?: string | null;
  locationId?: number | null;
  pills?: Tany;
  brandImage?: string;
  statusId?: number;
  subscriptionId?: number;
  NID?: string;
  numberOfAssignment?: number;
  createdAt?: string;
  saleValue?: number | null;
  saleValueUsd?: number | null;
  period?: number;
  startDay?: string;
  statusName?: string;
  shipmentStatusId?: number | null;
  shipmentStatusName?: string | null;
  address?: string | null;
  additionalReferences?: string | null;
  currentAssignment?: IassigneeInfo[];
  oldAssignment?: IassigneeInfo[];
  sku?: string;
  acquisitionDate?: string;
  productCondition?: string;
  warranty?: string;
  acqusitionType?: string;
  referenceModel?: TtoolReferenceModel;
  nudosCareName?: TnudosCare | null;
  monthlyValue?: number;
  monthlyValueUsd?: number;
  acquisitionType?: string;
  endDay?: string;
  logisticStatus?: TlogisticServiceStatus | null;
  logisticAdditionalServices?: IadditionalServiceDataForToolLogistics[];
  comments?: string;
  destination: IlogisticsDestinationData;
  logisticServiceId?: number;
  rentValueUsd?: string;
  financingMethod?: string;
  nameStringId?: string | null;
  isKit?: boolean;
}

export enum TProductEndCategories {
  'Básica',
  'Media',
  'Alta',
  'Power User'
}

export interface IFeatureFlagEnvOptions {
  development?: boolean;
  local?: boolean;
  production?: boolean;
}

export type descriptionItem = {
  name: string;
  value: string[];
};

export interface IElement {
  target: Tany;
}

export type TfunctionOneToVoid = (param: Tany) => void;

export interface ImoduleFilter {
  name: string;
  includes: string;
  values: Tany[];
}

export interface IassigneeInfo {
  userId: number;
  organizationId: number;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  phoneData: IPhoneData;
  area: string;
  phone: string;
  position: string;
  assignmentDate?: string | null;
  unAssignmentDate?: string | null;
  entryDate?: string | null;
  exitDate?: string | null;
  productId: number;
  assignmentDocumentUrl?: string | null;
  photoProfile?: string;
  assignmentId?: string;
  place?: string;
  locationName?: string | null;
  locationId?: number | null;
  address?: Tany;
  country: ICountryDetail;
  logisticServiceId?: number | null;
  locationLogId?: number | null;
  manualAdjustment?: boolean | null;
  comments?: string | null;
  isBuyAndHold?: string | null;
  logisticAdditionalServices?: IadditionalServiceDataForToolLogistics[];
}

export interface IassigneeForm extends IassigneeInfo {
  isNewEmployee?: boolean;
}

export interface INewEmployeeInfo {
  id?: number;
  organizationId: number;
  firstName: string;
  lastName: string;
  personalId?: string;
  city: string;
  cityId?: number | null;
  area: string;
  phone: string;
  position: string;
  country?: ICountryDetail;
  address?: IdeliveryAddress | null;
  email: string;
  assignmentDate?: string | null;
  uploadedPhoto?: File | null;
  photoProfile?: string;
  active?: number | boolean | null;
  userId?: number | null;
  phoneCode?: number | string | null;
  countryPhone?: ICountryDetail;
}

export interface IExistingEmployeeInfo {
  id: number;
  organizationId: number;
  userId: number;
  firstName?: string | null;
  lastName?: string | null;
  locationId?: number | null;
  personalId?: string | null;
  phoneData?: IPhoneData | null;
  city?: string | null;
  cityId?: number | null;
  area?: string | null;
  position?: string | null;
  active?: number | boolean | null;
  country?: ICountryDetail | null;
  address?: IdeliveryAddress | null;
}

export type IemployeeInfo = INewEmployeeInfo | IExistingEmployeeInfo;

export interface ImodifyAssigneeInfo {
  firstName: string;
  lastName: string;
  countryCode: string;
  city: string;
  area: string;
  position: string;
}

export type TplaceholderProducer = (buttonText: string) => JSX.Element;
export interface IassigneeFormInputs {
  id: string;
  placeholder: string | TplaceholderProducer;
  label: string;
  type: string;
  order: number;
  defaultValue?: Tany;
  options?: JSX.Element[];
  changeOptionFunction?: TfunctionOneToVoid;
  isReadOnly?: boolean;
  predefinedValue?: string | number | null;
  areOptionsShown?: boolean;
  showOptions?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOption?: Tany;
}

export interface IcardSectionsThatRedirect {
  compressedSection?: boolean;
  leftInfo?: boolean;
  rightInfo?: boolean;
  expandedSection?: boolean;
}

export interface IProfileDetail {
  id: number;
  name: string;
  description: string;
  descriptionStringId: string;
  nameStringId: string;
}
export type IProfileType = IProfileDetail[];

export interface ILineDetail {
  id: number;
  name: string;
  description: string;
  descriptionStringId: string;
  nameStringId: string;
}
export type ILineType = ILineDetail[];

export interface IDetailFilter {
  id?: number;
  value: string;
  label: string;
  description?: string;
}
export type IFilter = IDetailFilter[];

export enum TValueAddedTaxByCountry {
  'mx' = 0.16,
  'co' = 0.19,
  'pe' = 0.18,
  'ar' = 0.21,
  'cl' = 0.19,
  'uy' = 0.22
}

export interface laptopsDetails {
  Almacenamiento: string;
  Procesador: string;
  Pantalla: string;
  Memoria: string;
  Garantía: string;
  Accesorios: string;
  Software: string;
}

export interface monitorsDetails {
  Pantalla: string;
  Resolución: string;
  'Tipo HD': string;
  Frecuencia: string;
  Color?: string;
  Garantía?: string;
}

export interface furnitureDetails {
  'Peso neto': string;
  Ancho: string;
  Largo: string;
  Altura: string;
  Material: string;
  Color?: string;
  Garantía?: string;
}

export type TproductTechnicalDetails = laptopsDetails | monitorsDetails | furnitureDetails;

export interface ICountryPrice {
  name: string;
  code: string;
  flag: string;
  nameStringId?: string;
}
export interface IpriceInformation {
  price: number;
  ivaAmount: number;
  startsAtUnits: number;
  endsAtUnits?: number | null;
  country?: ICountryPrice;
  pricePrimeUsd: number | string;
  priceWithoutPrimeUsd: number | string;
}

export interface IdaasContactForm {
  neededServices: string;
  neededProducts: string[];
  employees: string;
  duration: string;
  otherSpecifications: string;
  contactMedium: string;
}

export interface IpostOrderResponse {
  id: number;
  organizationId: number;
  paymentType: string;
  billingData: string;
  deliveryAddress: string;
  multipleAddress: boolean;
  isFirstOrder: boolean;
  updatedAt: string;
  createdAt: string;
  total: number;
  iva: number;
}

export type TtoolCategories =
  | 'Laptops'
  | 'Celulares'
  | 'Monitores'
  | 'Escritorios'
  | 'Sillas'
  | 'Accesorios'
  | 'Impresoras'
  | 'Mobiliario'
  | 'Laptops con DEP'
  | 'Laptops CTOs'
  | 'PC';

export type TtoolReferenceModel = 'CatalogProduct' | 'ExternalProduct';

export interface ItoolCommonInfo {
  id: number;
  userId: number;
  productId: string;
  category?: TtoolCategories | null;
  serial: string;
  productName: string;
  brand: string;
  brandImage: string;
  image: string;
  pills: string;
  assignedSince: string;
  movementRequestDate?: string | null;
  statusName: null;
  shipmentStatusName?: string | null;
  deliveryDate?: string | null;
  assignedUntil?: string | null;
  assngedDocumentUrl?: string; // The typo comes from backend
  assignmentId?: number;
  referenceModel?: TtoolReferenceModel | null;
  orderId?: number | string | null;
  locationId?: number | null;
  country?: ICountryDetail;
  logisticStatus?: string | null;
  sku: string;
  logisticServiceId?: number;
  nudosCareName?: TnudosCare | null;
}
export interface IassignedSubscriptionTool extends ItoolCommonInfo {
  subscriptionId: number;
  monthlyValue: string;
  period: string;
  saleId?: null;
  saleValue?: null;
}

export interface IassignedPurchaseTool extends ItoolCommonInfo {
  saleId: number;
  saleValue: string;
  subscriptionId?: null;
  monthlyValue?: null;
  period?: null;
}

export type IassignedTool = IassignedSubscriptionTool | IassignedPurchaseTool;
export interface Iemployee {
  id: number;
  locationId?: number | null;
  organizationId: number;
  userId: number;
  firstName?: string | null;
  lastName?: string | null;
  personalId?: string | null;
  city?: string | null;
  cityId?: number | null;
  area?: string | null;
  areaStringId?: string | null;
  ['employeeArea.nameStringId']?: string | null;
  position?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  country?: ICountryDetail | null;
  mail?: string | null;
  photoProfile?: string | null;
  address?: IdeliveryAddress | null;
  assignedTools?: IassignedTool[] | null;
  toolsInTransit?: IassignedTool[] | null;
  active?: boolean | number | null;
  phoneCode?: string | number | null;
  indicative?: string | number | number | null;
  phoneData?: IPhoneData | null;
}

export interface IPhoneData {
  country?: string | null;
  countryId?: number | null;
  flag?: string | null;
  phone?: string | null;
  phoneCode?: string | null;
}

export interface IaddressFromGoogleMaps {
  address?: string | null;
  coordinates?: {
    lat?: string | null;
    lng?: string | null;
  } | null;
  country?: string | null;
  city?: string | null;
}

export interface IMenuIcon {
  activeIcon: React.ReactNode;
  inactiveIcon: React.ReactNode;
  freeIcon: React.ReactNode;
  constructionIcon: React.ReactNode;
  hoverIcon: React.ReactNode;
}

export interface IComming {
  title: string;
  subtitle: string;
}
export interface IModuleProperties {
  name: string;
  alias?: string;
  path: string | string[];
  isDeactivated: boolean;
  isDisabled: boolean;
  disabledOnFreeSubscription?: boolean;
  forceRedirect?: string;
  moduleUnderConstruction?: boolean;
  menuIcons: IMenuIcon;
  coming?: IComming;
}

export interface IModuleList {
  title?: string;
  subtitle?: string;
}

export type TnudosCare = 'Free' | 'Basic' | 'Log' | 'Full';

export interface IreceiverInformation {
  receiverName?: string | null;
  receiverPhone?: string | null;
  receiverPhoneCountry?: ICountryDetail | null;
  receiverIdDocument?: string | null;
}

export type TcomponentSize =
  | 'xsSmall'
  | 'extraSmall-74'
  | 'extraSmall'
  | 'small'
  | 'small-184'
  | 'medium'
  | 'large'
  | 'extraLarge'
  | 'w256'
  | 'w368';

export interface IDocumentDetail {
  documentName: string;
  documentFile: File;
}

export interface IreturnLocation {
  returnText: string;
  returnAction: string;
}

export enum PRODUCT_CATEGORY {
  LAPTOP = 'Laptops',
  CELLPHONES = 'Celulares',
  DESKTOP = 'Monitores',
  DESKS = 'Escritorios',
  CHAIRS = 'Sillas',
  ACCESSORIES = 'Accesorios',
  PRINTERS = 'Impresoras',
  PC = 'PC'
}

export enum LOCATION_PLACE_TYPE {
  NUDOS = 'nudos',
  DIAGNOSTIC = 'diagnostic',
  WARRANTY = 'warranty',
  USER = 'user',
  OFFICE = 'office',
  OTHER = 'other'
}

export interface IobjectWithDate {
  date?: string | null;
}
