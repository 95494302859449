import { ModalArrowBack, NudosLogo } from '../../../assets/images/components/NudosComponents';
import XSign from '../../../assets/images/components/NudosComponents/XSign';
import { Tany } from '../../../types/global';
import { useTranslation } from 'react-i18next';
import './NudosModalContent.scss';

/**
 * React functional component corresponding to the content of the modal, to be used in conjunction with the NudosGenericModal as the value for the property modalContent
 * TO ADJUST TO A SIZE DIFFERENT THAN THE STANDARD ONES USE THE customWidth and customHeight properties IN CONJUNCTION
 * @property {JSX.Element} CustomModalComponent -  A JSX.Element with the actual content of the modal
 * @property {TfunctionOneToVoid} toCloseModal -  A function, usually a React.Dispatch<React.SetStateAction<boolean>>, to update the boolean state that controls if the modal is opened or not. It would be used to closed the modal when clicking on the x in the top right corner.
 * @property {string} modalContentTitle
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "medium" or "large", for portrait oriented, and "landscapeSmall", for landscapeOriented. Default value is large.
 * @property {string} customWidth - Optional, for example "300px". The desired value for the width, corresponds to a custom value for the width and maxWidth css property. MUST BE USED IN CONJUNCTION WITH customHeight TO HAVE EFFECT
 * @property {string} customHeight - Optional, for example "200px" The desired value for the height, corresponds to a custom value for the height css property. MUST BE USED IN CONJUNCTION WITH customWidth TO HAVE EFFECT
 * @property {boolean} excludeHeader Optional, when is "true", the header of the component will not be displayed, nor will the height style of the custom section container. The default value is "false"
 */
const NudosModalContent = ({
  CustomModalComponent,
  toCloseModal,
  modalContentTitle,
  componentSize,
  customWidth,
  customHeight,
  leftAlignmentTitle,
  customHeaderClass,
  closeIconColor,
  excludeHeader = false,
  hideNudosIcon = false,
  toBackModalAction
}: InudosModalContent) => {
  const { t } = useTranslation();
  const onCloseModal = () => toCloseModal && toCloseModal(false);
  const sizeStyles =
    customWidth && customHeight ? { width: customWidth, maxWidth: customWidth, height: customHeight } : undefined;
  return (
    <div className={`nudosModalContent ${componentSize ? componentSize : ''}`} style={sizeStyles}>
      {toBackModalAction && (
        <div
          className="backModalBox"
          onClick={() => {
            toBackModalAction();
          }}
        >
          <div className="iconArrowBack">
            <ModalArrowBack />
          </div>
          <div className="textBack">{t('nodi:nudosModalContentTextBack')}</div>
        </div>
      )}
      {!excludeHeader && (
        <div className={`contentHeader ${customHeaderClass || ''} ${leftAlignmentTitle ? 'leftAlignmentTitle' : ''} `}>
          {!hideNudosIcon && <NudosLogo className="nudosLogo" />}
          <div className={`modalContentTitle ${leftAlignmentTitle ? 'leftAlignmentTitle' : ''}`}>
            {modalContentTitle || 'Asignación'}
          </div>
        </div>
      )}

      <div className={!excludeHeader ? 'customSectionContainer' : 'overflow-y-scroll'}>{CustomModalComponent}</div>
      {toCloseModal && (
        <div className="exitContentButton" onClick={onCloseModal}>
          <XSign fill={closeIconColor || '#B3B3B3'} />
        </div>
      )}
    </div>
  );
};

export default NudosModalContent;

export interface InudosModalContent {
  CustomModalComponent: JSX.Element;
  toCloseModal?: TfunctionOneToVoid;
  modalContentTitle?: string;
  componentSize?: string;
  customWidth?: string;
  customHeight?: string;
  customHeaderClass?: string;
  closeIconColor?: string;
  excludeHeader?: boolean;
  hideNudosIcon?: boolean;
  toBackModalAction?: () => void;
  leftAlignmentTitle?: boolean;
}
export type TfunctionOneToVoid = (param: Tany) => void;
