import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={6} fill="none" ref={ref} {...props}>
    <path
      fill={props?.fill || '#999'}
      d="M4 2.623c0-.279-.052-.554-.152-.812a2.137 2.137 0 0 0-.434-.689 1.997 1.997 0 0 0-.649-.46 1.9 1.9 0 0 0-1.53 0 1.997 1.997 0 0 0-.65.46 2.137 2.137 0 0 0-.433.69c-.1.257-.152.532-.152.811h1c0-.14.026-.276.076-.405.05-.13.124-.246.217-.345a.998.998 0 0 1 .324-.23.95.95 0 0 1 .766 0 .998.998 0 0 1 .324.23 1.115 1.115 0 0 1 .293.75h1ZM3 3.623c0 .279.052.554.152.812s.248.492.434.689c.185.197.406.354.649.46a1.899 1.899 0 0 0 1.53 0c.243-.106.463-.263.65-.46.185-.197.332-.431.433-.69.1-.257.152-.532.152-.811H6c0 .14-.026.277-.076.405-.05.13-.124.246-.217.345a.998.998 0 0 1-.324.23.95.95 0 0 1-.766 0 .998.998 0 0 1-.324-.23 1.069 1.069 0 0 1-.217-.345A1.116 1.116 0 0 1 4 3.623H3ZM10 2.623c0-.279-.052-.554-.152-.812a2.137 2.137 0 0 0-.434-.689 1.997 1.997 0 0 0-.649-.46 1.9 1.9 0 0 0-1.53 0 1.997 1.997 0 0 0-.65.46 2.137 2.137 0 0 0-.433.69c-.1.257-.152.532-.152.811h1c0-.14.026-.276.076-.405.05-.13.124-.246.217-.345a.998.998 0 0 1 .324-.23.95.95 0 0 1 .766 0 .998.998 0 0 1 .324.23 1.115 1.115 0 0 1 .293.75h1Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconNudosFace = memo(ForwardRef);
export default IconNudosFace;
