import algoliaSearchService from '../../services/algolia.service';
import { useHistory } from 'react-router-dom';
const { search } = algoliaSearchService();
import useShopStore from '../../state/useShopContext';
import useStoreCountryState from '../../state/useStoreCountryState';
import { IProductItemList } from '../../types/catalogue';
const searchProductController = () => {
  const { storeCountry } = useStoreCountryState();
  const { setSearchResult, setSearchString, setBarSearch } = useShopStore();
  const { push } = useHistory();
  const productDetailsOrigin = location?.pathname?.includes('/catalogue/product/');
  const handeOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setBarSearch: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const target = event.target as HTMLInputElement;
    setBarSearch(target.value);
  };

  const getProductName = async (event: React.KeyboardEvent, q: string) => {
    const target = event.target as HTMLInputElement;
    if (event.key === 'Enter') {
      const result = (await search(
        q,
        storeCountry ? storeCountry.countryCode : undefined
      )) as unknown as IProductItemList[];
      setSearchResult(result);
      setSearchString(target.value);
      setBarSearch('Resultado para: ');
      push(`/catalogue/search${productDetailsOrigin ? `?origin=productDetails` : ''}`);
    }
  };

  return { getProductName, handeOnChange };
};

export default searchProductController;
