import { useEffect, useState } from 'react';
import { NudosSearchbarDropdown } from '../../../../../../../components/DesignSystem';
import { getToolConditionDropdownOptions, updateToolCondition } from '../../../../../../../services/tools.service';
import { ItoolConditionSelectOption } from '../../../../../../../types/tools.types';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../utils/displayNudosStandardNotifications';

const ToolConditionDropdown = ({
  productId,
  currentCondition
}: {
  productId?: number | string | null;
  currentCondition?: string | null;
}) => {
  const [toolConditionOptions, setToolConditionOptions] = useState<ItoolConditionSelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ItoolConditionSelectOption>();
  const [loading, setLoading] = useState(false);

  const getToolConditionOptions = async () => {
    try {
      const productConditionOptions = await getToolConditionDropdownOptions();
      setToolConditionOptions(productConditionOptions);
    } catch {
      displayErrorNotification();
    }
  };

  const handleSelectNewCondition = async (
    newToolConditionOption: ItoolConditionSelectOption,
    closeDropdown?: (option: ItoolConditionSelectOption) => void
  ) => {
    if (!newToolConditionOption?.name || !productId) return;
    try {
      setLoading(true);
      const body = {
        productCondition: newToolConditionOption.name
      };
      await updateToolCondition(productId, body);
      setSelectedOption(newToolConditionOption);
      displaySuccessNotification({ customJSXMessage: <>Se actualizó el estado de la herramienta correctamente</> });
      closeDropdown && closeDropdown(newToolConditionOption);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const formDropdownOptionsCallback = (
    rawOptions: ItoolConditionSelectOption[],
    closeDropDown?: (option: ItoolConditionSelectOption) => void
  ) => {
    return rawOptions.map((toolCondition, i) => {
      return (
        <div
          className="optionContainer"
          key={`toolCondition${i}${toolCondition?.id}${toolCondition?.nameStringId}`}
          onClick={() => handleSelectNewCondition(toolCondition, closeDropDown)}
        >
          {toolCondition?.name}
        </div>
      );
    });
  };

  const formSelectedOptionUICallback = (toolCondition: ItoolConditionSelectOption) => {
    return <div className="optionContainer">{toolCondition?.name}</div>;
  };

  const filterBySearchStringCallback = (search: string, rawOptions: ItoolConditionSelectOption[]) => {
    return rawOptions.filter(toolCondition =>
      toolCondition?.name?.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  };

  useEffect(() => {
    getToolConditionOptions();
  }, []);

  useEffect(() => {
    if (!currentCondition || !toolConditionOptions?.length) return;
    const currentOption = toolConditionOptions.find(condition => condition?.name === currentCondition);
    setSelectedOption(currentOption);
  }, [currentCondition, toolConditionOptions?.length]);

  return (
    <div className="toolConditionDropdown">
      <NudosSearchbarDropdown
        includeMagnifierIcon={false}
        componentStyles={{ height: '12px', width: '102px' }}
        rawOptions={toolConditionOptions}
        formDropdownOptionsCallback={formDropdownOptionsCallback}
        formSelectedOptionUICallback={formSelectedOptionUICallback}
        filterBySearchStringCallback={filterBySearchStringCallback}
        currentValue={selectedOption}
        isFilled={!!currentCondition}
        showSkeletonLoader={loading}
        customPlaceholder="Selecciona el estado"
        alternativeStyling="matchingBackground"
      />
    </div>
  );
};

export default ToolConditionDropdown;
