import { FC, useEffect } from 'react';
import { IconWarning } from '../../../../assets/DesignSystem/SVGComponents';
import { NudosButton, NudosGenericModal } from '../../../../components/NudosComponents';
import { Trans, useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../utils/segment';

import './ModalCountryChangeAlert.scss';
import useStoreCountryState from '../../../../state/useStoreCountryState';

interface IModalCountryChangeAlert {
  loadingModal?: boolean;
  firstAction?: () => void;
  secondAction?: () => void;
}

const ModalCountryChangeAlert: FC<IModalCountryChangeAlert> = ({
  firstAction,
  secondAction,
  loadingModal
}: IModalCountryChangeAlert) => {
  const { storeCountry } = useStoreCountryState();
  const { t } = useTranslation();
  const translations = 'ecommerce:modalCountryChangeAlert:';

  const modalEvent = () => {
    segmentTrackEvent({ catalogueChangeCountryView: { CountryName: storeCountry?.countryName || '' } });
  };

  useEffect(() => {
    modalEvent();
  }, []);

  return (
    <>
      <NudosGenericModal
        modalContent={
          <div id="modalCountryChangeAlert">
            <div className="warningIconContainer">
              <div className="boxWarnigIcon">
                <div className="yellowCircle">
                  <IconWarning color="#FFBB00" style={{ transform: 'scale(1.8)' }} />
                </div>
              </div>
            </div>
            <div className="changeCountryText">
              <Trans
                i18nKey={`${translations}text`}
                components={{
                  1: <br />
                }}
              />{' '}
            </div>
            <div className="buttonsChangeCountryContainer">
              <NudosButton
                buttonText={t(`${translations}button`)}
                isButtonDisabled={loadingModal}
                colorPalette="secondary"
                componentSize="small"
                handleClick={() => {
                  if (firstAction) {
                    firstAction();
                  }
                }}
              />
              <NudosButton
                buttonText={t(`${translations}button2`)}
                componentSize="small"
                isButtonLoading={loadingModal}
                handleClick={() => {
                  if (secondAction) {
                    segmentTrackEvent({ catalogueChangeCountryYesClick: null });
                    secondAction();
                  }
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default ModalCountryChangeAlert;
