import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IbillingData } from '../../../types/checkout';
import { TcomponentSize } from '../../../types/global';
import NudosSearchbarDropdown from '../NudosSearchbarDropdown/NudosSearchbarDropdown';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { getOrganizationBillingDataForAllItsCountries } from '../../../services/account';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { NudosHoverText } from '../../NudosComponents';
import { ICountryDetail } from '../../../types/countries';
import './NudosBillingCountryDropdown.scss';

const NudosBillingCountryDropdown = ({
  componentSize,
  updateCurrentValueCallback,
  countriesList,
  currentValue,
  billingDataForAllCountriesProp,
  isFocused,
  openDropdown,
  customPlaceholder,
  addNewBillingCountryCallback
}: {
  componentSize?: TcomponentSize;
  updateCurrentValueCallback: (newValue: IbillingData) => void;
  countriesList: ICountryDetail[];
  currentValue?: IbillingData;
  billingDataForAllCountriesProp?: IbillingData[];
  isFocused?: boolean;
  openDropdown?: boolean;
  customPlaceholder?: string;
  addNewBillingCountryCallback?: () => void;
}) => {
  const { t } = useTranslation();
  const orgId = getOrgData()?.organizationId;

  const [billingDataForAllCountries, setBillingDataForAllCountries] = useState<IbillingData[]>([]);

  const translationKey = 'designSystemComponents:nudosBillingCountryDropdown:';
  const placeholderTranslation = t(`${translationKey}dropdownPlaceholder`);
  const dropdownPlaceholder = customPlaceholder || placeholderTranslation;
  const addNewBillingCountryText = t(`${translationKey}addNewBillingCountryText`);

  const getAllOrganizationBillingDataByCountry = async () => {
    if (!orgId) return;
    try {
      const response = await getOrganizationBillingDataForAllItsCountries(orgId);
      const billingDataEntitiesByCountry = Object.values(response).map(
        countryBillingData => countryBillingData.billingEntities
      );
      const allOrganizationBillingEntities = billingDataEntitiesByCountry.flat();
      setBillingDataForAllCountries(allOrganizationBillingEntities);
    } catch (error) {
      displayErrorNotification();
    }
  };

  const getBillingOptionUI = (
    isActive: boolean,
    rawBillingData: IbillingData,
    clickOptionCallback?: (option: IbillingData) => void
  ) => {
    const { documentCountry, document } = rawBillingData;
    const countriesMap = new Map(countriesList.map(country => [country?.code, country]));
    const countryNameKey = documentCountry?.nameStringId;
    const countryName = countryNameKey
      ? t(`services:countries:${countryNameKey}`)
      : documentCountry?.name || 'Sin datos';
    const countryFlag = `url(${documentCountry?.flag?.replace(/\s/g, '%20') || ''})`;
    const documentType = countriesMap?.get(documentCountry?.code || '')?.documentType || 'Documento';
    const documentText = ` - ${documentType} ${document || 'Sin datos'}`;

    const handleSelectOption = (option: IbillingData) => {
      if (!isActive) return;
      updateCurrentValueCallback(option);
      clickOptionCallback && clickOptionCallback(option);
    };

    return (
      <div
        className="optionContainer billingDataOption"
        key={`billingOption${rawBillingData?.id}`}
        onClick={() => handleSelectOption(rawBillingData)}
      >
        <div className="flag" style={{ backgroundImage: countryFlag }} />
        <NudosHoverText onlyIfTruncated customClassName="countryName" text={countryName} charactersLimit={20} />
        <NudosHoverText onlyIfTruncated customClassName="document" text={documentText} charactersLimit={30} />
      </div>
    );
  };

  const formDropdownOptionsCallback = (
    rawOptions: IbillingData[],
    clickOptionCallback?: (option: IbillingData) => void,
    searchString?: string
  ) => {
    const addNewBillingCountryOption = addNewBillingCountryCallback ? (
      <div key="addOther" className="addNewBillingCountryOption" onClick={addNewBillingCountryCallback}>
        {addNewBillingCountryText}
      </div>
    ) : undefined;
    const noResultsOption = (
      <div className="optionContainer" key="loading">
        {searchString ? t(`recurrentWords:noSearchOption`) : `${t(`recurrentWords:loading`)} ...`}
      </div>
    );

    if (rawOptions.length === 0) {
      return addNewBillingCountryOption ? [noResultsOption, addNewBillingCountryOption] : [noResultsOption];
    }
    const billingCountryOptions = rawOptions.map(rawBillingData => {
      return getBillingOptionUI(true, rawBillingData, clickOptionCallback);
    });
    return addNewBillingCountryOption ? [...billingCountryOptions, addNewBillingCountryOption] : billingCountryOptions;
  };

  const formSelectedOptionUICallback = (selectedOption: IbillingData) =>
    getBillingOptionUI(true, selectedOption, undefined);

  const filterBySearchStringCallback = (search: string, rawOptions: IbillingData[]) => {
    return rawOptions.filter(rawBillingData => {
      return (
        rawBillingData?.documentCountry?.name?.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
        rawBillingData?.businessName?.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
        rawBillingData?.document?.toLowerCase().trim().includes(search.toLowerCase().trim())
      );
    });
  };

  useEffect(() => {
    if (billingDataForAllCountriesProp) return setBillingDataForAllCountries(billingDataForAllCountriesProp);
    getAllOrganizationBillingDataByCountry();
  }, [orgId]);

  return (
    <div className="NudosBillingCountryDropdown">
      <NudosSearchbarDropdown
        openDropdown={openDropdown}
        isFocused={isFocused}
        currentValue={currentValue}
        isFilled={!!currentValue}
        includeMagnifierIcon={false}
        componentSize={componentSize}
        rawOptions={billingDataForAllCountries}
        customPlaceholder={dropdownPlaceholder}
        formDropdownOptionsCallback={formDropdownOptionsCallback}
        formSelectedOptionUICallback={formSelectedOptionUICallback}
        filterBySearchStringCallback={filterBySearchStringCallback}
      />
    </div>
  );
};

export default NudosBillingCountryDropdown;
