import { FC, useEffect, useState } from 'react';
import TermsIcon from '../../../../../assets/images/components/account/ArrowsCheckbox';
import EditIcon from '../../../../../assets/images/components/account/editIcon';
import noPicture from '../../../../../assets/images/views/Admin/main/noPicture.png';
import {
  NudosButton,
  NudosModalContent,
  NudosPhoneInput,
  NudosTextInput
} from '../../../../../components/NudosComponents';
import useProfileImage from '../../../../../state/useProfileImage';
import { IPersonal } from '../../../../../types/account';
import { ICountryDetail } from '../../../../../types/countries';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import { useTranslation, Trans } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import terms from '../../../../../assets/docs/terms.pdf';
import { changeLanguage } from '../../../../../utils/changeLanguage';
import { NudosGenericModal } from '../../../../../components/NudosComponents';
import { Tany } from '../../../../../types/global';
import IconLanguageSign from '../../../../../assets/DesignSystem/SVGComponents/IconLanguageSign';
import { putUserLanguagePreference, getUserLanguagePreference } from '../../../../../services/account';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { userLanguagePreference } from '../../../../../utils/getDefaultLanguage';
import { NudosSearchbarDropdown } from '../../../../../components/DesignSystem';
import resetPassword from '../../../../../utils/resetPassword';
import './PersonalForm.scss';

interface IPersonalInfo {
  firstName: string;
  lastName: string;
  phone: string;
  countryPhoneId: number;
}
interface IPersonalForm {
  handleFileChange: Tany;
  personalData?: IPersonal;
  updatePersonalInfo?: (personalData: IPersonalInfo) => void;
  setIsPersonalEdit: (flag: boolean) => void;
  isPersonalEdit: boolean;
  countriesList?: ICountryDetail[];
}
interface Language {
  name: string;
  english: string;
  key: 'es' | 'en';
}

const PersonalForm: FC<IPersonalForm> = ({
  handleFileChange,
  personalData,
  updatePersonalInfo,
  setIsPersonalEdit,
  isPersonalEdit,
  countriesList
}: IPersonalForm) => {
  const { t } = useTranslation();
  const { orgData } = useEcommerceControler();
  const userId = orgData?.userId;
  const { temporalImg } = useProfileImage();
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [defaultPhoneCountry, setDefaultPhoneCountry] = useState<ICountryDetail>();
  const [countryPhone, setCountryPhone] = useState<ICountryDetail>();
  const [languageModal, setLanguageModal] = useState<boolean>(false);
  const [passwordResetEmailWasAttempted, setPasswordResetEmailWasAttempted] = useState(false);
  const i18nLanguageKey = 'nodi:account:personalForm:';
  const [languageKey, setLanguageKey] = useState<'es' | 'en'>('es');
  const [currentLanguageKey, setCurrentLanguageKey] = useState<string>();
  const userLanguageData = userLanguagePreference();
  const removeAccents = (string: string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const disabledResetPasswordStyles = passwordResetEmailWasAttempted ? 'disabled' : '';
  const languages: Language[] = [
    {
      name: 'Español (Latinoamérica)',
      english: 'Spanish (Latin America)',
      key: 'es'
    },
    { name: 'Inglés (EEUU)', english: 'English (US)', key: 'en' }
  ];

  const changeUserLanguagePreference = async () => {
    try {
      await putUserLanguagePreference(userId, languageKey);
      const languagePreference = await getUserLanguagePreference(userId);
      setLanguageModal(false);
      changeLanguage(languagePreference);
      window.location.href = '/nodi/dashboard';
    } catch (error) {
      displayErrorNotification();
    }
  };

  const recoveryPassword = async () => {
    if (!personalData?.email || passwordResetEmailWasAttempted) return;
    setPasswordResetEmailWasAttempted(true);
    resetPassword(personalData?.email);
  };

  const ChangeLanguageModal = () => {
    return (
      <div className="changeLanguageModal">
        <IconLanguageSign />
        <p className="text mt-4">{t(`${i18nLanguageKey}changeLanguageModal:title`)}</p>
        <p className="text">{`${t(`${i18nLanguageKey}changeLanguageModal:${currentLanguageKey}`)}?`}</p>
        <div className="mt-8 mx-auto flex items-center justify-center gap-2">
          <NudosButton
            customClassName="nudosButton"
            handleClick={() => {
              setLanguageModal(false);
              setLanguageKey(userLanguageData?.language?.code);
            }}
            buttonText={t(`${i18nLanguageKey}changeLanguageModal:nudosButtonCancel`)}
            colorPalette="secondary"
          />
          <NudosButton
            customClassName="nudosButton"
            handleClick={() => changeUserLanguagePreference()}
            buttonText={t(`${i18nLanguageKey}changeLanguageModal:nudosButtonUpdate`)}
          />
        </div>
      </div>
    );
  };

  const filterBySearchStringCallback = (search: string, rawOptions: Language[]) => {
    return rawOptions.filter(
      option =>
        removeAccents(option.name.toLowerCase().trim()).includes(removeAccents(search.toLowerCase().trim())) ||
        removeAccents(option.english.toLowerCase().trim()).includes(removeAccents(search.toLowerCase().trim()))
    );
  };

  const languageOptions = (language: Language[]) => {
    return language.map(language => {
      const getOnClickFunction = () => {
        if (userLanguageData?.language?.code !== language.key) {
          setLanguageModal(true);
          setLanguageKey(language.key);
          setCurrentLanguageKey(language.key);
        }
      };

      return (
        <div className="languageOptions" key={language.name} onClick={() => getOnClickFunction()}>
          <p className="font-extrabold">{language.name}</p>
          <p className="mb-1">{language.english}</p>
        </div>
      );
    });
  };
  const formSelectedOption = (rawSelectedOption: Language) => (
    <div className="selectedOption">{rawSelectedOption?.name}</div>
  );

  const userIsAdmin = personalData?.role?.toLowerCase() === 'admin';
  const userRoleInOrganizationText = (
    <Trans
      i18nKey={t(`${i18nLanguageKey}userRoleInOrganizationText:i18nKey`)}
      components={{
        1: `${
          userIsAdmin
            ? t(`${i18nLanguageKey}userRoleInOrganizationText:admin`)
            : t(`${i18nLanguageKey}userRoleInOrganizationText:user`)
        }`
      }}
    />
  );
  const userImage = orgData?.photoProfile ? orgData?.photoProfile.replaceAll(' ', '%20') : undefined;

  const handleChange = (state: string, value: string) => {
    if (state === 'name') {
      setName(value);
    } else if (state === 'lastName') {
      setLastName(value);
    }
    setIsPersonalEdit(true);
  };

  const editPersonalInfo = () => {
    const isPersonalInfo = {
      firstName: name,
      lastName,
      countryPhoneId: countryPhone?.id || 0,
      phone
    };
    if (updatePersonalInfo) {
      updatePersonalInfo(isPersonalInfo);
    }
  };

  const enabledButton = () => {
    if (isPersonalEdit) {
      if (name && lastName && countryPhone && phone) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleExternalCountry = (country?: ICountryDetail) => setCountryPhone(country);
  const handleChangeExternalPhone = (phone?: string) => setPhone(phone || '');

  const defaultCountry = () => {
    if (personalData?.phoneData?.phoneCode && countriesList) {
      const myDefaultCountry = countriesList.find(
        element => element.phoneCode?.toString() === personalData?.phoneData?.phoneCode
      );
      setDefaultPhoneCountry(myDefaultCountry);
    }
  };

  const openTerms = () => window.open(terms, '_blank');

  useEffect(() => {
    setName(personalData?.firstName || '');
    setLastName(personalData?.lastName || '');
    if (personalData?.phoneData?.phone) {
      setPhone(personalData?.phoneData?.phone || '');
    }
  }, [personalData]);

  useEffect(() => {
    defaultCountry();
  }, [countriesList, personalData]);

  useEffect(() => {
    if (!countryPhone || !phone) return;
    if (countryPhone?.code !== personalData?.phoneData?.country) setIsPersonalEdit(true);
    if (personalData?.phoneData?.phone !== phone) setIsPersonalEdit(true);
  }, [countryPhone, phone]);

  return (
    <div id="personalForm">
      <div className="titleFormAccount">{t(`${i18nLanguageKey}titleFormAccount`)}</div>
      <section className="profileSection">
        <div
          className="profileImg"
          style={{
            backgroundImage: `url(${temporalImg || userImage || noPicture})`
          }}
        >
          <EditIcon className="editIcon" />
          <input className="inputPosition" type={'file'} onChange={handleFileChange} />
        </div>
        <div className="detailsImg">
          <div>
            <div className="titleImg">{t(`${i18nLanguageKey}titleImg`)}</div>
            <div className="subtitleImg">{t(`${i18nLanguageKey}subtitleImg`)}</div>
          </div>
        </div>
      </section>
      <section className="firstSection">
        <div className="nameInput">
          <NudosTextInput
            label={t(`${i18nLanguageKey}inputsSection:name`)}
            placeholder={t(`${i18nLanguageKey}inputsSection:name`)}
            defaultValue={name}
            isFilled={!!name}
            handleChange={(value: string) => {
              handleChange('name', value);
            }}
            errorText={name?.length === 0 ? t(`${i18nLanguageKey}missingInformation`) : undefined}
          />
        </div>
        <div className="nameInput">
          <NudosTextInput
            label={t(`${i18nLanguageKey}inputsSection:surname`)}
            placeholder={t(`${i18nLanguageKey}inputsSection:surname`)}
            defaultValue={lastName}
            isFilled={!!lastName}
            handleChange={(value: string) => {
              handleChange('lastName', value);
            }}
            errorText={lastName?.length === 0 ? t(`${i18nLanguageKey}missingInformation`) : undefined}
          />
        </div>

        <div className="nameInput">
          <NudosPhoneInput
            label={t(`${i18nLanguageKey}inputsSection:phone`)}
            handleCountryPhoneChange={handleExternalCountry}
            handlePhoneChange={handleChangeExternalPhone}
            defaultPhoneNumber={personalData?.phoneData?.phone || undefined}
            defaultCountryData={defaultPhoneCountry}
            personalClass={'countryInput'}
            placeholder={t(`${i18nLanguageKey}inputsSection:phone`)}
            errorText={!countryPhone || !phone ? t(`${i18nLanguageKey}missingInformation`) : undefined}
            countriesList={countriesList || []}
          />
        </div>
      </section>
      <NudosButton
        customClassName="saveButton"
        componentSize="medium"
        isButtonDisabled={!enabledButton()}
        handleClick={() => editPersonalInfo()}
        buttonText={t(`${i18nLanguageKey}nudosButton`)}
      />
      <div className="lineForm" />
      <section>
        <div className="titleFormAccount languageTitle">{t(`${i18nLanguageKey}languageSection:languageTitle`)}</div>
        <NudosSearchbarDropdown
          filterBySearchStringCallback={filterBySearchStringCallback}
          formSelectedOptionUICallback={formSelectedOption}
          formDropdownOptionsCallback={languageOptions}
          includeMagnifierIcon={false}
          customPlaceholder={
            (userLanguageData?.language?.label &&
              t(`services:languageButtonDropdown:${userLanguageData?.language?.label}`)) ||
            'Español (Latinoamérica)'
          }
          rawOptions={languages}
          componentSize="medium"
          label={t(`${i18nLanguageKey}languageSection:label`)}
          isFilled={true}
        />
        {languageModal && (
          <NudosGenericModal
            modalContent={
              <NudosModalContent
                excludeHeader={true}
                componentSize="medium"
                customHeight="220px"
                customWidth="466px"
                CustomModalComponent={<ChangeLanguageModal />}
              />
            }
          />
        )}
      </section>
      <div className="lineForm" />
      <section className="accessInformationSection">
        <div className="titleFormAccount accessInformationTitle mt-6">
          {t(`${i18nLanguageKey}accessInformationTitle`)}
          <div className="orgAdminText">{userRoleInOrganizationText}</div>
        </div>
        <div className="emailSection">
          <NudosTextInput
            componentSize="medium"
            placeholder={t(`${i18nLanguageKey}inputsSection:corporateEmail`)}
            label={t(`${i18nLanguageKey}inputsSection:corporateEmail`)}
            isDeactivated
            defaultValue={personalData?.email || ''}
            isFilled={false}
          />
          <div className={`textChangePassword ${disabledResetPasswordStyles}`} onClick={recoveryPassword}>
            {t(`${i18nLanguageKey}textChangePassword`)}
          </div>
        </div>
      </section>
      <div className="lineForm" />
      <div className="titleFormAccount mt-6">{t(`${i18nLanguageKey}applicationPolicies`)}</div>
      <button className="buttonTerms" onClick={() => openTerms()}>
        <TermsIcon className="mr-2" /> {t(`${i18nLanguageKey}buttonTerms`)}
      </button>
    </div>
  );
};

export default PersonalForm;
