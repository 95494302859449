import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={11} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g>
      <path d="M5.5 9.375a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75Z" fill="#CCC" stroke="#CCC" />
      <circle cx={5.5} cy={2.813} r={0.625} fill="#fff" />
      <rect x={4.875} y={4.375} width={1.25} height={3.438} rx={0.625} fill="#fff" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MoreInfoIcon = memo(ForwardRef);
export default MoreInfoIcon;
