import { FC, Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NudosEmployeeSearchBar, NudosSelectDropdown } from '../../../../../../../components/NudosComponents';
import { Iemployee } from '../../../../../../../types/global';
import { ICartShipping, IProductsCart } from '../../../../../../../types/cart';
import { ShipToEmployeeModalFlux } from '..';
import { isEmployeeDataComplete } from '../../../../../../../utils/productDefinitions';
import { IWarehouse, IorganizationOfficeData } from '../../../../../../../types/account';
import { checkIfOfficeAddressDataIsComplete } from '../../../../checkout.utils';
import { getEmployeeById } from '../../../../../../../services/employees.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../utils/displayNudosStandardNotifications';
import useStateSubscriptions from '../../../../../../../state/useStateSubscriptions';
import { ICountryDetail } from '../../../../../../../types/countries';
import IconGreenThunder from '../../../../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import './ReferenceOptions.scss';

interface IOptions {
  value: number;
  name: string;
}

interface IReferenceOptions {
  optionsSelect?: IOptions[];
  openAddOfficeAddress: (getOfficeAddressCallback?: (data?: IorganizationOfficeData) => void) => Promise<void> | void;
  externalIndex: number;
  defaultOfficeAddress?: boolean;
  setEveryOneOffice?: (check: boolean) => void;
  officeAddress?: IorganizationOfficeData;
  productInfo?: IProductsCart;
  handleShippingReference?: (
    type: 'employee' | 'office' | 'nudos',
    employeeInfo?: Iemployee,
    officeData?: IorganizationOfficeData,
    storeNudosData?: IWarehouse
  ) => void;
  deleteShipping?: () => void;
  countryName: string;
  everyOneOffice?: boolean;
  referenceInitialData?: ICartShipping;
  globalShippingCartWasPreloaded?: boolean;
  countryWarehouse?: IWarehouse;
  refrenceProductId: number;
  completeListOfCountries?: ICountryDetail[];
  handleDynamicBanner?: (state: boolean) => void;
}

const ReferenceOptions: FC<IReferenceOptions> = ({
  optionsSelect,
  openAddOfficeAddress,
  officeAddress,
  defaultOfficeAddress,
  externalIndex,
  setEveryOneOffice,
  productInfo,
  handleShippingReference,
  deleteShipping,
  countryName,
  everyOneOffice,
  referenceInitialData,
  globalShippingCartWasPreloaded,
  countryWarehouse,
  refrenceProductId,
  completeListOfCountries,
  handleDynamicBanner
}: IReferenceOptions) => {
  const { state }: { state: { userId: number; referenceInitialData: ICartShipping } } = useLocation();
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();

  const [shippingSelect, setShippingSelect] = useState<IOptions>();
  const [selectedEmployee, setSelectedEmployee] = useState<Iemployee | undefined>();
  const [showShipToEmployeeModalFlux, setShowShipToEmployeeModalFlux] = useState(false);
  const [closeOutside, setCloseOutside] = useState<boolean>(false);
  const [newlyCreatedEmployeeWasPreloadedIfRequired, setNewlyCreatedEmployeeWasPreloadedIfRequired] = useState(false);

  const { userId: newEmployeeId, referenceInitialData: dataBeforeEmployeeCreation } = state || {};
  const translationKey = 'designSystemComponents:referenceOptions:';
  const shippingName = shippingSelect?.name;
  const selectDropdownPlaceholder = shippingName ? `${shippingName}` : t(`${translationKey}shipTo`);

  const handleChangeOptionOffice = (
    shipmentOption: IOptions,
    type: 'employee' | 'office',
    employeeData?: Iemployee,
    officeData?: IorganizationOfficeData
  ) => {
    if (shipmentOption && setEveryOneOffice) {
      setShippingSelect(shipmentOption);
      setEveryOneOffice(false);
      setSelectedEmployee(undefined);
      if (handleShippingReference) {
        handleShippingReference(type, employeeData, officeData);
      }
    }
  };

  const handleChangeOptionStoreNudos = (shipmentOption: IOptions) => {
    if (shipmentOption && setEveryOneOffice) {
      setShippingSelect(shipmentOption);
      setEveryOneOffice(false);
      setSelectedEmployee(undefined);
      if (handleShippingReference && countryWarehouse) {
        handleShippingReference('nudos', undefined, undefined, countryWarehouse);
      }
    }
  };

  const handleChangeOptionEmployee = (e: IOptions) => {
    if (e && setEveryOneOffice && deleteShipping) {
      setShippingSelect(e);
      setEveryOneOffice(false);
      deleteShipping();
      displaySuccessNotification({
        customJSXMessage: <>{t(`${translationKey}employeeOptionNotification`)}</>
      });
    }
  };

  const assignEmployeeAddress = (newlySelectedEmployee?: Iemployee) => {
    setSelectedEmployee(newlySelectedEmployee);
    if (newlySelectedEmployee && isEmployeeDataComplete(newlySelectedEmployee)) {
      handleShippingReference && handleShippingReference('employee', newlySelectedEmployee);
      return displaySuccessNotification({
        customJSXMessage: <>{t(`${translationKey}assignEmployeeAddressText`)}</>
      });
    }
    if (newlySelectedEmployee) return;
    deleteShipping && deleteShipping();
  };

  const selectNewlyCreatedEmployee = async (userId: number) => {
    if (!userId) return;
    try {
      setNewlyCreatedEmployeeWasPreloadedIfRequired(true);
      setShippingSelect({ value: 2, name: t(`${translationKey}employee`) });
      setEveryOneOffice && setEveryOneOffice(false);
      const employeeData = await getEmployeeById(userId);
      if (employeeData?.country?.id !== referenceInitialData?.countryId) {
        displayErrorNotification({
          customJSXMessage: <>{t(`${translationKey}wrongCountryAlert`)}</>
        });
        return window.history.replaceState({}, '');
      }
      assignEmployeeAddress(employeeData);
      setNewlyCreatedEmployeeWasPreloadedIfRequired(true);
      window.history.replaceState({}, '');
    } catch (error) {
      displayErrorNotification();
    }
  };

  const officeDataIsComplete = checkIfOfficeAddressDataIsComplete(officeAddress);
  const callbackFunctionForOfficeDestinationModal = (item: IOptions, modalData?: IorganizationOfficeData) => {
    checkIfOfficeAddressDataIsComplete(modalData) && handleChangeOptionOffice(item, 'office', undefined, modalData);
    displaySuccessNotification({
      customJSXMessage: <>{t(`${translationKey}officeDataIsComplete`)}</>
    });
  };

  const handleChangeShipmentOption = (item: IOptions) => {
    if (item.value === 1 && !officeDataIsComplete) {
      openAddOfficeAddress(
        () => (modalData: IorganizationOfficeData) => callbackFunctionForOfficeDestinationModal(item, modalData)
      );
    } else if (item.value === 1 && officeDataIsComplete) {
      handleChangeOptionOffice(item, 'office', undefined, officeAddress);
      displaySuccessNotification({
        customJSXMessage: <>{t(`${translationKey}officeDataIsComplete`)}</>
      });
    } else if (item.value === 2) {
      handleChangeOptionEmployee(item);
    }
  };

  const generateOptions = () => {
    return (
      optionsSelect &&
      optionsSelect.length > 0 &&
      optionsSelect.map((item, index) => {
        return (
          <Fragment
            key={`shipping-option-${item.value}-${index}-external-${externalIndex}-productId-${productInfo?.productId}`}
          >
            {shippingSelect?.value !== item.value && (
              <>
                {item.value !== 3 && (
                  <div
                    className="shippingOption extraShippingOption"
                    onClick={() => {
                      handleChangeShipmentOption(item);
                      setCloseOutside(true);
                    }}
                  >
                    {item.name}
                    {!officeAddress?.address && item?.value === 1 && (
                      <div className="noOffice">{t('recurrentWords:add')}</div>
                    )}
                    {officeAddress?.address && item?.value === 1 && (
                      <div className="grayAddress extraGrayAddress">{officeAddress?.address}</div>
                    )}
                  </div>
                )}
                {item.value === 3 && countryWarehouse && (
                  <>
                    {stateSubscription.levelSubscription === 'Lite' ? (
                      <div
                        className="shippingOption extraShippingOption"
                        onClick={() => {
                          handleChangeOptionStoreNudos(item);
                          setCloseOutside(true);
                        }}
                      >
                        {item.name}
                        <div className="containerNudosStore extraContainerNudosStore">
                          <div className="nodiIcon extraNodiIcon">
                            <IconGreenThunder fill="#7B61FF" />
                          </div>
                          <div className="nudosStore extraPurpleText">{t(`${translationKey}primeMessage`)}</div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="shippingOption relative extraShippingOption"
                        style={{ color: '#CCCCCC' }}
                        onClick={() => {
                          if (handleDynamicBanner) handleDynamicBanner(true);
                        }}
                      >
                        {item.name}
                        <div className="containerNudosStore extraContainerNudosStore">
                          <div className="nodiIcon extraNodiIcon">
                            <IconGreenThunder fill="#7B61FF" />
                          </div>
                          <div className="nudosStoreGray extraPurpleText">{t(`${translationKey}primeMessage`)}</div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </Fragment>
        );
      })
    );
  };

  const finishEmployeeDestinationFluxCallbackFn = async (employeeUserId: string | number) => {
    if (!employeeUserId) return;
    const updatedEmployeeData = await getEmployeeById(`${employeeUserId}`);
    setSelectedEmployee(updatedEmployeeData);
    handleShippingReference && handleShippingReference('employee', updatedEmployeeData);
    displaySuccessNotification({
      customJSXMessage: <>{t(`${translationKey}finishEmployeeDestination`)}</>
    });
    setShowShipToEmployeeModalFlux(false);
  };

  const changeCloseOutside = (status: boolean) => {
    setCloseOutside(status);
  };

  useEffect(() => {
    if (
      handleShippingReference &&
      officeAddress?.address &&
      defaultOfficeAddress &&
      optionsSelect &&
      optionsSelect.length > 0
    ) {
      setShippingSelect(optionsSelect[0]);
      handleShippingReference('office', undefined, officeAddress);
    }
  }, [defaultOfficeAddress]);

  useEffect(() => {
    if (
      shippingSelect?.name === t(`${translationKey}employee`) &&
      selectedEmployee &&
      !isEmployeeDataComplete(selectedEmployee)
    )
      setShowShipToEmployeeModalFlux(true);
  }, [selectedEmployee]);

  useEffect(() => {
    if (showShipToEmployeeModalFlux) return;
    if (
      shippingSelect?.name === t(`${translationKey}employee`) &&
      selectedEmployee &&
      !isEmployeeDataComplete(selectedEmployee)
    )
      setSelectedEmployee(undefined);
  }, [showShipToEmployeeModalFlux]);

  useEffect(() => {
    if (everyOneOffice) setSelectedEmployee(undefined);
  }, [everyOneOffice]);

  useEffect(() => {
    if (shippingSelect || !referenceInitialData?.destinationLocationId || !globalShippingCartWasPreloaded) return;
    if (referenceInitialData?.destinationIsOffice) {
      const officeOption = { value: 1, name: t(`${translationKey}officeAddress`) };
      handleChangeOptionOffice(officeOption, 'office', undefined, officeAddress);
    }
    if (!referenceInitialData?.destinationIsOffice && referenceInitialData?.employee) {
      const employeeOption = { value: 2, name: t(`${translationKey}employee`) };
      setSelectedEmployee(referenceInitialData.employee);
      handleShippingReference && handleShippingReference('employee', referenceInitialData.employee);
      setShippingSelect(employeeOption);
    }
    if (
      !referenceInitialData?.destinationIsOffice &&
      !referenceInitialData?.employee &&
      !!referenceInitialData.destinationLocationId
    ) {
      if (stateSubscription.levelSubscription === 'Lite') {
        const nudosOption = { value: 3, name: t(`${translationKey}nudosWarehouse`) };
        handleChangeOptionStoreNudos(nudosOption);
      }
      if (deleteShipping && stateSubscription.levelSubscription === 'Free') {
        deleteShipping();
      }
    }
  }, [referenceInitialData, globalShippingCartWasPreloaded]);

  useEffect(() => {
    // This effect handles the preselection of a newly created employee when the user goes from the employee search dropdown directly to the create employee module and returns
    if (newlyCreatedEmployeeWasPreloadedIfRequired || !dataBeforeEmployeeCreation || !newEmployeeId) return;
    const isOtherTool = dataBeforeEmployeeCreation?.onlyShippingUid !== referenceInitialData?.onlyShippingUid;
    const isSameEmployee = dataBeforeEmployeeCreation?.employee?.userId === newEmployeeId;
    const employeeWasSelected = referenceInitialData?.employee?.userId === newEmployeeId;
    if (isOtherTool || isSameEmployee || employeeWasSelected || referenceInitialData?.destinationLocationId) return;
    selectNewlyCreatedEmployee(newEmployeeId);
  }, [newEmployeeId, dataBeforeEmployeeCreation, referenceInitialData]);

  return (
    <div id="referenceOptions">
      <div className="referenceGeneral">
        <div className="containerNumber">
          <div className="numberText">{`${externalIndex + 1}.`}</div>
        </div>
        <div
          className="containerSelect"
          id={`item-product-${refrenceProductId}-country-${countryName}-index-${externalIndex}`}
        >
          <NudosSelectDropdown
            closesOnChangeSelection={stateSubscription?.levelSubscription === 'Lite'}
            heightDropDown={32}
            isFilled={!!shippingSelect}
            selectOptions={generateOptions() || []}
            currentValueOrplaceholder={selectDropdownPlaceholder}
            customContainer={'customPadding extraCustomPadding'}
            closeOutside={stateSubscription?.levelSubscription === 'Free' && closeOutside}
            changeCloseOutside={changeCloseOutside}
          />
        </div>
        {shippingSelect?.value === 1 && officeAddress && (
          <div className="containerOfficeAddress">
            <div className="officeTitle">{t(`${translationKey}officeTitle`)}</div>
            <div className="addressOfficeInfo limitAddress">{`${officeAddress?.address || ''}`}&nbsp;</div>
            <div className="addressOfficeInfo limitUserName">
              {`/ ${officeAddress?.shipmentInfo?.receiverName || ''} `}&nbsp;
            </div>
            <div className="addressOfficeInfo limitPhone">{`- ${
              officeAddress?.shipmentInfo?.receiverPhone || ''
            }`}</div>
          </div>
        )}
        {shippingSelect?.value === 1 && !officeAddress && (
          <div className="shipmentInfoSkeletonLoader animationLoader" />
        )}
        {shippingSelect?.value === 2 && (
          <div className="containerEmployeeAddress">
            <div className={`employeeTitle ${selectedEmployee ? 'active' : ''}`}>
              {t(`${translationKey}officeTitle`)}
            </div>
            <div className="containerSelectEmployees">
              <NudosEmployeeSearchBar
                customWidth={280}
                customHeight={40}
                customClassName="mediumHeight"
                customPlaceholder={t(`${translationKey}employeePlaceholder`)}
                setSelectedEmployee={assignEmployeeAddress}
                selectedEmployee={selectedEmployee}
                customShowAddressDataButtonTextCompleteEmployee={t(`${translationKey}completeEmployee`)}
                customShowAddressDataButtonTextIncompleteEmployee={t(`${translationKey}incompleteEmployee`)}
                showAddressData
                seeAddressCallback={() => setShowShipToEmployeeModalFlux(true)}
                countriesFilter={countryName ? [countryName] : undefined}
                originDataForRedirection={{
                  origin: '/catalogue/checkout',
                  returnTextKey: 'checkoutRedirectionText',
                  referenceInitialData,
                  createFromSpecificCountry: countryName
                }}
              />
            </div>
          </div>
        )}
        {shippingSelect?.value === 3 && (
          <div className="containerOfficeAddress">
            <div className="officeTitle">{t(`${translationKey}officeTitle`)}</div>
            <div className="addressOfficeInfo">{t(`${translationKey}addressOfficeInfo`)}</div>
          </div>
        )}
      </div>
      {showShipToEmployeeModalFlux && selectedEmployee && (
        <ShipToEmployeeModalFlux
          setShowShipToEmployeeModalFlux={setShowShipToEmployeeModalFlux}
          employeeData={selectedEmployee}
          finishEmployeeDestinationFluxCallbackFn={finishEmployeeDestinationFluxCallbackFn}
          completeListOfCountries={completeListOfCountries}
        />
      )}
    </div>
  );
};

export default ReferenceOptions;
