import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={8} height={5} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M1 1C2.07841 2.31623 2.73598 2.97391 4 4C5.26402 2.97391 5.92159 2.31623 7 1"
      stroke={props.stroke || '#B3B3B3'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const EmployeesArrow = memo(ForwardRef);
export default EmployeesArrow;
