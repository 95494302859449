import { BlueSmile, NoSmile } from '../../../assets/images/components/floatForm';
import { ItoolEssentialData } from '../../../types/requestLogisticsModule';
import { NudosCareTag, NudosHoverText, NudosSelectButton } from '../../NudosComponents';
import ProductPills from '../../ProductPills/ProductPills';
import ToolSerial from '../../ToolSerial/ToolSerial';
import NudosToolImage from '../NudosToolImage/NudosToolImage';
import './NudosLogisticsToolCard.scss';

/**
 *@property { ItoolEssentialData } toolData
 *@property { boolean } isSelected - a boolean indicating if the tool is currently selected
 *@property { () => void } handleClickSelectButton - a callback function to execute upon clicking the tool select button
 *@property { boolean } toolHasSelectButton - a boolean indicating if the tool has or not a select button
 */
const NudosLogisticsToolCard = ({
  toolData,
  isSelected,
  handleClickSelectButton,
  toolHasSelectButton,
  totalTools
}: {
  toolData: ItoolEssentialData;
  isSelected: boolean;
  handleClickSelectButton?: () => void;
  toolHasSelectButton: boolean;
  totalTools?: number;
}) => {
  const image = toolData?.image || toolData?.imageUrl || '';
  const name = toolData?.productName || toolData?.commercialName || 'Sin nombre';
  const brandImage = toolData?.brandImage || '';
  const brandName = toolData?.brand || 'Sin marca';
  const pills = typeof toolData?.pills === 'string' ? JSON.parse(toolData.pills) : toolData?.pills || {};
  const serial = toolData?.serial || 'Sin serial';

  const referenceModelIcon =
    toolData?.referenceModel === 'ExternalProduct' ? (
      <NoSmile className="referenceModelIcon" />
    ) : (
      <BlueSmile className="referenceModelIcon" />
    );

  const opacityStyle = isSelected ? '' : 'hasOpacity';
  return (
    <div className={`NudosLogisticsToolCard ${opacityStyle}`}>
      {totalTools === 1 && <div className="w-5" />}
      {toolHasSelectButton && totalTools !== 1 && (
        <NudosSelectButton handleClickButton={handleClickSelectButton} isButtonActive={isSelected} />
      )}
      <NudosToolImage imageUrl={image} customSize="56px" />
      <div className="nameBrandAndPillsSection">
        <div className="nameAndBrand">
          <div className="brand">
            {brandImage && <img src={brandImage} />}
            {!brandImage && <NudosHoverText onlyIfTruncated text={brandName} charactersLimit={12} />}
          </div>
          <NudosHoverText customClassName="name" onlyIfTruncated text={name} charactersLimit={42} />
        </div>
        <div className="serialAndPills">
          <ProductPills pills={pills} />
          <ToolSerial serial={serial} charactersLimitForHover={15} />
        </div>
      </div>
      <div className="nudoscareAndReferenceModel">
        <div className="referenceModel">{referenceModelIcon}</div>
        <NudosCareTag nudosCareType={toolData?.nudosCareName} />
      </div>
    </div>
  );
};

export default NudosLogisticsToolCard;
