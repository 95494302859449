import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <>
      {props?.role === 'active' ? (
        <>
          <g clipPath="url(#clip0_2230_11333)">
            <path
              d="M0.822708 8.93098C0.954822 10.1659 1.94799 11.159 3.18225 11.2966C5.07807 11.5079 6.92186 11.5079 8.81765 11.2966C10.0519 11.159 11.0451 10.1659 11.1772 8.93098C11.2747 8.0194 11.3571 7.08352 11.3571 6.12939C11.3571 5.17526 11.2747 4.23938 11.1772 3.3278C11.0451 2.09295 10.0519 1.09977 8.81765 0.962199C6.92186 0.750882 5.07807 0.750882 3.18225 0.962199C1.94799 1.09977 0.954822 2.09295 0.822708 3.3278C0.725179 4.23938 0.642822 5.17526 0.642822 6.12939C0.642822 7.08352 0.72518 8.0194 0.822708 8.93098Z"
              fill="#383838"
              stroke="#383838"
            />
            <path d="M7.5152 4.61426L4.48474 7.64471" stroke="white" strokeLinecap="round" />
            <path d="M7.5152 7.64471L4.48474 4.61426" stroke="white" strokeLinecap="round" />
          </g>
          <defs>
            <clipPath id="clip0_2230_11333">
              <rect width="12" height="12" fill="white" transform="translate(0 0.129395)" />
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <g clipPath="url(#clip0_2230_11340)">
            <path
              d="M0.822708 8.93098C0.954822 10.1659 1.94799 11.159 3.18225 11.2966C5.07807 11.5079 6.92186 11.5079 8.81765 11.2966C10.0519 11.159 11.0451 10.1659 11.1772 8.93098C11.2747 8.0194 11.3571 7.08352 11.3571 6.12939C11.3571 5.17526 11.2747 4.23938 11.1772 3.3278C11.0451 2.09295 10.0519 1.09977 8.81765 0.962199C6.92186 0.750882 5.07807 0.750882 3.18225 0.962199C1.94799 1.09977 0.954822 2.09295 0.822708 3.3278C0.725179 4.23938 0.642822 5.17526 0.642822 6.12939C0.642822 7.08352 0.72518 8.0194 0.822708 8.93098Z"
              fill="white"
              stroke="#CCCCCC"
            />
          </g>
          <defs>
            <clipPath id="clip0_2230_11340">
              <rect width="12" height="12" fill="white" transform="translate(0 0.129395)" />
            </clipPath>
          </defs>
        </>
      )}
    </>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CheckFilters = memo(ForwardRef);
export default CheckFilters;
