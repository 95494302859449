import CartIcon from '../../../assets/images/views/Admin/dashboard/cart';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import noImg from '../../../assets/images/views/Admin/dashboard/noImg.png';
import ContainerModules from '../../../components/containerModules';
import EmptyAlert from '../../../components/emptyAlert';
import { NudosButton, NudosGenericModal, NudosModalContent } from '../../../components/NudosComponents';
import PurpleAlert from '../../../components/purpleAlert';
import { formatCost } from '../../../utils/formatNumbers';
import ArrowCheck from '../../../assets/images/components/counterItems/ArrowsCheckbox';
import useStatusAlert from '../../../state/useAlert';
import { useEffect, useLayoutEffect, useState } from 'react';
import {
  getAcquisitionSales,
  getAcquisitionSubscription,
  getChartAreas,
  getChartEmployees,
  getChartTools,
  getDataAlert,
  getDataNotifications,
  getDownLoadInventoryResume,
  getFilterAreaValue,
  getFilterToolsValue,
  postMassiveToolsUploadRequest
} from '../../../services/dashboard';
import {
  IAcquisitionRent,
  IAcquisitionSales,
  IAreasData,
  IChartTools,
  IDataChartEmployees,
  IDataNotifications,
  IToolsChartDetail,
  IToolsFilters
} from '../../../types/dashboard';
import { useHistory } from 'react-router-dom';
import { getOrganizationAccount, getPersonalAccount } from '../../../services/account';
import {
  BlackBanner,
  EquipamentChart,
  EquipEmployeeModal,
  GenericLoader,
  NotificationCard,
  GenericNoResults
} from './components';
import SimpleSelect from '../../../components/simpleSelect';
import { segmentTrackEvent } from '../../../utils/segment';
import MagicIcon from '../../../assets/images/views/Admin/dashboard/magiIcon';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { blueTutoriel, updateStatusTutoriel } from '../../../services/tutoriel';
import HeaderModule from './components/headerModule';
import {
  ChangeIcon,
  computerIcon,
  degraded,
  employeIcon,
  MonitorIcon,
  PersonIcon,
  IconBlueDownLoad
} from '../../../assets/images/views/Admin/dashboard';
import EmployeesTable from './components/EmployeesTable';
import { getOrgData, getSessionLogin } from '../../../utils/getLocalStorageData';
import { ZoomAlert } from './components/ZoomAlert';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { formatOrgData } from '../../../utils/orgFormatData';
import { useTranslation } from 'react-i18next';
import NudosDynamicBanner, {
  INudosDynamicBanner
} from '../../../components/DesignSystem/NudosDynamicBanner/NudosDynamicBanner';
import { OnboardingNodiFreeSteps } from '../../../components/Onboarding/NodiFree/OnboardingNodiFreeSteps';
import useOnboardingState from '../../../state/useOnboardingState';
import { OnboardingNodiPrimeSteps } from '../../../components/Onboarding/NodiPrime';
import { getOnboarding } from '../../../services/onboarding';
import { NudosCountryDropdown } from '../../../components/DesignSystem';
import { ICountryDetail } from '../../../types/countries';
import './DashBoardModule.scss';

export interface ISelectData {
  value: number;
  name: string;
  image?: string;
  nameStringId?: string | null;
  categoryStringId?: string | null;
}

interface IPayLoadTools {
  name: string;
  value: number;
}

interface IToolsChart {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  payload: IPayLoadTools;
  value: number;
  name: string;
  categoryStringId?: string | null;
  minPorcent?: boolean;
}

interface IPieChart {
  name: string;
  value: number;
  color: string;
  percentage: number | string;
}

const DashBoardModule = () => {
  const { push } = useHistory();
  const { isOpenPurpleAlert, setNotificactionId } = useStatusAlert();
  const {
    showFreeOnboarding,
    setShowFreeOnboarding,
    showPrimeOnboarding,
    setShowPrimeOnboarding,
    detailsPrimeOnboarding,
    setDetailsPrimeOnboarding,
    detailsFreeOnboarding,
    setDetailsFreeOnboarding
  } = useOnboardingState();
  const orgData = getOrgData();
  const orgInfo = formatOrgData();
  const userLogin = getSessionLogin();
  const { t } = useTranslation();
  const formatOrgInfo = formatOrgData();

  //blockedCompany 332
  const blockMarketPlace = formatOrgInfo?.organizationId === 332;
  const isFirstLogIn = +userLogin?.lastLoginAt - +userLogin?.createdAt < 21600000; // 6 hours
  const { stateSubscription, showBlackBanner } = useStateSubscriptions();
  const [dataChartEmployees, setDataChartEmployees] = useState<IDataChartEmployees>();
  const [employeesFilter, setEmployeesFilter] = useState<ISelectData>({
    value: 1,
    name: 'Todos',
    categoryStringId: 'allcategories'
  });
  const [employeesLoading, setEmployeesLoading] = useState<boolean>(true);
  const [fullEmployees, setFullEmployees] = useState<number>(0);
  const [allTools, setAllTools] = useState<number>(0);
  const [toolsData, setToolsData] = useState<IToolsChartDetail>();
  const [toolsLoading, setToolsLoading] = useState<boolean>(false);
  const allCountriesFilter = {
    id: 0,
    flag: '',
    code: '',
    value: 9999,
    name: 'Todos los países',
    nameStringId: 'allCountries'
  };
  const [selectCountryTools, setSelectCountryTools] = useState<ICountryDetail>(allCountriesFilter);
  const [selectAvailabilityTools, setSelectAvailabilityTools] = useState<ISelectData>({
    value: 0,
    name: 'Todos',
    categoryStringId: 'allcategories'
  });
  const [dataPieChart, setDataPieChart] = useState<IPieChart[]>();
  const [loadingToolsFilters, setLoadingToolsFilters] = useState<boolean>(false);
  const [countryToolsFilters, setCountryToolsFilters] = useState<IToolsFilters[]>();
  const [availableToolsFilters, setAvailableToolsFilters] = useState<ISelectData[]>();
  const [showNameToolsChart, setShowNameToolsChart] = useState<string>();

  const [salesData, setSalesData] = useState<IAcquisitionSales>();
  const [salesLoading, setSalesLoading] = useState<boolean>(false);
  const [subscriptionsData, setsubScriptionsData] = useState<IAcquisitionRent>();

  const [filterAreaCharts, setFilterAreaCharts] = useState<ICountryDetail>(allCountriesFilter);
  const [chartAreasData, setChartAreasData] = useState<IAreasData>();
  const [availableAreaFilters, setAvailableAreaFilters] = useState<IToolsFilters[]>();
  const [areaChartsLoading, setAreaChartsLoading] = useState<boolean>(true);
  const [areaFiltersLoading, setAreaFiltersLoading] = useState<boolean>(true);

  const [alertId, setAlertId] = useState<number>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loadingAlert, setLoadingAlert] = useState<boolean>(false);
  const [organizationImg, setOrganizationImg] = useState<string>();
  const _activeWasShown = localStorage.getItem('activeWasShown');
  const activeWasShown = _activeWasShown && JSON.parse(_activeWasShown);
  const _personalData = localStorage.getItem('personalData');
  const personalData = _personalData && JSON.parse(_personalData);
  const [showChartDetail, setShowChartDetail] = useState<string>();
  const isPersonalDataComplete = personalData?.firstName && personalData?.lastName && personalData?.phoneData?.phone;
  const purpleAlertText = !isPersonalDataComplete
    ? 'La información de tu registro está incompleta, ayúdanos completándola.'
    : undefined;
  const purpleAlertButtonText = !isPersonalDataComplete ? 'Completar perfil' : undefined;
  const [notifiacations, setNotifications] = useState<IDataNotifications[]>([]);
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(false);
  const [showEquipEmployeeModal, setShowEquipEmployeeModal] = useState(false);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [loadingBlueButton, setLoadingBlueButton] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [buyAcquisition, setBuyAcquisition] = useState<boolean>(true);
  const [loadingDownLoad, setLoadingDownload] = useState<boolean>(false);
  const [downLoadFilters, setDownloadFilters] = useState<string>('');
  const [bannerMessage, setBannerMessage] = useState<INudosDynamicBanner>();

  //onboarding
  const freePlatform = stateSubscription?.levelSubscription === 'Free';
  const dashboardLenguageText = 'nodi:dashboard:';

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    value,
    name,
    minPorcent
  }: IToolsChart) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.12;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        <text
          onMouseEnter={() => {
            setShowChartDetail(name);
          }}
          onMouseOver={() => {
            setShowChartDetail(name);
          }}
          onMouseLeave={() => {
            setShowChartDetail(undefined);
          }}
          className="employesChartLabel"
          x={x}
          y={y}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fill="#383838"
        >
          {name}
        </text>

        {(showChartDetail === name || minPorcent) && (
          <text
            className="employesChartLabelDetail"
            x={x}
            y={y - 10}
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
            fill="#999999"
          >
            {`${value} (${(percent * 100).toFixed(0)}%)`}
          </text>
        )}
      </>
    );
  };

  const employeesFilterList = [
    { value: 1, name: t('services:countries:employeesFilterList:value1') },
    { value: 2, name: t('services:countries:employeesFilterList:value2') },
    { value: 3, name: t('services:countries:employeesFilterList:value3') }
  ];
  const handleClickPurpleAlertButton = () => {
    if (isPersonalDataComplete) {
      setNotificactionId(alertId || 0);
    } else push('/nodi/account');
  };

  const getShopping = async () => {
    setSalesLoading(true);
    try {
      const data = await getAcquisitionSales(orgData.organizationId);
      setSalesData(data);
      setSalesLoading(false);
    } catch (error) {
      setSalesLoading(false);
      displayErrorNotification();
    }
  };
  const getSubscriptions = async () => {
    try {
      const data = await getAcquisitionSubscription(orgData.organizationId);
      setsubScriptionsData({ ...data });
    } catch (error) {
      displayErrorNotification();
    }
  };

  const getAlertInformation = async () => {
    setLoadingAlert(true);
    try {
      const data = await getDataAlert(orgData.userId);
      const notifiacationDash = data.find(element => element.displayIn === 'dashboard');
      if (notifiacationDash && !notifiacationDash.clicked) {
        setAlertId(notifiacationDash.id);
        setShowAlert(true);
      }
      setLoadingAlert(false);
    } catch (error) {
      setLoadingAlert(false);
      displayErrorNotification();
    }
  };

  const redirectToWaitingScreen = () => {
    if (orgData?.organizationState === 'active') return;
    return push(`/state/inactive`);
  };

  const showNewOrganizationWelcomingAlert = () => {
    displaySuccessNotification({
      customJSXMessage: (
        <div>
          <div>Bienvenid@ a Nodi, te invitamos a configurar la plataforma</div>
          <div>para poder empezar a equipar a tus empleados. Comienza</div>
          <div>cargando tus empleados e inventario.</div>
        </div>
      ),
      button1Text: 'Gracias, lo hago luego',
      button2Text: 'Cargar empleados',
      button2Action: () => push('/nodi/upload-employees?from=dashboard')
    });
  };

  const showFirstTimeInvitedUserWelcomingAlert = () =>
    displaySuccessNotification({
      customJSXMessage: <p>Bienvenid@, tu cuenta ha quedado asociada a {orgData.organizationName || 'tu empresa'}</p>
    });

  const showRecurrentUserWelcomingAlert = () =>
    displaySuccessNotification({
      customJSXMessage: <>{t(`${dashboardLenguageText}recurrentUserWelcomingAlert`)}</>
    });

  const showAppropiateWelcomingAlert = () => {
    if (isFirstLogIn && orgData?.organizationState === 'active' && isPersonalDataComplete) {
      showNewOrganizationWelcomingAlert();
    }
    if (isFirstLogIn && orgData?.organizationState === 'active' && !isPersonalDataComplete) {
      showFirstTimeInvitedUserWelcomingAlert();
    }
    if (!isFirstLogIn && orgData?.organizationState === 'active') {
      showRecurrentUserWelcomingAlert();
    }
    if (isFirstLogIn) {
      segmentTrackEvent({ generalSignUpCompleted: { SignUpCreated: true } });
    }
    localStorage.setItem('activeWasShown', JSON.stringify(true));
  };

  const getInfoCompany = async () => {
    const infoCompany = await getOrganizationAccount(orgData?.organizationId);
    setOrganizationImg(infoCompany?.image || '');
  };

  const chartsColors = (category: string) => {
    switch (category) {
      //MB: Preguntar colores
      case 'Laptops':
        return '#ff664e';
      case 'Monitores':
        return '#F5A8A8';
      case 'Sillas':
        return '#54A1FC';
      case 'Escritorios':
        return '#D6E9FF';
      case 'Accesorios':
        return '#8F9CD0';
      case 'Impresoras':
        return '#E6D2FD';
      case 'Mobiliario':
        return '#E6D2FD';
      case 'Laptops con DEP':
        return '#E6D2FD';
      case 'Laptops CTOs':
        return '#E6D2FD';
      case 'PC':
        return '#CCCCCC';
      default:
        return '#ff664e';
    }
  };

  const prepareCategoryList = (categories: IChartTools[]) => {
    const newCategorieList = [];
    if (categories.length > 0) {
      for (const category of categories) {
        const temporalCategory = {
          name: category.name,
          value: category.number,
          color: chartsColors(category.name),
          percentage: category.percentage
        };
        newCategorieList.push(temporalCategory);
      }
      return newCategorieList;
    }
    return [];
  };

  const formatToolsFilter = (countryfilter?: string, statusFilter?: string) => {
    const countryFiltersParam = countryfilter ? `{"name":"country","includes":"in","values":["${countryfilter}"]}` : '';
    const labelStatusFilter =
      statusFilter === 'Disponibles' || statusFilter === 'En uso'
        ? 'productAvailability'
        : statusFilter === 'Almacenados' || statusFilter === 'En oficinas/ otros'
        ? 'place'
        : 'statusFilter';
    const statusFiltersParam = statusFilter
      ? `{"name":"${labelStatusFilter}","includes":"in","values":["${
          statusFilter === 'Almacenados'
            ? 'Bodega Nudos'
            : statusFilter === 'En oficinas/ otros'
            ? `Oficinas","Otras direcciones`
            : statusFilter
        }"]}`
      : '';

    const hasFilters = countryfilter || statusFilter;
    const hasBothFilters = countryfilter && statusFilter;
    const filters = hasFilters
      ? `?filters=[${countryFiltersParam}${hasBothFilters ? ',' : ''}${statusFiltersParam}]`
      : '';

    setDownloadFilters(filters || '');
  };

  const charTools = async (countryFilterParam?: string, availabilityFilterParam?: string) => {
    setToolsLoading(true);
    try {
      const countryFilter = countryFilterParam === 'Todos los países' ? '' : countryFilterParam;
      const availabilityFilter = availabilityFilterParam === 'Todos' ? '' : availabilityFilterParam;
      const chartData = await getChartTools(orgData.organizationId, countryFilter, availabilityFilter);
      const newData = prepareCategoryList(chartData?.categoriesData);
      setToolsData({ ...chartData });
      setDataPieChart([...newData]);
      setToolsLoading(false);
      formatToolsFilter(countryFilter, availabilityFilter);
      if (!allTools) {
        setAllTools(chartData.total);
      }
    } catch (error) {
      displayErrorNotification();
      setToolsLoading(false);
    }
  };

  const chartEmployees = async (filter?: number) => {
    setEmployeesLoading(true);
    try {
      const chartData = await getChartEmployees(
        orgData.organizationId,
        filter === 1 ? '' : filter === 2 ? 'equipped' : 'unequipped'
      );
      setDataChartEmployees({ ...chartData });
      if (!fullEmployees) {
        setFullEmployees(chartData?.total);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setEmployeesLoading(false);
    }
  };

  const employeeFilterChange = (item: ISelectData) => {
    setEmployeesFilter(item);
  };

  const toolsCountryFilterChange = (item: ICountryDetail) => {
    setSelectCountryTools(item);
  };

  const toolsAvailabilityFilterChange = (item: ISelectData) => {
    setSelectAvailabilityTools(item);
  };

  const areaFilterChange = (item: ICountryDetail) => {
    setFilterAreaCharts(item);
  };

  const getNotifications = async () => {
    setLoadingNotifications(true);
    try {
      const dataNotifications = await getDataNotifications(orgData.organizationId);
      setNotifications(dataNotifications);
      setLoadingNotifications(false);
    } catch (error) {
      displayErrorNotification();
      setLoadingNotifications(false);
    }
  };

  const changeStatusTraining = async () => {
    setLoadingBlueButton(true);
    try {
      await updateStatusTutoriel(orgData.userId);
      displaySuccessNotification({
        customJSXMessage: <> {t(`${dashboardLenguageText}header:changeStatusTraining`)}</>
      });
      await getInfoTutoriel();
    } catch (error) {
      setLoadingBlueButton(false);
      displayErrorNotification();
    }
  };

  const getInfoTutoriel = async (initialLoading?: boolean) => {
    if (initialLoading) {
      setInitialLoading(true);
    }
    try {
      const blueData = await blueTutoriel(orgData.userId);
      setShowTutorial(blueData.showTutorial);
      setInitialLoading(false);
      setLoadingBlueButton(false);
    } catch (error) {
      setInitialLoading(false);
      displayErrorNotification();
    }
  };

  const validatePersonalData = async () => {
    if (localStorage.getItem('personalData')) {
      const myPersonalData = JSON.parse(localStorage.getItem('personalData') || '');
      if (!myPersonalData?.phoneData) {
        try {
          const dataPersonal = await getPersonalAccount(orgData.userId);
          dataPersonal && localStorage.setItem('personalData', JSON.stringify(dataPersonal));
        } catch (error) {
          displayErrorNotification();
        }
      }
    }
  };

  const excludValue = (list: ISelectData[], select?: ISelectData) => {
    if (select) {
      const myNewList = list.filter(element => element.value !== select.value);
      return myNewList;
    }
    return list;
  };

  const generateCardDetails = (total: number, perdiod: number) => {
    return (
      <>
        {!!total && total > 0 ? (
          <div className="boxAmounts">
            <div className="containerPrincipalAmounts">
              <div className="principalAmount">
                {`$${formatCost(perdiod || 0)}`}
                <span className="mxnText">MXN</span>
              </div>
              <div className="amountMonth">
                <ArrowCheck className="iconCheck" />
                {buyAcquisition ? ' Compras del mes' : 'Pago mensual'}
              </div>
            </div>
            <div className="downBuyBox">
              <div className="totalAmount">
                {buyAcquisition ? (
                  <>
                    {`$${formatCost(total || 0)}`}
                    <span style={{ fontSize: '10px' }}>MXN</span>
                  </>
                ) : (
                  total
                )}
              </div>
              <div className="subtotalAmount">{buyAcquisition ? 'Compras totales' : 'Total de rentas'} </div>
            </div>
          </div>
        ) : (
          <div className="alertContainerShopping">
            {!blockMarketPlace && (
              <EmptyAlert
                text={'Adquirir tu primera herramienta'}
                onClick={() => {
                  push('/catalogue/home');
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const getToolFiters = async () => {
    setLoadingToolsFilters(true);
    try {
      const filtersData = await getFilterToolsValue(orgData.organizationId);
      const availableToolsFiltersFixedOptions = [
        { value: 0, name: 'Todos', categoryStringId: 'value0' },
        { value: 1, name: 'Disponibles', categoryStringId: 'value1' },
        { value: 2, name: 'En uso', categoryStringId: 'value2' },
        { value: 3, name: 'Almacenados', categoryStringId: 'value3' },
        { value: 4, name: 'En oficinas/ otros', categoryStringId: 'value4' }
      ];
      const allFiltersTools = [...filtersData, ...[allCountriesFilter]];
      setCountryToolsFilters(allFiltersTools);
      setAvailableToolsFilters(availableToolsFiltersFixedOptions);
      setLoadingToolsFilters(false);
    } catch (error) {
      setLoadingToolsFilters(false);
      displayErrorNotification();
    }
  };

  const getDataChartAreas = async (filter?: string) => {
    setAreaChartsLoading(true);
    try {
      const areasData = await getChartAreas(orgData.organizationId, filter === 'Todos los países' ? '' : filter);
      setChartAreasData(areasData);
      setAreaChartsLoading(false);
    } catch (error) {
      displayErrorNotification();
      setAreaChartsLoading(false);
    }
  };

  const getAreaFiters = async () => {
    setAreaFiltersLoading(true);
    try {
      const filtersData = await getFilterAreaValue(orgData.organizationId);
      const areaFilters = [...filtersData, ...[allCountriesFilter]];
      setAvailableAreaFilters(areaFilters);
      setAreaFiltersLoading(false);
    } catch (error) {
      displayErrorNotification();
      setAreaFiltersLoading(false);
    }
  };

  const requireMassiveToolsUpload = async () => {
    if (!orgData?.organizationId || !orgData?.userId) return;
    try {
      await postMassiveToolsUploadRequest(orgData.organizationId, orgData.userId);
      displaySuccessNotification({
        customJSXMessage: (
          <div>
            <div>Un asesor se estará comunicando contigo para enviarte el</div>
            <div>excel para el cargue masivo de inventario </div>
          </div>
        )
      });
    } catch (error) {
      displayErrorNotification();
    }
  };

  const blueDownloadOnClickAction = () => {
    if (freePlatform) {
      segmentTrackEvent({ nodiFreeCsvDashboardClick: null });
      setBannerMessage({
        bannerSubtitle:
          'Obtén toda la información de tu inventario en un solo clic, accede a descuentos exclusivos en tus compras y disfruta de todo lo que Nudos tiene para ofrecerte.',
        bannerTitle: 'Descarga información detallada de tu inventario '
      });
    } else {
      downLoadAction();
    }
  };

  const hideShoppingModule = true; // CAMBIAR-JC: PARA DESOCULTAR EL MÓDULO DE COMPRAS
  const isEmployeesFilterBoxEnabled =
    !!employeesFilterList && employeesFilterList.length > 0 && !!dataChartEmployees?.total;
  const noNotificationsText = toolsData?.total ? 'No tienes notificaciones nuevas' : 'Aun no tienes notificaciones';
  const organizationHaveTools =
    (toolsData && toolsData?.total && toolsData.total > 0) ||
    selectCountryTools?.name !== 'Todos los países' ||
    selectAvailabilityTools?.name !== 'Todos';

  const downLoadAction = async () => {
    setLoadingDownload(true);
    try {
      const urlResume = await getDownLoadInventoryResume(Number(orgInfo?.organizationId), downLoadFilters || '');
      if (urlResume) {
        window.open(urlResume, '_blank');
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingDownload(false);
    }
  };

  const submitBannerCallback = () => {
    segmentTrackEvent({ nodiFreeCsvModalNodiPrimeAdvertiseClick: null });
  };

  const handleStatusOnboarding = async () => {
    if (stateSubscription.levelSubscription) {
      const levelSubscription = stateSubscription.levelSubscription;
      try {
        const onboardingInfo = await getOnboarding(Number(orgInfo?.organizationId), levelSubscription);
        if (onboardingInfo && !onboardingInfo.isCompleted) {
          if (levelSubscription === 'Lite') {
            setShowPrimeOnboarding(true);
            setShowFreeOnboarding(false);
            setDetailsPrimeOnboarding({ ...onboardingInfo });
          }
          if (levelSubscription === 'Free') {
            setShowFreeOnboarding(true);
            setShowPrimeOnboarding(false);
            setDetailsFreeOnboarding({ ...onboardingInfo });
          }
        }
      } catch (error) {
        displayErrorNotification();
      }
    }
  };

  useLayoutEffect(() => {
    if (activeWasShown) return;
    showAppropiateWelcomingAlert();
  }, [activeWasShown, isFirstLogIn, orgData?.organizationState]);

  useEffect(() => {
    redirectToWaitingScreen();
    getShopping();
    getSubscriptions();
    getAlertInformation();
    getInfoCompany();
    getNotifications();
    getInfoTutoriel(true);
    validatePersonalData();
    getToolFiters();
    getAreaFiters();
    segmentTrackEvent({ dashboardView: { EntryMode: isFirstLogIn ? 'Signup' : 'Login' } });
  }, []);

  useEffect(() => {
    charTools(selectCountryTools?.name, selectAvailabilityTools?.name);
  }, [selectCountryTools, selectAvailabilityTools, stateSubscription]);

  useEffect(() => {
    chartEmployees(employeesFilter?.value);
  }, [employeesFilter, stateSubscription]);

  useEffect(() => {
    getDataChartAreas(filterAreaCharts?.name);
  }, [filterAreaCharts, stateSubscription]);

  useEffect(() => {
    handleStatusOnboarding();
  }, [stateSubscription.levelSubscription]);

  return (
    <>
      {showFreeOnboarding && detailsFreeOnboarding && <OnboardingNodiFreeSteps />}
      {showPrimeOnboarding && detailsPrimeOnboarding && <OnboardingNodiPrimeSteps />}
      {bannerMessage && (
        <NudosDynamicBanner
          bannerTitle={bannerMessage?.bannerTitle || ''}
          bannerSubtitle={bannerMessage?.bannerSubtitle || ''}
          closeModalCallback={() => setBannerMessage(undefined)}
          submitBannerCallback={submitBannerCallback}
          openedBannerOrigin="descarga de archivo en inventario"
          alertType={2}
        />
      )}
      {showEquipEmployeeModal && (
        <div className="noAvailableToolsModalContainer">
          <NudosGenericModal
            modalContent={
              <NudosModalContent
                CustomModalComponent={<EquipEmployeeModal />}
                toCloseModal={() => setShowEquipEmployeeModal(false)}
                modalContentTitle="Equipar empleado"
                componentSize="landscapeSmall"
              />
            }
          />
        </div>
      )}
      <ZoomAlert />

      <ContainerModules>
        <div id="dashBoardModule">
          <div className="boxDashboard">
            {loadingAlert ? (
              <div className="loaderAlert animationLoader" />
            ) : (
              <>
                {(showAlert || !isPersonalDataComplete) && !isOpenPurpleAlert && (
                  <PurpleAlert
                    textAlert={purpleAlertText}
                    buttonText={purpleAlertButtonText}
                    onClick={handleClickPurpleAlertButton}
                    customClassName={!isPersonalDataComplete ? 'incompleteData' : ''}
                  />
                )}
              </>
            )}
            {!showAlert && isPersonalDataComplete && !isOpenPurpleAlert && (
              <div className="containerBanners">
                {!stateSubscription.nudosCare && showBlackBanner && <BlackBanner />}
              </div>
            )}
            <div className="companyName">
              <div className="iconCompany" style={{ backgroundImage: `url(${organizationImg || noImg})` }} />
              {t(`${dashboardLenguageText}header:titleHeader`)},
              <span className="exeptionName">
                &nbsp;{orgData?.organizationName ? orgData?.organizationName.toLowerCase() : ''}
              </span>
              <div className="containerBlueButton">
                {initialLoading || loadingAlert ? (
                  <div className="w-full h-full rounded-md animationLoader" />
                ) : (
                  <>
                    {showTutorial ? (
                      <button className="trainingNodi" style={{ cursor: 'not-allowed' }}>
                        {loadingBlueButton ? (
                          <GenericLoader borderWidth={2} sizeLoader={20} pb={4} />
                        ) : (
                          <>
                            <MagicIcon className="mr-2 rotate-90" />
                            {t(`${dashboardLenguageText}header:trainingNodi`)}
                          </>
                        )}
                      </button>
                    ) : (
                      <button
                        className="trainingNodi"
                        onClick={() => {
                          changeStatusTraining();
                        }}
                      >
                        {loadingBlueButton ? (
                          <GenericLoader borderWidth={2} sizeLoader={20} pb={4} />
                        ) : (
                          <>
                            <MagicIcon className="mr-2" />
                            {t(`${dashboardLenguageText}header:nodiCapacitationButton`)}
                          </>
                        )}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="firstData">
              <div className="employees" style={{ paddingTop: employeesLoading ? '0px' : '' }}>
                <HeaderModule
                  icon={employeIcon}
                  title={t(`${dashboardLenguageText}body:employees:title`)}
                  totalNumber={dataChartEmployees?.total || 0}
                />
                <div className="sectionInfo">
                  <div className="boxSectionInfo">
                    <div className="containerInfoDetail">
                      {employeesLoading ? (
                        <GenericLoader />
                      ) : (
                        <>
                          {dataChartEmployees && dataChartEmployees?.total && dataChartEmployees?.total > 0 ? (
                            <div className="containerTable">
                              <EmployeesTable dataEmployees={dataChartEmployees.countriesData} />
                            </div>
                          ) : (
                            <>
                              {fullEmployees === 0 ? (
                                <div className="emptyEmployees">
                                  <EmptyAlert
                                    width="195px"
                                    text={t(`${dashboardLenguageText}emptyEmployees`)}
                                    onClick={() => {
                                      segmentTrackEvent({ employeesNewClick: { NewEmployeeOrigin: 'Dashboard' } });
                                      if (freePlatform) {
                                        setBannerMessage({
                                          bannerTitle: 'Carga empleados en un click',
                                          bannerSubtitle:
                                            'Carga empleados masivamente y sin límites, obtén descuentos y mucho más con todo lo que Nudos tiene para ti.'
                                        });
                                      } else {
                                        push('/nodi/upload-employees?from=dashboard');
                                      }
                                    }}
                                  />
                                </div>
                              ) : (
                                <GenericNoResults />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="boxFilter">
                      <SimpleSelect
                        enabled={isEmployeesFilterBoxEnabled}
                        defaultValue={employeesFilter}
                        selectList={
                          !!employeesFilterList && employeesFilterList.length > 0
                            ? excludValue(employeesFilterList, employeesFilter)
                            : []
                        }
                        customIcon={
                          <PersonIcon className={`personIcon ${isEmployeesFilterBoxEnabled ? '' : 'inactive'}`} />
                        }
                        changeValue={employeeFilterChange}
                        useAlias
                      />
                      <div className="buttonConntainer">
                        <NudosButton
                          customWidth="100px"
                          customHeight="24px"
                          colorPalette="secondary"
                          customClassName="redirectionButton"
                          buttonText={t(`${dashboardLenguageText}body:employees:NewHire`)}
                          handleClick={() => {
                            segmentTrackEvent({
                              employeesNewClick: {
                                NewEmployeeOrigin: 'Dashboard'
                              }
                            });
                            push('/nodi/employees/create?origin=dashboard');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tools" style={{ paddingTop: toolsLoading ? '0px' : '' }}>
                <HeaderModule
                  icon={computerIcon}
                  title={t(`${dashboardLenguageText}body:inventory:title`)}
                  totalNumber={toolsData?.total || 0}
                />
                <div className="sectionInfo">
                  <div className="boxSectionInfo">
                    <div className="containerInfoDetail">
                      {toolsLoading || loadingToolsFilters ? (
                        <GenericLoader />
                      ) : (
                        <>
                          {toolsData && toolsData?.total && toolsData.total > 0 ? (
                            <div className="containerToolsChart">
                              <div className="floatBox" />
                              <div className="boxToolsChart">
                                {dataPieChart && dataPieChart.length > 0 && (
                                  <>
                                    <ResponsiveContainer width="100%" height="100%">
                                      <PieChart width={114} height={114}>
                                        <Pie
                                          isAnimationActive={false}
                                          className="cursor-pointer"
                                          onMouseEnter={e => {
                                            setShowNameToolsChart(e?.payload?.payload.name);
                                          }}
                                          onMouseOver={e => {
                                            setShowNameToolsChart(e?.payload?.payload.name);
                                          }}
                                          onMouseLeave={() => {
                                            setShowNameToolsChart(undefined);
                                          }}
                                          stroke="none"
                                          data={dataPieChart}
                                          cx="50%"
                                          cy="50%"
                                          labelLine={false}
                                          label={e => {
                                            if (e.payload.payload.percentage > 5) {
                                              return renderCustomizedLabel(e);
                                            } else if (
                                              e?.payload?.payload?.percentage < 6 &&
                                              e?.payload?.payload?.name === showNameToolsChart
                                            ) {
                                              const myData = { ...e, ...{ minPorcent: true } };
                                              return renderCustomizedLabel(myData);
                                            }
                                          }}
                                          outerRadius={56}
                                          dataKey="value"
                                        >
                                          {dataPieChart.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                          ))}
                                        </Pie>
                                      </PieChart>
                                    </ResponsiveContainer>
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            <>
                              {allTools === 0 ? (
                                <div className="emptyEmployees">
                                  <EmptyAlert
                                    width="195px"
                                    text={t(`${dashboardLenguageText}emptyInventory`)}
                                    onClick={() => {
                                      if (freePlatform) {
                                        setBannerMessage({
                                          bannerTitle: 'Carga todas las herramientas que necesites',
                                          bannerSubtitle:
                                            'Actualiza a Nodi Prime para cargar todas las herramientas que desees, obtener descuentos y disfrutar de mucho más con todo lo que Nudos tiene para ti.'
                                        });
                                      } else {
                                        requireMassiveToolsUpload();
                                      }
                                    }}
                                  />
                                </div>
                              ) : (
                                <GenericNoResults customHeight={85} />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="boxFilter">
                      <NudosCountryDropdown
                        displayLabel={false}
                        currentValue={selectCountryTools}
                        countriesList={countryToolsFilters || []}
                        charactersLimit={12}
                        updateCurrentValueCallback={toolsCountryFilterChange}
                      />
                      <SimpleSelect
                        enabled={organizationHaveTools}
                        defaultValue={selectAvailabilityTools}
                        selectList={
                          !!availableToolsFilters && availableToolsFilters.length > 0
                            ? excludValue(availableToolsFilters, selectAvailabilityTools)
                            : []
                        }
                        customIcon={<MonitorIcon className={`personIcon ${organizationHaveTools ? '' : 'inactive'}`} />}
                        includeImageOnListItems
                        changeValue={toolsAvailabilityFilterChange}
                        useAlias
                      />
                      {loadingDownLoad ? (
                        <div className="downLoadInventary cursor-not-allowed">
                          <>
                            <GenericLoader borderWidth={1} sizeLoader={10} />
                          </>
                        </div>
                      ) : (
                        <div className="downLoadInventary" onClick={() => blueDownloadOnClickAction()}>
                          <div className="blueDownload">{t(`${dashboardLenguageText}body:inventory:blueDownload`)}</div>
                          <IconBlueDownLoad className="iconPosition" />
                        </div>
                      )}

                      <div className="buttonConntainer toolsButtonContainer" style={{ marginTop: '33px' }}>
                        <NudosButton
                          customWidth="100px"
                          customHeight="24px"
                          colorPalette="secondary"
                          customClassName="redirectionButton"
                          buttonText={t(`${dashboardLenguageText}body:inventory:uploadInventoryButton`)}
                          handleClick={() => {
                            push('/nodi/upload-external-tools?from=dashboard');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="thirdData">
              {loadingNotifications ? (
                <>
                  <div className="loadingNotifications animationLoader" />
                  <div className="w-full h-4" />
                </>
              ) : (
                <>
                  <div className={`${notifiacations && notifiacations.length === 0 ? 'emptyHeader' : 'blakHeader'} `}>
                    <div className="continerTitle">
                      <div className="whiteText">{t(`${dashboardLenguageText}body:notifications:title`)}</div>
                    </div>
                  </div>
                  <div className="boxNotifiations">
                    <div className="whiteDegraded">
                      <img alt="degraded-img" className="limitsDegraded" src={degraded} />
                    </div>
                    <div className="bodyNotifications">
                      {notifiacations && notifiacations.length === 0 ? (
                        <div className="noNotifications">{noNotificationsText}</div>
                      ) : (
                        <>
                          {notifiacations &&
                            notifiacations.length &&
                            notifiacations.map((item, index: number) => {
                              return <NotificationCard dataNotification={item} key={`notification${index}`} />;
                            })}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-full h-4" />
                </>
              )}
            </div>
            <div className="secondData">
              {!hideShoppingModule && (
                <div className="shopping">
                  {salesLoading ? (
                    <div className="w-full h-full animationLoader rounded-md" />
                  ) : (
                    <div className="boxShopping">
                      {!!subscriptionsData?.subscriptionsTotalNumber &&
                        Number(subscriptionsData?.subscriptionsTotalNumber || 0) > 0 && (
                          <div
                            className="changeAcquisitionType"
                            style={{ width: buyAcquisition ? '70px' : '58px' }}
                            onClick={() => {
                              setBuyAcquisition(!buyAcquisition);
                            }}
                          >
                            <div className="changeText">{buyAcquisition ? ' Rentas' : 'Compras'}</div>
                            <ChangeIcon />
                          </div>
                        )}
                      <div className="containerSubtitle">
                        <div className="boxIcon">
                          <CartIcon stroke="#FFFFFF" />
                        </div>
                        <div className="texTitle">{buyAcquisition ? 'Compras' : 'Rentas'}</div>
                      </div>
                      {generateCardDetails(
                        buyAcquisition
                          ? Number(salesData?.salesTotalAmount || 0)
                          : Number(subscriptionsData?.subscriptionsTotalNumber || 0),
                        buyAcquisition
                          ? Number(salesData?.actualMonthAmount || 0)
                          : Number(subscriptionsData?.subscriptionTotalAmount || 0)
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className="chartEquipmentContainer">
                <div className="equipamentHeader">
                  <div className="boxHeaderEquipament">
                    <div className="headerTitle">{t(`${dashboardLenguageText}body:chartEquipmentContainer:title`)}</div>
                    <div className="chartdescriptions">
                      <div className="equipped">
                        <div className="textEquipament">
                          {t(`${dashboardLenguageText}body:chartEquipmentContainer:equipament`)}
                        </div>
                        <div className="equipamentMark" />
                      </div>
                      <div className="notEquipped">
                        <div className="textNoEquipament">
                          {t(`${dashboardLenguageText}body:chartEquipmentContainer:noEquipament`)}
                        </div>
                        <div className="noEquipamentMark" />
                      </div>
                    </div>
                    <div className="selectContainer">
                      <div className="boxContainer">
                        <NudosCountryDropdown
                          displayLabel={false}
                          currentValue={filterAreaCharts}
                          countriesList={availableAreaFilters || []}
                          charactersLimit={12}
                          updateCurrentValueCallback={areaFilterChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="bodyEquipament"
                  style={{ paddingTop: areaChartsLoading || areaFiltersLoading ? '0px' : '' }}
                >
                  {areaChartsLoading || areaFiltersLoading ? (
                    <GenericLoader />
                  ) : (
                    <>
                      {chartAreasData?.areasData && chartAreasData?.areasData?.length ? (
                        <div className="containerEquipamentChart">
                          <div className="boxEquipamentChart">
                            <EquipamentChart chartData={chartAreasData.areasData} />
                          </div>
                        </div>
                      ) : (
                        <div className="emptyEquipament">
                          <EmptyAlert
                            height="22px"
                            width="212px"
                            text={t(`${dashboardLenguageText}emptyEquipament`)}
                            onClick={() => {
                              segmentTrackEvent({
                                employeesNewClick: {
                                  NewEmployeeOrigin: 'Dashboard'
                                }
                              });
                              push('/nodi/employees/create?origin=dashboard');
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="h-5 w-full" />
          </div>
        </div>
      </ContainerModules>
    </>
  );
};
export default DashBoardModule;
