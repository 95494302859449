import { useState } from 'react';
import IconGreenThunder from '../../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import { NudosButton } from '../../../../../components/NudosComponents';
import { SmallPrimeSavings, SmallSavingBanner } from '../../../../../components/NudosPrimeOrFree';
import { DiscountPercentTooltip } from '../../../../../components/NudosPrimeOrFree/DiscountPercentTooltip';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import {
  ICartInfo,
  IProductPrices,
  IShipmentGroupedByDestinationLocationAndProductId,
  ISummaryItem
} from '../../../../../types/cart';
import { ICountryDetail } from '../../../../../types/countries';
import { nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import {
  costForPaidShipments,
  lowerLimitForFreeShipping,
  minimumShippingCost
} from '../../../../../utils/productDefinitions';
import { truncateText } from '../../../../../utils/truncateText';
import { FooterBanner } from '../../../../RequestLogistics/LogisticsQuoteAndBillingStep/LogisticServicePrincingQuote/Components';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../../utils/segment';
import './CheckoutSummary.scss';

const CheckoutSummary = ({
  firstSectionListingElements,
  showShipmentsSection,
  secondSectionListingElements,
  secondSectionListItems,
  boldAndLargeTextInSecondLine,
  buttonText,
  handleClickButton,
  isButtonDisabled,
  customClassName,
  shipmentsList,
  shipmentsPricingDataByDestination = {},
  listOfCountriesOpenForBuying = [],
  referencesList,
  loadingButton,
  checkoutStep,
  itemsCart,
  heightLimitProductList,
  thirdSectionListItems,
  totalSaved,
  totalShippingDiscount
}: IcheckoutSummaryProps) => {
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();

  const [selectTooltipKey, setSelectTooltipKey] = useState<string>();

  const { levelSubscription } = stateSubscription;
  const typePlan = levelSubscription ? levelSubscription : 'Free';
  const isPrime = levelSubscription === 'Lite' || levelSubscription === 'Vip';
  const isFree = levelSubscription === 'Free';
  const summaryHasShipmentsList = shipmentsList && shipmentsList.length > 0;
  const isStepOne = checkoutStep === 1;
  const isStepTwo = checkoutStep === 2;
  const isStepThree = checkoutStep === 3;
  const summaryHasReferencesListForStep3 =
    (isStepThree || isStepOne || isStepTwo) && referencesList && referencesList.length > 0;
  const translationKey = 'nodi:orderDetails:orderDetailsHeader:';

  const trackOnHoverEvent = () => {
    segmentTrackEvent({
      [isPrime ? 'primeToolTipDiscountPrimeHover' : 'freeToolTipDiscountPrimeHover']: {
        ScreenName: checkoutStep === 1 ? 'Kart' : checkoutStep === 2 ? 'Shipping' : 'Billing&Payment'
      }
    });
  };

  const referencesPrices = (prices: IProductPrices) => {
    if (prices) {
      const referencePrice = Number(isPrime ? prices.subTotalWithPrime || 0 : prices.subTotalWithoutPrime || 0);
      return `${nudosFormatCurrency({ value: referencePrice, maximumFractionDigits: 2 })} USD`;
    }
    return '$0 USD';
  };

  const getSectionLines = (sectionListingElements: { [key: string]: string | number }, customClassName?: string) => {
    return Object.entries(sectionListingElements).map(([key, value], i) => {
      const customKeyStyles = key.toLowerCase().replaceAll(' ', '').trim() || '';
      return (
        <div className={`sectionLine ${customClassName || ''} ${customKeyStyles}`} key={`sectionLine${i}-${key}`}>
          <div className="left">{key}</div>
          <div className={`right ${value === t('recurrentWords:toBeCalculated') ? 'text-nudos-blue' : ''}`}>
            {value}
          </div>
        </div>
      );
    });
  };

  const getListSectionLines = (sectionListingElements: ISummaryItem[], customClassName?: string) => {
    if (sectionListingElements && sectionListingElements?.length > 0) {
      return sectionListingElements.map((item, index) => {
        const customKeyStyles = item?.name.toLowerCase().replaceAll(' ', '').trim() || '';
        if (!item?.hidden) {
          return (
            <div
              className={`sectionLine ${customClassName || ''} ${customKeyStyles} ${
                item?.isBold ? 'boldAndLargeTextInSecondLine' : ''
              }`}
              key={`sectionLine${index}-${item.name}-other-list`}
            >
              <div className="left flex">
                {item?.name}
                {item?.tooltip ? <div className="extraTooltipContainers">{item?.tooltip || <></>}</div> : <></>}
              </div>
              <div className={`right flex ${item?.toCalculate ? 'text-nudos-blue' : ''}`}>
                {item?.showSecondaruValue && (
                  <div className="secondaryValue">
                    (
                    {item.showGrayIcon && (
                      <div className="thunderContainer">
                        <IconGreenThunder fill="#7B61FF" />
                      </div>
                    )}
                    <div className={`secondaryValueType ${item?.isBold ? 'font-bold' : ''}`}>
                      {item?.secondaryValue || `$0 USD`}
                    </div>
                    )
                  </div>
                )}
                {item.showYellowIcon && (
                  <div className="yellowIconPrime">
                    <IconGreenThunder fill="#7B61FF" />
                  </div>
                )}
                {item?.mainValue}
              </div>
            </div>
          );
        }
      });
    }
    return <></>;
  };

  const getShipmentsDataToDisplay = (shipmentData: IShipmentGroupedByDestinationLocationAndProductId) => {
    const countryFlag =
      listOfCountriesOpenForBuying.find(country => country?.id === shipmentData?.countryId)?.flag || '';
    const employeeName = `${shipmentData?.employee?.firstName || ''} ${shipmentData?.employee?.lastName || ''}`;
    const shipmentText = shipmentData?.employee
      ? truncateText(employeeName, 20)
      : shipmentData?.destinationIsOffice
      ? t('nodi:toolInternal:NodiDetailsHeader:offices')
      : t('nodi:toolInternal:NodiDetailsHeader:nudosWarehouse');
    const totalPriceData = shipmentData.unitPrice;
    const shipmentsForThisDestinationTotalValue =
      shipmentsPricingDataByDestination[shipmentData?.destinationLocationId || 0];
    const shipmentPriceText =
      totalPriceData >= lowerLimitForFreeShipping ? `${minimumShippingCost} USD` : `${costForPaidShipments}  USD`;
    const shipmentPriceTextOnlyValue =
      totalPriceData >= lowerLimitForFreeShipping ? `${minimumShippingCost}` : `${costForPaidShipments}`;
    const shipmentPriceValue =
      shipmentsForThisDestinationTotalValue < lowerLimitForFreeShipping ? `${costForPaidShipments}` : `0`;
    return { countryFlag, shipmentText, shipmentPriceText, shipmentPriceValue, shipmentPriceTextOnlyValue };
  };

  const getShipmentsListUI = () => {
    return shipmentsList?.map((item, index) => {
      const { countryFlag, shipmentText, shipmentPriceText, shipmentPriceTextOnlyValue } =
        getShipmentsDataToDisplay(item);
      return (
        <div className="sectionLine shipmentsInfo" key={`shipment-${index}-${item?.onlyShippingUid}`}>
          <div className="left">
            {t(`${translationKey}shipmentsCount`)} {index + 1}
          </div>
          <div className="right">
            <div className="flagContainer">
              <img src={countryFlag} className="limitsFlagImg" />
            </div>
            <div className="shipmentText">{shipmentText} - </div>
            <div className={`shipmentPrice ${isPrime && shipmentPriceTextOnlyValue === '10' ? 'line-through' : ''}`}>
              {shipmentPriceText}
            </div>
          </div>
        </div>
      );
    });
  };
  const getCalcShipments = () => {
    return (
      <div className="sectionLine shipmentsInfo">
        <div className="left">{t(`${translationKey}otherShipments`)} </div>
        <div className="right">
          <div className="shipmentPrice">{t('recurrentWords:toBeCalculated')}</div>
        </div>
      </div>
    );
  };
  const getReferencesListForStep3 = () => {
    return (
      <div
        className={`secondSection ${heightLimitProductList ? 'extraLimitsSection' : ''}`}
        style={{ marginBottom: '8px', maxHeight: heightLimitProductList ? `${heightLimitProductList}px` : '' }}
      >
        <div
          onScroll={() => setSelectTooltipKey(undefined)}
          className={heightLimitProductList ? 'extraForHeigthLimits' : ''}
          style={{ maxHeight: heightLimitProductList ? `${heightLimitProductList - 16}px` : '' }}
        >
          <div className="headerReferences">
            <div className={`referencesName ${isStepOne || isStepTwo ? 'step1ReferencesName' : ''}`}>
              {t('recurrentWords:referencesName')}
            </div>
            <div className={`referencesQuantity ${isStepOne || isStepTwo ? 'step1ReferencesQuantity' : ''}`}>
              {t('recurrentWords:quantity')}
            </div>
            <div
              className={`referencesTotal ${isStepOne || isStepTwo ? 'step1ReferencesTotal' : ''} ${
                isStepThree ? 'longReferenceTotal' : ''
              }`}
            >
              Total
            </div>
          </div>
          {referencesList?.map((item, i) => {
            return (
              <div className="bodyReference extraBodyReference" key={`referenceList${item.productId}${i}`}>
                <div className="boxBodyReference">
                  <div className={`bodyName ${isStepOne || isStepTwo ? 'step1BodyName' : ''}`}>{item.productName}</div>
                  <div
                    className={`bodyQuantity ${isStepOne || isStepTwo || isStepThree ? 'step1bodyQuantity' : ''} ${
                      isStepThree ? 'longQuantityData' : ''
                    }`}
                  >
                    <div className="containerTextQuatity">
                      <div className="quantityText">{item.quantity}</div>
                      <div className="countryFlagContainer">
                        <div className="minFlag">
                          <div className="minFlag" style={{ backgroundImage: `url(${item.countryFlag})` }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`bodyTotal ${isStepOne || isStepTwo || isStepThree ? 'step1BodyTotal' : ''} ${
                      isStepThree ? 'longBodyTotal' : ''
                    }`}
                  >
                    {Number(item?.unitPrice || 0) >= 170 && (
                      <div
                        onMouseOverCapture={() => {
                          setSelectTooltipKey(`item-discount-position-${item.productId}-${i}`);
                        }}
                        onMouseMoveCapture={() => {
                          setSelectTooltipKey(`item-discount-position-${item.productId}-${i}`);
                        }}
                        onMouseOutCapture={() => {
                          setSelectTooltipKey(undefined);
                        }}
                        onMouseOut={() => {
                          setSelectTooltipKey(undefined);
                        }}
                        className="itemDiscuount"
                        id={`item-discount-position-${item.productId}-${i}`}
                      >
                        {selectTooltipKey === `item-discount-position-${item.productId}-${i}` && (
                          <DiscountPercentTooltip
                            subTotalWithoutPrime={item?.prices?.subTotalWithoutPrime || ''}
                            subTotalWithPrime={item?.prices?.subTotalWithPrime || ''}
                            subTotalDiscountValue={item.prices?.subTotalDiscountValue || ''}
                            selectTooltipKey={selectTooltipKey || ''}
                            tooltipKey={`item-discount-position-${item.productId}-${i}`}
                          />
                        )}
                        {isFree ? (
                          <div className="freeItem" onMouseEnter={() => trackOnHoverEvent()}>
                            <IconGreenThunder fill="#7B61FF" />
                            <div className="textFreeItem">3% off</div>
                          </div>
                        ) : (
                          <div className="primeItem" onMouseEnter={() => trackOnHoverEvent()}>
                            <IconGreenThunder fill="#7B61FF" />
                            <div className="textPrimeItem">3% off</div>
                          </div>
                        )}
                      </div>
                    )}
                    {Number(item?.subTotal || 0) >= 170 ? (
                      <div className="limitsNewTotal">{item?.prices ? referencesPrices(item.prices) : '$0 USD'}</div>
                    ) : (
                      <>
                        {`${nudosFormatCurrency({ value: Number(item?.subTotal || 0), maximumFractionDigits: 2 })}`} USD
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={`checkoutSummary ${customClassName || ''}`}>
      <div className="firstSection">{getSectionLines(firstSectionListingElements)}</div>
      {summaryHasReferencesListForStep3 && getReferencesListForStep3()}
      {showShipmentsSection && (
        <div className="numberOfShipmentsSection">
          <div className="titleSection">{t('recurrentWords:shipments')}</div>
          <div className="textBlueSection">{t('recurrentWords:toBeCalculated')}</div>
        </div>
      )}
      <div className={`secondSection ${isStepOne && isPrime ? 'extraSecondSection' : ''}`}>
        {(isStepTwo || isStepThree) && summaryHasShipmentsList && getShipmentsListUI()}
        {isStepTwo && isButtonDisabled && getCalcShipments()}
        {!isStepThree && secondSectionListingElements && getSectionLines(secondSectionListingElements)}
        {!isStepThree && secondSectionListItems && getListSectionLines(secondSectionListItems)}
        {boldAndLargeTextInSecondLine && getSectionLines(boldAndLargeTextInSecondLine, 'boldAndLargeTextInSecondLine')}
        {isStepOne && isFree && (
          <SmallSavingBanner
            step={checkoutStep}
            shippingDiscount={0}
            discountedTotal={Number(totalSaved || itemsCart?.totalDiscountValue || 0)}
          />
        )}
      </div>
      {thirdSectionListItems && thirdSectionListItems.length > 0 && (
        <div className="secondSection">
          {(isStepTwo || isStepThree) && thirdSectionListItems && getListSectionLines(thirdSectionListItems)}
          {(isStepTwo || isStepThree) && isFree && (
            <SmallSavingBanner
              step={checkoutStep}
              shippingDiscount={totalShippingDiscount || 0}
              discountedTotal={Number(totalSaved || itemsCart?.totalDiscountValue || 0)}
            />
          )}
        </div>
      )}
      {(isStepOne || isStepTwo || isStepThree) && isPrime && (
        <div className={`primeSavingsContainer ${isStepThree ? 'bigContainer' : ''}`}>
          <SmallPrimeSavings
            shippingDiscount={totalShippingDiscount || 0}
            totalSavings={totalSaved || itemsCart?.totalDiscountValue || 0}
          />
        </div>
      )}
      {isStepThree && (
        <div className="containerMinBanner">
          <FooterBanner
            originBanner="checkoutSummary"
            typePlan={typePlan === 'Vip' ? 'Lite' : typePlan}
            checkoutSummaryValue={Number(totalSaved)}
          />
        </div>
      )}
      <NudosButton
        isButtonLoading={loadingButton}
        buttonText={buttonText}
        handleClick={handleClickButton}
        isButtonDisabled={isButtonDisabled || loadingButton}
      />
    </div>
  );
};

export default CheckoutSummary;

interface IcheckoutSummaryProps {
  firstSectionListingElements: { [key: string]: string | number };
  showShipmentsSection?: boolean;
  secondSectionListingElements?: { [key: string]: string | number };
  secondSectionListItems?: ISummaryItem[];
  thirdSectionListItems?: ISummaryItem[];
  boldAndLargeTextInSecondLine?: { [key: string]: string | number };
  shipmentsList?: IShipmentGroupedByDestinationLocationAndProductId[];
  shipmentsPricingDataByDestination?: { [key: string]: number };
  listOfCountriesOpenForBuying?: ICountryDetail[];
  referencesList?: IReferences[];
  buttonText: string;
  handleClickButton: () => void;
  isButtonDisabled: boolean;
  customClassName?: string;
  loadingButton?: boolean;
  checkoutStep: 1 | 2 | 3;
  itemsCart?: ICartInfo;
  heightLimitProductList?: number;
  totalSaved?: number;
  totalShippingDiscount?: number;
}

interface IReferences {
  productId: number;
  productName: string;
  quantity: number;
  countryFlag: string;
  subTotal?: string | number;
  unitPrice?: string | number;
  prices?: IProductPrices | null | undefined;
}
