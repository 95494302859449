import { useRef, useState } from 'react';
import { NudosStaticAlertInComponent, NudosToolImage } from '../../../../components/DesignSystem';
import NudosLogisticsGeneralContainer from '../../../../components/DesignSystem/NudosLogisticsGeneralContainer/NudosLogisticsGeneralContainer';
import { NudosHoverText } from '../../../../components/NudosComponents';
import NudosLogisticsKeepInMind from '../NudosUnassignmentKeepInMind/NudosLogisticsKeepInMind';
import { useLocation } from 'react-router-dom';
import { ItoolEssentialData } from '../../../../types/requestLogisticsModule';
import { TotalsDropdown } from '../TotalsDropdown';
import { ILogisticQuoteGeneralData } from '../../../../types/logisticsServicesModule.types';
import { TstoragePlace } from '../../../../types/assignationFluxes';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../utils/unCamelCase';
import './NewLogisticsVerifyData.scss';

const NewLogisticsVerifyData = ({
  type,
  collectionName = 'Sin información',
  deliveryName = 'Sin información',
  rejectedLayout = false,
  selectedDatesRange = 'Sin información',
  estimatedDelivery = 'Sin información',
  logisticServiceStatus,
  customContainerTitle,
  tools,
  timeAlertText,
  bodyAlertComponent,
  emailToAssignee,
  quoteData,
  originPlace,
  destinationPlace
}: {
  type: 'assignment' | 'unassignment';
  collectionName?: string;
  deliveryName?: string;
  rejectedLayout?: boolean;
  selectedDatesRange?: string;
  estimatedDelivery?: string;
  logisticServiceStatus?: string;
  customContainerTitle?: string;
  tools: ItoolEssentialData[];
  timeAlertText?: string | JSX.Element;
  bodyAlertComponent?: React.ReactNode;
  emailToAssignee?: string;
  quoteData?: ILogisticQuoteGeneralData;
  originPlace?: TstoragePlace;
  destinationPlace?: TstoragePlace;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [showExpandedEmailToAssignee, setShowExpandedEmailToAssignee] = useState(false);
  const emailToAssigneeContainerDiv = useRef<HTMLDivElement>(null);

  const originText = capitalize(t('recurrentWords:origin'));
  const viewMoreText = capitalize(t('recurrentWords:viewMore'));
  const viewLessText = capitalize(t('recurrentWords:viewLess'));
  const destinationText = t('recurrentWords:destination');
  const attachedMessageText = t('services:logistics:endStep:attachedMessage');

  const searchParams = new URLSearchParams(search);
  const requireLogisticsJSON = searchParams?.get('logistics');
  const requireLogistics = requireLogisticsJSON === 'false' ? false : true;
  const emailToAssigneeSectionExceedsAllowedHeight =
    emailToAssignee && emailToAssigneeContainerDiv.current?.clientHeight
      ? emailToAssigneeContainerDiv.current?.clientHeight > 60
      : false;
  const expandedStyles = showExpandedEmailToAssignee ? 'expanded' : '';
  const emailToLongStyles = emailToAssigneeSectionExceedsAllowedHeight ? 'long' : '';
  const fieldValueCharactersLimit = 22;
  const isAssignment = type === 'assignment';
  const datesRangeTitle = isAssignment ? 'Estimado de entrega' : 'Estimado de recolección';

  const expandEmailToAssigneeSection = () => setShowExpandedEmailToAssignee(!showExpandedEmailToAssignee);

  return (
    <div className="NewLogisticsVerifyData">
      <NudosLogisticsGeneralContainer customClassname="logisticServiceSummary" title={customContainerTitle}>
        <section className="informationSection">
          <div className="informationLine">
            <div className="informationField">
              <div className="fieldTitle">{originText}</div>
              <NudosHoverText
                onlyIfTruncated
                customClassName="fieldValue"
                text={collectionName?.toLocaleLowerCase()}
                charactersLimit={fieldValueCharactersLimit}
              />
            </div>
            <div className="informationField">
              <div className="fieldTitle">{destinationText}</div>
              <NudosHoverText
                onlyIfTruncated
                customClassName="fieldValue"
                text={deliveryName?.toLocaleLowerCase()}
                charactersLimit={fieldValueCharactersLimit}
              />
            </div>
            {logisticServiceStatus && (
              <div className="informationField">
                <div className="fieldTitle">Estado</div>
                <div className="status">{logisticServiceStatus}</div>
              </div>
            )}
          </div>
          {requireLogistics && (
            <div className="informationLine">
              <div className="informationField">
                <div className="fieldTitle">{datesRangeTitle}</div>
                <NudosHoverText
                  onlyIfTruncated
                  customClassName="fieldValue"
                  text={selectedDatesRange}
                  charactersLimit={20}
                />
              </div>
              {!isAssignment && (
                <div className="informationField">
                  <div className="fieldTitle">Estimado de entrega</div>
                  <NudosHoverText
                    onlyIfTruncated
                    customClassName="fieldValue deliveryDaysRange"
                    text={estimatedDelivery}
                    charactersLimit={20}
                  />
                </div>
              )}
            </div>
          )}
        </section>
        {requireLogistics && !rejectedLayout && (
          <>
            <NudosStaticAlertInComponent
              type="time"
              alertOptions={{
                title: timeAlertText
                  ? timeAlertText
                  : 'Los tiempos dentro del servicio son estimados, podrás ver los tiempos reales una vez se active el servicio.'
              }}
            />
          </>
        )}
        <div className="divider" />
        <section className="toolsSection">
          {tools?.map((tool, i) => (
            <NudosToolImage
              key={`toolImage${i}${tool?.serial}`}
              imageUrl={tool?.image || tool?.imageUrl || ''}
              customSize="40px"
            />
          ))}
        </section>
        {emailToAssignee && (
          <section className="emailToAssigneeSection">
            <div className="divider" />
            <div className="emailSectionTitle">{attachedMessageText}</div>
            <div className={`emailContent ${expandedStyles} ${emailToLongStyles}`} ref={emailToAssigneeContainerDiv}>
              {emailToAssignee}
            </div>
            <div className="w-full flex items-center justify-center">
              {emailToAssigneeSectionExceedsAllowedHeight && (
                <button className="seeMoreButton" onClick={expandEmailToAssigneeSection}>
                  {showExpandedEmailToAssignee ? viewLessText : viewMoreText}
                </button>
              )}
            </div>
          </section>
        )}
        {requireLogistics && !rejectedLayout && (
          <>
            <div className="divider" />
            {quoteData && (
              <TotalsDropdown quoteData={quoteData} destinationPlace={destinationPlace} originPlace={originPlace} />
            )}
            <NudosLogisticsKeepInMind />
            <NudosStaticAlertInComponent
              type="warning"
              alertOptions={{
                title: '¡Importante!',
                subtitle: 'Las herramientas deben estar empacadas en cajas cerradas para poder ser recolectadas.'
              }}
              bodyAlertComponent={bodyAlertComponent}
            />
          </>
        )}
      </NudosLogisticsGeneralContainer>
    </div>
  );
};

export default NewLogisticsVerifyData;
