import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={8} height={8} fill="none" ref={ref} {...props}>
    <path d="M1.5 1.91406H7.33333L6.5 5.03906H2.33333L1.5 1.91406Z" stroke="#FF664E" strokeLinejoin="round" />
    <path d="M1.5 1.91536L1.29167 1.08203H0.25" stroke="#FF664E" strokeLinejoin="round" />
    <ellipse cx="2.54175" cy="6.70703" rx="0.625" ry="0.625" stroke="#FF664E" strokeLinejoin="round" />
    <circle cx="6.29175" cy="6.70703" r="0.625" stroke="#FF664E" strokeLinejoin="round" />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const EmployeeCartIcon = memo(ForwardRef);
export default EmployeeCartIcon;
