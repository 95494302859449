import { FC } from 'react';
import IconAlert from '../../assets/images/components/emptyAlert/IconAlert';
import './emptyAlert.scss';
interface IEmptyAlert {
  text?: string;
  onClick?: () => void;
  type?: string;
  height?: string;
  width?: string;
}

const EmptyAlert: FC<IEmptyAlert> = ({ text, onClick, type, height, width }: IEmptyAlert) => {
  return (
    <div
      id="emptyAlert"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      style={{
        width: type === 'small' ? '136px' : width || undefined,
        height: type === 'small' ? '34px' : height || undefined
      }}
    >
      <div className="boxAlert">
        <div className="containerText">
          <div className="textStyle">{text || 'No hay texto'}</div>
        </div>
        <div className="containerIcon">
          <IconAlert />
        </div>
      </div>
    </div>
  );
};
export default EmptyAlert;
