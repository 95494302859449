import { useTranslation } from 'react-i18next';
import {
  IconAddTool,
  IconCalendar,
  IconPin,
  IconQuotation,
  IconTruck
} from '../../../../../assets/DesignSystem/SVGComponents';
import { NudosStepsProgressBar } from '../../../../../components/NudosComponents';
import './UnassignmentModuleStepper.scss';

/**
 * @property  { number } currentStep - The current step of the checkout
 */
const UnassignmentModuleStepper = ({
  currentStep,
  requireLogistics,
  includeQuoteAndBillingStep
}: {
  currentStep: number;
  requireLogistics: boolean;
  includeQuoteAndBillingStep: boolean;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'services:unassignmentModule';
  const isStepActive = (step: number) => (step === currentStep ? 'active' : '');
  const stepOrderNoSl: number = requireLogistics ? 2 : 1;
  const progressSteps = [
    {
      order: 0,
      showStep: true,
      stepContent: (
        <div className={`step0 unassignmentModuleStepContent`}>
          <IconPin className="stepIcon IconPin" />
          {!!isStepActive(0) && <div className="stepText">{t(`${i18nLanguageKey}:steps:destination`)}</div>}
        </div>
      ),
      stepWidth: isStepActive(0) ? 74 : 24,
      customStepStyles: undefined
    },
    {
      order: 1,
      showStep: requireLogistics,
      stepContent: (
        <div className={`step1 unassignmentModuleStepContent`}>
          <IconTruck className={`stepIcon IconTruck ${currentStep >= 1 ? 'active' : ''}`} />
          {!!isStepActive(1) && <div className="stepText">{t(`${i18nLanguageKey}:steps:address`)}</div>}
        </div>
      ),
      stepWidth: isStepActive(1) ? 75 : 24,
      customStepStyles: undefined
    },
    {
      order: stepOrderNoSl,
      showStep: true,
      stepContent: (
        <div className={`step2 unassignmentModuleStepContent`}>
          <IconAddTool className={`stepIcon IconAddTool ${currentStep >= stepOrderNoSl ? 'active' : ''}`} />
          {!!isStepActive(stepOrderNoSl) && <div className="stepText">{t(`${i18nLanguageKey}:steps:devices`)}</div>}
        </div>
      ),
      stepWidth: isStepActive(stepOrderNoSl) ? 84 : 24,
      customStepStyles: undefined
    },
    {
      order: 3,
      showStep: requireLogistics,
      stepContent: (
        <div className={`step3 unassignmentModuleStepContent`}>
          <IconCalendar className={`stepIcon IconCalendar ${currentStep >= 3 ? 'active' : ''}`} />
          {!!isStepActive(3) && <div className="stepText">{t(`${i18nLanguageKey}:steps:dates`)}</div>}
        </div>
      ),
      stepWidth: isStepActive(3) ? 55 : 24,
      customStepStyles: undefined
    },
    {
      order: 4,
      showStep: requireLogistics && includeQuoteAndBillingStep,
      stepContent: (
        <div className={`step2 unassignmentModuleStepContent`}>
          <IconQuotation className={`stepIcon IconQuotation ${currentStep >= 4 ? 'active' : ''}`} />
          {!!isStepActive(4) && <div className="quoteAndBillingtText">{t(`${i18nLanguageKey}:steps:price`)}</div>}
        </div>
      ),
      stepWidth: isStepActive(4) ? 64 : 24,
      customStepStyles: undefined
    }
  ];

  const definitiveSteps = progressSteps.filter(step => step?.showStep);

  return (
    <div className="UnassignmentModuleStepper">
      <NudosStepsProgressBar
        steps={definitiveSteps}
        currentStep={currentStep}
        firstAndLastConectorWidth={32}
        intermediateStepsConectorWidth={40}
        customHeight={24}
      />
    </div>
  );
};

export default UnassignmentModuleStepper;
