import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" ref={ref} {...props}>
    <g clipPath="url(#clip0_8145_35461)">
      <path
        d="M1.09703 11.7355C1.27318 13.382 2.59741 14.7063 4.24308 14.8897C6.77084 15.1714 9.22923 15.1714 11.757 14.8897C13.4027 14.7063 14.7269 13.382 14.903 11.7355C15.0331 10.5201 15.1429 9.27225 15.1429 8.00007C15.1429 6.7279 15.0331 5.48006 14.903 4.26462C14.7269 2.61816 13.4027 1.29392 11.757 1.11049C9.22923 0.828731 6.77084 0.828731 4.24308 1.11049C2.59741 1.29392 1.27318 2.61816 1.09703 4.26462C0.966987 5.48006 0.857178 6.7279 0.857178 8.00007C0.857178 9.27225 0.966988 10.5201 1.09703 11.7355Z"
        fill="white"
        stroke="#FF664E"
      />
      <path d="M7.99996 5.14282V10.8571M10.8571 7.99996H5.14282" stroke="#FF664E" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_8145_35461">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconAdd = memo(ForwardRef);
export default IconAdd;
