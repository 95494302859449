import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NodiIcon, nodiNotRegistered } from '../../../../assets/images/views/signIn';
import { NudosButton } from '../../../../components/NudosComponents';
import { isProduction } from '../../../../constants';
import { segmentTrackEvent } from '../../../../utils/segment';
import { useTranslation } from 'react-i18next';
import './NotRegistered.scss';

const NotRegistered = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { search } = useLocation();
  const email = new URLSearchParams(search).get('email');
  const i18nLanguageKey = 'nodi:notRegistered:';
  const nudosPage = isProduction ? 'https://www.nudos.co' : 'https://landing.beta.nudos.co';
  const handleGoToDemo = () => {
    segmentTrackEvent({ nodiLoginAccessRequestNoAccountClick: { ContactMail: email || '' } });
    push('/demo');
  };

  useEffect(() => {
    segmentTrackEvent({ nodiLoginNoAccountView: { ContactMail: email || '' } });
  }, []);
  return (
    <div className="notRegistered">
      <div className="content">
        <NodiIcon className="nodiIcon" />
        <div className="nodiNotRegisteredIcon">
          <img src={nodiNotRegistered} alt="Icono Nodi bloqueado" />
        </div>
        <div className="explanationTitle">{t(`${i18nLanguageKey}explanationTitle`)}</div>
        <div className="explanationText">{t(`${i18nLanguageKey}explanationText`)}</div>
        <NudosButton
          buttonText={t(`${i18nLanguageKey}applyToAccessButton`)}
          customClassName="applyToAccessButton"
          componentSize="large"
          handleClick={handleGoToDemo}
        />
        <NudosButton
          buttonText={t(`${i18nLanguageKey}secondary`)}
          colorPalette="secondary"
          componentSize="large"
          handleClick={() => (window.location.href = nudosPage)}
        />
      </div>
    </div>
  );
};

export default NotRegistered;
