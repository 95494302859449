import { useState } from 'react';
import * as yup from 'yup';
import { IformInputs } from '../../../../../../types/SignUp';
import { useTranslation } from 'react-i18next';

const useSignUpFormStep2Controller = () => {
  const { t } = useTranslation();
  const [jobPosition, setJobPosition] = useState<string>();
  const i18nLanguageKey = 'authentication:SignUpForm:formInputsStep2:';

  const positionOptions = [
    'RRHH',
    t(`${i18nLanguageKey}positionOptions:finance`),
    t(`${i18nLanguageKey}positionOptions:purchasing`),
    t(`${i18nLanguageKey}positionOptions:management`),
    'TI'
  ].map(option => {
    return (
      <div key={`option-${option}`} className="optionContainer" onClick={() => setJobPosition(option)}>
        {option}
      </div>
    );
  });

  const formInputsStep2: IformInputs[] = [
    {
      id: 'firstName',
      placeholder: 'Juanita',
      label: t(`${i18nLanguageKey}firstName`),
      type: 'text'
    },
    {
      id: 'lastName',
      placeholder: 'Castañeda',
      label: t(`${i18nLanguageKey}lastName`),
      type: 'text'
    },
    {
      id: 'position',
      placeholder: 'RRHH',
      label: t(`${i18nLanguageKey}position`),
      type: 'select',
      options: positionOptions
    },
    {
      id: 'phone',
      placeholder: t(`${i18nLanguageKey}phone`),
      label: t(`${i18nLanguageKey}phone`),
      type: 'phone'
    }
  ];

  const formSchemaStep2 = yup.object().shape({
    firstName: yup
      .string()
      .required()
      .min(2, 'El nombre debe incluir al menos 2 caracteres')
      .max(100, 'El nombre no debe exceder los 100 caracteres'),
    lastName: yup
      .string()
      .required()
      .min(2, 'El apellido debe incluir al menos 2 caracteres')
      .max(100, 'El apellido no debe exceder los 100 caracteres'),
    position: yup.string().required('La posición es requerida'),
    phone: yup
      .string()
      .min(6, 'El teléfono es muy corto. Escribe un número válido para tu país')
      .max(13, 'El teléfono es muy largo. Escribe un número válido para tu país')
      .matches(/^[0-9]{6,13}$/, 'El teléfono no es válido. Solo puedes ingresar números')
      .required('El teléfono es requerido')
  });

  return { formInputsStep2, formSchemaStep2, jobPosition };
};

export default useSignUpFormStep2Controller;
