import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={34} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M13.6 8.283c0-.959-.176-1.906-.517-2.792a7.357 7.357 0 0 0-1.475-2.368A6.79 6.79 0 0 0 9.402 1.54 6.397 6.397 0 0 0 6.8.984c-.893 0-1.777.19-2.602.556a6.79 6.79 0 0 0-2.206 1.583A7.359 7.359 0 0 0 .518 5.49 7.756 7.756 0 0 0 0 8.283h3.4c0-.48.088-.952.259-1.395a3.68 3.68 0 0 1 .737-1.184c.316-.339.69-.608 1.103-.791a3.199 3.199 0 0 1 2.602 0c.413.183.788.452 1.103.791.316.34.566.742.737 1.184.171.443.26.915.26 1.395h3.4ZM10.207 11.717c0 .959.176 1.906.518 2.791.342.886.843 1.691 1.474 2.37a6.792 6.792 0 0 0 2.206 1.582c.825.367 1.71.556 2.603.556.893 0 1.777-.19 2.602-.556a6.792 6.792 0 0 0 2.206-1.583 7.36 7.36 0 0 0 1.474-2.368 7.757 7.757 0 0 0 .518-2.792h-3.4c0 .48-.088.952-.26 1.394-.17.443-.42.846-.736 1.185-.316.339-.69.608-1.103.791a3.2 3.2 0 0 1-2.603 0 3.395 3.395 0 0 1-1.103-.791 3.677 3.677 0 0 1-.737-1.184 3.87 3.87 0 0 1-.258-1.395h-3.4ZM34 8.283c0-.959-.176-1.906-.518-2.792a7.359 7.359 0 0 0-1.474-2.368 6.79 6.79 0 0 0-2.206-1.583A6.397 6.397 0 0 0 27.2.984c-.893 0-1.777.19-2.602.556a6.79 6.79 0 0 0-2.206 1.583 7.357 7.357 0 0 0-1.475 2.368 7.757 7.757 0 0 0-.517 2.792h3.4c0-.48.088-.952.259-1.395.17-.442.421-.845.737-1.184.315-.339.69-.608 1.103-.791a3.2 3.2 0 0 1 2.602 0c.413.183.787.452 1.103.791.316.34.566.742.737 1.184.171.443.259.915.259 1.395H34Z"
      fill="#ff664e"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const NudosLogo = memo(ForwardRef);
export default NudosLogo;
