import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import EditIcon from '../../../../../assets/images/components/account/editIcon';
import noPicture from '../../../../../assets/images/views/Admin/main/noPicture.png';
import { NudosButton, NudosSelectDropdown, NudosTextInput } from '../../../../../components/NudosComponents';
import { IOrganization, IPersonal } from '../../../../../types/account';
import { ICountryDetail } from '../../../../../types/countries';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import EnclircledPlusIcon from '../../../../../assets/images/components/EnclircledPlusIcon';
import InviteCollaboratorModal from '../../../../../components/InviteCollaboratorModal';
import { ICoordinates, Tany } from '../../../../../types/global';
import { useTranslation } from 'react-i18next';
import './CompanyForm.scss';

interface iUpdateAddress {
  address?: string;
  additionalReferences?: string | null;
  country?: string;
  coordinates: ICoordinates;
  city: string;
}

interface IOrganizationInfo {
  image?: string;
  file?: Tany;
  numberOfEmployees: number | string;
  address?: iUpdateAddress;
}

interface ICompanyForm {
  organizationData?: IOrganization;
  updateOrganizationInfo?: (info: IOrganizationInfo) => void;
  personalData?: IPersonal;
  listOfCountries?: ICountryDetail[];
}

const CompanyForm: FC<ICompanyForm> = ({
  organizationData,
  updateOrganizationInfo,
  personalData,
  listOfCountries
}: ICompanyForm) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { orgData } = useEcommerceControler();
  const [labelEmployees, setLabelEmployees] = useState<string>('');
  const [file, setFile] = useState<Tany>('');
  const [temporalUrl, setTemporalUrl] = useState<string>('');
  const [collaboratorEmail, setCollaboratorEmail] = useState<string>();
  const [openInviteCollaboratorModal, setOpenInviteCollaboratorModal] = useState(false);
  const i18nLanguageKey = 'nodi:account:companyForm:';

  const registrationInfoId = new URLSearchParams(search).get('registrationInfoId');
  const collaboratorEmailParam = new URLSearchParams(search).get('email');
  const userIsAdmin = personalData?.role?.toLowerCase().trim() === 'admin';
  const organizationAssociatedUsers = organizationData?.associatedUsers?.filter(
    associatedUser => associatedUser.email !== personalData?.email
  );

  const numberOfEmployees = [
    t(`${i18nLanguageKey}formatEmployees:case1`),
    t(`${i18nLanguageKey}formatEmployees:case2`),
    t(`${i18nLanguageKey}formatEmployees:case3`),
    t(`${i18nLanguageKey}formatEmployees:case4`),
    t(`${i18nLanguageKey}formatEmployees:case5`)
  ].map(option => {
    return (
      <div
        key={`option-${option}`}
        className="optionContainer"
        onClick={() => {
          setLabelEmployees(option);
          saveOrganizationEmployees(option);
        }}
      >
        {option}
      </div>
    );
  });

  const formatEmployees = (numberOfEmployees: number) => {
    switch (numberOfEmployees) {
      case 1:
        return { label: t(`${i18nLanguageKey}formatEmployees:case1`) };
      case 2:
        return { label: t(`${i18nLanguageKey}formatEmployees:case2`) };
      case 3:
        return { label: t(`${i18nLanguageKey}formatEmployees:case3`) };
      case 4:
        return { label: t(`${i18nLanguageKey}formatEmployees:case4`) };
      case 5:
        return { label: t(`${i18nLanguageKey}formatEmployees:case5`) };
      default:
        return { label: '' };
    }
  };

  const idEmployees = (labelEmployees: string) => {
    switch (labelEmployees) {
      case t(`${i18nLanguageKey}formatEmployees:case1`):
        return { id: 1 };
      case t(`${i18nLanguageKey}formatEmployees:case2`):
        return { id: 2 };
      case t(`${i18nLanguageKey}formatEmployees:case3`):
        return { id: 3 };
      case t(`${i18nLanguageKey}formatEmployees:case4`):
        return { id: 4 };
      case t(`${i18nLanguageKey}formatEmployees:case5`):
        return { id: 5 };
      default:
        return { id: 5 };
    }
  };

  const setLabelSelect = () => {
    const numberOfEmployees = formatEmployees(organizationData?.numberOfEmployees || 0);
    setLabelEmployees(numberOfEmployees.label);
  };

  const saveOrganizationEmployees = (option: string) => {
    const bodyOrganization = {
      numberOfEmployees: idEmployees(option).id || 5
    };
    if (updateOrganizationInfo) {
      updateOrganizationInfo(bodyOrganization);
    }
  };

  const saveOrganizationData = async (myFile?: Tany) => {
    const bodyOrganization = {
      numberOfEmployees: idEmployees(labelEmployees).id || 5,
      file: myFile?.target?.files[0] || file?.target?.files[0] || null
    };
    if (updateOrganizationInfo) {
      updateOrganizationInfo(bodyOrganization);
    }
  };

  const isImageFileValid = (file: File) => {
    const isFormatCorrect = file.type === 'image/png' || file.type === 'image/jpeg';
    const isSizeValid = file.size / 1024 / 1024 < 2;
    if (!isFormatCorrect) {
      displayErrorNotification({ customJSXMessage: <>{t(`recurrentWords:isImageFileValid:format`)}</> });
      return false;
    }
    if (!isSizeValid) {
      displayErrorNotification({ customJSXMessage: <>{t(`recurrentWords:isImageFileValid:size`)}</> });
      return false;
    }
    return true;
  };

  const onChangeFile = (file: Tany) => {
    const validFile = isImageFileValid(file?.target?.files[0]);
    if (validFile) {
      setFile(file);
      const base64File: string = URL.createObjectURL(file?.target?.files[0]);
      setTemporalUrl(base64File);
      saveOrganizationData(file);
    }
  };

  const getCountryPlaceholder = (countryCode?: string) => {
    const countryData = listOfCountries?.find(country => country.code === countryCode);
    if (countryCode && countryCode !== 'other') {
      return (
        <div className="countryOptionContainer">
          <div className="flagContainer" style={{ backgroundImage: `url(${countryData?.flag || ''})` }} />
          <div className="countryName">{countryData?.name ? countryData.name.slice(0, 3) : ''}</div>
        </div>
      );
    }
    if (countryCode && countryCode === 'other') {
      return (
        <div className="countryOptionContainer">
          <div className="countryName">Otros</div>
        </div>
      );
    }
    return 'Otros';
  };

  useEffect(() => {
    if (organizationData?.numberOfEmployees && organizationData?.numberOfEmployees > 0) {
      setLabelSelect();
    }
  }, []);

  useEffect(() => {
    !!registrationInfoId && !!collaboratorEmailParam && setOpenInviteCollaboratorModal(true);
    !!collaboratorEmailParam && setCollaboratorEmail(collaboratorEmailParam);
  }, [registrationInfoId, collaboratorEmailParam]);

  return (
    <div id="companyForm">
      <div className="titleFormAccount">{t(`${i18nLanguageKey}titleFormAccount`)}</div>
      <div className="profileSection">
        <div
          className="profileImg"
          style={{
            backgroundImage: `url(${temporalUrl || organizationData?.image || noPicture})`
          }}
        >
          {userIsAdmin && <EditIcon className="editIcon" />}
          <input className="inputPosition" disabled={!userIsAdmin} type={'file'} onChange={e => onChangeFile(e)} />
        </div>
        <div className="detailsImg">
          <div>
            <div className="titleImg">{t(`${i18nLanguageKey}titleImg`)}</div>
            <div className="subtitleImg">{t(`${i18nLanguageKey}subtitleImg`)}</div>
          </div>
        </div>
      </div>
      <div className="firstSection">
        <div className="spaceCompany mr-6">
          <div className="textInput">{t(`${i18nLanguageKey}companyName`)}</div>
          <NudosTextInput
            isDeactivated
            placeholder={t(`${i18nLanguageKey}companyName`)}
            defaultValue={organizationData?.name}
            isFilled={false}
          />
        </div>
        <div className="spaceCompany">
          <NudosSelectDropdown
            label={t(`${i18nLanguageKey}numberOfEmployees`)}
            selectOptions={numberOfEmployees}
            currentValueOrplaceholder={labelEmployees || t(`${i18nLanguageKey}numberOfEmployees`)}
            isFilled={true}
            isDeactivated={!userIsAdmin}
          />
        </div>
      </div>
      <div className="firstSection">
        <div className="spaceCompany mr-6">
          <div className="textInput">{t(`${i18nLanguageKey}businessName`)}</div>
          <NudosTextInput
            placeholder={t(`${i18nLanguageKey}businessName`)}
            defaultValue={organizationData?.businessName}
            isDeactivated
            isFilled={false}
          />
        </div>
        <div className="spaceCompany countryAndTaxCode">
          <NudosSelectDropdown
            selectClassName="countrySelect"
            label={t(`${i18nLanguageKey}country`)}
            selectOptions={[]}
            currentValueOrplaceholder={getCountryPlaceholder(orgData?.country?.code) || ''}
            isDeactivated
            isFilled={false}
          />
          <NudosTextInput
            customClassName="taxCode"
            label={t(`${i18nLanguageKey}taxIdentificationDocument`)}
            placeholder="RFC"
            defaultValue={organizationData?.document}
            isDeactivated
            isFilled={false}
          />
        </div>
      </div>
      {personalData?.role === 'admin' && (
        <section className="associatedUsersSection">
          <div className="titleFormAccount">{t(`${i18nLanguageKey}associatedUsersSection`)}</div>
          <div className="associatedUsersContainer">
            {organizationAssociatedUsers?.map((associatedUser, i) => {
              return (
                <NudosTextInput
                  key={`associatedUser${associatedUser?.id}${i}`}
                  customClassName="associatedUser"
                  defaultValue={associatedUser?.email || t(`${i18nLanguageKey}noData`)}
                  placeholder={associatedUser?.email || t(`${i18nLanguageKey}noData`)}
                  componentSize="small"
                  label={`${t(`${i18nLanguageKey}user`)} ${i + 1}`}
                  isFilled
                  isDeactivated
                />
              );
            })}
          </div>
          <NudosButton
            customClassName="inviteUserButton"
            buttonText={t(`${i18nLanguageKey}inviteUser`)}
            handleClick={() => setOpenInviteCollaboratorModal(true)}
            customWidth="128px"
            customHeight="18px"
            colorPalette="secondary"
            customFontStyles={{ fontSize: '10px', lineHeight: '10px', fontWeight: '400' }}
            buttonIcon={<EnclircledPlusIcon className="encircledPlusIcon" />}
          />
        </section>
      )}
      {openInviteCollaboratorModal && (
        <InviteCollaboratorModal
          modalTitle={t(`${i18nLanguageKey}inviteCollaborator:inviteModal`)}
          setOpenInviteCollaboratorModal={setOpenInviteCollaboratorModal}
          isAnInvitation={true}
          existingOrganizationData={{
            organizationId: orgData.organizationId,
            id: registrationInfoId ? +registrationInfoId : undefined,
            collaboratorEmail: collaboratorEmail,
            name: 'Nudos'
          }}
        />
      )}
    </div>
  );
};

export default CompanyForm;
