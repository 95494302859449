import { TnudosCare } from '../../../types/global';
import './NudosCareTag.scss';

const NudosCareTag = ({
  nudosCareType,
  customWidth,
  customHeight,
  customFontSize,
  customClass,
  freeBG,
  freeFont
}: InudosCareTag) => {
  const getNudosCareText = () => {
    if (nudosCareType === 'Free') return 'No Care';
    if (nudosCareType === 'Log') return 'Logistic Care';
    if (nudosCareType === 'Basic') return 'Basic Care';
    if (nudosCareType === 'Full') return 'Full Care';
    return 'No Care';
  };
  const getBackgroundColor = () => {
    if (nudosCareType === 'Free') return freeBG || '#E6E6E6';
    if (nudosCareType === 'Log') return '#DDFBBC';
    if (nudosCareType === 'Basic') return '#D6E9FF';
    if (nudosCareType === 'Full') return '#383838';
    return '#E6E6E6';
  };
  const getFontColor = () => {
    if (nudosCareType === 'Free') return freeFont || '#999999';
    if (nudosCareType === 'Log') return '#81BC3F';
    if (nudosCareType === 'Basic') return '#54A1FC';
    if (nudosCareType === 'Full') return '#FFFFFF';
    return '#999999';
  };

  const nudosCareTagStyles = {
    backgroundColor: getBackgroundColor(),
    color: getFontColor(),
    width: customWidth,
    height: customHeight,
    fontSize: customFontSize
  };

  return (
    <div className={`nudosCareTag ${customClass || ''}`} style={nudosCareTagStyles}>
      {getNudosCareText()}
    </div>
  );
};

export default NudosCareTag;

interface InudosCareTag {
  nudosCareType?: TnudosCare | null;
  customWidth?: string;
  customHeight?: string;
  customFontSize?: string;
  customClass?: string;
  freeBG?: string;
  freeFont?: string;
}
