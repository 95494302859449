import { IconBroom, IconInformation } from '../../../../assets/DesignSystem/SVGComponents';
import { LS_STATUS } from '../../../../types/logisticsServicesModule.types';
import { IadditionalServiceDataForToolLogistics } from '../../../../types/requestLogisticsModule';
import { formatShortDate } from '../../../../utils/formatDatesAndHours';
import NudosLinkTypeButton from '../../NudosLinkTypeButton/NudosLinkTypeButton';
import { getAdditionalServiceDisplayElements } from '../utils';
import './NudosWipingStatusHoverData.scss';

const NudosWipingStatusHoverData = ({ wipingData }: { wipingData?: IadditionalServiceDataForToolLogistics }) => {
  const { status } = wipingData || {};
  const { createdAt, statusName, imageUrl } = status || {};

  const haveStatus = !!status?.statusId;
  const lsWasCancelled = wipingData?.logisticServiceStatus === LS_STATUS.CANCELLED;
  const noDataText = 'Sin datos';
  const wipingDescription = 'Limpieza del hardware y restauración de software (reinstalación sistema operativo)';
  const displayData = wipingData ? getAdditionalServiceDisplayElements(wipingData) : undefined;
  const colorStyles = displayData?.color ? { color: displayData?.color } : undefined;

  const downloadEvidence = () => imageUrl && window.open(imageUrl, 'blank');

  return (
    <div className="nudosWipingStatusHoverData">
      <div className="descriptionSection">
        <div className="iconContainer">
          <IconBroom className="iconBroom" />
        </div>
        <div className="descriptionContainer">
          <div className="serviceName">Wiping</div>
          <div className="serviceDescription">{wipingDescription}</div>
        </div>
      </div>
      {haveStatus && !lsWasCancelled && (
        <div className="statusSection">
          <div className="statusField">
            <div className="statusTitle">Fecha</div>
            <div className="statusContent">{formatShortDate(createdAt || '')?.onlyDate || noDataText}</div>
          </div>
          <div className="statusField">
            <div className="statusTitle">Estado</div>
            <div className="statusContent wipingStatusName" style={colorStyles}>
              {statusName || noDataText}
            </div>
          </div>
          <div className="statusField">
            <div className="statusTitle">Evidencia</div>
            <NudosLinkTypeButton
              customClassname="statusContent evidenceLink"
              text="Consultar"
              isDisabled={!imageUrl}
              hideIcon
              handleClickCallback={downloadEvidence}
            />
          </div>
        </div>
      )}
      {(!haveStatus || lsWasCancelled) && (
        <div className="zeroState">
          <IconInformation className="iconInformation" />
          <div className="zeroStateText">Puedes solicitar este servicio en el siguiente paso</div>
        </div>
      )}
    </div>
  );
};

export default NudosWipingStatusHoverData;
