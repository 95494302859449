import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IassigneeInfo } from '../../../../../../../types/global';
import { formatShortDate } from '../../../../../../../utils/formatDatesAndHours';
import ManualAdjustmentSection from '../ManualAdjustmentSection/ManualAdjustmentSection';
import { IconNudosFace, IconReserveBox } from '../../../../../../../assets/DesignSystem/SVGComponents';
import './PreviousLocationCard.scss';

interface IpreviousLocationCard {
  data: IassigneeInfo;
  updateDataCallback?: () => void;
  place?: string;
  locationName?: string | null;
  orderId?: number;
}
const PreviousLocationCard: FC<IpreviousLocationCard> = ({
  data,
  updateDataCallback,
  place,
  locationName,
  orderId
}: IpreviousLocationCard) => {
  const { t } = useTranslation();
  const { country, exitDate, entryDate, logisticServiceId } = data || {};
  const displayBuyAndHold = !!data?.isBuyAndHold;
  const { id: productId } = useParams<{ id: string }>();

  const toolTimeZone = country?.timeZone || undefined;
  const getCurrentHoldersName = () => {
    const i18nLanguageKeyHoldersName = 'nodi:toolsLogisticsOriginAndDestinationTag:';
    switch (place) {
      case 'nudos':
        return t(`${i18nLanguageKeyHoldersName}nudosWarehouse`);
      case 'office':
        return t(`${i18nLanguageKeyHoldersName}offices`);
      case 'other':
      case 'start':
      case 'ownLocation':
        return locationName || t(`${i18nLanguageKeyHoldersName}other`);
      case 'warranty':
        return t(`${i18nLanguageKeyHoldersName}warranty`);
      case 'diagnostic':
        return t(`${i18nLanguageKeyHoldersName}diagnostic`);
      case 'store':
        return 'Tienda Nudos';

      default:
        return t(`${i18nLanguageKeyHoldersName}unassigned`);
    }
  };

  const redirectionDataObject = {
    pathname: `/nodi/logistics-services/details/${logisticServiceId}`,
    state: { returnText: 'Herramienta' }
  };

  const seeLogisticServiceButton = logisticServiceId ? (
    <Link to={redirectionDataObject} className="seeLogisticLink">
      Ver servicio
    </Link>
  ) : null;

  const seeOrderDetailsButton = orderId ? (
    <Link to={`/nodi/orders/details/${orderId}?from=tool-details&id=${productId}`} className="seeLogisticLink">
      Ver Orden
    </Link>
  ) : null;

  return (
    <div className="previousLocationCard">
      <div className="imageSection">
        <div className="iconContainer">
          {place === 'store' ? (
            <IconNudosFace style={{ transform: 'scale(2)' }} />
          ) : (
            <IconReserveBox className="IconReserveBox" />
          )}
          <div className="flagContainer" style={{ backgroundImage: `url(${country?.flag || ''})` }} />
        </div>
      </div>
      <div className="locationAndAddressSection">
        <div className="location">{getCurrentHoldersName()}</div>
        {seeLogisticServiceButton}
        {!logisticServiceId && !!orderId && seeOrderDetailsButton}
      </div>
      <div className="managementDatesSection">
        <div>
          {exitDate && (
            <div className="firstDate">
              <span>{t('nodi:toolInternal:containerDates:firstDate')}</span>
              <span className="font-medium">{formatShortDate(exitDate, toolTimeZone)?.onlyDate}</span>
            </div>
          )}
          {place !== 'store' && (
            <div className="secondDate">
              <span>{t('nodi:toolInternal:containerDates:secondDate')}</span>
              <span className="font-medium">{formatShortDate(entryDate || '', toolTimeZone)?.onlyDate}</span>
            </div>
          )}
          {displayBuyAndHold && <div className="buyAndHold">Buy and hold</div>}
        </div>
      </div>
      <ManualAdjustmentSection assignee={data} updateDataCallback={updateDataCallback} />
    </div>
  );
};

export default PreviousLocationCard;
