import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11} height={11} fill="none" ref={ref} {...props}>
    <g>
      <path
        fill="#fff"
        stroke="#383838"
        strokeLinejoin="round"
        d="M4.944 1.62C5.266.84 6.346.815 6.67 1.594c.224.54.395 1.095.567 1.821.762-.007 1.357.009 1.957.063.845.076 1.186 1.103.528 1.638-.371.303-.77.583-1.27.897a.364.364 0 0 0-.154.42c.234.723.391 1.31.514 1.927.163.82-.7 1.445-1.402.991-.538-.347-1.027-.748-1.629-1.32-.596.55-1.082.939-1.614 1.285-.706.459-1.588-.16-1.424-.986a14.89 14.89 0 0 1 .516-1.894.364.364 0 0 0-.152-.425 12.04 12.04 0 0 1-1.31-.923c-.646-.532-.316-1.545.518-1.622.61-.057 1.22-.069 2.01-.05a17.42 17.42 0 0 1 .619-1.796Z"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconStar = memo(ForwardRef);
export default IconStar;
