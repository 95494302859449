import { FC, useEffect, useState } from 'react';
import { WarningIcon } from '../../../../../assets/images/views/Admin/successLoad';
import { ICountryDetail } from '../../../../../types/countries';
import { IEmployeeDetail } from '../../../../../types/employes';
import './UploadedEmployeCard.scss';

interface IEmployeeCard {
  data: IEmployeeDetail;
  infoCountriesList?: ICountryDetail[];
}

interface IErrors {
  emailError?: boolean;
  phoneError?: boolean;
  areaError?: boolean;
}

const UploadedEmployeCard: FC<IEmployeeCard> = ({ data, infoCountriesList }: IEmployeeCard) => {
  const [errors, setErrors] = useState<IErrors>();
  const shortNumber = (countryName: string) => {
    if (infoCountriesList) {
      const myCoutry = infoCountriesList.find(item => item.name === countryName);
      return { flag: myCoutry?.flag, phoneCode: myCoutry?.phoneCode };
    } else return { flag: undefined, phoneCode: undefined };
  };

  const noItem = () => {
    return (
      <div className="noItem">
        <WarningIcon />
      </div>
    );
  };

  const findPhoneError = () => {
    const error = data.Error.find(
      element => element.includes('invalidPhone') || element.includes('phoneAlreadyRegistered')
    );
    if (error) {
      return error;
    }
    return null;
  };

  const findEmailError = () => {
    const error = data.Error.find(
      element => element.includes('emailAlreadyRegistered') || element.includes('invalidEmail')
    );
    if (error) {
      return error;
    }
    return null;
  };

  const findAreaError = () => {
    const error = data.Error.find(element => element.includes('area'));
    return error || null;
  };

  const employeeErrors = () => {
    if (data?.Error && Array.isArray(data?.Error) && data?.Error.length > 0) {
      const phoneError = !!findPhoneError();
      const emailError = !!findEmailError();
      const areaError = !!findAreaError();
      setErrors({ emailError, phoneError, areaError });
    }
  };

  useEffect(() => {
    employeeErrors();
  }, []);

  return (
    <div id="uploadedEmployeCard">
      <div className="generalList nameList">
        {data?.Nombre ? <div className="boldText">{data?.Nombre}</div> : noItem()}
      </div>
      <div className="generalList lastNameList">
        {data?.Apellido ? <div className="boldText">{data?.Apellido}</div> : noItem()}
      </div>
      <div className="generalList emailList">
        {data?.Correo ? (
          <div className="simpleText" style={{ color: errors?.emailError ? '#FF0000' : '' }}>
            {data?.Correo}
          </div>
        ) : (
          noItem()
        )}
      </div>
      <div className="generalList ladaList">
        {data?.['País del teléfono'] ? (
          <>
            <div className="containerNumber">{shortNumber(String(data?.['País del teléfono'] || '')).phoneCode}</div>
            <div className="containerFlag">
              <div className="myFlag">
                {data?.['País del teléfono'] && (
                  <img src={shortNumber(data?.['País del teléfono'])?.flag || undefined} />
                )}
              </div>
            </div>
          </>
        ) : (
          noItem()
        )}
      </div>
      <div className="generalList phoneList">
        {data.Celular ? (
          <div className="simpleText" style={{ color: errors?.phoneError ? '#FF0000' : '' }}>
            {data.Celular}
          </div>
        ) : (
          noItem()
        )}
      </div>
      <div className="generalList areaList">
        {data?.Area ? (
          <div className="simpleText" style={{ color: errors?.areaError ? '#FF0000' : '' }}>
            {data.Area}
          </div>
        ) : (
          noItem()
        )}
      </div>
      <div className="generalList positionList">
        {data?.Cargo ? <div className="simpleText">{data.Cargo}</div> : noItem()}
      </div>
      <div className="generalList countryList">
        {data?.País ? <div className="simpleText">{data.País}</div> : noItem()}
      </div>
      <div className="generalList cityList">
        {data?.Ciudad ? <div className="simpleText">{data.Ciudad}</div> : noItem()}
      </div>
    </div>
  );
};

export default UploadedEmployeCard;
