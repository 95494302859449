import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IconArrow,
  IconBuilding,
  IconEmployee,
  IconPin,
  IconReserveBox,
  IconShield,
  IconToolWrench
} from '../../../../../assets/DesignSystem/SVGComponents';
import { InudoInfo, LOCATION_PLACE_TYPE } from '../../../../../types/global';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import NudosRedirectionIcon from '../../../../../components/DesignSystem/NudosRedirectionIcon/NudosRedirectionIcon';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import './ActiveLogisticsServiceCard.scss';

interface IActiveLogisticsServiceCard {
  dataLS?: InudoInfo;
  generalLoading?: boolean;
}

const ActiveLogisticsServiceCard: FC<IActiveLogisticsServiceCard> = ({ dataLS }: IActiveLogisticsServiceCard) => {
  const { push } = useHistory();

  const lastAssociatedLocation = dataLS?.oldAssignment
    ? dataLS?.oldAssignment?.find(location => !location.exitDate) || dataLS?.oldAssignment[0]
    : undefined;
  const lastAssigneeUserId = lastAssociatedLocation?.userId;
  const originLocationIsUser = dataLS?.place === LOCATION_PLACE_TYPE.USER;
  const lastLocationEntryDate = formatShortDate(lastAssociatedLocation?.entryDate || '')?.onlyDate || '-';
  const displayBuyAndHoldTag = !!dataLS?.isBuyAndHold;

  const generateOriginData = (place?: TstoragePlace) => {
    switch (place) {
      case 'user':
        return {
          icon: <IconEmployee style={{ transform: 'scale(.75)' }} />,
          profileImg: dataLS?.oldAssignment?.[0]?.photoProfile,
          name: dataLS?.locationUserName,
          dateTitle: 'Asignado: '
        };
      case 'other':
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />,
          name: dataLS?.locationName || 'Otra dirección'
        };
      case 'office':
        return {
          icon: <IconBuilding style={{ transform: 'scale(0.8)' }} />,
          name: `Oficinas ${dataLS?.country?.name}`
        };
      case 'nudos':
        return {
          icon: <IconReserveBox />,
          name: 'Bodegas Nudos'
        };
      case 'warranty':
        return {
          icon: <IconShield style={{ transform: 'scale(1.13)' }} />,
          name: 'Garantia con la marca'
        };
      case 'diagnostic':
        return {
          icon: <IconToolWrench style={{ transform: 'scale(1.13)' }} />,
          name: 'Diagnóstico'
        };
      default:
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />,
          name: dataLS?.country?.name
        };
    }
  };

  const generateDestinationData = (place?: TstoragePlace) => {
    switch (place) {
      case 'user':
        return {
          profileImg: dataLS?.currentAssignment?.[0]?.photoProfile,
          icon: <IconEmployee style={{ transform: 'scale(.75)' }} />,
          name: dataLS?.destination?.locationUserName,
          details: dataLS?.currentAssignment?.[0]?.email || ''
        };
      case 'nudos':
        return {
          icon: <IconReserveBox />,
          name: 'Bodegas Nudos'
        };
      case 'office':
        return {
          icon: <IconBuilding style={{ transform: 'scale(0.8)' }} />,
          name: `Oficinas ${dataLS?.destination?.country?.name}`,
          details: dataLS?.destination?.address
        };
      case 'warranty':
        return {
          icon: <IconShield style={{ transform: 'scale(1.13)' }} />,
          name: 'Garantia con la marca'
        };
      case 'diagnostic':
        return {
          icon: <IconToolWrench style={{ transform: 'scale(1.13)' }} />,
          name: 'Diagnóstico'
        };
      default:
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />
        };
    }
  };

  const originData = generateOriginData(dataLS?.place);

  const getRedirectToEmployeeDetails = (userId?: string | number) => {
    if (!userId) return {};
    return {
      pathname: `/nodi/employees/details/${userId}`,
      state: {
        originUrl: `/nodi/details/${dataLS?.productId}`,
        returnText: 'Herramienta'
      }
    };
  };

  const redirectToOriginEmployee = getRedirectToEmployeeDetails(lastAssigneeUserId);
  const redirectToDestinationEmployee = getRedirectToEmployeeDetails(dataLS?.userId || undefined);

  return (
    <div id="activeLogisticsServiceCard">
      <div className="titleSL">Asignado / ubicado:</div>
      <div className="nameSL">
        <div className="title">Servicio logístico</div>
        <div className="dinamicContainer">
          <div className="blackText">{dataLS?.logisticStatus}</div>
          <div className="spaceGray">&nbsp;-&nbsp;</div>
          <div
            className="blueText"
            onClick={() => {
              push(`/nodi/logistics-services/details/${dataLS?.logisticServiceId}?from=tools&id=${dataLS?.productId}`);
            }}
          >
            Ver
          </div>
        </div>
      </div>
      <div className="containerActiveLogisticsServiceCard">
        <div className="boxActiveLogisticsServiceCard">
          <div className="originContainerIcon">
            <div className="originIcon">
              {dataLS?.place === 'user' && originData.profileImg ? (
                <div
                  className="photoStyle"
                  style={{
                    backgroundImage: `url("${originData?.profileImg?.replace(/\s/g, '%20')}")`
                  }}
                />
              ) : (
                <>{originData.icon}</>
              )}
              <div className="absoluteMinFlag" style={{ backgroundImage: `url("${dataLS?.country?.flag}")` }}></div>
            </div>
          </div>

          <div className="originContainerPlace">
            <div className="generalPlace">
              <div className="titleContainer">
                <div className="originTitle">{originData.name}</div>
                {lastAssigneeUserId && originLocationIsUser && (
                  <NudosRedirectionIcon redirectionDataObject={redirectToOriginEmployee} />
                )}
              </div>
              <div className="movementDates">
                <span className="dateTitle">{originData?.dateTitle || 'Ingreso: '}</span>
                <span className="date">{lastLocationEntryDate}</span>
              </div>
            </div>
          </div>
          <div className="arrowContainer">
            <IconArrow style={{ transform: 'scale(1.46)' }} />
          </div>
          <div className="destinationContainerIcon">
            <div className="destinationIcon">
              {dataLS?.destination.place === 'user' && generateDestinationData(dataLS?.destination.place).profileImg ? (
                <div
                  className="photoStyle"
                  style={{
                    backgroundImage: `url("${generateDestinationData(dataLS?.destination?.place)?.profileImg?.replace(
                      /\s/g,
                      '%20'
                    )}")`
                  }}
                />
              ) : (
                <>{generateDestinationData(dataLS?.destination?.place || '')?.icon}</>
              )}
              <div
                className="absoluteMinFlag"
                style={{ backgroundImage: `url("${dataLS?.destination?.country?.flag}")` }}
              />
            </div>
          </div>
          <div className="destinationContainerPlace">
            <div className="generalPlace">
              <div className="titleContainer">
                <div className="originTitle">{generateDestinationData(dataLS?.destination?.place || '').name}</div>
                {dataLS?.destination.place === 'user' && (
                  <NudosRedirectionIcon redirectionDataObject={redirectToDestinationEmployee} />
                )}
              </div>
              {generateDestinationData(dataLS?.destination?.place || '').details && (
                <div className="originSubtitle">
                  {generateDestinationData(dataLS?.destination?.place || '').details}
                </div>
              )}
            </div>
          </div>
          {displayBuyAndHoldTag && <div className="buyAndHold">Buy and Hold</div>}
        </div>
      </div>
    </div>
  );
};
export default ActiveLogisticsServiceCard;
