import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BodyAlert, NewLogisticsVerifyData } from '../../../Components';
import {
  IlocationAddressForLogistics,
  TlogisticsOriginOrDestinationData,
  IOutRange,
  IPrincipalBodyAlert
} from '../../../../../types/requestLogisticsModule';
import { Iemployee } from '../../../../../types/global';
import { ItoolDataForRequiringLogistics, TstoragePlace } from '../../../../../types/assignationFluxes';
import useRequireLogisticsSummaryData from '../../../../../state/useRequireLogisticsSummaryData';
import { useTranslation } from 'react-i18next';
import './AssignmentModuleStep5DataVerification.scss';

const AssignmentModuleStep5DataVerification: FC<IAssignmentModuleStep5DataVerification> = ({
  originData,
  destinationData,
  dateRange,
  toolsToAssignData,
  selectedOrigin,
  type,
  emailToAssignee
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { setEstimatedCollection, setSuperiorAlert, setInferiorAlert } = useRequireLogisticsSummaryData();
  const [timeAlertMessage, setTimeAlertMessage] = useState<string | JSX.Element>('');
  const [alertMessage, setAlertMessage] = useState<IPrincipalBodyAlert>();

  //const assignmentSummaryText = t('services:logistics:endStep:assignmentSummary');

  const searchParams = new URLSearchParams(search);
  const requireLogisticsJSON = searchParams?.get('logistics');
  const requireLogistics = requireLogisticsJSON === 'false' ? false : true;
  const deliveryName = `${destinationData?.firstName || ''} ${destinationData?.lastName}`;

  const logisticsServiceSummaryTitle = requireLogistics
    ? 'Revisa los datos del servicio para proceder a cotizar'
    : t('services:logistics:endStep:assignmentSummary');

  const getCollectionName = () => {
    switch (selectedOrigin) {
      case 'diagnostic':
      case 'nudos':
      case 'warranty':
        return 'Bodegas Nudos';
      case 'office':
        return `Oficinas ${originData?.country?.name || ''}`;
      case 'other':
        return (originData as IlocationAddressForLogistics)?.locationName || 'Otra dirección';
      case 'start':
        return (originData as IlocationAddressForLogistics)?.city || 'Sin asignar ';
      default:
        return 'Sin asignar ';
    }
  };

  const formatDateRange = () => {
    if (dateRange) {
      const startDate = dateRange.startDate;
      const formatStartDay = startDate.getDate();
      const startDay = formatStartDay <= 9 ? `0${formatStartDay}` : formatStartDay;
      const formatStartMonth = startDate.getMonth() + 1;
      const startMonth = formatStartMonth <= 9 ? `0${formatStartMonth}` : formatStartMonth;
      const startYear = startDate.getFullYear().toString();
      const endDate = dateRange.endDate;
      const formatEndDay = endDate.getDate();
      const endDay = formatEndDay <= 9 ? `0${formatEndDay}` : formatEndDay;
      const formatEndMonth = endDate.getMonth() + 1;
      const endMonth = formatEndMonth <= 9 ? `0${formatEndMonth}` : formatEndMonth;
      const endYear = endDate.getFullYear().toString();
      const formatDate = `${startDay}/${startMonth}/${startYear.substring(
        2,
        4
      )} - ${endDay}/${endMonth}/${endYear.substring(2, 4)}`;
      return formatDate;
    }
  };

  const setAlertText = () => {
    if (selectedOrigin === 'diagnostic' || selectedOrigin === 'nudos' || selectedOrigin === 'warranty') {
      const alertComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            {
              titleList: 'El empleado que recibe las herramientas debe:',
              subList: [
                'Completar un formulario especificando los elementos recibidos.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            }
          ]
        }
      };
      setAlertMessage(alertComponent);
      return setInferiorAlert(alertComponent);
    }
    if (type === 'locale') {
      const alertComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            { titleList: 'Las herramientas deben ser embaladas en cajas selladas.' },
            { titleList: 'Las cajas no serán manipuladas ni abiertas durante el servicio.' },
            {
              titleList: 'Quienes entregan y reciben las herramientas deben:',
              subList: [
                'Completar un formulario especificando los elementos entregados y recibidos.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            }
          ]
        }
      };
      setAlertMessage(alertComponent);
      return setInferiorAlert(alertComponent);
    }
    if (type === 'international') {
      const alertComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            { titleList: 'Las herramientas deben ser embaladas en cajas selladas.' },
            { titleList: 'Por razones de seguridad, se inspeccionan las cajas antes de enviarlas al país de destino.' },
            {
              titleList: 'Quienes entregan y reciben las herramientas deben:',
              subList: [
                'Completar un formulario especificando los elementos entregados y recibidos.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            }
          ]
        }
      };
      setAlertMessage(alertComponent);
      return setInferiorAlert(alertComponent);
    }
  };

  const setSuperiorAlertText = () => {
    const warehouseText =
      'Los tiempos dentro del servicio son estimados, podrás ver los tiempos reales una vez se active el servicio. ';
    const otherLocationsText = (
      <div className="assignmentSummaryTimeAlert">
        <div>
          Los tiempos dentro del servicio son estimados, podrás ver los tiempos reales una vez se active el servicio.
        </div>
        <div>
          Estaremos en contacto con el punto de recolección días antes para asegurarnos que llegue(n) la herramienta(s)
          en las fechas solicitadas.
        </div>
      </div>
    );
    switch (selectedOrigin) {
      case 'nudos':
      case 'diagnostic':
      case 'warranty':
        setTimeAlertMessage(warehouseText);
        return setSuperiorAlert(warehouseText);
      default:
        setTimeAlertMessage(otherLocationsText);
        setSuperiorAlert(otherLocationsText);
    }
  };

  useEffect(() => {
    setSuperiorAlertText();
    setAlertText();
  }, []);

  useEffect(() => {
    setEstimatedCollection(formatDateRange());
  }, []);

  return (
    <div className="AssignmentModuleStep5DataVerification">
      <NewLogisticsVerifyData
        type="assignment"
        tools={toolsToAssignData || []}
        collectionName={getCollectionName()}
        selectedDatesRange={formatDateRange()}
        deliveryName={deliveryName}
        customContainerTitle={logisticsServiceSummaryTitle}
        bodyAlertComponent={alertMessage && <BodyAlert data={alertMessage.data} />}
        timeAlertText={timeAlertMessage}
        emailToAssignee={emailToAssignee}
      />
    </div>
  );
};

export default AssignmentModuleStep5DataVerification;

interface IAssignmentModuleStep5DataVerification {
  originData?: TlogisticsOriginOrDestinationData;
  destinationData?: Iemployee;
  dateRange?: IOutRange;
  toolsToAssignData?: ItoolDataForRequiringLogistics[];
  estimatedDelivery?: string;
  selectedOrigin?: TstoragePlace;
  type?: 'locale' | 'international';
  emailToAssignee?: string;
  logisticsEstimatedTimeByCountry?: string;
}
