import { FC, useState } from 'react';
import { NudosLogoM } from '../../assets/images';
import { useUserAuth } from '../../state/userAuthContext';
import Loading from '../NudosComponents/Loading';

interface IModal {
  setShowModal: (statusModal: boolean) => void;
}

const ModalPassWord: FC<IModal> = ({ setShowModal }: IModal) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { logOut } = useUserAuth();

  const closeSession = () => {
    if (logOut) {
      logOut();
      setShowModal(false);
      setLoading(false);
    }
  };

  return (
    <div id="modalPassword">
      <div className="layoutModal">
        <div className="modalContainer">
          <div className="titleModal">
            <div className="logoNudos">
              <NudosLogoM fill="#ff664e" />
            </div>
            Inicio de sesión
          </div>
          <div className="subtitleModal">
            Para mejorar la experiencia hemos cambiado nuestro inicio de
            <br /> sesión con correo y contraseña.
          </div>
          <div className="listModal">
            <div className="titleList">Para crear tu contraseña de acceso sigue los siguientes pasos:</div>
            <div className="textLi">
              <div className="circleCont">
                <div className="littleCircle" />
              </div>
              Cierra sesión
            </div>
            <div className="textLi">
              <div className="circleCont">
                <div className="littleCircle" />
              </div>
              En la pantalla de ingreso escribe tu correo
            </div>
            <div className="textLi">
              <div className="circleCont">
                <div className="littleCircle" />
              </div>
              Da click a recuperar contraseña{' '}
            </div>
            <div className="orangeAlert">¡Y nuevamente ingresa a tu cuenta!</div>
          </div>

          <div className="containerButton">
            <button
              onClick={() => {
                closeSession();
              }}
              className="savePassword"
            >
              {!loading ? 'Cerrar sesión' : <Loading />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPassWord;
