import { useCallback, useEffect, useRef, useState } from 'react';
import MoreInfoIcon from '../../../assets/images/components/MoreInfoIcon';
import { BlackPopoverIcon } from '../../../assets/images/components/NudosComponents';
import { IElement } from '../NudosGenericModal/NudosGenericModal';
import IconTooltipQuestionMark from '../../../assets/DesignSystem/SVGComponents/IconTooltipQuestionMark';
import './NudosToolTip.scss';

export interface InudosToolTipProps {
  tooltipContent?: JSX.Element;
  tooltipContentCustomStyles?: { [key: string]: string };
  children?: React.ReactNode;
  type?: TnudosTooltipTypes;
  shouldStayOpen?: boolean;
  clickIconHandler?: () => void;
  clickAwayCallback?: () => void;
  position?: 'left' | 'right';
}

/**
 * IMPORTANT: for the usual tooltip content use the tooltipContent prop as well as the tooltipContentCustomStyles. If the desired tooltip content is to be customized use the children property instead and styles in the target scss file.
 * @property {JSX.Element} tooltipContent - A JSX.Element to be displayed as the tooltipContent. Usualy a fragment with text content eg <>Just an <strong>Example</strong></strong></>
 * @property {[key: string]: string} tooltipContentCustomStyles = An object with valid styles for the tooltip content
 * @property {React.ReactNode} children - A react node with the actual content to display once the toolTip is active, the styles to this component must be managed for each case
 * @property {TnudosTooltipTypes} type - Optional, the type of toolTip, corresponds to the icon that will be shown. Default case is the info toolTip
 * @property {boolean} shouldStayOpen - Optional, a boolean indicating that the content of the modal toolTip must remain open and preventing it from closing on mouseLeave
 * @property {() => void} clickIconHandler - Optional, a callback to execute upon clicking the tooltip icon
 * @property {() => void} clickAwayCallback - Optional,  a callback to execute upon clicking away from the component
 * @property { 'left' | 'right'} position - Optional, the position for the tooltipContent. DEFAULT is left
 */
const NudosToolTip = (props: InudosToolTipProps) => {
  const { tooltipContent, tooltipContentCustomStyles } = props;
  const { children, type, shouldStayOpen, clickIconHandler, clickAwayCallback, position } = props;
  const toolTipReference = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const activeStyles = showTooltip ? 'active' : '';
  const positionRight = position === 'right' ? 'positionRight' : '';

  const handleMouseEnter = () => !showTooltip && setShowTooltip(true);
  const handleMouseLeave = () => showTooltip && !shouldStayOpen && setShowTooltip(false);

  const handleClickAway = useCallback(
    (e: IElement) => {
      if (toolTipReference.current && !toolTipReference.current.contains(e.target)) {
        setShowTooltip(false);
        clickAwayCallback && clickAwayCallback();
      }
    },
    [toolTipReference]
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleClickAway);

    () => window.removeEventListener('mousedown', handleClickAway);
  }, [handleClickAway]);

  return (
    <div className={`nudosToolTip ${activeStyles}`} ref={toolTipReference}>
      {(type === 'info' || !type) && (
        <MoreInfoIcon
          className="tooltipIcon moreInfoIcon"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={clickIconHandler ? clickIconHandler : undefined}
        />
      )}
      {type === 'question' && (
        <IconTooltipQuestionMark
          className="tooltipIcon"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={clickIconHandler ? clickIconHandler : undefined}
        />
      )}
      {type === 'popover' && (
        <BlackPopoverIcon
          className="blackPopoverIcon"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={clickIconHandler ? clickIconHandler : undefined}
        />
      )}
      {showTooltip && tooltipContent && (
        <div className={`genericToolTipStyles ${positionRight}`} style={tooltipContentCustomStyles}>
          {tooltipContent}
        </div>
      )}
      {showTooltip && children}
    </div>
  );
};

export default NudosToolTip;

type TnudosTooltipTypes = 'info' | 'popover' | 'question';
