import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStore from '../../../../../../state';
import AssignedToolCard from '../AssignedToolCard';
import { IassignedTool, Iemployee, Tany } from '../../../../../../types/global';
import { EmployeeCartIcon, laptopIcon } from '../../../../../../assets/images/views/Admin/employees';
import { ReturnButton } from '../../../../../../assets/images/components/NudosComponents';
import { NudosButton, NudosToolTip } from '../../../../../../components/NudosComponents';
import EnclircledPlusIcon from '../../../../../../assets/images/components/EnclircledPlusIcon';
import EnclircledMinusIcon from '../../../../../../assets/images/components/EnclircledMinusIcon';
import { DeleteSquare } from '../../../../../../assets/images/views/Admin/main';
import UnassignToolsFromEmployeeModal from '../UnassignToolsFromEmployeeModal';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { ICountryDetail } from '../../../../../../types/countries';
import { useTranslation, Trans } from 'react-i18next';
import AssignToolsModal from '../../../Components/assignToolsModal';
import ToolsInTransit from '../ToolsInTransit/ToolsInTransit';
import { formatOrgData } from '../../../../../../utils/orgFormatData';

import './EmployeeDetailsBody.scss';

const EmployeeDetailsBody = ({
  employee,
  updateFile,
  loaderFileCardId,
  listOfCountriesWithLogistics
}: {
  employee: Iemployee;
  updateFile?: (assignmentId: number, file: Tany) => void;
  loaderFileCardId?: number;
  listOfCountriesWithLogistics?: ICountryDetail[];
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { setEmployeeToAssign } = useStore();
  const formatOrgInfo = formatOrgData();

  const [showEquipementHistory, setShowEquipementHistory] = useState(false);
  const [isPossibleToUnassign, setIsPossibleToUnassign] = useState(false);
  const [toolsToUnassign, setToolsToUnassign] = useState<IassignedTool[]>([]);
  const [showUnassignModal, setShowUnassignModal] = useState(false);
  const [showAssignToolsModal, setShowAssignToolsModal] = useState<boolean>(false);
  const employeeDetailLenguageText = 'nodi:employeeInternal:';

  //blockedCompany 332
  const blockMarketPlace = formatOrgInfo?.organizationId === 332;

  const employeeToAssignData = {
    id: employee?.id,
    organizationId: employee?.organizationId,
    userId: employee?.userId,
    firstName: employee?.firstName,
    lastName: employee?.lastName,
    city: employee?.city,
    area: employee?.area,
    position: employee?.position,
    country: employee?.country,
    address: employee?.address
  };

  const handleEquipWithoutLogistics = () => {
    setEmployeeToAssign(employeeToAssignData);
    segmentTrackEvent({
      employeesDetailAssignClick: {
        EmployeeName: `${employee?.firstName} ${employee?.lastName}`,
        EmployeeCountry: employee?.country?.name || 'Otro'
      }
    });
    if (!employee?.userId) return;
    push(
      `/nodi/request-logistics/assignment/${employee?.userId}?logistics=false&origin=employee-details${
        employee?.userId ? `&originId=${employee?.userId}` : ''
      }`
    );
  };

  const currentEquipment = [...(employee?.assignedTools || [])].filter(tool => {
    return (
      !tool.assignedUntil && !(tool?.logisticStatus && !['cancelado', 'entregado'].includes(tool?.logisticStatus || ''))
    );
  });
  const equipmentHistory = [...(employee?.assignedTools || [])].filter(tool => {
    return (
      !!tool.assignedUntil &&
      !(tool?.logisticStatus && !['cancelado', 'entregado'].includes(tool?.logisticStatus || ''))
    );
  });

  const noCurrentEquipmentStyles =
    !currentEquipment || currentEquipment.length <= 0 ? { paddingTop: '66px' } : undefined;

  const inactiveAssignButtonStyles = currentEquipment?.length > 0 ? '' : 'inactive';
  const inactiveEmployeeStyles = employee?.active ? '' : 'inactive';

  const isProceedToUnassignButtonDisabled = !(toolsToUnassign?.length > 0);
  const handleClickRevertUnassignPossibility = () => {
    setIsPossibleToUnassign(false);
    setToolsToUnassign([]);
  };
  const handleProceedToUnassign = () => setShowUnassignModal(true);

  const toolsDataToUnassign = toolsToUnassign.map(tool => {
    return {
      sku: tool?.sku,
      productId: +tool?.productId,
      productName: tool?.productName,
      productBrand: tool?.brand,
      serial: tool?.serial || ''
    };
  });

  const logisticsCountryData =
    employee?.country?.id && employee?.country?.name && employee?.country?.flag && employee?.country?.code
      ? {
          id: employee.country.id,
          name: employee.country.name,
          flag: employee.country.flag,
          code: employee.country.code
        }
      : undefined;

  const handleClickAssignTool = () => {
    const isEmployeeInACountryWithLogistics =
      employee?.country?.code &&
      listOfCountriesWithLogistics?.some(countryWithLogistics => countryWithLogistics.code === employee?.country?.code);
    segmentTrackEvent({
      employeesDetailAssignClick: {
        EmployeeCountry: employee?.country?.name || 'Otro',
        EmployeeName: `${employee?.firstName} ${employee?.lastName}`
      }
    });
    if (isEmployeeInACountryWithLogistics) return setShowAssignToolsModal(!showAssignToolsModal);

    return handleEquipWithoutLogistics();
  };

  return (
    <div id="employeeDetailsBody">
      <ToolsInTransit
        toolsInTransit={employee?.toolsInTransit || []}
        updateFileCallback={updateFile}
        loaderFileCardId={loaderFileCardId}
      />
      <div className="titleAndEquipButton">
        <div className="title">{t(`${employeeDetailLenguageText}employeeDetailsBody:title`)}</div>
        <div className="buttonsContainer">
          <div className="unassignButtonContainer">
            <NudosButton
              buttonText={t(`${employeeDetailLenguageText}employeeDetailsBody:unassignButton`)}
              customClassName={`assignmentButton ${inactiveAssignButtonStyles}`}
              buttonIcon={
                <div className="plusMinusButton minusButton">
                  <div className="sign">-</div>
                </div>
              }
              handleClick={() => {
                segmentTrackEvent({
                  employeesDetailUnassignClick: {
                    EmployeeCountry: employee?.country?.name || 'Otro',
                    EmployeeName: `${employee?.firstName} ${employee?.lastName}`
                  }
                });
                setIsPossibleToUnassign(true);
              }}
              isButtonDisabled={currentEquipment?.length <= 0}
            />
          </div>

          {!blockMarketPlace && (
            <div className="assignButtonContainer ">
              <NudosButton
                colorPalette="secondary"
                isButtonDisabled={!employee?.active}
                buttonText={t(`${employeeDetailLenguageText}employeeDetailsBody:buyinMarketPlace`)}
                customClassName={`assignmentButton ${inactiveEmployeeStyles}`}
                handleClick={() => {
                  push('/catalogue/home');
                }}
                buttonIcon={<EmployeeCartIcon />}
              />
            </div>
          )}

          <div className="assignButtonContainer" style={{ borderLeft: '0px', paddingLeft: '0px' }}>
            <NudosButton
              colorPalette="secondary"
              isButtonDisabled={!employee?.active}
              buttonText={t(`${employeeDetailLenguageText}employeeDetailsBody:assignButton:buttonText`)}
              customClassName={`assignmentButton ${inactiveEmployeeStyles}`}
              handleClick={handleClickAssignTool}
              buttonIcon={
                <div className="plusMinusButton plusButton">
                  <div className="sign">+</div>
                </div>
              }
            />
          </div>
          <NudosToolTip>
            <div className="tooltip">
              <div className="assignText">
                <Trans
                  i18nKey={t(`${employeeDetailLenguageText}employeeDetailsBody:nudosToolTip:assignText`)}
                  components={{ 1: <strong />, 2: <EnclircledPlusIcon className="signContainer" /> }}
                />
              </div>
              <div className="unassignText">
                <Trans
                  i18nKey={t(`${employeeDetailLenguageText}employeeDetailsBody:nudosToolTip:unassignText`)}
                  components={{ 1: <strong />, 2: <EnclircledMinusIcon className="signContainer" /> }}
                />
              </div>
            </div>
          </NudosToolTip>
        </div>
      </div>
      <div className="employeeDetailsBodyContent">
        <div className="currentEquipment" style={noCurrentEquipmentStyles}>
          {currentEquipment && currentEquipment?.length > 0 && (
            <div className="cardsContainer">
              {currentEquipment &&
                currentEquipment.length > 0 &&
                currentEquipment.map((tool, i) => {
                  return (
                    <AssignedToolCard
                      key={`assignedTool${i}`}
                      assignedTool={tool}
                      toolsToUnassign={toolsToUnassign}
                      setToolsToUnassign={setToolsToUnassign}
                      isPossibleToUnassign={isPossibleToUnassign}
                      updateFile={updateFile}
                      loaderFileCardId={loaderFileCardId}
                    />
                  );
                })}
            </div>
          )}
          {(!currentEquipment || currentEquipment.length <= 0) && (
            <div className="noAssignedTools">
              <div className="items">
                <div className="iconContainer">
                  <img className="limitsImgLaptop" src={laptopIcon} alt="Icono laptop" />
                </div>
                <div className="noToolText">{t(`${employeeDetailLenguageText}employeeDetailsBody:noToolText`)}</div>
              </div>
            </div>
          )}
        </div>
        {isPossibleToUnassign && currentEquipment && (
          <div className="revertOrProceedToUnassignButtons">
            <DeleteSquare className="revertUnassignButton" onClick={handleClickRevertUnassignPossibility} />
            <NudosButton
              buttonText="Desasignar"
              customHeight="18px"
              customWidth="80px"
              customClassName="proceedToUnassignButton"
              isButtonDisabled={isProceedToUnassignButtonDisabled}
              handleClick={handleProceedToUnassign}
            />
          </div>
        )}
        <div className="equipmentHistory">
          <div className="titleContainer" onClick={() => setShowEquipementHistory(!showEquipementHistory)}>
            <div className="text">{t(`${employeeDetailLenguageText}employeeDetailsBody:equipmentHistory`)}</div>
            {!showEquipementHistory && (
              <div className="expandArrow">
                <ReturnButton />
              </div>
            )}
            {showEquipementHistory && (
              <div className="contractArrow">
                <ReturnButton />
              </div>
            )}
          </div>
          <div className="cardsContainer">
            {showEquipementHistory &&
              equipmentHistory &&
              equipmentHistory.length > 0 &&
              equipmentHistory.map((tool, i) => {
                return (
                  <AssignedToolCard
                    key={`assignedTool${i}`}
                    assignedTool={tool}
                    inactiveCard
                    toolsToUnassign={toolsToUnassign}
                    setToolsToUnassign={setToolsToUnassign}
                    updateFile={updateFile}
                    loaderFileCardId={loaderFileCardId}
                  />
                );
              })}
            <div className="marginAtBottom w-10 h-10" />
          </div>
        </div>
        {(!equipmentHistory || equipmentHistory.length <= 0) && showEquipementHistory && (
          <div className="noHistoryText">{t(`${employeeDetailLenguageText}employeeDetailsBody:noHistoryText`)}</div>
        )}
      </div>
      {showUnassignModal && (
        <UnassignToolsFromEmployeeModal
          setShowUnassignModal={setShowUnassignModal}
          toolsToUnassign={toolsDataToUnassign}
          loadingInitialData={false}
          employee={{ ...employee, country: logisticsCountryData }}
          logisticsOriginCountry={logisticsCountryData}
          navigationOrigin="employee-details"
          navigationOriginId={employee?.userId}
        />
      )}

      {showAssignToolsModal && (
        <AssignToolsModal
          employee={{ ...employee, country: logisticsCountryData }}
          navigationOrigin="employee-details"
          navigationOriginId={employee?.userId}
          closeModalCallback={setShowAssignToolsModal}
          logisticsOriginCountry={logisticsCountryData}
          listOfCountriesWithLogistics={listOfCountriesWithLogistics}
        />
      )}
    </div>
  );
};

export default EmployeeDetailsBody;
