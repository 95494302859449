import { useTranslation } from 'react-i18next';
import CheckFilters from '../../../../assets/images/components/NudosComponents/checkFilters';
import { IFilter } from '../../../../types/global';
import { segmentTrackEvent } from '../../../../utils/segment';

import './EquipmentFilters.scss';

const EquipmentFilters = ({
  handleChangeFilters,
  isFilterActive,
  profileFilter,
  lineFilter,
  filtersStyles
}: {
  handleChangeFilters: (key: string, value: string) => void;
  isFilterActive: (key: string, value?: string) => boolean;
  profileFilter?: IFilter;
  lineFilter?: IFilter;
  filtersStyles?: string;
}) => {
  const { t } = useTranslation();

  const i18nLanguageKey = 'nodi:checkout:';
  const equipmentConsultingText = t(`${i18nLanguageKey}equipmentConsulting`);
  const profileFilterTitle = t(`${i18nLanguageKey}profile`);
  const positionFilterTitle = t(`${i18nLanguageKey}positions`);

  const validateJSONFormat = (string: string) => {
    try {
      JSON.parse(string);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div id="equipmentFilters" className={`${filtersStyles || ''}`}>
      <div className="containerEquipmentFilters">
        <div className="boxEquipmentFilters newPaddings">
          <div className="titleEquipmentFilter">
            <div className="secondTitleEquipament">{equipmentConsultingText}</div>
          </div>
          <div className="containerFilterList">
            {profileFilter && profileFilter.length > 0 && (
              <div className="filterList">
                <div className="filterTypeTitle filterTypeTitleNewStore">{profileFilterTitle}</div>
                {profileFilter?.map(item => {
                  const descriptionArray =
                    item?.description && validateJSONFormat(item?.description) && JSON.parse(item?.description);

                  const descriptionHoverUI = (
                    <div className="descriptionHover">
                      <ul className="descriptionItemsList">
                        {descriptionArray &&
                          descriptionArray?.map((descriptionLine: string, i: number) => {
                            return (
                              <li key={`profileDescriptionLine${i}`} className="descriptionLine">
                                {descriptionLine}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  );
                  return (
                    <div
                      onClick={() => {
                        handleChangeFilters('profile', item.value);
                        isFilterActive('profile', item.value) &&
                          segmentTrackEvent({ storeFilterEquipment: { ProfileType: item.value } });
                      }}
                      key={`profile-key-item-${item.id}`}
                      className="itemCheckFilters"
                    >
                      {descriptionHoverUI}
                      <div className="containerCheck">
                        <CheckFilters
                          role={isFilterActive('profile', item?.value) ? 'active' : 'inactive'}
                          className="positionCheckIcon"
                        />
                      </div>
                      <div className="containerCheckText">
                        <div className="textCheckFilters">{item?.label}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {lineFilter && lineFilter.length > 0 && (
              <div className="filterList">
                <div className="filterTypeTitle filterTypeTitleNewStore">{positionFilterTitle}</div>
                {lineFilter.map(item => {
                  const descriptionHoverUI = <div className="descriptionHover">{item?.description}</div>;
                  return (
                    <div
                      onClick={() => handleChangeFilters('line', item?.value)}
                      key={`cargos-filter-${item?.id}`}
                      className="itemCheckFilters"
                    >
                      {descriptionHoverUI}
                      <div className="containerCheck">
                        <CheckFilters
                          role={isFilterActive('line', item?.value) ? 'active' : 'inactive'}
                          className="positionCheckIcon"
                        />
                      </div>
                      <div className="containerCheckText">
                        <div className="textCheckFilters">{item?.label}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipmentFilters;
