import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6531_143816)">
      <path
        d="M15.5153 4.57031C15.5153 5.05222 15.0203 5.97636 14.1826 6.90986M1.80103 4.57031C1.80103 5.05222 2.29605 5.97636 3.13375 6.90986M3.13375 6.90986L1.80103 8.57031M3.13375 6.90986C3.84499 7.70246 4.80327 8.5018 5.91532 9.04297C6.19319 9.17819 6.48066 9.29729 6.77628 9.39614M14.1826 6.90986L15.5153 8.57031M14.1826 6.90986C13.4714 7.70246 12.5131 8.5018 11.401 9.04297C11.1232 9.17819 10.8357 9.29729 10.5401 9.39614M6.77628 9.39614C7.37426 9.59609 8.00557 9.71317 8.65817 9.71317C9.31077 9.71317 9.94209 9.59609 10.5401 9.39614M6.77628 9.39614L5.91531 11.4275M10.5401 9.39614L11.401 11.4275"
        stroke="#ff664e"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6531_143816">
        <rect width="16" height="16" fill="white" transform="translate(0.658203)" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CloseEye = memo(ForwardRef);
export default CloseEye;
