import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_3923_5042)">
      <path
        d="M6.00021 11.3569C8.95889 11.3569 11.3574 8.95841 11.3574 5.99972C11.3574 3.04105 8.95889 0.642578 6.00021 0.642578C3.04154 0.642578 0.643066 3.04105 0.643066 5.99972C0.643066 8.95841 3.04154 11.3569 6.00021 11.3569Z"
        fill="white"
        stroke="#383838"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.85693 6.53599L5.41537 8.14314C6.14872 6.03655 6.75946 5.11216 8.14265 3.85742"
        stroke="#383838"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3923_5042">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>{' '}
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SuccessBlueIcon = memo(ForwardRef);
export default SuccessBlueIcon;
