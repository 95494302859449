import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <rect width={24} height={24} rx={4} fill="#ff664e" />
    <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <path d="m18 18-4-4" />
      <path d="M11 16a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z" fill="#ff664e" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SelectReferenceStepIcon = memo(ForwardRef);
export default SelectReferenceStepIcon;
