import { IassigneeInfo } from '../../../../../../../types/global';
import AssignmentCard from '../PreviousAssignmentCard/PreviousAssignmentCard';
import PreviousLocationCard from '../PreviousLocationCard/PreviousLocationCard';
import ToolHistoryCardAdditionalServicesSection from './ToolHistoryCardAdditionalServicesSection/ToolHistoryCardAdditionalServicesSection';
import './ToolHistoryCard.scss';

const ToolHistoryCard = ({
  assignation,
  updateDataCallback,
  orderId
}: {
  assignation: IassigneeInfo;
  updateDataCallback: () => void;
  orderId?: number;
}) => {
  const { userId, assignmentId, logisticAdditionalServices } = assignation;
  const isPreviousUserAssignment = !!userId || !!assignmentId;

  const historyCardHasAdditionalServices = !!logisticAdditionalServices?.length;
  return (
    <div className="toolHistoryCard">
      {!isPreviousUserAssignment && (
        <PreviousLocationCard
          data={assignation}
          updateDataCallback={updateDataCallback}
          place={assignation?.place}
          locationName={assignation?.locationName}
          orderId={orderId}
        />
      )}
      {isPreviousUserAssignment && <AssignmentCard assignee={assignation} updateDataCallback={updateDataCallback} />}
      {!!historyCardHasAdditionalServices && <div className="historyCardDividerLine" />}
      {!!historyCardHasAdditionalServices && (
        <ToolHistoryCardAdditionalServicesSection logisticAdditionalServices={logisticAdditionalServices} />
      )}
    </div>
  );
};

export default ToolHistoryCard;
