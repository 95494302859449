import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g>
      <path
        className="path1"
        d="M8.48486 8.29928L13.6319 13.4463"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="path2"
        d="M2.29689 3.68522C1.82881 2.60032 2.87592 1.50362 3.95842 1.97712C4.56731 2.24352 5.16595 2.56722 5.88737 3.01192C6.49413 2.39272 6.9988 1.91032 7.52023 1.46322C8.4214 0.690415 9.77694 1.37122 9.63319 2.54962C9.56982 3.06912 9.47228 3.60302 9.33115 4.22372C9.27956 4.45065 9.38791 4.68469 9.59494 4.791C10.3571 5.18234 10.9702 5.53181 11.5644 5.9178C12.5457 6.55528 12.3086 8.0336 11.1594 8.25413C10.4359 8.39296 9.69002 8.46016 8.73592 8.48473C8.69749 9.41342 8.62284 10.1457 8.48605 10.8595C8.26419 12.0173 6.77367 12.2792 6.13267 11.2899C5.7601 10.7149 5.41872 10.1093 5.04069 9.35192C4.93541 9.14098 4.6984 9.0297 4.46866 9.08259C3.82463 9.23088 3.27283 9.33222 2.73056 9.39598C1.5663 9.53287 0.888465 8.20081 1.65083 7.31028C2.10758 6.77675 2.60844 6.26152 3.26312 5.63619C2.86154 4.90017 2.55969 4.29432 2.29689 3.68522Z"
        fill="#ff664e"
        stroke="white"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MagicIcon = memo(ForwardRef);
export default MagicIcon;
