import { useUserAuth } from '../../state/userAuthContext';
import { NudosGenericModal } from '../NudosComponents';
import segment from '../../utils/segment';
import useLogOutState from '../../state/useLogOutState';
import { useTranslation } from 'react-i18next';
import './logout.scss';

const Logout = ({ setIsLogoutModalOpen }: { setIsLogoutModalOpen: (state: boolean) => void }) => {
  const { openLogoutModal, setOpenLogoutModal } = useLogOutState();

  const { logOut } = useUserAuth();
  const handleClickLogout = () => {
    if (!logOut) return;
    segment.resetEvent();
    logOut();
  };

  const closeLogoutModal = () => {
    setOpenLogoutModal(false);
    setIsLogoutModalOpen(false);
  };
  return (
    <div className="logout">
      {openLogoutModal && logOut && (
        <div className="logout-modal">
          <NudosGenericModal
            customZIndex={99999999}
            modalContent={<LogoutModal onClickLogout={handleClickLogout} closeLogoutModal={closeLogoutModal} />}
            closesOnClickOutside={true}
            clickOutsideCallback={closeLogoutModal}
            closeModalCallback={closeLogoutModal}
          />
        </div>
      )}
    </div>
  );
};
export default Logout;

const LogoutModal = ({
  onClickLogout,
  closeLogoutModal
}: {
  onClickLogout: () => void;
  closeLogoutModal: () => void;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:headBar:profileInfo:floatOptions:logoutModal:';
  return (
    <div className="logout-modal-container">
      <div className="text">
        <p>{t(`${i18nLanguageKey}text`)}</p>
      </div>
      <div className="options-buttons">
        <button onClick={onClickLogout}>
          <p>{t(`${i18nLanguageKey}yes`)}</p>
        </button>
        <button onClick={closeLogoutModal}>
          <p>No</p>
        </button>
      </div>
    </div>
  );
};
