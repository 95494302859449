import { Route } from '../types/global';
import { NotFound, ProductNotFoundView, SignIn, SignUp } from '../views';
import {
  Main,
  EmployeesModule,
  EmployeeDetails,
  CreateEmployee,
  EditEmployeeInfo,
  UploadExternalToolsModule,
  DisabledModule,
  NodiInternalProductDetails
} from '../views/Admin';
import { checkout, ListOfProducts, InternalProductCatalog } from '../views/ecommerce';
import WaitForEmail from '../views/WaitForEmail';
import SearchResult from '../views/ecommerce/searchResult';
import DashBoardModule from '../views/Admin/dashboard';
import NotRegistered from '../views/signIn/components/NotRegistered';
import PendingOrRejectedView from '../views/signUp/components/PendingOrRejectedView';
import Account from '../views/Admin/account';
import { OrderList } from '../views/Admin/orderList';
import EmployeesLoad from '../views/Admin/employeesLoad';
import OrderDetails from '../views/Admin/orderDetails';
import FrequentlyAskedQuestions from '../views/Admin/FrequentlyAskedQuestions';
import LogisticsServicesListing from '../views/Admin/LogisticsServicesModule/LogisticsServicesListing/LogisticsServicesListing';
import LogisticsServiceDetail from '../views/Admin/LogisticsServicesModule/LogisticsServiceDetail/LogisticsServiceDetail';
import UnassignmentModule from '../views/RequestLogistics/UnassignmentModule';
import AssignmentModule from '../views/RequestLogistics/AssignmentModule';
import ToolsManagementModule from '../views/RequestLogistics/ToolsManagementModule/ToolsManagementModule';
import LogisticServiceRejectedView from '../views/RequestLogistics/LogisticServiceRejectedView/LogisticServiceRejectedView';
import NewLogisticServiceSummary from '../views/RequestLogistics/NewLogisticServiceSummary/NewLogisticServiceSummary';
import { AllSystemIcons } from '../views/System/AllIcons';
import UploadExternalToolsSummary from '../views/Admin/uploadExternalToolsModule/UploadExternalToolsSummary/UploadExternalToolsSummary';
import UploadEmployeesSummary from '../views/Admin/employeesLoad/UploadEmployeesSummary/UploadEmployeesSummary';

const authRoutes: Route[] = [
  {
    component: SignIn,
    name: 'SignIn',
    label: 'Sign In',
    layout: '',
    path: '/login'
  },
  {
    component: SignUp,
    name: 'SignUp',
    label: 'Sign Up',
    layout: '',
    path: '/demo'
  },
  {
    component: NotRegistered,
    name: 'NotRegistered',
    label: 'Not Registered',
    layout: '',
    path: '/not-registered'
  },
  {
    component: WaitForEmail,
    name: 'WaitForEmail',
    label: 'Wait For Email',
    layout: '',
    path: '/wait-for-email'
  }
];
const adminRoutes: Route[] = [
  {
    component: NotFound,
    name: 'NotFound',
    label: 'Not Found',
    layout: 'nodi',
    path: '/not-found'
  },
  {
    component: Main,
    name: 'toolsModule',
    label: 'ToolsModule',
    layout: 'nodi',
    path: '/tools',
    disabledFree: false,
    disabledComponent: DisabledModule
  },
  {
    component: NodiInternalProductDetails,
    name: 'nodiProductDetails',
    label: 'NodiProductDetails',
    layout: 'nodi',
    path: '/details/:id',
    disabledFree: false,
    disabledComponent: DisabledModule
  },
  {
    component: EmployeesModule,
    name: 'employeesModule',
    label: 'EmployeesModule',
    layout: 'nodi',
    path: '/employees',
    disabledFree: false,
    disabledComponent: DisabledModule
  },
  {
    component: DashBoardModule,
    name: 'dashboardModule',
    label: 'DashboardModule',
    layout: 'nodi',
    path: '/dashboard'
  },
  {
    component: Account,
    name: 'accountModule',
    label: 'AccountModule',
    layout: 'nodi',
    path: '/account'
  },
  {
    component: EmployeeDetails,
    name: 'employeeDetails',
    label: 'EmployeeDetails',
    layout: 'nodi',
    path: '/employees/details/:id',
    disabledFree: false,
    disabledComponent: DisabledModule
  },
  {
    component: CreateEmployee,
    name: 'createEmployee',
    label: 'CreateEmployee',
    layout: 'nodi',
    path: '/employees/create'
  },
  {
    component: EditEmployeeInfo,
    name: 'editEmployeeInfo',
    label: 'EditEmployeeInfo',
    layout: 'nodi',
    path: '/employees/edit/:id'
  },
  {
    component: PendingOrRejectedView,
    name: 'approvalStatus',
    label: 'approvalStatus',
    layout: 'state',
    path: '/inactive'
  },
  {
    component: UploadExternalToolsModule,
    name: 'uploadExternalToolsModule',
    label: 'uploadExternalToolsModule',
    layout: 'nodi',
    path: '/upload-external-tools'
  },
  {
    component: UploadExternalToolsSummary,
    name: 'UploadExternalToolsSummary',
    label: 'UploadExternalToolsSummary',
    layout: 'nodi',
    path: '/upload-external-tools/summary'
  },
  {
    component: EmployeesLoad,
    name: 'EmployeesLoad',
    label: 'EmployeesLoad',
    layout: 'nodi',
    path: '/upload-employees'
  },
  {
    component: UploadEmployeesSummary,
    name: 'UploadEmployeesSummary',
    label: 'UploadEmployeesSummary',
    layout: 'nodi',
    path: '/upload-employees/summary'
  },
  { component: OrderList, name: 'orderList', label: 'orderList', layout: 'nodi', path: '/orders' },
  { component: OrderDetails, name: 'OrderDetails', label: 'OrderDetails', layout: 'nodi', path: '/orders/details/:id' },
  {
    component: LogisticsServicesListing,
    name: 'logisticsServicesList',
    label: 'logisticsServicesList',
    layout: 'nodi',
    path: '/logistics-services'
  },
  {
    component: LogisticsServiceDetail,
    name: 'logisticsServicesList',
    label: 'logisticsServicesList',
    layout: 'nodi',
    path: '/logistics-services/details/:id'
  },
  {
    component: UnassignmentModule,
    name: 'unassignmentModule',
    label: 'unassignmentModule',
    layout: 'nodi',
    path: '/request-logistics/unassignment/:userId',
    disabledFree: false
  },
  {
    component: AssignmentModule,
    name: 'assignmentModule',
    label: 'assignmentModule',
    layout: 'nodi',
    path: '/request-logistics/assignment/:userId',
    disabledFree: false
  },
  {
    component: ToolsManagementModule,
    name: 'toolsManagementModule',
    label: 'toolsManagementModule',
    layout: 'nodi',
    path: '/request-logistics/tools-management/:toolsIds',
    disabledFree: false
  },
  {
    component: NewLogisticServiceSummary,
    name: 'newLogisticServiceSummary',
    label: 'newLogisticServiceSummary',
    layout: 'nodi',
    path: '/request-logistics/success/:logisticsId',
    disabledFree: false
  },
  {
    component: LogisticServiceRejectedView,
    name: 'logisticServiceRejectedView',
    label: 'logisticServiceRejectedView',
    layout: 'nodi',
    path: '/request-logistics/rejected',
    disabledFree: false
  },
  {
    component: FrequentlyAskedQuestions,
    name: 'FrequentlyAskedQuestions',
    label: 'FrequentlyAskedQuestions',
    layout: 'nodi',
    path: '/faqs'
  },
  {
    component: AllSystemIcons,
    name: 'AllSystemIcons',
    label: 'AllSystemIcons',
    layout: 'nodi',
    path: '/all-system-icons'
  }
];
const ecommerceRoutes: Route[] = [
  {
    component: ListOfProducts,
    name: 'ecommerce',
    label: 'Ecommerce',
    layout: 'catalogue',
    path: '/home'
  },
  {
    component: checkout,
    name: 'checkout',
    label: 'Checkout',
    layout: 'catalogue',
    path: '/checkout'
  },
  {
    component: InternalProductCatalog,
    name: 'productDetails',
    label: 'Product Details',
    layout: 'catalogue',
    path: '/product/:id'
  },
  {
    component: SearchResult,
    name: 'searchResult',
    label: 'Search Result',
    layout: 'catalogue',
    path: '/search'
  },
  {
    component: ProductNotFoundView,
    name: 'productNotFoundView',
    label: 'Product Not Found View',
    layout: 'catalogue',
    path: '/product-not-found'
  }
];

export { authRoutes, adminRoutes, ecommerceRoutes };
