import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M5.5 10a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
      fill="#fff"
      stroke="#999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m10.357 11.357-2-2" stroke="#999" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MagnifierIcon = memo(ForwardRef);
export default MagnifierIcon;
