import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="encircledRightArrow"
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path id="circle" d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0Z" fill="#fff" stroke="#2C7152" />
    <path
      id="arrow"
      d="M4 7h6.03M7.969 9.062C8.873 8.32 9.325 7.869 10.03 7c-.705-.869-1.157-1.32-2.061-2.062"
      stroke="#2C7152"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const EncircledRightArrow = memo(ForwardRef);
export default EncircledRightArrow;
