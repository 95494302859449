import { FC, useEffect, useState } from 'react';
import { NudosSimpleSelect } from '../../../../../components/NudosComponents';
import { ILocationAddress } from '../../../../../types/account';
import { useTranslation } from 'react-i18next';
import { Tany } from '../../../../../types/global';
import './listAddressCard.scss';

interface IListAddressCard {
  cardData: ILocationAddress;
  selectData?: Tany;
  openModalAddressDetail?: (item: ILocationAddress) => void;
}

interface ISelectData {
  value: number;
  name: string;
  active?: boolean;
}

const ListAddressCard: FC<IListAddressCard> = ({ cardData, selectData, openModalAddressDetail }: IListAddressCard) => {
  const { t } = useTranslation();
  const [addressList, setAddressList] = useState<ILocationAddress[]>();
  const [formatList, setFormatList] = useState<ISelectData[]>();
  const i18nLanguageKey = 'nodi:account:organizationAddressesTab:listAddressCard:';

  const formatSelectList = (list: ILocationAddress[]) => {
    const newList: ISelectData[] = [];
    for (const item of list) {
      if (item.id && (item.locationName || item.address)) {
        const newObjet = {
          value: item.id,
          name: item.locationName || item.address || '',
          active: item.active || false
        };
        newList.push(newObjet);
      }
    }
    setFormatList([...newList]);
  };

  const countryAddressList = () => {
    if (selectData && cardData?.country) {
      if (selectData[cardData?.country?.code]) {
        setAddressList(selectData[cardData?.country?.code]);
        formatSelectList(selectData[cardData?.country?.code]);
      }
    }
  };

  const handleChangeItem = (item: ISelectData) => {
    const myItem = addressList?.find(element => element.id === item.value);
    if (myItem && openModalAddressDetail) {
      openModalAddressDetail(myItem);
    }
  };
  useEffect(() => {
    countryAddressList();
  }, []);

  return (
    <div id="listAddressCard">
      <div className="boxOtherAddressCard">
        <div className="flagContainer">
          <div className="boxCountryFlag">
            {cardData?.country && <img alt="flag_icon" src={cardData?.country?.flag} />}
          </div>
        </div>
        <div className="otherAddressText">{t(`${i18nLanguageKey}otherAddressText`)}</div>
        <div className="containerSelectItem">
          <div className="boxSelectItem">
            <NudosSimpleSelect
              changeValue={e => {
                handleChangeItem(e);
              }}
              addressOptions={true}
              customHeight={28}
              selectList={formatList}
              enabled={!!formatList}
              myPlaceHolder={
                formatList ? t(`${i18nLanguageKey}nudosSimpleSelect`) : t(`${i18nLanguageKey}nudosSimpleSelectDefault`)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListAddressCard;
