import { useTranslation } from 'react-i18next';
import { NudosButton } from '../../../../../../components/NudosComponents';
import './ConfigurationCommentsModal.scss';

const ConfigurationCommentsModalContent = ({
  comments,
  closeModalCallback
}: {
  comments: string;
  closeModalCallback: () => void;
}) => {
  const { t } = useTranslation();
  const translateKey = 'services:logistics:additionalServices:configurationModal';
  const subTitleText = t(`${translateKey}:subTitle`);
  const configurationDetailsText = t(`${translateKey}:configurationDetails`);

  return (
    <div className="ConfigurationCommentsModalContent">
      <h2 className="modalSubtitle">{subTitleText}</h2>
      <div className="centralSection">
        <div className="pt-6">
          <div className="textContainer">
            <div className="configurationCommentsTitle">{configurationDetailsText}</div>
            <div>{comments}</div>
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        <NudosButton componentSize="small" buttonText={t('recurrentWords:close')} handleClick={closeModalCallback} />
      </div>
    </div>
  );
};

export default ConfigurationCommentsModalContent;
