import { v4 as uuid } from 'uuid';
import { MoreEmployeesIcon, noEmployeesIcon, SingleEmployee } from '../../../assets/images/views/Admin/employees';
import { NudosButton, NudosLoaderModal, NudosSearchBar } from '../../../components/NudosComponents';
import EmployeeCard from './Components/EmployeeCard';
import { getEmployeesListing, getFiltersEmployees } from '../../../services/employees.service';
import { GenericFetchError } from '../../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Iemployee } from '../../../types/global';
import useDebounce from '../../../hooks/useDebounce';
import { BoardFiltersIcon } from '../../../assets/images/components/board';
import GenericFilter from '../../../components/genericFilter';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { magnifier } from '../../../assets/images/views/Admin/main';
import FilterXMark from '../../../assets/images/components/NudosComponents/FilterXMark';
import { segmentTrackEvent } from '../../../utils/segment';
import useStore from '../../../state';
import { useTranslation } from 'react-i18next';
import useLanguagePlatformState from '../../../state/useLenguageState';
import NudosDynamicBanner, {
  INudosDynamicBanner
} from '../../../components/DesignSystem/NudosDynamicBanner/NudosDynamicBanner';
import useStateSubscriptions from '../../../state/useStateSubscriptions';

import './EmployeesModule.scss';

interface IIOptions {
  value: number | string;
  name: string;
  isChecked: boolean;
}

const EmployeesModule = () => {
  const { t } = useTranslation();
  const JSONorgData = localStorage.getItem('orgData');
  const orgData = JSONorgData && JSON.parse(JSONorgData);
  if (!orgData) return null;

  const { push, replace } = useHistory();
  const { search } = useLocation();
  const urlRedirectionFilter = new URLSearchParams(search).get('filter');
  const {
    setEmployeesCount,
    employeeSearchbarText,
    setEmployeeSearchbarText,
    employeeParamsFilters,
    setEmployeeParamsFilters
  } = useStore();
  const { platformLanguage } = useLanguagePlatformState();
  const { stateSubscription } = useStateSubscriptions();

  const unequippedFilter = urlRedirectionFilter === 'unequipped';
  const i18nLanguageKey = 'nodi:employee:';
  const languageIsEnglish = platformLanguage && platformLanguage === 'enUS' ? true : false;
  const freePlarform = stateSubscription?.levelSubscription === 'Free';
  const [data, setData] = useState<Iemployee[]>();
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [employeeStatusList, setEmployeeStatusList] = useState<IIOptions[]>([]);
  const [openCountryFilter, setOpenCountryFilter] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<IIOptions[]>([]);
  const [openCityFilter, setOpenCityFilter] = useState<boolean>(false);
  const [cityList, setCityList] = useState<IIOptions[]>([]);
  const [openAreaFilter, setOpenAreaFilter] = useState<boolean>(false);
  const [hasHadFilters, setHasHadFilters] = useState(false);
  const [areaList, setAreaList] = useState<IIOptions[]>([]);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [bannerMessage, setBannerMessage] = useState<INudosDynamicBanner>();
  const [unequippedAlertWasShown, setUnequippedAlertWasShown] = useState(false);
  const [openEmployeeStatusFilter, setOpenEmployeeStatusFilter] = useState<boolean>(false);
  const [employeesViewEventWasSent, setEmployeesViewEventWasSent] = useState(false);

  const filterDebounce = useDebounce(employeeParamsFilters, 500);
  const debouncedEmployeeNameSearchString = useDebounce(employeeSearchbarText, 500);

  const handleClickNewEmployeeButton = () => {
    segmentTrackEvent({ employeesNewClick: { NewEmployeeOrigin: 'Listing' } });
    push('/nodi/employees/create?origin=employeesList');
  };

  const formatGenericList = (items: string[]) => {
    const genericList: IIOptions[] = [];
    for (const item of items) {
      const newItem = {
        value: item,
        name: item,
        isChecked: item === 'No equipado' && unequippedFilter ? true : false
      };
      genericList.push(newItem);
    }
    return genericList;
  };

  const formatFilters = (items: IIOptions[]) => {
    const listFilters = [];
    for (const item of items) {
      listFilters.push(item.value);
    }
    if (listFilters && listFilters.length > 0) {
      return JSON.stringify(listFilters);
    }
    return '';
  };
  const generateFilters = () => {
    if (employeeParamsFilters) return;
    const searchEmployeeStatus = employeeStatusList.filter(element => element.isChecked);
    const formatEmployeeStatus = formatFilters(searchEmployeeStatus);
    const searchCountry = countryList.filter(element => element.isChecked);
    const formatCountry = formatFilters(searchCountry);
    const searchCity = cityList.filter(element => element.isChecked);
    const formatCity = formatFilters(searchCity);
    const searchArea = areaList.filter(element => element.isChecked);
    const formatArea = formatFilters(searchArea);
    const finalFilters =
      formatCountry || formatCity || formatArea || formatEmployeeStatus
        ? `?filters=[${
            formatCountry
              ? `{"name":"country","includes":"in","values":${formatCountry}}${
                  formatArea || formatCity || formatEmployeeStatus ? ',' : ''
                }`
              : ''
          }${
            formatCity
              ? `{"name": "city","includes":"in","values":${formatCity}}${
                  formatArea || formatEmployeeStatus ? ',' : ''
                }`
              : ''
          }${
            formatArea ? `{"name":"area","includes":"in","values":${formatArea}}${formatEmployeeStatus ? ',' : ''}` : ''
          }${
            formatEmployeeStatus
              ? `{"name": "employeeStatus", "includes": "in", "values": ${formatEmployeeStatus}}`
              : ''
          }] `
        : unequippedFilter
        ? `?filters=[{"name": "employeeStatus", "includes": "in", "values":["No equipado"]}]`
        : '';

    setEmployeeParamsFilters(finalFilters);
  };

  const getData = async (paramsFilter?: string, nameSearchParam?: string) => {
    setLoading(true);
    try {
      const dataEmployees = await getEmployeesListing(orgData.organizationId, paramsFilter, nameSearchParam);
      setEmployeesCount(dataEmployees?.length || 0);
      setData(dataEmployees);
      if (!employeesViewEventWasSent) {
        segmentTrackEvent({ employeesView: { NumberOfEmployees: dataEmployees?.length } });
        setEmployeesViewEventWasSent(true);
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  const getFilters = async () => {
    try {
      const dataFilters = await getFiltersEmployees(orgData.organizationId);
      const countryFilters = formatGenericList(dataFilters?.country || []);
      const cityFilters = formatGenericList(dataFilters?.city || []);
      const areaFilters = formatGenericList(dataFilters?.area || []);
      const employeeStatusFilters = formatGenericList(dataFilters?.employeeStatus || []);
      setCountryList([...countryFilters]);
      setCityList([...cityFilters]);
      setAreaList([...areaFilters]);
      setEmployeeStatusList([...employeeStatusFilters]);
    } catch (error) {
      displayErrorNotification();
    }
  };

  const resetFilter = (filterName: string, filterListOfOptions: IIOptions[]) => {
    const newFilter = filterListOfOptions.map(option => {
      return { ...option, isChecked: false };
    });
    if (filterName === 'country') setCountryList(newFilter);
    if (filterName === 'city') setCityList(newFilter);
    if (filterName === 'area') setAreaList(newFilter);
    if (filterName === 'employeeStatus') setEmployeeStatusList(newFilter);
  };

  const resetAllFilters = () => {
    resetFilter('country', countryList);
    resetFilter('city', cityList);
    resetFilter('area', areaList);
    resetFilter('employeeStatus', employeeStatusList);
    unequippedFilter && replace('/nodi/employees');
  };

  const loadEmployeesButtonAction = () => {
    if (freePlarform) {
      setBannerMessage({
        bannerSubtitle:
          'Carga empleados masivamente y sin límites, obtén descuentos y mucho más con todo lo que Nudos tiene para ti.',
        bannerTitle: 'Carga empleados en un click'
      });
    } else {
      push('/nodi/upload-employees');
    }
  };

  const submitBannerCallback = () => {
    segmentTrackEvent({ nodiFreeMassiveEmployeeUploadModalNodiPrimeAdvertiseClick: null });
  };

  const filtersAreActive = !!employeeParamsFilters;
  const activeFiltersStyle = filtersAreActive ? 'active' : '';
  const filtersTitle = activeFiltersStyle
    ? t(`${i18nLanguageKey}containerFilters:activeFiltersStyle`)
    : t(`${i18nLanguageKey}containerFilters:activeFiltersStyleDefault`);
  const filtersIcon = activeFiltersStyle ? <FilterXMark role="active" /> : <BoardFiltersIcon stroke="#999999" />;

  useLayoutEffect(() => {
    if (unequippedFilter) {
      setShowLoaderModal(true);
    }
  }, []);

  useEffect(() => {
    if (!unequippedFilter || !showLoaderModal) return;
    const timerUnequippedEmployees = setTimeout(() => {
      setShowLoaderModal(false);
      if (!employeeParamsFilters) return;
      !unequippedAlertWasShown &&
        displaySuccessNotification({ customJSXMessage: <>Estás visualizando los empleados sin equipar</> });
      !unequippedAlertWasShown && setUnequippedAlertWasShown(true);
    }, 2000);

    return () => {
      clearTimeout(timerUnequippedEmployees);
    };
  }, [data]);

  useEffect(() => {
    generateFilters();
  }, [countryList, cityList, areaList, employeeStatusList]);

  useEffect(() => {
    getData(filterDebounce, debouncedEmployeeNameSearchString);
  }, [filterDebounce, debouncedEmployeeNameSearchString]);

  useEffect(() => {
    if (hasHadFilters) return;
    !!employeeParamsFilters && setHasHadFilters(true);
  }, [employeeParamsFilters]);

  useEffect(() => {
    getFilters();
    return () => {
      setEmployeesCount(0);
    };
  }, []);

  return (
    <>
      <div className="searchbarContainer">
        <NudosSearchBar
          defaultValue={employeeSearchbarText}
          placeholder={t(`${i18nLanguageKey}searchbarContainer`)}
          handleChange={(inputText: string) => setEmployeeSearchbarText(inputText)}
          componentSize={languageIsEnglish ? undefined : 'large'}
          hasDeleteButton
          customClassName={`${languageIsEnglish ? 'languageIsEnglish' : ''}`}
        />
      </div>
      {showLoaderModal && <NudosLoaderModal loaderText="Buscando empleados no equipados" modalTopPosition={92} />}
      {bannerMessage && (
        <NudosDynamicBanner
          bannerSubtitle={bannerMessage?.bannerSubtitle || ''}
          bannerTitle={bannerMessage?.bannerTitle || ''}
          openedBannerOrigin="cargue masivo de empleados"
          submitBannerCallback={submitBannerCallback}
          closeModalCallback={() => {
            setBannerMessage(undefined);
          }}
          alertType={2}
        />
      )}
      {!showLoaderModal && (
        <div id="employeeModuleHeader">
          <div className="containerFilters">
            <div className={`boardFiltersIconContainer ${activeFiltersStyle}`} onClick={resetAllFilters}>
              {filtersIcon}
              <div className="filtersTitle">{filtersTitle}</div>
            </div>
            <div className="firstFilter">
              <GenericFilter
                filterName={t(`${i18nLanguageKey}containerFilters:firstFilter`)}
                openFilter={openEmployeeStatusFilter}
                setOpenFilter={setOpenEmployeeStatusFilter}
                optionsList={employeeStatusList}
                setNewList={setEmployeeStatusList}
              />
            </div>
            <div className="secondFilter">
              <GenericFilter
                filterName={t(`${i18nLanguageKey}containerFilters:secondFilter`)}
                openFilter={openCountryFilter}
                setOpenFilter={setOpenCountryFilter}
                optionsList={countryList}
                setNewList={setCountryList}
              />
            </div>
            <div className="thirdFilter">
              <GenericFilter
                filterName={t(`${i18nLanguageKey}containerFilters:thirdFilter`)}
                openFilter={openCityFilter}
                setOpenFilter={setOpenCityFilter}
                optionsList={cityList}
                setNewList={setCityList}
              />
            </div>
            <div className="fourthFilter">
              <GenericFilter
                filterName={t(`${i18nLanguageKey}containerFilters:fourthFilter`)}
                openFilter={openAreaFilter}
                setOpenFilter={setOpenAreaFilter}
                optionsList={areaList}
                setNewList={setAreaList}
              />
            </div>
          </div>
          <NudosButton
            customClassName="employeeButton loadEmployeesButton"
            buttonText={t(`${i18nLanguageKey}loadEmployeesButton`)}
            buttonIcon={<MoreEmployeesIcon className="loadEmployeesIcon" />}
            handleClick={() => loadEmployeesButtonAction()}
          />
          <NudosButton
            customClassName="employeeButton newEmployeeButton "
            buttonText={t(`${i18nLanguageKey}newEmployeeButton`)}
            buttonIcon={<SingleEmployee />}
            handleClick={handleClickNewEmployeeButton}
          />
        </div>
      )}
      {!showLoaderModal && (
        <div id="employeesModule">
          {((!data && !error) || loading) && (
            <div className="skeletonEmployeeCardList">
              {[1, 2, 3, 4, 5, 6, 7, 8].map(element => {
                return <div key={element} className="skeletonEmployeeCard animationLoader" />;
              })}
            </div>
          )}
          {!loading && data && data.length > 0 && !error && (
            <div className="employeeListing">
              {data.map(employee => {
                return <EmployeeCard key={uuid()} employee={employee} />;
              })}
            </div>
          )}
          {!loading && data && data.length === 0 && !error && (
            <div className="noEmployees">
              <div className="items">
                <div className="iconContainer">
                  <img
                    src={employeeParamsFilters || unequippedFilter || hasHadFilters ? magnifier : noEmployeesIcon}
                    alt="Icono empleado"
                    style={employeeParamsFilters || hasHadFilters ? { width: '32px', height: '32px' } : undefined}
                  />
                </div>
                <div className="noEmployeesText">
                  {!employeeParamsFilters &&
                    !unequippedFilter &&
                    !employeeSearchbarText &&
                    !hasHadFilters &&
                    'Aún no tienes empleados creados'}
                  {(employeeParamsFilters || hasHadFilters || employeeSearchbarText) &&
                    !unequippedFilter &&
                    'No hay resultados para estos filtros'}
                  {employeeParamsFilters && unequippedFilter && 'No tienes empleados sin equipar'}
                </div>
              </div>
            </div>
          )}
          {error && <GenericFetchError />}
        </div>
      )}
    </>
  );
};

export default EmployeesModule;
