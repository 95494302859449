import { AxiosError } from 'axios';
import { orgAPI } from '../constants';
import { TLevelSubscription } from '../types/subscriptions';
import { apiGet, apiPut } from './api';
import { IOnboardingDetails } from '../types/onboarding';

const getOnboarding = async (
  organizationId: number,
  subscriptionType: TLevelSubscription
): Promise<IOnboardingDetails> => {
  return apiGet<IOnboardingDetails, IOnboardingDetails>(`${orgAPI}/onboarding/${organizationId}/${subscriptionType}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

const updateSteps = async (
  organizationId: number,
  lastStep: number,
  isCompleted: boolean,
  subscription: TLevelSubscription
): Promise<string> => {
  const bodyLastStep = {
    lastStepSeen: lastStep,
    isCompleted: isCompleted,
    onboardingName: subscription
  };

  return apiPut(`${orgAPI}/onboarding/${organizationId}`, bodyLastStep)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export { getOnboarding, updateSteps };
