import { useTranslation } from 'react-i18next';
import { LS_ADDITIONAL_SERVICE_STATUS } from '../../../../../types/logisticsServicesModule.types';
import { IadditionalServiceDataForToolLogistics } from '../../../../../types/requestLogisticsModule';
import { NudosButton } from '../../../../NudosComponents';
import AdditionalServiceStatusDataTable from './AdditionalServiceStatusDataTable';
import './NudosAdditionalServiceStatusModal.scss';

const AdditionalServiceStatusModalContent = ({
  additionalServiceData,
  closeModalCallback
}: {
  closeModalCallback: () => void;
  additionalServiceData: IadditionalServiceDataForToolLogistics;
}) => {
  const { t } = useTranslation();
  const { statusName } = additionalServiceData?.status || {};
  const wasntCompleted = statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED;

  const translateKey = 'services:logistics:additionalServices:';
  const subTitleNotCompletedText = t(`${translateKey}subsLassNotCompletedModal:subTitle`);
  const subTitleCompletedText = t(`${translateKey}subsLassCompletedModal:subTitle`);
  const understoodText = t(`${translateKey}understood`);

  const subtitle = wasntCompleted ? subTitleNotCompletedText : subTitleCompletedText;

  return (
    <div className="additionalServiceStatusModalContent">
      <div className="subtitle">{subtitle}</div>
      <div
        className="additionalServiceDataSection"
        style={wasntCompleted ? { maxHeight: '228px', overflowY: 'scroll' } : undefined}
      >
        <AdditionalServiceStatusDataTable additionalServiceData={additionalServiceData} />
      </div>
      <NudosButton componentSize="small" buttonText={understoodText} handleClick={closeModalCallback} />
    </div>
  );
};

export default AdditionalServiceStatusModalContent;
