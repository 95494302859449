import { useEffect, useState } from 'react';
import NudosSearchbarDropdown from '../NudosSearchbarDropdown/NudosSearchbarDropdown';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { TcomponentSize } from '../../../types/global';
import { IwarehouseData, TlogisticsOriginOrDestinationData } from '../../../types/requestLogisticsModule';
import { getNudosWarehousesListIncludingAdditionalServices } from '../../../services/logisticsServicesModule.services';
import { ICountryDetail } from '../../../types/countries';

/**
 *@property { TcomponentSize } componentSize - It determines the width of the component
 *@property { (newValue: TlogisticsOriginOrDestinationData) => void } updateCurrentValueCallback - the callback function to update the selected warehouse as it is stored in the parent component state
 *@property { IwarehouseData } currentValue - the data for the currently selected warehouse
 *@property { boolean } isFocused - a boolean indicating if the component is focused or not. DEFAULT is false.
 *@property { ICountryDetail[] }  filterCountries - A list of country objects from which the warehouse MUST BELONG (only warehouses from this countries will be shown)
 *@property { ICountryDetail }  currentCountryForExclusion - The data of the current country for excluding the warehouse of this country
 */
const NudosLogisticsWarehouseDropdown = ({
  componentSize,
  updateCurrentValueCallback,
  currentValue,
  warehousesListProp,
  isFocused,
  filterCountries,
  currentCountryForExclusion,
  countryOfOrigin
}: {
  componentSize?: TcomponentSize;
  updateCurrentValueCallback: (newValue: TlogisticsOriginOrDestinationData) => void;
  currentValue?: IwarehouseData;
  warehousesListProp?: IwarehouseData[];
  isFocused?: boolean;
  filterCountries?: ICountryDetail[];
  currentCountryForExclusion?: ICountryDetail;
  countryOfOrigin?: string;
}) => {
  const [nudosWarehousesList, setNudosWarehousesList] = useState<IwarehouseData[]>([]);
  const orgId = getOrgData()?.organizationId;

  const selectAValidCountryByDefault = (countryOfOrigin: string, warehousesInValidCountries: IwarehouseData[]) => {
    if (countryOfOrigin) {
      const defaultCountry = warehousesInValidCountries.find(country => countryOfOrigin === country?.country?.code);
      if (defaultCountry) updateCurrentValueCallback(defaultCountry);
    }
  };

  const setValidWarehouses = (newWarehouseList: IwarehouseData[]) => {
    const validCountries =
      filterCountries && filterCountries.length ? filterCountries.map(country => country.code) : undefined;
    const warehousesInValidCountries = validCountries
      ? newWarehouseList.filter(warehouse => validCountries.includes(warehouse?.country?.code || ''))
      : newWarehouseList;
    const validWarehouseExcludingCurrentCountryIfDesired = currentCountryForExclusion
      ? warehousesInValidCountries.filter(warehouse => warehouse?.country?.code !== currentCountryForExclusion?.code)
      : warehousesInValidCountries;
    if (warehousesInValidCountries) {
      selectAValidCountryByDefault(countryOfOrigin || '', validWarehouseExcludingCurrentCountryIfDesired);
      setNudosWarehousesList(validWarehouseExcludingCurrentCountryIfDesired);
    }
  };

  const getNudosWarehousesList = async () => {
    if (warehousesListProp || !orgId) return;
    try {
      const newNudosWarehousesList = await getNudosWarehousesListIncludingAdditionalServices(orgId);
      setValidWarehouses(newNudosWarehousesList);
    } catch {
      displayErrorNotification();
    }
  };

  const formDropdownOptionsCallback = (
    rawOptions: IwarehouseData[],
    clickOptionCallback?: (option: IwarehouseData) => void,
    searchString?: string
  ) => {
    const handleSelectOption = (option: IwarehouseData) => {
      updateCurrentValueCallback(option);
      clickOptionCallback && clickOptionCallback(option);
    };
    if (rawOptions.length === 0)
      return [
        <div className="optionContainer" key="loading">
          {searchString ? 'No hay resultados para tu búsqueda' : 'Cargando ...'}
        </div>
      ];
    return rawOptions.map((rawWarehouseData, i) => {
      return (
        <div
          className="optionContainer"
          key={`warehouse${i}${rawWarehouseData?.locationId}`}
          onClick={() => handleSelectOption(rawWarehouseData)}
        >
          {`Bodega ${rawWarehouseData?.country?.name || ''}`}
        </div>
      );
    });
  };

  const formSelectedOptionUICallback = (rawWarehouseData: IwarehouseData) => {
    return <div className="optionContainer">{`Bodega ${rawWarehouseData?.country?.name || ''}`}</div>;
  };

  const filterBySearchStringCallback = (search: string, rawOptions: IwarehouseData[]) => {
    return rawOptions.filter(rawWarehouseData =>
      rawWarehouseData?.country?.name?.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  };

  useEffect(() => {
    if (nudosWarehousesList && nudosWarehousesList.length) return;
    !warehousesListProp && getNudosWarehousesList();
  }, [orgId]);

  useEffect(() => {
    warehousesListProp && setValidWarehouses(warehousesListProp);
  }, [currentCountryForExclusion, warehousesListProp]);

  return (
    <div className="NudosLogisticsWarehouseDropdown">
      <NudosSearchbarDropdown
        componentSize={componentSize}
        rawOptions={nudosWarehousesList}
        formDropdownOptionsCallback={formDropdownOptionsCallback}
        formSelectedOptionUICallback={formSelectedOptionUICallback}
        filterBySearchStringCallback={filterBySearchStringCallback}
        currentValue={currentValue}
        isFilled={!!currentValue}
        isFocused={isFocused}
        customPlaceholder="Selecciona la bodega"
      />
    </div>
  );
};

export default NudosLogisticsWarehouseDropdown;
