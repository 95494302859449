import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="delete-square"
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_503_67787)">
      <path
        d="M0.959867 10.2684C1.114 11.7091 2.2727 12.8678 3.71266 13.0283C5.92445 13.2748 8.07554 13.2748 10.2873 13.0283C11.7273 12.8678 12.886 11.7091 13.0401 10.2684C13.1539 9.20493 13.25 8.11307 13.25 6.99991C13.25 5.88676 13.1539 4.7949 13.0401 3.73139C12.886 2.29074 11.7273 1.13203 10.2873 0.971524C8.07554 0.724987 5.92445 0.724987 3.71266 0.971524C2.2727 1.13203 1.114 2.29074 0.959867 3.73139C0.846083 4.7949 0.75 5.88676 0.75 6.99991C0.75 8.11307 0.846084 9.20493 0.959867 10.2684Z"
        fill="#383838"
        stroke="#383838"
      />
      <path d="M8.76772 5.23218L5.23218 8.76771" stroke="white" strokeLinecap="round" />
      <path d="M8.76772 8.76771L5.23218 5.23218" stroke="white" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_503_67787">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const BlackDeleteSquare = memo(ForwardRef);
export default BlackDeleteSquare;
