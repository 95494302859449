import { IconTimeClock } from '../../../../assets/DesignSystem/SVGComponents';
import { NudosButton } from '../../../../components/NudosComponents';
import './SecurityLogoutModal.scss';

const SecurityLogoutModalContent = ({ closeModalCallback }: { closeModalCallback: () => void }) => {
  const submitModal = () => {
    localStorage.removeItem('tokenExpired');
    closeModalCallback();
  };

  return (
    <div className="SecurityLogoutModalContent">
      <div className="iconContainer">
        <IconTimeClock className="IconTimeClock" />
      </div>
      <div className="modalText">Por seguridad, hemos cerrado tu sesión. Inicia sesión para ingresar nuevamente.</div>
      <NudosButton componentSize="small" buttonText="Entendido" handleClick={submitModal} />
    </div>
  );
};

export default SecurityLogoutModalContent;
