import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={8} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6436_140630)">
      <path
        d="M3.68626 0.486638C3.29954 0.449223 2.90121 0.429688 2.49437 0.429688C2.08752 0.429688 1.68919 0.449223 1.30246 0.486639C0.876908 0.527811 0.551359 0.959139 0.532138 1.48433C0.50245 2.29548 0.486816 3.13751 0.486816 4.00112C0.486816 4.86472 0.50245 5.70676 0.532138 6.51792C0.551359 7.04312 0.876908 7.47443 1.30246 7.51557C1.68919 7.553 2.08752 7.57254 2.49437 7.57254C2.90121 7.57254 3.29954 7.553 3.68626 7.51557C4.11181 7.47443 4.43736 7.04312 4.45658 6.51792C4.46718 6.22849 4.47598 5.93517 4.48292 5.6383V2.36395C4.47598 2.06709 4.46718 1.77374 4.45658 1.48433C4.43736 0.959139 4.11181 0.52781 3.68626 0.486638Z"
        fill="white"
      />
      <path
        d="M4.45658 1.48433C4.43737 0.959139 4.11181 0.52781 3.68626 0.486638C3.29954 0.449223 2.90121 0.429688 2.49437 0.429688C2.08752 0.429688 1.68919 0.449223 1.30246 0.486639C0.876908 0.527811 0.551359 0.959139 0.532138 1.48433C0.50245 2.29548 0.486816 3.13751 0.486816 4.00112C0.486816 4.86472 0.50245 5.70676 0.532138 6.51792C0.551359 7.04312 0.876908 7.47443 1.30246 7.51557C1.68919 7.553 2.08752 7.57254 2.49437 7.57254C2.90121 7.57254 3.29954 7.553 3.68626 7.51557C4.11181 7.47443 4.43737 7.04312 4.45658 6.51792"
        stroke="#383838"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M5.72998 5.69551C6.44981 5.10572 6.80952 4.74609 7.37067 4.05479C6.80952 3.36348 6.44981 3.00386 5.72998 2.41406"
        stroke="#383838"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.28784 4.05469H7.35223"
        stroke="#383838"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6436_140630">
        <rect width="8" height="8" fill="white" />
      </clipPath>
    </defs>{' '}
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const IconHeaderLogOut = memo(ForwardRef);
export default IconHeaderLogOut;
