import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { successCheckmark } from '../../../assets/images/views/RequestLogistics';
import { NudosButton } from '../../../components/NudosComponents';
import { BodyAlert, NewLogisticsVerifyData } from '../Components';
import { getLogisticServiceDetailsById } from '../../../services/logisticsServicesModule.services';
import {
  IlogisticServiceDetailedData,
  IlogisticServiceLocationData
} from '../../../types/logisticsServicesModule.types';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { GenericLoader } from '../../Admin/dashboard/components';
import useRequireLogisticsSummaryData from '../../../state/useRequireLogisticsSummaryData';
import { segmentTrackEvent } from '../../../utils/segment';
import { IconWarning } from '../../../assets/DesignSystem/SVGComponents';
import { locationFormat } from '../../../utils/locationFormat';

import './NewLogisticServiceSummary.scss';

const NewLogisticServiceSummary = () => {
  const { logisticsId } = useParams<{ logisticsId: string }>();
  const { push } = useHistory();
  const { search } = useLocation();
  const {
    estimatedCollection: collectionData,
    estimatedDelivery: deliveryData,
    inferiorAlert,
    superiorAlert,
    stateEventsUnassignment
  } = useRequireLogisticsSummaryData();
  const [loading, setLoading] = useState(true);
  const [logisticServiceData, setLogisticServiceData] = useState<IlogisticServiceDetailedData>();
  const { collection, delivery, status, tools } = logisticServiceData || {};
  const allToolHasNudosCare = tools?.every(tool => tool.nudosCare !== 'Free');
  const isManualQuoute = logisticServiceData?.manualQuote;

  const nudosCareSummarySubtitle = (
    <div className="subtitle nudosCareSubtitle">
      Tu servicio fue confirmado con éxito gracias a Nudos Care, podrás ver el avance en el detalle del servicio
      logístico
    </div>
  );

  const genericSummarySubtitle = (
    <div className={`subtitle  ${isManualQuoute ? 'manualQuouteText' : ''}`}>
      {isManualQuoute ? (
        <>
          En breves te enviaremos la cotización a través de tu correo electrónico. También podrás consultarlo desde el
          detalle del servicio.
        </>
      ) : (
        <>
          Pronto <strong>recibirás un correo electrónico con el enlace de pago y la factura</strong>. Además, toda esta
          información estará disponible en detalle en el servicio logístico en Nodi.
        </>
      )}
    </div>
  );

  const summarySubtitle = allToolHasNudosCare ? nudosCareSummarySubtitle : genericSummarySubtitle;

  const estimatedCollection = collectionData || '';
  const estimatedDelivery = deliveryData || '';
  const noDataText = 'Sin información';
  const searchParams = new URLSearchParams(search);

  const navigationOrigin = searchParams.get('origin');
  const oldUserId = searchParams.get('oldUserId');
  const flux = searchParams.get('flux');
  const selectedDatesAreForCollectionNotDelivery = searchParams.get('collection');

  const toolsIds = (tools || []).reduce<number[]>((prev, curr) => {
    curr?.productId && +curr?.productId !== 0 && prev.push(+curr?.productId);
    return prev;
  }, []);
  const hasTools = !!toolsIds.length;
  const multipleTools = toolsIds.length > 1;

  const summaryType =
    flux === 'assignment' || selectedDatesAreForCollectionNotDelivery === 'false' ? 'assignment' : 'unassignment';

  const logisticDetailsRedirection = {
    pathname: `/nodi/logistics-services/details/${logisticsId}`,
    state: {
      returnText: 'Dashboard',
      returnAction: '/nodi/dashboard'
    }
  };

  const getLogisticServiceDetail = async () => {
    if (!logisticsId) return;
    useParams;
    setLoading(true);
    try {
      const response = await getLogisticServiceDetailsById(logisticsId);
      setLogisticServiceData(response);
      setLoading(false);
    } catch {
      displayErrorNotification();
      setLoading(false);
    }
  };

  const handleRedirectionToOrigin = () => {
    switch (navigationOrigin) {
      case 'inventory': {
        displaySuccessNotification({
          customJSXMessage: <>Las herramientas se desasignaron correctamente. </>,
          button1Text: 'Ver empleado',
          button1Action: () => push(`/nodi/employees/details/${oldUserId}`)
        });
        return push(`/nodi/details/${logisticServiceData?.tools[0].productId}`);
      }
      case 'tool-details': {
        return push(`/nodi/details/${logisticServiceData?.tools[0].productId}`);
      }
      case 'inventory-management': {
        return push(`/nodi/details/${logisticServiceData?.tools[0].productId}`);
      }
      default:
        if (hasTools) {
          displaySuccessNotification({
            customJSXMessage: <>Las herramientas se desasignaron correctamente. </>,
            button1Text: multipleTools ? 'Ver herramientas' : 'Ver herramienta',
            button1Action: () => push(multipleTools ? '/nodi/tools' : `/nodi/details/${toolsIds[0]}`)
          });
        }
        return push(`/nodi/employees/details/${oldUserId}`);
    }
  };

  const getFormattedName = (locationData?: IlogisticServiceLocationData) => {
    if (!locationData) return noDataText;
    switch (locationData?.place) {
      case 'office':
        return `Oficinas ${locationData?.country?.name || ''}`;
      case 'user':
        return locationData?.additionalInfo?.name || noDataText;
      case 'warranty':
        return 'Marca';
      case 'nudos':
        return `Bodegas Nudos ${locationData?.country?.name || ''}`;
      case 'diagnostic':
        return 'Diagnóstico';
      default:
        return `${locationData?.additionalInfo?.name || locationData?.locationName || noDataText}`;
    }
  };

  const unassignmentEvent = () => {
    segmentTrackEvent({
      nodiLogisticServices51ResumeAprovedView: {
        DestinationCountry: stateEventsUnassignment?.destinationCountry || '',
        LogisticServiceDestination: locationFormat(stateEventsUnassignment?.logisticServiceDestination || ''),
        LogisticServiceOrigin: stateEventsUnassignment?.logisticServiceOrigin || '',
        NudosCare: !!stateEventsUnassignment?.nudosCare,
        OriginCountry: stateEventsUnassignment?.originCountry || '',
        TypeOfPlatform: stateEventsUnassignment?.typeOfPlatform || ''
      }
    });
  };

  const successEvent = () => {
    if (stateEventsUnassignment) {
      if (stateEventsUnassignment?.type === 'unassignment') {
        unassignmentEvent();
      }
    }
  };

  const YellowMessage = () => {
    return (
      <div className="yellowMessage">
        <IconWarning />
        Si el pago no se efectúa mediante el enlace proporcionado se procederá a la devolución del dinero.
      </div>
    );
  };

  useEffect(() => {
    getLogisticServiceDetail();
    successEvent();
  }, []);

  if (loading)
    return (
      <div className="NewLogisticServiceSummary">
        <GenericLoader />
      </div>
    );
  return (
    <div className="NewLogisticServiceSummary">
      <div className="successImage">
        <img src={successCheckmark} alt="Success" />
      </div>
      <div className="title">El servicio logístico fue solicitado correctamente</div>
      <div className={`subtitle  ${isManualQuoute ? 'manualQuouteText' : ''}`}>{summarySubtitle}</div>
      {!isManualQuoute && (
        <>
          <div className="paymentAgreementAlert">
            ⚠️Si cuentas con un acuerdo de pago, pronto se confirmará el servicio y se te asignará una fecha de pago.
          </div>
          <YellowMessage />
        </>
      )}
      <NewLogisticsVerifyData
        type={summaryType}
        collectionName={getFormattedName(collection)}
        deliveryName={getFormattedName(delivery)}
        selectedDatesRange={estimatedCollection}
        estimatedDelivery={estimatedDelivery}
        logisticServiceStatus={status || undefined}
        tools={tools || []}
        timeAlertText={superiorAlert}
        bodyAlertComponent={inferiorAlert && inferiorAlert?.data && <BodyAlert data={inferiorAlert.data} />}
        originPlace={logisticServiceData?.collection?.place || ''}
        destinationPlace={logisticServiceData?.delivery?.place || ''}
        quoteData={logisticServiceData?.logisticsQuoteData}
      />
      <div className="floatContainerButtosServiceSummary">
        <div className="boxButtonsContainer">
          <div className="buttonsContainer">
            {navigationOrigin === 'inventory' ||
            navigationOrigin === 'tool-details' ||
            navigationOrigin === 'inventory-management' ? (
              <NudosButton
                componentSize="large"
                colorPalette="secondary"
                buttonText="Ver herramienta"
                handleClick={handleRedirectionToOrigin}
              />
            ) : (
              <NudosButton
                componentSize="large"
                colorPalette="secondary"
                buttonText="Ver empleado"
                handleClick={handleRedirectionToOrigin}
              />
            )}
            <Link className="logisticDetailsRedirection" to={logisticDetailsRedirection}>
              <NudosButton buttonText="Ver servicio logistico" />
            </Link>
          </div>
        </div>
      </div>
      <div className="whiteSpaceAtTheBottom w-10 h-3" />
    </div>
  );
};

export default NewLogisticServiceSummary;
