import { FC, useState } from 'react';
import { NudosButton, NudosTextInput } from '../../../../../components/NudosComponents';
import NudosPopover from '../../../../../components/NudosComponents/NudosPopover/NudosPopover';
import { ILocationAddress } from '../../../../../types/account';
import './modalAddressDetail.scss';

interface IModalAddressDetail {
  modalData?: ILocationAddress;
  modalLoading?: boolean;
  modalCallBack?: () => void;
  modalExit?: () => void;
}

const ModalAddressDetail: FC<IModalAddressDetail> = ({
  modalData,
  modalLoading,
  modalCallBack,
  modalExit
}: IModalAddressDetail) => {
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  return (
    <div id="modalAddressDetail">
      <div className="boxModal">
        <div className="containerInputs mb-2">
          <NudosTextInput
            customClassName="mr-5"
            componentSize="small"
            label="Nombre de la dirección"
            isFilled
            defaultValue={modalData?.locationName || modalData?.address || ''}
            placeholder=""
            isDeactivated
          />
          <NudosTextInput
            componentSize="small"
            label="Indicaciones (opcional)"
            isFilled
            placeholder=""
            isDeactivated
            defaultValue={modalData?.additionalReferences || ''}
          />
        </div>
        <div className="containerInputs mb-6">
          <NudosTextInput
            customClassName="mr-5"
            componentSize="large"
            label="Dirección"
            isFilled
            placeholder=""
            isDeactivated
            defaultValue={modalData?.address || ''}
          />
        </div>
        <div className="detailButtons">
          <div className="firstButton">
            {openPopover && (
              <NudosPopover
                topPopover={-95}
                leftPopover={'0'}
                positionArrow="bottomLeft"
                firstButtonText={modalData?.active ? 'Deshabilitar' : 'Habilitar'}
                secondButtonText="Cancelar"
                callBackFirstButton={() => {
                  if (modalCallBack) {
                    setOpenPopover(false);
                    modalCallBack();
                  }
                }}
                callBackSecondButton={() => {
                  setOpenPopover(false);
                }}
              >
                ¿Estás seguro de deshabilitar <br />
                esta dirección para servicios
                <br /> logísticos?
              </NudosPopover>
            )}
            <NudosButton
              isButtonLoading={modalLoading}
              isButtonDisabled={modalLoading}
              colorPalette="secondary"
              buttonText={modalData?.active ? 'Deshabilitar' : 'Habilitar'}
              customClassName="enabledButton"
              handleClick={() => {
                if (modalCallBack) {
                  setOpenPopover(true);
                }
              }}
            />
          </div>
          <NudosButton
            handleClick={() => {
              if (modalExit) {
                modalExit();
              }
            }}
            buttonText="Listo"
            customClassName="exitButton"
          />
        </div>
      </div>
    </div>
  );
};

export default ModalAddressDetail;
