import { FC, useEffect, useState } from 'react';
import { ShippingCountryHeader } from '../ShippingCountryHeader';
import useShopStore from '../../../../../../../state/useShopContext';
import { IProductsCart } from '../../../../../../../types/cart';
import { ShippingReference } from '../ShippingRefereces';
import { ICountryDetail } from '../../../../../../../types/countries';
import { IWarehouse, IorganizationOfficeData } from '../../../../../../../types/account';
import ShipToOfficeModalFlux from '../ShipToOfficeModalFlux/ShipToOfficeModalFlux';
import { getOrgData } from '../../../../../../../utils/getLocalStorageData';
import { getOfficeByCountry } from '../../../../../../../services/account';
import { displayErrorNotification } from '../../../../../../../utils/displayNudosStandardNotifications';
import useCartState from '../../../../../../../state/useCartState';
import { checkIfOfficeAddressDataIsComplete } from '../../../../checkout.utils';
import useStoreCountryState from '../../../../../../../state/useStoreCountryState';
import { GenericLoader } from '../../../../../../Admin/dashboard/components';

import './ShippingCountry.scss';

interface IShippingCountry {
  countryKey?: string;
  countryData?: ICountryDetail;
  listOfCountriesOpenForBuying: ICountryDetail[];
  productIds?: number[];
  globalShippingCartWasPreloaded?: boolean;
  setSomeCountryOfficeDataIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  completeListOfCountries?: ICountryDetail[];
}

interface ICountryInfo {
  countryName: string;
  countryFlag: string;
  countryNameStringId: string;
}

const ShippingCountry: FC<IShippingCountry> = ({
  listOfCountriesOpenForBuying,
  globalShippingCartWasPreloaded,
  setSomeCountryOfficeDataIsLoading,
  completeListOfCountries
}: IShippingCountry) => {
  const { itemsCart } = useShopStore();
  const { globalShippingCart, setGlobalShippingCart, setDefaultAddressShipment } = useCartState();
  const productIds = itemsCart?.products?.map(countryReference => +countryReference.productId);
  const { storeCountry } = useStoreCountryState();
  const countryData = storeCountry;
  const [everyOneOffice, setEveryOneOffice] = useState<boolean>(false);
  const [officeAddress, setOfficeAddress] = useState<IorganizationOfficeData>();
  const [loadingOfficeAddress, setLoadingOfficeAddress] = useState(false);
  const [countryProductsList, setProductsList] = useState<IProductsCart[]>();
  const [countryInfo, setCountryInfo] = useState<ICountryInfo>({
    countryFlag: '',
    countryName: '',
    countryNameStringId: ''
  });
  const [showShipToOfficeModalFlux, setShowShipToOfficeModalFlux] = useState(false);
  const [countryWarehouse, setCountryWarehouse] = useState<IWarehouse>();
  const [finishShipToOfficeFluxCallbackFn, setFinishShipToOfficeFluxCallbackFn] =
    useState<(modalData?: IorganizationOfficeData) => void>();

  const [loadingShippingCountry, setLoadingShippingCountry] = useState(false);

  const orgId = getOrgData()?.organizationId;

  const getReferenceShipmentDataForThisCountry = (productId: number) => {
    const oldShipmentCardData = globalShippingCart?.shipments || [];
    const newShipmentCartData = [...oldShipmentCardData];
    if (!newShipmentCartData || newShipmentCartData.length <= 0) return;
    return newShipmentCartData.filter(
      element => element?.productId === productId && element?.countryId === countryData?.id
    );
  };

  const updateOfficeDestinationShipmentInformation = () => {
    if (
      !officeAddress?.locationId ||
      !checkIfOfficeAddressDataIsComplete(officeAddress) ||
      !officeAddress?.shipmentInfo?.receiverName ||
      !officeAddress?.shipmentInfo?.receiverPhone ||
      !globalShippingCart
    )
      return;
    const oldShipmentsData = globalShippingCart?.shipments ? [...globalShippingCart.shipments] : [];
    const newReceiverInformation = {
      receiverName: officeAddress?.shipmentInfo?.receiverName,
      receiverPhone: officeAddress?.shipmentInfo?.receiverPhone,
      personalId: officeAddress?.shipmentInfo?.receiverIdDocument
    };
    const newShipmentsData = oldShipmentsData.map(shipmentItem => {
      if (
        shipmentItem?.countryName === officeAddress?.country?.name &&
        productIds &&
        productIds.length > 0 &&
        shipmentItem?.productId &&
        productIds?.includes(+shipmentItem.productId) &&
        (everyOneOffice || shipmentItem?.destinationIsOffice)
      ) {
        return {
          ...shipmentItem,
          destinationLocationId: officeAddress.locationId,
          receiverInformation: newReceiverInformation,
          destinationIsOffice: everyOneOffice ? true : shipmentItem?.destinationIsOffice || false,
          employee: undefined
        };
      } else {
        return { ...shipmentItem };
      }
    });
    const newGlobalShippingCart = { ...globalShippingCart, shipments: newShipmentsData };
    setGlobalShippingCart(newGlobalShippingCart);
  };

  const getShipmentDataForOfficeInCountry = (countryId: number, productIds: number[]) => {
    if (typeof countryId !== 'number' || !productIds || productIds.length <= 0) return;
    const shipments = globalShippingCart?.shipments || [];
    const officeInCountryShipmentData = shipments.find(
      shipmentItem =>
        shipmentItem.countryId === countryId &&
        productIds.includes(+shipmentItem.productId) &&
        shipmentItem.destinationIsOffice
    );
    const hasReceiverInformation =
      officeInCountryShipmentData?.receiverInformation?.receiverName &&
      officeInCountryShipmentData?.receiverInformation?.receiverPhone;
    return hasReceiverInformation
      ? {
          receiverName: officeInCountryShipmentData?.receiverInformation?.receiverName || '',
          receiverPhone: officeInCountryShipmentData?.receiverInformation?.receiverPhone || '',
          receiverIdDocument: `${officeInCountryShipmentData?.receiverInformation?.personalId || ''}`
        }
      : undefined;
  };

  const getThisCountryOfficeData = async (countryId?: number | string) => {
    if (!orgId || !countryId || !productIds || productIds.length <= 0) return;
    setLoadingOfficeAddress(true);
    setSomeCountryOfficeDataIsLoading(true);
    try {
      const thisCountryOfficeData = await getOfficeByCountry(orgId, countryId);
      const shipmentData = getShipmentDataForOfficeInCountry(+countryId, productIds);
      const officeAddressExists =
        typeof thisCountryOfficeData?.office === 'object' &&
        Object.keys(thisCountryOfficeData?.office || {}).length > 0;
      const previousShipmentData = { ...(shipmentData || thisCountryOfficeData?.office?.shipmentInfo) };
      const officeDataIncludingShipmentInfo = {
        ...(officeAddressExists ? thisCountryOfficeData?.office : {}),
        shipmentInfo: previousShipmentData
      };
      if (officeAddressExists) setOfficeAddress(officeDataIncludingShipmentInfo);
      if (thisCountryOfficeData?.warehouse) {
        setCountryWarehouse(thisCountryOfficeData?.warehouse);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingOfficeAddress(false);
      setSomeCountryOfficeDataIsLoading(false);
    }
  };

  const openAddOfficeAddress = async (finishFluxCallbackFunction?: () => void) => {
    if (!checkIfOfficeAddressDataIsComplete(officeAddress)) {
      setShowShipToOfficeModalFlux(true);
      await getThisCountryOfficeData(countryData?.id || undefined);
      finishFluxCallbackFunction && setFinishShipToOfficeFluxCallbackFn(finishFluxCallbackFunction);
    } else {
      finishFluxCallbackFunction && setFinishShipToOfficeFluxCallbackFn(finishFluxCallbackFunction);
      setShowShipToOfficeModalFlux(true);
    }
  };

  const assignDefaultShippingAddress = () => {
    if (globalShippingCart && globalShippingCart?.shipments?.length > 0) {
      const filterShipment = globalShippingCart.shipments.find(shipment => !!shipment.destinationLocationId);
      if (filterShipment) setDefaultAddressShipment(filterShipment);
    }
  };

  const sendEverythingToOneAddress = (status: boolean) => {
    setLoadingShippingCountry(status);
  };

  useEffect(() => {
    if (officeAddress || !countryData?.id) return;
    getThisCountryOfficeData(countryData.id);
  }, [countryData]);

  useEffect(() => {
    if (itemsCart && itemsCart?.products) {
      const countryDetails = itemsCart?.products;
      if (countryDetails) {
        setProductsList(countryDetails);
        setCountryInfo({
          countryName: countryDetails[0]?.countryName || '',
          countryFlag: countryDetails[0]?.countryFlag || '',
          countryNameStringId: countryDetails[0]?.countryNameStringId || ''
        });
      }
    }
  }, []);

  useEffect(() => {
    updateOfficeDestinationShipmentInformation();
  }, [officeAddress, everyOneOffice]);

  useEffect(() => {
    assignDefaultShippingAddress();
  }, [globalShippingCart]);

  return (
    <div id="shippingCountry" className="extraShippingCountry">
      {!!officeAddress && (
        <ShippingCountryHeader
          officeAddress={officeAddress}
          loadingOfficeAddress={loadingOfficeAddress}
          everyOneOffice={everyOneOffice}
          setEveryOneOffice={setEveryOneOffice}
          countryInfo={countryInfo}
          openAddOfficeAddress={openAddOfficeAddress}
          sendEverythingToOneAddress={sendEverythingToOneAddress}
        />
      )}
      {!loadingShippingCountry &&
        !loadingOfficeAddress &&
        !!countryProductsList &&
        countryProductsList.length > 0 &&
        countryProductsList.map((item, index) => {
          return (
            <ShippingReference
              key={`country-reference-id-${item.productId}-country-${item.countryId}-${index}`}
              defaultOfficeAddress={everyOneOffice}
              officeAddress={officeAddress}
              openAddOfficeAddress={openAddOfficeAddress}
              setEveryOneOffice={setEveryOneOffice}
              referenceData={item}
              countryName={storeCountry?.countryName || ''}
              everyOneOffice={everyOneOffice}
              referenceInitialShipmentsData={getReferenceShipmentDataForThisCountry(item.productId)}
              globalShippingCartWasPreloaded={globalShippingCartWasPreloaded}
              countryWarehouse={countryWarehouse}
              completeListOfCountries={completeListOfCountries}
            />
          );
        })}
      {(loadingShippingCountry || loadingOfficeAddress) && (
        <div className="w-full h-52 flex items-center content-center justify-center">
          <GenericLoader sizeLoader={25} borderWidth={3} />
        </div>
      )}
      {showShipToOfficeModalFlux && countryData && (
        <ShipToOfficeModalFlux
          setShowShipToOfficeModalFlux={setShowShipToOfficeModalFlux}
          listOfCountries={listOfCountriesOpenForBuying}
          officeAddress={officeAddress}
          setOfficeAddress={setOfficeAddress}
          finishShipToOfficeFluxCallbackFn={finishShipToOfficeFluxCallbackFn}
          preselectedOfficeCountry={{
            code: countryData.countryCode,
            flag: countryData?.countryFlag,
            id: countryData.id,
            name: countryData.countryName
          }}
        />
      )}
    </div>
  );
};

export default ShippingCountry;
