import ContainerModules from '../../../components/containerModules';
import './DisabledModule.scss';
const DisabledModule = () => {
  return (
    <ContainerModules>
      <div id="disabledModule">
        <div className="textDisabledModule">
          Suscríbete a la versión Lite
          <br /> para desbloquear el modulo.{' '}
        </div>
      </div>
    </ContainerModules>
  );
};

export default DisabledModule;
