import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_7036_194971)">
      <path
        d="M0.584961 7.42969V8.1089C0.584961 8.46919 0.728082 8.81476 0.982847 9.06954C1.23761 9.32426 1.58314 9.4674 1.94343 9.4674H8.05655C8.41684 9.4674 8.76234 9.32426 9.01712 9.06954C9.27184 8.81476 9.41498 8.46919 9.41498 8.1089V7.42969"
        stroke={props?.stroke || 'white'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.64014 4.36719C3.10295 5.21897 3.79227 5.99521 4.68027 6.53208C4.87694 6.65098 5.12292 6.65097 5.31958 6.53206C6.20755 5.99515 6.89686 5.21895 7.35969 4.36719"
        stroke={props?.stroke || 'white'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 6.61844V0.710938" stroke={props?.stroke || 'white'} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_7036_194971">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MinDownloadIcon = memo(ForwardRef);
export default MinDownloadIcon;
