import { employeeAndInventoryManagement } from '../../../../assets/images/views/signUp';
import { useTranslation } from 'react-i18next';
import './SignUpLeftAside.scss';

const SignUpLeftAside = () => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'authentication:SignUpForm:signUpLeftAside:';
  return (
    <aside className="signUpLeftAside">
      <div className="title">
        <div className="line1">{t(`${i18nLanguageKey}line1`)}</div>
        <div className="line2">{t(`${i18nLanguageKey}line2`)}</div>
        <div className="line3">
          {t(`${i18nLanguageKey}line3`)} <div className="nudos">Nudos Y OnePoint</div>
        </div>
      </div>
      <div className="subtitle">{t(`${i18nLanguageKey}subtitle`)}</div>
      <div className="imageContainer">
        <img src={employeeAndInventoryManagement} alt="Control de inventarios y de empleados" />
      </div>
    </aside>
  );
};

export default SignUpLeftAside;
