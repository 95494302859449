import { useTranslation } from 'react-i18next';
import {
  NudosButton,
  NudosDatePicker,
  NudosGenericModal,
  NudosModalContent,
  NudosTextInput
} from '../../../../../../../../components/NudosComponents';
import { InudoInfo, Tany } from '../../../../../../../../types/global';
import { useEffect, useState } from 'react';
import AdquisitionType from '../../../../../../../../components/productDetails/adquisitionType';
import Bulb from '../../../../../../../../assets/images/components/newCard/bulb';
import { NoSmile } from '../../../../../../../../assets/images/components/floatForm';
import Cart from '../../../../../../../../assets/images/components/newCard/cart';
import { formatDateToPicker } from '../../../../../../../../utils/formatDatesAndHours';
import './EditAcquisitionDataModal.scss';

interface IProductCondition {
  value: number;
  name: string;
}

const ToolEditAcquisitionDataModal = ({
  closeModalCallback,
  searchedAcquisition,
  updateToolDataCallback
}: {
  closeModalCallback: () => void;
  searchedAcquisition: InudoInfo | undefined;
  updateToolDataCallback: (data: Tany) => void;
}) => {
  const EditAcquisitionModalContent = () => {
    const { t } = useTranslation();
    const [toolEndDate, setToolEndDate] = useState<string>();
    const [toolWarranty, setToolWarranty] = useState<string>();
    const [toolSalesValue, setToolSalesValue] = useState<string>();
    const [toolMonthlyValue, setToolMonthlyValue] = useState<string>();
    const [toolAcquisitionDate, setToolAdquisitionDate] = useState<string>();
    const [toolAcquisitionType, setToolAcquisitionType] = useState<IProductCondition>();

    const isExternalTool = searchedAcquisition?.referenceModel === 'ExternalProduct';
    const toolTimeZone = searchedAcquisition?.country?.timeZone || undefined;
    const i18nLanguage = 'nodi:toolInternal:NodiDetailsHeader:';
    const isNumberRegex = /^[0-9]+(.[0-9]+)?$/;
    const invalidSaleValueError = toolSalesValue && !isNumberRegex.test(toolSalesValue) ? 'Solo números' : undefined;

    const isSale = toolAcquisitionType && toolAcquisitionType.value === 2;
    const isSubscripton = toolAcquisitionType && toolAcquisitionType.value === 1;

    const toolAcquisitionTypeList = [
      { icon: <Bulb stroke="#383838" />, value: 1, name: 'Renta' },
      { icon: <Cart stroke="#383838" />, value: 2, name: 'Compra' }
    ];

    const assignAcquisitionType = (type: string) => {
      const acquisitionType = toolAcquisitionTypeList.find(element => element.name === type);
      if (acquisitionType) setToolAcquisitionType(acquisitionType);
    };

    const updateToolData = () => {
      const externalProduct = {
        endDate: toolEndDate || null,
        warranty: toolWarranty || null,
        monthlyValue: toolMonthlyValue ? parseFloat(toolMonthlyValue) : null,
        purchaseValue: toolSalesValue ? parseFloat(toolSalesValue) : null,
        acquisitionDate: toolAcquisitionDate || null,
        acquisitionType: toolAcquisitionType?.name || null
      };
      if (updateToolDataCallback) updateToolDataCallback(externalProduct);
    };

    useEffect(() => {
      searchedAcquisition?.endDay && setToolEndDate(searchedAcquisition?.endDay);
      searchedAcquisition?.warranty && setToolWarranty(searchedAcquisition?.warranty);
      searchedAcquisition?.monthlyValueUsd && setToolMonthlyValue(String(searchedAcquisition?.monthlyValueUsd));
      searchedAcquisition?.acquisitionType && assignAcquisitionType(searchedAcquisition?.acquisitionType);
      searchedAcquisition?.acquisitionDate && setToolAdquisitionDate(searchedAcquisition?.acquisitionDate);
      searchedAcquisition?.saleValueUsd && setToolSalesValue(String(searchedAcquisition?.saleValueUsd));
    }, [searchedAcquisition]);

    if (!isExternalTool) return <></>;
    return (
      <div className="toolEditAcquisitionDataModal">
        <div className="toolReferenceModel">
          <div className="containerIcon">
            <div className="centerContainerIcon">{<NoSmile />}</div>
          </div>
          <div className="containerText">{t(`${i18nLanguage}statusContainer:external`)}</div>
        </div>

        <div className="toolExternalEditAcquisitionDataContainer">
          <NudosDatePicker
            label={t(`${i18nLanguage}floatForm:dateContainer`)}
            fontSize={10}
            xPadding={8}
            isFilled={!!toolAcquisitionDate}
            inputHeight={28}
            defaultValue={formatDateToPicker(toolAcquisitionDate)}
            customClassName="editToolField toolDatePicker"
            desiredTimeZone={toolTimeZone}
            updateCurrentValueCallback={e => setToolAdquisitionDate(e)}
          />
          <div>
            <div>Tipo de adquisición</div>
            <AdquisitionType
              enabled
              selectList={toolAcquisitionTypeList}
              changeValue={e => setToolAcquisitionType(e)}
              defaultValue={toolAcquisitionType}
            />
          </div>

          {isSale && (
            <>
              <NudosTextInput
                label={t(`${i18nLanguage}floatForm:warrantyInput`)}
                isFilled={!!toolWarranty}
                placeholder="3 años"
                inputHeight={28}
                defaultValue={toolWarranty}
                inputPadding={11}
                handleChange={e => setToolWarranty(!e ? '' : String(e))}
                customClassName="editToolField toolWarrantyTextInput"
              />
              <NudosTextInput
                label={t(`${i18nLanguage}floatForm:purchaseValue`)}
                isFilled={!!toolSalesValue}
                placeholder="$ 0.00 USD"
                inputHeight={28}
                defaultValue={toolSalesValue}
                inputPadding={11}
                handleChange={e => setToolSalesValue(String(e))}
                customClassName="editToolField toolCostTextInput"
                errorText={invalidSaleValueError}
              />
            </>
          )}

          {isSubscripton && (
            <>
              <NudosTextInput
                label={t(`${i18nLanguage}floatForm:containerAdquisitionDate:title`)}
                placeholder="$ 0.00 USD"
                isFilled={!!toolMonthlyValue}
                inputHeight={28}
                defaultValue={toolMonthlyValue}
                inputPadding={11}
                handleChange={e => setToolMonthlyValue(String(e))}
                customClassName="toolMonthlyValueTextInput"
              />
              <NudosDatePicker
                label={t(`${i18nLanguage}floatForm:containerAdquisitionDate:NudosDatePicker`)}
                fontSize={10}
                xPadding={8}
                isFilled={!!toolEndDate}
                inputHeight={28}
                defaultValue={toolEndDate}
                customClassName="toolDatePicker"
                desiredTimeZone={toolTimeZone}
                updateCurrentValueCallback={e => setToolEndDate(e)}
              />
            </>
          )}
        </div>

        <NudosButton
          buttonText="Guardar"
          handleClick={updateToolData}
          customClassName="customButton"
          isButtonDisabled={!!invalidSaleValueError}
        />
      </div>
    );
  };

  return (
    <div>
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            customWidth="466px"
            customHeight="220px"
            toCloseModal={closeModalCallback}
            excludeHeader={true}
            CustomModalComponent={<EditAcquisitionModalContent />}
          />
        }
      />
    </div>
  );
};

export default ToolEditAcquisitionDataModal;
