import { IconWarning } from '../../../../assets/DesignSystem/SVGComponents';
import { NudosButton, NudosGenericModal, NudosModalContent } from '../../../../components/NudosComponents';
import './LogisticServicePaymentModalInformation.scss';

const LogisticServicePaymentModalInformation = ({ closeModalCallback }: { closeModalCallback: () => void }) => {
  const PaymentModalData = () => {
    return (
      <div className="LogisticServicePaymentModalInformation">
        <div className="iconContainer">
          <IconWarning className="iconWarning" />
        </div>
        <p className="paymentModalText">
          Los servicios logísticos son facturados desde USA y cobrados en USD a través del link de pago que recibirás en
          tu correo al finalizar el servicio
        </p>
        <NudosButton
          buttonText="Entendido"
          handleClick={closeModalCallback}
          customWidth="180px"
          customHeight="38px"
          customClassName="modalNudosButton"
        />
      </div>
    );
  };

  return (
    <div>
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            customWidth="466px"
            customHeight="232px"
            excludeHeader={true}
            CustomModalComponent={<PaymentModalData />}
          />
        }
      />
    </div>
  );
};

export default LogisticServicePaymentModalInformation;
