import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11} height={11} fill="none" ref={ref} {...props}>
    <path d="M8.09375 3.04688L2.46875 8.67186" stroke="#999999" strokeLinecap="round" />
    <path d="M8.09375 8.67186L2.46875 3.04688" stroke="#999999" strokeLinecap="round" />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconSimpleX = memo(ForwardRef);
export default IconSimpleX;
