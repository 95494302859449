import { useHistory } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { BackArrowIcon } from '../../../../assets/images/views/employeesLoad';
import { NudosGenericModal, NudosSearchBar } from '../../../../components/NudosComponents';
import { IconArrow, IconInformation, IconLocation } from '../../../../assets/DesignSystem/SVGComponents';
import { getCountryName, getListOfCountriesByOfferedService } from '../../../../utils/getCountries';
import { ICountryDetail } from '../../../../types/countries';
import { GenericLoader } from '../../../Admin/dashboard/components';
import { MagnifyingGlass, World } from '../../../../assets/DesignSystem/images';
import useStoreCountryState from '../../../../state/useStoreCountryState';
import useShopStore from '../../../../state/useShopContext';
import { formatOrgData } from '../../../../utils/orgFormatData';
import useCheckoutStore from '../../checkout/state';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../utils/segment';
import useStateSubscriptions from '../../../../state/useStateSubscriptions';
import { handleChangeCountryStore } from '../../../../utils/statusCart';
import { productsNumber } from '../../../../utils/cart';
import useCartState from '../../../../state/useCartState';
import './ModalToSelectStoreCountry.scss';

interface IModalToSelectStoreCountry {
  closeModalExternally?: () => void;
  countryName?: string;
  origin: 'ecommerce' | 'flowToChangeCountryStore';
}
const ModalToSelectStoreCountry: FC<IModalToSelectStoreCountry> = ({
  closeModalExternally,
  countryName,
  origin
}: IModalToSelectStoreCountry) => {
  const { push } = useHistory();
  const { itemsCart, setCart } = useShopStore();
  const orgDetails = formatOrgData();
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();
  const productsTotalNumber = productsNumber();
  const { setGlobalShippingCart } = useCartState();
  const { setStoreCountry, setInitialCountryTooltip } = useStoreCountryState();
  const { step: checkoutStep, setStep } = useCheckoutStore();

  const [searchCountry, setSearchCountry] = useState<string>('');
  const [loadingChangeCountry, setLoadingChangeCountry] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCountriesAvailable, setTotalCountriesAvailable] = useState<ICountryDetail[]>();
  const [filterCountries, setFilterCountries] = useState<ICountryDetail[]>([]);
  const primePlatform = stateSubscription?.levelSubscription === 'Lite' || 'Prime';
  const translations = 'ecommerce:modalToSelectStoreCountry:';

  const modalEvent = async () => {
    segmentTrackEvent({ catalogueCountryView: { TypeOfPlatform: primePlatform ? 'Prime' : 'Free' } });
  };

  const getCountriesList = async () => {
    setLoading(true);
    modalEvent();
    await getListOfCountriesByOfferedService(setTotalCountriesAvailable, 'buy');
    setLoading(false);
  };

  const removeAccents = (searchText: string) => {
    return searchText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const searchCountries = (search: string) => {
    if (totalCountriesAvailable) {
      const filterList = totalCountriesAvailable?.filter(country =>
        removeAccents(country?.name?.toLowerCase())?.includes(removeAccents(search?.toLowerCase()))
      );
      setFilterCountries([...filterList]);
    }
  };

  const resetCheckoutStep = () => checkoutStep !== 0 && setStep(0);

  const changeCountryStore = (item: ICountryDetail) => {
    if (item && item?.nameStringId && !loadingChangeCountry) {
      if (closeModalExternally && item) {
        setLoadingChangeCountry(item.id);
        handleChangeCountryStore({
          countryCode: item.code,
          selectedCountryInformation: {
            countryCode: item.code,
            countryFlag: item.flag,
            id: item.id,
            countryName: item.name,
            nameStringId: item.nameStringId || ''
          },
          organizationId: Number(orgDetails?.organizationId),
          shoppingCartId: Number(itemsCart?.shoppingCartId),
          userId: Number(orgDetails?.userId),
          setLoadingChangeCountry,
          orgDetails,
          itemsCart,
          productsTotalNumber,
          setCart,
          setGlobalShippingCart,
          setStoreCountry,
          resetCheckoutStep,
          setInitialCountryTooltip,
          closeModalExternally,
          origin
        });
      }
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  useEffect(() => {
    searchCountries(searchCountry);
  }, [searchCountry, totalCountriesAvailable]);

  return (
    <>
      <NudosGenericModal
        modalContent={
          <div id="modalToSelectStoreCountry">
            <div
              className="backButtonModalContainer"
              onClick={() => {
                if (closeModalExternally) {
                  closeModalExternally();
                } else {
                  push('/nodi/dashboard');
                }
              }}
            >
              <div className="iconBackButton">
                <BackArrowIcon fill="#383838" style={{ transform: 'scale(0.8)' }} />
              </div>
              <div className="textBackButton">
                {`${
                  closeModalExternally && countryName
                    ? `${t(`${translations}backButton`)} ${countryName}`
                    : t(`${translations}backDashboard`)
                }`}
              </div>
            </div>
            <div className="imgModalContainer">
              <div className="imgWorldBox">
                <img src={World} />
              </div>
            </div>
            <div className="titleModalContainer">
              <div className="orangeText">{t(`${translations}title`)}</div>
            </div>
            <div className="subtitleModalContainer">
              <div className="blackText">{t(`${translations}subtitle`)}</div>
            </div>
            <div className="searcherContainer">
              {loading ? (
                <div className="loaderSearcherContainer">
                  <GenericLoader />
                </div>
              ) : (
                <>
                  <div className="searchBarContainerModal">
                    <div className="searchBox">
                      <NudosSearchBar
                        handleChange={e => setSearchCountry(e)}
                        customSearchBarContainer="customBorder"
                        placeholder={t(`${translations}searBarPlaceHolder`)}
                      />
                    </div>
                  </div>
                  <div className="resultsContainer">
                    <div className="boxResultsContainer">
                      <div className="paddingContainerBox">
                        {filterCountries &&
                          filterCountries.length > 0 &&
                          filterCountries?.map((item, index) => {
                            const totalItems = filterCountries.length;
                            const countryNumberInTheList = index + 1;
                            const lastCountry = totalItems > 4 && countryNumberInTheList === totalItems;
                            return (
                              <div
                                key={`country-buy-${item.id}-${index}`}
                                className={`countrySelectContainer ${lastCountry ? 'lastCountry' : ''}`}
                                onClick={() => changeCountryStore(item)}
                              >
                                <div className="boxCountry">
                                  {loadingChangeCountry === item.id ? (
                                    <div className="w-full h-full flex items-center content-center justify-center">
                                      <GenericLoader sizeLoader={10} borderWidth={2} />
                                    </div>
                                  ) : (
                                    <>
                                      <div className="countryFlagContainer">
                                        <div
                                          style={{ backgroundImage: `url("${item.flag}")` }}
                                          className="adjustFlag"
                                        />
                                      </div>
                                      <div className="countryName">{getCountryName(item)}</div>
                                      <div className="floatBlueFlag">
                                        <IconArrow stroke="#54A1FC" />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        {!!searchCountry && filterCountries?.length <= 2 && (
                          <div
                            className={filterCountries?.length === 0 ? 'notFoundContainerEmpty' : 'notFoundContainer'}
                          >
                            <div className="notFoundBox">
                              <div className="notFoundImg">
                                <div className="noFoundBoxImg">
                                  <img src={MagnifyingGlass} />
                                </div>
                              </div>
                              <div className="notFoundTitle">{t(`${translations}noCountryAlertQuestion`)}</div>
                              <div className="notFoundSubtitle">{t(`${translations}contactSupport`)}</div>
                            </div>
                          </div>
                        )}
                        {filterCountries.length >= 5 && <div className="blurCountry" />}
                      </div>
                    </div>
                  </div>
                  <div className="comingSoonContainer">
                    <div className="firstIconModal">
                      <IconLocation />
                    </div>
                    <div className="minGrayText">{t(`${translations}quote`)}</div>
                    <div className="minOrangeText">{t(`${translations}orangeQuote`)}</div>
                    <div className="secondIconModal">
                      <IconArrow stroke="#CCCCCC" />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="informationContianer">
              <div className="informationIconContainer">
                <IconInformation />
              </div>
              <div className="informationText">{t(`${translations}information`)}</div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default ModalToSelectStoreCountry;
