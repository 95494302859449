import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModuleFilters, {
  TgetFiltersList
} from '../../../../../../components/NudosComponents/ModuleFilters/ModuleFilters';
import { useLogisticsServicesModuleState } from '../../../../../../state/useLogisticsServicesModuleState';
import { ICountryDetail } from '../../../../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../../../../utils/getCountries';
import { capitalize } from '../../../../../../utils/unCamelCase';

const LogisticsServicesFilters = () => {
  const { t } = useTranslation();
  const { logisticsServicesFilters, setLogisticsServicesFilters } = useLogisticsServicesModuleState();

  const [countriesOpenToLogistics, setCountriesOpenToLogistics] = useState<ICountryDetail[]>();
  const [openStatusFilter, setOpenStatusFilter] = useState<boolean>(false);
  const [openOriginCountryFilter, setOpenOriginCountryFilter] = useState<boolean>(false);
  const [openDestinationCountryFilter, setOpenDestinationCountryFilter] = useState<boolean>(false);

  const catalogueFilterMapping = {
    Estado: 'status',
    Origen: 'origin',
    Entrega: 'destination'
  };

  const countryNameToCodeMap: Map<string, string> = countriesOpenToLogistics
    ? new Map(countriesOpenToLogistics?.map(countryData => [countryData.name, countryData.code]))
    : new Map();

  const statusFilterRawOptions = ['por confirmar', 'confirmado', 'activo', 'entregado', 'cancelado'];
  const logisticsCountriesRawOptions =
    countriesOpenToLogistics && countriesOpenToLogistics.length
      ? countriesOpenToLogistics.reduce<string[]>((accum, current) => {
          if (current?.name) accum.push(current.name);
          return accum;
        }, [])
      : undefined;

  const i18nLanguageKey = 'nodi:orderDetails:ordersFilters:';

  const getCatalogueFiltersList: TgetFiltersList = (
    formModuleFilters,
    handleClickFilterOption,
    getFilterActiveStatus
  ) => [
    {
      title: t(`${i18nLanguageKey}status`),
      openFunction: setOpenStatusFilter,
      openStatus: openStatusFilter,
      rawSelectOptions: formModuleFilters('Estado', statusFilterRawOptions),
      handleChangeSelectedOption: handleClickFilterOption('status', 'name'),
      isFilterActive: getFilterActiveStatus('status')
    },
    {
      title: capitalize(t('recurrentWords:origin')) ?? '',
      openFunction: setOpenOriginCountryFilter,
      openStatus: openOriginCountryFilter,
      rawSelectOptions: formModuleFilters('Origen', logisticsCountriesRawOptions, countryNameToCodeMap),
      handleChangeSelectedOption: handleClickFilterOption('origin', 'value'),
      isFilterActive: getFilterActiveStatus('origin')
    },
    {
      title: capitalize(t('recurrentWords:delivery')) ?? '',
      openFunction: setOpenDestinationCountryFilter,
      openStatus: openDestinationCountryFilter,
      rawSelectOptions: formModuleFilters('Entrega', logisticsCountriesRawOptions, countryNameToCodeMap),
      handleChangeSelectedOption: handleClickFilterOption('destination', 'value'),
      isFilterActive: getFilterActiveStatus('destination')
    }
  ];

  useEffect(() => {
    getListOfCountriesByOfferedService(setCountriesOpenToLogistics, 'logisticService');
  }, []);

  return (
    <div className="logisticsServicesFilters">
      <ModuleFilters
        moduleFilters={logisticsServicesFilters}
        setModuleFilters={setLogisticsServicesFilters}
        getFiltersList={getCatalogueFiltersList}
        moduleFiltersTitlesMapping={catalogueFilterMapping}
        filtersContainerStyles={{ gap: '24px' }}
      />
    </div>
  );
};

export default LogisticsServicesFilters;
