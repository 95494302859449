import searchProductController from './searchProduct.controller';
import { useEffect, useState } from 'react';
import useShopStore from '../../state/useShopContext';
import MinMagnifier from '../../assets/images/views/Admin/main/minMagnifer';
import { useTranslation } from 'react-i18next';

import './SearchProduct.scss';

function searchProduct({ customClassName, marginLeft }: { customClassName?: string; marginLeft?: number }) {
  const { t } = useTranslation();
  const { getProductName, handeOnChange } = searchProductController();
  const [barSearch, setBarSearch] = useState('');
  const { searchString } = useShopStore();

  useEffect(() => {
    if (searchString?.length == 0) {
      setBarSearch('');
    }
  }, [searchString]);

  return (
    <div
      className={`productSearchBar newMarginsChangesStore ${customClassName ? customClassName : ''}`}
      style={{ marginLeft: marginLeft ? `${marginLeft}px` : '' }}
    >
      <div className={`containerSearchBar ${barSearch ? 'blackBorder' : 'grayBorder'}`}>
        <div className="iconSearch">
          <MinMagnifier stroke={barSearch ? 'black' : ''} />
        </div>
        <div className="containerInputSearch">
          <input
            type="text"
            placeholder={t('nodi:headBar:search')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handeOnChange(event, setBarSearch)}
            onKeyPress={event => getProductName(event, barSearch)}
            className="myInput"
            value={barSearch}
          />
        </div>
      </div>
    </div>
  );
}
export default searchProduct;
