import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="svg-container"
    width={10}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path d="M1.25 8.75L1.25 1.5625" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.75 8.75L8.75 1.5625" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.25 8.15137L6.25 2.16178" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.75 8.15137L3.75 2.16178" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SerialIcon = memo(ForwardRef);
export default SerialIcon;
