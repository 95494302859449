import './EmployeeDetailsSkeletonLoader.scss';

const EmployeeDetailsSkeletonLoader = () => {
  return (
    <div className="employeeDetailsSkeletonLoader">
      <div className="skeletonEmployeeDetailsHeader">
        <div className="skeletonProfileImage" />
        <div className="skeletonEmployeeDataSection">
          <div className="employeeData" />
          <div className="employeeData" />
          <div className="employeeData" />
        </div>
        <div className="sekeletonActiveStatusSection">
          <div className="activeStatus" />
          <div className="activeStatus" />
          <div className="toolTipSkeleton" />
        </div>
        <div className="skeletonProgressBarSection">
          <div className="skeletonProgressBar1" />
          <div className="skeletonProgressBar2" />
        </div>
      </div>
      {[1, 2, 3, 4, 5, 6].map(cardNumber => {
        return <div key={`skeletonCard${cardNumber}`} className="skeletonCard" />;
      })}
    </div>
  );
};

export default EmployeeDetailsSkeletonLoader;
