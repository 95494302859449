import { memo, useEffect, useState } from 'react';
import useShopStore from '../../state/useShopContext';
import { valueKey } from '../../types/global';
import { capitalize } from '../../utils/unCamelCase';
import CheckFilters from '../../assets/images/components/NudosComponents/checkFilters';
import MinArrowFilter from '../../assets/images/components/customCheck/MinArrowFilter';
import { segmentTrackEvent } from '../../utils/segment';

function _Filter({
  filters,
  name,
  unique = false,
  alias
}: {
  filters: valueKey[];
  name: string;
  unique?: boolean;
  alias?: string;
}) {
  const { filters: stateFilters, setFilters } = useShopStore();
  const _filter = stateFilters.find(item => item.name === name);
  const [checkeds, setCheckeds] = useState<(string | number | undefined)[]>(_filter ? _filter.values : []);
  const [expanded, setExpanded] = useState(false);

  const filterIsCountry = name === 'disponibilidad';
  const filterIsProcessor = name === 'procesador';
  const filterIsMemory = name === 'memoria';
  const filterIsStorage = name === 'almacenamiento';
  const filterIsBrand = name === 'marca';
  const trackEvent =
    !!filterIsProcessor || !!filterIsMemory || !!filterIsStorage || !!filterIsCountry || !!filterIsBrand;

  const getEventName = () => {
    switch (name) {
      case 'memoria':
        return 'storeFilterMemory';
      case 'procesador':
        return 'storeFilterProcessor';
      case 'almacenamiento':
        return 'storeFilterStorage';
      case 'disponibilidad':
        return 'storeFilterCountry';
      case 'marca':
        return 'storeFilterBrand';
      default:
        return '';
    }
  };

  const getEventPropertyName = () => {
    switch (name) {
      case 'memoria':
        return 'MemoryType';
      case 'procesador':
        return 'ProcessorType';
      case 'almacenamiento':
        return 'StorageType';
      case 'disponibilidad':
        return 'Country';
      case 'marca':
        return 'BrandType';
      default:
        return '';
    }
  };

  const eventName = getEventName();
  const eventPropertyName = getEventPropertyName();

  useEffect(() => {
    !_filter && setCheckeds([]);
  }, [stateFilters]);

  useEffect(() => {
    const countryFilterSelected = stateFilters.find(item => item.name === 'disponibilidad');
    if (filterIsCountry && countryFilterSelected) setExpanded(true);
  }, [stateFilters]);

  const setValues = async (value: string | number | undefined) => {
    const valueIndex = checkeds.findIndex(v => `${v}` === `${value}`);
    const _newValues = checkeds;
    if (valueIndex >= 0) {
      _newValues.splice(valueIndex, 1);
    } else {
      _newValues.push(`${value}`);
    }
    !unique && setCheckeds(_newValues);
    unique && checkeds[0] !== `${value}` && setCheckeds([`${value}`]);
    const getPriceRange = () => {
      const filtersPrices = _newValues
        .map(value => typeof value === 'string' && value.split('-'))
        .flat()
        .map(value => +value);
      const minValue =
        filtersPrices.length && filtersPrices.reduce((minimum, current) => (current < minimum ? current : minimum));
      const maxValue =
        filtersPrices.length && filtersPrices.reduce((maximum, current) => (current > maximum ? current : maximum));
      return [minValue, maxValue];
    };
    const paramValues = name === 'precio' ? getPriceRange() : _newValues;
    const newParam = {
      name,
      includes: 'in',
      values: !unique ? paramValues : [`${value}`]
    };
    if (stateFilters.length > 0) {
      const _params = stateFilters;
      const _index = _params.findIndex(param => param.name === name);

      if (_index >= 0) {
        if (_newValues.length === 0) {
          _params.splice(_index, 1);
        } else {
          _params[_index] = newParam;
        }
      } else {
        _params.push(newParam);
      }
      setFilters(_params);
    } else {
      setFilters([newParam]);
    }
  };

  const reset = async () => {
    const _params = stateFilters;
    const _index = _params.findIndex(param => param.name === name);
    _params.splice(_index, 1);
    setFilters(_params);
    setCheckeds([]);
  };

  const formatName = (name: string) => {
    if (name === 'profile') {
      return 'perfil';
    } else if (name === 'line') {
      return 'cargos';
    }
    return name;
  };

  return (
    <div className="side-bar-filter">
      <div className="filter-title" onClick={() => setExpanded(!expanded)}>
        <h2 className={`${checkeds && checkeds.length > 0 ? 'font-extrabold' : ''}`}>
          {capitalize(formatName(alias || name))}
        </h2>
        <MinArrowFilter className={`${expanded ? 'expanded' : ''}`} />
      </div>
      {expanded && (
        <div className="filter-options-container">
          {filters.map(({ label, value }) => {
            const _setValues = () => {
              setValues(value);
              checkeds.includes(`${value}`) &&
                trackEvent &&
                segmentTrackEvent({
                  [eventName]: {
                    [eventPropertyName]: filterIsCountry || filterIsBrand ? label : value
                  }
                });
            };
            return (
              <div className="filter-option" onClick={_setValues} key={`${name}-${label}-${value}`}>
                <CheckFilters role={checkeds.includes(`${value}`) ? 'active' : 'inactive'} />
                <label className="filteaLabel">{label}</label>
              </div>
            );
          })}
        </div>
      )}
      {unique && (
        <label className="cursor-pointer" onClick={reset}>
          Reset
        </label>
      )}
    </div>
  );
}
const Filter = memo(_Filter);
export default Filter;
