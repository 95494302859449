import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" ref={ref} {...props}>
    <path
      stroke="#383838"
      strokeWidth={0.5}
      d="m4.615 7.415.095-.321H1.875c-.18 0-.375-.175-.375-.453V1.953c0-.278.195-.453.375-.453h6.25c.18 0 .375.175.375.453v4.688c0 .278-.195.453-.375.453H5.29l.095.32.209.704.053.179H6.77c.007 0 .02.002.034.019a.124.124 0 0 1 .028.082.124.124 0 0 1-.028.083c-.015.016-.027.019-.034.019H3.229c-.006 0-.019-.002-.034-.02a.124.124 0 0 1-.028-.082c0-.036.013-.064.028-.082.015-.017.028-.02.034-.02H4.353l.053-.178.209-.703Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const MonitorIcon = memo(ForwardRef);
export default MonitorIcon;
