import { Store } from 'react-notifications-component';
import NudosNotification from '../components/NudosNotification';
import { useTranslation } from 'react-i18next';
import activateNotify from './activeNotify';

/**
 * IMPORTANT: line heights must be in the html structure of the customJSXmessage. If a string is to be passed it should be within <></>
 * @property {JSX.Elemen} customJSXMessage - A JSXElement to display as message. To passed a string include it in a fragment <></>
 * @property {string} button1Text - The text to show on the first button
 * @property {()=>void} button1Action - The callback to execute onClick the first button
 * @property {string} button2Text - The text to show on the second button
 * @property {()=>void} button2Action - The callback to execute onClick the second button
 * @property {number} duration - Optional: The time the notification must be displayed, in milliseconds. By default the notification is active until the user clicks on it to close it
 */
const displaySuccessNotification = (params?: IdisplayNudosNotificationParams) => {
  Store.removeAllNotifications();
  const { button1Text, button2Text, duration, customJSXMessage, button1Action, button2Action } = params || {};
  const notificationHasActions = !!button1Text || !!button2Text;
  const notificationDuration = notificationHasActions ? duration : 5000;

  const greenNotificationMessage = (
    <div className="messageContainer">
      <div className="message">{customJSXMessage}</div>
    </div>
  );
  activateNotify(
    <NudosNotification
      className="nudosSuccessNotification"
      message={greenNotificationMessage}
      button1Text={button1Text}
      button1Action={button1Action}
      button2Text={button2Text}
      button2Action={button2Action}
    />,
    notificationDuration
  );
};

/**
 * IMPORTANT: if no customJSXMessage is passed, the message shown is the genericErrorMessage
 * IMPORTANT: line heights must be in the html structure of the customJSXmessage. If a string is to be passed it should be within <></>
 * @property {JSX.Elemen} customJSXMessage - A JSXElement to display as message. To passed a string include it in a fragment <></>.
 * @property {string} button1Text - The text to show on the first button
 * @property {()=>void} button1Action - The callback to execute onClick the first button
 * @property {string} button2Text - The text to show on the second button
 * @property {()=>void} button2Action - The callback to execute onClick the second button
 * @property {number} duration - Optional: The time the notification must be displayed, in milliseconds. By default the notification is active until the user clicks on it to close it
 */
const displayErrorNotification = (params?: IdisplayNudosNotificationParams) => {
  Store.removeAllNotifications();
  const redNotificationMessage = (
    <div className="messageContainer">
      <div className="message">{params?.customJSXMessage || <DefaultMessage />}</div>
    </div>
  );
  activateNotify(
    <NudosNotification
      className="nudosSuccessNotification"
      errorNotification
      message={redNotificationMessage}
      button1Text={params?.button1Text}
      button1Action={params?.button1Action}
      button2Text={params?.button2Text}
      button2Action={params?.button2Action}
    />,
    params?.duration
  );
};

const DefaultMessage = () => {
  const { t } = useTranslation();
  return <>{t('recurrentWords:genericErrorMessage')}</>;
};

export { displaySuccessNotification, displayErrorNotification };

export interface IdisplayNudosNotificationParams {
  customJSXMessage?: JSX.Element;
  duration?: number;
  button1Text?: string;
  button1Action?: () => void;
  button2Text?: string;
  button2Action?: () => void;
}
