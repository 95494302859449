import { useTranslation } from 'react-i18next';
import { CartIcon, MoneyIcon } from '../../../../../assets/images/views/Ecommerce';
import { NudosStepsProgressBar } from '../../../../../components/NudosComponents';
import { DeliveryAddressIcon, SuccessIcon } from '../SVGcomponents';

import './CheckoutProgressBar.scss';

/**
 * @property  { number } currentStep - The current step of the checkout
 */
const CheckoutProgressBar = ({ currentStep }: { currentStep: number }) => {
  const { t } = useTranslation();

  const progressSteps = [
    {
      order: 0,
      stepContent: (
        <div className="checkoutProgressStepContent">
          <CartIcon className="stepIcon shoppingCartIcon" />
          {currentStep === 0 && <div className="stepText">{t('recurrentWords:cart')}</div>}
        </div>
      ),
      stepWidth: currentStep === 0 ? 68 : 24,
      customStepStyles: undefined
    },
    {
      order: 1,
      stepContent: (
        <div className="checkoutProgressStepContent">
          <DeliveryAddressIcon className={`stepIcon deliveryAddressIcon ${currentStep >= 1 ? 'active' : ''}`} />
          {currentStep === 1 && <div className="stepText">{t('recurrentWords:shipments')}</div>}
        </div>
      ),
      stepWidth: currentStep === 1 ? 68 : 24,
      customStepStyles: undefined
    },
    {
      order: 2,
      stepContent: (
        <div className="checkoutProgressStepContent">
          <MoneyIcon className={`stepIcon moneyIcon ${currentStep >= 2 ? 'active' : ''}`} />
          {currentStep === 2 && <div className="stepText">{t('recurrentWords:payments')}</div>}
        </div>
      ),
      stepWidth: currentStep === 2 ? 68 : 24,
      customStepStyles: undefined
    },
    {
      order: 3,
      stepContent: (
        <div className="checkoutProgressStepContent">
          <SuccessIcon className={`stepIcon successIcon ${currentStep >= 3 ? 'active' : ''}`} />
          {currentStep === 3 && <div className="stepText">{t('recurrentWords:summary')}</div>}
        </div>
      ),
      stepWidth: currentStep === 3 ? 68 : 24,
      customStepStyles: undefined
    }
  ];

  return (
    <div className="checkoutProgressBar extraStylesProgressBar">
      <NudosStepsProgressBar
        steps={progressSteps}
        currentStep={currentStep}
        firstAndLastConectorWidth={40}
        intermediateStepsConectorWidth={56}
        customHeight={24}
      />
    </div>
  );
};

export default CheckoutProgressBar;
