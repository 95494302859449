import { useTranslation } from 'react-i18next';
import useShopStore from '../../../../state/useShopContext';
import useStore from '../../../../state';
import useAssignationState from '../../../../views/Admin/assignation/state/Assignation.state';
import useStateOrders from '../../../../state/useStateOrders';
import { useLogisticsServicesModuleState } from '../../../../state/useLogisticsServicesModuleState';

const useHeaderNavigationRoutes = () => {
  const { t } = useTranslation();
  const { nudos } = useShopStore();
  const { employeesCount } = useStore();
  const { numberOrders } = useStateOrders();
  const { logisticsServicesNumber } = useLogisticsServicesModuleState();
  const { step: assignationStep } = useAssignationState();

  const i18nLanguageKey = 'nodi:headBar:';

  const headerNavigationRoutes: { [key: string]: IheaderNavigationRoute } = {
    '/nodi/tools': {
      title: `${t(`${i18nLanguageKey}headerTextBold:tools`)} - ${nudos?.length || 0}`,
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/details': {
      title: t(`${i18nLanguageKey}headerTextBold:tools`),
      subtitle: t(`${i18nLanguageKey}headerTextRegular:detail`),
      returnPath: '/nodi/tools',
      returnAction: undefined
    },
    '/nodi/assignation': {
      title: 'Asignación',
      subtitle: assignationStep ? t(`${i18nLanguageKey}headerTextRegular:createEmployee`) : '',
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/employees': {
      title: `${t(`${i18nLanguageKey}headerTextBold:employee`)} - ${employeesCount || 0}`,
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/dashboard': {
      title: 'Dashboard',
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/account': {
      title: t(`${i18nLanguageKey}headerTextBold:account`),
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/employees/details': {
      title: t(`${i18nLanguageKey}headerTextBold:employee`),
      subtitle: t(`${i18nLanguageKey}headerTextRegular:employeeDetails`),
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/employees/create': {
      title: t(`${i18nLanguageKey}headerTextBold:employee`),
      subtitle: t(`${i18nLanguageKey}headerTextRegular:createEmployee`),
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/employees/edit': {
      title: t(`${i18nLanguageKey}headerTextBold:employee`),
      subtitle: t(`${i18nLanguageKey}headerTextRegular:editEmployee`),
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/inactive': {
      title: '',
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/upload-external-tools': {
      title: t(`${i18nLanguageKey}headerTextBold:tools`),
      subtitle: t(`${i18nLanguageKey}headerTextRegular:uploadExternalTools`),
      returnPath: undefined,
      returnAction: undefined
    },
    '/upload-external-tools/summary': {
      title: t(`${i18nLanguageKey}headerTextBold:tools`),
      subtitle: t(`${i18nLanguageKey}headerTextRegular:uploadExternalTools`),
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/upload-employees': {
      title: t(`${i18nLanguageKey}headerTextBold:employee`),
      subtitle: t(`${i18nLanguageKey}headerTextRegular:uploadEmployees`),
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/upload-employees/summary': {
      title: t(`${i18nLanguageKey}headerTextBold:employee`),
      subtitle: t(`${i18nLanguageKey}headerTextRegular:uploadEmployees`),
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/orders': {
      title: `${t(`${i18nLanguageKey}headerTextBold:orders`)} - ${numberOrders || 0}`,
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/orders/details': {
      title: t(`${i18nLanguageKey}headerTextBold:orders`),
      subtitle: t(`${i18nLanguageKey}headerTextRegular:orderDetail`),
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/logistics-services': {
      title: `${t(`${i18nLanguageKey}headerTextBold:logisticService`)} - ${logisticsServicesNumber || 0}`,
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/logistics-services/details': {
      title: t(`${i18nLanguageKey}headerTextBold:logisticService`),
      subtitle: t(`${i18nLanguageKey}headerTextBold:serviceDetails`),
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/request-logistics/unassignment': {
      title: 'Offboarding',
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/request-logistics/assignment': {
      title: '',
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/request-logistics/tools-management': {
      title: '',
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/request-logistics/success': {
      title: '',
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/nodi/faqs': {
      title: 'FAQs',
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/catalogue/home': {
      title: t(`${i18nLanguageKey}buttonStore`),
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/catalogue/checkout': {
      title: '',
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/catalogue/product/:id': {
      title: t(`${i18nLanguageKey}buttonStore`),
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/catalogue/product': {
      title: t(`${i18nLanguageKey}buttonStore`),
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    },
    '/catalogue/search': {
      title: t(`${i18nLanguageKey}buttonStore`),
      subtitle: undefined,
      returnPath: undefined,
      returnAction: undefined
    }
  };

  return { headerNavigationRoutes };
};

export default useHeaderNavigationRoutes;

export interface IheaderNavigationRoute {
  title: string;
  subtitle?: string;
  returnPath?: string;
  returnAction?: (...args: (string | number | boolean)[]) => void;
}
