import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import EmployeeCard from './UploadedEmployeCard/UploadedEmployeCard';
import ContainerModules from '../../../../components/containerModules';
import { successArrowLoad } from '../../../../assets/images/views/Admin/successLoad';
import { NudosButton } from '../../../../components/NudosComponents';
import { displaySuccessNotification } from '../../../../utils/displayNudosStandardNotifications';
import useEmployeesUploadingState from '../../../../state/useEmployeesUploadingState';
import { ICountryDetail } from '../../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../../utils/getCountries';
import './UploadEmployeesSummary.scss';

const UploadEmployeesSummary = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { uploadedEmployeesData } = useEmployeesUploadingState();
  const [infoCountriesList, setInfoCountriesList] = useState<ICountryDetail[]>();

  const { totalRegistries = 0, registryWithErrors = 0, errors } = uploadedEmployeesData || {};
  const totalSuccessfullyLoadedEmployees = totalRegistries - registryWithErrors;
  const i18nLanguageKey = 'nodi:employee:uploadEmployeesSummary:';

  useEffect(() => {
    getListOfCountriesByOfferedService(setInfoCountriesList);
    if (!uploadedEmployeesData) push('/nodi/dashboard');
  }, []);

  return (
    <ContainerModules fullScreen>
      <div id="uploadEmployeesSummary">
        <div className="centeringContainer">
          <div className="arrowHeader">
            <img src={successArrowLoad} />
          </div>
          <div className="textSuccess">{t(`${i18nLanguageKey}title`)}</div>
          <div className="totalRegistries">{`${totalSuccessfullyLoadedEmployees}/${totalRegistries}`}</div>
          <div className="containerButtonSuccess">
            <div className="boxButtonSuccess">
              <NudosButton
                handleClick={() => {
                  if (totalRegistries > registryWithErrors) {
                    displaySuccessNotification({
                      customJSXMessage: <>Los empleados se han cargado correctamente</>
                    });
                  }
                  push('/nodi/employees');
                }}
                buttonText={t(`${i18nLanguageKey}button`)}
              />
            </div>
          </div>
          {registryWithErrors > 0 && (
            <>
              <div className="noLoadTitle employeesTitle">
                <div className="orangeTitle">Estos empleados no se pudieron cargar</div>
                <div className="blackSubtitle">
                  Recuerda que debes llenar toda la información y que los correos no deben existir en la plataforma.
                </div>
              </div>
              {errors && errors?.length > 0 && (
                <div className="containerEmployeesLoad">
                  <div className="floatTotal">
                    <strong>{registryWithErrors}</strong>&nbsp; errores en total
                  </div>
                  <div className="containerNoLoadEmployees">
                    <div className="headerList">
                      <div className="nameList generalListLines">Nombre</div>
                      <div className="lastNameList generalListLines">Apellido</div>
                      <div className="emailList generalListLines">Correo</div>
                      <div className="ladaList generalListLines">Indica.</div>
                      <div className="phoneList generalListLines">Número celular</div>
                      <div className="areaList generalListLines">Área</div>
                      <div className="positionList generalListLines">Cargo</div>
                      <div className="countryList generalListLines">País</div>
                      <div className="nameList generalListLines" style={{ borderRight: 'none' }}>
                        Ciudad
                      </div>
                    </div>
                    <div className="bodyList">
                      {errors &&
                        errors.length > 0 &&
                        errors.map(item => {
                          return <EmployeeCard infoCountriesList={infoCountriesList} data={item} key={uuid()} />;
                        })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </ContainerModules>
  );
};

export default UploadEmployeesSummary;
