import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <rect width={24} height={24} rx={4} fill="#ff664e" />
    <path
      d="M12 6.5c-1.553 0-3.177 0-4.532.255a1.6 1.6 0 0 0-1.25 1.204C6 8.854 6 9.532 6 11s0 2.146.217 3.04a1.6 1.6 0 0 0 1.251 1.205c1.355.255 2.98.255 4.532.255 1.553 0 3.177 0 4.532-.255a1.6 1.6 0 0 0 1.25-1.204C18 13.146 18 12.468 18 11s0-2.146-.217-3.04a1.6 1.6 0 0 0-1.251-1.205C15.177 6.5 13.552 6.5 12 6.5Z"
      fill="#ff664e"
      stroke="#fff"
    />
    <path d="M12 15.495v2.755M10 18.25h4" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 9v4M14 11h-4" stroke="#fff" strokeLinecap="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const UploadToolsStepIcon = memo(ForwardRef);
export default UploadToolsStepIcon;
