import { FC, useState } from 'react';
import { NudosAddressSearchBar, NudosButton, NudosTextInput } from '../../../../../components/NudosComponents';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import { ILocationCountryAddress } from '../../../../../types/account';
import { ICountryDetail } from '../../../../../types/countries';
import { IaddressFromGoogleMaps } from '../../../../../types/global';
import { useTranslation } from 'react-i18next';
import { NudosCountryDropdown } from '../../../../../components/DesignSystem';
import './modalAddOfficeAddress.scss';
interface IModalAddOfficeAddress {
  validCountryList?: ICountryDetail[];
  handleNewCountry?: (data: ILocationCountryAddress) => void;
  loadingNewAddres?: boolean;
}

const ModalAddOfficeAddress: FC<IModalAddOfficeAddress> = ({
  validCountryList,
  handleNewCountry,
  loadingNewAddres
}: IModalAddOfficeAddress) => {
  const { t } = useTranslation();
  const { orgData } = useEcommerceControler();
  const [myAddress, setMyAddress] = useState<IaddressFromGoogleMaps>();
  const [optionalText, setOptionalText] = useState<string>();
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const i18nLanguageKey = 'nodi:account:organizationAddressesTab:modalEditAddress:';

  const saveAddres = () => {
    if (!selectedCountry?.id) return;
    const body = {
      address: myAddress?.address || '',
      additionalReferences: optionalText || '',
      coordinates: myAddress?.coordinates || undefined,
      city: myAddress?.city || '',
      countryId: selectedCountry.id
    };
    if (handleNewCountry) {
      handleNewCountry(body);
    }
  };

  const showAlertSearchBar = () => {
    if (myAddress && selectedCountry && myAddress?.country !== selectedCountry?.code) {
      return `Esta dirección no pertenece a ${selectedCountry?.name || 'este país'}`;
    }
    return undefined;
  };

  return (
    <div id="modalAddOfficeAddress">
      <div className="boxModalAddOfficeAddress">
        <div className="containerModalOfficeAddress">
          <div className="subtitleModalAddress">{t(`${i18nLanguageKey}orangeEditAddress`)}</div>
          <div className="selectFlagContainer">
            <NudosCountryDropdown
              charactersLimit={6}
              displayLabel={false}
              currentValue={selectedCountry}
              countriesList={validCountryList || []}
              customPlaceholder="País"
              updateCurrentValueCallback={setSelectedCountry}
            />
            <div className="organizationName">{orgData?.organizationName}</div>
          </div>
          <div className="containerSearchBarOfficeAddress">
            <NudosAddressSearchBar
              handleAddressSelection={e => setMyAddress(e)}
              isDisabled={!selectedCountry}
              label={t(`${i18nLanguageKey}address`)}
              errorText={showAlertSearchBar()}
              regionBias={selectedCountry?.code}
              didntFindAddressTooltipTopPosition={-4}
              didntFindAddressTooltipRightPosition={0}
            />
          </div>
          <div className="containerOptionalText">
            <NudosTextInput
              isFilled={!!optionalText}
              placeholder={t(`${i18nLanguageKey}directions:placeholder`)}
              label={t(`${i18nLanguageKey}directions:label`)}
              handleChange={e => setOptionalText(e)}
            />
          </div>
          <div className="containerButtonModalOfficeAddress">
            <NudosButton
              handleClick={() => saveAddres()}
              isButtonLoading={loadingNewAddres}
              isButtonDisabled={
                loadingNewAddres || !selectedCountry || !myAddress || !!showAlertSearchBar() || !optionalText
              }
              buttonText={t(`${i18nLanguageKey}nudosButton`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalAddOfficeAddress;
