import { useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { ClosedEyeIcon, OpenEyeIcon } from '../../../assets/images/views/Admin/main';
import { Tany } from '../../../types/global';
import './NudosPasswordInput.scss';

/**
 * React functional component corresponding to the Nudos password input
 * @property {boolean} isFilled - Required, boolean indicating if the input is filled. Should be passed to change the style from empty to filled. Its value should reflect the change in the password value. Default is false, unless it has a default value, in which case is true.
 * @property {string} label - Optional
 * @property {string} placeholder - Optional, if no value is provided the placeholder would be 7 dots
 * @property {string} errorText - Optional, the text to be shown if theres an error with the password input. If an error is passed the style of the component will change to reflect that.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "extraSmall", "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {string} defaultValue - Optional.
 * @property {boolean} isDeactivated - Optional, boolean indicating if the input is deactivated. If a value is provided the input will behave as a read only field and styles will be accordingly.
 * @property {string} autoComplete - Optional, a valid value for the autocomplete attribute. Default is "off".
 * @property {string} registerId - Optional, an Id to register the value of the input. To be used if form employs react-hook-form.
 * @property {UseFormRegister<Tany>} register - Optional, the register function of react-hook-form
 * @property {string} customClassName - Optional, a className to customize styles
 * @property {string} hideErrorText - Optional, to be used if the component is part of another component that shows an error text, used this boolean to prevent from repeating the error text.
 * @property {funcion(inputText: string): void} handleChange - Optional, a callback to execute upon changing the input text
 */
const NudosPasswordInput = ({
  isFilled,
  label,
  placeholder,
  errorText,
  componentSize,
  isDeactivated,
  defaultValue,
  autoComplete,
  register,
  registerId,
  customClassName,
  hideErrorText,
  handleChange
}: InudosTextInput) => {
  const [currentText, setCurrentText] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);

  const errorStyles = errorText && errorText.length ? 'error' : '';
  const filledStyles =
    (isFilled || (defaultValue && defaultValue.length > 0) || (currentText && currentText.length > 0)) && !isDeactivated
      ? 'filled'
      : '';
  const deactivatedStyles = isDeactivated ? 'deactivated' : '';
  const passwordStyles = showPassword ? '' : 'password';
  const noCustomPlaceholderStyles = placeholder ? '' : 'noCustomPlaceholder';
  const handleInputChange = (event: React.SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setCurrentText(value);
    handleChange && handleChange(value);
  };

  return (
    <div
      className={`nudosPasswordInput ${errorStyles} ${filledStyles} ${deactivatedStyles} ${passwordStyles} ${noCustomPlaceholderStyles} ${
        componentSize ? componentSize : ''
      } ${customClassName ? customClassName : ''}`}
    >
      {label && <label>{label}</label>}
      <div className="inputContainer">
        {registerId && register && (
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder || '* * * * * *'}
            readOnly={isDeactivated}
            defaultValue={defaultValue}
            autoComplete={autoComplete ? autoComplete : 'off'}
            {...register(registerId)}
          />
        )}
        {(!registerId || !register) && (
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder || '* * * * * *'}
            readOnly={isDeactivated}
            defaultValue={defaultValue}
            autoComplete={autoComplete ? autoComplete : 'off'}
            onChange={handleInputChange}
          />
        )}
        {showPassword && <OpenEyeIcon className="shownPasswordIcon" onClick={() => setShowPassword(!showPassword)} />}
        {!showPassword && (
          <ClosedEyeIcon className="hidenPasswordIcon" onClick={() => setShowPassword(!showPassword)} />
        )}
      </div>
      {errorText && !hideErrorText && errorText.length > 0 && <div className="errorText">{errorText}</div>}
    </div>
  );
};

export default NudosPasswordInput;

export interface InudosTextInput {
  isFilled: boolean;
  label?: string;
  placeholder?: string;
  errorText?: string;
  componentSize?: string;
  defaultValue?: string;
  isDeactivated?: boolean;
  autoComplete?: string;
  registerId?: string;
  register?: UseFormRegister<Tany>;
  customClassName?: string;
  hideErrorText?: boolean;
  handleChange?: (inputText: string) => void;
}
