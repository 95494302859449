import { useTranslation } from 'react-i18next';
import { BlueSmile, NidIcon, NoSmile } from '../../../../../../../assets/images/components/floatForm';
import { IModuleList, InudoInfo } from '../../../../../../../types/global';
import { ModalDeleteSerial } from './Components';
import { useState } from 'react';
import './ToolsInformation.scss';

const ToolsInformation = ({
  deleteToolLoading,
  deleteToolCallback,
  searchedAcquisition
}: {
  deleteToolLoading: boolean;
  deleteToolCallback?: () => void;
  searchedAcquisition?: InudoInfo | undefined;
}) => {
  const { t } = useTranslation();
  const [showDeleteToolModal, setShowDeleteToolModal] = useState<boolean>(false);
  const isExternalTool = searchedAcquisition?.referenceModel === 'ExternalProduct';
  const i18nLanguage = 'nodi:toolInternal:ModalDeleteSerial:';

  const listModal: IModuleList[] = [
    {
      title: t(`${i18nLanguage}listModal:firstModal:title`),
      subtitle: t(`${i18nLanguage}listModal:firstModal:subtitle`)
    },
    {
      title: t(`${i18nLanguage}listModal:secondModal:title`),
      subtitle: t(`${i18nLanguage}listModal:secondModal:subtitle`)
    }
  ];
  return (
    <div className="toolsInformation">
      <div className="toolsInformationHeader">
        <div className="title">Información de la herramienta</div>
        {isExternalTool && (
          <div className="deleteTool" onClick={() => setShowDeleteToolModal(true)}>
            Eliminar herramienta
          </div>
        )}
      </div>

      <div className="toolsInformationContainer">
        <div className="property">
          <div className="left">Tipo</div>
          <div className="toolReferenceModel">
            <div className="containerIcon">{isExternalTool ? <NoSmile /> : <BlueSmile />}</div>
            <div className="containerText">{isExternalTool ? 'Herramienta externa' : 'Herramienta nudos'}</div>
          </div>
        </div>

        <div className="property">
          <div className="left">SKU</div>
          <div className="nidContainer">{searchedAcquisition?.sku}</div>
        </div>

        <div className="property">
          <div className="left">Nudos ID</div>
          <div className="nidContainer">
            <NidIcon className="nidIcon" />
            <div>{`NID ${searchedAcquisition?.NID}`}</div>
          </div>
        </div>
      </div>

      {showDeleteToolModal && (
        <ModalDeleteSerial
          listModal={listModal}
          firstAction={() => setShowDeleteToolModal(false)}
          handleClose={() => setShowDeleteToolModal(false)}
          secondAction={deleteToolCallback}
          secondLoading={deleteToolLoading}
        />
      )}
    </div>
  );
};

export default ToolsInformation;
