import { v4 as uuid } from 'uuid';
import './ProductPills.scss';

const ProductPills = ({
  pills,
  isLoading = false,
  customHeight
}: {
  pills: { [key: string]: string | null | undefined };
  isLoading?: boolean;
  customHeight?: number;
}) => {
  const profileAndLinePills = ['Gama', 'Cargo', 'Perfil', 'Perfil 1', 'Perfil 2', 'Perfil 3', 'Perfil 4', 'Perfil 5'];
  const pillsArr = pills ? Object.entries(pills) : [];
  const specsPills = pillsArr.filter(([key, value]) => value && key && !profileAndLinePills.includes(key)).slice(0, 3);
  return (
    <>
      {isLoading && <ProductPillsSkeletonLoader />}
      {!isLoading && (
        <div className="productPills" style={{ height: customHeight ? `${customHeight}px` : '' }}>
          {specsPills &&
            specsPills.length > 0 &&
            specsPills.map(([key, value]) => {
              return (
                <div key={uuid()} className="pill technicalDetail">
                  <p className="pillText">{value && value.length > 14 ? `${value?.slice(0, 14)}...` : value}</p>
                  <div className="hoverContainer">
                    <div className="hoverKey">{key ? key : 'Propiedad'}:</div>
                    <div className="hoverValue">{value ? value : 'Valor'}</div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default ProductPills;

const ProductPillsSkeletonLoader = () => {
  return (
    <div className="productPillsSkeletonLoader">
      {[0, 1, 2].map(pill => {
        return <div key={`pill${pill}`} className="pill animationLoader" />;
      })}
    </div>
  );
};
