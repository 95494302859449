import { FC } from 'react';
import './headerModule.scss';
interface IHeaderModule {
  icon?: string;
  title?: string;
  totalNumber?: number;
}

const HeaderModule: FC<IHeaderModule> = ({ icon, title, totalNumber }: IHeaderModule) => {
  return (
    <div id="headerModule">
      <div className="headerDashBoardInfo">
        <div className="iconTitle">
          <div className="headerIcon">
            <img alt={icon} src={icon} />
          </div>
          <div className="titleHeader">{title}</div>
        </div>
        <div className="totalContainer">
          <div className="totalTitle">{totalNumber}</div>
          <div className="totalNumber">Total</div>
        </div>
      </div>
    </div>
  );
};

export default HeaderModule;
