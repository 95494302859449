import { useTranslation } from 'react-i18next';
import { BILLING_METHODS, IorderDetails } from '../../../../../../types/orders';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import { getOrderRequiredDataByPaymentMethod } from '../../../../../../utils/productDefinitions';
import { NudosUploadButton } from '../../../../../../components/NudosComponents';
import { formatRoundedUpNumber } from '../../../../../../utils/formatNumbers';
import { PAYMENT_TYPES } from '../../../../../../types/checkout';
import { NudosLinkTypeButton } from '../../../../../../components/DesignSystem';
import { isValidLink } from '../../../../../../utils/formValidations';
import './PaymentMethodAndLink.scss';

const PaymentMethodAndLink = ({
  orderData,
  uploadProofOfPaymentCallback,
  openPaymentInfoModal
}: {
  orderData?: IorderDetails;
  uploadProofOfPaymentCallback: (file?: File) => Promise<void>;
  openPaymentInfoModal: () => void;
}) => {
  const { t } = useTranslation();

  const { paymentMethod, proofOfPaymentUrl, paymentLink, paymentDate, committedPaymentDate, billingMethod } =
    orderData || {};
  const { paymentMethodStringId, exchangeRate } = orderData || {};
  const i18nLanguageKey = 'nodi:orderDetails:orderPaymentDetails:';
  const { requiresProofOfPayment, requiresPaymentLink, showLocalCurrency, requiresBankData } =
    getOrderRequiredDataByPaymentMethod(paymentMethod || '', billingMethod?.billingMethodName as BILLING_METHODS);
  const paymentMethodText = paymentMethodStringId
    ? t(`services:paymentType:${paymentMethodStringId}`)
    : paymentMethod || t(`${i18nLanguageKey}paymentMethodValue`);
  const validPaymentLink = isValidLink(paymentLink || '') ? paymentLink : undefined;
  const exchangeRateTitle = t(`${i18nLanguageKey}exchangeRate`);
  const exchangeRateValue = exchangeRate ? formatRoundedUpNumber(+exchangeRate, 2) : t(`${i18nLanguageKey}values`);
  const countryAdjustedExchangeRateValue =
    paymentMethod === PAYMENT_TYPES.TRANSFER_ARS ? 'al valor del mercado' : exchangeRateValue;
  const exchangeRateText = `${exchangeRateTitle} ${countryAdjustedExchangeRateValue}`;
  const fullLineStyle = requiresPaymentLink ? 'fullLine' : '';
  const nudosLinkTypeButtonText = t(`${i18nLanguageKey}nudosLinkTypeButtonText`);

  return (
    <div className="paymentMethodAndLink">
      <div className="paymentInfoTitle paymentMethodTitle">{t(`${i18nLanguageKey}paymentMethodTitle`)}</div>
      <div className="informationSection">
        <div className="paymentField paymentMethod">{paymentMethodText}</div>

        {!proofOfPaymentUrl && requiresBankData && (
          <NudosLinkTypeButton
            customClassname="paymentField"
            text={nudosLinkTypeButtonText}
            handleClickCallback={openPaymentInfoModal}
          />
        )}

        {showLocalCurrency && <div className="exchangeRate">{exchangeRateText}</div>}

        {requiresProofOfPayment && (
          <div className={`paymentField proofOfPaymentButton ${fullLineStyle}`}>
            <NudosUploadButton
              uploadedFileHandler={uploadProofOfPaymentCallback}
              isFilled={!!proofOfPaymentUrl}
              buttonEmptyText={t(`${i18nLanguageKey}proofOfPaymentButton:buttonEmptyText`)}
              buttonFilledText={t(`${i18nLanguageKey}proofOfPaymentButton:buttonFilledText`)}
              height="16px"
              redFilled={!!committedPaymentDate && !proofOfPaymentUrl}
            />
            <div className="emoji">{proofOfPaymentUrl ? '👍' : '⚠️'}</div>
          </div>
        )}

        {requiresPaymentLink && (
          <div className="paymentField paymentLink">
            {!validPaymentLink && (
              <div className="paymentLinkPending"> {paymentLink || t(`${i18nLanguageKey}paymentLinkPending`)}</div>
            )}
            {validPaymentLink && (
              <a className="activePaymentLink" href={validPaymentLink} target="blank">
                {validPaymentLink}
              </a>
            )}
          </div>
        )}

        {committedPaymentDate && (
          <div className="paymentField containerDateCommitment">
            <div className={`textCommitment ${paymentDate ? 'simpleText' : 'redText'}`}>
              {`${paymentDate ? 'Pagado el ' : 'Acuerdo de pago para'} `}{' '}
              <span className="bigText">
                {formatShortDate(paymentDate || committedPaymentDate || '')?.textWithTimezone}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentMethodAndLink;
