import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import urlParameter from '../../utils/urlParameter';
import useOtpController from '../../components/Otp/otp.controller';
import LoginLeftAside from './components/LoginLeftAside';
import { NudosButton, NudosTextInput, NudosPasswordInput } from '../../components/NudosComponents';
import useSignInController, { FormValues } from './signIn.controller';
import { segmentTrackEvent } from '../../utils/segment';
import resetPassword from '../../utils/resetPassword';
import usePlatform from '../../hooks/usePlatform';
import SecurityLogoutModal from './components/SecurityLogoutModal/SecurityLogoutModal';
import { nudosOnePointLargeLogo } from '../../assets/images/views/Admin/main';
import './signIn.scss';

const SignIn = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { resolverSchema, onSubmit, onError, verifyEmail, error } = useSignInController();
  const { verifyLink } = useOtpController();
  const { isMobile } = usePlatform();

  const [loginStep, setLoginStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState<string>();
  const [submittedPassword, setSubmittedPassword] = useState<string>();
  const [showSecurityLogoutModal, setShowSecurityLogoutModal] = useState(false);
  const i18nLanguageKey = 'authentication:signIn:';

  const { register, handleSubmit, watch } = useForm<FormValues>({
    shouldFocusError: true,
    resolver: yupResolver(resolverSchema)
  });
  const password = watch('password');
  const currentEmail = watch('email');
  const isValidEmail =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
      currentEmail
    );
  const hasPasswordChangeSinceLastSubmit = submittedPassword !== password;

  const token = urlParameter('apiKey');
  const mode = urlParameter('mode');

  const handleCloseModal = () => setShowSecurityLogoutModal(false);

  const starAutoLogin = async () => {
    if (token && mode && mode === 'signIn') verifyLink();
  };

  const recoveryPassword = () => {
    segmentTrackEvent({ loginRecoverPasswordClick: { ContactMail: currentEmail } });
    resetPassword(currentEmail);
  };

  const handleContinueOnFirstStep = () => {
    segmentTrackEvent({ loginClick: null });
    verifyEmail(currentEmail, setLoading, setLoginStep);
  };

  const handleGoToRegister = () => {
    segmentTrackEvent({ loginAccessRequestClick: null });
    push('/demo');
  };

  const handleSuccessfullSubmit = (data: FormValues) => {
    segmentTrackEvent({ loginPasswordClick: { ContactMail: data.email } });
    setPasswordError(undefined);
    setSubmittedPassword(password);
    onSubmit(data, setLoading, setPasswordError);
  };

  const handleErrorSubmitting = (data: { email?: FieldError }) => onError(data);

  useEffect(() => {
    starAutoLogin();
    const tokenExpired = localStorage.getItem('tokenExpired');
    if (tokenExpired) setShowSecurityLogoutModal(true);
  }, []);

  useEffect(() => {
    if (loginStep === 0) {
      segmentTrackEvent({ loginView: null });
    }
  }, [loginStep]);

  useEffect(() => {
    if (loginStep === 1) {
      segmentTrackEvent({ nodiLoginPasswordView: { ContactMail: currentEmail } });
    }
  }, [loginStep]);

  return (
    <div id="signIn">
      {showSecurityLogoutModal && <SecurityLogoutModal closeModalCallback={handleCloseModal} />}
      {token && mode && mode === 'signIn' ? (
        <div id="containerLoading">
          <div className="boxLoading" />
        </div>
      ) : (
        <>
          {!isMobile && <LoginLeftAside />}
          <form
            id="loginForm"
            className="content"
            onSubmit={handleSubmit(handleSuccessfullSubmit, handleErrorSubmitting)}
          >
            <img src={nudosOnePointLargeLogo} className="nudosOnePointLargeLogo" />
            <div className="title">{t(`${i18nLanguageKey}title`)}</div>
            {loginStep === 0 && (
              <>
                <NudosTextInput
                  placeholder="juanita@somosnudos.com"
                  label={t(`${i18nLanguageKey}corporateEmail`)}
                  isFilled={!!currentEmail}
                  componentSize="large"
                  registerId={'email'}
                  register={register}
                  errorText={error}
                />
                <NudosButton
                  buttonText={t(`${i18nLanguageKey}buttonText`)}
                  componentSize="large"
                  customClassName="advanceButton"
                  handleClick={handleContinueOnFirstStep}
                  isButtonDisabled={!currentEmail || !isValidEmail}
                  isButtonLoading={loading}
                />
              </>
            )}
            {loginStep === 1 && (
              <>
                <NudosPasswordInput
                  label={t(`${i18nLanguageKey}password`)}
                  isFilled={!!password}
                  componentSize="large"
                  registerId="password"
                  register={register}
                  errorText={hasPasswordChangeSinceLastSubmit ? undefined : passwordError}
                />
                <div className="resetPassword" onClick={recoveryPassword}>
                  {t(`${i18nLanguageKey}resetPassword`)}
                </div>
                <NudosButton
                  buttonText={t(`${i18nLanguageKey}login`)}
                  componentSize="large"
                  customClassName="loginButton"
                  isOfTypeSubmit={true}
                  isButtonLoading={loading}
                  formId="loginForm"
                  isButtonDisabled={!password || !currentEmail || !isValidEmail}
                />
              </>
            )}
            <div className="toRegisterSection">
              <div className="advisorText"> {t(`${i18nLanguageKey}toRegisterSection:advisorText`)}</div>
              <NudosButton
                buttonText={t(`${i18nLanguageKey}toRegisterSection:nudosButton`)}
                componentSize="small"
                handleClick={handleGoToRegister}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default SignIn;
