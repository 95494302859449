import React, { FC } from 'react';
import './containerModules.scss';
interface IContainerModule {
  children: React.ReactNode;
  fullScreen?: boolean;
}
const ContainerModules: FC<IContainerModule> = ({ children, fullScreen }: IContainerModule) => {
  return (
    <div
      id="containerModules"
      style={{
        width: fullScreen ? '1024px' : '',
        left: fullScreen ? 'calc(0.2 * (100vw - 1024px))' : ''
      }}
    >
      <div className="relativePosition">{children}</div>
    </div>
  );
};

export default ContainerModules;
