import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={28} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M14.9177 17.1353L13.9507 17.1353"
      stroke="#ff664e"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.9177 12L13.9507 12" stroke="#ff664e" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.9177 6.86475L13.9507 6.86475"
      stroke="#ff664e"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Points = memo(ForwardRef);
export default Points;
