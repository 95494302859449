import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" ref={ref} {...props}>
    <path
      className="documentOutline"
      fill="#fff"
      stroke="#000"
      d="M21.212 19.001a111.971 111.971 0 0 0 .18-9.886 3.276 3.276 0 0 0-.629-1.856c-1.508-2.054-2.709-3.331-4.688-4.859a3.22 3.22 0 0 0-1.898-.662A99.39 99.39 0 0 0 12 1.715c-2.326 0-4.203.07-6.145.203A3.296 3.296 0 0 0 2.788 5C2.646 7.26 2.57 9.6 2.57 12c0 2.4.075 4.742.217 7.001a3.296 3.296 0 0 0 3.067 3.082c1.942.133 3.819.203 6.145.203 2.326 0 4.203-.07 6.145-.203A3.296 3.296 0 0 0 21.212 19Z"
    />
    <path className="middleLine" stroke="#000" strokeLinecap="round" d="M8.732 12.027h6.536" />
    <path
      className="topAndButtonLines"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.732 6.855h3.944M8.732 17.007h6.516-6.516Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconDocument = memo(ForwardRef);
export default IconDocument;
