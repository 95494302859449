import { FC, useEffect, useState } from 'react';
import { getOrgData, getPersonalData } from '../../../../../../utils/getLocalStorageData';
import { bannerLSPurple, bannerLSBlue } from '../../../../../../assets/images/views/RequestLogistics';
import { BackArrowIcon } from '../../../../../../assets/images/views/employeesLoad';
import IconWarningm from '../../../../../../assets/DesignSystem/SVGComponents/IconWarning';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../utils/displayNudosStandardNotifications';
import { ILogisticQuoteGeneralData } from '../../../../../../types/logisticsServicesModule.types';
import { formatCost, nudosFormatCurrency } from '../../../../../../utils/formatNumbers';
import { sendSubscriptionSlackMessage } from '../../../../../../services/subscriptions.services';
import { Trans, useTranslation } from 'react-i18next';
import './FooterBanner.scss';

export interface IFotterBanner {
  typePlan: 'Lite' | 'Free' | 'Care';
  logisticServiceQuoteData?: ILogisticQuoteGeneralData;
  clickInformation?: () => void;
  originBanner?: 'catalogueProductDetail' | 'checkoutSummary' | undefined;
  checkoutSummaryValue?: number;
}

export interface IBannerMessage {
  title: string;
  subtitle: React.ReactNode;
  moreInformation?: string;
  hideMoreInformationButton?: boolean;
}

const FooterBanner: FC<IFotterBanner> = ({
  typePlan,
  logisticServiceQuoteData,
  clickInformation,
  originBanner,
  checkoutSummaryValue = 0
}: IFotterBanner) => {
  const { t } = useTranslation();
  const organizationData = getOrgData();
  const personalData = getPersonalData();

  const [bannerMessagge, setBannerMessage] = useState<IBannerMessage>();

  const languageKey = 'nodi:footerBanner:';
  const typePlanIsLite = typePlan === 'Lite';
  const footerBackgroundImage = `url("${typePlanIsLite ? bannerLSBlue : bannerLSPurple}")`;
  const slackMessageService = typePlanIsLite ? 'Nudos Care' : 'Nodi Prime';
  const notificationMessage = (
    <Trans
      i18nKey={t(`${languageKey}notificationMessage`)}
      components={{
        1: typePlanIsLite ? 'Nudos Care' : 'Nodi Prime'
      }}
    />
  );
  const formatedCurrency = nudosFormatCurrency({ value: checkoutSummaryValue, maximumFractionDigits: 2 });

  const slackMessageSavingAmount = typePlanIsLite
    ? logisticServiceQuoteData?.nudosCareCouldSaving
    : logisticServiceQuoteData?.nudosPrimeCouldSaving;

  const initialValues = () => {
    if (originBanner === 'catalogueProductDetail') {
      setBannerMessage({
        title: typePlanIsLite
          ? t(`${languageKey}catalogueProductDetail:bannerTitleLite`)
          : t(`${languageKey}catalogueProductDetail:bannerTitleNotLite`),
        subtitle: typePlanIsLite ? (
          <div className="minSubtitleBanner">
            <Trans
              i18nKey={t(`${languageKey}catalogueProductDetail:minSubtitleBannerLite`)}
              components={{
                1: <span className="font-extrabold" style={{ fontSize: '16px' }}></span>
              }}
            />
          </div>
        ) : (
          <div className="minSubtitleBanner">
            <Trans
              i18nKey={t(`${languageKey}catalogueProductDetail:minSubtitleBannerNotLite`)}
              components={{
                1: <span className="font-extrabold" style={{ fontSize: '16px' }}></span>
              }}
            />
          </div>
        ),
        moreInformation: t(`${languageKey}moreInformation`),
        hideMoreInformationButton: typePlanIsLite ? true : false
      });
    } else if (originBanner === 'checkoutSummary') {
      setBannerMessage({
        title: typePlanIsLite
          ? t(`${languageKey}checkoutSummary:bannerTitleLite`)
          : t(`${languageKey}checkoutSummary:bannerTitleNotLite`),
        subtitle: typePlanIsLite ? (
          <div className="minSubtitleBanner">
            <Trans
              i18nKey={t(`${languageKey}checkoutSummary:minSubtitleBannerLite`)}
              components={{
                1: <strong></strong>,
                2: formatedCurrency
              }}
            />
          </div>
        ) : (
          <div className="minSubtitleBanner">
            <Trans
              i18nKey={t(`${languageKey}checkoutSummary:minSubtitleBannerNotLite`)}
              components={{
                1: <strong></strong>,
                2: formatedCurrency
              }}
            />
          </div>
        ),
        moreInformation: t(`${languageKey}moreInformation`),
        hideMoreInformationButton: typePlanIsLite ? true : false
      });
    } else {
      if (typePlanIsLite) {
        setBannerMessage({
          title: t(`${languageKey}typePlanIsLite:bannerTitle`),
          subtitle: (
            <div className="minSubtitleBanner">
              <Trans
                i18nKey={t(`${languageKey}typePlanIsLite:minSubtitleBanner`)}
                components={{
                  1: (
                    <strong>{` $${formatCost(
                      Number(logisticServiceQuoteData?.nudosCareCouldSaving || 0)
                    )} USD`}</strong>
                  ),
                  2: <br />
                }}
              />
            </div>
          )
        });
      }
      if (typePlan === 'Free') {
        setBannerMessage({
          title: t(`${languageKey}catalogueProductDetail:bannerTitleNotLite`),
          subtitle: (
            <div className="subtitleBanner">
              <Trans
                i18nKey={t(`${languageKey}typePlanIsFree`)}
                components={{
                  1: (
                    <strong>{` $${formatCost(
                      Number(logisticServiceQuoteData?.nudosPrimeCouldSaving || 0)
                    )} USD`}</strong>
                  )
                }}
              />
            </div>
          )
        });
      }
    }
  };

  const sendSlackMessage = async () => {
    if (!typePlan) return;
    clickInformation && clickInformation();
    try {
      const body = {
        organizationName: organizationData?.organizationName || '',
        requesterName: `${personalData?.firstName} ${personalData?.lastName}`,
        service: slackMessageService,
        origin: 'desde la cotización',
        userRole: personalData?.role,
        proposedAmount: logisticServiceQuoteData?.total,
        savingAmount: slackMessageSavingAmount
      };
      await sendSubscriptionSlackMessage(body);
      displaySuccessNotification({
        customJSXMessage: <>{notificationMessage}</>,
        button1Text: t('recurrentWords:understoodButton')
      });
    } catch (error) {
      displayErrorNotification();
    }
  };

  useEffect(() => {
    initialValues();
  }, [typePlan, checkoutSummaryValue]);

  return (
    <>
      {typePlan === 'Care' ? (
        <div id="careBanner">
          <div className="boxCareBanner">
            <div className="iconWarningContainer">
              <IconWarningm className="resizeIcon" color="#999999" />
            </div>

            <div className="textWarning">
              <Trans
                i18nKey={t(`${languageKey}textWarning`)}
                components={{
                  1: <br />
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div id="footerBanner" style={{ backgroundImage: footerBackgroundImage }}>
          <div className="containerBannerGeneral">
            <div className="centerItemsBanner">
              {typePlan === 'Free' ? (
                <div className="titleBanner mb-0.5">{bannerMessagge?.title}</div>
              ) : (
                <div className="minTitleBanner mb-0.5">{bannerMessagge?.title}</div>
              )}
              {bannerMessagge?.subtitle || ''}
            </div>
          </div>
          {!bannerMessagge?.hideMoreInformationButton && (
            <div className="floatInformationContainer">
              <div className="contaninerInformationAndIcon" onClick={sendSlackMessage}>
                <div className="textInformacion">
                  {bannerMessagge?.moreInformation || t(`nodi:primeBanner:blackButtonText`)}
                </div>
                <div className="iconInformation">
                  <div className="arrowRotate">
                    <BackArrowIcon fill="#FFFFFF" className="arrowInformation" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FooterBanner;
