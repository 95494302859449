import { FC, useState } from 'react';

interface ICustomBar {
  equippedPercent: number;
  name: string;
  equippedNumber: number;
  totalPositions: number;
}

const CustomBar: FC<ICustomBar> = ({ equippedPercent, name, equippedNumber, totalPositions }: ICustomBar) => {
  const [showEquipamentItem, setShowEquipamentItem] = useState<boolean>(false);
  const [showNoEquipamentItem, setShowNoEquipamentItem] = useState<boolean>(false);
  return (
    <div className="itemBar">
      <div
        className="customBar"
        onMouseEnter={() => {
          setShowNoEquipamentItem(true);
        }}
        onMouseOver={() => {
          setShowNoEquipamentItem(true);
        }}
        onMouseLeave={() => {
          setShowNoEquipamentItem(false);
        }}
      >
        {showNoEquipamentItem && !showEquipamentItem && (
          <div className="noEquipamentItems">
            <div className="labelItems">{totalPositions - equippedNumber}</div>
          </div>
        )}

        <div
          className="orangeBar"
          onMouseEnter={() => {
            setShowEquipamentItem(true);
          }}
          onMouseOver={() => {
            setShowEquipamentItem(true);
          }}
          onMouseLeave={() => {
            setShowEquipamentItem(false);
          }}
          style={{ height: `${equippedPercent || 0}%` }}
        >
          {equippedPercent > 0 && (
            <div
              className={`percentLabel ${equippedPercent > 80 ? 'extraProcentStyle' : ''}`}
            >{`${equippedPercent}%`}</div>
          )}
          {showEquipamentItem && (
            <div className="positionOrange">
              <div className="equipamentItems" style={{ top: equippedPercent > 95 ? '3px' : '' }}>
                <div className="labelItems">{equippedNumber}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="titleBar">{name}</div>
    </div>
  );
};
export default CustomBar;
