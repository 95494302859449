import { useHistory } from 'react-router-dom';
import { IconTimeClock } from '../../../../assets/DesignSystem/SVGComponents';
import { NudosButton, NudosGenericModal, NudosModalContent } from '../../../../components/NudosComponents';
import './TimeOutModal.scss';
import { FC } from 'react';

interface ITimeOutModal {
  resetSteps?: () => void;
}

const TimeOutModal: FC<ITimeOutModal> = ({ resetSteps }: ITimeOutModal) => {
  const { push } = useHistory();

  return (
    <NudosGenericModal
      modalContent={
        <NudosModalContent
          customHeight="220px"
          customWidth="466px"
          excludeHeader
          CustomModalComponent={
            <div id="timeOutModal">
              <div className="boxTimeOutModal">
                <div className="clockIconBox">
                  <div className="blueContainer">
                    <IconTimeClock className="scaleIcon" />
                  </div>
                </div>
                <div className="timeOutText">
                  Tiempo de confirmación excedido. Solicita de nuevo tu
                  <br /> servicio logístico para continuar.
                </div>
                <div className="containerButtons">
                  <NudosButton
                    customClassName="mr-2"
                    customWidth="180px"
                    customHeight="38px"
                    colorPalette="secondary"
                    buttonText="Volver al inicio"
                    handleClick={() => {
                      push('/nodi/dashboard');
                    }}
                  />
                  <NudosButton
                    customWidth="180px"
                    customHeight="38px"
                    buttonText="Repetir servicio"
                    handleClick={() => {
                      if (resetSteps) {
                        resetSteps();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          }
        />
      }
    />
  );
};

export default TimeOutModal;
