import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="delete-square"
    width={15}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.99524 3.32993C5.99524 2.89248 5.91771 2.46054 5.76706 2.05638C5.61642 1.65223 5.39561 1.285 5.11726 0.975675C4.8389 0.666348 4.50845 0.420976 4.14476 0.25357C3.78107 0.0861631 3.39127 -1.91217e-08 2.99762 0C2.60397 1.91218e-08 2.21417 0.0861631 1.85048 0.25357C1.48679 0.420976 1.15634 0.666348 0.877983 0.975675C0.599628 1.285 0.378825 1.65223 0.22818 2.05638C0.0775357 2.46054 -3.44142e-08 2.89248 0 3.32993L1.49881 3.32993C1.49881 3.1112 1.53758 2.89585 1.6129 2.69377C1.68822 2.4917 1.79862 2.30808 1.9378 2.15342C2.07698 1.99876 2.24221 1.87607 2.42405 1.79237C2.60589 1.70866 2.80079 1.66558 2.99762 1.66558C3.19445 1.66558 3.38935 1.70866 3.57119 1.79237C3.75303 1.87607 3.91826 1.99876 4.05744 2.15342C4.19662 2.30808 4.30702 2.4917 4.38234 2.69377C4.45766 2.89585 4.49643 3.1112 4.49643 3.32993H5.99524Z"
      fill="#ff664e"
    />
    <path
      d="M4.49982 4.89565C4.49982 5.33311 4.57735 5.76505 4.728 6.1692C4.87864 6.57336 5.09944 6.94058 5.3778 7.24991C5.65615 7.55924 5.98661 7.80461 6.3503 7.97202C6.71398 8.13942 7.10378 8.22559 7.49744 8.22559C7.89109 8.22559 8.28089 8.13942 8.64458 7.97202C9.00826 7.80461 9.33872 7.55924 9.61707 7.24991C9.89543 6.94058 10.1162 6.57336 10.2669 6.1692C10.4175 5.76505 10.4951 5.33311 10.4951 4.89565L8.99625 4.89565C8.99625 5.11438 8.95748 5.32974 8.88216 5.53181C8.80683 5.73389 8.69643 5.9175 8.55725 6.07217C8.41808 6.22683 8.25285 6.34952 8.07101 6.43322C7.88916 6.51692 7.69426 6.56 7.49744 6.56C7.30061 6.56 7.10571 6.51692 6.92387 6.43322C6.74202 6.34952 6.57679 6.22683 6.43762 6.07217C6.29844 5.9175 6.18804 5.73389 6.11272 5.53181C6.03739 5.32973 5.99863 5.11438 5.99863 4.89565L4.49982 4.89565Z"
      fill="#ff664e"
    />
    <path
      d="M14.9875 3.32993C14.9875 2.89248 14.91 2.46054 14.7593 2.05638C14.6087 1.65223 14.3879 1.285 14.1095 0.975675C13.8312 0.666348 13.5007 0.420976 13.137 0.25357C12.7733 0.0861631 12.3835 -1.91217e-08 11.9899 0C11.5962 1.91218e-08 11.2064 0.0861631 10.8427 0.25357C10.479 0.420976 10.1486 0.666348 9.87023 0.975675C9.59188 1.285 9.37107 1.65223 9.22043 2.05638C9.06978 2.46054 8.99225 2.89248 8.99225 3.32993L10.4911 3.32993C10.4911 3.1112 10.5298 2.89585 10.6051 2.69377C10.6805 2.4917 10.7909 2.30808 10.9301 2.15342C11.0692 1.99876 11.2345 1.87607 11.4163 1.79237C11.5981 1.70866 11.793 1.66558 11.9899 1.66558C12.1867 1.66558 12.3816 1.70866 12.5634 1.79237C12.7453 1.87607 12.9105 1.99876 13.0497 2.15342C13.1889 2.30808 13.2993 2.4917 13.3746 2.69377C13.4499 2.89585 13.4887 3.1112 13.4887 3.32993H14.9875Z"
      fill="#ff664e"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MinSmile = memo(ForwardRef);
export default MinSmile;
