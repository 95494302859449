import './ToolManagementActions.scss';

const ToolManagementActionsCard = ({
  cardOptions,
  clickCardCallback,
  showSkeletonLoader
}: {
  cardOptions: ImanagementActionCard;
  clickCardCallback: () => void;
  showSkeletonLoader?: boolean;
}) => {
  const { isActive, icon, hoverComponent, titleFirstLine, titleSecondLine, handleSelectThisDestination } = cardOptions;

  const inactiveStyles = isActive ? '' : 'inactive';

  const handleClickCard = () => {
    if (!isActive) return;
    handleSelectThisDestination();
    return clickCardCallback();
  };

  if (showSkeletonLoader) return <div className="ToolManagementActionsCard animationLoader skeletonLoader" />;
  return (
    <div className={`ToolManagementActionsCard ${inactiveStyles}`} onClick={handleClickCard}>
      {!isActive && hoverComponent}
      <div className="iconContainer">{icon}</div>
      <h3 className="titleFirstLine">{titleFirstLine}</h3>
      <h2 className="titleSecondLine">{titleSecondLine}</h2>
    </div>
  );
};

export default ToolManagementActionsCard;

export interface ImanagementActionCard {
  order: number;
  showCard: boolean;
  isActive: boolean;
  icon: JSX.Element;
  hoverComponent: JSX.Element;
  titleFirstLine: string;
  titleSecondLine: string;
  handleSelectThisDestination: () => void;
}
