import { FC, useEffect, useState } from 'react';
import { UploadIcon } from '../../../assets/images/components/NudosComponents';
import { TrashCanIcon } from '../../../assets/images/views/Ecommerce';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { IDocumentDetail } from '../../../types/global';
import { useTranslation } from 'react-i18next';
import './NudosUploadFile.scss';

interface INudosUploadFile {
  size?: number;
  title?: string;
  handleExternalDocuments?: (documentDetails: IDocumentDetail | undefined) => void;
  defaultFile?: IDocumentDetail;
}

const NudosUploadFile: FC<INudosUploadFile> = ({
  size,
  title,
  handleExternalDocuments,
  defaultFile
}: INudosUploadFile) => {
  const { t } = useTranslation();

  const [internalDocument, setInternalDocument] = useState<IDocumentDetail | undefined>();

  const translationKey = 'services:logistics:email:';
  const uploadFileLabel = t(`${translationKey}uploadFileLabel`);
  const uploadFileDragText = t(`${translationKey}uploadFileDragText`);
  const uploadFileSizeAndFormatText = t(`${translationKey}uploadFileSizeAndFormatText`);
  const searchText = t('recurrentWords:search');

  const handleChangeFile = (file: File) => {
    const isPdf = file.name?.includes('.pdf');
    const isSizeValid = file.size / 1024 / 1024 < 2;
    if (!isPdf || !isSizeValid) {
      displayErrorNotification({ customJSXMessage: <>{uploadFileSizeAndFormatText}</> });
    }
    if (isPdf && isSizeValid) {
      const bodyDocument: IDocumentDetail = { documentFile: file, documentName: file.name };
      if (bodyDocument) {
        setInternalDocument(bodyDocument);
        if (handleExternalDocuments) {
          handleExternalDocuments(bodyDocument);
        }
      }
    }
  };

  const defaultVaules = () => {
    if (defaultFile) {
      setInternalDocument(defaultFile);
    }
  };

  useEffect(() => {
    defaultVaules();
  }, []);

  return (
    <div id="nudosUploadFile" style={{ width: size && size > 279 ? `${size || 380}px` : '380px' }}>
      <div className="titleUploadFile">{title || uploadFileLabel}</div>
      <div className="boxFileContainer">
        {internalDocument?.documentFile && internalDocument?.documentName ? (
          <div className="uploadedDocumentBox">
            <div className="boxFileDetails">
              <div className="fileNameBox">
                <div className="textNameFile">{internalDocument.documentName}</div>
              </div>
              <div
                className="removeFileElement"
                onClick={() => {
                  setInternalDocument(undefined);
                  if (handleExternalDocuments) {
                    handleExternalDocuments(undefined);
                  }
                }}
              >
                <div className="containerTrashIcon">
                  <TrashCanIcon className="trashIconPosition" stroke="#FF0000" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="uploadDocumentBox">
            <input
              type="file"
              className="customInputLoad"
              onChange={event => {
                const file = event?.target?.files?.[0] as File;
                if (file) {
                  handleChangeFile(file);
                }
              }}
            />
            <div className="descriptionUploadFile">
              <div className="uploadIconFile">
                <div className="containerUploadIcon">
                  <UploadIcon className="iconPositionUpload" />
                </div>
              </div>
              <div className="descriptionText">
                <div className="boxDescriptionText">
                  <div className="superiorInfo">
                    <span className="text-nudos-blue underline">{searchText}</span> {uploadFileDragText}
                  </div>
                  <div className="inferiorInfo">{uploadFileSizeAndFormatText}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NudosUploadFile;
