import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LS_ADDITIONAL_SERVICE_STATUS } from '../../../../../types/logisticsServicesModule.types';
import { IadditionalServiceDataForToolLogistics } from '../../../../../types/requestLogisticsModule';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import NudosLinkTypeButton from '../../../NudosLinkTypeButton/NudosLinkTypeButton';
import { getAdditionalServiceDisplayElements } from '../../utils';
import { iconEvidence } from '../../../../../assets/images/views/RequestLogistics';
import './NudosAdditionalServiceStatusModal.scss';

const AdditionalServiceStatusDataTable = ({
  additionalServiceData
}: {
  additionalServiceData: IadditionalServiceDataForToolLogistics;
}) => {
  const { t } = useTranslation();
  const [expandedText, setExpandedText] = useState(false);
  const { notCompletedReason, otherReasonComment, imageUrl, createdAt } = additionalServiceData?.status || {};
  const { text, color, status, icon } = getAdditionalServiceDisplayElements(additionalServiceData);

  const isImage =
    imageUrl?.toLowerCase().includes('.jpg') ||
    imageUrl?.toLowerCase().includes('.png') ||
    imageUrl?.toLowerCase().includes('.jpeg');

  const reason = otherReasonComment || notCompletedReason;
  const wasntCompleted = status === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED;
  const completedMargin = wasntCompleted ? undefined : { marginTop: '20px' };
  const notCompletedImageMargin = wasntCompleted && isImage ? undefined : { marginTop: '16px' };
  const specialMargin = completedMargin || notCompletedImageMargin;

  const textExceedsLargeLimit = reason?.length && reason.length > 210;
  const truncatedText = textExceedsLargeLimit ? reason?.slice(0, 210).concat('...') : reason;

  const imageData = {
    imageClassname: isImage ? 'includeImage' : 'placeholderImage',
    imageStyles: imageUrl && isImage ? { backgroundImage: `url("${imageUrl.replaceAll(' ', '%20')}")` } : undefined
  };

  const translateKey = 'services:logistics:additionalServices:';
  const requestedServiceText = t(`${translateKey}requestedService`);
  const statusText = t(`${translateKey}status`);
  const dateText = t(`${translateKey}date`);
  const viewEvidenceText = t(`${translateKey}viewEvidence`);

  const showTextButtonCopy = expandedText ? t('recurrentWord:viewMore') : t('recurrentWord:viewLess');

  const downloadDocument = () => imageUrl && window.open(imageUrl, 'blank');

  const expandOrContractText = () => setExpandedText(!expandedText);

  return (
    <div className="additionalServiceStatusDataTable" style={specialMargin}>
      <div className="textSection">
        <div className="fieldContainer">
          <div className="contentLabel">{requestedServiceText}</div>
          <div className="content">
            {icon}
            <div> {text}</div>
          </div>
        </div>
        <div className="fieldContainer">
          <div className="contentLabel">{statusText}</div>
          <div className="content">
            {status}
            <div className="colorDot" style={{ backgroundColor: color }} />
          </div>
        </div>
        <div className="fieldContainer">
          <div className="contentLabel">{dateText}</div>
          <div className="content">{formatShortDate(createdAt || '')?.onlyDate}</div>
        </div>
        {wasntCompleted && (
          <div className="fieldContainer">
            <div className="contentLabel">Motivo</div>
            <div className={`content reasonItWasntCompleted ${!expandedText ? 'truncatedText' : ''}`}>
              {expandedText ? reason : truncatedText}
            </div>
            {textExceedsLargeLimit && (
              <NudosLinkTypeButton hideIcon text={showTextButtonCopy} handleClickCallback={expandOrContractText} />
            )}
          </div>
        )}
      </div>
      <div className="imageSection">
        {imageData?.imageStyles && (
          <div className={`imageContainer ${imageData.imageClassname}`} style={imageData.imageStyles}></div>
        )}
        {!imageData.imageStyles && (
          <div className="placeholderImage">
            <img src={iconEvidence} alt="evidence icon" />
          </div>
        )}
        <NudosLinkTypeButton
          text={viewEvidenceText}
          handleClickCallback={downloadDocument}
          specialIcon="download"
          isDisabled={!imageUrl}
        />
      </div>
    </div>
  );
};

export default AdditionalServiceStatusDataTable;
