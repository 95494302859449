import { FC } from 'react';
import { EncircledRightArrow } from '../../assets/images/views/Admin/main';
import { NudosButton } from '../NudosComponents';
import './purpleAlert.scss';
import { SuccessIcon } from '../../assets/images/utils';

interface IPurpleAlert {
  onClick?: () => void;
  textAlert?: string;
  buttonText?: string;
  customClassName?: string;
}
const PurpleAlert: FC<IPurpleAlert> = ({ onClick, textAlert, buttonText, customClassName }: IPurpleAlert) => {
  const isIncompleteDataButton = customClassName === 'incompleteData';
  return (
    <div id="purpleAlert" className={`${customClassName ? customClassName : ''}`}>
      <div className="textAlert">
        {textAlert
          ? textAlert
          : 'La información de tu registro está completa, ahora empieza a equipar a tus colaboradores con Nudos '}
      </div>
      <div className="buttonContainer">
        <NudosButton
          handleClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          customClassName="buttonStyle"
          colorPalette="secondary"
          buttonText={buttonText ? buttonText : 'Entendido'}
          buttonIcon={isIncompleteDataButton ? <EncircledRightArrow className="encircledArrow" /> : <SuccessIcon />}
        />
      </div>
    </div>
  );
};
export default PurpleAlert;
