import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NudosAddressSearchBar,
  NudosButton,
  NudosCountrySelect,
  NudosPhoneInput,
  NudosTextInput
} from '../../NudosComponents';
import { NudosCityDropdown } from '../../DesignSystem';
import { ICountryDetail } from '../../../types/countries';
import { IaddressFromGoogleMaps } from '../../../types/global';
import { getAddressErrorText, getIdDocumentData, isPhoneValid } from '../../../utils/formValidations';
import {
  countriesRequiringPersonalId,
  getIsInvalidZipCode,
  invalidZipCodeText,
  supportUrl
} from '../../../utils/productDefinitions';
import { IorganizationOfficeData } from '../../../types/account';
import { IconInformation, IconWarning } from '../../../assets/DesignSystem/SVGComponents';
import { IReports } from '../../../types/orders';
import { segmentTrackEvent } from '../../../utils/segment';
import { IcityDetails } from '../../../types/cities.types';
import { IEditLocationData } from '../../../types/logisticsServicesModule.types';
import { getCountriesList } from '../../../services/countries.service';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import './NudosEditOfficeAddressModalInternalOrder.scss';

/**
 * IMPORTANT: Use this modal as the prop for the CustomModalComponent prop of the NudosModalContent, which in turn must be the modalContent prop of the NudosGenericModal
 * @property { string } subtitle  - The text to show as a modal subtitle
 * @property { string } giverOrReceiverSectionTitle  - The text specifying the role of the person whose name, phone and personalId would be stored. Just for UI purposes
 * @property { ICountryDetail[] } listOfCountries  - A list of countries from which to choose the address country
 * @property { (modalData: IorganizationOfficeData) => void } clickButtonCallback  - a function to execute upon clickin the modal button, receives as param the data of the modal
 * @property { IorganizationOfficeData } currentOfficeData - The information currently on DB for the organization's office whose data will be edited by the modal
 * @property { boolean } disableCountrySelection - A boolean indicating if a the country select for the addres is disabled. Only works if the currentOfficeData contains a country listed in the listOfCountries
 * @property { boolean } isButtonLoading - A boolean indicating if the modal submit data button is loading
 * @property { ICountryDetail } preselectedOfficeCountry - The default country to which the office whose data will be modified by the modal belongs
 */

const NudosEditOfficeAddressModalInternalOrder = ({
  subtitle,
  listOfCountries,
  isButtonLoading,
  currentOfficeData,
  clickButtonCallback,
  disableCountrySelection,
  preselectedOfficeCountry,
  giverOrReceiverSectionTitle,
  shipmentInfoEditingIsAllowed,
  reports,
  recivedPhoneCode,
  statusName
}: {
  subtitle: string;
  listOfCountries?: ICountryDetail[];
  isButtonLoading?: boolean;
  currentOfficeData?: IEditLocationData;
  clickButtonCallback?: (modalData: IorganizationOfficeData) => void;
  disableCountrySelection?: boolean;
  preselectedOfficeCountry?: ICountryDetail;
  giverOrReceiverSectionTitle: string;
  shipmentInfoEditingIsAllowed: boolean;
  reports: IReports[];
  recivedPhoneCode?: string;
  statusName?: string;
}) => {
  const { t } = useTranslation();

  const [city, setCity] = useState<IcityDetails>();
  const [zipCode, setZipCode] = useState<string>();
  const [personalId, setPersonalId] = useState<string>();
  const [receiverName, setReceiverName] = useState<string>();
  const [phoneCountry, setPhoneCountry] = useState<ICountryDetail>();
  const [receiverPhone, setReceiverPhone] = useState<string>();
  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const [selectedAddress, setSelectedAddress] = useState<IaddressFromGoogleMaps>();
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [addressAdditionalReferences, setAddressAdditionalReferences] = useState<string>();
  const [addressReport, setAddressReport] = useState<string>();
  const [phoneReport, setPhoneReport] = useState<string>();
  const [cityReport, setCityReport] = useState<string>();
  const [additionalInfoReport, setAdditionalInfoReport] = useState<string>();
  const [zipCodeReport, setZipCodeReport] = useState<string>();
  const [wasEdited, setWasEdited] = useState<boolean>(false);
  const [editFieldsList, setEditFieldsList] = useState<string[]>([]);

  const i18nLanguageKey = 'nodi:orderDetails:updateOrderBillingDataFlux:officeDestinationModal:';
  const translationsErrors = 'nodi:orderDetails:updateOrderBillingDataFlux:errorsDestinationModal:';
  const defaultCountry = countriesList?.find(country => country.code === currentOfficeData?.country?.code);
  const countryRequiredId = countriesRequiringPersonalId.includes(selectedCountry?.code || '');
  const personalIdIsRequiredAndCurrentlyEmpty = countryRequiredId && !personalId;
  const defaultPhoneCountry = countriesList?.find(countriesList => countriesList.code === recivedPhoneCode);
  const idDocumentData = getIdDocumentData(selectedCountry, personalId);
  const alertText = t(`${i18nLanguageKey}yellowAlert`);

  const orderLocationData = currentOfficeData?.shipmentInfo;
  const orderPhoneNumber = orderLocationData?.receiverPhone || undefined;
  const orderPhoneCountry = orderLocationData?.receiverPhoneCountry || undefined;
  const orderReceiverName = orderLocationData?.receiverName || undefined;
  const orderDocumentId = orderLocationData?.receiverIdDocument || undefined;

  const personalIdValue = orderDocumentId;
  const receiverNameValue = orderReceiverName;
  const receiverPhoneValue = orderPhoneNumber;
  const phoneCountryValue = orderPhoneCountry;
  const countrySelectlist = listOfCountries;
  const doesNotHavePhoneCode = phoneCountryValue ? t(`${translationsErrors}phone`) : '';

  const addressErrorText = getAddressErrorText(
    selectedCountry?.code,
    selectedCountry?.name,
    selectedAddress?.country || undefined,
    true,
    selectedAddress?.coordinates
  );

  const addressSearchBarErrorText = !selectedAddress?.address && !currentOfficeData?.address ? ' ' : addressErrorText;

  const cityDetails: IcityDetails = {
    id: currentOfficeData?.cityId,
    name: currentOfficeData?.city,
    country: selectedCountry
  };

  const openSupportWindow = () => window.open(supportUrl);

  const cancelLsContainerAction = () => {
    openSupportWindow();
    segmentTrackEvent({
      nodiOrderEditModalNotAvailableSupportClick: { Country: defaultCountry?.name || '', ModalType: 'Office' }
    });
  };

  const getZipCodeError = () => {
    if (!zipCode) return ' ';
    if (getIsInvalidZipCode(zipCode)) invalidZipCodeText;
  };

  const getIsButtonDisabled = () => {
    if (!wasEdited) return true;
    if (!selectedCountry || !zipCode || !addressAdditionalReferences) return true;
    if (!selectedAddress || !receiverName || !receiverPhone) return true;
    if (idDocumentData?.errorText) return true;
    if (addressErrorText) return true;
    if (getPhoneError()) return true;
    if (!phoneCountry) return true;
    if (cityReport || additionalInfoReport || zipCodeReport || addressReport || phoneReport) return true;
    if (getIsInvalidZipCode(zipCode)) return true;
    return false;
  };

  const getInitialData = () => {
    if (loadingCountries) return;
    if (!phoneCountry) setPhoneCountry(phoneCountryValue || defaultPhoneCountry);
    if (!personalId) setPersonalId(personalIdValue);
    if (!selectedCountry) setSelectedCountry(defaultCountry);
    if (!zipCode) setZipCode(currentOfficeData?.zipCode?.trim().replaceAll(' ', '') || undefined);
    if (!addressAdditionalReferences)
      setAddressAdditionalReferences(currentOfficeData?.additionalReferences || undefined);
    if (!selectedAddress)
      setSelectedAddress((currentOfficeData?.address as unknown as IaddressFromGoogleMaps) || undefined);
    if (!receiverName) setReceiverName(receiverNameValue);
    if (!receiverPhone) setReceiverPhone(receiverPhoneValue);
    if (!city) setCity(cityDetails);
  };

  const getCountries = async () => {
    try {
      setLoadingCountries(true);
      const dataCountries = await getCountriesList();
      setCountriesList(dataCountries);
    } catch {
      displayErrorNotification();
    } finally {
      setLoadingCountries(false);
    }
  };

  const getPhoneError = () => {
    if (receiverPhone && receiverPhone.length > 3 && !isPhoneValid(receiverPhone))
      return `${t('recurrentWords:inputError:invalidPhone')}  ${selectedCountry?.name}`;
    return undefined;
  };

  const addEditFieldEditList = (field: string) => {
    const searchField = editFieldsList.find(fieldList => field === fieldList);
    if (!searchField) {
      editFieldsList.push(field);
      setEditFieldsList([...editFieldsList]);
    }
  };

  const sendEvents = () => {
    if (shipmentInfoEditingIsAllowed) {
      segmentTrackEvent({
        nodiOrderEditModalAvailableClick: {
          Country: defaultCountry?.name || '',
          ModalType: 'Office',
          OrderStatus: statusName || '',
          FieldsEdited: editFieldsList.toString()
        }
      });
    } else {
      segmentTrackEvent({
        nodiOrderEditModalNotAvailableClick: { Country: defaultCountry?.name || '', ModalType: 'Office' }
      });
    }
  };

  const handleClickModalButton = () => {
    sendEvents();
    if (
      !selectedCountry ||
      !zipCode ||
      !addressAdditionalReferences ||
      !selectedAddress ||
      !city ||
      !clickButtonCallback ||
      !receiverName ||
      !receiverPhone ||
      personalIdIsRequiredAndCurrentlyEmpty ||
      idDocumentData?.errorText
    )
      return;

    const modalData = {
      locationId: (currentOfficeData as IorganizationOfficeData)?.locationId || 0,
      zipCode: zipCode,
      additionalReferences: addressAdditionalReferences,
      address: selectedAddress?.address || currentOfficeData?.address,
      coordinates: selectedAddress?.coordinates || undefined,
      city: city?.name || '',
      cityId: currentOfficeData?.cityId,
      countryId: selectedCountry?.id,
      countryCode: selectedCountry?.code,
      country: {
        id: selectedCountry?.id,
        code: selectedCountry?.code,
        name: selectedCountry?.name,
        flag: selectedCountry?.flag
      },
      shipmentInfo: {
        receiverName,
        receiverPhone,
        receiverIdDocument: personalId || '',
        receiverPhoneCountryCode: phoneCountry?.code
      }
    };
    clickButtonCallback(modalData);
  };

  const nudosButtonAction = () => handleClickModalButton();

  const intialErrors = () => {
    const openReports = reports?.find(report => report.status === 'OPEN');
    if (currentOfficeData && reports && reports?.length > 0 && openReports) {
      const initialReportErrors = reports[0]?.wrongAttributes;
      const findAdditionalReferences = initialReportErrors?.find(error => error === 'additionalReferences');
      const findCity = initialReportErrors?.find(error => error === 'city');
      const findPhoneNumber = initialReportErrors?.find(error => error === 'phoneNumber');
      const findAddress = initialReportErrors?.find(error => error === 'address');
      const findZipCode = initialReportErrors?.find(error => error === 'zipCode');
      setAddressReport(findAddress ? t(`${translationsErrors}address`) : undefined);
      setPhoneReport(findPhoneNumber ? t(`${translationsErrors}phone`) : undefined);
      setCityReport(findCity ? t(`${translationsErrors}city`) : undefined);
      setAdditionalInfoReport(findAdditionalReferences ? t(`${translationsErrors}indications`) : undefined);
      setZipCodeReport(findZipCode ? t(`${translationsErrors}zipCode`) : undefined);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  useLayoutEffect(() => {
    getInitialData();
    intialErrors();
  }, [currentOfficeData, loadingCountries]);

  useEffect(() => {
    if (selectedCountry || !preselectedOfficeCountry) return;
    setSelectedCountry(preselectedOfficeCountry);
  }, [preselectedOfficeCountry]);

  return (
    <div className="nudosEditOfficeAddressModalInternalOrder">
      <h2 className="subtitle">{subtitle}</h2>
      <section className="officeSection">
        <div className="lineInputs">
          <NudosCountrySelect
            label={t(`${i18nLanguageKey}country`)}
            errorText={selectedCountry ? undefined : ' '}
            isDisabled={!!selectedCountry && disableCountrySelection}
            listOfCountries={countrySelectlist}
            defaultCountry={selectedCountry}
            showSkeletonLoader={loadingCountries}
            handleCountryChange={setSelectedCountry}
            componentSize="extraSmall"
          />
          <NudosCityDropdown
            countryId={selectedCountry?.id}
            countryCode={selectedCountry?.code}
            currentValue={city}
            componentSize="small"
            isDeactivated={!shipmentInfoEditingIsAllowed}
            showSkeletonLoader={loadingCountries}
            updateCurrentValueCallback={e => {
              setCity(e);
              setCityReport(undefined);
              setWasEdited(true);
            }}
            errorText={cityReport}
          />
          <NudosTextInput
            label={t(`${i18nLanguageKey}directions:label`)}
            isFilled={!!addressAdditionalReferences}
            placeholder={t(`${i18nLanguageKey}directions:placeholder`)}
            handleChange={e => {
              setAddressAdditionalReferences(e);
              setAdditionalInfoReport(undefined);
              setWasEdited(true);
              addEditFieldEditList('Directions');
            }}
            defaultValue={addressAdditionalReferences}
            componentSize="small"
            isDeactivated={!shipmentInfoEditingIsAllowed}
            showSkeletonLoader={loadingCountries}
            errorText={additionalInfoReport}
          />
        </div>
        <div className="lineInputs">
          <NudosTextInput
            label={t(`${i18nLanguageKey}postalCode`)}
            isFilled={!!zipCode}
            handleBlur={newZipCode => setZipCode(newZipCode?.trim().replaceAll(' ', ''))}
            errorText={getZipCodeError() || zipCodeReport}
            placeholder="CP"
            defaultValue={zipCode}
            handleChange={e => {
              setZipCode(e);
              setZipCodeReport(undefined);
              setWasEdited(true);
            }}
            componentSize="extraSmall"
            isDeactivated={!shipmentInfoEditingIsAllowed}
            showSkeletonLoader={loadingCountries}
          />
          <NudosAddressSearchBar
            label={t(`${i18nLanguageKey}address`)}
            errorText={addressSearchBarErrorText || addressReport}
            isDisabled={!shipmentInfoEditingIsAllowed}
            regionBias={selectedCountry?.code}
            componentSize="large"
            showSkeletonLoader={loadingCountries}
            handleAddressSelection={e => {
              setSelectedAddress(e);
              setAddressReport(undefined);
              setWasEdited(true);
              addEditFieldEditList('Address');
            }}
            defaultValueAddressName={selectedAddress?.address || currentOfficeData?.address || undefined}
            didntFindAddressTooltipTopPosition={56 + (addressErrorText ? 10 : 0)}
            didntFindAddressTooltipRightPosition={0}
          />
        </div>
      </section>

      <section className="receiverSection">
        <div className="sectionTitle">{giverOrReceiverSectionTitle}</div>
        <div className="personalIdWarning">{t(`${i18nLanguageKey}personalIdWarning`)}</div>
        <div className="lineInputs">
          <NudosTextInput
            label={t(`${i18nLanguageKey}nameAndLastName`)}
            isFilled={!!receiverName}
            errorText={!receiverName ? ' ' : undefined}
            placeholder={t(`${i18nLanguageKey}nameAndLastName`)}
            handleChange={e => {
              setReceiverName(e);
              setWasEdited(true);
            }}
            defaultValue={receiverName}
            componentSize="small"
            isDeactivated={!shipmentInfoEditingIsAllowed}
            showSkeletonLoader={loadingCountries}
          />

          <NudosPhoneInput
            label={t(`${i18nLanguageKey}contactNumber`)}
            errorText={doesNotHavePhoneCode || getPhoneError() || phoneReport}
            placeholder={t(`${i18nLanguageKey}contactNumber`)}
            countriesList={countriesList || []}
            personalClass="phoneInputStyles"
            handlePhoneChange={setReceiverPhone}
            defaultCountryData={phoneCountry}
            defaultPhoneNumber={receiverPhone}
            showSkeletonLoader={loadingCountries}
            handleCountryPhoneChange={setPhoneCountry}
            simpleChangeOfValues={() => {
              setPhoneReport(undefined);
              setWasEdited(true);
              addEditFieldEditList('EmployeePhone');
            }}
          />
          <NudosTextInput
            label={idDocumentData?.label}
            isFilled={!!idDocumentData?.value}
            errorText={idDocumentData?.errorText}
            placeholder={idDocumentData?.placeholder}
            defaultValue={idDocumentData?.value}
            handleChange={e => {
              setWasEdited(true);
              setPersonalId(e);
              addEditFieldEditList('EmployeeID');
            }}
            customClassName="idDocument"
            componentSize="small"
            isDeactivated={!shipmentInfoEditingIsAllowed}
            showSkeletonLoader={loadingCountries}
            caption={idDocumentData?.caption}
          />
        </div>
      </section>
      <div className="alertMessage">
        <IconWarning />
        {alertText}
      </div>
      {!shipmentInfoEditingIsAllowed && (
        <div className="cancelLsContainer">
          <IconInformation className="IconInformation" />
          <div className="cancelLsTextContainer">
            <div>{t(`${translationsErrors}orderInDeliveryProcess`)}</div>
            <div className="cancelLsBlueText" onClick={() => cancelLsContainerAction()}>
              {t(`${translationsErrors}contactSupport`)}
            </div>
          </div>
        </div>
      )}
      <div className="buttonOfficeContainer">
        <NudosButton
          componentSize="medium"
          buttonText={t(`${i18nLanguageKey}continue`)}
          isButtonDisabled={getIsButtonDisabled()}
          handleClick={nudosButtonAction}
          isButtonLoading={isButtonLoading}
          customClassName="saveButton"
        />
      </div>
    </div>
  );
};

export default NudosEditOfficeAddressModalInternalOrder;
