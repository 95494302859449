import Points from '../../../../assets/images/components/newCard/points';
import './NewCardSkeleton.scss';

const NewCardSkelleton = () => {
  return (
    <div className="newCardSkeleton">
      <div className="boxCard">
        <div className={`containerImg`}>
          <div className="boxImg">
            <img className="limitImg" />
          </div>
        </div>
        <div className={`containerInfo`}>
          <div className="titleAndBrand">
            <div className="titleContainer" />
            <div className="containerBrand">
              <img className="styleImgBrand" />
            </div>
          </div>
          <div className="pills">
            {[0, 1, 2, 3, 4].map(i => {
              return <div key={`pill-${i}`} className="pill" />;
            })}
          </div>
        </div>
        <div className="containerModelAndSerial">
          <div className="subContainer" />
          <div className="subContainer" />
        </div>
        <div className="containerName">
          <div className="subContainer" />
          <div className="subContainer" />
        </div>
        <div className="containerButton">
          <div className="button" />
        </div>
        <div className="containerPoints">
          <Points />
        </div>
      </div>
    </div>
  );
};

export default NewCardSkelleton;
