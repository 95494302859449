import {
  IconBuilding,
  IconDocument,
  IconEmployee,
  IconTruck,
  IconAccountGear,
  IconParty,
  IconDollarSign,
  IconSandClock,
  IconNudosFace,
  IconIslandUmbrella,
  IconQuestion,
  IconTooltipQuestionMark,
  IconDropdownArrow,
  IconReserveBox,
  IconShield,
  IconToolWrench,
  IconPin,
  IconAddTool,
  IconCalendar,
  IconWarning,
  IconTimeClock,
  IconRangeDateArrow,
  IconRangeDateCalendar,
  IconRangeDateCalendarDisabled,
  IconOrangeEmployee,
  IconArrow,
  IconInformation,
  IconLightbulb,
  IconToolComputer,
  IconAvatarNudos,
  IconSendEmail,
  IconXInSquare,
  IconRedirection,
  IconXInCircle,
  IconQuotation,
  IconSimpleCheck,
  IconSimpleX,
  IconBroom,
  IconStar
} from '../../../assets/DesignSystem/SVGComponents';
import ContainerModules from '../../../components/containerModules';
import { v4 as uuid } from 'uuid';
import { displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './AllSystemIcons.scss';

interface IIconsSystem {
  name: string;
  icon: React.ReactNode;
  bgColor?: string;
}

const AllSystemIcons = () => {
  const { push } = useHistory();
  const location = window.location.hostname;
  const availableRoute = !!location.includes('localhost');

  const systemIcons: IIconsSystem[] = [
    { name: '<IconBuilding />', icon: <IconBuilding /> },
    { name: '<IconDocument />', icon: <IconDocument /> },
    { name: '<IconEmployee />', icon: <IconEmployee /> },
    { name: '<IconTruck />', icon: <IconTruck /> },
    { name: '<IconAccountGear />', icon: <IconAccountGear /> },
    { name: '<IconParty />', icon: <IconParty /> },
    { name: '<IconDollarSign />', icon: <IconDollarSign /> },
    { name: '<IconSandClock />', icon: <IconSandClock /> },
    { name: '<IconNudosFace />', icon: <IconNudosFace /> },
    { name: '<IconIslandUmbrella />', icon: <IconIslandUmbrella /> },
    { name: '<IconQuestion />', icon: <IconQuestion /> },
    { name: '<IconTooltipQuestionMark />', icon: <IconTooltipQuestionMark /> },
    { name: '<IconDropdownArrow />', icon: <IconDropdownArrow /> },
    { name: '<IconReserveBox />', icon: <IconReserveBox /> },
    { name: '<IconShield />', icon: <IconShield /> },
    { name: '<IconToolWrench />', icon: <IconToolWrench /> },
    { name: '<IconPin />', icon: <IconPin /> },
    { name: '<IconAddTool />', icon: <IconAddTool /> },
    { name: '<IconCalendar />', icon: <IconCalendar /> },
    { name: '<IconWarning />', icon: <IconWarning /> },
    { name: '<IconTimeClock />', icon: <IconTimeClock /> },
    { name: '<IconRangeDateArrow />', icon: <IconRangeDateArrow /> },
    { name: '<IconRangeDateCalendar />', icon: <IconRangeDateCalendar /> },
    { name: '<IconRangeDateCalendarDisabled />', icon: <IconRangeDateCalendarDisabled /> },
    { name: '<IconOrangeEmployee />', icon: <IconOrangeEmployee /> },
    { name: '<IconArrow />', icon: <IconArrow /> },
    { name: '<IconInformation />', icon: <IconInformation /> },
    { name: '<IconLightbulb />', icon: <IconLightbulb /> },
    { name: '<IconToolComputer />', icon: <IconToolComputer /> },
    { name: '<IconAvatarNudos />', icon: <IconAvatarNudos /> },
    { name: '<IconSendEmail />', icon: <IconSendEmail /> },
    { name: '<IconXInSquare />', icon: <IconXInSquare /> },
    { name: '<IconRedirection />', icon: <IconRedirection /> },
    { name: '<IconXInCircle />', icon: <IconXInCircle /> },
    { name: '<IconQuotation />', icon: <IconQuotation />, bgColor: '#b3b3b3' },
    { name: '<IconSimpleCheck />', icon: <IconSimpleCheck /> },
    { name: '<IconSimpleX />', icon: <IconSimpleX /> },
    { name: '<IconBroom />', icon: <IconBroom /> },
    { name: '<IconStart/>', icon: <IconStar /> }
  ];

  const copyNameIcon = (name: string) => {
    displaySuccessNotification({ customJSXMessage: <>Copiaste el Icono {name}</> });
    navigator.clipboard.writeText(name || '');
  };

  const redirection = () => {
    if (!availableRoute) {
      push('/nodi/dashboard');
    }
  };

  useEffect(() => {
    redirection();
  }, [availableRoute]);

  return (
    <ContainerModules>
      <div id="allSystemIcons">
        <div className="containerIconsSystem">
          {systemIcons &&
            systemIcons.length > 0 &&
            systemIcons.map(icon => {
              return (
                <div
                  key={uuid()}
                  className="individualIcon"
                  onClick={() => {
                    copyNameIcon(icon.name);
                  }}
                >
                  <div className="iconContainerSystem" style={{ backgroundColor: icon?.bgColor || '' }}>
                    {icon.icon}
                  </div>
                  <div className="nameIconSystem">
                    <div className="textMinSystem">{icon.name}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </ContainerModules>
  );
};

export default AllSystemIcons;
