import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="closedEyeIcon"
    width={16}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M14.857.571c0 .482-.495 1.406-1.333 2.34M1.143.57c0 .482.495 1.406 1.333 2.34m0 0L1.143 4.57m1.333-1.66c.71.792 1.67 1.592 2.781 2.133.278.135.565.254.861.353m7.406-2.486 1.333 1.66m-1.333-1.66c-.71.792-1.67 1.592-2.781 2.133a7.147 7.147 0 0 1-.861.353m-3.764 0c.598.2 1.23.317 1.882.317.653 0 1.284-.117 1.882-.317m-3.764 0-.86 2.031m4.624-2.03.86 2.03"
      stroke="#ff664e"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const ClosedEyeIcon = memo(ForwardRef);
export default ClosedEyeIcon;
