import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="success-icon"
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      className="path-1"
      d="M7 13.25A6.25 6.25 0 1 0 7 .75a6.25 6.25 0 0 0 0 12.5Z"
      fill="#F0F0F0"
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="path-2"
      d="M4.5 7.625 6.318 9.5c.856-2.458 1.568-3.536 3.182-5"
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SuccessIcon = memo(ForwardRef);
export default SuccessIcon;
