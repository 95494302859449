import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import './productDescription.scss';
import { NudosHoverText } from '../../NudosComponents';

interface IHeaderDescription {
  name?: string;
  pills?: string[];
  sku?: string;
  children?: React.ReactNode;
}

const ProductDescription: FC<IHeaderDescription> = ({ name, pills, sku, children }) => {
  const generatePills = () => {
    return pills?.map(item => {
      return (
        <div key={uuid()} className="pillContainer">
          {item}
        </div>
      );
    });
  };

  return (
    <div className="headerDescription">
      <NudosHoverText customClassName="productName" text={name || 'ProductName'} charactersLimit={25} onlyIfTruncated />
      {pills && pills.length > 0 && <div className="boxPills">{generatePills()}</div>}
      <div className="boxSerialProduct">SKU: {sku || 'no hay SKU'}</div>
      {!!children && children}
    </div>
  );
};

export default ProductDescription;
