const ToolDetailsRightSkeletonLoader = () => {
  return (
    <div className="ToolDetailsRightSkeletonLoader">
      <div className="animationLoader skeletonLoaderTitle" />
      <div className="animationLoader skeletonLoader1" />
      <div className="animationLoader skeletonLoaderTitle" />
      <div className="animationLoader skeletonLoader2" />
      <div className="animationLoader skeletonLoaderTitle" />
      <div className="animationLoader skeletonLoader3" />
      <div className="animationLoader skeletonLoaderTitle" />
      <div className="animationLoader skeletonLoader4" />
    </div>
  );
};

export default ToolDetailsRightSkeletonLoader;
