import { IconWarning } from '../../../assets/DesignSystem/SVGComponents';
import { NudosButton, NudosGenericModal, NudosModalContent } from '../../NudosComponents';
import './NudosConfirmActionModal.scss';

const NudosConfirmActionModal = ({
  secondButtonAction,
  firstButtonAction,
  firstButtonText,
  secondButtonText,
  modalText
}: {
  firstButtonAction: () => void;
  secondButtonAction: () => void;
  firstButtonText: string;
  secondButtonText: string;
  modalText: string;
}) => {
  const ModalData = () => {
    return (
      <div className="nudosConfirmActionModal">
        <div className="iconContainer">
          <IconWarning className="iconWarning" />
        </div>
        <div className="modalText">{modalText}</div>
        <div className="buttonsContainer">
          <NudosButton
            buttonText={firstButtonText}
            handleClick={firstButtonAction}
            colorPalette="secondary"
            customClassName="nudosButton"
          />
          <NudosButton buttonText={secondButtonText} handleClick={secondButtonAction} customClassName="nudosButton" />
        </div>
      </div>
    );
  };
  return (
    <div>
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            customWidth="466px"
            customHeight="220px"
            excludeHeader={true}
            componentSize="medium"
            CustomModalComponent={<ModalData />}
          />
        }
      />
    </div>
  );
};

export default NudosConfirmActionModal;
