import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" ref={ref} {...props}>
    <path
      className="path1"
      fill="#fff"
      stroke="#383838"
      d="M16 3.429c-3.55 0-7.26 0-10.358.582-1.414.265-2.52 1.355-2.86 2.753-.496 2.044-.496 3.595-.496 6.95 0 3.356 0 4.906.496 6.95.34 1.4 1.446 2.488 2.86 2.754C8.74 24 12.451 24 16 24c3.549 0 7.26 0 10.358-.582 1.414-.266 2.52-1.355 2.86-2.753.496-2.045.496-3.595.496-6.95 0-3.356 0-4.907-.496-6.951-.34-1.398-1.446-2.488-2.86-2.753-3.097-.582-6.809-.582-10.358-.582Z"
    />
    <path
      className="path2"
      stroke="#383838"
      strokeLinecap="round"
      d="M16 23.99v6.296m-4.571 0h9.142M16 9.143v9.143m4.571-4.572H11.43"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconAddTool = memo(ForwardRef);
export default IconAddTool;
