import { AxiosError } from 'axios';
import { addNewAddressOffice, addOtherLogisticsAddress } from '../../../services/account';
import { getEmployeeById, updateEmployeeData } from '../../../services/employees.service';
import {
  getNudosWarehousesListIncludingAdditionalServices,
  getProductLocationById,
  postAssignmentOrUnassignment
} from '../../../services/logisticsServicesModule.services';
import { DTOupdateEmployeeData } from '../../../types/DTO';
import { ILocationCountryAddress, IaddLogisticAddress } from '../../../types/account';
import { TstoragePlace } from '../../../types/assignationFluxes';
import { IdeliveryAddress, Iemployee } from '../../../types/global';
import {
  IOutRange,
  IassignmentInfoForAssignmentOrUnassignment,
  IcollectionInfoForAssignmentOrUnassignment,
  IdeliveryInfoForAssignmentOrUnassignment,
  IlocationAddressForLogistics,
  IoriginAndDestinationDataForAssignmentOrUnassignment,
  ItoolEssentialData,
  IwarehouseData,
  TlogisticsOriginOrDestinationData
} from '../../../types/requestLogisticsModule';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { getOrgData } from '../../../utils/getLocalStorageData';
import {
  isAddressDataComplete,
  isEmployeeDataComplete,
  isReceiverDataComplete
} from '../../../utils/productDefinitions';
import { getNodiProductById } from '../../../services/services';
import { ICountryDetail } from '../../../types/countries';
import { IbillingData } from '../../../types/checkout';
import { IToolInfo } from '../../../types/logisticsServicesModule.types';
import { deleteAndCreateNewID } from '../../../utils/segment';

export const getToolData = async (
  toolId: string | number,
  dataHandler: (response: ItoolEssentialData) => void,
  loaderHandler?: (loading: boolean) => void
) => {
  loaderHandler && loaderHandler(true);
  try {
    const response = await getNodiProductById(`${toolId}`);
    dataHandler(response);
    return response;
  } catch {
    displayErrorNotification();
  } finally {
    loaderHandler && loaderHandler(false);
  }
};

export const getNudosWarehousesList = async (
  dataHandler: (response: IwarehouseData[]) => void,
  loaderHandler?: (loading: boolean) => void
) => {
  const { organizationId } = getOrgData();
  if (!organizationId) return;
  loaderHandler && loaderHandler(true);
  try {
    const nudosWarehousesList = await getNudosWarehousesListIncludingAdditionalServices(organizationId);
    if (nudosWarehousesList) {
      dataHandler(nudosWarehousesList);
    }
  } catch {
    displayErrorNotification();
  } finally {
    loaderHandler && loaderHandler(false);
  }
};

export const getLocationData = async (
  locationId: string | number,
  dataHandler: (response: IlocationAddressForLogistics) => void,
  loaderHandler?: (loading: boolean) => void
) => {
  loaderHandler && loaderHandler(true);
  try {
    const response = await getProductLocationById(locationId);
    if (response) dataHandler(response);
  } catch {
    displayErrorNotification();
  } finally {
    loaderHandler && loaderHandler(false);
  }
};

export const getEmployeeDataByUserId = async (
  userId: number | string,
  dataHandler: (employeeData: Iemployee) => void,
  loadingHandler?: (loading: boolean) => void
) => {
  if (!userId) return;
  loadingHandler && loadingHandler(true);
  try {
    const employeeData = await getEmployeeById(userId);
    dataHandler(employeeData);
  } catch {
    displayErrorNotification();
  } finally {
    loadingHandler && loadingHandler(false);
  }
};

export const isDataComplete = (
  originOrDestination: TstoragePlace,
  data?: TlogisticsOriginOrDestinationData,
  countriesWithDefinedCities?: ICountryDetail[]
) => {
  if (!data) return false;
  const dataAsEmployee = data as Iemployee;
  const officeOrOtherAddressData = data as IlocationAddressForLogistics;
  const countryData =
    originOrDestination === 'user'
      ? dataAsEmployee?.country
      : officeOrOtherAddressData?.countryData || officeOrOtherAddressData?.country;
  const countryRequireCityId = !!countriesWithDefinedCities?.some(
    countryWithDefinedCities => countryWithDefinedCities?.code === countryData?.code
  );
  const isOfficeOrOtherAddress = ['office', 'other']?.includes(officeOrOtherAddressData?.place || '');
  if (['diagnostic', 'warranty', 'nudos'].includes(originOrDestination)) return true;
  if (dataAsEmployee?.userId) {
    return isEmployeeDataComplete(dataAsEmployee, true, countryRequireCityId);
  }
  if (isOfficeOrOtherAddress) {
    const officeAddress = {
      address: officeOrOtherAddressData?.address,
      additionalReferences: officeOrOtherAddressData?.additionalReferences,
      receiverInformation: officeOrOtherAddressData?.receiverInformation,
      country: officeOrOtherAddressData?.country || officeOrOtherAddressData?.countryData,
      coordinates: officeOrOtherAddressData?.coordinates,
      zipCode: officeOrOtherAddressData?.zipCode,
      city: officeOrOtherAddressData?.city
    };
    if (countryRequireCityId && !officeOrOtherAddressData?.cityId) return false;
    const completeOfficeAdddress = isAddressDataComplete(officeAddress as IdeliveryAddress, true);
    const completeReceiverData = isReceiverDataComplete(
      officeOrOtherAddressData?.receiverInformation,
      officeOrOtherAddressData?.countryData?.code || officeOrOtherAddressData?.country?.code
    );
    return completeOfficeAdddress && completeReceiverData;
  }
  return false;
};

export const updateLogisticsEmployeeData = async (
  employeeData: Iemployee,
  dataHandler: (newDataForEmployee: Iemployee) => void
) => {
  if (!employeeData || !employeeData?.userId) return;
  const updateEmployeeBody: DTOupdateEmployeeData = {
    firstName: employeeData?.firstName || '',
    lastName: employeeData?.lastName || '',
    personalId: employeeData?.personalId || '',
    city: employeeData?.city || '',
    cityId: employeeData?.cityId,
    area: employeeData?.area || '',
    position: employeeData?.position || '',
    address: { ...employeeData?.address, cityId: employeeData?.cityId },
    countryId: Number(employeeData?.country?.id),
    countryPhoneId: Number(employeeData?.phoneData?.countryId),
    phone: employeeData?.phoneData?.phone
  };
  await updateEmployeeData(employeeData.userId, updateEmployeeBody);
  const newDataForEmployee = await getEmployeeById(Number(employeeData?.userId));
  if (newDataForEmployee) dataHandler(newDataForEmployee);
};

export const updateOfficeData = async (
  newOfficeData: IlocationAddressForLogistics,
  updateLocationDataCallback?: (newVariableLocation: TlogisticsOriginOrDestinationData) => void
) => {
  const orgData = getOrgData();
  const requestUpdateOffice: ILocationCountryAddress = {
    address: newOfficeData?.address || '',
    additionalReferences: newOfficeData?.additionalReferences || '',
    coordinates: newOfficeData?.coordinates,
    city: newOfficeData?.city || '',
    cityId: newOfficeData?.cityId,
    zipCode: newOfficeData?.zipCode,
    countryId: Number(newOfficeData?.countryData?.id || newOfficeData?.country?.id),
    countryCode: newOfficeData?.countryData?.code || newOfficeData?.country?.code // CAMBIAR-JC: This is a hotfix, the definitive correction should be made in the context of a refactor that resolves the differences in countryIds between the different microservices, so only the countryId should work
  };
  const response = await addNewAddressOffice(Number(orgData?.organizationId), requestUpdateOffice);
  const newLocationData = response?.id ? { ...newOfficeData, id: response.id } : undefined; // In backend a new location was created, hence the requirement that the location id must be updated to the new one
  if (newLocationData && updateLocationDataCallback) updateLocationDataCallback(newLocationData);
};

export const updateOtherLocation = async (
  newOtherLocationData?: IlocationAddressForLogistics,
  updateLocationDataCallback?: (newVariableLocation: TlogisticsOriginOrDestinationData) => void
) => {
  const orgData = getOrgData();
  if (
    !newOtherLocationData?.address ||
    !newOtherLocationData?.city ||
    !newOtherLocationData?.countryId ||
    !newOtherLocationData?.locationName
  )
    return;
  const updatedOtherLocationData: IaddLogisticAddress = {
    id: newOtherLocationData?.id,
    address: newOtherLocationData?.address,
    additionalReferences: newOtherLocationData?.additionalReferences || '',
    zipCode: newOtherLocationData?.zipCode,
    city: newOtherLocationData?.city,
    cityId: newOtherLocationData?.cityId,
    coordinates: newOtherLocationData?.coordinates,
    countryId: +newOtherLocationData?.countryId,
    countryCode: newOtherLocationData?.countryData?.code,
    locationName: newOtherLocationData?.locationName
  };
  const response = await addOtherLogisticsAddress(Number(orgData?.organizationId), updatedOtherLocationData);
  const newLocationData = response?.id ? { ...newOtherLocationData, id: response.id } : undefined; // In backend a new location was created, hence the requirement that the location id must be updated to the new one
  if (newLocationData && updateLocationDataCallback) updateLocationDataCallback(newLocationData);
};

export const updateSingleLocation = async (
  locationPlace?: TstoragePlace,
  locationData?: TlogisticsOriginOrDestinationData,
  updateLocationDataCallback?: (newVariableLocation: TlogisticsOriginOrDestinationData) => void
) => {
  if (locationPlace === 'user' && updateLocationDataCallback) {
    await updateLogisticsEmployeeData(locationData as Iemployee, updateLocationDataCallback);
  }
  if (locationPlace === 'office' && updateLocationDataCallback) {
    await updateOfficeData(locationData as IlocationAddressForLogistics, updateLocationDataCallback);
  }
  if (locationPlace === 'other' && updateLocationDataCallback) {
    await updateOtherLocation(locationData as IlocationAddressForLogistics, updateLocationDataCallback);
  }
};

export const updateLocationsData = async (
  originPlace?: TstoragePlace,
  originLocationData?: TlogisticsOriginOrDestinationData,
  updateOriginLocationData?: (newFixeedLocation: TlogisticsOriginOrDestinationData) => void,
  destinationPlace?: TstoragePlace,
  destinationLocationData?: TlogisticsOriginOrDestinationData,
  updateDestinationLocationData?: (newFixeedLocation: TlogisticsOriginOrDestinationData) => void,
  handleLoading?: (loading: boolean) => void,
  afterUpdatingCallback?: () => void
) => {
  handleLoading && handleLoading(true);
  try {
    await updateSingleLocation(originPlace, originLocationData, updateOriginLocationData);
    await updateSingleLocation(destinationPlace, destinationLocationData, updateDestinationLocationData);
    afterUpdatingCallback && afterUpdatingCallback();
  } catch {
    displayErrorNotification();
  } finally {
    handleLoading && handleLoading(false);
  }
};

export const formatToolsForAssignmentOrUnassignment = (toolsEssentialData?: ItoolEssentialData[]) => {
  if (!toolsEssentialData || toolsEssentialData.some(tool => !tool?.productId)) return;
  return toolsEssentialData.map(tool => {
    return {
      productId: tool?.productId ? +tool.productId : 0,
      logisticAdditionalServicesData: tool?.newLogisticAdditionalServices || undefined
    };
  });
};

export const formatDateRange = (dateRange: IOutRange) => {
  if (dateRange) {
    const startDate = dateRange.startDate;
    const formatStartDay = startDate.getDate();
    const startDay = formatStartDay <= 9 ? `0${formatStartDay}` : formatStartDay;
    const formatStartMonth = startDate.getMonth() + 1;
    const startMonth = formatStartMonth <= 9 ? `0${formatStartMonth}` : formatStartMonth;
    const startYear = startDate.getFullYear().toString();
    const endDate = dateRange.endDate;
    const formatEndDay = endDate.getDate();
    const endDay = formatEndDay <= 9 ? `0${formatEndDay}` : formatEndDay;
    const formatEndMonth = endDate.getMonth() + 1;
    const endMonth = formatEndMonth <= 9 ? `0${formatEndMonth}` : formatEndMonth;
    const endYear = endDate.getFullYear().toString();
    const formatDate = `${startDay}/${startMonth}/${startYear.substring(
      2,
      4
    )} - ${endDay}/${endMonth}/${endYear.substring(2, 4)}`;
    return formatDate;
  }
};

/**
 * @param isAssignment - If the flux does NOT involve an unassignment this flag should be true, otherwise should be false (ie, the tools must be unassigned first)
 * @param requiresLogistics - A boolean indicating if the assignment/unassignment is to be done with logistics by Nudos
 * @param sendMail - 1 if the employee should be informed of the ASSIGNMENT, 0 if it should NOT
 * @param originAndDestinationData - The origin and destination data require for the request
 * @param toolsData - An array with the tools involved in the assignment/unassignment
 * @param assignmentInfo - REQUIRED for ASSIGNMENTS to another employee or to MOVE THE TOOL to an office/other location The information required for the assignment/movement of the tools.
 * @param collectionInfo - The information pertaining the dates, comments and data of the person involved in the collection of the tools
 * @param deliveryInfo - The information pertaining the dates, comments and data of the person involved in the reception of the delivered tools
 * @param File - The file to annexed in the email notification for the employee about the ASSIGNMENT
 * @param invoiceAdditionalEmail - An optional additional email (string) used to send a slack message
 * @param handleLoading - A callback used to set the loading state for the request wherever this funcion is invoked
 * @param successfulRequestCallback - A callback to execute if the request succeeded
 * @param handleErrorCallback - A callback to execute if the request failed
 * @returns void
 */
export const assignOrUnassign = async (
  isAssignment: boolean,
  requiresLogistics: boolean,
  sendMail: boolean,
  originAndDestinationData: IoriginAndDestinationDataForAssignmentOrUnassignment,
  billingData: IbillingData | null,
  billingCountry?: ICountryDetail | null,
  toolsData?: ItoolEssentialData[],
  assignmentInfo?: IassignmentInfoForAssignmentOrUnassignment,
  collectionInfo?: IcollectionInfoForAssignmentOrUnassignment,
  deliveryInfo?: IdeliveryInfoForAssignmentOrUnassignment,
  File?: File,
  invoiceAdditionalEmail?: string,
  handleLoading?: (loading: boolean) => void,
  successfulRequestCallback?: (logisticServiceId: number) => void,
  handleErrorCallback?: (error: AxiosError) => void
) => {
  const orgData = getOrgData();
  const formatedTools = formatToolsForAssignmentOrUnassignment(toolsData);

  if (!formatedTools) return;
  const logisticServiceData = {
    ...originAndDestinationData,
    tools: formatedTools,
    requester: { userId: orgData?.userId },
    collectionInfo,
    deliveryInfo
  };
  const body = {
    organizationId: orgData?.organizationId,
    organizationName: orgData?.organizationName,
    assignmentInfo,
    logisticService: logisticServiceData,
    billingData,
    billingCountryCode: billingCountry?.code,
    invoiceAdditionalEmail
  };
  handleLoading && handleLoading(true);
  try {
    const response = await postAssignmentOrUnassignment(isAssignment, requiresLogistics, sendMail, body, File);
    deleteAndCreateNewID();
    successfulRequestCallback && successfulRequestCallback(response);
  } catch (error) {
    handleErrorCallback && handleErrorCallback(error as AxiosError);
    !handleErrorCallback && displayErrorNotification();
  } finally {
    handleLoading && handleLoading(false);
  }
};

export const formatCheckQuoteAndBillingDataTools = (tools: ItoolEssentialData[]) => {
  const originalToolsList: ItoolEssentialData[] = tools;
  const toolsList: IToolInfo[] = [];
  if (originalToolsList) {
    for (let i = 0; i < originalToolsList.length; i++) {
      const indexItem = originalToolsList[i];
      const newObj: IToolInfo = {
        productId: Number(indexItem?.productId),
        logisticAdditionalServicesData: indexItem?.newLogisticAdditionalServices
      };
      if (newObj?.productId) {
        toolsList.push(newObj);
      }
    }
    return toolsList;
  }
  return toolsList;
};
