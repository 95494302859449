import { useTranslation } from 'react-i18next';
import { IconLightbulb } from '../../../../../assets/DesignSystem/SVGComponents';
import { NudosStaticAlertInComponent } from '../../../../../components/DesignSystem';
import { NudosButton } from '../../../../../components/NudosComponents';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import { LOCATION_PLACE_TYPE } from '../../../../../types/global';
import {
  IadditionalServiceDataForToolLogistics,
  IlogisticAdditionalService,
  ItoolEssentialData
} from '../../../../../types/requestLogisticsModule';
import { NudosToolLogisticsWithAdditionalServices } from '../../../Components';
import './AssignmentModuleStep1SelectTools.scss';

const ToolsToAssignListingSection = ({
  openModalCallback,
  selectedToolsToAssign,
  selectedOrigin,
  toolsOriginCountryWarehouseAdditionalServices,
  handleClickToolSelectButton,
  handleUpdateSelectedToolsToAssign
}: {
  openModalCallback: () => void;
  selectedOrigin?: TstoragePlace;
  selectedToolsToAssign: ItoolEssentialData[];
  toolsOriginCountryWarehouseAdditionalServices?: IlogisticAdditionalService[];
  handleClickToolSelectButton: (tool: ItoolEssentialData) => void;
  handleUpdateSelectedToolsToAssign: (newToolsToAssign: ItoolEssentialData[]) => void;
}) => {
  const { t } = useTranslation();
  const assignMoreToolsButton = t('services:logistics:assignMoreToolsButton');
  const additionalServicesPossibilityAlertText = t('services:logistics:additionalServicesPossibilityAlertText');
  const additionalServicesPossibilityAlertOptions = {
    title: additionalServicesPossibilityAlertText,
    icon: <IconLightbulb className="IconLightbulb" />
  };
  const atLeastOneToolCanHaveAdditionalServices = selectedToolsToAssign?.some(
    tool =>
      (tool.category === 'Laptops' ||
        tool.category === 'Laptops CTOs' ||
        tool.category === 'Laptops con DEP' ||
        tool.category === 'PC') &&
      tool?.place === 'nudos'
  );
  const listingWithoutAdditionalServicesStyle = atLeastOneToolCanHaveAdditionalServices
    ? undefined
    : 'noAdditionalServices';

  const updateAdditionalServicesForSelectedTool = (
    specificTool?: ItoolEssentialData,
    type?: 'edit' | 'delete',
    additionalServicesForSpecificTool?: IadditionalServiceDataForToolLogistics[]
  ) => {
    const toolIndex = selectedToolsToAssign.findIndex(
      toolToAssign => toolToAssign?.productId === specificTool?.productId
    );
    if (toolIndex < 0) return;
    const newToolsToAssign = [...selectedToolsToAssign];
    newToolsToAssign[toolIndex].newLogisticAdditionalServices = additionalServicesForSpecificTool;
    handleUpdateSelectedToolsToAssign(newToolsToAssign);
  };

  return (
    <div className="ToolsToAssignListingSection">
      <NudosButton
        componentSize="small"
        buttonText={assignMoreToolsButton}
        customClassName="openToolsSelectionModalButton"
        handleClick={openModalCallback}
      />
      <div className="divider" />
      <section className="selectedToolsSection">
        <h3 className="sectionTitle">{t('nodi:UploadExternalToolsModule:Step1SelectReferences:textTitle')}</h3>
        <div className={`toolsContainer ${listingWithoutAdditionalServicesStyle}`}>
          {selectedToolsToAssign.map((tool, i) => {
            return (
              <NudosToolLogisticsWithAdditionalServices
                key={`selectedTool${tool?.id}${i}`}
                toolData={tool}
                isSelected
                toolHasSelectButton={true}
                handleClickSelectButton={() => handleClickToolSelectButton(tool)}
                toolLogisticsPlaceThatMayBeWarehouse={selectedOrigin}
                warehouseAditionalServices={toolsOriginCountryWarehouseAdditionalServices}
                updateToolsToAssignOrUnassignData={updateAdditionalServicesForSelectedTool}
                destination={'user' as LOCATION_PLACE_TYPE}
              />
            );
          })}
        </div>
        {atLeastOneToolCanHaveAdditionalServices && (
          <NudosStaticAlertInComponent alertOptions={additionalServicesPossibilityAlertOptions} />
        )}
      </section>
    </div>
  );
};

export default ToolsToAssignListingSection;
