import create from 'zustand';

export interface iState {
  isLogoutModalOpen: boolean;
  setIsLogoutModalOpen: (isLogoutModalOpen: boolean) => void;
  openLogoutModal: boolean;
  setOpenLogoutModal: (openLogoutModal: boolean) => void;
  openMinMenu: boolean;
  setOpenMinMenu: (openMinMenu: boolean) => void;
}

const useLogOutState = create<iState>(set => ({
  isLogoutModalOpen: false,
  setIsLogoutModalOpen: isLogoutModalOpen => set({ isLogoutModalOpen }),
  openLogoutModal: false,
  setOpenLogoutModal: (openLogoutModal: boolean) => set({ openLogoutModal }),
  openMinMenu: false,
  setOpenMinMenu: openMinMenu => set({ openMinMenu })
}));

export default useLogOutState;
