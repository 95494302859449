import React from 'react';
import { ReturnButton } from '../../../assets/images/components/NudosComponents';
import NudosGenericSelect from '../NudosGenericSelect';
import NudosSelectButton from '../NudosSelectButton/NudosSelectButton';
import './ModuleFilters.scss';

const ModuleFiltersExpandableSelect = ({
  filterTitle,
  openSelectStatus,
  openSelectFunction,
  rawSelectOptions,
  handleChangeSelectedOption,
  isFilterActive
}: IModuleFiltersExpandableSelect) => {
  const changeOpenStatusFunction = () => openSelectFunction(!openSelectStatus);

  const formSelectOptionsUI = () => {
    return rawSelectOptions.reduce<JSX.Element[]>((prev, option, i) => {
      if (option?.name?.length > 0) {
        const newOption = (
          <div
            key={`${filterTitle}Option${i}`}
            className={`optionContainer`}
            onClick={() => handleChangeSelectedOption(option)}
          >
            <div className="optionText">
              {typeof option?.icon === 'string' && <img src={option?.icon} alt="option icon" />}
              {typeof option?.icon !== 'string' && React.isValidElement(option?.icon) && option?.icon}
              {option.name}
            </div>
            <NudosSelectButton iconName="xmark" componentSize="small" isButtonActive={option?.isActive} />
          </div>
        );
        const newOptions: JSX.Element[] = [...prev, newOption];
        return newOptions;
      }
      return prev;
    }, []);
  };

  return (
    <div className={`moduleFilterExpandableSelect ${isFilterActive ? 'active' : ''}`}>
      <div className="closedSelect" onClick={changeOpenStatusFunction}>
        {filterTitle}
      </div>
      {!openSelectStatus && (
        <div className="arrowIcon expandArrow" onClick={changeOpenStatusFunction}>
          <ReturnButton />
        </div>
      )}
      {openSelectStatus && (
        <div className="arrowIcon contractArrow">
          <ReturnButton />
        </div>
      )}
      {openSelectStatus && (
        <NudosGenericSelect
          selectClassName={`genericSelect`}
          selectOptions={formSelectOptionsUI()}
          changeSelectedOption={handleChangeSelectedOption}
          defaultValue={undefined}
          changeOpenStatusFunction={changeOpenStatusFunction}
        />
      )}
    </div>
  );
};

export default ModuleFiltersExpandableSelect;

export interface IModuleFiltersExpandableSelect {
  filterTitle: string;
  openSelectStatus: boolean;
  openSelectFunction: React.Dispatch<React.SetStateAction<boolean>>;
  rawSelectOptions: IrawSelectOption[];
  handleChangeSelectedOption: (selectedOption: IrawSelectOption) => void;
  isFilterActive?: boolean;
}

export interface IrawSelectOption {
  name: string;
  value?: string;
  id?: string | number;
  icon?: string | null;
  isActive?: boolean;
}
