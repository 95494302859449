import { FC } from 'react';
import { ILogisticQuoteGeneralData } from '../../../../../../types/logisticsServicesModule.types';
import CardQuoteTool from './CardQuoteTool';
import './LogisticsQuoteByTool.scss';
import { TstoragePlace } from '../../../../../../types/assignationFluxes';

interface ILogisticsQuoteByTool {
  logisticServiceQuoteData: ILogisticQuoteGeneralData;
  destinationPlace?: TstoragePlace;
  originPlace?: TstoragePlace;
}

const LogisticsQuoteByTool: FC<ILogisticsQuoteByTool> = ({
  logisticServiceQuoteData,
  destinationPlace,
  originPlace
}: ILogisticsQuoteByTool) => {
  return (
    <div id="logisticsQuoteByTool">
      <div className="containerQuoteByTools">
        {logisticServiceQuoteData &&
          logisticServiceQuoteData?.tools &&
          logisticServiceQuoteData?.tools.length > 0 &&
          logisticServiceQuoteData.tools.map((item, index) => {
            return (
              <CardQuoteTool
                index={index}
                cardData={item}
                key={`card-quote-id-${item.productId}-index-${index}`}
                originPlace={originPlace}
                destinationPlace={destinationPlace}
              />
            );
          })}
      </div>
    </div>
  );
};

export default LogisticsQuoteByTool;
