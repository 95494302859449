import { useTranslation } from 'react-i18next';
import { DTOgetTaxationRelatedDefinitionsOptions, IbillingData } from '../../../types/checkout';
import { useEffect, useState } from 'react';
import { ICountryDetail } from '../../../types/countries';
import { getBillingTaxDefinitionsOptions, useBillingData } from '../../../hooks/useBillingData';
import { NudosButton } from '../../NudosComponents';
import IconWarningm from '../../../assets/DesignSystem/SVGComponents/IconWarning';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import './NudosBillingDataFormModal.scss';

const NudosBillingDataFormModalContent = ({
  initialBillingData = {},
  showSkeletonLoader,
  submitModalCallback,
  setTemporaryBillingData,
  availableCountries
}: {
  initialBillingData?: IbillingData;
  showSkeletonLoader?: boolean;
  submitModalCallback: (updatedData?: IbillingData) => void;
  setTemporaryBillingData: (updatedData?: IbillingData) => void;
  availableCountries: ICountryDetail[];
}) => {
  const { t } = useTranslation();
  const [allCountries, setAllCountries] = useState<ICountryDetail[]>();
  const [taxationRelatedDefinitionsOptions, setTaxationRelatedDefinitionsOptions] =
    useState<DTOgetTaxationRelatedDefinitionsOptions>();

  const i18nLanguageKey = 'nodi:account:billingDataByCountryForm:';
  const alertText = t(`${i18nLanguageKey}alertText`);

  const {
    billingData,
    isDataIncomplete,
    loadingBillingDataForm,
    isAMexicanOrganization,
    organizationIsUruguay,
    thisBillingCountryRequiresTaxSituationDocument,
    getEmailInput,
    getCityInput,
    getZipCodeInput,
    getTaxDocumentInput,
    getBusinessNameInput,
    getBillingAddressInput,
    getNudosCountryDropwdown,
    getBillingDataStateInput,
    getAdditionalReferencesInput,
    getTaxUseDropdownWithSearchbar,
    getTaxRegimeDropdownWithSearchbar,
    getTaxDocumentUploadDownloadButton,
    handleUpdateBillingData,
    getPhoneNumberInput,
    getFantasyNameInput,
    getSpecialBusinessNameType
  } = useBillingData(initialBillingData);

  const handleClickSaveButton = () =>
    handleUpdateBillingData(newlyCreatedBillingData => {
      submitModalCallback({ ...billingData, ...newlyCreatedBillingData });
    });

  const saveButton = (
    <NudosButton
      buttonText={t(`${i18nLanguageKey}saveButton`)}
      handleClick={handleClickSaveButton}
      componentSize="medium"
      customClassName="saveButton"
      isButtonLoading={!isDataIncomplete() && loadingBillingDataForm}
      isButtonDisabled={isDataIncomplete()}
    />
  );

  useEffect(() => {
    billingData && setTemporaryBillingData && setTemporaryBillingData(billingData);
  }, [billingData]);

  useEffect(() => {
    getListOfCountriesByOfferedService(setAllCountries);
    getBillingTaxDefinitionsOptions(setTaxationRelatedDefinitionsOptions);
  }, []);

  return (
    <div className="NudosBillingDataFormModalContent">
      <div className="billingInputsContainer">
        <div className="formFieldsLine firstLine">
          <div className="countryDropwdown">
            {getNudosCountryDropwdown(
              availableCountries || allCountries || [],
              !!initialBillingData?.documentCountry?.id,
              showSkeletonLoader,
              true
            )}
          </div>
          {getBusinessNameInput(showSkeletonLoader)}
          {getTaxDocumentInput(showSkeletonLoader)}
        </div>
        {organizationIsUruguay && (
          <div className="formFieldsLine fifthLine">
            {getFantasyNameInput(showSkeletonLoader)}
            {getPhoneNumberInput(allCountries || [], showSkeletonLoader)}
          </div>
        )}
        <div className="formFieldsLine secondLine">
          {getEmailInput(showSkeletonLoader)}
          {getZipCodeInput(showSkeletonLoader)}
          {getCityInput(showSkeletonLoader)}
        </div>
        <div className="formFieldsLine thirdLine">
          {getBillingDataStateInput(showSkeletonLoader)}
          {getAdditionalReferencesInput(showSkeletonLoader)}
        </div>
        <div className="formFieldsLine fourthLine">{getBillingAddressInput(showSkeletonLoader, 'extraLarge')}</div>
        {isAMexicanOrganization && (
          <div className="formFieldsLine fifthLine">
            {getTaxRegimeDropdownWithSearchbar(
              taxationRelatedDefinitionsOptions?.taxSituation || [],
              showSkeletonLoader
            )}
            {getTaxUseDropdownWithSearchbar(taxationRelatedDefinitionsOptions?.taxUse || [], showSkeletonLoader)}
          </div>
        )}
        {thisBillingCountryRequiresTaxSituationDocument && (
          <div className="formFieldsLine">{getTaxDocumentUploadDownloadButton(showSkeletonLoader)}</div>
        )}
      </div>
      <div className="alert">
        <IconWarningm />
        {alertText}
      </div>
      {getSpecialBusinessNameType()}
      {saveButton}
    </div>
  );
};

export default NudosBillingDataFormModalContent;
