import { FC, useEffect, useState } from 'react';
import { nudosCaritas } from '../../../../../assets/images/views/Admin/uploadExternalToolModule';
import { IconDropdownArrow, IconWarning } from '../../../../../assets/DesignSystem/SVGComponents';
import NudosDynamicBanner, {
  INudosDynamicBanner
} from '../../../../../components/DesignSystem/NudosDynamicBanner/NudosDynamicBanner';
import { segmentTrackEvent } from '../../../../../utils/segment';
import './LimitToolsBanner.scss';

interface ILimitToolsBanner {
  maximumExternalUnits: number;
  totalUnits: number;
  screenName: string;
}

const LimitToolsBanner: FC<ILimitToolsBanner> = ({ maximumExternalUnits, totalUnits }: ILimitToolsBanner) => {
  const [percentageOfTools, setPercentageOfTools] = useState<number>(0);
  const [bannerInfo, setBannerInfo] = useState<INudosDynamicBanner>();
  const totalToolsExcedTheLimit = totalUnits > maximumExternalUnits;

  const calculatePercentage = () => {
    const initialNumber = 100 / maximumExternalUnits;
    const newTotalUnits = totalUnits > maximumExternalUnits ? maximumExternalUnits : totalUnits;
    const calcPercentage = initialNumber * newTotalUnits;
    setPercentageOfTools(+calcPercentage.toFixed(1));
  };

  const bannerPrimeButtonAction = () => {
    segmentTrackEvent({ nodiFreeUploadToolBannerNodiPrimeAdvertiseClick: null });
    setBannerInfo({
      bannerTitle: 'Carga todas las herramientas que necesites',
      bannerSubtitle:
        'Actualiza a Nodi Prime para cargar todas las herramientas que desees, obtener descuentos y disfrutar de mucho más con todo lo que Nudos tiene para ti.'
    });
    segmentTrackEvent({ nodiFreeUploadToolModalNodiPrimeAdvertiseView: { ScreenName: 'ToolsUpload Searcher' } });
  };

  const submitBannerCallback = () => {
    segmentTrackEvent({ nodiFreeUploadToolModalNodiPrimeAdvertiseClick: null });
  };

  useEffect(() => {
    calculatePercentage();
  }, [totalUnits]);

  return (
    <>
      {bannerInfo && (
        <NudosDynamicBanner
          bannerTitle={bannerInfo?.bannerTitle || ''}
          bannerSubtitle={bannerInfo?.bannerSubtitle || ''}
          openedBannerOrigin="cargue de herramientas"
          closeModalCallback={() => setBannerInfo(undefined)}
          submitBannerCallback={submitBannerCallback}
          alertType={2}
        />
      )}
      <div id="limitToolsBanner" style={{ backgroundImage: `url("${nudosCaritas}")` }}>
        <div className="containerProgress">
          <div className="boxProgress">
            <div className="percentageContainer">
              <svg width="64" height="64">
                <circle r="29" cx="50%" cy="50%" pathLength="100" />
                <circle
                  r="29"
                  cx="50%"
                  cy="50%"
                  pathLength="100"
                  style={{ strokeDasharray: `${percentageOfTools || 0} 100` }}
                />
              </svg>
              <span className="percentageText">{`${percentageOfTools}%`}</span>
            </div>
          </div>
        </div>
        <div className="containerInfo">
          <div className="boxContainerBannerInfo">
            <div className="bannerTitle">Herramientas externas cargadas</div>
            <div className="bannerQuantityContainer">
              <div className="bannerQuantity">{`${totalUnits}/${maximumExternalUnits}`}</div>
              {totalToolsExcedTheLimit && <IconWarning />}
            </div>
            <button className="bannerPrimeButton" onClick={() => bannerPrimeButtonAction()}>
              <div className="textButtonBanner">Actualiza a Nodi Prime</div>
              <div className="arrowButtonBanner">
                <div className="containerRotateArrow">
                  <IconDropdownArrow stroke="white" />
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LimitToolsBanner;
