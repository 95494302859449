import { ReturnButton } from '../../assets/images/components/header';
import useOtpController from '../../components/Otp/otp.controller';
import { useHistory } from 'react-router-dom';
import urlParameter from '../../utils/urlParameter';
import { useEffect } from 'react';
import SignUpLeftAside from '../signUp/components/SignUpLeftAside';
import { displayErrorNotification, displaySuccessNotification } from '../../utils/displayNudosStandardNotifications';
const WaitForEmail = () => {
  const email = localStorage.getItem('emailForSignIn');
  const status = urlParameter('status');
  const token = urlParameter('apiKey');
  const mode = urlParameter('mode');
  const { verifyLink } = useOtpController();

  const { sendLinkToEmail } = useOtpController();
  const { replace } = useHistory();

  const resendEmail = async () => {
    if (email) {
      const sendStatus = await sendLinkToEmail(email);
      if (sendStatus?.success) {
        displaySuccessNotification({ customJSXMessage: <>Hemos reenviado el link a tu correo</> });
      } else {
        displayErrorNotification();
      }
    }
  };

  const backScreen = () => {
    replace('/');
  };
  const starAutoLogin = async () => {
    if (token && mode && mode === 'signIn') {
      verifyLink();
    }
  };
  useEffect(() => {
    starAutoLogin();
  }, []);

  return (
    <div id="waitForMail">
      {token && mode && mode === 'signIn' ? (
        <div id="containerLoading">
          <div className="boxLoading" />
        </div>
      ) : (
        <>
          <SignUpLeftAside />

          <div className="sizeContainer">
            <div className="floatHeader" onClick={backScreen}>
              <div className="positionArrow">
                <ReturnButton fill="#ff664e" />
              </div>
              <div className="titleFloat">Link de acceso</div>
            </div>
            <div className="alignVerticalCenter">
              <div className="alignHorizontalCenter">
                {status && status === 'register' ? (
                  <div className="alertText">
                    Hemos enviadoun link a <span className="exeptionAlert">{email}</span>. Ingresa poder validar tu
                    correo y finalizar el proceso de registro
                  </div>
                ) : (
                  <div className="alertText">
                    Hemos enviado a tu correo <span className="exeptionAlert">{email}</span> el link de acceso al
                    e-commerce
                  </div>
                )}
                <div className="minAlert">
                  Si en 5 minutos no recibes el correo da click a{' '}
                  <span
                    onClick={() => {
                      resendEmail();
                    }}
                    className="exeptionAlert cursor-pointer"
                  >
                    reenviar
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default WaitForEmail;
