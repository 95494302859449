import { useState } from 'react';
import IconOpenBox from '../../../../../../../assets/DesignSystem/SVGComponents/IconOpenBox';
import { EditPencilIcon } from '../../../../../../../assets/images/views/Admin/employees';
import { InudoInfo, Tany } from '../../../../../../../types/global';
import ToolEditAcquisitionDataModal from './EditAcquisitionDataModal/EditAcquisitionDataModal';
import { formatShortDate } from '../../../../../../../utils/formatDatesAndHours';
import Bulb from '../../../../../../../assets/images/components/newCard/bulb';
import Cart from '../../../../../../../assets/images/components/newCard/cart';
import { NudosLinkTypeButton } from '../../../../../../../components/DesignSystem';
import { IconDropdownArrow } from '../../../../../../../assets/DesignSystem/SVGComponents';
import './ToolAcquisitionData.scss';

const ToolAcquisitionData = ({
  searchedAcquisition,
  updateToolDataCallback
}: {
  searchedAcquisition: InudoInfo | undefined;
  updateToolDataCallback: (data: Tany) => void;
}) => {
  const [showEditAcquisitionModal, setShowEditAcquisitionModal] = useState<boolean>(false);
  const [showExpandedSection, setShowExpandedSection] = useState(false);

  const isExternalTool = searchedAcquisition?.referenceModel === 'ExternalProduct';
  const toolTimeZone = searchedAcquisition?.country?.timeZone || undefined;
  const formatToolDate = formatShortDate(searchedAcquisition?.acquisitionDate, toolTimeZone).onlyDate;
  const formatToolendDay = formatShortDate(searchedAcquisition?.endDay, toolTimeZone).onlyDate;

  const toolSaleValue = searchedAcquisition?.saleValueUsd && `$${searchedAcquisition?.saleValueUsd} USD`;
  const expandedStyles = showExpandedSection ? { height: 'max-content' } : undefined;
  const expandOrContractButtonText = showExpandedSection ? 'Ver menos' : 'Ver más';
  const rotatedArrowstyles = { rotate: showExpandedSection ? '180deg' : '0deg' };

  const clickExpandOrContractButton = () => setShowExpandedSection(!showExpandedSection);

  const hasAcquistionData =
    searchedAcquisition?.acquisitionType ||
    searchedAcquisition?.acquisitionDate ||
    searchedAcquisition?.cost ||
    searchedAcquisition?.warranty ||
    searchedAcquisition?.monthlyValue ||
    searchedAcquisition?.endDay;

  const NoAcquisitionDataComponent = () => {
    return (
      <div className="noAcquisitionDataContainer">
        <div className="noAcquisitionDataIcon">{<IconOpenBox />}</div>
        <div className="noAcquisitionDataTitle">Completa los detalles de tu herramienta</div>
        <div className="noAcquisitionDataDescription">
          En este espacio podrás visualizar el tipo de adquisición, precio de compra, tiempo de garantía, fecha de
          compra y estado del producto
        </div>
        <div className="completeInformation" onClick={() => setShowEditAcquisitionModal(true)}>
          Completar información
        </div>
      </div>
    );
  };

  return (
    <div className="acquisitionData">
      <div className="acquisitionDataTitle">Adquisición</div>
      {!hasAcquistionData && <NoAcquisitionDataComponent />}

      {hasAcquistionData && (
        <div className="acquisitionDataContainer">
          <div className="dataContainer" style={expandedStyles}>
            <div className="property">
              <div className="left">Tipo de adquisición</div>
              {searchedAcquisition?.acquisitionType === 'Renta' && (
                <Bulb className="acquisitionTypeIcon" stroke="#383838" />
              )}
              {searchedAcquisition?.acquisitionType === 'Compra' && (
                <Cart className="acquisitionTypeIcon" stroke="#383838" />
              )}
              <div>{searchedAcquisition?.acquisitionType || 'Sin información'}</div>
              {isExternalTool && (
                <EditPencilIcon className="pencilIcon" onClick={() => setShowEditAcquisitionModal(true)} />
              )}
            </div>

            <div className="property">
              <div className="left">
                {searchedAcquisition?.acquisitionType === 'Renta' ? 'Fecha de adquisición' : 'Fecha de compra'}
              </div>
              <div>{formatToolDate || 'Sin información'}</div>
            </div>

            {searchedAcquisition?.acquisitionType === 'Renta' && isExternalTool && (
              <div className="property">
                <div className="left">Fecha fin de renta </div>
                <div>{formatToolendDay || 'Sin información'}</div>
              </div>
            )}

            {searchedAcquisition?.acquisitionType === 'Renta' && !isExternalTool && (
              <div className="property">
                <div className="left">Plazo</div>
                <div>{searchedAcquisition?.duration || 'Sin información'}</div>
              </div>
            )}

            {searchedAcquisition?.acquisitionType === 'Renta' && !isExternalTool && (
              <div className="property">
                <div className="left">Precio renta </div>
                <div>{searchedAcquisition?.rentValueUsd || 'Sin información'}</div>
              </div>
            )}

            {searchedAcquisition?.acquisitionType === 'Renta' && (
              <div className="property">
                <div className="left">Pago mensual </div>
                <div>{searchedAcquisition?.monthlyValueUsd || 'Sin información'}</div>
              </div>
            )}

            {searchedAcquisition?.acquisitionType !== 'Renta' && (
              <div className="property">
                <div className="left">Valor de la compra </div>
                <div>{toolSaleValue || 'Sin información'}</div>
              </div>
            )}

            {searchedAcquisition?.acquisitionType !== 'Renta' && (
              <div className="property">
                <div className="left">Garantía</div>
                <div>{searchedAcquisition?.warranty || 'Sin información'}</div>
              </div>
            )}
          </div>
          <NudosLinkTypeButton
            customClassname="expandOrContractButton"
            handleClickCallback={clickExpandOrContractButton}
            text={expandOrContractButtonText}
            hideIcon
            rightIcon={<IconDropdownArrow className="iconDropdownArrow" style={rotatedArrowstyles} />}
          />
        </div>
      )}

      {showEditAcquisitionModal && isExternalTool && (
        <ToolEditAcquisitionDataModal
          searchedAcquisition={searchedAcquisition}
          closeModalCallback={() => setShowEditAcquisitionModal(false)}
          updateToolDataCallback={updateToolDataCallback}
        />
      )}
    </div>
  );
};

export default ToolAcquisitionData;
