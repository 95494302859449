import React from 'react';
import './NudosButton.scss';
import Loading from '../Loading';

/**
 TO ADJUST TO A SIZE DIFFERENT THAN THE STANDARD ONES USE THE customWidth and customHeight properties in conjunction
 * @property {string} buttonText - Required, he text displayed on the button.
 * @property {() => void} handleClick - Optional, the callback action to execute upon clicking the button, should only be optional if the property isOfTypeSubmit is passed.
 * @property {boolean} isOfTypeSubmit - Optional, a boolean indicating if the button acts as submitter of a form.
 * @property {string} formId - Optional, the id of the corresponding form, if the button is of type submit.
 * @property {string} colorPalette - One of the defined color palettes, corresponding to one of the folowing words: "primary", "secondary", "blue". Default value is "primary".
 * @property {boolean} isButtonDisabled - Boolean indicading if the button is disabled (true) or active (false). Default value is false.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "extraSmall", "small", "medium", "large". If no value is passed the width of the component corresponds to that of the parent component.
 * @property {string} customClassName - Optional, a custom className to personalized the component.
 * @property {JSX.Element | string} buttonIcon - Optional, the icon adjacent to the text.
 * @property {boolean} isButtonLoading - Optional, boolean indicating if the button is waiting for some action to complete. If true, button is disabled. Default value is false.
 * @property {JSX.Element} customLoader - Optional, custom loader to be displayed when button is loading
 * @property {string} customWidth - Optional, for example "100px". The desired value for the width of the button, corresponds to a custom value for the width and maxWidth css property. MUST BE USED IN CONJUNCTION WITH customHeight TO HAVE EFFECT
 * @property {string} customHeight - Optional, for example "20px" The desired value for the height of the button, corresponds to a custom value for the height css property. MUST BE USED IN CONJUNCTION WITH customWidth TO HAVE EFFECT
 * @property { boolean } shouldAttractAttention -  Optional, a boolean indicating if the button should display an animation attracting attention
 */
const NudosButton = ({
  buttonText,
  handleClick,
  isOfTypeSubmit,
  formId,
  colorPalette,
  isButtonDisabled,
  componentSize,
  customClassName,
  buttonIcon,
  isButtonLoading,
  customLoader,
  customWidth,
  customHeight,
  customMarginIcon,
  customFontStyles,
  shouldAttractAttention
}: InudosButton): JSX.Element => {
  const sizeStyles =
    customWidth && customHeight ? { width: customWidth, maxWidth: customWidth, height: customHeight } : undefined;
  const attentionAtractionStyles = shouldAttractAttention ? 'attentionAnimation' : '';
  const componentSizeStyles = componentSize ? componentSize : '';
  const colorPaletteStyles = colorPalette ? colorPalette : '';
  const customClassname = customClassName ? customClassName : '';
  return (
    <button
      className={`nudosButton ${customClassname} ${componentSizeStyles} ${colorPaletteStyles} ${attentionAtractionStyles}`}
      disabled={isButtonDisabled || isButtonLoading ? true : false}
      onClick={handleClick}
      type={isOfTypeSubmit ? 'submit' : undefined}
      form={formId}
      style={sizeStyles}
    >
      {isButtonLoading ? (
        customLoader ? (
          { customLoader }
        ) : (
          <Loading />
        )
      ) : (
        <>
          {buttonText && (
            <div className="textContainer" style={customFontStyles}>
              {buttonText}
            </div>
          )}
          {buttonIcon && (
            <div className="iconContainer" style={{ marginLeft: customMarginIcon ? customMarginIcon : '' }}>
              {typeof buttonIcon === 'string' && <img src={buttonIcon} alt="Button icon" />}
              {React.isValidElement(buttonIcon) && <>{buttonIcon}</>}
            </div>
          )}
        </>
      )}
    </button>
  );
};

export default NudosButton;

export interface InudosButton {
  buttonText?: string;
  handleClick?: () => void;
  isOfTypeSubmit?: boolean;
  formId?: string;
  colorPalette?: string;
  isButtonDisabled?: boolean;
  componentSize?: string;
  customClassName?: string;
  buttonIcon?: string | JSX.Element;
  isButtonLoading?: boolean;
  customLoader?: JSX.Element;
  customWidth?: string;
  customHeight?: string;
  customMarginIcon?: string;
  customFontStyles?: { [key: string]: string };
  shouldAttractAttention?: boolean;
}
