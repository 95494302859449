import './NudosLogisticsGeneralContainer.scss';

/**
 * IMPORTANT: to show the dividing line include where needed, in the children, a div with the classname "divider"
 * @property { string } title - The title for the container
 * @property { React.ReactNode } children - The children
 */
const NudosLogisticsGeneralContainer = ({
  title,
  children,
  customStyles,
  customClassname
}: {
  title?: string;
  children: React.ReactNode;
  customStyles?: { [key: string]: string };
  customClassname?: string;
}) => {
  return (
    <div className={`NudosLogisticsGeneralContainer ${customClassname || ''}`} style={customStyles}>
      {title && <div className="generalContainerTitle">{title}</div>}
      {children}
    </div>
  );
};

export default NudosLogisticsGeneralContainer;
