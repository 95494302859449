import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M4.80008 3.39466C4.80008 3.05685 4.738 2.7233 4.61739 2.41121C4.49678 2.09911 4.31999 1.81554 4.09713 1.57667C3.87426 1.33781 3.60968 1.14833 3.3185 1.01905C3.02731 0.889778 2.71522 0.823242 2.40004 0.823242C2.08486 0.823242 1.77277 0.889779 1.48158 1.01905C1.1904 1.14833 0.92582 1.33781 0.702956 1.57667C0.480091 1.81554 0.303305 2.09911 0.182692 2.41121C0.0620788 2.7233 -2.75537e-08 3.05685 0 3.39466L1.20002 3.39466C1.20002 3.22576 1.23106 3.05946 1.29137 2.90341C1.35167 2.74736 1.44007 2.60557 1.5515 2.48614C1.66293 2.36671 1.79522 2.27197 1.94081 2.20733C2.08641 2.14269 2.24245 2.10943 2.40004 2.10943C2.55763 2.10943 2.71368 2.14269 2.85927 2.20733C3.00486 2.27197 3.13715 2.36671 3.24858 2.48614C3.36002 2.60557 3.44841 2.74736 3.50872 2.90341C3.56902 3.05946 3.60006 3.22576 3.60006 3.39466H4.80008Z"
      fill="#54A1FC"
    />
    <path
      d="M8.40164 7.17591C8.40164 6.8381 8.33957 6.50455 8.21895 6.19246C8.09834 5.88036 7.92155 5.59679 7.69869 5.35792C7.47582 5.11905 7.21125 4.92958 6.92006 4.8003C6.62887 4.67103 6.31678 4.60449 6.0016 4.60449C5.68643 4.60449 5.37433 4.67103 5.08315 4.8003C4.79196 4.92958 4.52738 5.11906 4.30452 5.35792C4.08165 5.59679 3.90487 5.88036 3.78425 6.19246C3.66364 6.50455 3.60156 6.8381 3.60156 7.17591L4.80158 7.17591C4.80158 7.00701 4.83262 6.84071 4.89293 6.68466C4.95324 6.52861 5.04163 6.38682 5.15306 6.26739C5.26449 6.14796 5.39678 6.05322 5.54238 5.98858C5.68797 5.92394 5.84401 5.89068 6.0016 5.89068C6.15919 5.89068 6.31524 5.92394 6.46083 5.98858C6.60642 6.05322 6.73871 6.14796 6.85015 6.26739C6.96158 6.38682 7.04997 6.52861 7.11028 6.68466C7.17058 6.84071 7.20162 7.00701 7.20162 7.17591L8.40164 7.17591Z"
      fill="#54A1FC"
    />
    <path
      d="M11.9993 3.39466C11.9993 3.05685 11.9372 2.7233 11.8166 2.41121C11.696 2.09911 11.5192 1.81554 11.2963 1.57667C11.0735 1.33781 10.8089 1.14833 10.5177 1.01905C10.2265 0.889778 9.91444 0.823242 9.59926 0.823242C9.28408 0.823242 8.97199 0.889779 8.6808 1.01905C8.38962 1.14833 8.12504 1.33781 7.90217 1.57667C7.67931 1.81554 7.50252 2.09911 7.38191 2.41121C7.2613 2.7233 7.19922 3.05685 7.19922 3.39466L8.39924 3.39466C8.39924 3.22576 8.43028 3.05946 8.49059 2.90341C8.55089 2.74736 8.63928 2.60557 8.75072 2.48614C8.86215 2.36671 8.99444 2.27197 9.14003 2.20733C9.28562 2.14269 9.44167 2.10943 9.59926 2.10943C9.75685 2.10943 9.91289 2.14269 10.0585 2.20733C10.2041 2.27197 10.3364 2.36671 10.4478 2.48614C10.5592 2.60557 10.6476 2.74736 10.7079 2.90341C10.7682 3.05946 10.7993 3.22576 10.7993 3.39466H11.9993Z"
      fill="#54A1FC"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const NoSmile = memo(ForwardRef);
export default NoSmile;
