import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" ref={ref} {...props}>
    <path className="path1" fill="#fff" d="m3.867 7.531-2.534 7.133 7.134-2.526" />
    <path
      className="path2"
      fill="#fff"
      d="M7.333 8.664c1.287 1.287 1.887 2.78 1.334 3.334-.554.553-2.047-.047-3.334-1.334C4.047 9.378 3.447 7.884 4 7.331c.553-.553 2.047.047 3.333 1.333Z"
    />
    <path
      className="path3"
      stroke="#7B61FF"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m3.867 7.531-2.534 7.133 7.134-2.526M7.333 8.664c1.287 1.287 1.887 2.78 1.334 3.334-.554.553-2.047-.047-3.334-1.334C4.047 9.378 3.447 7.884 4 7.331c.553-.553 2.047.047 3.333 1.333ZM1.56 2.128h.006M13.56 5.46h.006m-4.673-4H8.9m4.66 12h.006m-.006-12-1.494.5a1.934 1.934 0 0 0-1.306 2.08.974.974 0 0 1-.967 1.087H9.54c-.574 0-1.067.4-1.174.96l-.14.706m5.334 2-.547-.22a.978.978 0 0 0-1.32.74.967.967 0 0 1-.953.814h-.514m-4-8.667.22.547a.978.978 0 0 1-.74 1.32.962.962 0 0 0-.813.953v.513"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconParty = memo(ForwardRef);
export default IconParty;
