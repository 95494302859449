import { DateTime } from 'luxon';
import { NudosStaticAlertInComponent } from '../../../../../../components/DesignSystem';
import { NudosLink } from '../../../../../../components/NudosComponents';
import { IdateRange, IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import { additionalDaysForUnassignmentCollectionDate } from '../../../../../../utils/productDefinitions';
import { isValidLink } from '../../../../../../utils/formValidations';
import { useTranslation } from 'react-i18next';
import './LogisticServiceTrackingSection.scss';

const LogisticServiceTrackingSection = ({
  logisticServiceData,
  isLoading = false,
  haveSomeDeliveredData
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  isLoading?: boolean;
  haveSomeDeliveredData?: boolean;
}) => {
  const { t } = useTranslation();
  const {
    status,
    trackingLink,
    chosenCollectionDate,
    chosenDeliveryDate,
    estimatedDeliveryTime,
    trackingCode,
    estimatedDates,
    deliveryDate,
    collection,
    delivery
  } = logisticServiceData || {};
  const { collect: collectConfirmedDates, delivery: deliveryConfirmedDates } = estimatedDates || {};

  const noDataText = 'Sin datos';
  const translationKey = 'nodi:logisticServices:logisticServiceTrackingSection:';
  const showInitialLogisticsData = ['por confirmar', 'confirmado', 'en proceso'].includes(status || '');
  const serviceIsCanceled = status === 'cancelado';
  const serviceIsDelivered = status === 'entregado';
  const serviceDestinationIsWarehouse = delivery?.place === 'nudos';
  const collectionTimeZone = collection?.country?.timeZone || undefined;
  const deliveryTimeZone = delivery?.country?.timeZone || undefined;
  const validTrackingLink = isValidLink(trackingLink || '');
  const showOfficeAndOtherLocationsTimeAlert =
    ['office', 'other'].includes(collection?.place || '') &&
    ['office', 'other', 'user'].includes(delivery?.place || '');

  const defaultTimeAlertText = t(`${translationKey}defaultTimeAlertText`);
  const dataCollectionTitle = t(`${translationKey}dataCollectionTitle`);
  const dataDeliveryTitle = t(`${translationKey}dataDeliveryTitle`);
  const trackingLinkText = t(`${translationKey}trackingLinkText`);
  const trackingCodeText = t(`${translationKey}trackingCodeText`);
  const assignmentSummaryTimeAlertText = t(`${translationKey}assignmentSummaryTimeAlertText`);
  const businessDays = t(`recurrentWords:businessDays`);

  const officeAndOtherLocationsText = (
    <div className="assignmentSummaryTimeAlert">
      <div>{defaultTimeAlertText}</div>
      <div>{assignmentSummaryTimeAlertText}</div>
    </div>
  );

  const appropiateAlertText = showOfficeAndOtherLocationsTimeAlert ? officeAndOtherLocationsText : defaultTimeAlertText;

  const estimatedDeliveryTimeText =
    estimatedDeliveryTime && estimatedDeliveryTime?.since
      ? `${estimatedDeliveryTime?.since}${
          estimatedDeliveryTime?.until ? ` - ${estimatedDeliveryTime?.until}` : ''
        } ${businessDays}`
      : noDataText;

  const estimatedUpperLimitForChosenCollectionDate = chosenCollectionDate
    ? DateTime.fromISO(chosenCollectionDate).plus({ days: additionalDaysForUnassignmentCollectionDate }).toISODate()
    : undefined;

  const estimatedUpperLimitForChosenDeliveryDate = chosenDeliveryDate
    ? DateTime.fromISO(chosenDeliveryDate).plus({ days: additionalDaysForUnassignmentCollectionDate }).toISODate()
    : undefined;

  const serviceHasConfirmedDeliveryDates =
    deliveryConfirmedDates && !!Object.values(deliveryConfirmedDates || {}).filter(val => !!val).length;

  const serviceHasConfirmedCollectionDates =
    collectConfirmedDates && !!Object.values(collectConfirmedDates || {}).filter(val => !!val).length;

  const getDateText = (dateRange?: IdateRange, timeZone?: number) => {
    const sinceDate = dateRange?.since ? formatShortDate(dateRange.since, timeZone)?.onlyDate : undefined;
    const untilDate = dateRange?.until ? formatShortDate(dateRange.until, timeZone)?.onlyDate : undefined;
    if (sinceDate && untilDate) return `${sinceDate || ''} - ${untilDate || ''}`;
    if (untilDate) return `${untilDate || ''}`;
    if (sinceDate) return `${sinceDate || ''}`;
    return noDataText;
  };

  return (
    <>
      {(isLoading || !logisticServiceData) && <LogisticServiceTrackingSectionSkeletonLoader />}
      {!isLoading && logisticServiceData && (
        <div className="logisticServiceTrackingSection">
          {showInitialLogisticsData && (
            <>
              <section className="initialEstimatedDatesSection">
                {chosenCollectionDate && (
                  <div className="dataField">
                    <h3 className="dataTitle">{dataCollectionTitle}</h3>
                    <div className="dataContent">
                      {getDateText(
                        { since: chosenCollectionDate, until: estimatedUpperLimitForChosenCollectionDate } || undefined,
                        collectionTimeZone
                      )}
                    </div>
                  </div>
                )}
                {chosenDeliveryDate && (
                  <div className="dataField">
                    <h3 className="dataTitle">{dataDeliveryTitle}</h3>
                    <div className="dataContent">
                      {getDateText(
                        { since: chosenDeliveryDate, until: estimatedUpperLimitForChosenDeliveryDate } || undefined,
                        deliveryTimeZone
                      )}
                    </div>
                  </div>
                )}
                {!chosenDeliveryDate && (
                  <div className="dataField">
                    <h3 className="dataTitle">{dataDeliveryTitle}</h3>
                    <div className="dataContent">{estimatedDeliveryTimeText}</div>
                  </div>
                )}
              </section>
              <NudosStaticAlertInComponent
                type="time"
                alertOptions={{
                  title: appropiateAlertText
                }}
              />
            </>
          )}
          {!showInitialLogisticsData && !serviceIsCanceled && haveSomeDeliveredData && (
            <>
              <section className="definitiveDataSection">
                {serviceHasConfirmedCollectionDates && (
                  <div className="dataField">
                    <h3 className="dataTitle">{dataCollectionTitle}</h3>
                    <div className="dataContent">{getDateText(collectConfirmedDates, collectionTimeZone)}</div>
                  </div>
                )}
                {!serviceIsDelivered && serviceHasConfirmedDeliveryDates && (
                  <div className="dataField">
                    <h3 className="dataTitle">{dataDeliveryTitle}</h3>
                    <div className="dataContent">{getDateText(deliveryConfirmedDates, deliveryTimeZone)}</div>
                  </div>
                )}
                {!serviceIsDelivered && (!serviceHasConfirmedDeliveryDates || serviceDestinationIsWarehouse) && (
                  <div className="dataField">
                    <h3 className="dataTitle">{dataDeliveryTitle}</h3>
                    <div className="dataContent">{estimatedDeliveryTimeText}</div>
                  </div>
                )}
                {serviceIsDelivered && (
                  <div className="dataField">
                    <h3 className="dataTitle">{dataDeliveryTitle}</h3>
                    <div className="dataContent">{getDateText({ since: deliveryDate })}</div>
                  </div>
                )}
                <div className="dataField">
                  <h3 className="dataTitle">{trackingLinkText}</h3>
                  <div className="dataContent">
                    {validTrackingLink && (
                      <NudosLink
                        linkUrl={trackingLink || undefined}
                        maxWidth="160px"
                        inactiveLinkText={trackingLink || noDataText}
                      />
                    )}
                    {!validTrackingLink && trackingLink}
                  </div>
                </div>
                <div className="dataField">
                  <h3 className="dataTitle">{trackingCodeText}</h3>
                  <div className="dataContent">{trackingCode || noDataText}</div>
                </div>
              </section>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default LogisticServiceTrackingSection;

const LogisticServiceTrackingSectionSkeletonLoader = () => {
  return (
    <div className="logisticServiceTrackingSectionSkeletonLoader">
      <div className="initialEstimatedDatesSection">
        <div className="dataField">
          <div className="dataTitle animationLoader" />
          <div className="dataContent animationLoader" />
        </div>
        <div className="dataField">
          <div className="dataTitle animationLoader" />
          <div className="dataContent animationLoader" />
        </div>
      </div>
      <div className="trackingDataSection">
        <div className="dataField">
          <div className="dataTitle animationLoader" />
          <div className="dataContent animationLoader" />
        </div>
        <div className="dataField">
          <div className="dataTitle animationLoader" />
          <div className="dataContent animationLoader" />
        </div>
      </div>
    </div>
  );
};
