import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateProofOfPayment } from '../../../../../services/orders';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import validateFile, {
  formatErrorTextForImageDocuments,
  validFormatsForDocumentsIncludingImages
} from '../../../../../utils/validateFile';
import { DetailsPaymentModal } from '../DetailsPaymentModal';
import { IorderDetails } from '../../../../../types/orders';
import OrderPaymentDocuments from './OrderPaymentDocuments/OrderPaymentDocuments';
import PaymentMethodAndLink from './PaymentMethodAndLink/PaymentMethodAndLink';
import './OrderPaymentDetails.scss';

/**
 * @property {() => void} updateOrderDataFunction - The function callback to execute for reloading the OrderData after uploading the proof of payment document
 * @property {string} paymentMethod - The order's payment method
 * @property {string} orderInvoice - The url to download the invoice for the orders
 * @property {boolean} loading - A boolean indicating if the component is loading and therfore must show its skeleton loader
 * @property {setLoading={React.Dispatch<React.SetStateAction<boolean>>}} setLoading - The react updater for the loading state
 */
const OrderPaymentDetails = ({
  updateOrderDataFunction,
  loading,
  setLoading,
  orderData,
  getOrderDatawithoutLoader
}: {
  updateOrderDataFunction: () => void;
  loading?: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  orderData?: IorderDetails;
  getOrderDatawithoutLoader?: () => void;
}) => {
  const { t } = useTranslation();
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const { orderId } = orderData || {};
  const i18nLanguageKey = 'nodi:orderDetails:orderPaymentDetails:';

  const openPaymentInfoModal = () => setShowInfoModal(true);

  const uploadProofOfPayment = async (file?: File) => {
    if (!orderId || !file) return;
    const isFileValid = validateFile(
      file,
      validFormatsForDocumentsIncludingImages,
      10,
      formatErrorTextForImageDocuments,
      'El documento no debe superar los 10MB'
    );
    if (!isFileValid) return;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      await updateProofOfPayment(orderId, formData);
      updateOrderDataFunction && updateOrderDataFunction();
      displaySuccessNotification({
        customJSXMessage: (
          <div>
            <div>El comprobante se cargo correctamente, Esperamos acreditar</div>
            <div>tu pago lo antes posible.</div>
          </div>
        )
      });
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const closeModalInfo = () => {
    setShowInfoModal(false);
  };

  return (
    <>
      {showInfoModal && orderData && orderData?.orderId && (
        <DetailsPaymentModal
          orderData={orderData}
          closeModal={closeModalInfo}
          paymentMethod={orderData?.paymentMethod || ''}
        />
      )}
      {loading && <OrderPaymentDetailsSkeletonLoader />}
      {!loading && (
        <div className="orderPaymentDetails">
          <h2 className="paymentDetailsTitle">{t(`${i18nLanguageKey}paymentDetailsTitle`)}</h2>
          <div className="paymentInformation">
            <PaymentMethodAndLink
              orderData={orderData}
              uploadProofOfPaymentCallback={uploadProofOfPayment}
              openPaymentInfoModal={openPaymentInfoModal}
            />
            <OrderPaymentDocuments orderData={orderData} getOrderDatawithoutLoader={getOrderDatawithoutLoader} />
          </div>
        </div>
      )}
    </>
  );
};

const OrderPaymentDetailsSkeletonLoader = () => {
  return (
    <div className="orderPaymentDetailsSkeletonLoader">
      <div className="paymentDetailsTitle animationLoader" />
      <div className="paymentInformation animationLoader" />
    </div>
  );
};

export default OrderPaymentDetails;
