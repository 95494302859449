import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M3.63259 5.68902L7.29934 2.12874C7.47612 1.95709 7.7629 1.95709 7.93968 2.12874L8.36739 2.54405C8.54398 2.71553 8.54417 2.99326 8.36814 3.1651L5.4621 5.99991L8.36796 8.8349C8.54417 9.00674 8.54379 9.28447 8.3672 9.45595L7.93949 9.87126C7.76271 10.0429 7.47593 10.0429 7.29915 9.87126L3.63259 6.3108C3.4558 6.13914 3.4558 5.86068 3.63259 5.68902Z"
      fill={props?.fill || '#54A1FC'}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const BackArrowIcon = memo(ForwardRef);
export default BackArrowIcon;
