import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M4.80008 3.39466C4.80008 3.05685 4.738 2.7233 4.61739 2.41121C4.49678 2.09911 4.31999 1.81554 4.09713 1.57667C3.87426 1.33781 3.60968 1.14833 3.3185 1.01905C3.02731 0.889778 2.71522 0.823242 2.40004 0.823242C2.08486 0.823242 1.77277 0.889779 1.48158 1.01905C1.1904 1.14833 0.92582 1.33781 0.702956 1.57667C0.480091 1.81554 0.303305 2.09911 0.182692 2.41121C0.0620788 2.7233 -2.75537e-08 3.05685 0 3.39466L1.20002 3.39466C1.20002 3.22576 1.23106 3.05946 1.29137 2.90341C1.35167 2.74736 1.44007 2.60557 1.5515 2.48614C1.66293 2.36671 1.79522 2.27197 1.94081 2.20733C2.08641 2.14269 2.24245 2.10943 2.40004 2.10943C2.55763 2.10943 2.71368 2.14269 2.85927 2.20733C3.00486 2.27197 3.13715 2.36671 3.24858 2.48614C3.36002 2.60557 3.44841 2.74736 3.50872 2.90341C3.56902 3.05946 3.60006 3.22576 3.60006 3.39466H4.80008Z"
      fill="#54A1FC"
    />
    <path
      d="M3.60226 4.60436C3.60226 4.94217 3.66434 5.27572 3.78495 5.58781C3.90557 5.89991 4.08235 6.18348 4.30522 6.42235C4.52808 6.66122 4.79266 6.8507 5.08385 6.97997C5.37503 7.10924 5.68713 7.17578 6.0023 7.17578C6.31748 7.17578 6.62957 7.10924 6.92076 6.97997C7.21195 6.8507 7.47652 6.66122 7.69939 6.42235C7.92225 6.18349 8.09904 5.89991 8.21965 5.58782C8.34027 5.27572 8.40234 4.94217 8.40234 4.60436L7.20232 4.60436C7.20232 4.77327 7.17128 4.93957 7.11098 5.09561C7.05067 5.25166 6.96228 5.39345 6.85085 5.51288C6.73941 5.63232 6.60712 5.72705 6.46153 5.79169C6.31594 5.85633 6.15989 5.8896 6.0023 5.8896C5.84471 5.8896 5.68867 5.85633 5.54308 5.79169C5.39748 5.72705 5.26519 5.63232 5.15376 5.51288C5.04233 5.39345 4.95394 5.25166 4.89363 5.09561C4.83332 4.93957 4.80228 4.77327 4.80228 4.60436L3.60226 4.60436Z"
      fill="#54A1FC"
    />
    <path
      d="M11.9993 3.39466C11.9993 3.05685 11.9372 2.7233 11.8166 2.41121C11.696 2.09911 11.5192 1.81554 11.2963 1.57667C11.0735 1.33781 10.8089 1.14833 10.5177 1.01905C10.2265 0.889778 9.91444 0.823242 9.59926 0.823242C9.28408 0.823242 8.97199 0.889779 8.6808 1.01905C8.38962 1.14833 8.12504 1.33781 7.90217 1.57667C7.67931 1.81554 7.50252 2.09911 7.38191 2.41121C7.2613 2.7233 7.19922 3.05685 7.19922 3.39466L8.39924 3.39466C8.39924 3.22576 8.43028 3.05946 8.49059 2.90341C8.55089 2.74736 8.63928 2.60557 8.75072 2.48614C8.86215 2.36671 8.99444 2.27197 9.14003 2.20733C9.28562 2.14269 9.44167 2.10943 9.59926 2.10943C9.75685 2.10943 9.91289 2.14269 10.0585 2.20733C10.2041 2.27197 10.3364 2.36671 10.4478 2.48614C10.5592 2.60557 10.6476 2.74736 10.7079 2.90341C10.7682 3.05946 10.7993 3.22576 10.7993 3.39466H11.9993Z"
      fill="#54A1FC"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const BlueSmile = memo(ForwardRef);
export default BlueSmile;
