import { FC } from 'react';
import { NudosButton, NudosGenericModal, NudosTextArea } from '../../../../../../components/NudosComponents';
import './CancelLogisticServiceModal.scss';
import { MenuFaqsIcon } from '../../../../../../assets/images/components';
import XSign from '../../../../../../assets/images/components/NudosComponents/XSign';
import { segmentTrackEvent } from '../../../../../../utils/segment';

interface ICancelLogisticServiceModal {
  defaultValue?: string;
  updateValue?: (value: string) => void;
  continueCancellation?: () => void;
  skipCancellation?: () => void;
  loading?: boolean;
  total?: number;
}

const CancelLogisticServiceModal: FC<ICancelLogisticServiceModal> = ({
  defaultValue,
  updateValue,
  continueCancellation,
  skipCancellation,
  loading,
  total
}: ICancelLogisticServiceModal) => {
  return (
    <NudosGenericModal
      modalContent={
        <div id="cancelLogisticServiceModal">
          <div className="boxCancelLogisticServiceModal">
            <div
              className="floatCloseX"
              onClick={() => {
                if (skipCancellation && !loading) {
                  skipCancellation();
                  segmentTrackEvent({
                    nodiLogisticServicesRejectQuoteModalCloseClick: {
                      Comment: !!defaultValue,
                      LogisticServiceTotalUsd: total || 0
                    }
                  });
                }
              }}
            >
              <XSign fill="#B3B3B3" />
            </div>
            <div className="iconCancelModalLine">
              <div className="iconContainer">
                <div className="colorIconCancel">
                  <MenuFaqsIcon stroke="#54A1FC" className="scaleQuestionIcon" />
                </div>
              </div>
            </div>
            <div className="titleCancelModalLine">
              <div className="orangeCancelModalText">Ayúdanos a mejorar</div>
            </div>
            <div className="subtitleCancelModalLine">
              Tu servicio logístico fue rechazado. Sin embargo, nos encantaría
              <br /> conocer los motivos para poder ofrecerte un mejor servicio.
            </div>
            <div className="textAreaCancelModalLine">
              <div className="containerTextArea">
                <NudosTextArea
                  label="Comentarios"
                  noResize
                  textHeight={64}
                  customFontSize={10}
                  xPadding="16"
                  yPadding="14"
                  currentValue={defaultValue || ''}
                  maxLength={1000}
                  updateCurrentValueCallback={e => {
                    if (updateValue) {
                      updateValue(e);
                    }
                  }}
                  placeholder="Cuéntanos las razones por las cuales rechazaste tu servicio"
                />
              </div>
            </div>
            <div className="firstButtonCancelModalLine">
              <NudosButton
                isButtonLoading={loading}
                customHeight="38px"
                customWidth="280px"
                buttonText="Continuar"
                isButtonDisabled={!defaultValue}
                handleClick={() => {
                  if (continueCancellation) {
                    segmentTrackEvent({
                      nodiLogisticServicesRejectQuoteModalContinueClick: {
                        Comment: !!defaultValue,
                        LogisticServiceTotalUsd: total || 0
                      }
                    });
                    continueCancellation();
                  }
                }}
              />
            </div>
            <div className="secondButtonCancelModalLine">
              <button
                disabled={loading}
                className="whiteButton"
                onClick={() => {
                  if (skipCancellation) {
                    segmentTrackEvent({
                      nodiLogisticServicesRejectQuoteModalContinueSkipClick: {
                        Comment: !!defaultValue,
                        LogisticServiceTotalUsd: total || 0
                      }
                    });
                    skipCancellation();
                  }
                }}
              >
                Omitir
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default CancelLogisticServiceModal;
