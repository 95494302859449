import { ICountryDetail } from '../../../../../types/countries';
import { IassigneeFormInputs, IassigneeInfo, TfunctionOneToVoid } from '../../../../../types/global';

const useNewEmployeeFormController = () => {
  const formOptions = (onChangeSelectedOption: TfunctionOneToVoid, optionList: string[], inputClassName: string) => {
    return optionList.map((option, i) => {
      return (
        <div
          key={`${inputClassName}-option-${i}`}
          className={`optionContainer ${`${inputClassName}OptionContainer`}`}
          onClick={() => {
            onChangeSelectedOption(option);
          }}
        >
          <p>{option}</p>
        </div>
      );
    });
  };

  const countryFormOptions = (
    onChangeSelectedOption: TfunctionOneToVoid,
    inputClassName: string,
    optionList?: ICountryDetail[]
  ) => {
    if (optionList) {
      return optionList.map((option, i) => {
        return (
          <div
            key={`${inputClassName}-country-option-${i}`}
            className={`optionContainer ${`${inputClassName}OptionContainer`}`}
            onClick={() => {
              onChangeSelectedOption(option);
            }}
          >
            <p>{option.name}</p>
          </div>
        );
      });
    }
    return [];
  };
  const inactiveFieldsOnEditing = ['email', 'assignmentDate', 'assignmentDocumentUrl'];
  const modifyAssigneeFormInputs = (newAssigneeFormInputs: IassigneeFormInputs[], currentAssignee: IassigneeInfo) => {
    const modifiedAssigneeInfoFormInputs = [...newAssigneeFormInputs];
    modifiedAssigneeInfoFormInputs.forEach((formField: IassigneeFormInputs) => {
      if (formField.id === 'assignmentDate') {
        const predefinedDate = currentAssignee[formField.id as keyof typeof currentAssignee] as string;
        formField['predefinedValue'] = predefinedDate.split('T')[0];
      } else {
        formField['predefinedValue'] = currentAssignee[formField.id as keyof typeof currentAssignee];
      }
      if (inactiveFieldsOnEditing.includes(formField.id)) {
        formField['isReadOnly'] = true;
      }
    });
    return modifiedAssigneeInfoFormInputs;
  };
  return { formOptions, modifyAssigneeFormInputs, inactiveFieldsOnEditing, countryFormOptions };
};

export default useNewEmployeeFormController;
