import { DateRange } from 'react-date-range';
import { enUS, es } from 'date-fns/locale';
import { addDays, isWeekend } from 'date-fns';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tany } from '../../../types/global';
import useLanguagePlatformState from '../../../state/useLenguageState';
import {
  IconRangeDateArrow,
  IconRangeDateCalendar,
  IconRangeDateCalendarDisabled
} from '../../../assets/DesignSystem/SVGComponents';
import { GenericLoader } from '../../../views/Admin/dashboard/components';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { successPromise } from '../../../utils/simulationPromise';
import { additionalDaysForUnassignmentCollectionDate } from '../../../utils/productDefinitions';
import './NudosDateRange.scss';

interface IDateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface IOutRange {
  startDate: Date;
  endDate: Date;
}

interface INudosRange {
  sendValueOut?: (dataRange: IOutRange) => void;
  startDay: number;
  defaultDateRange?: IOutRange;
  validRange?: (status: boolean) => void;
}

const NudosDateRange: FC<INudosRange> = ({ sendValueOut, startDay, defaultDateRange, validRange }) => {
  const { t } = useTranslation();
  const { platformLanguage } = useLanguagePlatformState();
  const [openDateRange, setOpenDateRange] = useState<boolean>(false);
  const [stateDate, setStateDate] = useState<IDateRange[]>();
  const [defaultDate, setDefaultDate] = useState<boolean>(false);
  const [lodingChange, setLoadingChange] = useState<boolean>(false);
  const [isvalid, setIsvalid] = useState<boolean>(false);

  const refContainer = useRef<HTMLDivElement>(null);
  const onHandleClickAway = useCallback(
    async (e: Tany) => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        setOpenDateRange(false);
      }
    },
    [refContainer]
  );
  const i18nLanguageKey = 'designSystemComponents:nudosDateRange';

  const defaultDateValues = () => {
    const startDate = addDays(new Date(), startDay || 1);
    setStateDate([
      {
        startDate: startDate,
        endDate: startDate,
        key: 'selection'
      }
    ]);
  };

  const newDateValues = async (startDate: Date) => {
    setLoadingChange(true);
    try {
      await successPromise(200);
      if (startDate) {
        const objetDate = {
          startDate: startDate,
          endDate: addDays(startDate, additionalDaysForUnassignmentCollectionDate),
          key: 'selection'
        };
        setStateDate([objetDate]);
      }
    } catch (eror) {
      displayErrorNotification();
    } finally {
      setLoadingChange(false);
    }
  };

  const formatDateRange = () => {
    if (stateDate && stateDate.length > 0) {
      const startDate = stateDate[0].startDate;
      const formatStartDay = startDate.getDate();
      const startDay = formatStartDay <= 9 ? `0${formatStartDay}` : formatStartDay;
      const formatStartMonth = startDate.getMonth() + 1;
      const startMonth = formatStartMonth <= 9 ? `0${formatStartMonth}` : formatStartMonth;

      const startYear = startDate.getFullYear().toString();

      const endDate = stateDate[0].endDate;
      const formatEndDay = endDate.getDate();
      const endDay = formatEndDay <= 9 ? `0${formatEndDay}` : formatEndDay;
      const formatEndMonth = endDate.getMonth() + 1;
      const endMonth = formatEndMonth <= 9 ? `0${formatEndMonth}` : formatEndMonth;
      const endYear = endDate.getFullYear().toString();

      return `${startDay}-${startMonth}-${startYear.substring(2, 4)} al ${endDay}-${endMonth}-${endYear.substring(
        2,
        4
      )}`;
    }
  };

  const handleSendValueOut = () => {
    if (sendValueOut && stateDate && stateDate.length > 0) {
      sendValueOut({ startDate: stateDate[0]?.startDate, endDate: stateDate[0]?.endDate });
    }
  };

  const asssignDefaultRangeDate = () => {
    if (!defaultDate) {
      if (defaultDateRange && defaultDateRange?.endDate && defaultDateRange?.startDate) {
        const objetDate = {
          startDate: defaultDateRange?.startDate,
          endDate: defaultDateRange?.endDate,
          key: 'selection'
        };
        setStateDate([objetDate]);
      }
      setDefaultDate(true);
    }
  };
  const diabledDaysList = (e: Date) => {
    if (isWeekend(e)) {
      return true;
    }
    return false;
  };

  const compareRanges = () => {
    if (stateDate && stateDate.length > 0) {
      const firstDate = stateDate[0]?.startDate.getTime();
      const secondDate = stateDate[0]?.endDate.getTime();
      if (secondDate !== firstDate) {
        if (validRange) {
          setIsvalid(true);
          validRange(true);
        }
      } else {
        if (validRange) {
          setIsvalid(false);
          validRange(false);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  useEffect(() => {
    handleSendValueOut();
    compareRanges();
  }, [stateDate]);

  useEffect(() => {
    asssignDefaultRangeDate();
  }, [defaultDateRange]);

  return (
    <div id="nudosDateRange" ref={refContainer}>
      <div
        className={`containerRange ${openDateRange ? 'openContainer' : ''}`}
        onClick={() => {
          setOpenDateRange(!openDateRange);
          if (!stateDate) {
            defaultDateValues();
          }
        }}
      >
        {((!openDateRange && !stateDate) || (!openDateRange && stateDate && !isvalid)) && (
          <div className="closedRangeNoRange">
            <div className="calendarIcon">
              <IconRangeDateCalendarDisabled />
            </div>
            <div className="titleContainer">
              <div className="stylesTitle">{t(`${i18nLanguageKey}.chooseWeek`)}</div>
            </div>
            <div className="arrowIcon">
              <IconRangeDateArrow />
            </div>
          </div>
        )}
        {openDateRange && (
          <div className="openRangeNoRange">
            <div className="calendarIcon">
              <IconRangeDateCalendar />
            </div>
            <div className="titleContainer">
              <div className="stylesTitle">{t(`${i18nLanguageKey}.chooseWeek`)}</div>
            </div>
            <div className="arrowIcon">
              <IconRangeDateArrow className="rotateArrow" stroke="#383838" />
            </div>
          </div>
        )}
        {!openDateRange && stateDate && isvalid && (
          <div className="closedWhithRange">
            <div className="calendarIcon">
              <IconRangeDateCalendar />
            </div>
            <div className="titleContainer">
              <div className="stylesTitle">{formatDateRange()}</div>
            </div>
            <div className="arrowIcon">
              <IconRangeDateArrow stroke="#383838" />
            </div>
          </div>
        )}
      </div>
      {openDateRange && (
        <div className="containerDateRange">
          {lodingChange ? (
            <div className="rangeLoading">
              <GenericLoader sizeLoader={45} />
            </div>
          ) : (
            <DateRange
              className="customDateRange"
              onChange={(e: Tany) => {
                newDateValues(e.selection.startDate);
              }}
              showMonthAndYearPickers={false}
              showMonthArrow={true}
              minDate={addDays(new Date(), startDay || 1)}
              locale={platformLanguage === 'esMX' ? es : enUS}
              ranges={stateDate}
              showDateDisplay={false}
              showPreview={false}
              rangeColors={['#D6E9FF']}
              disabledDay={diabledDaysList}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default NudosDateRange;
