import { IshipmentDetails, IshipmentProductInfo } from '../../../../../types/orders';
import ShipmentProductCard from '../ShipmentProductCard/ShipmentProductCard';
import './OrderShipmentCardExpandedSection.scss';

const OrderShipmentCardExpandedSection = ({
  shipmentData,
  showExpandedSection,
  thisShipmentProducts
}: {
  shipmentData: IshipmentDetails;
  showExpandedSection: boolean;
  thisShipmentProducts: IshipmentProductInfo[];
}) => {
  const componentHeight = thisShipmentProducts.length * 80;
  const expandedStyles = showExpandedSection ? { height: `${componentHeight}px` } : undefined;
  return (
    <div className={`orderShipmentCardExpandedSection`} style={expandedStyles}>
      {thisShipmentProducts?.map((product, i) => {
        return (
          <ShipmentProductCard
            key={`product${product?.serial || ''}-${i}`}
            productData={product}
            shipmentData={shipmentData}
          />
        );
      })}
    </div>
  );
};

export default OrderShipmentCardExpandedSection;
