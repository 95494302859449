import { useEffect, useState } from 'react';
import {
  NudosButton,
  NudosCountrySelect,
  NudosDropdownWithSearchbar,
  NudosGenericModal,
  NudosModalContent,
  NudosPhoneInput,
  NudosTextInput,
  NudosUploadDownloadButton
} from '../../../../../components/NudosComponents';
import { IorderDetailsBillingData } from '../../../../../types/orders';
import { getOrgData } from '../../../../../utils/getLocalStorageData';
import { isTaxDocumentByCountryValid } from '../../../../../utils/formValidations';
import { ICountryDetail } from '../../../../../types/countries';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import { updateOrderBillingData } from '../../../../../services/orders';
import { getListOfCountriesByOfferedService } from '../../../../../utils/getCountries';
import {
  countriesRequiringTaxSituationDocument,
  formatZipCode,
  getIsInvalidBusinessName,
  getIsInvalidZipCode,
  invalidBusinessName,
  invalidZipCodeText
} from '../../../../../utils/productDefinitions';
import './UpdateOrderBillingDataFlux.scss';
import {
  DTOgetTaxationRelatedDefinitionsOptions,
  ItaxationRelatedDataRequiredForBilling
} from '../../../../../types/checkout';
import { getTaxationRelatedDefinitionsOptions } from '../../../../../services/checkout';
import { useTranslation } from 'react-i18next';

const UpdateOrderBillingDataFlux = ({
  initialData,
  billingCountryData,
  setShowUpdateOrderBillingDataModal,
  updateOrderData,
  orderId
}: {
  initialData: IorderDetailsBillingData;
  billingCountryData?: ICountryDetail;
  setShowUpdateOrderBillingDataModal: React.Dispatch<React.SetStateAction<boolean>>;
  updateOrderData: () => void;
  orderId: number | string;
}) => {
  const { t } = useTranslation();
  const isAMexicanOrganization = !!initialData?.documentCountry?.code && initialData?.documentCountry?.code === 'mx';
  const requiresTaxDocument = countriesRequiringTaxSituationDocument.includes(initialData?.documentCountry?.code || '');
  const i18nLanguageKey = 'nodi:orderDetails:updateOrderBillingDataFlux:';
  const organizationIsUruguay = !!initialData?.documentCountry?.code && initialData?.documentCountry?.code === 'uy';

  const getHeight = () => {
    if (organizationIsUruguay) return '490px';
    if (isAMexicanOrganization) return '556px';
    if (requiresTaxDocument) return '480px';
    if (!initialData?.documentCountry?.code) return '556px';
    return '418px';
  };

  return (
    <div className="updateOrderBillingDataFlux">
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            CustomModalComponent={
              <UpdateOrderBillingDataModal
                initialData={initialData}
                setShowUpdateOrderBillingDataModal={setShowUpdateOrderBillingDataModal}
                billingCountryData={billingCountryData}
                updateOrderData={updateOrderData}
                orderId={orderId}
              />
            }
            toCloseModal={setShowUpdateOrderBillingDataModal}
            customHeight={getHeight()}
            customWidth="780px"
            modalContentTitle={t(`${i18nLanguageKey}modalContentTitle`)}
          />
        }
      />
    </div>
  );
};

export default UpdateOrderBillingDataFlux;

const UpdateOrderBillingDataModal = ({
  initialData,
  billingCountryData,
  setShowUpdateOrderBillingDataModal,
  updateOrderData,
  orderId
}: {
  initialData: IorderDetailsBillingData;
  billingCountryData?: ICountryDetail;
  setShowUpdateOrderBillingDataModal: React.Dispatch<React.SetStateAction<boolean>>;
  updateOrderData: () => void;
  orderId: number | string;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:orderDetails:updateOrderBillingDataFlux:';
  const [loading, setLoading] = useState(false);
  const [businessName, setBusinessName] = useState<string>();
  const [taxNumber, setTaxNumber] = useState<string>();
  const [city, setCity] = useState<string>();
  const [addressAditionalReferences, setAddressAditionalReferences] = useState<string>();
  const [zipCode, setZipCode] = useState<string>();
  const [billingAddress, setBillingAddress] = useState<string>();
  const [countryRegionOrState, setCountryRegionOrState] = useState<string>();
  const [billingOrganizationCountry, setBillingOrganizationCountry] = useState<ICountryDetail>();
  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();
  const [billingEmail, setBillingEmail] = useState<string>();
  const [taxationRelatedDefinitionsOptions, setTaxationRelatedDefinitionsOptions] =
    useState<DTOgetTaxationRelatedDefinitionsOptions>();
  const [newTaxSituation, setTaxSituation] = useState<ItaxationRelatedDataRequiredForBilling>();
  const [newTaxUse, setTaxUse] = useState<ItaxationRelatedDataRequiredForBilling>();
  const [taxSituationDocument, setTaxSituationDocument] = useState<File>();
  const [fantasyName, setFantasyName] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneCountry, setPhoneCountry] = useState<ICountryDetail>();

  const orgData = getOrgData();
  const organizationId = orgData?.organizationId;
  const isAMexicanOrganization = !!billingOrganizationCountry?.code && billingOrganizationCountry?.code === 'mx';
  const organizationIsUruguay = !!billingOrganizationCountry?.code && billingOrganizationCountry?.code === 'uy';
  const requiresTaxDocument = countriesRequiringTaxSituationDocument.includes(billingOrganizationCountry?.code || '');
  const taxNumberErrorText =
    taxNumber && !isTaxDocumentByCountryValid(billingCountryData, taxNumber) ? 'Documento inválido' : '';

  const getBillingTaxDefinitionsOptions = async () => {
    if (!isAMexicanOrganization) return;
    try {
      const newBillingTaxDefinitionsOptions = await getTaxationRelatedDefinitionsOptions();
      newBillingTaxDefinitionsOptions && setTaxationRelatedDefinitionsOptions(newBillingTaxDefinitionsOptions);
    } catch (err) {
      displayErrorNotification();
    }
  };

  const formTaxDataDropdownOptionsFn = (
    setCurrentOption: React.Dispatch<React.SetStateAction<ItaxationRelatedDataRequiredForBilling | undefined>>
  ) => {
    return (
      rawOptions: ItaxationRelatedDataRequiredForBilling[],
      clickOptionCallback: (option: ItaxationRelatedDataRequiredForBilling) => void
    ) => {
      {
        return rawOptions.map((option, i) => {
          const handleSelectThisOption = () => {
            setCurrentOption(option);
            clickOptionCallback && clickOptionCallback(option);
          };
          return (
            <div className="optionContainer" key={`option-${option.code}-${i}`} onClick={handleSelectThisOption}>
              {option.name}
            </div>
          );
        });
      }
    };
  };

  const handleSelectTaxStatusDocumentToUpload = (file?: File) => setTaxSituationDocument(file);

  const filterBySearchStringCallback = (search: string, rawOptions: ItaxationRelatedDataRequiredForBilling[]) => {
    return rawOptions.filter(taxData => taxData?.name?.toLowerCase().trim().includes(search.toLowerCase().trim()));
  };

  const getTaxDocumentButtonText = () => {
    if (isAMexicanOrganization) return 'Constancia de situación fiscal';
    if (initialData?.documentCountry?.code === 'co') return 'RUT';
    return 'Documento Fiscal de la empresa';
  };

  const getBusinessNameError = () => {
    if (!businessName?.trim()) return ' ';
    if (getIsInvalidBusinessName(businessName)) return invalidBusinessName;
    return undefined;
  };

  const isButtonDisable = () => {
    if (!organizationId) return true;
    if (!businessName || !taxNumber || !billingAddress || !countryRegionOrState || !city) return true;
    if (getIsInvalidBusinessName(businessName)) return true;
    if (!addressAditionalReferences || !zipCode || !isTaxDocumentByCountryValid(billingCountryData, taxNumber))
      return true;
    if (!billingOrganizationCountry) return true;
    if (!billingEmail) return true;
    if (getIsInvalidZipCode(zipCode)) return true;
    if (isAMexicanOrganization && (!newTaxSituation || !newTaxUse)) return true;
    if (organizationIsUruguay && (!fantasyName || !phoneNumber || !phoneCountry)) return true;
    return false;
  };

  const handleSaveBillingData = async () => {
    if (!organizationId || !orderId) return;
    if (!businessName || !taxNumber || !billingAddress || !countryRegionOrState || !city) return;
    if (!addressAditionalReferences || !zipCode || !isTaxDocumentByCountryValid(billingCountryData, taxNumber)) return;
    const body: IorderDetailsBillingData = {
      id: initialData?.id,
      organizationId: initialData?.organizationId,
      name: initialData?.name,
      businessName,
      document: taxNumber,
      documentCountryCode: billingOrganizationCountry?.code || '',
      documentCountry: billingOrganizationCountry,
      address: billingAddress,
      additionalReferences: addressAditionalReferences,
      zipCode,
      city,
      fantasyName,
      phone: phoneNumber,
      phoneCountry,
      state: countryRegionOrState,
      email: billingEmail,
      taxSituationDocUrl: initialData?.taxSituationDocUrl || '',
      taxSituation: newTaxSituation,
      taxUse: newTaxUse
    };
    setLoading(true);
    const formData = new FormData();
    formData.append('billingData', JSON.stringify(body));
    taxSituationDocument && formData.append('file', taxSituationDocument);
    try {
      await updateOrderBillingData(orderId, formData);
      updateOrderData();
      displaySuccessNotification({
        customJSXMessage: <>{t(`${i18nLanguageKey}displaySuccessNotification`)}</>
      });
      setLoading(false);
      setShowUpdateOrderBillingDataModal(false);
    } catch (error) {
      displayErrorNotification();
      setLoading(false);
    }
  };

  const setFormFieldsInitialValues = (initialData?: IorderDetailsBillingData) => {
    if (!initialData) return;
    if (!businessName && initialData?.businessName) setBusinessName(initialData?.businessName);
    if (!taxNumber && initialData?.document) setTaxNumber(initialData?.document);
    if (!city && initialData?.city) setCity(initialData?.city);
    if (!addressAditionalReferences && initialData?.additionalReferences)
      setAddressAditionalReferences(initialData?.additionalReferences);
    if (!zipCode && initialData?.zipCode) setZipCode(formatZipCode(initialData?.zipCode));
    if (!billingAddress && initialData?.address) setBillingAddress(initialData?.address);
    if (!countryRegionOrState && initialData?.state) setCountryRegionOrState(initialData?.state);
    if (!newTaxSituation && initialData?.taxSituation) setTaxSituation(initialData?.taxSituation);
    if (!newTaxUse && initialData?.taxUse) setTaxUse(initialData?.taxUse);
    if (initialData?.documentCountry) {
      setBillingOrganizationCountry(initialData?.documentCountry);
    }
    if (initialData?.email) {
      setBillingEmail(initialData?.email);
    }
    if (!phoneCountry && initialData?.phoneCountry) {
      setPhoneCountry(initialData?.phoneCountry);
    }
    if (!phoneNumber && !!initialData?.phone) {
      setPhoneNumber(initialData?.phone);
    }
    if (!fantasyName && initialData?.fantasyName) setFantasyName(initialData?.fantasyName);
  };

  useEffect(() => {
    initialData && setFormFieldsInitialValues(initialData);
    getBillingTaxDefinitionsOptions();
  }, [initialData]);

  useEffect(() => {
    isAMexicanOrganization && getBillingTaxDefinitionsOptions();
  }, [isAMexicanOrganization]);

  useEffect(() => {
    getListOfCountriesByOfferedService(setCountriesList);
  }, []);

  return (
    <div className="updateOrderBillingDataModal">
      <h2 className="modalSubtitle">{t(`${i18nLanguageKey}modalSubtitle`)}</h2>
      <div className="orderBillingDataForm">
        <div className="formFieldsLine firstLine">
          <NudosTextInput
            label={t(`${i18nLanguageKey}updateOrderBillingDataModal:denomination`)}
            componentSize="small"
            placeholder={t(`${i18nLanguageKey}updateOrderBillingDataModal:legalName`)}
            isFilled={!!businessName}
            defaultValue={businessName}
            handleChange={setBusinessName}
            errorText={getBusinessNameError()}
          />
          <NudosCountrySelect
            label={t(`${i18nLanguageKey}updateOrderBillingDataModal:idCountry`)}
            listOfCountries={countriesList}
            defaultCountry={billingOrganizationCountry}
            handleCountryChange={e => setBillingOrganizationCountry(e)}
            isDisabled={!!billingOrganizationCountry}
          />
          <NudosTextInput
            label={t(`${i18nLanguageKey}updateOrderBillingDataModal:taxDocument`)}
            componentSize="small"
            placeholder="RUT, CUIT, NIT"
            isFilled={!!taxNumber}
            defaultValue={taxNumber}
            handleChange={setTaxNumber}
            errorText={taxNumberErrorText}
          />
          <NudosTextInput
            label={`${t(`${i18nLanguageKey}updateOrderBillingDataModal:legalName`)}*`}
            componentSize="small"
            placeholder={t(`${i18nLanguageKey}updateOrderBillingDataModal:taxDocument`)}
            isFilled={!!countryRegionOrState}
            defaultValue={countryRegionOrState}
            handleChange={e => setCountryRegionOrState(e)}
          />
        </div>
        {organizationIsUruguay && (
          <div className="formFieldsLine secondLine">
            <NudosPhoneInput
              label="Número de teléfono"
              placeholder="Número de teléfono"
              countriesList={countriesList || []}
              personalClass="phoneInput"
              componentSize="medium"
              defaultPhoneNumber={phoneNumber}
              defaultCountryData={phoneCountry}
              handlePhoneChange={setPhoneNumber}
              handleCountryPhoneChange={setPhoneCountry}
            />
            <NudosTextInput
              label="Nombre fantasía*"
              isFilled={!!fantasyName}
              defaultValue={fantasyName}
              placeholder="Nombre fantasía"
              handleChange={e => setFantasyName(e)}
              customClassName="fantasyNameInput"
            />
          </div>
        )}
        <div className="formFieldsLine secondLine">
          <NudosTextInput
            label="CP*"
            componentSize="extraSmall"
            placeholder="Zip/ CP*"
            isFilled={!!zipCode}
            defaultValue={zipCode}
            handleChange={setZipCode}
            handleBlur={() => setZipCode(formatZipCode(zipCode))}
            errorText={getIsInvalidZipCode(zipCode) ? invalidZipCodeText : undefined}
          />
          <NudosTextInput
            label={`${t(`${i18nLanguageKey}updateOrderBillingDataModal:city`)}*`}
            componentSize="small"
            placeholder={`${t(`${i18nLanguageKey}updateOrderBillingDataModal:city`)}*`}
            isFilled={!!city}
            defaultValue={city}
            handleChange={setCity}
          />
          <NudosTextInput
            label={t(`${i18nLanguageKey}directions:label`)}
            componentSize="w368"
            placeholder={t(`${i18nLanguageKey}directions:placeholder`)}
            isFilled={!!addressAditionalReferences}
            defaultValue={addressAditionalReferences}
            handleChange={setAddressAditionalReferences}
          />
        </div>
        <div className="formFieldsLine thirdLine">
          <NudosTextInput
            customClassName="billingAddress"
            label={t(`${i18nLanguageKey}billingAddress:label`)}
            componentSize="large"
            placeholder={t(`${i18nLanguageKey}billingAddress:placeholder`)}
            isFilled={!!billingAddress}
            defaultValue={billingAddress}
            handleChange={setBillingAddress}
          />
          <NudosTextInput
            customClassName="billingAddress"
            label={`${t(`${i18nLanguageKey}updateOrderBillingDataModal:billingEmail`)}*`}
            componentSize="w256"
            placeholder={t(`${i18nLanguageKey}updateOrderBillingDataModal:billingEmail`)}
            isFilled={!!billingEmail}
            defaultValue={billingEmail}
            handleChange={e => setBillingEmail(e)}
          />
        </div>
        {isAMexicanOrganization && (
          <div className="formFieldsLine fourthLine">
            <NudosDropdownWithSearchbar
              componentSize="medium"
              label={`${t(`${i18nLanguageKey}updateOrderBillingDataModal:taxSystem`)}*`}
              customPlaceholder={t(`${i18nLanguageKey}updateOrderBillingDataModal:taxSystem`)}
              rawOptions={taxationRelatedDefinitionsOptions?.taxSituation || []}
              formDropdownOptionsCallback={formTaxDataDropdownOptionsFn(setTaxSituation)}
              filterBySearchStringCallback={filterBySearchStringCallback}
              isFilled={!!newTaxSituation}
              currentValue={newTaxSituation}
            />
            <NudosDropdownWithSearchbar
              componentSize="small"
              label={`${t(`${i18nLanguageKey}updateOrderBillingDataModal:taxRegime`)}*`}
              customPlaceholder={t(`${i18nLanguageKey}updateOrderBillingDataModal:taxRegime`)}
              rawOptions={taxationRelatedDefinitionsOptions?.taxUse || []}
              formDropdownOptionsCallback={formTaxDataDropdownOptionsFn(setTaxUse)}
              filterBySearchStringCallback={filterBySearchStringCallback}
              isFilled={!!newTaxUse}
              currentValue={newTaxUse}
            />
          </div>
        )}
        {requiresTaxDocument && (
          <div className="formFieldsLine fifthLine">
            <NudosUploadDownloadButton
              customWidth="155px"
              uploadedFileHandler={handleSelectTaxStatusDocumentToUpload}
              currentFileUrl={initialData?.taxSituationDocUrl || ''}
              downloadButtonText={t(`${i18nLanguageKey}updateOrderBillingDataModal:viewDocument`)}
              buttonEmptyText={getTaxDocumentButtonText()}
              buttonFilledText={getTaxDocumentButtonText()}
            />
          </div>
        )}
        <NudosButton
          buttonText={t(`${i18nLanguageKey}nudosButton`)}
          componentSize="large"
          handleClick={handleSaveBillingData}
          isButtonDisabled={isButtonDisable()}
          isButtonLoading={loading}
        />
      </div>
    </div>
  );
};
