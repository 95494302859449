import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M11.2125 12.2125C11.1825 12.6423 10.6768 12.8431 10.3703 12.5472L7.621 9.8933C7.27254 9.55692 6.72747 9.55692 6.37901 9.89329L3.62978 12.5472C3.32319 12.8431 2.81755 12.6423 2.7875 12.2125C2.58324 9.29268 2.57463 6.36451 2.7443 3.44424C2.79999 2.48583 3.58594 1.75 4.52621 1.75H9.4738C10.414 1.75 11.2 2.48583 11.2557 3.44424C11.4254 6.36451 11.4168 9.29268 11.2125 12.2125Z"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const NidIcon = memo(ForwardRef);
export default NidIcon;
