import create from 'zustand';
import { IOnboardingDetails } from '../types/onboarding';

export interface iState {
  showFreeOnboarding: boolean;
  setShowFreeOnboarding: (showFreeOnboarding: boolean) => void;
  showPrimeOnboarding: boolean;
  setShowPrimeOnboarding: (showPrimeOnboarding: boolean) => void;
  detailsPrimeOnboarding?: IOnboardingDetails;
  setDetailsPrimeOnboarding: (detailsPrimeOnboarding: IOnboardingDetails) => void;
  detailsFreeOnboarding?: IOnboardingDetails;
  setDetailsFreeOnboarding: (detailsFreeOnboarding: IOnboardingDetails) => void;
}

const useOnboardingState = create<iState>(set => ({
  showFreeOnboarding: false,
  showPrimeOnboarding: false,
  detailsPrimeOnboarding: undefined,
  detailsFreeOnboarding: undefined,
  setShowFreeOnboarding: showFreeOnboarding => set({ showFreeOnboarding }),
  setShowPrimeOnboarding: showPrimeOnboarding => set({ showPrimeOnboarding }),
  setDetailsPrimeOnboarding: detailsPrimeOnboarding => set({ detailsPrimeOnboarding }),
  setDetailsFreeOnboarding: detailsFreeOnboarding => set({ detailsFreeOnboarding })
}));

export default useOnboardingState;
