import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M3 3.5H10L9 7.25H4L3 3.5Z" stroke={props?.stroke || '#B3B3B3'} strokeLinejoin="round" />
    <path d="M3 3.5L2.75 2.5H1.5" stroke={props?.stroke || '#B3B3B3'} strokeLinejoin="round" />
    <circle cx="4.25" cy="9.25" r="0.75" stroke={props?.stroke || '#B3B3B3'} strokeLinejoin="round" />
    <circle cx="8.75" cy="9.25" r="0.75" stroke={props?.stroke || '#B3B3B3'} strokeLinejoin="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Cart = memo(ForwardRef);
export default Cart;
