import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={8} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6436_140625)">
      <path
        d="M4.00007 4.00112C4.98629 4.00112 5.78578 3.20162 5.78578 2.2154C5.78578 1.22918 4.98629 0.429688 4.00007 0.429688C3.01385 0.429688 2.21436 1.22918 2.21436 2.2154C2.21436 3.20162 3.01385 4.00112 4.00007 4.00112Z"
        fill="white"
        stroke="#383838"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.1704 6.0529C7.93606 6.56422 7.45583 7.57079 6.53514 7.57079H1.46488C0.544181 7.57079 0.0639851 6.56422 0.829635 6.0529C1.73686 5.44699 2.82718 5.09375 4.00001 5.09375C5.17283 5.09375 6.26314 5.44699 7.1704 6.0529Z"
        fill="white"
        stroke="#383838"
        strokeWidth="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_6436_140625">
        <rect width="8" height="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const IconHeaderAccount = memo(ForwardRef);
export default IconHeaderAccount;
