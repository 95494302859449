import { FC } from 'react';
import { NoCheck, SuccessCheck } from '../../../../../../../assets/images/components';
import { IorganizationOfficeData } from '../../../../../../../types/account';
import { checkIfOfficeAddressDataIsComplete } from '../../../../checkout.utils';
import { NudosHoverText } from '../../../../../../../components/NudosComponents';
import { displaySuccessNotification } from '../../../../../../../utils/displayNudosStandardNotifications';
import { useTranslation } from 'react-i18next';
import useCartState from '../../../../../../../state/useCartState';
import { ICartShipping, IProductShipping } from '../../../../../../../types/cart';

import './ShippingCountryHeader.scss';
import { successPromise } from '../../../../../../../utils/simulationPromise';

interface ICountryInfo {
  countryName: string;
  countryFlag: string;
  countryNameStringId: string;
}
interface IShipping {
  everyOneOffice?: boolean;
  setEveryOneOffice?: (check: boolean) => void;
  officeAddress?: IorganizationOfficeData;
  loadingOfficeAddress?: boolean;
  countryInfo: ICountryInfo;
  openAddOfficeAddress: (getOfficeAddressCallback?: (data?: IorganizationOfficeData) => void) => Promise<void> | void;
  sendEverythingToOneAddress: (state: boolean) => void;
}

const ShippingCountryHeader: FC<IShipping> = ({
  everyOneOffice,
  setEveryOneOffice,
  officeAddress,
  loadingOfficeAddress,
  countryInfo,
  openAddOfficeAddress,
  sendEverythingToOneAddress
}) => {
  const { t } = useTranslation();

  const { defaultAddressShipment, globalShippingCart, setGlobalShippingCart } = useCartState();
  const defaultAddressIsUser = !!defaultAddressShipment?.employee;
  const defaultAddressIsOffice = !!defaultAddressShipment?.destinationIsOffice;
  const defaultAddressIsOfficeWarehouse =
    !defaultAddressShipment?.employee &&
    !defaultAddressShipment?.destinationIsOffice &&
    !!defaultAddressShipment?.destinationLocationId;
  const noDestinationSelected = !defaultAddressIsUser && !defaultAddressIsOffice && !defaultAddressIsOfficeWarehouse;
  const officeName = officeAddress?.address || '';
  const receiverName = officeAddress?.shipmentInfo?.receiverName;
  const receiverPhone = officeAddress?.shipmentInfo?.receiverPhone;
  const translationKey = 'nodi:checkout:shippingCountryHeader:';
  const isOfficeAddressDataComplete = checkIfOfficeAddressDataIsComplete(officeAddress);

  const displayAllToolsWillBeSendToOfficeAlert = () =>
    displaySuccessNotification({ customJSXMessage: <>{t(`${translationKey}toolsWillBeSendText`)}</> });
  const justEditAddressConclusionCallbackFn = () =>
    displaySuccessNotification({ customJSXMessage: <>{t(`${translationKey}editAddressConclusionText`)}</> });

  const sendAllToolsToOfficeModalConclusionCallbackFn = () => {
    setEveryOneOffice && setEveryOneOffice(true);
    displayAllToolsWillBeSendToOfficeAlert();
  };

  const changeCheck = (editInfo?: boolean) => {
    if (editInfo) return openAddOfficeAddress(() => justEditAddressConclusionCallbackFn);
    if (isOfficeAddressDataComplete && !everyOneOffice) {
      return sendAllToolsToOfficeModalConclusionCallbackFn();
    }
    if (everyOneOffice) {
      return setEveryOneOffice && setEveryOneOffice(false);
    }
    openAddOfficeAddress(() => sendAllToolsToOfficeModalConclusionCallbackFn);
  };
  const assignDefaultShippingAddress = async () => {
    const actualShipments = globalShippingCart?.shipments || [];
    const newShipmentsFormat: ICartShipping[] = [];
    const defaultObj = {
      destinationIsOffice: defaultAddressShipment?.destinationIsOffice,
      destinationLocationId: defaultAddressShipment?.destinationLocationId,
      employee: defaultAddressShipment?.employee,
      receiverInformation: defaultAddressShipment?.receiverInformation
    };
    for (let i = 0; i < actualShipments?.length; i++) {
      const individualShipment: ICartShipping = actualShipments[i];
      const newIndividualShipment = { ...individualShipment, ...defaultObj };
      newShipmentsFormat.push(newIndividualShipment);
    }
    const newGlobalShippingCart: IProductShipping = {
      shipments: newShipmentsFormat,
      shoppingCartId: Number(globalShippingCart?.shoppingCartId)
    };
    sendEverythingToOneAddress(true);
    setGlobalShippingCart({ ...newGlobalShippingCart });
    await successPromise(100);
    sendEverythingToOneAddress(false);
  };

  const allShipmentsToTheSameAddress = () => {
    if (globalShippingCart && globalShippingCart?.shipments && globalShippingCart?.shipments?.length > 0) {
      const firstId = globalShippingCart?.shipments[0]?.destinationLocationId;
      const allIdsAreTheSame = globalShippingCart?.shipments.every(
        shipment => !!shipment?.destinationLocationId && shipment.destinationLocationId === firstId
      );
      return allIdsAreTheSame;
    }
    return false;
  };

  return (
    <div id="shippingCountryHeader" className="extraShippingCountryHeader">
      <div className="shippingCountryName">
        <div className="shippingFlag">
          <div
            className="myShippingFlag"
            style={{
              backgroundImage: `url(${countryInfo?.countryFlag})`
            }}
          ></div>
        </div>
        <div className="whiteName">
          {t('nodi:orderDetails:shipmentsList:countryName')}{' '}
          {t(`services:countries:${countryInfo?.countryNameStringId}`)}
        </div>
      </div>
      <div className="containerSelectOffice">
        <div
          className="officeCheck"
          onClick={() => {
            if (!noDestinationSelected && !allShipmentsToTheSameAddress()) {
              assignDefaultShippingAddress();
            }
          }}
        >
          {allShipmentsToTheSameAddress() ? <SuccessCheck /> : <NoCheck stroke="#ECECEC" />}
        </div>
        <div className="officeInfoContainer">
          <div className="officeNameGray" style={{ color: noDestinationSelected ? '#ECECEC' : '' }}>
            {t(`${translationKey}allToTheSameAddress`)}
          </div>
          {defaultAddressIsOffice && (
            <>
              {officeAddress && (
                <>
                  {officeName && (
                    <div className="officeNameBlack">
                      <NudosHoverText
                        onlyIfTruncated
                        text={`${t(`${translationKey}offices`)} - ${officeName}`}
                        charactersLimit={35}
                      />
                      /
                    </div>
                  )}
                  {receiverName ? (
                    <div className="existDataBlack maxName">{receiverName}</div>
                  ) : (
                    <div className="noDataGray">{t('recurrentWords:name')} </div>
                  )}
                  {receiverPhone ? (
                    <div className="existDataBlack maxPhone">&nbsp;{`- ${receiverPhone}`}</div>
                  ) : (
                    <div className="noDataGray">&nbsp;{`- ${t('recurrentWords:contact')}`}</div>
                  )}
                </>
              )}
              {!officeAddress && loadingOfficeAddress && (
                <div className="officeAddressInHeaderSkeletonLoader animationLoader" />
              )}
            </>
          )}
          {defaultAddressIsOfficeWarehouse && (
            <div className="officeNameBlack">{t(`${translationKey}nudosWarehouse`)}</div>
          )}
          {defaultAddressIsUser && (
            <>
              {officeName && (
                <div className="officeNameBlack">
                  <NudosHoverText
                    onlyIfTruncated
                    text={`${defaultAddressShipment?.employee?.firstName} ${defaultAddressShipment?.employee?.lastName} - ${defaultAddressShipment?.employee?.address?.address}`}
                    charactersLimit={40}
                  />
                  /
                </div>
              )}
            </>
          )}
        </div>

        {defaultAddressIsOffice && (
          <div className="actionHeader">
            <div className="blueAction" onClick={() => changeCheck(true)}>
              {isOfficeAddressDataComplete ? t('recurrentWords:edit') : t('recurrentWords:add')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShippingCountryHeader;
