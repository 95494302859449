import * as yup from 'yup';
import usePlatform from '../../../../../../hooks/usePlatform';
import { IformInputs } from '../../../../../../types/SignUp';
import { isPasswordValid } from '../../../../../../utils/formValidations';

const isMobile = usePlatform();

const useSignUpFromInvitationController = () => {
  const formInputs: IformInputs[] = [
    {
      id: 'email',
      placeholder: '',
      label: 'Correo electrónico*',
      type: 'text'
    },
    {
      id: 'password',
      placeholder: '',
      label: isMobile
        ? 'Crea una contraseña* Debe tener una mayus, un número y un carácter especial'
        : 'Crea una contraseña* Debe tener  mínimo una mayus, un número y un carácter especial',
      type: 'password'
    },
    {
      id: 'confirmPassword',
      placeholder: '',
      label: 'Repite tu contraseña*',
      type: 'password'
    }
  ];

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .required()
      .matches(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
        'El email no es válido'
      ),
    password: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.-=])([A-Za-z\d$@$!%*?&.-=]|[^ ]){8,15}$/,
        'La contraseña no es válida'
      ),
    confirmPassword: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.-=])([A-Za-z\d$@$!%*?&.-=]|[^ ]){8,15}$/,
        'La contraseña no es válida'
      )
  });

  const checkPasswordInputs = (password: string, confirmPassword: string) => {
    if (!!password && !!confirmPassword) {
      if (password === confirmPassword && !!isPasswordValid(password)) {
        return true;
      }
      return false;
    }
    return true;
  };

  return { formInputs, formSchema, isPasswordValid, checkPasswordInputs };
};

export default useSignUpFromInvitationController;
