import { FC } from 'react';
import { NudosDateRange, NudosStaticAlertInComponent } from '../../../../../components/DesignSystem';
import { NudosTextArea } from '../../../../../components/NudosComponents';
import { IOutRange } from '../../../../../types/requestLogisticsModule';
import './AssignmentModuleStep3SelectDate.scss';
import { useTranslation } from 'react-i18next';

interface IAssignmentModuleStep2SelectDate {
  startDay: number;
  handleCheckRange?: (dateRange: IOutRange) => void;
  isValidRange?: (state: boolean) => void;
  defaultDateRange?: IOutRange;
  handleCollectionDetailsText?: (text: string) => void;
  collectionDetailsText?: string;
}

const AssignmentModuleStep3SelectDate: FC<IAssignmentModuleStep2SelectDate> = ({
  startDay,
  handleCheckRange,
  isValidRange,
  defaultDateRange,
  handleCollectionDetailsText,
  collectionDetailsText
}: IAssignmentModuleStep2SelectDate) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:dates:';
  const titleText = t(`${translationKey}title`);
  const alertOptionsTitle = t(`${translationKey}alertOptionsTitle`);
  const specifyDeliveryDetailsText = t(`${translationKey}specifyDeliveryDetails`);
  const textareaPlaceholderText = t(`${translationKey}textareaPlaceholderText`);
  const textareaCaptionText = t(`${translationKey}textareaCaptionText`);

  const isMyRDateRange = (dateRange: IOutRange) => {
    if (handleCheckRange) {
      handleCheckRange(dateRange);
    }
  };

  return (
    <div id="assignmentModuleStep2SelectDate">
      <div className="boxSelectDate">
        <div className="titleDate">{titleText}</div>{' '}
        <div className="containerRange">
          <div className="boxRangeItem">
            <NudosDateRange
              sendValueOut={isMyRDateRange}
              startDay={startDay}
              defaultDateRange={defaultDateRange}
              validRange={isValidRange}
            />
          </div>
        </div>
        <div className="basicLine">
          <div className="boxBasicLine" />
        </div>
        <div className="containerAlert">
          <div className="containerBlueAlert">
            <NudosStaticAlertInComponent
              customClassname="my-0"
              type="time"
              alertOptions={{
                title: alertOptionsTitle
              }}
            />
          </div>
        </div>
        <div className="containerTextArea">
          <div className="boxTextArea">
            <NudosTextArea
              label={specifyDeliveryDetailsText}
              currentValue={collectionDetailsText || ''}
              maxLength={1000}
              placeholder={textareaPlaceholderText}
              caption={textareaCaptionText}
              updateCurrentValueCallback={e => {
                if (handleCollectionDetailsText) {
                  handleCollectionDetailsText(e);
                }
              }}
              noResize
              textHeight={64}
              componentSize="large"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentModuleStep3SelectDate;
