import { FC } from 'react';
import { NudosToolTip } from '../../../../../components/NudosComponents';
import { IListLocationAddress, ILocationAddress } from '../../../../../types/account';
import { AddCountryAddress, CountryCard, ListAddressCard } from '../AccountComponents';
import { useTranslation, Trans } from 'react-i18next';
import './OrganizationAddressesTab.scss';

interface IOrganizationAddressesTab {
  addressList?: IListLocationAddress;
  openModalAddressDetail?: (item: ILocationAddress) => void;
  loadingAddress?: boolean;
  blockedAddButton?: boolean;
  setOpenModalNewAddress?: (state: boolean) => void;
  editAddress?: (item: ILocationAddress) => void;
}

const OrganizationAddressesTab: FC<IOrganizationAddressesTab> = ({
  addressList,
  openModalAddressDetail,
  loadingAddress,
  blockedAddButton,
  setOpenModalNewAddress,
  editAddress
}: IOrganizationAddressesTab) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:account:organizationAddressesTab:';

  return (
    <div className="organizationAddressesTab">
      <div className="mapTitle titleFormAccount">
        {t(`${i18nLanguageKey}titleFormAccount`)}
        <NudosToolTip>
          <div className="toolTip">
            <Trans i18nKey={t(`${i18nLanguageKey}toolTip`)} components={{ 1: <strong></strong> }} />
          </div>
        </NudosToolTip>
      </div>
      {loadingAddress ? (
        <div>{t(`${i18nLanguageKey}loadingAddress`)}</div>
      ) : (
        <>
          {addressList && (
            <div className="boxMaps">
              <div className="formContainer">
                {addressList &&
                  addressList?.office &&
                  addressList.office.map(item => {
                    return (
                      <CountryCard
                        editAddress={() => {
                          if (editAddress) {
                            editAddress(item);
                          }
                        }}
                        cardData={item}
                        key={`country-card-${item.id}`}
                      />
                    );
                  })}
                <AddCountryAddress
                  blockedAddButton={blockedAddButton}
                  setOpenModalNewAddress={setOpenModalNewAddress}
                />
              </div>
              <div className="viewContainer">
                {addressList?.office &&
                  addressList.office.map(item => {
                    return (
                      <ListAddressCard
                        openModalAddressDetail={openModalAddressDetail}
                        cardData={item}
                        selectData={addressList?.others}
                        key={`list-address-card${item.id}`}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OrganizationAddressesTab;
