import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BlueSmile, NoSmile } from '../../../../../assets/images/components/floatForm';
import { ToolSerial } from '../../../../../components';
import { NudosHoverText } from '../../../../../components/NudosComponents';
import ProductPills from '../../../../../components/ProductPills/ProductPills';
import { IshipmentDetails, IshipmentProductInfo, ORDER_SHIPMENT_STATUS } from '../../../../../types/orders';
import { NudosRedirectionIcon } from '../../../../../components/DesignSystem';
import './ShipmentProductCard.scss';

const ShipmentProductCard = ({
  productData,
  shipmentData
}: {
  productData: IshipmentProductInfo;
  shipmentData: IshipmentDetails;
}) => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();

  const i18nLanguageKey = 'nodi:orderDetails:shipmentProductCard:';
  const image = productData?.image || '';
  const name = productData?.name || `${t(`${i18nLanguageKey}noName`)}`;
  const brandImage = productData?.brandImage || '';
  const brandName = productData?.brand || `${t(`${i18nLanguageKey}noBrand`)}`;
  const pills = typeof productData?.pills === 'string' ? JSON.parse(productData.pills) : productData?.pills || {};
  const serial = productData?.serial || `${t(`${i18nLanguageKey}noSerialNumber`)}`;
  const redirectionsTranslationKey = `nodi:headBar:`;
  const shipmentIsConfirmed =
    !!shipmentData?.statusName &&
    ![ORDER_SHIPMENT_STATUS.PENDING, ORDER_SHIPMENT_STATUS.CANCELED].includes(
      shipmentData?.statusName as ORDER_SHIPMENT_STATUS
    );
  const redirectionToToolIsDeactivated = !productData?.productId || !shipmentIsConfirmed;

  const referenceModelIcon =
    productData?.referenceModel === 'ExternalProduct' ? (
      <NoSmile className="referenceModelIcon" />
    ) : (
      <BlueSmile className="referenceModelIcon" />
    );

  const redirectionToToolDetailsData = {
    pathname: `/nodi/details/${productData?.productId}`,
    state: {
      customReturnPath: `/nodi/orders/details/${orderId}`,
      customNavigationTitle: t(`${redirectionsTranslationKey}headerTextBold:orders`),
      customNavigationSubtitle: t(`${redirectionsTranslationKey}headerTextRegular:productDetails`)
    }
  };

  const cantViewToolYetTooltip = (
    <div className="cantViewToolYetTooltip">{t(`${i18nLanguageKey}cantViewToolYetTooltip`)}</div>
  );

  return (
    <div className="shipmentProductCard">
      <div className="photoSection">
        <img alt="Foto del producto" src={image} />
      </div>
      <div className="nameBrandAndPillsSection">
        <div className="nameAndBrand">
          <div className="brand">
            <div className="brand">
              {brandImage && <img src={brandImage} />}
              {!brandImage && <NudosHoverText onlyIfTruncated text={brandName} charactersLimit={12} />}
            </div>
          </div>
          <NudosHoverText customClassName="name" onlyIfTruncated text={name} charactersLimit={42} />
          <NudosRedirectionIcon
            showHoverTooltip={!shipmentIsConfirmed}
            isDeactivated={redirectionToToolIsDeactivated}
            redirectionDataObject={redirectionToToolDetailsData}
            toolTipComponent={cantViewToolYetTooltip}
          />
        </div>
        <ProductPills pills={pills} />
      </div>
      <div className="serialAndReferenceModel">
        <div className="referenceModel">
          {referenceModelIcon}
          <div className="containerWarranty">
            <div className="textWarranty">{`${t(`${i18nLanguageKey}warranty`)} ${productData?.warranty || '-'}`}</div>
          </div>
        </div>
        <ToolSerial serial={serial} charactersLimitForHover={18} />
      </div>
    </div>
  );
};

export default ShipmentProductCard;
