import { useEffect, useState } from 'react';
import { NudosSelectButton } from '../../../../../../../components/NudosComponents';
import { IInvoicePaymentMethods, IPaymentExtra } from '../../../../../../../types/cart';
import { CardIcon, TransferIcon } from '../../../../../../../assets/images/views/Ecommerce';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../utils/displayNudosStandardNotifications';
import { getPaymentMethods } from '../../../../../../../services/checkout';
import { PaymentMethodCard } from '../PaymentMethodCard';
import { NudosStaticAlertInComponent } from '../../../../../../../components/DesignSystem';
import { EditPencilIcon } from '../../../../../../../assets/images/views/Admin/employees';
import { ModalShareInvoice } from '../../../../../../RequestLogistics/LogisticsQuoteAndBillingStep/LogisticServiceBillingData/Components';
import NudosCurrencyDropdown from '../../../../../../../components/DesignSystem/NudosCurrencyDropdown/NudosCurrencyDropdown';
import useCheckoutStore from '../../../../state';
import { Trans, useTranslation } from 'react-i18next';
import useStoreCountryState from '../../../../../../../state/useStoreCountryState';
import { validatePaymentMethods } from '../../../../../../../utils/checkout';
import { SPECIAL_BUSINESS_NAME_TYPES } from '../../../../../../../types/checkout';

import './PaymentMethodSection.scss';

const PaymentMethodSection = ({
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  updateBillingData
}: {
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<IInvoicePaymentMethods | undefined>>;
  selectedPaymentMethod?: IInvoicePaymentMethods;
  updateBillingData?: (method: IInvoicePaymentMethods) => void;
}) => {
  const { storeCountry } = useStoreCountryState();
  const { t } = useTranslation();
  const { invoiceAdditionalEmail, setInvoiceAdditionalEmail, checkoutBillingData } = useCheckoutStore();

  const [paymentMethodsList, setPaymentMethodsList] = useState<IInvoicePaymentMethods[]>();
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(false);

  const [showShareInvoiceEmailModal, setShowShareInvoiceEmailModal] = useState(false);
  const [currency, setCurrency] = useState<IInvoicePaymentMethods>();

  const translationKey = 'nodi:billingDataSection:paymentMethodSection:';
  const billingDataCountryCode = checkoutBillingData?.documentCountry?.code || '';
  const specialCountriesCodes: string[] = ['co', 'ar', 'mx', 'pe', 'uy'];
  const countryIsPeru = billingDataCountryCode === 'pe';
  const contryIsMx = billingDataCountryCode === 'mx';
  const transfUSDWithCLABEText = contryIsMx ? 'CLABE' : '';
  const titleMethod = t(`${translationKey}titleMethod`);
  const alertTitle = t(`${translationKey}alertTitle`);
  const currencyDropdownPlaceholder = t(`${translationKey}currencyDropdownPlaceholder`);
  const shareInvoiceButton = t(`${translationKey}shareInvoiceButton`);
  const transfMxnExtraBody = t(`${translationKey}transfMxnExtraBody`);
  const creditCardExtraBody = t(`${translationKey}creditCardExtraBody`);
  const internationalCardExtraBody = t(`${translationKey}internationalCardExtraBody`);
  const transfCopExtraBody = t(`${translationKey}transfCopExtraBody`);
  const transfPenArsExtraBody = t(`${translationKey}transfPenArsExtraBody`);
  const copCreditCardExtraBody = t(`${translationKey}copCreditCardExtraBody`);
  const arsBlueMessage = t(`${translationKey}arsBlueMessage`);
  const transferAlias = t(`${translationKey}transferAlias`);
  const nationalCreditCard = t(`${translationKey}nationalCreditCard`);
  const speiTransfer = t(`${translationKey}speiTransfer`);
  const internationalCreditCard = t(`${translationKey}internationalCreditCard`);

  const changeNeedInvoiceNotification = t(`${translationKey}changeNeedInvoiceNotification`);
  const changeMethodNotificationText = t(`${translationKey}changeMethodNotificationText`);
  const transfUsdExtraBody = t(`${translationKey}transfUsdExtraBody`);

  const handleCheckBoxClick = () => {
    if (!invoiceAdditionalEmail) setShowShareInvoiceEmailModal(true);
    if (invoiceAdditionalEmail) setInvoiceAdditionalEmail('');
  };

  const extraDataList: IExtraData = {
    'Transf. MXN': {
      icon: <TransferIcon />,
      extraBody: transfMxnExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: speiTransfer
    },
    'Tarjeta crédito': {
      icon: <CardIcon />,
      extraBody: creditCardExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: nationalCreditCard
    },
    'Transf. USD': {
      icon: <TransferIcon />,
      extraBody: <Trans i18nKey={transfUsdExtraBody} components={{ 1: transfUSDWithCLABEText }} />,
      showCheckInvoice: true,
      needInvoice: false,
      alias: transferAlias
    },
    'Tarjeta internacional': {
      icon: <CardIcon />,
      extraBody: internationalCardExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: internationalCreditCard
    },
    'Transf. COP': {
      icon: <TransferIcon />,
      extraBody: transfCopExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: transferAlias
    },
    'Transf. ARS': {
      icon: <TransferIcon />,
      extraBody: transfPenArsExtraBody,
      showCheckInvoice: false,
      needInvoice: false,
      alias: transferAlias,
      blueMessage: arsBlueMessage
    },
    'Transf. PEN': {
      icon: <TransferIcon />,
      extraBody: transfPenArsExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: transferAlias
    },
    'Tarjeta crédito COP': {
      icon: <CardIcon />,
      extraBody: copCreditCardExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: nationalCreditCard
    }
  };

  const handleChangeMethod = (method: IInvoicePaymentMethods | undefined) => {
    setSelectedPaymentMethod(method);
    if (
      method?.name.includes('Tarjeta') &&
      method.transactionFee &&
      specialCountriesCodes?.includes(billingDataCountryCode)
    ) {
      displaySuccessNotification({
        customJSXMessage: (
          <>
            <Trans i18nKey={changeMethodNotificationText} components={{ 1: method?.transactionFee }} />
          </>
        )
      });
    }
    if (updateBillingData && method) {
      updateBillingData(method);
    }
  };

  const changeNeedInvoice = (method: IInvoicePaymentMethods) => {
    if (method && method?.extraData) {
      const editExtraData = { ...method?.extraData };
      editExtraData.needInvoice = !method?.extraData?.needInvoice;
      setSelectedPaymentMethod({ ...method, extraData: editExtraData });
      !method?.extraData?.needInvoice &&
        displaySuccessNotification({
          customJSXMessage: <>{changeNeedInvoiceNotification}</>
        });
    }
  };

  const formatMethodsList = (list: IInvoicePaymentMethods[]) => {
    const newList = list;
    const formatList: IInvoicePaymentMethods[] = [];
    for (const item of newList) {
      const editMethod = { ...item };
      const extraData = extraDataList?.[item.name];
      editMethod.extraData = extraData;
      formatList.push(editMethod);
    }
    setPaymentMethodsList(formatList);
  };

  const getPaymentMethodsList = async (countryCode: string) => {
    setPaymentMethodsLoading(true);
    try {
      const dataList = await getPaymentMethods(countryCode);
      if (dataList) {
        formatMethodsList(dataList);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setPaymentMethodsLoading(false);
    }
  };

  const filterUniqueCurrencies = (currencyList?: IInvoicePaymentMethods[]) => {
    if (!currencyList) return;
    const uniqueCurrencies = new Set();
    const currencies: IInvoicePaymentMethods[] = [];
    if (countryIsPeru) currencies.push(currencyList[0]);
    if (!countryIsPeru) {
      currencyList.forEach(currencyList => {
        if (!uniqueCurrencies.has(currencyList?.currency?.currencyId)) {
          uniqueCurrencies.add(currencyList?.currency?.currencyId);
          currencies.push(currencyList);
        }
      });
    }

    return currencies;
  };

  const filteredPaymentMethodsList = filterUniqueCurrencies(paymentMethodsList);

  const setBillingDataWhenJustOneExist = () => {
    if (!!filteredPaymentMethodsList && filteredPaymentMethodsList.length === 1)
      setCurrency(filteredPaymentMethodsList[0]);
  };

  useEffect(() => {
    setBillingDataWhenJustOneExist();
  }, [paymentMethodsList]);

  useEffect(() => {
    if (!checkoutBillingData || !checkoutBillingData?.documentCountry?.id || !storeCountry) return;
    const idToCheckPaymentMethods = validatePaymentMethods({
      storeCountryCode: storeCountry?.countryCode || '',
      billinDataCountryCode: checkoutBillingData.countryCode || '',
      documentCountryCode: checkoutBillingData?.documentCountry?.code,
      specialBusinessNameTypeId: checkoutBillingData?.specialBusinessNameTypeId as SPECIAL_BUSINESS_NAME_TYPES
    });
    getPaymentMethodsList(idToCheckPaymentMethods);
  }, [checkoutBillingData]);

  useEffect(() => {
    return () => {
      setSelectedPaymentMethod(undefined);
      setInvoiceAdditionalEmail('');
    };
  }, []);

  return (
    <div className="paymentMethodSection">
      <div className="paymentMethodSectionContainer">
        <div className="titleMethod">{titleMethod}</div>
        <NudosStaticAlertInComponent
          type="information"
          color="gray"
          customClassname="explanationTextContainer"
          alertOptions={{
            title: alertTitle
          }}
          keepTypeIconButChangeColor
        />
        <NudosCurrencyDropdown
          currencyList={filteredPaymentMethodsList || []}
          currentValue={currency}
          charactersLimit={20}
          componentSize="medium"
          customPlaceholder={selectedPaymentMethod?.name || currencyDropdownPlaceholder}
          showSkeletonLoader={paymentMethodsLoading}
          updateCurrentValueCallback={e => {
            setCurrency(e);
            setSelectedPaymentMethod(undefined);
          }}
        />
        <div className="paymentMethodContainer">
          {!!paymentMethodsList && !paymentMethodsLoading && !!currency && (
            <div className="containerMethodCards">
              {!!paymentMethodsList &&
                paymentMethodsList.length > 0 &&
                paymentMethodsList.map(item => {
                  if (item?.currency?.code === currency?.currency?.code) {
                    return (
                      <PaymentMethodCard
                        key={`select-payment-method-${item.id}-country-${item?.currency?.code}`}
                        detailCard={item}
                        paymenthMethod={item.id === selectedPaymentMethod?.id ? selectedPaymentMethod : undefined}
                        handleChangeMethod={method => handleChangeMethod(method)}
                        changeNeedInvoice={method => changeNeedInvoice(method)}
                      />
                    );
                  } else return null;
                })}
            </div>
          )}
        </div>
      </div>

      <div className="shareInvoiceSectionContainer">
        <div className="shareInvoiceButton">
          <NudosSelectButton
            isButtonActive={!!invoiceAdditionalEmail}
            handleClickButton={() => handleCheckBoxClick()}
          />
          {shareInvoiceButton}
        </div>
        {invoiceAdditionalEmail && (
          <div className="shareInvoiceEmail">
            {invoiceAdditionalEmail}
            <EditPencilIcon className="editPencilIcon" onClick={() => setShowShareInvoiceEmailModal(true)} />
          </div>
        )}
      </div>
      <div className="h-10 w-10" />
      {showShareInvoiceEmailModal && (
        <ModalShareInvoice
          emailShareInvoice={invoiceAdditionalEmail}
          closeModalCallback={() => setShowShareInvoiceEmailModal(false)}
          setEmailShareInvoice={setInvoiceAdditionalEmail}
        />
      )}
    </div>
  );
};

export default PaymentMethodSection;

interface IExtraData {
  [key: string]: IPaymentExtra;
}
