import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NudosButton, NudosRadioButton } from '../../../../../components/NudosComponents';
import { displaySuccessNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { segmentTrackEvent } from '../../../../../utils/segment';
import './EquipEmployeeModal.scss';

const EquipEmployeeModal = () => {
  const { push } = useHistory();
  const [selectedOption, setSelectedOption] = useState<number>();
  const handleClickGoWatchButton = () => {
    if (selectedOption === 1) {
      push('/nodi/employees?filter=unequipped');
    }
    if (selectedOption === 2) {
      push('/nodi/employees');
      displaySuccessNotification({ customJSXMessage: <>Estás visualizando todos los empleados</> });
    }
    if (selectedOption === 3) {
      push('/nodi/employees/create');
      segmentTrackEvent({
        employeesNewClick: { NewEmployeeOrigin: 'Dashboard' }
      });
      displaySuccessNotification({ customJSXMessage: <>Crea un empleado nuevo</> });
    }
  };

  return (
    <div className="equipEmployeeModal">
      <div className="question">¿Qué empleados quieres equipar?</div>
      <div className="optionsContainer">
        <div className="optionWrapper" onClick={() => setSelectedOption(1)}>
          <NudosRadioButton componentSize="small" isButtonActive={selectedOption === 1} />
          <div className="optionText">
            Ver empleados <strong>no equipados</strong>
          </div>
        </div>
        <div className="optionWrapper" onClick={() => setSelectedOption(2)}>
          <NudosRadioButton componentSize="small" isButtonActive={selectedOption === 2} />
          <div className="optionText">
            Ver <strong>todos los empleados</strong>
          </div>
        </div>
        <div className="optionWrapper" onClick={() => setSelectedOption(3)}>
          <NudosRadioButton componentSize="small" isButtonActive={selectedOption === 3} />
          <div className="optionText">
            Crear un <strong>empleado nuevo</strong>
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        <NudosButton
          buttonText="Ver"
          componentSize="large"
          handleClick={handleClickGoWatchButton}
          isButtonDisabled={!selectedOption}
        />
      </div>
    </div>
  );
};

export default EquipEmployeeModal;
