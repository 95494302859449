import { useEffect, useState } from 'react';
import {
  IIssueReported,
  ILogisticReports,
  IlogisticServiceDetailedData,
  LS_ADDITIONAL_SERVICE_STATUS,
  LS_STATUS
} from '../../../../../../types/logisticsServicesModule.types';
import LogisticServiceBanner from '../LogisticServiceBanner/LogisticServiceBanner';
import AdditionalServicesNotification from './AdditionalServicesNotification/AdditionalServicesNotification';
import { reportReason } from '../../../../../../constants/logisticServices.constants';
import { Trans, useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../../../utils/segment';

const LogisticServiceNotifications = ({
  logisticServiceData,
  getLogisticServiceDetailCallback,
  isLoading
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  getLogisticServiceDetailCallback: () => void;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  const [openAddressIssue, setOpenAddressIssue] = useState<boolean>(true);
  const [showAdditionalServicesBanner, setShowAdditionalServicesBanner] = useState(true);

  const {
    addressIssue,
    status,
    quoteDocumentUrl,
    reports,
    manualQuote,
    reportNumberOfTries,
    isCancelledAutomatically,
    tools,
    id
  } = logisticServiceData || {};

  const translationKey = 'nodi:logisticServices:logisticServiceNotifications:';
  const originTranslation = t('recurrentWords:origin');
  const destinationTranslation = t('recurrentWords:destination');
  const awaitResponseReportText = t(`${translationKey}awaitResponseReportText`);
  const employeeDoesNotAnswerText = t(`${translationKey}employeeDoesNotAnswerText`);
  const withoutReasonReportText = t(`${translationKey}withoutReasonReportText`);
  const holidayReportText = t(`${translationKey}holidayReportText`);
  const waitingQuoteText = t(`${translationKey}waitingQuoteText`);
  const externalProblemInProgressState = t(`${translationKey}externalProblemInProgressState`);
  const externalProblemState = t(`${translationKey}externalProblemState`);
  const theQuoteIsAvailable = t(`${translationKey}theQuoteIsAvailable`);

  const originIssueReported = addressIssue?.origin?.issueReported?.length;
  const destinationIssueReported = addressIssue?.destination?.issueReported?.length;

  const originIssueReportedData: IIssueReported | undefined =
    addressIssue?.origin.editedBy !== 'user'
      ? addressIssue?.origin?.issueReported?.[addressIssue?.origin?.issueReported?.length - 1]
      : undefined;

  const destinationIssueReportedData: IIssueReported | undefined =
    addressIssue?.destination?.editedBy !== 'user'
      ? addressIssue?.destination?.issueReported?.[addressIssue?.destination?.issueReported?.length - 1]
      : undefined;

  const originFilterIssuesOtherThanCity = originIssueReportedData?.wrongFields?.find(
    field => field.addressWrongField.name !== 'Número de teléfono'
  );

  const destinationFilterIssuesOtherThanCity = destinationIssueReportedData?.wrongFields?.find(
    field => field.addressWrongField.name !== 'Número de teléfono'
  );

  const originHasOnlyPhoneIssues = !destinationIssueReportedData && !originFilterIssuesOtherThanCity;

  const destinationHasOnlyePhoneIssues = !originIssueReportedData && !destinationFilterIssuesOtherThanCity;

  const bothHaveOnlyPhoneIssues =
    !!originIssueReportedData &&
    !!destinationIssueReportedData &&
    !originFilterIssuesOtherThanCity &&
    !destinationFilterIssuesOtherThanCity;

  const onlyPhoneIssue = bothHaveOnlyPhoneIssues || originHasOnlyPhoneIssues || destinationHasOnlyePhoneIssues;

  const originAddressEditedByUser = addressIssue?.origin?.editedBy === 'user';
  const destinationAddressEditedByUser = addressIssue?.destination?.editedBy === 'user';
  const originAddressChangedByOps = addressIssue?.origin?.editedBy === 'nudos';
  const destinationAddressChangedByOps = addressIssue?.destination?.editedBy === 'nudos';

  const originViewedChanges = addressIssue?.origin?.viewedChanges;
  const destinationViewedChanges = addressIssue?.destination?.viewedChanges;
  const originReportedAndNotEdited = !!originIssueReported && !originAddressEditedByUser;
  const destinationReportedAndNotEdited = !!destinationIssueReported && !destinationAddressEditedByUser;
  const addressReportedAndNotEdited = originReportedAndNotEdited || destinationReportedAndNotEdited;

  const originChangedNotViewed = originAddressChangedByOps && !originViewedChanges;
  const destinationChangedNotViewed = destinationAddressChangedByOps && !destinationViewedChanges;
  const addressChangedByOpsNotViewed = originChangedNotViewed || destinationChangedNotViewed;

  const displayOriginIssueText = !!originIssueReported && !originAddressEditedByUser;
  const displayDestinationIssueText = !!destinationIssueReported && !destinationAddressEditedByUser;

  const placeValue =
    originAddressChangedByOps || (displayOriginIssueText && displayDestinationIssueText)
      ? 'ambas ubicaciones'
      : displayOriginIssueText
      ? originTranslation
      : destinationTranslation;

  const displayAddresIssue = displayOriginIssueText || displayDestinationIssueText;
  const displayAddressIssueOnlyPhone = displayAddresIssue && onlyPhoneIssue;
  const canceledLogisticService = status === LS_STATUS.CANCELLED;
  const isCancelledAutomaticallyByReports = isCancelledAutomatically && !!reports?.length;

  const displayReportsBanner =
    reports?.some(report => report?.currentStatus === 'OPEN') || isCancelledAutomaticallyByReports;

  const failureToMeetDeadlinesState = (
    <Trans i18nKey={t(`${translationKey}failureToMeetDeadlinesState`)} components={{ 1: `${reportNumberOfTries}` }} />
  );
  const employeeNotCooperateState = (
    <Trans i18nKey={t(`${translationKey}employeeNotCooperateState`)} components={{ 1: `${reportNumberOfTries}` }} />
  );
  const addresIssueState = <Trans i18nKey={t(`${translationKey}addresIssueState`)} components={{ 1: placeValue }} />;
  const addressChangedNotViewedState = (
    <Trans i18nKey={t(`${translationKey}addressChangedNotViewedState`)} components={{ 1: placeValue }} />
  );

  const findReportByReason = (reasons: string | string[]) =>
    reports?.find(report => {
      if (!report?.reason) return;
      const reasonMatch = Array.isArray(reasons) ? reasons?.includes(report?.reason) : report?.reason === reasons;
      return reasonMatch;
    });

  const withoutReasonReport = findReportByReason(reportReason?.WITHOUT_REASON);
  const employeeNotWillingReport = findReportByReason(reportReason?.EMPLOYEE_NOT_WILLING);
  const failureToMeetDeadlines = findReportByReason([
    reportReason?.EMPLOYEE_NOT_DATE_DELIVERY,
    reportReason?.EMPLOYEE_NOT_DATE_RECEIVER
  ]);
  const employeeDoesNotCooperate = findReportByReason(reportReason?.EMPLOYEE_DOES_NOT_COOPERATE);
  const holidayReport = findReportByReason(reportReason?.HOLIDAY_REPORT);
  const externalProblem = findReportByReason([
    reportReason?.ORIGIN_POINT_ADVERSE,
    reportReason?.DESTINATION_POINT_ADVERSE
  ]);
  const awaitResponseReport = findReportByReason(reportReason?.AWAIT_RESPONSE);
  const employeeDoesNotAnswerReport = findReportByReason([
    reportReason?.UNRESPONSIVE_DELIVERY_EMPLOYEE,
    reportReason?.UNRESPONSIVE_RECEIVING_EMPLOYEE
  ]);

  const sortedReports = reports?.sort((date1, date2) => {
    const getLastLogDate = (report: ILogisticReports) => new Date(report?.logs?.[report.logs.length - 1]?.date || 0);
    return getLastLogDate(date2).getTime() - getLastLogDate(date1).getTime();
  });

  const latestReport = sortedReports?.[0];
  const latestReportIsInProgress = latestReport?.reasonGlobalStatus === 'IN_PROGRESS';
  const displayAddresReported = addressReportedAndNotEdited || addressChangedByOpsNotViewed;
  const displayBannerForAddres = !canceledLogisticService && displayAddresReported;

  const someToolHasFailedAdditionalServices = !!tools?.some(
    tool =>
      !!tool?.logisticAdditionalServices?.some(
        service => service?.status?.statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED
      )
  );

  const displayAdditionalServicesBanner = !showAdditionalServicesBanner && someToolHasFailedAdditionalServices;
  const displayOpenAddressIssue =
    (displayAddresReported && !isLoading && !openAddressIssue) ||
    (!openAddressIssue && displayReportsBanner && !isLoading);

  const displayLogisticBanner = (displayBannerForAddres || displayReportsBanner) && !isLoading && openAddressIssue;
  const displayAdditionalServicesNotification = !displayLogisticBanner && showAdditionalServicesBanner;
  const displayOpenNotificationBanner = displayOpenAddressIssue || displayAdditionalServicesBanner;

  const latestReportName = latestReport?.reasonNameString;
  const statusIsActive = status === 'activo';
  const statusInProgress = status === 'en proceso';
  const trackReportEvent = !!latestReport && (statusIsActive || statusInProgress);
  const reportReasonProperty = statusIsActive ? 'ReportReasonInDelayed' : 'ReportReasonInProcess';
  const viewEventTitle = statusIsActive
    ? 'logisticServiceProblemDelayedView'
    : 'logisticServiceProblemReportInProcessView';
  const openClickEventTitle = statusIsActive
    ? 'logisticServiceProblemDelayedOpenClick'
    : 'logisticServiceProblemInProcessOpenClick';
  const closeClickEventTitle = statusIsActive
    ? 'logisticServiceProblemDelayedCloseClick'
    : 'logisticServiceProblemInProcessCloseClick';

  const getLogistictState = () => {
    const getLogisticServiceState = () => {
      if (isLoading) return null;
      if (displayReportsBanner && latestReport) {
        switch (latestReport) {
          case awaitResponseReport:
            return awaitResponseReportText;
          case employeeDoesNotAnswerReport:
            return employeeDoesNotAnswerText;
          case failureToMeetDeadlines:
            return failureToMeetDeadlinesState;
          case employeeDoesNotCooperate:
          case employeeNotWillingReport:
            return employeeNotCooperateState;
          case holidayReport:
            return holidayReportText;
          case withoutReasonReport:
            return withoutReasonReportText;
          case externalProblem:
            return latestReportIsInProgress ? externalProblemInProgressState : externalProblemState;
        }
      }
      if (status === 'cancelado') return null;
      if (someToolHasFailedAdditionalServices) return '⚠️ No se pudo realizar uno de los servicios solicitados';
      if (manualQuote) return quoteDocumentUrl ? theQuoteIsAvailable : waitingQuoteText;
      if (addressChangedByOpsNotViewed) return addressChangedNotViewedState;
      if (displayAddressIssueOnlyPhone) return `⚠️ Hay problemas con el número de teléfono de ${placeValue}`;
      if (displayAddresIssue) return addresIssueState;
      return '';
    };
    const logisticServiceState = getLogisticServiceState();
    return logisticServiceState ? <div className="state">{logisticServiceState}</div> : null;
  };

  const openBanners = () => {
    setOpenAddressIssue(true);
    setShowAdditionalServicesBanner(true);
    trackReportEvent &&
      segmentTrackEvent({
        [openClickEventTitle]: {
          SL: id || 0,
          [reportReasonProperty]: latestReportName
        }
      });
  };

  const closeAlertCallback = () => {
    setOpenAddressIssue(false);
    trackReportEvent &&
      segmentTrackEvent({
        [closeClickEventTitle]: {
          SL: id || 0,
          [reportReasonProperty]: latestReportName
        }
      });
  };

  useEffect(() => {
    trackReportEvent &&
      segmentTrackEvent({
        [viewEventTitle]: {
          SL: id || 0,
          [reportReasonProperty]: latestReportName
        }
      });
  }, [trackReportEvent]);

  return (
    <div className="logisticServiceNotifications">
      <div className="titleAndQuoteStatus">
        <div className="title">{t(`${translationKey}title`)}</div>
        {getLogistictState()}
        {displayOpenNotificationBanner && (
          <div className="openNotificationBanner" onClick={openBanners}>
            {t(`${translationKey}openNotificationBanner`)}
          </div>
        )}
      </div>
      {displayLogisticBanner && (
        <LogisticServiceBanner
          closeAlertCallback={closeAlertCallback}
          logisticServiceData={logisticServiceData}
          getLogisticServiceCallback={getLogisticServiceDetailCallback}
          onlyPhoneIssue={onlyPhoneIssue}
        />
      )}
      {displayAdditionalServicesNotification && (
        <AdditionalServicesNotification
          logisticServiceData={logisticServiceData}
          setShowAdditionalServicesBanner={setShowAdditionalServicesBanner}
        />
      )}
    </div>
  );
};

export default LogisticServiceNotifications;
