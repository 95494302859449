import { ISpecialBusinessNameType } from './cart';
import { ICountryDetail } from './countries';

export interface UserData {
  organizationId: number;
}

export interface IbillingData {
  id?: number | null;
  countryId?: number | null;
  countryCode?: string | null;
  organizationId?: number | null;
  name?: string | null;
  businessName?: string | null;
  specialBusinessNameTypeId?: number | null;
  document?: string | null;
  address?: string | null;
  additionalReferences?: string | null;
  zipCode?: string | null;
  city?: string | null;
  state?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  email?: string;
  documentCountry?: ICountryDetail;
  taxSituationDocUrl?: string | null;
  taxSituation?: ItaxationRelatedDataRequiredForBilling;
  taxUse?: ItaxationRelatedDataRequiredForBilling;
  taxSituationId?: number;
  taxUseId?: number;
  phone?: string | null;
  phoneCountry?: ICountryDetail | null;
  phoneCountryId?: number | null;
  fantasyName?: string | null;
  street?: string | null;
  number?: string | number | null;
  internalNumber?: string | null;
  colony?: string | null;
  documentCountryId?: number | null;
  specialBusinessNameType?: ISpecialBusinessNameType;
}

export interface DTOgetTaxationRelatedDefinitionsOptions {
  taxSituation: ItaxationRelatedDataRequiredForBilling[];
  taxUse: ItaxationRelatedDataRequiredForBilling[];
}

export interface ItaxationRelatedDataRequiredForBilling {
  id?: number;
  code?: string;
  name?: string;
}

export interface DTOupdatedBillingData {
  countryId: number;
  name: string;
  businessName: string;
  document: string;
  address: string;
  additionalReferences: string;
  zipCode: string;
  city: string;
  state: string;
  documentCountryId?: string | number;
  email?: string;
  taxSituationDocUrl?: string | null;
  taxSituationId?: number;
  taxUseId?: number;
}

export interface DTOrequestQuotationPdf {
  url?: string | null;
}

export interface IrequestShoppingCartQuote {
  shoppingCartId: number;
  billingDataId: number;
}

export type TCurrencyCountryCode = 'mxn' | 'cop';

export interface IcreateOrderPaymentDto {
  orderId: number;
  paymentMethod?: string;
  userData: {
    organizationId: number;
  };
}

export interface IspeiRequiredDataResponse {
  clabe?: string;
  bank?: string;
  swift?: string;
  beneficiaryAddress?: string;
  beneficiaryBankAddress?: string;
  routingNumber?: string;
  accountNumber?: string;
  beneficiary?: string;
  rut?: string;
  beneficiaryBank?: string;
  cbu?: string;
  cuit?: string;
  cci?: string;
  bankCode?: string;
  reference?: string;
  amountRemaining?: number;
  isPaid?: boolean;
  stripeProvider?: number;
  currency?: string;
}

export enum STRIPE_PAYMENT_PROVIDERS {
  STRIPE_MX = 1,
  STRIPE_USA_C_CORP = 2,
  STRIPE_USA_LLC = 3
}

export interface IstripeCheckoutPaymentSessionData {
  orderId?: number;
  clientSecret: string;
  paymentSessionId: string;
  stripeProvider: STRIPE_PAYMENT_PROVIDERS;
}

export type IcreateOrderPaymentResponse = IspeiRequiredDataResponse | IstripeCheckoutPaymentSessionData;

export interface IBank {
  clabe: string;
  bank: string;
  bankCode: string;
  reference: string;
  amountRemaining?: number;
  isPaid?: boolean;
  accountNumber: string;
  bankName: string;
  beneficiary?: string;
  currency?: string;
}

export enum PAYMENT_TYPES {
  TRANSFER_MXN = 'Transf. MXN',
  CARD_MXN = 'Tarjeta crédito',
  TRANSFER_USD = 'Transf. USD',
  INTERNATIONAL_CARD = 'Tarjeta internacional',
  TRANSFER_COP = 'Transf. COP',
  CARD_COP = 'Tarjeta crédito COP',
  TRANSFER_PEN = 'Transf. PEN',
  TRANSFER_ARS = 'Transf. ARS'
}

export enum ORDER_PAYMENT_STRIPE_METHOD {
  BANK_TRANSFER = 'bank_transfer',
  CARD = 'card'
}

export enum SPECIAL_BUSINESS_NAME_TYPES {
  TRADING = 1,
  FREE_ZONE = 2
}

export interface StripePaymentSessionDataDto {
  paymentSessionId: string;
  stripeProvider?: STRIPE_PAYMENT_PROVIDERS;
}

export interface IstripePaymentStatusResponse {
  isPaid: boolean;
  isConcluded: boolean;
}
