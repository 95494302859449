import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { FloatCartDetail, HorizontalFilters } from '../../../components';
import { getCategories, getProductList } from '../../../services/services';
import useShopStore from '../../../state/useShopContext';
import { GenericFetchError } from '../../../constants';
import useEcommerceControler from '../ecommerce.controller';
import SkeletonFilters from '../../../components/SkeletonFilters';
import { ObjectKey, valueKey } from '../../../types/global';
import { magnifier } from '../../../assets/images/views/Admin/main';
import { LoaderProductCard, ProductEcommerceCard, SuperiorPrimeBanner } from './Components';
import useCartState from '../../../state/useCartState';
import { formatOrgData } from '../../../utils/orgFormatData';
import { useTranslation } from 'react-i18next';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import useStoreCountryState from '../../../state/useStoreCountryState';
import { IProductItemList } from '../../../types/catalogue';
import { segmentTrackEvent } from '../../../utils/segment';

let allowLoadingMore = true;
let listeningIsValidating = false;

const ListOfProducts = () => {
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();
  const { levelSubscription } = stateSubscription;
  const { storeCountry } = useStoreCountryState();
  const { filters: search, setPillsFilters, setCategoryFilters, setLoadingFilterPills } = useShopStore();
  const { openCart } = useCartState();
  const { formSinglePillFilterUI } = useEcommerceControler();

  const [page, setPage] = useState<number>(1);
  const intitialPagination = page === 1;

  const [productsInTheList, setProductsInTheList] = useState<IProductItemList[]>([]);
  const divRef = useRef<HTMLDivElement>(null);
  const itemLimit = 10;
  const freePlan = levelSubscription === 'Free';
  const options = {
    revalidateOnFocus: false,
    revalidateIfStale: false
  };

  const params =
    search && search.length > 0
      ? `?filters=${JSON.stringify([
          ...search,
          { name: 'country', includes: 'in', values: [storeCountry?.countryCode || ''] }
        ])}`
      : '';
  const orgInfo = formatOrgData();

  const { data, isValidating, error } = useSWR(
    params && itemLimit && page && storeCountry
      ? `/catalog/items-by-organization/${orgInfo?.organizationId}${params}&limit=${itemLimit}&page=${page}`
      : '',
    getProductList,
    options
  );

  listeningIsValidating = isValidating;

  const { data: filters, error: errorFilerts } = useSWR('/category', getCategories, options);

  const loadingList = !data && !error && intitialPagination;
  const loadingHasMore = !data && !error && !intitialPagination;

  const creatingAndFormattingFilters = () => {
    if (intitialPagination) {
      setLoadingFilterPills(true);
      if (data?.pillsFilters) {
        const pillsFilters = Object.entries(data.pillsFilters).map(pillFilter => {
          const [pillFilterName] = pillFilter;
          const pillFilterObject: ObjectKey<valueKey[]> = {};
          pillFilterObject[`${pillFilterName}`] = formSinglePillFilterUI(pillFilter);
          return pillFilterObject;
        });
        setPillsFilters(pillsFilters);
        setLoadingFilterPills(false);
      }
    }
  };

  useEffect(() => {
    creatingAndFormattingFilters();
  }, [data]);

  const resetPage = () => {
    if (storeCountry) {
      setPage(0);
      allowLoadingMore = true;
    }
  };

  useEffect(() => {
    if (data?.items) {
      setProductsInTheList(prevItems => [...prevItems, ...data.items]);
      if (data.items.length < 10) {
        allowLoadingMore = false;
      }
    }
  }, [data]);

  const handleScroll = () => {
    const div = divRef.current;
    const addScrollAndHeight = Number(div?.scrollTop) + Number(div?.clientHeight) + 5;
    const isValidForPagination =
      div && addScrollAndHeight >= div.scrollHeight && allowLoadingMore && !loadingHasMore && !listeningIsValidating;
    if (isValidForPagination) {
      setPage(prevPageIndex => prevPageIndex + 1);
    }
  };

  const eventProductList = () => {
    segmentTrackEvent({
      catalogueView: { TypeOfPlatform: freePlan ? 'Free' : 'Prime', CountryName: storeCountry?.countryName || '' }
    });
  };

  useEffect(() => {
    eventProductList();
  }, []);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
      return () => div.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    filters && setCategoryFilters(filters);
  }, [filters]);

  useEffect(() => {
    resetPage();
  }, [storeCountry, params]);

  useEffect(() => {
    if (page === 0) {
      setProductsInTheList([]);
      setPage(1);
    }
  }, [page]);

  return (
    <section id="ecommerce-home">
      {openCart && <FloatCartDetail />}
      {freePlan && <SuperiorPrimeBanner />}
      {!!filters && (
        <HorizontalFilters
          filters={filters}
          name="category"
          resetPage={() => {
            resetPage();
          }}
        />
      )}
      {!filters && !errorFilerts && <SkeletonFilters />}
      <div id="product-list-container">
        {loadingList && (
          <div className="containerMyProductList">
            <LoaderProductCard />
          </div>
        )}
        <div ref={divRef} className="containerMyProductList">
          {!!productsInTheList &&
            productsInTheList?.length > 0 &&
            productsInTheList?.map((item, index) => {
              return (
                <ProductEcommerceCard
                  dataProductCard={item}
                  key={`product-ecommerce-card-${item?.productId}-index${index}`}
                />
              );
            })}
        </div>
        {loadingHasMore && (
          <div className="containerMyProductList">
            <LoaderProductCard numberCards={2} />
          </div>
        )}
        {intitialPagination && data?.items?.length === 0 && (
          <div className="unsuccessful">
            <div className="magnifier-icon-container">
              <img src={magnifier} />
            </div>
            <p>{t('nodi:genericNoResults')}</p>
          </div>
        )}
        {intitialPagination && error && <GenericFetchError />}
      </div>
    </section>
  );
};

export default ListOfProducts;
