import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_2230_9931)">
      <path
        d="M0.822708 8.80178C0.954822 10.0367 1.94799 11.0298 3.18225 11.1674C4.099 11.2696 5.04028 11.3574 5.99996 11.3574C6.95965 11.3574 7.90093 11.2696 8.81765 11.1674C10.0519 11.0298 11.0451 10.0367 11.1772 8.80178C11.2747 7.89023 11.3571 6.95434 11.3571 6.00021C11.3571 5.04607 11.2747 4.11019 11.1772 3.19861C11.0451 1.96377 10.0519 0.970589 8.81765 0.833017C7.90093 0.730831 6.95965 0.643066 5.99996 0.643066C5.04028 0.643066 4.099 0.730831 3.18225 0.833017C1.94799 0.970589 0.954822 1.96377 0.822708 3.19861C0.725179 4.11019 0.642822 5.04607 0.642822 6.00021C0.642822 6.95434 0.72518 7.89023 0.822708 8.80178Z"
        fill="#383838"
        stroke="#383838"
      />
      <path d="M3.42847 3.85693H5.9999" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.57143 8.14307H6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.28571 5.14272C7.9958 5.14272 8.57143 4.56709 8.57143 3.857C8.57143 3.14692 7.9958 2.57129 7.28571 2.57129C6.57563 2.57129 6 3.14692 6 3.857C6 4.56709 6.57563 5.14272 7.28571 5.14272Z"
        fill="#383838"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.71418 9.42836C4.0041 9.42836 3.42847 8.8527 3.42847 8.14265C3.42847 7.43256 4.0041 6.85693 4.71418 6.85693C5.42426 6.85693 5.9999 7.43256 5.9999 8.14265C5.9999 8.8527 5.42426 9.42836 4.71418 9.42836Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2230_9931">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const FiltersIcon = memo(ForwardRef);
export default FiltersIcon;
