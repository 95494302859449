import { useState } from 'react';
import {
  IlocationAddressForLogistics,
  IwarehouseData,
  TlogisticsOriginOrDestinationData
} from '../../../../../types/requestLogisticsModule';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import { Iemployee } from '../../../../../types/global';
import { ICountryDetail } from '../../../../../types/countries';
import {
  IconBuilding,
  IconOrangeEmployee,
  IconPin,
  IconQuestion,
  IconReserveBox,
  IconShield,
  IconToolWrench
} from '../../../../../assets/DesignSystem/SVGComponents';
import NudosLogisticsGeneralContainer from '../../../../../components/DesignSystem/NudosLogisticsGeneralContainer/NudosLogisticsGeneralContainer';
import {
  NudosLogisticServiceUserHeader,
  NudosLogisticsEmployeesDropdown,
  NudosLogisticsOfficeDropdown,
  NudosLogisticsWarehouseDropdown
} from '../../../../../components/DesignSystem';
import './ToolsManagementStep1SelectDestination.scss';

const ToolsManagementStep1SelectDestination = ({
  selectedDestination,
  originData,
  originUrl,
  destinationData,
  nudosWarehousesList,
  updateDestinationDataCallback,
  showSkeletonLoader = false,
  filterCountriesForLogistics
}: {
  originUrl: string;
  showSkeletonLoader?: boolean;
  selectedDestination?: TstoragePlace;
  originData?: IlocationAddressForLogistics;
  destinationData?: TlogisticsOriginOrDestinationData;
  nudosWarehousesList: IwarehouseData[];
  updateDestinationDataCallback: (destinationData: TlogisticsOriginOrDestinationData) => void;
  requireLogistics: boolean;
  loadingWarehousesList?: boolean;
  filterCountriesForLogistics?: ICountryDetail[];
}) => {
  const [specificDestinationDropdownIsFocused, setSpecificDestinationDropdownIsFocused] = useState(false);
  const originCountry = originData?.countryData || originData?.country;
  const originFlag = originCountry?.flag || '';
  const originIsOffice = originData?.place === 'office';
  const originIsWarehouse = originData?.place === 'nudos';

  const destinationIsOffice = selectedDestination === 'office';
  const destinationIsUser = selectedDestination === 'user';
  const destinationIsWarehouse = selectedDestination === 'nudos';
  const destinationFlag = destinationData?.country?.flag || '';
  const pendingDestination = destinationData ? 'pendingDestination' : undefined;
  const pendingDestinationIcon = destinationData ? undefined : (
    <div className="pendingDestinationIcon">
      <IconQuestion />
    </div>
  );

  const getWarehouseDestinationInformation = (place: TstoragePlace) => {
    switch (place) {
      case 'warranty':
        return { cardName: 'Marca', icon: <IconShield className="iconShield" /> };
      case 'diagnostic':
        return { cardName: 'Diagnóstico (Bodegas Nudos)', icon: <IconToolWrench className="iconToolWrench" /> };
      case 'nudos':
        return {
          cardName: `Bodega ${(destinationData as IwarehouseData)?.country?.name || ''}`,
          icon: <IconReserveBox className="iconReserveBox" />
        };
      default:
        return { cardName: '', icon: <IconQuestion className="iconQuestion" /> };
    }
  };

  const getFormattedName = (place: TstoragePlace, data?: TlogisticsOriginOrDestinationData | null) => {
    if (!data) return undefined;
    const dataAsOffice = data as IlocationAddressForLogistics;
    switch (place) {
      case 'user':
        return `${(data as Iemployee)?.firstName || ''} ${(data as Iemployee)?.lastName || ''}`;
      case 'office':
        return `Oficina en ${dataAsOffice?.countryData?.name || dataAsOffice?.country?.name || 'País'}`;
      case 'other':
        return dataAsOffice?.locationName || 'Otra dirección';
      default:
        return getWarehouseDestinationInformation(place)?.cardName;
    }
  };

  const getPhoto = (place: TstoragePlace, data?: TlogisticsOriginOrDestinationData | null) => {
    switch (place) {
      case 'user':
        return (data as Iemployee)?.photoProfile?.replaceAll(' ', '%20') || '';
      default:
        return undefined;
    }
  };

  const getIconInsteadOfPhoto = (place: TstoragePlace, data?: TlogisticsOriginOrDestinationData | null) => {
    const destination = data as Iemployee;
    switch (place) {
      case undefined:
        return pendingDestinationIcon;
      case 'user':
        if (!destination?.photoProfile) {
          return <IconOrangeEmployee />;
        }
        return undefined;
      case 'office':
        return <div className="destinationIcon">{<IconBuilding className="iconBuilding" />}</div>;
      case 'other':
        return <div className="destinationIcon">{<IconPin className="IconPin" />}</div>;
      default:
        return <div className="destinationIcon">{getWarehouseDestinationInformation(place)?.icon}</div>;
    }
  };

  const updateDestinationDataCallbackWithFocusFunctionality = (destinationData: TlogisticsOriginOrDestinationData) => {
    setSpecificDestinationDropdownIsFocused(false);
    updateDestinationDataCallback(destinationData);
  };

  return (
    <div className="ToolsManagementStep1SelectDestination">
      <NudosLogisticsGeneralContainer title="Elige el destino de las herramientas">
        <div className="dropdownsContainer">
          {destinationIsOffice && (
            <NudosLogisticsOfficeDropdown
              componentSize="large"
              updateCurrentValueCallback={updateDestinationDataCallbackWithFocusFunctionality}
              currentValue={destinationData as IlocationAddressForLogistics}
              isFocused={specificDestinationDropdownIsFocused}
              filterCountries={filterCountriesForLogistics}
              currentCountryForExclusion={originIsOffice && originCountry ? originCountry : undefined}
            />
          )}
          {destinationIsUser && (
            <NudosLogisticsEmployeesDropdown
              originUrl={originUrl}
              componentSize="large"
              updateCurrentValueCallback={updateDestinationDataCallbackWithFocusFunctionality}
              currentValue={destinationData as Iemployee}
              isFocused={specificDestinationDropdownIsFocused}
              filterCountries={filterCountriesForLogistics}
              showFlag
              nameCharactersLimit={50}
            />
          )}
          {destinationIsWarehouse && (
            <NudosLogisticsWarehouseDropdown
              componentSize="large"
              updateCurrentValueCallback={updateDestinationDataCallbackWithFocusFunctionality}
              currentValue={destinationData as IwarehouseData}
              warehousesListProp={nudosWarehousesList}
              isFocused={specificDestinationDropdownIsFocused}
              currentCountryForExclusion={originIsWarehouse && originCountry ? originCountry : undefined}
              countryOfOrigin={originCountry?.code}
            />
          )}
        </div>
        <div className="divider" />
        <div className="selectedLocations">
          <NudosLogisticServiceUserHeader
            type={'origin'}
            photoUrl={getPhoto(originData?.place || '', originData)}
            name={getFormattedName(originData?.place || '', originData)}
            flagUrl={originFlag}
            photoJSX={getIconInsteadOfPhoto(originData?.place || '', originData)}
            showSkeletonLoader={showSkeletonLoader}
          />
          <NudosLogisticServiceUserHeader
            customClassName={pendingDestination}
            type={'destination'}
            name={getFormattedName(selectedDestination || '', destinationData)}
            photoUrl={getPhoto(selectedDestination || '', destinationData)}
            flagUrl={destinationFlag}
            photoJSX={getIconInsteadOfPhoto(selectedDestination || '', destinationData)}
            showSkeletonLoader={showSkeletonLoader}
          />
        </div>
      </NudosLogisticsGeneralContainer>
    </div>
  );
};

export default ToolsManagementStep1SelectDestination;
