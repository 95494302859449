import { FC, useEffect, useState } from 'react';
import { NudosButton } from '../../../../../components/NudosComponents';
import useCartState from '../../../../../state/useCartState';
import { useHistory } from 'react-router-dom';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import { updateProofOfPayment } from '../../../../../services/orders';
import validateFile from '../../../../../utils/validateFile';
import { getSpeiBankData, requestOrderSummaryPDF } from '../../../../../services/checkout';
import { DownloadIcon } from '../../../../../assets/images/components/NudosComponents';
import { Tany } from '../../../../../types/global';
import { IBank } from '../../../../../types/checkout';
import { NudosTransfDetails } from '../../../../../components/DesignSystem';
import { segmentTrackEvent } from '../../../../../utils/segment';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import { Trans, useTranslation } from 'react-i18next';
import useStoreCountryState from '../../../../../state/useStoreCountryState';
import './Step4FinishCart.scss';

interface IStep4FinishCart {
  deliveryDayRange?: string;
}

const Step4FinishCart: FC<IStep4FinishCart> = ({ deliveryDayRange }: IStep4FinishCart) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { dataFinishCart, setDataFinishCart } = useCartState();
  const { stateSubscription } = useStateSubscriptions();
  const { storeCountry } = useStoreCountryState();
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [transfMX, setTransfMX] = useState<boolean>();
  const [transfCOP, setTransfCOP] = useState<boolean>();
  const [transfUSD, setTransfUSD] = useState<boolean>();
  const [creditCardCOP, setCreditCardCOP] = useState<boolean>();
  const [nationalCreditCard, setNationalCreditCard] = useState<boolean>();
  const [internationalCreditCard, setInternationalCreditCard] = useState<boolean>();
  const [uploadFile, setUploadFile] = useState<Tany>();
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [SPEIBankData, setSPEIBankData] = useState<IBank>();
  const [loadingBank, setLoadingBank] = useState<boolean>(false);
  const [loadingOrderSummary, setLoadingOrderSummary] = useState(false);
  const [orderSummaryPDFUrl, setOrderSummaryPDFUrl] = useState('');

  const { levelSubscription } = stateSubscription;
  const isPrime = levelSubscription === 'Lite' || levelSubscription === 'Vip';
  const translationKey = 'nodi:billingDataSection:step4FinishCart:';
  const containerTitle = t(`${translationKey}containerTitle`);
  const graySubtitle = t(`${translationKey}graySubtitle`);
  const nudosButtonText = t(`${translationKey}nudosButtonText`);
  const orangeButtonText = t(`${translationKey}orangeButtonText`);
  const chargeFileNotification = t(`${translationKey}chargeFileNotification`);
  const chargeFileErrorFormat = t(`${translationKey}chargeFileErrorFormat`);
  const chargeFileErrorSize = t(`${translationKey}chargeFileErrorSize`);

  const paymentMethodIsStripe =
    dataFinishCart?.currencyCode === 'MXN' && dataFinishCart?.paymentType === 'Tarjeta crédito';
  const transfPEN = paymentMethod === 'Transf. PEN';
  const transfARS = paymentMethod === 'Transf. ARS';
  const displayQuotationButton = !paymentMethodIsStripe && !transfCOP && !transfPEN;
  const displayTransfDetails = transfMX || transfCOP || transfPEN || transfARS || transfUSD;

  const orderId = dataFinishCart?.orderId;
  const orderShippigs = dataFinishCart?.shippings;
  const orderText = t(`${translationKey}containerOrderId`);
  const withWord = t(`nodi:dynamicBanner:with`);
  const shipmentsWord = t(`recurrentWords:shipments`);

  const summary = dataFinishCart?.summary;
  const destinationCountries = summary?.shippingsList?.map(item => item?.countryName);
  const orderTextId = `${orderText} ${orderId} ${withWord} ${orderShippigs} ${shipmentsWord}`;

  const chargeFile = async (file: Tany) => {
    const uploadedFile = file.target.files[0];
    const isFileValid = validateFile(uploadedFile, ['application/pdf'], 2, chargeFileErrorFormat, chargeFileErrorSize);
    if (!isFileValid) return;
    setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', uploadedFile);
    try {
      await updateProofOfPayment(dataFinishCart?.orderId || 0, formData);
      setUploadFile(uploadedFile);
      setLoadingFile(false);
      displaySuccessNotification({
        customJSXMessage: <>{chargeFileNotification}</>
      });
    } catch (error) {
      setLoadingFile(false);
      displayErrorNotification();
    }
  };

  const deleteFile = () => setUploadFile(undefined);
  const resetStates = () => localStorage.setItem('savedStep', '0');

  const initialPaymentMethod = () => {
    if (dataFinishCart && dataFinishCart.paymentType) {
      setPaymentMethod(dataFinishCart?.paymentType);
      resetStates();
    }
    if (!dataFinishCart) {
      resetStates();
      push('/catalogue/home');
    }
  };

  const getInfoBank = async () => {
    setLoadingBank(true);
    if (dataFinishCart?.orderId) {
      try {
        const data = await getSpeiBankData(dataFinishCart.orderId);
        setSPEIBankData(data);
        setLoadingBank(false);
      } catch (error) {
        displayErrorNotification();
        setLoadingBank(false);
      }
    }
  };

  const downloadOrderSummary = async () => {
    if (!dataFinishCart?.orderId) return;
    setLoadingOrderSummary(true);
    try {
      const { url } = await requestOrderSummaryPDF(dataFinishCart?.orderId);
      if (url) {
        setOrderSummaryPDFUrl(url);
        window.open(url, 'blank');
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingOrderSummary(false);
    }
  };

  const handleClickRequestQuotation = () => {
    if (orderSummaryPDFUrl) window.open(orderSummaryPDFUrl, 'blank');
    else downloadOrderSummary();
  };

  useEffect(() => {
    if (paymentMethod === 'Transf. MXN') {
      setTransfMX(true);
      getInfoBank();
    }
    if (paymentMethod === 'Transf. USD') {
      setTransfUSD(true);
      getInfoBank();
    }
    if (paymentMethod === 'Tarjeta crédito') {
      setNationalCreditCard(true);
    }
    if (paymentMethod === 'Tarjeta internacional') {
      setInternationalCreditCard(true);
    }
    if (paymentMethod === 'Tarjeta crédito COP') {
      setCreditCardCOP(true);
    }
    if (paymentMethod === 'Transf. COP') {
      setTransfCOP(true);
    }
  }, [paymentMethod]);

  useEffect(() => {
    initialPaymentMethod();
  }, [dataFinishCart]);

  useEffect(() => {
    return () => setDataFinishCart(undefined);
  }, []);

  useEffect(() => {
    segmentTrackEvent({
      [!isPrime ? 'free4CheckoutResumeView' : 'prime4CheckoutResumeView']: {
        PaymentMethods: dataFinishCart?.paymentType,
        DestinationCountry: destinationCountries
      }
    });
  }, []);

  return (
    <div id="step4FinishCart">
      <div className="boxFinishCart">
        <div
          className={`containerInfo ${
            nationalCreditCard || creditCardCOP
              ? 'extraPadding'
              : internationalCreditCard
              ? 'internationalExtraPadding'
              : ''
          }`}
        >
          <div className="containerTitle">{containerTitle}</div>
          <div className="containerOrderId" style={{ marginBottom: internationalCreditCard ? '32px' : '' }}>
            {orderTextId}
          </div>
          {nationalCreditCard && (
            <div className="confirmationSubBlack">
              <Trans i18nKey={t(`${translationKey}confirmationSubBlack`)} components={{ 1: <br /> }} />
            </div>
          )}
          {(internationalCreditCard || creditCardCOP) && (
            <div className="confirmationSub font-medium">
              <Trans i18nKey={t(`${translationKey}confirmationSub`)} components={{ 1: <br /> }} />
            </div>
          )}
          {(transfMX || transfCOP) && (
            <div className="containerInfoAccount">
              <Trans i18nKey={t(`${translationKey}containerInfoAccountMx`)} components={{ 1: <br /> }} />
            </div>
          )}
          {(transfUSD || transfARS) && (
            <>
              <div className="containerInfoAccount">
                <Trans
                  i18nKey={t(`${translationKey}containerInfoAccountArs`)}
                  components={{ 1: <span className="text-nudos-orange font-bold"></span>, 2: <br /> }}
                />
              </div>
            </>
          )}
          {displayTransfDetails && (
            <div className="boxAccount">
              <NudosTransfDetails
                SPEIBankData={SPEIBankData}
                loading={loadingBank}
                chargeFile={file => chargeFile(file)}
                uploadFile={uploadFile}
                deleteFile={deleteFile}
                loadingFile={loadingFile}
              />
            </div>
          )}
          <div className="orangeSubtitle">
            Realiza el pago para confirmar tu orden. En caso de tener <br /> acuerdo de pago, se confirma
            automáticamente{' '}
          </div>
          <div className="dateContainer">
            <div className="blackTitle">Entrega estimada: </div>
            <div className="flagAndDaysContianer">
              {storeCountry && (
                <div className="flagContainer">
                  <div className="flagBg" style={{ backgroundImage: `url("${storeCountry?.countryFlag}")` }} />
                </div>
              )}
              {`${storeCountry ? storeCountry?.countryName : '📆'}: ${deliveryDayRange} días hábiles`}{' '}
            </div>
          </div>
          <div className="graySubtitle">{graySubtitle}</div>
          {displayQuotationButton && (
            <NudosButton
              colorPalette="blue"
              customHeight="28px"
              customWidth="166px"
              buttonText={nudosButtonText}
              buttonIcon={<DownloadIcon className="downloadIcon" />}
              customClassName="requestOrderSummaryButton"
              handleClick={handleClickRequestQuotation}
              isButtonLoading={loadingOrderSummary}
              isButtonDisabled={loadingOrderSummary}
            />
          )}
          <div className="containerButtons">
            <div className="orangeButton">
              <NudosButton
                handleClick={() => {
                  if (dataFinishCart?.orderId) {
                    push(`/nodi/orders/details/${dataFinishCart?.orderId}`);
                  }
                }}
                buttonText={orangeButtonText}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4FinishCart;
