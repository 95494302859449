import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={8} height={8} fill={props.fill} xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_1775_91684)">
      <path
        d="M0.548635 5.86764C0.636711 6.6909 1.29883 7.35302 2.12166 7.44473C3.38554 7.58559 4.61473 7.58559 5.8786 7.44473C6.70145 7.35302 7.36357 6.6909 7.45163 5.86764C7.51665 5.25992 7.57157 4.636 7.57157 3.99991C7.57157 3.36383 7.51665 2.73991 7.45163 2.13219C7.36357 1.30896 6.70145 0.646839 5.8786 0.555122C4.61473 0.414243 3.38554 0.414243 2.12166 0.555122C1.29883 0.646839 0.636711 1.30896 0.548635 2.13219C0.483616 2.73991 0.428711 3.36383 0.428711 3.99991C0.428711 4.636 0.483616 5.25992 0.548635 5.86764Z"
        fill="white"
        stroke={props?.stroke || '#FF664E'}
      />
      <path d="M5.01005 2.98975L2.98975 5.01005" stroke={props?.stroke || '#FF664E'} strokeLinecap="round" />
      <path d="M5.01005 5.01005L2.98975 2.98975" stroke={props?.stroke || '#FF664E'} strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_1775_91684">
        <rect width="8" height="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CloseFile = memo(ForwardRef);
export default CloseFile;
