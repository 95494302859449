import {
  MenuDashBoardIcon,
  MenuEmployeesIcon,
  MenuToolsIcon,
  MenuOrdersIcon,
  MenuServicesIcon,
  MenuFaqsIcon,
  MenuTeamsIcon,
  MenuAnalyticsIcon,
  MenuPerksIcon,
  MenuIntegrationsIcon,
  MenuMDMIcon
} from '../assets/images/components';
import { IModuleProperties } from '../types/global';

export const menuModules: IModuleProperties[] = [
  {
    name: 'dashboard',
    alias: 'Dashboard',
    path: 'dashboard',
    menuIcons: {
      activeIcon: <MenuDashBoardIcon stroke="white" />,
      inactiveIcon: <MenuDashBoardIcon stroke="#999999" />,
      freeIcon: <MenuDashBoardIcon stroke="#CCCCCC" />,
      constructionIcon: <MenuDashBoardIcon stroke="#ECECEC" />,
      hoverIcon: <MenuDashBoardIcon stroke="#FF664E" />
    },
    isDeactivated: false,
    isDisabled: false
  },
  {
    name: 'employees',
    alias: 'nodi:nodiMenu:employees',
    path: 'employees',
    menuIcons: {
      activeIcon: <MenuEmployeesIcon stroke="white" fill="#FF664E" />,
      inactiveIcon: <MenuEmployeesIcon />,
      freeIcon: <MenuEmployeesIcon stroke="#CCCCCC" />,
      constructionIcon: <MenuEmployeesIcon />,
      hoverIcon: <MenuEmployeesIcon stroke="#FF664E" />
    },
    isDeactivated: false,
    isDisabled: false,
    disabledOnFreeSubscription: false
  },
  {
    name: 'inventory',
    alias: 'nodi:nodiMenu:inventory',
    path: ['tools', 'assignation', 'details', 'upload-external-tools'],
    forceRedirect: '/nodi/tools',
    menuIcons: {
      activeIcon: <MenuToolsIcon fill="white" />,
      inactiveIcon: <MenuToolsIcon />,
      freeIcon: <MenuToolsIcon fill="#CCCCCC" />,
      constructionIcon: <MenuToolsIcon />,
      hoverIcon: <MenuToolsIcon fill="#FF664E" />
    },
    isDeactivated: false,
    isDisabled: true,
    disabledOnFreeSubscription: false
  },

  {
    name: 'orders',
    alias: 'nodi:nodiMenu:orders',
    path: 'orders',
    menuIcons: {
      activeIcon: <MenuOrdersIcon fill="#FF664E" stroke="white" />,
      inactiveIcon: <MenuOrdersIcon />,
      freeIcon: <MenuOrdersIcon />,
      constructionIcon: <MenuOrdersIcon />,
      hoverIcon: <MenuOrdersIcon fill="white" stroke="#FF664E" />
    },
    isDeactivated: false,
    isDisabled: false
  },

  {
    name: 'logServices',
    alias: 'nodi:nodiMenu:logServices',
    path: 'logistics-services',
    menuIcons: {
      activeIcon: <MenuServicesIcon fill="white" />,
      inactiveIcon: <MenuServicesIcon />,
      freeIcon: <MenuServicesIcon fill="#CCCCCC" />,
      constructionIcon: <MenuServicesIcon />,
      hoverIcon: <MenuServicesIcon fill="#FF664E" />
    },
    disabledOnFreeSubscription: false,
    isDeactivated: false,
    isDisabled: false
  },
  {
    name: 'Teams',
    path: '',
    isDeactivated: false,
    isDisabled: false,
    menuIcons: {
      activeIcon: <MenuTeamsIcon />,
      inactiveIcon: <MenuTeamsIcon />,
      freeIcon: <MenuTeamsIcon />,
      constructionIcon: <MenuTeamsIcon stroke="#CCCCCC" />,
      hoverIcon: <MenuTeamsIcon />
    },
    moduleUnderConstruction: true,
    coming: {
      title: 'nodi:nodiMenu:comingSoon',
      subtitle: 'nodi:nodiMenu:teamsSubtitle'
    }
  },
  {
    name: 'nodi:nodiMenu:analytics:name',
    path: '',
    isDeactivated: false,
    isDisabled: false,
    menuIcons: {
      activeIcon: <MenuAnalyticsIcon />,
      inactiveIcon: <MenuAnalyticsIcon />,
      freeIcon: <MenuAnalyticsIcon />,
      constructionIcon: <MenuAnalyticsIcon stroke="#CCCCCC" />,
      hoverIcon: <MenuAnalyticsIcon />
    },
    moduleUnderConstruction: true,
    coming: {
      title: 'nodi:nodiMenu:comingSoon',
      subtitle: 'nodi:nodiMenu:analytics:subtitle'
    }
  },
  {
    name: 'Perks',
    path: '',
    isDeactivated: false,
    isDisabled: false,
    menuIcons: {
      activeIcon: <MenuPerksIcon />,
      inactiveIcon: <MenuPerksIcon />,
      freeIcon: <MenuPerksIcon />,
      constructionIcon: <MenuPerksIcon stroke="#CCCCCC" />,
      hoverIcon: <MenuPerksIcon />
    },
    moduleUnderConstruction: true,
    coming: {
      title: 'nodi:nodiMenu:comingSoon',
      subtitle: 'nodi:nodiMenu:perksSubtitle'
    }
  },
  {
    name: 'nodi:nodiMenu:integrations:name',
    path: '',
    isDeactivated: false,
    isDisabled: false,
    menuIcons: {
      activeIcon: <MenuIntegrationsIcon />,
      inactiveIcon: <MenuIntegrationsIcon />,
      freeIcon: <MenuIntegrationsIcon />,
      constructionIcon: <MenuIntegrationsIcon stroke="#CCCCCC" />,
      hoverIcon: <MenuIntegrationsIcon />
    },
    moduleUnderConstruction: true,
    coming: {
      title: 'nodi:nodiMenu:comingSoon',
      subtitle: 'nodi:nodiMenu:integrations:subtitle'
    }
  },
  {
    name: 'MDM',
    path: '',
    isDeactivated: false,
    isDisabled: false,
    menuIcons: {
      activeIcon: <MenuMDMIcon />,
      inactiveIcon: <MenuMDMIcon />,
      freeIcon: <MenuMDMIcon />,
      constructionIcon: <MenuMDMIcon stroke="#CCCCCC" />,
      hoverIcon: <MenuMDMIcon />
    },
    moduleUnderConstruction: true,
    coming: {
      title: 'nodi:nodiMenu:comingSoon',
      subtitle: 'nodi:nodiMenu:mdmSubtitle'
    }
  },
  {
    name: 'FAQs',
    path: 'faqs',
    menuIcons: {
      activeIcon: <MenuFaqsIcon fill="#FF664E" stroke="white" />,
      inactiveIcon: <MenuFaqsIcon />,
      freeIcon: <MenuFaqsIcon />,
      constructionIcon: <MenuFaqsIcon />,
      hoverIcon: <MenuFaqsIcon fill="white" stroke="#FF664E" />
    },
    isDeactivated: false,
    isDisabled: false
  }
];
