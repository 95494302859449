import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M5.68902 8.36741L2.12874 4.70066C1.95709 4.52388 1.95709 4.2371 2.12874 4.06032L2.54405 3.63261C2.71553 3.45602 2.99326 3.45583 3.1651 3.63186L5.99991 6.5379L8.8349 3.63204C9.00674 3.45583 9.28447 3.45621 9.45595 3.6328L9.87126 4.06051C10.0429 4.23729 10.0429 4.52406 9.87126 4.70085L6.3108 8.36741C6.13914 8.5442 5.86068 8.5442 5.68902 8.36741Z"
      fill={props?.fill || '#ff664e'}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const ArrowsFilter = memo(ForwardRef);
export default ArrowsFilter;
