import create from 'zustand';
import { IExistingEmployeeInfo } from '../types/global';

export interface iState {
  items: number;
  employeesCount?: number;
  employeeToAssign?: IExistingEmployeeInfo;
  employeeParamsFilters?: string;
  employeeSearchbarText?: string;
  increaseItems: () => void;
  removeAllItems: () => void;
  setEmployeeToAssign: (employeeToAssign?: IExistingEmployeeInfo) => void;
  setEmployeesCount: (employeesCount: number) => void;
  setEmployeeSearchbarText: (employeeSearchbarText: string) => void;
  setEmployeeParamsFilters: (employeeParamsFilters: string) => void;
}
const useStore = create<iState>(set => ({
  items: 0,
  employeesCount: undefined,
  employeeToAssign: undefined,
  employeeParamsFilters: '',
  employeeSearchbarText: '',
  increaseItems: () => set(state => ({ items: state.items + 1 })),
  removeAllItems: () => set({ items: 0 }),
  setEmployeeToAssign: employeeToAssign => set({ employeeToAssign }),
  setEmployeesCount: employeesCount => set({ employeesCount }),
  setEmployeeSearchbarText: employeeSearchbarText => set({ employeeSearchbarText }),
  setEmployeeParamsFilters: employeeParamsFilters => set({ employeeParamsFilters })
}));

export default useStore;
