import { useEffect, useState } from 'react';
import { IbillingData, SPECIAL_BUSINESS_NAME_TYPES } from '../../../types/checkout';
import { getOrganizationAccount } from '../../../services/account';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { NudosButton, NudosHoverText } from '../../NudosComponents';
import { CameraIcon } from '../../../assets/images/components/NudosComponents';
import { useTranslation } from 'react-i18next';
import NudosStaticAlertInComponent from '../NudosStaticAlertInComponent/NudosStaticAlertInComponent';
import useStoreCountryState from '../../../state/useStoreCountryState';
import { useLocation } from 'react-router-dom';
import './NudosBillingDataSummaryComponent.scss';

const NudosBillingDataSummaryComponent = ({
  billingData,
  openBillingDataModal,
  cleanUpdatedBillingData
}: {
  billingData?: IbillingData;
  openBillingDataModal?: () => void;
  cleanUpdatedBillingData?: () => void;
}) => {
  const { t } = useTranslation();
  const orgData = getOrgData();
  const { storeCountry } = useStoreCountryState();
  const [organizationImg, setOrganizationImg] = useState<string>();
  const { pathname } = useLocation();

  const flag = billingData?.documentCountry?.flag.replace(/\s/g, '%20') || '';
  const businessName = billingData?.businessName || '';
  const translationKey = 'designSystemComponents:billingDataSummaryComponent:';
  const billingEmail = t(`${translationKey}billingEmail`);
  const nudosButtonText = t(`${translationKey}nudosButtonText`);
  const changeBillingDataButton = t(`${translationKey}changeBillingDataButton`);
  const isCheckoutFlux = pathname.includes('catalogue');

  const displaySpecialBusinessAlert =
    billingData?.specialBusinessNameType?.id === SPECIAL_BUSINESS_NAME_TYPES?.FREE_ZONE &&
    storeCountry?.countryCode === 'uy' &&
    isCheckoutFlux;

  const getInfoCompany = async () => {
    const infoCompany = await getOrganizationAccount(orgData.organizationId);
    setOrganizationImg(infoCompany?.image || '');
  };

  const noProfilePhotoUrl = {
    backgroundImage: `url('${CameraIcon}')`
  };

  useEffect(() => {
    getInfoCompany();
  }, []);

  return (
    <div className="NudosBillingDataSummaryComponent">
      <div className="billingDataSummaryContainer">
        <div className="userInformation">
          <div className="photoAndFlag">
            {organizationImg && <div className="photo" style={{ backgroundImage: `url("${organizationImg}")` }} />}
            {!organizationImg && <div className="noPhoto" style={noProfilePhotoUrl} />}
            {flag && <div className="flag" style={{ backgroundImage: `url(${flag})` }} />}
          </div>
          <div className="billingNameAndEmailContainer">
            <NudosHoverText onlyIfTruncated customClassName="userName" text={businessName} charactersLimit={15} />
            {billingData?.document}
          </div>
        </div>
        <div className="billingRightSection">
          <div className="billingEmail">{billingEmail}</div>
          {billingData?.email}
        </div>
      </div>
      <NudosButton
        buttonText={nudosButtonText}
        handleClick={openBillingDataModal}
        customClassName="openModalText"
        customFontStyles={{ fontSize: '10px' }}
      />
      <div className="changeBillingDataButton" onClick={cleanUpdatedBillingData}>
        {changeBillingDataButton}
      </div>
      {displaySpecialBusinessAlert && (
        <NudosStaticAlertInComponent
          customClassname="specialBusinessAlert"
          type="information"
          color="blue"
          alertOptions={{
            title: 'Tu razón social fue marcada como Zona Franca, por tanto no cobraremos el 22% de impuestos'
          }}
        />
      )}
    </div>
  );
};

export default NudosBillingDataSummaryComponent;
