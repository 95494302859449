import { PAYMENT_TYPES } from '../types/checkout';
import { BILLING_METHODS } from '../types/orders';
import GenericFetchError from './GenericFetchError';

export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development' || window.location.host.includes('.dev');
export const isLocal = process.env.REACT_APP_MODE === 'local';

export const BASE_URL: string = isLocal ? 'http://localhost:3000/' : '';
export const usersAPI = process.env.REACT_APP_API_USERS;
export const orgAPI = process.env.REACT_APP_API;
export const catalogAPI = process.env.REACT_APP_CATALOG;
export const subscriptionAPI = process.env.REACT_APP_SUBSCRIPTION;
export const cartAPI = process.env.REACT_APP_CART;
export const fileGeneratorAPI = process.env.REACT_APP_FILE_GENERATOR;
export const crispKey = process.env.REACT_APP_CRISP_KEY;
export const stripeMX = process.env.REACT_APP_STRIPE_PUBLIC_KEY_MX;
export const stripeUSAC = process.env.REACT_APP_STRIPE_PUBLIC_KEY_USA_C_CORP;
export const stripeUSAL = process.env.REACT_APP_STRIPE_PUBLIC_KEY_USA_LLC;

export const dateTimeSQLFormat = 'yyyy-LL-dd, HH:mm';
export const emailPattern =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const verifyFirebaseOtpErrors = [
  {
    errorCode: 'auth/code-expired',
    displayMessage:
      'Has excedido el número de intentos o el tiempo límite. Por favor comunícate con soporte para brindarte ayuda.'
  },
  {
    errorCode: 'auth/invalid-verification-code',
    displayMessage: 'Código inválido, ingrésalo nuevamente.'
  }
];

export const sendFirebaseOtpErrors = [
  {
    errorCode: 'auth/quota-exceeded',
    displayMessage: 'Has excedido el número de intentos. Por favor comunícate con soporte para brindarte ayuda.'
  }
];

export const signUpSubmitErrors = [
  { errorCode: 'The given document already exists in DB', correspondingNotification: 'companyAlreadyRegistered' },
  { errorCode: 'The uid, email or phone already exists', correspondingNotification: 'userAlreadyRegistered' },
  { errorCode: 'The Business Name already exists in DB', correspondingNotification: 'businessNameAlreadyRegistered' },
  { errorCode: 'auth/invalid-phone-number', correspondingNotification: 'invalidPhoneNumber' }
];

export const genericErrorMessage = 'Ha habido un problema, comunícate con soporte para obtener ayuda.';

export const ALOGLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
export const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
export const ALGOLIA_INDEX_NAME = process.env.REACT_APP_ALGOLIA_INDEX_NAME;

export const VALID_AND_INTEGRATED_METHODS_TO_OPEN_THE_STRIPE_MODAL = [
  PAYMENT_TYPES.CARD_MXN,
  PAYMENT_TYPES.INTERNATIONAL_CARD
];
export const VALID_AND_INTEGRATED_TRANSFER_METHODS = [PAYMENT_TYPES.TRANSFER_MXN, PAYMENT_TYPES.TRANSFER_USD];
export const BILLING_METHODS_WITH_INTEGRATED_PAYMENTS = [
  // CAMBIAR-JC: Once the LLC business name is approved, this two billing metholds should be uncommented
  // BILLING_METHODS.LOCAL_SALE_TRADING_ENTITY_URUGUAY,
  // BILLING_METHODS.INTERNATIONAL_SALE,
  BILLING_METHODS.LOCAL_SALE_MEXICO,
  BILLING_METHODS.LOCAL_SALE_USA
];

export const TYPE_OF_LANGUAGES = {
  esMX: 'esMX',
  enUS: 'enUS'
} as const;

export { GenericFetchError };
