import { useEffect, useState } from 'react';
import { NudosButton, NudosHoverText } from '../../../../../components/NudosComponents';
import { IorderDetailsBillingData } from '../../../../../types/orders';
import './OrderBillingData.scss';
import UpdateOrderBillingDataFlux from '../UpdateOrderBillingDataFlux/UpdateOrderBillingDataFlux';
import { ICountryDetail } from '../../../../../types/countries';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { getCountryById } from '../../../../../services/countries.service';
import { useTranslation } from 'react-i18next';
const OrderBillingData = ({
  orderId,
  billingData,
  updateOrderData,
  disableEditingOfBillingData,
  billingCountryId = 3, // México in production
  loading
}: {
  orderId: number | string;
  billingData: IorderDetailsBillingData;
  updateOrderData: () => void;
  disableEditingOfBillingData: boolean;
  billingCountryId?: number;
  loading?: boolean;
}) => {
  const { t } = useTranslation();
  const [showUpdateOrderBillingDataModal, setShowUpdateOrderBillingDataModal] = useState(false);
  const [billingCountryData, setBillingCountryData] = useState<ICountryDetail>();
  const i18nLanguageKey = 'nodi:orderDetails:orderBillingData:';

  const noDataText = 'Sin datos';
  const businessName = billingData?.businessName || noDataText;
  const document = billingData?.document || noDataText;
  const state = billingData?.state || noDataText;
  const city = billingData?.city || noDataText;
  const address = billingData?.address || noDataText;
  const additionalReferences = billingData?.additionalReferences || noDataText;
  const zipCode = billingData?.zipCode || noDataText;

  const handleOpenUpdateBillingDataModal = () => setShowUpdateOrderBillingDataModal(true);

  const getCountryBillingData = async () => {
    try {
      const newCountryBillingData = await getCountryById(billingCountryId);
      setBillingCountryData(newCountryBillingData);
    } catch (error) {
      displayErrorNotification();
    }
  };

  useEffect(() => {
    billingCountryId && getCountryBillingData();
  }, [billingCountryId]);

  return (
    <>
      {!loading && (
        <div className="orderBillingData">
          <h2 className="sectionTitle">{t(`${i18nLanguageKey}sectionTitle`)}</h2>
          <div className="sectionBody">
            <div className="billingDataLine line1">
              <div className="billingDataField businessName">
                <span className="billingDataLineTitle">{t(`${i18nLanguageKey}businessName`)}</span>
                <NudosHoverText onlyIfTruncated text={businessName} charactersLimit={25} />
              </div>
              <div className="billingDataField taxDocument relative">
                <span className="billingDataLineTitle">{t(`${i18nLanguageKey}businessId`)}</span>
                <NudosHoverText onlyIfTruncated text={document} charactersLimit={12} />
                {billingData?.documentCountry?.flag && (
                  <div className="floatFlag">
                    <div className="containerFloatFlag">
                      <div
                        className="bg-cover bg-center w-full h-full bg-no-repeat rounded-sm"
                        style={{
                          backgroundImage: `url(${billingData?.documentCountry?.flag})`
                        }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="billingDataLine line2">
              <div className="billingDataField regionOrState">
                <span className="billingDataLineTitle">{t(`${i18nLanguageKey}regionOrState`)}</span>
                <NudosHoverText onlyIfTruncated text={state} charactersLimit={32} />
              </div>
              <div className="billingDataField city">
                <span className="billingDataLineTitle">{t(`${i18nLanguageKey}city`)}</span>
                <NudosHoverText onlyIfTruncated text={city} charactersLimit={32} />
              </div>
            </div>
            <div className="billingDataLine line3">
              <div className="billingDataField zipCode">
                <span className="billingDataLineTitle">{t(`${i18nLanguageKey}zipCode`)}</span>
                <NudosHoverText onlyIfTruncated text={zipCode} charactersLimit={25} />
              </div>
              <div className="billingDataField addressAdditionalReferences">
                <span className="billingDataLineTitle">{t(`${i18nLanguageKey}addressAdditionalReferences`)}</span>
                <NudosHoverText onlyIfTruncated text={additionalReferences} charactersLimit={25} />
              </div>
            </div>
            <div className="billingDataLine line4">
              <div className="billingDataField address">
                <span className="billingDataLineTitle">{t(`${i18nLanguageKey}address1`)}</span>
                <NudosHoverText onlyIfTruncated text={billingData?.email || ''} charactersLimit={55} />
              </div>
            </div>
            <div className="billingDataLine line4">
              <div className="billingDataField address">
                <span className="billingDataLineTitle">{t(`${i18nLanguageKey}address2`)}</span>
                <NudosHoverText onlyIfTruncated text={address} charactersLimit={55} />
              </div>
            </div>
            <NudosButton
              customClassName="editBillingDataButton"
              buttonText={t(`${i18nLanguageKey}nudosButton`)}
              customWidth="80px"
              customHeight="18px"
              handleClick={handleOpenUpdateBillingDataModal}
              isButtonDisabled={disableEditingOfBillingData}
            />
          </div>
          <div className="whiteSpaceAtTheBottom w-10 h-10" />
        </div>
      )}
      {loading && (
        <div className="orderBillingDataSkeletonLoader orderBillingData">
          <div className="sectionTitle animationLoader" />
          <div className="sectionBody animationLoader" />
          <div className="whiteSpaceAtTheBottom w-10 h-10" />
        </div>
      )}
      {showUpdateOrderBillingDataModal && (
        <UpdateOrderBillingDataFlux
          initialData={billingData}
          billingCountryData={billingCountryData}
          setShowUpdateOrderBillingDataModal={setShowUpdateOrderBillingDataModal}
          updateOrderData={updateOrderData}
          orderId={orderId}
        />
      )}
    </>
  );
};

export default OrderBillingData;
