import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} fill="none" ref={ref} {...props}>
    <path
      fill="#fff"
      d="M14.828 10.035V3.368a1.334 1.334 0 0 0-1.333-1.333H4.16a1.333 1.333 0 0 0-1.333 1.333v9.334a1.333 1.333 0 0 0 1.333 1.333h6.667m4-4v.115c0 .353-.14.692-.39.942l-2.553 2.553c-.25.25-.589.39-.942.39h-.115m4-4h-2.666a1.333 1.333 0 0 0-1.334 1.333v2.667"
    />
    <path
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.828 10.035V3.368a1.334 1.334 0 0 0-1.333-1.333H4.16a1.333 1.333 0 0 0-1.333 1.333v9.334a1.333 1.333 0 0 0 1.333 1.333h6.667m4-4v.115c0 .353-.14.692-.39.942l-2.553 2.553c-.25.25-.589.39-.942.39h-.115m4-4h-2.666a1.333 1.333 0 0 0-1.334 1.333v2.667M5.492 4.702h6.667M5.492 7.37h6.667m-6.667 2.667H8.16"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconNoteOnPaper = memo(ForwardRef);
export default IconNoteOnPaper;
