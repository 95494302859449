import { useTranslation } from 'react-i18next';
import './LogisticsStatusDisplay.scss';

/**
 * @property {string} statusStringId - Required, the status string id of the order status translation
 * @property {string} statusName - Required, the status name of the order
 */
const LogisticsStatusDisplay = ({ statusStringId, statusName }: { statusStringId?: string; statusName: string }) => {
  const { t } = useTranslation();

  const translationKey = 'services:logisticServiceStatus:';
  const statusText = statusStringId ? t(`${translationKey}${statusStringId}`) : statusName;

  const getStatusColor = (status?: string) => {
    switch (status) {
      case 'por confirmar':
        return 'pendingColor';
      case 'confirmado':
        return 'defaultColor';
      case 'confirmada':
        return 'defaultColor';
      case 'activo':
        return 'activeColor';
      case 'activa':
        return 'activeColor';
      case 'entregado':
        return 'deliveredColor';
      case 'entregada':
        return 'deliveredColor';
      case 'cancelado':
        return 'canceledColor';
      case 'cancelada':
        return 'canceledColor';
      default:
        return 'defaultColor';
    }
  };
  return (
    <div className="logisticsStatusDisplay">
      <div className={`statusCircle ${getStatusColor(statusName)}`} />
      <div className="statusText">{statusText}</div>
    </div>
  );
};
export default LogisticsStatusDisplay;
