import { useHistory } from 'react-router-dom';
import { emptyCartIcon } from '../../assets/images/components/foatCartDetail';
import useEcommerceControler from '../../views/ecommerce/ecommerce.controller';
import useCartState from '../../state/useCartState';
import { productsOnly } from '../../utils/cart';
import { nudosFormatCurrency } from '../../utils/formatNumbers';
import { IProductsCart } from '../../types/cart';
import useStateSubscriptions from '../../state/useStateSubscriptions';
import { calculateLowerMultiple } from '../../utils/calculateLowerMultiple';
import { useTranslation } from 'react-i18next';
import './FloatCartDetails.scss';

const FloatCartDetail = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { orgData } = useEcommerceControler();
  const { setOpenCart } = useCartState();
  const productsList = productsOnly();
  const myElement = document.getElementById('icon-cart-header');
  const positionIcon = myElement ? myElement.getBoundingClientRect() : undefined;
  const { stateSubscription } = useStateSubscriptions();

  const { levelSubscription } = stateSubscription;
  const isFree = levelSubscription === 'Free';
  const translationKey = 'nodi:checkout:floatCartDetail:';

  const redirectCart = () => {
    if (orgData?.organizationState === 'active') {
      setOpenCart(false);
      push('/catalogue/checkout');
    }
  };

  const calcPrice = (reference: IProductsCart) => {
    if (reference && reference.unitPrice && reference.quantity) {
      const price = Number(reference.unitPrice || 0);
      const quantity = Number(reference.quantity || 0);
      const subtotal = price * quantity;
      const freeTotal = calculateLowerMultiple(subtotal);
      if (price >= 170 && isFree) {
        const freePrice = `${nudosFormatCurrency({ value: freeTotal, maximumFractionDigits: 2 })}`;
        return freePrice;
      }
      const primePrice = `${nudosFormatCurrency({ value: subtotal, maximumFractionDigits: 2 })}`;
      return primePrice;
    } else {
      return `$0 USD`;
    }
  };
  return (
    <>
      {productsList.length > 0 ? (
        <div
          id="FloatCartDetail"
          style={{
            top: positionIcon?.y ? positionIcon?.y + 5 : '',
            left: positionIcon?.x ? positionIcon.x : ''
          }}
          onMouseLeave={() => setOpenCart(false)}
        >
          <div className="superiorButton" style={{ height: positionIcon?.height }}>
            <div
              className="falseButton"
              onClick={() => redirectCart()}
              style={{ height: positionIcon?.height, width: positionIcon?.width }}
            />
            <div
              className="dashboarButton"
              onClick={() => {
                setOpenCart(false);
                push('/nodi/dashboard');
              }}
            />
          </div>
          <div className="superiorContainer">
            <div className="containerAllItems">
              <div className="titleAllItems">{t(`${translationKey}titleAllItems`)}</div>
              {productsList &&
                productsList.length &&
                productsList.map((item, index) => {
                  if (index < 2) {
                    return (
                      <div
                        key={`cart-item-${item.productId}`}
                        className="individualItem"
                        onClick={() => redirectCart()}
                      >
                        <div className="quantityContainer">{item.quantity}x</div>
                        <div className="nameContainer">{item.productName}</div>
                        <div className="flagContainer">
                          <div
                            className="sizeImgFlagProduct"
                            style={{ backgroundImage: `url(${item?.countryFlag})` }}
                          />
                        </div>
                        <div className="priceContainer">
                          {calcPrice(item)}
                          <span className="font-normal">&nbsp;USD</span>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              {productsList && productsList.length > 2 && (
                <div className="viewAllDetails" onClick={() => redirectCart()}>
                  {t(`${translationKey}viewAllDetails`)}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          id="NoFloatCartItems"
          style={{
            top: positionIcon?.y ? positionIcon?.y + 35 : '',
            left: positionIcon?.x ? positionIcon.x : ''
          }}
        >
          <div className="containerItems">
            <div className="titleNoItems">{t(`${translationKey}titleAllItems`)}</div>
            <div className="emptyCart">
              <img className="sizeCartIcon" alt="empty-cart-icon" src={emptyCartIcon} />
              <div className="textEmptyCart">{t(`${translationKey}textEmptyCart`)}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FloatCartDetail;
