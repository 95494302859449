import { Link } from 'react-router-dom';
import { NudosButton } from '../../components/NudosComponents';
import productNotFound from '../../assets/images/views/productNotFound/productNotFound.webp';
import { useTranslation } from 'react-i18next';
import { supportUrl } from '../../utils/productDefinitions';
import './ProductNotFoundView.scss';

const ProductNotFoundView = () => {
  const { t } = useTranslation();

  const translationKey = 'nodi:checkout:productNotFoundView:';
  const handleClickForHelp = () => window.open(supportUrl);

  return (
    <div className="ProductNotFoundView">
      <img className="productNotFoundImage" src={productNotFound} />
      <div className="notFoundTitle">{t(`${translationKey}notFoundTitle`)}</div>
      <div className="notFoundSubtitle">{t(`${translationKey}notFoundSubtitle`)}</div>
      <div className="buttonsContainer">
        <NudosButton
          buttonText={t(`${translationKey}supportButton`)}
          handleClick={handleClickForHelp}
          colorPalette="secondary"
          componentSize="small"
        />
        <Link to={'home'}>
          <NudosButton customClassName="notFoundButton" buttonText={t(`${translationKey}notFoundButton`)} />
        </Link>
      </div>
    </div>
  );
};

export default ProductNotFoundView;
