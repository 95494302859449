import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { AddIcon, DeleteIcon, RemoveIcon } from '../../../../assets/images/components/countryPrices';
import { IProductUpdate } from '../../../../types/cart';
import { debounce } from '../../../../utils/debounce';
import { deleteProductCart, getShoppingCart, updateProductCart } from '../../../../services/cart.services';
import { getOrgData } from '../../../../utils/getLocalStorageData';
import {
  addProductCart,
  deleteProduct,
  editInputQuantity,
  firstProduct,
  removeProductCard
} from '../../../../utils/cart';
import useShopStore from '../../../../state/useShopContext';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../utils/displayNudosStandardNotifications';
import { NudosPopover } from '../../../../components/NudosComponents';
import useStateSubscriptions from '../../../../state/useStateSubscriptions';
import { useTranslation } from 'react-i18next';
import useStoreCountryState from '../../../../state/useStoreCountryState';

import './AddingSubtractingInputButton.scss';

/**
 * @property { number } productId
 * @property { string } productName
 * @property { string } brandImage
 * @property { string } image - A string with the url for the product image
 * @property { ObjectKey<string> } pills - An object with the pills of the product
 * @property { string } countryFlag - A string with the url for the country falg
 * @property { string } countryName
 * @property { number } countryId
 * @property { number | string } priceUsd - The price by unit of the product
 * @property { number } initialQuantity - The initial number of items for the product, will be shown as the initial input value
 */
const AddingSubtractingInputButton = ({ itemData }: { itemData: IProductUpdate }) => {
  const {
    productId,
    countryNameStringId,
    image,
    productName,
    brandImage,
    pills,
    unitPrice: priceUsd,
    quantity: initialQuantity,
    countryId,
    countryName,
    countryFlag,
    prices,
    brandName,
    category
  } = itemData;

  const { t } = useTranslation();
  const orgInfo = getOrgData();
  const { itemsCart, setCart } = useShopStore();
  const { stateSubscription } = useStateSubscriptions();
  const { storeCountry } = useStoreCountryState();
  const { levelSubscription } = stateSubscription;
  const isPrime = levelSubscription === 'Lite' || levelSubscription === 'Vip';
  const [quantityBeforeWritingZero, setQuantityBeforeWritingZero] = useState(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [isEditQuantity, setIsEditQuantity] = useState<boolean>(false);
  const [showConfirmDeletePopover, setShowConfirmDeletePopover] = useState(false);
  const translationKey = 'nodi:checkout:step1ShoppingCart:addingSubtractingInputButton:';

  const getUserCart = async () => {
    if (!orgInfo) return;
    try {
      const dataCart = await getShoppingCart(orgInfo?.userId, orgInfo?.organizationId);
      setCart(dataCart);
      localStorage.setItem('bagItems', JSON.stringify(dataCart));
    } catch (error) {
      displayErrorNotification();
    }
  };

  const handleChange = async (updateData: IDebounceProduct) => {
    if (updateData.type === 'edit' && updateData.product) {
      const format = {
        userId: orgInfo?.userId || 0,
        organizationId: orgInfo?.organizationId || 0,
        products: [updateData.product],
        havePrime: isPrime,
        countryCode: storeCountry?.countryCode || ''
      };
      try {
        await updateProductCart(format);
        if (!updateData?.shoppingCartId && orgInfo) {
          firstProduct(setCart, orgInfo.userId, orgInfo.organizationId);
        }
        await getUserCart();
      } catch (error) {
        displayErrorNotification();
      }
    }
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const addQuantityProduct = async (quantity: number) => {
    const formatProduct: IProductUpdate = {
      productId: +productId,
      productName: productName || '',
      brandImage: brandImage || '',
      image: image || '',
      pills: pills || undefined,
      countryFlag: countryFlag || '',
      countryName: countryName || '',
      countryId: countryId || 0,
      quantity: quantity,
      countryNameStringId: countryNameStringId || '',
      unitPrice: priceUsd || '',
      brandName: brandName,
      category: category,
      prices: prices || null
    };
    setQuantity(quantity);
    if (itemsCart && setCart) {
      addProductCart(formatProduct, itemsCart, setCart);
      optimizedFn({ product: formatProduct, type: 'edit', shoppingCartId: itemsCart.shoppingCartId });
    }
  };

  const removeItem = (quantity: number) => {
    const formatProduct: IProductUpdate = {
      productId: +productId,
      productName: productName || '',
      brandImage: brandImage || '',
      image: image || '',
      pills: pills || undefined,
      countryFlag: countryFlag || '',
      countryName: countryName || '',
      countryNameStringId: countryNameStringId || '',
      countryId: countryId || 0,
      quantity: quantity,
      unitPrice: priceUsd || '',
      brandName: brandName,
      category: category,
      prices: prices || null
    };
    setQuantity(quantity);
    if (countryName && itemsCart) {
      removeProductCard(+productId, quantity, itemsCart, setCart);
      optimizedFn({ product: formatProduct, quantity, type: 'edit', shoppingCartId: itemsCart.shoppingCartId });
    }
  };

  const deleteItem = async () => {
    if (countryName && itemsCart && typeof countryId === 'number') {
      deleteProduct(+productId, itemsCart, setCart);
      if (itemsCart?.shoppingCartId) {
        optimizedFn({ product: {}, quantity, type: 'delete', shoppingCartId: itemsCart.shoppingCartId });
        await deleteProductCart(itemsCart.shoppingCartId, +productId, countryId);
        await getUserCart();
      }
    }
  };

  const editBlurQuantity = async (quantity: number) => {
    if (quantity === 0 && countryName && itemsCart) setShowConfirmDeletePopover(true);
    else if (countryName && itemsCart) {
      const formatProduct: IProductUpdate = {
        productId: +productId,
        productName: productName,
        brandImage: brandImage || '',
        image: image || '',
        pills: pills || undefined,
        countryFlag: countryFlag || '',
        countryName: countryName || '',
        countryId: countryId || 0,
        quantity: quantity,
        countryNameStringId: '',
        unitPrice: priceUsd || '',
        brandName: brandName,
        category: category,
        prices: prices || null
      };
      await updateProductCart({
        userId: orgInfo?.userId || 0,
        organizationId: orgInfo?.organizationId || 0,
        havePrime: isPrime,
        products: [formatProduct],
        countryCode: storeCountry?.countryCode || ''
      });
      editInputQuantity(+productId, itemsCart, quantity, setCart);
      await getUserCart();
    }
    setIsEditQuantity(false);
  };

  const handleMouseOut = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    if (isEditQuantity) editBlurQuantity(+(value || 0));
  };

  const handleInputChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    +value === 0 ? setQuantityBeforeWritingZero(quantity || 1) : setQuantityBeforeWritingZero(+value || 1);
    if (!isNaN(+value) && +value >= 0) {
      setQuantity(+value);
      setIsEditQuantity(true);
    }
  };

  const handleDecreaseQuantity = () => {
    setQuantityBeforeWritingZero(quantity - 1);
    quantity > 0 ? removeItem(quantity - 1) : undefined;
  };
  const handleIncreaseQuantity = () => {
    addQuantityProduct(quantity + 1);
    setQuantityBeforeWritingZero(quantityBeforeWritingZero);
  };

  const handleConfirmDeleteInPopover = () => {
    deleteItem();
    displaySuccessNotification({
      customJSXMessage: <>{t(`${translationKey}successNotification`)} </>,
      button1Text: t('recurrentWords:undo'),
      button1Action: () => addQuantityProduct(quantityBeforeWritingZero || 1),
      button2Text: t('recurrentWords:understoodButton')
    });
  };
  const handlePreventDeleteInPopover = () => {
    setQuantity(quantityBeforeWritingZero || 1);
    setShowConfirmDeletePopover(false);
  };

  useEffect(() => setQuantity(initialQuantity), [initialQuantity]);

  return (
    <div className="addingSubtractingInputButton">
      <div className="popover">
        {showConfirmDeletePopover && (
          <NudosPopover
            topPopover={'-80'}
            rigthPopover={'32'}
            positionArrow={'bottomRigth'}
            firstButtonText={t('recurrentWords:cancel')}
            secondButtonText={t('recurrentWords:delete')}
            callBackFirstButton={handlePreventDeleteInPopover}
            callBackSecondButton={handleConfirmDeleteInPopover}
            changeOpenStatusFunction={handlePreventDeleteInPopover}
          >
            <div className="popoverText">{t(`${translationKey}popoverText`)}</div>
          </NudosPopover>
        )}
      </div>
      {quantity <= 1 ? (
        <DeleteIcon className="deleteIcon" onClick={() => setShowConfirmDeletePopover(true)} />
      ) : (
        <RemoveIcon className="removeIcon" onClick={handleDecreaseQuantity} />
      )}
      <div className="inputContainer">
        <div className="boxInputContainer">
          <input
            className="customInput"
            onBlur={event => handleMouseOut(event)}
            onMouseOut={event => handleMouseOut(event)}
            onChange={event => handleInputChange(event)}
            value={quantity || 0}
          />
        </div>
      </div>
      <AddIcon className="addIcon" onClick={handleIncreaseQuantity} />
    </div>
  );
};

export default AddingSubtractingInputButton;

interface IDebounceProduct {
  product: IProductUpdate;
  type: string;
  shoppingCartId?: number | null;
}
