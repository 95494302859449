import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import MinSerialIcon from '../../../../../assets/images/components/newCard/MinSerialIcon';
import ToolLogisticsOrigenAndDestinationTag from '../../../../../components/ToolLogisticsOrigenAndDestinationTag/ToolLogisticsOrigenAndDestinationTag';
import { BlueSmile, NoSmile } from '../../../../../assets/images/components/floatForm';
import { NudosCareTag, NudosSelectButton } from '../../../../../components/NudosComponents';
import { ItoolEssentialData } from '../../../../../types/requestLogisticsModule';
import ProductPills from '../../../../../components/ProductPills/ProductPills';
import { NudosWipingStatusMarker } from '../../../../../components/DesignSystem/LogisticsAdditionalServices';
import { LS_ADDITIONAL_SERVICE_TYPE } from '../../../../../types/logisticsServicesModule.types';
import { LOCATION_PLACE_TYPE, PRODUCT_CATEGORY } from '../../../../../types/global';
import './SelectProductCard.scss';

const SelectProductCard: FC<ISelectProductCard> = ({
  toolData,
  actionSelect,
  isSelected,
  actionDeselected
}: ISelectProductCard) => {
  const { t } = useTranslation();

  const { pills, logisticAdditionalServices, category } = toolData;

  const wipingService = logisticAdditionalServices?.find(service => service.name === LS_ADDITIONAL_SERVICE_TYPE.WIPING);
  const i18nLanguageKey = 'nodi:tools:';
  const toolIsLocatedInNudosWarehouse = ['nudos', 'warranty', 'diagnostic'].includes(toolData?.place || '');
  const noDataText = 'Sin datos';

  const toolCategorySupportWiping = [PRODUCT_CATEGORY.LAPTOP, PRODUCT_CATEGORY.PC].includes(
    (category || '') as PRODUCT_CATEGORY
  );
  const showWipingStatusMarker = toolCategorySupportWiping && toolData?.place === LOCATION_PLACE_TYPE.NUDOS;

  const toolOriginData = {
    place: toolData?.place,
    address: toolData?.address,
    city: toolData?.city,
    country: toolData?.country,
    locationName: toolData?.locationName
  };

  const getToolCity = () => {
    if (toolIsLocatedInNudosWarehouse) return toolData?.country?.name || '-';
    if (toolData?.city && toolData?.address) return toolData?.city;
    if (!toolData?.city || !toolData?.address) return t(`${i18nLanguageKey}noLocation`);
    return t(`${i18nLanguageKey}noLocation`);
  };

  const handleSelectTool = () => actionSelect && actionSelect();

  const handleClickCheckbox = isSelected ? actionDeselected : handleSelectTool;

  return (
    <div className="selectProductCard">
      <NudosSelectButton componentSize="small" isButtonActive={isSelected} handleClickButton={handleClickCheckbox} />
      <div className="productImgContainer">
        {toolData?.imageUrl && <div className="imgDetail" style={{ backgroundImage: `url("${toolData.imageUrl}")` }} />}
      </div>

      <div className="productDetailsContainer">
        <div className="superiorDetail">
          {toolData?.brandImage && (
            <div className="brandImg">
              <img className="imgLimits" src={toolData?.brandImage} />
            </div>
          )}
          <div className="productName">{toolData?.commercialName || noDataText}</div>
        </div>
        <div className="inferiorDetail">
          <ProductPills pills={pills} />
          <div className="serialContainer">
            <MinSerialIcon className="mr-1" />
            <div className="fontSerial truncate">{toolData?.serial || '-'}</div>
          </div>
        </div>
      </div>

      <div className="wipingSection">
        {showWipingStatusMarker && <NudosWipingStatusMarker wipingData={wipingService} />}
      </div>

      <div className="actualLocationContainer">
        <div className="generalStylesLocation">
          <ToolLogisticsOrigenAndDestinationTag
            origin={toolOriginData}
            logisticServiceIsConcluded={true}
            isBuyAndHold={toolData?.isBuyAndHold}
          />
        </div>
        <div className="citySelectTool">
          <div className="containerFlag">
            <div className="cityFlag" style={{ backgroundImage: `url("${toolData?.country?.flag || ''}")` }} />
          </div>
          <div className="cityName">{getToolCity()}</div>
        </div>
      </div>

      <div className="nudosCareInfo">
        <div className="typeTool">{toolData.referenceModel === 'CatalogProduct' ? <BlueSmile /> : <NoSmile />}</div>
        <NudosCareTag nudosCareType={toolData?.nudosCareName} />
      </div>
    </div>
  );
};

export default SelectProductCard;

interface ISelectProductCard {
  toolData: ItoolEssentialData;
  actionSelect: () => void;
  isSelected?: boolean;
  actionDeselected: () => void;
}
