import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NudosGenericModal } from '../../../../../components/NudosComponents';
import ModalPredefineLogistics from '../../employeeDetails/Components/UnassignToolsFromEmployeeModal/ModalPredefineLogistics';
import { useHistory } from 'react-router-dom';
import { GenericLoader } from '../../../dashboard/components';
import { ICountryDetail } from '../../../../../types/countries';
import { IemployeeDataToUnassign } from '../../../../../types/assignationFluxes';
import { getListOfCountriesByOfferedService } from '../../../../../utils/getCountries';

const AssignToolsModal: FC<IAssignToolsModal> = props => {
  const {
    closeModalCallback,
    employee,
    logisticsOriginCountry,
    navigationOriginId,
    navigationOrigin,
    listOfCountriesWithLogistics,
    submitModalCallback,
    setRequireLogistics
  } = props;
  const { push } = useHistory();
  const { t } = useTranslation();

  const [logisticsPredefined, setLogisticsPredefined] = useState(false);
  const [originCountryIsOpenForLogistics, setOriginCountryIsOpenForLogistics] = useState<boolean>(false);
  const [countriesWithLogistics, setCountriesWithLogistics] = useState<ICountryDetail[]>();

  const translationKey = 'services:logistics:assignToolsSelectedOptionsModal:';
  const titleText = t(`${translationKey}title`);
  const subtitleText = t(`${translationKey}subtitle`);

  const pendingFindingIfLogisticsIsPosible = originCountryIsOpenForLogistics === undefined;

  const handleCloseModal = () => closeModalCallback(false);

  const startAssignmentFluxCallback = () => {
    if (submitModalCallback) return submitModalCallback();
    if (!employee?.userId) return;
    const navigationParam = `&origin=${navigationOrigin}${navigationOriginId ? `&originId=${navigationOriginId}` : ''}`;
    const params = `?logistics=${logisticsPredefined || false}${navigationParam}`;
    push(`/nodi/request-logistics/assignment/${employee?.userId}${params}`);
  };

  const modalContent = pendingFindingIfLogisticsIsPosible ? (
    <GenericLoader />
  ) : (
    <ModalPredefineLogistics
      title={titleText}
      subtitle={subtitleText}
      isAssignment={true}
      closeModalCallback={handleCloseModal}
      submitModalCallback={startAssignmentFluxCallback}
      updateLogisticsPredefined={newValue => {
        setLogisticsPredefined(newValue), setRequireLogistics && setRequireLogistics(newValue);
      }}
    />
  );

  useEffect(() => {
    if (!countriesWithLogistics || countriesWithLogistics.length < 1 || !logisticsOriginCountry) return;
    const isOpenForLogistics = countriesWithLogistics?.some(
      countryWithLogistics => countryWithLogistics?.code === logisticsOriginCountry?.code
    );
    setOriginCountryIsOpenForLogistics(isOpenForLogistics);
  }, [countriesWithLogistics, logisticsOriginCountry]);

  useEffect(() => {
    if (listOfCountriesWithLogistics && listOfCountriesWithLogistics.length > 1) {
      setCountriesWithLogistics(listOfCountriesWithLogistics);
    } else getListOfCountriesByOfferedService(setCountriesWithLogistics, 'logisticService');
  }, []);

  return <div className="AssignToolsModal">{<NudosGenericModal modalContent={modalContent} />}</div>;
};

export default AssignToolsModal;

interface IAssignToolsModal {
  employee?: IemployeeDataToUnassign;
  navigationOrigin?: string;
  navigationOriginId?: string | number;
  logisticsOriginCountry?: ICountryDetail;
  listOfCountriesWithLogistics?: ICountryDetail[];
  submitModalCallback?: () => void;
  setRequireLogistics?: React.Dispatch<React.SetStateAction<boolean>>;
  closeModalCallback: React.Dispatch<React.SetStateAction<boolean>>;
}
