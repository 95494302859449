import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NudosButton, NudosEllipseProgressTracker } from '../../../../components/NudosComponents';
import SignUpFormStep1 from './Components/SignUpFormStep1/SignUpFormStep1';
import SignUpFormStep2 from './Components/SignUpFormStep2/SignUpFormStep2';
import SignUpFormStep3 from './Components/SignUpFormStep3/SignUpFormStep3';
import usePlatform from '../../../../hooks/usePlatform';
import { isProduction } from '../../../../constants';
import InviteCollaboratorModal from '../../../../components/InviteCollaboratorModal';
import { DTOOrganizationDocumentAlreadyExistResponse } from '../../../../types/SignUp';
import SignUpFromInvitation from './Components/SignUpFromInvitation/SignUpFromInvitation';
import { getCountriesList } from '../../../../services/countries.service';
import { ICountryDetail } from '../../../../types/countries';
import { useTranslation } from 'react-i18next';
import { nudosOnePointLargeLogo } from '../../../../assets/images/views/Admin/main';
import './SignUpForm.scss';

const SignUpForm = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { isMobile } = usePlatform();
  const [formStep, setFormStep] = useState(0);
  const nudosPage = isProduction ? 'https://www.nudos.co' : 'https://landing.beta.nudos.co';
  const [existingRFCError, setExistingRFCError] = useState<string>();
  const [businessNameAlreadyRegisteredError, setBusinessNameAlreadyRegisteredError] = useState<string>();
  const [existingOrganizationData, setExistingOrganizationData] =
    useState<DTOOrganizationDocumentAlreadyExistResponse>();
  const [openInviteCollaboratorModal, setOpenInviteCollaboratorModal] = useState(false);
  const [countriesOpenForRegistration, setCountriesOpenForRegistration] = useState<ICountryDetail[]>([]);
  const [countryPhone, setCountryPhone] = useState<ICountryDetail>();
  const emailParam = new URLSearchParams(search.replace('+', '{{i}}')).get('email')?.replace('{{i}}', '+');
  const registrationInfoIdParam = new URLSearchParams(search).get('registrationInfoId');
  const i18nLanguageKey = 'authentication:SignUpForm:';
  const titleText = emailParam ? t(`${i18nLanguageKey}titleText`) : t(`${i18nLanguageKey}titleTextDefault`);
  const subTitleText = emailParam ? t(`${i18nLanguageKey}subTitleText`) : t(`${i18nLanguageKey}subTitleTextDefault`);

  const getCountriesOpenForRegistration = async () => {
    const listOfCountriesOpenForRegistration = await getCountriesList('registry');
    setCountriesOpenForRegistration(listOfCountriesOpenForRegistration);
  };

  useEffect(() => {
    getCountriesOpenForRegistration();
  }, []);

  return (
    <div id="signUpForm">
      <img src={nudosOnePointLargeLogo} className="nudosOnePointLargeLogo" />
      <img className="nodiIcon" />
      <h1 className="title">{titleText}</h1>
      <h2 className="subtitle">{subTitleText}</h2>
      <div className="signUpForm">
        {!emailParam && (
          <>
            {formStep === 0 && (
              <SignUpFormStep1
                setFormStep={setFormStep}
                setExistingRFCError={setExistingRFCError}
                existingRFCError={existingRFCError}
                businessNameAlreadyRegisteredError={businessNameAlreadyRegisteredError}
                setBusinessNameAlreadyRegisteredError={setBusinessNameAlreadyRegisteredError}
                setOpenInviteCollaboratorModal={setOpenInviteCollaboratorModal}
                setExistingOrganizationData={setExistingOrganizationData}
                countriesOpenForRegistration={countriesOpenForRegistration}
              />
            )}
            {formStep === 1 && (
              <SignUpFormStep2
                updatePhoneCountryCallback={setCountryPhone}
                setFormStep={setFormStep}
                countriesOpenForRegistration={countriesOpenForRegistration}
                phoneCountry={countryPhone}
              />
            )}
            {formStep === 2 && (
              <SignUpFormStep3
                countryPhone={countryPhone}
                setFormStep={setFormStep}
                setExistingRFCError={setExistingRFCError}
                setBusinessNameAlreadyRegisteredError={setBusinessNameAlreadyRegisteredError}
              />
            )}
            <NudosEllipseProgressTracker currentStep={formStep} />
          </>
        )}
        {emailParam && (
          <SignUpFromInvitation emailParam={emailParam} registrationInfoIdParam={registrationInfoIdParam} />
        )}
      </div>
      {isMobile && formStep === 0 && (
        <div className="backToNudosButton">
          <NudosButton
            buttonText={t(`${i18nLanguageKey}backToNudosButton`)}
            colorPalette="secondary"
            handleClick={() => (window.location.href = nudosPage)}
          />
        </div>
      )}
      {openInviteCollaboratorModal && existingOrganizationData && (
        <InviteCollaboratorModal
          setOpenInviteCollaboratorModal={setOpenInviteCollaboratorModal}
          existingOrganizationData={existingOrganizationData}
        />
      )}
    </div>
  );
};

export default SignUpForm;
