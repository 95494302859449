import i18next from 'i18next';
import { getCountriesList } from '../services/countries.service';
import { ICountryDetail, IListType } from '../types/countries';
import { displayErrorNotification } from './displayNudosStandardNotifications';

const getListOfCountriesByOfferedService = async (
  setListOfCountries?: React.Dispatch<React.SetStateAction<ICountryDetail[] | undefined>>,
  offeredService?: IListType
) => {
  try {
    const completeListOfCountries = await getCountriesList(offeredService);
    setListOfCountries && setListOfCountries(completeListOfCountries);
    return completeListOfCountries;
  } catch (error) {
    displayErrorNotification();
  }
};

const getCountryIdFromCode = (countryCode: string, listOfCountries: ICountryDetail[]) => {
  if (!listOfCountries) return;
  const countryData = listOfCountries.find(country => country.code === countryCode);
  return countryData?.id;
};

const getCountryDataFromCountryId = (countryId: number, listOfCountries: ICountryDetail[]) => {
  if (!listOfCountries) return;
  const countryData = listOfCountries.find(country => country.id === countryId);
  return countryData;
};

const getCountryDataFromCountryCode = (countryCode?: string, listOfCountries?: ICountryDetail[]) => {
  if (!listOfCountries) return;
  const countryData = listOfCountries.find(country => country?.code === countryCode);
  return countryData;
};

const getCountryName = (country: ICountryDetail) => {
  const { name, nameStringId } = country;
  const translatedName =
    nameStringId && i18next.exists(`services:countries:${nameStringId}` || 'nonExistantKey')
      ? i18next.t(`services:countries:${nameStringId}`)
      : name;
  return translatedName || name;
};

export {
  getListOfCountriesByOfferedService,
  getCountryIdFromCode,
  getCountryDataFromCountryId,
  getCountryName,
  getCountryDataFromCountryCode
};
