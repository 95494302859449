import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '../../../../../assets/images/components/countryPrices';
import BillingDataByCountryForm from '../BillingDataByCountryForm/BillingDataByCountryForm';
import { getOrgData } from '../../../../../utils/getLocalStorageData';
import { getOrganizationBillingDataForAllItsCountries } from '../../../../../services/account';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { DTOgetTaxationRelatedDefinitionsOptions } from '../../../../../types/checkout';
import { getListOfCountriesByOfferedService } from '../../../../../utils/getCountries';
import { ICountryDetail } from '../../../../../types/countries';
import { getBillingTaxDefinitionsOptions } from '../../../../../hooks/useBillingData';
import FilterXMark from '../../../../../assets/images/components/NudosComponents/FilterXMark';
import BillingDataByCountry from '../BillingDataByCountry/BillingDataByCountry';
import { DTOgetBillingDataByOrganizationResponse } from '../../../../../types/account';
import './OrganizationBillingData.scss';

const OrganizationBillingData = () => {
  const { t } = useTranslation();
  const [addNewCountry, setAddNewCountry] = useState(false);
  const [organizationBillingData, setOrganizationBillingData] = useState<DTOgetBillingDataByOrganizationResponse>({});
  const [loadingOrganizationBillingCountries, setLoadingOrganizationBillingCountries] = useState(false);
  const [listOfAvailableCountries, setListOfAvailableCountries] = useState<ICountryDetail[]>();
  const [taxationRelatedDefinitionsOptions, setTaxationRelatedDefinitionsOptions] =
    useState<DTOgetTaxationRelatedDefinitionsOptions>();

  const i18nLanguageKey = 'nodi:account:billingDataByCountryForm:organizationBillingData:';
  const orgData = getOrgData();
  const organizationBillingDataEntries = Object.entries(organizationBillingData);

  const someCountryHasBillingEntities = Object.values(organizationBillingData)?.some(
    countryBillingData => countryBillingData.billingEntities.length > 0
  );
  const hasExistingData = organizationBillingData && someCountryHasBillingEntities;
  const addCountryButtonText = addNewCountry
    ? t(`${i18nLanguageKey}addCountryButtonText`)
    : t(`${i18nLanguageKey}addCountryButtonTextDefault`);

  const countriesBillingDataMap = new Map(organizationBillingDataEntries);

  const availableCountries = listOfAvailableCountries?.filter(country => {
    const thisCountryBillingData = countriesBillingDataMap.get(country.code);
    return !thisCountryBillingData || !!thisCountryBillingData?.canCreateNew;
  });

  const addCountryIcon = addNewCountry ? <FilterXMark className="cancelIcon" /> : <AddIcon className="addIcon" />;

  const handleClickAddNewCountryButton = () => setAddNewCountry(prevVal => !prevVal);

  const getOrganizationBillingData = async () => {
    if (!orgData?.organizationId) return;
    setLoadingOrganizationBillingCountries(true);
    try {
      const response = await getOrganizationBillingDataForAllItsCountries(orgData?.organizationId);
      setOrganizationBillingData(response);
      setLoadingOrganizationBillingCountries(false);
      setAddNewCountry(false);
    } catch (error) {
      displayErrorNotification();
      setLoadingOrganizationBillingCountries(false);
    }
  };

  useEffect(() => {
    getOrganizationBillingData();
    getListOfCountriesByOfferedService(setListOfAvailableCountries);
    getBillingTaxDefinitionsOptions(setTaxationRelatedDefinitionsOptions);
  }, []);

  return (
    <div className="organizationBillingData">
      <h1 className="tabTitle">{t(`${i18nLanguageKey}tabTitle`)}</h1>
      {loadingOrganizationBillingCountries && (
        <>
          <div className="addCountryButtonSkeletonLoader animationLoader" />
          {[0, 1, 2, 3].map(i => {
            return (
              <BillingDataByCountryForm key={`loader${i}`} showSkeletonLoader={loadingOrganizationBillingCountries} />
            );
          })}
        </>
      )}
      {!loadingOrganizationBillingCountries && hasExistingData && (
        <div className="addCountryButton" onClick={handleClickAddNewCountryButton}>
          {addCountryIcon}
          <div className="buttonText">{addCountryButtonText}</div>
        </div>
      )}
      {!loadingOrganizationBillingCountries && !hasExistingData && (
        <h2 className="firstCountrySubtitle">{t(`${i18nLanguageKey}firstCountrySubtitle`)}</h2>
      )}
      {!loadingOrganizationBillingCountries && (addNewCountry || !hasExistingData) && (
        <BillingDataByCountryForm
          updateDataCallback={getOrganizationBillingData}
          showSkeletonLoader={loadingOrganizationBillingCountries}
          listOfAvailableCountries={availableCountries}
          taxationRelatedDefinitionsOptions={taxationRelatedDefinitionsOptions}
        />
      )}
      {!loadingOrganizationBillingCountries &&
        hasExistingData &&
        organizationBillingDataEntries.map(([country, data]) => {
          return (
            <BillingDataByCountry
              key={country}
              countryBillingData={data}
              getOrganizationBillingData={getOrganizationBillingData}
              taxationRelatedDefinitionsOptions={taxationRelatedDefinitionsOptions}
              loadingOrganizationBillingCountries={loadingOrganizationBillingCountries}
            />
          );
        })}
      <div className="whiteSpaceAtTheBottom w-10 h-10" />
    </div>
  );
};

export default OrganizationBillingData;
