import { useTranslation } from 'react-i18next';
import { IIssueReported, IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import { useEffect, useState } from 'react';
import { NudosEditAddressModal } from '../../../../../../components/DesignSystem';
import { IconPencilBox } from '../../../../../../assets/DesignSystem/SVGComponents';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../utils/displayNudosStandardNotifications';
import { requestLogisticServiceCancelation } from '../../../../../../services/logisticsServicesModule.services';
import { capitalize } from '../../../../../../utils/unCamelCase';
import './LogisticServiceLocationCard.scss';

const LogisticServiceLocationCard = ({
  isLoading = false,
  tipeOfCard,
  logisticServiceData,
  getLogisticServiceDetailCallback,
  issueReported
}: {
  isLoading?: boolean;
  tipeOfCard: 'origin' | 'destination';
  logisticServiceData?: IlogisticServiceDetailedData;
  getLogisticServiceDetailCallback: () => void;
  issueReported?: IIssueReported;
}) => {
  const { t } = useTranslation();
  const [editDirectionModal, setEditDirectionModal] = useState<boolean>();
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [indicationsError, setIndicationsError] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<boolean>(false);

  const { status, id } = logisticServiceData || {};
  const isOriginCard = tipeOfCard === 'origin';
  const originData = logisticServiceData?.collection;
  const destinationnData = logisticServiceData?.delivery;
  const locationData = isOriginCard ? originData : destinationnData;
  const { place, additionalInfo, locationName, address, additionalReferences, country } = locationData || {};

  const {
    name: personInChargeName,
    phone: personInChargePhone,
    personalId: personInChargePersonalId
  } = additionalInfo || {};

  const lSLocationCardKey = 'nodi:logisticServices:logisticServiceLocationCard:';
  const designSystemKey = 'designSystemComponents:referenceOptions:';
  const phoneLabel = t('recurrentWords:phone');
  const addressLabel = t(`${designSystemKey}address`);
  const directionsLabel = t(`${lSLocationCardKey}directionsLabel`);
  const locationLabel = t('recurrentWords:location');
  const serviceLabel = t(`${lSLocationCardKey}serviceLabel`);
  const originText = capitalize(t('recurrentWords:origin'));
  const destinationText = capitalize(t('recurrentWords:destination'));
  const officeText = t('recurrentWords:office');
  const storageText = t(`${lSLocationCardKey}storageText`);
  const cancelLSNotification = t(`${lSLocationCardKey}cancelLSNotification`);
  const whoDelivers = t(`${lSLocationCardKey}whoDelivers`);
  const whoReceives = t(`${lSLocationCardKey}whoReceives`);
  const brandText = t('nodi:checkout:brand');
  const nudosWarehouse = t(`${designSystemKey}nudosWarehouse`);
  const diagnosticMaintenance = t(`${lSLocationCardKey}diagnosticMaintenance`);
  const warrantyManagement = t(`${lSLocationCardKey}warrantyManagement`);

  const typeOfCardText = isOriginCard ? originText : destinationText;
  const countryFlag = country?.flag || '';
  const noValueText = 'N/A';
  const countryName = country?.nameStringId
    ? t(`services:countries:${country?.nameStringId || ''}`)
    : country?.name || noValueText;

  const placeIsUser = place === 'user';
  const placeIsOffice = place === 'office';
  const placeIsOtherAddress = place === 'other';
  const placeIsOfficeOrOtherAddress = placeIsOffice || placeIsOtherAddress;
  const placeIsWarehouse = place === 'nudos';
  const placeIsWarranty = place === 'warranty';
  const placeIsDiagnostics = place === 'diagnostic';
  const assignedPersonTitle = isOriginCard ? whoDelivers : whoReceives;

  const showId = placeIsUser;
  const showAssignedPerson = placeIsOfficeOrOtherAddress;
  const showPhone = placeIsUser || placeIsOfficeOrOtherAddress;
  const showFlag = !placeIsWarranty;
  const activeStatus = status === 'activo';
  const confirmedStatus = status === 'confirmado';
  const inProgressStatus = status === 'en proceso';
  const pendingConfirmationStatus = status === 'por confirmar';

  const showAddressAndAdditionalReferences = placeIsUser || placeIsOfficeOrOtherAddress;
  const showLocationAndService = placeIsWarehouse || placeIsDiagnostics || placeIsWarranty;
  const displayEditButton =
    (confirmedStatus || inProgressStatus || pendingConfirmationStatus || activeStatus) && !showLocationAndService;

  const haveIssueReported = issueReported;

  const getNameText = () => {
    if (placeIsOffice) return `${officeText} ${countryName}`;
    if (placeIsOtherAddress) return locationName || noValueText;
    if (placeIsWarehouse || placeIsDiagnostics) return nudosWarehouse;
    if (placeIsWarranty) return brandText;
    return personInChargeName || noValueText;
  };

  const getServiceText = () => {
    if (placeIsWarehouse) return storageText;
    if (placeIsDiagnostics) return diagnosticMaintenance;
    if (placeIsWarranty) return warrantyManagement;
    return noValueText;
  };

  const getPhoneText = () => {
    if (placeIsOfficeOrOtherAddress) return personInChargePhone ? `+ ${personInChargePhone}` : noValueText;
    return `+ ${personInChargePhone}` || noValueText;
  };

  const getAddressText = () => {
    return address || noValueText;
  };

  const cancelLogisticService = async () => {
    try {
      if (!id) return;
      await requestLogisticServiceCancelation(id);
      setEditDirectionModal(false);
      displaySuccessNotification({ customJSXMessage: <>{cancelLSNotification}</> });
    } catch (error) {
      setEditDirectionModal(false);
      displayErrorNotification();
    }
  };

  const findAndShowErrors = () => {
    if (issueReported) {
      const phoneReport = issueReported?.wrongFields.find(
        report => report.addressWrongField.name === 'Número de teléfono'
      );
      const indicationsReport = issueReported?.wrongFields.find(
        report => report.addressWrongField.name === 'Indicaciones'
      );
      const addressReport = issueReported?.wrongFields.find(report => report.addressWrongField.name === 'Dirección');
      if (phoneReport) {
        setPhoneError(!!phoneReport);
      }
      if (indicationsReport) {
        setIndicationsError(!!indicationsReport);
      }
      if (addressReport) {
        setAddressError(!!addressReport);
      }
    } else {
      setPhoneError(false);
      setIndicationsError(false);
      setAddressError(false);
    }
  };

  useEffect(() => {
    findAndShowErrors();
  }, [issueReported]);

  return (
    <>
      {(isLoading || !locationData) && <LogisticServiceLocationCardSkeletonLoader />}
      {editDirectionModal && (
        <NudosEditAddressModal
          originDestination={tipeOfCard}
          closeModalCallback={() => setEditDirectionModal(false)}
          requestCancelLSCallback={cancelLogisticService}
          logisticServiceData={logisticServiceData}
          getLogisticServiceDetailCallback={getLogisticServiceDetailCallback}
          issueReported={issueReported}
        />
      )}
      {!isLoading && locationData && (
        <div className="logisticServiceLocationCardContainer">
          <div className="logisticServiceLocationCard">
            <div className="informationLine firstLine">
              <div className="leftTitle">{typeOfCardText}</div>
              <div className="rightValue">
                {getNameText()}
                {showFlag && <div className="valueFlag" style={{ backgroundImage: `url(${countryFlag})` }} />}
              </div>
            </div>
            {showId && (
              <div className="informationLine">
                <div className="leftTitle">ID</div>
                <div className="rightValue">{personInChargePersonalId || noValueText}</div>
              </div>
            )}
            {showAssignedPerson && (
              <div className="informationLine">
                <div className="leftTitle">{assignedPersonTitle}</div>
                <div className="rightValue">{personInChargeName || noValueText}</div>
              </div>
            )}
            {showPhone && (
              <div className={`informationLine ${phoneError ? 'errorText' : ''}`}>
                <div className={`leftTitle ${phoneError ? 'leftErrorText' : ''}`}>{phoneLabel}</div>
                <div className="rightValue">{getPhoneText()}</div>
              </div>
            )}
            {showAddressAndAdditionalReferences && (
              <div className={`informationLine ${addressError ? 'errorText' : ''}`}>
                <div className={`leftTitle ${addressError ? 'leftErrorText' : ''}`}>{addressLabel}</div>
                <div className="rightValue">{getAddressText()}</div>
              </div>
            )}
            {showAddressAndAdditionalReferences && (
              <div className={`informationLine ${indicationsError ? 'errorText' : ''}`}>
                <div className={`leftTitle ${indicationsError ? 'leftErrorText' : ''}`}>{directionsLabel}</div>
                <div className="rightValue">{additionalReferences || noValueText}</div>
              </div>
            )}
            {showLocationAndService && (
              <div className="informationLine">
                <div className="leftTitle">{locationLabel}</div>
                <div className="rightValue">{countryName}</div>
              </div>
            )}
            {showLocationAndService && !isOriginCard && (
              <div className="informationLine">
                <div className="leftTitle">{serviceLabel}</div>
                <div className="rightValue">{getServiceText()}</div>
              </div>
            )}
          </div>
          {displayEditButton && (
            <div
              className={`editDirectionModal ${haveIssueReported ? 'reportStyle' : ''}`}
              onClick={() => setEditDirectionModal(true)}
            >
              {t('recurrentWords:edit')}
              <IconPencilBox color={haveIssueReported ? '#FF0000' : ''} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LogisticServiceLocationCard;

const LogisticServiceLocationCardSkeletonLoader = () => {
  return (
    <div className="logisticServiceLocationCardSkeletonLoader">
      {[0, 1, 2, 3, 4].map(line => {
        return (
          <div className="informationLine" key={`skeletonLine${line}`}>
            <div className="leftTitle animationLoader" />
            <div className="rightValue animationLoader" />
          </div>
        );
      })}
    </div>
  );
};
