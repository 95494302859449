import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g stroke="#fff">
      <path
        d="M5.998 1.286c-1.33 0-2.722 0-3.884.218-.53.1-.945.508-1.072 1.033-.187.767-.187 1.348-.187 2.606 0 1.259 0 1.84.187 2.607.127.524.542.932 1.072 1.032C3.276 9 4.667 9 5.998 9s2.723 0 3.885-.218c.53-.1.945-.508 1.072-1.032.186-.767.186-1.348.186-2.607 0-1.258 0-1.84-.186-2.606a1.372 1.372 0 0 0-1.072-1.033c-1.162-.218-2.554-.218-3.885-.218Z"
        fill="#ff664e"
      />
      <path d="M6 8.996v2.361M4.285 11.357h3.429" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 3.429v3.428M7.714 5.143H4.285" strokeLinecap="round" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const AddToolIcon = memo(ForwardRef);
export default AddToolIcon;
