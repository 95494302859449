import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  NudosBreadcrumbButton,
  NudosButton,
  NudosPhoneInput,
  NudosSelectDropdown,
  NudosTextInput
} from '../../../../../../components/NudosComponents';
import useSignUpFormStep2Controller from './SignUpFormStep2.controller';
import { updateRegisterFormData } from '../../../../../../services/signUp.service';
import { IformStep2Fields, IformStep2FieldsError } from '../../../../../../types/SignUp';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { ICountryDetail } from '../../../../../../types/countries';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import { useTranslation } from 'react-i18next';
import { verifyPhoneAndEmail } from '../../../../../../services/register';
import { emailAndPhoneValidationResponseTexts } from '../../../../../../utils/backendConstants';
import './SignUpFormStep2.scss';

const SignUpFormStep2 = ({
  setFormStep,
  countriesOpenForRegistration,
  updatePhoneCountryCallback,
  phoneCountry
}: IsignUpFormStep2Params) => {
  const { t } = useTranslation();
  const { formInputsStep2, formSchemaStep2, jobPosition } = useSignUpFormStep2Controller();
  const stringNewRegisterData = sessionStorage.getItem('newRegisterInformation');
  const newRegisterData = !!stringNewRegisterData && JSON.parse(stringNewRegisterData);

  const [errors, setErrors] = useState<IformStep2FieldsError>();
  const [phoneExistError, setPhoneExistError] = useState<string>();
  const [loading, setloading] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>();
  const [submittedData, setSubmittedData] = useState<IformStep2Fields>();
  const { register, handleSubmit, watch, setValue } = useForm<IformStep2Fields>({
    shouldFocusError: true,
    resolver: yupResolver(formSchemaStep2),
    defaultValues: {
      firstName: newRegisterData ? newRegisterData.firstName : '',
      lastName: newRegisterData ? newRegisterData.lastName : '',
      position: newRegisterData ? newRegisterData.position : '',
      phone: newRegisterData ? newRegisterData.phone : ''
    }
  });
  const currentData = watch();
  const i18nLanguageKey = 'authentication:SignUpForm:useSignUpFormStep1Controller:';

  const handleChangeSelectedPhoneNumber = (newNumber?: string) => setSelectedPhoneNumber(newNumber);

  const handleSuccessfullSubmit = async (data: IformStep2Fields) => {
    segmentTrackEvent({
      signupSecondStepClick: {
        ContactNumber: data.phone,
        Name: data.firstName,
        RoleName: data.position,
        Surname: data.lastName
      }
    });
    setSubmittedData(data);
    setloading(true);
    const body = {
      ...data,
      countryPhoneId: phoneCountry?.id
    };
    try {
      const responseValidatePhone = await verifyPhoneAndEmail({
        phone: currentData.phone,
        email: '-validationNotRequired-'
      });
      const phoneExists = [
        emailAndPhoneValidationResponseTexts.phoneExists,
        emailAndPhoneValidationResponseTexts.phoneAndEmailExists
      ].includes(responseValidatePhone);
      if (phoneExists) {
        setPhoneExistError(t(`${i18nLanguageKey}phoneExistError`));
        return;
      }
      const response = await updateRegisterFormData(body, newRegisterData.id);
      if (response) {
        const newRegisterInformation = JSON.stringify(response);
        sessionStorage.setItem('newRegisterInformation', newRegisterInformation);
        setFormStep(2);
      } else {
        const updatedRegisterInformation = { ...newRegisterData, ...body };
        const stringUpdatedRegisterInformation = JSON.stringify(updatedRegisterInformation);
        sessionStorage.setItem('newRegisterInformation', stringUpdatedRegisterInformation);
        setFormStep(2);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setloading(false);
    }
  };

  const handleErrorSubmitting = (data: IformStep2FieldsError) => {
    setSubmittedData(currentData);
    setErrors(data);
  };

  const handleFieldBlurr = (fieldId: string, fieldInputHasChangedSinceSubmitted: boolean) => {
    const newErrors = { ...errors };
    newErrors[fieldId as keyof IformStep2FieldsError] = undefined;
    fieldInputHasChangedSinceSubmitted && setErrors(newErrors);
    fieldId === 'phone' && setPhoneExistError(undefined);
  };

  const isPhoneValid = /^[0-9]{6,13}$/.test(currentData.phone) && phoneCountry?.id;
  const invalidPhoneError = currentData?.phone?.length > 0 && !isPhoneValid && t(`${i18nLanguageKey}invalidPhoneError`);

  const isButtonDisabled = !Object.values(currentData).every(value => value && value.length > 0) || !isPhoneValid;

  useEffect(() => {
    jobPosition && setValue('position', jobPosition);
    selectedPhoneNumber && setValue('phone', selectedPhoneNumber);
  }, [jobPosition, selectedPhoneNumber]);

  useEffect(() => {
    segmentTrackEvent({ signupSecondStepView: null });
  }, []);

  return (
    <form
      id="formStep2"
      className="formStep formStep2"
      onSubmit={handleSubmit(handleSuccessfullSubmit, handleErrorSubmitting)}
    >
      {formInputsStep2.map(field => {
        if (field.type === 'text') {
          const fieldInputHasChangedSinceSubmitted =
            !!submittedData &&
            currentData[field.id as keyof IformStep2Fields] !== submittedData[field.id as keyof IformStep2Fields];
          const errorText =
            !fieldInputHasChangedSinceSubmitted && errors
              ? errors[field.id as keyof IformStep2FieldsError]?.message
              : undefined;

          return (
            <NudosTextInput
              key={`field-${field?.id}`}
              componentSize="large"
              label={field?.label}
              placeholder={field?.placeholder}
              isFilled={!!currentData[field.id as keyof IformStep2Fields]}
              register={register}
              registerId={field?.id}
              errorText={errorText}
              defaultValue={newRegisterData ? newRegisterData[field.id as keyof IformStep2Fields] : undefined}
              handleBlur={() => handleFieldBlurr(field.id, fieldInputHasChangedSinceSubmitted)}
            />
          );
        }
        if (field.type === 'select' && field.options && field.options.length > 0) {
          return (
            <NudosSelectDropdown
              key={`field-${field.id}`}
              componentSize="large"
              label={field.label}
              selectOptions={field.options}
              currentValueOrplaceholder={
                jobPosition ||
                (newRegisterData && newRegisterData?.position ? newRegisterData.position : field.placeholder)
              }
              isFilled={!!jobPosition || !!newRegisterData?.position}
              errorText={errors ? errors[field.id as keyof IformStep2FieldsError]?.message : undefined}
            />
          );
        }
        if (field.type === 'phone') {
          const fieldInputHasChangedSinceSubmitted =
            !!submittedData &&
            currentData[field.id as keyof IformStep2Fields] !== submittedData[field.id as keyof IformStep2Fields];
          const phoneError =
            errors && !fieldInputHasChangedSinceSubmitted
              ? errors[field.id as keyof IformStep2FieldsError]?.message
              : undefined;
          const existingPhoneError =
            phoneExistError && !fieldInputHasChangedSinceSubmitted ? phoneExistError : undefined;
          return (
            <NudosPhoneInput
              key={`field-${field?.id}`}
              componentSize="large"
              label={field?.label}
              placeholder={field?.placeholder}
              defaultCountryData={phoneCountry}
              defaultPhoneNumber={newRegisterData?.phone}
              errorText={invalidPhoneError || phoneError || existingPhoneError}
              handlePhoneChange={handleChangeSelectedPhoneNumber}
              handleBlur={() => handleFieldBlurr(field.id, fieldInputHasChangedSinceSubmitted)}
              countriesList={countriesOpenForRegistration}
              handleCountryPhoneChange={updatePhoneCountryCallback}
            />
          );
        }
      })}
      <div className="buttonContainer">
        <NudosBreadcrumbButton returnText={t(`${i18nLanguageKey}returnText`)} returnAction={() => setFormStep(0)} />
        <NudosButton
          componentSize="small"
          buttonText={t(`${i18nLanguageKey}nudosButton`)}
          isButtonDisabled={isButtonDisabled}
          isButtonLoading={loading}
          isOfTypeSubmit
          formId="formStep2"
        />
      </div>
    </form>
  );
};

export default SignUpFormStep2;

export interface IsignUpFormStep2Params {
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  countriesOpenForRegistration: ICountryDetail[];
  updatePhoneCountryCallback: (e: ICountryDetail | undefined) => void;
  phoneCountry?: ICountryDetail;
}
