import { useState } from 'react';
import { CloseWhite, OtherButtonIcon } from '../../../../../assets/images/components';
import { NudosButton } from '../../../../../components/NudosComponents';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { sendSubscriptionSlackMessage } from '../../../../../services/subscriptions.services';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { useTranslation, Trans } from 'react-i18next';
import { displaySuccessNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { getPersonalData } from '../../../../../utils/getLocalStorageData';
import './BlackBanner.scss';

const BlackBanner = () => {
  const { t } = useTranslation();
  const { setShowBlackBanner } = useStateSubscriptions();
  const orgData = formatOrgData();
  const personalData = getPersonalData();
  const [loadingMessage, setLoaddingMessage] = useState<boolean>(false);
  const i18nLanguageKey = 'nodi:blackBanner:';

  const sendSlackMessage = async () => {
    setLoaddingMessage(true);
    try {
      const data = {
        organizationName: orgData?.organizationName || '',
        requesterName: `${personalData?.firstName} ${personalData?.lastName}`,
        service: 'Nudos Care',
        origin: 'desde el Dashboard',
        userRole: personalData?.role
      };
      await sendSubscriptionSlackMessage(data);
      displaySuccessNotification({
        customJSXMessage: <>{t(`${i18nLanguageKey}displaySuccessNotification:text`)}</>,
        button1Text: t(`${i18nLanguageKey}displaySuccessNotification:button`)
      });
      setShowBlackBanner(false);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoaddingMessage(false);
    }
  };

  return (
    <div id="blackBanner">
      <div className="boxBlackBanner">
        <div className="titleBlackBanner">
          <Trans
            i18nKey={`${i18nLanguageKey}titleBlackBanner`}
            components={{ 1: <span className="minTitle"></span> }}
          />
        </div>
        <div className="subtitle">
          <Trans i18nKey={`${i18nLanguageKey}subtitle`} components={{ 1: <br /> }} />
        </div>
        <div
          className="closeFloat"
          onClick={() => {
            setShowBlackBanner(false);
          }}
        >
          <CloseWhite />
        </div>
        <div className="floatButton">
          <NudosButton
            handleClick={() => {
              sendSlackMessage();
            }}
            isButtonDisabled={loadingMessage}
            isButtonLoading={loadingMessage}
            colorPalette="lightBlue"
            buttonText={t(`${i18nLanguageKey}nudosButton`)}
            customClassName="myButton"
            buttonIcon={<OtherButtonIcon />}
          />
        </div>
      </div>
    </div>
  );
};

export default BlackBanner;
