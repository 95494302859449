import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" ref={ref} {...props}>
    <g stroke="#FF664E" strokeLinecap="round" strokeLinejoin="round">
      <path fill="#fff" d="M7 .75a6.25 6.25 0 1 1 0 12.5A6.25 6.25 0 0 1 7 .75Z" />
      <path d="M7 4.186v5.63M9.355 7.794c-.715.904-1.203 1.325-1.93 1.875a.693.693 0 0 1-.85 0c-.727-.55-1.214-.97-1.93-1.875" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const DownwardArrow = memo(ForwardRef);
export default DownwardArrow;
