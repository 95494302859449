import { IorganizationBillingDataByCountry } from '../../../../../types/account';
import { DTOgetTaxationRelatedDefinitionsOptions } from '../../../../../types/checkout';
import BillingDataByCountryForm from '../BillingDataByCountryForm/BillingDataByCountryForm';
import './BillingDataByCountry.scss';

const BillingDataByCountry = ({
  countryBillingData,
  getOrganizationBillingData,
  loadingOrganizationBillingCountries,
  taxationRelatedDefinitionsOptions
}: {
  countryBillingData: IorganizationBillingDataByCountry;
  getOrganizationBillingData: () => Promise<void>;
  loadingOrganizationBillingCountries: boolean;
  taxationRelatedDefinitionsOptions?: DTOgetTaxationRelatedDefinitionsOptions;
}) => {
  const { countryData, billingEntities } = countryBillingData || {};
  return (
    <div className="billingDataByCountry">
      {countryData && (
        <div className="countryTitle">
          <div className="countryFlag" style={{ backgroundImage: `url(${countryData?.flag || ''})` }} />
          <div className="countryName">{countryData?.name}</div>
        </div>
      )}
      {billingEntities.map(billingEntity => {
        return (
          <BillingDataByCountryForm
            key={`${billingEntity.id}`}
            initialBillingData={billingEntity}
            updateDataCallback={getOrganizationBillingData}
            showSkeletonLoader={loadingOrganizationBillingCountries}
            taxationRelatedDefinitionsOptions={taxationRelatedDefinitionsOptions}
          />
        );
      })}
    </div>
  );
};

export default BillingDataByCountry;
