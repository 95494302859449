import {
  IconAddTool,
  IconCalendar,
  IconPin,
  IconQuotation,
  IconSendEmail,
  IconTruck
} from '../../../../../assets/DesignSystem/SVGComponents';
import { NudosStepsProgressBar } from '../../../../../components/NudosComponents';
import './ToolsManagementStepper.scss';

/**
 * @property  { number } currentStep - The current step of the checkout
 * @property  { boolean } requireLogistics - A boolean indicating if the step is only shown when logistics by nudos is required
 * @property  { boolean } includeEmailStep -  A boolean indicating if the email step should be shown, ie when the destination is an user
 * @property  { boolean } includeDestinationStep -  A boolean indicating if the destination step, iet the first, should be shown, ie when the destination is an user
 */
const ToolsManagementStepper = ({
  currentStep,
  requireLogistics,
  includeEmailStep,
  includeDestinationStep,
  includeQuoteAndBillingStep
}: {
  currentStep: number;
  requireLogistics: boolean;
  includeEmailStep: boolean;
  includeDestinationStep: boolean;
  includeQuoteAndBillingStep: boolean;
}) => {
  const isStepActive = (step: number) => (step - orderModifierAccordingToFirstStep === currentStep ? 'active' : '');
  const orderModifierAccordingToFirstStep = includeDestinationStep ? 0 : 1;
  const toolsStepOrderNoLs = requireLogistics ? 2 : 1;
  const emailStepOrderNoLs = requireLogistics ? 4 : 2;
  const quoteAndBillingtStep = includeEmailStep ? 5 : 4;

  const progressSteps = [
    {
      order: 0,
      showStep: includeDestinationStep,
      stepContent: (
        <div className={`step0 toolManagementStepContent`}>
          <IconPin className="stepIcon IconPin" />
          {!!isStepActive(0) && <div className="stepText">Destino</div>}
        </div>
      ),
      stepWidth: isStepActive(0) ? 58 : 24,
      customStepStyles: undefined
    },
    {
      order: 1 - orderModifierAccordingToFirstStep,
      showStep: requireLogistics,
      stepContent: (
        <div className={`step1 toolManagementStepContent`}>
          <IconTruck className={`stepIcon IconTruck ${currentStep >= 1 ? 'active' : ''}`} />
          {!!isStepActive(1) && <div className="stepText">Direcciones</div>}
        </div>
      ),
      stepWidth: isStepActive(1) ? 75 : 24,
      customStepStyles: undefined
    },
    {
      order: toolsStepOrderNoLs - orderModifierAccordingToFirstStep,
      showStep: true,
      stepContent: (
        <div className={`step2 toolManagementStepContent`}>
          <IconAddTool className={`stepIcon IconAddTool ${currentStep >= toolsStepOrderNoLs ? 'active' : ''}`} />
          {!!isStepActive(toolsStepOrderNoLs) && <div className="stepText">Herramientas</div>}
        </div>
      ),
      stepWidth: isStepActive(toolsStepOrderNoLs) ? 84 : 24,
      customStepStyles: undefined
    },
    {
      order: 3 - orderModifierAccordingToFirstStep,
      showStep: requireLogistics,
      stepContent: (
        <div className={`step2 toolManagementStepContent`}>
          <IconCalendar className={`stepIcon IconAddTool ${currentStep >= 3 ? 'active' : ''}`} />
          {!!isStepActive(3) && <div className="stepText">Fechas</div>}
        </div>
      ),
      stepWidth: isStepActive(3) ? 55 : 24,
      customStepStyles: undefined
    },
    {
      order: emailStepOrderNoLs - orderModifierAccordingToFirstStep,
      showStep: includeEmailStep,
      stepContent: (
        <div className={`step2 toolManagementStepContent`}>
          <IconSendEmail className={`stepIcon stepEmailIcon ${currentStep >= emailStepOrderNoLs ? 'active' : ''}`} />
          {!!isStepActive(emailStepOrderNoLs) && <div className="stepText emailText">Correo</div>}
        </div>
      ),
      stepWidth: isStepActive(emailStepOrderNoLs) ? 54 : 24,
      customStepStyles: undefined
    },
    {
      order: quoteAndBillingtStep - orderModifierAccordingToFirstStep,
      showStep: requireLogistics && includeQuoteAndBillingStep,
      stepContent: (
        <div className={`step2 toolManagementStepContent`}>
          <IconQuotation className={`stepIcon IconQuotation ${currentStep >= quoteAndBillingtStep ? 'active' : ''}`} />
          {!!isStepActive(quoteAndBillingtStep) && <div className="quoteAndBillingtText">Cotización</div>}
        </div>
      ),
      stepWidth: isStepActive(quoteAndBillingtStep) ? 64 : 24,
      customStepStyles: undefined
    }
  ];

  const definitiveSteps = progressSteps.filter(step => !!step?.showStep);

  return (
    <div className="ToolsManagementStepper">
      <NudosStepsProgressBar
        steps={definitiveSteps}
        currentStep={currentStep}
        firstAndLastConectorWidth={32}
        intermediateStepsConectorWidth={40}
        customHeight={24}
      />
    </div>
  );
};

export default ToolsManagementStepper;
