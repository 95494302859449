import { useTranslation } from 'react-i18next';
import { LS_ADDITIONAL_SERVICE_STATUS } from '../../../../../types/logisticsServicesModule.types';
import { IadditionalServiceDataForToolLogistics } from '../../../../../types/requestLogisticsModule';
import { NudosGenericModal, NudosModalContent } from '../../../../NudosComponents';
import AdditionalServiceStatusModalContent from './AdditionalServiceStatusModalContent';
import './NudosAdditionalServiceStatusModal.scss';

const NudosAdditionalServiceStatusModal = ({
  additionalServiceData,
  closeModalCallback
}: {
  closeModalCallback: () => void;
  additionalServiceData: IadditionalServiceDataForToolLogistics;
}) => {
  const { t } = useTranslation();
  const { statusName } = additionalServiceData?.status || {};
  const translateKey = 'services:logistics:additionalServices:';
  const modalTitle =
    statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED
      ? t(`${translateKey}subsLassNotCompletedModal:title`)
      : t(`${translateKey}subsLassCompletedModal:title`);

  return (
    <div className="nudosAdditionalServiceStatusModal">
      <NudosGenericModal
        closeModalCallback={closeModalCallback}
        modalContent={
          <NudosModalContent
            hideNudosIcon
            customWidth="580px"
            customHeight="426px"
            toCloseModal={closeModalCallback}
            modalContentTitle={modalTitle}
            CustomModalComponent={
              <AdditionalServiceStatusModalContent
                additionalServiceData={additionalServiceData}
                closeModalCallback={closeModalCallback}
              />
            }
          />
        }
      />
    </div>
  );
};

export default NudosAdditionalServiceStatusModal;
