import { useCallback, useEffect, useRef, useState } from 'react';
import { DownwardArrow } from '../../../../../assets/images/views/Admin/FrequentlyAskedQuestions';
import './FAQCard.scss';
import { IElement } from '../../../../../types/global';

const FAQCard = ({
  question,
  answer,
  expandedHeight
}: {
  question: JSX.Element;
  answer: JSX.Element;
  expandedHeight: number;
}) => {
  const [showExpandedSection, setShowExpandedSection] = useState(false);
  const faqCardRef = useRef<HTMLDivElement>(null);

  const expandedStyles = showExpandedSection ? { height: `${expandedHeight}px` } : undefined;
  const rotatedArrowStyle = showExpandedSection ? { transform: 'rotate(180deg)' } : undefined;

  const handleCloseExpandedSection = () => setShowExpandedSection(false);
  const handleClickArrow = () => setShowExpandedSection(!showExpandedSection);

  const onHandleClickAway = useCallback(
    (e: IElement) => {
      if (faqCardRef.current && !faqCardRef.current.contains(e.target)) handleCloseExpandedSection();
    },
    [faqCardRef]
  );

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div className="faqCard" ref={faqCardRef}>
      <section className="compressedSection">
        <div>{question}</div>
        <DownwardArrow className="downwardArrow" onClick={handleClickArrow} style={rotatedArrowStyle} />
      </section>
      <section className="expandedSection" style={expandedStyles}>
        {answer}
      </section>
    </div>
  );
};

export default FAQCard;
