import { Fragment, useEffect, useState } from 'react';
import { NudosSelectDropdown, NudosTextInput } from '../../../../../components/NudosComponents';
import { validateExistingSerialNumber } from '../../../../../services/uploadExternalTools.service';
import {
  ILocationOptions,
  IUploadCountries,
  IuploadedItemData,
  TproductItemCondition
} from '../../../../../types/uploadExternalTools.types';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { IsharedDataForToolReferenceItems } from '../Step2AddInformation/Step2AddInformation';
import NudosEmployeeSearchBar from '../../../../../components/NudosComponents/NudosEmployeeSearchBar/NudosEmployeeSearchBar';
import { Iemployee } from '../../../../../types/global';
import { IListDefaultLocationAddress, ILocationAddress } from '../../../../../types/account';
import { LocationToolsFlux } from '../../../logisticServices';
import NudosGenericSearchBar from '../../../../../components/NudosComponents/NudosGenericSearchBar';
import { useTranslation } from 'react-i18next';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { servicesDefaultLocationAddress } from '../../../../../services/account';
import { NudosCountryDropdown } from '../../../../../components/DesignSystem';
import './ProductItemDataForm.scss';

interface IproductItemDataFormProps {
  handleUpdateThisItemData: (updatedItemData: IuploadedItemData) => void;
  previousItemData: IuploadedItemData;
  uncheckSharedDataForToolReferenceItems: () => void;
  sharedDataForToolReferenceItemsIsChecked: boolean;
  sharedData?: IsharedDataForToolReferenceItems;
  serialIsRepeatedMessage?: string;
  countriesData?: IUploadCountries[];
  listDefaultAddress?: IListDefaultLocationAddress;
  refreshAddress?: () => void;
  index: number;
  itemFormIdentifier: string;
}

const ProductItemDataForm = (props: IproductItemDataFormProps) => {
  const {
    handleUpdateThisItemData,
    previousItemData,
    sharedData,
    sharedDataForToolReferenceItemsIsChecked,
    uncheckSharedDataForToolReferenceItems,
    serialIsRepeatedMessage,
    countriesData,
    listDefaultAddress,
    refreshAddress,
    index,
    itemFormIdentifier
  } = props;
  const { t } = useTranslation();
  const [itemCondition, setItemCondition] = useState<TproductItemCondition>();
  const [itemData, setItemData] = useState<IuploadedItemData>();
  const [serialAlreadyExistsError, setSerialAlreadyExistsError] = useState('');
  const [validatedSerial, setValidatedSerial] = useState('');
  const [itemCountry, setItemCountry] = useState<IUploadCountries>();
  const [selectedEmployee, setSelectedEmployee] = useState<Iemployee>();
  const [selectLocation, setSelectLocation] = useState<ILocationOptions>();

  const [openEditOfficeAddress, setOpenEditOfficeAddress] = useState<boolean>(false);
  const [openEditOtherAddress, setOpenEditOtherAddress] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>('');
  const [showAddressSelect, setShowAddressSelect] = useState<boolean>(false);
  const [selectOtherAddress, setSelectOtherAddress] = useState<ILocationAddress>();
  const [assingDefaultOtherAddress, setAssingDefaultOtherAddress] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:uploadToolSteps:';
  const productConditionRawOptions = [
    { value: 'Muy mal', label: t(`${i18nLanguageKey}stateProduct:value1`) },
    { value: 'Mal', label: t(`${i18nLanguageKey}stateProduct:value2`) },
    { value: 'Regular', label: t(`${i18nLanguageKey}stateProduct:value3`) },
    { value: 'Bien', label: t(`${i18nLanguageKey}stateProduct:value4`) },
    { value: 'Excelente', label: t(`${i18nLanguageKey}stateProduct:value5`) }
  ];

  const locationToolsOption: ILocationOptions[] = [
    { optionId: 1, label: t(`${i18nLanguageKey}locationToolsOption:optionId1`) },
    { optionId: 2, label: t(`${i18nLanguageKey}locationToolsOption:optionId2`) },
    { optionId: 3, label: t(`${i18nLanguageKey}locationToolsOption:optionId3`) },
    { optionId: 4, label: t(`${i18nLanguageKey}locationToolsOption:optionId4`) }
  ];
  const productConditionUIOption = productConditionRawOptions.map((condition, i) => {
    return (
      <div
        key={`condition-${i}-${condition}`}
        className="optionContainer"
        onClick={() => {
          setItemCondition(condition?.value as TproductItemCondition);
          handleUpdateProductCondition(condition?.value as TproductItemCondition);
        }}
      >
        {condition.label}
      </div>
    );
  });

  const handleUpdateSerial = (inputText: string) => {
    const updatedData = {
      serial: inputText || '',
      productCondition: itemCondition || itemData?.productCondition || '',
      warranty: itemData?.warranty || '',
      country: itemData?.country || undefined,
      assignedEmployee: itemData?.assignedEmployee,
      optionId: itemData?.optionId || selectLocation?.optionId || 0,
      otherAddressDetail: itemData?.otherAddressDetail || selectOtherAddress
    };
    setItemData(updatedData);
  };

  const handleUpdateProductCondition = (itemCondition: TproductItemCondition) => {
    const updatedData = {
      serial: itemData?.serial || '',
      productCondition: itemCondition || itemData?.productCondition || '',
      warranty: itemData?.warranty || '',
      country: itemData?.country || undefined,
      assignedEmployee: itemData?.assignedEmployee,
      optionId: itemData?.optionId || selectLocation?.optionId || 0,
      otherAddressDetail: itemData?.otherAddressDetail || selectOtherAddress
    };
    sharedDataForToolReferenceItemsIsChecked && uncheckSharedDataForToolReferenceItems();
    setItemData(updatedData);
  };

  const refreshNewLocation = async (type?: 'office' | 'other') => {
    if (type === 'other' || type === 'office') {
      const allList = await servicesDefaultLocationAddress(Number(formatOrgData()?.organizationId));
      if (allList && allList.others && type === 'other') {
        const lastOtherAddress = allList.others[0];
        return lastOtherAddress;
      }
      if (allList && allList.offices && type === 'office') {
        const lastOfficeAddress = allList.offices[0];
        return lastOfficeAddress;
      }
    }
  };
  const handleUpdateOptionIdLocation = async (
    optionId: number,
    address?: ILocationAddress,
    type?: 'office' | 'other'
  ) => {
    const newOtherAddress = await refreshNewLocation(type);
    const updatedData = {
      serial: itemData?.serial || '',
      productCondition: itemCondition || itemData?.productCondition || '',
      warranty: itemData?.warranty || '',
      country: itemCountry || itemData?.country || undefined,
      assignedEmployee: itemData?.assignedEmployee,
      optionId: optionId,
      otherAddressDetail:
        type === 'other' ? itemData?.otherAddressDetail || selectOtherAddress || address || newOtherAddress : undefined
    };
    sharedDataForToolReferenceItemsIsChecked && uncheckSharedDataForToolReferenceItems();
    setItemData(updatedData);
  };

  const handleUpdateOtherAddress = (address: ILocationAddress) => {
    const updatedData = {
      serial: itemData?.serial || '',
      productCondition: itemCondition || itemData?.productCondition || '',
      warranty: itemData?.warranty || '',
      country: itemCountry || itemData?.country || undefined,
      assignedEmployee: itemData?.assignedEmployee,
      optionId: itemData?.optionId || selectLocation?.optionId || 0,
      otherAddressDetail: address
    };

    sharedDataForToolReferenceItemsIsChecked && uncheckSharedDataForToolReferenceItems();

    setItemData(updatedData);
  };

  const handleUpdatedAssignedEmployee = () => {
    const assignedEmployeeCountryData = {
      id: selectedEmployee?.country?.id || 0,
      flag: selectedEmployee?.country?.flag || '',
      code: selectedEmployee?.country?.code || '',
      name: selectedEmployee?.country?.name || ''
    };
    if (selectedEmployee) setItemCountry(assignedEmployeeCountryData);
    const updatedData = {
      serial: itemData?.serial || previousItemData?.serial || '',
      productCondition: itemCondition || itemData?.productCondition || previousItemData?.productCondition || '',
      warranty: itemData?.warranty || previousItemData?.warranty || '',
      country: assignedEmployeeCountryData?.id
        ? assignedEmployeeCountryData
        : itemData?.country || previousItemData?.country,
      assignedEmployee: selectedEmployee,
      optionId: itemData?.optionId || selectLocation?.optionId || previousItemData?.optionId || 0,
      otherAddressDetail: itemData?.otherAddressDetail || selectOtherAddress || previousItemData.otherAddressDetail
    };
    sharedDataForToolReferenceItemsIsChecked && uncheckSharedDataForToolReferenceItems();

    setItemData(updatedData);
  };

  const validateExistingSerial = async (serial: string) => {
    if (!serial) return;
    try {
      const response = await validateExistingSerialNumber(serial);
      if (response) {
        setValidatedSerial(serial);
        setSerialAlreadyExistsError('Este serial ya existe en tu base de datos');
      }
    } catch (error) {
      displayErrorNotification();
    }
  };

  const handleSerialBlur = (inputText: string) => {
    setValidatedSerial('');
    setSerialAlreadyExistsError('');
    if (!inputText) return;
    validateExistingSerial(inputText);
  };

  const errorPersists = validatedSerial === itemData?.serial;
  const getSerialError = () => {
    if (serialIsRepeatedMessage) return serialIsRepeatedMessage;
    if (!errorPersists) return undefined;
    if (serialAlreadyExistsError) return serialAlreadyExistsError;
  };

  const generateDefaultOfficeAddress = () => {
    const filterAddress = listDefaultAddress?.offices?.find(element => element.country?.code === itemCountry?.code);
    if (filterAddress) {
      return filterAddress?.address;
    }
    return null;
  };

  const defaultOtherAddress = () => {
    const filterAddress = listDefaultAddress?.others?.find(element => element.country?.code === itemCountry?.code);
    if (filterAddress && assingDefaultOtherAddress) {
      setSelectOtherAddress(filterAddress);
      setSearchString(filterAddress?.locationName || '');
      setAssingDefaultOtherAddress(false);
    }
    return null;
  };

  const generateOptions = () => {
    if (locationToolsOption && locationToolsOption.length > 0) {
      return locationToolsOption.map(item => {
        return (
          <div key={`id-option-${item.optionId}`} className="textStyle">
            <div
              className="titleText"
              onClick={() => {
                if (item.optionId === 1 && !generateDefaultOfficeAddress()) {
                  setSelectedEmployee(undefined);
                  setSelectOtherAddress(undefined);
                  setSearchString('');
                  setOpenEditOfficeAddress(true);
                  handleUpdateOptionIdLocation(item.optionId);
                } else {
                  setSelectedEmployee(undefined);
                  setSelectOtherAddress(undefined);
                  setSearchString('');
                  handleUpdateOptionIdLocation(item.optionId);
                  setSelectLocation(item);
                }
              }}
            >
              {item.label}
            </div>
            {item.optionId === 1 && <div className="addressDetail">{generateDefaultOfficeAddress()}</div>}
            {item.optionId !== 4 && item.optionId !== 1 && (
              <>
                <div
                  className="editLabel"
                  onClick={() => {
                    handleUpdateOptionIdLocation(item.optionId);
                    setSearchString('');
                    setSelectLocation(item);
                    setSelectedEmployee(undefined);
                    setSelectOtherAddress(undefined);
                  }}
                >
                  {t(`${i18nLanguageKey}selectLabel`)}
                </div>
              </>
            )}
            {item.optionId === 1 && (
              <>
                {generateDefaultOfficeAddress() ? (
                  <div
                    className="editLabel"
                    onClick={() => {
                      setOpenEditOfficeAddress(true);
                      handleUpdateOptionIdLocation(item.optionId);
                      setSelectOtherAddress(undefined);
                      setSelectLocation(item);
                      setSelectedEmployee(undefined);
                    }}
                  >
                    {t(`${i18nLanguageKey}editLabel`)}
                  </div>
                ) : (
                  <div
                    className="editLabel"
                    onClick={() => {
                      setOpenEditOfficeAddress(true);
                      handleUpdateOptionIdLocation(item.optionId);
                      setSelectOtherAddress(undefined);
                      setSelectLocation(item);
                      setSelectedEmployee(undefined);
                    }}
                  >
                    {t(`${i18nLanguageKey}newAddBlue`)}
                  </div>
                )}
              </>
            )}
          </div>
        );
      });
    }
    return [];
  };

  const generateOtherAddressOptions = () => {
    const myOtherAddress = listDefaultAddress?.others.filter(
      element => element.country?.code === itemCountry?.code && element?.locationName
    );
    const searchWord = searchString
      ? myOtherAddress?.filter(element =>
          element.locationName?.toLocaleLowerCase()?.includes(searchString.toLocaleLowerCase())
        )
      : myOtherAddress;
    if (
      listDefaultAddress &&
      listDefaultAddress?.others &&
      listDefaultAddress.others.length > 0 &&
      myOtherAddress &&
      myOtherAddress.length > 0 &&
      searchWord &&
      searchWord.length > 0
    ) {
      return searchWord.map((item, index) => {
        if (item?.locationName) {
          return (
            <Fragment key={`item-${item.id}`}>
              <div
                className="adddressOptions"
                onClick={() => {
                  handleUpdateOtherAddress(item);
                  setSelectOtherAddress(item);
                  setSearchString(item?.locationName || '');
                  setShowAddressSelect(false);
                }}
              >
                {item?.locationName}
              </div>
              {searchWord.length === index + 1 && (
                <div
                  className="newAddBlue"
                  onClick={() => {
                    setOpenEditOtherAddress(true);
                  }}
                >
                  {t(`${i18nLanguageKey}newAddBlue`)}
                </div>
              )}
            </Fragment>
          );
        }
        return <></>;
      });
    }
    return [
      <div
        className="newAddBlue"
        key="item-not-found-address"
        onClick={() => {
          setOpenEditOtherAddress(true);
        }}
      >
        {t(`${i18nLanguageKey}newAddBlue`)}
      </div>
    ];
  };

  const existLocationId = (locationId: number) => {
    if (listDefaultAddress && listDefaultAddress?.others?.length > 0 && locationId) {
      const otherAddress = listDefaultAddress?.others.find(element => element?.id === locationId);
      if (otherAddress) {
        setSelectOtherAddress(otherAddress);
        setSearchString(otherAddress?.locationName || '');
        setShowAddressSelect(false);
        handleUpdateOtherAddress(otherAddress);
        handleUpdateOptionIdLocation(2, otherAddress);
      }
    }
  };

  const updateList = async () => {
    if (refreshAddress) {
      await refreshAddress();
    }
  };
  useEffect(() => {
    previousItemData && setItemData(previousItemData);
    previousItemData?.assignedEmployee && setSelectedEmployee(previousItemData.assignedEmployee);
    if (previousItemData && previousItemData?.country) {
      setItemCountry(previousItemData.country);
    }
    if (previousItemData && previousItemData?.optionId) {
      const myLocation = locationToolsOption.find(element => previousItemData.optionId === element.optionId);
      if (myLocation) {
        setSelectLocation(myLocation);
      }
    }

    if (previousItemData && previousItemData?.otherAddressDetail) {
      setSelectOtherAddress(previousItemData?.otherAddressDetail);
      setSearchString(previousItemData?.otherAddressDetail?.locationName || '');
    }
    if (previousItemData?.country) {
      setItemCountry(previousItemData?.country);
    }
    if (previousItemData?.productCondition) {
      const condition = previousItemData?.productCondition as TproductItemCondition;
      setItemCondition(condition);
    }
  }, []);

  useEffect(() => {
    if (selectedEmployee) return;
    const dataWithShareDateAndCondition = {
      serial: itemData?.serial || previousItemData?.serial || '',
      productCondition: sharedData?.condition || itemData?.productCondition || previousItemData?.productCondition || '',
      warranty: itemData?.warranty || previousItemData?.warranty || '',
      country: sharedData?.country || itemData?.country || previousItemData?.country || undefined,
      optionId: sharedData?.optionId || itemData?.optionId || previousItemData?.optionId || 0
    };
    if (sharedData?.country) {
      setItemCountry(sharedData?.country);
    }
    if (sharedData?.optionId) {
      const myLocation = locationToolsOption.find(element => sharedData.optionId === element.optionId);
      if (myLocation) {
        if (index !== 0) {
          setSelectedEmployee(undefined);
          setSearchString('');
          setSelectOtherAddress(undefined);
        }

        setSelectLocation(myLocation);
      }
    }
    if (sharedData?.condition) {
      const condition = sharedData?.condition as TproductItemCondition;
      setItemCondition(condition);
    }
    setItemData(dataWithShareDateAndCondition);
  }, [sharedData]);

  useEffect(() => {
    itemData && handleUpdateThisItemData(itemData);
  }, [itemData]);

  useEffect(() => {
    handleUpdatedAssignedEmployee();
  }, [selectedEmployee]);

  useEffect(() => {
    defaultOtherAddress();
  }, [listDefaultAddress]);

  return (
    <div className="productItemDataForm">
      {itemCountry && itemCountry.code && (openEditOfficeAddress || openEditOtherAddress) && (
        <LocationToolsFlux
          listOfAllCountries={[itemCountry]}
          closeLocationModal={() => {
            setOpenEditOfficeAddress(false);
            setOpenEditOtherAddress(false);
          }}
          updateCardListData={(e, existId) => {
            if (existId) {
              // edit
              existLocationId(Number(existId));
              setAssingDefaultOtherAddress(true);
              setSelectLocation({ optionId: 2, label: t(`${i18nLanguageKey}productItemDataForm:optionId2`) });
            } else {
              updateList();
              if (e === 'officeEdit') {
                setSelectLocation({
                  optionId: 1,
                  label: t(`${i18nLanguageKey}productItemDataForm:optionId1`)
                });
                handleUpdateOptionIdLocation(1, undefined, 'office');
              }
              if (e === 'otherAddressEdit') {
                setAssingDefaultOtherAddress(true);
                setSelectLocation({
                  optionId: 2,
                  label: t(`${i18nLanguageKey}productItemDataForm:optionId2`)
                });
                handleUpdateOptionIdLocation(2, undefined, 'other');
              }
            }
          }}
          defaultCountryCode={itemCountry.code}
          officeDirect={openEditOfficeAddress}
          otherAddressDirect={openEditOtherAddress}
        />
      )}
      <NudosTextInput
        inputHeight={28}
        label="Serial*"
        placeholder="1288373367363"
        isFilled={!!itemData?.serial}
        componentSize="small"
        handleChange={handleUpdateSerial}
        defaultValue={previousItemData.serial}
        handleBlur={handleSerialBlur}
        errorText={getSerialError()}
      />
      <NudosSelectDropdown
        heightDropDown={28}
        label={t(`${i18nLanguageKey}productItemDataForm:sizeState`)}
        isFilled={!!itemCondition || !!previousItemData.productCondition}
        componentSize="small"
        selectOptions={productConditionUIOption}
        currentValueOrplaceholder={
          itemData?.productCondition ||
          itemCondition ||
          previousItemData.productCondition ||
          t(`${i18nLanguageKey}stateProduct:value4`)
        }
      />
      {countriesData && (
        <NudosCountryDropdown
          charactersLimit={6}
          currentValue={itemCountry}
          countriesList={countriesData || []}
          customPlaceholder={t('nodi:nudosPhoneInputPlaceholder')}
          updateCurrentValueCallback={item => {
            setItemCountry(item);
            setSelectLocation(undefined);
          }}
        />
      )}
      {itemCountry && (
        <>
          <div className="containerSelectLocation">
            <NudosSelectDropdown
              label={t(`${i18nLanguageKey}productItemDataForm:toolLocation:label`)}
              isFilled={!!selectLocation}
              currentValueOrplaceholder={
                selectLocation?.label || t(`${i18nLanguageKey}productItemDataForm:toolLocation:placeholder`)
              }
              selectOptions={generateOptions()}
              heightDropDown={28}
              customContainer="containerStyle"
            />
          </div>

          {selectLocation?.optionId === 1 && !!generateDefaultOfficeAddress() && (
            <div className="containerOfficeText">{generateDefaultOfficeAddress()}</div>
          )}
          {selectLocation?.optionId === 2 && (
            <div className="containerOtherAddress">
              <NudosGenericSearchBar
                customHeight="28px"
                searchString={searchString}
                setSearchString={setSearchString}
                searchedOptions={generateOtherAddressOptions()}
                isSelectOpen={showAddressSelect}
                setIsSelectOpen={setShowAddressSelect}
                customPlaceholder={t(`${i18nLanguageKey}nudosGenericSearchBar`)}
                customClassName="genericSelectClass"
                genericSelectClass="posititonClass"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                handleChangeSelectedOption={() => {}}
                handleInputSearch={() => {
                  setSelectOtherAddress(undefined);
                }}
                showSelectOptionsOnFocus
                loadOfTools
              />
            </div>
          )}
          {selectLocation?.optionId === 3 && (
            <div className="containerEmployeesSearch">
              <NudosEmployeeSearchBar
                customWidth={180}
                customHeight={28}
                uniqueIdentifier={itemFormIdentifier}
                selectedEmployee={selectedEmployee}
                setSelectedEmployee={setSelectedEmployee}
                customPlaceholder={t(
                  `${i18nLanguageKey}productItemDataForm:containerEmployeesSearch:customPlaceholder`
                )}
                label={t(`${i18nLanguageKey}productItemDataForm:containerEmployeesSearch:label`)}
                countriesFilter={itemCountry?.name ? [itemCountry?.name] : undefined}
                excludeEmployeesWithoutLocation
                originDataForRedirection={{
                  origin: '/nodi/upload-external-tools',
                  returnTextKey: 'externalToolsRedirectionText',
                  createFromSpecificCountry: itemCountry?.name,
                  uniqueEmployeeSearchbarIdentifier: itemFormIdentifier
                }}
              />
            </div>
          )}

          {selectLocation?.optionId === 4 && itemCountry && (
            <div className="containerOfficeText">{itemCountry?.name}</div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductItemDataForm;
