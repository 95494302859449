import { FC, useState } from 'react';
import './dinamicProfiles.scss';
interface IDinamicProfilesDetails {
  text?: string;
  hoverText?: string;
}

interface IDinamicProfiles {
  dataProfile: IDinamicProfilesDetails;
  elementsNumber: number;
}

const DinamicProfiles: FC<IDinamicProfiles> = ({ dataProfile, elementsNumber }: IDinamicProfiles) => {
  const maxWidth = 100 / elementsNumber;
  const [showOver, setShowOver] = useState<boolean>();
  return (
    <div
      id="dinamicProfiles"
      onMouseEnter={() => {
        setShowOver(true);
      }}
      onMouseOver={() => {
        setShowOver(true);
      }}
      onMouseLeave={() => {
        setShowOver(false);
      }}
      style={{
        maxWidth: `${maxWidth || 100}%`,
        border: showOver ? '1px solid #FF664E' : '',
        background: showOver ? '#FF664E' : ''
      }}
    >
      <div className={`normalTextProfile truncate ${showOver ? 'hoverText' : ''}`}> {dataProfile?.text}</div>
      {showOver && dataProfile?.hoverText && <div className="hoverProfile">{dataProfile?.hoverText}</div>}
    </div>
  );
};

export default DinamicProfiles;
