import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#a)" fill="#ff664e" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m5.947 11.972 6.469-6.125c1.075-1.018.992-2.82-.184-3.976C11.085.743 9.335.666 8.322 1.698L2.03 8.121s1.394.554 2.374 1.517c.98.963 1.543 2.334 1.543 2.334Z"
      />
      <path d="m1.524 13.114 4.42-1.138s-.563-1.37-1.542-2.334c-.979-.964-2.372-1.52-2.372-1.52l-1.162 4.33c-.101.377.277.76.656.662Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const EditPhotoIcon = memo(ForwardRef);
export default EditPhotoIcon;
