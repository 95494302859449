import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11} height={11} fill="none" ref={ref} {...props}>
    <path
      stroke={props?.stroke || '#383838'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.585 5.449H8.42M5.466 8.396c1.293-1.06 1.94-1.706 2.947-2.947C7.405 4.207 6.759 3.56 5.466 2.502"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconArrow = memo(ForwardRef);
export default IconArrow;
