import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g stroke="#ff664e">
      <path
        d="M8.055 6.008a2.056 2.056 0 0 0 .73-3.98M4.595 5.6a2.229 2.229 0 1 0 0-4.458 2.229 2.229 0 0 0 0 4.458Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.009 8.731c1.09.727.354 2.127-.956 2.127h-4.92c-1.31 0-2.045-1.4-.955-2.127a6.14 6.14 0 0 1 3.415-1.03A6.14 6.14 0 0 1 8.01 8.73Z" />
      <path d="M6.863 10.858h3.118c1.209 0 1.888-1.292.882-1.962a5.68 5.68 0 0 0-.882-.48" strokeLinecap="round" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MoreEmployeesIcon = memo(ForwardRef);
export default MoreEmployeesIcon;
