import { FC, useEffect, useState } from 'react';
import { ButtonToEditQuantities, DinamicPills, DinamicProfiles } from '../../../../../components';
import { useHistory } from 'react-router-dom';
import { isRouteMyLocation } from '../../../../../utils/getPathRoute';
import { nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import IconGreenThunder from '../../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { NudosButton } from '../../../../../components/NudosComponents';
import { IProductItemList } from '../../../../../types/catalogue';
import useStoreCountryState from '../../../../../state/useStoreCountryState';
import './ProductEcommerceCard.scss';

export interface ObjectKey<T> {
  [key: string]: T;
}

interface IFormatPills {
  name: string;
  value?: string;
}

interface ICardData {
  dataProductCard?: IProductItemList;
}

const ProductEcommerceCard: FC<ICardData> = ({ dataProductCard }: ICardData) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { storeCountry } = useStoreCountryState();
  const { stateSubscription } = useStateSubscriptions();

  const [formatPills, setFormatPills] = useState<IFormatPills[]>();

  const { countryCode } = storeCountry || {};
  const priceLimit = Number(dataProductCard?.prices?.pricePrimeUsd) >= 170;
  const { levelSubscription } = stateSubscription;
  const freePlatform = levelSubscription === 'Free';
  const originSearcher = !!isRouteMyLocation('search', 2);
  const translationKey = 'nodi:checkout:newProductCard:';
  const translationsProductEcommerceCard = 'ecommerce:productEcommerceCard:';
  const countryCodeParam = `?countryCode=${countryCode}`;
  const productDetailsURL = `/catalogue/product/${dataProductCard?.productId}${countryCodeParam}${
    originSearcher ? '&originSeeker=true' : ''
  }`;

  const generatePills = () => {
    const pills =
      typeof dataProductCard?.pills === 'string' ? JSON.parse(dataProductCard?.pills) : dataProductCard?.pills;
    if (pills) {
      const myKeys = Object.keys(pills);
      const myPillsList = [];
      if (myKeys.length > 0) {
        for (const key of myKeys) {
          if (!key.includes('Perfil') && !key.includes('Gama')) {
            const objetPill = { name: key, value: pills?.[key] };
            if (key === 'Cargo') {
              myPillsList.unshift(objetPill);
            } else {
              myPillsList.push(objetPill);
            }
          }
        }
        if (myPillsList && myPillsList.length > 0) {
          setFormatPills([...myPillsList]);
        }
      }
    }
  };

  const goToProductDetails = () => {
    segmentTrackEvent({
      catalogueProductCardClick: {
        CategoryName: `${dataProductCard?.categoryName}`,
        CountryName: storeCountry?.countryName || '',
        SKUId: `${dataProductCard?.sku}`,
        TypeOfPlatform: freePlatform ? 'Free' : 'Prime',
        ProductName: dataProductCard?.productName || ''
      }
    });
    if (dataProductCard?.productId) push(productDetailsURL);
  };

  useEffect(() => {
    generatePills();
  }, [dataProductCard]);

  return (
    <section id="productEcommerceCard">
      <div className="containerProductCard">
        <div className="descriptionCard">
          <div className="containerDescription">
            <div className="descriptionTitle">
              <div className="textDescriptionTitle">{dataProductCard?.productName}</div>
              <div className="imgDescriptionTitle">
                {dataProductCard?.brandImage && (
                  <img alt="img-brand" className="imgBrand" src={dataProductCard?.brandImage} />
                )}
              </div>
            </div>
            <div className="descriptionPills">
              {formatPills &&
                formatPills.length > 0 &&
                formatPills.map(element => {
                  return (
                    <DinamicPills
                      key={`product-pull-${element.name}`}
                      elementsNumber={formatPills.length}
                      dataPills={{ text: element?.value, hoverText: element.name }}
                    />
                  );
                })}
            </div>
            <div className="descriptionProfiles">
              {dataProductCard?.profiles &&
                dataProductCard?.profiles?.length > 0 &&
                dataProductCard.profiles.map(item => {
                  return (
                    <DinamicProfiles
                      key={`profile-item-${dataProductCard?.productId}-${item}`}
                      dataProfile={{ text: item, hoverText: t(`${translationKey}profileHoverText`) }}
                      elementsNumber={dataProductCard?.profiles?.length || 0}
                    />
                  );
                })}
            </div>

            <div className="pricesCardContainer">
              <div className="priceLabel">{t(`${translationsProductEcommerceCard}price`)}</div>
              <div className="containerGeneralPrice">
                {priceLimit ? (
                  <>
                    {freePlatform ? (
                      <div className="freeContainer">
                        <div className="blackPrice">{`${nudosFormatCurrency({
                          value: Number(dataProductCard?.prices?.priceWithoutPrimeUsd),
                          maximumFractionDigits: 2
                        })} USD`}</div>
                        <div className="grayPriceCardContainer">
                          <div className="textGrayPrice">(</div>
                          <div className="containerIconPrice">
                            <IconGreenThunder fill="#7B61FF" />
                          </div>
                          <div className="textGrayPrice line-through">
                            {`${nudosFormatCurrency({
                              value: Number(dataProductCard?.prices?.pricePrimeUsd),
                              maximumFractionDigits: 2
                            })} USD`}
                            )
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="primeContainer">
                        <div className="blackPriceCardContainer">
                          <div className="iconContainer">
                            <IconGreenThunder fill="#7B61FF" />
                          </div>
                          <div className="blackPrice">
                            {`${nudosFormatCurrency({
                              value: Number(dataProductCard?.prices?.pricePrimeUsd),
                              maximumFractionDigits: 2
                            })} USD`}
                          </div>
                        </div>
                        <div className="grayPrice line-through">{`(${nudosFormatCurrency({
                          value: Number(dataProductCard?.prices?.priceWithoutPrimeUsd),
                          maximumFractionDigits: 2
                        })} USD)`}</div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="containerSimplePrice">
                    <div className="blackPrice">{`${nudosFormatCurrency({
                      value: Number(dataProductCard?.prices?.pricePrimeUsd),
                      maximumFractionDigits: 2
                    })} USD`}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="previewProduct">
          <div className="containerMinImg">
            <img className="productImg" alt="product-img" src={dataProductCard?.imageUrl || ''} />
          </div>
          <div className="priceContainer">
            <div className="buttonsProductContainer">
              <div className="addButtonContainer">
                {dataProductCard && <ButtonToEditQuantities dataProductCard={dataProductCard} />}
              </div>
              <NudosButton
                customWidth="80px"
                customHeight="28px"
                handleClick={goToProductDetails}
                customFontStyles={{ fontWeight: '400' }}
                buttonText={t(`${translationsProductEcommerceCard}goToProduct`)}
                colorPalette="secondary"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductEcommerceCard;
