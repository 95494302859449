import { SuccessBlueIcon } from '../../../../../assets/images/views/Admin/dashboard';
import useStateZoomAlert from '../../../../../state/useZoomAlertState';
import { useTranslation, Trans } from 'react-i18next';
import './ZoomAlert.scss';

const ZoomAlert = () => {
  const { showZoomAlert, setShowZoomAlert } = useStateZoomAlert();
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:dashboard:zoomAlert:';
  return (
    <>
      {showZoomAlert && !sessionStorage.getItem('hiddenShowAlert') && (
        <div id="zoomAlert">
          <div className="boxZoolAlert">
            <div className="titleZoomAlert">{t(`${i18nLanguageKey}titleZoomAlert`)}</div>
            <div className="subtitleZoomAlert">
              <Trans
                i18nKey={t(`${i18nLanguageKey}subtitleZoomAlert`)}
                components={{ 1: <span className="specialText"></span> }}
              />
              <span className="specialText"> 140%</span>
            </div>
            <div className="floatButtonBox">
              <button
                className="customBlueButton"
                onClick={() => {
                  sessionStorage.setItem('hiddenShowAlert', 'true');
                  setShowZoomAlert(false);
                }}
              >
                <div className="textButton">{t(`${i18nLanguageKey}customBlueButton`)}</div>
                <div className="containerIcon">
                  <SuccessBlueIcon />
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ZoomAlert;
