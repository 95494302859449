import { useEffect, useState } from 'react';
import { ICountryDetail } from '../../../types/countries';
import NudosSelectDropdown from '../NudosSelectDropdown/NudosSelectDropdown';
import { TcomponentSize } from '../../../types/global';
import { useTranslation } from 'react-i18next';
import './NudosCountrySelect.scss';

/**
 * @property {ICountryDetail[]} listOfCountries  - REQUIRED, an array of objects, each with the data of a country
 * @property {string} label - Optional, a label to display on top of the country input
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "extraSmall", "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {string} errorText - Optional, the text to be shown if theres an error with the country select. If an error is passed the style of the component will change to reflect that.
 * @property {boolean} isDisabled - Optional, if a value is provided the input would not allowed new searches. Styles would reflect its status as disabled.
 * @property {boolean} showSkeletonLoader - Optional, boolean indicating if the component is loading and therefore the skeleton loader is to be shown. Default is false.
 * @property {function(ICountryDetail): void} handleCountryChange - Optional, a callback to execute upon changing the selected country
 * @property {ICountryDetail} defaultCountry - Optional, for cases when there is a default value, corresponds to the object with the information of the default country
 * @property {string} styleLabel - Optional, a custom classname for styling the component label
 */

const NudosCountrySelect = ({
  listOfCountries,
  label,
  componentSize,
  errorText,
  isDisabled,
  showSkeletonLoader = false,
  handleCountryChange,
  defaultCountry,
  styleLabel,
  beforeSelectAction
}: InudosCountrySelect) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const filledStyles = selectedCountry || defaultCountry ? 'filled' : '';
  const errorStyles = errorText && errorText.length > 0 ? 'error' : '';
  const disabledStyles = isDisabled ? 'disabled' : '';
  const componentSizeStyles = componentSize ? componentSize : '';
  const labelStyles = styleLabel ? styleLabel : '';

  const handleSelectOption = (selectedOption: ICountryDetail) => {
    setSelectedCountry(selectedOption);
    if (beforeSelectAction) {
      beforeSelectAction();
    }
  };

  const formOptions = (countryOptions?: ICountryDetail[]) => {
    if (!countryOptions) return [];
    return countryOptions.map((val, i) => {
      return (
        <div key={`countryOption${i}`} className="optionContainer" onClick={() => handleSelectOption(val)}>
          <div className="flag" style={{ backgroundImage: `url(${val.flag})` }} />
          <div className="countryFirstLetters">{`${val.name?.slice(0, 3)}`}</div>
        </div>
      );
    });
  };

  const formPlaceholder = (selectedCountry?: ICountryDetail) => {
    if (!selectedCountry) return;
    return (
      <div className="countryPlaceholderOrCurrentValue">
        <div className="flag" style={{ backgroundImage: `url(${selectedCountry.flag})` }}>
          {!(defaultCountry || selectedCountry) && <div className="flagFilter" />}
        </div>
        <div className="countryFirstLetters">{`${selectedCountry.name?.slice(0, 3)}`}</div>
      </div>
    );
  };

  const getSkeletonLoader = () => {
    return (
      <div className={`nudosCountrySelectSkeletonLoader ${componentSizeStyles}`}>
        {label && <div className={`labelSkeleton ${labelStyles}`}>{label}</div>}
        <div className="countryDropdownSkeletonLoder animationLoader" />
      </div>
    );
  };

  useEffect(() => {
    if (!handleCountryChange) return;
    selectedCountry && handleCountryChange(selectedCountry);
  }, [selectedCountry]);

  useEffect(() => {
    defaultCountry && setSelectedCountry(defaultCountry);
  }, [defaultCountry]);

  return (
    <>
      {showSkeletonLoader && getSkeletonLoader()}
      {!showSkeletonLoader && (
        <div className={`nudosCountrySelect ${componentSizeStyles} ${errorStyles} ${disabledStyles} ${filledStyles}`}>
          {label && <label className={labelStyles}>{label}</label>}
          <div className="countryDropdown">
            <NudosSelectDropdown
              componentSize={componentSize}
              selectOptions={formOptions(listOfCountries)}
              currentValueOrplaceholder={
                formPlaceholder(selectedCountry) ||
                formPlaceholder(defaultCountry) ||
                t('nodi:nudosPhoneInputPlaceholder')
              }
              isFilled={!!selectedCountry || !!defaultCountry}
              errorText={errorText}
              hideErrorText={true}
              isDeactivated={isDisabled}
            />
          </div>
          {errorText && <div className="errorText">{errorText}</div>}
        </div>
      )}
    </>
  );
};

export default NudosCountrySelect;

export interface InudosCountrySelect {
  listOfCountries?: ICountryDetail[];
  label?: string;
  componentSize?: TcomponentSize;
  errorText?: string;
  isDisabled?: boolean;
  showSkeletonLoader?: boolean;
  handleCountryChange?: (country: ICountryDetail) => void;
  defaultCountry?: ICountryDetail;
  styleLabel?: string;
  beforeSelectAction?: () => void;
}
