import { FC, useEffect, useState } from 'react';
import { MinDownloadIcon } from '../../../../assets/images/components/NudosComponents';
import { FooterBanner, InformationText, LogisticsQuoteByTool, TotalDescription } from './Components';
import { ILogisticQuoteGeneralData } from '../../../../types/logisticsServicesModule.types';
import { segmentTrackEvent } from '../../../../utils/segment';
import useStateSubscriptions from '../../../../state/useStateSubscriptions';
import { TlogisticsOriginOrDestinationData } from '../../../../types/requestLogisticsModule';
import { IDetailsQuote, TstoragePlace } from '../../../../types/assignationFluxes';
import { generateLogisticsQuoteDataPdf } from '../../../../services/logisticsServicesModule.services';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import { GenericLoader } from '../../../Admin/dashboard/components';
import { IconWarning } from '../../../../assets/DesignSystem/SVGComponents';
import { locationFormat } from '../../../../utils/locationFormat';

import useStatusFixedTooltip from '../../../../state/fixedTooltipsState';

import './LogisticServicePrincingQuote.scss';

interface ILogisticServicePrincingQuote {
  logisticServiceQuoteData?: ILogisticQuoteGeneralData;
  logisticsType?: 'assignment' | 'unassignment';
  originData?: TlogisticsOriginOrDestinationData;
  detinationData?: TlogisticsOriginOrDestinationData;
  destinationPlace?: TstoragePlace;
  originPlace?: TstoragePlace;
  detailsQuote?: IDetailsQuote;
}

const LogisticServicePrincingQuote: FC<ILogisticServicePrincingQuote> = ({
  logisticServiceQuoteData,
  logisticsType,
  originData,
  detinationData,
  destinationPlace,
  originPlace,
  detailsQuote
}: ILogisticServicePrincingQuote) => {
  const platformInfo = useStateSubscriptions();
  const { setShowFirstMonthTooltip, setShowLSTooltip, setShowDatesTooltip } = useStatusFixedTooltip();
  const [fileUrl, setFileUrl] = useState<string>('');
  const [generalTypePlan, setGeneralTypePlan] = useState<'Free' | 'Care' | 'Lite'>('Free');
  const [blueDownloadLoader, setBlueDownloadLoader] = useState<boolean>(false);

  const unassignmentType = logisticsType === 'unassignment';
  const initialSetData = () => {
    if (logisticServiceQuoteData?.nudosCareCouldSaving === 0) {
      setGeneralTypePlan('Care');
    } else {
      setGeneralTypePlan(logisticServiceQuoteData?.platformSubscription === 'Lite' ? 'Lite' : 'Free');
    }
  };

  const downLoadFile = async () => {
    if (fileUrl) return window.open(fileUrl, '_blank');
    setBlueDownloadLoader(true);
    if (unassignmentType) {
      segmentTrackEvent({ nodiLogisticServicesDownloadQuotationClick: null });
    }
    try {
      const body = {
        nudosLogo: logisticServiceQuoteData?.nudosLogo || '',
        currency: logisticServiceQuoteData?.currency || '',
        platformSubscription: logisticServiceQuoteData?.platformSubscription || '',
        organizationName: logisticServiceQuoteData?.organizationName || '',
        originName: logisticServiceQuoteData?.originName || '',
        destinationName: logisticServiceQuoteData?.destinationName || '',
        movement: logisticServiceQuoteData?.movement || '',
        nudosPrimeSaving: logisticServiceQuoteData?.nudosPrimeSaving || 0,
        nudosCareSaving: logisticServiceQuoteData?.nudosCareSaving || 0,
        nudosPrimeCouldSaving: logisticServiceQuoteData?.nudosPrimeCouldSaving || 0,
        nudosCareCouldSaving: logisticServiceQuoteData?.nudosCareCouldSaving || 0,
        date: logisticServiceQuoteData?.date || '',
        managementCommission: logisticServiceQuoteData?.managementCommission || 0,
        paymentCommission: logisticServiceQuoteData?.paymentCommission || 0,
        type: logisticServiceQuoteData?.type || '',
        subtotal: logisticServiceQuoteData?.subtotal || 0,
        total: logisticServiceQuoteData?.total || 0,
        tools: logisticServiceQuoteData?.tools || []
      };
      const { url } = await generateLogisticsQuoteDataPdf(body);
      if (url) {
        setFileUrl(url);
        window.open(url, '_blank');
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setBlueDownloadLoader(false);
    }
  };

  const moreInformation = async () => {
    if (unassignmentType) {
      segmentTrackEvent({
        nodiLogisticServicesMoreInfoClick: {
          DestinationCountry: detinationData?.country?.name || '',
          Fee: detailsQuote?.fee || 0,
          LogisticServiceDestination: locationFormat(destinationPlace || ''),
          LogisticServiceOrigin: 'Employee',
          LogisticServiceTotalUsd: logisticServiceQuoteData?.total || 0,
          NudosCare: platformInfo?.stateSubscription?.nudosCare,
          OriginCountry: originData?.country?.name || '',
          TypeOfPlatform: platformInfo?.stateSubscription?.levelSubscription === 'Lite' ? 'Prime' : 'Basic'
        }
      });
    }
  };

  useEffect(() => {
    initialSetData();
  }, [logisticServiceQuoteData]);

  if (!logisticServiceQuoteData) return <></>;
  return (
    <div className="LogisticServicePrincingQuote">
      <div className="boxServicePrincingQuote">
        <div className="productsContainer">
          <div className="titlePrincingQuote">Revisa y aprueba la cotización</div>
          <div className="grayAlert">
            <div className="boxGrayAlert">
              <div className="grayAlertIcon">
                <IconWarning color="#999999" />
              </div>
              <div className="textGrayAlertIcon">
                <div className="styleGrayText">
                  Es posible que haya variaciones en la cotización, ya que el área logística realiza una revisión
                  exhaustiva antes de proceder con la facturación.
                </div>
              </div>
            </div>
          </div>
          <div
            className="containerToolsDetails"
            onScroll={() => {
              setShowFirstMonthTooltip(undefined);
              setShowLSTooltip(undefined);
              setShowDatesTooltip(undefined);
            }}
            style={{ maxHeight: generalTypePlan !== 'Care' ? '154px' : '' }}
          >
            {logisticServiceQuoteData &&
              logisticServiceQuoteData?.tools &&
              logisticServiceQuoteData?.tools?.length > 0 && (
                <LogisticsQuoteByTool
                  logisticServiceQuoteData={logisticServiceQuoteData}
                  destinationPlace={destinationPlace}
                  originPlace={originPlace}
                />
              )}
          </div>
          <div className="graySpace" />
          <TotalDescription typePlan={generalTypePlan} logisticServiceQuoteData={logisticServiceQuoteData} />
          {blueDownloadLoader && (
            <div className="blueDownloadLoader">
              <GenericLoader sizeLoader={10} borderWidth={1} pb={12} />
            </div>
          )}
          {!blueDownloadLoader && (
            <div className="dowloadFileContainer">
              <div className="centerItems" onClick={() => downLoadFile()}>
                <div className="containerDownloadIcon">
                  <MinDownloadIcon stroke="#54A1FC" />
                </div>
                <div className="downloadText">Descargar</div>
              </div>
            </div>
          )}
          <InformationText typePlan={generalTypePlan} logisticServiceQuoteData={logisticServiceQuoteData} />
        </div>
        <div className="containerBan">
          <FooterBanner
            clickInformation={() => moreInformation()}
            typePlan={generalTypePlan}
            logisticServiceQuoteData={logisticServiceQuoteData}
          />
        </div>
      </div>
    </div>
  );
};

export default LogisticServicePrincingQuote;
