import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M9.76779 7.99967L12.4212 5.3463C12.7468 5.0207 12.7468 4.49278 12.4212 4.16691L11.8315 3.57721C11.5059 3.25161 10.9779 3.25161 10.6521 3.57721L7.9987 6.23058L5.34533 3.57721C5.01972 3.25161 4.4918 3.25161 4.16593 3.57721L3.57624 4.16691C3.25063 4.49252 3.25063 5.02043 3.57624 5.3463L6.22961 7.99967L3.57624 10.653C3.25063 10.9787 3.25063 11.5066 3.57624 11.8324L4.16593 12.4221C4.49154 12.7477 5.01972 12.7477 5.34533 12.4221L7.9987 9.76877L10.6521 12.4221C10.9777 12.7477 11.5059 12.7477 11.8315 12.4221L12.4212 11.8324C12.7468 11.5068 12.7468 10.9789 12.4212 10.653L9.76779 7.99967Z"
      fill="#B3B3B3"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CloseXIcon = memo(ForwardRef);
export default CloseXIcon;
