import { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { DisabledModuleTooltip, ModulesComingSoonTooltip, NodiHeader, SideBar } from '../components';
import useEcommerceControler from '../views/ecommerce/ecommerce.controller';
import { adminRoutes } from './routes';
import useStateSubscriptions from '../state/useStateSubscriptions';
import { useLogisticsServicesModuleState } from '../state/useLogisticsServicesModuleState';
import useStateOrders from '../state/useStateOrders';
import useShopStore from '../state/useShopContext';
import useStore from '../state';

const switchLiteRoutes = (
  <Switch>
    {adminRoutes.map((prop, key) => {
      if (prop.layout === 'nodi') {
        return <Route path={`/${prop.layout}${prop.path}`} component={prop.component} key={key} exact />;
      }
      return null;
    })}
  </Switch>
);

const switchFreeRoutes = (
  <Switch>
    {adminRoutes.map((prop, key) => {
      if (prop.layout === 'nodi') {
        return (
          <Route
            path={`/${prop.layout}${prop.path}`}
            component={prop?.disabledFree ? prop.disabledComponent : prop.component}
            key={key}
            exact
          />
        );
      }
      return null;
    })}
  </Switch>
);

const Admin = () => {
  const { pathname } = useLocation();
  const { user } = useEcommerceControler();
  const { setOrdersFilters } = useStateOrders();
  const { setBoardFilters, setSearchbarString } = useShopStore();
  const { setEmployeeSearchbarText, setEmployeeParamsFilters } = useStore();
  const { setLogisticsServicesFilters } = useLogisticsServicesModuleState();
  const { stateSubscription, showModuleTooltip, comingSoonTooltip } = useStateSubscriptions();

  const pathWithoutIds = pathname.replace(/\/[0-9]+/, '');

  const testing = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.returnValue = '';
  };

  const resetModuleFilters = () => {
    if (pathWithoutIds !== '/nodi/logistics-services' && pathWithoutIds !== '/nodi/logistics-services/details')
      setLogisticsServicesFilters([]);
    if (pathWithoutIds !== '/nodi/orders' && pathWithoutIds !== '/nodi/orders/details') setOrdersFilters([]);
    if (pathWithoutIds !== '/nodi/tools' && pathWithoutIds !== '/nodi/details') {
      setBoardFilters([]);
      setSearchbarString('');
    }
    if (pathWithoutIds !== '/nodi/employees' && pathWithoutIds !== '/nodi/employees/details') {
      setEmployeeSearchbarText('');
      setEmployeeParamsFilters('');
    }
  };

  useEffect(() => {
    resetModuleFilters();
  }, [pathWithoutIds]);

  useEffect(() => {
    window.addEventListener('beforeunload', testing);
    return window.removeEventListener('beforeunload', testing);
  }, []);
  return (
    <div id="layout">
      {user && showModuleTooltip && <DisabledModuleTooltip />}
      {!!comingSoonTooltip && (
        <ModulesComingSoonTooltip
          title={comingSoonTooltip?.title}
          subtitle={comingSoonTooltip?.subtitle}
          elementName={comingSoonTooltip?.elementName}
        />
      )}
      {user && <SideBar path="nodi" />}
      {user && <NodiHeader />}
      {user && stateSubscription.levelSubscription === 'Lite' && switchLiteRoutes}
      {user && stateSubscription.levelSubscription === 'Free' && switchFreeRoutes}
    </div>
  );
};

export default Admin;
