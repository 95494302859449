import { useEffect, useState } from 'react';
import { ContainerOnboardingItems } from '../../ContainerOnbordingItems';
import { NodiFreeStartModal } from '../NodiFreeStartModal';
import { ItemNodiFreeInfoOnboarding } from '../ItemNodiFreeInfoOnboarding';
import {
  dashboardStep,
  toolsStep,
  ordersStep,
  logisticServicesStep,
  faqStep,
  employeesStep,
  marketplaceStep
} from '../../../../assets/images/components/Onboarding';
import { useTranslation } from 'react-i18next';
import { updateSteps } from '../../../../services/onboarding';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import { formatOrgData } from '../../../../utils/orgFormatData';

import NudosDynamicBanner from '../../../DesignSystem/NudosDynamicBanner/NudosDynamicBanner';
import useOnboardingState from '../../../../state/useOnboardingState';
import { segmentTrackEvent } from '../../../../utils/segment';

import './OnboardingNodiFreeSteps.scss';

export interface IFreeOnboardingStep {
  stepId: number;
  title: string;
  positionId: string;
  type: string;
  polygonPosition: 'topLeft' | 'bottomLeft' | 'topRight' | 'leftCenter';
  polygonColor?: string;
  includedServicesList: string[];
  blockedServicesList: string[];
  warningMessage?: string;
  stepImage: {
    image: string;
    width: string;
    height: string;
  };
  eventName: string;
}

const OnboardingNodiFreeSteps = () => {
  const { t } = useTranslation();
  const orgData = formatOrgData();

  const { setShowFreeOnboarding, detailsFreeOnboarding } = useOnboardingState();
  const [actualStep, setActualStep] = useState<IFreeOnboardingStep>();
  const [showPrincipalModal, setShowPrincipalModal] = useState<boolean>(false);
  const [showDynamicBanner, setShowDynamicBanner] = useState<boolean>(false);

  const dynamicBannerTranslations = 'nodi:dynamicBanner:';
  const generalTranslations = 'nodi:onboarding:featureList:';
  const translationsDashboard = `${generalTranslations}dashboard:`;
  const translationsEmployees = `${generalTranslations}employees:`;
  const translationsInventory = `${generalTranslations}inventory:`;
  const translationsOrders = `${generalTranslations}orders:`;
  const translationsLogisticServices = `${generalTranslations}logisticServices:`;
  const translationsFrequentlyAskedQuestions = `${generalTranslations}frequentlyAskedQuestions:`;
  const translationsMarketPlace = `${generalTranslations}marketPlace:`;

  const onBoardingSteps: IFreeOnboardingStep[] = [
    {
      stepId: 1,
      title: t(`${translationsDashboard}title`),
      positionId: 'menu-module-name-dashboard',
      type: 'menu-item',
      polygonPosition: 'topLeft',
      includedServicesList: [
        t(`${translationsDashboard}list:first`),
        t(`${translationsDashboard}list:second`),
        t(`${translationsDashboard}list:third`),
        t(`${translationsDashboard}list:fourth`)
      ],
      blockedServicesList: [t(`${translationsDashboard}list:fifth`)],
      stepImage: {
        image: dashboardStep,
        width: '236px',
        height: '116px'
      },
      eventName: 'nodiOnboardingFree1View'
    },
    {
      stepId: 2,
      title: t(`${translationsEmployees}title`),
      positionId: 'menu-module-name-employees',
      type: 'menu-item',
      polygonPosition: 'topLeft',
      includedServicesList: [t(`${translationsEmployees}list:first`), t(`${translationsEmployees}list:second`)],
      blockedServicesList: [t(`${translationsEmployees}list:third`)],
      stepImage: {
        image: employeesStep,
        width: '231px',
        height: '104px'
      },
      eventName: 'nodiOnboardingFree2View'
    },
    {
      stepId: 3,
      title: t(`${translationsInventory}title`),
      positionId: 'menu-module-name-inventory',
      type: 'menu-item',
      polygonPosition: 'topLeft',
      includedServicesList: [t(`${translationsInventory}list:first`), t(`${translationsInventory}list:secondFree`)],
      blockedServicesList: [t(`${translationsInventory}list:thirdFree`)],
      warningMessage: t(`${translationsInventory}warningMessage`),
      stepImage: {
        image: toolsStep,
        width: '243px',
        height: '130px'
      },
      eventName: 'nodiOnboardingFree3View'
    },
    {
      stepId: 4,
      title: t(`${translationsOrders}title`),
      positionId: 'menu-module-name-orders',
      type: 'menu-item',
      polygonPosition: 'topLeft',
      includedServicesList: [
        t(`${translationsOrders}list:first`),
        t(`${translationsOrders}list:second`),
        t(`${translationsOrders}list:third`)
      ],
      blockedServicesList: [],
      stepImage: {
        image: ordersStep,
        width: '250px',
        height: '116px'
      },
      eventName: 'nodiOnboardingFree4View'
    },
    {
      stepId: 5,
      title: t(`${translationsLogisticServices}title`),
      positionId: 'menu-module-name-logServices',
      type: 'center-menu-item',
      polygonPosition: 'leftCenter',
      includedServicesList: [
        t(`${translationsLogisticServices}list:first`),
        t(`${translationsLogisticServices}list:second`)
      ],
      blockedServicesList: [t(`${translationsLogisticServices}list:third`)],
      stepImage: {
        image: logisticServicesStep,
        width: '270px',
        height: '120px'
      },
      eventName: 'nodiOnboardingFree5View'
    },
    {
      stepId: 6,
      title: t(`${translationsFrequentlyAskedQuestions}title`),
      positionId: 'menu-module-name-FAQs',
      type: 'inferior-menu-item',
      polygonPosition: 'bottomLeft',
      polygonColor: 'white',
      includedServicesList: [
        t(`${translationsFrequentlyAskedQuestions}list:first`),
        t(`${translationsFrequentlyAskedQuestions}list:second`),
        t(`${translationsFrequentlyAskedQuestions}list:third`)
      ],
      blockedServicesList: [],
      stepImage: {
        image: faqStep,
        width: '175px',
        height: '101px'
      },
      eventName: 'nodiOnboardingFree6View'
    },
    {
      stepId: 7,
      title: t(`${translationsMarketPlace}title`),
      positionId: 'button-header-marketPlace',
      type: 'header-item-button',
      polygonPosition: 'topRight',
      includedServicesList: [t(`${translationsMarketPlace}list:first`)],
      blockedServicesList: [
        t(`${translationsMarketPlace}list:second`),
        t(`${translationsMarketPlace}list:third`),
        t(`${translationsMarketPlace}list:fourth`)
      ],
      stepImage: {
        image: marketplaceStep,
        width: '270px',
        height: '125px'
      },
      eventName: 'nodiOnboardingFree7View'
    }
  ];

  const updateOnboardingSteps = async (lastStep: number) => {
    try {
      await updateSteps(Number(orgData?.organizationId), lastStep, false, 'Free');
    } catch (error) {
      displayErrorNotification();
    }
  };

  const nextStep = (stepNumber: number) => {
    setActualStep(onBoardingSteps[stepNumber]);
    updateOnboardingSteps(stepNumber + 1);
  };

  const openDynamicBanner = () => {
    segmentTrackEvent({ nodiOnboardingFreeBannerView: null });
    setActualStep(undefined);
    setShowDynamicBanner(true);
    setShowPrincipalModal(false);
  };

  const finishOnboarding = async () => {
    openDynamicBanner();
    await updateSteps(Number(orgData?.organizationId), 1, true, 'Free');
  };

  const closeDynamicModalCallback = () => {
    setShowFreeOnboarding(false);
  };

  const initializeSteps = () => {
    segmentTrackEvent({ nodiFreeOnboardingClick: null });
    setActualStep(onBoardingSteps[0]);
    setShowPrincipalModal(false);
    updateOnboardingSteps(1);
  };

  const stepEvents = () => {
    if (actualStep?.eventName) {
      segmentTrackEvent({ [actualStep?.eventName]: null });
    }
  };
  const verifyLastStep = () => {
    if (detailsFreeOnboarding) {
      const findStep = onBoardingSteps.find(step => step.stepId === Number(detailsFreeOnboarding.lastStepSeen));
      if (findStep) {
        setShowPrincipalModal(false);
        setActualStep({ ...findStep });
      } else {
        setShowPrincipalModal(true);
      }
    } else {
      setShowPrincipalModal(true);
    }
  };

  const previusStep = (stepNumber: number) => {
    setActualStep(onBoardingSteps[stepNumber]);
  };

  useEffect(() => {
    stepEvents();
  }, [actualStep]);

  useEffect(() => {
    verifyLastStep();
  }, [detailsFreeOnboarding]);

  return (
    <>
      {showDynamicBanner && (
        <NudosDynamicBanner
          bannerSubtitle={t(`${dynamicBannerTranslations}subtitles:onboardingScreen`)}
          bannerTitle={t(`${dynamicBannerTranslations}titles:onboardingScreen`)}
          openedBannerOrigin="onboarding"
          closeModalCallback={closeDynamicModalCallback}
          submitBannerCallback={closeDynamicModalCallback}
          alertType={2}
        />
      )}
      {actualStep && (
        <ContainerOnboardingItems>
          <ItemNodiFreeInfoOnboarding
            infoStep={actualStep}
            numberSteps={onBoardingSteps.length}
            elementIndex={onBoardingSteps.findIndex(element => element.stepId === actualStep.stepId)}
            nextStep={step => {
              setActualStep(undefined);
              nextStep(step);
            }}
            previusStep={step => {
              setActualStep(undefined);
              previusStep(step);
            }}
            openDynamicBanner={() => {
              openDynamicBanner();
            }}
            finishOnboarding={() => {
              setActualStep(undefined);
              finishOnboarding();
            }}
          />
        </ContainerOnboardingItems>
      )}

      {showPrincipalModal && (
        <NodiFreeStartModal
          clickInitializeSteps={() => {
            initializeSteps();
          }}
        />
      )}
    </>
  );
};

export default OnboardingNodiFreeSteps;
