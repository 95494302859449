import useShopStore from '../../../state/useShopContext';
import { GenericFetchError } from '../../../constants';
import { useMachine } from '@xstate/react';
import { productListMachine } from '../productsList/productList.controller';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MinReturnButton } from '../../../assets/images/components/header';
import { BlackDeleteSquare, magnifier } from '../../../assets/images/views/Admin/main';
import { LoaderProductCard, ProductEcommerceCard } from '../productsList/Components';
import { useTranslation } from 'react-i18next';
import urlParameter from '../../../utils/urlParameter';
import { supportUrl } from '../../../utils/productDefinitions';

const SearchResult = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { searchResult, searchString, barSearch, setSearchResult, setSearchString, setBarSearch } = useShopStore();
  const productDetailsOrigin = urlParameter('origin') === 'productDetails';

  const [message, setMessage] = useState('');
  const [stateMachine, send] = useMachine(productListMachine);

  const returnAction = () => {
    setSearchString('');
    goBack();
  };

  const clearButton = () => {
    setSearchResult([]);
    setSearchString('');
    setBarSearch('');
    setMessage('clear');
  };

  useEffect(() => {
    send('DATA_CHANGE');
    if (searchResult) {
      if (searchResult.length === 0) {
        if (message === 'clear') {
          setMessage('a');
        } else {
          setMessage('');
        }
        send('MISS');
      } else {
        send('RESOLVE');
      }
    }
  }, [searchResult]);

  return (
    <section id="search-result">
      <section className="return-button-section" onClick={() => returnAction()}>
        <span className="return-icon">
          <MinReturnButton className="mb-3 cursor-pointer" onClick={() => returnAction()} />
        </span>
        <span className="return-text"> {productDetailsOrigin ? 'Detalle del producto' : 'Catálogo'} </span>
      </section>
      <section>
        {stateMachine.matches('resolved') && (
          <section onClick={() => clearButton()} className="searchDelete">
            {searchString === undefined ? '' : barSearch}{' '}
            <span> {` ${searchString === undefined ? '' : searchString}`}</span>
            <span className="square-span">
              {searchString && <BlackDeleteSquare className="iconRelative" onClick={() => clearButton()} />}
            </span>
          </section>
        )}
        {stateMachine.matches('pending') && (
          <div className="containerMyProductList">
            <LoaderProductCard />
          </div>
        )}
        {stateMachine.matches('resolved') && (
          <>
            {searchResult && searchResult?.length > 0 && (
              <div className="containerMyProductList">
                {searchResult?.map((item, index) => {
                  return (
                    <ProductEcommerceCard
                      dataProductCard={item}
                      key={`product-ecommerce-card-${item?.productId}-index${index}`}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
        {stateMachine.matches('missed') && (
          <>
            <section onClick={() => clearButton()} className="searchDelete">
              {searchString === undefined ? '' : barSearch}{' '}
              <span> {` ${searchString === undefined ? '' : searchString}`}</span>
              <span className="square-span">
                {searchString && <BlackDeleteSquare className="iconRelative" onClick={() => clearButton()} />}
              </span>
            </section>
            <div className="unsuccessful">
              <div className="magnifier-icon-container">
                <img src={magnifier} />
              </div>
              <p>{message == '' ? t('nodi:genericNoResults') : 'Escribe lo que deseas buscar'}</p>
              <div className="supportText" onClick={() => window.open(supportUrl, '_blank')}>
                Cuéntanos que producto buscas y conseguimos para ti{' '}
              </div>
            </div>
          </>
        )}
        {stateMachine.matches('rejected') && <GenericFetchError />}
      </section>
    </section>
  );
};

export default SearchResult;
