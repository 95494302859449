import { FC, useCallback, useEffect, useRef, useState } from 'react';
import SimpleArrow from '../../assets/images/components/simpleSelect/SimpleArrow';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Tany } from '../../types/global';
import './simpleSelect.scss';

interface IElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: Tany;
}

interface ISelectData {
  value: number;
  name: string;
  image?: string;
  nameStringId?: string | null;
  categoryStringId?: string | null;
}

interface ISimpleSelect {
  enabled?: boolean;
  selectList?: ISelectData[];
  defaultValue?: ISelectData;
  changeValue?: (item: ISelectData) => void;
  isGray?: boolean;
  customIcon?: React.ReactNode;
  includeImageOnListItems?: boolean;
  useAlias?: boolean;
}

/**
 * @property {boolean} includeImageOnListItems - Optional, pass true if each (or some) item on the list has an image, for example a flag, that is required to show on the select items. To work is required that elements on the selectList include an image property (string)
 */
const SimpleSelect: FC<ISimpleSelect> = ({
  customIcon,
  enabled,
  selectList,
  defaultValue,
  changeValue,
  isGray,
  includeImageOnListItems,
  useAlias
}: ISimpleSelect) => {
  const { t } = useTranslation();
  const refContainer = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        setOpen(false);
      }
    },
    [refContainer]
  );

  const handleChange = (item: ISelectData) => {
    if (changeValue) {
      changeValue(item);
    }
  };

  const includeDefaultValueImage = includeImageOnListItems && defaultValue?.image;
  const includeDefaultValueImageStyle = includeDefaultValueImage ? 'includeDefaultValueImage' : '';
  const colorStyles = { color: !enabled || isGray ? '#CCCCCC' : '' };

  const generateList = () => {
    return selectList?.map(item => {
      const includeItemsImage = includeImageOnListItems && item?.image;
      const selectItemOnList = () => {
        handleChange(item);
        setOpen(false);
      };
      const getListNameItems = () => {
        if (useAlias && item?.nameStringId) {
          return t(`services:countries:${item?.nameStringId}`);
        } else if (useAlias && item?.categoryStringId) {
          return t(`services:category:categoryStringId:${item?.categoryStringId}`);
        }
        return item.name;
      };

      return (
        <div key={uuid()} className="itemSelect" onClick={selectItemOnList}>
          <span className={`listItemName ${includeItemsImage ? 'includesImage' : ''}`}>{getListNameItems()}</span>
          {includeItemsImage && <span className="listItemImage" style={{ backgroundImage: `url(${item.image})` }} />}
        </div>
      );
    });
  };
  const getSelectedItemName = () => {
    if (useAlias && defaultValue?.nameStringId) {
      return t(`services:countries:${defaultValue?.nameStringId}`);
    } else if (useAlias && defaultValue?.categoryStringId) {
      return t(`services:category:categoryStringId:${defaultValue?.categoryStringId}`);
    }
    return defaultValue?.name || 'Todos';
  };

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div id="simpleSelect" className="simpleSelect" ref={refContainer}>
      <div
        className="selectButton"
        style={{ border: !enabled || isGray ? '0.5px solid #CCCCCC' : '' }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className={`nameButton ${includeDefaultValueImageStyle}`} style={colorStyles}>
          <div className="selectedItemName">{getSelectedItemName()}</div>

          {includeDefaultValueImage && (
            <span className="selectedItemImg" style={{ backgroundImage: `url(${defaultValue?.image})` }} />
          )}
          {customIcon && <div className="customSelectIcon">{customIcon}</div>}
        </div>
        <SimpleArrow fill={!enabled || isGray ? '#CCCCCC' : ''} />
      </div>

      {enabled && open && selectList && selectList.length > 0 && (
        <div className="selectOptionsAbsoluteContainer">
          <div className="floatSelect">{selectList && selectList.length > 0 && generateList()}</div>
        </div>
      )}
    </div>
  );
};

export default SimpleSelect;
