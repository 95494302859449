import { Link, useHistory } from 'react-router-dom';
import { NudosButton } from '../../components/NudosComponents';
import redirectionToCheckoutImage from '../../assets/images/views/redirectionToCheckout/redirectionToCheckoutImage.webp';
import { useTranslation } from 'react-i18next';
import './RedirectionToCheckoutView.scss';

const RedirectionToCheckoutView = ({ openLinkAction }: { openLinkAction?: () => void }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const translationKey = 'nodi:checkout:redirectionToCheckoutView:';
  const redirectionToCheckout = () => push('/dashboard');
  return (
    <div className="redirectionToCheckoutView">
      <img className="redirectionToCheckoutImage" src={redirectionToCheckoutImage} />
      <div className="redirectionToCheckoutTitle">{t(`${translationKey}redirectionToCheckoutTitle`)}</div>
      <div className="redirectionToCheckoutSubtitle">{t(`${translationKey}redirectionToCheckoutSubtitle`)}</div>
      <div className="buttonsContainer">
        <NudosButton
          buttonText={t(`${translationKey}goToDashboardButton`)}
          handleClick={redirectionToCheckout}
          colorPalette="secondary"
          componentSize="small"
        />
        <Link to={'/nodi/dashboard'}>
          <NudosButton
            customClassName="openLinkButton"
            buttonText={t(`${translationKey}openLinkButton`)}
            handleClick={openLinkAction}
          />
        </Link>
      </div>
    </div>
  );
};

export default RedirectionToCheckoutView;
