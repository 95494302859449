import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DTOpostManualAdjustmentAndComments } from '../../../../../../../types/tools.types';
import {
  NudosButton,
  NudosGenericModal,
  NudosModalContent,
  NudosTextArea
} from '../../../../../../../components/NudosComponents';
import './ManualAdjustmentModal.scss';

const ManualAdjustmentModal = ({
  closeModalCallback,
  locationLogId,
  preloadedData,
  loadingInManualAdjustmentModal,
  modalSubmitCallback
}: {
  closeModalCallback: (newClosingSatus: boolean) => void;
  locationLogId?: string | number;
  preloadedData?: string;
  loadingInManualAdjustmentModal?: boolean;
  modalSubmitCallback: (locationLogId: string | number, body: DTOpostManualAdjustmentAndComments) => Promise<void>;
}) => {
  const { t } = useTranslation();
  return (
    <div className="manualAdjustmentModal">
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            modalContentTitle={t('nodi:toolInternal:manualAdjustmentSection')}
            CustomModalComponent={
              <ManualAdjustmentForm
                preloadedData={preloadedData}
                locationLogId={locationLogId}
                loadingInManualAdjustmentModal={loadingInManualAdjustmentModal}
                modalSubmitCallback={modalSubmitCallback}
              />
            }
            toCloseModal={() => closeModalCallback(false)}
            componentSize="medium"
            customWidth="580px"
            customHeight="438px"
          />
        }
      />
    </div>
  );
};

export default ManualAdjustmentModal;

const ManualAdjustmentForm = ({
  locationLogId,
  preloadedData,
  loadingInManualAdjustmentModal,
  modalSubmitCallback
}: {
  locationLogId?: string | number;
  preloadedData?: string;
  loadingInManualAdjustmentModal?: boolean;
  modalSubmitCallback: (locationLogId: string | number, body: DTOpostManualAdjustmentAndComments) => Promise<void>;
}) => {
  const [comments, setComments] = useState('');

  const handleCickContinue = () => {
    if (!locationLogId) return;
    const body = {
      comments,
      manualAdjustment: true
    };
    modalSubmitCallback(locationLogId, body);
  };

  useEffect(() => {
    if (preloadedData && !comments) setComments(preloadedData);
  }, [preloadedData]);

  return (
    <div className="manualAdjustmentForm">
      <h2 className="subtitle">Agrega alguna nota</h2>
      <NudosTextArea
        placeholder="Escribe comentarios"
        currentValue={comments}
        updateCurrentValueCallback={setComments}
        maxLength={500}
        textHeight={216}
        xPadding="16"
        yPadding="8"
        noResize
      />
      <NudosButton
        buttonText="Guardar"
        componentSize="large"
        handleClick={handleCickContinue}
        isButtonLoading={loadingInManualAdjustmentModal}
        isButtonDisabled={!locationLogId}
      />
    </div>
  );
};
