import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={27} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M10.9002 5.66664C10.9002 4.92221 10.7657 4.18717 10.5043 3.49941C10.243 2.81164 9.85998 2.18673 9.37711 1.66034C8.89423 1.13394 8.32098 0.716388 7.69008 0.431507C7.05917 0.146626 6.38297 -3.254e-08 5.70009 0C5.0172 3.25401e-08 4.341 0.146626 3.7101 0.431507C3.0792 0.716388 2.50594 1.13394 2.02307 1.66034C1.5402 2.18673 1.15716 2.81164 0.895833 3.49941C0.634504 4.18717 0.5 4.92221 0.5 5.66664L3.10004 5.66664C3.10004 5.29443 3.1673 4.92795 3.29796 4.58407C3.42863 4.24019 3.62014 3.92773 3.86158 3.66454C4.10302 3.40134 4.38964 3.19256 4.70509 3.05012C5.02055 2.90768 5.35865 2.83437 5.70009 2.83437C6.04153 2.83437 6.37963 2.90768 6.69508 3.05012C7.01053 3.19256 7.29716 3.40134 7.5386 3.66454C7.78003 3.92773 7.97155 4.24019 8.10222 4.58407C8.23288 4.92795 8.30013 5.29443 8.30013 5.66664H10.9002Z"
      fill="#FF664E"
    />
    <path
      d="M8.30588 8.33336C8.30588 9.07779 8.44038 9.81283 8.70171 10.5006C8.96304 11.1884 9.34608 11.8133 9.82895 12.3397C10.3118 12.8661 10.8851 13.2836 11.516 13.5685C12.1469 13.8534 12.8231 14 13.506 14C14.1889 14 14.8651 13.8534 15.496 13.5685C16.1269 13.2836 16.7001 12.8661 17.183 12.3397C17.6659 11.8133 18.0489 11.1884 18.3102 10.5006C18.5716 9.81283 18.7061 9.07779 18.7061 8.33336L16.106 8.33336C16.106 8.70557 16.0388 9.07205 15.9081 9.41593C15.7774 9.75981 15.5859 10.0723 15.3445 10.3355C15.103 10.5987 14.8164 10.8074 14.501 10.9499C14.1855 11.0923 13.8474 11.1656 13.506 11.1656C13.1645 11.1656 12.8264 11.0923 12.511 10.9499C12.1955 10.8074 11.9089 10.5987 11.6675 10.3355C11.426 10.0723 11.2345 9.75981 11.1038 9.41593C10.9732 9.07205 10.9059 8.70557 10.9059 8.33336L8.30588 8.33336Z"
      fill="#FF664E"
    />
    <path
      d="M26.4998 5.66664C26.4998 4.92221 26.3653 4.18717 26.104 3.49941C25.8426 2.81164 25.4596 2.18673 24.9767 1.66034C24.4938 1.13394 23.9206 0.716388 23.2897 0.431507C22.6588 0.146626 21.9826 -3.254e-08 21.2997 0C20.6168 3.25401e-08 19.9406 0.146626 19.3097 0.431507C18.6788 0.716388 18.1056 1.13394 17.6227 1.66034C17.1398 2.18673 16.7568 2.81164 16.4954 3.49941C16.2341 4.18717 16.0996 4.92221 16.0996 5.66664L18.6997 5.66664C18.6997 5.29443 18.7669 4.92795 18.8976 4.58407C19.0282 4.24019 19.2198 3.92773 19.4612 3.66454C19.7026 3.40134 19.9893 3.19256 20.3047 3.05012C20.6202 2.90768 20.9583 2.83437 21.2997 2.83437C21.6411 2.83437 21.9792 2.90768 22.2947 3.05012C22.6101 3.19256 22.8968 3.40134 23.1382 3.66454C23.3796 3.92773 23.5712 4.24019 23.7018 4.58407C23.8325 4.92795 23.8997 5.29443 23.8997 5.66664H26.4998Z"
      fill="#FF664E"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MinNudosLogo = memo(ForwardRef);
export default MinNudosLogo;
