import { useHistory } from 'react-router-dom';
import Points from '../../../../../assets/images/components/newCard/points';
import { EmployeePhotoPlaceholder, HomeIcon } from '../../../../../assets/images/views/Admin/employees';
import { EmployeePills } from '../../../../../components';
import { Iemployee } from '../../../../../types/global';
import { truncateText } from '../../../../../utils/truncateText';
import { useTranslation } from 'react-i18next';
import './EmployeeCard.scss';

const EmployeeCard = ({ employee }: IemployeeCard) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const i18nLanguageKey = 'nodi:employee:employeeCard:';
  const cityPillKey = t(`${i18nLanguageKey}employeePills:city`);
  const areaPillKey = t(`${i18nLanguageKey}employeePills:area`);
  const positionPillKey = t(`${i18nLanguageKey}employeePills:position`);

  const employeePills = {
    [cityPillKey]: employee.city || '',
    [areaPillKey]: employee.areaStringId ? t(`services:employeeArea:${employee.areaStringId}`) : employee.area || '',
    [positionPillKey]: employee.position || ''
  };

  const employeeFullName = `${employee.firstName} ${employee.lastName}`;
  const noAddress =
    !employee.address ||
    !employee.address.address ||
    typeof employee.address.address !== 'string' ||
    !employee.address.country ||
    !employee.address.city;
  const noAddressStyle = noAddress ? 'noAddress' : '';
  const addressExistsAndApply = !noAddress;
  const currentTools = employee?.assignedTools?.filter(tool => !tool?.assignedUntil);
  const isActive = !!employee?.active;
  const handleClickCard = () => {
    push(`/nodi/employees/details/${employee.userId}`);
  };

  return (
    <div className="employeeCard" onClick={handleClickCard}>
      <div className="imageSection">
        {!isActive && <div className="filterPhoto" />}
        {!employee?.photoProfile && <EmployeePhotoPlaceholder />}
        {employee?.photoProfile && (
          <div
            className={`profilePhoto`}
            style={{
              backgroundImage: `url("${employee?.photoProfile.replaceAll(' ', '%20')}")`
            }}
          ></div>
        )}
        <div
          className="flagContainer"
          style={{
            backgroundImage: `url(${employee?.country?.flag || ''})`
          }}
        />
      </div>
      <div className="addressAndName">
        {employee.firstName && employee.lastName && (
          <div className={`name ${!isActive ? 'grayName' : ''}`}>
            {truncateText(employeeFullName, 30)}
            <div className="nameHover">{employeeFullName}</div>
          </div>
        )}
        <div className={`address ${noAddressStyle}`}>
          <HomeIcon style={{ opacity: !isActive ? '.3' : '' }} className={`homeIcon ${!isActive ? 'inactive' : ''}`} />
          {addressExistsAndApply && (
            <>
              <div style={{ color: !isActive ? '#cccccc' : '' }}>
                {truncateText(employee?.address?.address || '', 37)}
              </div>
              <div className={`addressHover ${!isActive ? 'secondGray' : ''}`}>{employee?.address?.address}</div>
            </>
          )}
          {noAddress && (
            <>
              <div className={`noAddressText ${!isActive ? 'secondGray' : ''}`}>
                {t(`${i18nLanguageKey}noAddressText`)}
              </div>
            </>
          )}
        </div>
      </div>
      <EmployeePills pills={employeePills} isActive={!!employee?.active} />
      <div className="assignedToolsSection">
        {currentTools && currentTools?.length > 0 && (
          <>
            <div className="imgContainer">
              <img
                src={employee?.assignedTools ? employee?.assignedTools[0]?.image : undefined}
                alt="Una de las herramientas asignadas"
              />
            </div>
            <div className="counter">+{currentTools?.length}</div>
          </>
        )}
        {(!currentTools || currentTools?.length === 0) && t(`${i18nLanguageKey}noTools`)}
      </div>
      {employee?.active ? (
        <>
          {currentTools && currentTools?.length >= 0 && (
            <div className="assignationPercentage">
              {t(`${i18nLanguageKey}assignationPercentage1`)}
              {currentTools?.length <= 4 ? `${((currentTools?.length / 4) * 100).toFixed(0)}%` : '100%'}
            </div>
          )}
          {(!currentTools || currentTools?.length < 0) && (
            <div className="assignationPercentage">{t(`${i18nLanguageKey}assignationPercentage2`)}</div>
          )}
        </>
      ) : (
        <div className="assignationPercentage">
          <div className="disabledText">{t(`${i18nLanguageKey}disabledText`)}</div>
        </div>
      )}
      <div className="threeDotsButton">
        <Points />
      </div>
    </div>
  );
};

export default EmployeeCard;

export interface IemployeeCard {
  employee: Iemployee;
}
