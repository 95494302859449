import { NudosGenericModal, NudosModalContent } from '../../NudosComponents';
import NudosDynamicBannerContent from './NudosDynamicBannerContent';

export interface INudosDynamicBanner {
  bannerTitle?: string;
  bannerSubtitle?: string;
}

const NudosDynamicBanner = ({
  bannerTitle,
  bannerSubtitle,
  openedBannerOrigin,
  closeModalCallback,
  submitBannerCallback,
  startNodiMultiColor,
  alertType,
  eventWithinTheAlert,
  resetExternalEvent
}: {
  bannerTitle: string;
  bannerSubtitle: string;
  openedBannerOrigin?: string;
  closeModalCallback?: () => void;
  submitBannerCallback?: () => void;
  startNodiMultiColor?: string;
  alertType?: number;
  eventWithinTheAlert?: string;
  resetExternalEvent?: () => void;
}) => {
  return (
    <div>
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            customWidth="780px"
            toCloseModal={() => {
              if (closeModalCallback) {
                closeModalCallback();
              }
            }}
            customHeight="368px"
            excludeHeader={true}
            CustomModalComponent={
              <NudosDynamicBannerContent
                bannerTitle={bannerTitle}
                bannerSubtitle={bannerSubtitle}
                openedBannerOrigin={openedBannerOrigin || ''}
                submitBannerCallback={submitBannerCallback}
                closeModalCallback={() => {
                  if (closeModalCallback) closeModalCallback();
                }}
                startNodiMultiColor={startNodiMultiColor}
                alertType={alertType}
                eventWithinTheAlert={eventWithinTheAlert}
                resetExternalEvent={resetExternalEvent}
              />
            }
          />
        }
      />
    </div>
  );
};

export default NudosDynamicBanner;
