import './NudosToolImage.scss';

/**
 *@property { string } imageUrl - required, the url of the image
 *@property { number } customSize - optiona, the size of the image. Must be a valid value for the css width and height properties. Default is 64px.
 */
const NudosToolImage = ({ imageUrl, customSize }: { imageUrl: string; customSize?: string }) => {
  const customSizeStyles = customSize ? { width: customSize, height: customSize } : undefined;
  return (
    <div className="NudosToolImage" style={customSizeStyles}>
      <img alt="Foto del producto" src={imageUrl} />
    </div>
  );
};

export default NudosToolImage;
