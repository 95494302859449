import { useEffect, useState } from 'react';
import { NudosLogisticsToolCard } from '../../../../components/DesignSystem';
import {
  IadditionalServiceDataForToolLogistics,
  IlogisticAdditionalService,
  ItoolEssentialData
} from '../../../../types/requestLogisticsModule';
import { TstoragePlace } from '../../../../types/assignationFluxes';
import { WarehouseAdditionalServicesDropdown } from '../../UnassignmentModule/Components';
import { AdditionalServicesCommentsModal } from '..';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import { LOCATION_PLACE_TYPE } from '../../../../types/global';
import './NudosToolLogisticsWithAdditionalServices.scss';
import { useTranslation } from 'react-i18next';

const NudosToolLogisticsWithAdditionalServices = ({
  toolData,
  isSelected,
  handleClickSelectButton,
  toolHasSelectButton,
  toolLogisticsPlaceThatMayBeWarehouse,
  warehouseAditionalServices,
  updateToolsToAssignOrUnassignData,
  totalTools,
  destination
}: {
  toolData: ItoolEssentialData;
  isSelected: boolean;
  handleClickSelectButton?: () => void;
  toolHasSelectButton: boolean;
  toolLogisticsPlaceThatMayBeWarehouse?: TstoragePlace;
  warehouseAditionalServices?: IlogisticAdditionalService[];
  updateToolsToAssignOrUnassignData?: (
    specificTool?: ItoolEssentialData,
    type?: 'edit' | 'delete',
    additionalServicesForSpecificTool?: IadditionalServiceDataForToolLogistics[]
  ) => void;
  totalTools?: number;
  destination?: LOCATION_PLACE_TYPE;
}) => {
  const { t } = useTranslation();
  const errorText = t('services:logistics:errorText');

  const [thisToolAdditionalServices, setThisToolAdditionalServices] = useState<number[]>([]);
  const [thisToolConfigurationComment, setThisToolConfigurationComment] = useState<string>();
  const [showAdditionalServicesCommentsModal, setShowAdditionalServicesCommentsModal] = useState(false);
  const toolCategoryAllowsAdditionalService =
    toolData?.category === 'Laptops' ||
    toolData?.category === 'Laptops CTOs' ||
    toolData?.category === 'Laptops con DEP' ||
    toolData?.category === 'PC';

  const logisticsPlaceIsWarehouse = ['nudos'].includes(toolLogisticsPlaceThatMayBeWarehouse || '');
  const showAdditionalServicesDropdown =
    logisticsPlaceIsWarehouse &&
    warehouseAditionalServices &&
    warehouseAditionalServices?.length > 0 &&
    toolCategoryAllowsAdditionalService;

  const includeAdditionalServicesStyle = logisticsPlaceIsWarehouse ? 'includeAdditionalServices' : '';
  const hasConfigurationAsAdditionalService = thisToolAdditionalServices.some(
    service => service === 2 || service === 3
  ); // 2 = "configuración básica"; 3 = "configuración avanzada"

  const openAdditionalServicesModal = () => setShowAdditionalServicesCommentsModal(true);

  const closeAdditionalServicesModal = () => {
    if (!thisToolConfigurationComment) {
      displayErrorNotification({
        customJSXMessage: <>{errorText}</>
      });
    }

    setShowAdditionalServicesCommentsModal(false);
  };

  const clickSelectOptionCallback = (clickedService: number) => {
    const newToolAdditionalServices = [...thisToolAdditionalServices];
    const clickedServiceIndex = thisToolAdditionalServices?.findIndex(tool => tool === clickedService);
    if (clickedServiceIndex < 0) {
      newToolAdditionalServices.push(clickedService);
    } else {
      newToolAdditionalServices.splice(clickedServiceIndex, 1);
    }
    const hasConfigurationAsAdditionalService = newToolAdditionalServices.some(
      service => service === 2 || service === 3
    ); // 2 = "configuración básica"; 3 = "configuración avanzada"
    if (!hasConfigurationAsAdditionalService) setThisToolConfigurationComment(undefined);
    setThisToolAdditionalServices(newToolAdditionalServices);
  };

  const handleCloseAdditionalServicesDropdown = () => {
    if (!hasConfigurationAsAdditionalService) return;
    openAdditionalServicesModal();
  };

  const handleSubmitAdditionalServicesModal = (modalData: string) => {
    if (!modalData) return closeAdditionalServicesModal();
    setThisToolConfigurationComment(modalData);
    setShowAdditionalServicesCommentsModal(false);
  };

  useEffect(() => {
    if (
      !toolData ||
      !toolData?.newLogisticAdditionalServices ||
      thisToolAdditionalServices?.length ||
      thisToolConfigurationComment
    )
      return;
    const initialAdditionalServices = toolData?.newLogisticAdditionalServices.reduce<number[]>((prev, curr) => {
      curr?.id && prev.push(curr.id);
      return prev;
    }, []);
    const initialComments = toolData?.newLogisticAdditionalServices.find(service => service.comments)?.comments;
    initialAdditionalServices && setThisToolAdditionalServices(initialAdditionalServices);
    initialComments && setThisToolConfigurationComment(initialComments);
  }, [toolData]);

  useEffect(() => {
    if (!isSelected || !updateToolsToAssignOrUnassignData) return;
    const thisToolAdditionalServicesDataWithComments = thisToolAdditionalServices.map(additionalServiceId => {
      const comments = [2, 3].includes(additionalServiceId) ? thisToolConfigurationComment : undefined;
      // 2 = "configuración básica"; 3 = "configuración avanzada"
      return { id: additionalServiceId, comments };
    });
    updateToolsToAssignOrUnassignData(toolData, 'edit', thisToolAdditionalServicesDataWithComments);
  }, [thisToolAdditionalServices, thisToolConfigurationComment]);

  return (
    <div className={`NudosToolLogisticsWithAdditionalServices ${includeAdditionalServicesStyle}`}>
      <NudosLogisticsToolCard
        toolData={toolData}
        isSelected={isSelected}
        handleClickSelectButton={handleClickSelectButton}
        toolHasSelectButton={toolHasSelectButton}
        totalTools={totalTools}
      />
      {showAdditionalServicesDropdown && (
        <WarehouseAdditionalServicesDropdown
          clickSelectOptionCallback={clickSelectOptionCallback}
          thisToolAdditionalServices={thisToolAdditionalServices}
          thisToolPreviousAdditionalServices={toolData?.logisticAdditionalServices || []}
          thisToolConfigurationComment={thisToolConfigurationComment}
          currentWarehouseAdditionalServices={warehouseAditionalServices || []}
          dropdownCloseCallback={handleCloseAdditionalServicesDropdown}
          openModalCallback={openAdditionalServicesModal}
          hasConfigurationAsAdditionalService={hasConfigurationAsAdditionalService}
          destinationIsWarehouse={destination === LOCATION_PLACE_TYPE.NUDOS}
        />
      )}
      {showAdditionalServicesCommentsModal && (
        <AdditionalServicesCommentsModal
          closeModalCallback={closeAdditionalServicesModal}
          submitModalCallback={handleSubmitAdditionalServicesModal}
          currentComment={thisToolConfigurationComment}
        />
      )}
    </div>
  );
};

export default NudosToolLogisticsWithAdditionalServices;
