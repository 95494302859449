import { FC } from 'react';
import { magnifier } from '../../../../../assets/images/views/Admin/main';
import { useTranslation } from 'react-i18next';
import './GenericNoResults.scss';

interface IGenericNoResults {
  customHeight?: number;
}

const GenericNoResults: FC<IGenericNoResults> = ({ customHeight }: IGenericNoResults) => {
  const { t } = useTranslation();
  return (
    <div id="genericNoResults" style={{ height: customHeight ? `${customHeight}%` : '' }}>
      <div className="containerItems">
        <div className="limitsImg">
          <img className="imgStyle" src={magnifier} />
        </div>
        <div className="limitsText">
          <div className="textStyle">{t('nodi:genericNoResults')}</div>
        </div>
      </div>
    </div>
  );
};

export default GenericNoResults;
