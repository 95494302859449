import LogisticServicePaymentSection from '../LogisticServicePaymentSection/LogisticServicePaymentSection';
import LogisticServiceTrackingSection from '../LogisticServiceTrackingSection/LogisticServiceTrackingSection';
import LogisticServiceDocumentsSection from '../LogisticServiceDocumentsSection/LogisticServiceDocumentsSection';
import LogisticServiceLocationCard from '../LogisticServiceLocationCard/LogisticServiceLocationCard';
import { IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import './LogisticsDetailsSummary.scss';

const LogisticsDetailsSummary = ({
  logisticServiceData,
  getLogisticServiceDetailCallback,
  setLoadingCallback,
  loading
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  getLogisticServiceDetailCallback: () => void;
  setLoadingCallback: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
}) => {
  const { status, trackingLink, trackingCode, estimatedDates, deliveryDate, addressIssue } = logisticServiceData || {};
  const { collect } = estimatedDates || {};
  const serviceIsCanceled = status === 'cancelado';
  const hasTrackingData = !!trackingCode || !!trackingLink;
  const haveSomeDeliveredData = hasTrackingData || !!Object.values(collect || {}).length || !!deliveryDate;
  const ifDeliveredServiceHaveData = status !== 'entregado' || haveSomeDeliveredData;
  const originAddressEditedByUser = addressIssue?.origin?.editedBy === 'user';
  const destinationAddressEditedByUser = addressIssue?.destination?.editedBy === 'user';
  const originIssueReported = logisticServiceData?.addressIssue?.origin?.issueReported;
  const destinationIssueReported = logisticServiceData?.addressIssue?.destination?.issueReported;

  return (
    <div className="LogisticsDetailsSummary">
      <LogisticServiceTrackingSection
        logisticServiceData={logisticServiceData}
        isLoading={loading}
        haveSomeDeliveredData={haveSomeDeliveredData}
      />
      {!serviceIsCanceled && ifDeliveredServiceHaveData && <div className="divider" />}
      <LogisticServiceLocationCard
        isLoading={loading}
        tipeOfCard="origin"
        logisticServiceData={logisticServiceData}
        getLogisticServiceDetailCallback={getLogisticServiceDetailCallback}
        issueReported={
          !originAddressEditedByUser && originIssueReported && originIssueReported?.length > 0
            ? originIssueReported?.[originIssueReported.length - 1]
            : undefined
        }
      />
      <div className="divider" />
      <LogisticServiceLocationCard
        isLoading={loading}
        tipeOfCard="destination"
        logisticServiceData={logisticServiceData}
        getLogisticServiceDetailCallback={getLogisticServiceDetailCallback}
        issueReported={
          !destinationAddressEditedByUser && destinationIssueReported && destinationIssueReported?.length > 0
            ? destinationIssueReported?.[destinationIssueReported.length - 1]
            : undefined
        }
      />
      <div className="divider" />
      <LogisticServicePaymentSection
        logisticServiceData={logisticServiceData}
        isLoading={loading}
        setLoadingCallback={setLoadingCallback}
        updateDataCallback={() => getLogisticServiceDetailCallback()}
      />
      <div className="divider" />
      <LogisticServiceDocumentsSection logisticServiceData={logisticServiceData} isLoading={loading} />
    </div>
  );
};

export default LogisticsDetailsSummary;
