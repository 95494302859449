import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <>
      {props?.role === 'active' ? (
        <>
          <rect
            className="square-active"
            x="0.5"
            y="0.5"
            width="19"
            height="19"
            rx="3.5"
            fill="#ff664e"
            stroke="#ff664e"
          />
          <path
            className="checkmark-active"
            d="M5 11.875L9 16C10.8822 10.5931 12.4498 8.22049 16 5"
            stroke="white"
            strokeWidth="0.88"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <>
          <rect
            className="square"
            x={0.5}
            y={0.5}
            width={19}
            height={19}
            rx={3.5}
            fill={props.fill || 'transparent'}
            stroke={props.stroke || '#000'}
          />
          <path
            className="checkmark"
            d="M7.095 15.62 2.22 10.9a.71.71 0 0 1 0-1.028l1.06-1.027a.767.767 0 0 1 1.061 0l3.284 3.18 7.034-6.812a.767.767 0 0 1 1.06 0L16.78 6.24a.71.71 0 0 1 0 1.027l-8.625 8.354a.767.767 0 0 1-1.06 0Z"
            fill={props.fill || 'transparent'}
          />
        </>
      )}
    </>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const FilterCheckMark = memo(ForwardRef);
export default FilterCheckMark;
