import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NudosBreadcrumbButton, NudosButton } from '../../../components/NudosComponents';
import { GenericLoader } from '../../Admin/dashboard/components';
import { rangeHorus } from '../../../utils/calculateDateRangeHours';
import {
  IOutRange,
  IadditionalServiceDataForToolLogistics,
  IassignmentInfoForAssignmentOrUnassignment,
  IlocationAddressForLogistics,
  ItoolEssentialData,
  IwarehouseData,
  TlogisticsOriginOrDestinationData
} from '../../../types/requestLogisticsModule';
import { ICountryDetail } from '../../../types/countries';
import { IDetailsQuote, TstoragePlace } from '../../../types/assignationFluxes';
import ToolManagementStepper from './Components/ToolsManagementStepper/ToolsManagementStepper';
import {
  assignOrUnassign,
  formatCheckQuoteAndBillingDataTools,
  formatDateRange,
  getLocationData,
  getNudosWarehousesList,
  getToolData,
  isDataComplete,
  updateLocationsData
} from '../utils/requestNewLogisticsUtilFunctions';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import {
  ToolsManagementStep1SelectDestination,
  ToolsManagementStep2ConfirmLocations,
  ToolsManagementStep3Tools,
  ToolsManagementStep4SelectDate,
  ToolsManagementStep5SendEmail,
  ToolsManagementStep6DataVerification
} from './ToolsManagementSteps';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import {
  aproximateDays,
  getWarehouseLocation,
  isAutomatable,
  rejectedLogisticService
} from '../../../services/logisticsServicesModule.services';
import { formatDateFromDateRange } from '../../../utils/formatDateRange';
import { IDocumentDetail, Iemployee, TnudosCare } from '../../../types/global';
import LogisticsQuoteAndBillingStep from '../LogisticsQuoteAndBillingStep/LogisticsQuoteAndBillingStep';
import useRequireLogisticsSummaryData from '../../../state/useRequireLogisticsSummaryData';
import { formatOrgData } from '../../../utils/orgFormatData';
import { IIsAutomatable, IToolsRejected } from '../../../types/logisticsServicesModule.types';
import useLogisticsServiceBillingState from '../../../state/useLogisticsQuoteAndBilling';
import { CancelLogisticServiceModal } from '../LogisticsQuoteAndBillingStep/LogisticServicePrincingQuote/Components';
import { useTranslation } from 'react-i18next';
import './ToolsManagementModule.scss';
import { segmentTrackEvent } from '../../../utils/segment';

const ToolsManagementModule = () => {
  const { t } = useTranslation();

  const { toolsIds: toolsIdsJson } = useParams<{ toolsIds: string }>();
  const { search } = useLocation();
  const orgData = formatOrgData();
  const {
    invoiceAdditionalEmail,
    billingCountry: selectedBillingCountry,
    logisticsServiceBillingData,
    setLogisticsServiceBillingData,
    cleanLsBillingSate
  } = useLogisticsServiceBillingState();
  const { push } = useHistory();
  const { setEstimatedDelivery } = useRequireLogisticsSummaryData();
  const [showTimeOutModal, setShowTimeOutModal] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
  const [loadingSubmission, setLoadingSubmission] = useState<boolean>(false);
  const [listOfCountriesOpenForLogistics, setListOfCountriesOpenForLogistics] = useState<ICountryDetail[]>();
  const [listOfAllCountries, setListOfAllCountries] = useState<ICountryDetail[]>();
  const [nudosWarehousesList, setNudosWarehousesList] = useState<IwarehouseData[]>([]);
  const [loadingWarehousesList, setLoadingWarehousesList] = useState(false);
  const [toolsToManageData, setToolsToManageData] = useState<ItoolEssentialData[]>();
  const [originData, setOriginData] = useState<IlocationAddressForLogistics>();
  const [destinationData, setDestinationData] = useState<TlogisticsOriginOrDestinationData>();
  const [detailsQuote, setDetailsQuote] = useState<IDetailsQuote>();
  const [loadingQuote, setLoadingQuote] = useState<boolean>(false);
  const [errorQuote, setErrorQuote] = useState<boolean>(false);
  const [loaderWarehouse, setLoaderWareHouse] = useState<boolean>(false);
  // step 5 state - Date selection
  const [dateRange, setDateRange] = useState<IOutRange>();
  const [aproximateDay, setAproximateDay] = useState<number>(0);
  const [estimatedDeliveryTimeByCountry, setEstimatedDeliveryTimeByCountry] = useState<string>('');
  const [existDateRange, setExistDateRange] = useState<boolean>(false);
  const [logisticsComments, setLogisticsComments] = useState<string>();

  // step 4 state - Email and message
  const [employeMessage, setEmployeeMessage] = useState<string>();
  const [optionalEmployeeFile, setOptionalEmployeeFile] = useState<IDocumentDetail>();

  const [loadingRejected, setLoadingRejected] = useState<boolean>(false);
  const [rejectComments, setRejectComments] = useState<string>();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const [showCanQuoteBeAutomated, setShowCanQuoteBeAutomated] = useState<boolean>(false);
  const [loadingShowBillingData, setLoadingShowBillingData] = useState<boolean>(false);

  const setAndContinueText = t('recurrentWords:setAndContinue');
  const confirmOnboardingText = t('services:logistics:endStep:confirmOnboarding');

  const searchParams = new URLSearchParams(search);
  const toolsIds = JSON.parse(toolsIdsJson);

  const navigationOrigin = searchParams.get('origin');
  const navigationOriginId = searchParams.get('originId');

  const selectedDestination = searchParams?.get('destination') || '';
  const selectedDestinationIsUser = selectedDestination === 'user';
  const destinationAsUser = destinationData as Iemployee;
  const incompleteDestinationUser = selectedDestination === 'user' && !destinationAsUser?.address?.address;
  const destinationAsLocation = destinationData as IlocationAddressForLogistics;
  const destinationDataLocationId = destinationData?.locationId || destinationAsLocation?.id || undefined;
  const destinationIsWarrantyOrDiagnostics = ['diagnostic', 'warranty'].includes(selectedDestination);
  const selectedDatesAreForCollectionNotDelivery =
    ['nudos', 'warranty', 'diagnostic'].includes(selectedDestination) && originData?.place !== 'nudos';

  const originDataCountry = originData?.countryData || originData?.country;
  const destinationCountryData = destinationAsLocation?.countryData || destinationAsLocation?.country;
  const requireLogisticsJSON = searchParams?.get('logistics');
  const requireLogistics: boolean = requireLogisticsJSON ? JSON.parse(requireLogisticsJSON) : false;
  const isLocalAssignation = originDataCountry?.code === destinationCountryData?.code;
  // When the user selects billing in local country, the invoice is generated in the destination country
  const billingCountry =
    selectedBillingCountry?.code === 'localBillingCountry' ? destinationCountryData : selectedBillingCountry;

  const incompleteDestinationDataForLogistics =
    requireLogistics && (!destinationDataLocationId || incompleteDestinationUser);

  const showQuoteAndBillingData = showCanQuoteBeAutomated && requireLogistics;

  const validationToDisplaySecondBillingButton =
    showQuoteAndBillingData &&
    ((step === 4 && !selectedDestinationIsUser) || (selectedDestinationIsUser && step === 5));

  const originUrl = `/nodi/request-logistics/tools-management/[${toolsIds}]?logistics=${requireLogisticsJSON}&destination=${selectedDestination}&origin=${navigationOrigin}&originId=${navigationOriginId}`;

  const updateOneToolToManage = (tool: ItoolEssentialData) => setToolsToManageData([tool]); // Adapter to work with only one tool in the request that brings the tools to manage data. Remove and used directly the setToolsToManageData if required to work with many tools
  const advanceStep = () => setStep(step + 1);
  const returnStep = () => {
    setErrorQuote(false);
    setStep(step - 1);
  };

  const checkShowQuoteAndBillingData = async () => {
    setLoadingShowBillingData(true);

    try {
      const body: IIsAutomatable = {
        sameCountryRelocation: isLocalAssignation,
        origin: originData?.place as TstoragePlace,
        destination: selectedDestination,
        tools: formatCheckQuoteAndBillingDataTools(toolsToManageData || [])
      };
      const checkIsAutomatable = await isAutomatable(body);
      if (checkIsAutomatable) {
        setShowCanQuoteBeAutomated(checkIsAutomatable?.canQuoteBeAutomated);
      }
      advanceStep();
      setLoadingShowBillingData(false);
    } catch (error) {
      displayErrorNotification();
      setLoadingShowBillingData(false);
    }
  };

  const logicSelectTools = async () => {
    await checkShowQuoteAndBillingData();
  };

  const breadcrumbButtonReturnAction = () => {
    if (step > 0) return returnStep();
    switch (navigationOrigin) {
      case 'tool-details':
        return push(navigationOriginId ? `/nodi/details/${navigationOriginId}` : '/nodi/tools');
      default:
        return push('/nodi/tools');
    }
  };

  const backToOriginText = () => {
    switch (navigationOrigin) {
      case 'inventory':
        return 'Inventario';
      case 'tool-details':
        return 'Detalle de la herramienta';
      case 'inventory-management':
        return 'Inventario';
      default:
        return 'Regresar';
    }
  };

  const getReturnText = () => {
    switch (step) {
      case 0:
        return backToOriginText();
      case 1:
        if (destinationIsWarrantyOrDiagnostics) return 'Direcciones';
        return 'Ubicación';
      case 2:
        if (destinationIsWarrantyOrDiagnostics) return 'Herramientas';
        if (!requireLogistics) return 'Herramientas';
        return 'Direcciones';
      case 3:
        if (destinationIsWarrantyOrDiagnostics) return 'Fechas';
        if (!requireLogistics) return 'Correo';
        return 'Herramientas';
      case 4:
        return 'Fechas';
      case 5:
        return 'Correo';
      default:
        return 'Regresar';
    }
  };

  const handleCheckRange = (dateRange: IOutRange) => {
    if (dateRange) setDateRange(dateRange);
  };

  const isValidRange = (status: boolean) => setExistDateRange(status);

  const updateLogisticsComments = (text: string) => setLogisticsComments(text);

  const getAproximateDay = async (countryCode: string) => {
    try {
      const dataDay = await aproximateDays(countryCode);
      const gapDaysToInitialAvailableDay = selectedDatesAreForCollectionNotDelivery
        ? dataDay?.collectGap
        : dataDay?.deliveryGap;
      setAproximateDay(gapDaysToInitialAvailableDay || 0);
      setEstimatedDeliveryTimeByCountry(`${dataDay.deliverySince}-${dataDay.deliveryUntil}`);
    } catch {
      displayErrorNotification();
      setAproximateDay(3);
    }
  };

  const assignRangeDays = async () => {
    const sameCountryLogistics = originDataCountry?.code === destinationCountryData?.code;
    if (originDataCountry?.id && destinationCountryData?.id) {
      await getAproximateDay(sameCountryLogistics ? originDataCountry?.code : '1111'); // 1111 is the id of an international logistic service
    }
  };
  const handleEmployeeMessage = (message: string) => setEmployeeMessage(message);
  const handleChangeOptionalFile = (fileDetails: IDocumentDetail | undefined) => setOptionalEmployeeFile(fileDetails);

  const updateOriginDataCallback = (originData: IlocationAddressForLogistics) => setOriginData(originData);

  const updateDestinationDataCallback = (destinationData: TlogisticsOriginOrDestinationData) =>
    setDestinationData(destinationData);

  const step2UpdateLocationsCallback = () => {
    if (!originData?.place) return;
    updateLocationsData(
      originData?.place,
      originData,
      setOriginData as (newData: TlogisticsOriginOrDestinationData) => void,
      selectedDestination,
      destinationData,
      setDestinationData,
      setLoadingLocations,
      advanceStep
    );
  };

  const getDestinationDeliveryData = () => {
    const receiverDataForOfficeAndOtherLocation = {
      receiverName: destinationAsLocation?.receiverInformation?.receiverName,
      receiverPersonalId: destinationAsLocation?.receiverInformation?.receiverIdDocument,
      receiverCountryIdPhone: destinationAsLocation?.receiverInformation?.receiverPhoneCountry?.id,
      receiverPhone: destinationAsLocation?.receiverInformation?.receiverPhone
    };
    const receiverEmployeeData = {
      receiverName: `${destinationAsUser?.firstName || ''} ${destinationAsUser?.lastName || ''}`,
      receiverPersonalId: destinationAsUser?.personalId,
      receiverCountryIdPhone: destinationAsUser?.phoneData?.countryId,
      receiverPhone: destinationAsUser?.phoneData?.phone
    };
    const deliveryDatesAndComments = {
      chosenDeliveryDate: formatDateFromDateRange(dateRange, destinationCountryData?.timeZone),
      deliveryComment: logisticsComments
    };
    switch (selectedDestination) {
      case 'user':
        return { ...receiverEmployeeData, ...deliveryDatesAndComments };
      case 'office':
      case 'other':
        return { ...receiverDataForOfficeAndOtherLocation, ...deliveryDatesAndComments };
      default:
        if (selectedDatesAreForCollectionNotDelivery) return {};
        return deliveryDatesAndComments;
    }
  };

  const getOriginCollectionData = () => {
    const collectionDatesAndComments = selectedDatesAreForCollectionNotDelivery
      ? {
          chosenCollectionDate: formatDateFromDateRange(dateRange, originDataCountry?.timeZone),
          collectionComment: logisticsComments
        }
      : {};
    switch (originData?.place) {
      case 'office':
      case 'other':
        return {
          ...collectionDatesAndComments,
          delivererName: originData?.receiverInformation?.receiverName,
          delivererPersonalId: originData?.receiverInformation?.receiverIdDocument,
          delivererCountryIdPhone: originData?.receiverInformation?.receiverPhoneCountry?.id,
          delivererPhone: originData?.receiverInformation?.receiverPhone
        };
      default:
        if (selectedDatesAreForCollectionNotDelivery) return collectionDatesAndComments;
        return {};
    }
  };

  const handeSuccessfulAssignament = (logisticServiceId?: number | string) => {
    setLogisticsServiceBillingData(undefined);
    if (requireLogistics) {
      setEstimatedDelivery(estimatedDeliveryTimeByCountry);
      return push(
        `/nodi/request-logistics/success/${logisticServiceId}?origin=${navigationOrigin}&flux=management&collection=${
          selectedDatesAreForCollectionNotDelivery || false
        }`
      );
    } else {
      return push(`/nodi/details/${navigationOriginId}`);
    }
  };

  const checkDate = () => {
    const localDate = JSON.parse(localStorage.getItem('actualDateLogisticsService') || '');
    const saveDate: Date = new Date(localDate?.date || '');
    const hours = rangeHorus(saveDate, new Date());
    if (hours >= 24) {
      setShowTimeOutModal(true);
    } else {
      handleToolManagement();
    }
  };

  const handleToolManagement = () => {
    if (!originData?.id || !destinationCountryData?.id || !selectedDestination) return;
    if (incompleteDestinationDataForLogistics || !originDataCountry?.id || !originData?.place) return;
    const originAndDestinationData = {
      originLocationId: originData?.id,
      originCountryId: originDataCountry?.id,
      destinationCountryId: destinationCountryData?.id,
      destinationLocationId: destinationDataLocationId,
      origin: originData?.place,
      destination: selectedDestination
    };
    const products =
      toolsToManageData?.reduce<number[]>((prev, tool) => {
        !!tool?.productId && prev.push(+tool?.productId);
        return prev;
      }, []) || [];
    let assignmentInfo: IassignmentInfoForAssignmentOrUnassignment | undefined;
    if (selectedDestination === 'user') {
      if (!destinationAsUser?.firstName || !destinationAsUser?.lastName) return;
      if (!destinationAsUser?.country?.id) return;
      assignmentInfo = {
        userId: destinationAsUser?.userId,
        firstName: destinationAsUser?.firstName,
        lastName: destinationAsUser?.lastName,
        products,
        address: destinationAsUser?.address?.address || undefined,
        countryId: destinationAsUser?.country?.id,
        destinationLocationId: destinationAsUser?.locationId || undefined,
        customMessage: employeMessage
      };
    }
    if (selectedDestination !== 'user') {
      assignmentInfo = {
        destinationLocationId: destinationDataLocationId,
        products
      };
    }
    const collectionInfo = getOriginCollectionData();
    const deliveryInfo = getDestinationDeliveryData();
    if (selectedDestinationIsUser) {
      segmentTrackEvent({ generalOnCompleted: { OnCompleted: true } });
    }
    assignOrUnassign(
      selectedDestinationIsUser,
      requireLogistics,
      !!employeMessage,
      originAndDestinationData,
      logisticsServiceBillingData || null,
      billingCountry,
      toolsToManageData,
      assignmentInfo,
      collectionInfo,
      deliveryInfo,
      optionalEmployeeFile?.documentFile,
      invoiceAdditionalEmail,
      setLoadingSubmission,
      handeSuccessfulAssignament
    );
  };

  const checkIfCommentIsRequired = (tools: ItoolEssentialData[]) => {
    const toolsThatHaveAdditionalServices = tools.filter(element => !!element?.newLogisticAdditionalServices?.length);
    if (toolsThatHaveAdditionalServices && toolsThatHaveAdditionalServices.length > 0) {
      return toolsThatHaveAdditionalServices.some(toolsThatHaveAdditionalServices =>
        toolsThatHaveAdditionalServices.newLogisticAdditionalServices?.find(
          additionalServices =>
            (additionalServices.id === 2 || additionalServices.id == 3) && !additionalServices?.comments
        )
      );
    }
    return false;
  };

  const handleStep2ButtonIsDisabled = () => {
    return (
      !isDataComplete(selectedDestination, destinationData, listOfCountriesOpenForLogistics) ||
      !isDataComplete(originData?.place || '', originData, listOfCountriesOpenForLogistics)
    );
  };

  const handleStep3ButtonIsDisabled = () =>
    toolsToManageData && toolsToManageData.length > 0 && checkIfCommentIsRequired(toolsToManageData);

  const selectedDatesRange = dateRange && formatDateRange(dateRange);

  const onlyNames = (additional: IadditionalServiceDataForToolLogistics[]) => {
    const names: string[] = [];
    const originalList = additional;
    for (let i = 0; i < originalList.length; i++) {
      const indexItem = originalList[i];
      if (indexItem.id) {
        names.push(
          indexItem.id === 1
            ? 'Wiping'
            : indexItem.id === 2
            ? 'configuración básica'
            : indexItem.id === 3
            ? 'configuración avanzada'
            : ''
        );
      }
    }
    return names;
  };

  const formatRefuseTools = (tools: ItoolEssentialData[]) => {
    const toolsList: IToolsRejected[] = [];
    const originalList = tools;
    for (let i = 0; i < originalList.length; i++) {
      const indexItem = originalList[i];
      const newItem: IToolsRejected = {
        sku: indexItem?.sku || '',
        productId: Number(indexItem?.productId),
        productName: indexItem?.commercialName || '',
        productBrand: indexItem?.brand || '',
        serial: indexItem?.serial || '',
        nudosCareName: indexItem?.nudosCareName as TnudosCare,
        logisticAdditionalServices: onlyNames(indexItem?.newLogisticAdditionalServices || [])
      };
      if (newItem?.productId) {
        toolsList.push(newItem);
      }
    }
    return toolsList;
  };
  const redirectRefusedService = async () => {
    const collectionName = `${originData?.firstName} ${originData?.lastName}`;
    const deliveryName = `${destinationAsUser?.firstName} ${destinationAsUser?.lastName}`;
    const originLocationData = {
      place: (originData?.userId && 'user') || originData?.place,
      userName: collectionName,
      countryName: originData?.country?.name,
      locationName: originData?.locationName
    };
    const destinationLocationData = {
      place: (destinationAsUser?.userId && 'user') || destinationAsLocation?.place || selectedDestination,
      userName: deliveryName,
      countryName: destinationData?.country?.name,
      locationName: destinationAsLocation?.locationName
    };
    const logisticServiceData = {
      products: toolsToManageData,
      estimatedDelivery: estimatedDeliveryTimeByCountry,
      dateRange: selectedDatesRange,
      originLocationData: originLocationData,
      destinationLocationData: destinationLocationData
    };
    const redirectionDataObject = {
      pathname: `/nodi/request-logistics/rejected`,
      state: {
        originUrl: originUrl,
        logisticServiceData: logisticServiceData,
        flux: 'management'
      }
    };
    push(redirectionDataObject);
  };

  const refuseLogisticService = async (sendComment?: boolean) => {
    setLoadingRejected(true);
    const destinationTool = destinationData as Iemployee;
    const rejectedBody = {
      organizationId: Number(orgData?.organizationId),
      userId: Number(orgData?.userId),
      origin: {
        country: originData?.country?.name || '',
        city: originData?.city || '',
        place: 'user'
      },
      destination: {
        country: destinationTool?.country?.name || '',
        city: destinationTool?.city || '',
        place: selectedDestination as TstoragePlace
      },
      rejectComments: sendComment ? rejectComments || '' : '',
      rejectedAmount: Number(detailsQuote?.total),
      tools: formatRefuseTools(toolsToManageData || [])
    };
    try {
      await rejectedLogisticService(rejectedBody);
      await redirectRefusedService();
      sendComment &&
        displaySuccessNotification({
          customJSXMessage: (
            <>Gracias por tus comentarios, los tomaremos en cuenta para mejorar nuestros servicios para ti.</>
          )
        });
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingRejected(false);
    }
  };

  const getSecondButtonOnlyShownForBillingStep = () => {
    if (!validationToDisplaySecondBillingButton) return <></>;
    return (
      <div
        className="billingButtonContainer"
        onClick={() => {
          setShowCancelModal(true);
        }}
      >
        <NudosButton
          isButtonLoading={loadingRejected}
          isButtonDisabled={loadingQuote || errorQuote || loadingSubmission}
          buttonText="Rechazar y cancelar servicio"
          colorPalette="secondary"
        />
      </div>
    );
  };

  const resetSteps = () => {
    setShowTimeOutModal(false);
    setStep(0);
  };

  const getQuouteDetails = (details: IDetailsQuote) => {
    setDetailsQuote(details);
  };

  const handleLoadingQuote = (loading: boolean) => {
    setLoadingQuote(loading);
  };

  const handleErrorQuote = (therIsAnError: boolean) => {
    setErrorQuote(therIsAnError);
  };

  const toolManagementModuleSteps = [
    {
      buttonData: {
        text: 'Siguiente',
        callback: advanceStep,
        loading: initialLoading,
        isDisabled: !destinationData
      },
      showStep: !destinationIsWarrantyOrDiagnostics,
      component: (
        <ToolsManagementStep1SelectDestination
          originUrl={originUrl}
          originData={originData}
          destinationData={destinationData}
          selectedDestination={selectedDestination || undefined}
          updateDestinationDataCallback={updateDestinationDataCallback}
          nudosWarehousesList={nudosWarehousesList}
          requireLogistics={requireLogistics}
          loadingWarehousesList={loadingWarehousesList}
          filterCountriesForLogistics={requireLogistics ? listOfCountriesOpenForLogistics : undefined}
        />
      )
    },
    {
      buttonData: {
        text: 'Siguiente',
        callback: step2UpdateLocationsCallback,
        loading: loadingLocations,
        isDisabled: handleStep2ButtonIsDisabled()
      },
      showStep: requireLogistics,
      component: (
        <ToolsManagementStep2ConfirmLocations
          selectedOrigin={originData?.place || ''}
          selectedDestination={selectedDestination || ''}
          originData={originData}
          destinationData={destinationData}
          updateOriginDataCallback={updateOriginDataCallback as (newData: TlogisticsOriginOrDestinationData) => void}
          updateDestinationDataCallback={updateDestinationDataCallback}
          listOfCountries={listOfAllCountries}
        />
      )
    },
    {
      buttonData: {
        text: 'Siguiente',
        callback: logicSelectTools,
        loading: false,
        isDisabled: handleStep3ButtonIsDisabled()
      },
      showStep: true,
      component: (
        <ToolsManagementStep3Tools
          toolsToManage={toolsToManageData}
          handleUpdateSelectedToolsToAssign={setToolsToManageData}
          selectedDestination={selectedDestination}
          originData={originData}
          destinationData={destinationData}
          nudosWarehousesList={nudosWarehousesList}
        />
      )
    },
    {
      buttonData: {
        text: 'Siguiente',
        callback: advanceStep,
        loading: false,
        isDisabled: requireLogistics && !existDateRange
      },
      showStep: requireLogistics,
      component: (
        <ToolsManagementStep4SelectDate
          type={isLocalAssignation ? 'local' : 'international'}
          startDay={aproximateDay}
          selectedDatesAreForCollectionNotDelivery={selectedDatesAreForCollectionNotDelivery}
          handleCheckRange={handleCheckRange}
          isValidRange={isValidRange}
          defaultDateRange={dateRange}
          updateLogisticsCommentsCallback={updateLogisticsComments}
          logisticsComments={logisticsComments}
          estimatedDeliveryTimeByCountry={estimatedDeliveryTimeByCountry}
        />
      )
    },
    {
      buttonData: {
        text: setAndContinueText,
        callback: advanceStep,
        loading: false,
        isDisabled: !employeMessage
      },
      showStep: selectedDestinationIsUser,
      component: (
        <ToolsManagementStep5SendEmail
          destinationEmployeeData={destinationData ? (destinationData as Iemployee) : undefined}
          handleEmployeeMessage={handleEmployeeMessage}
          defaultEmployeeMessage={employeMessage}
          handleChangeOptionalFile={handleChangeOptionalFile}
          defaultFile={optionalEmployeeFile}
        />
      )
    },
    {
      buttonData: {
        text: showQuoteAndBillingData
          ? 'Aprobar servicio'
          : requireLogistics
          ? 'Cotizar el servicio logístico'
          : confirmOnboardingText,
        callback: showQuoteAndBillingData ? checkDate : handleToolManagement,
        loading: loadingSubmission,
        isDisabled: showQuoteAndBillingData ? !logisticsServiceBillingData : false
      },
      showStep: true,
      component: showQuoteAndBillingData ? (
        <LogisticsQuoteAndBillingStep
          originData={originData}
          destinationData={destinationData}
          selectedDatesRange={selectedDatesRange}
          logisticsEstimatedTimeByCountry={estimatedDeliveryTimeByCountry}
          logisticsType="assignment"
          resetSteps={resetSteps}
          showTimeOutModal={showTimeOutModal}
          toolsData={toolsToManageData}
          place={selectedDestination}
          getQuouteDetails={getQuouteDetails}
          handleLoadingQuote={handleLoadingQuote}
          handleErrorQuote={handleErrorQuote}
        />
      ) : (
        <ToolsManagementStep6DataVerification
          originData={originData}
          destinationData={destinationData}
          dateRange={dateRange}
          toolsData={toolsToManageData}
          selectedOrigin={originData?.place || ''}
          selectedDestination={selectedDestination}
          type={isLocalAssignation ? 'locale' : 'international'}
          emailToAssignee={employeMessage}
          estimatedDeliveryTimeByCountry={estimatedDeliveryTimeByCountry}
          selectedDatesAreForCollectionNotDelivery={selectedDatesAreForCollectionNotDelivery}
        />
      )
    }
  ];

  const definitiveSteps = toolManagementModuleSteps?.filter(step => !!step?.showStep);

  const ommitEmailButtonOnlyShownForAssignment =
    selectedDestinationIsUser && ((step === 4 && requireLogistics) || (step === 2 && !requireLogistics)) ? (
      <div className="emailButtonContainer">
        <NudosButton
          colorPalette="secondary"
          buttonText="Omitir"
          isButtonDisabled={
            !!employeMessage || !!optionalEmployeeFile?.documentFile || !!optionalEmployeeFile?.documentName
          }
          handleClick={definitiveSteps[step]?.buttonData?.callback}
        />
      </div>
    ) : null;

  const warehouseLocation = async (countryId: number) => {
    if ((selectedDestination === 'warranty' || selectedDestination === 'diagnostic') && originData) {
      try {
        setLoaderWareHouse(true);
        const warehouseData = await getWarehouseLocation(Number(countryId), selectedDestination);
        if (warehouseData?.id) {
          setDestinationData({ ...warehouseData });
        }
        setLoaderWareHouse(false);
      } catch (error) {
        displayErrorNotification();
        setLoaderWareHouse(false);
      }
    }
  };

  useEffect(() => {
    if (originDataCountry) {
      if (originDataCountry) {
        warehouseLocation(originDataCountry.id);
      }
    }
  }, [selectedDestination, originDataCountry?.id]);

  useEffect(() => {
    getNudosWarehousesList(setNudosWarehousesList, setLoadingWarehousesList);
    getListOfCountriesByOfferedService(setListOfCountriesOpenForLogistics, 'logisticService');
    getListOfCountriesByOfferedService(setListOfAllCountries);
    if (toolsIds && toolsIds.length) getToolData(toolsIds[0], updateOneToolToManage);
    return () => cleanLsBillingSate();
  }, []);

  useEffect(() => {
    const toolToManage = toolsToManageData ? toolsToManageData[0] : undefined;
    if (!toolToManage || !toolToManage?.locationId || originData) return;
    getLocationData(toolToManage.locationId, setOriginData);
    setInitialLoading(false);
  }, [toolsToManageData]);

  useEffect(() => {
    assignRangeDays();
  }, [originDataCountry?.code, destinationCountryData?.code]);

  if (initialLoading)
    return (
      <div className="ToolsManagementModule initialLoading">
        <GenericLoader />
      </div>
    );
  return (
    <div className="ToolsManagementModule">
      {showCancelModal && (
        <CancelLogisticServiceModal
          updateValue={e => {
            setRejectComments(e);
          }}
          skipCancellation={() => refuseLogisticService(false)}
          continueCancellation={() => refuseLogisticService(true)}
          defaultValue={rejectComments}
          loading={loadingRejected}
        />
      )}
      <NudosBreadcrumbButton returnText={getReturnText()} returnAction={breadcrumbButtonReturnAction} />
      <ToolManagementStepper
        currentStep={step}
        requireLogistics={requireLogistics}
        includeEmailStep={selectedDestinationIsUser}
        includeDestinationStep={!destinationIsWarrantyOrDiagnostics}
        includeQuoteAndBillingStep={showQuoteAndBillingData}
      />
      {definitiveSteps[step]?.component}
      {/* The following is meant to position the button at the bottom of the component while ensuring there is always at least 32px of space between the content preceding the button and the button itself. */}
      <div className="spaceBeforeButton h-20 w-10" />
      <div className="spaceBeforeButton h-0.5 w-10" />
      <div className="toolsManagementFloatButton">
        {ommitEmailButtonOnlyShownForAssignment}
        {getSecondButtonOnlyShownForBillingStep()}
        <NudosButton
          customClassName="buttonPosition"
          isButtonDisabled={
            definitiveSteps[step]?.buttonData?.isDisabled ||
            loadingRejected ||
            loadingQuote ||
            errorQuote ||
            loaderWarehouse
          }
          componentSize="large"
          isButtonLoading={definitiveSteps[step]?.buttonData?.loading || loadingShowBillingData || loaderWarehouse}
          buttonText={definitiveSteps[step]?.buttonData?.text}
          handleClick={definitiveSteps[step]?.buttonData?.callback || advanceStep}
        />
      </div>
    </div>
  );
};

export default ToolsManagementModule;
