import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" ref={ref} {...props}>
    <g clipPath="url(#clip0_9595_43563)">
      <path
        d="M0.935155 9.02153C1.07345 10.2553 2.07625 11.2348 3.3163 11.2946C4.1734 11.3358 5.04896 11.3573 5.99922 11.3573C6.94949 11.3573 7.82504 11.3358 8.68217 11.2946C9.9222 11.2348 10.925 10.2553 11.0633 9.02153C11.1564 8.19067 11.2329 7.33886 11.2329 6.47113C11.2329 5.60341 11.1564 4.7516 11.0633 3.92076C10.925 2.687 9.9222 1.70742 8.68217 1.6477C7.82504 1.60642 6.94949 1.58496 5.99922 1.58496C5.04896 1.58496 4.1734 1.60642 3.3163 1.6477C2.07625 1.70742 1.07345 2.687 0.935155 3.92076C0.842029 4.7516 0.765625 5.60341 0.765625 6.47113C0.765625 7.33886 0.84203 8.19067 0.935155 9.02153Z"
        fill="white"
        stroke="#CCCCCC"
      />
      <path
        d="M4.57317 0.642578C4.57317 0.366436 4.34932 0.142578 4.07317 0.142578C3.79703 0.142578 3.57317 0.366436 3.57317 0.642578H4.57317ZM3.57317 2.81902C3.57317 3.09516 3.79703 3.31902 4.07317 3.31902C4.34932 3.31902 4.57317 3.09516 4.57317 2.81902H3.57317ZM8.4186 0.642578C8.4186 0.366436 8.19474 0.142578 7.9186 0.142578C7.64246 0.142578 7.4186 0.366436 7.4186 0.642578H8.4186ZM7.4186 2.81902C7.4186 3.09516 7.64246 3.31902 7.9186 3.31902C8.19474 3.31902 8.4186 3.09516 8.4186 2.81902H7.4186ZM3.57317 0.642578V2.81902H4.57317V0.642578H3.57317ZM7.4186 0.642578V2.81902H8.4186V0.642578H7.4186ZM1.01562 5.21401H11.0335V4.21401H1.01562V5.21401Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_9595_43563">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconRangeDateCalendarDisabled = memo(ForwardRef);
export default IconRangeDateCalendarDisabled;
