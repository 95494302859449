import * as React from 'react';
import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={32} fill="none" ref={ref} {...props}>
    <path
      fill="#FF664E"
      d="M44.504 5.283h3.288v13.154h-3.288V5.283ZM47.833 2.98a1.644 1.644 0 1 1-3.289 0 1.644 1.644 0 0 1 3.289 0Z"
    />
    <circle cx={21.192} cy={12.08} r={6.093} fill="#FF664E" stroke="#FF664E" strokeWidth={0.53} />
    <path
      fill="#fff"
      d="M20.342 11.677a1.731 1.731 0 0 0-.497-1.213 1.692 1.692 0 0 0-1.198-.503 1.677 1.677 0 0 0-1.2.503 1.719 1.719 0 0 0-.496 1.213h.848a.864.864 0 0 1 .523-.793.838.838 0 0 1 .924.186.86.86 0 0 1 .248.607h.848ZM22.887 12.484a1.732 1.732 0 0 1-.497 1.213 1.695 1.695 0 0 1-1.199.502 1.678 1.678 0 0 1-1.198-.502 1.719 1.719 0 0 1-.497-1.213h.848a.864.864 0 0 0 .523.792.84.84 0 0 0 .924-.186.86.86 0 0 0 .248-.606h.848ZM25.428 11.677a1.731 1.731 0 0 0-.497-1.213 1.692 1.692 0 0 0-1.198-.503 1.676 1.676 0 0 0-1.2.503 1.717 1.717 0 0 0-.496 1.213h.848a.864.864 0 0 1 .523-.793.837.837 0 0 1 .924.186.858.858 0 0 1 .248.607h.848Z"
    />
    <path
      fill="#FF664E"
      d="M0 12.092c0-1.79.616-3.304 1.85-4.54 1.218-1.22 2.72-1.83 4.507-1.83 1.771 0 3.274.618 4.507 1.854 1.234 1.236 1.85 2.741 1.85 4.516v6.345H9.513l-.023-6.345c0-.872-.301-1.617-.902-2.234a3.036 3.036 0 0 0-2.23-.927c-.87 0-1.613.309-2.23.927a3.048 3.048 0 0 0-.925 2.234v6.345H-.001v-6.345ZM41.854 12.068c0 1.775-.618 3.28-1.854 4.516-1.22 1.236-2.717 1.854-4.492 1.854-1.79 0-3.303-.61-4.54-1.83-1.22-1.236-1.83-2.75-1.83-4.54 0-1.774.619-3.272 1.854-4.492 1.236-1.236 2.742-1.853 4.516-1.853 1.775 0 3.272.617 4.492 1.853 1.236 1.22 1.854 2.718 1.854 4.492Zm-3.209 0c0-.855-.308-1.592-.926-2.21-.618-.618-1.355-.927-2.21-.927-.872 0-1.617.31-2.235.927-.618.602-.927 1.339-.927 2.21 0 .872.31 1.617.927 2.235a3.045 3.045 0 0 0 2.234.926c.872 0 1.608-.309 2.21-.926a3.045 3.045 0 0 0 .927-2.235Z"
    />
    <path fill="#FF664E" d="M38.516 0h3.338v12.08l-3.338-1.028V0Z" />
    <path
      fill="#999"
      d="M4.209 32v-9.426h6.975v1.24H5.623v2.894h3.663v1.199H5.623V32H4.209ZM14.849 32v-9.426h4.268c.629 0 1.176.117 1.643.35.476.234.844.561 1.104.983.27.413.404.898.404 1.455 0 .62-.175 1.153-.525 1.602-.35.449-.821.772-1.414.97L22.43 32h-1.562l-1.953-3.864h-2.652V32h-1.414Zm1.414-5.05h2.747c.556 0 1-.139 1.333-.417.34-.287.511-.673.511-1.158 0-.467-.17-.844-.511-1.131-.332-.287-.777-.43-1.333-.43h-2.747v3.137ZM26.014 32v-9.426h6.949v1.24h-5.535v2.827h3.636v1.198h-3.636v2.923h5.589V32h-7.003ZM36.76 32v-9.426h6.948v1.24h-5.535v2.827h3.636v1.198h-3.636v2.923h5.588V32H36.76Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const NodiLogoFree = memo(ForwardRef);
export default NodiLogoFree;
