import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <circle className="outer-circle" cx={12} cy={12} r={11.5} stroke={`${props.stroke || '#ff664e'}`} fill="none" />
    <circle className="inner-circle" cx={12} cy={12} r={9} fill={`${props.fill || '#ff664e'}`} />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const RadioButton = memo(ForwardRef);
export default RadioButton;
