import { useHistory } from 'react-router-dom';
import { CartIcon } from '../../../../../assets/images/views/Ecommerce';
import { NudosButton, NudosGenericModal } from '../../../../../components/NudosComponents';
import './ModalInvalidCart.scss';

const ModalInvalidCart = () => {
  const { push } = useHistory();

  return (
    <NudosGenericModal
      modalContent={
        <div id="modalInvalidCart">
          <div className="iconContainer">
            <div className="iconBox">
              <CartIcon style={{ transform: 'scale(1.5)' }} stroke="#54A1FC" fill="white" />
            </div>
          </div>
          <div className="infoContainer">
            Tu carrito está vacío, vuelve a seleccionar tus productos para continuar con tu compra
          </div>
          <div className="buttonContainer">
            <NudosButton
              customWidth="180px"
              customHeight="38px"
              buttonText="Ir a la tienda"
              handleClick={() => {
                push('/catalogue/home');
              }}
            />
          </div>
        </div>
      }
    />
  );
};

export default ModalInvalidCart;
