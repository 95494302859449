import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      className="path1"
      d="M4.98753 3.57129C3.64749 3.57129 2.37538 4.53527 2.53538 5.86572C2.54782 5.96909 2.56265 6.07753 2.57989 6.19289C2.63118 6.53617 2.93053 6.78558 3.27761 6.78558H3.67436L3.96383 8.58265C4.04565 9.09065 4.48412 9.46415 4.99867 9.46415C5.51322 9.46415 5.95168 9.09065 6.03351 8.58265L6.32297 6.78558H6.72483C7.06965 6.78558 7.36812 6.53954 7.42069 6.19875C7.43755 6.08987 7.45198 5.98703 7.46405 5.88844C7.62819 4.54849 6.3375 3.57129 4.98753 3.57129Z"
      fill="white"
      stroke={props?.stroke || 'black'}
      strokeLinejoin="round"
    />
    <path
      className="path2"
      d="M5.0001 3.39328C5.78908 3.39328 6.42868 2.75368 6.42868 1.9647C6.42868 1.17573 5.78908 0.536133 5.0001 0.536133C4.21113 0.536133 3.57153 1.17573 3.57153 1.9647C3.57153 2.75368 4.21113 3.39328 5.0001 3.39328Z"
      fill="white"
      stroke={props?.stroke || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const PersonIcon = memo(ForwardRef);
export default PersonIcon;
