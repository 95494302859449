import { FC, useState } from 'react';
import { NudosStaticAlertInComponent } from '../../../../../components/DesignSystem';
import { NudosGenericModal, NudosTextArea, NudosUploadFile } from '../../../../../components/NudosComponents';
import ExampleEmailModal from '../../../Components/ExampleEmailModal/ExampleEmailModal';

import { Iemployee } from '../../../../../types/global';
import './AssignmentModuleStep4SendEmail.scss';
import { useTranslation } from 'react-i18next';

interface IDocumentDetail {
  documentName: string;
  documentFile: File;
}
interface IAssignmentModuleStep4SendEmail {
  destinationEmployeeData?: Iemployee;
  handleEmployeeMessage?: (message: string) => void;
  defaultEmployeeMessage?: string;
  handleChangeOptionalFile?: (documentDetails: IDocumentDetail | undefined) => void;
  defaultFile?: IDocumentDetail;
}

const AssignmentModuleStep4SendEmail: FC<IAssignmentModuleStep4SendEmail> = ({
  destinationEmployeeData,
  handleEmployeeMessage,
  defaultEmployeeMessage,
  handleChangeOptionalFile,
  defaultFile
}: IAssignmentModuleStep4SendEmail) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:email:';
  const whatWriteText = t(`${translationKey}whatWriteText`);
  const exampleEmail = t(`${translationKey}exampleEmail`);
  const alertOptionText = t(`${translationKey}alertOptionText`);

  const [showExampleModal, setShowExampleModal] = useState<boolean>(false);

  const employeeName = `${destinationEmployeeData?.firstName || ''} ${destinationEmployeeData?.lastName || ''}`;
  const closeExampleModal = () => setShowExampleModal(false);

  const handleChangeEmployeeMessage = (message: string) => {
    if (handleEmployeeMessage) handleEmployeeMessage(message);
  };

  const titleText = t(`${translationKey}title`, {
    employeeName
  });
  const textareaLabel = t(`${translationKey}textareaLabel`);
  const textareaPlaceholder = t(`${translationKey}textareaPlaceholder`, {
    employeeName
  });

  return (
    <>
      {showExampleModal && (
        <NudosGenericModal
          clickOutsideCallback={() => setShowExampleModal(false)}
          closeModalCallback={() => closeExampleModal()}
          modalContent={<ExampleEmailModal closeModal={closeExampleModal} />}
        />
      )}
      <div id="assignmentModuleStep4SendEmail">
        <div className="boxAssignmentModuleStep4SendEmail">
          <div className="titleSendEmail">{titleText}</div>
          <div className="textAreaSendMail">
            <div className="boxTextAreaSendMail">
              <NudosTextArea
                label={textareaLabel}
                customFontSize={10}
                componentSize="large"
                currentValue={defaultEmployeeMessage || ''}
                maxLength={1000}
                placeholder={textareaPlaceholder}
                updateCurrentValueCallback={e => handleChangeEmployeeMessage(e)}
                textHeight={64}
                noResize
                borderError={!defaultEmployeeMessage && !!defaultFile}
              />
            </div>
          </div>
          <div className="fileSendEmail">
            <NudosUploadFile size={380} handleExternalDocuments={handleChangeOptionalFile} defaultFile={defaultFile} />
          </div>
          <div className="containerLine">
            <div className="mailGrayLine" />
          </div>
          <div className="inferiorAlertEmail">
            <div className="boxAlertEmail">
              <NudosStaticAlertInComponent
                customClassname="staticComponent"
                type="bulb"
                customTitleClass="titleStaticComponent"
                alertOptions={{
                  title: alertOptionText
                }}
              />
            </div>
          </div>
          <div className="containerEmailExample">
            <div className="boxEmailExample">
              <div className="infoText">{whatWriteText}</div>
              <div className="blueText" onClick={() => setShowExampleModal(true)}>
                &nbsp;{exampleEmail}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentModuleStep4SendEmail;
