import { useHistory } from 'react-router-dom';
import { NudosButton } from '../../../../../components/NudosComponents';
import { ItoolEssentialData } from '../../../../../types/requestLogisticsModule';
import { IconToolComputer } from '../../../../../assets/DesignSystem/SVGComponents';
import { useTranslation } from 'react-i18next';
import './AssignmentModuleStep1SelectTools.scss';

const NoToolsToAssignSection = ({
  openModalCallback,
  allToolsAvailableToAssignForThisOrganization,
  selectedToolsToAssign
}: {
  openModalCallback: () => void;
  allToolsAvailableToAssignForThisOrganization: ItoolEssentialData[];
  selectedToolsToAssign: ItoolEssentialData[];
}) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:';
  const notSelectedToolsDescriptionText = t(`${translationKey}notSelectedToolsDescription`);
  const youHaveNoDevicesAvailableText = t(`${translationKey}youHaveNoDevicesAvailable`);
  const acquireAToolOrUploadYourOwnDevicesFromInventoryText = t(
    `${translationKey}acquireAToolOrUploadYourOwnDevicesFromInventory`
  );
  const uploadDevicesText = t(`${translationKey}uploadDevices`);
  const searchByInventoryText = t(`${translationKey}searchByInventoryText`);
  const buyToolsText = t(`${translationKey}buyToolsText`);
  const { push } = useHistory();
  const organizationHasSomeAvailableToolsToAssign = allToolsAvailableToAssignForThisOrganization.length > 0;
  const haveSelectedToolsToAssign = selectedToolsToAssign.length > 0;
  const noAvailableToolsStyle = !organizationHasSomeAvailableToolsToAssign ? 'noAvailableTools' : '';

  const redirectToUploadTools = () => push(`/nodi/upload-external-tools`);
  const redirectToEcommerce = () => push(`/catalogue/home`);

  return (
    <div className="NoToolsToAssignSection">
      <div className="iconContainer">
        <IconToolComputer className={`IconToolComputer ${noAvailableToolsStyle}`} />
      </div>
      <div className="explainationTextsSections">
        {!organizationHasSomeAvailableToolsToAssign && (
          <div className="doesntHaveToolsTitle">{youHaveNoDevicesAvailableText}</div>
        )}
        {!organizationHasSomeAvailableToolsToAssign && (
          <div className="doesntHaveToolsText">{acquireAToolOrUploadYourOwnDevicesFromInventoryText}</div>
        )}
        {organizationHasSomeAvailableToolsToAssign && !haveSelectedToolsToAssign && (
          <div className="noToolsSelectedText">{notSelectedToolsDescriptionText}</div>
        )}
      </div>
      <div className="buttonsSection">
        {!organizationHasSomeAvailableToolsToAssign && (
          <>
            <NudosButton
              componentSize="small"
              colorPalette="secondary"
              buttonText={uploadDevicesText}
              handleClick={redirectToUploadTools}
            />
            <NudosButton componentSize="small" buttonText={buyToolsText} handleClick={redirectToEcommerce} />
          </>
        )}
        {organizationHasSomeAvailableToolsToAssign && !haveSelectedToolsToAssign && (
          <NudosButton
            componentSize="small"
            buttonText={searchByInventoryText}
            customClassName="noSelectedToolsButton"
            handleClick={openModalCallback}
          />
        )}
      </div>
    </div>
  );
};

export default NoToolsToAssignSection;
