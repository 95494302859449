import { FC, Fragment, useEffect, useState } from 'react';
import { ReferenceOptions } from '../ReferenceOptions';
import { ICartShipping, IProductShipping, IProductsCart } from '../../../../../../../types/cart';
import useCartState from '../../../../../../../state/useCartState';
import useShopStore from '../../../../../../../state/useShopContext';
import { Iemployee } from '../../../../../../../types/global';
import { IWarehouse, IorganizationOfficeData } from '../../../../../../../types/account';
import { ICountryDetail } from '../../../../../../../types/countries';
import NudosDynamicBanner from '../../../../../../../components/DesignSystem/NudosDynamicBanner/NudosDynamicBanner';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../../../../utils/segment';
import './ShippingReference.scss';

interface IShippingReference {
  openAddOfficeAddress: (getOfficeAddressCallback?: (data?: IorganizationOfficeData) => void) => Promise<void> | void;
  officeAddress?: IorganizationOfficeData;
  defaultOfficeAddress?: boolean;
  setEveryOneOffice?: (check: boolean) => void;
  referenceData: IProductsCart;
  countryName: string;
  everyOneOffice?: boolean;
  referenceInitialShipmentsData?: ICartShipping[];
  globalShippingCartWasPreloaded?: boolean;
  countryWarehouse?: IWarehouse;
  completeListOfCountries?: ICountryDetail[];
}

const ShippingReference: FC<IShippingReference> = ({
  openAddOfficeAddress,
  officeAddress,
  defaultOfficeAddress,
  setEveryOneOffice,
  referenceData,
  countryName,
  everyOneOffice,
  referenceInitialShipmentsData,
  globalShippingCartWasPreloaded,
  countryWarehouse,
  completeListOfCountries
}: IShippingReference) => {
  const { t } = useTranslation();

  const { globalShippingCart, setGlobalShippingCart } = useCartState();
  const { itemsCart } = useShopStore();
  const [shippings, setShippings] = useState<IProductsCart[]>();
  const [openDynamicBanner, setOpenDynamicBanner] = useState<boolean>(false);
  const translationKey = 'nodi:';

  const optionsSelect = [
    { value: 1, name: t(`designSystemComponents:referenceOptions:officeAddress`) },
    {
      value: 2,
      name: t(`designSystemComponents:referenceOptions:employee`)
    },
    {
      value: 3,
      name: t(`nodi:toolInternal:NodiDetailsHeader:nudosWarehouse`)
    }
  ];

  const associatedShippingCart = (
    index: number,
    type: 'employee' | 'office' | 'nudos',
    employeeInfo?: Iemployee,
    officeData?: IorganizationOfficeData,
    storeNudosData?: IWarehouse
  ) => {
    const myReferenceList = [...(globalShippingCart?.shipments || [])];
    const reduceList = myReferenceList.filter(
      element => element?.productId === referenceData?.productId && element?.countryId === referenceData?.countryId
    );
    const assignElement = reduceList[index];
    const referenceIndex = myReferenceList?.findIndex(
      element => element?.onlyShippingUid === assignElement?.onlyShippingUid
    );

    const employeeFullName =
      type === 'employee' && employeeInfo ? `${employeeInfo?.firstName} ${employeeInfo?.lastName}` : '';
    const employeePhone =
      type === 'employee' && employeeInfo
        ? `+${employeeInfo?.phoneData?.phoneCode} ${employeeInfo?.phoneData?.phone}`
        : '';
    const receiverPersonalId =
      type === 'employee' ? employeeInfo?.personalId : officeData?.shipmentInfo?.receiverIdDocument;
    const receiverName = type === 'employee' ? employeeFullName : officeData?.shipmentInfo?.receiverName;
    const receiverPhone = type === 'employee' ? employeePhone : officeData?.shipmentInfo?.receiverPhone;

    myReferenceList[referenceIndex] = {
      ...assignElement,
      destinationLocationId:
        type === 'employee'
          ? employeeInfo?.locationId || undefined
          : type === 'nudos'
          ? storeNudosData?.locationId
          : officeData?.locationId,
      employee: type === 'employee' ? employeeInfo : undefined,
      receiverInformation:
        receiverName && receiverPhone
          ? {
              personalId: receiverPersonalId,
              receiverName: receiverName,
              receiverPhone: receiverPhone
            }
          : undefined,
      destinationIsOffice: type === 'office'
    };
    if (itemsCart?.shoppingCartId) {
      const newShippingCart: IProductShipping = {
        shoppingCartId: itemsCart?.shoppingCartId,
        shipments: myReferenceList
      };
      setGlobalShippingCart(newShippingCart);
    }
  };

  const resetReference = (index: number) => {
    const myReferenceList = globalShippingCart?.shipments || [];
    const reduceList = myReferenceList.filter(
      element => element?.productId === referenceData?.productId && element?.countryId === referenceData?.countryId
    );
    const assignElement = reduceList[index];
    const referenceIndex = myReferenceList?.findIndex(
      element => element?.onlyShippingUid === assignElement?.onlyShippingUid
    );
    myReferenceList[referenceIndex] = {
      ...assignElement,
      destinationLocationId: undefined,
      employee: undefined,
      receiverInformation: undefined,
      destinationIsOffice: undefined
    };
    if (itemsCart?.shoppingCartId) {
      const newShippingCart: IProductShipping = {
        shoppingCartId: itemsCart?.shoppingCartId,
        shipments: myReferenceList
      };
      setGlobalShippingCart({ ...newShippingCart });
    }
  };

  const handleDynamicBanner = (state: boolean) => {
    setOpenDynamicBanner(state);
    state &&
      segmentTrackEvent({
        freeCheckoutBuyAndHoldModalView: null
      });
  };

  useEffect(() => {
    if (referenceData && referenceData?.quantity && referenceData.quantity > 0) {
      const items = Array(referenceData.quantity).fill({ referenceData });
      setShippings(items);
    }
  }, []);

  return (
    <div id="shippingReference" className="extraShippingReference">
      {openDynamicBanner && (
        <NudosDynamicBanner
          bannerSubtitle={t(`${translationKey}checkout:shippingReference:bannerSubtitle`)}
          bannerTitle={t(`${translationKey}checkout:shippingReference:bannerTitle`)}
          closeModalCallback={() => handleDynamicBanner(false)}
          submitBannerCallback={() => handleDynamicBanner(false)}
        />
      )}
      <div className="generalReferenceContainer">
        <div className="referenceInfo">
          <div className="referenceImg">
            <img className="containerReference" src={referenceData?.image || ''} />
          </div>
          <div className="nameAndPills">
            <div className="nameAndBrand">
              <div className="limitName">{referenceData?.productName}</div>
              <div className="limitBrandImg">
                <img className="brandImg" src={referenceData?.brandImage} />
              </div>
            </div>
            <div className="referencePills">
              {referenceData?.pills &&
                Object?.keys(referenceData?.pills) &&
                Object?.keys(referenceData?.pills).length > 0 &&
                Object?.keys(referenceData?.pills).map(item => {
                  return (
                    <Fragment
                      key={`reference-pill-${item}-productId-${referenceData?.productId}-countryId-${referenceData?.countryId}`}
                    >
                      {!item.includes('Perfil') && (
                        <div className="referencePill">
                          {item === 'Cargo' ? `Cargo ${referenceData?.pills?.[item]}` : referenceData?.pills?.[item]}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
            </div>
          </div>
          <div className="unitsReference">
            {`${referenceData?.quantity}   ${
              referenceData?.quantity > 1 ? t('recurrentWords:units') : t('recurrentWords:unit')
            }`}
          </div>
        </div>
        <div className="referenceGrayLine" />
        {shippings &&
          shippings.length > 0 &&
          shippings.map((item, index) => {
            const referenceInitialData =
              referenceInitialShipmentsData && index < referenceInitialShipmentsData.length
                ? referenceInitialShipmentsData[index]
                : undefined;
            return (
              <ReferenceOptions
                key={`reference-shipping-${index}`}
                externalIndex={index}
                officeAddress={officeAddress}
                optionsSelect={optionsSelect}
                openAddOfficeAddress={openAddOfficeAddress}
                defaultOfficeAddress={defaultOfficeAddress}
                setEveryOneOffice={setEveryOneOffice}
                handleShippingReference={(
                  type: 'employee' | 'office' | 'nudos',
                  employeeInfo?: Iemployee,
                  officeData?: IorganizationOfficeData,
                  storeNudosData?: IWarehouse
                ) => {
                  associatedShippingCart(index, type, employeeInfo, officeData, storeNudosData);
                }}
                productInfo={item}
                deleteShipping={() => resetReference(index)}
                countryName={countryName}
                everyOneOffice={everyOneOffice}
                referenceInitialData={referenceInitialData}
                globalShippingCartWasPreloaded={globalShippingCartWasPreloaded}
                countryWarehouse={countryWarehouse}
                refrenceProductId={referenceData.productId}
                completeListOfCountries={completeListOfCountries}
                handleDynamicBanner={state => {
                  handleDynamicBanner(state);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ShippingReference;
