import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M1.92673 10.3205C1.95579 10.7332 2.28039 11.0634 2.69267 11.0979C4.96216 11.2882 7.03742 11.2882 9.30691 11.0979C9.71919 11.0634 10.0438 10.7332 10.0728 10.3205C10.2492 7.81483 10.2492 5.44751 10.0728 2.94179C10.0438 2.52908 9.71928 2.19993 9.30862 2.14948C8.24806 2.01919 7.13992 1.85156 5.99979 1.85156C4.85966 1.85156 3.75152 2.01919 2.69094 2.14948C2.2803 2.19993 1.95578 2.52908 1.92673 2.94179C1.75033 5.44751 1.75033 7.81482 1.92673 10.3205Z"
      fill={props.fill || 'white'}
      stroke={props?.stroke || '#999999'}
    />
    <path d="M4.36621 5.58594H7.63407" stroke={props?.stroke || '#999999'} strokeLinecap="round" />
    <path d="M4.36621 8.07812H6.3382" stroke={props?.stroke || '#999999'} strokeLinecap="round" />
    <path
      d="M7.15217 0.640625H4.84817C4.14567 0.640625 3.57617 1.21012 3.57617 1.91262V1.91348C3.57617 2.61599 4.14567 3.18548 4.84817 3.18548H7.15217C7.85468 3.18548 8.42417 2.61599 8.42417 1.91348V1.91262C8.42417 1.21012 7.85468 0.640625 7.15217 0.640625Z"
      fill={props?.fill || 'white'}
      stroke={props?.stroke || '#999999'}
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuOrdersIcon = memo(ForwardRef);
export default MenuOrdersIcon;
