import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ToolManagementActionsCard from './ToolManagementActionsCard';
import {
  IconBuilding,
  IconEmployee,
  IconReserveBox,
  IconShield,
  IconToolWrench,
  IconXInSquare
} from '../../../../../assets/DesignSystem/SVGComponents';
import ManageToolActionModal from '../ManageToolActionModal/ManageToolActionModal';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import {
  disableToolManagementBeforeDeliveryAlertText,
  logisticServiceInCourseAlertText
} from '../../../../../utils/productDefinitions';
import './ToolManagementActions.scss';
import { TtoolCategories } from '../../../../../types/global';
import { segmentTrackEvent } from '../../../../../utils/segment';

const ToolManagementActions = ({
  orderId,
  toolProductId,
  toolManagementRequiresLogistics,
  toolCountryHasNudosWarehouse,
  toolSupportsWarrantyOrDiagnosticManagement,
  isCurrentlyAssigned,
  currentUserId,
  toolHasLogisticServicePending,
  toolCountryAllowsLogistics,
  showSkeletonLoader,
  toolAllowsWarrantyManagement,
  toolHasntBeenDelivered,
  categoryName,
  toolIsKit,
  productCountry
}: {
  orderId?: number | string | null;
  toolProductId?: number | string;
  toolManagementRequiresLogistics: boolean;
  toolCountryHasNudosWarehouse: boolean;
  toolSupportsWarrantyOrDiagnosticManagement: boolean;
  isCurrentlyAssigned: boolean;
  currentUserId?: number | null;
  toolHasLogisticServicePending: boolean;
  toolCountryAllowsLogistics: boolean;
  showSkeletonLoader?: boolean;
  toolAllowsWarrantyManagement: boolean;
  toolHasntBeenDelivered?: boolean;
  categoryName?: TtoolCategories;
  toolIsKit?: boolean;
  productCountry?: string;
}) => {
  const { push } = useHistory();
  const [selectedDestination, setSelectedDestination] = useState<TstoragePlace>();
  const [requireLogistics, setRequireLogistics] = useState(false);
  const [showLogisticsModal, setShowLogisticsModal] = useState(false);
  const logisticsInProgress = toolHasntBeenDelivered || toolHasLogisticServicePending;
  const accessoryIsAKit = categoryName === 'Accesorios' && toolIsKit;

  const inactiveWithoutLogisticsHover = (additionalStyles: string) => (
    <div className={`toolManagementActionCardHoverComponent inactiveWithoutLogisticsHover ${additionalStyles}`}>
      Puedes disfrutar de estos servicios en los países con Servicios Logísticos disponibles
    </div>
  );

  const inactiveWhileLogisticsInProgressHover = (additionalStyles: string) => (
    <div className={`toolManagementActionCardHoverComponent inactiveWhileLogisticsInProgressHover ${additionalStyles}`}>
      {logisticServiceInCourseAlertText}
    </div>
  );

  const InactiveWhileToolHasntBeenDelivered = (additionalStyles: string) => {
    const redirection = orderId ? `/nodi/orders/details/${orderId}?from=tool-details&id=${toolProductId}` : '';
    return (
      <div
        className={`toolManagementActionCardHoverComponent inactiveWhileLogisticsInProgressHover ${additionalStyles}`}
      >
        {disableToolManagementBeforeDeliveryAlertText}
        <Link to={redirection} className="link">
          Ver orden
        </Link>
      </div>
    );
  };

  const countryDoesntHaveWarehouse = (additionalStyles: string) => (
    <div className={`toolManagementActionCardHoverComponent disabledWarrantyManagementHover ${additionalStyles}`}>
      Puedes disfrutar de estos servicios en los países con bodegas Nudos
    </div>
  );

  const disabledWarrantyManagementHover = (additionalStyles: string) => (
    <div className={`toolManagementActionCardHoverComponent disabledWarrantyManagementHover ${additionalStyles}`}>
      Este servicio es exclusivo de herramientas compradas en tienda o aseguradas con Nudos Care
    </div>
  );

  const getHoverComponent = (additionalStyles: string) => {
    if (toolHasntBeenDelivered) return InactiveWhileToolHasntBeenDelivered(additionalStyles);
    if (toolHasLogisticServicePending) return inactiveWhileLogisticsInProgressHover(additionalStyles);
    if (!toolCountryAllowsLogistics) return inactiveWithoutLogisticsHover(additionalStyles);
    if (!toolCountryHasNudosWarehouse) return countryDoesntHaveWarehouse(additionalStyles);
    if (!toolAllowsWarrantyManagement) return disabledWarrantyManagementHover(additionalStyles);
    return <></>;
  };

  const originParams = `&origin=tool-details&originId=${toolProductId}`;
  const toolManagementFlowUrl = `/nodi/request-logistics/tools-management/[${toolProductId}]?`;
  const unassignmentFlowUrl = `/nodi/request-logistics/unassignment/${currentUserId}?tools=[${toolProductId}]&`;
  const appropiateFlowForToolSituation = isCurrentlyAssigned ? unassignmentFlowUrl : toolManagementFlowUrl;

  const getToolManagementDetailsParams = (logisticsParam?: boolean, destinationParam?: TstoragePlace) => {
    return `logistics=${logisticsParam || requireLogistics}&destination=${destinationParam || selectedDestination}`;
  };

  const openLogisticsModal = () => setShowLogisticsModal(true);
  const closeLogisticsModal = () => setShowLogisticsModal(false);

  const handleStartToolManagementFlux = () => {
    if (!selectedDestination || !toolProductId) return;
    const managementDestination = `${appropiateFlowForToolSituation}${getToolManagementDetailsParams()}${originParams}`;
    if (isCurrentlyAssigned) {
      if (requireLogistics) {
        segmentTrackEvent({
          nodiLogisticServicesHireClick: { CategoryName: categoryName || '', ProductCountry: productCountry || '' }
        });
      } else {
        segmentTrackEvent({
          nodiLogisticServicesUpdateClick: { CategoryName: categoryName || '', ProductCountry: productCountry || '' }
        });
      }
    }
    return push(managementDestination);
  };

  const handleClickCard = () => {
    if (!toolCountryAllowsLogistics) handleStartToolManagementFlux();
  };

  const handleSelectManagementAction = (destination: TstoragePlace) => {
    if (['nudos', 'warranty', 'diagnostic'].includes(destination)) {
      return push(
        `${appropiateFlowForToolSituation}${getToolManagementDetailsParams(true, destination)}${originParams}`
      );
    }
    if (toolManagementRequiresLogistics) {
      return push(
        `${appropiateFlowForToolSituation}${getToolManagementDetailsParams(true, destination)}${originParams}`
      );
    }
    if (!toolCountryAllowsLogistics) {
      return push(
        `${appropiateFlowForToolSituation}${getToolManagementDetailsParams(false, destination)}${originParams}`
      );
    }
    setSelectedDestination(destination);
    openLogisticsModal();
  };

  const toolManagementOptions = [
    {
      order: 1,
      showCard: !isCurrentlyAssigned,
      isActive: !logisticsInProgress,
      icon: <IconEmployee className="IconEmployee" />,
      hoverComponent: getHoverComponent(''),
      titleFirstLine: 'Asignar a',
      titleSecondLine: 'Empleado',
      handleSelectThisDestination: () => handleSelectManagementAction('user')
    },
    {
      order: 1,
      showCard: isCurrentlyAssigned,
      isActive: !logisticsInProgress,
      icon: <IconXInSquare className="IconXInSquare" />,
      hoverComponent: getHoverComponent(''),
      titleFirstLine: 'Desasignar',
      titleSecondLine: 'Herramienta',
      handleSelectThisDestination: () => handleSelectManagementAction('unassignment')
    },
    {
      order: 2,
      showCard: true,
      isActive: !logisticsInProgress,
      icon: <IconBuilding className="IconBuilding" />,
      hoverComponent: getHoverComponent(''),
      titleFirstLine: 'Mover a ',
      titleSecondLine: 'Oficinas',
      handleSelectThisDestination: () => handleSelectManagementAction('office')
    },
    {
      order: 3,
      showCard: true,
      isActive: !logisticsInProgress && toolCountryAllowsLogistics,
      icon: <IconReserveBox className="IconReserveBox" />,
      hoverComponent: getHoverComponent(''),
      titleFirstLine: 'Almacenar en',
      titleSecondLine: 'Bodegas',
      handleSelectThisDestination: () => handleSelectManagementAction('nudos')
    },
    {
      order: 4,
      showCard: toolSupportsWarrantyOrDiagnosticManagement,
      isActive:
        !logisticsInProgress &&
        toolCountryAllowsLogistics &&
        toolAllowsWarrantyManagement &&
        toolCountryHasNudosWarehouse,
      icon: <IconShield className="IconShield" />,
      hoverComponent: getHoverComponent(''),
      titleFirstLine: 'Aplicar',
      titleSecondLine: 'Garantía',
      handleSelectThisDestination: () => handleSelectManagementAction('warranty')
    },
    {
      order: 5,
      showCard: toolSupportsWarrantyOrDiagnosticManagement,
      isActive: !logisticsInProgress && toolCountryAllowsLogistics && toolCountryHasNudosWarehouse && !accessoryIsAKit,
      icon: <IconToolWrench className="IconToolWrench" />,
      hoverComponent: getHoverComponent('lastCard'),
      titleFirstLine: 'Solicitar',
      titleSecondLine: 'Diagnóstico',
      handleSelectThisDestination: () => handleSelectManagementAction('diagnostic')
    }
  ];

  const definitiveCards = toolManagementOptions.filter(card => !!card.showCard);

  return (
    <div className="ToolManagementActions">
      {definitiveCards.map((managementOption, i) => {
        return (
          <ToolManagementActionsCard
            key={`managementOption${i}${managementOption.order}`}
            cardOptions={managementOption}
            clickCardCallback={handleClickCard}
            showSkeletonLoader={showSkeletonLoader}
          />
        );
      })}
      {showLogisticsModal && (
        <ManageToolActionModal
          closeModalCallback={closeLogisticsModal}
          submitModalCallback={handleStartToolManagementFlux}
          updateLogisticsPredefinedCallback={setRequireLogistics}
        />
      )}
    </div>
  );
};

export default ToolManagementActions;
