import { v4 as uuid } from 'uuid';
import { Dot } from '../../views/ecommerce/checkout/Components/SVGcomponents';
import './GenericStepsProgressBar.scss';

export interface IgenericStepsProgressBar {
  stepsData: IprogressBarStepData[];
  currentStep: number;
  progressBarWidth?: number;
  individualLineWidth?: number;
}
/**
 * @property { IprogressBarStepData } stepsData - An array of objects with the structure { order: number; icon: JSX.Element}. The order is a base 0 number with the position of said Icon, the icon a JSX element.
 * @property { number } currentStep - REQUIRED: the current step, number based 0.
 * @property { number } progressBarWidth - optional, the width of the entire progress bar in pixels. Its value should be Total = (iconWidth * #icons) + (lineWidth * #lines). Default is 580px.
 * @property { number } individualLineWidth - optional, the width of each line (equal lengths) in pixels. Default value is 126px.
 */
const GenericStepsProgressBar = ({
  stepsData,
  currentStep,
  progressBarWidth,
  individualLineWidth
}: IgenericStepsProgressBar) => {
  const getStepStyle = (stepOrder: number) =>
    currentStep < stepOrder ? '' : currentStep === stepOrder ? 'current' : 'check';

  const getWidthStyle = (width?: number) => {
    if (!width) return undefined;
    return { width: `${width}px` };
  };

  return (
    <div className="genericStepsProgressBar" style={getWidthStyle(progressBarWidth)}>
      {stepsData?.map(step => {
        return (
          <div key={uuid()} className={`stepContainer ${getStepStyle(step?.order)}`}>
            <div className="stepDottedLine" style={getWidthStyle(individualLineWidth)}>
              <div className="container topContainer">{step?.order === 0 && <Dot />}</div>
              <div className="container" />
            </div>
            <div className={`stepIconContainer ${getStepStyle(step?.order)}`}>{step?.icon}</div>
            {step?.order === stepsData?.length - 1 && (
              <div className="stepDottedLine" style={getWidthStyle(individualLineWidth)}>
                <div className="afterLine container topContainer">{<Dot />}</div>
                <div className="container" />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GenericStepsProgressBar;

export interface IprogressBarStepData {
  order: number;
  icon: JSX.Element;
}
