import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={49} fill="none" ref={ref} {...props}>
    <rect width={48} height={48} y={0.5} fill="#F0F0F0" rx={24} />
    <path
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.667 15.167H20m0 0h6m-6 0V12.5m9.333 2.667H26m0 0c-1.093 3.646-3.385 7.093-6 10.124m0 0c-2.165 2.508-4.553 4.73-6.667 6.542M20 25.291c-1.333-1.458-3.467-4.391-4-5.458m4 5.458 4 3.876"
    />
    <path fill="#fff" d="m31.333 22.5-3.809 10h7.619l-3.81-10Z" />
    <path
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m26 36.5 1.524-4m0 0 3.81-10 3.809 10m-7.619 0h7.619m1.524 4-1.524-4"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconLanguageSign = memo(ForwardRef);
export default IconLanguageSign;
