import { FC } from 'react';
import { InudoInfo } from '../../../../../types/global';
import './HeaderNodiInternalProductDetails.scss';
import { truncateText } from '../../../../../utils/truncateText';
import { useTranslation } from 'react-i18next';
import ProductPills from '../../../../../components/ProductPills/ProductPills';
import { ToolSerial } from '../../../../../components';
import { NudosCareTag } from '../../../../../components/NudosComponents';
import LoaderHeaderNodiInternalProductDetails from './LoaderHeaderNodiInternalProductDetails';

interface IHeaderNodiInternalProductDetails {
  dataHeader?: InudoInfo;
  generalLoading?: boolean;
}

const HeaderNodiInternalProductDetails: FC<IHeaderNodiInternalProductDetails> = ({
  dataHeader,
  generalLoading
}: IHeaderNodiInternalProductDetails) => {
  const { t } = useTranslation();
  const i18nLanguage = 'nodi:toolInternal:NodiDetailsHeader:';

  return (
    <>
      {generalLoading ? (
        <LoaderHeaderNodiInternalProductDetails />
      ) : (
        <div id="headerNodiInternalProductDetails">
          <div className="boxHeaderInternalProduct">
            <div className="containerImg">
              <img className="limitsHeaderImg" src={dataHeader?.imageUrl} />
            </div>
            <div className="containerDetails">
              <div className="centerItems">
                <div className="topDetails">
                  <div className="brandDetails">
                    <img className="limitsImgBrand" src={dataHeader?.brandImage} />
                  </div>
                  <div className="textDetails">
                    {truncateText(dataHeader?.commercialName, 30)}
                    {dataHeader?.commercialName && dataHeader?.commercialName?.length > 30 && (
                      <div className="nameHover">{dataHeader?.commercialName}</div>
                    )}
                  </div>
                  <div className="flagDetails">
                    <div className="limitsFlag" style={{ backgroundImage: `url("${dataHeader?.country?.flag}")` }} />
                  </div>
                  <div className="availabilityDetails">
                    <div className="textAvailability">
                      {dataHeader?.assignedTo ? t(`${i18nLanguage}negativeStatus`) : t(`${i18nLanguage}positiveStatus`)}{' '}
                    </div>
                    <div className="availabilityIndicatorBox">
                      <div className={`${dataHeader?.assignedTo ? 'grayIndicator' : 'blueIndicator'}`} />
                    </div>
                  </div>
                </div>
                <div className="downDetails">
                  <div className="containerPills">
                    <ProductPills customHeight={20} pills={dataHeader?.pills} />
                  </div>
                  <div className="containerSerial">
                    <ToolSerial
                      customStyles={{ borderRadius: '4px', height: '20px' }}
                      serial={dataHeader?.serial || '-'}
                      charactersLimitForHover={25}
                    />
                  </div>
                  <div className="containerNudosCare">
                    <NudosCareTag
                      freeBG="#ECECEC"
                      freeFont="#383838"
                      customClass="classCareTag"
                      nudosCareType={dataHeader?.nudosCareName}
                      customHeight="20px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderNodiInternalProductDetails;
