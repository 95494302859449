import { memo } from 'react';
import { TimgSize } from '../../types/global';

const _ProductImage = ({ imgUrl, size }: { imgUrl?: string; size?: TimgSize }) => {
  const slicedUrl = imgUrl?.split('-');
  const urlBySize = slicedUrl && size && `${slicedUrl[0]}-${slicedUrl[1]}-${size}.png`;
  const imgScr = size ? urlBySize : imgUrl;
  return (
    <>
      <img src={imgScr} alt="product image" />
    </>
  );
};

const ProductImage = memo(_ProductImage);
export default ProductImage;
