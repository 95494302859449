import { useTranslation } from 'react-i18next';
import { NudosGenericModal, NudosModalContent } from '../../../../../../components/NudosComponents';
import ConfigurationCommentsModalContent from './ConfigurationCommentsModalContent';
import { capitalize } from '../../../../../../utils/unCamelCase';
import './ConfigurationCommentsModal.scss';

const ConfigurationCommentsModal = ({
  closeModalCallback,
  comments
}: {
  closeModalCallback: () => void;
  comments: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className="ConfigurationCommentsModal">
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            CustomModalComponent={
              <ConfigurationCommentsModalContent comments={comments} closeModalCallback={closeModalCallback} />
            }
            toCloseModal={closeModalCallback}
            customWidth={'580px'}
            customHeight={'446px'}
            modalContentTitle={capitalize(t('recurrentWords:configuration'))}
            customHeaderClass="leftAlignedTitle"
          />
        }
      />
    </div>
  );
};

export default ConfigurationCommentsModal;
