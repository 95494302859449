import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg className="homeIcon" width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g stroke="#999" strokeLinejoin="round">
      <path d="M.536 6.05 4.46 1.499a.714.714 0 0 1 1.082 0l3.924 4.55" strokeLinecap="round" />
      <path
        d="m1.91 4.46 2.548-2.969a.714.714 0 0 1 1.084 0l2.549 2.97.145 1.038a9.687 9.687 0 0 1-.019 2.82.969.969 0 0 1-.958.821H2.74a.969.969 0 0 1-.957-.821 9.69 9.69 0 0 1-.02-2.82l.146-1.038Z"
        fill="#fff"
      />
      <path
        d="M5 5.777c.658 0 1.19.534 1.19 1.191V9.14H3.81V6.968c0-.657.533-1.19 1.19-1.19Z"
        fill="#fff"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const HomeIcon = memo(ForwardRef);
export default HomeIcon;
