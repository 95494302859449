import { useState } from 'react';
import IconGreenThunder from '../../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import { bgMap, bgStar } from '../../../../../assets/images/views/Ecommerce';
import { NudosButton } from '../../../../../components/NudosComponents';
import { sendSubscriptionSlackMessage } from '../../../../../services/subscriptions.services';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import { getPersonalData } from '../../../../../utils/getLocalStorageData';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import NudosDynamicBanner from '../../../../../components/DesignSystem/NudosDynamicBanner/NudosDynamicBanner';
import { useTranslation } from 'react-i18next';

import './SuperiorPrimeBanner.scss';

const SuperiorPrimeBanner = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [openDynamicBanner, setOpenDynamicBanner] = useState<boolean>(false);
  const organizationData = formatOrgData();
  const personalData = getPersonalData();

  const languageKey = 'nodi:primeBanner:';
  const slackMessageService = 'Nodi Prime';

  const notificationMessage = t(`${languageKey}notificationMessage`);
  const bannerSubtitle = t(`${languageKey}bannerSubtitle`);
  const starTitle = t(`${languageKey}starTitle`);
  const subtitleInfo = t(`${languageKey}subtitleInfo`);
  const bannerTitle = t(`${languageKey}bannerTitle`);
  const simpleTitleNodi = t(`${languageKey}simpleTitleNodi`);
  const termsText = t(`${languageKey}termsText`);
  const blackButtonText = t(`${languageKey}blackButtonText`);
  const bannerButtonText = t(`${languageKey}bannerButtonText`);
  const understoodButton = t(`recurrentWords:understoodButton`);

  const sendSlackMessage = async () => {
    setLoading(true);
    try {
      const body = {
        organizationName: organizationData?.organizationName || '',
        requesterName: `${personalData?.firstName} ${personalData?.lastName}`,
        service: slackMessageService,
        origin: 'desde la cotización',
        userRole: personalData?.role
      };
      await sendSubscriptionSlackMessage(body);
      displaySuccessNotification({
        customJSXMessage: <>{notificationMessage}</>,
        button1Text: understoodButton
      });
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {openDynamicBanner && (
        <NudosDynamicBanner
          bannerSubtitle={bannerSubtitle}
          bannerTitle={bannerTitle}
          startNodiMultiColor="Nodi Prime: "
          closeModalCallback={() => setOpenDynamicBanner(false)}
          openedBannerOrigin="product-list"
          submitBannerCallback={() => setOpenDynamicBanner(false)}
        />
      )}
      <div id="superiorPrimeBanner">
        <div className="boxSuperiorPrimeBanner" style={{ backgroundImage: `url("${bgMap}")` }}>
          <div className="starContainer">
            <div className="starBox" style={{ backgroundImage: `url("${bgStar}")` }}>
              <div className="boxTextStar">
                <div className="starTitle">{starTitle}</div>
                <div className="numberStar">3%</div>
                <div className="starTitle mb-0">{`${t(`dynamicBanner:with`)} Nodi Prime`}</div>
              </div>
            </div>
          </div>
          <div className="infoContainer">
            <div className="titleInfo">
              <div className="thunderContainer">
                <IconGreenThunder className="resizeIcon" fill="#7B61FF" />
              </div>
              <div className="multiColorNodi">Nodi Prime:</div>
              <div className="simpleTitleNodi">&nbsp;{simpleTitleNodi}</div>
            </div>
            <div className="subtitleInfo">{subtitleInfo}</div>
            <div className="termsText">{termsText}</div>
          </div>
          <div className="buttosContainer">
            <div className="boxButtons">
              <NudosButton
                handleClick={() => sendSlackMessage()}
                isButtonDisabled={loading}
                isButtonLoading={loading}
                customClassName="minOrangeButton"
                buttonText={bannerButtonText}
                customWidth="104px"
                customHeight="28px"
              />
              <button onClick={() => setOpenDynamicBanner(true)} className="blackButton">
                {blackButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperiorPrimeBanner;
