import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IDataNotifications,
  IlogisticServiceNotificationData,
  IorderNotificationData
} from '../../../../types/dashboard';
import { NudosHoverText } from '../../../../components/NudosComponents';
import { isTruncated } from '../../../../utils/truncateText';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../utils/segment';
import './notificationCard.scss';

interface INotificationCard {
  dataNotification: IDataNotifications;
}

const NotificationCard: FC<INotificationCard> = ({ dataNotification }: INotificationCard) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const notificationCardLenguage = 'nodi:dashboard:body:notifications:';
  const isOrder = 'orderId' in dataNotification;
  const {
    orderId = undefined,
    numberOfShipments = undefined,
    shipmentsDestinationsCountriesFlags = [],
    message = undefined,
    statusName = 'Sin información del estado'
  } = isOrder ? (dataNotification as IorderNotificationData) : {};
  const {
    id: logisticsId = 0,
    title = undefined,
    originName = undefined,
    originCountry = undefined,
    destinationName = undefined,
    destinationCountry = undefined,
    productsImages = undefined,
    status = undefined,
    numberOfProducts = undefined,
    logisticServiceId = undefined,
    textToShow = undefined
  } = !isOrder ? (dataNotification as IlogisticServiceNotificationData) : {};
  const addressIssueMsg = (dataNotification as IlogisticServiceNotificationData)?.addressIssueMsg;
  const reportLsMsg = (dataNotification as IlogisticServiceNotificationData)?.nameString;

  const displayImgAndCounter = !isOrder && !addressIssueMsg && !reportLsMsg;

  const isLogisticsService = !!logisticsId || !!logisticServiceId;
  const getNotificationText = () => {
    if (isOrder) return orderId ? `${t(`${notificationCardLenguage}getNotificationText`)} ${orderId}` : '';
    if (!isOrder)
      return `${title?.split(':')[0] || 'Servicio Logístico'} ${
        isLogisticsService ? `- SL N${logisticsId || logisticServiceId}` : ''
      }`;
  };

  const getNameOrAddressText = () => {
    if (orderId && numberOfShipments) return `${numberOfShipments} ${numberOfShipments === 1 ? 'envío' : 'envíos'}`;
    return 'Sin información de envíos';
  };

  const redirectToOrderDetails = () => {
    if (!orderId) push('/nodi/orders');
    else push(`/nodi/orders/details/${orderId}?from=dashboard`);
  };

  const redirectToLogisticsDetails = () => {
    //The logistic service Id is returned by backend with the names "logisticId" and "logisticServiceId" in different circunstances, despite being the same data
    if (!logisticsId && !logisticServiceId) push('/nodi/logistics-services');
    else push(`/nodi/logistics-services/details/${logisticsId || logisticServiceId}?from=dashboard`);
  };

  const statusIsActive = status === 'activo';
  const statusInProgress = status === 'en proceso';
  const trackReportEvent = !!reportLsMsg && (statusIsActive || statusInProgress);
  const reportReasonProperty = statusIsActive ? 'ReportReasonInDelayed' : 'ReportReasonInProcess';
  const reportProblemOpenEventName = statusIsActive
    ? 'dashboardSLProblemReportDelayedOpenClick'
    : 'dashboardSLProblemReportInProcessOpenClick';

  const handleSeeDetails = () => {
    if (isOrder) return redirectToOrderDetails();
    if (isLogisticsService) {
      if (trackReportEvent)
        segmentTrackEvent({
          [reportProblemOpenEventName]: {
            SL: logisticsId || logisticServiceId || 0,
            [reportReasonProperty]: reportLsMsg || ''
          }
        });
      return redirectToLogisticsDetails();
    }
    return undefined;
  };

  const notificationStatusText = isOrder ? statusName : status || 'Sin estatus';
  const canceledStyles =
    statusName?.toLowerCase().includes('cancel') || status?.toLowerCase().includes('cancel') ? 'canceled' : '';
  const inactiveLinkStyles = !isOrder && !isLogisticsService && 'inactive';
  const distinctFlags = [...new Set(shipmentsDestinationsCountriesFlags || [])];
  const productImageToShow = productsImages ? productsImages[0] : undefined;
  const numberOfAditionalProducts = numberOfProducts
    ? numberOfProducts - 1
    : productsImages
    ? productsImages?.length - 1
    : 0;

  const getFlags = () => {
    const orderFlags = distinctFlags?.map((flag, i) => {
      return <div key={`flag${i}`} className="orderFlag" style={{ backgroundImage: `url(${flag})` }} />;
    });
    const firstFlags = orderFlags.slice(0, 3);
    const excessFlags = orderFlags.length > 3 ? orderFlags.length - 3 : 0;
    const moreFlagsIndicator = (
      <div key="moreFlagsIndicator" className="orderFlag moreFlags">
        +{excessFlags}
      </div>
    );
    if (orderFlags.length <= 4) return orderFlags;
    else return [firstFlags, moreFlagsIndicator];
  };

  return (
    <div id="notificationCard">
      <div className="notificationPoint" />
      <div className="notificationTitle">{getNotificationText()}</div>
      <div className="notificationOriginAndDestinationData">
        {isOrder && (
          <div className="orderDestinationDetail">
            <div className="textNameOrAddress">{getNameOrAddressText()}</div>
            <div className="flagsContainer">{getFlags()}</div>
          </div>
        )}
        {!isOrder && !addressIssueMsg && !reportLsMsg && (
          <div className="logisticServiceOriginAndDestinationData">
            <div className="originData">
              <NudosHoverText onlyIfTruncated text={originName || 'Origen'} charactersLimit={25} />
              <div className="logisticsFlag" style={{ backgroundImage: `url("${originCountry?.flag}")` }} />
            </div>
            {' - '}
            <div className="destinationData">
              <NudosHoverText onlyIfTruncated text={destinationName || 'Destino'} charactersLimit={25} />
              <div className="logisticsFlag" style={{ backgroundImage: `url("${destinationCountry?.flag}")` }} />
            </div>
          </div>
        )}
        {!isOrder && (!!addressIssueMsg || !!reportLsMsg || !!textToShow?.es) && (
          <div className="addressIssueMsg">{textToShow?.es || addressIssueMsg || `⚠️ ${reportLsMsg}`}</div>
        )}
      </div>
      <div className="subTitleNotificacion">
        <div className="subTextNotification" dangerouslySetInnerHTML={{ __html: message || '' }} />
        {isTruncated(message || 'Sin información', 110) && (
          <div className="hoverText" dangerouslySetInnerHTML={{ __html: message || '' }} />
        )}
      </div>
      {displayImgAndCounter && (
        <div className="containerItems">
          <div className="grayItems">
            <div className="referenceImg">
              {productImageToShow && <img alt="referenceImg" className="referenceLimits" src={productImageToShow} />}
            </div>
            {!!numberOfAditionalProducts && <div className="blueCounter">+{numberOfAditionalProducts}</div>}
          </div>
        </div>
      )}
      <div className="statusAndRedirectionContainer">
        <div className="notificationStatus">
          <span>{t(`${notificationCardLenguage}notificationStatus`)} </span>
          <span className={`statusText ${canceledStyles}`}>{notificationStatusText}</span>
        </div>
        <div className={`seeDetails ${inactiveLinkStyles}`} onClick={handleSeeDetails}>
          {isOrder
            ? t(`${notificationCardLenguage}seeDetails:order`)
            : t(`${notificationCardLenguage}seeDetails:service`)}
        </div>
      </div>
    </div>
  );
};
export default NotificationCard;
