import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" ref={ref} {...props}>
    <g clipPath="url(#clip0_8145_35457)">
      <path
        d="M1.09678 11.7355C1.27293 13.382 2.59716 14.7063 4.24283 14.8897C6.77059 15.1714 9.22898 15.1714 11.7567 14.8897C13.4024 14.7063 14.7267 13.382 14.9028 11.7355C15.0328 10.5201 15.1426 9.27225 15.1426 8.00007C15.1426 6.7279 15.0328 5.48006 14.9028 4.26462C14.7267 2.61816 13.4024 1.29392 11.7567 1.11049C9.22898 0.828731 6.77059 0.828731 4.24283 1.11049C2.59716 1.29392 1.27293 2.61816 1.09678 4.26462C0.966743 5.48006 0.856934 6.7279 0.856934 8.00007C0.856934 9.27225 0.966744 10.5201 1.09678 11.7355Z"
        fill="white"
        stroke="#FF664E"
      />
      <path d="M10.8574 8H5.14307" stroke="#FF664E" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_8145_35457">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconSubstract = memo(ForwardRef);
export default IconSubstract;
