import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  NudosBreadcrumbButton,
  NudosButton,
  NudosPasswordInput,
  NudosTextInput
} from '../../../../../../components/NudosComponents';
import useSignUpFormStep3Controller from './SignUpFormStep3.controller';
import { postOfSignUp } from '../../../../../../services/signUp.service';
import { genericErrorMessage, signUpSubmitErrors } from '../../../../../../constants';
import { IformStep3Fields, IformStep3FieldsError } from '../../../../../../types/SignUp';
import useOtpController from '../../../../../../components/Otp/otp.controller';
import axios, { AxiosError } from 'axios';
import { UserInfo } from '@firebase/auth';
import createFirebaseUser from '../../../../../../utils/createUser';
import CustomCheck from '../../../customCheck';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { ICountryDetail } from '../../../../../../types/countries';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import { verifyPhoneAndEmail } from '../../../../../../services/register';
import { emailAndPhoneValidationResponseTexts } from '../../../../../../utils/backendConstants';

const SignUpFormStep3 = ({
  setFormStep,
  setExistingRFCError,
  setBusinessNameAlreadyRegisteredError,
  countryPhone
}: IsignUpFormStep3Params) => {
  const { t } = useTranslation();
  const { loginEmailAndPassWord } = useOtpController();
  const { formInputsStep3, formSchemaStep3, verifyPassword, checkPasswordInputs, signUpFormStep3ErrorNotifications } =
    useSignUpFormStep3Controller();
  const stringNewRegisterData = sessionStorage.getItem('newRegisterInformation');
  const newRegisterData = !!stringNewRegisterData && JSON.parse(stringNewRegisterData);

  const [errors, setErrors] = useState<IformStep3FieldsError>();
  const [emailExistError, setEmailExistError] = useState<string>();
  const [loading, setloading] = useState(false);
  const [submittedData, setSubmittedData] = useState<IformStep3Fields>();
  const [passwordError, setPasswordError] = useState<string>();
  const [aceptTerms, setAceptTems] = useState<boolean>(false);
  const [secondAceptTerms, setSecondAceptTems] = useState<boolean>(false);
  const i18nLanguageKey = 'authentication:SignUpForm:useSignUpFormStep3Controller:';

  const { register, handleSubmit, watch } = useForm<IformStep3Fields>({
    shouldFocusError: true,
    resolver: yupResolver(formSchemaStep3),
    defaultValues: {
      email: newRegisterData?.email ? newRegisterData.email : '',
      password: newRegisterData?.password ? newRegisterData.password : '',
      confirmPassword: newRegisterData?.confirmPassword ? newRegisterData.confirmPassword : ''
    }
  });
  const currentData = watch();

  const isEmailValid = (email: string) => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return regex.test(email);
  };

  const isPasswordValid = (password: string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.-=])([A-Za-z\d$@$!%*?&.-=]|[^ ]){8,15}$/;
    return regex.test(password);
  };

  const handleSuccessfullSubmit = async (data: IformStep3Fields) => {
    if (!newRegisterData) return;
    setloading(true);
    const updatedRegisterInformation = { ...newRegisterData, ...data };
    const stringUpdatedRegisterInformation = JSON.stringify(updatedRegisterInformation);
    sessionStorage.setItem('newRegisterInformation', stringUpdatedRegisterInformation);
    const body = {
      name: newRegisterData.organizationName,
      businessName: newRegisterData.organizationBusinessName,
      document: newRegisterData.rfc?.toUpperCase(),
      countryId: newRegisterData.countryId,
      numberOfEmployees: newRegisterData.numberOfEmployees,
      firstName: newRegisterData.firstName,
      lastName: newRegisterData.lastName,
      position: newRegisterData.position,
      email: data.email,
      phone: newRegisterData.phone,
      registrationInfoId: +newRegisterData.id,
      countryPhoneId: countryPhone?.id
    };
    try {
      const stringStoredUserFirebaseData = localStorage.getItem('userFirebaseData');
      const storedUserFirebaseData = stringStoredUserFirebaseData && JSON.parse(stringStoredUserFirebaseData);
      let firebaseData: UserInfo;
      if (!storedUserFirebaseData) {
        const userData: UserInfo = await createFirebaseUser(data.email, data.password);
        const stringUserData = JSON.stringify(userData);
        localStorage.setItem('userFirebaseData', stringUserData);
        firebaseData = userData;
      } else {
        firebaseData = storedUserFirebaseData;
      }
      const response = await postOfSignUp({ ...body, uid: firebaseData.uid });
      if (response) {
        localStorage.removeItem('userFirebaseData');
        sessionStorage.removeItem('newRegisterInformation');
        await loginEmailAndPassWord(data.email, data.password, setPasswordError);
        segmentTrackEvent({ signupThirdStepClick: { ContactMail: data.email } });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const catchedError: AxiosError = err;
        const databaseError = signUpSubmitErrors.find(catchErr => {
          return (
            catchErr.errorCode === catchedError?.response?.data ||
            catchErr.errorCode === catchedError?.response?.data.message
          );
        });
        const notificationError = databaseError?.correspondingNotification ?? genericErrorMessage;
        const backError = catchedError?.response?.data?.message;
        if (databaseError) {
          if (databaseError.correspondingNotification === 'companyAlreadyRegistered') {
            setExistingRFCError('El RFC ingresado ya se encuentra registrado');
            setFormStep(0);
          }
          if (databaseError.correspondingNotification === 'businessNameAlreadyRegistered') {
            setBusinessNameAlreadyRegisteredError(
              'La razón social ingresada o denominación ya se encuentra registrada'
            );
            setFormStep(0);
          } else {
            displayErrorNotification({
              customJSXMessage: (
                <>
                  {signUpFormStep3ErrorNotifications[notificationError]?.message ||
                    'Algunos de los datos ya se encuentran registrados'}
                </>
              )
            });
          }
          throw { errorData: catchedError?.response?.data };
        } else if (backError) {
          displayErrorNotification();
          throw { errorData: backError };
        }
      } else if (err?.error === 'auth/email-already-in-use') {
        setEmailExistError(t(`${i18nLanguageKey}setEmailExistError`));
      } else {
        displayErrorNotification();
        throw { errorData: err };
      }
    } finally {
      setloading(false);
    }
  };

  const handleErrorSubmitting = (data: IformStep3FieldsError) => {
    setErrors(data);
  };

  const isButtonDisabled =
    !Object.values(currentData).every(value => value && value.length > 0) ||
    !verifyPassword(currentData.password) ||
    !checkPasswordInputs(currentData.password, currentData.confirmPassword) ||
    !aceptTerms ||
    !secondAceptTerms;

  const onChangeState = () => {
    setAceptTems(!aceptTerms);
  };

  const onChangeStateTerms = () => {
    setSecondAceptTems(!secondAceptTerms);
  };

  const handleEmailInputBlur = async () => {
    setEmailExistError(undefined);
    setSubmittedData(currentData);
    if (!isEmailValid(currentData.email)) return;
    const responseValidateEmail = await verifyPhoneAndEmail({
      email: currentData.email,
      phone: '-validationNotRequired-'
    });
    const emailExists = [
      emailAndPhoneValidationResponseTexts.emailExists,
      emailAndPhoneValidationResponseTexts.phoneAndEmailExists
    ].includes(responseValidateEmail);
    if (emailExists) setEmailExistError(t(`${i18nLanguageKey}setEmailExistError`));
  };

  const handleClickReturn = () => {
    const updatedRegisterInformation = { ...newRegisterData, ...currentData };
    const stringUpdatedRegisterInformation = JSON.stringify(updatedRegisterInformation);
    sessionStorage.setItem('newRegisterInformation', stringUpdatedRegisterInformation);
    setFormStep(1);
  };

  useEffect(() => {
    segmentTrackEvent({ signupThirdStepView: null });
  }, []);

  return (
    <form className="formStep formStep3" onSubmit={handleSubmit(handleSuccessfullSubmit, handleErrorSubmitting)}>
      {formInputsStep3.map(field => {
        if (field.type === 'text' && field.id === 'email') {
          const emailHasChangedSinceSubmission = !!submittedData && currentData['email'] !== submittedData['email'];
          const emailErrorText =
            !emailHasChangedSinceSubmission &&
            (emailExistError || (errors ? errors[field.id as keyof IformStep3FieldsError]?.message : undefined));
          return (
            <NudosTextInput
              key={`field-${field?.id}`}
              componentSize="large"
              label={field?.label}
              placeholder={field?.placeholder}
              isFilled={!!currentData[field.id as keyof IformStep3Fields]}
              register={register}
              registerId={field?.id}
              errorText={emailErrorText || undefined}
              defaultValue={newRegisterData ? newRegisterData[field.id as keyof IformStep3Fields] : undefined}
              handleBlur={handleEmailInputBlur}
            />
          );
        }
        if (field.type === 'password') {
          const currentField = currentData[field.id as keyof IformStep3Fields];
          const passwordIsTooShortError =
            field.id === 'password' &&
            currentField.length < 8 &&
            currentField.length > 0 &&
            t(`${i18nLanguageKey}passwordIsTooShortError`);
          const passwordIsTooLongError = currentField.length > 15 && t(`${i18nLanguageKey}passwordIsTooLongError`);
          const invalidPasswordError =
            !currentField || currentField.length === 0 || isPasswordValid(currentField) || field.id !== 'password'
              ? ''
              : 'Incluye 8-15 caracteres al menos 1 mayúscula, minúscula, número y caracter especial.';
          const passwordsDontMatchError =
            !!currentData['password'] &&
            !!currentData['confirmPassword'] &&
            field.id === 'confirmPassword' &&
            !checkPasswordInputs(currentData.password, currentData.confirmPassword) &&
            t(`${i18nLanguageKey}passwordsDontMatchError`);
          const passwordErrorText =
            passwordIsTooShortError ||
            passwordIsTooLongError ||
            invalidPasswordError ||
            passwordsDontMatchError ||
            passwordError ||
            (errors ? errors[field.id as keyof IformStep3FieldsError]?.message : undefined);
          return (
            <NudosPasswordInput
              key={`field-${field?.id}`}
              isFilled={field?.id === 'password' ? !!currentData.password : !!currentData.confirmPassword}
              componentSize="large"
              label={field?.label}
              errorText={passwordErrorText}
              register={register}
              registerId={field?.id}
            />
          );
        }
      })}
      <div className="termsAndConditionsContainer">
        <div>
          <CustomCheck check={aceptTerms} type="terms" onChangeState={onChangeState} />
          <CustomCheck check={secondAceptTerms} type="warning" onChangeState={onChangeStateTerms} />
        </div>
      </div>
      <div className="buttonContainer">
        <NudosBreadcrumbButton returnText={t(`${i18nLanguageKey}returnText`)} returnAction={handleClickReturn} />
        <NudosButton
          componentSize="small"
          buttonText={t(`${i18nLanguageKey}nudosButton`)}
          isButtonDisabled={isButtonDisabled}
          isButtonLoading={loading}
          isOfTypeSubmit={true}
        />
      </div>
    </form>
  );
};

export default SignUpFormStep3;

export interface IsignUpFormStep3Params {
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  setExistingRFCError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setBusinessNameAlreadyRegisteredError: React.Dispatch<React.SetStateAction<string | undefined>>;
  countryPhone?: ICountryDetail;
}
