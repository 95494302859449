import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_2820_94116)">
      <path
        d="M5.00042 9.46422C7.46598 9.46422 9.4647 7.46549 9.4647 4.99993C9.4647 2.53437 7.46598 0.535645 5.00042 0.535645C2.53486 0.535645 0.536133 2.53437 0.536133 4.99993C0.536133 7.46549 2.53486 9.46422 5.00042 9.46422Z"
        fill="#383838"
        stroke="white"
      />
      <path d="M5 3.21436V6.78578" stroke="white" strokeLinecap="round" />
      <path d="M6.7853 5H3.21387" stroke="white" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_2820_94116">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const IconAlert = memo(ForwardRef);
export default IconAlert;
