import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill={props.fill} xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <circle cx="7" cy="7" r="6.5" fill="white" stroke="#383838" />
    <circle cx="7" cy="7" r="4.5" fill="#383838" stroke="#383838" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CheckPaymentIcon = memo(ForwardRef);
export default CheckPaymentIcon;
