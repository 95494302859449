import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} fill="none" ref={ref} {...props}>
    <g stroke="#999" strokeLinecap="round" strokeLinejoin="round">
      <path d="M7.318 7.48a3.125 3.125 0 0 0-4.42 0m4.606-5.395A2.5 2.5 0 0 0 4.089 3l4.33 2.5a2.5 2.5 0 0 0-.915-3.415Z" />
      <path d="M6.972 4.667c.69-1.196.927-2.352.528-2.582-.399-.23-1.281.553-1.972 1.748m.722.417L5 6.415M1.25 8.521a1 1 0 0 1 .417-.104 1 1 0 0 1 .833.416 1 1 0 0 0 .833.417 1 1 0 0 0 .834-.417A1 1 0 0 1 5 8.417a1 1 0 0 1 .833.416 1 1 0 0 0 .834.417 1 1 0 0 0 .833-.417 1 1 0 0 1 .833-.416 1 1 0 0 1 .417.104" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const IconIslandUmbrella = memo(ForwardRef);
export default IconIslandUmbrella;
