import { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { convertSimpleDateToDateTimeZulu, formatDateToPicker } from '../../../utils/formatDatesAndHours';
import './NudosDatePicker.scss';
/**
 * React functional component corresponding to the Nudos date input. Uses an html input of type date to return a datetime string in utc-0, corresponding to the selected date from the picker at the 13:00 hours of the desiredTimeZone (DEFAULT utc-0)
 * @property {funcion(inputText: string): void} updateCurrentValueCallback - REQUIRED, the callback to execute upon changing the input text, should update the current value
 * @property {boolean} isFilled - Required, boolean indicating if the input is filled. Should be passed to change the style from empty to filled. Its value should reflect the change in the input value. Default is false, unless it has a default value, in which case is true.
 * @property {string} defaultValue - Optional, the initial value for the date picker
 * @property {string} label - Optional
 * @property {string} errorText - Optional, the text to be shown if theres an error with the input form. If an error is passed the style of the component will change to reflect that.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "extraSmall", "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {boolean} isDeactivated - Optional, boolean indicating if the input is deactivated. If a value is provided the input will behave as a read only field and styles will be accordingly.
 * @property {string} customClassName - Optional, a className to customize styles
 * @property {string} hideErrorText - Optional, if the dropdown is part of another component that shows an error text, used this boolean to prevent from repeating the error text.
 * @property {number} debounceTime - Optional, a time in milliseconds to debounce the execution of the updateCurrentValueCallback function
 * @property {funcion(): void} handleFocus - Optional, a callback to execute for the onFocus event
 * @property {number} desiredTimeZone - Optional, an integer between -12 and 14, corresponds to the utc offset for the desired time zone in which the selected date will be displayed and saved
 */
const NudosDatePicker = ({
  updateCurrentValueCallback,
  isFilled,
  defaultValue,
  desiredTimeZone,
  label,
  errorText,
  componentSize,
  isDeactivated,
  customClassName,
  hideErrorText,
  debounceTime,
  xPadding,
  inputHeight,
  handleFocus,
  fontSize
}: InudosDatePicker) => {
  const [currentDate, setCurrentDate] = useState('');

  const debouncedDate = !!debounceTime && useDebounce(currentDate, debounceTime);
  const customClassname = customClassName ? customClassName : '';
  const errorStyles = errorText && errorText.length ? 'error' : '';
  const filledStyles = (isFilled || (currentDate && currentDate.length > 0)) && !isDeactivated ? 'filled' : '';
  const componentSizeStyles = componentSize ? componentSize : '';
  const deactivatedStyles = isDeactivated ? 'deactivated' : '';

  const dateInputStyles = {
    paddingLeft: xPadding ? `${xPadding}px` : '',
    paddingRight: xPadding ? `${xPadding}px` : '',
    height: inputHeight ? `${inputHeight}px` : '',
    fontSize: fontSize ? `${fontSize}px` : ''
  };

  const handleInputChange = (event: React.SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    const newDateTime = convertSimpleDateToDateTimeZulu(value, desiredTimeZone);
    const newSimpleDate = formatDateToPicker(newDateTime, desiredTimeZone);
    setCurrentDate(newSimpleDate || '');
    updateCurrentValueCallback && !debounceTime && updateCurrentValueCallback(newDateTime);
  };

  useEffect(() => {
    if (!debouncedDate || !updateCurrentValueCallback) return;
    const newDateTime = convertSimpleDateToDateTimeZulu(debouncedDate, desiredTimeZone);
    updateCurrentValueCallback(newDateTime);
  }, [debouncedDate]);

  useEffect(() => {
    if (currentDate) return;
    const newSimpleDate = formatDateToPicker(defaultValue, desiredTimeZone);
    setCurrentDate(newSimpleDate || '');
  }, [defaultValue]);

  return (
    <section
      className={`nudosDatePicker ${customClassname} ${errorStyles} ${filledStyles} ${deactivatedStyles} ${componentSizeStyles}`}
    >
      {label && <label>{label}</label>}
      <input
        type="date"
        value={currentDate}
        style={dateInputStyles}
        readOnly={isDeactivated}
        onChange={handleInputChange}
        onFocus={handleFocus}
      />
      {errorText && !hideErrorText && errorText.length > 0 && <div className="errorText">{errorText}</div>}
    </section>
  );
};

export default NudosDatePicker;

export interface InudosDatePicker {
  updateCurrentValueCallback: (inputText: string) => void;
  isFilled: boolean;
  defaultValue?: string;
  label?: string;
  desiredTimeZone?: number;
  errorText?: string;
  componentSize?: string;
  isDeactivated?: boolean;
  customClassName?: string;
  hideErrorText?: boolean;
  debounceTime?: number;
  xPadding?: number;
  inputHeight?: number;
  handleFocus?: () => void;
  fontSize?: number;
}
