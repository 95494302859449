import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={11} fill="none" ref={ref} {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M3.85 1.888c-.621.028-1.23.096-1.824.165a1.496 1.496 0 0 0-1.31 1.313c-.086.77-.17 1.567-.17 2.381 0 .815.084 1.612.17 2.382a1.496 1.496 0 0 0 1.31 1.313c.775.09 1.575.178 2.394.178.818 0 1.619-.088 2.393-.178a1.496 1.496 0 0 0 1.31-1.313c.062-.554.151-1.7.151-1.7"
      />
      <path
        fill="#fff"
        d="M7.22 1.097 4.588 4.084l-.36 1.695c-.058.273.226.532.492.448l1.688-.53 2.719-2.861c.451-.476.373-1.27-.174-1.754-.534-.472-1.31-.465-1.733.015Z"
      />
      <path
        fill={props?.color || '#54A1FC'}
        d="M3.872 2.388a.5.5 0 0 0-.045-1l.045 1Zm-1.846-.335-.057-.497.057.497ZM.716 3.366l.497.055-.497-.055Zm0 4.763-.497.056.497-.056Zm1.31 1.313-.057.497.057-.497Zm4.787 0 .057.497-.057-.497Zm1.31-1.313.497.056-.497-.056Zm.65-1.661a.5.5 0 1 0-.997-.078l.997.078ZM7.22 1.097l-.375-.33.375.33ZM4.588 4.084l-.375-.331a.5.5 0 0 0-.114.227l.49.104Zm-.36 1.695.489.104-.49-.104Zm.492.448.15.477-.15-.477Zm1.688-.53.15.476a.5.5 0 0 0 .213-.132l-.363-.345Zm2.719-2.861.362.344-.362-.344Zm-.174-1.754-.331.375.331-.375Zm-5.126.307c-.64.028-1.265.098-1.858.167l.115.993a25.529 25.529 0 0 1 1.788-.161l-.045-1Zm-1.858.167A1.995 1.995 0 0 0 .219 3.31l.994.111a.996.996 0 0 1 .87-.872l-.114-.993ZM.219 3.31C.133 4.08.047 4.902.047 5.747h1c0-.783.08-1.555.166-2.326L.219 3.31ZM.047 5.747c0 .846.086 1.668.172 2.438l.994-.111c-.086-.771-.166-1.543-.166-2.327h-1Zm.172 2.438a1.996 1.996 0 0 0 1.75 1.754l.115-.993a.996.996 0 0 1-.871-.872l-.994.111Zm1.75 1.754c.772.09 1.6.181 2.45.181v-1c-.786 0-1.56-.085-2.335-.174l-.115.993Zm2.45.181c.852 0 1.679-.092 2.451-.181l-.114-.993c-.777.09-1.55.174-2.336.174v1Zm2.452-.181a1.995 1.995 0 0 0 1.75-1.754l-.995-.111a.996.996 0 0 1-.87.872l.114.993Zm1.75-1.754c.03-.285.07-.716.1-1.072a117.007 117.007 0 0 0 .052-.644v-.001l-.499-.039-.498-.039v.003l-.001.01a29.96 29.96 0 0 1-.014.174l-.037.452c-.03.356-.068.775-.098 1.045l.994.11ZM6.844.766 4.213 3.753l.75.661 2.632-2.986-.75-.662ZM4.1 3.98l-.36 1.696.978.207.36-1.695L4.1 3.98Zm-.36 1.696a.879.879 0 0 0 .277.831.88.88 0 0 0 .854.197l-.3-.954c.066-.02.109.008.118.017.01.008.043.048.029.116l-.978-.207ZM4.87 6.704l1.688-.53-.3-.955-1.687.53.3.955Zm1.9-.663L9.49 3.18l-.726-.689-2.718 2.86.725.69ZM9.49 3.18c.681-.718.512-1.837-.206-2.472l-.662.749c.375.332.363.802.142 1.034l.725.689ZM9.283.708c-.702-.621-1.8-.666-2.439.058l.75.662c.208-.236.661-.294 1.027.029l.662-.75Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .167h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconPencilBox = memo(ForwardRef);
export default IconPencilBox;
