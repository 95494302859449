import { NudosGenericModal, NudosModalContent } from '../../../../../components/NudosComponents';
import SelectToolsForAssignmentModalCustomComponent from './SelectToolsForAssignmentModalCustomComponent';
import { ItoolEssentialData } from '../../../../../types/requestLogisticsModule';
import './SelectToolsForAssignmentModal.scss';
import { DTOgetModuleFilters, IQueryTools } from '../../../../../types/DTO';

const SelectToolsForAssignmentModal = ({
  closeModalCallback,
  toolsSelectionCallback,
  toolsAvailableToAssignAccordingToAppliedFilters,
  selectedToolsToAssign,
  filtersData,
  handleChangeExtraQueryData,
  refreshLoading
}: {
  closeModalCallback: () => void;
  toolsSelectionCallback: (newToolsToAssign: ItoolEssentialData[]) => void;
  toolsAvailableToAssignAccordingToAppliedFilters?: ItoolEssentialData[];
  selectedToolsToAssign: ItoolEssentialData[];
  filtersData?: DTOgetModuleFilters;
  handleChangeExtraQueryData?: (query: IQueryTools) => void;
  refreshLoading?: boolean;
}) => {
  return (
    <div className="SelectToolsForAssignmentModal">
      <NudosGenericModal
        closeModalCallback={closeModalCallback}
        modalContent={
          <NudosModalContent
            excludeHeader
            customWidth="744px"
            customHeight="480px"
            toCloseModal={closeModalCallback}
            CustomModalComponent={
              <SelectToolsForAssignmentModalCustomComponent
                toolsSelectionCallback={toolsSelectionCallback}
                toolsAvailableToAssignAccordingToAppliedFilters={toolsAvailableToAssignAccordingToAppliedFilters}
                selectedToolsToAssign={selectedToolsToAssign}
                closeModalCallback={closeModalCallback}
                filtersData={filtersData}
                handleChangeExtraQueryData={handleChangeExtraQueryData}
                selectLocationTool={
                  selectedToolsToAssign && selectedToolsToAssign.length > 0 ? selectedToolsToAssign[0] : undefined
                }
                refreshLoading={refreshLoading}
              />
            }
          />
        }
      />
    </div>
  );
};

export default SelectToolsForAssignmentModal;
