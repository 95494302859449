import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g>
      <path
        d="M1.64554 17.6032C1.90977 20.073 3.89611 22.0593 6.36461 22.3344C10.1563 22.757 13.8438 22.757 17.6354 22.3344C20.104 22.0593 22.0903 20.073 22.3545 17.6032C22.5496 15.78 22.7143 13.9083 22.7143 12C22.7143 10.0917 22.5496 8.21997 22.3545 6.39681C22.0903 3.92712 20.104 1.94076 17.6354 1.66561C13.8438 1.24297 10.1563 1.24297 6.36461 1.66561C3.89611 1.94076 1.90977 3.92712 1.64554 6.39681C1.45048 8.21997 1.28577 10.0917 1.28577 12C1.28577 13.9083 1.45048 15.78 1.64554 17.6032Z"
        fill="white"
        stroke="#FF664E"
      />
      <g>
        <path d="M5.75 8.74512H18.25" stroke="#FF664E" strokeLinecap="round" />
        <path
          d="M16.4907 8.74512H7.50506C7.36001 11.3677 7.36227 13.97 7.75318 16.576C7.8975 17.5381 8.72399 18.2499 9.69687 18.2499H14.2989C15.2718 18.2499 16.0982 17.5381 16.2426 16.576C16.6335 13.97 16.6357 11.3677 16.4907 8.74512Z"
          fill="white"
          stroke="#FF664E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.50183 8.74521V8.24601C9.50183 7.58402 9.7648 6.94916 10.2329 6.48106C10.701 6.01297 11.3359 5.75 11.9978 5.75C12.6598 5.75 13.2947 6.01297 13.7628 6.48106C14.2309 6.94916 14.4939 7.58402 14.4939 8.24601V8.74521"
          stroke="#FF664E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10.5002 11.4189V15.5568" stroke="#FF664E" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.4955 11.4189V15.5568" stroke="#FF664E" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const DeleteIcon = memo(ForwardRef);
export default DeleteIcon;
