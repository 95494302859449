import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" ref={ref} {...props}>
    <path
      d="M2.40156 4.1998C3.69566 5.77928 4.48474 6.5685 6.00156 7.7998C7.51839 6.5685 8.30747 5.77928 9.60156 4.1998"
      stroke={props?.stroke || '#CCCCCC'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconRangeDateArrow = memo(ForwardRef);
export default IconRangeDateArrow;
