import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" ref={ref} {...props}>
    <path
      className="path1"
      fill="#fff"
      stroke="#383838"
      d="M8 1.714c-1.775 0-3.63 0-5.18.291a1.829 1.829 0 0 0-1.43 1.377c-.247 1.022-.247 1.798-.247 3.475 0 1.678 0 2.453.248 3.475.17.7.723 1.244 1.43 1.377C4.369 12 6.225 12 8 12c1.774 0 3.63 0 5.179-.291a1.829 1.829 0 0 0 1.43-1.377c.248-1.022.248-1.797.248-3.475 0-1.677 0-2.453-.248-3.475a1.829 1.829 0 0 0-1.43-1.377c-1.549-.29-3.405-.29-5.18-.29Z"
    />
    <path
      className="path2"
      fill="#383838"
      d="M8.25 11.995a.25.25 0 1 0-.5 0h.5Zm-.5 3.148a.25.25 0 1 0 .5 0h-.5Zm-2.036-.25a.25.25 0 1 0 0 .5v-.5Zm4.571.5a.25.25 0 0 0 0-.5v.5ZM7.75 11.995v3.148h.5v-3.148h-.5Zm-2.036 3.398h4.571v-.5H5.714v.5Zm-4.478-5.91h13.62v-.5H1.237v.5Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconToolComputer = memo(ForwardRef);
export default IconToolComputer;
