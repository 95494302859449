import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NudosLogisticsGeneralContainer from '../../../../components/DesignSystem/NudosLogisticsGeneralContainer/NudosLogisticsGeneralContainer';
import { Iemployee, LOCATION_PLACE_TYPE, TnudosCare } from '../../../../types/global';
import { ItoolDataForRequiringLogistics, TstoragePlace } from '../../../../types/assignationFluxes';
import { NudosToolLogisticsWithAdditionalServices } from '../../Components';
import {
  ItoolEssentialData,
  IwarehouseData,
  TlogisticsOriginOrDestinationData,
  IadditionalServiceDataForToolLogistics
} from '../../../../types/requestLogisticsModule';
import { segmentTrackEvent } from '../../../../utils/segment';
import './UnassignmentStep3Tools.scss';

const UnassignmentStep3Tools = ({
  originData,
  selectedToolsWhenFluxStarted,
  toolsToUnassignData,
  updateToolsToUnassignDataCallback,
  userCanSelectOtherTools,
  selectedDestination,
  destinationData
}: {
  originData?: Iemployee;
  selectedToolsWhenFluxStarted: number[];
  toolsToUnassignData: ItoolDataForRequiringLogistics[];
  updateToolsToUnassignDataCallback: (newToolsData: ItoolDataForRequiringLogistics[]) => void;
  userCanSelectOtherTools: boolean;
  selectedDestination?: TstoragePlace;
  destinationData?: TlogisticsOriginOrDestinationData;
}) => {
  const { t } = useTranslation();
  const { assignedTools } = originData || {};
  const [toolsToUnassign, setToolsToUnassign] = useState<ItoolEssentialData[]>([]);
  const [unselectedTools, setUnselectedTools] = useState<ItoolEssentialData[]>([]);
  const i18nLanguageKey = 'services:unassignmentModule';
  const showOtherToolsSection = userCanSelectOtherTools && unselectedTools?.length > 0;
  const destinationIsWarehouse = ['nudos'].includes(selectedDestination || '');
  const atLeastOneToolCanHaveAdditionalServices = toolsToUnassign?.some(
    tool =>
      tool.category === 'Laptops' ||
      tool?.category === 'Laptops CTOs' ||
      tool?.category === 'Laptops con DEP' ||
      tool.category === 'PC'
  );
  const listingWithoutAdditionalServicesStyle =
    destinationIsWarehouse && atLeastOneToolCanHaveAdditionalServices ? undefined : 'noAdditionalServices';
  const warehouseAditionalServices = destinationIsWarehouse
    ? (destinationData as IwarehouseData)?.logisticAdditionalServices
    : [];
  const getRequiredToolDataToUnassign = (
    tool: ItoolEssentialData,
    additionalServices?: IadditionalServiceDataForToolLogistics[]
  ) => {
    return {
      sku: tool?.sku,
      productId: +(tool?.productId || '') || undefined,
      productName: tool?.productName || undefined,
      productBrand: tool?.brand || undefined,
      serial: tool?.serial || undefined,
      newLogisticAdditionalServices: additionalServices || [] || undefined,
      image: tool.image || undefined,
      nudosCareName: tool?.nudosCareName as TnudosCare
    };
  };

  const updateToolsToUnassignData = (
    specificTool?: ItoolEssentialData,
    type?: 'edit' | 'delete',
    additionalServicesForSpecificTool?: IadditionalServiceDataForToolLogistics[]
  ) => {
    if (toolsToUnassignData.length === 0) {
      const newToolsToUnassignData = toolsToUnassign.map(tool => getRequiredToolDataToUnassign(tool));
      return updateToolsToUnassignDataCallback(newToolsToUnassignData);
    }
    if (!specificTool) return;
    const newToolsToUnassignData = [...toolsToUnassignData];
    const specificToolIndex = newToolsToUnassignData.findIndex(
      tool => tool?.productId === +(specificTool?.productId || '')
    );
    const specificToolCurrentAdditionalServices =
      specificToolIndex >= 0 ? newToolsToUnassignData[specificToolIndex]?.newLogisticAdditionalServices : undefined;
    const specificToolNewData = getRequiredToolDataToUnassign(
      specificTool,
      additionalServicesForSpecificTool && additionalServicesForSpecificTool?.length
        ? additionalServicesForSpecificTool
        : specificToolCurrentAdditionalServices || undefined
    );
    if (specificToolIndex < 0 && type === 'edit') newToolsToUnassignData.push(specificToolNewData);
    if (specificToolIndex >= 0 && type === 'edit') newToolsToUnassignData[specificToolIndex] = specificToolNewData;
    if (specificToolIndex >= 0 && type === 'delete') newToolsToUnassignData.splice(specificToolIndex, 1);
    return updateToolsToUnassignDataCallback(newToolsToUnassignData);
  };

  const handleSelectTool = (selectedTool: ItoolEssentialData) => {
    if (!userCanSelectOtherTools) return;
    const newToolsToUnassign = [...toolsToUnassign];
    const newUnselectedTools = [...unselectedTools]?.filter(tool => tool?.productId !== selectedTool?.productId);
    newToolsToUnassign.push(selectedTool);
    setToolsToUnassign(newToolsToUnassign);
    setUnselectedTools(newUnselectedTools);
    updateToolsToUnassignData(selectedTool, 'edit');
  };

  const handleUnselectTool = (unselectedTool: ItoolEssentialData) => {
    if (!userCanSelectOtherTools) return;
    const newToolsToUnassign = [...toolsToUnassign]?.filter(tool => tool?.productId !== unselectedTool?.productId);
    const newUnselectedTools = [...unselectedTools];
    newUnselectedTools.push(unselectedTool);
    setToolsToUnassign(newToolsToUnassign);
    setUnselectedTools(newUnselectedTools);
    updateToolsToUnassignData(unselectedTool, 'delete');
  };

  useEffect(() => {
    if (!assignedTools || toolsToUnassign.length > 0) return;
    const initiallySelectedToolsIds =
      toolsToUnassignData && toolsToUnassignData.length
        ? toolsToUnassignData.map(tool => +(tool.productId || 0))
        : selectedToolsWhenFluxStarted;
    const previouslySelectedAdditionalServicesMap = new Map();
    toolsToUnassignData.forEach(toolToUnassign => {
      previouslySelectedAdditionalServicesMap.set(
        toolToUnassign.productId,
        toolToUnassign.newLogisticAdditionalServices
      );
    });
    const newAssignedTools = [];
    for (let i = 0; i < assignedTools.length; i++) {
      const tool = assignedTools[i];
      const logisticServiceInCourse =
        tool?.logisticStatus && tool?.logisticStatus !== 'entregado' && tool?.logisticStatus !== 'cancelado';
      const toolHasntBeenDelivered = tool?.referenceModel === 'CatalogProduct' && tool?.statusName !== 'Entregado';
      const disabledTool = logisticServiceInCourse || toolHasntBeenDelivered ? 'inactive' : '';
      const finalToolData = {
        ...tool,
        newLogisticAdditionalServices: previouslySelectedAdditionalServicesMap.get(tool?.productId)
      };
      if (!disabledTool) newAssignedTools.push(finalToolData);
    }
    const initiallySelectedTools = newAssignedTools?.filter(
      tool => tool?.productId && !tool?.assignedUntil && initiallySelectedToolsIds?.includes(+tool.productId)
    );
    const initiallyUnselectedTools = newAssignedTools?.filter(
      tool => tool?.productId && !tool?.assignedUntil && !initiallySelectedToolsIds?.includes(+tool.productId)
    );
    setToolsToUnassign(initiallySelectedTools);
    setUnselectedTools(initiallyUnselectedTools);
  }, [assignedTools, selectedToolsWhenFluxStarted]);

  useEffect(() => {
    updateToolsToUnassignData();
  }, [toolsToUnassign]);

  const viewEvent = () => {
    const aditionalServices: string[] = [];
    if (warehouseAditionalServices && warehouseAditionalServices.length > 0) {
      for (let i = 0; i < warehouseAditionalServices.length; i++) {
        const item = warehouseAditionalServices[i];
        if (item.name) {
          aditionalServices.push(item.name);
        }
      }
    }

    segmentTrackEvent({
      nodiLogisticServices2ToolView: { AdditionalServicesAvailable: aditionalServices?.length > 0 }
    });
  };

  useEffect(() => {
    viewEvent();
  }, []);

  return (
    <div className={`UnassignmentStep3Tools`}>
      <NudosLogisticsGeneralContainer title={t(`${i18nLanguageKey}:unassignmentStep3Tools:title`)}>
        <section className="selectedToolsSection">
          {userCanSelectOtherTools && (
            <div className="sectionTitle">{t(`${i18nLanguageKey}:unassignmentStep3Tools:selectedTools`)}</div>
          )}
          <div className={`toolsContainer ${listingWithoutAdditionalServicesStyle}`}>
            {toolsToUnassign.map((tool, i) => {
              const logisticServiceInCourse =
                tool?.logisticStatus && tool?.logisticStatus !== 'entregado' && tool?.logisticStatus !== 'cancelado';
              const toolHasntBeenDelivered =
                tool?.referenceModel === 'CatalogProduct' && tool?.statusName !== 'Entregado';
              const disabledTool = logisticServiceInCourse || toolHasntBeenDelivered ? 'inactive' : '';
              if (!disabledTool) {
                return (
                  <NudosToolLogisticsWithAdditionalServices
                    key={`unselectedTool${tool?.id}${i}`}
                    toolData={tool}
                    isSelected
                    toolHasSelectButton={userCanSelectOtherTools}
                    handleClickSelectButton={() => handleUnselectTool(tool)}
                    toolLogisticsPlaceThatMayBeWarehouse={selectedDestination}
                    warehouseAditionalServices={warehouseAditionalServices}
                    updateToolsToAssignOrUnassignData={updateToolsToUnassignData}
                    totalTools={toolsToUnassign?.length || 0}
                    destination={selectedDestination as LOCATION_PLACE_TYPE}
                  />
                );
              }
            })}
          </div>
        </section>
        {showOtherToolsSection && (
          <>
            <div className="divider" />
            <section className="unselectedToolsSection">
              <div className="sectionTitle">{t(`${i18nLanguageKey}:unassignmentStep3Tools:othersTools`)}</div>
              <div className="toolsContainer">
                {unselectedTools.map((tool, i) => {
                  const logisticServiceInCourse =
                    tool?.logisticStatus &&
                    tool?.logisticStatus !== 'entregado' &&
                    tool?.logisticStatus !== 'cancelado';
                  const toolHasntBeenDelivered =
                    tool?.referenceModel === 'CatalogProduct' && tool?.statusName !== 'Entregado';
                  const disabledTool = logisticServiceInCourse || toolHasntBeenDelivered ? 'inactive' : '';
                  if (!disabledTool) {
                    return (
                      <NudosToolLogisticsWithAdditionalServices
                        key={`unselectedTool${tool?.id}${i}`}
                        toolData={tool}
                        isSelected={false}
                        toolHasSelectButton={userCanSelectOtherTools}
                        handleClickSelectButton={() => handleSelectTool(tool)}
                        toolLogisticsPlaceThatMayBeWarehouse={selectedDestination}
                        destination={selectedDestination as LOCATION_PLACE_TYPE}
                      />
                    );
                  }
                })}
              </div>
            </section>
          </>
        )}
      </NudosLogisticsGeneralContainer>
    </div>
  );
};

export default UnassignmentStep3Tools;
