import { FC, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { NudosButton } from '../../../../../components/NudosComponents';
import { ListDescription, ProductDescription } from '../../../../../components/uploadExternalProducts';
import useLoadTools from '../../../../../state/useStateSuccessLoadTools';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { useTranslation } from 'react-i18next';
import './Step3UploadTools.scss';

interface ISteep3 {
  updateExteralTools: () => void;
}

const Step3UploadTools: FC<ISteep3> = ({ updateExteralTools }) => {
  const { t } = useTranslation();
  const { setSelectedReferences, selectedReferences } = useLoadTools();

  useEffect(() => {
    segmentTrackEvent({ toolsConfirmInformationView: null });
  }, []);

  return (
    <div className="step3UploadTools">
      <div className="bodyItems">
        {selectedReferences &&
          selectedReferences.length > 0 &&
          selectedReferences.map(reference => {
            return (
              <div className="containerItems" key={uuid()}>
                <ProductDescription name={reference.name} sku={reference.sku} pills={reference.pills} />
                {reference &&
                  reference?.individualToolsData &&
                  reference.individualToolsData.length > 0 &&
                  reference.individualToolsData.map((item, index) => {
                    const itemLocationOrAssignment =
                      item?.assignedEmployee?.firstName && item?.assignedEmployee?.lastName
                        ? `${item?.assignedEmployee?.firstName} ${item?.assignedEmployee?.lastName}`
                        : item?.country?.name || undefined;
                    return (
                      <ListDescription
                        key={uuid()}
                        index={index + 1}
                        state={item?.productCondition}
                        serial={item?.serial}
                        itemLocationOrAssignment={
                          item.otherAddressDetail
                            ? item.otherAddressDetail?.locationName || ''
                            : item.optionId === 1
                            ? `Oficinas ${itemLocationOrAssignment}`
                            : itemLocationOrAssignment
                        }
                      />
                    );
                  })}
              </div>
            );
          })}
      </div>
      <NudosButton
        handleClick={() => {
          setSelectedReferences(selectedReferences);
          updateExteralTools();
        }}
        buttonText={t('nodi:UploadExternalToolsModule:uploadToolSteps:nudosButton')}
        componentSize="small"
      />
    </div>
  );
};

export default Step3UploadTools;
