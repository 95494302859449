import { useEffect, useState } from 'react';
import { ContainerOnboardingItems } from '../../ContainerOnbordingItems';
import { NodiPrimeStartModal } from '../NodiPrimeStartModal';
import { BlackItemNodiPrimeInfoOnboarding } from '../BlackItemNodiPrimeInfoOnboarding';
import {
  dashboardStep,
  toolsStep,
  ordersStep,
  logisticServicesStep,
  faqStep,
  employeesStep,
  marketplaceStep
} from '../../../../assets/images/components/Onboarding';
import { useTranslation } from 'react-i18next';
import { formatOrgData } from '../../../../utils/orgFormatData';
import { updateSteps } from '../../../../services/onboarding';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import { segmentTrackEvent } from '../../../../utils/segment';
import useOnboardingState from '../../../../state/useOnboardingState';

import './OnboardingNodiPrimeSteps.scss';

export interface IPrimeOnboardingStep {
  stepId: number;
  title: string;
  positionId: string;
  type: string;
  polygonPosition: 'topLeft' | 'bottomLeft' | 'topRight' | 'leftCenter';
  polygonColor?: string;
  includedServicesList: string[];
  blockedServicesList: string[];
  warningMessage?: string;
  stepImage: {
    image: string;
    width: string;
    height: string;
  };
  eventName: string;
}

const OnboardingNodiPrimeSteps = () => {
  const { t } = useTranslation();
  const orgData = formatOrgData();
  const generalTranslations = 'nodi:onboarding:featureList:';
  const translationsInventory = `${generalTranslations}inventory:`;
  const translationsOrders = `${generalTranslations}orders:`;
  const translationsLogisticServices = `${generalTranslations}logisticServices:`;
  const translationsFrequentlyAskedQuestions = `${generalTranslations}frequentlyAskedQuestions:`;
  const translationsMarketPlace = `${generalTranslations}marketPlace:`;

  const { setShowPrimeOnboarding, detailsPrimeOnboarding } = useOnboardingState();

  const [actualStep, setActualStep] = useState<IPrimeOnboardingStep>();
  const [showPrincipalModal, setShowPrincipalModal] = useState<boolean>(false);

  const onBoardingSteps: IPrimeOnboardingStep[] = [
    {
      stepId: 1,
      title: t(`${generalTranslations}dashboard:title`),
      positionId: 'menu-module-name-dashboard',
      type: 'menu-item',
      polygonPosition: 'topLeft',
      includedServicesList: [
        t(`${generalTranslations}dashboard:list:first`),
        t(`${generalTranslations}dashboard:list:second`),
        t(`${generalTranslations}dashboard:list:third`),
        t(`${generalTranslations}dashboard:list:fourth`)
      ],
      blockedServicesList: [t(`${generalTranslations}dashboard:list:fifth`)],
      stepImage: {
        image: dashboardStep,
        width: '236px',
        height: '116px'
      },
      eventName: 'nodiOnboardingPrime1View'
    },
    {
      stepId: 2,
      title: t(`${generalTranslations}employees:title`),
      positionId: 'menu-module-name-employees',
      type: 'menu-item',
      polygonPosition: 'topLeft',
      includedServicesList: [
        t(`${generalTranslations}employees:list:first`),
        t(`${generalTranslations}employees:list:second`)
      ],
      blockedServicesList: [t(`${generalTranslations}employees:list:third`)],
      stepImage: {
        image: employeesStep,
        width: '231px',
        height: '104px'
      },
      eventName: 'nodiOnboardingPrime2View'
    },
    {
      stepId: 3,
      title: t(`${translationsInventory}title`),
      positionId: 'menu-module-name-inventory',
      type: 'menu-item',
      polygonPosition: 'topLeft',
      includedServicesList: [t(`${translationsInventory}list:first`)],
      blockedServicesList: [t(`${translationsInventory}list:second`), t(`${translationsInventory}list:third`)],
      stepImage: {
        image: toolsStep,
        width: '243px',
        height: '130px'
      },
      eventName: 'nodiOnboardingPrime3View'
    },
    {
      stepId: 4,
      title: t(`${translationsOrders}title`),
      positionId: 'menu-module-name-orders',
      type: 'menu-item',
      polygonPosition: 'topLeft',
      includedServicesList: [
        t(`${translationsOrders}list:first`),
        t(`${translationsOrders}list:second`),
        t(`${translationsOrders}list:third`)
      ],
      blockedServicesList: [],
      stepImage: {
        image: ordersStep,
        width: '250px',
        height: '116px'
      },
      eventName: 'nodiOnboardingPrime4View'
    },
    {
      stepId: 5,
      title: t(`${translationsLogisticServices}title`),
      positionId: 'menu-module-name-logServices',
      type: 'center-menu-item',
      polygonPosition: 'leftCenter',
      includedServicesList: [
        t(`${translationsLogisticServices}list:first`),
        t(`${translationsLogisticServices}list:second`)
      ],
      blockedServicesList: [t(`${translationsLogisticServices}list:third`)],
      stepImage: {
        image: logisticServicesStep,
        width: '270px',
        height: '120px'
      },
      eventName: 'nodiOnboardingPrime5View'
    },
    {
      stepId: 6,
      title: t(`${translationsFrequentlyAskedQuestions}title`),
      positionId: 'menu-module-name-FAQs',
      type: 'inferior-menu-item',
      polygonPosition: 'bottomLeft',
      polygonColor: 'black',
      includedServicesList: [
        t(`${translationsFrequentlyAskedQuestions}list:first`),
        t(`${translationsFrequentlyAskedQuestions}list:second`),
        t(`${translationsFrequentlyAskedQuestions}list:third`)
      ],
      blockedServicesList: [],
      stepImage: {
        image: faqStep,
        width: '175px',
        height: '101px'
      },
      eventName: 'nodiOnboardingPrime6View'
    },
    {
      stepId: 7,
      title: t(`${translationsMarketPlace}title`),
      positionId: 'button-header-marketPlace',
      type: 'header-item-button',
      polygonPosition: 'topRight',
      includedServicesList: [t(`${translationsMarketPlace}list:first`)],
      blockedServicesList: [
        t(`${translationsMarketPlace}list:second`),
        t(`${translationsMarketPlace}list:third`),
        t(`${translationsMarketPlace}list:fourth`)
      ],
      stepImage: {
        image: marketplaceStep,
        width: '270px',
        height: '125px'
      },
      eventName: 'nodiOnboardingPrime7View'
    }
  ];

  const updateOnboardingSteps = async (lastStep: number) => {
    try {
      await updateSteps(Number(orgData?.organizationId), lastStep, false, 'Lite');
    } catch (error) {
      displayErrorNotification();
    }
  };

  const nextStep = (stepNumber: number) => {
    setActualStep(onBoardingSteps[stepNumber]);
    updateOnboardingSteps(stepNumber + 1);
  };

  const previusStep = (stepNumber: number) => {
    setActualStep(onBoardingSteps[stepNumber]);
  };

  const closeOnboarding = async () => {
    setShowPrimeOnboarding(false);
  };

  const initializeSteps = () => {
    segmentTrackEvent({ nodiPrimeOnboardingClick: null });
    setActualStep(onBoardingSteps[0]);
    setShowPrincipalModal(false);
    updateOnboardingSteps(1);
  };

  const stepEvents = () => {
    if (actualStep?.eventName) {
      segmentTrackEvent({ [actualStep.eventName]: null });
    }
  };

  const verifyLastStep = () => {
    if (detailsPrimeOnboarding) {
      const findStep = onBoardingSteps.find(step => step.stepId === Number(detailsPrimeOnboarding.lastStepSeen));
      if (findStep) {
        setShowPrincipalModal(false);
        setActualStep({ ...findStep });
      } else {
        setShowPrincipalModal(true);
      }
    } else {
      setShowPrincipalModal(true);
    }
  };

  const finishOnboarding = async () => {
    setShowPrimeOnboarding(false);
    await updateSteps(Number(orgData?.organizationId), 1, true, 'Lite');
  };

  useEffect(() => {
    stepEvents();
  }, [actualStep]);

  useEffect(() => {
    verifyLastStep();
  }, [detailsPrimeOnboarding]);

  return (
    <>
      {actualStep && (
        <ContainerOnboardingItems>
          <BlackItemNodiPrimeInfoOnboarding
            infoStep={actualStep}
            numberSteps={onBoardingSteps.length}
            elementIndex={onBoardingSteps.findIndex(element => element.stepId === actualStep.stepId)}
            nextStep={step => {
              setActualStep(undefined);
              nextStep(step);
            }}
            previusStep={step => {
              setActualStep(undefined);
              previusStep(step);
            }}
            finishOnboarding={() => {
              setActualStep(undefined);
              finishOnboarding();
            }}
            closeOnboarding={closeOnboarding}
          />
        </ContainerOnboardingItems>
      )}

      {showPrincipalModal && (
        <NodiPrimeStartModal
          clickInitializeSteps={() => {
            initializeSteps();
          }}
        />
      )}
    </>
  );
};

export default OnboardingNodiPrimeSteps;
