import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6723_149180)">
      <path
        d="M4.99993 0.535156C7.4655 0.535156 9.46422 2.53388 9.46422 4.99944C9.46422 7.46501 7.4655 9.46373 4.99993 9.46373C2.53437 9.46373 0.535645 7.46501 0.535645 4.99944C0.535645 2.53388 2.53437 0.535156 4.99993 0.535156Z"
        fill="white"
        stroke="#54A1FC"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 2.98828V7.00962" stroke="#54A1FC" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.68194 5.56641C6.17082 6.2122 5.82269 6.51273 5.30389 6.90578C5.12032 7.04485 4.87949 7.04485 4.69591 6.90578C4.17711 6.51273 3.82899 6.2122 3.31787 5.56641"
        stroke="#54A1FC"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6723_149180">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const IconBlueDownLoad = memo(ForwardRef);
export default IconBlueDownLoad;
