import { Trans, useTranslation } from 'react-i18next';
import ArrowButton from '../../../../assets/images/components/newCard/arrowButton';
import { useState } from 'react';
import { segmentTrackEvent } from '../../../../utils/segment';
import { IDetailNewCard } from '../../newCard';
import { IassigneeInfo, InudoInfo } from '../../../../types/global';
import { getAcquisitionAssignationHistory, getNodiProductById } from '../../../../services/services';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import './NewCardManageButton.scss';

const NewCardManageButton = ({
  currentAssignee,
  setShowUnassignToolModal,
  setCurrentAssignee,
  setSearchedAcquisition,
  setLoadingAssigneeData,
  onGoToAssignation,
  disabledLogisticServiceInProcess,
  toolHasntBeenDelivered,
  blockedCountry,
  acceptLogisticService,
  dataCard,
  assignedTo
}: {
  currentAssignee?: IassigneeInfo;
  setShowUnassignToolModal: (boolean: boolean) => void;
  setCurrentAssignee: (assignee?: IassigneeInfo) => void;
  setSearchedAcquisition: (assignee?: InudoInfo) => void;
  setLoadingAssigneeData: (assignee: boolean) => void;
  onGoToAssignation: () => void;
  disabledLogisticServiceInProcess: boolean;
  toolHasntBeenDelivered: boolean;
  blockedCountry: boolean;
  acceptLogisticService: boolean;
  dataCard: IDetailNewCard;
  assignedTo?: string | null;
}) => {
  const { t } = useTranslation();
  const { country, commercialName, statusId, referenceModel } = dataCard;
  const [openLogisticAlert, setOpenLogisticAlert] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:tools:';
  const displayDisabledButton =
    statusId === 1 || statusId === 4 || statusId === 7 || (!statusId && referenceModel !== 'ExternalProduct');
  const displayDisabledUnassignButton =
    assignedTo && (disabledLogisticServiceInProcess || blockedCountry || toolHasntBeenDelivered);
  const displayUnassignButton =
    assignedTo && !(disabledLogisticServiceInProcess || blockedCountry || toolHasntBeenDelivered);
  const displayDisabledAssignButton = disabledLogisticServiceInProcess || toolHasntBeenDelivered || blockedCountry;

  const blockedCountryMessage = (
    <>
      Esta herramienta se encuentra <br />
      en un país no habilitado para <br />
      servicios logísticos
    </>
  );

  const getAssigneeAndTool = async () => {
    try {
      const response = await getAcquisitionAssignationHistory(`${dataCard.productId}`);
      if (response) {
        const currAssignee = response.find(assignee => !assignee.unAssignmentDate);
        setCurrentAssignee(currAssignee);
        const responseSearchedAcquisition = await getNodiProductById(`${dataCard.productId}`);
        if (responseSearchedAcquisition) {
          setSearchedAcquisition(responseSearchedAcquisition);
          setLoadingAssigneeData(false);
        }
      }
    } catch (error) {
      displayErrorNotification();
      setLoadingAssigneeData(false);
    }
  };

  const sendToolsManagementSegmentEvent = (ManagementType: string) => {
    segmentTrackEvent({
      toolsManagementClick: {
        CountryTool: country?.name || 'Sin información de País',
        ManagementType: ManagementType,
        ProductName: commercialName || ''
      }
    });
  };

  const handleClickUnassign = async () => {
    if (!dataCard.productId) return;
    sendToolsManagementSegmentEvent('Unassign');
    if (currentAssignee) setShowUnassignToolModal(true);
    if (!currentAssignee) {
      setShowUnassignToolModal(true);
      getAssigneeAndTool();
    }
  };

  const alertLogisticService = (alternativeText?: React.ReactNode, size?: number) => {
    return (
      <div className="alertLogisticService" style={{ height: size ? `${size}px` : '' }}>
        {alternativeText && alternativeText}
        {!alternativeText && <Trans i18nKey={t(`${i18nLanguageKey}alertLogisticService`)} components={{ 1: <br /> }} />}
      </div>
    );
  };

  const getBlockedAssignationAlert = () => {
    if (toolHasntBeenDelivered) return;
    if (blockedCountry) return alertLogisticService(blockedCountryMessage, 48);
    return alertLogisticService();
  };

  const assignButton = (
    <div className="assignButtonContainer">
      {displayDisabledAssignButton && (
        <div className="containerFirsDisabledButton">
          {openLogisticAlert && getBlockedAssignationAlert()}
          <button
            className="disabledWhiteButton"
            onMouseEnter={() => setOpenLogisticAlert(true)}
            onMouseLeave={() => setOpenLogisticAlert(false)}
          >
            {t(`${i18nLanguageKey}activateButton`)}
            <ArrowButton className="iconButton" stroke="#CCCCCC" fill="white" />
          </button>
        </div>
      )}
      {!displayDisabledAssignButton && (
        <button
          disabled={acceptLogisticService && blockedCountry}
          className="simpleButtonCard"
          onClick={onGoToAssignation}
        >
          {t(`${i18nLanguageKey}activateButton`)}
          <ArrowButton
            className="iconButton"
            stroke={acceptLogisticService && blockedCountry ? '#CCCCCC' : '#ff664e'}
          />
        </button>
      )}
    </div>
  );

  return (
    <>
      {displayDisabledButton && (
        <button className="buttonCardBlock">
          <div className="textButton">{t(`${i18nLanguageKey}activateButton`)}</div>
          <ArrowButton className="iconButton" />
        </button>
      )}
      {displayDisabledUnassignButton && (
        <div className="containerFirsDisabledButton">
          {openLogisticAlert && getBlockedAssignationAlert()}
          <button
            className="disabledOrangeButton"
            onMouseEnter={() => setOpenLogisticAlert(true)}
            onMouseLeave={() => setOpenLogisticAlert(false)}
          >
            {t(`${i18nLanguageKey}myDisabledButton`)}
            <div className="iconButton">
              <ArrowButton className="iconButton" stroke="white" fill="#CCCCCC" />
            </div>
          </button>
        </div>
      )}
      {displayUnassignButton && (
        <div className="containerFirsDisabledButton">
          <button className="buttonCard" onClick={handleClickUnassign}>
            {t(`${i18nLanguageKey}myDisabledButton`)}
            <div className="iconButton">
              <ArrowButton className="iconButton" stroke="white" fill="#ff664e" />
            </div>
          </button>
        </div>
      )}
      {!assignedTo && assignButton}
    </>
  );
};

export default NewCardManageButton;
