import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={8} height={8} fill="none" ref={ref} {...props}>
    <g clipPath="url(#clip0_14320_5066)">
      <path
        d="M7.32244 5.27801C7.08981 5.93725 6.66724 6.5129 6.10792 6.93233C5.54868 7.35182 4.87775 7.59633 4.17973 7.63507C3.53448 7.63519 2.90484 7.43667 2.37632 7.0665C1.8478 6.69639 1.44602 6.17251 1.22556 5.5661M1.037 3.70666C1.47698 2.50195 2.82835 1.34961 4.17973 1.34961C4.82812 1.35143 5.46008 1.55376 5.98895 1.92886C6.51787 2.30395 6.91775 2.83346 7.13392 3.44477M7.68907 6.43267C7.58872 5.95824 7.50855 5.7056 7.32318 5.28023C6.86878 5.37418 6.61615 5.45441 6.17072 5.64612M0.761719 2.53396C0.826684 3.01454 0.887947 3.27242 1.04132 3.71034C1.50142 3.65027 1.75928 3.58895 2.2177 3.43072"
        stroke="#54A1FC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14320_5066">
        <rect width="8" height="8" fill="white" transform="translate(0.181641 0.492188)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconRegenerateInvoice = memo(ForwardRef);
export default IconRegenerateInvoice;
