import { TfunctionOneToVoid } from '../../../types/global';
import { NudosButton, NudosModalContent, NudosRadioButton } from '../../NudosComponents';
import NudosStaticAlertInComponent, { IalertOptions } from '../NudosStaticAlertInComponent/NudosStaticAlertInComponent';
import { useTranslation } from 'react-i18next';
import './NudosModalSelectBetweenTwoOptions.scss';

/**
 * @property { string } subtitle - The subtitle of the modal
 * @property { IcardOptionData[] } optionsCards - An array of LENGTH 2 of objects with the require data and functionallity for each of the two cards. If the length is greater than 2 only the first two objects will be considered. The properties of the object include the title, description, icon, isSelected, action, isRecommended, cardStyles
 * @property { IalertOptions } alertOptions - An object specifying the contents of the alert between the subtitle and the option cards. It can contain an icon, title and subtitle. If this property is undefined no alert is shown and thus the space between the subtitle and the option cards is empty.
 * @property { boolean } isButtonDisabled - A boolean indicating if the modal button is disabled or not
 * @property { boolean } isButtonLoading - A boolean indicating if the modal button is loading
 * @property { () => void } submitModalCallback - The callback function to execute upon clicking the callback function
 */
const NudosTwoOptionsModalCustomSection = ({
  subtitle,
  optionsCards = [],
  alertOptions,
  isButtonDisabled,
  isButtonLoading,
  submitModalCallback
}: INudosTwoOptionsModalCustomSection) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:orderDetails:orderShipmentCard:selectShipmentUpdatingOption:';
  const validatedCards = optionsCards.length > 2 ? optionsCards.slice(0, 2) : optionsCards;
  const subtitleBottomMargin = alertOptions ? '' : '46px';

  return (
    <div className="nudosTwoOptionsModalCustomSection">
      <h2 className="subtitle" style={{ marginBottom: subtitleBottomMargin }}>
        {subtitle}
      </h2>
      {alertOptions && <NudosStaticAlertInComponent alertOptions={alertOptions} />}
      <div className="cardsContainer">
        {validatedCards.map((option, i) => {
          const { title, description, icon, action, cardStyles, isSelected, isRecommended } = option;
          const optionId = `optionCard${i + 1}`;
          const selectedStyles = isSelected ? 'selected' : '';
          const classNames = `optionCard ${optionId} ${selectedStyles}`;
          return (
            <div key={optionId} className={classNames} style={cardStyles} onClick={action}>
              <NudosRadioButton componentSize="small" isButtonActive={isSelected} />
              {isRecommended && <div className="recommendedTag">{t(`${i18nLanguageKey}favoriteTag`)}</div>}
              <div className="iconContainer">{icon}</div>
              <div className="optionTitle">{title}</div>
              <div className="optionDescription">{description}</div>
            </div>
          );
        })}
      </div>
      <NudosButton
        componentSize="large"
        buttonText={t(`${i18nLanguageKey}nudosButton`)}
        handleClick={submitModalCallback}
        isButtonDisabled={isButtonDisabled}
        isButtonLoading={isButtonLoading}
      />
    </div>
  );
};

/**
 * @property { () => void } closeModalCallback - The callback function to close the modal
 * @property { string } title - The title of the modal
 * @property { string } subtitle - The subtitle of the modal
 * @property { IcardOptionData[] } optionsCards - An array of LENGTH 2 of objects with the require data and functionallity for each of the two cards. If the length is greater than 2 only the first two objects will be considered. The properties of the object include the title, description, icon, isSelected, action, isRecommended, cardStyles
 * @property { IalertOptions } optionsCards - An object specifying the contents of the alert between the subtitle and the option cards. It can contain an icon, title and subtitle. If this property is undefined no alert is shown and thus the space between the subtitle and the option cards is empty.
 * @property { boolean } isButtonDisabled - A boolean indicating if the modal button is disabled or not
 * @property { boolean } isButtonLoading - A boolean indicating if the modal button is loading
 * @property { () => void } submitModalCallback - The callback function to execute upon clicking the callback function
 */
const NudosModalSelectBetweenTwoOptions = ({
  closeModalCallback,
  title,
  subtitle,
  optionsCards,
  alertOptions,
  isButtonDisabled,
  isButtonLoading,
  submitModalCallback
}: INudosModalSelectBetweenTwoOptions) => {
  return (
    <div className="nudosModalSelectBetweenTwoOptions">
      <NudosModalContent
        customWidth="468px"
        customHeight="402px"
        modalContentTitle={title}
        CustomModalComponent={
          <NudosTwoOptionsModalCustomSection
            subtitle={subtitle}
            optionsCards={optionsCards}
            alertOptions={alertOptions}
            isButtonDisabled={isButtonDisabled || isButtonLoading}
            submitModalCallback={submitModalCallback}
            isButtonLoading={isButtonLoading}
          />
        }
        toCloseModal={closeModalCallback}
      />
    </div>
  );
};

export default NudosModalSelectBetweenTwoOptions;

interface IcardOptionData {
  title: JSX.Element;
  description: JSX.Element;
  icon: JSX.Element;
  isSelected: boolean;
  action: () => void;
  isRecommended?: boolean;
  cardStyles?: { [key: string]: string };
}

interface INudosModalSelectBetweenTwoOptions {
  closeModalCallback: TfunctionOneToVoid;
  title: string;
  subtitle: string;
  optionsCards?: IcardOptionData[];
  alertOptions?: IalertOptions;
  isButtonDisabled: boolean;
  isButtonLoading: boolean;
  submitModalCallback: () => void;
}

interface INudosTwoOptionsModalCustomSection {
  subtitle: string;
  optionsCards?: IcardOptionData[];
  alertOptions?: IalertOptions;
  isButtonDisabled: boolean;
  isButtonLoading: boolean;
  submitModalCallback: () => void;
}
