import { useState } from 'react';
import { IconInvoice } from '../../../../../../assets/DesignSystem/images';
import { NudosButton } from '../../../../../../components/NudosComponents';
import useLogisticsServiceBillingState from '../../../../../../state/useLogisticsQuoteAndBilling';
import LogisticServiceBillingCountrySearchbarDropdown from '../LogisticServiceBillingCountrySearchbarDropdown/LogisticServiceBillingCountrySearchbarDropdown';
import { ICountryDetail } from '../../../../../../types/countries';
import './SelectLogisticServiceBillingCountryModal.scss';

const SelectLogisticServiceBillingCountryModalContent = ({
  closeModalCallback,
  listOfCountries
}: {
  closeModalCallback: () => void;
  listOfCountries: ICountryDetail[];
}) => {
  const { setBillingCountry } = useLogisticsServiceBillingState();
  const [selectedBillingCountry, setSelectedBillingCountry] = useState<ICountryDetail>();

  const handleSaveNewBillingCountry = () => {
    if (!selectedBillingCountry) return;
    setBillingCountry(selectedBillingCountry);
    closeModalCallback();
  };

  return (
    <div className="selectLogisticServiceBillingCountryModalContent">
      <div className="iconContainer">
        <img src={IconInvoice} alt="invoice icon" />
      </div>
      <div className="modalcontentTitle">Factura emitida desde otro país</div>
      <div className="explainingText">
        Solo selecciona esta opción si has hablado con tu KAM previamente de este tema. En caso de no ser así tu
        servicio podrá ser cancelado.
      </div>
      <div className="alertText">⚠️Al elegir esta opción, la facturación y el link de pago puede tener demoras.</div>
      <LogisticServiceBillingCountrySearchbarDropdown
        selectedBillingCountry={selectedBillingCountry}
        setSelectedBillingCountry={setSelectedBillingCountry}
        listOfCountries={listOfCountries}
      />
      <div className="buttonsContainer">
        <NudosButton
          componentSize="small"
          buttonText={'Cancelar'}
          colorPalette="secondary"
          handleClick={closeModalCallback}
        />
        <NudosButton
          componentSize="small"
          buttonText={'Ya hablé con mi KAM, continuar'}
          handleClick={handleSaveNewBillingCountry}
          isButtonDisabled={!selectedBillingCountry}
        />
      </div>
    </div>
  );
};

export default SelectLogisticServiceBillingCountryModalContent;
