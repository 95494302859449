import { useEffect, useState } from 'react';
import { InudoInfo, Tany } from '../../../../../types/global';
import { AxiosError } from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import ToolsInformation from './components/ToolInformation/ToolsInformation';
import { useTranslation } from 'react-i18next';
import ToolAcquisitionData from './components/ToolAcquisitionData/ToolAcquisitionData';
import ToolDetailTagSection from '../../../../../assets/images/views/Admin/nodiProductDetails/ToolDetailTagSection.webp';
import { deleteExternalProduct, updateProductSubscription } from '../../../../../services/uploadExternalTools.service';
import { NudosButton, NudosTextArea } from '../../../../../components/NudosComponents';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import ToolDetailsRightSkeletonLoader from './components/ToolDetailsRightSkeletonLoader';
import segment from '../../../../../utils/segment';
import ToolStatusSection from './components/ToolStatusSection/ToolStatusSection';
import './ToolDetailsRightSection.scss';

const ToolDetailsRightSection = ({
  showSkeletonLoader,
  searchedAcquisition,
  reloadDataCallback
}: {
  showSkeletonLoader: boolean;
  searchedAcquisition?: InudoInfo;
  reloadDataCallback: () => Promise<void>;
}) => {
  const { id: productId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { push } = useHistory();
  const [toolComment, setToolComment] = useState<string>('');
  const [deleteToolLoading, setDeleteToolLoading] = useState<boolean>(false);
  const [toolCommentChanged, setToolCommentChanged] = useState<boolean>(false);
  const i18nLanguage = 'nodi:toolInternal:ModalDeleteSerial:';

  const handleClickNudosButton = () => {
    setToolCommentChanged(false);
    handleUpdateToolData();
  };

  const handleUpdateToolData = () => {
    const catalogProduct = {
      comments: toolComment || ''
    };
    if (updateToolData) updateToolData(catalogProduct);
  };

  const deleteTool = async () => {
    setDeleteToolLoading(true);
    try {
      await deleteExternalProduct(Number(searchedAcquisition?.productId));
      displaySuccessNotification({
        customJSXMessage: <>{t(`${i18nLanguage}deleteSerial`)} </>
      });
      push('/nodi/tools');
    } catch (error) {
      const messageError = error as AxiosError;
      if (messageError?.response?.data?.message) {
        displayErrorNotification({
          customJSXMessage: <>{messageError?.response?.data?.message}</>
        });
      } else {
        displayErrorNotification();
      }
    } finally {
      setDeleteToolLoading(false);
    }
  };

  const updateToolData = async (data: Tany) => {
    try {
      await updateProductSubscription(productId, data);
      segment.sessionEndingEvent();
      await reloadDataCallback();
      displaySuccessNotification({
        customJSXMessage: <>Se actualizo la información de la herramienta correctamente </>
      });
    } catch (error) {
      displayErrorNotification();
    }
  };

  useEffect(() => {
    searchedAcquisition?.comments && setToolComment(searchedAcquisition?.comments);
  }, [searchedAcquisition]);

  if (showSkeletonLoader) return <ToolDetailsRightSkeletonLoader />;

  return (
    <div className="toolDetailsRightSection">
      <ToolsInformation
        deleteToolLoading={deleteToolLoading}
        deleteToolCallback={deleteTool}
        searchedAcquisition={searchedAcquisition}
      />
      <ToolAcquisitionData searchedAcquisition={searchedAcquisition} updateToolDataCallback={updateToolData} />
      <ToolStatusSection toolData={searchedAcquisition} />
      <div>
        <div className="title">Tags</div>
        <img className="imgStyles" src={ToolDetailTagSection} />
      </div>
      <div className="toolDetailsComment">
        <div className="title">Comentario</div>
        <NudosTextArea
          noResize
          xPadding="16"
          yPadding="14"
          maxLength={500}
          textHeight={64}
          placeholder={'Escribe comentarios importantes para tener en cuenta sobre la herramienta '}
          hidenCounter
          currentValue={toolComment}
          customFontSize={10}
          updateCurrentValueCallback={e => {
            setToolCommentChanged(true);
            setToolComment(String(e));
          }}
        />
        <div className="commentButtonContainer">
          <NudosButton
            buttonText="Guardar"
            handleClick={handleClickNudosButton}
            isButtonLoading={false}
            customClassName="commentButton"
            isButtonDisabled={!toolCommentChanged}
          />
        </div>
      </div>
    </div>
  );
};

export default ToolDetailsRightSection;
