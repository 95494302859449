import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#a)" stroke={props?.stroke || '#ff664e'}>
      <path
        d="M.96 10.268a3.132 3.132 0 0 0 2.753 2.76c1.07.12 2.167.222 3.287.222s2.218-.102 3.287-.222a3.132 3.132 0 0 0 2.753-2.76c.114-1.063.21-2.155.21-3.268s-.096-2.205-.21-3.269a3.132 3.132 0 0 0-2.753-2.76C9.217.853 8.12.75 7 .75S4.782.852 3.713.972A3.132 3.132 0 0 0 .96 3.732C.846 4.794.75 5.886.75 7s.096 2.205.21 3.268Z"
        fill="#fff"
      />
      <path d="M4 4.5h3M10 9.5H7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" fill="#fff" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const BoardFiltersIcon = memo(ForwardRef);
export default BoardFiltersIcon;
