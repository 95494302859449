import { useTranslation } from 'react-i18next';
import {
  IconArrow,
  IconBuilding,
  IconEmployee,
  IconNudosFace,
  IconPin,
  IconReserveBox,
  IconShield
} from '../../assets/DesignSystem/SVGComponents';
import { IlogisticsDestinationData } from '../../types/global';
import { NudosHoverText } from '../NudosComponents';
import './ToolLogisticsOrigenAndDestinationTag.scss';

/**
 * @property { IlogisticsDestinationData } origin - The data for the origin/current location of the tool
 * @property { number } isBuyAndHold - Number used as boolean to indicate when the tool is buy and hold
 * @property { IlogisticsDestinationData } destination - Optional, the data for the destination location of the tool (ie, the place where the location will be once the logistic service is delivered)
 * @property { boolean } logisticServiceIsConcluded - a boolean indicating if the logistic service is on course
 * @property { number } customCharactersLimit - the limit of characters before the text is truncated and show when hover
 */
const ToolLogisticsOrigenAndDestinationTag = ({
  origin,
  destination,
  logisticServiceIsConcluded,
  customCharactersLimit,
  assignedTo,
  isBuyAndHold,
  toolHasntBeenDelivered
}: {
  origin: IlogisticsDestinationData;
  destination?: IlogisticsDestinationData;
  logisticServiceIsConcluded?: boolean;
  customCharactersLimit?: number;
  assignedTo?: string | null;
  isBuyAndHold?: number;
  toolHasntBeenDelivered?: boolean;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:toolsLogisticsOriginAndDestinationTag:';

  const getPlaceDisplayData = (locationData?: IlogisticsDestinationData) => {
    const country = locationData?.country?.name || '';
    const place = locationData?.place || '';
    const address = locationData?.address;
    const locationName = locationData?.locationName;
    const locationUserName = logisticServiceIsConcluded && !assignedTo ? undefined : locationData?.locationUserName;
    switch (place) {
      case 'store':
        return {
          icon: (
            <div className="iconStoreContainer">
              <IconNudosFace fill="#000000" className="iconNudosFace" />
            </div>
          ),
          text: 'Tienda',
          address: 'Tienda'
        };
      case 'diagnostic':
        return {
          icon: <IconReserveBox className="IconReserveBox" />,
          text: t(`${i18nLanguageKey}diagnostic`),
          address: `${t(`${i18nLanguageKey}nudosWarehouse`)} ${country}`
        };
      case 'nudos':
        return {
          icon: <IconReserveBox className="IconReserveBox" />,
          text: t(`${i18nLanguageKey}nudosWarehouse`),
          address: `${t(`${i18nLanguageKey}nudosWarehouse`)} ${country}`
        };
      case 'warranty':
        return {
          icon: <IconShield className="IconShield" />,
          text: t(`${i18nLanguageKey}warranty`),
          address: t(`${i18nLanguageKey}brandAddress`)
        };
      case 'office':
        return {
          icon: <IconBuilding className="IconBuilding" />,
          text: `${t(`${i18nLanguageKey}offices`)} ${country}`,
          address: address || t(`${i18nLanguageKey}noRegisteredAddress`)
        };
      case 'other':
        return {
          icon: <IconPin className="IconPin" />,
          text: locationName || t(`${i18nLanguageKey}other`),
          address: address || t(`${i18nLanguageKey}noRegisteredAddress`)
        };
      case 'user':
        return {
          icon: <IconEmployee className="IconEmployee" />,
          text: locationUserName || t(`${i18nLanguageKey}unassigned`),
          address: address || t(`${i18nLanguageKey}noRegisteredAddress`)
        };
      default:
        return {
          icon: <IconPin className="IconPin" />,
          text: t(`${i18nLanguageKey}unassigned`),
          address: address || t(`${i18nLanguageKey}noRegisteredAddress`)
        };
    }
  };

  const originDisplayData = getPlaceDisplayData(toolHasntBeenDelivered ? { place: 'store' } : origin);
  const destinationDisplayData = getPlaceDisplayData(toolHasntBeenDelivered ? origin : destination);
  const charactersLimit = customCharactersLimit ? customCharactersLimit : logisticServiceIsConcluded ? 25 : 10;
  const displayBuyAndHoldTag = !!isBuyAndHold && logisticServiceIsConcluded;

  return (
    <div className="ToolLogisticsOrigenAndDestinationTag">
      <div className="originSection">
        <div className="icon">
          {originDisplayData?.icon}
          <div className="hoverElement">{originDisplayData?.address}</div>
        </div>
        <NudosHoverText onlyIfTruncated text={originDisplayData?.text || ''} charactersLimit={charactersLimit} />
      </div>
      {(!logisticServiceIsConcluded || toolHasntBeenDelivered) && (
        <>
          <IconArrow className="IconArrow" />
          <div className="destinationSection">
            <div className="icon">
              {destinationDisplayData?.icon}
              <div className="hoverElement">{destinationDisplayData?.address}</div>
            </div>
            <NudosHoverText
              onlyIfTruncated
              text={destinationDisplayData?.text || ''}
              charactersLimit={charactersLimit}
            />
          </div>
        </>
      )}
      {displayBuyAndHoldTag && <div className="buyAndHold">Buy & Hold</div>}
    </div>
  );
};

export default ToolLogisticsOrigenAndDestinationTag;
