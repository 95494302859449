import { useTranslation } from 'react-i18next';
import {
  IconAddTool,
  IconCalendar,
  IconQuotation,
  IconSendEmail,
  IconTruck
} from '../../../../../assets/DesignSystem/SVGComponents';
import { NudosStepsProgressBar } from '../../../../../components/NudosComponents';
import './AssignmentModuleStepper.scss';

/**
 * @property  { number } currentStep - The current step of the checkout
 */
const AssignmentModuleStepper = ({
  currentStep,
  requireLogistics,
  includeQuoteAndBillingStep
}: {
  currentStep: number;
  requireLogistics: boolean;
  includeQuoteAndBillingStep: boolean;
}) => {
  const { t } = useTranslation();

  const orderZero = t('recurrentWords:devices');
  const addressesText = t('recurrentWords:addresses');
  const datesText = t('recurrentWords:dates');
  const emailText = t('recurrentWords:email');

  const isStepActive = (step: number) => (step === currentStep ? 'active' : '');
  const stepOrderNoLs: number = requireLogistics ? 3 : 1;

  const progressSteps = [
    {
      order: 0,
      showStep: true,
      stepContent: (
        <div className={`step2 assignmentModuleStepContent`}>
          <IconAddTool className={`stepIcon IconAddTool ${currentStep >= 0 ? 'active' : ''}`} />
          {!!isStepActive(0) && <div className="stepText">{orderZero}</div>}
        </div>
      ),
      stepWidth: isStepActive(0) ? 84 : 24,
      customStepStyles: undefined
    },
    {
      order: 1,
      showStep: requireLogistics,
      stepContent: (
        <div className={`step1 assignmentModuleStepContent`}>
          <IconTruck className={`stepIcon IconTruck ${currentStep >= 1 ? 'active' : ''}`} />
          {!!isStepActive(1) && <div className="stepText">{addressesText}</div>}
        </div>
      ),
      stepWidth: isStepActive(1) ? 75 : 24,
      customStepStyles: undefined
    },
    {
      order: 2,
      showStep: requireLogistics,
      stepContent: (
        <div className={`step2 assignmentModuleStepContent`}>
          <IconCalendar className={`stepIcon IconAddTool ${currentStep >= 2 ? 'active' : ''}`} />
          {!!isStepActive(2) && <div className="stepText">{datesText}</div>}
        </div>
      ),
      stepWidth: isStepActive(2) ? 55 : 24,
      customStepStyles: undefined
    },
    {
      order: stepOrderNoLs,
      showStep: true,
      stepContent: (
        <div className={`step2 assignmentModuleStepContent`}>
          <IconSendEmail className={`stepIcon stepEmailIcon ${currentStep >= stepOrderNoLs ? 'active' : ''}`} />
          {!!isStepActive(stepOrderNoLs) && <div className="stepText emailText">{emailText}</div>}
        </div>
      ),
      stepWidth: isStepActive(stepOrderNoLs) ? 54 : 24,
      customStepStyles: undefined
    },
    {
      order: 4,
      showStep: requireLogistics && includeQuoteAndBillingStep,
      stepContent: (
        <div className={`step2 assignmentModuleStepContent`}>
          <IconQuotation className={`stepIcon IconQuotation ${currentStep >= 4 ? 'active' : ''}`} />
          {!!isStepActive(4) && <div className="quoteAndBillingtText">Cotización</div>}
        </div>
      ),
      stepWidth: isStepActive(4) ? 64 : 24,
      customStepStyles: undefined
    }
  ];

  const definitiveSteps = progressSteps.filter(step => step?.showStep);

  return (
    <div className="AssignmentModuleStepper">
      <NudosStepsProgressBar
        steps={definitiveSteps}
        currentStep={currentStep}
        firstAndLastConectorWidth={32}
        intermediateStepsConectorWidth={40}
        customHeight={24}
      />
    </div>
  );
};

export default AssignmentModuleStepper;
