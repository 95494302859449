import React from 'react';
import './NudosNotification.scss';
import { DeleteSquareXButton } from '../../assets/images/components/NudosComponents';

/**
 * IMPORTANT! if the structure of the message is complex used a JSX element as message, if its simple pass a string
 * @property {string | JSX.Element} message - the message to be displayed. It can be a string or a JSX element if more complex structure is required
 * @property {boolean} errorNotification - a boolean indicating if is an Error notification. Defalt False, ie, a success notification
 * @property {string} className - a class name to pass custom css
 * @property {string} button1Text - The text to show on the first button
 * @property {()=>void} button1Action - The callback to execute onClick the first button
 * @property {string} button2Text - The text to show on the second button
 * @property {()=>void} button2Action - The callback to execute onClick the second button
 */
const NudosNotification = ({
  message,
  errorNotification,
  className,
  button1Text,
  button1Action,
  button2Text,
  button2Action
}: InudosNotification) => {
  const errorStyles = errorNotification ? 'errorNotification' : '';
  const hasButtons = !!button1Text || !!button2Text;
  return (
    <div className={`nudosNotification  ${className || ''} ${errorStyles}`}>
      <DeleteSquareXButton className="closeAlertButton" />
      {React.isValidElement(message) && <div className="messageContainer">{message}</div>}
      {hasButtons && (
        <div className="buttonsContainer">
          {button1Text && (
            <button className="button1" onClick={button1Action}>
              {button1Text}
            </button>
          )}
          {button2Text && (
            <button className="button2" onClick={button2Action}>
              {button2Text}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default NudosNotification;

export interface InudosNotification {
  message: string | JSX.Element;
  errorNotification?: boolean;
  className?: string;
  duration?: number;
  button1Text?: string;
  button1Action?: () => void;
  button2Text?: string;
  button2Action?: () => void;
}
