import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DetailIcon } from '../../../../../assets/images/views/Admin/orderList';
import { IorderInListing } from '../../../../../types/orders';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import { useTranslation } from 'react-i18next';
import { NudosHoverText } from '../../../../../components/NudosComponents';
import './newOrderCard.scss';

interface INewOrderCard {
  dataOrderCard: IorderInListing;
}

const NewOrderCard: FC<INewOrderCard> = ({ dataOrderCard }: INewOrderCard) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [openHover, setOpenHover] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:orderDetails:newOrderCard:';
  const paymentType = dataOrderCard?.paymentType;
  const paymentTransfUSD = paymentType === 'Transf. USD';
  const paymentTransfCOP = paymentType === 'Transf. COP';
  const requiresProofOfPayment =
    paymentTransfUSD ||
    paymentType === 'Tarjeta internacional' ||
    paymentTransfCOP ||
    paymentType === 'Tarjeta crédito COP';
  const statusNameKey = dataOrderCard?.statusNameStringId;
  const statusNameTranslation = t(`services:statusName:${statusNameKey}`);
  const orderStatus = statusNameKey ? statusNameTranslation : dataOrderCard?.statusName;
  const statusNameText = orderStatus.split(' ')[0];
  const paymentMethodKey = dataOrderCard?.paymentTypeStringId;
  const paymentMethodTranslation = t(`services:paymentType:${paymentMethodKey}`);
  const textPaymentMethod = paymentMethodKey ? paymentMethodTranslation : dataOrderCard?.paymentType || 'Sin datos';
  const currencyARS = dataOrderCard?.currency === 'ARS';
  const formatCommittedPaymentDate = formatShortDate(dataOrderCard?.committedPaymentDate)?.onlyDate;
  const formatPaymentDate = formatShortDate(dataOrderCard?.paymentDate)?.onlyDate;
  const isSingleShipment = dataOrderCard?.shipments === 1;
  const renderDeliveryNumber = isSingleShipment
    ? t(`${i18nLanguageKey}textSingleDeliveryNumber`)
    : t(`${i18nLanguageKey}textDeliveryNumber`);

  const goToDetails = () => {
    if (typeof dataOrderCard?.orderId === 'number') push(`/nodi/orders/details/${dataOrderCard.orderId}`);
  };

  const assignStatusColor = (status?: string) => {
    switch (status) {
      case 'Pendiente de confirmación':
        return 'defaultColor';
      case 'Activa':
        return 'confirmColor';
      case 'Entregada':
        return 'deliveryColor';
      case 'Cancelada':
        return 'cancelColor';
      default:
        return 'defaultColor';
    }
  };

  return (
    <div id="newOrderCard">
      <div className="boxOrderCard" onClick={goToDetails}>
        <div className="contentOrderCard">
          <div className="boxDateOrder">
            <div className="textDate">{formatShortDate(dataOrderCard?.createdAt)?.textWithTimezone}</div>
          </div>
          <div className="boxNumberOrder">
            <div className="textNumber">
              {t(`${i18nLanguageKey}textNumber`)} N°{dataOrderCard?.orderId}
            </div>
          </div>
          <div
            className="boxDeliveryNumberOrder"
            onMouseEnter={() => setOpenHover(true)}
            onMouseLeave={() => setOpenHover(false)}
          >
            <div className={`textDeliveryNumber ${openHover ? 'activateDeliveryNumber' : ''}`}>
              {`${dataOrderCard?.shipments} ${renderDeliveryNumber}`}{' '}
            </div>
            {openHover && dataOrderCard?.shipmentsInfo && dataOrderCard?.shipmentsInfo?.length > 0 && (
              <div className="detailDelivery">
                {dataOrderCard?.shipmentsInfo.map((item, index) => {
                  return (
                    <div key={`shipping-info-${item.destinationName}-index-${index}`} className="containerDelivery">
                      {`${item.shipmentNumber}: ${item.destinationName}`}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="boxPaymentMethodOrder">
            <div className="textPaymentMethodContainer">
              <span className="textPaymentMethod font-bold">{t(`${i18nLanguageKey}textPaymentMethod`)} </span>
              <NudosHoverText
                onlyIfTruncated
                charactersLimit={12}
                text={textPaymentMethod}
                customClassName="textPaymentMethod"
              />
            </div>
          </div>
          <div className="boxTotalAmountOrder">
            <div className="textAmount">
              <span className="font-bold">Total:</span>
              {` ${new Intl.NumberFormat().format(
                dataOrderCard?.currency === 'USD' || currencyARS ? dataOrderCard?.totalUsd : dataOrderCard?.total || 0
              )} ${currencyARS ? 'USD' : dataOrderCard?.currency || 'Sin datos'}`}
            </div>
          </div>
          <div className="boxVoucherOrder">
            {dataOrderCard?.committedPaymentDate ? (
              <>
                {dataOrderCard?.paymentDate ? (
                  <div className="paymentAlertBlack">
                    {t(`${i18nLanguageKey}paymentAlertBlack`)}
                    <span className="font-extrabold ml-1">{formatPaymentDate} 👍🏻</span>
                  </div>
                ) : (
                  <div className="paymentAlert">
                    {t(`${i18nLanguageKey}paymentAlert`)}
                    <span className="font-extrabold ml-1">{formatCommittedPaymentDate} ⚠️</span>
                  </div>
                )}
              </>
            ) : (
              <>
                {requiresProofOfPayment && (
                  <div>
                    {dataOrderCard?.proofOfPaymentUrl ? (
                      <div className="voucherCheck">{t(`${i18nLanguageKey}voucherCheck`)} 👍🏻</div>
                    ) : (
                      <div className="noVoucher">{t(`${i18nLanguageKey}noVoucher`)} ⚠️ </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="boxStatusOrder">
            <div className={`pointStatus ${assignStatusColor(dataOrderCard?.statusName)}`} />
            <div className="textStatus">{statusNameText}</div>
          </div>
          <div className="pointsDetail">
            <DetailIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrderCard;
