import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_7036_156097)">
      <path
        d="M1.09703 11.7358C1.27318 13.3823 2.59741 14.7065 4.24308 14.8899C6.77084 15.1717 9.22923 15.1717 11.757 14.8899C13.4027 14.7065 14.7269 13.3823 14.903 11.7358C15.0331 10.5203 15.1429 9.2725 15.1429 8.00031C15.1429 6.72814 15.0331 5.4803 14.903 4.26486C14.7269 2.61841 13.4027 1.29417 11.757 1.11073C9.22923 0.828975 6.77084 0.828975 4.24308 1.11073C2.59741 1.29417 1.27318 2.61841 1.09703 4.26486C0.966987 5.4803 0.857178 6.72814 0.857178 8.00031C0.857178 9.2725 0.966988 10.5203 1.09703 11.7358Z"
        fill="#383838"
        stroke="white"
      />
      <path d="M8 5.14258V10.8569" stroke="white" strokeLinecap="round" />
      <path d="M10.8571 8H5.14282" stroke="white" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_7036_156097">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const BlackAddIcon = memo(ForwardRef);
export default BlackAddIcon;
