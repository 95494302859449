import { memo, useEffect } from 'react';
import { valueKey } from '../../types/global';
import useShopStore from '../../state/useShopContext';
import { NudosToolTip } from '../NudosComponents';
import { IconAppleLogo, IconToolWrench } from '../../assets/DesignSystem/SVGComponents';
import { segmentTrackEvent } from '../../utils/segment';
import { useTranslation } from 'react-i18next';

import './horizontalFilters.scss';

function _HorizontalFilters({
  filters,
  name,
  resetPage
}: {
  filters: valueKey[];
  name: string;
  resetPage?: () => void;
}) {
  const { t } = useTranslation();
  const { filters: stateFilters, setFilters, categoryFilters, setPillsFilters } = useShopStore();
  const _filter = stateFilters.find(item => item.name === name);
  const translationKey = 'nodi:checkout:';

  function setFilter(_newValue: string | number | undefined) {
    const filtersToPreserve = stateFilters.filter(filter =>
      ['disponibilidad', 'line', 'profile'].includes(filter.name)
    );
    const newParam = {
      name,
      includes: 'in',
      values: [_newValue]
    };
    setFilters([...filtersToPreserve, newParam]);
    setPillsFilters([]);
  }

  const getFilterTooltip = (value: string) => {
    const filterIsLaptopDEP = value === '9';
    const tooltipTitle = filterIsLaptopDEP ? `Laptops ${t('nodi:dynamicBanner:with')} DEP` : 'Laptops CTOs';
    const tooltipDescription = filterIsLaptopDEP
      ? t(`${translationKey}filterIsLaptopDEP`)
      : t(`${translationKey}filterIsLaptopCTO`);
    const tooltipIcon = filterIsLaptopDEP ? <IconAppleLogo /> : <IconToolWrench className="iconToolWrench" />;
    const tooltipContent = (
      <div className="tooltipContent">
        <div className="tooltipDescriptionContainer">
          <div className="tooltipTitle">{tooltipTitle}</div>
          <div className="tooltipDescription">{tooltipDescription}</div>
        </div>
        {tooltipIcon}
      </div>
    );
    return (
      <div className="filtersTooltip">
        <NudosToolTip
          tooltipContent={tooltipContent}
          tooltipContentCustomStyles={{ width: '240px', height: '104px' }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (name === 'category' && !stateFilters.find(filter => filter.name === 'category') && categoryFilters) {
      const laptopsId = categoryFilters.find(filter => (filter.label = 'Laptops'))?.value;
      typeof laptopsId !== undefined || null ? setFilter(laptopsId) : setFilter(categoryFilters[0].value);
    }
  }, [categoryFilters]);

  return (
    <div id="horizontal-filters-container" className="extraHorizontalFilters">
      {filters.map(({ label, value, imageUrl, nameStringId }, index) => {
        const role = _filter?.values[0] === value ? 'active' : 'normal';
        const showToolTip = value === '9' || value === '10';
        const _setFilter = () => {
          setFilter(value);
          if (resetPage) {
            resetPage();
          }
          segmentTrackEvent({
            storeFilterCategory: {
              CategoryName: `${label}`
            }
          });
        };

        return (
          <button
            onClick={_setFilter}
            role={role}
            key={`${label}-${value}`}
            style={{ marginLeft: `${index === 0 ? '0px' : ''}` }}
          >
            <div className="containerImg">
              <div className="boxImg">
                <img className="limitImg" src={imageUrl || ''} />
              </div>
            </div>
            <div className="containerText">
              <div className="styleText"> {t(`services:filters:category:${nameStringId}`)}</div>
              {showToolTip && getFilterTooltip(value)}
            </div>
          </button>
        );
      })}
    </div>
  );
}

const HorizontalFilters = memo(_HorizontalFilters);
export default HorizontalFilters;
