import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_1723_14324)">
      <path
        d="M11.1429 6.00021C11.1429 3.15989 8.84033 0.857352 6 0.857352C3.15968 0.857352 0.857147 3.15989 0.857147 6.00021C0.857147 8.84053 3.15968 11.1431 6 11.1431C8.84033 11.1431 11.1429 8.84053 11.1429 6.00021Z"
        fill={props?.fill || 'white'}
        stroke={props?.stroke || '#CCCCCC'}
      />
      <path d="M3.42856 6H8.5979" stroke={props?.stroke || '#CCCCCC'} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.83069 7.76734C7.60605 7.13208 7.99348 6.74472 8.59793 6.00012C7.99348 5.25552 7.60605 4.86817 6.83069 4.23291"
        stroke={props?.stroke || '#CCCCCC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1723_14324">
        <rect width="12" height="12" fill={props?.fill || 'white'} />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const ArrowButton = memo(ForwardRef);
export default ArrowButton;
