import { FC, useCallback, useEffect, useState } from 'react';
import { CartIcon } from '../../assets/images/views/Ecommerce';
import { IconAdd, IconSubstract } from '../../assets/DesignSystem/SVGComponents';
import { IProductItemList } from '../../types/catalogue';
import {
  addProductCart,
  deleteProduct,
  editInputQuantity,
  firstProduct,
  productQuantity,
  removeProductCard
} from '../../utils/cart';
import useShopStore from '../../state/useShopContext';
import { IProductUpdate } from '../../types/cart';
import useStoreCountryState from '../../state/useStoreCountryState';
import useCheckoutStore from '../../views/ecommerce/checkout/state';
import { Tany } from '../../types/global';
import { deleteProductCart, updateProductCart } from '../../services/cart.services';
import { displayErrorNotification } from '../../utils/displayNudosStandardNotifications';
import { formatOrgData } from '../../utils/orgFormatData';
import useStateSubscriptions from '../../state/useStateSubscriptions';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../utils/segment';

import './ButtonToEditQuantities.scss';

interface IButtonToEditQuantities {
  dataProductCard: IProductItemList;
}

interface IDebounceProduct {
  product: IProductUpdate;
  type: string;
  shoppingCartId?: number | null;
}

const ButtonToEditQuantities: FC<IButtonToEditQuantities> = ({ dataProductCard }: IButtonToEditQuantities) => {
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();
  const isFreePlatform = stateSubscription?.levelSubscription === 'Free';
  const translationsProductEcommerceCard = 'ecommerce:productEcommerceCard:';
  const { itemsCart, setCart } = useShopStore();
  const { step: checkoutStep, setStep } = useCheckoutStore();
  const { storeCountry } = useStoreCountryState();
  const [productCardQuantity, setProductCardQuantity] = useState<number>(0);
  const orgInfo = formatOrgData();
  const { levelSubscription } = stateSubscription;
  const isPrime = levelSubscription === 'Lite' || levelSubscription === 'Vip';
  const [isEditQuantity, setIsEditQuantity] = useState<boolean>();
  const [openInput, setOpenInput] = useState<boolean>(false);

  const initialAmount = () => {
    if (itemsCart) {
      const defaultQuantity = productQuantity(Number(dataProductCard?.productId), itemsCart);
      setProductCardQuantity(defaultQuantity);
    }
  };

  const resetCheckoutStep = () => checkoutStep !== 0 && setStep(0);

  const debounce = (func: Tany) => {
    let timer: Tany;
    return function (...args: Tany) {
      const context: Tany = '';
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const deleteItem = async () => {
    setProductCardQuantity(0);
    if (storeCountry?.countryName && itemsCart && storeCountry?.id) {
      deleteProduct(Number(dataProductCard.productId), itemsCart, setCart);
      if (itemsCart?.shoppingCartId) {
        optimizedFn({ product: {}, productCardQuantity, type: 'delete', shoppingCartId: itemsCart.shoppingCartId });
        resetCheckoutStep();
        await deleteProductCart(itemsCart.shoppingCartId, Number(dataProductCard.productId), storeCountry?.id);
      }
    }
  };

  const removeItem = (quantity: number) => {
    const formatProduct: IProductUpdate = {
      productId: Number(dataProductCard?.productId),
      productName: dataProductCard?.productName || '',
      brandImage: dataProductCard.brandImage || '',
      image: dataProductCard.imageUrl || '',
      pills: dataProductCard?.pills || undefined,
      countryFlag: storeCountry?.countryFlag || '',
      countryName: storeCountry?.countryName || '',
      countryNameStringId: storeCountry?.nameStringId || '',
      countryId: storeCountry?.id || 0,
      quantity: quantity,
      unitPrice: dataProductCard?.prices?.pricePrimeUsd || '',
      brandName: dataProductCard?.brandName || '',
      category: dataProductCard?.categoryName || '',
      prices: null,
      sku: dataProductCard?.sku || ''
    };
    setProductCardQuantity(quantity);
    if (storeCountry?.countryName && itemsCart) {
      removeProductCard(Number(dataProductCard.productId), quantity, itemsCart, setCart);
      optimizedFn({ product: formatProduct, quantity, type: 'edit', shoppingCartId: itemsCart.shoppingCartId });
      resetCheckoutStep();
    }
  };

  const addQuantityProduct = (quantity: number) => {
    const formatProduct: IProductUpdate = {
      productId: Number(dataProductCard?.productId),
      productName: dataProductCard.productName || '',
      brandImage: dataProductCard.brandImage || '',
      image: dataProductCard.imageUrl || '',
      pills: dataProductCard?.pills || undefined,
      countryFlag: storeCountry?.countryFlag || '',
      countryName: storeCountry?.countryName || '',
      countryNameStringId: storeCountry?.nameStringId || '',
      countryId: Number(storeCountry?.id),
      quantity: quantity,
      unitPrice: dataProductCard?.prices?.pricePrimeUsd || '',
      brandName: dataProductCard?.brandName || '',
      category: dataProductCard?.categoryName || '',
      prices: null,
      sku: dataProductCard?.sku || ''
    };
    setProductCardQuantity(quantity);
    segmentTrackEvent({
      catalogueExternalProductKartClick: {
        CategoryName: dataProductCard?.categoryName || '',
        CountryName: storeCountry?.countryName || '',
        SKUId: dataProductCard?.sku || '',
        TypeOfPlatform: isFreePlatform ? 'Free' : 'Prime',
        USDTotal: String(
          dataProductCard?.prices?.havePrime
            ? dataProductCard?.prices?.pricePrimeUsd
            : dataProductCard?.prices?.priceWithoutPrimeUsd
        ),
        TotalUnits: quantity,
        ProductName: dataProductCard?.productName || ''
      }
    });
    if (itemsCart && setCart) {
      addProductCart(formatProduct, itemsCart, setCart);
      optimizedFn({ product: formatProduct, type: 'edit', shoppingCartId: itemsCart.shoppingCartId });
      resetCheckoutStep();
    }
  };

  const editBlurQuantity = async (quantity: number) => {
    if (quantity === 0 && storeCountry?.countryName && itemsCart) {
      deleteProduct(Number(dataProductCard.productId), itemsCart, setCart);
      await deleteProductCart(Number(itemsCart.shoppingCartId), Number(dataProductCard.productId), storeCountry?.id);
      resetCheckoutStep();
    } else if (storeCountry?.countryName && itemsCart) {
      const formatProduct: IProductUpdate = {
        productId: Number(dataProductCard?.productId),
        productName: dataProductCard.productName || '',
        brandImage: dataProductCard.brandImage || '',
        image: dataProductCard.imageUrl || '',
        pills: dataProductCard?.pills || undefined,
        countryFlag: storeCountry?.countryFlag || '',
        countryName: storeCountry?.countryName || '',
        countryNameStringId: storeCountry?.nameStringId || '',
        countryId: storeCountry?.id,
        quantity: quantity,
        unitPrice: dataProductCard?.prices?.pricePrimeUsd || '',
        brandName: dataProductCard?.brandName || '',
        category: dataProductCard?.categoryName || '',
        prices: null,
        sku: dataProductCard?.sku || ''
      };
      await updateProductCart({
        userId: orgInfo?.userId || 0,
        havePrime: isPrime,
        organizationId: orgInfo?.organizationId || 0,
        products: [formatProduct],
        countryCode: storeCountry.countryCode || ''
      });
      resetCheckoutStep();
      editInputQuantity(Number(dataProductCard.productId), itemsCart, quantity, setCart);
    }
    setIsEditQuantity(false);
  };

  const handleChange = async (updateData: IDebounceProduct) => {
    if (updateData.type === 'edit' && updateData.product) {
      const format = {
        userId: orgInfo?.userId || 0,
        havePrime: isPrime,
        organizationId: orgInfo?.organizationId || 0,
        products: [updateData.product],
        countryCode: storeCountry?.countryCode || ''
      };
      try {
        await updateProductCart(format);
        resetCheckoutStep();
        if (!updateData?.shoppingCartId && orgInfo) {
          firstProduct(setCart, orgInfo.userId, orgInfo.organizationId);
        }
      } catch (error) {
        displayErrorNotification();
      }
    }
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  useEffect(() => {
    initialAmount();
  }, []);

  return (
    <div id="buttonToEditQuantities">
      {productCardQuantity === 0 && !openInput ? (
        <div
          className="zeroQuantity"
          onClick={() => {
            addQuantityProduct(productCardQuantity + 1);
          }}
        >
          <div className="textZeroQuantity">
            <div className="textZeroQuantity">{t(`${translationsProductEcommerceCard}addButton`)}</div>
          </div>
          <div>
            <CartIcon />
          </div>
        </div>
      ) : (
        <div className="productsCounterContainer">
          <div
            className="removeQuantities"
            onClick={() => {
              if (productCardQuantity === 1) {
                deleteItem();
              } else {
                removeItem(productCardQuantity - 1);
              }
            }}
          >
            <div className="removeItemsBox">
              <IconSubstract />
            </div>
          </div>
          <div className="inputContainerCounter">
            <input
              className="inputStyles"
              defaultValue={productCardQuantity}
              value={productCardQuantity}
              onMouseOut={(e: Tany) => {
                if (isEditQuantity) {
                  editBlurQuantity(Number(e.target.value || 0));
                  setOpenInput(false);
                }
              }}
              onChange={e => {
                if (/^\d*$/.test(e.target.value)) {
                  setProductCardQuantity(Number(e.target.value));
                  setIsEditQuantity(true);
                  setOpenInput(true);
                }
              }}
            />
          </div>
          <div
            className="addQuantities"
            onClick={() => {
              addQuantityProduct(productCardQuantity + 1);
            }}
          >
            <IconAdd />
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonToEditQuantities;
