import { useEffect, useState } from 'react';
import { CameraIcon } from '../../../assets/images/components/NudosComponents';
import { Iemployee, IaddressFromGoogleMaps } from '../../../types/global';
import {
  NudosAddressSearchBar,
  NudosButton,
  NudosHoverText,
  NudosPhoneInput,
  NudosTextInput
} from '../../NudosComponents';
import { getAddressErrorText, getIdDocumentData, isPhoneValid } from '../../../utils/formValidations';
import { ICountryDetail } from '../../../types/countries';
import { formatZipCode, getIsInvalidZipCode, invalidZipCodeText } from '../../../utils/productDefinitions';
import { DTOupdateEmployeeData } from '../../../types/DTO';
import { IshipmentDetails } from '../../../types/orders';
import './EmployeeDestinationModal.scss';

/**
 * IMPORTANT: Use this modal as the prop for the CustomModalComponent prop of the NudosModalContent, which in turn must be the modalContent prop of the NudosGenericModal
 * @property {Iemployee} employeeData -The employee whose address is the destination, this is the initial data for the modal
 * @property {string} subtitle - The text to show as a modal subtitle
 * @property {string} buttonText - The text for the modal button
 * @property {(modalData: DTOupdateEmployeeData) => void | Promise<void>} clickButtonCallbac - a function to execute upon clickin the modal button, receives as param the data of the modal
 * @property { boolean } isButtonLoading - A boolean indicating if the modal submit data button is loading
 */
const EmployeeDestinationModal = ({
  employeeData,
  shipmentExistingData,
  subtitle,
  buttonText,
  clickButtonCallback,
  isButtonLoading,
  listOfCountries
}: {
  employeeData?: Iemployee;
  shipmentExistingData?: IshipmentDetails;
  subtitle: string;
  buttonText: string;
  clickButtonCallback: (modalData: DTOupdateEmployeeData) => void | Promise<void>;
  isButtonLoading?: boolean;
  listOfCountries?: ICountryDetail[];
}) => {
  const { firstName, lastName, photoProfile, country, phoneData, personalId, address } = employeeData || {};

  const [updatedPersonalId, setUpdatedPersonalId] = useState<string>();
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<string>();
  const [updatedPhoneCountry, setUpdatedPhoneCountry] = useState<ICountryDetail>();
  const [updatedZipCode, setUpdatedZipCode] = useState<string>();
  const [updatedAddress, setUpdatedAddress] = useState<IaddressFromGoogleMaps>();
  const [updatedAddressAditionalReferences, setUpdatedAddressAditionalReferences] = useState<string>();

  const employeeIsFromBrazil = country?.code === 'br';
  const employeeFullName = shipmentExistingData?.receiverName || `${firstName || ''} ${lastName || ''}`;
  const employeeNameMaxLength = employeeIsFromBrazil ? 18 : 40;
  const employeeImage = photoProfile ? `url("${photoProfile.replaceAll(' ', '%20')}")` : `url("${CameraIcon}")`;
  const noImageStyle = !photoProfile ? 'noImage' : '';
  const idDocumentData = getIdDocumentData(country || undefined, updatedPersonalId);

  const additionalReferencesErrorText =
    updatedAddress && !updatedAddressAditionalReferences ? 'Completa las indicaciones' : '';

  const defaultPhoneCountryData =
    country && phoneData?.phoneCode
      ? { ...country, phoneCode: phoneData?.phoneCode }
      : listOfCountries?.find(countryInList => countryInList?.code === country?.code);

  const updatedCompleteAddress = {
    ...updatedAddress,
    address: updatedAddress?.address || '',
    zipCode: updatedZipCode || '',
    additionalReferences: updatedAddressAditionalReferences || ''
  };

  const addressError = !updatedAddress?.address
    ? ' '
    : getAddressErrorText(
        country?.code,
        country?.name,
        updatedAddress?.country || undefined,
        true,
        updatedAddress?.coordinates
      );

  const getZipCodeErrorText = () => {
    if (!updatedZipCode) return ' ';
    if (getIsInvalidZipCode(updatedZipCode)) return invalidZipCodeText;
  };

  const getInvalidPhoneError = () => {
    if (!updatedPhoneNumber) return ' ';
    if (!isPhoneValid(updatedPhoneNumber)) return `Ingresa un teléfono válido para ${country?.name || 'este país'}`;
  };

  const isButtonDisabled = () => {
    const addressIsIncomplete = Object.entries(updatedCompleteAddress).some(
      ([key, value]) => !['coordinates'].includes(key) && !value
    );
    if (!updatedPhoneNumber || !updatedPhoneCountry || !updatedZipCode) return true;
    if (!updatedAddress || !updatedAddressAditionalReferences) return true;
    if (addressIsIncomplete) return true;
    if (idDocumentData?.errorText) return true;
    if (
      getAddressErrorText(
        country?.code,
        country?.name,
        updatedAddress?.country || undefined,
        true,
        updatedAddress?.coordinates
      )
    )
      return true;
    if (!isPhoneValid(updatedPhoneNumber)) return true;
    if (getIsInvalidZipCode(updatedZipCode)) return true;
    if (!updatedPhoneCountry?.id || !employeeData?.country?.id) return true;
    if (!updatedAddressAditionalReferences) return true;
    return false;
  };

  const handleClickModalButton = () => {
    if (isButtonDisabled() || !updatedPhoneCountry?.id || !employeeData?.country?.id) return;
    const modalData = {
      firstName: employeeData?.firstName || undefined,
      lastName: employeeData?.lastName || undefined,
      personalId: idDocumentData?.value,
      phone: updatedPhoneNumber || employeeData?.phoneData?.phone,
      countryId: employeeData?.country?.id,
      city: employeeData?.city || undefined,
      cityId: employeeData?.cityId || undefined, // CAMBIAR-JC: Cambiar si alguna vez diseño implemente un select de ciudad en este modal
      area: employeeData?.area || undefined,
      position: employeeData?.position || undefined,
      address: updatedCompleteAddress || employeeData?.address,
      countryPhoneId: updatedPhoneCountry?.id
    };
    clickButtonCallback(modalData);
  };

  useEffect(() => {
    if (!updatedPhoneNumber) setUpdatedPhoneNumber(phoneData?.phone || undefined);
    if (!updatedZipCode) setUpdatedZipCode(formatZipCode(address?.zipCode) || undefined);
    if (!updatedPersonalId) setUpdatedPersonalId(personalId || undefined);
    if (!updatedAddress)
      setUpdatedAddress({
        ...employeeData?.address,
        country: employeeData?.country?.code || employeeData?.address?.country || '',
        city: employeeData?.city || employeeData?.address?.city || ''
      });
    if (!updatedAddressAditionalReferences)
      setUpdatedAddressAditionalReferences(employeeData?.address?.additionalReferences || undefined);
  }, [employeeData]);

  return (
    <div className="employeeDestinationModal">
      <h2 className="subtitle">{subtitle}</h2>
      <div className="photoAndName">
        <div className={`photo ${noImageStyle}`} style={{ backgroundImage: employeeImage }} />
        <NudosHoverText
          customClassName="name"
          text={employeeFullName}
          charactersLimit={employeeNameMaxLength}
          onlyIfTruncated
        />
        <div className="flag" style={{ backgroundImage: `url(${country?.flag || ''})` }} />
        {employeeIsFromBrazil && (
          <em className="personalIdWarningText">
            El CPF es obligatorio para entregas en Brasil. En caso de que este dato no este correcto la entrega podría
            retrasarse o no entregarse en los tiempos estipulados.
          </em>
        )}
      </div>
      <div className="firstLineFields">
        <NudosTextInput
          componentSize="small"
          label={idDocumentData?.label}
          placeholder={idDocumentData?.placeholder}
          isFilled={!!idDocumentData?.value}
          handleChange={setUpdatedPersonalId}
          defaultValue={idDocumentData?.value}
          errorText={idDocumentData?.errorText}
          caption={idDocumentData?.caption}
        />
        <NudosPhoneInput
          componentSize="small"
          label="Teléfono"
          placeholder="Ej: 3000000001"
          defaultCountryData={defaultPhoneCountryData}
          defaultPhoneNumber={phoneData?.phone || undefined}
          errorText={getInvalidPhoneError()}
          handlePhoneChange={setUpdatedPhoneNumber}
          countriesList={listOfCountries || []}
          handleCountryPhoneChange={setUpdatedPhoneCountry}
        />
        <NudosTextInput
          componentSize="extraSmall"
          label="Código postal"
          placeholder="CP"
          isFilled={!!updatedZipCode}
          handleChange={setUpdatedZipCode}
          handleBlur={newZipCode => setUpdatedZipCode(formatZipCode(newZipCode))}
          defaultValue={updatedZipCode || undefined}
          errorText={getZipCodeErrorText()}
        />
      </div>
      <div className="secondLineFields">
        <NudosAddressSearchBar
          componentSize="large"
          label="Dirección"
          regionBias={country?.code}
          handleAddressSelection={setUpdatedAddress}
          errorText={addressError}
          defaultValueAddressName={address?.address || undefined}
        />
      </div>
      <div className="thirdLineFields">
        <NudosTextInput
          componentSize="medium"
          label="Indicaciones"
          errorText={additionalReferencesErrorText}
          placeholder="Ej: Torre, Apto, Casa"
          isFilled={!!updatedAddressAditionalReferences}
          handleChange={setUpdatedAddressAditionalReferences}
          defaultValue={updatedAddressAditionalReferences || undefined}
        />
      </div>
      <NudosButton
        componentSize="medium"
        buttonText={buttonText}
        isButtonDisabled={isButtonDisabled()}
        handleClick={handleClickModalButton}
        isButtonLoading={isButtonLoading}
      />
    </div>
  );
};

export default EmployeeDestinationModal;
