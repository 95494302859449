import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={9} height={9} fill="none" ref={ref} {...props}>
    <g>
      <path
        stroke="#383838"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.833 1.96c-.982.01-1.53.073-1.894.437-.439.439-.439 1.146-.439 2.56 0 1.415 0 2.122.44 2.56.439.44 1.146.44 2.56.44 1.414 0 2.121 0 2.56-.44.354-.353.423-.88.437-1.81m.003-3.75H5.25m2.25 0v2.25m0-2.25-2.75 2.75"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconRedirection = memo(ForwardRef);
export default IconRedirection;
