import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={10} height={11} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M5.813 2.125c-1.317 1.078-1.974 1.736-3 3 1.026 1.264 1.683 1.922 3 3"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const BreadcrumbButtonReturnIcon = memo(ForwardRef);
export default BreadcrumbButtonReturnIcon;
