import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CloseEye, OpenEye } from '../../../../../../assets/images/components/assignToolCard';
import { BlueSmile, NoSmile } from '../../../../../../assets/images/components/floatForm';
import { DownloadIcon } from '../../../../../../assets/images/components/NudosComponents';
import { NudosButton, NudosFileInput, NudosSelectButton } from '../../../../../../components/NudosComponents';
import ProductPills from '../../../../../../components/ProductPills/ProductPills';
import { IassignedTool, Tany } from '../../../../../../types/global';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import { isTruncated, truncateText } from '../../../../../../utils/truncateText';
import CantManageTooltip from '../../../../../../components/CantManageTooltip/CantManageTooltip';
import {
  disableToolManagementBeforeDeliveryAlertText,
  logisticServiceInCourseAlertText
} from '../../../../../../utils/productDefinitions';
import { ToolSerial } from '../../../../../../components';
import './AssignedToolCard.scss';

export interface IassignedToolCardProps {
  assignedTool: IassignedTool;
  toolsToUnassign?: IassignedTool[];
  setToolsToUnassign?: React.Dispatch<React.SetStateAction<IassignedTool[]>>;
  inactiveCard?: boolean;
  isPossibleToUnassign?: boolean;
  updateFile?: (assignmentId: number, file: Tany) => void;
  loaderFileCardId?: number;
  toolInTransit?: boolean;
}

const AssignedToolCard = ({
  assignedTool,
  inactiveCard,
  toolsToUnassign,
  setToolsToUnassign,
  isPossibleToUnassign,
  updateFile,
  loaderFileCardId,
  toolInTransit
}: IassignedToolCardProps) => {
  const { t } = useTranslation();
  const { id: employeeId } = useParams<{ id: string }>();
  const [checked, setChecked] = useState(false);
  const [openCard, setOpenCard] = useState<boolean>(false);

  const AssignedToolCardLenguage = 'nodi:employeeInternal:employeeDetailsBody:assignedToolCard:';
  const pills = typeof assignedTool?.pills === 'string' ? JSON.parse(assignedTool?.pills) : assignedTool?.pills;
  const toolTimeZone = assignedTool?.country?.timeZone || undefined;
  const redirectionsTranslationKey = `nodi:headBar:`;

  const toolHasntBeenDelivered =
    assignedTool?.referenceModel === 'CatalogProduct' &&
    (assignedTool?.shipmentStatusName || assignedTool?.statusName) !== 'Entregado';

  const logisticServiceInCourse =
    assignedTool?.logisticStatus &&
    assignedTool?.logisticStatus !== 'entregado' &&
    assignedTool?.logisticStatus !== 'cancelado';

  const orderDeliveryStatusActive = ['Confirmado', 'Proceso de entrega'].includes(
    assignedTool?.shipmentStatusName || ''
  );

  const orderToolInShippingProcess = orderDeliveryStatusActive && toolInTransit;

  const checkedStyles = checked ? '' : 'notChecked';
  const disabledCheckButtonStyles = logisticServiceInCourse || toolHasntBeenDelivered ? 'inactive' : '';
  const hasUnassignCheckbox = !inactiveCard && isPossibleToUnassign;

  const disabledToolManagementRedirectionText = toolHasntBeenDelivered && assignedTool?.orderId ? 'Ver Orden' : '';
  const disabledToolManagementRedirectionUrl =
    toolHasntBeenDelivered && assignedTool?.orderId
      ? `/nodi/orders/details/${assignedTool?.orderId}?from=employee-details&id=${employeeId}`
      : '';

  const toolAssignmentIsCompleted = !!assignedTool?.assignedSince && !logisticServiceInCourse;

  const assignedOrRequestAssignmentDateTitle = toolAssignmentIsCompleted
    ? t(`${AssignedToolCardLenguage}assignmentDate`)
    : t(`${AssignedToolCardLenguage}assignmentRequestDate`);

  const assignedOrRequestAssignmentDate = toolAssignmentIsCompleted
    ? assignedTool?.assignedSince
    : assignedTool?.movementRequestDate || '';

  const getDisableToolManagementAlertText = () => {
    if (toolHasntBeenDelivered) return disableToolManagementBeforeDeliveryAlertText;
    if (!toolHasntBeenDelivered && logisticServiceInCourse) return logisticServiceInCourseAlertText;
    return '';
  };

  const handleCheckUnassign = () => {
    if (!toolsToUnassign || !setToolsToUnassign) return;
    if (checked) {
      const newToolsToUnassign = [...toolsToUnassign].filter(tool => tool?.assignmentId !== assignedTool?.assignmentId);
      setToolsToUnassign(newToolsToUnassign);
    } else {
      const newToolsToUnassign = [...toolsToUnassign, assignedTool];
      setToolsToUnassign(newToolsToUnassign);
    }
    setChecked(!checked);
  };

  const downLoadDocument = (url: string) => window.open(url, '_blank');

  const logisticDetailsRedirection = {
    pathname: `/nodi/logistics-services/details/${assignedTool.logisticServiceId}`,
    state: {
      returnText: 'Empleado'
    }
  };

  const orderDetailsReditection = `/nodi/orders/details/${assignedTool?.orderId}?from=employee-details&id=${employeeId}`;

  const toolDetailsRedirection = {
    pathname: `/nodi/details/${assignedTool.productId}`,
    state: {
      customReturnPath: `/nodi/employees/details/${employeeId}`,
      customNavigationTitle: t(`${redirectionsTranslationKey}headerTextBold:employee`),
      customNavigationSubtitle: t(`${redirectionsTranslationKey}headerTextRegular:productDetails`)
    }
  };

  useEffect(() => {
    if (!toolsToUnassign) return;
    const shouldBeChecked = toolsToUnassign.some(tool => tool?.assignmentId === assignedTool?.assignmentId);
    shouldBeChecked && setChecked(true);
    !shouldBeChecked && setChecked(false);
  }, [toolsToUnassign]);

  return (
    <>
      <div className={`assignedToolCard ${openCard ? 'openExtraSyle' : ''} `}>
        <div className="imageSection">
          {assignedTool.image && <img src={assignedTool.image} alt="Product asignado" />}
        </div>
        <div className="productDetailsSection">
          <div className="nameAndBrand">
            {assignedTool?.brandImage && (
              <div className="brandImage">
                <img src={assignedTool?.brandImage} alt="Logo marca" />
              </div>
            )}
            {!assignedTool?.brandImage && <div className="brandName">{assignedTool?.brand}</div>}
            <div className="name">
              {truncateText(assignedTool?.productName, 24)}
              {isTruncated(assignedTool?.productName, 24) && (
                <div className="nameHover">{assignedTool?.productName}</div>
              )}
            </div>
            {assignedTool?.referenceModel === 'CatalogProduct' && <BlueSmile opacity={inactiveCard ? 0.5 : 1} />}
            {assignedTool?.referenceModel === 'ExternalProduct' && <NoSmile opacity={inactiveCard ? 0.5 : 1} />}
          </div>
          <div className="pillsAndSerial">
            <ProductPills pills={pills} />
            <ToolSerial serial={assignedTool?.serial || 'Sin serial'} charactersLimitForHover={18} />
          </div>
        </div>
        <div className="acquisitionInfoSection">
          {assignedTool?.logisticServiceId && <div className="topText">{t(`${AssignedToolCardLenguage}topText`)}</div>}
          {!assignedTool?.logisticServiceId && orderToolInShippingProcess && (
            <>
              <div className="topText">{t(`${AssignedToolCardLenguage}orderStatus`)}</div>
              <div className="downText">
                <span className="statusText">{assignedTool?.shipmentStatusName}</span>
                <span className="grayLine"> - </span>
                <Link className="blueLine" to={orderDetailsReditection}>
                  {t(`${AssignedToolCardLenguage}downText`)}
                </Link>
              </div>
            </>
          )}
          <div className="downText">
            {assignedTool?.logisticStatus && <span className="statusText">{assignedTool?.logisticStatus}</span>}
            {assignedTool?.logisticServiceId && (
              <>
                <span className="grayLine"> - </span>
                <Link className="blueLine" to={logisticDetailsRedirection}>
                  {t(`${AssignedToolCardLenguage}downText`)}
                </Link>
              </>
            )}
          </div>
        </div>
        <Link className="checkToolButton" to={toolDetailsRedirection}>
          {t(`${AssignedToolCardLenguage}containerMinFormCard:checkToolButton`)}
        </Link>
        <div className="containerIcon">
          {!hasUnassignCheckbox && (
            <>
              {openCard ? (
                <OpenEye onClick={() => setOpenCard(false)} />
              ) : (
                <CloseEye onClick={() => setOpenCard(true)} />
              )}
            </>
          )}
        </div>
        <div className="checkboxButtonContainer">
          <div className="disabledManagementTooltipContainer">
            {hasUnassignCheckbox && (
              <NudosSelectButton
                customClassName={`unassignSelectCheckmark ${checkedStyles}`}
                isButtonActive={checked}
                handleClickButton={handleCheckUnassign}
                isButtonDisabled={!!disabledCheckButtonStyles}
              />
            )}
            {!!disabledCheckButtonStyles && (
              <CantManageTooltip
                toolTipText={getDisableToolManagementAlertText()}
                toolTipStyles={{ top: '20px', right: '2px', width: '160px' }}
                redirectionText={disabledToolManagementRedirectionText}
                redirectionUrl={disabledToolManagementRedirectionUrl}
              />
            )}
          </div>
        </div>
      </div>
      {openCard && (
        <div className="containerUploadFile">
          {loaderFileCardId === assignedTool?.assignmentId ? (
            <>
              <div className="containerMinFormCard">
                <div className="containerInputFileCard">
                  <div className="titleLoader mb-1 animationLoader rounded" style={{ height: '10px' }} />
                  <div className="titleLoader animationLoader rounded" style={{ height: '38px' }} />
                </div>
              </div>
              <div className="containerMinButton animationLoader rounded" />
            </>
          ) : (
            <>
              <div className="containerMinFormCard">
                <div className="assignationDateSection">
                  {inactiveCard && assignedTool?.assignedUntil && (
                    <div className="unassignmentDate">
                      {t(`${AssignedToolCardLenguage}unassignmentDate`)}
                      <span>{formatShortDate(assignedTool.assignedUntil, toolTimeZone)?.textWithTimezone}</span>
                    </div>
                  )}
                  <div className="assignmentDate">
                    {assignedOrRequestAssignmentDateTitle}
                    <span>{`${formatShortDate(assignedOrRequestAssignmentDate, toolTimeZone)?.textWithTimezone}`}</span>
                  </div>
                </div>
                <div className="containerInputFileCard">
                  <NudosFileInput
                    color="orange"
                    isFilled={!!assignedTool?.assngedDocumentUrl}
                    label={t(`${AssignedToolCardLenguage}containerMinFormCard:containerInputFileCard`)}
                    uploadedFileHandler={e => {
                      if (updateFile && assignedTool?.assignmentId) {
                        updateFile(assignedTool?.assignmentId, e);
                      }
                    }}
                    buttonText={
                      assignedTool?.assngedDocumentUrl
                        ? `Documento ${assignedTool.productName}`
                        : t(`${AssignedToolCardLenguage}containerMinFormCard:containerInputFileCard`)
                    }
                  />
                </div>
              </div>
              <div className="containerMinButton">
                <NudosButton
                  handleClick={() => {
                    if (assignedTool?.assngedDocumentUrl) {
                      downLoadDocument(assignedTool?.assngedDocumentUrl);
                    }
                  }}
                  customClassName="myMinButton"
                  isButtonDisabled={!assignedTool?.assngedDocumentUrl}
                  customWidth="75px"
                  customHeight="20px"
                  buttonText={t(`${AssignedToolCardLenguage}myMinButton`)}
                  buttonIcon={<DownloadIcon />}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AssignedToolCard;
