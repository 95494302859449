import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NudosButton, NudosPasswordInput, NudosTextInput } from '../../../../../../components/NudosComponents';
import { signUpCollaborator } from '../../../../../../services/signUp.service';
import { IformStep3Fields, IformStep3FieldsError } from '../../../../../../types/SignUp';
import useOtpController from '../../../../../../components/Otp/otp.controller';
import { UserInfo } from '@firebase/auth';
import createFirebaseUser from '../../../../../../utils/createUser';
import CustomCheck from '../../../customCheck';
import useSignUpFromInvitationController from './SignUpFromInvitation.controller';
import { isPasswordValid } from '../../../../../../utils/formValidations';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import { useTranslation } from 'react-i18next';

const SignUpFromInvitation = ({ emailParam, registrationInfoIdParam }: IsignUpFromInvitation) => {
  const { loginEmailAndPassWord } = useOtpController();
  const {
    formInputs,
    formSchema,
    isPasswordValid: verifyPassword,
    checkPasswordInputs
  } = useSignUpFromInvitationController();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<IformStep3FieldsError>();
  const [emailExistError, setEmailExistError] = useState<string>();
  const [loading, setloading] = useState(false);
  const [submittedData, setSubmittedData] = useState<IformStep3Fields>();
  const [passwordError, setPasswordError] = useState<string>();
  const [aceptTerms, setAceptTems] = useState<boolean>(false);
  const [secondAceptTerms, setSecondAceptTems] = useState<boolean>(false);
  const i18nLanguageKey = 'authentication:SignUpForm:useSignUpFormStep3Controller:';

  const { register, handleSubmit, watch } = useForm<IformStep3Fields>({
    shouldFocusError: true,
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: emailParam,
      password: '',
      confirmPassword: ''
    }
  });
  const currentData = watch();

  const handleSuccessfullSubmit = async (data: IformStep3Fields) => {
    setSubmittedData(data);
    setloading(true);
    const body = {
      email: data.email,
      registrationInfoId: registrationInfoIdParam ? +registrationInfoIdParam : undefined
    };
    try {
      const stringStoredUserFirebaseData = localStorage.getItem('userFirebaseData');
      const storedUserFirebaseData = stringStoredUserFirebaseData && JSON.parse(stringStoredUserFirebaseData);
      let firebaseData: UserInfo;
      if (!storedUserFirebaseData) {
        const userData: UserInfo = await createFirebaseUser(data.email, data.password);
        const stringUserData = JSON.stringify(userData);
        localStorage.setItem('userFirebaseData', stringUserData);
        firebaseData = userData;
      } else {
        firebaseData = storedUserFirebaseData;
      }
      const response = await signUpCollaborator({ ...body, uid: firebaseData.uid });
      if (response) {
        localStorage.removeItem('userFirebaseData');
        sessionStorage.removeItem('newRegisterInformation');
        await loginEmailAndPassWord(data.email, data.password, setPasswordError);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.error === 'auth/email-already-in-use') {
        setEmailExistError(t(`${i18nLanguageKey}setEmailExistError`));
      } else if (err?.response?.data?.message === 'emailAlreadyRegistered') {
        setEmailExistError(t(`${i18nLanguageKey}setEmailExistError`));
      } else {
        displayErrorNotification();
        throw { errorData: err };
      }
    } finally {
      setloading(false);
    }
  };

  const handleErrorSubmitting = (data: IformStep3FieldsError) => {
    setErrors(data);
  };

  const isButtonDisabled =
    !Object.values(currentData).every(value => value && value.length > 0) ||
    !verifyPassword(currentData.password) ||
    !checkPasswordInputs(currentData.password, currentData.confirmPassword) ||
    !aceptTerms ||
    !secondAceptTerms;

  const onChangeState = () => {
    setAceptTems(!aceptTerms);
  };

  const onChangeStateTerms = () => {
    setSecondAceptTems(!secondAceptTerms);
  };

  return (
    <form className="signUpFromInvitation" onSubmit={handleSubmit(handleSuccessfullSubmit, handleErrorSubmitting)}>
      {formInputs.map(field => {
        if (field.type === 'text' && field.id === 'email') {
          const emailHasChangedSinceSubmission = !!submittedData && currentData['email'] !== submittedData['email'];
          const emailErrorText =
            !emailHasChangedSinceSubmission &&
            (emailExistError || (errors ? errors[field.id as keyof IformStep3FieldsError]?.message : undefined));
          return (
            <NudosTextInput
              key={`field-${field?.id}`}
              componentSize="large"
              label={field?.label}
              placeholder={emailParam}
              isFilled={true}
              register={register}
              registerId={field?.id}
              errorText={emailErrorText || undefined}
              defaultValue={emailParam}
              isDeactivated
            />
          );
        }
        if (field.type === 'password') {
          const currentField = currentData[field.id as keyof IformStep3Fields];
          const passwordIsTooShortError =
            field.id === 'password' &&
            currentField.length < 8 &&
            currentField.length > 0 &&
            t(`${i18nLanguageKey}passwordIsTooShortError`);
          const passwordIsTooLongError = currentField.length > 15 && t(`${i18nLanguageKey}passwordIsTooLongError`);
          const invalidPasswordError =
            !currentField || currentField.length === 0 || isPasswordValid(currentField) || field.id !== 'password'
              ? ''
              : 'Incluye 8-15 caracteres al menos 1 mayúscula, minúscula, número y caracter especial.';
          const passwordsDontMatchError =
            !!currentData['password'] &&
            !!currentData['confirmPassword'] &&
            field.id === 'confirmPassword' &&
            !checkPasswordInputs(currentData.password, currentData.confirmPassword) &&
            t(`${i18nLanguageKey}passwordsDontMatchError`);
          const passwordErrorText =
            passwordIsTooShortError ||
            passwordIsTooLongError ||
            invalidPasswordError ||
            passwordsDontMatchError ||
            passwordError ||
            (errors ? errors[field.id as keyof IformStep3FieldsError]?.message : undefined);
          return (
            <NudosPasswordInput
              key={`field-${field?.id}`}
              isFilled={field?.id === 'password' ? !!currentData.password : !!currentData.confirmPassword}
              componentSize="large"
              label={field?.label}
              errorText={passwordErrorText}
              register={register}
              registerId={field?.id}
            />
          );
        }
      })}
      <div className="termsAndConditionsContainer">
        <div>
          <CustomCheck check={aceptTerms} type="terms" onChangeState={onChangeState} />
          <CustomCheck check={secondAceptTerms} type="warning" onChangeState={onChangeStateTerms} />
        </div>
      </div>
      <div className="buttonContainer">
        <NudosButton
          componentSize="small"
          buttonText="Guardar"
          isButtonDisabled={isButtonDisabled}
          isButtonLoading={loading}
          isOfTypeSubmit={true}
        />
      </div>
    </form>
  );
};

export default SignUpFromInvitation;

export interface IsignUpFromInvitation {
  emailParam: string;
  registrationInfoIdParam?: number | string | null;
}
