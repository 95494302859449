import { useEffect, useState } from 'react';
import ModuleFilterExpandableSelect, { IrawSelectOption } from './ModuleFiltersExpandableSelect';
import './ModuleFilters.scss';
import { ImoduleFilter } from '../../../types/global';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import ModuleFiltersIcon from '../../../assets/images/components/NudosComponents/ModuleFiltersIcon';
import FilterXMark from '../../../assets/images/components/NudosComponents/FilterXMark';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { useTranslation } from 'react-i18next';
interface ImoduleFiltersProps {
  moduleFilters: ImoduleFilter[];
  setModuleFilters: (moduleFilters: ImoduleFilter[]) => void;
  getModuleFiltersRequestFunction?: (organizationId: number | string) => Promise<DTOgetModuleFiltersRequestFunction>;
  getFiltersList: TgetFiltersList;
  moduleFiltersTitlesMapping: { [key: string]: string };
  filtersContainerStyles?: { [key: string]: string };
}

/**
 * @property {ImoduleFilter[]} moduleFilters - An array of the current filters for the module. Corresponds to the state variable that stores the filters the user has selected.
 * @property {(moduleFilters: ImoduleFilter[]) => void} setModuleFilters - The function that updates the filters when the user selects/unselects a filter value. Corresponds to the state updater for the moduleFilters.
 * @property {() => Promise<DTOgetModuleFiltersRequestFunction>} getModuleFiltersRequestFunction -The request function that fetch the value of the filters for the module.
 * @property {TgetFiltersList} getFiltersList - A function that returns the array with the complete data and control functions for each of the filters. This function must accept as params the functions declared on the ModuleFilter component: formModuleFilters, handleClickFilterOption,  getFilterActiveStatus, which in turn must be used in the getFiltersList function to get the properties rawSelectOptions, handleChangeSelectedOption, isFilterActive for each filter. The title property of each filter will be shown on the filters tab. The openFunction and openStatus properties of each filter correspond to the state function and value that control if the filter is open or closed.
 * @property {{ [key: string]: string }} moduleFiltersTitlesMapping - An object with keys corresponding to the name under which each filter is returned in the getModuleFiltersRequestFunction, and the value is the name that is expected for that filter by the function that brings the filtered data.
 */
const ModuleFilters = ({
  moduleFilters,
  setModuleFilters,
  getModuleFiltersRequestFunction,
  getFiltersList,
  moduleFiltersTitlesMapping,
  filtersContainerStyles
}: ImoduleFiltersProps) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:orderDetails:ordersFilters:';
  const [usersAvailableModuleFilters, setUsersAvailableModuleFilters] = useState<DTOgetModuleFiltersRequestFunction>();
  const orgId = getOrgData()?.organizationId;

  const getOptionActiveStyle = (filterName: string, optionValue?: string | number) => {
    const searchedFilter = moduleFilters.find((filter: ImoduleFilter) => filter.name === filterName);
    if (!searchedFilter) return false;
    const searchedOption = searchedFilter.values.find((value: string | number) => value === optionValue);
    if (!searchedOption) return false;
    if (searchedOption) return true;
  };
  const formModuleFilters = (
    filterTitle: string,
    defaultRawFilterOptions?: string[],
    nameToValueMap?: Map<string, string>
  ): IrawSelectOption[] => {
    if (
      !defaultRawFilterOptions &&
      (!usersAvailableModuleFilters || Object.entries(usersAvailableModuleFilters).length === 0)
    )
      return [];
    let rawFilterOptions;
    if (defaultRawFilterOptions) rawFilterOptions = [...defaultRawFilterOptions];
    if (!defaultRawFilterOptions && usersAvailableModuleFilters) {
      rawFilterOptions = usersAvailableModuleFilters[filterTitle];
    }
    if (!rawFilterOptions) return [];
    const filtersSelectOptions = rawFilterOptions.map(filterOptionName => {
      return {
        name: filterOptionName,
        value: nameToValueMap ? nameToValueMap.get(filterOptionName) : filterOptionName,
        isActive: getOptionActiveStyle(
          moduleFiltersTitlesMapping[filterTitle as keyof typeof moduleFiltersTitlesMapping],
          nameToValueMap ? nameToValueMap.get(filterOptionName) : filterOptionName
        ),
        id: filterOptionName
      };
    });
    return filtersSelectOptions;
  };

  const handleResetFilters = () => setModuleFilters([]);

  const handleClickFilterOption =
    (filterName: string, filterOptionProperty: keyof IrawSelectOption) => (option: IrawSelectOption) => {
      const newFilters = [...moduleFilters];
      if (!option) return;
      const currentFilterIndex = newFilters.findIndex(filter => filter.name === filterName);
      if (currentFilterIndex >= 0) {
        const currentValueIndex = newFilters[currentFilterIndex]?.values.findIndex(
          (value: string | number) => value === option[filterOptionProperty]
        );
        if (currentValueIndex >= 0) {
          if (newFilters[currentFilterIndex]?.values.length <= 1) {
            newFilters.splice(currentFilterIndex, 1);
            setModuleFilters(newFilters);
          } else {
            newFilters[currentFilterIndex]?.values.splice(currentValueIndex, 1);
            setModuleFilters(newFilters);
          }
        } else {
          newFilters[currentFilterIndex]?.values.push(option[filterOptionProperty]);
          setModuleFilters(newFilters);
        }
      } else {
        const newModuleFilter = {
          name: filterName,
          includes: 'in',
          values: [option[filterOptionProperty]]
        };
        newFilters.push(newModuleFilter);
        setModuleFilters(newFilters);
      }
    };

  const getFilterActiveStatus = (filterTitle: string) => moduleFilters.some(filter => filter.name === filterTitle);
  const filtersTitle =
    moduleFilters.length > 0 ? t(`${i18nLanguageKey}filtersTitle`) : t(`${i18nLanguageKey}filtersTitleDefault`);
  const filtersActiveStyle = moduleFilters.length > 0 ? 'active' : '';
  const filtersIcon =
    moduleFilters.length > 0 ? <FilterXMark role="active" /> : <ModuleFiltersIcon className="moduleFiltersIcon" />;

  const filtersList: IModuleFilterInfo[] = getFiltersList(
    formModuleFilters,
    handleClickFilterOption,
    getFilterActiveStatus
  );

  const getUsersModuleFilters = async () => {
    if (!orgId || !getModuleFiltersRequestFunction) return;
    try {
      const userModuleFilters = await getModuleFiltersRequestFunction(orgId);
      if (userModuleFilters) setUsersAvailableModuleFilters(userModuleFilters);
    } catch {
      displayErrorNotification();
    }
  };

  useEffect(() => {
    getUsersModuleFilters();
  }, []);

  return (
    <section className="moduleFilters">
      <div className={`moduleFiltersIconContainer ${filtersActiveStyle}`} onClick={handleResetFilters}>
        {filtersIcon}
        <div className="filtersTitle">{filtersTitle}</div>
      </div>
      <div className="filtersContainer" style={filtersContainerStyles}>
        {filtersList.map((filter, i) => {
          return (
            <ModuleFilterExpandableSelect
              key={`filter-${i}-${filter.title.replace(' ', '')}`}
              filterTitle={filter.title}
              openSelectStatus={filter.openStatus}
              openSelectFunction={filter.openFunction}
              rawSelectOptions={filter.rawSelectOptions}
              handleChangeSelectedOption={filter.handleChangeSelectedOption}
              isFilterActive={filter.isFilterActive}
            />
          );
        })}
      </div>
    </section>
  );
};

export default ModuleFilters;

export interface IModuleFilterInfo {
  title: string;
  openFunction: React.Dispatch<React.SetStateAction<boolean>>;
  openStatus: boolean;
  rawSelectOptions: IrawSelectOption[];
  handleChangeSelectedOption: (option: IrawSelectOption) => void;
  isFilterActive: boolean;
}

export interface DTOgetModuleFiltersRequestFunction {
  [key: string]: string[];
}

export type TgetFiltersList = (
  formModuleFilters: (
    filterTitle: string,
    defaultRawFilterOptions?: string[],
    nameToValueMap?: Map<string, string>
  ) => IrawSelectOption[],
  handleClickFilterOption: (
    filterName: string,
    filterOptionProperty: keyof IrawSelectOption
  ) => (option: IrawSelectOption) => void,
  getFilterActiveStatus: (filterTitle: string) => boolean
) => IModuleFilterInfo[];
