import { useEffect, useState } from 'react';
import { ICountryDetail } from '../../../types/countries';
import NudosTextInput from '../NudosTextInput/NudosTextInput';
import { NudosCountryDropdown } from '../../DesignSystem';
import './NudosPhoneInput.scss';

/**
 * A react functional component corresponding to the nudos phone input
 * @property {string} placeholder - Required
 * @property {string} label - Optional, a label to display on top of the phone input
 * @property {string} errorText - Optional, the text to be shown if theres an error with the input form. If an error is passed the style of the component will change to reflect that.
 * @property {boolean} isDisabled - Optional, if a value is provided the input would not allowed new searches. Styles would reflect its status as disabled.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "small", "medium" or "large". If no value is passed the default is small.
 * @property {function(phoneNumber: string): void} handlePhoneChange - Optional, a callback to execute upon changing the phoneNumber
 * @property {ICountryDetail} defaultCountryData - Optional, an object with the data of the default country
 * @property {string} defaultPhoneNumber - Optional, for cases when there is a default value. The phone number withount the country code
 * @property {funcion(): void} handleBlur - Optional, a callback to pass to the onBlur event for the input component (the text input, not the select one)
 * @property { string } personalClass - Optional, a string for customizing styles
 * @property { ICountryDetail[] } countriesList - The list of countries from which the user can select the phone country
 * @property { (newPhoneCountry: ICountryDetail | undefined) => void } handleCountryPhoneChange - The function that updates the countryData of the phone
 */

const NudosPhoneInput = ({
  placeholder,
  label,
  errorText,
  isDisabled,
  componentSize,
  handlePhoneChange,
  defaultCountryData,
  defaultPhoneNumber,
  handleBlur,
  personalClass,
  showSkeletonLoader,
  countriesList,
  handleCountryPhoneChange,
  simpleChangeOfValues
}: InudosPhoneInput) => {
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const [inputPhoneNumber, setInputPhoneNumber] = useState<string>('');
  const filledStyles =
    (selectedCountry && inputPhoneNumber) || (defaultCountryData && !!inputPhoneNumber) ? 'filled' : '';
  const errorStyles = errorText && errorText.length > 0 ? 'error' : '';
  const disabledStyles = isDisabled ? 'disabled' : '';
  const componentSizeStyles = componentSize ? componentSize : '';

  useEffect(() => {
    handlePhoneChange(inputPhoneNumber);
    handleCountryPhoneChange(selectedCountry);
  }, [selectedCountry, inputPhoneNumber]);

  useEffect(() => {
    defaultPhoneNumber && setInputPhoneNumber(defaultPhoneNumber);
  }, [defaultPhoneNumber]);

  useEffect(() => {
    if (!defaultCountryData || selectedCountry) return;
    setSelectedCountry(defaultCountryData);
  }, [defaultCountryData]);

  return (
    <div className={`nudosPhoneInput ${errorStyles} ${disabledStyles} ${componentSizeStyles} ${filledStyles}`}>
      {label && <label>{label}</label>}
      <div className="phoneInput">
        <NudosCountryDropdown
          charactersLimit={5}
          errorText={errorText}
          hideErrorText={true}
          displayLabel={false}
          currentValue={selectedCountry}
          countriesList={countriesList || []}
          isDeactivated={isDisabled}
          customPlaceholder="País"
          isPhoneIndicative={true}
          showSkeletonLoader={showSkeletonLoader}
          updateCurrentValueCallback={e => {
            setSelectedCountry(e);
            if (simpleChangeOfValues) {
              simpleChangeOfValues();
            }
          }}
        />
        <NudosTextInput
          placeholder={placeholder}
          isFilled={!!inputPhoneNumber}
          errorText={errorText}
          hideErrorText={true}
          isDeactivated={isDisabled}
          handleChange={e => {
            setInputPhoneNumber(e);
            if (simpleChangeOfValues) {
              simpleChangeOfValues();
            }
          }}
          defaultValue={inputPhoneNumber}
          handleBlur={handleBlur}
          customClassName={personalClass || ''}
          showSkeletonLoader={showSkeletonLoader}
        />
      </div>
      {errorText && <div className="errorText">{errorText}</div>}
    </div>
  );
};

export default NudosPhoneInput;

export interface InudosPhoneInput {
  placeholder: string;
  countriesList: ICountryDetail[];
  handleCountryPhoneChange: (newPhoneCountry: ICountryDetail | undefined) => void;
  handlePhoneChange: (phoneNumber: string) => void;
  label?: string;
  errorText?: string;
  isDisabled?: boolean;
  componentSize?: string;
  defaultCountryData?: ICountryDetail;
  defaultPhoneNumber?: string;
  handleBlur?: () => void;
  personalClass?: string;
  showSkeletonLoader?: boolean;
  simpleChangeOfValues?: () => void;
}
