import { FC } from 'react';
import { NudosRedirectionIcon } from '../../../../../components/DesignSystem';
import { InudoInfo } from '../../../../../types/global';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import {
  IconArrow,
  IconBuilding,
  IconEmployee,
  IconNudosFace,
  IconPin,
  IconReserveBox,
  IconShield,
  IconToolWrench
} from '../../../../../assets/DesignSystem/SVGComponents';
import { useHistory } from 'react-router-dom';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';

import './ActiveOrderCard.scss';

interface IActiveOrderCard {
  orderDetails?: InudoInfo;
  generalLoading?: boolean;
}

const ActiveOrderCard: FC<IActiveOrderCard> = ({ orderDetails }: IActiveOrderCard) => {
  const { push } = useHistory();
  const lastAssociatedLocation = orderDetails?.oldAssignment
    ? orderDetails?.oldAssignment?.find(location => !location.exitDate) || orderDetails?.oldAssignment[0]
    : undefined;
  const lastLocationEntryDate = formatShortDate(lastAssociatedLocation?.entryDate || '')?.onlyDate || '-';
  const generateDestinationData = (place?: TstoragePlace) => {
    switch (place) {
      case 'user':
        return {
          profileImg: orderDetails?.currentAssignment?.[0]?.photoProfile,
          icon: <IconEmployee style={{ transform: 'scale(.75)' }} />,
          name: orderDetails?.assignedTo,
          details: 'Asignado: '
        };
      case 'nudos':
        return {
          icon: <IconReserveBox />,
          name: 'Bodegas Nudos'
        };
      case 'office':
        return {
          icon: <IconBuilding style={{ transform: 'scale(0.8)' }} />,
          name: `Oficinas ${orderDetails?.destination?.country?.name}`,
          details: orderDetails?.destination?.address
        };
      case 'warranty':
        return {
          icon: <IconShield style={{ transform: 'scale(1.13)' }} />,
          name: 'Garantia con la marca'
        };
      case 'diagnostic':
        return {
          icon: <IconToolWrench style={{ transform: 'scale(1.13)' }} />,
          name: 'Diagnóstico'
        };
      default:
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />
        };
    }
  };

  const getRedirectToEmployeeDetails = (userId?: string | number) => {
    if (!userId) return {};
    return {
      pathname: `/nodi/employees/details/${userId}`,
      state: {
        originUrl: `/nodi/details/${orderDetails?.productId}`,
        returnText: 'Herramienta'
      }
    };
  };

  const redirectToDestinationEmployee = getRedirectToEmployeeDetails(orderDetails?.userId || undefined);

  return (
    <div id="activeOrderCard">
      <div className="titleSL">Asignado / ubicado:</div>
      <div className="nameSL">
        <div className="title">Estado de la orden</div>
        <div className="dinamicContainer">
          <div className="blackText">{orderDetails?.shipmentStatusName}</div>
          <div className="spaceGray">&nbsp;-&nbsp;</div>
          <div
            className="blueText"
            onClick={() => {
              push(`/nodi/orders/details/${orderDetails?.orderId}?from=tool-details&id=${orderDetails?.productId}`);
            }}
          >
            Ver
          </div>
        </div>
      </div>
      <div className="containerActiveLogisticsServiceCard">
        <div className="boxActiveLogisticsServiceCard">
          <div className="originContainerIcon">
            <div className="originIcon">
              <IconNudosFace fill="#383838" style={{ transform: 'scale(2)' }} />
              <div className="absoluteMinFlag" style={{ backgroundImage: `url("${orderDetails?.country?.flag}")` }} />
            </div>
          </div>

          <div className="originContainerPlace">
            <div className="generalPlace">
              <div className="titleContainer">
                <div className="originTitle">Tienda Nudos</div>
              </div>
              <div className="movementDates">
                <span className="dateTitle">Adquirido: </span>
                <span className="date">{formatShortDate(orderDetails?.acquisitionDate || '').onlyDate}</span>
              </div>
            </div>
          </div>
          <div className="arrowContainer">
            <IconArrow style={{ transform: 'scale(1.46)' }} />
          </div>
          <div className="destinationContainerIcon">
            <div className="destinationIcon">
              {orderDetails?.place === 'user' && generateDestinationData(orderDetails?.place).profileImg ? (
                <div
                  className="photoStyle"
                  style={{
                    backgroundImage: `url("${generateDestinationData(orderDetails?.place)?.profileImg?.replace(
                      /\s/g,
                      '%20'
                    )}")`
                  }}
                />
              ) : (
                <>{generateDestinationData(orderDetails?.place || '')?.icon}</>
              )}
              <div
                className="absoluteMinFlag"
                style={{ backgroundImage: `url("${lastAssociatedLocation?.country.flag}")` }}
              />
            </div>
          </div>
          <div className="destinationContainerPlace">
            <div className="generalPlace">
              <div className="titleContainer">
                <div className="originTitle">{generateDestinationData(orderDetails?.place || '').name}</div>
                {orderDetails?.place === 'user' && (
                  <NudosRedirectionIcon redirectionDataObject={redirectToDestinationEmployee} />
                )}
              </div>
              {generateDestinationData(orderDetails?.place || '').details && (
                <div className="originSubtitle">{`${
                  generateDestinationData(orderDetails?.place || '').details
                } ${lastLocationEntryDate}`}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveOrderCard;
