import { useEffect, useState } from 'react';
import { NudosModalSelectBetweenTwoOptions } from '../../../../../../components/DesignSystem';
import { TfunctionOneToVoid } from '../../../../../../types/global';
import { IconAccountGear, IconTruck } from '../../../../../../assets/DesignSystem/SVGComponents';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { useTranslation } from 'react-i18next';
import './ModalPredefineLogistics.scss';

const ModalPredefineLogistics = ({
  title,
  subtitle,
  isAssignment = false,
  isButtonLoading = false,
  submitModalCallback,
  closeModalCallback,
  updateLogisticsPredefined,
  navigationOrigin
}: {
  title: string;
  subtitle: string;
  isAssignment?: boolean;
  isButtonLoading?: boolean;
  closeModalCallback: TfunctionOneToVoid;
  submitModalCallback: () => void;
  updateLogisticsPredefined: (newValue: boolean) => void;
  navigationOrigin?: string;
}) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:assignToolsSelectedOptionsModal:';
  const optionOneTitle = t(`${translationKey}options:optionOneTitle`);
  const optionTwoTitle = t(`${translationKey}options:optionTwoTitle`);
  const optionOneSubtitle = t(`${translationKey}options:optionOneSubtitle`);
  const optionTwoSubtitle = t(`${translationKey}options:optionTwoSubtitle`);

  const [requireLogistics, setRequireLogistics] = useState<boolean>();
  const hasntSelectAnyOption = requireLogistics === undefined;

  const optionOneDescription = () => {
    return (
      <>
        {isAssignment && (
          <div className="logisticsOptionDescription">
            <div className="description">{optionOneSubtitle}</div>
          </div>
        )}

        {!isAssignment && (
          <div className="logisticsOptionDescription">
            <div className="description">Nosotros nos encargaremos de recoger el producto y llevarlo al destino</div>
            <em className="clarification">*Aplica también para gestión de garantías y diagnósticos</em>
          </div>
        )}
      </>
    );
  };
  const getOptionTwoDescription = () => {
    return (
      <>
        {isAssignment && <>{optionTwoSubtitle}</>}
        {!isAssignment && <>Indica dónde quieres visualizar la herramienta.</>}
      </>
    );
  };

  const optionsCards = [
    {
      title: <>{optionOneTitle}</>,
      description: optionOneDescription(),
      icon: <IconTruck className="iconTruck" />,
      isSelected: requireLogistics === true,
      action: () => setRequireLogistics(true),
      isRecommended: true,
      cardStyles: {}
    },
    {
      title: <>{optionTwoTitle}</>,
      description: getOptionTwoDescription(),
      icon: <IconAccountGear />,
      isSelected: requireLogistics === false,
      action: () => setRequireLogistics(false),
      isRecommended: false,
      cardStyles: {}
    }
  ];

  useEffect(() => {
    if (requireLogistics !== undefined) updateLogisticsPredefined(requireLogistics);
  }, [requireLogistics]);

  const segmentEvents = () => {
    if (!isAssignment) {
      segmentTrackEvent({
        nodiLogisticServices0ChooseView: {
          ScreenName: navigationOrigin === 'inventory' ? 'Inventory listing' : 'Device internal Screen'
        }
      });
    }
  };

  useEffect(() => {
    segmentEvents();
  }, []);

  return (
    <div className="modalPredefineLogistics">
      <NudosModalSelectBetweenTwoOptions
        title={title}
        subtitle={subtitle}
        optionsCards={optionsCards}
        closeModalCallback={closeModalCallback}
        isButtonDisabled={hasntSelectAnyOption}
        isButtonLoading={isButtonLoading}
        submitModalCallback={submitModalCallback}
      />
    </div>
  );
};

export default ModalPredefineLogistics;
