import { FC, useEffect, useState } from 'react';
import { NudosAddressSearchBar, NudosButton, NudosTextInput } from '../../../../../components/NudosComponents';
import { ILocationAddress, ILocationCountryAddress } from '../../../../../types/account';
import { ICountryDetail } from '../../../../../types/countries';
import { IaddressFromGoogleMaps } from '../../../../../types/global';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import { useTranslation } from 'react-i18next';
import { getAddressErrorText } from '../../../../../utils/formValidations';
import './modalEditAddress.scss';

interface IModalEditAddress {
  addressData?: ILocationAddress;
  addNewCountry: (data: ILocationCountryAddress) => void;
  loading?: boolean;
  addressCountry?: ICountryDetail;
}

const ModalEditAddress: FC<IModalEditAddress> = ({
  addressData,
  addNewCountry,
  loading,
  addressCountry
}: IModalEditAddress) => {
  const { t } = useTranslation();
  const { orgData } = useEcommerceControler();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editAddress, setEditAddress] = useState<IaddressFromGoogleMaps>();
  const [extraText, setExtraText] = useState<string>('');
  const i18nLanguageKey = 'nodi:account:organizationAddressesTab:modalEditAddress:';

  const defaultValues = () => {
    if (addressData?.additionalReferences) {
      setExtraText(addressData?.additionalReferences || '');
    }
  };

  const standardAddressErrorText = getAddressErrorText(
    addressCountry?.code,
    addressCountry?.name,
    editAddress?.country || undefined,
    true,
    editAddress?.coordinates || addressData?.coordinates
  );

  const showAlertSearchBar = () => {
    if (standardAddressErrorText) return standardAddressErrorText;
    if (
      addressCountry &&
      editAddress &&
      editAddress?.country &&
      addressCountry?.code &&
      addressCountry?.code !== editAddress.country
    ) {
      return `Esta dirección no pertenece ${addressCountry?.name || 'este país'}`;
    }
    return undefined;
  };

  const handleEditAddress = () => {
    if (!addressCountry?.id) return;
    const body = {
      address: editAddress?.address || addressData?.address || '',
      coordinates: editAddress?.coordinates || undefined,
      additionalReferences: extraText || '',
      city: editAddress?.city || addressData?.city || '',
      cityId: addressData?.cityId,
      countryId: addressCountry.id
    };
    if (addNewCountry) {
      addNewCountry(body);
    }
  };

  useEffect(() => {
    defaultValues();
  }, [addressData]);

  return (
    <div id="modalEditAddress">
      <div className="boxModalEditAddres">
        <div className="boxContainerEditModal">
          <div className="orangeEditAddress">{t(`${i18nLanguageKey}orangeEditAddress`)}</div>
          <div className="titleEditAddress">
            <div className="flag">{addressCountry && <img src={addressCountry?.flag || undefined} />}</div>
            <div className="contryText">{orgData.organizationName}</div>
          </div>
          <div className="addressInputContainer">
            <NudosAddressSearchBar
              handleAddressSelection={e => {
                setEditAddress(e);
                setIsEdit(true);
              }}
              label={t(`${i18nLanguageKey}address`)}
              defaultValueAddressName={addressData?.address || ''}
              errorText={showAlertSearchBar()}
              regionBias={addressCountry?.code || undefined}
              didntFindAddressTooltipTopPosition={-4}
              didntFindAddressTooltipRightPosition={0}
            />
          </div>
          <div className="extraTextContainer">
            <NudosTextInput
              isFilled={!!extraText}
              label={t(`${i18nLanguageKey}directions:label`)}
              placeholder={t(`${i18nLanguageKey}directions:placeholder`)}
              defaultValue={extraText}
              handleChange={e => {
                setExtraText(e);
                setIsEdit(true);
              }}
            />
          </div>
          <div className="containerMyButton">
            <NudosButton
              buttonText={t(`${i18nLanguageKey}nudosButton`)}
              isButtonLoading={loading}
              isButtonDisabled={!isEdit || !!showAlertSearchBar() || loading || !extraText}
              handleClick={() => {
                handleEditAddress();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditAddress;
