import { FC } from 'react';
import './ContainerOnboardingItems.scss';

interface IContainerOnboardingItems {
  children: React.ReactNode;
  bgTest?: boolean;
}

const ContainerOnboardingItems: FC<IContainerOnboardingItems> = ({ bgTest, children }: IContainerOnboardingItems) => {
  return (
    <div id="containerOnboardingItems" style={{ backgroundColor: bgTest ? 'rgba(0, 0, 0, 0.6)' : '' }}>
      {children}
    </div>
  );
};

export default ContainerOnboardingItems;
