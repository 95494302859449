import './LoaderProductDetails.scss';

const LoaderProductDetails = () => {
  return (
    <div id="loaderProductDetails">
      <div className="rightItems">
        <div className="mb-6 animationLoader rounded-sm" style={{ height: '13px', width: '46px' }} />
        <div className="w-56 mb-1 animationLoader rounded-sm" style={{ height: '22px' }} />
        <div className="h-5 w-full flex justify-start gap-1 mb-4">
          <div className="w-11 h-full rounded-md animationLoader" />
          <div className="w-7 h-full rounded-md animationLoader" />
          <div className="w-8 h-full rounded-md animationLoader" />
          <div className="w-10 h-full rounded-md animationLoader" />
        </div>
        <div className="h-5 w-full flex justify-start gap-1 mb-4">
          <div className="w-11 h-full rounded-md animationLoader" />
          <div className="w-14 h-full rounded-md animationLoader" />
          <div className="w-9 h-full rounded-md animationLoader" />
          <div className="w-10 h-full rounded-md animationLoader" />
        </div>
        <div className="mb-4 animationLoader rounded-sm" style={{ height: '72.5px', width: '376px' }} />
        <div className="mb-4 animationLoader rounded-sm" style={{ width: '376px', height: '12px' }} />
        <div className="mb-4 animationLoader rounded-sm" style={{ width: '380px', height: '62px' }} />
        <div className="mb-4 animationLoader rounded-lg" style={{ width: '380px', height: '45px' }} />
        <div className="mb-4" style={{ width: '380px', borderBottom: ' 1px solid #D6D6D6' }} />
        <div className="animationLoader rounded-lg" style={{ width: '380px', height: '120px' }} />
      </div>
      <div className="leftItems">
        <div className="boxImgesLoaderContainer">
          <div className="containerImgsLoader">
            <div className="bigImgLoader animationLoader" />
            <div className="spaceLoader" />
            <div className="minImgListLoaderContainer">
              <div className="itemLoader animationLoader" />
              <div className="itemLoader animationLoader" />
              <div className="itemLoader animationLoader" style={{ marginBottom: '0px' }} />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end">
          <div className="totalsContainerLoader">
            <div className="buttonContainerLoader animationLoader" />
            <div className="totalsInfoLoader animationLoader" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderProductDetails;
