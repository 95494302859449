import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      className="path1"
      d="M.823 8.802a2.684 2.684 0 0 0 2.36 2.365c1.895.212 3.739.212 5.635 0a2.684 2.684 0 0 0 2.36-2.365c.097-.912.18-1.848.18-2.802s-.083-1.89-.18-2.802A2.684 2.684 0 0 0 8.817.833a25.127 25.127 0 0 0-5.636 0 2.684 2.684 0 0 0-2.36 2.365C.726 4.11.643 5.046.643 6s.083 1.89.18 2.802Z"
      fill="#ff664e"
      stroke="#ff664e"
    />
    <path className="path2" d="m7.515 4.485-3.03 3.03M7.515 7.515l-3.03-3.03" stroke="#fff" strokeLinecap="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const FilterXMark = memo(ForwardRef);
export default FilterXMark;
