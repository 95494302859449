import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_2125_15568)">
      <path
        d="M5.50002 10.8044C7.9853 10.8044 10 8.78974 10 6.30446C10 3.81917 7.9853 1.80444 5.50002 1.80444C3.01472 1.80444 1 3.81917 1 6.30446C1 8.78974 3.01472 10.8044 5.50002 10.8044Z"
        fill="white"
        stroke="#CCCCCC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.3572 12.1616L8.35718 10.1616" stroke="#CCCCCC" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2125_15568">
        <rect width="12" height="12" fill="white" transform="translate(0 0.804443)" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MinMagnifier = memo(ForwardRef);
export default MinMagnifier;
