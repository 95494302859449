import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_3881_92154)">
      <path
        d="M11.1426 5.99972C11.1426 3.1594 8.84011 0.856864 5.99979 0.856864C3.15947 0.856864 0.856934 3.1594 0.856934 5.99972C0.856934 8.84004 3.15947 11.1426 5.99979 11.1426C8.84011 11.1426 11.1426 8.84004 11.1426 5.99972Z"
        fill="#D6E9FF"
        stroke="#383838"
      />
      <path d="M3.42871 6H8.59805" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.83057 7.76685C7.60593 7.13159 7.99336 6.74424 8.59781 5.99964C7.99336 5.25504 7.60593 4.86768 6.83057 4.23242"
        stroke="#383838"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3881_92154">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const OtherButtonIcon = memo(ForwardRef);
export default OtherButtonIcon;
