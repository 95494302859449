import create from 'zustand';

export interface iState {
  isOpenPurpleAlert?: boolean;
  setOpenPurpleAlert: (email: boolean) => void;
  idNotification?: number;
  setNotificactionId: (id: number) => void;
}

const useStatusAlert = create<iState>(set => ({
  isOpenPurpleAlert: false,
  setOpenPurpleAlert: isOpenPurpleAlert => set({ isOpenPurpleAlert }),
  idNotification: 0,
  setNotificactionId: idNotification => set({ idNotification })
}));

export default useStatusAlert;
