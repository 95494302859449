import { useEffect, useState } from 'react';
import { ModalUpdateShipmentInfoForm } from '..';
import { NudosGenericModal } from '../../../../../components/NudosComponents';
import { Iemployee } from '../../../../../types/global';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import { getEmployeeById } from '../../../../../services/employees.service';
import { TshipmentDestinationData, IshipmentDetails } from '../../../../../types/orders';
import { GenericLoader } from '../../../dashboard/components';
import { updateShipmentInformation } from '../../../../../services/orders';
import { getOrgData } from '../../../../../utils/getLocalStorageData';
import { ICountryDetail } from '../../../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../../../utils/getCountries';
import { DTOupdateEmployeeData } from '../../../../../types/DTO';
import { IorganizationOfficeData } from '../../../../../types/account';
import { getOfficeByCountry } from '../../../../../services/account';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../../utils/segment';

const ModalFluxUpdateShipmentInfo = ({
  closeModalCallback,
  userId,
  shipmentExistingData,
  updateOrderDataFunction,
  shipmentInfoEditingIsAllowed
}: {
  closeModalCallback: () => void;
  userId?: number;
  shipmentExistingData: IshipmentDetails;
  updateOrderDataFunction: () => void;
  shipmentInfoEditingIsAllowed: boolean;
}) => {
  const { t } = useTranslation();
  const [employeeExistingData, setEmployeeExistingData] = useState<Iemployee>();
  const [loadingEmployeeExistingData, setLoadingEmployeeExistingData] = useState(false);
  const [officeAddress, setOfficeAddress] = useState<IorganizationOfficeData>();
  const [loadingOfficeAddress, setLoadingOfficeAddress] = useState(false);
  const [isStep2ButtonLoading, setIsStep2ButtonLoading] = useState(false);
  const [listOfCountries, setListOfCountries] = useState<ICountryDetail[]>();
  const { shipmentId, receiverName, receiverPhone, receiverIdDocument, destinationAddress } = shipmentExistingData;

  const orgData = getOrgData();
  const shipmentCountryData = listOfCountries?.find(country => country?.code === shipmentExistingData.country);
  const doesntHaveInitialData =
    (!employeeExistingData && !officeAddress) || loadingEmployeeExistingData || loadingOfficeAddress;

  const getEmployeeExistingData = async () => {
    if (!userId) return;
    setLoadingEmployeeExistingData(true);
    try {
      const employeeData = await getEmployeeById(userId);
      setEmployeeExistingData(employeeData);
      setLoadingEmployeeExistingData(false);
    } catch {
      displayErrorNotification({ customJSXMessage: <>Error Obteniendo los datos del empleado</> });
      setLoadingEmployeeExistingData(false);
    }
  };

  const getThisCountryOfficeData = async () => {
    if (!orgData?.organizationId || !shipmentCountryData?.id || userId) return;
    setLoadingOfficeAddress(true);
    try {
      const thisCountryOfficeData = await getOfficeByCountry(orgData?.organizationId, shipmentCountryData?.id);
      const officeAddressExists =
        typeof thisCountryOfficeData.office === 'object' && Object.keys(thisCountryOfficeData.office).length > 0;
      const officeDataIncludingShipmentInfo = {
        ...(officeAddressExists ? thisCountryOfficeData?.office : {}),
        zipCode: destinationAddress?.zipCode || thisCountryOfficeData?.office?.zipCode,
        additionalReferences:
          destinationAddress?.additionalReferences || thisCountryOfficeData?.office?.additionalReferences,
        address: destinationAddress?.address || thisCountryOfficeData?.office?.address,
        coordinates: destinationAddress?.coordinates || thisCountryOfficeData?.office?.coordinates,
        city: destinationAddress?.city || thisCountryOfficeData?.office?.city,
        shipmentInfo: {
          receiverName: receiverName,
          receiverPhone: receiverPhone,
          receiverIdDocument: receiverIdDocument
        }
      };
      if (officeAddressExists) setOfficeAddress(officeDataIncludingShipmentInfo);
      setLoadingOfficeAddress(false);
    } catch (error) {
      displayErrorNotification();
      setLoadingOfficeAddress(false);
    }
  };

  const handleSubmitModal = async (modalData: TshipmentDestinationData) => {
    if (!orgData?.organizationId || !shipmentId || !modalData) return;
    setIsStep2ButtonLoading(true);
    const userInfo = userId ? (modalData as DTOupdateEmployeeData) : undefined;
    const officeInfo = !userId ? ({ ...modalData, shipmentInfo: undefined } as IorganizationOfficeData) : undefined;
    const officeReceiverInfo = !userId ? (modalData as IorganizationOfficeData)?.shipmentInfo : undefined;
    const body =
      shipmentExistingData?.statusName === 'Pendiente de confirmación' ||
      shipmentExistingData?.statusName === 'Confirmado'
        ? { userInfo, officeInfo, officeReceiverInfo, onlyOrder: false, platform: 'NODI' }
        : { userInfo, officeInfo, officeReceiverInfo, onlyOrder: false };
    const orgId = userId ? undefined : orgData.organizationId;
    try {
      await updateShipmentInformation(shipmentId, body, orgId, userId);
      displaySuccessNotification({
        customJSXMessage: (
          <>{t('nodi:orderDetails:alerts:successfullyUpdated')} La información se actualizó correctamente.</>
        )
      });
      setIsStep2ButtonLoading(false);
      updateOrderDataFunction();
      closeModalCallback();
    } catch (error) {
      displayErrorNotification();
      setIsStep2ButtonLoading(false);
    }
  };
  const fluxSteps = (
    <ModalUpdateShipmentInfoForm
      employeeData={employeeExistingData}
      officeData={officeAddress}
      closeModalCallback={closeModalCallback}
      advanceCallback={handleSubmitModal}
      destinationIsEmployee={!!userId}
      shipmentExistingData={shipmentExistingData}
      listOfCountries={listOfCountries || []}
      activateAlternativeModals
      shipmentInfoEditingIsAllowed={shipmentInfoEditingIsAllowed}
      loadingUpdate={isStep2ButtonLoading}
    />
  );

  const segmentEvents = () => {
    if (shipmentCountryData) {
      if (shipmentInfoEditingIsAllowed) {
        segmentTrackEvent({
          nodiOrderEditModalAvailableView: {
            ModalType: userId ? 'Employee' : 'Office'
          }
        });
      } else {
        segmentTrackEvent({
          nodiOrderEditModalNotAvailableView: {
            ModalType: userId ? 'Employee' : 'Office'
          }
        });
      }
    }
  };

  useEffect(() => {
    if (!userId || employeeExistingData) return;
    getEmployeeExistingData();
  }, [userId]);

  useEffect(() => {
    getThisCountryOfficeData();
    segmentEvents();
  }, [shipmentCountryData]);

  useEffect(() => {
    getListOfCountriesByOfferedService(setListOfCountries);
  }, []);

  return (
    <div className="modalFluxUpdateShipmentInfo">
      <NudosGenericModal
        modalContent={doesntHaveInitialData ? <GenericLoader /> : fluxSteps}
        closeModalCallback={closeModalCallback}
      />
    </div>
  );
};

export default ModalFluxUpdateShipmentInfo;
