import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { FAQCard } from './Components';
import { ICountryDetail } from '../../../types/countries';
import { getCountryName, getListOfCountriesByOfferedService } from '../../../utils/getCountries';

import './FrequentlyAskedQuestions.scss';

const FrequentlyAskedQuestions = () => {
  const { t } = useTranslation();
  const [countriesOpenForSale, setCountriesOpenForSale] = useState<ICountryDetail[]>();
  const [countriesOpenForStorage, setCountriesOpenForStorage] = useState<ICountryDetail[]>();
  const [countriesOpenForLogistics, setCountriesOpenForLogistics] = useState<ICountryDetail[]>();
  const i18nLanguageKey = 'nodi:frequentlyAskedQuestions:';

  const saleCountriesText = countriesOpenForSale
    ? countriesOpenForSale.map(country => getCountryName(country)).join(',  ')
    : 'Cargando los datos ...';
  const storageCountriesText = countriesOpenForStorage
    ? countriesOpenForStorage.map(country => getCountryName(country)).join(',  ')
    : 'Cargando los datos ...';
  const logisticsCountriesText = countriesOpenForLogistics
    ? countriesOpenForLogistics.map(country => getCountryName(country)).join(',  ')
    : 'Cargando los datos ...';

  const getHeightForCountries = (countriesText: string) => {
    const estimatedTextLines = Math.ceil(countriesText.length / 90);
    const lineHeightInPixels = 12;
    return estimatedTextLines * lineHeightInPixels;
  };

  const faqs = [
    {
      question: <>{t(`${i18nLanguageKey}question0:question`)}</>,
      answer: (
        <div>
          <div className="timeByCountryText">{t(`${i18nLanguageKey}question0:answer:timeByCountryText`)}</div>
          <ul className="timeByCountryList">
            <Trans
              i18nKey={t(`${i18nLanguageKey}question0:answer:timeByCountryList`)}
              components={{ 1: <li></li>, 2: <span className="numberOfDays"></span> }}
            />
          </ul>
          <div className="blueNotes">{t(`${i18nLanguageKey}question0:answer:blueNotes`)}</div>
        </div>
      ),
      expandedHeight: 140
    },
    {
      question: <>{t(`${i18nLanguageKey}question1:question`)}</>,
      answer: (
        <div>
          <div>{saleCountriesText}.</div>
          <div className="blueNotes">
            <Trans i18nKey={t(`${i18nLanguageKey}question1:answer:blueNotes`)} components={{ 1: <div></div> }} />
          </div>
        </div>
      ),
      expandedHeight: 44 + getHeightForCountries(saleCountriesText)
    },
    {
      question: <>{t(`${i18nLanguageKey}question2:question`)}</>,
      answer: (
        <div>
          <div>{t(`${i18nLanguageKey}question2:answer:timeByCountryText`)}</div>
          <ul className="timeByCountryList">
            <Trans
              i18nKey={t(`${i18nLanguageKey}question2:answer:timeByCountryList`)}
              components={{ 1: <li></li>, 2: <span className="numberOfDays"></span> }}
            />
          </ul>
          <div className="blueNotes">{t(`${i18nLanguageKey}question2:answer:blueNotes`)}</div>
        </div>
      ),
      expandedHeight: 152
    },
    {
      question: <>{t(`${i18nLanguageKey}question3:question`)}</>,
      answer: <div>{logisticsCountriesText}.</div>,
      expandedHeight: 10 + getHeightForCountries(logisticsCountriesText)
    },
    {
      question: <>{t(`${i18nLanguageKey}question4:question`)}</>,
      answer: <div>{storageCountriesText}.</div>,
      expandedHeight: 10 + getHeightForCountries(storageCountriesText)
    }
  ];

  useEffect(() => {
    getListOfCountriesByOfferedService(setCountriesOpenForSale, 'buy');
    getListOfCountriesByOfferedService(setCountriesOpenForStorage, 'storage');
    getListOfCountriesByOfferedService(setCountriesOpenForLogistics, 'logisticService');
  }, []);

  return (
    <div className="frequentlyAskedQuestions">
      <h1>{t(`${i18nLanguageKey}title`)}</h1>
      <div className="faqCardsContainer">
        {faqs.map((faq, i) => {
          return (
            <FAQCard key={`faq-${i}`} question={faq.question} answer={faq.answer} expandedHeight={faq.expandedHeight} />
          );
        })}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
