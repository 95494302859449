import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M9.74991 4.67462C9.75437 4.04537 9.58848 3.42642 9.2695 2.88204C8.95052 2.33765 8.4901 1.88768 7.93606 1.57883C7.382 1.26998 6.7545 1.1135 6.11871 1.12566C5.48291 1.13781 4.86199 1.31815 4.32041 1.64795C3.77883 1.97775 3.33633 2.44498 3.03885 3.00114C2.74137 3.55732 2.59975 4.18214 2.62869 4.81076C2.65764 5.43937 2.8561 6.04886 3.20346 6.57596C3.82376 7.51719 4.19101 7.86319 4.19101 8.98332C4.19101 9.08715 4.23271 9.1868 4.30694 9.2603C4.38118 9.33371 4.48187 9.375 4.58685 9.375H7.78802C7.893 9.375 7.99369 9.33371 8.06792 9.2603C8.14215 9.1868 8.18386 9.08715 8.18386 8.98332C8.18386 7.83619 8.59469 7.49567 9.21084 6.52005C9.56047 5.96649 9.74719 5.3274 9.74991 4.67462Z"
      fill={props?.fill || '#FAFAFA'}
      stroke={props?.stroke || '#81BC3F'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.875 10.875H7.125" stroke={props?.stroke || '#81BC3F'} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Bulb = memo(ForwardRef);
export default Bulb;
