import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M7.0419 11.1577L7.23213 10.5156H6.5625H2.8125C2.6248 10.5156 2.375 10.3241 2.375 9.96094V2.92969C2.375 2.56656 2.6248 2.375 2.8125 2.375H12.1875C12.3752 2.375 12.625 2.56656 12.625 2.92969V9.96094C12.625 10.3241 12.3752 10.5156 12.1875 10.5156H8.4375H7.76787L7.9581 11.1577L8.2706 12.2124L8.37666 12.5703H8.75H10.1208C10.1231 12.5769 10.125 12.586 10.125 12.5977C10.125 12.6094 10.1231 12.6184 10.1208 12.625H4.87946C4.87705 12.6184 4.875 12.6093 4.875 12.5977C4.875 12.586 4.87705 12.5769 4.87946 12.5703H6.25H6.62334L6.7294 12.2124L7.0419 11.1577Z"
      stroke={props?.stroke || '#ECECEC'}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuMDMIcon = memo(ForwardRef);
export default MenuMDMIcon;
