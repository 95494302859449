import { SignUpForm } from './components';
import urlParameter from '../../utils/urlParameter';
import { useEffect } from 'react';
import useOtpController from '../../components/Otp/otp.controller';
import SignUpLeftAside from './components/SignUpLeftAside';
import usePlatform from '../../hooks/usePlatform';

const SignUp = () => {
  const token = urlParameter('apiKey');
  const mode = urlParameter('mode');
  const { verifyLink } = useOtpController();
  const { isMobile } = usePlatform();

  const starAutoLogin = async () => {
    if (token && mode && mode === 'signIn') {
      verifyLink();
    }
  };

  useEffect(() => {
    starAutoLogin();
  }, []);

  return (
    <div id="sign-up">
      {token && mode && mode === 'signIn' ? (
        <div id="containerLoading">
          <div className="boxLoading" />
        </div>
      ) : (
        <>
          {!isMobile && <SignUpLeftAside />}
          <SignUpForm />
        </>
      )}
    </div>
  );
};

export default SignUp;
