import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg className="lightbulb" width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_1034_71101)">
      <path
        d="M0.702026 8.91211V9.72717C0.702026 10.1595 0.873772 10.5742 1.17949 10.8799C1.48521 11.1856 1.89984 11.3574 2.33218 11.3574H9.66794C10.1003 11.3574 10.5149 11.1856 10.8206 10.8799C11.1263 10.5742 11.2981 10.1595 11.2981 9.72717V8.91211"
        stroke="#383838"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.16821 5.24121C3.72359 6.26335 4.55077 7.19484 5.61638 7.83908C5.85238 7.98176 6.14756 7.98175 6.38354 7.83906C7.44911 7.19477 8.27628 6.26333 8.83167 5.24121"
        stroke="#383838"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 7.94643V0.857422" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1034_71101">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const BlackArrowDownload = memo(ForwardRef);
export default BlackArrowDownload;
