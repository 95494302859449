import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} fill="none" ref={ref} {...props}>
    <g stroke="#383838" strokeLinecap="round" strokeLinejoin="round">
      <path className="circle" fill="#fff" d="M5 9.464A4.464 4.464 0 1 0 5 .535a4.464 4.464 0 0 0 0 8.929Z" />
      <path
        className="questionMark"
        d="M5 5.325c0-.258.21-.416.58-.663.344-.23.525-.527.444-.932a1.063 1.063 0 0 0-.82-.82 1.054 1.054 0 0 0-1.247 1.024M5 7.11v-.179"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconTooltipQuestionMark = memo(ForwardRef);
export default IconTooltipQuestionMark;
