import { NudosGenericModal, NudosModalContent } from '../../../../../../components/NudosComponents';
import SelectLogisticServiceBillingCountryModalContent from './SelectLogisticServiceBillingCountryModalContent';
import { ICountryDetail } from '../../../../../../types/countries';
import './SelectLogisticServiceBillingCountryModal.scss';

const SelectLogisticServiceBillingCountryModal = ({
  closeModalCallback,
  listOfCountries
}: {
  closeModalCallback: () => void;
  listOfCountries: ICountryDetail[];
}) => {
  return (
    <div className="selectLogisticServiceBillingCountryModal">
      <NudosGenericModal
        closeModalCallback={closeModalCallback}
        modalContent={
          <NudosModalContent
            hideNudosIcon
            excludeHeader
            customWidth="580px"
            customHeight="438px"
            toCloseModal={closeModalCallback}
            CustomModalComponent={
              <SelectLogisticServiceBillingCountryModalContent
                closeModalCallback={closeModalCallback}
                listOfCountries={listOfCountries}
              />
            }
          />
        }
      />
    </div>
  );
};

export default SelectLogisticServiceBillingCountryModal;
