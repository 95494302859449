import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  NudosBreadcrumbButton,
  NudosButton,
  NudosSelectDropdown,
  NudosTextInput
} from '../../../../../../components/NudosComponents';
import { isProduction } from '../../../../../../constants';
import usePlatform from '../../../../../../hooks/usePlatform';
import { sendRegisterFormFirstStep, updateRegisterFormData } from '../../../../../../services/signUp.service';
import { ICountryDetail } from '../../../../../../types/countries';
import {
  DTOOrganizationDocumentAlreadyExistResponse,
  IformStep1Fields,
  IformStep1FieldsError
} from '../../../../../../types/SignUp';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import useSignUpFormStep1Controller from './SignUpFormStep1.controller';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import { useTranslation } from 'react-i18next';
import { NudosCountryDropdown } from '../../../../../../components/DesignSystem';
import './SignUpFormStep1.scss';

const SignUpFormStep1 = ({
  setFormStep,
  setExistingRFCError,
  setOpenInviteCollaboratorModal,
  setExistingOrganizationData,
  existingRFCError,
  setBusinessNameAlreadyRegisteredError,
  businessNameAlreadyRegisteredError,
  countriesOpenForRegistration
}: IsignUpFormStep1Params) => {
  const { t } = useTranslation();
  const {
    formInputsStep1,
    formSchemaStep1,
    selectedNumberOfEmployees,
    setSelectedNumberOfEmployees,
    selectedCountry,
    setSelectedCountry
  } = useSignUpFormStep1Controller(countriesOpenForRegistration);
  const stringNewRegisterData = sessionStorage.getItem('newRegisterInformation');
  const newRegisterData = !!stringNewRegisterData && JSON.parse(stringNewRegisterData);
  const { isMobile } = usePlatform();
  const numberOfEmployeesPreviouslySelected =
    newRegisterData && TnumberOfEmployeesEnum[+newRegisterData.numberOfEmployees];

  const [errors, setErrors] = useState<IformStep1FieldsError>();
  const [submittedData, setSubmittedData] = useState<IformStep1Fields>();
  const [loading, setloading] = useState(false);
  const i18nLanguageKey = 'authentication:SignUpForm:useSignUpFormStep1Controller:';

  const { register, handleSubmit, watch, setValue, reset } = useForm<IformStep1Fields>({
    shouldFocusError: true,
    resolver: yupResolver(formSchemaStep1),
    defaultValues: {
      organizationName: newRegisterData ? newRegisterData.organizationName : '',
      organizationBusinessName: newRegisterData ? newRegisterData.organizationBusinessName : '',
      rfc: newRegisterData ? newRegisterData.rfc : '',
      numberOfEmployees: numberOfEmployeesPreviouslySelected ? numberOfEmployeesPreviouslySelected : '',
      countryId: newRegisterData?.countryId || undefined
    }
  });
  const currentData = watch();

  const countryInputField = formInputsStep1[2];
  const rfcInputField = formInputsStep1[3];
  const numberOfEmployeesInputField = formInputsStep1[4];

  const nudosPage = isProduction ? 'https://www.nudos.co' : 'https://landing.beta.nudos.co';

  const handleSuccessfullSubmit = async (data: IformStep1Fields) => {
    segmentTrackEvent({
      signupFirstStepClick: {
        CountryName: selectedCountry?.name || '',
        EmployeeRange: TnumberOfEmployeesEnum[data?.numberOfEmployees as keyof typeof TnumberOfEmployeesEnum]
      }
    });

    setSubmittedData(data);
    setloading(true);
    const body = {
      ...data,
      numberOfEmployees: TnumberOfEmployeesEnum[data.numberOfEmployees as keyof typeof TnumberOfEmployeesEnum]
    };
    try {
      const response =
        newRegisterData && newRegisterData.id && !newRegisterData?.organizationDocumentExist
          ? await updateRegisterFormData(body, newRegisterData.id)
          : await sendRegisterFormFirstStep(body);
      if (response) {
        if (response.exist) {
          setExistingOrganizationData({
            ...response,
            countryId: data?.countryId,
            countryName: selectedCountry?.name || undefined
          });
          reset();
          setSelectedNumberOfEmployees(undefined);
          setOpenInviteCollaboratorModal(true);
        } else {
          const newRegisterInformation = { ...response, countryId: data?.countryId };
          const newRegisterInformationString = JSON.stringify(newRegisterInformation);
          sessionStorage.setItem('newRegisterInformation', newRegisterInformationString);
          setFormStep(1);
        }
      } else {
        const updatedRegisterInformation = { ...newRegisterData, ...body, countryId: data?.countryId };
        const stringUpdatedRegisterInformation = JSON.stringify(updatedRegisterInformation);
        sessionStorage.setItem('newRegisterInformation', stringUpdatedRegisterInformation);
        setFormStep(1);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setloading(false);
    }
  };

  const handleErrorSubmitting = (data: IformStep1FieldsError) => {
    setSubmittedData(currentData);
    setErrors(data);
  };

  const expectedTaxCodePattern = selectedCountry?.documentExample;
  const taxCodeRegEx = selectedCountry?.documentRegEx ? new RegExp(selectedCountry?.documentRegEx, 'i') : /^.{5,}$/;
  const isTaxCodeValid = !!taxCodeRegEx && taxCodeRegEx.test(currentData?.rfc);

  const getTaxCodeError = () => {
    if (!hasRFCchangedSinceSubmitted && existingRFCError) return existingRFCError;
    if (selectedCountry?.documentRegEx && expectedTaxCodePattern && currentData?.rfc.length > 0 && !isTaxCodeValid)
      return `${t(`${i18nLanguageKey}taxCodeErrorCodePattern`)} ${expectedTaxCodePattern}`;
    if (!selectedCountry?.documentRegEx && currentData?.rfc?.length > 0 && !isTaxCodeValid)
      return t(`${i18nLanguageKey}taxCodeErroLength`);
    return undefined;
  };

  const isButtonDisabled =
    !Object.values(currentData).every(value => value && (typeof value !== 'string' || value.length > 0)) ||
    !isTaxCodeValid;

  const handleClickReturntoNudos = () => (window.location.href = nudosPage);

  const hasRFCchangedSinceSubmitted = !!newRegisterData?.rfc && newRegisterData.rfc !== currentData.rfc;

  const handleTaxCodeFieldBlurr = () => {
    hasRFCchangedSinceSubmitted && setExistingRFCError(undefined);
  };

  useEffect(() => {
    selectedNumberOfEmployees && setValue('numberOfEmployees', selectedNumberOfEmployees);
  }, [selectedNumberOfEmployees]);

  useEffect(() => {
    selectedCountry?.id && setValue('countryId', selectedCountry?.id);
  }, [selectedCountry]);

  useEffect(() => {
    if (!newRegisterData?.countryId || selectedCountry) return;
    const previouslySelectedCountry = countriesOpenForRegistration.find(
      countryInList => countryInList.id === newRegisterData.countryId
    );
    previouslySelectedCountry && setSelectedCountry(previouslySelectedCountry);
  }, [newRegisterData]);

  useEffect(() => {
    segmentTrackEvent({ signupFirstStepView: null });
  }, []);

  return (
    <form
      id="step1Form"
      className="formStep formStep1 signUpFormStep1"
      onSubmit={handleSubmit(handleSuccessfullSubmit, handleErrorSubmitting)}
    >
      {formInputsStep1.map(field => {
        if (['organizationName', 'organizationBusinessName'].includes(field.id)) {
          const fieldInputHasChangedSinceSubmitted =
            !!submittedData &&
            currentData[field.id as keyof IformStep1Fields] !== submittedData[field.id as keyof IformStep1Fields];
          const errorText =
            !fieldInputHasChangedSinceSubmitted && errors
              ? errors[field.id as keyof IformStep1FieldsError]?.message
              : undefined;
          const hasBusinessNamechangedSinceSubmitted =
            !!newRegisterData?.organizationBusinessName &&
            newRegisterData.organizationBusinessName !== currentData.organizationBusinessName;
          const handleFieldBlurr = () => {
            const newErrors = { ...errors };
            newErrors[field.id as keyof IformStep1FieldsError] = undefined;
            fieldInputHasChangedSinceSubmitted && setErrors(newErrors);
            field?.id === 'rfc' && hasRFCchangedSinceSubmitted && setExistingRFCError(undefined);
            field?.id === 'organizationBusinessName' &&
              hasBusinessNamechangedSinceSubmitted &&
              setBusinessNameAlreadyRegisteredError(undefined);
          };
          return (
            <NudosTextInput
              key={`field-${field?.id}`}
              componentSize="large"
              label={field?.label}
              placeholder={field?.placeholder}
              isFilled={!!currentData[field.id as keyof IformStep1Fields]}
              register={register}
              registerId={field?.id}
              errorText={
                (!hasBusinessNamechangedSinceSubmitted &&
                  field?.id === 'organizationBusinessName' &&
                  businessNameAlreadyRegisteredError) ||
                errorText
              }
              defaultValue={newRegisterData ? newRegisterData[field.id as keyof IformStep1Fields] : undefined}
              handleBlur={handleFieldBlurr}
            />
          );
        }
      })}
      {countryInputField && rfcInputField && (
        <div className="countryAndTaxDocumentContainer">
          <NudosCountryDropdown
            charactersLimit={7}
            customPlaceholder="País"
            currentValue={selectedCountry}
            countriesList={countriesOpenForRegistration}
            updateCurrentValueCallback={setSelectedCountry}
          />
          <div className="taxCodeInputContainer">
            <NudosTextInput
              componentSize="medium"
              label={rfcInputField?.label}
              placeholder={rfcInputField?.placeholder}
              isFilled={!!currentData['rfc']}
              register={register}
              registerId={'rfc'}
              errorText={getTaxCodeError()}
              defaultValue={newRegisterData ? newRegisterData['rfc'] : undefined}
              handleBlur={handleTaxCodeFieldBlurr}
            />
          </div>
        </div>
      )}
      {numberOfEmployeesInputField?.options && (
        <NudosSelectDropdown
          componentSize="large"
          label={t(`${i18nLanguageKey}numberOfEmployees`)}
          selectOptions={numberOfEmployeesInputField.options}
          currentValueOrplaceholder={
            selectedNumberOfEmployees ||
            (newRegisterData && numberOfEmployeesPreviouslySelected
              ? numberOfEmployeesPreviouslySelected
              : numberOfEmployeesInputField?.placeholder)
          }
          isFilled={!!selectedNumberOfEmployees || !!numberOfEmployeesPreviouslySelected}
          errorText={
            errors ? errors[numberOfEmployeesInputField?.id as keyof IformStep1FieldsError]?.message : undefined
          }
        />
      )}
      <div className="buttonContainer firstStep">
        {!isMobile && (
          <NudosBreadcrumbButton
            returnText={t(`${i18nLanguageKey}backToNudosButton`)}
            returnAction={handleClickReturntoNudos}
          />
        )}
        <NudosButton
          componentSize="small"
          buttonText={t(`${i18nLanguageKey}nudosButton`)}
          isButtonDisabled={isButtonDisabled}
          isButtonLoading={loading}
          isOfTypeSubmit
          formId="step1Form"
        />
      </div>
    </form>
  );
};

export default SignUpFormStep1;

export enum TnumberOfEmployeesEnum {
  '0-10' = 1,
  '11-50',
  '51-100',
  '101-200',
  '+ 200'
}

export interface IsignUpFormStep1Params {
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  setExistingRFCError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setOpenInviteCollaboratorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setExistingOrganizationData: React.Dispatch<
    React.SetStateAction<DTOOrganizationDocumentAlreadyExistResponse | undefined>
  >;
  countriesOpenForRegistration: ICountryDetail[];
  existingRFCError?: string;
  setBusinessNameAlreadyRegisteredError: React.Dispatch<React.SetStateAction<string | undefined>>;
  businessNameAlreadyRegisteredError?: string;
}
