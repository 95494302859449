import { useEffect, useState } from 'react';
import { NudosButton, NudosTextArea } from '../../../../components/NudosComponents';
import './AdditionalServicesCommentsModal.scss';
import { useTranslation } from 'react-i18next';

const AdditionalServicesCommentsModalContent = ({
  submitModalCallback,
  currentComment
}: {
  submitModalCallback: (modalData: string) => void;
  currentComment?: string;
}) => {
  const limitOfCharacters = 50;

  const { t } = useTranslation();
  const translationKey = 'services:logistics:additionalServicesCommentsModalContent:';
  const subtitle = t(`${translationKey}subtitle`);
  const toolConfiguration = t(`${translationKey}toolConfiguration`);
  const textAreaPlaceholder = t(`${translationKey}textAreaPlaceholder`);
  const buttonText = t('recurrentWords:continue');
  const toolConfigurationCommentText = t(`${translationKey}toolConfigurationCommentText`, { value: limitOfCharacters });

  const [toolConfigurationComment, setToolConfigurationComment] = useState('');

  const updateToolConfigurationComment = (newComment: string) => setToolConfigurationComment(newComment);

  const submitModal = () => submitModalCallback(toolConfigurationComment);
  const insufficientLengthError =
    !!toolConfigurationComment && toolConfigurationComment.length < limitOfCharacters
      ? toolConfigurationCommentText
      : undefined;
  const isButtonDisabled = !toolConfigurationComment || !!insufficientLengthError;

  useEffect(() => {
    currentComment && setToolConfigurationComment(currentComment);
  }, [currentComment]);

  return (
    <div className="AdditionalServicesCommentsModalContent">
      <h4 className="modalSubtitle">{subtitle}</h4>
      <NudosTextArea
        noResize
        label={toolConfiguration}
        placeholder={textAreaPlaceholder}
        currentValue={toolConfigurationComment}
        textHeight={64}
        maxLength={1000}
        errorText={insufficientLengthError}
        updateCurrentValueCallback={updateToolConfigurationComment}
      />
      <NudosButton
        componentSize="large"
        buttonText={buttonText}
        handleClick={submitModal}
        isButtonDisabled={isButtonDisabled}
      />
    </div>
  );
};

export default AdditionalServicesCommentsModalContent;
