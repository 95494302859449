import React, { useCallback, useEffect, useRef } from 'react';
import { MagnifierIcon } from '../../../assets/images/components/NudosComponents';
import { IElement, Tany } from '../../../types/global';
import Loading from '../Loading';
import NudosGenericSelect from '../NudosGenericSelect';
import { useTranslation } from 'react-i18next';
import './NudosGenericSearchBar.scss';

/**
 * @property { string } searchString - REQUIRED: the current state value for the searched string. Will be used as the value of the input as a controlled component.
 * @property { React.Dispatch<React.SetStateAction<string>> } setSearchString - REQUIRED: the react state updater function for the searched string, will be used to updated the search string as the search input changed
 * @property { string } customClassName - a classname for custom styles
 * @property { string } customPlaceholder - a custom placeholder to show in the search input field, default is "Buscar"
 * @property { React.Dispatch<React.SetStateAction<boolean>> } setIsSelectOpen - a react state updater function for the state controlling whether the select input component with the options returned as findings for the searched string is open or not
 * @property { JSX.Element[] } searchedOptions - An array with the JSX.Elements formed from the returned findings for the searched string
 * @property { React.Dispatch<Tany> } handleChangeSelectedOption - A react updater function for the state that contains the selected option after it has been clicked in the select component
 * @property { Tany } defaultValue - A default value to be selected if the user clicks outside
 * @property { boolean } isSelectOpen - A boolean indicating if the select component with the returned options from the searched string is open or not
 * @property { JSX.Element } noResultsMessage - A JSX.element with the message to show when there are no results, ie when the searchedOptions is undefined.
 * @property { React.Dispatch<React.SetStateAction<string>> } noResultsUpdater - A react state function to clean the no results message when an option is selected
 * @property { string } errorText - A string to show if there is an error with the selection
 * @property { boolean } isDisabled - A boolean to be used in inactivating the search bar
 * @property { boolean } activeStylesIfCustomPlaceholder - A boolean indicating if the searched bar must look active if a custom placeholder is passed
 * @property { JSX.Element } absolutePositionElement - A JSX element to position wherever its required, useful for adding icons to the searchbar
 * @property { boolean } isLoading - A boolean indicating if the searchbar is loading, useful to show the loader while the options list returned as findings for the searched bar are updating
 * @property { string } customWidth - The desired width for the component, for example: "180px"
 * @property { string } customHeight - The desired height for the component, for example: "28px"
 * @property { boolean } showSelectOptionsOnFocus - A boolean indicating if the select options must be displayed upon focusing the search input, even if no query has been written
 */
const NudosGenericSearchBar = ({
  searchString,
  setSearchString,
  customClassName,
  customPlaceholder,
  setIsSelectOpen,
  searchedOptions,
  handleChangeSelectedOption,
  defaultValue,
  isSelectOpen,
  noResultsMessage,
  noResultsUpdater,
  errorText,
  isDisabled,
  activeStylesIfCustomPlaceholder,
  absolutePositionElement,
  isLoading,
  customWidth,
  customHeight,
  showSelectOptionsOnFocus,
  genericSelectClass,
  loadOfTools,
  handleInputSearch
}: InudosGenericSearchBar) => {
  const { t } = useTranslation();
  const searchBarRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setSearchString(value);
    if (handleInputSearch && loadOfTools) {
      handleInputSearch();
    }
    if (!setIsSelectOpen) return;
    if (value.length === 0 && !loadOfTools) {
      setIsSelectOpen(false);
      noResultsUpdater && noResultsUpdater('');
    }
    if (value.length > 0) setIsSelectOpen(true);
  };

  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
        setIsSelectOpen(false);
      }
    },
    [searchBarRef]
  );

  const activeStyles =
    searchString.length > 0 || (activeStylesIfCustomPlaceholder && customPlaceholder && customPlaceholder?.length > 0)
      ? 'active'
      : '';

  const sizeStyles = customWidth || customHeight ? { width: customWidth, height: customHeight } : undefined;

  const getSelectOptions = () => {
    if (isLoading)
      return [
        <div className="loaderContainer" key="loading-option">
          <Loading />
        </div>
      ];
    if (searchedOptions) return searchedOptions;
    if (noResultsMessage) return [noResultsMessage];
    return [];
  };

  const handleFocus = () => showSelectOptionsOnFocus && setIsSelectOpen(true);

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  useEffect(() => {
    isSelectOpen && inputRef.current?.focus();
  }, [isSelectOpen]);

  return (
    <>
      <div
        className={`nudosGenericSearchBar ${customClassName ? customClassName : ''} ${activeStyles} ${
          errorText && errorText.length > 0 ? 'error' : ''
        } ${isDisabled ? 'disabled' : ''}`}
        ref={searchBarRef}
        style={sizeStyles}
      >
        <div className="searchIconContainer">
          <MagnifierIcon className="magnifierIcon" />
        </div>
        <input
          value={searchString}
          className="searchInput"
          placeholder={customPlaceholder || t('nodi:nudosSearchBarPlaceholder')}
          onChange={event => handleChange(event)}
          disabled={isDisabled}
          ref={inputRef}
          onFocus={handleFocus}
        />
        {(searchedOptions || noResultsMessage) && handleChangeSelectedOption && isSelectOpen && (
          <NudosGenericSelect
            selectOptions={getSelectOptions()}
            changeSelectedOption={handleChangeSelectedOption}
            defaultValue={defaultValue || undefined}
            absolutePositionElement={absolutePositionElement}
            selectClassName={genericSelectClass || ''}
          />
        )}
      </div>
    </>
  );
};

export default NudosGenericSearchBar;

export interface InudosGenericSearchBar {
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  customClassName?: string;
  customPlaceholder?: string;
  setIsSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
  searchedOptions?: JSX.Element[];
  handleChangeSelectedOption?: React.Dispatch<Tany>;
  defaultValue?: Tany;
  isSelectOpen?: boolean;
  noResultsMessage?: JSX.Element;
  noResultsUpdater?: React.Dispatch<React.SetStateAction<string>>;
  errorText?: string;
  isDisabled?: boolean;
  activeStylesIfCustomPlaceholder?: boolean;
  absolutePositionElement?: JSX.Element;
  isLoading?: boolean;
  customWidth?: string;
  customHeight?: string;
  showSelectOptionsOnFocus?: boolean;
  genericSelectClass?: string;
  loadOfTools?: boolean;
  handleInputSearch?: () => void;
}
