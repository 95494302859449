import { magnifierIcon } from '../../assets/images/components';
import { useTranslation } from 'react-i18next';
import './UnsuccessfulFilteredResultsView.scss';

/**
 * @property {number} paddingTop - Optional, the padding top in px, default is 150 (150px)
 */
const UnsuccessfulFilteredResultsView = ({ paddingTop, customText }: { paddingTop?: number; customText?: string }) => {
  const { t } = useTranslation();
  const customStyles = { paddingTop: typeof paddingTop === 'number' ? `${paddingTop}px` : '150px' };
  return (
    <div className="unsuccessfulFilteredResultsView" style={customStyles}>
      <div className="magnifierIconContainer">
        <img src={magnifierIcon} />
      </div>
      <p>{customText || t('nodi:genericNoResults')}</p>
    </div>
  );
};

export default UnsuccessfulFilteredResultsView;
