import { useState } from 'react';
import { NudosButton, NudosTextInput } from '../../../../../../components/NudosComponents';
import { yellowEmailCard } from '../../../../../../assets/images/views/RequestLogistics';
import { isEmailValid } from '../../../../../../utils/formValidations';
import './ModalShareInvoice.scss';
import { useTranslation } from 'react-i18next';

const ModalShareInvoiceContent = ({
  emailShareInvoice,
  closeModalCallback,
  setEmailShareInvoice
}: {
  emailShareInvoice?: string;
  closeModalCallback: () => void;
  setEmailShareInvoice?: (email: string) => void;
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>();

  const i18nLanguageKey = 'designSystemComponents:modalShareInvoiceContent:';
  const modalTitle = t(`${i18nLanguageKey}modalTitle`);
  const modalSubtitle = t(`${i18nLanguageKey}modalSubtitle`);
  const nudosTextInputLabel = t(`${i18nLanguageKey}nudosTextInputLabel`);
  const nudosButtonText = t(`${i18nLanguageKey}nudosButtonText`);

  const handleSetEmailShareInvoice = () => {
    !!email && setEmailShareInvoice && setEmailShareInvoice(email);
    closeModalCallback();
  };

  const isButtonDisabled = () => {
    if (!email) return true;
    if (email.length < 6) return true;
    return !isEmailValid(email);
  };

  return (
    <div className="ModalShareInvoiceContent">
      <img className="iconEmailCard" src={yellowEmailCard} />
      <div className="modalTitle">{modalTitle}</div>
      <div className="modalSubtitle">{modalSubtitle}</div>
      <NudosTextInput
        label={nudosTextInputLabel}
        isFilled={!!email}
        defaultValue={emailShareInvoice}
        placeholder={nudosTextInputLabel}
        handleChange={e => setEmail(e)}
        componentSize="medium"
        customClassName="emailInput"
      />
      <NudosButton
        buttonText={nudosButtonText}
        handleClick={handleSetEmailShareInvoice}
        componentSize="medium"
        isButtonDisabled={isButtonDisabled()}
      />
    </div>
  );
};

export default ModalShareInvoiceContent;
