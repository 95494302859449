import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6451_3451)">
      <path
        d="M9.5 0.5H2.5C1.8372 0.500794 1.20178 0.764441 0.73311 1.23311C0.264441 1.70178 0.000793929 2.3372 0 3L0 7C0.000793929 7.6628 0.264441 8.29822 0.73311 8.76689C1.20178 9.23556 1.8372 9.49921 2.5 9.5H5.5V10.5H3.5C3.36739 10.5 3.24021 10.5527 3.14645 10.6464C3.05268 10.7402 3 10.8674 3 11C3 11.1326 3.05268 11.2598 3.14645 11.3536C3.24021 11.4473 3.36739 11.5 3.5 11.5H8.5C8.63261 11.5 8.75979 11.4473 8.85355 11.3536C8.94732 11.2598 9 11.1326 9 11C9 10.8674 8.94732 10.7402 8.85355 10.6464C8.75979 10.5527 8.63261 10.5 8.5 10.5H6.5V9.5H9.5C10.1628 9.49921 10.7982 9.23556 11.2669 8.76689C11.7356 8.29822 11.9992 7.6628 12 7V3C11.9992 2.3372 11.7356 1.70178 11.2669 1.23311C10.7982 0.764441 10.1628 0.500794 9.5 0.5ZM2.5 1.5H9.5C9.89782 1.5 10.2794 1.65804 10.5607 1.93934C10.842 2.22064 11 2.60218 11 3V6.5H1V3C1 2.60218 1.15804 2.22064 1.43934 1.93934C1.72064 1.65804 2.10218 1.5 2.5 1.5ZM9.5 8.5H2.5C2.19087 8.49872 1.88969 8.40196 1.63765 8.22296C1.38562 8.04395 1.19504 7.79145 1.092 7.5H10.908C10.805 7.79145 10.6144 8.04395 10.3623 8.22296C10.1103 8.40196 9.80913 8.49872 9.5 8.5Z"
        fill={props?.fill || '#999999'}
      />
    </g>
    <defs>
      <clipPath id="clip0_6451_3451">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuToolsIcon = memo(ForwardRef);
export default MenuToolsIcon;
