import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={34} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M13.6 6.881c0-.904-.176-1.796-.518-2.632a6.889 6.889 0 0 0-1.474-2.233A6.794 6.794 0 0 0 9.402.524a6.728 6.728 0 0 0-5.204 0 6.795 6.795 0 0 0-2.206 1.492A6.89 6.89 0 0 0 .518 4.25 6.944 6.944 0 0 0 0 6.881h3.4a3.466 3.466 0 0 1 .996-2.431 3.364 3.364 0 0 1 4.808 0A3.467 3.467 0 0 1 10.2 6.88h3.4ZM10.209 10.119c0 .904.175 1.796.517 2.632a6.89 6.89 0 0 0 1.474 2.233 6.793 6.793 0 0 0 2.206 1.492 6.729 6.729 0 0 0 5.205 0 6.794 6.794 0 0 0 2.206-1.492 6.892 6.892 0 0 0 1.474-2.233 6.945 6.945 0 0 0 .518-2.632h-3.4a3.467 3.467 0 0 1-.996 2.431 3.366 3.366 0 0 1-4.808 0 3.465 3.465 0 0 1-.996-2.431h-3.4ZM34 6.881c0-.904-.175-1.796-.517-2.632a6.889 6.889 0 0 0-1.474-2.233A6.795 6.795 0 0 0 29.803.524a6.728 6.728 0 0 0-5.205 0 6.795 6.795 0 0 0-2.206 1.492 6.889 6.889 0 0 0-1.474 2.233 6.945 6.945 0 0 0-.518 2.632h3.4a3.467 3.467 0 0 1 .996-2.431 3.364 3.364 0 0 1 4.808 0A3.467 3.467 0 0 1 30.6 6.88H34Z"
      fill={props?.fill || '#2C7152'}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const NudosLogoM = memo(ForwardRef);
export default NudosLogoM;
