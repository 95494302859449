import './NudosStepsProgressBar.scss';

/**
@property { IprogressStepData[] } steps - An array with the information for each step of the progress bar, including the folowing data: order (number), stepContent (JSX.Element), stepWidth (number), customStepStyles ({ [key: string]: string })
@property { number } currentStep -A number indicating the index of the step in the steps array that should be display as current
@property { number } firstAndLastConectorWidth - The width in pixels (only the number) for the first and last connector lines of the progress bar (the ones coneecting to the points)
@property { number } intermediateStepsConectorWidth - The width in pixels (only the number) for the connector lines connecting the different steps of the progress bar
@property { number } customHeight - The height of the boxes for the steps of the progress bar, ie the height of the component as a whole
@property { IprogressStepData[] } skeletonSteps - An array with the information for each of the skeleton steps. Can contain the same data as the steps property, but the most relevant  are the order and stepWidth, that should be equal to that of the steps in the steps property
 */
const NudosStepsProgressBar = ({
  steps,
  currentStep,
  firstAndLastConectorWidth,
  intermediateStepsConectorWidth,
  customHeight,
  customStepperClassname,
  skeletonSteps,
  loading
}: {
  steps: IprogressStepData[];
  currentStep: number;
  firstAndLastConectorWidth: number;
  intermediateStepsConectorWidth: number;
  customHeight: number;
  customStepperClassname?: string;
  skeletonSteps?: IprogressStepData[];
  loading?: boolean;
}) => {
  const getConnector = (activeStyle: string, width: number, customClass?: string) => {
    const widthStyle = typeof width === 'number' ? `${width}px` : '16px';
    const isFirstOrLastConnector = customClass === 'firstConnector' || customClass === 'lastConnector';
    const circlePositionInLastConnector = customClass === 'lastConnector' ? `${width}px` : undefined;
    return (
      <div className={`connector ${activeStyle} ${customClass || ''}`} style={{ width: widthStyle }}>
        {isFirstOrLastConnector && (
          <div className={`circle ${activeStyle}`} style={{ left: circlePositionInLastConnector }} />
        )}
      </div>
    );
  };

  const lastConnectorStyle = typeof currentStep === 'number' && currentStep >= steps.length - 1 ? 'active' : '';
  const heightStyles = customHeight ? `${customHeight}px` : undefined;

  return (
    <>
      {steps && typeof currentStep === 'number' && !loading && (
        <div className={`nudosStepsProgressBar ${customStepperClassname || ''}`}>
          {steps.map(step => {
            const isLastStep = currentStep === steps.length - 1;
            const stepActiveStyle = currentStep < step.order ? '' : 'active';
            const lastStepStyle = isLastStep ? 'lastStepStyle' : '';
            const stepCurrentStyle = currentStep === step.order && !isLastStep ? 'current' : '';
            const customClass = step.order === 0 ? 'firstConnector' : undefined;
            const connectorWidth = step.order === 0 ? firstAndLastConectorWidth : intermediateStepsConectorWidth;
            return (
              <div
                key={`progressStep${step.order}`}
                className={`progressStep progressStep${step.order}`}
                style={step?.customStepStyles}
              >
                {getConnector(stepActiveStyle, connectorWidth, customClass)}
                <div
                  className={`stepContentBox ${stepActiveStyle} ${stepCurrentStyle} ${lastStepStyle}`}
                  style={{
                    width: step.stepWidth,
                    ...step.customStepStyles,
                    height: heightStyles
                  }}
                >
                  {step.stepContent}
                </div>
              </div>
            );
          })}
          {getConnector(lastConnectorStyle, firstAndLastConectorWidth, 'lastConnector')}
        </div>
      )}
      {(!steps || typeof currentStep !== 'number' || loading) && skeletonSteps && (
        <ProgressBarSkeletonLoader
          skeletonSteps={skeletonSteps}
          firstAndLastConectorWidth={firstAndLastConectorWidth}
          intermediateStepsConectorWidth={intermediateStepsConectorWidth}
          heightStyles={heightStyles}
        />
      )}
    </>
  );
};

const ProgressBarSkeletonLoader = ({
  skeletonSteps,
  firstAndLastConectorWidth,
  intermediateStepsConectorWidth,
  heightStyles
}: {
  skeletonSteps: IprogressStepData[];
  firstAndLastConectorWidth: number;
  intermediateStepsConectorWidth: number;
  heightStyles?: string;
}) => {
  return (
    <div className="progressBarSkeletonLoader">
      {skeletonSteps.map(skeletonStep => {
        const widthStyle = {
          width: typeof skeletonStep.stepWidth === 'number' ? `${skeletonStep.stepWidth}px` : '16px'
        };
        const firstSkeletonLeftMargin = skeletonStep.order === 0 ? firstAndLastConectorWidth : undefined;

        return (
          <div
            key={`skeletonStep${skeletonStep.order}`}
            className="stepSkeleton animationLoader"
            style={{
              ...widthStyle,
              ...skeletonStep.customStepStyles,
              marginLeft: firstSkeletonLeftMargin,
              marginRight: `${intermediateStepsConectorWidth}px`,
              height: heightStyles
            }}
          />
        );
      })}
    </div>
  );
};

export default NudosStepsProgressBar;

export interface IprogressStepData {
  order: number;
  stepContent?: JSX.Element;
  stepWidth: number;
  customStepStyles?: { [key: string]: string };
}
