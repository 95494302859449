import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill={props.fill} xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <rect x="0.5" y="0.5" width="13" height="13" rx="3.5" fill="#383838" stroke="#383838" />
    <g clipPath="url(#clip0_1341_77350)">
      <path
        d="M3.42847 7.893L6.02587 10.5716C7.24811 7.06059 8.26601 5.51994 10.5713 3.42871"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1341_77350">
        <rect width="10" height="10" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SuccessCheck = memo(ForwardRef);
export default SuccessCheck;
