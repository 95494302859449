import { FC, useEffect } from 'react';
import { bgFreeModal } from '../../../../assets/images/components/Onboarding';
import { NodiLogoFree } from '../../../../assets/images/views/Admin/main';
import { NudosButton, NudosGenericModal } from '../../../NudosComponents';
import './NodiFreeStartModal.scss';
import { segmentTrackEvent } from '../../../../utils/segment';
import { useTranslation } from 'react-i18next';

interface INodiFreeStartModal {
  clickInitializeSteps: () => void;
}

const NodiFreeStartModal: FC<INodiFreeStartModal> = ({ clickInitializeSteps }: INodiFreeStartModal) => {
  const { t } = useTranslation();

  const modalEvent = () => {
    segmentTrackEvent({ nodiOnboardingFree0View: null });
  };

  useEffect(() => {
    modalEvent();
  }, []);
  return (
    <>
      <NudosGenericModal
        modalContent={
          <div id="nodiFreeStartModal">
            <div className="boxNodiFreeStartModal">
              <div className="headerStartModal bgPosition" style={{ backgroundImage: `url("${bgFreeModal}")` }}>
                <div className="containerFreeIcon">
                  <NodiLogoFree className="scaleIcon" />
                </div>
              </div>
              <div className="bodyStartModal">
                <div className="titleStartModal">{t('nodi:onboarding:startFreeModal:title')}</div>
                <div className="subtitleStartModal">{t('nodi:onboarding:startFreeModal:subtitle')}</div>
                <div className="buttonStartModal">
                  <NudosButton
                    customHeight="38px"
                    customWidth="180px"
                    buttonText={t('nodi:onboarding:buttons:start')}
                    handleClick={() => {
                      clickInitializeSteps();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
export default NodiFreeStartModal;
