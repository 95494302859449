import { NudosDownloadDocumentButton, NudosUploadButton } from '../../../../../../components/NudosComponents';
import { uploadLogisticServiceDocument } from '../../../../../../services/logisticsServicesModule.services';
import { IlogisticServiceDetailedData, LS_STATUS } from '../../../../../../types/logisticsServicesModule.types';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../utils/displayNudosStandardNotifications';
import validateFile from '../../../../../../utils/validateFile';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import {
  LOGISTIC_SERVICE_DEFAULT_BILLING_COUNTRY,
  PAYMENTS_PROVIDER
} from '../../../../../../constants/logisticServices.constants';
import { useTranslation } from 'react-i18next';
import './LogisticServicePaymentSection.scss';

const LogisticServicePaymentSection = ({
  logisticServiceData,
  isLoading = false,
  setLoadingCallback,
  updateDataCallback
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  isLoading?: boolean;
  setLoadingCallback: React.Dispatch<React.SetStateAction<boolean>>;
  updateDataCallback: () => void;
}) => {
  const { t } = useTranslation();

  const {
    id,
    invoiceUrl,
    proofOfPaymentUrl,
    paymentAgreementDate,
    isPaymentAgreement,
    isPaymentReceived,
    paymentLink,
    paymentType,
    paymentDate,
    billingCountry,
    logisticsQuoteData,
    status
  } = logisticServiceData || {};

  const coveredByNudosCare = paymentType === 'Care';
  const billingWasAutomated = billingCountry?.code === LOGISTIC_SERVICE_DEFAULT_BILLING_COUNTRY && !!logisticsQuoteData;
  const wasPaidThroughStripe = proofOfPaymentUrl === PAYMENTS_PROVIDER;
  const showProofOfPaymentButton = !coveredByNudosCare && !billingWasAutomated;
  const serviceWasCanceled = status === LS_STATUS.CANCELLED;
  const showDownloadProofOfPayment = proofOfPaymentUrl && !wasPaidThroughStripe;
  const showPaymentAgreementDate = !!paymentAgreementDate && !!isPaymentAgreement && !isPaymentReceived;

  const paymentAgreementWasFullfilled = isPaymentReceived && paymentDate;
  const hasSomethingToShowInSecondLine = !coveredByNudosCare || showProofOfPaymentButton || showDownloadProofOfPayment;
  const paymentDateText = paymentDate ? formatShortDate(paymentDate).onlyDate : '';
  const paymentAgreementDateText = paymentAgreementDate ? formatShortDate(paymentAgreementDate).onlyDate : '';

  const translationKey = 'nodi:logisticServices:logisticServicePaymentSection:';
  const recurrentWordsKey = 'recurrentWords:chargeFile';
  const paidOn = t(`${translationKey}paidOn`);
  const invoiceText = t(`${translationKey}invoice`);
  const paymentTitle = t(`${translationKey}paymentTitle`);
  const proofOfPayment = t(`${translationKey}proofOfPayment`);
  const paymentAgreement = t(`${translationKey}paymentAgreement`);
  const coveredWithNudosCare = t(`${translationKey}coveredWithNudosCare`);
  const downloadLinkTextBlue = t(`${translationKey}downloadLinkTextBlue`);
  const downloadLinkTextGray = t(`${translationKey}downloadLinkTextGray`);
  const chargeFileErrorSize = t(`${recurrentWordsKey}chargeFileErrorSize`);
  const chargeFileErrorFormat = t(`${recurrentWordsKey}chargeFileErrorFormat`);
  const proofOfPaymentNotification = t(`${translationKey}proofOfPaymentNotification`);

  const uploadLogisticServiceProofOfPayment = async (fileToUpload?: File) => {
    if (!id || !fileToUpload) return;
    const isFileValid = validateFile(fileToUpload, ['application/pdf'], 2, chargeFileErrorFormat, chargeFileErrorSize);
    if (!isFileValid) return;
    const formData = new FormData();
    formData.append('file', fileToUpload);
    setLoadingCallback(true);
    try {
      const response = await uploadLogisticServiceDocument(id, 'proofOfPayment', formData);
      if (response) {
        updateDataCallback();
        displaySuccessNotification({ customJSXMessage: <>{proofOfPaymentNotification}</> });
        setLoadingCallback(false);
      }
    } catch {
      displayErrorNotification();
      setLoadingCallback(false);
    }
  };

  const goToPaymentLink = () => paymentLink && window.open(paymentLink, '_blank');

  if (isLoading || !logisticServiceData) return <div className="h-5 logisticServicePaymentSection animationLoader" />;

  return (
    <div className="logisticServicePaymentSection">
      <div className="sectionTitle">{paymentTitle}</div>
      <div className="sectionContents">
        <div className="billingAndPaymentLinkLine">
          <NudosDownloadDocumentButton
            buttonText={invoiceText}
            downloadLink={invoiceUrl || ''}
            height={18}
            width={76}
          />
          {!proofOfPaymentUrl && (
            <div
              className="containerPaymentLink"
              style={{ paddingLeft: isPaymentReceived || proofOfPaymentUrl ? '65px' : '' }}
            >
              {paymentLink && !serviceWasCanceled && (
                <div className="downloadLinkTextBlue" onClick={goToPaymentLink}>
                  {downloadLinkTextBlue}
                </div>
              )}
              {!paymentLink && <div className="downloadLinkTextGray">{downloadLinkTextGray}</div>}
            </div>
          )}
        </div>
        {hasSomethingToShowInSecondLine && (
          <div className="proofOfPaymentAndPaymentAgreementLine">
            {coveredByNudosCare && <div className="coveredWithNudosCare">{coveredWithNudosCare}</div>}
            {showProofOfPaymentButton && (
              <div className="uploadProofOfPaymentButton">
                <NudosUploadButton
                  uploadedFileHandler={uploadLogisticServiceProofOfPayment}
                  isFilled={!!proofOfPaymentUrl}
                  buttonEmptyText={proofOfPayment}
                  buttonFilledText={proofOfPayment}
                  height="16px"
                />
                {!!proofOfPaymentUrl && <div className="triangleYellowAlert">👍🏻</div>}
                {!proofOfPaymentUrl && <div className="triangleYellowAlert">⚠️</div>}
              </div>
            )}
            {showDownloadProofOfPayment && (
              <NudosDownloadDocumentButton
                buttonText={proofOfPayment}
                downloadLink={proofOfPaymentUrl || ''}
                height={18}
              />
            )}
            {showPaymentAgreementDate && (
              <div className="paymentAgreementDateContainer">
                {paymentAgreement} <strong>{paymentAgreementDateText}</strong>
              </div>
            )}
            {paymentAgreementWasFullfilled && (
              <div className="datePaymentText">
                {paidOn} <strong>{paymentDateText}</strong>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LogisticServicePaymentSection;
