import { v4 as uuid } from 'uuid';

const SidebarFiltersSkeleton = () => {
  const generateLoadingItem = () => {
    const cards = Array(4).fill('');
    return cards.map(() => {
      return <div key={uuid()} className="option-container newStoreOptionContainer" />;
    });
  };
  return <div className="sidebar-filter-skeleton">{generateLoadingItem()}</div>;
};

export default SidebarFiltersSkeleton;
