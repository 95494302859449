import { Link } from 'react-router-dom';
import { IconRedirection } from '../../../assets/DesignSystem/SVGComponents';
import './NudosRedirectionIcon.scss';

const NudosRedirectionIcon = ({
  redirectionDataObject,
  showHoverTooltip,
  isDeactivated,
  toolTipComponent
}: {
  redirectionDataObject: { [key: string]: unknown };
  showHoverTooltip?: boolean;
  isDeactivated?: boolean;
  toolTipComponent?: JSX.Element;
}) => {
  const inactiveStyles = isDeactivated ? 'inactive' : '';
  return (
    <div className={`nudosRedirectionIcon ${inactiveStyles}`}>
      {!isDeactivated && (
        <Link to={redirectionDataObject}>
          <IconRedirection />
        </Link>
      )}
      {isDeactivated && <IconRedirection />}
      {showHoverTooltip && toolTipComponent && <div className="tooltip">{toolTipComponent}</div>}
    </div>
  );
};

export default NudosRedirectionIcon;
