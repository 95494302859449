import { useHistory } from 'react-router-dom';
import { getPathRoute } from '../../../../utils/getPathRoute';
import { useState } from 'react';
import { menuModules } from '../../../../constants/menu';
import { IMenuIcon, IModuleProperties } from '../../../../types/global';
import useStateSubscriptions from '../../../../state/useStateSubscriptions';
import useLogOutState from '../../../../state/useLogOutState';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../utils/segment';
import './NodiMenu.scss';

const NodiMenu = () => {
  const { t } = useTranslation();
  const { replace } = useHistory();
  const modules: IModuleProperties[] = menuModules;
  const currentModule = getPathRoute();
  const { stateSubscription, setShowModuleTooltip, setComingSoonTooltip } = useStateSubscriptions();
  const { setOpenMinMenu } = useLogOutState();

  const [hoverItem, setHoverItem] = useState<string>();
  const redirectMenu = (path: string | string[]) => {
    if (path) {
      const isArray = Array.isArray(path);
      if (isArray && path.length > 0) {
        const menuRoute = path[0];
        setOpenMinMenu(false);
        replace(`/nodi/${menuRoute}`);
      } else {
        setOpenMinMenu(false);
        replace(`/nodi/${path}`);
      }
    }
  };

  const assingIcon = (status: string, icons: IMenuIcon) => {
    switch (status) {
      case 'activeIcon':
        return icons.activeIcon;
      case 'inactiveIcon':
        return icons.inactiveIcon;
      case 'freeIcon':
        return icons.freeIcon;
      case 'constructionIcon':
        return icons.constructionIcon;
      case 'hoverIcon':
        return icons.hoverIcon;

      default:
        return icons.inactiveIcon;
    }
  };

  const generateMenuItems = (item: IModuleProperties) => {
    const isActive = typeof item.path === 'string' ? currentModule === item.path : item.path.includes(currentModule);
    const disabledFreeSubscription = item?.disabledOnFreeSubscription && stateSubscription.levelSubscription === 'Free';
    return (
      <div
        id={`menu-module-name-${item.name}`}
        onMouseEnter={() => {
          const status = isActive
            ? 'activeIcon'
            : disabledFreeSubscription
            ? 'freeIcon'
            : item.moduleUnderConstruction
            ? 'constructionIcon'
            : 'inactiveIcon';

          if (status === 'inactiveIcon') {
            setHoverItem(item.name);
          }
          if (status === 'constructionIcon' && item?.coming) {
            setComingSoonTooltip({
              title: t(item?.coming?.title),
              subtitle: t(item?.coming?.subtitle),
              elementName: `menu-module-name-${item.name}`
            });
          }
        }}
        onMouseOutCapture={() => {
          setHoverItem(undefined);
          setShowModuleTooltip(undefined);
        }}
        onMouseLeave={() => {
          setHoverItem(undefined);
          setShowModuleTooltip(undefined);
        }}
        className={`moduleItem ${
          isActive
            ? 'activeItem'
            : disabledFreeSubscription
            ? 'freeModule'
            : item?.moduleUnderConstruction
            ? 'constructionModule'
            : 'inactiveItem'
        }`}
        key={`item-${item.name}`}
        onClick={() => {
          segmentTrackEvent({ faqsButtonClick: null });
          if (!disabledFreeSubscription && item.path) {
            redirectMenu(item.path);
          } else if (disabledFreeSubscription) {
            setShowModuleTooltip(`menu-module-name-${item.name}`);
          }
        }}
      >
        <div className="containerMenuIcon">
          {assingIcon(
            isActive
              ? 'activeIcon'
              : disabledFreeSubscription
              ? 'freeIcon'
              : item.moduleUnderConstruction
              ? 'constructionIcon'
              : item.name === hoverItem
              ? 'hoverIcon'
              : 'inactiveIcon',
            item.menuIcons
          )}
        </div>
        <div
          className={
            isActive
              ? 'activeText'
              : disabledFreeSubscription
              ? 'freeText'
              : item.moduleUnderConstruction
              ? 'constructionText'
              : 'inactiveText'
          }
        >
          {t(item?.alias || item?.name || '')}
        </div>
      </div>
    );
  };

  return (
    <div id="nodiMenu">
      <div>
        {modules.map(item => {
          return generateMenuItems(item);
        })}
      </div>
    </div>
  );
};

export default NodiMenu;
