import { FC, useEffect, useState } from 'react';
import { NudosButton, NudosSelectButton } from '../../../../../components/NudosComponents';
import NudosGenericSearchBar from '../../../../../components/NudosComponents/NudosGenericSearchBar';
import { ProductDescription } from '../../../../../components/uploadExternalProducts';
import useDebounce from '../../../../../hooks/useDebounce';
import { findProductReferencesToUpload } from '../../../../../services/uploadExternalTools.service';
import {
  DTOfindProductReferencesToUpload,
  IuploadedItemData,
  IuploadedProductReferenceData
} from '../../../../../types/uploadExternalTools.types';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { useTranslation } from 'react-i18next';
import { LimitToolsBanner } from '../LimitToolsBanner';
import NudosDynamicBanner, {
  INudosDynamicBanner
} from '../../../../../components/DesignSystem/NudosDynamicBanner/NudosDynamicBanner';
import useLoadTools from '../../../../../state/useStateSuccessLoadTools';
import { FREE_TOOLS_TO_UPLOAD_LIMIT } from '../../../../../utils/productDefinitions';
import './Step1SelectReferences.scss';

export interface Istep1SelectReferences {
  activateModal: () => void;
  freePlatform?: boolean;
  numberOfExternalReferences?: number;
  maximumExternalUnits?: number;
  screenName: string;
}

const Step1SelectReferences: FC<Istep1SelectReferences> = (props: Istep1SelectReferences) => {
  const { t } = useTranslation();
  const { activateModal, freePlatform, numberOfExternalReferences, screenName } = props;
  const { selectedReferences, setSelectedReferences, setCurrentStep } = useLoadTools();

  const [searchString, setSearchString] = useState('');
  const [showReferencesSelect, setShowReferencesSelect] = useState(false);
  const [searchMatchingReferences, setSearchMatchingReferences] = useState<DTOfindProductReferencesToUpload[]>();
  const [possibleReferencesNotFound, setPossibleReferencesNotFound] = useState('');
  const [loading, setLoading] = useState(true);
  const [bannerInfo, setBannerInfo] = useState<INudosDynamicBanner>();

  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:Step1SelectReferences:';
  const searchQuery = useDebounce(searchString, 500);
  const totalNumberOfReferences = selectedReferences
    .map(reference => reference?.quantity)
    .reduce((acumulator, referenceQuantity) => acumulator + referenceQuantity, 0);
  const externalToolsPlusSelectedTools = (numberOfExternalReferences || 0) + totalNumberOfReferences;
  const totalToolsExcedTheLimit = freePlatform && externalToolsPlusSelectedTools > FREE_TOOLS_TO_UPLOAD_LIMIT;

  const createEmptyadditionalDataArray = (n: number) => {
    const emptyDataObject = { serial: '', productCondition: '', warranty: '' };
    const newArray = [];
    for (let i = 0; i < n; i++) newArray.push(emptyDataObject);
    return newArray as IuploadedItemData[];
  };

  const addNewReference = (
    totalReferences: IuploadedProductReferenceData[],
    searchedReference: DTOfindProductReferencesToUpload
  ) => {
    const referenceAlreadyExists = totalReferences.findIndex(reference => reference.sku === searchedReference.sku) >= 0;
    const newTotalReferences = [...totalReferences];
    if (!referenceAlreadyExists) {
      const newReference = {
        sku: searchedReference.sku,
        name: searchedReference.name,
        pills: searchedReference.pills,
        category: searchedReference?.category,
        quantity: 1,
        individualToolsData: createEmptyadditionalDataArray(1)
      };
      newTotalReferences.push(newReference);
      setSelectedReferences(newTotalReferences);
    }
  };

  const removeExistingReference = (
    totalReferences: IuploadedProductReferenceData[],
    searchedReference: DTOfindProductReferencesToUpload
  ) => {
    const searchedReferenceIndex = totalReferences.findIndex(reference => reference.sku === searchedReference.sku);
    const newTotalReferences = [...totalReferences];
    if (searchedReferenceIndex >= 0) {
      newTotalReferences.splice(searchedReferenceIndex, 1);
      setSelectedReferences(newTotalReferences);
    }
  };

  const setUnits = (
    totalReferences: IuploadedProductReferenceData[],
    searchedReference: IuploadedProductReferenceData,
    newUnits: number
  ) => {
    const previousUnits = searchedReference.individualToolsData?.length;
    const previousAdditionalData = [...searchedReference.individualToolsData];
    const searchedReferenceIndex = totalReferences.findIndex(reference => reference.sku === searchedReference.sku);
    const newTotalReferences = [...totalReferences];
    if (newUnits < 0) return;
    const hasSomeAdditionalData =
      searchedReference.individualToolsData?.length > 0 &&
      searchedReference.individualToolsData.some(reference => {
        return !!reference.serial || !!reference.productCondition;
      });
    let newIndividualToolsData: IuploadedItemData[] = [];
    if (!hasSomeAdditionalData) newIndividualToolsData = createEmptyadditionalDataArray(newUnits);
    if (hasSomeAdditionalData && newUnits === 0) newIndividualToolsData = [];
    if (hasSomeAdditionalData && newUnits > 0 && newUnits < previousUnits)
      newIndividualToolsData = previousAdditionalData.splice(0, newUnits);
    if (hasSomeAdditionalData && newUnits > 0 && newUnits === previousUnits)
      newIndividualToolsData = previousAdditionalData;
    if (hasSomeAdditionalData && newUnits > 0 && newUnits > previousUnits) {
      const unitsInExcess = createEmptyadditionalDataArray(newUnits - previousUnits);
      newIndividualToolsData = [...searchedReference.individualToolsData, ...unitsInExcess];
    }
    const updatedReference = {
      sku: searchedReference.sku,
      name: searchedReference.name,
      pills: searchedReference.pills,
      quantity: newUnits,
      category: searchedReference?.category,
      individualToolsData: newIndividualToolsData
    };
    newTotalReferences[searchedReferenceIndex] = updatedReference;
    setSelectedReferences(newTotalReferences);
  };

  const onChangeSelectedReferences = (newSelectedReference: DTOfindProductReferencesToUpload) => {
    if (!newSelectedReference) return;
    const isCurrentlySelected = selectedReferences?.some(reference => reference?.sku === newSelectedReference?.sku);
    if (!isCurrentlySelected) addNewReference(selectedReferences, newSelectedReference);
    if (isCurrentlySelected) removeExistingReference(selectedReferences, newSelectedReference);
  };

  const formPossibleReferencesUI = (rawPossibleReferences: DTOfindProductReferencesToUpload[]): JSX.Element[] => {
    return rawPossibleReferences.map((reference: DTOfindProductReferencesToUpload, i: number) => {
      const specificationsPills = Object.fromEntries(
        Object.entries(reference?.pills).filter(([key]) => key !== 'Cargo' && key !== 'Gama' && !key.includes('Perfil'))
      );
      const pills = specificationsPills ? Object.values(specificationsPills) : [];
      const isCurrentlySelected = selectedReferences.some(savedReference => savedReference.sku === reference.sku);
      return (
        <div
          key={`reference-${i}-${reference?.sku}`}
          className="referenceOption"
          onClick={() => onChangeSelectedReferences({ ...reference, pills })}
        >
          <NudosSelectButton isButtonActive={isCurrentlySelected} />
          <ProductDescription name={reference?.name} pills={pills} sku={reference?.sku} />
        </div>
      );
    });
  };

  const handleClickAddNewReference = () => activateModal();

  const addANewReferenceButton = (
    <div className="addANewReferenceButton" onClick={handleClickAddNewReference}>
      {t(`${i18nLanguageKey}addANewReferenceButton`)}
    </div>
  );

  const getSearchedReferences = async () => {
    setLoading(true);
    try {
      const findProductReferencesResponse = await findProductReferencesToUpload(searchQuery);
      if (findProductReferencesResponse && findProductReferencesResponse?.length > 0)
        setSearchMatchingReferences(findProductReferencesResponse);
      if (findProductReferencesResponse && !(findProductReferencesResponse?.length > 0)) {
        setSearchMatchingReferences(undefined);
        setPossibleReferencesNotFound(t('nodi:genericNoResults'));
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const handleChangeUnitsOfGivenReference = (
    event: React.SyntheticEvent,
    totalReferences: IuploadedProductReferenceData[],
    searchedReference: IuploadedProductReferenceData
  ) => {
    const { value } = event.target as HTMLInputElement;
    setUnits(totalReferences, searchedReference, +value);
  };

  const isButtonDisabled =
    selectedReferences.length <= 0 ||
    !selectedReferences.some(reference => reference.quantity > 0) ||
    selectedReferences.some(reference => !/^\d*[1-9]\d*$/.test(`${reference.quantity}`));

  const handleClickContinue = () => {
    if (totalToolsExcedTheLimit) return bannerPrimeButtonAction();
    const referencesWithItems = [...selectedReferences].filter(reference => +reference.quantity > 0);
    setSelectedReferences(referencesWithItems);
    setCurrentStep(1);
  };

  const bannerPrimeButtonAction = () => {
    segmentTrackEvent({ nodiFreeUploadToolBannerNodiPrimeAdvertiseClick: null });
    setBannerInfo({
      bannerTitle: 'Carga todas las herramientas que necesites',
      bannerSubtitle:
        'Actualiza a Nodi Prime para cargar todas las herramientas que desees, obtener descuentos y disfrutar de mucho más con todo lo que Nudos tiene para ti.'
    });
    segmentTrackEvent({ nodiFreeUploadToolModalNodiPrimeAdvertiseView: { ScreenName: 'ToolsUpload Searcher' } });
  };

  const closeDynamicBannerCallback = () => {
    setBannerInfo(undefined);
    displayErrorNotification({ customJSXMessage: <>Elimina algunas herramientas para poder continuar</> });
    segmentTrackEvent({ nodiFreeUploadToolToast10NodiPrimeAdvertiseView: null });
  };

  const submitBannerCallback = () => {
    segmentTrackEvent({ nodiFreeUploadToolModalNodiPrimeAdvertiseClick: null });
  };

  useEffect(() => {
    searchQuery && getSearchedReferences();
  }, [searchQuery]);

  useEffect(() => {
    segmentTrackEvent({ toolsUploadView: null });
  }, []);

  return (
    <>
      {bannerInfo && (
        <NudosDynamicBanner
          bannerTitle={bannerInfo?.bannerTitle || ''}
          openedBannerOrigin="cargue de herramientas"
          bannerSubtitle={bannerInfo?.bannerSubtitle || ''}
          closeModalCallback={() => closeDynamicBannerCallback()}
          submitBannerCallback={submitBannerCallback}
          alertType={2}
        />
      )}
      <div className="step1SelectReferences">
        <section className="searchBarSection">
          <div className="searchBarContainer">
            <NudosGenericSearchBar
              searchString={searchString}
              setSearchString={setSearchString}
              setIsSelectOpen={setShowReferencesSelect}
              searchedOptions={
                searchMatchingReferences ? formPossibleReferencesUI(searchMatchingReferences) : undefined
              }
              handleChangeSelectedOption={onChangeSelectedReferences}
              defaultValue={undefined}
              isSelectOpen={showReferencesSelect}
              noResultsMessage={<em key="no-results-option">{possibleReferencesNotFound}</em>}
              noResultsUpdater={setPossibleReferencesNotFound}
              customPlaceholder={t(`${i18nLanguageKey}searchBarPlaceholder`)}
              absolutePositionElement={addANewReferenceButton}
              isLoading={loading}
            />
          </div>
        </section>
        {freePlatform && (
          <div className="containerLimitBanner">
            <LimitToolsBanner
              screenName={screenName}
              maximumExternalUnits={FREE_TOOLS_TO_UPLOAD_LIMIT}
              totalUnits={externalToolsPlusSelectedTools}
            />
          </div>
        )}
        <section className="selectedReferencesSection">
          <div className={`titles ${selectedReferences.length <= 0 ? 'inactive' : ''}`}>
            <div className="textTitle">{t(`${i18nLanguageKey}textTitle`)}</div>
            <div className="unitsTitle">{t(`${i18nLanguageKey}unitsTitle`)}</div>
          </div>
          <div className="referencesContainer">
            {selectedReferences?.length > 0 &&
              selectedReferences.map((reference, i) => {
                const specificationsPills = Object.fromEntries(
                  Object.entries(reference?.pills).filter(
                    ([key]) => key !== 'Cargo' && key !== 'Gama' && !key.includes('Perfil')
                  )
                );

                const pills = specificationsPills ? Object.values(specificationsPills) : undefined;
                const handleClickDelete = () => removeExistingReference(selectedReferences, reference);
                return (
                  <div key={`reference-${i}-${reference?.sku}`} className="referenceContainer">
                    <div className="productWrapper">
                      <NudosSelectButton iconName="xmark" isButtonActive handleClickButton={handleClickDelete} />
                      <ProductDescription name={reference?.name} pills={pills} sku={reference?.sku} />
                    </div>
                    <input
                      className={totalToolsExcedTheLimit ? 'unitsInputRed' : 'unitsInput'}
                      type={'number'}
                      onChange={event => handleChangeUnitsOfGivenReference(event, selectedReferences, reference)}
                      value={reference?.quantity || undefined}
                      min={0}
                      step={1}
                    />
                  </div>
                );
              })}
          </div>
        </section>
        <NudosButton
          buttonText={t(`${i18nLanguageKey}nudosButton`)}
          componentSize="small"
          isButtonDisabled={isButtonDisabled}
          handleClick={handleClickContinue}
        />
      </div>
    </>
  );
};

export default Step1SelectReferences;
