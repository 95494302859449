import { FilterCheckMark } from '../../../assets/images/components/NudosComponents';
import FilterXMark from '../../../assets/images/components/NudosComponents/FilterXMark';
import './NudosSelectButton.scss';

/**
 * React functional component corresponding to the Nudos select button
 * @property {boolean} isButtonActive - a boolean indicating if the component is active (true) or not (false). Default value is false.
 * @property {string} customClassName - Optional.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "extraSmall", "small" or "large". Defaul value is small.
 * @property {function(): void} handleClickButton - Optional, callback to execute on clicking the component.
 * @property {string} iconName - Optional, one of the standard icons used for the component, corresponding to one of the following words: "checkmark" or "xmark". Defaul value is "checkmark".
 * @property {string} colorPalette - Optional, a string representing a valid color for the component, one of the following: "black", "orange". Default is orange.
 * @property {boolean} isButtonDisabled - Optional
 */
const NudosSelectButton = ({
  isButtonActive,
  customClassName,
  componentSize,
  handleClickButton,
  iconName,
  colorPalette,
  isButtonDisabled
}: InudosSwitchButton) => {
  const componentSizeStyles = componentSize ? componentSize : 'small';
  const iconNameStyles = iconName ? iconName : '';
  const customClassNameStyles = customClassName ? customClassName : '';
  const colorPaletteStyle = colorPalette ? colorPalette : 'orange';
  const disabledStyles = isButtonDisabled ? 'disabled' : '';
  return (
    <div
      id="nudosSelectButton"
      className={`nudosSelectButton ${componentSizeStyles} ${iconNameStyles} ${customClassNameStyles} ${colorPaletteStyle} ${disabledStyles}`}
      onClick={isButtonDisabled ? undefined : handleClickButton}
    >
      {(!iconName || iconName === 'checkmark') && <FilterCheckMark role={isButtonActive ? 'active' : ''} />}
      {iconName === 'xmark' && <FilterXMark role={isButtonActive ? 'active' : ''} />}
    </div>
  );
};

export default NudosSelectButton;

export interface InudosSwitchButton {
  isButtonActive?: boolean;
  customClassName?: string;
  componentSize?: string;
  handleClickButton?: () => void;
  iconName?: string;
  colorPalette?: string;
  isButtonDisabled?: boolean;
}
