import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICountryDetail } from '../../../types/countries';
import { IdeliveryAddress } from '../../../types/global';
import NudosLogisticServiceUserHeader from '../NudosLogisticServiceUserHeader/NudosLogisticServiceUserHeader';
import NudosLogisticsCardAddressForm from '../NudosLogisticsCardAddressForm/NudosLogisticsCardAddressForm';
import { NudosPhoneInput, NudosTextInput } from '../../NudosComponents';
import { IconBuilding, IconPin } from '../../../assets/DesignSystem/SVGComponents';
import { IlocationAddressForLogistics } from '../../../types/requestLogisticsModule';
import { TstoragePlace } from '../../../types/assignationFluxes';
import { getIdDocumentData, isPhoneValid } from '../../../utils/formValidations';
import { IcityDetails } from '../../../types/cities.types';
import './NudosLogisticsOfficeOrOtherAddressCard.scss';

/**
 * @property { 'origin' | 'destination' } type  - The type of the card, will be used in the title of the card
 * @property { IlocationAddressForLogistics } existingOfficeOrOtherAddressData  - The initial data for the office, will be used to preload the card
 * @property { ICountryDetail[] } listOfCountries  - The list of countries available on the Nodi platform, will be used to display the options for the phone and address country dropdowns. Will also be used to obtain the data for the initial phone and address country starting from the codes stored in the existingOfficeOrOtherAddressData
 * @property { (newOfficeData: IlocationAddressForLogistics) => void } updateLogisticsLocationDataCallback  - The callback function to update the logistics location data in the parent component (for example, if the card is used to update the origin or destination information, that data should be stored in the parent component and a function to updated it should be pass in this prop)
 * @property { boolean } disableCountryUpdating  - A boolean indicating if it is possible to update the country of the office, or only the address within the initial country
 */
const NudosLogisticsOfficeOrOtherAddressCard = ({
  type,
  listOfCountries,
  existingOfficeOrOtherAddressData,
  disableCountryUpdating,
  selectedOriginOrDestination,
  updateLogisticsLocationDataCallback
}: INudosLogisticsOfficeCard) => {
  const { t } = useTranslation();
  const [updatedOfficeAddress, setUpdatedOfficeAddress] = useState<IdeliveryAddress>();
  const [updatedCity, setUpdatedCity] = useState<IcityDetails>();
  const [addressCountry, setAddressCountry] = useState<ICountryDetail>();
  const [updatedReceiverName, setUpdatedReceiverName] = useState<string>();
  const [updatedReceiverPhone, setUpdatedReceiverPhone] = useState<string>();
  const [updatedPhoneCountry, setUpdatedPhoneCountry] = useState<ICountryDetail>();
  const [updatedReceiverPersonalId, setUpdatedReceiverPersonalId] = useState<string>();
  const officeOrOtherAddressCountryData =
    existingOfficeOrOtherAddressData?.countryData || existingOfficeOrOtherAddressData?.country;

  const getInvalidPhoneError = () => {
    if (!updatedReceiverPhone || !updatedPhoneCountry) return 'Ingresa número de teléfono para continuar';
    if (!isPhoneValid(updatedReceiverPhone))
      return `${t('recurrentWords:inputError:invalidPhone')} ${updatedPhoneCountry?.name || 'este país'}`;
    return;
  };

  const receiverNameError = !updatedReceiverName ? 'Ingresa nombre y apellido para continuar' : undefined;

  const headerName =
    selectedOriginOrDestination && selectedOriginOrDestination === 'office'
      ? `Oficina en ${officeOrOtherAddressCountryData?.name || 'País'}`
      : existingOfficeOrOtherAddressData?.locationName || 'Otra dirección';

  const idDocumentData = getIdDocumentData(addressCountry || undefined, updatedReceiverPersonalId);

  const updateAddress = (newAddress?: IdeliveryAddress) => setUpdatedOfficeAddress(newAddress);
  const updateAddressCountry = (newCountry?: ICountryDetail) => setAddressCountry(newCountry);
  const updatePhone = (newPhone?: string) => setUpdatedReceiverPhone(newPhone);
  const updatePhoneCountry = (newCountry?: ICountryDetail) => setUpdatedPhoneCountry(newCountry);
  const updatePersonalId = (newId?: string) => setUpdatedReceiverPersonalId(newId);
  const updateReceiverName = (newReceiverName?: string) => setUpdatedReceiverName(newReceiverName);

  const getFormatedAddress = (officeAddress: IlocationAddressForLogistics) => {
    return {
      address: officeAddress?.address,
      coordinates: officeAddress?.coordinates,
      additionalReferences: officeAddress?.additionalReferences,
      country: officeAddress?.country?.code,
      zipCode: officeAddress?.zipCode,
      city: updatedCity?.name || officeAddress?.city
    };
  };

  const getPhotoJSX = () => {
    if (selectedOriginOrDestination === 'office') {
      return <IconBuilding className="iconBuilding" />;
    }
    return <IconPin className="IconPin" />;
  };

  const getTitle = () => {
    if (type === 'origin') return 'Quién entrega';
    return 'Quién recibe';
  };

  useEffect(() => {
    if (!existingOfficeOrOtherAddressData) return;
    const receiverInformation = {
      receiverName: updatedReceiverName,
      receiverPhone: updatedReceiverPhone,
      receiverPhoneCountry: updatedPhoneCountry,
      receiverIdDocument: updatedReceiverPersonalId
    };
    const newOfficeData = {
      ...existingOfficeOrOtherAddressData,
      address: updatedOfficeAddress?.address,
      coordinates: updatedOfficeAddress?.coordinates,
      additionalReferences: updatedOfficeAddress?.additionalReferences,
      country: addressCountry,
      zipCode: updatedOfficeAddress?.zipCode || undefined,
      city: updatedCity?.name || existingOfficeOrOtherAddressData?.city || undefined,
      cityId: updatedCity?.id || existingOfficeOrOtherAddressData?.cityId || undefined,
      receiverInformation
    };
    updateLogisticsLocationDataCallback(newOfficeData);
  }, [
    updatedOfficeAddress,
    addressCountry,
    updatedReceiverPhone,
    updatedPhoneCountry,
    updatedReceiverPersonalId,
    updatedReceiverName
  ]);
  useEffect(() => {
    const initialAddressCountry = listOfCountries.find(
      country => country.code === officeOrOtherAddressCountryData?.code
    );
    const initialPhoneCountry = listOfCountries.find(
      country => country.code === existingOfficeOrOtherAddressData?.receiverInformation?.receiverPhoneCountry?.code
    );
    if (
      !existingOfficeOrOtherAddressData ||
      (!existingOfficeOrOtherAddressData?.country?.code && !existingOfficeOrOtherAddressData?.countryData?.code) ||
      addressCountry
    )
      return;
    const formattedAddress = getFormatedAddress(existingOfficeOrOtherAddressData);
    if (!updatedOfficeAddress || !updatedOfficeAddress?.address) setUpdatedOfficeAddress(formattedAddress);
    if (!updatedReceiverPhone)
      setUpdatedReceiverPhone(existingOfficeOrOtherAddressData?.receiverInformation?.receiverPhone || undefined);
    if (!updatedReceiverPersonalId && existingOfficeOrOtherAddressData?.receiverInformation?.receiverIdDocument)
      setUpdatedReceiverPersonalId(existingOfficeOrOtherAddressData?.receiverInformation?.receiverIdDocument);
    if (!updatedReceiverName && existingOfficeOrOtherAddressData?.receiverInformation?.receiverName)
      setUpdatedReceiverName(existingOfficeOrOtherAddressData?.receiverInformation?.receiverName);
    if (!addressCountry && initialAddressCountry) setAddressCountry(initialAddressCountry);
    if (!updatedPhoneCountry && initialPhoneCountry) setUpdatedPhoneCountry(initialPhoneCountry);
  }, [existingOfficeOrOtherAddressData, listOfCountries]);
  return (
    <div className="NudosLogisticsOfficeOrOtherAddressCard">
      <NudosLogisticServiceUserHeader
        type={type}
        name={headerName || undefined}
        flagUrl={addressCountry?.flag || existingOfficeOrOtherAddressData?.countryData?.flag}
        photoJSX={<div className="destinationIcon">{getPhotoJSX()}</div>}
      />
      <div className="cardBody">
        <NudosLogisticsCardAddressForm
          currentCity={updatedCity}
          cityUpdaterCallback={setUpdatedCity}
          addressUpdaterCallback={updateAddress}
          addressCountryUpdaterCallback={updateAddressCountry}
          listOfCountries={listOfCountries || []}
          initialAddressData={updatedOfficeAddress}
          initialAddressCountry={addressCountry}
          disableCountryUpdating={disableCountryUpdating}
          cityId={updatedCity?.id || existingOfficeOrOtherAddressData?.cityId || undefined}
        />
        <section className="receiverDataSection">
          <h1 className="sectionTitle">{getTitle()}</h1>
          <div className="fieldLine firstLine">
            <NudosTextInput
              componentSize="small-184"
              label="Nombre y apellido"
              placeholder="Ingresa nombre y apellido"
              isFilled={!!updatedReceiverName}
              errorText={receiverNameError}
              handleChange={updateReceiverName}
              defaultValue={updatedReceiverName}
            />
            <NudosPhoneInput
              componentSize="small-184"
              label="Número de teléfono"
              placeholder="Teléfono"
              defaultCountryData={updatedPhoneCountry}
              defaultPhoneNumber={
                updatedReceiverPhone ||
                existingOfficeOrOtherAddressData?.receiverInformation?.receiverPhone ||
                undefined
              }
              errorText={getInvalidPhoneError()}
              handlePhoneChange={updatePhone}
              countriesList={listOfCountries || []}
              handleCountryPhoneChange={updatePhoneCountry}
            />
          </div>
          <div className="fieldLine secondLine">
            <NudosTextInput
              componentSize="small-184"
              customClassName="idDocumentInput"
              label={idDocumentData?.label}
              placeholder={idDocumentData?.placeholder}
              isFilled={!!idDocumentData?.value}
              handleChange={updatePersonalId}
              defaultValue={idDocumentData?.value}
              errorText={idDocumentData?.errorText}
              caption={idDocumentData?.caption}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default NudosLogisticsOfficeOrOtherAddressCard;

interface INudosLogisticsOfficeCard {
  type: 'origin' | 'destination';
  listOfCountries: ICountryDetail[];
  existingOfficeOrOtherAddressData?: IlocationAddressForLogistics;
  disableCountryUpdating?: boolean;
  selectedOriginOrDestination?: TstoragePlace;
  updateLogisticsLocationDataCallback: (newOfficeData: IlocationAddressForLogistics) => void;
}
