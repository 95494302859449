import { DateTime } from 'luxon';
import { IobjectWithDate } from '../types/global';
import { getDefaultLanguage } from './getDefaultLanguage';
import { TYPE_OF_LANGUAGES } from '../constants';

/**
 * Starting from a time offset returns a string to be used in luxon functions as the zone option
 * @param utcTimeOffset an integer between -12 and 14, corresponding to a valid UTC offset. If no value or an invalid value is provided the DEFAULT will be -6 (the time zone for México, by definition)
 * @returns a valid timezone to be used as the zone in luxon DateTime.fromISO function
 */
export const getUtcOffsetForLuxon = (utcTimeOffset?: number) => {
  const isValidTimeZone = typeof utcTimeOffset === 'number' && utcTimeOffset <= 14 && utcTimeOffset >= -12;
  // range of valid utc zones was taken from https://en.wikipedia.org/wiki/List_of_UTC_offsets
  const integerTimeOffset = utcTimeOffset?.toFixed();
  const zoneNumber = utcTimeOffset && utcTimeOffset < 0 ? integerTimeOffset : `+${utcTimeOffset}`;
  const timeZone = isValidTimeZone ? `utc${zoneNumber}` : 'utc-6';
  const timeZoneText = timeZone.replace('utc', 'GMT');
  return { timeZone, timeZoneText };
};

/**
 * Takes a date and returns a datetime in the utc-0 timezone corresponding to the original date at 13:00 of the provided utcTimeOffset (default is utc-6, ie MX timezone)
 * @param dateValueIsoFormat a date in ISO format, eg ("2023-07-13")
 * @param utcTimeOffset an integer between -12 and 14, corresponding to a valid UTC offset. If no value or an invalid value is provided the DEFAULT will be utc-6 (ie MX timezone)
 * @returns a datetime in ISO string format in the utc (zulu-time) timezone. The date corresponds to the original date (choose to be at 13:00 of the utcTimeOffset or MX by default) transformed to the zulu (utc) time
 */
export const convertSimpleDateToDateTimeZulu = (dateValueIsoFormat: string, utcTimeOffset?: number) => {
  const dateWithHour = `${dateValueIsoFormat}T13:00:00.000`; // by definition all dates are set at 13:00 hours https://nudosplatform.atlassian.net/browse/NUD-2050
  const { timeZone } = getUtcOffsetForLuxon(utcTimeOffset);
  const dateTimeInOriginalZone = DateTime.fromISO(dateWithHour, { zone: timeZone || 'utc' }).toString();
  const dateTimeInUTC0 = DateTime.fromISO(dateTimeInOriginalZone, { zone: 'utc' }).toString();
  return dateTimeInUTC0;
};

/**
 * Takes a datetime in ISO format and transform it to its equivalent in the desired utc time zone (if no param is provided default is MX)
 * @param dateValueIsoFormat a string with a datetime in ISO format, eg ("2023-07-13T13:00:00.000Z")
 * @param utcTimeOffset an integer between -12 and 14, corresponding to a valid UTC offset. If no value or an invalid value is provided the DEFAULT will be utc-6 (ie MX timezone)
 * @returns The original datetime in ISO string format TRANSFORMED to the desired utcTimeOffset (DEFAULT is utc-6).
 */
export const displayDateInChosenTimeZone = (dateValueIsoFormat: string, utcTimeOffset?: number) => {
  const { timeZone } = getUtcOffsetForLuxon(utcTimeOffset);
  const dateInDesiredTimeZone = DateTime.fromISO(dateValueIsoFormat, { zone: timeZone }).toString();
  return dateInDesiredTimeZone;
};

/**
 * Takes a datetime in ISO format and returns a formatted date in the desired utc time zone to be passed as value to an html input of type date
 * @param dateValueIsoFormat a string with a date in ISO format, eg ("2023-07-13")
 * @param utcTimeOffset an integer between -12 and 14, corresponding to a valid UTC offset. If no value or an invalid value is provided the DEFAULT will be 0 (ie utc or zulu time)
 * @returns The date in the utcTimeOffset time zone (DEFAULT is utc-0) TRANSFORMED to a format accepted by an HTML input element of type date
 */
export const formatDateToPicker = (dateValueIsoFormat?: string, utcTimeOffset?: number) => {
  if (!dateValueIsoFormat) return undefined;
  const dateInAppropiateTimeZone = displayDateInChosenTimeZone(dateValueIsoFormat, utcTimeOffset);
  const formattedDate = DateTime.fromISO(dateInAppropiateTimeZone).toFormat('yyyy-LL-dd');
  return formattedDate;
};

/**
 * Takes a datetime in ISO format and returns a formatted date in a locale string, in the desired utc time zone.
 * @param dateValueIsoFormat a string with a date or datetime in ISO format, eg ("2023-07-13T13:00:00.000Z"). If only the date is passed the default hour is "00:00:00.000Z"
 * @param desiredZoneUtcTimeOffset the UTC offset for the zone to which the date corresponds. The utc datetime returned from backend will be converted to the desired utc offset, securing the correct date is displayed. Must be an integer between -12 and 14, corresponding to a valid UTC offset. If no value or an invalid value is provided the DEFAULT will be utc-6 (ie MX time zone)
 * @returns a locale string for the date, for example "14 de julio de 2023" (precise format depends on locale). If no datetime is passed as value returns an empty string
 */
export const convertDateToLocaleString = (dateTimeValueIsoFormat?: string, desiredZoneUtcTimeOffset?: number) => {
  const localeLanguage = getDefaultLanguage()

  const renderLocaleLanguage = {
    [TYPE_OF_LANGUAGES.esMX]: 'es',
    [TYPE_OF_LANGUAGES.enUS]: 'en',
  }

  if (!dateTimeValueIsoFormat) return {};
  const { timeZone, timeZoneText } = getUtcOffsetForLuxon(desiredZoneUtcTimeOffset);
  const formattedDateInDesiredTimeZone = DateTime.fromISO(dateTimeValueIsoFormat, { zone: timeZone, locale: renderLocaleLanguage[localeLanguage] }).toLocaleString(
    DateTime.DATE_FULL
  );
  const onlyDate = formattedDateInDesiredTimeZone;
  const textWithTimezone = `${formattedDateInDesiredTimeZone} (${timeZoneText})`;
  return { onlyDate, textWithTimezone, timeZoneText };
};

/**
 * Takes a datetime in ISO format and returns a formatted date in the format DD/MM/YYYY, in the desired utc time zone.
 * @param dateValueIsoFormat a string with a date or datetime in ISO format, eg ("2023-07-13T13:00:00.000Z"). If only the date is passed the default hour is "00:00:00.000Z"
 * @param desiredZoneUtcTimeOffset the UTC offset for the zone to which the date corresponds. The utc datetime returned from backend will be converted to the desired utc offset, securing the correct date is displayed. Must be an integer between -12 and 14, corresponding to a valid UTC offset. If no value or an invalid value is provided the DEFAULT will be utc-6 (ie MX time zone)
 * @returns a date in the format DD/MM/YYYY, eg "14/07/2023. If no datetime is passed as value returns an empty string
 */
export const formatShortDate = (dateTimeValueIsoFormat?: string, desiredZoneUtcTimeOffset?: number) => {
  if (!dateTimeValueIsoFormat) return {};
  const { timeZone, timeZoneText } = getUtcOffsetForLuxon(desiredZoneUtcTimeOffset);
  const dayMonthYearDate = DateTime.fromISO(dateTimeValueIsoFormat, { zone: timeZone }).toFormat('dd/LL/yyyy'); // LL corresponds to the padding2 month number in Luxon eg: "08"
  const onlyDate = dayMonthYearDate;
  const textWithTimezone = `${dayMonthYearDate} (${timeZoneText})`;
  return { onlyDate, textWithTimezone, timeZoneText };
};

/**
 * Takes a datetime in ISO format and returns a formatted date and hour in the format DD/MM/YYYY - hh:mm, in the desired utc time zone. DEFAULT is MX local time (utc-6), eg: "09:14am"
 * @param dateValueIsoFormat a string with a date or datetime in ISO format, eg ("2023-07-13T13:00:00.000Z"). If only the date is passed the default hour is "00:00:00.000Z"
 * @param desiredZoneUtcTimeOffset the UTC offset for the zone to which the date corresponds. The utc datetime returned from backend will be converted to the desired utc offset, securing the correct date is displayed. Must be an integer between -12 and 14, corresponding to a valid UTC offset. If no value or an invalid value is provided the DEFAULT will be 0 (ie utc or zulu time)
 * @returns a datetime in the format DD/MM/YYYY - hh:mm, eg "14/07/2023 - 04:40pm". If no datetime is passed as value returns an empty string
 */
export const formatShortDateAndHour = (dateTimeValueIsoFormat?: string, desiredZoneUtcTimeOffset?: number) => {
  if (!dateTimeValueIsoFormat) return {};
  const { timeZone, timeZoneText } = getUtcOffsetForLuxon(desiredZoneUtcTimeOffset);
  const dayMonthYearDate = DateTime.fromISO(dateTimeValueIsoFormat, { zone: timeZone }).toFormat('dd/LL/yyyy - hh:mma'); // LL corresponds to the padding2 month number in Luxon eg: "08"
  const onlyDateAndHour = dayMonthYearDate.toLocaleLowerCase();
  const textWithTimezone = `${dayMonthYearDate.toLocaleLowerCase()} (${timeZoneText})`;
  return { onlyDateAndHour, textWithTimezone, timeZoneText };
};

/**
 * Takes a datetime value and return the hour in a 12 hour format for the specified UTC time offset. DEFAULT is MX local time (utc-6), eg: "09:14am"
 * @param dateTimeValueIsoFormat - The datetime from which to extract the hour
 * @returns the hour in a 12 hour format, eg: "09:14am" for the MX local time
 */
export const getHourInAmPmFormat = (dateTimeValueIsoFormat: string, desiredZoneUtcTimeOffset?: number) => {
  if (!dateTimeValueIsoFormat) return {};
  const { timeZone, timeZoneText } = getUtcOffsetForLuxon(desiredZoneUtcTimeOffset);
  const dayMonthYearDate = DateTime.fromISO(dateTimeValueIsoFormat, { zone: timeZone }).toFormat('hh:mma'); // LL corresponds to the padding2 month number in Luxon eg: "08"
  const onlyHour = dayMonthYearDate.toLocaleLowerCase();
  const textWithTimezone = `${dayMonthYearDate.toLocaleLowerCase()} (${timeZoneText})`;
  return { onlyHour, textWithTimezone, timeZoneText };
};

/**
 * Order an array of objects with a date property
 * @param dates the array of objects to be sorted. Each object must have the date property in it.
 * @param order the desired order of the sorted array, may be "asc" or "desc"
 * @returns the array sorted by date according to the specified order
 */
export const sortObjectWithDates = <T extends IobjectWithDate>(dates: T[], order: 'asc' | 'desc') => {
  const datesToSort = [...dates];
  const isAscending = order === 'asc';

  datesToSort.sort((a, b) => {
    const dateA = DateTime.fromISO(a?.date || '');
    const dateB = DateTime.fromISO(b?.date || '');
    if (dateA < dateB) return isAscending ? -1 : 1;
    if (dateB < dateA) return isAscending ? 1 : -1;
    else return 0;
  });
  return datesToSort;
};
