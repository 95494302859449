import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NudosHoverText } from '../../../../../components/NudosComponents';
import { IshipmentDetails } from '../../../../../types/orders';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import { ShipmentStatusTracker } from '../ShipmentStatusTracker/ShipmentStatusTracker';
import OrderShipmentCardExpandedSection from '../OrderShipmentCardExpandedSection/OrderShipmentCardExpandedSection';
import { IElement } from '../../../../../types/global';
import { BreadcrumbButtonReturnIcon } from '../../../../../assets/images/components/NudosComponents';
import { truncateText } from '../../../../../utils/truncateText';
import { ModalFluxUpdateShipmentInfo } from '..';
import { IconPencilBox } from '../../../../../assets/DesignSystem/SVGComponents';
import { updateConfirmEdition } from '../../../../../services/orders';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { getNameByPlace } from '../../../../../utils/getNameByPlace';

import './OrderShipmentCard.scss';

/**
 * @property {number} shipmentCardIndex - The order of the card in its corresponding country list of shipments
 * @property {IshipmentDetails} shipmentId - The data for the shipment, including "shipmentId", "destinationName", "destinationAddress", "receiverName", "receiverPhone", "statusName", "shipmentTrackingCode", "shipmentTrackingUrl", "estimatedDeliveryDate" and "statusUpdatedAt"
 * @property {() => void} updateOrderDataFunction - A function to upload the OrderData
 */
const OrderShipmentCard = ({
  shipmentCardIndex,
  shipmentData,
  updateOrderDataFunction,
  countryTimeZone,
  getOrderDatawithoutLoader
}: {
  shipmentCardIndex: number;
  shipmentData: IshipmentDetails;
  updateOrderDataFunction: () => void;
  countryName?: string | null;
  countryTimeZone?: number;
  getOrderDatawithoutLoader: () => void;
}) => {
  const {
    destinationName,
    destinationPlace,
    destinationAddress,
    receiverName,
    receiverPhone,
    user,
    statusName,
    shipmentTrackingCode,
    shipmentTrackingUrl,
    estimatedDeliveryDate,
    deliveryDate,
    productsInfo
  } = shipmentData;

  const { t } = useTranslation();
  const [showReceiverDataModal, setShowReceiverDataModal] = useState(false);
  const [showExpandedSection, setShowExpandedSection] = useState(false);
  const orderShipmentCardRef = useRef<HTMLDivElement>(null);
  const existReports =
    shipmentData?.reports?.length > 0 && shipmentData.reports.find(report => report?.status === 'OPEN');
  const i18nLanguageKey = 'nodi:orderDetails:orderShipmentCard:';
  const completeReceiverData = receiverName && receiverPhone;
  const shipmentIsFinished = deliveryDate || statusName === 'Cancelado' || statusName === 'Entregado';
  const shipmentIsInDeliveryProcess = statusName === 'Proceso de entrega';
  const receiverText = completeReceiverData
    ? t(`${i18nLanguageKey}completeReceiverData`)
    : receiverName
    ? t(`${i18nLanguageKey}receiverName`)
    : t(`${i18nLanguageKey}receiverTextDefault`);
  const activeDeliveryDateStyle = deliveryDate || estimatedDeliveryDate ? 'active' : '';
  const destinationIsNudos = destinationName === 'Bodega Nudos';
  const shipmentInfoEditingIsAllowed = !shipmentIsFinished && !destinationIsNudos;
  const receiverActiveStyles = shipmentInfoEditingIsAllowed ? 'active' : '';
  const expandedStyles = showExpandedSection ? 'expanded' : '';
  const isTrackingUrlAValidLink = !!shipmentTrackingUrl?.toLowerCase()?.includes('https');
  const activeCodeStyles = shipmentTrackingCode ? 'active' : '';
  const editAlert = existReports && shipmentInfoEditingIsAllowed;

  const destinationNameByPlace = getNameByPlace({
    name: destinationName ?? '',
    place: destinationPlace ?? ''
  });

  const enableFormEditing = shipmentInfoEditingIsAllowed && !shipmentIsInDeliveryProcess;
  const externalEdition = !editAlert && shipmentData?.editShipments?.platform === 'SOGA';
  const closeReceiverDataModalCallback = () => setShowReceiverDataModal(false);
  const handleClickShowReceiverModal = () => shipmentInfoEditingIsAllowed && setShowReceiverDataModal(true);
  const handleCloseExpandedSection = () => setShowExpandedSection(false);
  const handleShowOrHideExpandedSection = () => setShowExpandedSection(!showExpandedSection);

  const onHandleClickAway = useCallback(
    (e: IElement) => {
      if (orderShipmentCardRef.current && !orderShipmentCardRef.current.contains(e.target))
        handleCloseExpandedSection();
    },
    [orderShipmentCardRef]
  );

  const getShipmentTrackingLinkText = () => {
    if (isTrackingUrlAValidLink)
      return (
        <a className="trackingUrl" href={shipmentTrackingUrl || undefined} target="blank">
          {shipmentTrackingUrl}
        </a>
      );
    if (!isTrackingUrlAValidLink && shipmentTrackingUrl)
      return (
        <NudosHoverText
          customClassName="trackingInstructions"
          onlyIfTruncated
          text={shipmentTrackingUrl}
          charactersLimit={40}
        />
      );
    if (!shipmentTrackingUrl)
      return <div className="pendingTrackingUrl">{t(`${i18nLanguageKey}pendingTrackingUrl`)}</div>;
  };

  const getDeliveryOrEstimatedDeliveryDate = () => {
    const deliveryDateText = `Entregado: ${
      formatShortDate(deliveryDate || undefined, countryTimeZone)?.textWithTimezone || '-'
    }`;
    const estimatedDeliveryDateText = `${t(`${i18nLanguageKey}estimatedDeliveryDateText`)} : ${
      formatShortDate(estimatedDeliveryDate || undefined, countryTimeZone)?.textWithTimezone || '-'
    }`;
    if (deliveryDate) return deliveryDateText;
    if (estimatedDeliveryDate) return estimatedDeliveryDateText;
    return `${t(`${i18nLanguageKey}estimatedDeliveryDateText`)} -`;
  };

  const confirmEdition = async () => {
    if (externalEdition) {
      try {
        await updateConfirmEdition({
          reportId: Number(shipmentData?.editShipments?.id),
          status: 'CLOSED',
          shipmentId: Number(shipmentData.shipmentId),
          haveReport: false
        });
        await getOrderDatawithoutLoader();
      } catch (error) {
        displayErrorNotification();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div className="orderShipmentCard" ref={orderShipmentCardRef}>
      {showReceiverDataModal && (
        <ModalFluxUpdateShipmentInfo
          closeModalCallback={closeReceiverDataModalCallback}
          userId={user?.userId || undefined}
          shipmentExistingData={shipmentData}
          updateOrderDataFunction={updateOrderDataFunction}
          shipmentInfoEditingIsAllowed={enableFormEditing}
        />
      )}
      <div className={`orderShipmentCardContractedSection ${expandedStyles}`}>
        <div className="firstLineInformation">
          <div
            className={`shipmentId ${externalEdition ? 'cursor-pointer' : ''}`}
            onClick={() => {
              confirmEdition();
            }}
          >
            {externalEdition && !editAlert && <>ℹ️ </>}
            {editAlert && <>⚠️ </>}
            {t(`${i18nLanguageKey}shipmentId`)} {shipmentCardIndex + 1}
          </div>
          <div className="destinationInfo">
            {destinationIsNudos && <div className="name">{destinationNameByPlace}</div>}
            {!destinationIsNudos && (
              <strong>
                <NudosHoverText
                  customClassName="name"
                  text={destinationName ? `${destinationNameByPlace} ` : 'Destino '}
                  charactersLimit={22}
                />
                {destinationAddress?.address && '-'}
              </strong>
            )}
            <NudosHoverText
              text={` ${destinationAddress?.address || ''}` || ' Sin datos'}
              charactersLimit={40}
              onlyIfTruncated={true}
            />
          </div>
          {!destinationIsNudos && !user?.userId && (
            <div className="receiver">
              <span className="greyText">{t(`${i18nLanguageKey}greyText`)}</span>
              <NudosHoverText text={truncateText(receiverName || '', 40)} charactersLimit={18} onlyIfTruncated={true} />
            </div>
          )}
          {!destinationIsNudos && (
            <>
              {completeReceiverData ? (
                <button
                  className={`updateReceiverButton alignItemsButton  ${receiverActiveStyles}`}
                  style={{ color: editAlert ? '#FF0000' : '' }}
                  onClick={() => {
                    handleClickShowReceiverModal();
                    confirmEdition();
                    segmentTrackEvent({
                      nodiOrderEditClick: {
                        Country: shipmentData?.countryName || '',
                        OrderStatus: shipmentData?.statusName || ''
                      }
                    });
                  }}
                >
                  {receiverText}
                  <IconPencilBox
                    color={editAlert ? '#FF0000' : !shipmentInfoEditingIsAllowed ? '#CCC' : ''}
                    className="iconEditPosition"
                  />
                </button>
              ) : (
                <button
                  className={`updateReceiverButton  ${receiverActiveStyles}`}
                  onClick={handleClickShowReceiverModal}
                >
                  {receiverText}
                </button>
              )}
            </>
          )}
        </div>
        <div className="secondLineInformation">
          <div className="truck">🚛</div>
          {getShipmentTrackingLinkText()}
          <NudosHoverText
            customClassName={`trackingCode ${activeCodeStyles}`}
            text={`${t(`${i18nLanguageKey}trackingCode`)} ${shipmentTrackingCode || '-'}`}
            charactersLimit={22}
            onlyIfTruncated
          />
          <div className={`estimatedDeliveryDate ${activeDeliveryDateStyle}`}>
            {getDeliveryOrEstimatedDeliveryDate()}
          </div>
        </div>
        <div className="expandButton" onClick={handleShowOrHideExpandedSection}>
          {t(`${i18nLanguageKey}expandButton`)}
          <BreadcrumbButtonReturnIcon className={`arrowIcon ${expandedStyles}`} />
        </div>
        <ShipmentStatusTracker shipmentStatus={statusName} />
      </div>
      <OrderShipmentCardExpandedSection
        shipmentData={shipmentData}
        showExpandedSection={showExpandedSection}
        thisShipmentProducts={productsInfo || []}
      />
    </div>
  );
};

const OrderShipmentCardSkeletonLoader = () => {
  return (
    <div className="orderShipmentCardSkeletonLoader">
      <div className="firstLineField firstLineInformationSkeleton">
        <div className="firstLineField shipmentIdSkeleton animationLoader" />
        <div className="firstLineField destinationInfoSkeleton animationLoader" />
        <div className="firstLineField receiverSkeleton animationLoader" />
        <button className="firstLineField updateReceiverButtonSkeleton animationLoader" />
      </div>
      <div className="secondLineInformationSkeleton">
        <div className="secondLineField trackingCode animationLoader" />
        <div className="secondLineField trackingUrl animationLoader" />
        <div className="secondLineField estimatedDeliveryDate animationLoader" />
      </div>
      <ShipmentStatusTracker />
    </div>
  );
};

export { OrderShipmentCard, OrderShipmentCardSkeletonLoader };
