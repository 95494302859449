import { FC, useEffect, useState } from 'react';
import { formatOrgData } from '../../../../../../utils/orgFormatData';
import { ICountryDetail } from '../../../../../../types/countries';
import {
  NudosAddressSearchBar,
  NudosButton,
  NudosDropdownWithSearchbar,
  NudosTextInput
} from '../../../../../../components/NudosComponents';
import { IaddressFromGoogleMaps } from '../../../../../../types/global';
import { addOtherLogisticsAddress } from '../../../../../../services/account';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import { TdestinationAddress } from '../../../../../../types/assignationFluxes';
import { useTranslation } from 'react-i18next';
import { NudosCountryDropdown } from '../../../../../../components/DesignSystem';
import './LocationOtherAddressModal.scss';

interface ILocationOtherAddressModal {
  listOfAllCountries: ICountryDetail[];
  othersAddressList: TdestinationAddress[];
  refresOtherAddressData: (otherCode: string) => void;
  updateCardListData: (type?: string, existId?: number) => void;
  closeLocationModal: () => void;
  handleChangeOption: (option: number) => void;
  actionTemporalOption: (temporalOption: number) => void;
  defaultCodeOtherAddress: string;

  updateOtherAddres: (otherAddress: TdestinationAddress) => void;
  otherAddressDirect?: boolean;
  defaultOtherAddress?: TdestinationAddress;
}
const LocationOtherAddressModal: FC<ILocationOtherAddressModal> = ({
  listOfAllCountries,
  refresOtherAddressData,
  updateCardListData,
  closeLocationModal,
  handleChangeOption,
  actionTemporalOption,
  defaultCodeOtherAddress,
  otherAddressDirect,
  updateOtherAddres,
  defaultOtherAddress,
  othersAddressList
}: ILocationOtherAddressModal) => {
  const { t } = useTranslation();
  const orgInfo = formatOrgData();
  const [selectOtherCountry, setSelectOtherCountry] = useState<ICountryDetail>();
  const [otherLabelAddress, setOtherLabelAddress] = useState<string>();
  const [otherFullAddress, setOtherFullAddress] = useState<IaddressFromGoogleMaps>();
  const [otherZipCode, setOtherZipCode] = useState<string>();
  const [otherReferences, setOtherReferences] = useState<string>();
  const [saveLoadingOtherAddress, setSaveLoadingOtherAddress] = useState<boolean>(false);
  const [newOtherLocation, setNewOtherLocation] = useState<TdestinationAddress>();
  const i18nLanguageKey = 'nodi:tools:locationOtherAddressModal:';

  const otherButtonDisabled = () => {
    if (otherFullAddress && selectOtherCountry?.code !== otherFullAddress.country) return true;
    if (otherFullAddress && !newOtherLocation?.locationName) return true;
    if (!otherReferences) return true;
    return false;
  };

  const saveOtherAddress = async () => {
    setSaveLoadingOtherAddress(true);
    const body = {
      id: Number(orgInfo?.organizationId),
      address: otherFullAddress?.address || otherLabelAddress || '',
      additionalReferences: otherReferences || '',
      city: otherFullAddress?.city || defaultOtherAddress?.city || '',
      cityId: defaultOtherAddress?.cityId, // CAMBIAR-JC: Cambiar si alguna vez diseño implemente un select de ciudad en este modal
      zipCode: otherZipCode,
      coordinates: otherFullAddress?.coordinates,
      countryId: Number(selectOtherCountry?.id),
      locationName: newOtherLocation?.locationName || ''
    };
    try {
      const newAddress = await addOtherLogisticsAddress(Number(orgInfo?.organizationId), body);
      await refresOtherAddressData(selectOtherCountry?.code || '');
      setNewOtherLocation(undefined);
      setOtherFullAddress(undefined);
      setOtherZipCode(undefined);
      setOtherReferences(undefined);
      if (otherAddressDirect) {
        await updateCardListData('otherAddressEdit');
        closeLocationModal();
      } else {
        updateOtherAddres({ ...newAddress, code: newAddress?.country, locationId: newAddress.id });
        handleChangeOption(0);
        actionTemporalOption(2);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setSaveLoadingOtherAddress(false);
    }
  };

  const onlyAssignLocation = async () => {
    if (newOtherLocation) {
      if (otherAddressDirect) {
        await updateCardListData('otherAddressEdit', newOtherLocation.locationId);
        closeLocationModal();
      } else {
        updateOtherAddres(newOtherLocation);
        handleChangeOption(0);
        actionTemporalOption(2);
      }
    }
  };

  const initialOtherCountry = () => {
    if (defaultCodeOtherAddress) {
      const defaultCountry = listOfAllCountries.find(element => element.code === defaultCodeOtherAddress);
      setSelectOtherCountry(defaultCountry);
    }
    if (defaultOtherAddress) {
      setNewOtherLocation(defaultOtherAddress);
      setOtherZipCode(defaultOtherAddress?.zipCode);
      setOtherReferences(defaultOtherAddress.additionalReference);
      setOtherLabelAddress(defaultOtherAddress?.address);
    }
  };

  const formOtherLocationDataDropdownOptionsFn = (
    setCurrentOption: React.Dispatch<React.SetStateAction<TdestinationAddress | undefined>>
  ) => {
    return (rawOptions: TdestinationAddress[], clickOptionCallback: (option: TdestinationAddress) => void) => {
      return rawOptions.map((option, i) => {
        const handleSelectThisOption = () => {
          setCurrentOption(option);
          setOtherLabelAddress(option?.address || '');
          setOtherReferences(option.additionalReference || '');
          setOtherZipCode(option.zipCode || '');
          clickOptionCallback && clickOptionCallback(option);
        };
        return (
          <div className="optionContainer" key={`option-${option.code}-${i}`} onClick={handleSelectThisOption}>
            {option.locationName}
          </div>
        );
      });
    };
  };

  const filterBySearchStringCallback = (search: string, rawOptions: TdestinationAddress[]) => {
    return rawOptions.filter(taxData =>
      taxData?.locationName?.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  };

  const filterByCountry = () => {
    const listByCountry = othersAddressList.filter(element => element.code === selectOtherCountry?.code);
    if (listByCountry.length > 0) {
      return listByCountry;
    }
    return [];
  };

  const addNewLocation = (locationName: string) => {
    resetOtherAddress();
    setNewOtherLocation({
      additionalReference: '',
      address: '',
      city: '',
      locationName: locationName
    });
  };

  const resetOtherAddress = () => {
    setNewOtherLocation(undefined);
    setOtherFullAddress(undefined);
    setOtherLabelAddress(undefined);
    setOtherReferences(undefined);
    setOtherZipCode(undefined);
  };

  useEffect(() => {
    initialOtherCountry();
  }, [defaultOtherAddress]);

  return (
    <div id="locationOtherAddressModal">
      <div className="orangeTitle">{t(`${i18nLanguageKey}orangeTitle`)} </div>
      <div className="boxBodyModal">
        <div className="organizationName">{orgInfo?.businessName}</div>
        <div className="countrySection">
          <div id="ModalNudosCountryDropdown">
            <NudosCountryDropdown
              charactersLimit={5}
              currentValue={selectOtherCountry}
              countriesList={listOfAllCountries || []}
              customPlaceholder={t('nodi:nudosPhoneInputPlaceholder')}
              updateCurrentValueCallback={setSelectOtherCountry}
            />
          </div>
          <div className="nameAddress">
            <NudosDropdownWithSearchbar
              componentSize="small"
              label={t(`${i18nLanguageKey}nameAddress`)}
              customPlaceholder={t(`${i18nLanguageKey}nameAddress`)}
              rawOptions={filterByCountry() || []}
              formDropdownOptionsCallback={formOtherLocationDataDropdownOptionsFn(setNewOtherLocation)}
              filterBySearchStringCallback={filterBySearchStringCallback}
              isFilled={!!newOtherLocation}
              currentValue={newOtherLocation}
              notFoundElement
              addOutAction={e => addNewLocation(e)}
              customLabel="labelDropDown"
            />
          </div>
        </div>
        <div className="addressBox">
          <NudosAddressSearchBar
            label={t(`${i18nLanguageKey}addressBox:label`)}
            styleLabel="cutomLabel"
            customPlaceholder={t(`${i18nLanguageKey}addressBox:customPlaceholder`)}
            hideDidntFindAddressToolTip
            defaultValueAddressName={otherLabelAddress}
            handleAddressSelection={e => setOtherFullAddress(e)}
            errorText={
              otherFullAddress && selectOtherCountry?.code !== otherFullAddress?.country
                ? `Esta dirección no pertenece a ${selectOtherCountry?.name || 'este país'}`
                : ''
            }
            isDisabled={!!newOtherLocation?.locationId}
          />
        </div>
        <div className="extraAddressBox">
          <div className="postalCode">
            <NudosTextInput
              defaultValue={otherZipCode}
              handleChange={e => {
                setOtherZipCode(e);
              }}
              label={t(`${i18nLanguageKey}postalCode`)}
              isFilled={!!otherZipCode}
              placeholder={t(`${i18nLanguageKey}postalCode`)}
              styleLabel="cutomLabel"
              isDeactivated={!!newOtherLocation?.locationId}
            />
          </div>
          <div className="references">
            <NudosTextInput
              defaultValue={otherReferences}
              label={t(`${i18nLanguageKey}references:label`)}
              isFilled={!!otherReferences}
              placeholder={t(`${i18nLanguageKey}references:placeholder`)}
              styleLabel="cutomLabel"
              handleChange={e => setOtherReferences(e)}
              isDeactivated={!!newOtherLocation?.locationId}
            />
          </div>
        </div>
        <div>
          <NudosButton
            buttonText={t(`${i18nLanguageKey}nudosButton`)}
            isButtonDisabled={
              saveLoadingOtherAddress || !selectOtherCountry?.id || !otherZipCode || otherButtonDisabled()
            }
            isButtonLoading={saveLoadingOtherAddress}
            handleClick={() => {
              if (newOtherLocation?.locationId) {
                onlyAssignLocation();
              } else {
                saveOtherAddress();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationOtherAddressModal;
