import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NudosGenericModal, NudosModalContent } from '../../../../../../../components/NudosComponents';
import { addNewAddressOffice } from '../../../../../../../services/account';
import { IorganizationOfficeData } from '../../../../../../../types/account';
import { ICountryDetail } from '../../../../../../../types/countries';
import { displayErrorNotification } from '../../../../../../../utils/displayNudosStandardNotifications';
import { getOrgData } from '../../../../../../../utils/getLocalStorageData';
import { NudosEditOfficeAddressModal } from '../../../../../../../components/DesignSystem/NudosEditAddressModal';
import './ShipToOfficeModalFlux.scss';

/**
 * @property { React.Dispatch<React.SetStateAction<boolean>> } setShowShipToOfficeModalFlux - a react state updater function for determing if the modal is open or closed
 * @property { ICountryDetail[] } listOfCountries - An array with the countries data. Will be passed to the OfficeDestinationModal to determine both the default country and the list of countries from which to choose the office address
 * @property { IorganizationOfficeData } officeAddress - The office data with which the modal will preloaded
 * @property { React.Dispatch<React.SetStateAction<IorganizationOfficeData | undefined>> } setOfficeAddress - The office data updater function
 * @property {() => void } finishShipToOfficeFluxCallbackFn - a callback function to execute upon the end of the clickButtonCallBack, right before the modal is closed
 * @property { ICountryDetail } preselectedOfficeCountry - The default country to which the office whose data will be modified by the modal belongs
 */
const ShipToOfficeModalFlux = ({
  setShowShipToOfficeModalFlux,
  listOfCountries,
  officeAddress,
  setOfficeAddress,
  finishShipToOfficeFluxCallbackFn,
  preselectedOfficeCountry
}: {
  setShowShipToOfficeModalFlux: React.Dispatch<React.SetStateAction<boolean>>;
  listOfCountries: ICountryDetail[];
  officeAddress?: IorganizationOfficeData;
  setOfficeAddress: React.Dispatch<React.SetStateAction<IorganizationOfficeData | undefined>>;
  finishShipToOfficeFluxCallbackFn?: (modalData?: IorganizationOfficeData) => void;
  preselectedOfficeCountry?: ICountryDetail;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const organizationId = getOrgData()?.organizationId;
  const i18nLanguageKey = 'nodi:orderDetails:orderShipmentCard:shipToOfficeModalFlux:';

  const clickButtonCallback = async (data: IorganizationOfficeData) => {
    if (!organizationId) return;
    if (!data?.address || !data?.additionalReferences || !data?.country?.id) return;
    const body = {
      address: data?.address,
      coordinates: data?.coordinates,
      city: data?.city,
      cityId: data?.cityId,
      countryId: data?.country?.id,
      zipCode: data?.zipCode || undefined,
      additionalReferences: data?.additionalReferences
    };
    setLoading(true);
    try {
      const createdOfficeData = await addNewAddressOffice(organizationId, body);
      const newOfficeData = {
        ...body,
        locationId: createdOfficeData?.id || undefined,
        countryId: data?.country?.id,
        country: data?.country,
        shipmentInfo: {
          receiverName: data?.shipmentInfo?.receiverName || '',
          receiverPhone: data?.shipmentInfo?.receiverPhone || '',
          receiverIdDocument: data?.shipmentInfo?.receiverIdDocument || ''
        }
      };
      newOfficeData?.locationId && setOfficeAddress(newOfficeData);
      if (finishShipToOfficeFluxCallbackFn && newOfficeData?.locationId)
        finishShipToOfficeFluxCallbackFn({ ...data, locationId: newOfficeData.locationId });
      setLoading(false);
      setShowShipToOfficeModalFlux(false);
    } catch (error) {
      displayErrorNotification();
      setLoading(false);
    }
  };

  return (
    <div className="shipToOfficeModalFlux">
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            customHeight="530px"
            customWidth="636px"
            modalContentTitle={t(`${i18nLanguageKey}modalContentTitle`)}
            CustomModalComponent={
              <NudosEditOfficeAddressModal
                subtitle={t(`${i18nLanguageKey}subtitle`)}
                buttonText={t(`${i18nLanguageKey}buttonText`)}
                listOfCountries={listOfCountries || []}
                isButtonLoading={loading}
                currentOfficeData={officeAddress}
                clickButtonCallback={clickButtonCallback}
                disableCountrySelection
                preselectedOfficeCountry={preselectedOfficeCountry}
                giverOrReceiverSectionTitle={t(`${i18nLanguageKey}giverOrReceiverTitle`)}
              />
            }
            toCloseModal={setShowShipToOfficeModalFlux}
          />
        }
      />
    </div>
  );
};

export default ShipToOfficeModalFlux;
