import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11} height={11} fill="none" ref={ref} {...props}>
    <g clipPath="url(#clip0_14274_37419)">
      <path
        d="M5.5 10.3125C7.91625 10.3125 9.875 8.35375 9.875 5.9375C9.875 3.52125 7.91625 1.5625 5.5 1.5625C3.08375 1.5625 1.125 3.52125 1.125 5.9375C1.125 8.35375 3.08375 10.3125 5.5 10.3125Z"
        fill="white"
        stroke={props?.color || '#7B61FF'}
      />
      <circle cx="5.5" cy="3.75" r="0.625" fill={props?.color || '#7B61FF'} />
      <rect x="4.875" y="5.3125" width="1.25" height="3.4375" rx="0.625" fill={props?.color || '#7B61FF'} />
    </g>
    <defs>
      <clipPath id="clip0_14274_37419">
        <rect width="10" height="10" fill="white" transform="translate(0.5 0.9375)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconWarningInformation = memo(ForwardRef);
export default IconWarningInformation;
