import { IconInformation, IconLightbulb, IconTimeClock, IconWarning } from '../../../assets/DesignSystem/SVGComponents';
import './NudosStaticAlertInComponent.scss';

/**
 * @property { IalertOptions } alertOptions - An object specifying the contents of the warning between the subtitle and the option cards. It can contain an icon, title and subtitle. If this property is undefined no warning is shown and thus the space between the subtitle and the option cards is empty.
 * @property { string } customClassname - A classname for custom styles
 */
const NudosStaticAlertInComponent = ({
  alertOptions,
  customClassname,
  color,
  type,
  keepTypeIconButChangeColor,
  bodyAlertComponent,
  customTitleClass
}: {
  alertOptions: IalertOptions;
  customClassname?: string;
  color?: 'yellow' | 'blue' | 'gray';
  type?: 'warning' | 'time' | 'bulb' | 'information';
  keepTypeIconButChangeColor?: boolean;
  bodyAlertComponent?: React.ReactNode;
  customTitleClass?: string;
}) => {
  const getIconByType = () => {
    switch (type) {
      case 'warning':
        return <IconWarning className="IconWarning" />;
      case 'time':
        return <IconTimeClock className="IconTimeClock" />;
      case 'bulb':
        return <IconLightbulb />;
      case 'information':
        return <IconInformation className="IconInformation" />;
      default:
        return alertOptions?.icon;
    }
  };

  const getColorByType = () => {
    if (keepTypeIconButChangeColor && color) return color;
    switch (type) {
      case 'warning':
        return 'yellow';
      case 'time':
        return 'blue';
      case 'bulb':
        return 'blue';
      default:
        return color || '';
    }
  };

  return (
    <div className={`NudosStaticAlertInComponent ${customClassname || ''} ${getColorByType()}`}>
      {getIconByType()}
      <div>
        {alertOptions.title && <div className={`alertTitle ${customTitleClass || ''}`}>{alertOptions.title}</div>}
        {bodyAlertComponent ? (
          bodyAlertComponent
        ) : (
          <>{alertOptions.subtitle && <div className="alertSubtitle">{alertOptions.subtitle}</div>}</>
        )}
      </div>
    </div>
  );
};

export default NudosStaticAlertInComponent;

export interface IalertOptions {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  icon?: JSX.Element;
}
