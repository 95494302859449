import Points from '../../assets/images/components/newCard/points';
import { useHistory } from 'react-router-dom';
import { FC, useMemo, useState } from 'react';
import {
  IassigneeInfo,
  IlogisticsDestinationData,
  InudoInfo,
  PRODUCT_CATEGORY,
  TnudosCare,
  TtoolCategories
} from '../../types/global';
import { truncateText } from '../../utils/truncateText';
import SerialIcon from '../../assets/images/components/newCard/serialIcon';
import useStore from '../../state';
import { NudosCareTag, NudosGenericModal, NudosModalContent } from '../NudosComponents';
import CompleteAssigneeInfoModal from '../../views/Admin/assignation/Components/CompleteAssigneeInfoModal';
import UnassignToolsFromEmployeeModal from '../../views/Admin/employees/employeeDetails/Components/UnassignToolsFromEmployeeModal';
import { TstoragePlace } from '../../types/assignationFluxes';
import { BlueSmile, NoSmile } from '../../assets/images/components/floatForm';
import { displaySuccessNotification } from '../../utils/displayNudosStandardNotifications';
import { LocationToolsFlux } from '../../views/Admin/logisticServices';
import urlParameter from '../../utils/urlParameter';
import { ICountryDetail } from '../../types/countries';
import ProductPills from '../ProductPills/ProductPills';
import MinSerialIcon from '../../assets/images/components/newCard/MinSerialIcon';
import CantManageTooltip from '../CantManageTooltip/CantManageTooltip';
import { disableToolManagementBeforeDeliveryAlertText } from '../../utils/productDefinitions';
import { useTranslation } from 'react-i18next';
import ToolLogisticsOrigenAndDestinationTag from '../ToolLogisticsOrigenAndDestinationTag/ToolLogisticsOrigenAndDestinationTag';
import { ManageToolActionModal } from '../../views/Admin/NodiInternalProductDetails/Components';
import NewCardManageButton from './components/NewCardManageButton/NewCardManageButton';
import { NudosWipingStatusMarker } from '../DesignSystem/LogisticsAdditionalServices';
import { IadditionalServiceDataForToolLogistics } from '../../types/requestLogisticsModule';
import { LS_ADDITIONAL_SERVICE_TYPE } from '../../types/logisticsServicesModule.types';
import './newCard.scss';

export interface IDetailNewCard {
  sku?: string | null;
  commercialName?: string;
  imageUrl?: string;
  category?: TtoolCategories | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pills?: any;
  brandImage?: string;
  brand?: string;
  statusId?: number;
  serial?: string | null;
  city?: string | null;
  country?: ICountryDetail;
  place?: TstoragePlace;
  locationName?: string | null;
  statusName?: string;
  locationUserName?: string | null;
  locationId?: number | null;
  address?: string | null;
  additionalReferences?: string | null;
  shipmentStatusName?: string | null;
  assignedTo?: string | null;
  productId?: string | number;
  subscriptionId?: number;
  referenceModel?: string;
  nudosCareName?: TnudosCare | null;
  logisticStatus?: 'por confirmar' | 'entregado' | 'cancelado' | 'confirmado' | null;
  destination?: IlogisticsDestinationData;
  userId?: null | number;
  orderId?: number | string | null;
  isBuyAndHold?: number;
  logisticAdditionalServices?: IadditionalServiceDataForToolLogistics[];
}
interface IIDataCard {
  cardIndex: number;
  dataCard: IDetailNewCard;
  updateCardListData: () => void;
  listOfAllCountries: ICountryDetail[];
  listOfCountriesWithLogistics: ICountryDetail[];
  employeeToAssignUserId?: string | null;
}

const NewCard: FC<IIDataCard> = ({
  dataCard,
  updateCardListData,
  listOfAllCountries,
  listOfCountriesWithLogistics,
  employeeToAssignUserId
}: IIDataCard) => {
  const { t } = useTranslation();
  const { push, goBack } = useHistory();
  const { employeeToAssign } = useStore();

  const { commercialName, imageUrl, pills, brandImage, brand, statusId, serial, referenceModel } = dataCard;
  const { city, country, place, locationName, locationUserName, productId, address } = dataCard;
  const { nudosCareName, logisticStatus, destination, statusName, userId, orderId } = dataCard;
  const { category, shipmentStatusName, isBuyAndHold, locationId, assignedTo, logisticAdditionalServices } = dataCard;

  const [currentAssignee, setCurrentAssignee] = useState<IassigneeInfo>();
  const [loadingAssigneeData, setLoadingAssigneeData] = useState(true);
  const [searchedAcquisition, setSearchedAcquisition] = useState<InudoInfo>();
  const [isAssignationModalOpen, setIsAssignationModalOpen] = useState(false);
  const [showUnassignToolModal, setShowUnassignToolModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [openActionModal, setOpenActionModal] = useState<boolean>(false);
  const [requireSL, setRequiereSL] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:tools:';
  const employeeCountryCode = urlParameter('countryCode');
  const acceptLogisticServiceParam = urlParameter('acceptLogisticService');
  const acceptLogisticService = acceptLogisticServiceParam === 'true';
  const notLogisticService = !!urlParameter('notLogisticService');
  const toolIsLocatedInNudosWarehouse = ['nudos', 'warranty', 'diagnostic'].includes(place || '');
  const displayCompleteAssigneeInfoModal = employeeToAssign && isAssignationModalOpen && productId && locationId;
  const canHaveWiping = [PRODUCT_CATEGORY.LAPTOP, PRODUCT_CATEGORY.PC].includes((category || '') as PRODUCT_CATEGORY);
  const wipingService = logisticAdditionalServices?.find(service => service.name === LS_ADDITIONAL_SERVICE_TYPE.WIPING);

  const referenceModelIsCatalog = referenceModel === 'CatalogProduct';
  const referenceModelIsExternal = referenceModel === 'ExternalProduct';
  const toolHasntBeenDelivered = referenceModelIsCatalog && (shipmentStatusName || statusName) !== 'Entregado';
  const isActiveStyles = (statusId !== 1 && statusId !== 4 && statusId !== 7 && statusId) || referenceModelIsExternal;
  const canceledLogisticService = logisticStatus === 'cancelado';
  const deliveredLogisticService = logisticStatus === 'entregado';
  const logisticServiceIsConcluded = !logisticStatus || canceledLogisticService || deliveredLogisticService;
  const inactiveStyles = statusId === 1 || statusId === 4 || statusId === 7 ? 'inactive' : '';
  const disabledLogisticServiceInProcess = !!logisticStatus && !deliveredLogisticService && !canceledLogisticService;

  const warehouseToUserUrl = `/nodi/request-logistics/tools-management/[${productId}]?logistics=true&destination=user&origin=inventory-management&originId=${productId}`;
  const warrantyAndDiagnosticToUserUrl = `/nodi/request-logistics/tools-management/[${productId}]?logistics=true&destination=user&origin=inventory-management&originId=${productId}`;

  const acceptLogisticServiceQueryParam = acceptLogisticServiceParam
    ? `?acceptLogisticService=${acceptLogisticServiceParam}`
    : '';

  const employeeCountryHasLogistics = listOfCountriesWithLogistics.some(
    countryWithLogistics => countryWithLogistics.code === employeeCountryCode
  );

  const blockedCountry = useMemo(() => {
    return (
      !!employeeCountryCode &&
      !listOfCountriesWithLogistics.some(countryWithLogistic => countryWithLogistic.code === country?.code) &&
      !notLogisticService
    );
  }, [listOfCountriesWithLogistics, country?.code, notLogisticService]);
  const pointsActionValidation =
    (statusId !== 1 && statusId !== 4 && statusId !== 7 && statusId) || referenceModelIsExternal;

  const closeLocationModal = () => setOpenLocationModal(false);
  const openModalOfficeToUser = () => setOpenActionModal(true);
  const warehouseToUser = () => push(warehouseToUserUrl);
  const warrantyAndDiagnosticToUser = () => push(warrantyAndDiagnosticToUserUrl);
  const onGoToCardDetails = () => push(onGoToCardDetailsUrl);
  const pointsAction = () => pointsActionValidation && onGoToCardDetails();

  const getEmployeeToAssignUserIdParam = (connector: '?' | '&') => {
    if (employeeToAssignUserId) return `${connector}cuId=${employeeToAssignUserId}`;
    return '';
  };

  const onGoToCardDetailsUrl = `details/${productId}${acceptLogisticServiceQueryParam}${getEmployeeToAssignUserIdParam(
    !acceptLogisticServiceQueryParam ? '?' : '&'
  )}`;

  const officeToUser = (whitSL: boolean) => {
    push(
      `/nodi/request-logistics/tools-management/[${productId}]?logistics=${
        whitSL ? 'true' : 'false'
      }&destination=user&origin=inventory-management&originId=${productId}`
    );
  };

  const otherToUser = (whitSL: boolean) => {
    push(
      `/nodi/request-logistics/tools-management/[${productId}]?logistics=${
        whitSL ? 'true' : 'false'
      }&destination=user&origin=inventory-management&originId=${productId}`
    );
  };

  const isDisabledAssignationOfToolInNudosWarehouse = () => {
    if (!toolIsLocatedInNudosWarehouse) return false;
    if (employeeCountryCode && !employeeCountryHasLogistics) return true;
    if (employeeToAssign && (!employeeToAssign?.address?.address || !employeeToAssign?.address?.city)) return true;
    return false;
  };

  const verifyExistLS = (toolCountry: string) => {
    const availableCountries = listOfCountriesWithLogistics;
    if (availableCountries && availableCountries.length > 0) {
      const toolSL = availableCountries.find(elementCountry => elementCountry.code === toolCountry);
      if (toolSL) return true;
      return false;
    }
    return false;
  };

  const onGoToAssignation = () => {
    const invalidvalidLocation = !['user', 'office', 'nudos', 'warranty', 'diagnostic', 'other'].includes(place || '');
    const toolInLimbo = place === 'user' && !assignedTo && !disabledLogisticServiceInProcess;

    if (isDisabledAssignationOfToolInNudosWarehouse()) {
      return displaySuccessNotification({
        customJSXMessage: (
          <>
            La asignación desde bodega Nudos requiere servicio logístico, por lo que solo puede hacerse a empleados{' '}
            <br /> con dirección registrada y en países habilitados para ello
          </>
        )
      });
    }
    if ((employeeCountryCode && acceptLogisticService) || notLogisticService) {
      if (!employeeToAssign) return goBack();
      if (place === 'nudos' && notLogisticService) {
        displaySuccessNotification({
          customJSXMessage: <>Recuerda que esta asignación requiere servicio logístico</>
        });
      }
      if (locationId || !notLogisticService) {
        return setIsAssignationModalOpen(true);
      }
    } else if (place === 'nudos') {
      warehouseToUser();
    } else if (place === 'office') {
      const availableSL = verifyExistLS(country?.code || '');
      if (availableSL) {
        openModalOfficeToUser();
      } else {
        officeToUser(false);
      }
    } else if (place === 'other') {
      const availableSL = verifyExistLS(country?.code || '');
      if (availableSL) {
        openModalOfficeToUser();
      } else {
        otherToUser(false);
      }
    } else if (place === 'diagnostic') {
      warrantyAndDiagnosticToUser();
    } else if (place === 'warranty') {
      warrantyAndDiagnosticToUser();
    } else if (invalidvalidLocation || toolInLimbo) {
      setOpenLocationModal(true);
    }
  };

  const getToolCity = () => {
    if (toolIsLocatedInNudosWarehouse) return country?.name || '-';
    if (city && address) return truncateText(city, 20);
    if (!city || !address) return t(`${i18nLanguageKey}noLocation`);
    return t(`${i18nLanguageKey}noLocation`);
  };

  const toolLocationStyles =
    getToolCity() === t(`${i18nLanguageKey}noLocation`) || getToolCity() === '-' ? 'inactive' : '';

  const isActive = () => {
    if (isActiveStyles) return 'cursor-pointer';
    if (toolLocationStyles === 'inactive') return '';
    return '';
  };

  const toolsDataToUnassign =
    searchedAcquisition && !!searchedAcquisition?.sku
      ? {
          sku: `${searchedAcquisition?.sku}`,
          productId: +searchedAcquisition?.productId,
          productName: searchedAcquisition?.commercialName,
          productBrand: searchedAcquisition?.brand,
          serial: searchedAcquisition?.serial || ''
        }
      : undefined;

  const toolOriginData = {
    place,
    address,
    city,
    locationName,
    locationUserName,
    country
  };

  const logisticsCountryData =
    currentAssignee?.country?.id &&
    currentAssignee?.country?.flag &&
    currentAssignee?.country?.code &&
    currentAssignee?.country?.name
      ? {
          id: currentAssignee.country.id,
          flag: currentAssignee.country.flag,
          name: currentAssignee.country.name,
          code: currentAssignee.country.code
        }
      : undefined;

  const redirectionUser = () => {
    if (userId) push(`employees/edit/${userId}?from=productsList`);
  };

  const handleRedirectAfterAssignment = async () => {
    if (acceptLogisticService) {
      displaySuccessNotification({
        customJSXMessage: (
          <>La herramienta se asignó correctamente. Un asesor se comunicará contigo para gesionar el servicio.</>
        )
      });
    } else displaySuccessNotification({ customJSXMessage: <>La herramienta fue asignada correctamente.</> });
    goBack();
  };

  const manageToolActionModalCallback = () => {
    if (requireSL) {
      if (place === 'office') officeToUser(true);
      if (place === 'other') otherToUser(true);
    } else {
      if (place === 'office') {
        officeToUser(false);
      } else if (place === 'other') {
        otherToUser(false);
      }
    }
  };

  return (
    <>
      {openActionModal && (
        <ManageToolActionModal
          closeModalCallback={() => setOpenActionModal(false)}
          submitModalCallback={manageToolActionModalCallback}
          updateLogisticsPredefinedCallback={e => setRequiereSL(e)}
        />
      )}
      {showUnassignToolModal && (
        <UnassignToolsFromEmployeeModal
          setShowUnassignModal={setShowUnassignToolModal}
          toolsToUnassign={toolsDataToUnassign ? [toolsDataToUnassign] : toolsDataToUnassign}
          employee={currentAssignee}
          loadingInitialData={loadingAssigneeData}
          logisticsOriginCountry={logisticsCountryData}
          navigationOrigin="inventory"
          categoryName={dataCard?.category}
        />
      )}
      {displayCompleteAssigneeInfoModal && (
        <NudosGenericModal
          modalContent={
            <NudosModalContent
              CustomModalComponent={
                <>
                  <CompleteAssigneeInfoModal
                    employeeToAssign={employeeToAssign}
                    toCloseModal={setIsAssignationModalOpen}
                    productId={`${productId}`}
                    continueCallback={handleRedirectAfterAssignment}
                    assignationIsDisabled={isDisabledAssignationOfToolInNudosWarehouse()}
                    requiresLogistics={acceptLogisticService}
                    originLocationId={locationId}
                    originPlace={place}
                    originCountryId={country?.id}
                  />
                </>
              }
              toCloseModal={() => setIsAssignationModalOpen(false)}
              componentSize="medium"
            />
          }
        />
      )}
      {openLocationModal && (
        <LocationToolsFlux
          listOfAllCountries={listOfAllCountries}
          defaultCountryCode={country?.code}
          productId={productId}
          closeLocationModal={closeLocationModal}
          updateCardListData={updateCardListData}
        />
      )}
      <div className="newCard">
        <div className="boxCard">
          <div className={`containerImg`} onClick={pointsAction}>
            <div className="boxImg">
              <img className="limitImg" src={imageUrl} />
            </div>
          </div>
          <div className={`containerInfo`} onClick={pointsAction}>
            <div className="titleAndBrand">
              <div className="containerBrand">
                {brandImage && <img className="styleImgBrand" src={brandImage} />}
                {!brandImage && brand}
              </div>
              <div className="titleContainer">{commercialName || 'Sin Nombre'}</div>
            </div>
            <ProductPills pills={pills} />
          </div>

          <div className="wipingSection">
            {canHaveWiping && <NudosWipingStatusMarker wipingData={wipingService} hideHoverForToolsWithoutWipingData />}
          </div>

          <div className={`containerModelAndSerial`} onClick={pointsAction}>
            {referenceModelIsExternal ? (
              <NoSmile className="containerIconSmile" />
            ) : (
              <BlueSmile className="containerIconSmile" />
            )}
            <NudosCareTag nudosCareType={nudosCareName} />
            {serial ? (
              <div className={`serialContainer ${serial ? '' : 'empty'} ${inactiveStyles}`}>
                <SerialIcon className="serialIcon" />
                <div className="serial">{serial || 'Sin serial'}</div>
              </div>
            ) : (
              <div className="noSerial">
                <MinSerialIcon /> <div className="ml-1">-</div>
              </div>
            )}
          </div>

          <div
            className={`containerName ${isActive()}`}
            onClick={toolLocationStyles === 'inactive' ? undefined : pointsAction}
          >
            <ToolLogisticsOrigenAndDestinationTag
              origin={toolOriginData}
              destination={destination}
              logisticServiceIsConcluded={logisticServiceIsConcluded}
              assignedTo={assignedTo}
              isBuyAndHold={isBuyAndHold}
              toolHasntBeenDelivered={toolHasntBeenDelivered && place === 'user'}
            />
            <div className="cityContainer">
              <div className="flagContainer" style={{ backgroundImage: `url(${country?.flag || ''})` }} />
              <div className={`city ${toolLocationStyles}`}>
                {getToolCity()}
                {toolLocationStyles === 'inactive' && !toolIsLocatedInNudosWarehouse && !assignedTo && (
                  <div className="addToolLocationButton" onClick={() => setOpenLocationModal(true)}>
                    {t(`${i18nLanguageKey}addToolLocationButton`)}
                  </div>
                )}
                {toolLocationStyles === 'inactive' && !toolIsLocatedInNudosWarehouse && !!assignedTo && (
                  <div className="addToolLocationButton" onClick={redirectionUser}>
                    {t(`${i18nLanguageKey}addToolLocationButton`)}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="containerButton">
            {toolHasntBeenDelivered && (
              <CantManageTooltip
                toolTipText={disableToolManagementBeforeDeliveryAlertText}
                toolTipStyles={{ top: '50px', right: '2px', width: '160px' }}
                redirectionText={orderId ? 'Ver Orden' : ''}
                redirectionUrl={orderId ? `/nodi/orders/details/${orderId}?from=tools` : ''}
              />
            )}
            <NewCardManageButton
              disabledLogisticServiceInProcess={disabledLogisticServiceInProcess}
              setShowUnassignToolModal={setShowUnassignToolModal}
              toolHasntBeenDelivered={toolHasntBeenDelivered}
              setLoadingAssigneeData={setLoadingAssigneeData}
              setSearchedAcquisition={setSearchedAcquisition}
              acceptLogisticService={acceptLogisticService}
              setCurrentAssignee={setCurrentAssignee}
              onGoToAssignation={onGoToAssignation}
              currentAssignee={currentAssignee}
              blockedCountry={blockedCountry}
              assignedTo={assignedTo}
              dataCard={dataCard}
            />
          </div>
          <div className="continerPoints" onClick={pointsAction}>
            <div className={`boxPoints ${inactiveStyles}`}>
              <Points />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCard;
