import { NudosToolTip } from '../../../../../../components/NudosComponents';
import { useTranslation, Trans } from 'react-i18next';
import './EquippingProgressBar.scss';

const EquippingProgressBar = ({ equippingStatusPercentage }: { equippingStatusPercentage: number }) => {
  const { t } = useTranslation();
  const equippingProgressLenguageText = 'nodi:employeeInternal:equippingProgressBar:';
  const equippingProgressContent = {
    0: { statusText: '-', color: '#d6e9ff' },
    25: { statusText: t(`${equippingProgressLenguageText}statusText:critical`), color: '#d6e9ff' },
    50: { statusText: t(`${equippingProgressLenguageText}statusText:acceptable`), color: '#d6e9ff' },
    75: { statusText: t(`${equippingProgressLenguageText}statusText:good`), color: '#d6e9ff' },
    100: { statusText: t(`${equippingProgressLenguageText}statusText:suggested`), color: '#d6e9ff' }
  };

  if (![0, 25, 50, 75, 100].includes(equippingStatusPercentage)) return <></>;
  return (
    <div className="equippingProgressBar">
      <div className="title">
        <div className="text"> {t(`${equippingProgressLenguageText}equippedTo`)}</div>
        <NudosToolTip>
          <div className="tooltip">
            <Trans i18nKey={t(`${equippingProgressLenguageText}tooltip`)} components={{ 1: <strong /> }} />
          </div>
        </NudosToolTip>
      </div>
      <div className="progressBar">
        {equippingStatusPercentage !== 0 && (
          <div
            className="filled"
            style={{
              width: `${equippingStatusPercentage}%`,
              backgroundColor:
                equippingProgressContent[equippingStatusPercentage as keyof typeof equippingProgressContent]?.color
            }}
          >{`${equippingStatusPercentage}%`}</div>
        )}
        {equippingStatusPercentage === 0 && <div className="zero">0%</div>}
      </div>
      <div className={`statusText ${equippingStatusPercentage === 0 ? 'zeroStyle' : ''}`}>
        {equippingProgressContent[equippingStatusPercentage as keyof typeof equippingProgressContent]?.statusText}
      </div>
    </div>
  );
};

export default EquippingProgressBar;
