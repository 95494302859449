import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M5.8125 2C4.49627 3.07841 3.83859 3.73598 2.8125 5C3.83859 6.26402 4.49627 6.92159 5.8125 8"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MinReturnButton = memo(ForwardRef);
export default MinReturnButton;
