import { FC, useEffect, useState } from 'react';
import {
  IPrincipalBodyAlert,
  IlocationAddressForLogistics,
  TlogisticsOriginOrDestinationData
} from '../../../../../../types/requestLogisticsModule';
import { Iemployee } from '../../../../../../types/global';
import { ItoolDataForRequiringLogistics, TstoragePlace } from '../../../../../../types/assignationFluxes';
import { NudosStaticAlertInComponent } from '../../../../../../components/DesignSystem';
import { NudosHoverText } from '../../../../../../components/NudosComponents';
import useRequireLogisticsSummaryData from '../../../../../../state/useRequireLogisticsSummaryData';
import './LocalLogisticServiceSummary.scss';

const LocalLogisticServiceSummary: FC<ILocalLogisticServiceSummary> = ({
  originData,
  destinationData,
  logisticsType,
  selectedDatesRange,
  logisticsEstimatedTimeByCountry,
  placeDestination
}) => {
  const { setEstimatedCollection, setSuperiorAlert, setInferiorAlert } = useRequireLogisticsSummaryData();
  const [timeAlertMessage, setTimeAlertMessage] = useState<string | JSX.Element>('');

  const fieldValueCharactersLimit = 22;
  const isAssignment = logisticsType === 'assignment';
  const datesRangeTitle = isAssignment ? 'Estimado de entrega' : 'Estimado de recolección';
  const noDataText = 'Sin información';

  const originDataAsEmployee = originData as Iemployee;
  const originDataAsLocation = originData as IlocationAddressForLogistics;
  const destinationDataAsEmployee = destinationData as Iemployee;
  const destinationDataAsLocation = destinationData as IlocationAddressForLogistics;
  const selectedOrigin = originDataAsEmployee?.userId ? 'user' : originDataAsLocation?.place || '';
  const selectedDestination = destinationDataAsEmployee?.userId ? 'user' : destinationDataAsLocation?.place || '';

  const originDataCountry = originData?.locationId
    ? originData?.country?.name
    : (originData as IlocationAddressForLogistics)?.countryData?.name;

  const getFormattedName = (place: string, locationData?: TlogisticsOriginOrDestinationData) => {
    if (!locationData) return noDataText;
    const dataAsEmployee = locationData as Iemployee;
    const dataAsOfficeOrOtherAddress = locationData as IlocationAddressForLogistics;
    const dataCountry = dataAsOfficeOrOtherAddress?.countryData || dataAsOfficeOrOtherAddress?.country;
    switch (place) {
      case 'diagnostic':
        return 'Diagnóstico';
      case 'nudos':
        return `Bodegas Nudos ${dataCountry?.name || ''}`;
      case 'warranty':
        return 'Marca';
      case 'office':
        return `Oficinas ${dataCountry?.name || ''}`;
      case 'other':
        return dataAsOfficeOrOtherAddress?.locationName || 'Otra dirección';
      case 'start':
        return dataAsOfficeOrOtherAddress?.city || 'Sin asignar ';
      case 'user':
        return `${dataAsEmployee?.firstName || ''} ${dataAsEmployee?.lastName}`;
      default:
        return 'Sin asignar ';
    }
  };

  const setAlertText = () => {
    if (selectedOrigin === 'diagnostic' || selectedOrigin === 'nudos' || selectedOrigin === 'warranty') {
      const alertComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            {
              titleList: 'El empleado que recibe las herramientas debe:',
              subList: [
                'Completar un formulario especificando los elementos recibidos.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            }
          ]
        }
      };
      return setInferiorAlert(alertComponent); // This set the alert used in the NewLogisticServiceSummary
    }
    const alertComponent: IPrincipalBodyAlert = {
      data: {
        title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
        principalList: [
          { titleList: 'Las herramientas deben ser embaladas en cajas selladas.' },
          { titleList: 'Las cajas no serán manipuladas ni abiertas durante el servicio.' },
          {
            titleList: 'Quienes entregan y reciben las herramientas deben:',
            subList: [
              'Completar un formulario especificando los elementos entregados y recibidos.',
              'Adjuntar fotos del paquete como comprobante.'
            ]
          }
        ]
      }
    };
    return setInferiorAlert(alertComponent); // This set the alert used in the NewLogisticServiceSummary
  };

  const setSuperiorAlertText = () => {
    const warehouseOrGenericText =
      'Los tiempos dentro del servicio son estimados, podrás ver los tiempos reales una vez se active el servicio. ';
    const otherLocationsText = (
      <div className="assignmentSummaryTimeAlert">
        <div>
          Los tiempos dentro del servicio son estimados, podrás ver los tiempos reales una vez se active el servicio.
        </div>
        <div>
          Estaremos en contacto con el punto de recolección días antes para asegurarnos que llegue(n) la herramienta(s)
          en las fechas solicitadas.
        </div>
      </div>
    );
    const textWithTime = `Para entregas en ${
      originDataCountry || 'este país'
    }, los tiempos estimados son de ${logisticsEstimatedTimeByCountry} días hábiles, contando a partir del momento en que se inicia el servicio.`;
    setTimeAlertMessage(logisticsEstimatedTimeByCountry ? textWithTime : warehouseOrGenericText); // This sets the text of the alert for the NewLogisticServiceSummary
    switch (selectedOrigin) {
      case 'nudos':
      case 'diagnostic':
      case 'warranty':
        return setSuperiorAlert(warehouseOrGenericText);
      default:
        setSuperiorAlert(otherLocationsText);
    }
  };

  const collectionName = getFormattedName(selectedOrigin || '', originData);
  const deliveryName = getFormattedName(selectedDestination || placeDestination || '', destinationData);
  const estimatedDelivery = logisticsEstimatedTimeByCountry
    ? `${logisticsEstimatedTimeByCountry} días hábiles`
    : noDataText;

  useEffect(() => {
    setSuperiorAlertText();
    setAlertText();
  }, []);

  useEffect(() => {
    setEstimatedCollection(selectedDatesRange);
  }, []);

  return (
    <div className="LocalLogisticServiceSummary">
      <div className="dataVerificationTitle">Resumen del servicio</div>
      <div className="informationLine">
        <div className="informationField">
          <div className="fieldTitle">Origen</div>
          <NudosHoverText
            onlyIfTruncated
            customClassName="fieldValue"
            text={collectionName?.toLocaleLowerCase()}
            charactersLimit={fieldValueCharactersLimit}
          />
        </div>
        <div className="informationField">
          <div className="fieldTitle">Destino</div>
          <NudosHoverText
            onlyIfTruncated
            customClassName="fieldValue"
            text={deliveryName?.toLocaleLowerCase()}
            charactersLimit={fieldValueCharactersLimit}
          />
        </div>
      </div>
      <div className="informationLine">
        <div className="informationField">
          <div className="fieldTitle">{datesRangeTitle}</div>
          <NudosHoverText
            onlyIfTruncated
            customClassName="fieldValue"
            text={selectedDatesRange || noDataText}
            charactersLimit={19}
          />
        </div>
        {!isAssignment && (
          <div className="informationField">
            <div className="fieldTitle">Estimado de entrega</div>
            <NudosHoverText
              onlyIfTruncated
              customClassName="fieldValue"
              text={estimatedDelivery || noDataText}
              charactersLimit={20}
            />
          </div>
        )}
      </div>
      <NudosStaticAlertInComponent
        type="time"
        alertOptions={{
          title: timeAlertMessage
            ? timeAlertMessage
            : 'Los tiempos dentro del servicio son estimados, podrás ver los tiempos reales una vez se active el servicio.'
        }}
      />
    </div>
  );
};

export default LocalLogisticServiceSummary;

interface ILocalLogisticServiceSummary {
  originData?: TlogisticsOriginOrDestinationData;
  destinationData?: TlogisticsOriginOrDestinationData;
  selectedDatesRange?: string;
  toolsData?: ItoolDataForRequiringLogistics[];
  estimatedDelivery?: string;
  rangeDays?: string;
  logisticsType?: 'assignment' | 'unassignment';
  logisticsEstimatedTimeByCountry?: string;
  placeDestination?: TstoragePlace;
}
