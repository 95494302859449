import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useShopStore from '../../../../../state/useShopContext';
import { BoardFiltersIcon } from '../../../../../assets/images/components/board';
import BoardFilterExpandableSelect, { IrawSelectOption } from './BoardFilterExpandableSelect';
import { AddToolIcon, lightbulbIcon, shoppingCartIcon } from '../../../../../assets/images/views/Admin/main';
import { ImoduleFilter } from '../../../../../types/global';
import { getBoardFilters } from '../../../../../services/services';
import { DTOgetModuleFilters } from '../../../../../types/DTO';
import FilterXMark from '../../../../../assets/images/components/NudosComponents/FilterXMark';
import { NudosButton } from '../../../../../components/NudosComponents';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { ICountryDetail } from '../../../../../types/countries';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { IconBlueDownLoad } from '../../../../../assets/images/views/Admin/dashboard';
import { GenericLoader } from '../../../dashboard/components';
import { getDownLoadInventoryResume } from '../../../../../services/dashboard';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { useTranslation } from 'react-i18next';
import NudosDynamicBanner, {
  INudosDynamicBanner
} from '../../../../../components/DesignSystem/NudosDynamicBanner/NudosDynamicBanner';
import { getCounterExternalTools } from '../../../../../services/uploadExternalTools.service';
import { FREE_TOOLS_TO_UPLOAD_LIMIT } from '../../../../../utils/productDefinitions';
import './BoardFilters.scss';

const BoardFilters = ({
  isUploadToolsButtonActive,
  countriesList,
  formatFilters,
  freePlatform
}: {
  isUploadToolsButtonActive: boolean;
  countriesList?: ICountryDetail[];
  formatFilters?: string;
  freePlatform?: boolean;
  screenName: string;
}) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const orgInfo = formatOrgData();

  const [usersAvailableBoardFilters, setUsersAvailableBoardFilters] = useState<DTOgetModuleFilters>();
  const [openProductType, setOpenProductType] = useState<boolean>(false);
  const [openAvailability, setOpenAvailability] = useState<boolean>(false);
  const [openCountry, setOpenCountry] = useState<boolean>(false);
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  const { boardFilters, setBoardFilters } = useShopStore();
  const [loadingDownLoad, setLoadingDownLoad] = useState<boolean>(false);
  const [bannerMessage, setBannerMessage] = useState<INudosDynamicBanner>();
  const [eventTools, setEventTools] = useState<string>();
  const [loadingNumberExternalReferences, setLoadingNumberExternalReferences] = useState<boolean>(false);
  const [numberOfExternalReferences, setNumberOfExternalReferences] = useState<number>(0);
  const i18nLanguageKey = 'nodi:tools:filtersList:';

  const getOptionActiveStyle = (filterName: string, optionValue?: string | number) => {
    if (optionValue === 'Kits') {
      const searchedFilter = boardFilters.find((filter: ImoduleFilter) => filter.name === 'isKit');
      if (searchedFilter) {
        return true;
      }
      return false;
    }
    const searchedFilter = boardFilters.find((filter: ImoduleFilter) => filter.name === filterName);
    if (!searchedFilter) return false;
    const searchedOption = searchedFilter.values.find((value: string | number) => value === optionValue);
    if (!searchedOption) return false;
    if (searchedOption) return true;
  };
  const countriesData = countriesList?.reduce<{ [key: string]: ICountryDetail }>((preVal, currVal) => {
    const updatedMapOfCountries = { ...preVal };
    updatedMapOfCountries[currVal.code] = currVal;
    return updatedMapOfCountries;
  }, {});

  const formBoardFilters = (filterTitle: string): IrawSelectOption[] => {
    if (!usersAvailableBoardFilters || Object.entries(usersAvailableBoardFilters).length === 0 || !countriesData)
      return [];

    const rawFilterOptions = usersAvailableBoardFilters[filterTitle];
    if (!rawFilterOptions) return [];
    const filtersSelectOptions = rawFilterOptions.map(filterValue => {
      const selectOptionIcon =
        filterValue === 'Suscripción' ? lightbulbIcon : filterValue === 'Compra' ? shoppingCartIcon : undefined;
      const filterOptionName = filterValue;
      return {
        name: filterOptionName,
        icon: selectOptionIcon,
        isActive: getOptionActiveStyle(
          TboardFilterMapping[filterTitle as keyof typeof TboardFilterMapping],
          filterValue
        ),
        id: filterTitle === 'País' ? filterValue : undefined
      };
    });
    return filtersSelectOptions;
  };

  const handleResetFilters = () => setBoardFilters([]);

  const handleClickFilterOption =
    (filterName: string, filterOptionProperty: keyof IrawSelectOption) => (option: IrawSelectOption) => {
      const newFilters = [...boardFilters];
      if (!option) return;
      const currentFilterIndex = newFilters.findIndex(filter => filter.name === filterName);
      if (currentFilterIndex >= 0 && option?.name !== 'Kits') {
        const currentValueIndex = newFilters[currentFilterIndex]?.values.findIndex(
          (value: string | number) => value === option[filterOptionProperty]
        );
        if (currentValueIndex >= 0) {
          if (newFilters[currentFilterIndex]?.values.length <= 1) {
            newFilters.splice(currentFilterIndex, 1);
            setBoardFilters(newFilters);
          } else {
            newFilters[currentFilterIndex]?.values.splice(currentValueIndex, 1);
            setBoardFilters(newFilters);
          }
        } else {
          {
            newFilters[currentFilterIndex]?.values.push(option[filterOptionProperty]);
            setBoardFilters(newFilters);
          }
        }
      } else {
        if (option.name === 'Kits') {
          const searchedFilter = boardFilters.find((filter: ImoduleFilter) => filter.name === 'isKit');
          if (searchedFilter) {
            const filterKits = boardFilters.filter((filter: ImoduleFilter) => filter.name !== 'isKit');
            setBoardFilters([...filterKits]);
          } else {
            const newBoardFilter = {
              name: 'isKit',
              includes: 'in',
              values: [1]
            };
            newFilters.push(newBoardFilter);
            setBoardFilters(newFilters);
          }
        } else {
          const newBoardFilter = {
            name: filterName,
            includes: 'in',
            values: [option[filterOptionProperty]]
          };
          newFilters.push(newBoardFilter);
          setBoardFilters(newFilters);
        }
      }
    };

  const getFilterActiveStatus = (filterTitle: string, alternativeFilterTitle?: string) =>
    boardFilters.some(filter => filter.name === filterTitle || alternativeFilterTitle);
  const filtersTitle =
    boardFilters.length > 0
      ? t(`${i18nLanguageKey}activeFiltersStyle`)
      : t(`${i18nLanguageKey}activeFiltersStyleDefault`);
  const filtersActiveStyle = boardFilters.length > 0 ? 'active' : '';
  const filtersIcon = boardFilters.length > 0 ? <FilterXMark role="active" /> : <BoardFiltersIcon />;

  const filtersList: IboardFilterInfo[] = [
    {
      title: t(`${i18nLanguageKey}title0`),
      openFunction: setOpenProductType,
      openStatus: openProductType,
      rawSelectOptions: formBoardFilters('Tipo_de_producto'),
      handleChangeSelectedOption: handleClickFilterOption('productCategory', 'name'),
      isFilterActive: getFilterActiveStatus('productCategory', 'isKit')
    },
    {
      title: t(`${i18nLanguageKey}title1`),
      openFunction: setOpenAvailability,
      openStatus: openAvailability,
      rawSelectOptions: formBoardFilters('Disponibilidad'),
      handleChangeSelectedOption: handleClickFilterOption('productAvailability', 'name'),
      isFilterActive: getFilterActiveStatus('productAvailability')
    },
    {
      title: t(`${i18nLanguageKey}title2`),
      openFunction: setOpenCountry,
      openStatus: openCountry,
      rawSelectOptions: formBoardFilters('País'),
      handleChangeSelectedOption: handleClickFilterOption('country', 'id'),
      isFilterActive: getFilterActiveStatus('country')
    },
    {
      title: t(`${i18nLanguageKey}title3`),
      openFunction: setOpenLocation,
      openStatus: openLocation,
      rawSelectOptions: formBoardFilters('Ubicación'),
      handleChangeSelectedOption: handleClickFilterOption('location', 'name'),
      isFilterActive: getFilterActiveStatus('location')
    }
  ];
  const getUsersBoardFilters = async () => {
    const JSONorgData = localStorage.getItem('orgData');
    const orgData = JSONorgData && JSON.parse(JSONorgData);
    if (!orgData) return;
    try {
      const userBoardFilters = await getBoardFilters(orgData.organizationId);
      if (userBoardFilters) setUsersAvailableBoardFilters(userBoardFilters);
    } catch {
      displayErrorNotification();
    }
  };

  const handleClickNewToolButton = () => {
    if (freePlatform && numberOfExternalReferences >= FREE_TOOLS_TO_UPLOAD_LIMIT) {
      setBannerMessage({
        bannerSubtitle:
          'Actualiza a Nodi Prime para cargar todas las herramientas que desees, obtener descuentos y disfrutar de mucho más con todo lo que Nudos tiene para ti.',
        bannerTitle: 'Carga todas las herramientas que necesites'
      });
      setEventTools('nodiFreeUploadToolToastNodiPrimeAdvertiseClick');
      segmentTrackEvent({ nodiFreeUploadToolModalNodiPrimeAdvertiseView: { ScreenName: 'Inventory listing' } });
    } else {
      push('/nodi/upload-external-tools');
    }
  };

  const downLoadAction = async () => {
    setLoadingDownLoad(true);
    try {
      const urlResume = await getDownLoadInventoryResume(Number(orgInfo?.organizationId), formatFilters || '');
      if (urlResume) {
        window.open(urlResume, '_blank');
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingDownLoad(false);
    }
  };

  const organizationExternalTools = async () => {
    if (freePlatform) {
      setLoadingNumberExternalReferences(true);
      try {
        const externalData = await getCounterExternalTools(Number(orgInfo?.organizationId));
        setNumberOfExternalReferences(externalData?.length || 0);
      } catch (error) {
        displayErrorNotification();
      } finally {
        setLoadingNumberExternalReferences(false);
      }
    }
  };

  const blueDownloadOnClickAction = () => {
    if (freePlatform) {
      segmentTrackEvent({ nodiFreeCsvToolClick: null });
      setBannerMessage({
        bannerSubtitle:
          'Obtén toda la información de tu inventario en un solo clic, accede a descuentos exclusivos en tus compras y disfruta de todo lo que Nudos tiene para ofrecerte.',
        bannerTitle: 'Descarga información detallada de tu inventario'
      });
      segmentTrackEvent({ nodiFreeCsvModalNodiPrimeAdvertiseView: null });
    } else {
      downLoadAction();
    }
  };

  const submitBannerCallback = () => {
    segmentTrackEvent({ nodiFreeCsvModalNodiPrimeAdvertiseClick: null });
  };

  const resetEvent = () => {
    setEventTools(undefined);
  };

  useEffect(() => {
    getUsersBoardFilters();
    organizationExternalTools();
  }, []);

  return (
    <>
      {bannerMessage && (
        <NudosDynamicBanner
          bannerTitle={bannerMessage?.bannerTitle || ''}
          bannerSubtitle={bannerMessage?.bannerSubtitle || ''}
          openedBannerOrigin="cargue de herramientas"
          closeModalCallback={() => {
            setBannerMessage(undefined);
            setEventTools(undefined);
          }}
          submitBannerCallback={submitBannerCallback}
          alertType={2}
          eventWithinTheAlert={eventTools}
          resetExternalEvent={() => {
            resetEvent();
          }}
        />
      )}
      <section id="boardFilters">
        <div className={`boardFiltersIconContainer ${filtersActiveStyle}`} onClick={handleResetFilters}>
          {filtersIcon}
          <div className="filtersTitle">{filtersTitle}</div>
        </div>
        <div className="filtersContainer">
          {filtersList.map((filter, i) => {
            return (
              <BoardFilterExpandableSelect
                key={`filter-${i}-${filter.title.replace(' ', '')}`}
                filterTitle={filter.title}
                openSelectStatus={filter.openStatus}
                openSelectFunction={filter.openFunction}
                rawSelectOptions={filter.rawSelectOptions}
                handleChangeSelectedOption={filter.handleChangeSelectedOption}
                isFilterActive={filter.isFilterActive}
              />
            );
          })}
        </div>
        <div className="containerButtonNewTool">
          {loadingDownLoad ? (
            <div className="downLoadResume">
              <GenericLoader sizeLoader={10} borderWidth={1} pb={4} />
            </div>
          ) : (
            <div className="downLoadResume cursor-pointer" onClick={() => blueDownloadOnClickAction()}>
              <>
                <div className="blueText">{t('nodi:tools:downLoadResume')}</div>
                <IconBlueDownLoad className="iconPosition" />
              </>
            </div>
          )}
          <NudosButton
            customClassName="newToolButton"
            buttonText={t('nodi:tools:nudosButton')}
            buttonIcon={<AddToolIcon />}
            handleClick={handleClickNewToolButton}
            isButtonDisabled={!isUploadToolsButtonActive || loadingNumberExternalReferences}
          />
        </div>
      </section>
    </>
  );
};

export default BoardFilters;

export enum TboardFilterMapping {
  'Tipo_de_producto' = 'productCategory',
  'Disponibilidad' = 'productAvailability',
  'País' = 'country',
  'Ubicación' = 'location'
}

export interface IboardFilterInfo {
  title: string;
  openFunction: React.Dispatch<React.SetStateAction<boolean>>;
  openStatus: boolean;
  rawSelectOptions: IrawSelectOption[];
  handleChangeSelectedOption: (option: IrawSelectOption) => void;
  isFilterActive: boolean;
}
