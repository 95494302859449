import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="trash-can-svg"
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path className="path-1" d="M1.071 5.35h17.857" stroke={props?.stroke || '#ff664e'} strokeLinecap="round" />
    <path
      className="path-2"
      d="M16.415 5.35H3.578c-.207 3.747-.204 7.465.355 11.188a2.808 2.808 0 0 0 2.776 2.39h6.575c1.39 0 2.57-1.016 2.777-2.39.558-3.723.561-7.44.354-11.187Z"
      fill="#fff"
      stroke={props?.stroke || '#ff664e'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="path-3"
      d="M6.432 5.35v-.713a3.566 3.566 0 1 1 7.131 0v.713M7.857 9.17v5.911M12.137 9.17v5.911"
      stroke={props?.stroke || '#ff664e'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const TrashCanIcon = memo(ForwardRef);
export default TrashCanIcon;
