import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={10} fill="none" ref={ref} {...props}>
    <g>
      <path
        className="path1"
        fill="#CCC"
        d="M7.64 4.616h1.852a.472.472 0 0 0 .472-.472.463.463 0 0 0-.472-.472H7.64a.472.472 0 1 0 0 .944ZM4.363 6.415H3.289a.472.472 0 0 1-.408-.241.478.478 0 0 0-.814.5 1.407 1.407 0 0 0 1.222.713h.13a.472.472 0 1 0 .925 0A1.417 1.417 0 0 0 5.78 5.943a1.306 1.306 0 0 0-1.093-1.297l-1.435-.24a.361.361 0 0 1-.306-.352.472.472 0 0 1 .473-.473h1.074a.463.463 0 0 1 .407.232.472.472 0 1 0 .815-.472 1.417 1.417 0 0 0-1.222-.704h-.13a.472.472 0 0 0-.926 0 1.417 1.417 0 0 0-1.435 1.417A1.305 1.305 0 0 0 3.094 5.34l1.436.24a.361.361 0 0 1 .305.362.472.472 0 0 1-.472.472ZM7.64 6.32a.472.472 0 1 0 0-.945.472.472 0 0 0 0 .944Z"
      />
      <path
        className="path2"
        stroke="#CCC"
        strokeLinejoin="round"
        d="M9.687.656h-7.37c-1.023 0-1.852.83-1.852 1.852V7.49c0 1.022.829 1.851 1.852 1.851h7.37c1.023 0 1.852-.829 1.852-1.851V2.508c0-1.023-.83-1.852-1.852-1.852Z"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const MoneyIcon = memo(ForwardRef);
export default MoneyIcon;
