import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { NudosBreadcrumbButton } from '../../../../components/NudosComponents';
import { createNewEmployee } from '../../../../services/employees.service';
import { INewEmployeeInfo } from '../../../../types/global';
import NewEmployeeForm from '../Components/NewEmployeeForm';
import { DTOinformationCreateNewEmployee } from '../../../../types/DTO';
import urlParameter from '../../../../utils/urlParameter';
import segment, { segmentTrackEvent } from '../../../../utils/segment';
import { ICountryDetail } from '../../../../types/countries';
import { getCountriesList } from '../../../../services/countries.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../utils/displayNudosStandardNotifications';
import useStateSubscriptions from '../../../../state/useStateSubscriptions';
import { useTranslation } from 'react-i18next';
import { getListOfCountriesByOfferedService } from '../../../../utils/getCountries';
import { ICartShipping } from '../../../../types/cart';

import './CreateEmployee.scss';

const CreateEmployee = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const origin = urlParameter('origin');
  const fromDashboard = origin && origin === 'dashboard';
  const fromFirstCreate = origin && origin === 'employeesList';
  const { stateSubscription } = useStateSubscriptions();
  const { state }: { state: IemployeeSearchbarCreateEmployeeNavigationState } = useLocation();
  const { originUrl, originText, referenceInitialData, uniqueEmployeeSearchbarIdentifier } = state || {};

  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();
  const [listOfCountriesWithLogistics, setListOfCountriesWithLogistics] = useState<ICountryDetail[]>();
  const [createAndAssign, setCreateAndAssign] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [requireLogistics, setRequireLogistics] = useState<boolean>(false);
  const navigationOrigin = originUrl ? 'logistic-service' : 'create-employee';

  const returnAction = (userId?: number, createAndAssignParam?: boolean) => {
    if (originUrl && originUrl === '/catalogue/checkout') return push(originUrl, { referenceInitialData, userId });
    if (originUrl && originUrl === '/nodi/upload-external-tools')
      return push(originUrl, { newEmployeeUserId: userId, uniqueEmployeeSearchbarIdentifier });
    if (originUrl) return push(userId ? originUrl + '&from=create' : originUrl);
    if (!userId && fromFirstCreate) {
      return push('/nodi/employees');
    }
    if (stateSubscription?.levelSubscription !== 'Lite' || !userId) {
      return push('/nodi/dashboard');
    }
    const navigationParam = `&origin=employee-details&originId=${userId}`;
    const params = `?logistics=${requireLogistics || false}${navigationParam}`;
    const route =
      createAndAssign || createAndAssignParam
        ? `/nodi/request-logistics/assignment/${userId}${params}`
        : `/nodi/employees/details/${userId}`;
    push(route);
  };

  const handleSaveNewEmployee = async (employee: INewEmployeeInfo, createAndAssignParam?: boolean) => {
    if (!employee || !employee?.country?.id) return;
    setLoading(true);

    const informationBody: DTOinformationCreateNewEmployee = {
      organizationId: employee.organizationId,
      firstName: employee.firstName,
      lastName: employee.lastName,
      personalId: employee?.personalId,
      email: employee.email,
      phone: employee.phone,
      city: employee.city,
      cityId: employee?.cityId,
      area: employee.area,
      position: employee.position,
      address: { ...employee.address, cityId: employee?.cityId },
      countryId: employee.country.id,
      countryPhoneId: employee?.countryPhone?.id
    };
    const profilePhoto = employee.uploadedPhoto;
    const formData = new FormData();
    profilePhoto && formData.append('file', profilePhoto);
    formData.append('body', JSON.stringify(informationBody));
    try {
      const response = await createNewEmployee(formData);
      if (response) {
        displaySuccessNotification({ customJSXMessage: <>El empleado se ha creado correctamente.</> });
        segmentTrackEvent({
          alertNewEmployeeView: {
            EmployeeArea: employee?.area,
            EmployeeCity: employee?.city,
            EmployeeName: `${employee?.firstName} ${employee?.lastName}`
          }
        });
        segment.sessionEndingEvent();
        returnAction(response?.userId, createAndAssignParam);
      }
    } catch (error) {
      const err = error as AxiosError;
      const errorCode = err?.response?.status;
      const errorMessage = err?.response?.data?.message;

      if (err?.response?.data?.message === 'phoneAlreadyRegistered') {
        displayErrorNotification({ customJSXMessage: <>Este número de teléfono ya está registrado</> });
      } else if (errorCode === 409) {
        displayErrorNotification({
          customJSXMessage: <>{t('authentication:SignUpForm:useSignUpFormStep3Controller:setEmailExistError')}</>
        });
      } else if (errorMessage) {
        displayErrorNotification({
          customJSXMessage: errorMessage === 'Internal server error' ? undefined : <>{errorMessage} </>
        });
      } else {
        displayErrorNotification();
      }
    } finally {
      setLoading(false);
    }
  };

  const getCountries = async () => {
    try {
      const dataCountries = await getCountriesList();
      setCountriesList(dataCountries);
    } catch {
      displayErrorNotification();
    }
  };

  const getReturnText = () => {
    if (originText) return originText;
    if (origin === 'dashboard') return 'Dashboard';
    return t('nodi:returnTextBasedOnPreviousLocation:employee');
  };

  useEffect(() => {
    getCountries();
    getListOfCountriesByOfferedService(setListOfCountriesWithLogistics, 'logisticService');
    if (fromDashboard)
      displaySuccessNotification({
        customJSXMessage: <>{t('nodi:dashboard:body:employees:displaySuccessNotification')}</>
      });
  }, []);

  return (
    <div className="createEmployee">
      <NudosBreadcrumbButton returnText={getReturnText()} returnAction={returnAction} />
      {countriesList && (
        <NewEmployeeForm
          countriesList={countriesList}
          handleSubmitCustomCallback={handleSaveNewEmployee}
          navigationOrigin={navigationOrigin}
          isButtonLoading={loading}
          customTextButton={originUrl ? 'Guardar y volver' : undefined}
          setCreateAndAssign={setCreateAndAssign}
          setRequireLogistics={setRequireLogistics}
          listOfCountriesWithLogistics={listOfCountriesWithLogistics}
        />
      )}
    </div>
  );
};

export default CreateEmployee;

export interface IemployeeSearchbarCreateEmployeeNavigationState {
  originUrl?: string;
  originText?: string;
  referenceInitialData?: ICartShipping;
  uniqueEmployeeSearchbarIdentifier?: string;
  createFromSpecificCountry?: string;
  requireAllFields?: boolean;
}
