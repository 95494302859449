import { IconBroom } from '../../../../assets/DesignSystem/SVGComponents';
import { LS_ADDITIONAL_SERVICE_STATUS, LS_STATUS } from '../../../../types/logisticsServicesModule.types';
import { IadditionalServiceDataForToolLogistics } from '../../../../types/requestLogisticsModule';
import NudosWipingStatusHoverData from '../NudosWipingStatusHoverData/NudosWipingStatusHoverData';
import './NudosWipingStatusMarker.scss';

const NudosWipingStatusMarker = ({
  wipingData,
  hideHoverForToolsWithoutWipingData = false
}: {
  wipingData?: IadditionalServiceDataForToolLogistics;
  hideHoverForToolsWithoutWipingData?: boolean;
}) => {
  const { status, logisticServiceStatus } = wipingData || {};
  const { statusName = '' } = status || {};

  const getBroomIconStyles = () => {
    if (logisticServiceStatus === LS_STATUS.CANCELLED) {
      return {
        markerStyles: hideHoverForToolsWithoutWipingData ? 'disabled hideHover' : 'disabled',
        hideHover: hideHoverForToolsWithoutWipingData
      };
    }
    switch (statusName) {
      case LS_ADDITIONAL_SERVICE_STATUS.TO_COMPLETE:
        return {
          markerStyles: ''
        };
      case LS_ADDITIONAL_SERVICE_STATUS.COMPLETED:
        return {
          markerStyles: ''
        };
      case LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED:
        return {
          markerStyles: 'redAlert'
        };
      default:
        return {
          markerStyles: hideHoverForToolsWithoutWipingData ? 'disabled hideHover' : 'disabled',
          hideHover: hideHoverForToolsWithoutWipingData
        };
    }
  };

  const { markerStyles, hideHover = false } = getBroomIconStyles();

  return (
    <div className={`nudosWipingStatusMarker ${markerStyles}`}>
      <IconBroom className="wipingStatusMarkerIcon" />
      <div className="wipingText">Wiping</div>
      {!hideHover && <NudosWipingStatusHoverData wipingData={wipingData} />}
    </div>
  );
};

export default NudosWipingStatusMarker;
