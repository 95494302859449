import { Link } from 'react-router-dom';
import { IassigneeInfo } from '../../../../../../../types/global';
import { formatShortDate } from '../../../../../../../utils/formatDatesAndHours';
import { CameraIcon } from '../../../../../../../assets/images/components/NudosComponents';
import { useTranslation } from 'react-i18next';
import ManualAdjustmentSection from '../ManualAdjustmentSection/ManualAdjustmentSection';
import { NudosHoverText } from '../../../../../../../components/NudosComponents';
import { NudosRedirectionIcon } from '../../../../../../../components/DesignSystem';
import './PreviousAssignmentCard.scss';

const PreviousAssignmentCard = ({
  assignee,
  updateDataCallback
}: {
  assignee: IassigneeInfo;
  updateDataCallback?: () => void;
}) => {
  const { t } = useTranslation();
  const { firstName, lastName, photoProfile, country, logisticServiceId } = assignee;
  const { entryDate, exitDate } = assignee;
  const i18nLanguageKey = 'nodi:toolInternal:assignedCard:';

  const employeeFullName = `${firstName} ${lastName}`;
  const previousAssigneeStyles = 'previousAssignment';
  const profilePhotoStyles = photoProfile ? '' : 'noPhoto';
  const profilePhotoUrl = {
    backgroundImage: `url('${photoProfile ? photoProfile.replaceAll(' ', '%20') : CameraIcon}')`
  };
  const flagImageUrl = { backgroundImage: `url(${country?.flag || ''})` };
  const toolTimeZone = assignee?.country?.timeZone || undefined;
  const formattedUnassignmentDate = exitDate ? formatShortDate(exitDate, toolTimeZone)?.onlyDate : '';
  const formattedAssignmentDate = entryDate ? formatShortDate(entryDate, toolTimeZone)?.onlyDate : '';

  const redirectionDataObject = {
    pathname: `/nodi/logistics-services/details/${logisticServiceId}`,
    state: { returnText: 'Herramienta' }
  };

  const seeLogisticServiceButton = logisticServiceId ? (
    <Link to={redirectionDataObject} className="seeLogisticLink">
      Ver servicio
    </Link>
  ) : null;

  const employeeDetailsRedirectionData = {
    pathname: `/nodi/employees/details/${assignee.userId}`,
    state: {
      returnText: 'Herramienta'
    }
  };

  return (
    <div className={`PreviousAssignmentCard ${previousAssigneeStyles}`}>
      <div className={`profilePhoto ${previousAssigneeStyles} ${profilePhotoStyles}`} style={profilePhotoUrl}>
        {exitDate && <div className="photoFilter" />}
        <div className="containerFlag">
          <div className="flagContainer" style={flagImageUrl} />
        </div>
      </div>
      <div className="assigneeInfoSection">
        <div className="nameAndRedirection">
          <NudosHoverText onlyIfTruncated customClassName="name" text={employeeFullName} charactersLimit={14} />
          <NudosRedirectionIcon redirectionDataObject={employeeDetailsRedirectionData} />
        </div>
        {seeLogisticServiceButton}
      </div>
      <div className="managementDatesSection">
        {!!exitDate && (
          <div className="unassignmentDate">
            <span>{t(`${i18nLanguageKey}unassignmentDate`)}</span>
            <span className="font-medium">{formattedUnassignmentDate}</span>
          </div>
        )}
        <div className="assignmentDate">
          <span>{t(`${i18nLanguageKey}assignmentDate`)}</span>
          <span className="font-medium">{formattedAssignmentDate}</span>
        </div>
      </div>
      <ManualAdjustmentSection assignee={assignee} updateDataCallback={updateDataCallback} />
    </div>
  );
};

export default PreviousAssignmentCard;
