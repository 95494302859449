import React, { FC, useEffect, useState } from 'react';
import IconGreenThunder from '../../../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import IconWarningInformation from '../../../../../../assets/DesignSystem/SVGComponents/IconWarningInformation';
import { ILogisticQuoteGeneralData } from '../../../../../../types/logisticsServicesModule.types';
import { formatCost } from '../../../../../../utils/formatNumbers';
import './InformationText.scss';

interface IInformationText {
  typePlan: 'Care' | 'Free' | 'Lite';
  logisticServiceQuoteData?: ILogisticQuoteGeneralData;
}

interface IStatePlan {
  message: React.ReactNode;
  iColor: string;
  thunderColor: string;
  tooltipTitle: string;
  subtitleTooltip: React.ReactNode;
  lCopy: string;
  rCopy: string;
  optionalLabel?: {
    label: string;
    link: string;
  };
}

const InformationText: FC<IInformationText> = ({ typePlan, logisticServiceQuoteData }: IInformationText) => {
  const [statePlan, setStatePlan] = useState<IStatePlan>();
  const [showInformation, setShowInformation] = useState<boolean>(false);
  const intialData = () => {
    if (typePlan === 'Care') {
      setStatePlan({
        iColor: '#81BC3F',
        message: <>Servicio cubierto por Nudos Care</>,
        thunderColor: '#81BC3F',
        tooltipTitle: 'Nudos Care',
        subtitleTooltip: (
          <>
            Nudos Care te ayuda a tener tu producto
            <br /> asegurado con servicios de logística y TI.
          </>
        ),
        lCopy: 'Total ahorrado en este servicio ',
        rCopy: `$${formatCost(Number(logisticServiceQuoteData?.nudosCareSaving || 0), 2)}USD`
      });
    }
    if (typePlan === 'Free') {
      setStatePlan({
        iColor: '#7B61FF',
        message: (
          <>
            Ahorra
            <strong>
              &nbsp;$
              {formatCost(Number(logisticServiceQuoteData?.nudosPrimeCouldSaving), 2)}
              &nbsp;USD&nbsp;
            </strong>
            con Nudos Prime
          </>
        ),
        thunderColor: '#CCCCCC',
        tooltipTitle: 'Ahorro con Nodi Prime',
        subtitleTooltip: (
          <>
            Por Comisión de gestión ahorras
            {` $${formatCost(Number(logisticServiceQuoteData?.managementCommission || 0), 2)} USD`}
            <br />
            Por Comisión de pago ahorras
            {` $${formatCost(Number(logisticServiceQuoteData?.paymentCommission || 0), 2)} USD`}
          </>
        ),
        lCopy: 'Podrías ahorrar en este servicio',
        rCopy: `$ ${formatCost(Number(logisticServiceQuoteData?.nudosPrimeCouldSaving || 0), 2)} USD`,
        optionalLabel: {
          label: 'Solicitar información',
          link: 'asdasd'
        }
      });
    }
    if (typePlan === 'Lite') {
      setStatePlan({
        iColor: '#7B61FF',
        message: (
          <>
            Estás ahorrando
            <strong>
              &nbsp;
              {`$${formatCost(Number(logisticServiceQuoteData?.nudosPrimeSaving || 0), 2)} USD`}
            </strong>
            &nbsp;con Nodi Prime
          </>
        ),
        thunderColor: '#FFBB00',
        tooltipTitle: 'Ahorro con Nodi Prime',
        subtitleTooltip: (
          <>
            Por Comisión de gestión ahorras
            {` $${formatCost(
              Number(logisticServiceQuoteData?.managementCommission || 0) -
                Number(logisticServiceQuoteData?.managementCommissionToCharge || 0),
              2
            )} USD`}
            <br />
            Por Comisión de pago ahorras{' '}
            {`$${formatCost(Number(logisticServiceQuoteData?.paymentCommission || 0), 2)} USD`}
          </>
        ),
        lCopy: 'Total ahorrado en este servicio',
        rCopy: `$${formatCost(Number(logisticServiceQuoteData?.nudosPrimeSaving || 0), 2)} USD`
      });
    }
  };

  useEffect(() => {
    intialData();
  }, [typePlan]);

  return (
    <div id="informationText">
      <div className="boxInformationText">
        <div className="freePlan">
          <div className="iconPlanContainer">
            <IconGreenThunder fill={statePlan?.thunderColor} />
          </div>
          <div className="purpleText" style={{ color: statePlan?.iColor }}>
            {statePlan?.message}
          </div>
          <div className="containerInformationIcon">
            <IconWarningInformation
              className="cursor-pointer"
              color={statePlan?.iColor}
              onMouseOverCapture={() => {
                setShowInformation(true);
              }}
              onMouseMoveCapture={() => {
                setShowInformation(true);
              }}
              onMouseOutCapture={() => {
                setShowInformation(false);
              }}
              onMouseOut={() => {
                setShowInformation(false);
              }}
            />
            {showInformation && (
              <div className="floatAlertInformation" style={{ height: statePlan?.optionalLabel ? '94px' : '' }}>
                <div className="floatTitleInf" style={{ color: statePlan?.iColor }}>
                  {statePlan?.tooltipTitle}
                </div>
                <div className="floatSubtitleInfo">{statePlan?.subtitleTooltip}</div>
                <div className="containerSparateItems">
                  <div className="lTextItem">{statePlan?.lCopy}</div>
                  <div className="rTextItem">{statePlan?.rCopy}</div>
                </div>
                {statePlan?.optionalLabel && <div className="optionalLabelItem">{statePlan?.optionalLabel?.label}</div>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationText;
