import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" ref={ref} {...props}>
    <path
      fill="#fff"
      stroke="#383838"
      d="M2.496 24.057c.369 3.29 3.043 5.902 6.35 6.062 2.285.11 4.62.167 7.154.167a147.5 147.5 0 0 0 7.154-.167c3.307-.16 5.981-2.772 6.35-6.062.248-2.215.452-4.487.452-6.801s-.203-4.585-.452-6.8c-.369-3.291-3.043-5.903-6.35-6.062-2.285-.11-4.62-.168-7.154-.168s-4.869.057-7.154.168c-3.307.159-5.981 2.771-6.35 6.061-.248 2.216-.452 4.487-.452 6.801s.204 4.585.452 6.801Z"
    />
    <path
      fill="#383838"
      d="M11.365 1.714a.5.5 0 1 0-1 0h1Zm-1 5.804a.5.5 0 0 0 1 0h-1Zm11.254-5.804a.5.5 0 0 0-1 0h1Zm-1 5.804a.5.5 0 1 0 1 0h-1ZM10.365 1.714v5.804h1V1.714h-1Zm10.254 0v5.804h1V1.714h-1ZM2.712 13.071h26.714v-1H2.712v1Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconCalendar = memo(ForwardRef);
export default IconCalendar;
