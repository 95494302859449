import { FC, useEffect, useState } from 'react';
import { IFreeOnboardingStep } from '../OnboardingNodiFreeSteps/OnboardingNodiFreeSteps';
import { v4 as uuid } from 'uuid';
import { NudosButton } from '../../../NudosComponents';
import { PolygonOnboarding } from '../../../../assets/images/components/Onboarding';

import IconGreenThunder from '../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import { IconSimpleCheck, IconSimpleX, IconWarning } from '../../../../assets/DesignSystem/SVGComponents';
import XSign from '../../../../assets/images/components/NudosComponents/XSign';
import { useTranslation } from 'react-i18next';
import useLanguagePlatformState from '../../../../state/useLenguageState';

import './ItemNodiFreeInfoOnboarding.scss';

interface IItemNodiFreeInfoOnboarding {
  infoStep: IFreeOnboardingStep;
  numberSteps: number;
  elementIndex: number;
  nextStep: (step: number) => void;
  previusStep: (step: number) => void;
  openDynamicBanner: (actualStep: IFreeOnboardingStep) => void;
  finishOnboarding: (actualStep: IFreeOnboardingStep) => void;
}

const ItemNodiFreeInfoOnboarding: FC<IItemNodiFreeInfoOnboarding> = ({
  infoStep,
  numberSteps,
  elementIndex,
  nextStep,
  previusStep,
  openDynamicBanner,
  finishOnboarding
}: IItemNodiFreeInfoOnboarding) => {
  const { t } = useTranslation();
  const { platformLanguage } = useLanguagePlatformState();
  const buttonsTranslations = 'nodi:onboarding:buttons:';
  const stepsProgressBar = Array(numberSteps).fill('');
  const [position, setPosition] = useState<{ top: number; left: number } | undefined>();

  const onBoardingElementPosition = () => {
    const myElement = document.getElementById(infoStep.positionId);
    if (myElement) {
      if (infoStep.type == 'menu-item') {
        const positionTooltip = myElement ? myElement.getBoundingClientRect() : undefined;
        if (positionTooltip) {
          setPosition({ top: positionTooltip.top, left: positionTooltip?.right + 5 });
        }
      }
      if (infoStep.type === 'inferior-menu-item') {
        const positionTooltip = myElement ? myElement.getBoundingClientRect() : undefined;
        if (positionTooltip) {
          setPosition({ top: positionTooltip.top - 398, left: positionTooltip?.left + 23 });
        }
      }
      if (infoStep.type === 'center-menu-item') {
        const positionTooltip = myElement ? myElement.getBoundingClientRect() : undefined;
        if (positionTooltip) {
          setPosition({ top: positionTooltip.top - 150, left: positionTooltip?.right + 5 });
        }
      }
      if (infoStep.type === 'header-item-button') {
        const positionTooltip = myElement ? myElement.getBoundingClientRect() : undefined;
        if (positionTooltip) {
          setPosition({ top: positionTooltip.top + (positionTooltip.height + 30), left: positionTooltip.right - 391 });
        }
      }
    } else {
      setPosition({ top: 0, left: 0 });
    }
  };
  useEffect(() => {
    onBoardingElementPosition();
  }, [elementIndex]);

  return (
    <>
      {position && (
        <div id="itemNodiFreeInfoOnboarding" style={{ top: position?.top, left: position?.left }}>
          <div className="boxItemNodiFreeInfoOnboarding">
            <div
              className="closeButtonOnboarding"
              onClick={() => {
                openDynamicBanner(infoStep);
              }}
            >
              <XSign fill="#B3B3B3" />
            </div>
            <div className={`polygonContainer ${infoStep?.polygonPosition}`}>
              <PolygonOnboarding fill={infoStep.polygonColor} />
            </div>
            <div className="headerItem">
              <div className="stepImageContainer">
                <img
                  alt={infoStep?.stepImage?.image}
                  style={{ height: infoStep?.stepImage?.height, width: infoStep?.stepImage?.width }}
                  src={infoStep?.stepImage?.image}
                />
              </div>
              <div className="progressBarContainer">
                <div className="progressBarBox">
                  {stepsProgressBar &&
                    stepsProgressBar.length > 0 &&
                    stepsProgressBar.map((data, index) => {
                      return (
                        <div key={uuid()} className={`stepMark ${index === elementIndex ? 'selectedStep' : ''}`} />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="bodyOnboardingItem">
              <div className="stepOnboardingTitle">{infoStep.title}</div>
              {(infoStep.includedServicesList.length > 0 || infoStep.blockedServicesList.length > 0) && (
                <div className="containerModuleList">
                  <div className={`boxGrayList ${platformLanguage === 'enUS' ? 'maxGraySpace' : ''}`}>
                    {infoStep.includedServicesList.map((item, index) => {
                      return (
                        <div
                          className={`boxIncludedCheckIcon ${
                            index + 1 === infoStep.includedServicesList.length &&
                            infoStep.blockedServicesList.length === 0
                              ? 'noMarginButton'
                              : ''
                          }`}
                          key={`included-service-list-${item}-index-${index}`}
                        >
                          <div className="includeCheckIcon">
                            <div className="containerCheckIcon">
                              <IconSimpleCheck />
                            </div>
                          </div>
                          <div className="includedListText">{item}</div>
                        </div>
                      );
                    })}
                    {infoStep.blockedServicesList.map((item, index) => {
                      return (
                        <div className="boxBlockedCheckIcon" key={`included-blocked-list-${item}-index-${index}`}>
                          <div className="blockedXIcon">
                            <div className="containerXIcon">
                              <IconSimpleX />
                            </div>
                          </div>
                          <div className="blockedListText">
                            {item}
                            <span className="bluePrimeText">
                              {t(`${buttonsTranslations}freeLabel`)}
                              <div className="containerBlueIconCenter">
                                <IconGreenThunder fill="#7B61FF" />
                              </div>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {infoStep.warningMessage && (
                <div className="containerWarningMessage">
                  <div className="boxYellowWarning">
                    <div className="containerIconWaning">
                      <IconWarning className="iconWarning" />
                    </div>
                    <div className="yellowWarningText">{infoStep.warningMessage}</div>
                  </div>
                </div>
              )}
              <div className="containerButtonsOnboarding">
                {numberSteps > elementIndex + 1 ? (
                  <>
                    {elementIndex > 0 && (
                      <NudosButton
                        colorPalette="secondary"
                        customClassName="marginBackButton"
                        buttonText={t(`${buttonsTranslations}previous`)}
                        customHeight="38px"
                        customWidth="120px"
                        handleClick={() => {
                          setPosition(undefined);
                          previusStep(elementIndex - 1);
                        }}
                      />
                    )}
                    <NudosButton
                      buttonText={t(`${buttonsTranslations}next`)}
                      customHeight="38px"
                      customWidth="120px"
                      handleClick={() => {
                        setPosition(undefined);
                        nextStep(elementIndex + 1);
                      }}
                    />
                  </>
                ) : (
                  <NudosButton
                    buttonText={t(`${buttonsTranslations}finish`)}
                    customHeight="38px"
                    customWidth="120px"
                    handleClick={() => {
                      setPosition(undefined);
                      finishOnboarding(infoStep);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ItemNodiFreeInfoOnboarding;
