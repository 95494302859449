import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M4.59472 5.59984C5.82569 5.59984 6.8236 4.60194 6.8236 3.37097C6.8236 2.13999 5.82569 1.14209 4.59472 1.14209C3.36375 1.14209 2.36584 2.13999 2.36584 3.37097C2.36584 4.60194 3.36375 5.59984 4.59472 5.59984Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00877 8.73133C9.09903 9.45751 8.36283 10.858 7.05289 10.858H2.13369C0.823753 10.858 0.0875571 9.45751 1.17781 8.73133C2.15575 8.08003 3.33019 7.70044 4.59329 7.70044C5.8564 7.70044 7.03084 8.08003 8.00877 8.73133Z"
      stroke="white"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SingleEmployee = memo(ForwardRef);
export default SingleEmployee;
