import create from 'zustand';

export interface iState {
  showZoomAlert: boolean;
  setShowZoomAlert: (showZoomAlert: boolean) => void;
}

const useStateZoomAlert = create<iState>(set => ({
  showZoomAlert: true,
  setShowZoomAlert: showZoomAlert => set({ showZoomAlert })
}));

export default useStateZoomAlert;
