import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NudosGenericModal } from '../../../../../../components/NudosComponents';
import { IemployeeDataToUnassign, ItoolDataToUnassign } from '../../../../../../types/assignationFluxes';
import { ICountryDetail } from '../../../../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../../../../utils/getCountries';
import ModalPredefineLogistics from './ModalPredefineLogistics';
import { GenericLoader } from '../../../../dashboard/components';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { TtoolCategories } from '../../../../../../types/global';

const UnassignToolsFromEmployeeModal: FC<IunassignToolsFromEmployeeModal> = props => {
  const {
    setShowUnassignModal,
    employee,
    loadingInitialData,
    toolsToUnassign,
    logisticsOriginCountry,
    logisticsPredefinedProp,
    navigationOrigin,
    navigationOriginId,
    categoryName
  } = props;
  const { push } = useHistory();
  const [listOfCountriesWithLogistics, setListOfCountriesWithLogistics] = useState<ICountryDetail[]>();
  const [originCountryIsOpenForLogistics, setOriginCountryIsOpenForLogistics] = useState<boolean>();
  const [logisticsPredefined, setLogisticsPredefined] = useState(false);

  const pendingFindingIfLogisticsIsPosible = originCountryIsOpenForLogistics === undefined;

  const handleCloseModal = () => setShowUnassignModal(false);

  const startUnassignmentFluxCallback = () => {
    if (!employee?.userId || !toolsToUnassign || !toolsToUnassign.length) return;
    const toolsIds = toolsToUnassign?.map(tool => tool?.productId);
    const toolsParam = JSON.stringify(toolsIds);
    const navigationParam = `&origin=${navigationOrigin}${navigationOriginId ? `&originId=${navigationOriginId}` : ''}`;
    const params = `?tools=${toolsParam}&logistics=${logisticsPredefined || false}${navigationParam}`;
    push(`/nodi/request-logistics/unassignment/${employee?.userId}${params}`);
    if (logisticsPredefined) {
      segmentTrackEvent({
        nodiLogisticServicesHireClick: {
          CategoryName: categoryName || '',
          ProductCountry: logisticsOriginCountry?.name || ''
        }
      });
    } else {
      segmentTrackEvent({
        nodiLogisticServicesUpdateClick: {
          CategoryName: categoryName || '',
          ProductCountry: logisticsOriginCountry?.name || ''
        }
      });
    }
  };

  const modalContent =
    pendingFindingIfLogisticsIsPosible || loadingInitialData ? (
      <GenericLoader />
    ) : (
      <ModalPredefineLogistics
        title={'Desasignación'}
        subtitle={'¿Requieres de los servicios logísticos de Nudos para recoger las herramientas del empleado?'}
        closeModalCallback={handleCloseModal}
        submitModalCallback={startUnassignmentFluxCallback}
        updateLogisticsPredefined={newValue => setLogisticsPredefined(newValue)}
        navigationOrigin={navigationOrigin}
      />
    );

  useEffect(() => {
    if (!listOfCountriesWithLogistics || listOfCountriesWithLogistics.length < 1 || !logisticsOriginCountry) return;
    const isOpenForLogistics = listOfCountriesWithLogistics?.some(
      countryWithLogistics => countryWithLogistics?.code === logisticsOriginCountry?.code
    );
    setOriginCountryIsOpenForLogistics(isOpenForLogistics);
    !isOpenForLogistics && startUnassignmentFluxCallback();
  }, [listOfCountriesWithLogistics, logisticsOriginCountry]);

  useEffect(() => {
    logisticsPredefinedProp && setLogisticsPredefined(true);
  }, [logisticsPredefinedProp]);

  useLayoutEffect(() => {
    getListOfCountriesByOfferedService(setListOfCountriesWithLogistics, 'logisticService');
  }, []);

  return <div className="UnassignToolsFromEmployeeModal">{<NudosGenericModal modalContent={modalContent} />}</div>;
};

export default UnassignToolsFromEmployeeModal;

interface IunassignToolsFromEmployeeModal {
  setShowUnassignModal: React.Dispatch<React.SetStateAction<boolean>>;
  employee?: IemployeeDataToUnassign;
  loadingInitialData: boolean;
  toolsToUnassign?: ItoolDataToUnassign[];
  logisticsOriginCountry?: ICountryDetail;
  logisticsPredefinedProp?: boolean;
  navigationOrigin?: string;
  navigationOriginId?: string | number;
  categoryName?: TtoolCategories | null;
}
