import { NudosGenericModal } from '../../../../../components/NudosComponents';
import ModalPredefineLogistics from '../../../employees/employeeDetails/Components/UnassignToolsFromEmployeeModal/ModalPredefineLogistics';

const ManageToolActionModal = ({
  closeModalCallback,
  submitModalCallback,
  updateLogisticsPredefinedCallback
}: {
  closeModalCallback: () => void;
  submitModalCallback: () => void;
  updateLogisticsPredefinedCallback: (requireLogistics: boolean) => void;
}) => {
  return (
    <div className="ManageToolActionModal">
      <NudosGenericModal
        modalContent={
          <ModalPredefineLogistics
            title="Traslado de herramientas"
            subtitle="¿Requieres de los servicios logísticos de Nudos para realizar la recolección y entrega de las herramientas?"
            isAssignment={false}
            closeModalCallback={closeModalCallback}
            submitModalCallback={submitModalCallback}
            updateLogisticsPredefined={updateLogisticsPredefinedCallback}
          />
        }
      />
    </div>
  );
};

export default ManageToolActionModal;
