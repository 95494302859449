import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6451_3442)">
      <path
        d="M6.00021 11.3549C8.95889 11.3549 11.3574 8.95645 11.3574 5.99777C11.3574 3.0391 8.95889 0.640625 6.00021 0.640625C3.04154 0.640625 0.643066 3.0391 0.643066 5.99777C0.643066 8.95645 3.04154 11.3549 6.00021 11.3549Z"
        fill={props?.fill || 'white'}
        stroke={props?.stroke || '#999999'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99987 6.49876C7.03506 6.49876 7.87425 5.65957 7.87425 4.62438C7.87425 3.58919 7.03506 2.75 5.99987 2.75C4.96467 2.75 4.12549 3.58919 4.12549 4.62438C4.12549 5.65957 4.96467 6.49876 5.99987 6.49876Z"
        fill={props?.fill || 'white'}
        stroke={props?.stroke || '#999999'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.17492 10.317C8.28636 10.9718 7.18828 11.3588 5.9998 11.3588C4.81133 11.3588 3.71325 10.9718 2.82471 10.317C3.00166 9.65053 3.3844 9.05499 3.92079 8.61656C4.50752 8.13698 5.24201 7.875 5.9998 7.875C6.7576 7.875 7.49209 8.13698 8.07882 8.61656C8.6152 9.05499 8.99792 9.65053 9.17492 10.317Z"
        fill={props?.fill || 'white'}
        stroke={props?.stroke || '#999999'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6451_3442">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuEmployeesIcon = memo(ForwardRef);
export default MenuEmployeesIcon;
