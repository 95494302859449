import { SyntheticEvent, useRef } from 'react';
import { DeleteSquareXButton, UploadIcon } from '../../../assets/images/components/NudosComponents';
import { Tany } from '../../../types/global';
import './NudosUploadButton.scss';

/**
 * @property {(file?: File) => void} uploadedFileHandler - The function callback to execute upon changing the file to be uploaded
 * @property {boolean} isFilled - A boolean indicating if the button is to be shown as filled
 * @property {string} buttonEmptyText - The text to show when the button is empty
 * @property {string} buttonFilledText - The text to show when the button is filled
 * @property {boolean} isDisabled - A boolean indicating if the button is to be shown as disabled
 * @property {string} width - A custom width for the component, eg "80px". The default is max-content
 * @property {string} height - A custom height for the component, eg "20px". The default is 18px
 * @property {string} customClassName - A className for customizing styles
 */
const NudosUploadButton = ({
  uploadedFileHandler,
  isFilled,
  buttonEmptyText,
  buttonFilledText,
  isDisabled,
  width,
  height,
  customClassName,
  redFilled
}: InudosUploadButton) => {
  const hiddenFileinput = useRef<HTMLInputElement>(null);

  const disabledStyles = isDisabled ? 'disabled' : redFilled ? 'disabledRed' : '';
  const filledStyles = isFilled ? 'filled' : 'empty';
  const buttonText = isFilled ? buttonFilledText : buttonEmptyText;
  const customSize = { width, height };
  const customStyles = customClassName ? customClassName : '';

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();
    if (hiddenFileinput.current !== null) hiddenFileinput.current.click();
  };

  const handleChange = (event: Tany) => {
    const uploadedFile = event.target.files[0];
    uploadedFileHandler(uploadedFile);
  };

  return (
    <div className={`nudosUploadButton ${disabledStyles} ${customStyles} ${filledStyles}`}>
      <div className="uploaderButton" onClick={handleClick} style={customSize}>
        {<div className="buttonTextContainer">{buttonText}</div>}
        {isFilled && <DeleteSquareXButton className="xIcon" />}
        {!isFilled && <UploadIcon className="uploadIcon" />}
      </div>
      {!isDisabled && <input type="file" className="hidden" ref={hiddenFileinput} onChange={handleChange} />}
    </div>
  );
};

export default NudosUploadButton;

export interface InudosUploadButton {
  uploadedFileHandler: (file?: File) => void;
  isFilled: boolean;
  buttonEmptyText: string;
  buttonFilledText: string;
  isDisabled?: boolean;
  width?: string;
  height?: string;
  customClassName?: string;
  redFilled?: boolean;
}
