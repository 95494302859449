import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      className="path1"
      d="M4 4.666h9.333l-1.333 5H5.333L4 4.666Z"
      fill="#fff"
      stroke="#ff664e"
      strokeLinejoin="round"
    />
    <path className="path2" d="m4 4.666-.333-1.333H2" stroke="#ff664e" strokeLinejoin="round" />
    <circle className="circle" cx={5.666} cy={12.333} r={1} fill="#fff" stroke="#ff664e" strokeLinejoin="round" />
    <circle className="circle" cx={11.666} cy={12.333} r={1} fill="#fff" stroke="#ff664e" strokeLinejoin="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MinCart = memo(ForwardRef);
export default MinCart;
