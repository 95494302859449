import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={11} fill="none" ref={ref} {...props}>
    <g>
      <path className="path1" fill="white" d="M5.949 1.67v1.897a.474.474 0 0 0 .474.474H8.32" />
      <path
        className="path2"
        fill="white"
        d="M7.371 10.205H2.63a.948.948 0 0 1-.948-.949V2.618a.948.948 0 0 1 .948-.948h3.319l2.37 2.37v5.216a.948.948 0 0 1-.948.949Z"
      />
      <path
        className="path3"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.949 1.67v1.897a.474.474 0 0 0 .474.474H8.32M5.949 1.67H2.63a.948.948 0 0 0-.948.948v6.638a.948.948 0 0 0 .948.949H7.37a.948.948 0 0 0 .949-.949V4.041M5.949 1.67l2.37 2.37"
      />
      <path
        className="path4"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.947 5.462H4.762a.711.711 0 1 0 0 1.423h.474a.711.711 0 1 1 0 1.422H4.051m.948 0v.475m0-3.794v.474"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconQuotation = memo(ForwardRef);
export default IconQuotation;
