import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g>
      <path
        className="path1"
        d="M1.64554 17.6032C1.90977 20.073 3.89611 22.0593 6.36461 22.3344C10.1563 22.757 13.8438 22.757 17.6354 22.3344C20.104 22.0593 22.0903 20.073 22.3545 17.6032C22.5496 15.78 22.7143 13.9083 22.7143 12C22.7143 10.0917 22.5496 8.21997 22.3545 6.39681C22.0903 3.92712 20.104 1.94076 17.6354 1.66561C13.8438 1.24297 10.1563 1.24297 6.36461 1.66561C3.89611 1.94076 1.90977 3.92712 1.64554 6.39681C1.45048 8.21997 1.28577 10.0917 1.28577 12C1.28577 13.9083 1.45048 15.78 1.64554 17.6032Z"
        fill="white"
        stroke="#FF664E"
      />
      <path className="path2" d="M12 8V16" stroke="#FF664E" strokeLinecap="round" />
      <path className="path3" d="M16 12H8" stroke="#FF664E" strokeLinecap="round" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const AddIcon = memo(ForwardRef);
export default AddIcon;
