import { NudosButton, NudosToolTip } from '../../../../../components/NudosComponents';
import { useBillingData } from '../../../../../hooks/useBillingData';
import { DTOgetTaxationRelatedDefinitionsOptions, IbillingData } from '../../../../../types/checkout';
import { ICountryDetail } from '../../../../../types/countries';
import { useTranslation } from 'react-i18next';
import './BillingDataByCountryForm.scss';

const BillingDataByCountryForm = ({
  initialBillingData = {},
  showSkeletonLoader,
  updateDataCallback,
  listOfAvailableCountries,
  taxationRelatedDefinitionsOptions
}: {
  initialBillingData?: IbillingData;
  showSkeletonLoader?: boolean;
  updateDataCallback?: () => void;
  listOfAvailableCountries?: ICountryDetail[];
  taxationRelatedDefinitionsOptions?: DTOgetTaxationRelatedDefinitionsOptions;
}) => {
  const {
    loadingBillingDataForm,
    hasBillingDataChange,
    isDataIncomplete,
    isAMexicanOrganization,
    thisBillingCountryRequiresTaxSituationDocument,
    businessNameTooltip,
    organizationIsUruguay,
    businessHasSpecialNameType,
    getEmailInput,
    getCityInput,
    getZipCodeInput,
    getBillingDataStateInput,
    getTaxDocumentInput,
    getBusinessNameInput,
    getBillingAddressInput,
    getAdditionalReferencesInput,
    getTaxRegimeDropdownWithSearchbar,
    getTaxUseDropdownWithSearchbar,
    getTaxDocumentUploadDownloadButton,
    handleUpdateBillingData,
    getNudosCountryDropwdown,
    getFantasyNameInput,
    getPhoneNumberInput,
    getSpecialBusinessNameType
  } = useBillingData(initialBillingData);
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:account:billingDataByCountryForm:';

  const saveButton = (
    <NudosButton
      customClassName="saveButton"
      buttonText={t(`${i18nLanguageKey}saveButton`)}
      customWidth="80px"
      customHeight="18px"
      handleClick={() => handleUpdateBillingData(updateDataCallback)}
      isButtonDisabled={isDataIncomplete() || !hasBillingDataChange()}
      isButtonLoading={!isDataIncomplete() && loadingBillingDataForm}
      shouldAttractAttention={!isDataIncomplete() && hasBillingDataChange()}
    />
  );

  return (
    <div className="billingDataByCountryForm">
      {showSkeletonLoader && <div className="countryTitleSkeletonLoader animationLoader" />}
      <div className="formFieldsLine firstLine">
        <div className="countryDropwdown">
          {getNudosCountryDropwdown(
            listOfAvailableCountries || [],
            !!initialBillingData?.documentCountry?.id,
            showSkeletonLoader
          )}
        </div>
        {getTaxDocumentInput(showSkeletonLoader)}
        <div className="businessNameContainer">
          {getBusinessNameInput(showSkeletonLoader)}
          {isAMexicanOrganization && (
            <NudosToolTip
              tooltipContent={businessNameTooltip}
              tooltipContentCustomStyles={{ width: '218px', height: '76px' }}
            />
          )}
        </div>
        {thisBillingCountryRequiresTaxSituationDocument && getTaxDocumentUploadDownloadButton(showSkeletonLoader)}
      </div>
      {organizationIsUruguay && (
        <div className="formFieldsLine secondLine">
          {getFantasyNameInput(showSkeletonLoader)}
          {getPhoneNumberInput(listOfAvailableCountries || [])}
        </div>
      )}
      <div className="formFieldsLine secondLine">
        {getEmailInput(showSkeletonLoader)}
        {getCityInput(showSkeletonLoader)}
        {getZipCodeInput(showSkeletonLoader)}
        {getBillingDataStateInput(showSkeletonLoader)}
      </div>
      <div className="formFieldsLine thirdLine">
        {getBillingAddressInput(showSkeletonLoader)}
        {getAdditionalReferencesInput(showSkeletonLoader)}
        {saveButton}
      </div>
      {isAMexicanOrganization && (
        <div className="formFieldsLine fourthLine">
          {getTaxRegimeDropdownWithSearchbar(taxationRelatedDefinitionsOptions?.taxSituation || [], showSkeletonLoader)}
          {getTaxUseDropdownWithSearchbar(taxationRelatedDefinitionsOptions?.taxUse || [], showSkeletonLoader)}
        </div>
      )}
      <div className="documentLine">
        {businessHasSpecialNameType && getTaxDocumentUploadDownloadButton(showSkeletonLoader)}
      </div>
      <div>{getSpecialBusinessNameType()}</div>
    </div>
  );
};

export default BillingDataByCountryForm;
