import { useCallback, useEffect, useRef, useState } from 'react';
import { Tany } from '../../../types/global';
import './NudosGenericModal.scss';

/**
 * @property {JSX.Element} modalContent - REQUIRED: a JSX component to be displayed in the modal
 * @property {boolean} closesOnClickOutside - Optional: a boolean indicating if the modal is supposed to close when clicking outside of the content at its center. Default value is false.
 * @property {function() void} closeModalCallback - Optional: a callback function to execute when the modal is going to close as a consequence of passing the shouldCloseModal property as true.
 * @property {function() void} clickOutsideCallback - Optional: a callback function to execute when user clicks outside of the modal.
 * @property {boolean} shouldCloseModal - Optional: a boolean to close the modal, if pass a true the modal will close.
 */
const NudosGenericModal = ({
  modalContent,
  closesOnClickOutside,
  closeModalCallback,
  clickOutsideCallback,
  shouldCloseModal,
  customZIndex
}: {
  modalContent: JSX.Element;
  closesOnClickOutside?: boolean;
  closeModalCallback?: () => void;
  clickOutsideCallback?: () => void;
  shouldCloseModal?: boolean;
  customZIndex?: number;
}) => {
  const [isModalOpened, setIsModalOpened] = useState(true);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const onHandleClickAway = useCallback(
    (e: IElement) => {
      if (modalContentRef.current && !modalContentRef.current.contains(e.target)) {
        clickOutsideCallback && clickOutsideCallback();
        closesOnClickOutside && setIsModalOpened(false);
      }
    },
    [modalContentRef]
  );
  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);
  useEffect(() => {
    if (shouldCloseModal) {
      closeModalCallback && closeModalCallback();
      setIsModalOpened(false);
    }
  }, [shouldCloseModal]);

  return (
    <>
      {isModalOpened && (
        <div className={`nudosGenericModal ${isModalOpened ? '' : 'closed'}`} style={{ zIndex: customZIndex || '' }}>
          <div ref={modalContentRef} className="modalContentContainer">
            {modalContent}
          </div>
        </div>
      )}
    </>
  );
};

export default NudosGenericModal;

export interface IElement {
  target: Tany;
  customZIndex?: number;
}
