import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IassigneeInfo } from '../../../../../types/global';
import ToolHistoryCard from './Components/ToolHistoryCard/ToolHistoryCard';
import { BreadcrumbButtonReturnIcon } from '../../../../../assets/images/components/NudosComponents';
import './ToolLocationsHistory.scss';

const ToolLocationsHistory = ({
  previousAssignmentHistory,
  updateDataCallback,
  showSkeletonLoader,
  orderId
}: IassignationCardList) => {
  const { t } = useTranslation();
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [expandedStyles, setEpandedStyles] = useState('');
  const i18nLanguageKey = 'nodi:toolInternal:';

  const handleClickShowOrHideHistoryButton = () => {
    if (showHistory) {
      setEpandedStyles('');
      return setTimeout(() => setShowHistory(false), 1000);
    }
    setEpandedStyles('expanded');
    setShowHistory(true);
  };

  return (
    <div className={`ToolLocationsHistory ${expandedStyles}`}>
      <div className="showToolLocationHistoryButton" onClick={handleClickShowOrHideHistoryButton}>
        {t(`${i18nLanguageKey}history`)}
        <BreadcrumbButtonReturnIcon
          className="arrow"
          style={{ transform: !showHistory ? 'rotate(270deg)' : 'rotate(90deg)' }}
        />
      </div>
      {!showSkeletonLoader && (
        <div className="containerHistory">
          {showHistory &&
            previousAssignmentHistory &&
            previousAssignmentHistory.map((assignation, i) => {
              return (
                <ToolHistoryCard
                  key={`card${assignation.locationLogId || ''}-${i}`}
                  assignation={assignation}
                  updateDataCallback={updateDataCallback}
                  orderId={orderId}
                />
              );
            })}
          {<div className="marginAtBottom h-10 w-10" />}
        </div>
      )}
      {showSkeletonLoader && (
        <div className="containerHistory">
          {[0, 1, 2, 3, 4, 5].map(i => {
            return <div key={`skeletoCard-${i}`} className="tooHistoryCardSkeletonLoader animationLoader" />;
          })}
        </div>
      )}
      {!showSkeletonLoader && (!previousAssignmentHistory || previousAssignmentHistory.length <= 0) && showHistory && (
        <div className="noHistoryText"> {t(`${i18nLanguageKey}noHistoryText`)}</div>
      )}
    </div>
  );
};

export default ToolLocationsHistory;

export interface IassignationCardList {
  previousAssignmentHistory?: IassigneeInfo[];
  updateDataCallback: () => void;
  showSkeletonLoader?: boolean;
  orderId?: number;
}
