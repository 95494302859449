import { FC, useState } from 'react';
import IconGreenThunder from '../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import IconWarningInformation from '../../../assets/DesignSystem/SVGComponents/IconWarningInformation';
import { nudosFormatCurrency } from '../../../utils/formatNumbers';
import { useTranslation } from 'react-i18next';
import './SmallPrimeSavings.scss';
interface ISmallPriceSavings {
  totalSavings: number;
  shippingDiscount: number;
}

const SmallPrimeSavings: FC<ISmallPriceSavings> = ({ totalSavings = 0, shippingDiscount = 0 }: ISmallPriceSavings) => {
  const { t } = useTranslation();
  const [showDetailsTooltip, setShowDetailsTooltip] = useState<boolean>(false);
  const languageKey = 'nodi:checkout:countryPrices:';

  return (
    <div id="smallPrimeSavings">
      <div className="boxPrimeSavings">
        <div className="thunderSavingsIcon">
          <IconGreenThunder fill="#7B61FF" />
        </div>
        <div className="savingsBlueText">
          {t(`${languageKey}primeSavings`)}
          <strong>{` ${nudosFormatCurrency({
            value: totalSavings,
            maximumFractionDigits: 2
          })} USD `}</strong>
          {` ${t(`nodi:dynamicBanner:with`)} Prime`}
        </div>
        <div className="tooltipSavings">
          <IconWarningInformation
            onMouseOverCapture={() => setShowDetailsTooltip(true)}
            onMouseMoveCapture={() => setShowDetailsTooltip(true)}
            onMouseOutCapture={() => setShowDetailsTooltip(false)}
            onMouseOut={() => setShowDetailsTooltip(false)}
          />
          {showDetailsTooltip && (
            <div className="minModalInfo">
              <div className="minBlueTitle">{t('nodi:footerBanner:checkoutSummary:bannerTitleLite')}</div>
              <div className="minBlueBody">
                {t(`${languageKey}shippingPayments`)} =
                {` ${
                  shippingDiscount
                    ? nudosFormatCurrency({ value: shippingDiscount, maximumFractionDigits: 2 })
                    : t('recurrentWords:toBeCalculated')
                }`}
                <br /> {t(`${languageKey}purchaseDiscount`)} =
                {`${nudosFormatCurrency({
                  value: totalSavings - shippingDiscount,
                  maximumFractionDigits: 2
                })} USD`}
              </div>
              <div className="minListBlue">
                <div className="labelTotals">{t(`${languageKey}labelTotalSave`)} </div>
                <div className="labelAmount">
                  <strong>
                    {nudosFormatCurrency({
                      value: totalSavings,
                      maximumFractionDigits: 2
                    })}
                    USD
                  </strong>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SmallPrimeSavings;
