import { useHistory } from 'react-router-dom';
import './CantManageTooltip.scss';

/**
 * IMPORTANT: add the mixin showWhenHovered('cantManageTooltip') to the css styles of the parent component
 * This component is to be used as the tooltip to show when a tool can not be managed due to active logistics or order that hasnt been delivered.
 * @property { string } toolTipText - The text to display on the tooltip
 * @property { { [key: string]: string } } toolTipStyles - An object with styles for the tooltip, use to provided the width, height, top/bottom, right/left properties, as well as others deemed required
 * @property { string } redirectionText - The text that will act as call to action for redirecting to the desired route (eg: Ver orden)
 * @property { string } redirectionUrl - The route to which the user will be redirected upon clicking the redirectionText (eg "/nodi/orders/details/1363")
 * @returns
 */
const CantManageTooltip = ({
  toolTipText,
  toolTipStyles,
  redirectionText = '',
  redirectionUrl = ''
}: {
  toolTipText: string;
  toolTipStyles: { [key: string]: string };
  redirectionText: string;
  redirectionUrl: string;
}) => {
  const { push } = useHistory();
  const handleRedirect = () => {
    if (redirectionUrl) push(redirectionUrl);
  };

  return (
    <div className="cantManageTooltip" style={toolTipStyles}>
      {toolTipText}
      {!!redirectionUrl && <u onClick={handleRedirect}>{redirectionText}</u>}
    </div>
  );
};

export default CantManageTooltip;
