import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round">
      <path d="M7 13.75a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5Z" fill="#fff" />
      <path d="M7 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" fill="#fff" />
      <path d="M7 8v2" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const LocationIcon = memo(ForwardRef);
export default LocationIcon;
