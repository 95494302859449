import { useEffect, useLayoutEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  NudosAddressSearchBar,
  NudosButton,
  NudosCountrySelect,
  NudosPhoneInput,
  NudosTextInput
} from '../../../NudosComponents';
import { ICountryDetail } from '../../../../types/countries';
import { IaddressFromGoogleMaps } from '../../../../types/global';
import { getAddressErrorText, getIdDocumentData, isPhoneValid } from '../../../../utils/formValidations';
import { getIsInvalidZipCode, invalidZipCodeText, supportUrl } from '../../../../utils/productDefinitions';
import { IorganizationOfficeData } from '../../../../types/account';
import {
  IEditLocationData,
  IIssueReported,
  IUpdateLogisticServiceAddress,
  IlogisticServiceDetailedData
} from '../../../../types/logisticsServicesModule.types';
import { NudosCityDropdown } from '../..';
import { IcityDetails } from '../../../../types/cities.types';
import { getCountriesList } from '../../../../services/countries.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../utils/displayNudosStandardNotifications';
import { IconInformation, IconWarning } from '../../../../assets/DesignSystem/SVGComponents';
import { updateLogisticServiceAddress } from '../../../../services/logisticsServicesModule.services';
import { segmentTrackEvent } from '../../../../utils/segment';
import { capitalize } from '../../../../utils/unCamelCase';
import './NudosEditOfficeAddressModal.scss';

/**
 * IMPORTANT: Use this modal as the prop for the CustomModalComponent prop of the NudosModalContent, which in turn must be the modalContent prop of the NudosGenericModal
 * @property { string } subtitle  - The text to show as a modal subtitle
 * @property { string } giverOrReceiverSectionTitle  - The text specifying the role of the person whose name, phone and personalId would be stored. Just for UI purposes
 * @property { string } buttonText  - The text for the modal button
 * @property { ICountryDetail[] } listOfCountries  - A list of countries from which to choose the address country
 * @property { (modalData: IorganizationOfficeData) => void } clickButtonCallback  - a function to execute upon clickin the modal button, receives as param the data of the modal
 * @property { IorganizationOfficeData } currentOfficeData - The information currently on DB for the organization's office whose data will be edited by the modal
 * @property { boolean } disableCountrySelection - A boolean indicating if a the country select for the addres is disabled. Only works if the currentOfficeData contains a country listed in the listOfCountries
 * @property { boolean } isButtonLoading - A boolean indicating if the modal submit data button is loading
 * @property { ICountryDetail } preselectedOfficeCountry - The default country to which the office whose data will be modified by the modal belongs
 */

const NudosEditOfficeAddressModal = ({
  subtitle,
  buttonText,
  listOfCountries,
  isButtonLoading,
  currentOfficeData,
  closeModalCallback,
  originDestination,
  logisticServiceData,
  clickButtonCallback,
  requestCancelLSCallback,
  disableCountrySelection,
  preselectedOfficeCountry,
  giverOrReceiverSectionTitle,
  getLogisticServiceDetailCallback,
  issueReported,
  updateEditFieldList
}: {
  subtitle: string;
  buttonText: string;
  listOfCountries?: ICountryDetail[];
  isButtonLoading?: boolean;
  currentOfficeData?: IEditLocationData;
  originDestination?: 'origin' | 'destination';
  closeModalCallback?: () => void;
  logisticServiceData?: IlogisticServiceDetailedData;
  clickButtonCallback?: (modalData: IorganizationOfficeData) => void;
  requestCancelLSCallback?: () => void;
  disableCountrySelection?: boolean;
  preselectedOfficeCountry?: ICountryDetail;
  giverOrReceiverSectionTitle: string;
  getLogisticServiceDetailCallback?: () => void;
  issueReported?: IIssueReported;
  updateEditFieldList?: (field: string) => void;
}) => {
  const { t } = useTranslation();
  const [city, setCity] = useState<IcityDetails>();
  const [zipCode, setZipCode] = useState<string>();
  const [personalId, setPersonalId] = useState<string>();
  const [receiverName, setReceiverName] = useState<string>();
  const [phoneCountry, setPhoneCountry] = useState<ICountryDetail>();
  const [receiverPhone, setReceiverPhone] = useState<string>();
  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const [selectedAddress, setSelectedAddress] = useState<IaddressFromGoogleMaps>();
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [loadingUpdateData, setLoadingUpdateData] = useState(false);
  const [addressAdditionalReferences, setAddressAdditionalReferences] = useState<string>();

  // ERRORS
  const [phoneError, setPhoneError] = useState<string>();
  const [cityError, setCityError] = useState<string>();
  const [addressError, setAddressError] = useState<string>();
  const [zipCodeError, setZipCodeError] = useState<string>();
  const [indicationsError, setIndicationsError] = useState<string>();

  const originTranslation = t('recurrentWords:origin');
  const formTranslationKey = 'nodi:employeeInternal:newEmployeeForm:';
  const destinationTranslation = t('recurrentWords:destination');
  const i18nLanguageKey = 'nodi:orderDetails:updateOrderBillingDataFlux:officeDestinationModal:';
  const alertText = t(`${i18nLanguageKey}alertText`);

  const defaultCountry = countriesList?.find(country => country.code === currentOfficeData?.country?.code);
  const idDocumentData = getIdDocumentData(selectedCountry || undefined, personalId);
  const editingPoint = originDestination === 'origin' ? 'Origin' : 'Destination';
  const logServiceStatus = logisticServiceData?.status === 'por confirmar' ? 'To be confirmed' : 'Confirmed';

  const originDestinationValue = originDestination === 'origin' ? originTranslation : destinationTranslation;
  const { id, manualQuote } = logisticServiceData || {};
  const originCityId = logisticServiceData?.collection?.cityId;
  const destinationCityId = logisticServiceData?.delivery?.cityId;
  const activeStatusName = logisticServiceData?.status === 'activo';
  const logisticServiceCase = !!logisticServiceData;
  const isLogisticSameCity = logisticServiceCase && originCityId === destinationCityId;
  const displayCancelLsContainer = isLogisticSameCity || !!manualQuote;

  const orderLocationData = currentOfficeData?.shipmentInfo;
  const orderPhoneNumber = orderLocationData?.receiverPhone || undefined;
  const orderPhoneCountry = orderLocationData?.receiverPhoneCountry || undefined;
  const orderReceiverName = orderLocationData?.receiverName || undefined;
  const orderDocumentId = orderLocationData?.receiverIdDocument || undefined;

  const logisticLocationData = currentOfficeData?.additionalInfo;
  const logisticPhoneNumber = logisticLocationData?.phone || undefined;
  const logisticPhoneCountry = logisticLocationData?.phoneCountry || undefined;
  const logisticReceiverName = logisticLocationData?.name || undefined;
  const logisticDocumentId = logisticLocationData?.personalId || undefined;

  const personalIdValue = logisticServiceCase ? logisticDocumentId : orderDocumentId;
  const receiverNameValue = logisticServiceCase ? logisticReceiverName : orderReceiverName;
  const receiverPhoneValue = logisticServiceCase ? logisticPhoneNumber : orderPhoneNumber;
  const phoneCountryValue = logisticServiceCase ? logisticPhoneCountry : orderPhoneCountry;
  const disabledInputsLsCase = activeStatusName || manualQuote;
  const countrySelectlist = logisticServiceCase ? countriesList : listOfCountries;

  const cancelLsBlueText =
    manualQuote || activeStatusName
      ? t(`${formTranslationKey}contactSupport`)
      : t(`${formTranslationKey}requestCancellation`);
  const cancelLsMainText = manualQuote
    ? t(`${formTranslationKey}cantEditInformation`)
    : activeStatusName
    ? t(`${formTranslationKey}cantEditWhileActive`)
    : t(`${formTranslationKey}cancelToChangeData`);

  const addressErrorText = getAddressErrorText(
    selectedCountry?.code,
    selectedCountry?.name,
    selectedAddress?.country || undefined,
    true,
    selectedAddress?.coordinates
  );

  const addressSearchBarErrorText = !selectedAddress?.address && !currentOfficeData?.address ? ' ' : addressErrorText;

  const cityDetails: IcityDetails = {
    id: currentOfficeData?.cityId,
    name: currentOfficeData?.city,
    country: selectedCountry
  };

  const openSupportWindow = () => {
    segmentTrackEvent({
      logisticServicesSupportManualModalClick: {
        Country: defaultCountry?.name || '',
        EditingPoint: editingPoint,
        LogServiceStatus: logServiceStatus,
        ModalType: 'Employee'
      }
    });
    window.open(supportUrl);
  };

  const cancelLsContainerAction = () => {
    setLoadingUpdateData(true);
    if (manualQuote) return openSupportWindow();
    if (requestCancelLSCallback) return requestCancelLSCallback();
  };

  const getZipCodeError = () => {
    if (!zipCode) return ' ';
    if (getIsInvalidZipCode(zipCode)) invalidZipCodeText;
  };

  const getIsButtonDisabled = () => {
    if (!selectedCountry || !zipCode || !addressAdditionalReferences) return true;
    if (!selectedAddress || !receiverName || !receiverPhone) return true;
    if (idDocumentData?.errorText) return true;
    if (addressErrorText) return true;
    if (getIsInvalidZipCode(zipCode)) return true;
    return false;
  };

  const getInitialData = () => {
    const initialAddress = currentOfficeData?.address
      ? {
          address: currentOfficeData?.address,
          coordinates: currentOfficeData?.coordinates,
          country: currentOfficeData?.country?.code,
          city: currentOfficeData?.city
        }
      : undefined;
    if (loadingCountries) return;
    if (!phoneCountry) setPhoneCountry(phoneCountryValue);
    if (!personalId) setPersonalId(personalIdValue);
    if (!selectedCountry) setSelectedCountry(defaultCountry);
    if (!zipCode) setZipCode(currentOfficeData?.zipCode?.trim().replaceAll(' ', '') || undefined);
    if (!addressAdditionalReferences)
      setAddressAdditionalReferences(currentOfficeData?.additionalReferences || undefined);
    if (!selectedAddress) setSelectedAddress(initialAddress);
    if (!receiverName) setReceiverName(receiverNameValue);
    if (!receiverPhone) setReceiverPhone(receiverPhoneValue);
    if (!city) setCity(cityDetails);
  };

  const getCountries = async () => {
    try {
      setLoadingCountries(true);
      const dataCountries = await getCountriesList();
      setCountriesList(dataCountries);
    } catch {
      displayErrorNotification();
    } finally {
      setLoadingCountries(false);
    }
  };

  const getPhoneError = () => {
    if (receiverPhone && receiverPhone.length > 3 && !isPhoneValid(receiverPhone))
      return `${t('recurrentWords:inputError:invalidPhone')}`;
    return undefined;
  };

  const handleClickModalButton = () => {
    if (
      !selectedCountry ||
      !zipCode ||
      !addressAdditionalReferences ||
      !selectedAddress ||
      !city ||
      !clickButtonCallback ||
      !receiverName ||
      !receiverPhone ||
      idDocumentData?.errorText
    )
      return;

    const modalData = {
      locationId: (currentOfficeData as IorganizationOfficeData)?.locationId || 0,
      zipCode: zipCode,
      additionalReferences: addressAdditionalReferences,
      address: selectedAddress?.address || currentOfficeData?.address,
      coordinates: selectedAddress?.coordinates || undefined,
      city: city?.name || '',
      cityId: currentOfficeData?.cityId,
      countryId: selectedCountry?.id,
      country: {
        id: selectedCountry?.id,
        code: selectedCountry?.code,
        name: selectedCountry?.name,
        flag: selectedCountry?.flag
      },
      shipmentInfo: {
        receiverName,
        receiverPhone,
        receiverIdDocument: personalId || ''
      }
    };
    clickButtonCallback(modalData);
  };

  const notificationText = (
    <Trans
      i18nKey={t(`${formTranslationKey}notificationText`)}
      components={{ 1: capitalize(originDestinationValue) }}
    />
  );

  const updateLogisticServiceAddressData = async () => {
    if (!id || !originDestination) return;
    setLoadingUpdateData(true);
    try {
      const newDestinationData: IUpdateLogisticServiceAddress = {
        address: {
          address: selectedAddress?.address || undefined,
          additionalReferences: addressAdditionalReferences || undefined,
          coordinates: selectedAddress?.coordinates || undefined,
          city: city?.name || undefined,
          cityId: city?.id || undefined,
          zipCode: zipCode,
          country: selectedCountry?.name,
          countryId: selectedCountry?.id,
          countryCode: selectedCountry?.code,
          contactInformation: {
            name: receiverName || undefined,
            phone: receiverPhone,
            countryPhoneId: phoneCountry?.id,
            personalId: personalId
          }
        }
      };
      await updateLogisticServiceAddress(id, originDestination, newDestinationData);
      displaySuccessNotification({
        customJSXMessage: <>{notificationText}</>
      });
      closeModalCallback && closeModalCallback();
      getLogisticServiceDetailCallback && getLogisticServiceDetailCallback();
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingUpdateData(false);
    }
  };

  const nudosButtonAction = () => (logisticServiceCase ? updateLogisticServiceAddressData() : handleClickModalButton());

  const checkErrorsToDisableButton = () => {
    if (!!phoneError || !!zipCodeError || !!cityError || !!indicationsError || !!addressError) {
      return true;
    }
    return false;
  };

  const findAndShowErrors = () => {
    if (issueReported) {
      const phoneReport = issueReported?.wrongFields.find(
        report => report.addressWrongField.name === 'Número de teléfono'
      );
      const cityReport = issueReported?.wrongFields.find(report => report.addressWrongField.name === 'Ciudad');
      const zipCodeReport = issueReported?.wrongFields.find(
        report => report.addressWrongField.name === 'Código Postal'
      );
      const indicationsReport = issueReported?.wrongFields.find(
        report => report.addressWrongField.name === 'Indicaciones'
      );
      const AddressReport = issueReported?.wrongFields.find(report => report.addressWrongField.name === 'Dirección');

      if (phoneReport) {
        setPhoneError('Corrige el número de teléfono para continuar con el servicio');
      }
      if (cityReport) {
        setCityError('Corrige la ciudad para continuar con el servicio');
      }
      if (zipCodeReport) {
        setZipCodeError('Corrige el CP');
      }
      if (indicationsReport) {
        setIndicationsError('Corrige las indicaciones para continuar con el servicio');
      }
      if (AddressReport) {
        setAddressError('Corrige la dirección para continuar con el servicio ');
      }
    }
  };

  const updateEditFieldListOffice = (field: string) => {
    if (updateEditFieldList) {
      updateEditFieldList(field);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  useLayoutEffect(() => {
    getInitialData();
  }, [currentOfficeData, loadingCountries]);

  useEffect(() => {
    if (selectedCountry || !preselectedOfficeCountry) return;
    setSelectedCountry(preselectedOfficeCountry);
  }, [preselectedOfficeCountry]);

  useEffect(() => {
    findAndShowErrors();
  }, [issueReported]);

  return (
    <div className="nudosEditOfficeAddressModal">
      <h2 className="subtitle">{subtitle}</h2>
      <section className="officeSection">
        <div className="lineInputs">
          <NudosCountrySelect
            label={t(`${i18nLanguageKey}country`)}
            errorText={selectedCountry ? undefined : ' '}
            isDisabled={(!!selectedCountry && disableCountrySelection) || logisticServiceCase}
            listOfCountries={countrySelectlist}
            defaultCountry={selectedCountry}
            showSkeletonLoader={loadingCountries}
            handleCountryChange={setSelectedCountry}
            componentSize="extraSmall"
          />
          <NudosCityDropdown
            countryId={selectedCountry?.id}
            currentValue={city}
            componentSize="small"
            isDeactivated={isLogisticSameCity || loadingUpdateData || disabledInputsLsCase}
            showSkeletonLoader={loadingCountries}
            errorText={cityError}
            updateCurrentValueCallback={e => {
              setCity(e);
              setCityError(undefined);
            }}
          />
          <NudosTextInput
            label={t(`${i18nLanguageKey}directions:label`)}
            errorText={indicationsError}
            isFilled={!!addressAdditionalReferences}
            placeholder={t(`${i18nLanguageKey}directions:placeholder`)}
            handleChange={e => {
              setAddressAdditionalReferences(e);
              setIndicationsError(undefined);
              updateEditFieldListOffice('Directions');
            }}
            defaultValue={addressAdditionalReferences}
            componentSize="small"
            isDeactivated={loadingUpdateData || disabledInputsLsCase}
            showSkeletonLoader={loadingCountries}
          />
        </div>
        <div className="lineInputs">
          <NudosTextInput
            label={t(`${i18nLanguageKey}postalCode`)}
            isFilled={!!zipCode}
            handleBlur={newZipCode => setZipCode(newZipCode?.trim().replaceAll(' ', ''))}
            errorText={getZipCodeError() || zipCodeError}
            placeholder="CP"
            defaultValue={zipCode}
            handleChange={e => {
              setZipCode(e);
              setZipCodeError(undefined);
            }}
            componentSize="extraSmall"
            isDeactivated={loadingUpdateData || isLogisticSameCity || disabledInputsLsCase}
            showSkeletonLoader={loadingCountries}
          />
          <NudosAddressSearchBar
            label={t(`${i18nLanguageKey}address`)}
            errorText={addressSearchBarErrorText || addressError}
            isDisabled={loadingUpdateData || disabledInputsLsCase}
            regionBias={selectedCountry?.code}
            componentSize="large"
            showSkeletonLoader={loadingCountries}
            handleAddressSelection={e => {
              setSelectedAddress(e);
              setAddressError(undefined);
              updateEditFieldListOffice('Address');
            }}
            defaultValueAddressName={selectedAddress?.address || currentOfficeData?.address || undefined}
            didntFindAddressTooltipTopPosition={56 + (addressErrorText ? 10 : 0)}
            didntFindAddressTooltipRightPosition={0}
          />
        </div>
      </section>

      <section className="receiverSection">
        <div className="sectionTitle">{giverOrReceiverSectionTitle}</div>
        <div className="personalIdWarning">{t(`${i18nLanguageKey}personalIdWarning`)}</div>
        <div className="lineInputs">
          <NudosTextInput
            label={t(`${i18nLanguageKey}nameAndLastName`)}
            isFilled={!!receiverName}
            errorText={!receiverName ? ' ' : undefined}
            placeholder={t(`${i18nLanguageKey}nameAndLastName`)}
            handleChange={setReceiverName}
            defaultValue={receiverName}
            componentSize="small"
            isDeactivated={loadingUpdateData || disabledInputsLsCase}
            showSkeletonLoader={loadingCountries}
          />
          {logisticServiceCase && (
            <NudosPhoneInput
              label={t(`${i18nLanguageKey}contactNumber`)}
              errorText={getPhoneError() || phoneError}
              placeholder={t(`${i18nLanguageKey}contactNumber`)}
              countriesList={countriesList || []}
              personalClass="phoneInputStyles"
              handlePhoneChange={setReceiverPhone}
              defaultCountryData={phoneCountry}
              defaultPhoneNumber={receiverPhone}
              showSkeletonLoader={loadingCountries}
              handleCountryPhoneChange={setPhoneCountry}
              isDisabled={loadingUpdateData}
              simpleChangeOfValues={() => {
                setPhoneError(undefined);
                updateEditFieldListOffice('Phone');
              }}
            />
          )}
          {!logisticServiceCase && (
            <NudosTextInput
              label={t(`${i18nLanguageKey}contactNumber`)}
              isFilled={!!receiverPhone}
              errorText={!receiverPhone ? ' ' : undefined}
              placeholder={t(`${i18nLanguageKey}contactNumber`)}
              handleChange={setReceiverPhone}
              defaultValue={receiverPhone}
              componentSize="small"
              showSkeletonLoader={loadingCountries}
            />
          )}
          <NudosTextInput
            label={idDocumentData?.label}
            isFilled={!!idDocumentData?.value}
            customClassName="idDocumentInput"
            errorText={idDocumentData?.errorText}
            placeholder={idDocumentData?.placeholder}
            defaultValue={idDocumentData?.value}
            handleChange={e => {
              setPersonalId(e);
              updateEditFieldListOffice('PersonalID');
            }}
            componentSize="small"
            isDeactivated={loadingUpdateData || disabledInputsLsCase}
            showSkeletonLoader={loadingCountries}
            caption={idDocumentData?.caption}
          />
        </div>
      </section>
      {logisticServiceCase && (
        <div className="alertMessage">
          <IconWarning />
          {alertText}
        </div>
      )}
      {displayCancelLsContainer && (
        <div className="cancelLsContainer">
          <IconInformation className="IconInformation" />
          <div className="cancelLsTextContainer">
            <div>{cancelLsMainText}</div>
            <div className="cancelLsBlueText" onClick={() => cancelLsContainerAction()}>
              {cancelLsBlueText}
            </div>
          </div>
        </div>
      )}
      <NudosButton
        componentSize="medium"
        buttonText={buttonText}
        isButtonDisabled={getIsButtonDisabled() || checkErrorsToDisableButton()}
        handleClick={nudosButtonAction}
        isButtonLoading={isButtonLoading || loadingUpdateData}
        customClassName="saveButton"
      />
    </div>
  );
};

export default NudosEditOfficeAddressModal;
