import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6451_3501)">
      <path
        d="M2.68964 7.82947C3.70182 7.82947 4.52235 7.00895 4.52235 5.99677C4.52235 4.98459 3.70182 4.16406 2.68964 4.16406C1.67747 4.16406 0.856934 4.98459 0.856934 5.99677C0.856934 7.00895 1.67747 7.82947 2.68964 7.82947Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.05196 4.30604C10.0642 4.30604 10.8846 3.48551 10.8846 2.47333C10.8846 1.46116 10.0642 0.640625 9.05196 0.640625C8.03977 0.640625 7.21924 1.46116 7.21924 2.47333C7.21924 3.48551 8.03977 4.30604 9.05196 4.30604Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.05195 11.3608C10.0641 11.3608 10.8847 10.5402 10.8847 9.52803C10.8847 8.51585 10.0641 7.69531 9.05195 7.69531C8.03976 7.69531 7.21924 8.51585 7.21924 9.52803C7.21924 10.5402 8.03976 11.3608 9.05195 11.3608Z"
        fill="white"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.53955 4.39533C5.2131 3.45143 5.31465 3.44348 7.2192 2.90625"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.53955 7.60156C5.2131 8.54547 5.31465 8.55341 7.2192 9.09065"
        stroke={props?.stroke || '#ECECEC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6451_3501">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuIntegrationsIcon = memo(ForwardRef);
export default MenuIntegrationsIcon;
