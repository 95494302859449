import { FC, useState } from 'react';
import { NudosStaticAlertInComponent } from '../../../../../components/DesignSystem';
import { NudosGenericModal, NudosTextArea, NudosUploadFile } from '../../../../../components/NudosComponents';
import { Iemployee } from '../../../../../types/global';
import ExampleEmailModal from '../../../Components/ExampleEmailModal/ExampleEmailModal';
import { useTranslation } from 'react-i18next';
import './ToolsManagementStep5SendEmail.scss';

interface IDocumentDetail {
  documentName: string;
  documentFile: File;
}
interface IToolsManagementStep5SendEmail {
  destinationEmployeeData?: Iemployee;
  handleEmployeeMessage?: (message: string) => void;
  defaultEmployeeMessage?: string;
  handleChangeOptionalFile?: (documentDetails: IDocumentDetail | undefined) => void;
  defaultFile?: IDocumentDetail;
}

const ToolsManagementStep5SendEmail: FC<IToolsManagementStep5SendEmail> = ({
  destinationEmployeeData,
  handleEmployeeMessage,
  defaultEmployeeMessage,
  handleChangeOptionalFile,
  defaultFile
}: IToolsManagementStep5SendEmail) => {
  const { t } = useTranslation();

  const [showExampleModal, setShowExampleModal] = useState<boolean>(false);

  const translationKey = 'services:logistics:email:';
  const alertOptionText = t(`${translationKey}alertOptionText`);

  const employeeName = `${destinationEmployeeData?.firstName || ''} ${destinationEmployeeData?.lastName || ''}`;
  const closeExampleModal = () => setShowExampleModal(false);

  const handleChangeEmployeeMessage = (message: string) => {
    if (handleEmployeeMessage) handleEmployeeMessage(message);
  };

  return (
    <>
      {showExampleModal && (
        <NudosGenericModal
          clickOutsideCallback={() => setShowExampleModal(false)}
          closeModalCallback={() => closeExampleModal()}
          modalContent={<ExampleEmailModal closeModal={closeExampleModal} />}
        />
      )}
      <div className="ToolsManagementStep5SendEmail">
        <div className="boxAssignmentModuleStep4SendEmail">
          <div className="titleSendEmail">
            Establece un correo para informar a {employeeName} sobre su herramienta asignada.
          </div>
          <div className="textAreaSendMail">
            <div className="boxTextAreaSendMail">
              <NudosTextArea
                label="Mensaje al empleado"
                customFontSize={10}
                componentSize="large"
                currentValue={defaultEmployeeMessage || ''}
                maxLength={1000}
                placeholder={`Escribe el mensaje que quieres enviar a ${employeeName}`}
                updateCurrentValueCallback={e => handleChangeEmployeeMessage(e)}
                textHeight={64}
                noResize
                borderError={!defaultEmployeeMessage && !!defaultFile}
              />
            </div>
          </div>
          <div className="fileSendEmail">
            <NudosUploadFile size={380} handleExternalDocuments={handleChangeOptionalFile} defaultFile={defaultFile} />
          </div>
          <div className="containerLine">
            <div className="mailGrayLine" />
          </div>
          <div className="inferiorAlertEmail">
            <div className="boxAlertEmail">
              <NudosStaticAlertInComponent
                customClassname="staticComponent"
                type="bulb"
                customTitleClass="titleStaticComponent"
                alertOptions={{
                  title: alertOptionText
                }}
              />
            </div>
          </div>
          <div className="containerEmailExample">
            <div className="boxEmailExample">
              <div className="infoText">¿No sabes qué escribir?</div>
              <div className="blueText" onClick={() => setShowExampleModal(true)}>
                &nbsp;Mira un ejemplo del correo
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToolsManagementStep5SendEmail;
