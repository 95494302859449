import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_3256_94942)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.94716 11.9715L12.4159 5.84705C13.4911 4.82905 13.4079 3.02697 12.2325 1.8714C11.0846 0.743041 9.33406 0.665505 8.32231 1.69821L2.03003 8.12076C2.03003 8.12076 3.42391 8.67532 4.40347 9.63828C5.38304 10.6012 5.94716 11.9715 5.94716 11.9715Z"
        fill="#ff664e"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.52408 13.114L5.94358 11.9764C5.94358 11.9764 5.38073 10.6056 4.40205 9.64174C3.42337 8.67789 2.03 8.12207 2.03 8.12207L0.868068 12.4522C0.766842 12.8294 1.14549 13.2114 1.52408 13.114Z"
        fill="#ff664e"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3256_94942">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const EditIcon = memo(ForwardRef);
export default EditIcon;
