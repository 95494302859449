import { NudosButton } from '../../../../../components/NudosComponents';
import './NoAvailableToolsModal.scss';

const NoAvailableToolsModal = ({
  handleGoToEcommerce,
  handleGoToToolsListing
}: {
  handleGoToEcommerce: () => void;
  handleGoToToolsListing: () => void;
}) => {
  return (
    <div className="noAvailableToolsModal">
      <div className="textContent">
        <p>No cuentas con herramientas disponibles para</p>
        <p>asignar. Puedes ir a la tienda a comprar una herramienta</p>
        <p>o reasignar alguna que estés usando.</p>
      </div>
      <NudosButton componentSize="medium" buttonText="Ir a la tienda" handleClick={handleGoToEcommerce} />
      <NudosButton
        componentSize="medium"
        colorPalette="secondary"
        buttonText="Ir a herramientas"
        handleClick={handleGoToToolsListing}
      />
    </div>
  );
};

export default NoAvailableToolsModal;
