import {
  ConfirmationResult,
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  User
} from '@firebase/auth';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserAuth } from '../../state/userAuthContext';
import useSignStore from '../../views/signUp/state';
import { FirebaseError } from 'firebase/app';
import { verifyFirebaseOtpErrors, sendFirebaseOtpErrors, genericErrorMessage } from '../../constants';
import { getUsersOrganizationData } from '../../services/services';
import { getPersonalAccount } from '../../services/account';
import segment from '../../utils/segment';
import { displayErrorNotification } from '../../utils/displayNudosStandardNotifications';
import { getShoppingCart } from '../../services/cart.services';
import useShopStore from '../../state/useShopContext';
import { getInfoSubscription } from '../../services/subscriptions.services';
import useStateSubscriptions from '../../state/useStateSubscriptions';
import { getUserLanguagePreference } from '../../services/account';
import { getDefaultLanguage } from '../../utils/getDefaultLanguage';
import useStoreCountryState, { IStoreCountry } from '../../state/useStoreCountryState';

const useOtpController = () => {
  const [otpValue, setOtpValue] = useState<string | undefined>(undefined);
  const { setCart } = useShopStore();
  const { setStoreCountry } = useStoreCountryState();
  const [styleCodeInvalid, setStyleCodeInvalid] = useState<boolean>(false);
  const { addSteps } = useSignStore();
  const { setUpRecaptha } = useUserAuth();
  const { replace } = useHistory();
  const { setStateSubscription } = useStateSubscriptions();

  useEffect(() => {
    setStyleCodeInvalid(false);
  }, [otpValue]);

  const sendLinkToEmail = async (email: string) => {
    try {
      const auth = getAuth();
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true
      };
      if (email) {
        localStorage.setItem('emailForSignIn', email);
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        return { success: true, error: false };
      }
      {
        displayErrorNotification({ customJSXMessage: <>Correo electrónico requerido</> });
      }
    } catch (error) {
      return { success: false, error: true };
    }
  };

  const getOtp = async (
    _phone: string,
    setResult: React.Dispatch<React.SetStateAction<ConfirmationResult | undefined>>
  ) => {
    let successfulOtpReq = false;
    try {
      if (setUpRecaptha) {
        const response = await setUpRecaptha(_phone);
        if (response) {
          setResult(response);
          addSteps();
          successfulOtpReq = true;
        }
      } else {
        displayErrorNotification();
      }
    } catch (err) {
      if (err instanceof FirebaseError) {
        const error: FirebaseError = err;
        const displayError = sendFirebaseOtpErrors.find(val => val.errorCode === error.code);
        displayErrorNotification({ customJSXMessage: <>{displayError?.displayMessage || genericErrorMessage}</> });
      }
      return successfulOtpReq;
    }
  };

  const getUserData = async (user: User) => {
    if (!user?.uid) return;
    const userData = await getUsersOrganizationData(user?.uid);
    try {
      localStorage.setItem('orgData', JSON.stringify(userData));
      return userData;
    } catch {
      displayErrorNotification();
    }
  };

  const verifyLink = async () => {
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = localStorage.getItem('emailForSignIn') || '';
      if (!email) {
        displayErrorNotification({ customJSXMessage: <>Correo electrónico requerido</> });
      }
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any = await signInWithEmailLink(auth, email, window.location.href);
        const { user } = result;
        const orgData = await getUserData(user);
        localStorage.setItem('sessionLogin', JSON.stringify(user));
        localStorage.setItem('orgData', JSON.stringify(orgData));
        localStorage.removeItem('emailForSignIn');
        if (orgData?.organizationState === 'active') replace('/nodi/dashboard');
        else replace('/nodi/tools');
      } catch (error) {
        if (error) {
          replace('/login');
          displayErrorNotification();
        }
      }
    }
  };

  const loginEmailAndPassWord = async (
    email: string,
    password: string,
    setPasswordError: React.Dispatch<React.SetStateAction<string | undefined>>,
    redirectionPath?: string
  ) => {
    const auth = getAuth();
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await signInWithEmailAndPassword(auth, email, password);
      const { user } = result;
      const orgData = await getUserData(user);
      if (orgData) {
        const personalData = await getPersonalAccount(orgData.userId);
        const myCart = await getShoppingCart(orgData.userId, orgData.organizationId);
        const stateInfoSubscription = await getInfoSubscription(orgData.organizationId);
        const languagePreference = await getUserLanguagePreference(orgData?.userId);
        if (languagePreference && languagePreference?.language?.code !== getDefaultLanguage().substring(0, 2)) {
          window.location.reload();
        }
        setCart(myCart);
        if (myCart?.country) {
          const formartStoreCountry: IStoreCountry = {
            countryCode: myCart?.country?.code,
            countryFlag: myCart?.country?.flag,
            countryName: myCart?.country?.name,
            id: myCart?.country.id,
            nameStringId: myCart?.country?.nameStringId || ''
          };
          setStoreCountry(formartStoreCountry);
        }
        setStateSubscription({
          ...stateInfoSubscription,
          levelSubscription:
            stateInfoSubscription?.levelSubscription === 'Vip' ? 'Lite' : stateInfoSubscription?.levelSubscription
        });
        localStorage.setItem('bagItems', JSON.stringify(myCart));
        localStorage.setItem('sessionLogin', JSON.stringify(user));
        localStorage.setItem('orgData', JSON.stringify(orgData));
        localStorage.setItem('typeLogin', 'password');
        languagePreference && localStorage.setItem('language', JSON.stringify(languagePreference));
        personalData && localStorage.setItem('personalData', JSON.stringify(personalData));
        segment.identifyEvent({
          data: {
            email: personalData?.email || '',
            phone: personalData?.phone || ''
          }
        });
      }
      if (orgData?.organizationState === 'active') {
        replace(redirectionPath ? redirectionPath : '/nodi/dashboard');
      } else {
        replace('/inactive');
      }
    } catch (err) {
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/wrong-password') {
          setPasswordError('Contraseña incorrecta');
        } else {
          displayErrorNotification();
        }
      }
    }
  };

  const verifyOtp = async (
    setloading: React.Dispatch<React.SetStateAction<boolean>>,
    result: ConfirmationResult | undefined,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    setloading(true);
    if (otpValue === '' || otpValue === null) return;
    try {
      if (result && otpValue) {
        const _user = await result.confirm(otpValue);
        const _orgData = await getUserData(_user.user);
        localStorage.setItem('sessionLogin', JSON.stringify(_user.user));
        localStorage.setItem('orgData', JSON.stringify(_orgData));
        replace('/nodi/tools');
      }
    } catch (err) {
      if (err instanceof FirebaseError) {
        const error: FirebaseError = err;
        const displayError = verifyFirebaseOtpErrors.find(val => val.errorCode === error.code);
        setStyleCodeInvalid(true);
        displayErrorNotification({ customJSXMessage: <>{displayError?.displayMessage || genericErrorMessage}</> });
      }
    } finally {
      setloading(false);
    }
  };

  const codeInputCommonStyles = {
    width: '48px',
    height: '48px',
    marginRight: '8px',
    borderRadius: '6px',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center'
  };

  const codeInputStyles: { valid: object; invalid: object } = {
    valid: {
      ...codeInputCommonStyles,
      borderColor: 'black'
    },
    invalid: {
      ...codeInputCommonStyles,
      borderColor: '#ff664e',
      color: '#ff664e'
    }
  };

  return {
    getOtp,
    verifyOtp,
    otpValue,
    setOtpValue,
    codeInputStyles,
    styleCodeInvalid,
    setStyleCodeInvalid,
    getUserData,
    sendLinkToEmail,
    verifyLink,
    loginEmailAndPassWord
  };
};
export default useOtpController;
