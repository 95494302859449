import { FC } from 'react';
import './listDescription.scss';
import { NudosHoverText } from '../../NudosComponents';
import { useTranslation } from 'react-i18next';
import { TproductItemCondition } from '../../../types/uploadExternalTools.types';

interface IListDescription {
  index?: number;
  serial?: string | number;
  state?: string | number;
  itemLocationOrAssignment?: string;
}

const ListDescription: FC<IListDescription> = ({
  index,
  serial,
  state,
  itemLocationOrAssignment
}: IListDescription) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:uploadToolSteps:';
  const showState = (typeCondition: TproductItemCondition) => {
    switch (typeCondition) {
      case 'Muy mal':
        return t(`${i18nLanguageKey}stateProduct:value1`);
      case 'Mal':
        return t(`${i18nLanguageKey}stateProduct:value2`);
      case 'Regular':
        return t(`${i18nLanguageKey}stateProduct:value3`);
      case 'Bien':
        return t(`${i18nLanguageKey}stateProduct:value4`);
      case 'Excelente':
        return t(`${i18nLanguageKey}stateProduct:value5`);
      default:
        return '';
    }
  };

  return (
    <div className="externalItemSummary">
      <div className="containerIndex">{`${index || 'noIndex'}.`}</div>
      <div className="containerInfo sizeSerial">
        Serial:
        <span className="subInfo">
          <NudosHoverText
            customClassName="subInfo"
            onlyIfTruncated
            text={`${serial}` || 'Sin serial'}
            charactersLimit={15}
          />
        </span>
      </div>
      <div className="containerInfo sizeState">
        {t(`${i18nLanguageKey}externalItemSummary:sizeState`)}
        <span className="subInfo">{showState(state as TproductItemCondition) || 'Sin estado'}</span>
      </div>
      <div className="containerInfo itemLocationOrAssignment">
        {t(`${i18nLanguageKey}externalItemSummary:itemLocationOrAssignment`)}
        <NudosHoverText
          onlyIfTruncated
          customClassName="subInfo"
          text={itemLocationOrAssignment || 'Sin ubicación'}
          charactersLimit={50}
        />
      </div>
    </div>
  );
};

export default ListDescription;
