import './HeaderNodiInternalProductDetails.scss';

const LoaderHeaderNodiInternalProductDetails = () => {
  return (
    <div id="loaderheaderNodiInternalProductDetails">
      <div className="boxHeaderInternalProduct">
        <div className="containerImg">
          <div className="limitsHeaderImg animationLoader" />
        </div>
        <div className="containerDetails">
          <div className="centerItems">
            <div className="topDetails">
              <div className="brandDetails">
                <div className="limitsImgBrand animationLoader" />
              </div>
              <div className="textDetails animationLoader" />
              <div className="flagDetails">
                <div className="limitsFlag animationLoader" />
              </div>
              <div className="availabilityDetails">
                <div className="textAvailability animationLoader" />
                <div className="availabilityIndicatorBox">
                  <div className="grayIndicator animationLoader" />
                </div>
              </div>
            </div>
            <div className="downDetails">
              <div className="containerPills">
                <div className="fakePill animationLoader mr-1" />
                <div className="fakePill animationLoader mr-1" />
                <div className="fakePill animationLoader" />
              </div>
              <div className="containerSerial">
                <div className="fakeSerial animationLoader" />
              </div>
              <div className="containerNudosCare">
                <div className="fakeCare animationLoader" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderHeaderNodiInternalProductDetails;
