import { useEffect, useState } from 'react';
import { DeleteSquareXButton, MagnifierIcon } from '../../../assets/images/components/NudosComponents';
import useDebounce from '../../../hooks/useDebounce';
import { IconDropdownArrow } from '../../../assets/DesignSystem/SVGComponents';
import './NudosSearchBar.scss';

/**
 * @property {funcion(inputText: string): void} handleChange - Required, a callback to execute upon changing the input text
 * @property {string} placeholder - Required. If no placeholder is desired pass an empty string. Magnifiying glass will still be shown.
 * @property {string} label - Optional
 * @property {string} hasDeleteButton - Optional, a boolean indicating if the searchbar has a delete square on its right end
 * @property {string} customClassName - Optional, a className to customize styles
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {boolean} isDeactivated - Optional, boolean indicating if the input is deactivated. If a value is provided the input will behave as a read only field and styles will be accordingly.
 * @property {boolean} isFilled - Optional, boolean indicating if the input is filled. Should be passed to change the style from empty to filled. Its value should reflect the change in the input value. Default is false, unless it has a default value, in which case is true.
 * @property {boolean} includeMagnifierIcon : Optional, a boolean indicating if the dropdown component should have a magnifier icon on the left or not. DEFAULT is true
 * @property {boolean} includeDropdownArrowIcon : Optional, a boolean indicating if the dropdown component should have an arrow icon on the right or not. DEFAULT is false
 * @property {JSX.Element} customIcon : Optional, a custom icon to show on the dropdown closed UI, if this is passed it replaces the magnifier ico
 * @property {funcion(): void} handleBlur - Optional, a callback to pass to the onBlur event for the input component
 * @property {string} defaultValue - Optional
 */
const NudosSearchBar = ({
  handleChange,
  customClassName,
  placeholder,
  hasDeleteButton,
  label,
  componentSize,
  isDeactivated,
  isFilled,
  includeMagnifierIcon = true,
  includeDropdownArrowIcon = false,
  customIcon,
  handleBlur,
  defaultValue,
  customInputClass,
  customSearchBarContainer
}: InudosSearchBarParams) => {
  const [currentText, setCurrentText] = useState('');

  const componentSizeStyle = componentSize ? componentSize : '';
  const filledStyles = (isFilled || (currentText && currentText.length > 0)) && !isDeactivated ? 'filled' : '';
  const deactivatedStyles = isDeactivated ? 'deactivated' : '';
  const classNameStyles = customClassName ? customClassName : '';
  const handleInputChange = (event: React.SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    if (value) setCurrentText(value);
    if (!value) {
      setCurrentText('');
      handleChange('');
    }
  };
  const debouncedText = useDebounce(currentText, 300);
  const handleClickDelete = () => {
    setCurrentText('');
    handleChange('');
  };

  const deleteButtonStyles = hasDeleteButton ? 'deleteButtonStyles' : '';
  const includeRightSide = (hasDeleteButton && !!currentText) || includeDropdownArrowIcon;

  useEffect(() => {
    if (debouncedText === defaultValue) return;
    typeof debouncedText === 'string' && handleChange(debouncedText);
  }, [debouncedText]);

  useEffect(() => {
    defaultValue && setCurrentText(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={`nudosSearchBar ${filledStyles} ${deactivatedStyles} ${componentSizeStyle} ${classNameStyles} ${deleteButtonStyles}`}
    >
      {label && <label className="nudosSearchBarLabel">{label}</label>}
      <div className={`searchBarContainer ${customSearchBarContainer || ''}`}>
        <div className="left">
          {(includeMagnifierIcon || customIcon) && (
            <div className="magnifierIconContainer">
              {includeMagnifierIcon && !customIcon && <MagnifierIcon className="magnifierIcon" />}
              {customIcon || null}
            </div>
          )}
          <input
            className={customInputClass || ''}
            value={currentText}
            placeholder={placeholder}
            readOnly={isDeactivated}
            onChange={handleInputChange}
            onBlur={handleBlur}
            autoFocus
          />
        </div>
        {includeRightSide && (
          <div className="right">
            {hasDeleteButton && currentText && (
              <div className="deleteIconContainer" onClick={handleClickDelete}>
                <DeleteSquareXButton className="deleteIcon" />
              </div>
            )}
            {includeDropdownArrowIcon && <IconDropdownArrow className="IconDropdownArrow contractArrow" />}
          </div>
        )}
      </div>
    </div>
  );
};

export default NudosSearchBar;

export interface InudosSearchBarParams {
  handleChange: (inputText: string) => void;
  placeholder: string;
  hasDeleteButton?: boolean;
  label?: string;
  customClassName?: string;
  componentSize?: string;
  isDeactivated?: boolean;
  isFilled?: boolean;
  includeMagnifierIcon?: boolean;
  includeDropdownArrowIcon?: boolean;
  customIcon?: JSX.Element;
  handleBlur?: () => void;
  defaultValue?: string;
  customInputClass?: string;
  customSearchBarContainer?: string;
}
