import { nudosGreen200, nudosRed100, nudosYellow200 } from '../styles/constants';
import { TstoragePlace } from './assignationFluxes';
import { IbillingData } from './checkout';
import { ICountryDetail } from './countries';
import { ICoordinates, IreceiverInformation, TnudosCare } from './global';
import { ItoolEssentialData } from './requestLogisticsModule';

export interface DTOlogisticsServiceInListingData {
  organizationId?: number | null;
  id?: number | null;
  productsQuantity?: number | null;
  status?: TlogisticsStatus | null;
  statusStringId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  originName?: string | null;
  originPlace?: string | null;
  originFlag?: string | null;
  destinationName?: string | null;
  destinationPlace?: string | null;
  destinationFlag?: string | null;
}

export interface IdateRange {
  since?: string | null;
  until?: string | null;
}

export interface IlogisticsEstimatedDates {
  collect?: IdateRange;
  delivery?: IdateRange;
}

export interface IlogisticsCollectionOrDeliveryAdditionalInfo {
  name?: string | null;
  personalId?: string | null;
  phone?: string | null;
  phoneCountry: ICountryDetail | null;
}

export interface ILogisticServiceLocationUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  personalId?: string | null;
  phone?: string | null;
  phoneCountry: ICountryDetail | null;
  photoProfile?: string | null;
}

export interface IlogisticServiceLocationData {
  place?: string | null;
  locationName?: string | null;
  address?: string | null;
  zipCode?: string | null;
  additionalReferences?: string | null;
  coordinates?: ICoordinates | null;
  additionalInfo: IlogisticsCollectionOrDeliveryAdditionalInfo;
  country: ICountryDetail;
  city?: string;
  cityId?: number;
  userId?: number | null;
  locationUser?: ILogisticServiceLocationUser;
}

export interface IEditLocationData {
  locationId?: number | null;
  place?: string | null;
  locationName?: string | null;
  address?: string | null;
  zipCode?: string | null;
  additionalReferences?: string | null;
  additionalInfo?: IlogisticsCollectionOrDeliveryAdditionalInfo;
  country?: ICountryDetail | null;
  city?: string | null;
  cityId?: number | null;
  userId?: number | null;
  locationUser?: ILogisticServiceLocationUser | null;
  shipmentInfo?: IreceiverInformation | null;
  coordinates?: ICoordinates | null;
}

export interface IlogisticServiceDetailedData {
  id?: number | null;
  organizationId?: number | null;
  productQuantity?: number | null;
  tools: ItoolEssentialData[];
  status?: TlogisticsStatus | null;
  trackingLink?: string | null;
  trackingCode?: string | null;
  invoiceUrl?: string | null;
  proofOfPaymentUrl?: string | null;
  documentUrl?: string | null;
  estimatedDates: IlogisticsEstimatedDates;
  deliveryDate?: string | null;
  chosenCollectionDate?: string | null;
  chosenDeliveryDate?: string | null;
  estimatedDeliveryTime?: IestimatedDeliveryTime | null;
  createdAt?: string | null;
  collection: IlogisticServiceLocationData;
  delivery: IlogisticServiceLocationData;
  isPaymentAgreement: boolean;
  isPaymentReceived: boolean;
  paymentAgreementDate: string;
  paymentLink: string;
  paymentDate: string;
  paymentType: string;
  deliveryDocumentUrl: string;
  receiptDocumentUrl: string;
  logisticsQuoteData?: ILogisticQuoteGeneralData;
  quoteDocumentUrl?: string;
  manualQuote?: boolean;
  addressIssue?: IAddressIssue | null;
  billingData?: IbillingData;
  billingCountry?: ICountryDetail;
  reports?: ILogisticReports[];
  hasUpdatedDates?: boolean;
  reportNumberOfTries?: number;
  isCancelledAutomatically?: boolean;
  statusStringId?: string;
}

export interface ILogisicReportLogs {
  date?: string;
  message?: string | null;
  status?: string;
}

export interface ILogisticReports {
  currentStatus?: string;
  logs?: ILogisicReportLogs[];
  message?: string;
  reason?: string;
  reasonGlobalStatus?: string;
  reasonNameStringId?: string;
  reasonNameString?: string;
  reasonType?: string;
  reasonTypeNameStringId?: string;
  reportId?: number;
}

export interface IestimatedDeliveryTime {
  since: string | null;
  until: string | null;
}

export interface IAproximateDays {
  id?: number | null;
  countryId?: number | null;
  collectGap?: number | null;
  deliverySince?: number | null;
  deliveryUntil?: number | null;
  deliveryGap?: number | null;
}

export type TlogisticServiceDocumentType = 'invoice' | 'proofOfPayment' | 'document';

export type TlogisticsStatus = 'entregado' | 'por confirmar' | 'confirmado' | 'en proceso' | 'cancelado' | 'activo';

export interface ISimpleToolInfo {
  productId?: number | null;
  additionalServicesIds?: (number | null | undefined)[];
}

export interface IFees {
  redistribution: number;
  exitStorage: number;
  entryStorage: number;
  basicConfiguration: number;
  wiping: number;
  redistributionWithoutDiscount?: number;
}

export interface IStorageDates {
  entryDate: string;
  exitDate: string;
}

export interface IStorageDetails {
  totalStorageMonths?: number;
  totalStorageCosts?: number;
  storageSavings?: number;
  monthsToCharge?: number;
  monthlyFee?: number;
}
export interface IToolQuoteInfo {
  name: string;
  sku: string;
  nudosCareName: string;
  serial: string;
  category: string;
  fees: IFees;
  toolSubtotal: number;
  productId: number;
  isBuyAndHold?: boolean;
  storageDates: IStorageDates;
  storageDetails?: IStorageDetails;
}

export interface ILogisticQuote {
  organizationId: number;
  originId: number;
  destinationId: number;
  destinationCountryId: number;
  tools: ISimpleToolInfo[];
}

export interface ILogisticQuoteGeneralData {
  nudosLogo?: string;
  date?: string;
  organizationName: string;
  organizationId: number;
  platformSubscription: 'Care' | 'Free' | 'Lite' | 'Vip';
  currency: string;
  originName: string;
  destinationName: string;
  type: string;
  movement: string;
  paymentCommission: number;
  paymentCommissionToCharge: number;
  managementCommission: number;
  managementCommissionToCharge: number;
  subtotal: number;
  total: number;
  tools: IToolQuoteInfo[];
  nudosPrimeSaving: number;
  nudosCareSaving: number;
  nudosCareCouldSaving: number;
  nudosPrimeCouldSaving: number;
}

export interface IToolsRejected {
  sku: string;
  productId: number;
  productName: string;
  productBrand: string;
  serial: string;
  nudosCareName: TnudosCare;
  logisticAdditionalServicesIds?: number[];
  logisticAdditionalServices: string[] | null;
}

interface IOriginDestinationDetails {
  country: string;
  city: string;
  place: TstoragePlace;
}
export interface IRejectedLogisticService {
  organizationId: number;
  userId: number;
  origin: IOriginDestinationDetails;
  destination: IOriginDestinationDetails;
  rejectComments: string;
  rejectedAmount: number;
  tools: IToolsRejected[];
}

export type TLogisticServiceQuotePdfDto = {
  nudosLogo: string;
  currency: string;
  platformSubscription: string;
  organizationName: string;
  originName: string;
  destinationName: string;
  movement: string;
  nudosPrimeSaving: number;
  nudosCareSaving: number;
  nudosPrimeCouldSaving: number;
  nudosCareCouldSaving: number;
  date: string;
  managementCommission: number;
  paymentCommission: number;
  type: string;
  subtotal: number;
  total: number;
  tools: IToolQuoteInfo[];
};

export interface ILogisticServiceQuotePdfResponse {
  url: string;
}

export interface IToolInfo {
  productId: number;
  logisticAdditionalServicesData?: TAdditionalServiceData[] | null;
}

export interface TAdditionalServiceData {
  id?: number | null;
  comments?: string | null;
}
export interface IIsAutomatable {
  sameCountryRelocation?: boolean;
  origin?: string;
  destination?: string;
  tools: IToolInfo[];
}

export interface IIsAutomatableData {
  canQuoteBeAutomated: boolean;
  reasonsQuoteCantBeAutomated: string;
}

export type TlogisticsPaymentTypes = 'Care' | 'Cash' | 'Agreement';

export interface IAddressWrongField {
  name: 'Número de teléfono' | 'Ciudad' | 'Dirección' | 'Indicaciones' | 'Código Postal';
  nameStringId: string;
}

export interface IWrongFields {
  logisticServiceAddressIssueReportId: number;
  addressWrongFieldId: number;
  addressWrongField: IAddressWrongField;
}
export interface IIssueReported {
  reportSent: string;
  wrongFields: IWrongFields[];
}

export interface IAddressIssue {
  origin: IOriginDestinationIssue;
  destination: IOriginDestinationIssue;
}

export interface IOriginDestinationIssue {
  issueReported?: IIssueReported[];
  addressEdited?: boolean;
  editedBy?: string;
  viewedChanges?: boolean;
}

export interface IUpdateLogisticServiceAddress {
  firstName?: string;
  lastName?: string;
  personalId?: string;
  countryPhoneId?: number;
  phone?: string;
  address?: {
    address?: string;
    additionalReferences?: string;
    contactInformation?: {
      name?: string;
      phone?: string;
      countryPhoneId?: number;
      personalId?: string;
    };
    city?: string;
    cityId?: number;
    zipCode?: string;
    country?: string;
    countryId?: number;
    countryCode?: string;
    coordinates?: ICoordinates | null;
  };
  countryId?: number;
}

export enum LS_ADDITIONAL_SERVICE_TYPE {
  WIPING = 'Wiping',
  BASIC_CONFIG = 'Configuración básica',
  ADVANCED_CONFIG = 'Configuración avanzada'
}

export enum LS_ADDITIONAL_SERVICE_STATUS {
  TO_COMPLETE = 'Por completar',
  NOT_COMPLETED = 'No completado',
  COMPLETED = 'Completado'
}

export const LS_ADDITIONAL_SERVICE_STATUS_COLOR = {
  [`${LS_ADDITIONAL_SERVICE_STATUS.TO_COMPLETE}`]: nudosYellow200,
  [`${LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED}`]: nudosRed100,
  [`${LS_ADDITIONAL_SERVICE_STATUS.COMPLETED}`]: nudosGreen200
};

export enum LS_ADDITIONAL_SERVICE_NOT_COMPLETED_REASONS {
  MDM = 'MDM',
  APPLE_ID = 'Bloqueado por Apple ID',
  USER_BLOCKED = 'Bloqueado por el usuario',
  NON_FUNCTIONAL = 'Equipo no funcional',
  OTHER = 'Otras razones'
}

export enum LS_STATUS {
  TO_CONFIRM = 'por confirmar',
  CONFIRMED = 'confirmado',
  IN_PROCESS = 'en proceso',
  ACTIVE = 'activo',
  DELIVERED = 'entregado',
  CANCELLED = 'cancelado'
}
