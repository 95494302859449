import create from 'zustand';
import { IbillingData } from '../types/checkout';
import { ICountryDetail } from '../types/countries';

export interface logisticsServiceBillingState {
  invoiceAdditionalEmail?: string;
  setInvoiceAdditionalEmail: (invoiceAdditionalEmail: string) => void;
  logisticsServiceBillingData?: IbillingData;
  setLogisticsServiceBillingData: (logisticsServiceBillingData?: IbillingData) => void;
  billingCountry?: ICountryDetail;
  setBillingCountry: (billingCountryCode?: ICountryDetail) => void;
  cleanLsBillingSate: () => void;
}

const useLogisticsServiceBillingState = create<logisticsServiceBillingState>(set => ({
  invoiceAdditionalEmail: undefined,
  setInvoiceAdditionalEmail: invoiceAdditionalEmail => set({ invoiceAdditionalEmail }),
  logisticsServiceBillingData: undefined,
  setLogisticsServiceBillingData: logisticsServiceBillingData => set({ logisticsServiceBillingData }),
  billingCountry: undefined,
  setBillingCountry: billingCountry => set({ billingCountry }),
  cleanLsBillingSate: () => {
    set({ invoiceAdditionalEmail: undefined, logisticsServiceBillingData: undefined, billingCountry: undefined });
  }
}));

export default useLogisticsServiceBillingState;
