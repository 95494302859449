import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NudosButton } from '../../../../components/NudosComponents';
import { getDefaultLanguage } from '../../../../utils/getDefaultLanguage';
import { CloseXIcon } from '../../../../assets/images/components/floatForm';
import MinSmile from '../../../../assets/images/views/Admin/main/minSmile';
import { emailView, emailViewEn } from '../../../../assets/images/views/RequestLogistics';

import './ExampleEmailModal.scss';
import { TYPE_OF_LANGUAGES } from '../../../../constants';

interface IExampleEmailModal {
  closeModal?: () => void;
}

type LanguageType = keyof typeof TYPE_OF_LANGUAGES;

const ExampleEmailModal: FC<IExampleEmailModal> = ({ closeModal }: IExampleEmailModal) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:email:emailExampleModal:';
  const titleText = t(`${translationKey}title`);
  const description = t(`${translationKey}description`);
  const closeText = t('recurrentWords:close');

  const locale = getDefaultLanguage() as LanguageType;

  const renderEmailByLanguage: Record<LanguageType, JSX.Element> = {
    [TYPE_OF_LANGUAGES.esMX]: <img className="limitsEmailView" src={emailView} />,
    [TYPE_OF_LANGUAGES.enUS]: <img className="limitsEmailView" src={emailViewEn} />
  };

  return (
    <div id="exampleEmailModal">
      <div className="boxExampleModal">
        <CloseXIcon
          className="absolute top-4 right-6 cursor-pointer"
          onClick={() => {
            if (closeModal) {
              closeModal();
            }
          }}
        />
        <div className="titleExampleModal">
          <div className="containerNudosIcon">
            <MinSmile className="iconSize" />
          </div>
          <div className="containerTextNudos">{titleText}</div>
        </div>
        <div className="subtitleModalExample">{description}</div>
        <div className="previewExample">{renderEmailByLanguage[locale]}</div>
        <div className="closeExampleButton">
          <div className="butonBaseClose">
            <NudosButton
              customClassName="baseStyle"
              buttonText={closeText}
              handleClick={() => {
                if (closeModal) {
                  closeModal();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExampleEmailModal;
