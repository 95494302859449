import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={8} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g stroke="#383838">
      <path className="path1" d="M4 7.571A3.571 3.571 0 1 0 4 .428a3.571 3.571 0 0 0 0 7.143Z" fill="#fff" />
      <path className="path2" d="M5.429 4H2.57" strokeLinecap="round" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const EnclircledMinusIcon = memo(ForwardRef);
export default EnclircledMinusIcon;
