import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DownloadIcon, UploadIcon } from '../../../assets/images/components/NudosComponents';
import CloseIcon from '../../../assets/images/views/employeesLoad/closeIcon';
import ContainerModules from '../../../components/containerModules';
import { NudosBreadcrumbButton, NudosButton, NudosLoaderModal } from '../../../components/NudosComponents';
import { employeesFile } from '../../../services/employees.service';
import useEmployeesUploadingState from '../../../state/useEmployeesUploadingState';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import segment from '../../../utils/segment';
import useEcommerceControler from '../../ecommerce/ecommerce.controller';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import downLoadDoc from '../../../assets/docs/plantilla.xlsx';
import { ErrorIcon, SucessIcon } from '../../../assets/images/views/employeesLoad';
import EmployeesArrow from '../../../assets/images/views/employeesLoad/EmployeesArrow';
import { Tany } from '../../../types/global';
import { useTranslation, Trans } from 'react-i18next';
import './employeesLoad.scss';

const EmployeesLoad = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { search } = useLocation();
  const { orgData } = useEcommerceControler();
  const [myFile, setMyFile] = useState<Tany>();
  const [myNameFile, setMyFileName] = useState<string>();
  const [formatInvalid, setFormatInvalid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { setUploadedEmployeesData } = useEmployeesUploadingState();
  const fromWhereItCame = new URLSearchParams(search).get('from');
  const i18nLanguageKey = 'nodi:employee:employeesLoad:';

  const handleClickReturn = () => {
    if (fromWhereItCame === 'dashboard') return push('/nodi/dashboard');
    return push('/nodi/employees');
  };

  const getReturnText = () => {
    if (fromWhereItCame === 'dashboard') return 'Dashboard';
    return t(`${i18nLanguageKey}getReturnText`);
  };

  const downLoadDocument = () => {
    window.open(downLoadDoc, '_blank');
  };

  const shortName = (name: string) => {
    if (name) {
      if (name.length > 20) {
        return `${name.substring(0, 10)}...`;
      }
      return name;
    }
    return name;
  };

  const handleFile = (file: Tany) => {
    const uploadedFile = file.target.files[0];
    if (uploadedFile) {
      const extension = uploadedFile.name.substring(uploadedFile.name.lastIndexOf('.')).toLowerCase();
      if (extension !== '.xlsx' && extension !== 'xls') {
        displayErrorNotification({ customJSXMessage: <>{t(`${i18nLanguageKey}invalidFormat`)}</> });
        setFormatInvalid(true);
      }
      setMyFile(uploadedFile);
      setMyFileName(uploadedFile?.name || '');
    }
  };

  const uploadEmployees = async () => {
    setLoading(true);
    const formData = new FormData();
    myFile && formData.append('file', myFile);
    orgData && orgData?.organizationId && formData.append('organizationId', orgData.organizationId);
    try {
      const data = await employeesFile(formData);
      setUploadedEmployeesData(data);
      push('/nodi/upload-employees/summary');
      setLoading(false);
      segment.sessionEndingEvent();
    } catch (error) {
      displayErrorNotification();
      setLoading(false);
    }
  };

  return (
    <ContainerModules fullScreen>
      <NudosBreadcrumbButton
        customClassName="loadEmployeesReturnButton"
        returnText={getReturnText()}
        returnAction={handleClickReturn}
      />
      {loading && <NudosLoaderModal loaderText="Cargando archivo de empleados" fullModal />}
      <div id="employeesLoad">
        <div className="boxEmployeesLoad">
          <div className="containerItems">
            <div className="boxItems">
              <div className="titleLoad">{t(`${i18nLanguageKey}titleLoad`)}</div>
              <div className="subtitleLoad">{t(`${i18nLanguageKey}subtitleLoad`)}</div>
              <div className="RulesContainer">
                <div>{t(`${i18nLanguageKey}rulesContainer:first`)}</div>
                <div>{t(`${i18nLanguageKey}rulesContainer:second`)}</div>
                <div>
                  <Trans i18nKey={t(`${i18nLanguageKey}rulesContainer:third`)} components={{ 1: <strong /> }} />
                </div>
                <div>{t(`${i18nLanguageKey}rulesContainer:fourth`)}</div>
                <div>
                  <Trans
                    i18nKey={t(`${i18nLanguageKey}rulesContainer:fifth`)}
                    components={{
                      1: <strong />,
                      2: <EmployeesArrow stroke="#54A1FC" className="inline-block mx-0.5" />
                    }}
                  />
                </div>
                <div>{t(`${i18nLanguageKey}rulesContainer:sixth`)}</div>
                <div>{t(`${i18nLanguageKey}rulesContainer:seventh`)}</div>
                <div>
                  <Trans i18nKey={t(`${i18nLanguageKey}rulesContainer:eighth`)} components={{ 1: <strong /> }} />
                </div>
              </div>
              <div className="downloadText">{t(`${i18nLanguageKey}downloadText`)}</div>
              <div className="exampleCheck mb-4">
                <div className="exampleCheckIcon">
                  <SucessIcon />
                </div>
                <div className="exampleName">Alejandra</div>
                <div className="exampleName">García</div>
                <div className="exampleEmail">alejandra@empresa.com </div>
                <div className="exampleLada">
                  México
                  <EmployeesArrow className="styleArrow" style={{ marginLeft: '1px' }} />
                </div>
                <div className="examplePhone">8812749661</div>
                <div className="exampleArea">
                  Diseño <EmployeesArrow className="styleArrow" />
                </div>
                <div className="exampleCargo">Service designer</div>
                <div className="exampleArea">
                  México <EmployeesArrow className="styleArrow" />
                </div>
                <div className="exampleCity">Monterrey</div>
              </div>
              <div className="exampleCheck mb-6">
                <div className="exampleCheckIcon">
                  <ErrorIcon />
                </div>
                <div className="exampleName">Alejandra</div>
                <div className="exampleName">García</div>
                <div className="exampleEmail redBorder">alejandraempresa</div>
                <div className="exampleLada redBorder">
                  México <EmployeesArrow className="styleArrow" style={{ marginLeft: '1px' }} />
                </div>
                <div className="examplePhone redBorder">88-12749661 ext. 123</div>
                <div className="exampleArea">
                  Diseño
                  <EmployeesArrow className="styleArrow ml-0.5" />
                </div>
                <div className="exampleCargo">Service designer</div>
                <div className="exampleArea">
                  México
                  <EmployeesArrow className="styleArrow ml-0.5" />
                </div>
                <div className="exampleCity redBorder">Madrid</div>
              </div>
              <div className="grayLine" />
              <div className="boxButtons">
                <div className="containerButton">
                  <NudosButton
                    handleClick={() => {
                      downLoadDocument();
                    }}
                    customClassName="myButton"
                    buttonText={t(`${i18nLanguageKey}myButton`)}
                    buttonIcon={<DownloadIcon />}
                  />
                </div>
                <div className="containerDowloadFile">
                  {myFile ? (
                    <div
                      className="selectFile"
                      style={{
                        border: formatInvalid ? 'border: 1px solid #FF0000' : '',
                        color: formatInvalid ? '#FF0000' : ''
                      }}
                      onClick={() => {
                        setMyFile(undefined);
                        setMyFileName(undefined);
                        setFormatInvalid(false);
                      }}
                    >
                      <CloseIcon stroke={formatInvalid ? '#FF0000' : ''} className="mr-1" />
                      {myNameFile ? shortName(myNameFile) : 'file.xls'}
                    </div>
                  ) : (
                    <div className="noSelectFile">
                      {t(`${i18nLanguageKey}noSelectFile`)}
                      <input
                        className="customInputLoad"
                        onChange={e => {
                          handleFile(e);
                        }}
                        type="file"
                      />
                      <UploadIcon className="ml-1" />
                    </div>
                  )}
                </div>
                <div className="noteEmployees">
                  <div className="titleNote">⬅️&nbsp;&nbsp;&nbsp;{t(`${i18nLanguageKey}titleNote`)}</div>
                  <div className="subtitleNote">{t(`${i18nLanguageKey}subtitleNote`)}</div>
                </div>
              </div>

              {formatInvalid && <div className="invalidFormat">{t(`${i18nLanguageKey}invalidFormat`)}</div>}
            </div>
          </div>
          <div className="containerFotterButton">
            <NudosButton
              handleClick={() => uploadEmployees()}
              isButtonLoading={loading}
              isButtonDisabled={!myFile || formatInvalid || loading}
              buttonText={t(`${i18nLanguageKey}nudosButton`)}
            />
          </div>
        </div>
      </div>
    </ContainerModules>
  );
};

export default EmployeesLoad;
