import { NudosGenericModal, NudosModalContent } from '../../../../components/NudosComponents';
import SecurityLogoutModalContent from './SecurityLogoutModalContent';
import './SecurityLogoutModal.scss';

const SecurityLogoutModal = ({ closeModalCallback }: { closeModalCallback: () => void }) => {
  return (
    <div className="SecurityLogoutModal">
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            customHeight="220px"
            customWidth="466px"
            excludeHeader
            CustomModalComponent={<SecurityLogoutModalContent closeModalCallback={closeModalCallback} />}
          />
        }
      />
    </div>
  );
};

export default SecurityLogoutModal;
