import create from 'zustand';

export interface iState {
  showFirstMonthTooltip?: string | undefined;
  setShowFirstMonthTooltip: (showFirstMonthTooltip?: string | undefined) => void;
  showLSTooltip?: string | undefined;
  setShowLSTooltip: (showLSTooltip?: string | undefined) => void;
  showDatesTooltip?: string | undefined;
  setShowDatesTooltip: (showDatesTooltip?: string | undefined) => void;
}

const useStatusFixedTooltip = create<iState>(set => ({
  showFirstMonthTooltip: undefined,
  showLSTooltip: undefined,
  showDatesTooltip: undefined,
  setShowFirstMonthTooltip: showFirstMonthTooltip => set({ showFirstMonthTooltip }),
  setShowLSTooltip: showLSTooltip => set({ showLSTooltip }),
  setShowDatesTooltip: showDatesTooltip => set({ showDatesTooltip })
}));

export default useStatusFixedTooltip;
