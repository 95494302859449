import { useState } from 'react';
import ProductImage from '../../../../components/ProductImage';
import { IProductDetail } from '../../../../types/catalogue';

import './SecondaryImages.scss';

const SecondaryImages = ({ product }: { product: IProductDetail }) => {
  const { imageUrl, secondaryImages } = product;
  const [mainImage, setMainImage] = useState<string>(imageUrl || '');
  const [firstAlternativeImage, setFirstAlternativeImage] = useState<string>(
    secondaryImages && secondaryImages[0] ? secondaryImages[0] : ''
  );
  const [secondAlternativeImage, setSecondAlternativeImage] = useState<string>(
    secondaryImages && secondaryImages[1] ? secondaryImages[1] : ''
  );
  const [thirdAlternativeImage, setThirdAlternativeImage] = useState<string>(
    secondaryImages && secondaryImages[2] ? secondaryImages[2] : ''
  );
  const AlternativeImages = [firstAlternativeImage, secondAlternativeImage, thirdAlternativeImage];
  const imagesUpdaters = [setFirstAlternativeImage, setSecondAlternativeImage, setThirdAlternativeImage];
  const updateMainImage = (updater: React.Dispatch<React.SetStateAction<string>>, newMainImage: string) => {
    const tempImg = mainImage;
    setMainImage(newMainImage);
    updater(tempImg);
  };
  return (
    <section className="alternative-images-component">
      <div className="main-image">
        <ProductImage imgUrl={mainImage} />
      </div>
      {product?.secondaryImages && product?.secondaryImages?.length > 0 && (
        <div className="alternative-images-container">
          {AlternativeImages.map((altImg, i) => {
            const onUpdateMainImage = () => {
              updateMainImage(imagesUpdaters[i], altImg);
            };
            if (altImg) {
              return (
                <div key={`img-${altImg}-${i}`} className="alternative-image" onClick={onUpdateMainImage}>
                  <ProductImage imgUrl={altImg} />
                </div>
              );
            }
          })}
        </div>
      )}
    </section>
  );
};

export default SecondaryImages;
