import create from 'zustand';
import { ICartShipping, IFinishCart, IProductShipping } from '../types/cart';
import { getCurrentOrderShippingData } from '../utils/getLocalStorageData';

export interface iState {
  openCart?: boolean;
  setOpenCart: (openCart: boolean) => void;
  globalShippingCart: IProductShipping | undefined;
  setGlobalShippingCart: (globalShippingCart: IProductShipping | undefined) => void;
  dataFinishCart: IFinishCart | undefined;
  setDataFinishCart: (dataFinishCart?: IFinishCart) => void;
  preloadGlobalShippingCartUponReloading: () => void;
  defaultAddressShipment: ICartShipping | undefined;
  setDefaultAddressShipment: (defaultAddressShipment?: ICartShipping | undefined) => void;
}

const useCartState = create<iState>(set => ({
  openCart: false,
  globalShippingCart: undefined,
  dataFinishCart: undefined,
  defaultAddressShipment: undefined,
  setOpenCart: openCart => set({ openCart }),
  setGlobalShippingCart: globalShippingCart => {
    localStorage.setItem('currentOrderShippingData', JSON.stringify(globalShippingCart));
    set({ globalShippingCart });
  },
  setDataFinishCart: dataFinishCart => {
    dataFinishCart && localStorage.setItem('dataFinishCart', JSON.stringify(dataFinishCart));
    set({ dataFinishCart });
  },
  preloadGlobalShippingCartUponReloading: () => {
    const storedShippingData = getCurrentOrderShippingData();
    set({ globalShippingCart: storedShippingData });
  },
  setDefaultAddressShipment: defaultAddressShipment => set({ defaultAddressShipment })
}));

export default useCartState;
