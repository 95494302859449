import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={13} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <path d="M1.066 9.367v.815a1.63 1.63 0 0 0 1.63 1.63h7.336a1.63 1.63 0 0 0 1.63-1.63v-.815M3.532 5.696C4.087 6.718 4.914 7.65 5.98 8.294a.741.741 0 0 0 .767 0C7.813 7.65 8.64 6.718 9.195 5.696M6.364 8.401V1.312" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const DownloadIcon = memo(ForwardRef);
export default DownloadIcon;
