import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={15} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M12.8074 11.0838C12.8901 9.76567 12.9336 8.39969 12.9336 7C12.9336 6.43312 12.9265 5.87177 12.9125 5.31688C12.9027 4.92707 12.7762 4.54824 12.5455 4.2339C11.666 3.03578 10.9654 2.29092 9.81069 1.39977C9.49338 1.15487 9.104 1.022 8.70328 1.01324C8.30145 1.00446 7.88184 1 7.43359 1C6.07664 1 4.98204 1.04087 3.84929 1.11875C2.88565 1.18501 2.12032 1.95214 2.05981 2.91616C1.97708 4.23433 1.93359 5.60031 1.93359 7C1.93359 8.39969 1.97708 9.76567 2.05981 11.0838C2.12032 12.0479 2.88565 12.815 3.84929 12.8812C4.98204 12.9591 6.07664 13 7.43359 13C8.79054 13 9.88514 12.9591 11.0179 12.8812C11.9815 12.815 12.7469 12.0479 12.8074 11.0838Z"
      fill="white"
      stroke="#ff664e"
    />
    <path d="M5.52734 7.01562H9.33984" stroke="#ff664e" strokeLinecap="round" />
    <path
      d="M5.52734 4H7.82799M5.52734 9.92185H9.32799H5.52734Z"
      stroke="#ff664e"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const TermsIcon = memo(ForwardRef);
export default TermsIcon;
