import { FC } from 'react';
import './NoLocationLeft.scss';
import { locationIcon } from '../../../../../assets/images/views/Admin/nodiProductDetails';
import { GenericLoader } from '../../../dashboard/components';

interface INoLocationLeft {
  actionAddLocation?: () => void;
  generalLoading?: boolean;
}

const NoLocationLeft: FC<INoLocationLeft> = ({ actionAddLocation, generalLoading }: INoLocationLeft) => {
  return (
    <div id="noLocationLeft">
      <div className="containerIcon">
        <div className="limitsLocationIcon">
          {generalLoading ? (
            <GenericLoader sizeLoader={60} />
          ) : (
            <img className="max-h-full max-w-full" src={locationIcon} />
          )}
        </div>
      </div>
      {!generalLoading && (
        <div className="containerTextNoLocation">
          <div
            className="blueText"
            onClick={() => {
              if (actionAddLocation) {
                actionAddLocation();
              }
            }}
          >
            Agrega una ubicación
          </div>
          <div className="grayText">&nbsp;para comenzar a gestionar tu herramienta</div>
        </div>
      )}
    </div>
  );
};

export default NoLocationLeft;
